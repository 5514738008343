import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_DEVICE,
  DELETE_DEVICE,
  UPDATE_DEVICE,
  ADD_NEW_DEVICE,
  GET_DEVICE_ONE,
} from "./actionType";

import {
  getDeviceSuccess,
  getDeviceFail,
  deleteDeviceSuccess,
  deleteDeviceFail,
  updateDeviceSuccess,
  updateDeviceFail,
  addDeviceSuccess,
  addDeviceFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveDeviceApi,
  getDeviceApi,
  updateDeviceApi,
  deleteDeviceApi,
  addNewDeviceApi,
  getOneDeviceApi,
} from "../../helpers/backend_helper";

function* getDevice({ payload: device }) {
  try {
    const response = yield call(getDeviceApi, device);
    yield put(getDeviceSuccess(GET_DEVICE, response.data));
  } catch (error) {
    yield put(getDeviceFail(GET_DEVICE, error));
  }
}

function* getDeviceOne({ payload: id }) {
  try {
    const response = yield call(getOneDeviceApi, id);
    yield put(getDeviceSuccess(GET_DEVICE_ONE, response.data));
  } catch (error) {
    yield put(getDeviceFail(GET_DEVICE_ONE, error));
  }
}

function* onUpdateDevice({ payload: device }) {
  try {
    if (device.setApprove) {
      const response = yield call(approveDeviceApi, device);
      yield put(updateDeviceSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateDeviceApi, device);
      yield put(updateDeviceSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateDeviceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteDevice({ payload: device }) {
  try {
    const response = yield call(deleteDeviceApi, device);
    yield put(deleteDeviceSuccess({ device, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteDeviceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewDevice({ payload: device }) {
  try {
    const response = yield call(addNewDeviceApi, device);
    yield put(addDeviceSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addDeviceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetDevice() {
  yield takeEvery(GET_DEVICE, getDevice);
}

export function* watchUpdateDevice() {
  yield takeEvery(UPDATE_DEVICE, onUpdateDevice);
}

export function* watchDeleteDevice() {
  yield takeEvery(DELETE_DEVICE, onDeleteDevice);
}

export function* watchAddNewDevice() {
  yield takeEvery(ADD_NEW_DEVICE, onAddNewDevice);
}

export function* watchGetDeviceOne() {
  yield takeEvery(GET_DEVICE_ONE, getDeviceOne);
}

function* deviceSaga() {
  yield all([
    fork(watchGetDevice),
    fork(watchDeleteDevice),
    fork(watchUpdateDevice),
    fork(watchAddNewDevice),
    fork(watchGetDeviceOne),
  ]);
}

export default deviceSaga;
