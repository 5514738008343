import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_USER, DELETE_USER, UPDATE_USER, ADD_NEW_USER, GET_USER_ONE } from "./actionType";

import {
  getUserSuccess,
  getUserFail,
  deleteUserSuccess,
  deleteUserFail,
  updateUserSuccess,
  updateUserFail,
  addUserSuccess,
  addUserFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveUserApi,
  getUserApi,
  updateUserApi,
  deleteUserApi,
  addNewUserApi,
  getOneUserApi,

} from "../../helpers/backend_helper";

function* getUser({ payload: user }) {
  try {
    const response = yield call(getUserApi, user);
    yield put(getUserSuccess(GET_USER, response.data));
  } catch (error) {
    yield put(getUserFail(GET_USER, error));
  }
}

function* getUserOne({ payload: id }) {
  try {
    const response = yield call(getOneUserApi, id);
    yield put(getUserSuccess(GET_USER_ONE, response.data));
  } catch (error) {
    yield put(getUserFail(GET_USER_ONE, error));
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    if (user.setApprove) {
      const response = yield call(approveUserApi, user);
      yield put(updateUserSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateUserApi, user);
      yield put(updateUserSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateUserFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUserApi, user);
    yield put(deleteUserSuccess({ user, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteUserFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield call(addNewUserApi, user);
    yield put(addUserSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addUserFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetUser() {
  yield takeEvery(GET_USER, getUser);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, onUpdateUser);
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, onDeleteUser);
}

export function* watchAddNewUser() {
  yield takeEvery(ADD_NEW_USER, onAddNewUser);
}

export function* watchGetUserOne() {
  yield takeEvery(GET_USER_ONE, getUserOne);
}


function* userSaga() {
  yield all([
    fork(watchGetUser),
    fork(watchDeleteUser),
    fork(watchUpdateUser),
    fork(watchAddNewUser),
    fork(watchGetUserOne),
  ]);
}

export default userSaga;
