import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_DEVICETYPE,
  DELETE_DEVICETYPE,
  UPDATE_DEVICETYPE,
  ADD_NEW_DEVICETYPE,
} from "./actionType";

import {
  getDeviceTypeSuccess,
  getDeviceTypeFail,
  deleteDeviceTypeSuccess,
  deleteDeviceTypeFail,
  updateDeviceTypeSuccess,
  updateDeviceTypeFail,
  addDeviceTypeSuccess,
  addDeviceTypeFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveDeviceTypeApi,
  getDeviceTypeApi,
  updateDeviceTypeApi,
  deleteDeviceTypeApi,
  addNewDeviceTypeApi,
} from "../../helpers/backend_helper";

function* getDeviceType({ payload: deviceType }) {
  try {
    const response = yield call(getDeviceTypeApi, deviceType);
    yield put(getDeviceTypeSuccess(GET_DEVICETYPE, response.data));
  } catch (error) {
    yield put(getDeviceTypeFail(GET_DEVICETYPE, error));
  }
}

function* onUpdateDeviceType({ payload: deviceType }) {
  try {
    const response = yield call(updateDeviceTypeApi, deviceType);
    yield put(updateDeviceTypeSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateDeviceTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteDeviceType({ payload: deviceType }) {
  try {
    const response = yield call(deleteDeviceTypeApi, deviceType);
    yield put(deleteDeviceTypeSuccess({ deviceType, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteDeviceTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewDeviceType({ payload: deviceType }) {
  try {
    const response = yield call(addNewDeviceTypeApi, deviceType);
    yield put(addDeviceTypeSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addDeviceTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetDeviceType() {
  yield takeEvery(GET_DEVICETYPE, getDeviceType);
}

export function* watchUpdateDeviceType() {
  yield takeEvery(UPDATE_DEVICETYPE, onUpdateDeviceType);
}

export function* watchDeleteDeviceType() {
  yield takeEvery(DELETE_DEVICETYPE, onDeleteDeviceType);
}

export function* watchAddNewDeviceType() {
  yield takeEvery(ADD_NEW_DEVICETYPE, onAddNewDeviceType);
}

function* deviceTypeSaga() {
  yield all([
    fork(watchGetDeviceType),
    fork(watchDeleteDeviceType),
    fork(watchUpdateDeviceType),
    fork(watchAddNewDeviceType),
  ]);
}

export default deviceTypeSaga;
