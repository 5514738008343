import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_DOWNLOADAPP,
  DELETE_DOWNLOADAPP,
  UPDATE_DOWNLOADAPP,
  ADD_NEW_DOWNLOADAPP,
} from "./actionType";

import {
  getDownloadAppSuccess,
  getDownloadAppFail,
  deleteDownloadAppSuccess,
  deleteDownloadAppFail,
  updateDownloadAppSuccess,
  updateDownloadAppFail,
  addDownloadAppSuccess,
  addDownloadAppFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveDownloadAppApi,
  getDownloadAppApi,
  updateDownloadAppApi,
  deleteDownloadAppApi,
  addNewDownloadAppApi,
} from "../../helpers/backend_helper";

function* getDownloadApp({ payload: downloadApp }) {
  try {
    const response = yield call(getDownloadAppApi, downloadApp);
    yield put(getDownloadAppSuccess(GET_DOWNLOADAPP, response.data));
  } catch (error) {
    yield put(getDownloadAppFail(GET_DOWNLOADAPP, error));
  }
}

function* onUpdateDownloadApp({ payload: downloadApp }) {
  try {
    const response = yield call(updateDownloadAppApi, downloadApp);
    yield put(updateDownloadAppSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateDownloadAppFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteDownloadApp({ payload: downloadApp }) {
  try {
    const response = yield call(deleteDownloadAppApi, downloadApp);
    yield put(deleteDownloadAppSuccess({ downloadApp, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteDownloadAppFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewDownloadApp({ payload: downloadApp }) {
  try {
    const response = yield call(addNewDownloadAppApi, downloadApp);
    yield put(addDownloadAppSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addDownloadAppFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetDownloadApp() {
  yield takeEvery(GET_DOWNLOADAPP, getDownloadApp);
}

export function* watchUpdateDownloadApp() {
  yield takeEvery(UPDATE_DOWNLOADAPP, onUpdateDownloadApp);
}

export function* watchDeleteDownloadApp() {
  yield takeEvery(DELETE_DOWNLOADAPP, onDeleteDownloadApp);
}

export function* watchAddNewDownloadApp() {
  yield takeEvery(ADD_NEW_DOWNLOADAPP, onAddNewDownloadApp);
}

function* downloadAppSaga() {
  yield all([
    fork(watchGetDownloadApp),
    fork(watchDeleteDownloadApp),
    fork(watchUpdateDownloadApp),
    fork(watchAddNewDownloadApp),
  ]);
}

export default downloadAppSaga;
