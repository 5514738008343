export const STORAGERENAME_LOADING = "STORAGERENAME_LOADING";
export const STORAGERENAME_RESET = "STORAGERENAME_RESET";
export const GET_STORAGERENAME = "GET_STORAGERENAME";
export const GET_STORAGERENAME_SUCCESS = "GET_STORAGERENAME_SUCCESS";
export const GET_STORAGERENAME_FAIL = "GET_STORAGERENAME_FAIL";
export const DELETE_STORAGERENAME = "DELETE_STORAGERENAME";
export const DELETE_STORAGERENAME_SUCCESS = "DELETE_STORAGERENAME_SUCCESS";
export const DELETE_STORAGERENAME_FAIL = "DELETE_STORAGERENAME_FAIL";
export const UPDATE_STORAGERENAME = "UPDATE_STORAGERENAME";
export const UPDATE_STORAGERENAME_SUCCESS = "UPDATE_STORAGERENAME_SUCCESS";
export const UPDATE_STORAGERENAME_FAIL = "UPDATE_STORAGERENAME_FAIL";
export const ADD_NEW_STORAGERENAME = "ADD_NEW_STORAGERENAME";
export const ADD_STORAGERENAME_SUCCESS = "ADD_STORAGERENAME_SUCCESS";
export const ADD_STORAGERENAME_FAIL = "ADD_STORAGERENAME_FAIL";
export const GET_STORAGERENAME_ONE = "GET_STORAGERENAME_ONE";
