import {
  GET_FOLDER,
  GET_FOLDER_SUCCESS,
  GET_FOLDER_FAIL,
  DELETE_FOLDER,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_FAIL,
  UPDATE_FOLDER,
  UPDATE_FOLDER_SUCCESS,
  UPDATE_FOLDER_FAIL,
  ADD_NEW_FOLDER,
  ADD_FOLDER_SUCCESS,
  ADD_FOLDER_FAIL,
  FOLDER_LOADING,
  FOLDER_RESET,
} from "./actionType";

export const resetFolderState = (actionType) => ({
  type: FOLDER_RESET,
  payload: { actionType },
});

export const getFolderSuccess = (actionType, data) => ({
  type: GET_FOLDER_SUCCESS,
  payload: { actionType, data },
});

export const getFolderFail = (actionType, error) => ({
  type: GET_FOLDER_FAIL,
  payload: { actionType, error },
});

export const getFolder = data => ({
  type: GET_FOLDER,
  payload: data,
});

export const getFolderLoading = () => ({
  type: FOLDER_LOADING
});

export const deleteFolder = data => ({
  type: DELETE_FOLDER,
  payload: data,
});

export const deleteFolderSuccess = data => ({
  type: DELETE_FOLDER_SUCCESS,
  payload: data,
});

export const deleteFolderFail = error => ({
  type: DELETE_FOLDER_FAIL,
  payload: error,
});

export const updateFolder = data => ({
  type: UPDATE_FOLDER,
  payload: data,
});

export const updateFolderFail = error => ({
  type: UPDATE_FOLDER_FAIL,
  payload: error,
});

export const updateFolderSuccess = data => ({
  type: UPDATE_FOLDER_SUCCESS,
  payload: data,
});

export const addNewFolder = data => ({
  type: ADD_NEW_FOLDER,
  payload: data,
});

export const addFolderSuccess = data => ({
  type: ADD_FOLDER_SUCCESS,
  payload: data,
});

export const addFolderFail = error => ({
  type: ADD_FOLDER_FAIL,
  payload: error,
});