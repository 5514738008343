import {
  SENSOR_LOADING,
  GET_SENSOR,
  GET_SENSOR_SUCCESS,
  GET_SENSOR_FAIL,
  ADD_SENSOR_SUCCESS,
  ADD_SENSOR_FAIL,
  DELETE_SENSOR_SUCCESS,
  DELETE_SENSOR_FAIL,
  UPDATE_SENSOR_SUCCESS,
  UPDATE_SENSOR_FAIL,
  SENSOR_RESET,
} from "./actionType";

const INIT_STATE = {
  sensors: [],
  error: {},
};

const Sensor = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SENSOR_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SENSOR:
          return {
            ...state,
            sensors: action.payload.data,
            isSensorCreated: false,
            isSensorSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SENSOR_FAIL:
      switch (action.payload.actionType) {
        case GET_SENSOR_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isSensorCreated: false,
            isSensorSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SENSOR: {
      return {
        ...state,
        isSensorCreated: false,
        loading: true,
      };
    }

    case SENSOR_LOADING: {
      return {
        ...state,
        isSensorCreated: false,
        loading: true,
      };
    }

    case ADD_SENSOR_SUCCESS:
      return {
        ...state,
        isSensorCreated: true,
        loading: false,
        sensors: [...state.sensors, action.payload.data],
      };

    case ADD_SENSOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SENSOR_SUCCESS:
      return {
        ...state,
        loading: false,
        sensors: state.sensors.filter(
          (sensor) => sensor.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SENSOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SENSOR_SUCCESS:
      return {
        ...state,
        loading: false,
        sensors: state.sensors.map((sensor) =>
          sensor.id.toString() === action.payload.data.id.toString()
            ? { ...sensor, ...action.payload.data }
            : sensor
        ),
      };

    case UPDATE_SENSOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SENSOR_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Sensor;
