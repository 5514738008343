import React, {
    useEffect,
    useState,
    useMemo,
    useCallback,
} from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Row,
    Input,
    Spinner,
} from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect,
} from "react-table";
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
    getCompany as onGetCompanys,
    addNewCompany as onAddNewCompany,
    updateCompany as onUpdateCompany,
    deleteCompany as onDeleteCompany,
    getCompanyLoading,
} from "../../../store/company/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const Company = (props) => {
    const { t } = props;
    const [modal, setModal] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const dispatch = useDispatch();
    const [pIndex, setPIndex] = useState(0);

    const {
        companies,
        isCompanyCreated,
        isCompanySuccess,
        loading,
        error,
        isMediaDeleted,
        media,
        mediaLoading,
    } = useSelector((state) => ({
        companies: state.Company.companies,
        isCompanyCreated: state.Company.isCompanyCreated,
        isCompanySuccess: state.Company.isCompanySuccess,
        loading: state.Company.loading,
        error: state.Company.error,
    }));

    const [companyList, setCompanyList] = useState([]);
    const [company, setCompany] = useState([]);
    const [isExportCSV, setIsExportCSV] = useState(false);
    const [statusActive, setStatusActive] = useState([]);
    const [tableItem, setTableItem] = useState([]);

    const [isSearch, setIsSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);

    useEffect(() => {
        if (isCompanyCreated) {
            dispatch(onGetCompanys())
        }
    }, [dispatch, isCompanyCreated])

    const onClickDelete = (company) => {
        setCompany(company);
        setDeleteModal(true);
    };

    const handleDeleteCompany = () => {
        if (company) {
            dispatch(onDeleteCompany(company));
            setDeleteModal(false);
        }
    };

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        setCompanyList(companies);
    }, [companies]);

    useEffect(() => {
        if (!isEmpty(companies)) setCompanyList(companies);
    }, [companies]);

    const toggleTab = (tab, type) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            let filteredCompanys = companies;
            if (type !== "all") {
                filteredCompanys = companies.filter(
                    (company) => company.sensor_status.name === type
                );
            }
            setCompanyList(filteredCompanys);
        }
    };

    useEffect(() => {
        if (!isSearch) {
            dispatch(onGetCompanys());
        }
        setIsSearch(true);
    }, [dispatch, companies, isSearch]);

    useEffect(() => {
        setCompany(companies);
    }, [companies]);

    useEffect(() => {
        if (!isEmpty(companies)) {
            setCompany(companies);
            setIsEdit(false);
        }
    }, [companies]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setCompany(null);
        } else {
            setModal(true);
        }
    }, [modal]);

    const handleCompanyClick = useCallback(
        (arg) => {
            const company = arg;

            setCompany({
                id: company.id,
                name: company.name,
                code: company.code,
                description: company.description,
                status: {
                    label: company.sensor_status.name,
                    value: company.sensor_status.id,
                },
            });

            setIsEdit(true);
            toggle();
        },
        [toggle]
    );

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: (company && company.name) || "",
            code: (company && company.code) || "",
            description: (company && company.description) || "",
            status: (company && company.status) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            code: Yup.string().required("Please Enter Code"),
        }),
        onSubmit: (values) => {
            dispatch(getCompanyLoading());
            if (isEdit) {
                const editCompany = {
                    id: company ? company.id : 0,
                    table_value: JSON.stringify(tableItem),
                };
                console.log(editCompany);
                // dispatch(onUpdateCompany(editCompany));
                validation.resetForm();
            } else {
                const newCompany = {
                    table_value: JSON.stringify(tableItem),
                };
                console.log(newCompany);
                // dispatch(onAddNewCompany(newCompany));
                validation.resetForm();
            }
            toggle();
        },
    });

    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".dataCheckbox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    // Delete Multiple
    const deleteMultiple = () => {
        const ele = document.querySelectorAll(".dataCheckbox:checked");
        const checkall = document.getElementById("checkBoxAll");
        ele.forEach((element) => {
            dispatch(onDeleteCompany(element.value));
        });
        checkall.checked = false;
    };

    const defaultdate = () => {
        return moment().tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
    };

    const [filterDate, setFilterDate] = useState(defaultdate());

    const handleValidDate = (date) => {
        return moment(new Date(date), "YYYY/MM/DD")
            .tz("Asia/Jakarta")
            .format("MMM D, YYYY");
    };

    const generateFilterable = (column) => {
        return column.filterable
            ? column.isSorted
                ? column.isSortedDesc
                    ? " sorting_desc"
                    : " sorting_asc"
                : " sort"
            : "";
    };

    useEffect(() => {
        let fc = []
        companies.forEach((com) => {
            fc.push({
                companyId: com.id,
                name: com.name,
                mapApiKey: com.mapApiKey || ""
            })
        })
        setTableItem(fc)
    }, [companies])

    document.title = `${t("General")} | TELKOMSEL - IOT Monitoring`;
    return (
        <div className='page-content'>
            <ExportCSVModal
                show={isExportCSV}
                onCloseClick={() => setIsExportCSV(false)}
                data={companyList}
            />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteCompany}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultiple();
                    setDeleteModalMulti(false);
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
            <Container fluid>
                <BreadCrumb title={t("General")} pageTitle={t("Setting")} />
                <Row>
                    <Col lg={12}>
                        <Card id='companyList'>
                            <CardHeader className='card-header border-0'>
                                <div className='d-flex align-items-center'>
                                    <h5 className='card-title mb-2 flex-grow-1 fs-4'>
                                        {t("General")} &nbsp;
                                        {isLoading ? (
                                            <>
                                                <Spinner color='primary' type='grow' size={"sm"}>
                                                    Loading...
                                                </Spinner>
                                                &nbsp;
                                                <Spinner color='success' type='grow' size={"sm"}>
                                                    Loading...
                                                </Spinner>
                                                &nbsp;
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </h5>
                                </div>
                            </CardHeader>
                            <CardBody className='pt-0'>
                                <Card >
                                    <CardHeader className="border-0 fs-5 bg-light">
                                        Map Api Key
                                    </CardHeader>
                                    <CardBody className="bg-light">
                                        <div className="row">
                                            {tableItem.map((com, comIdx) => {
                                                return (
                                                    <div key={'com-' + comIdx} className="d-flex flex-row">
                                                        <div className="col-lg-4 mt-2">
                                                            <span className="fs-6">{com.name}</span>
                                                        </div>
                                                        <div className="col-lg-5 mb-2">
                                                            <Input
                                                                name='apiKey'
                                                                id='apiKey-field'
                                                                className='form-control ms-3'
                                                                placeholder={`${t("Enter")} ${t("Api Key")}`}
                                                                type='text'
                                                                onChange={(e) => {
                                                                    validation.setFieldValue(
                                                                        `tableItem[comIdx].mapApiKey`,
                                                                        e.target.value
                                                                    );
                                                                    tableItem[comIdx].mapApiKey =
                                                                        e.target.value;
                                                                }}
                                                                onBlur={validation.handleBlur}
                                                                value={tableItem[comIdx].mapApiKey || ""}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button className="btn btn-success" onClick={() => {
                                                let data = []
                                                tableItem.forEach((item) => {
                                                    data.push({
                                                        companyId: item.companyId,
                                                        mapApiKey: item.mapApiKey
                                                    })
                                                })
                                                const dataPatch = {
                                                    action: "mapApiKey",
                                                    data: data
                                                }
                                                console.log(dataPatch)
                                                dispatch(onAddNewCompany(dataPatch))
                                            }}>Submit</button>
                                        </div>
                                    </CardBody>
                                </Card>
                                <ToastContainer closeButton={false} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

Company.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(Company));
