import {
  PARAMETER_LOADING,
  GET_PARAMETER,
  GET_PARAMETER_SUCCESS,
  GET_PARAMETER_FAIL,
  ADD_PARAMETER_SUCCESS,
  ADD_PARAMETER_FAIL,
  DELETE_PARAMETER_SUCCESS,
  DELETE_PARAMETER_FAIL,
  UPDATE_PARAMETER_SUCCESS,
  UPDATE_PARAMETER_FAIL,
  PARAMETER_RESET,
} from "./actionType";

const INIT_STATE = {
  parameters: [],
  error: {},
};

const Parameter = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PARAMETER_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PARAMETER:
          return {
            ...state,
            parameters: action.payload.data,
            isParameterCreated: false,
            isParameterSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_PARAMETER_FAIL:
      switch (action.payload.actionType) {
        case GET_PARAMETER_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isParameterCreated: false,
            isParameterSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_PARAMETER: {
      return {
        ...state,
        isParameterCreated: false,
        loading: true,
      };
    }

    case PARAMETER_LOADING: {
      return {
        ...state,
        isParameterCreated: false,
        loading: true,
      };
    }

    case ADD_PARAMETER_SUCCESS:
      return {
        ...state,
        isParameterCreated: true,
        loading: false,
        parameters: [...state.parameters, action.payload.data],
      };

    case ADD_PARAMETER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false,
        parameters: state.parameters.filter(
          (parameter) => parameter.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PARAMETER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false,
        parameters: state.parameters.map((parameter) =>
          parameter.id.toString() === action.payload.data.id.toString()
            ? { ...parameter, ...action.payload.data }
            : parameter
        ),
      };

    case UPDATE_PARAMETER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PARAMETER_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Parameter;
