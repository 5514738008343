import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  ADD_NEW_NOTIFICATION,
} from "./actionType";

import {
  getNotificationSuccess,
  getNotificationFail,
  deleteNotificationSuccess,
  deleteNotificationFail,
  updateNotificationSuccess,
  updateNotificationFail,
  addNotificationSuccess,
  addNotificationFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveNotificationApi,
  getNotificationApi,
  updateNotificationApi,
  deleteNotificationApi,
  addNewNotificationApi,
} from "../../helpers/backend_helper";

function* getNotification({ payload: notification }) {
  try {
    const response = yield call(getNotificationApi, notification);
    yield put(getNotificationSuccess(GET_NOTIFICATION, response.data));
  } catch (error) {
    yield put(getNotificationFail(GET_NOTIFICATION, error));
  }
}

function* onUpdateNotification({ payload: notification }) {
  try {
    if (notification.setApprove) {
      const response = yield call(approveNotificationApi, notification);
      yield put(updateNotificationSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateNotificationApi, notification);
      yield put(updateNotificationSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateNotificationFail(error));
    toast.error(error.response.message, { autoClose: 3000 });
  }
}

function* onDeleteNotification({ payload: notification }) {
  try {
    const response = yield call(deleteNotificationApi, notification);
    yield put(deleteNotificationSuccess({ notification, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteNotificationFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewNotification({ payload: notification }) {
  try {
    const response = yield call(addNewNotificationApi, notification);
    yield put(addNotificationSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addNotificationFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetNotification() {
  yield takeEvery(GET_NOTIFICATION, getNotification);
}

export function* watchUpdateNotification() {
  yield takeEvery(UPDATE_NOTIFICATION, onUpdateNotification);
}

export function* watchDeleteNotification() {
  yield takeEvery(DELETE_NOTIFICATION, onDeleteNotification);
}

export function* watchAddNewNotification() {
  yield takeEvery(ADD_NEW_NOTIFICATION, onAddNewNotification);
}

function* notificationSaga() {
  yield all([
    fork(watchGetNotification),
    fork(watchDeleteNotification),
    fork(watchUpdateNotification),
    fork(watchAddNewNotification),
  ]);
}

export default notificationSaga;
