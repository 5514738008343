import {
  ROLE_LOADING,
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  ROLE_RESET
} from "./actionType";

const INIT_STATE = {
  roles: [],
  error: {},
};

const Role = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ROLE:
          return {
            ...state,
            roles: action.payload.data,
            isRoleCreated: false,
            isRoleSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ROLE_FAIL:
      switch (action.payload.actionType) {
        case GET_ROLE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isRoleCreated: false,
            isRoleSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ROLE: {
      return {
        ...state,
        isRoleCreated: false,
        loading: true,
      };
    }

    case ROLE_LOADING: {
      return {
        ...state,
        isRoleCreated: false,
        loading: true,
      };
    }

    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        isRoleCreated: true,
        loading: false,
        roles: [...state.roles, action.payload.data],
      };

    case ADD_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: state.roles.filter(
          role => role.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: state.roles.map(role =>
          role.id.toString() === action.payload.data.id.toString() ? { ...role, ...action.payload.data } : role
        ),
      };

    case UPDATE_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ROLE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Role;