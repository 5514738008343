import React from "react";
import { Card, CardHeader, CardBody, Spinner } from "reactstrap";
import { SplineAreaChart } from "../../pages/DeviceDetail/AreaCharts";

function StatisticComponent({ t, series, seriesCategories, publicLoading }) {
  return (
    <div>
      <Card>
        <CardHeader className='align-items-center d-flex'>
          {publicLoading ? (
            <>
              <Spinner color='primary' type='grow' size={"sm"}>
                Loading...
              </Spinner>
              &nbsp;
              <Spinner color='success' type='grow' size={"sm"}>
                Loading...
              </Spinner>
              &nbsp;
            </>
          ) : (
            <h4 className='card-title mb-0 flex-grow-1'>{t("Statistic")}</h4>
          )}
        </CardHeader>
        <CardBody>
          <SplineAreaChart
            dataColors='["--vz-primary", "--vz-success"]'
            seriesCategories={seriesCategories}
            series={series}
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default StatisticComponent;
