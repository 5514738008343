import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";
import Account from "./auth/register/reducer";
import User from "./users/reducer";
import Role from "./role/reducer";
import RoleList from "./roleList/reducer";
import Dashboard from "./dashboard/reducer";
import Client from "./client/reducer";
import Service from "./service/reducer";
import Device from "./device/reducer";
import Geofence from "./geofence/reducer";
import DeviceType from "./deviceType/reducer";
import Parameter from "./parameter/reducer";
import Unit from "./unit/reducer";
import Address from "./address/reducer";
import Folder from "./folder/reducer";
import File from "./file/reducer";
import Billing from "./billing/reducer";
import SensorType from "./sensorType/reducer";
import Sensor from "./sensor/reducer";
import History from "./history/reducer";
import Brand from "./brand/reducer";
import ReportTemplate from "./reportTemplate/reducer";
import ReportTemplateDetail from "./reportTemplateDetail/reducer";
import Uom from "./uom/reducer";
import BrandType from "./brandType/reducer";
import StorageFile from "./storageFiles/reducer";
import StorageFolder from "./storageFolder/reducer";
import StorageMove from "./storageMove/reducer";
import StorageRename from "./storageRename/reducer";
import Company from "./company/reducer";
import Branch from "./branch/reducer";
import DashboardTemplate from "./dashboardTemplate/reducer";
import DashboardTemplateDetail from "./dashboardTemplateDetail/reducer";
import Notification from "./notification/reducer";
import Task from "./task/reducer";
import ActivityLog from "./activityLog/reducer";
import DownloadApp from "./downloadApp/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  Dashboard,
  User,
  Role,
  Account,
  Client,
  Service,
  Register,
  Device,
  Geofence,
  DeviceType,
  Unit,
  Address,
  Folder,
  File,
  Parameter,
  Billing,
  SensorType,
  Sensor,
  History,
  Brand,
  ReportTemplate,
  ReportTemplateDetail,
  Uom,
  BrandType,
  StorageFile,
  StorageFolder,
  StorageMove,
  StorageRename,
  Company,
  Branch,
  DashboardTemplate,
  DashboardTemplateDetail,
  Notification,
  Task,
  RoleList,
  ActivityLog,
  DownloadApp
});

export default rootReducer;
