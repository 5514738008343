import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_DASHBOARDTEMPLATE,
  DELETE_DASHBOARDTEMPLATE,
  UPDATE_DASHBOARDTEMPLATE,
  ADD_NEW_DASHBOARDTEMPLATE,
} from "./actionType";

import {
  getDashboardTemplateSuccess,
  getDashboardTemplateFail,
  deleteDashboardTemplateSuccess,
  deleteDashboardTemplateFail,
  updateDashboardTemplateSuccess,
  updateDashboardTemplateFail,
  addDashboardTemplateSuccess,
  addDashboardTemplateFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveDashboardTemplateApi,
  getDashboardTemplateApi,
  updateDashboardTemplateApi,
  deleteDashboardTemplateApi,
  addNewDashboardTemplateApi,
} from "../../helpers/backend_helper";

function* getDashboardTemplate({ payload: dashboardTemplate }) {
  try {
    const response = yield call(getDashboardTemplateApi, dashboardTemplate);
    yield put(
      getDashboardTemplateSuccess(GET_DASHBOARDTEMPLATE, response.data)
    );
  } catch (error) {
    yield put(getDashboardTemplateFail(GET_DASHBOARDTEMPLATE, error));
  }
}

function* onUpdateDashboardTemplate({ payload: dashboardTemplate }) {
  try {
    if (dashboardTemplate.setApprove) {
      const response = yield call(
        approveDashboardTemplateApi,
        dashboardTemplate
      );
      yield put(updateDashboardTemplateSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(
        updateDashboardTemplateApi,
        dashboardTemplate
      );
      yield put(updateDashboardTemplateSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateDashboardTemplateFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteDashboardTemplate({ payload: dashboardTemplate }) {
  try {
    const response = yield call(deleteDashboardTemplateApi, dashboardTemplate);
    yield put(
      deleteDashboardTemplateSuccess({ dashboardTemplate, ...response })
    );
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteDashboardTemplateFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewDashboardTemplate({ payload: dashboardTemplate }) {
  try {
    const response = yield call(addNewDashboardTemplateApi, dashboardTemplate);
    yield put(addDashboardTemplateSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addDashboardTemplateFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetDashboardTemplate() {
  yield takeEvery(GET_DASHBOARDTEMPLATE, getDashboardTemplate);
}

export function* watchUpdateDashboardTemplate() {
  yield takeEvery(UPDATE_DASHBOARDTEMPLATE, onUpdateDashboardTemplate);
}

export function* watchDeleteDashboardTemplate() {
  yield takeEvery(DELETE_DASHBOARDTEMPLATE, onDeleteDashboardTemplate);
}

export function* watchAddNewDashboardTemplate() {
  yield takeEvery(ADD_NEW_DASHBOARDTEMPLATE, onAddNewDashboardTemplate);
}

function* dashboardTemplateSaga() {
  yield all([
    fork(watchGetDashboardTemplate),
    fork(watchDeleteDashboardTemplate),
    fork(watchUpdateDashboardTemplate),
    fork(watchAddNewDashboardTemplate),
  ]);
}

export default dashboardTemplateSaga;
