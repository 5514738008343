import React from "react";

// import socketIO from "./helpers/socket";

//import Scss
import "./assets/scss/themes.scss";
import "./App.css";

//imoprt Route
import Route from "./Routes";
import socketIO from "./helpers/socket";
const io = socketIO.getInstance();
io.setHost("https://socket.atapteknologi.id");

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
