import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_FOLDER,
  DELETE_FOLDER,
  UPDATE_FOLDER,
  ADD_NEW_FOLDER,
} from "./actionType";

import {
  getFolderSuccess,
  getFolderFail,
  deleteFolderSuccess,
  deleteFolderFail,
  updateFolderSuccess,
  updateFolderFail,
  addFolderSuccess,
  addFolderFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getFolderApi,
  updateFolderApi,
  deleteFolderApi,
  addNewFolderApi
} from "../../helpers/backend_helper";

function* getFolder({ payload: folder }) {
  try {
    const response = yield call(getFolderApi, folder);
    yield put(getFolderSuccess(GET_FOLDER, response.data));
  } catch (error) {
    yield put(getFolderFail(GET_FOLDER, error));
  }
}

function* onUpdateFolder({ payload: folder }) {
  try {
    const response = yield call(updateFolderApi, folder);
    yield put(updateFolderSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateFolderFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteFolder({ payload: folder }) {
  try {
    const response = yield call(deleteFolderApi, folder);
    yield put(deleteFolderSuccess({ folder, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteFolderFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewFolder({ payload: folder }) {
  try {
    const response = yield call(addNewFolderApi, folder);
    yield put(addFolderSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addFolderFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetFolder() {
  yield takeEvery(GET_FOLDER, getFolder);
}

export function* watchUpdateFolder() {
  yield takeEvery(UPDATE_FOLDER, onUpdateFolder);
}

export function* watchDeleteFolder() {
  yield takeEvery(DELETE_FOLDER, onDeleteFolder);
}

export function* watchAddNewFolder() {
  yield takeEvery(ADD_NEW_FOLDER, onAddNewFolder);
}

function* folderSaga() {
  yield all([
    fork(watchGetFolder),
    fork(watchDeleteFolder),
    fork(watchUpdateFolder),
    fork(watchAddNewFolder)
  ]);
}

export default folderSaga;
