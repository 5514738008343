module.exports = {
  google: {
    apiKey: "AIzaSyD0auKcsMNas3qbqi-BgF_s3UxwtQNP74Q",
    authDomain: "global-product-338605.firebaseapp.com",
    projectId: "global-product-338605",
    storageBucket: "global-product-338605.appspot.com",
    messagingSenderId: "729313900237",
    appId: "1:729313900237:web:4d67ccaf50a7fbc2b31b84",
    measurementId: "G-M1X4CTVBX8",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "https://api-iot.atapteknologi.id",
    // API_URL: "http://api.pms.atapteknologi.id",
    // API_URL: "http://localhost:8180",
    SSO_URL: "https://sso.atapteknologi.id",
    MEDIA_URL: "https://drive.atapteknologi.id",
    SMS_URL: "https://api-sms.atapteknologi.id",
    ERP_URL: "https://api-erp.atapteknologi.id",
  },
};
