import {
  GET_SERVICE,
  GET_SERVICE_SUCCESS,
  GET_SERVICE_FAIL,
  DELETE_SERVICE,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,
  UPDATE_SERVICE,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,
  ADD_NEW_SERVICE,
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAIL,
  SERVICE_LOADING,
  SERVICE_RESET,
} from "./actionType";

export const resetServiceState = (actionType) => ({
  type: SERVICE_RESET,
  payload: { actionType },
});

export const getServiceSuccess = (actionType, data) => ({
  type: GET_SERVICE_SUCCESS,
  payload: { actionType, data },
});

export const getServiceFail = (actionType, error) => ({
  type: GET_SERVICE_FAIL,
  payload: { actionType, error },
});

export const getService = (data) => ({
  type: GET_SERVICE,
  payload: data,
});

export const getServiceLoading = () => ({
  type: SERVICE_LOADING,
});

export const deleteService = (data) => ({
  type: DELETE_SERVICE,
  payload: data,
});

export const deleteServiceSuccess = (data) => ({
  type: DELETE_SERVICE_SUCCESS,
  payload: data,
});

export const deleteServiceFail = (error) => ({
  type: DELETE_SERVICE_FAIL,
  payload: error,
});

export const updateService = (data) => ({
  type: UPDATE_SERVICE,
  payload: data,
});

export const updateServiceFail = (error) => ({
  type: UPDATE_SERVICE_FAIL,
  payload: error,
});

export const updateServiceSuccess = (data) => ({
  type: UPDATE_SERVICE_SUCCESS,
  payload: data,
});

export const addNewService = (data) => ({
  type: ADD_NEW_SERVICE,
  payload: data,
});

export const addServiceSuccess = (data) => ({
  type: ADD_SERVICE_SUCCESS,
  payload: data,
});

export const addServiceFail = (error) => ({
  type: ADD_SERVICE_FAIL,
  payload: error,
});
