import React, { useState, useEffect } from "react";
import { SplineAreaChart } from "../../pages/Dashboard/AreaCharts";
import { getHistoryPublic } from "../../store/history/action";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

function ChartBar({ data }) {
  const dispatch = useDispatch()
  const {
    historyPublics
  } = useSelector((state) => ({
    historyPublics: state.History.historyPublics,
  }));
  const [series, setSeries] = useState([]);
  const [seriesCategories, setSeriesCategories] = useState([]);
  const [labels, setLabels] = useState([]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  useEffect(() => {
    if (data) {
      let date = new Date();
      let data = []
      data.device_parameter && data.device_parameter.forEach((dev) => {
        data.push({
          label: dev.label,
          value: 0, isShift: false, condition: "", parameter: dev.value
        })
      })
      dispatch(
        getHistoryPublic({
          parameter: JSON.stringify({ parameter: data }),
          uid: data.identifier,
          type: "today",
          aggregate: "$first",
          group: "$hour",
        })
      );
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (data) {
      let dataLabel = [];
      data.dd_parameter &&
        data.dd_parameter.forEach((item) => {
          if (item && item.ddp_parameter) {
            dataLabel.push({
              label: item && item.ddp_parameter && item.ddp_parameter.label,
              value: item && item.ddp_parameter && item.ddp_parameter.value,
            });
            setLabels(dataLabel);
          }
        });
    }
  }, [data]);

  useEffect(() => {
    if (historyPublics.length > 0) {
      historyPublics.sort((a, b) => a.hour - b.hour);

      console.log(historyPublics);

      let arrCat = historyPublics.map(item =>
        `${item.hour.toString().padStart(2, '0')}:00`
      );

      setSeriesCategories(arrCat);
    }
  }, [historyPublics]);

  useEffect(() => {
    if (historyPublics.length > 0 && labels) {
      let data = [];
      labels.forEach((z) => {
        if (z.value !== "timestamp") {
          let obj = {};
          obj.name = z.label && z.label;
          let arr = [];
          let entries;
          historyPublics && historyPublics.forEach((item) => {
            if (item) {
              entries = Object.entries(item);
              entries.forEach((e) => {
                if (e[0] !== null) {
                  if (e[0] === z.value) {
                    if (Number.isInteger(parseInt(e[1]))) {
                      arr.push(numberWithCommas(parseInt(e[1])));
                    } else {
                      arr.push(0)
                    }
                  }
                }
              });
            }
          });
          obj.data = arr;
          data.push(obj);
        }
      });
      setSeries(data);
    }
  }, [historyPublics, labels]);

  return (
    <div className='mb-3'>
      <SplineAreaChart
        dataColors='["--vz-primary", "--vz-success"]'
        seriesCategories={seriesCategories}
        series={series}
        type={data.period}
      />
    </div>
  );
}

export default ChartBar;
