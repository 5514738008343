import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GET_ROLE, DELETE_ROLE, UPDATE_ROLE, ADD_NEW_ROLE } from "./actionType";

import {
  getRoleSuccess,
  getRoleFail,
  deleteRoleSuccess,
  deleteRoleFail,
  updateRoleSuccess,
  updateRoleFail,
  addRoleSuccess,
  addRoleFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveRoleApi,
  getRoleApi,
  updateRoleApi,
  deleteRoleApi,
  addNewRoleApi,
} from "../../helpers/backend_helper";

function* getRole({ payload: role }) {
  try {
    const response = yield call(getRoleApi, role);
    yield put(getRoleSuccess(GET_ROLE, response.data));
  } catch (error) {
    yield put(getRoleFail(GET_ROLE, error));
  }
}

function* onUpdateRole({ payload: role }) {
  try {
    if (role.setApprove) {
      const response = yield call(approveRoleApi, role);
      yield put(updateRoleSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateRoleApi, role);
      yield put(updateRoleSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateRoleFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteRole({ payload: role }) {
  try {
    const response = yield call(deleteRoleApi, role);
    yield put(deleteRoleSuccess({ role, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteRoleFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewRole({ payload: role }) {
  try {
    const response = yield call(addNewRoleApi, role);
    yield put(addRoleSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addRoleFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetRole() {
  yield takeEvery(GET_ROLE, getRole);
}

export function* watchUpdateRole() {
  yield takeEvery(UPDATE_ROLE, onUpdateRole);
}

export function* watchDeleteRole() {
  yield takeEvery(DELETE_ROLE, onDeleteRole);
}

export function* watchAddNewRole() {
  yield takeEvery(ADD_NEW_ROLE, onAddNewRole);
}

function* roleSaga() {
  yield all([
    fork(watchGetRole),
    fork(watchDeleteRole),
    fork(watchUpdateRole),
    fork(watchAddNewRole),
  ]);
}

export default roleSaga;
