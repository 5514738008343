import React from "react";
import { Card, CardBody, ListGroup, ListGroupItem } from "reactstrap";
import moment from "moment";

function ParamsHistoryComponent({ data }) {
  console.log(data, "ParamsHistoryComponent");

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY HH:mm");
  };

  return (
    <Card>
      <CardBody>
        <span>akjsdkjahdshahdaksdhkhah</span>
        <h5 className='text-start'>{data.name}</h5>
        <div className='d-flex justify-content-center mt-2'></div>
        <ListGroup flush numbered className='mt-2'>
          {(data.device_parameter_history || []).map((wParams, idxWParams) => {
            console.log(wParams);
            return (
              <ListGroupItem key={idxWParams}>
                {`${wParams.value} - ${handleValidDate(wParams.createdat)}`}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </CardBody>
    </Card>
  );
}

export default ParamsHistoryComponent;
