import React from 'react';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const PortfolioCharts = ({ dataColors, labelText, labelSize, labelData, dataWidth, series, rpmIdx }) => {
    var donutchartportfolioColors = getChartColorsArray(dataColors);

    var options = {
        labels: labelData ? labelData : ["", "", "", ""],
        chart: {
            type: "donut",
            height: dataWidth ? dataWidth : 260,
        },

        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "70%",
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '18px',
                            offsetY: -5,
                        },
                        value: {
                            show: true,
                            fontSize: '20px',
                            color: '#343a40',
                            fontWeight: 500,
                            offsetY: 5,
                            formatter: function (val) {
                                return "" + val;
                            }
                        }
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return "" + value;
                }
            }
        },
        stroke: {
            lineCap: "round",
            width: 2
        },
        colors: donutchartportfolioColors,
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="donut"
                height={(dataWidth ? dataWidth : 170) + "px"}
                width={(dataWidth ? dataWidth : 170) + "px"}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const PortfolioCharts2 = ({ dataColors, labelText, labelSize, labelData, dataWidth, series }) => {
    var donutchartportfolioColors = getChartColorsArray(dataColors);

    var options = {
        labels: labelData ? labelData : ["", "", "", ""],
        chart: {
            type: "donut",
            height: dataWidth ? dataWidth : 260,
        },

        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "70%",
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '18px',
                            offsetY: -5,
                        },
                        value: {
                            show: true,
                            fontSize: '20px',
                            color: '#343a40',
                            fontWeight: 500,
                            offsetY: 5,
                            formatter: function (val) {
                                return "" + val;
                            }
                        },
                        total: {
                            show: true,
                            fontSize: labelSize ? labelSize : '13px',
                            label: labelText,
                            color: '#9599ad',
                            fontWeight: 500,
                            formatter: function (w) {
                                return "" + w.globals.seriesTotals.reduce(function (a, b) {
                                    return a + b;
                                }, 0);
                            }
                        }
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return "" + value;
                }
            }
        },
        stroke: {
            lineCap: "round",
            width: 2
        },
        colors: donutchartportfolioColors,
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="donut"
                height={(dataWidth ? dataWidth : 170) + "px"}
                width={(dataWidth ? dataWidth : 170) + "px"}
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const MarkerCharts = ({ dataColors, series }) => {
    var areachartSplineColors = getChartColorsArray(dataColors);
    var options = {
        chart: {
            height: 350,
            type: 'area',
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        colors: areachartSplineColors,
        xaxis: {
            type: 'datetime',
            categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            },
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="area"
                height="350"
                className="apex-charts"
            />
        </React.Fragment>)
};

const WidgetsCharts = ({ seriesData, chartsColor }) => {
    const areachartlitecoinColors = [chartsColor];
    var options = {
        chart: {
            width: 130,
            height: 46,
            type: "area",
            sparkline: {
                enabled: true,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 1.5,
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [50, 100, 100, 100],
            },
        },
        colors: areachartlitecoinColors
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={[...seriesData]}
                type="area"
                height="46"
                className="apex-charts"
            />
        </React.Fragment>
    );
};


export { PortfolioCharts, MarkerCharts, WidgetsCharts, PortfolioCharts2 };