import React, { useState, useEffect, useCallback, Fragment } from "react";
import {
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Row,
  Col,
  Label,
  Spinner,
  Input,
  FormFeedback,
} from "reactstrap";
import defaultImg from "../../assets/images/upload.png";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, clone } from "lodash";
import { getClient, resetClientState } from "../../store/client/action";
import { getCompany, resetCompanyState } from "../../store/company/action";
import {
  getParameter,
  resetParameterState,
} from "../../store/parameter/action";
import { getDevice, resetDeviceState } from "../../store/device/action";
import { api } from "../../config";
import { Link } from "react-router-dom";
import { SketchPicker } from "react-color";
import ModalInputFile from "../ModalInputFile";
import {
  addNewDashboardTemplateDetail as onAddNewDashboardTemplateDetail,
  updateDashboardTemplateDetail as onUpdateDashboardTemplateDetail,
  getDashboardTemplateDetailLoading,
} from "../../store/dashboardTemplateDetail/action";

const ModalInputTemplate = ({
  modal,
  toggle,
  isEdit,
  userType,
  clientId,
  companyId,
  clientName,
  companyName,
  type,
  dashboardTemplateDetail,
  t,
}) => {
  const dispatch = useDispatch();
  const [asTotalCheck, setAsTotalCheck] = useState(false);
  const [color, setColor] = useState("#fff");
  const [companyList, setCompanyList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [paramsList, setParamsList] = useState([]);
  const [mediaType, setMediaType] = useState("");
  const [modalMedia, setModalMedia] = useState(false);

  const valueMapList = [
    {
      options: [
        { label: "Roadmap", value: "roadmap" },
        { label: "Satellite", value: "satellite" },
        { label: "Hybrid", value: "hybrid" },
        { label: "Terrain", value: "terrain" },
      ],
    },
  ];

  const valueChartList = [
    {
      options: [
        { label: "Donut", value: "donut" },
        { label: "Line", value: "line" },
      ],
    },
  ];

  const valueWidgetList = [
    {
      options: [
        { label: "List", value: "list" },
        { label: "Single", value: "single" },
        { label: "Tank", value: "tank" },
        { label: "Gauge", value: "gauge" },
      ],
    },
  ];

  const valueBarList = [
    {
      options: [
        { label: "List", value: "list" },
        { label: "Single", value: "single" },
      ],
    },
  ];

  const periodChartList = [
    {
      options: [
        { label: "Daily", value: "daily" },
        { label: "Weekly", value: "weekly" },
        { label: "Monthly", value: "monthly" },
        {
          label: "Current daily vs Last daily",
          value: "current daily vs Last daily",
        },
        {
          label: "Current weekly vs Last weekly",
          value: "current weekly vs Last weekly",
        },
        {
          label: "Current monthly vs Last monthly",
          value: "current monthly vs Last monthly",
        },
      ],
    },
  ];

  const {
    dashboardTemplateDetails,
    loading,
    parameters,
    clients,
    companies,
    companiesLoading,
    devices,
    devicesLoading,
    dashboards,
  } = useSelector((state) => ({
    dashboardTemplateDetails:
      state.DashboardTemplateDetail.dashboardTemplateDetails,
    loading: state.DashboardTemplateDetail.loading,
    parameters: state.Parameter.parameters,
    clients: state.Client.clients,
    companies: state.Company.companies,
    companiesLoading: state.Company.loading,
    devices: state.Device.devices,
    devicesLoading: state.Device.loading,
    dashboards: state.Dashboard.dashboards,
  }));
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (dashboardTemplateDetail && dashboardTemplateDetail.name) || "",
      type: (dashboardTemplateDetail && dashboardTemplateDetail.type) || "",
      image: (dashboardTemplateDetail && dashboardTemplateDetail.image) || "",
      value: (dashboardTemplateDetail && dashboardTemplateDetail.value) || "",
      description:
        (dashboardTemplateDetail && dashboardTemplateDetail.description) || "",
      min: dashboardTemplateDetail && dashboardTemplateDetail.min,
      max: dashboardTemplateDetail && dashboardTemplateDetail.max,
      asTotal:
        (dashboardTemplateDetail && dashboardTemplateDetail.asTotal) || "",
      bgColor:
        (dashboardTemplateDetail && dashboardTemplateDetail.bgColor) || false,
      sort_order:
        (dashboardTemplateDetail && dashboardTemplateDetail.sort_order) || "",
      column: dashboardTemplateDetail && dashboardTemplateDetail.column,
      device: (dashboardTemplateDetail && dashboardTemplateDetail.device) || "",
      params: (dashboardTemplateDetail && dashboardTemplateDetail.params) || "",
      client:
        userType !== "admin"
          ? { label: clientName, value: clientId }
          : dashboardTemplateDetail && dashboardTemplateDetail.client,
      period: (dashboardTemplateDetail && dashboardTemplateDetail.period) || "",
      company:
        userType !== "admin"
          ? { label: companyName, value: companyId }
          : (dashboardTemplateDetail && dashboardTemplateDetail.company) || "",
      dashboardId:
        (dashboardTemplateDetail && dashboardTemplateDetail.dashboardId) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
    }),
    onSubmit: (values) => {
      dispatch(getDashboardTemplateDetailLoading());
      if (isEdit) {
        let dataParams = [];
        if (validation.values.params) {
          validation.values.params.forEach((item) => {
            dataParams.push(item.value);
          });
        }
        const editDashboardTemplateDetail = {
          id: dashboardTemplateDetail ? dashboardTemplateDetail.id : 0,
          name: values.name,
          type: type || values.type.value,
          image: values.image,
          value: values.value.value,
          description: values.description,
          min: values.min,
          max: values.max,
          asTotal: asTotalCheck,
          bgColor: color,
          sort_order: values.sort_order,
          column: values.column,
          deviceId: values.device.value,
          params: dataParams,
          clientId: values.client.value,
          companyId: values.company.value,
          dashboardId: dashboards[0].id,
          period: values.period.value,
        };
        dispatch(onUpdateDashboardTemplateDetail(editDashboardTemplateDetail));
        validation.resetForm();
      } else {
        let dataParams = [];
        if (validation.values.params) {
          validation.values.params.forEach((item) => {
            dataParams.push(item.value);
          });
        }
        const newDashboardTemplateDetail = {
          name: values.name,
          type: type || values.type.value,
          image: values.image,
          value: values.value.value,
          description: values.description,
          min: values.min,
          max: values.max,
          asTotal: asTotalCheck,
          bgColor: color,
          sort_order: values.sort_order,
          column: values.column,
          deviceId: values.device.value,
          params: dataParams,
          clientId: values.client.value,
          companyId: values.company.value,
          dashboardId: dashboards[0].id,
          period: values.period.value,
        };
        dispatch(onAddNewDashboardTemplateDetail(newDashboardTemplateDetail));
        validation.resetForm();
      }
      toggle();
    },
  });
  const handleAsTotal = () => {
    setAsTotalCheck(!asTotalCheck);
  };
  const handleColorChangeComplete = (color) => {
    setColor(color.hex);
  };
  useEffect(() => {
    if (!isEmpty(companies)) {
      var data = [];
      var opt = [];
      companies.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setCompanyList(data);
    }
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(devices)) {
      var data = [];
      var opt = [];
      devices.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = {
          label: name,
          value: id,
        };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setDeviceList(data);
    }
  }, [devices]);

  useEffect(() => {
    if (parameters) {
      let dataLabel = [];
      for (const data of parameters) {
        dataLabel.push({ label: data.label, value: data.id });
      }
      setParamsList(dataLabel);
    }
  }, [parameters]);

  useEffect(() => {
    if (modal) {
      dispatch(resetClientState());
      dispatch(getClient({ status: 2 }));
      if (userType !== "admin") {
        dispatch(resetDeviceState());
        dispatch(getDevice({ status: 2 }));
      }
      if (userType !== "admin") {
        dispatch(resetCompanyState());
        dispatch(getCompany({ status: 2 }));
      }
    }
  }, [dispatch, dashboardTemplateDetails, userType, modal]);

  const handleCompany = (data) => {
    dispatch(resetCompanyState());
    dispatch(getCompany({ clientId: data.value }));
  };

  const handleParams = (data) => {
    dispatch(resetParameterState());
    dispatch(getParameter({ device: data.value, status: 2 }));
  };

  const handleDevice = (data) => {
    dispatch(resetDeviceState());
    dispatch(getDevice({ companyId: data.value }));
  };

  const handleSelectFile = (file) => {
    switch (mediaType) {
      case "image":
        validation.setFieldValue("image", file.filename);
        break;
      default:
        // setProductFiles((val) => [...val, { filename: file.filename }]);
        break;
    }

    toggleMedia();
  };
  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  return (
    <>
      <Modal
        id='showModal'
        isOpen={modal}
        centered
        fullscreen={false}
        size='xl'>
        <ModalHeader className='bg-light p-3' toggle={toggle}>
          {!!isEdit
            ? `${t("Edit")}  ${t("Dashboard Detail")}`
            : `${t("Add")}  ${t("Dashboard Detail")}`}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}>
          <ModalBody>
            <Row>
              <Col lg={4}>
                {!isEdit ? (
                  <>
                    <div className='mb-3'>
                      <Label htmlFor='password-field' className='form-label'>
                        {t("Client")}
                      </Label>
                      <Select
                        name='client'
                        value={validation.values.client}
                        placeholder={`${t("Select")} ${t("Client")}`}
                        isDisabled={userType !== "admin" ? true : false}
                        onChange={(e) => {
                          setCompanyList([]);
                          validation.setFieldValue("client", e);
                          handleCompany(e);
                        }}
                        options={clientList}></Select>
                    </div>
                    <div className='mb-3'>
                      <Label htmlFor='password-field' className='form-label'>
                        {t("Company")}
                      </Label>
                      {companiesLoading ? (
                        <>
                          <Spinner
                            color='primary'
                            type='grow'
                            size={"sm"}
                            className='ms-2'></Spinner>
                          &nbsp;
                          <Spinner color='success' type='grow' size={"sm"}>
                            Loading...
                          </Spinner>
                          &nbsp;
                        </>
                      ) : (
                        ""
                      )}
                      <Select
                        name='company'
                        value={validation.values.company}
                        placeholder={`${t("Select")} ${t("Company")}`}
                        isDisabled={userType !== "admin" ? true : false}
                        onChange={(e) => {
                          // setDeviceList([]);
                          validation.setFieldValue("company", e);
                          handleDevice(e);
                        }}
                        // isDisabled={companyList.length > 0 ? false : true}
                        options={companyList}></Select>
                    </div>
                  </>
                ) : null}
                <div className='mb-3'>
                  <Label htmlFor='password-field' className='form-label'>
                    {t("Device")}
                  </Label>
                  {devicesLoading ? (
                    <>
                      <Spinner
                        color='primary'
                        type='grow'
                        size={"sm"}
                        className='ms-2'></Spinner>
                      &nbsp;
                      <Spinner color='success' type='grow' size={"sm"}>
                        Loading...
                      </Spinner>
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}
                  <Select
                    name='device'
                    value={validation.values.device}
                    placeholder={`${t("Select")} ${t("Device")}`}
                    onChange={(e) => {
                      setParamsList([]);
                      validation.setFieldValue("device", e);
                      handleParams(e);
                    }}
                    isDisabled={deviceList.length > 0 ? false : true}
                    options={deviceList}></Select>
                </div>
                <div className='mb-3'>
                  <Label htmlFor='params-field' className='form-label'>
                    Params
                  </Label>
                  <Select
                    name='params'
                    value={validation.values.params}
                    placeholder={`${t("Select")} ${t("Params")}`}
                    isMulti
                    isDisabled={paramsList.length > 0 ? false : true}
                    onChange={(opt, { option }) => {
                      let newOpts = opt;
                      if (option && option.value === "all") {
                        let filteredOptions = clone(paramsList);
                        filteredOptions = filteredOptions[0].options.filter(
                          (filteredOption) => !newOpts.includes(filteredOption)
                        );
                        newOpts = newOpts
                          .concat(filteredOptions)
                          .filter((newOpt) => newOpt.value !== "all");
                      }
                      validation.setFieldValue("params", newOpts);
                    }}
                    options={paramsList}></Select>
                </div>
                <div className='mb-1'>
                  <Input
                    name='asTotal'
                    id='asTotal-field'
                    className='form-check-input'
                    type='checkbox'
                    onChange={handleAsTotal}
                    onBlur={validation.handleBlur}
                    checked={asTotalCheck}
                  />{" "}
                  &nbsp;
                  <Label htmlFor='asTotal-field' className='form-label'>
                    {" "}
                    {t("As Total")}
                  </Label>
                </div>
              </Col>
              <Col lg={4}>
                <div className='mb-3'>
                  <Label htmlFor='name-field' className='form-label'>
                    {t("Name")}
                  </Label>
                  <Input
                    name='name'
                    id='name-field'
                    className='form-control'
                    placeholder={`${t("Enter")} ${t("Name")}`}
                    type='text'
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
                {type === "map" ? (
                  <Fragment>
                    <div className='mb-3'>
                      <Label htmlFor='active-status' className='form-label'>
                        {t("Value")}
                      </Label>
                      <Select
                        name='value'
                        value={validation.values.value}
                        validate={{ required: { value: true } }}
                        placeholder={`${t("Select")} ${t("Value")}`}
                        onChange={(e) => {
                          validation.setFieldValue("value", e);
                        }}
                        options={valueMapList}
                        invalid={
                          validation.touched.value && validation.errors.value
                            ? true
                            : false
                        }></Select>
                      {validation.touched.value && validation.errors.value ? (
                        <p className='text-danger' type='invalid'>
                          {validation.errors.value}
                        </p>
                      ) : null}
                    </div>
                  </Fragment>
                ) : type === "chart" ? (
                  <>
                    <div className='mb-3'>
                      <Label htmlFor='active-status' className='form-label'>
                        {t("Value")}
                      </Label>
                      <Select
                        name='value'
                        value={validation.values.value}
                        validate={{ required: { value: true } }}
                        placeholder={`${t("Select")} ${t("Params")}`}
                        onChange={(e) => {
                          validation.setFieldValue("value", e);
                        }}
                        options={valueChartList}
                        invalid={
                          validation.touched.value && validation.errors.value
                            ? true
                            : false
                        }></Select>
                      {validation.touched.value && validation.errors.value ? (
                        <p className='text-danger' type='invalid'>
                          {validation.errors.value}
                        </p>
                      ) : null}
                    </div>
                    {validation.values.value.value === "line" ? (
                      <div className='mb-3'>
                        <Label htmlFor='active-status' className='form-label'>
                          {t("Period")}
                        </Label>
                        <Select
                          name='period'
                          value={validation.values.period}
                          validate={{ required: { period: true } }}
                          placeholder={`${t("Select")} ${t("Period")}`}
                          onChange={(e) => {
                            validation.setFieldValue("period", e);
                          }}
                          options={periodChartList}
                          invalid={
                            validation.touched.period &&
                              validation.errors.period
                              ? true
                              : false
                          }></Select>
                        {validation.touched.period &&
                          validation.errors.period ? (
                          <p className='text-danger' type='invalid'>
                            {validation.errors.period}
                          </p>
                        ) : null}
                      </div>
                    ) : null}
                  </>
                ) : type === "widget" ? (
                  <div className='mb-3'>
                    <Label htmlFor='active-status' className='form-label'>
                      {t("Value")}
                    </Label>
                    <Select
                      name='value'
                      value={validation.values.value}
                      validate={{ required: { value: true } }}
                      placeholder={`${t("Select")} ${t("Value")}`}
                      onChange={(e) => {
                        validation.setFieldValue("value", e);
                      }}
                      options={valueWidgetList}
                      invalid={
                        validation.touched.value && validation.errors.value
                          ? true
                          : false
                      }></Select>
                    {validation.touched.value && validation.errors.value ? (
                      <p className='text-danger' type='invalid'>
                        {validation.errors.value}
                      </p>
                    ) : null}
                  </div>
                ) : type === "line" ? (
                  <div className='mb-3'>
                    <Label htmlFor='active-status' className='form-label'>
                      {t("Value")}
                    </Label>
                    <Select
                      name='value'
                      value={validation.values.value}
                      validate={{ required: { value: true } }}
                      placeholder={`${t("Select")} ${t("Value")}`}
                      onChange={(e) => {
                        validation.setFieldValue("value", e);
                      }}
                      options={valueBarList}
                      invalid={
                        validation.touched.value && validation.errors.value
                          ? true
                          : false
                      }></Select>
                    {validation.touched.value && validation.errors.value ? (
                      <p className='text-danger' type='invalid'>
                        {validation.errors.value}
                      </p>
                    ) : null}
                  </div>
                ) : null}
                {validation.values.value.value === "tank" ||
                  validation.values.value.value === "gauge" ? (
                  <>
                    <div className='mb-3'>
                      <Label htmlFor='min-field' className='form-label'>
                        Min
                      </Label>
                      <Input
                        name='min'
                        id='min-field'
                        className='form-control'
                        placeholder={`${t("Enter")} ${t("Min")}`}
                        type='number'
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.min || ""}
                        invalid={
                          validation.touched.min && validation.errors.min
                            ? true
                            : false
                        }
                      />
                      {validation.touched.min && validation.errors.min ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.min}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className='mb-3'>
                      <Label htmlFor='max-field' className='form-label'>
                        Max
                      </Label>
                      <Input
                        name='max'
                        id='max-field'
                        className='form-control'
                        placeholder={`${t("Enter")} ${t("Max")}`}
                        type='number'
                        validate={{ required: { value: true } }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.max || ""}
                        invalid={
                          validation.touched.max && validation.errors.max
                            ? true
                            : false
                        }
                      />
                      {validation.touched.max && validation.errors.max ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.max}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </>
                ) : null}
                <div className='mb-3'>
                  <Label htmlFor='sort_order-field' className='form-label'>
                    {t("Sort Order")}
                  </Label>
                  <Input
                    name='sort_order'
                    id='sort_order-field'
                    className='form-control'
                    placeholder={`${t("Enter")} ${t("Sort Order")}`}
                    type='number'
                    validate={{ required: { value: true } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.sort_order || ""}
                    invalid={
                      validation.touched.sort_order &&
                        validation.errors.sort_order
                        ? true
                        : false
                    }
                  />
                  {validation.touched.sort_order &&
                    validation.errors.sort_order ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.sort_order}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className='mb-3'>
                  <Label htmlFor='des-field' className='form-label'>
                    {t("Description")}
                  </Label>
                  <Input
                    name='description'
                    id='des-field'
                    className='form-control'
                    rows={4}
                    placeholder={`${t("Enter")} ${t("Description")}`}
                    type='textarea'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className='mb-3'>
                  <Label htmlFor='education' className='form-label'>
                    {t("Image")}
                  </Label>
                  <button
                    type='button'
                    className='btn btn-sm btn-label btn-soft-success'
                    onClick={() => {
                      setMediaType("image");
                      toggleMedia();
                    }}>
                    <i className='ri-file-cloud-fill label-icon align-middle fs-16 me-1'></i>{" "}
                    Media
                  </button>
                </div>
                {validation.values.image !== "" ? (
                  <div className='border-bottom mb-3'>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                      <div className='me-2'>
                        <img
                          src={
                            !validation.values.image
                              ? defaultImg
                              : `${api.MEDIA_URL}/media/${validation.values.image}-w-50`
                          }
                          alt=''
                          data-dz-thumbnail=''
                          height='30'
                          className='avatar-sm rounded bg-light'
                        />
                      </div>

                      <div className='text-truncate'>
                        {validation.values.image}
                      </div>
                      <div>
                        <Link
                          to='#'
                          className={
                            "btn-icon btn btn-sm btn-soft-danger remove-item-btn mx-2"
                          }
                          onClick={(e) => {
                            validation.setFieldValue("image", "");
                          }}>
                          <i className=' ri-delete-bin-line fs-15'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className='mb-3'>
                  <Label htmlFor='name-field' className='form-label'>
                    {t("Color")}
                  </Label>
                  <SketchPicker
                    color={color}
                    onChangeComplete={(e) => {
                      handleColorChangeComplete(e);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <div className='modal-footer mb3'>
            <div className='hstack gap-2 justify-content-end'>
              <button
                type='button'
                className='btn btn-light'
                onClick={() => {
                  validation.resetForm();
                  setAsTotalCheck(false);
                  toggle();
                }}>
                {t("Cancel")}
              </button>
              <button type='submit' className='btn btn-success'>
                <span className='d-flex align-items-center'>
                  <span className='flex-grow-1 me-2'>
                    {!!isEdit
                      ? `${t("Update")}  ${t("Dashboard Detail")}`
                      : `${t("Add")}  ${t("Dashboard Detail")}`}
                  </span>
                  {loading ? (
                    <Spinner size='sm' className='flex-shrink-0' role='status'>
                      Loading...
                    </Spinner>
                  ) : (
                    ""
                  )}
                </span>
              </button>
            </div>
          </div>
        </Form>
      </Modal>
      <ModalInputFile
        onSelect={handleSelectFile}
        loading={loading}
        modal={modalMedia}
        toggle={toggleMedia}
        userType={userType}
        clientId={clientId}
        companyId={companyId}
        clientName={clientName}
        companyName={companyName}
        type={"image"}
        t={t}
      />
    </>
  );
};

export default ModalInputTemplate;
