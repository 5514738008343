import {
  GET_UOM,
  GET_UOM_SUCCESS,
  GET_UOM_FAIL,
  DELETE_UOM,
  DELETE_UOM_SUCCESS,
  DELETE_UOM_FAIL,
  UPDATE_UOM,
  UPDATE_UOM_SUCCESS,
  UPDATE_UOM_FAIL,
  ADD_NEW_UOM,
  ADD_UOM_SUCCESS,
  ADD_UOM_FAIL,
  UOM_LOADING,
} from "./actionType";

export const getUomSuccess = (actionType, data) => ({
  type: GET_UOM_SUCCESS,
  payload: { actionType, data },
});

export const getUomFail = (actionType, error) => ({
  type: GET_UOM_FAIL,
  payload: { actionType, error },
});

export const getUom = data => ({
  type: GET_UOM,
  payload: data,
});

export const getUomLoading = () => ({
  type: UOM_LOADING
});

export const deleteUom = data => ({
  type: DELETE_UOM,
  payload: data,
});

export const deleteUomSuccess = data => ({
  type: DELETE_UOM_SUCCESS,
  payload: data,
});

export const deleteUomFail = error => ({
  type: DELETE_UOM_FAIL,
  payload: error,
});

export const updateUom = data => ({
  type: UPDATE_UOM,
  payload: data,
});

export const updateUomFail = error => ({
  type: UPDATE_UOM_FAIL,
  payload: error,
});

export const updateUomSuccess = data => ({
  type: UPDATE_UOM_SUCCESS,
  payload: data,
});

export const addNewUom = data => ({
  type: ADD_NEW_UOM,
  payload: data,
});

export const addUomSuccess = data => ({
  type: ADD_UOM_SUCCESS,
  payload: data,
});

export const addUomFail = error => ({
  type: ADD_UOM_FAIL,
  payload: error,
});