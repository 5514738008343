import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

//Import Components
import VerticalLayout from "./VerticalLayouts";
import TwoColumnLayout from "./TwoColumnLayout";
import { Container } from "reactstrap";
import HorizontalLayout from "./HorizontalLayout";

const Sidebar = ({ layoutType }) => {
  const [authUser, setAuthUser] = useState(null);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
    ) {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    } else if (
      document.documentElement.getAttribute("data-sidebar-size") ===
      "sm-hover-active"
    ) {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    }
  };

  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      setAuthUser(obj);
    } else {
      window.location = "/logout";
    }
  }, []);

  // useEffect(()=>{
  //   if (authUser) {
  //     const lg =  authUser.data.user_client.client_image ? process.env.REACT_APP_API_URL +
  //     "/media/"+ authUser.data.user_client.client_image?.file_folder.name + "/" +
  //     authUser.data.user_client.client_image?.filename : logoSm
  //     setLogo(lg)
  //   }
  // }, [authUser])

  return (
    <React.Fragment>
      <div className='app-menu navbar-menu'>
        <div className='navbar-brand-box pt-3 pb-1'>
          <Link to='/' className='logo logo-dark'>
            {authUser && authUser.data.user_client ? (
              <span className='d-none'>
                <img src={logoSm} alt='' height='33' />
              </span>
            ) : (
              <span className='logo-sm'>
                <img src={logoDark} alt='' height='33' />
              </span>
            )}
            <span className='logo-lg'>
              <img
                src={logoSm}
                alt=''
                height='100'
                className='ms-0'
                style={{ maxWidth: "185px" }}
              />
            </span>
          </Link>

          <Link to='/' className='logo logo-light'>
            {authUser && authUser.data.user_client ? (
              <span className='d-none'>
                <img src={logoDark} alt='' height='33' />
              </span>
            ) : (
              <span className='logo-sm'>
                <img src={logoSm} alt='' height='33' />
              </span>
            )}
            <span className='logo-lg'>
              <img
                src={logoDark}
                alt=''
                height='40'
                className='ms-0'
                style={{ maxWidth: "185px" }}
              />
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type='button'
            className='btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover'
            id='vertical-hover'>
            <i className='ri-record-circle-line'></i>
          </button>
        </div>
        {layoutType === "horizontal" ? (
          <div id='scrollbar'>
            <Container fluid>
              <div id='two-column-menu'></div>
              <ul className='navbar-nav' id='navbar-nav'>
                <HorizontalLayout />
              </ul>
            </Container>
          </div>
        ) : layoutType === "twocolumn" ? (
          <React.Fragment>
            <TwoColumnLayout layoutType={layoutType} />
            <div className='sidebar-background'></div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SimpleBar id='scrollbar' className='h-100'>
              <Container fluid>
                <div id='two-column-menu'></div>
                <ul className='navbar-nav mt-3' id='navbar-nav'>
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
            <div className='sidebar-background'></div>
          </React.Fragment>
        )}
      </div>
      <div className='vertical-overlay'></div>
    </React.Fragment>
  );
};

export default Sidebar;
