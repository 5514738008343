import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_DASHBOARDTEMPLATEDETAIL,
  DELETE_DASHBOARDTEMPLATEDETAIL,
  UPDATE_DASHBOARDTEMPLATEDETAIL,
  ADD_NEW_DASHBOARDTEMPLATEDETAIL,
} from "./actionType";

import {
  getDashboardTemplateDetailSuccess,
  getDashboardTemplateDetailFail,
  deleteDashboardTemplateDetailSuccess,
  deleteDashboardTemplateDetailFail,
  updateDashboardTemplateDetailSuccess,
  updateDashboardTemplateDetailFail,
  addDashboardTemplateDetailSuccess,
  addDashboardTemplateDetailFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveDashboardTemplateDetailApi,
  getDashboardTemplateDetailApi,
  updateDashboardTemplateDetailApi,
  deleteDashboardTemplateDetailApi,
  addNewDashboardTemplateDetailApi,
} from "../../helpers/backend_helper";

function* getDashboardTemplateDetail({ payload: dashboardTemplateDetail }) {
  try {
    const response = yield call(
      getDashboardTemplateDetailApi,
      dashboardTemplateDetail
    );
    yield put(
      getDashboardTemplateDetailSuccess(
        GET_DASHBOARDTEMPLATEDETAIL,
        response.data
      )
    );
  } catch (error) {
    yield put(
      getDashboardTemplateDetailFail(GET_DASHBOARDTEMPLATEDETAIL, error)
    );
  }
}

function* onUpdateDashboardTemplateDetail({
  payload: dashboardTemplateDetail,
}) {
  try {
    if (dashboardTemplateDetail.setApprove) {
      const response = yield call(
        approveDashboardTemplateDetailApi,
        dashboardTemplateDetail
      );
      yield put(updateDashboardTemplateDetailSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(
        updateDashboardTemplateDetailApi,
        dashboardTemplateDetail
      );
      yield put(updateDashboardTemplateDetailSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateDashboardTemplateDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteDashboardTemplateDetail({
  payload: dashboardTemplateDetail,
}) {
  try {
    const response = yield call(
      deleteDashboardTemplateDetailApi,
      dashboardTemplateDetail
    );
    yield put(
      deleteDashboardTemplateDetailSuccess({
        dashboardTemplateDetail,
        ...response,
      })
    );
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteDashboardTemplateDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewDashboardTemplateDetail({
  payload: dashboardTemplateDetail,
}) {
  try {
    const response = yield call(
      addNewDashboardTemplateDetailApi,
      dashboardTemplateDetail
    );
    yield put(addDashboardTemplateDetailSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addDashboardTemplateDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetDashboardTemplateDetail() {
  yield takeEvery(GET_DASHBOARDTEMPLATEDETAIL, getDashboardTemplateDetail);
}

export function* watchUpdateDashboardTemplateDetail() {
  yield takeEvery(
    UPDATE_DASHBOARDTEMPLATEDETAIL,
    onUpdateDashboardTemplateDetail
  );
}

export function* watchDeleteDashboardTemplateDetail() {
  yield takeEvery(
    DELETE_DASHBOARDTEMPLATEDETAIL,
    onDeleteDashboardTemplateDetail
  );
}

export function* watchAddNewDashboardTemplateDetail() {
  yield takeEvery(
    ADD_NEW_DASHBOARDTEMPLATEDETAIL,
    onAddNewDashboardTemplateDetail
  );
}

function* dashboardTemplateDetailSaga() {
  yield all([
    fork(watchGetDashboardTemplateDetail),
    fork(watchDeleteDashboardTemplateDetail),
    fork(watchUpdateDashboardTemplateDetail),
    fork(watchAddNewDashboardTemplateDetail),
  ]);
}

export default dashboardTemplateDetailSaga;
