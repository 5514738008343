import React from "react";
import { SplineAreaChart2 } from "../../pages/DeviceDetail/AreaCharts";
import { SimpleDonut, SimplePie } from "../../pages/Dashboard/PieCharts";

function ChartReport({ templateSelect, series, seriesCategories }) {
  console.log(templateSelect, 'chartTemp');
  console.log(series, 'chartTempSeries');
  console.log(seriesCategories, 'chartTempSeriesCat');
  return (
    <div>
      {templateSelect.type === "line" ? (
        <SplineAreaChart2
          dataColors='["--vz-primary", "--vz-success"]'
          seriesCategories={seriesCategories}
          series={series}
        />
      ) : templateSelect.type === 'donut' ? (
        <SimpleDonut dataColors='["--vz-primary", "--vz-success"]' seriesCategories={seriesCategories}
          series={series} title={templateSelect.detail[0].label} />
      ) : templateSelect.type === "pie" ? (
        <SimplePie dataColors='["--vz-primary", "--vz-success"]' seriesCategories={seriesCategories}
          series={series} title={templateSelect.detail[0].label} />
      ) : null}
    </div>
  );
}

export default ChartReport;
