import {
  GET_DEVICE,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAIL,
  DELETE_DEVICE,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAIL,
  UPDATE_DEVICE,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAIL,
  ADD_NEW_DEVICE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAIL,
  DEVICE_LOADING,
  DEVICE_RESET,
  GET_DEVICE_ONE,
} from "./actionType";

export const resetDeviceState = (actionType) => ({
  type: DEVICE_RESET,
  payload: { actionType },
});

export const getDeviceSuccess = (actionType, data) => ({
  type: GET_DEVICE_SUCCESS,
  payload: { actionType, data },
});

export const getDeviceFail = (actionType, error) => ({
  type: GET_DEVICE_FAIL,
  payload: { actionType, error },
});

export const getDevice = (data) => ({
  type: GET_DEVICE,
  payload: data,
});

export const getDeviceLoading = () => ({
  type: DEVICE_LOADING,
});

export const deleteDevice = (data) => ({
  type: DELETE_DEVICE,
  payload: data,
});

export const deleteDeviceSuccess = (data) => ({
  type: DELETE_DEVICE_SUCCESS,
  payload: data,
});

export const deleteDeviceFail = (error) => ({
  type: DELETE_DEVICE_FAIL,
  payload: error,
});

export const updateDevice = (data) => ({
  type: UPDATE_DEVICE,
  payload: data,
});

export const updateDeviceFail = (error) => ({
  type: UPDATE_DEVICE_FAIL,
  payload: error,
});

export const updateDeviceSuccess = (data) => ({
  type: UPDATE_DEVICE_SUCCESS,
  payload: data,
});

export const addNewDevice = (data) => ({
  type: ADD_NEW_DEVICE,
  payload: data,
});

export const addDeviceSuccess = (data) => ({
  type: ADD_DEVICE_SUCCESS,
  payload: data,
});

export const addDeviceFail = (error) => ({
  type: ADD_DEVICE_FAIL,
  payload: error,
});

export const getDeviceOne = (data) => ({
  type: GET_DEVICE_ONE,
  payload: data,
});
