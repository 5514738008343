import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AuthSaga from "./auth/login/saga";
import RegisterSaga from "./auth/register/saga";
import DashboardSaga from "./dashboard/saga";
import UserSaga from "./users/saga";
import RoleSaga from "./role/saga";
import ClientSaga from "./client/saga";
import ServiceSaga from "./service/saga";
import DeviceSaga from "./device/saga";
import GeofenceSaga from "./geofence/saga";
import DeviceTypeSaga from "./deviceType/saga";
import UnitSaga from "./unit/saga";
import AddressSaga from "./address/saga";
import FolderSaga from "./folder/saga";
import FileSaga from "./file/saga";
import ParameterSaga from "./parameter/saga";
import BillingSaga from "./billing/saga";
import sensorTypeSaga from "./sensorType/saga";
import SensorSaga from "./sensor/saga";
import HistorySaga from "./history/saga";
import BrandSaga from "./brand/saga";
import ReportTemplateSaga from "./reportTemplate/saga";
import ReportTemplateDetailSaga from "./reportTemplateDetail/saga";
import UomSaga from "./uom/saga";
import BrandTypeSaga from "./brandType/saga";
import StorageFileSaga from "./storageFiles/saga";
import StorageFolderSaga from "./storageFolder/saga";
import StorageMoveSaga from "./storageMove/saga";
import StorageRenameSaga from "./storageRename/saga";
import CompanySaga from "./company/saga";
import BranchSaga from "./branch/saga";
import DashboardTemplateSaga from "./dashboardTemplate/saga";
import DashboardTemplateDetailSaga from "./dashboardTemplateDetail/saga";
import NotificationSaga from "./notification/saga";
import TaskSaga from "./task/saga";
import RoleListSaga from "./roleList/saga";
import ActivityLogSaga from "./activityLog/saga";
import DownloadAppSaga from "./downloadApp/saga";

export default function* rootSaga() {
  yield all([fork(AuthSaga), fork(RegisterSaga), fork(DownloadAppSaga), fork(UserSaga), fork(LayoutSaga), fork(DashboardSaga), fork(RoleSaga), fork(ClientSaga), fork(ServiceSaga), fork(DeviceSaga), fork(GeofenceSaga), fork(DeviceTypeSaga), fork(UnitSaga), fork(AddressSaga), fork(FolderSaga), fork(FileSaga), fork(ParameterSaga), fork(BillingSaga), fork(sensorTypeSaga), fork(SensorSaga), fork(HistorySaga), fork(BrandSaga), fork(ReportTemplateSaga), fork(ReportTemplateDetailSaga), fork(UomSaga), fork(BrandTypeSaga), fork(StorageFileSaga), fork(StorageFolderSaga), fork(StorageMoveSaga), fork(StorageRenameSaga), fork(CompanySaga), fork(BranchSaga), fork(DashboardTemplateSaga), fork(DashboardTemplateDetailSaga), fork(NotificationSaga), fork(TaskSaga), fork(RoleListSaga), fork(ActivityLogSaga)]);
}
