import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";
import moment from "moment";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { useDispatch } from "react-redux";
import { addNewActivityLog } from "../../store/activityLog/action";
defineElement(lottie.loadAnimation);
const ExportExcelModal = ({ show, onCloseClick, data, filename, header, dataTemplate }) => {
  const dispatch = useDispatch()
  const date = new Date();
  const name = filename ? filename + "-" + moment(date).format("l") : "";
  const fileType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8`;
  const fileExstension = `.xlsx`;
  const exportToExcel = async () => {
    const ws = XLSX.utils.aoa_to_sheet(data, { header });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const datas = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(datas, name + fileExstension);
  };
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader toggle={onCloseClick}></ModalHeader>
      <ModalBody className='py-3 px-5'>
        <div className='mt-2 text-center'>
          <lord-icon
            src='https://cdn.lordicon.com/nocovwne.json'
            trigger='loop'
            colors='primary:#0ab39c,secondary:#f06548'
            style={{ width: "100px", height: "100px" }}></lord-icon>
          <div className='mt-4 pt-2 fs-15 mx-4 mx-sm-5'>
            <h4>Are you sure ?</h4>
            <p className='text-muted mx-4 mb-0'>
              Are you sure you want to export Excel file?
            </p>
          </div>
        </div>
        <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>
          <button
            type='button'
            className='btn w-sm btn-light'
            data-bs-dismiss='modal'
            onClick={onCloseClick}>
            Close
          </button>
          <Button
            data={data}
            type='button'
            onClick={(e) => {
              onCloseClick();
              exportToExcel(name);
              const obj = JSON.parse(sessionStorage.getItem("authUser"));
              if (obj && dataTemplate) {
                let dataPost = {
                  type: "download report",
                  detail: { companyId: dataTemplate.companyId, clientId: dataTemplate.clientId, templateId: dataTemplate.id },
                  userId: obj.data.userId
                }
                dispatch(addNewActivityLog(dataPost))
              }
            }}
            className='btn w-sm btn-success '
            id='delete-record'>
            Download
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

ExportExcelModal.propTypes = {
  onCloseClick: PropTypes.func,
  data: PropTypes.any,
  show: PropTypes.any,
};

export default ExportExcelModal;
