import {
  DOWNLOADAPP_LOADING,
  GET_DOWNLOADAPP,
  GET_DOWNLOADAPP_SUCCESS,
  GET_DOWNLOADAPP_FAIL,
  ADD_DOWNLOADAPP_SUCCESS,
  ADD_DOWNLOADAPP_FAIL,
  DELETE_DOWNLOADAPP_SUCCESS,
  DELETE_DOWNLOADAPP_FAIL,
  UPDATE_DOWNLOADAPP_SUCCESS,
  UPDATE_DOWNLOADAPP_FAIL,
  DOWNLOADAPP_RESET,
} from "./actionType";

const INIT_STATE = {
  downloadApps: [],
  error: {},
};

const DownloadApp = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOWNLOADAPP_SUCCESS:
      switch (action.payload.actionType) {
        case GET_DOWNLOADAPP:
          return {
            ...state,
            downloadApps: action.payload.data,
            isDownloadAppCreated: false,
            isDownloadAppSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_DOWNLOADAPP_FAIL:
      switch (action.payload.actionType) {
        case GET_DOWNLOADAPP_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isDownloadAppCreated: false,
            isDownloadAppSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_DOWNLOADAPP: {
      return {
        ...state,
        isDownloadAppCreated: false,
        loading: true,
      };
    }

    case DOWNLOADAPP_LOADING: {
      return {
        ...state,
        isDownloadAppCreated: false,
        loading: true,
      };
    }

    case ADD_DOWNLOADAPP_SUCCESS:
      return {
        ...state,
        isDownloadAppCreated: true,
        loading: false,
        downloadApps: [...state.downloadApps, action.payload.data],
      };

    case ADD_DOWNLOADAPP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_DOWNLOADAPP_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadApps: state.downloadApps.filter(
          (downloadApp) => downloadApp.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DOWNLOADAPP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_DOWNLOADAPP_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadApps: state.downloadApps.map((downloadApp) =>
          downloadApp.id.toString() === action.payload.data.id.toString()
            ? { ...downloadApp, ...action.payload.data }
            : downloadApp
        ),
      };

    case UPDATE_DOWNLOADAPP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DOWNLOADAPP_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default DownloadApp;
