import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getReportTemplate, resetReportTemplateState } from "../store/actions";
import { icon } from "leaflet";

const Navdata = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false)
  const [isUser, setIsUser] = useState(false);
  const [isUserSub, setIsUserSub] = useState(false);
  const [isReferralData, setIsReferralData] = useState(false);
  const [isStorages, setIsStorages] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isAssets, setIsAssets] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isCheckReportTemplate, setIsCheckReportTemplate] = useState(false);
  const [isCheckTemplate, setIsCheckTemplate] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [isRoles, setIsRoles] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isRef, setIsRef] = useState(false);
  const [isDevice, setIsDevice] = useState(false);
  const [isActivityLog, setIsActivityLog] = useState(false);
  const [userType, setUserType] = useState("user");
  const [roles, setRoles] = useState(null);

  const [isMonitoring, setIsMonitoring] = useState(false);
  const [isGeofence, setIsGeofence] = useState(false);
  const [isEmployees, setIsEmployees] = useState(false);
  const [isPayrolls, setIsPayrolls] = useState(false);
  const [isPayrollSchedules, setIsPayrollSchedule] = useState(false);
  const [isKeyPerformance, setIsKeyPerformance] = useState(false);
  const [isKeyPerformanceSub, setIsKeyPerformanceSub] = useState(false);
  const [isCalendar, setIsCalendar] = useState(false);

  const [isMasterData, setIsMasterData] = useState(false);
  const [isBilling, setIsBilling] = useState(false);
  const [isTask, setIsTask] = useState(false);
  const [isMasterDataCategories, setIsMasterDataCategories] = useState(false);
  const [isVendors, setIsVendors] = useState(false);

  const [isVariable, setIsVariable] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [isProductCategories, setIsProductCategories] = useState(false);

  const [isShipManagement, setIsShipManagement] = useState(false);
  const [isShipManagementCRM, setIsShipManagementCRM] = useState(false);

  const [isInventory, setIsInventory] = useState(false);
  const [isAccounting, setIsAccounting] = useState(false);
  const [isAccountingAccount, setIsAccountingAccount] = useState(false);
  const [isCurrentState, setIsCurrentState] = useState("Dashboard");
  const [isClient, setIsClient] = useState(false);
  const [childItem, setChildItem] = useState({});
  const [companyId, setCompanyId] = useState()

  const { reportTemplates } = useSelector((state) => ({
    reportTemplates: state.ReportTemplate.reportTemplates,
  }));
  // const [isDevice, setDevice] = useState(false);x

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      dispatch(resetReportTemplateState())
      dispatch(getReportTemplate({ status: 2 }))
      setCompanyId(obj.data.companyId)
    }
  }, [dispatch]);

  useEffect(() => {
    if (companyId && companyId === 14) {
      let data =
      {
        id: "Report",
        label: "Report",
        icon: "ri-file-chart-fill",
        link: "/report-cbu",
        click: function (e) {
          e.preventDefault();
          setIsReport(!isReport);
          setIsCurrentState("Report");
        },
        stateVariables: isReport,
        visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Report") !== -1) ? "" : " d-none",
      }
      setChildItem(data);
    } else {
      let data =
      {
        id: "Report",
        label: "Report",
        icon: "ri-file-chart-fill",
        link: "/report-template",
        click: function (e) {
          e.preventDefault();
          setIsReport(!isReport);
          setIsCurrentState("Report");
        },
        stateVariables: isReport,
        visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Report") !== -1) ? "" : " d-none",
      }
      setChildItem(data)
    }
  }, [companyId, isReport, roles, userType])

  useEffect(() => {
    if (!roles) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if (obj) {
        if (obj.data && obj.data.roleMenu) setRoles(obj.data.roleMenu);
        else setRoles(null);

        if (obj.data && obj.data.userType) setUserType(obj.data.userType);
      } else {
        window.location = "/logout";
      }
    }
    document.body.classList.remove("twocolumn-panel");
    if (isCurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (isCurrentState !== "Monitoring") {
      setIsMonitoring(false);
    }
    if (isCurrentState !== "Geofence") {
      setIsGeofence(false);
    }
    if (isCurrentState !== "User") {
      setIsUser(false);
    }
    if (isCurrentState !== "Storage") {
      setIsStorages(false);
    }
    if (isCurrentState !== "Assets") {
      setIsAssets(false);
    }
    if (isCurrentState !== "Messages") {
      setIsMessage(false);
    }
    if (isCurrentState !== "Calendar") {
      setIsCalendar(false);
    }
    if (isCurrentState !== "Variable") {
      setIsVariable(false);
    }
    if (isCurrentState !== "MasterData") {
      setIsMasterData(false);
    }
    if (isCurrentState !== "ShipManagement") {
      setIsShipManagement(false);
    }
    if (isCurrentState !== "Inventory") {
      setIsInventory(false);
    }
    if (isCurrentState !== "Accounting") {
      setIsAccounting(false);
    }
    if (isCurrentState !== "Roles") {
      setIsRoles(false);
    }
    if (isCurrentState !== "Client") {
      setIsClient(false);
    }
    if (isCurrentState !== "Billing") {
      setIsBilling(false);
    }
    if (isCurrentState !== "Apps") {
      setIsApps(false);
    }
  }, [
    history,
    isCurrentState,
    isDashboard,
    isStorages,
    isMonitoring,
    isGeofence,
    isUser,
    isAssets,
    isCheck,
    isCalendar,
    isMessage,
    isMasterData,
    isVariable,
    isShipManagement,
    isInventory,
    isAccounting,
    isClient,
    isBilling,
    isTask,
    isCheckTemplate,
    roles,
    isApps
  ]);
  const menuItems = [
    {
      id: "Dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-fill",
      link: "/dashboard",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Dashboard");
      },
      stateVariables: isDashboard,
      visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Dashboard") !== -1) ? "" : " d-none",
    },
    {
      id: "Monitoring",
      label: "Monitoring",
      icon: "ri-road-map-fill",
      link: "/monitoring",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Monitoring");
      },
      stateVariables: isMonitoring,
      visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Monitoring") !== -1) ? "" : " d-none",
    },
    {
      id: "Device",
      label: "Device",
      icon: "ri-apps-fill",
      link: "/device-detail",
      click: function (e) {
        e.preventDefault();
        setIsDevice(!isDevice);
        setIsCurrentState("Device");
      },
      stateVariables: isDevice,
      visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Device") !== -1) ? "" : " d-none",
    }, childItem,
    {
      id: "Activity Log",
      label: "Activity Log",
      icon: "ri-file-download-fill",
      link: "/activity-logs",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("ActivityLog");
      },
      stateVariables: isActivityLog,
      visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Activity Log") !== -1) ? "" : " d-none",
    },
    {
      id: "MasterData",
      label: "Master Data",
      icon: "ri-book-2-fill",
      link: "/#",
      stateVariables: isMasterData,
      click: function (e) {
        e.preventDefault();
        setIsMasterData(!isMasterData);
        setIsCurrentState("MasterData");
        updateIconSidebar(e);
      },
      visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Master Data") !== -1) ? "" : " d-none",
      subItems: [
        {
          id: "menuDevice",
          label: "Device",
          link: "/device",
          parentId: "MasterData",
          visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Master Data - Device") !== -1) ? "" : " d-none",
        },
        {
          id: "menuGeofence",
          label: "Geofence",
          link: "/geofence",
          parentId: "MasterData",
          visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Master Data - Geofence") !== -1) ? "" : " d-none",
        },
        {
          id: "menuSensor",
          label: "Sensor",
          link: "/sensor",
          parentId: "MasterData",
          visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Master Data - Sensor") !== -1) ? "" : " d-none",
        },
        {
          id: "menuParameter",
          label: "Parameter",
          link: "/parameter",
          parentId: "MasterData",
          visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Master Data - Parameter") !== -1) ? "" : " d-none",
        },
        {
          id: "menuReferensi",
          label: "Reference Data",
          link: "/#",
          parentId: "MasterData",
          isChildItem: true,
          stateVariables: isCheck,
          click: function (e) {
            e.preventDefault();
            setIsCheck(!isCheck);
          },
          visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Master Data") !== -1) ? "" : " d-none",
          childItems: [
            {
              id: 2,
              label: "Device Type",
              link: "/device-types",
              parentId: "MasterData",
              visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Master Data - Device Type") !== -1) ? "" : " d-none",
            },
            {
              id: 4,
              label: "Sensor Type",
              link: "/sensor-types",
              parentId: "MasterData",
              visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("Master Data - Sensor Type") !== -1) ? "" : " d-none",
            },
          ],
        },
      ],
    },
    {
      id: "Setting",
      label: "Setting",
      icon: "ri-settings-3-fill",
      link: "/#",
      stateVariables: isSetting,
      click: function (e) {
        e.preventDefault();
        setIsSetting(!isSetting);
        setIsCurrentState("Setting");
        updateIconSidebar(e);
      },
      visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : userType === "user" ? "d-none" : roles && (roles.split(",").indexOf("Dashboard Template") !== -1) ? "" : " d-none",
      subItems: [
        {
          id: "menuGeneral",
          label: "General",
          link: "/general",
          parentId: "Setting",
          visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : userType === "user" ? "d-none" : roles && (roles.split(",").indexOf("Dashboard Template") !== -1) ? "" : " d-none",
        },
        {
          id: "menuDashboardTemplate",
          label: "Dashboard Template",
          link: "/dashboard-templates",
          parentId: "Setting",
          visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : userType === "user" ? "d-none" : roles && (roles.split(",").indexOf("Dashboard Template") !== -1) ? "" : " d-none",
        },
      ],
    },
    {
      id: "User",
      label: "Users",
      icon: "ri-user-3-fill",
      link: "/users",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("User");
      },
      stateVariables: isUser,
      visible: (userType === "admin" || userType === "client" || userType === "company") ? "" : roles && (roles.split(",").indexOf("User") !== -1) ? "" : " d-none",
    },
    {
      id: "Apps",
      label: "Download Apk",
      icon: "ri-download-2-fill",
      link: "/apps",
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("Apps");
      },
      stateVariables: isApps,
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
