export const DASHBOARDTEMPLATE_LOADING = "DASHBOARDTEMPLATE_LOADING";
export const DASHBOARDTEMPLATE_RESET = "DASHBOARDTEMPLATE_RESET";
export const GET_DASHBOARDTEMPLATE = "GET_DASHBOARDTEMPLATE";
export const GET_DASHBOARDTEMPLATE_SUCCESS = "GET_DASHBOARDTEMPLATE_SUCCESS";
export const GET_DASHBOARDTEMPLATE_FAIL = "GET_DASHBOARDTEMPLATE_FAIL";
export const DELETE_DASHBOARDTEMPLATE = "DELETE_DASHBOARDTEMPLATE";
export const DELETE_DASHBOARDTEMPLATE_SUCCESS =
  "DELETE_DASHBOARDTEMPLATE_SUCCESS";
export const DELETE_DASHBOARDTEMPLATE_FAIL = "DELETE_DASHBOARDTEMPLATE_FAIL";
export const UPDATE_DASHBOARDTEMPLATE = "UPDATE_DASHBOARDTEMPLATE";
export const UPDATE_DASHBOARDTEMPLATE_SUCCESS =
  "UPDATE_DASHBOARDTEMPLATE_SUCCESS";
export const UPDATE_DASHBOARDTEMPLATE_FAIL = "UPDATE_DASHBOARDTEMPLATE_FAIL";
export const ADD_NEW_DASHBOARDTEMPLATE = "ADD_NEW_DASHBOARDTEMPLATE";
export const ADD_DASHBOARDTEMPLATE_SUCCESS = "ADD_DASHBOARDTEMPLATE_SUCCESS";
export const ADD_DASHBOARDTEMPLATE_FAIL = "ADD_DASHBOARDTEMPLATE_FAIL";
