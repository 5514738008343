import { load } from "protobufjs";
import { constant } from './constant.js'
import fileProto from './file.proto'

export function Fileserver(opt) {
    console.log('Masuk Server');
    let idInput = 'files';
    // let btnSend = 'btnsend';

    // let Domain = '103.84.206.223';
    let Domain = 'https://api-iot.atapteknologi.id';
    let Port = '80';
    let Scheme = 'ws';

    let onStart = {};
    let onProgress = {};
    let onEnd = {};

    let connection = undefined;
    let file = undefined;
    let token = '';
    let filename = '';
    let filesize = 0;
    let filetype = '';
    let allowedmime = [];
    let filedate = undefined;
    let filesend = undefined;
    let Header = undefined;
    let chunked = 5120;
    let userId=undefined;
    let folder = undefined
    let clientId=undefined

    const koneksi = async () => {
        if (!connection) {
            connection = new WebSocket(Scheme + '://' + Domain + ':' + Port + '/')
        } else {
            if (connection.readyState === WebSocket.CLOSING || connection.readyState === WebSocket.CLOSED) connection.close()
        }

        connection.onopen = function () {
            // connection.send('Ping'); // Send the message 'Ping' to the server
            console.log('koneksi open');
        };

        connection.onclose = function () {
            console.log('closed');
            connection = undefined;
            koneksi()
        }

        connection.onerror = function (error) {
            console.log('WebSocket Error ' + error);
        };

        connection.onmessage = function (e) {
            console.log(e.data)
            console.log(e.data.text())
            const fileReader = new FileReader();
            fileReader.onload = function () {
                console.log(fileReader.result)
                console.log(this.result)

                const uint8ArrayView = new Uint8Array(this.result);
                console.log(uint8ArrayView)
                console.log(filesend)
                const message = filesend.decode(uint8ArrayView);
                const object = filesend.toObject(message, { defaults: true });
                console.log(object)
                const data = getObject(object)
                console.log(data)
                if (object.cmd === constant.SEND_FILE) {
                    if (data.reply === String(constant.REPLY_SUCCESS)) {
                        console.log('data', token)
                        token = data.token
                        onStart(filesize);
                        mulaiKirimFile(0, chunked);
                    }
                    else {
                        console.log('GAGAL');
                    }
                }
                else if (object.cmd === constant.SEND_CHUNKED) {
                    var total = parseInt(data.total, 10);
                    if (data.reply === String(constant.REPLY_SUCCESS)) {
                        onProgress(total);
                        mulaiKirimFile(total, chunked);
                    }
                    else {
                        console.log('GAGAL');
                    }
                }
                else if (object.cmd === constant.SEND_END) {
                    onEnd(data);
                }
            };
            fileReader.readAsArrayBuffer(e.data);
        };
    }

    const mulaiKirimFile = async (startsz, besar) => {
        console.log('mulai');
        const reader = new FileReader();

        reader.onload = function () {
            var generatedBuffer = this.result;
            var uint8ArrayView = new Uint8Array(generatedBuffer);

            if (uint8ArrayView.length > 0) {
                var headers = [];
                var h1 = {
                    name: 'token',
                    value: token
                };
                var hh1 = Header.create(h1);
                headers.push(hh1);

                var payload = {
                    cmd: constant.SEND_CHUNKED,
                    headers: headers,
                    data: uint8ArrayView
                };

                // verify the payload if necessary (i.e. when possibly incomplete or invalid)
                var errMsg = filesend.verify(payload);
                if (errMsg) {
                    throw Error(errMsg);
                }

                var message = filesend.create(payload);
                var buffer = filesend.encode(message).finish();
                connection.send(buffer);
            } else {
                console.log('DATA KOSONG');
            }
        };

        var bbs = startsz + besar;
        if (bbs > filesize) bbs = filesize;

        var blob = file.slice(startsz, bbs);
        reader.readAsArrayBuffer(blob);
    }

    const kirimFile = async () => {
        koneksi()

        const headers = [];
        headers.push(addHeader({
            name: 'filename',
            value: filename
        }));

        headers.push(addHeader({
            name: 'folder',
            value: folder
        }));
        
        headers.push(addHeader({
            name: 'filesize',
            value: String(filesize)
        }));

        headers.push(addHeader({
            name: 'filetype',
            value: filetype
        }));

        headers.push(addHeader({
            name: 'userid',
            value: userId
        }));

        headers.push(addHeader({
            name: 'clientId',
            value: clientId ? clientId:""
        }));

        const payload = {
            cmd: constant.SEND_FILE,
            headers: headers
        };

        console.log(`payload:`, payload)

        const errMsg = filesend.verify(payload);
        if (errMsg) {
            throw Error(errMsg)
        }

        const message = filesend.create(payload);
        const buffer = filesend.encode(message).finish();
        connection.send(buffer)
    }

    const addHeader = (data) => {
        return Header.create(data)
    }

    const getObject = (param) => {
        console.log(param)
        const data = {}
        for (let i = 0; i < param.headers.length; i += 1) {
            console.log(param.headers[i])
            data[param.headers[i].name] = param.headers[i].value
        }
        console.log(data)
        return data
    }

    const getInfo = (opt) => {
        console.log(`ke getInfo`)
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            console.log(`support`)
            const iFile = document.querySelector('#' + idInput)
            console.log(iFile,'iFile')
            iFile.addEventListener('change', (e) => {
                console.log(`ke change`)
                e.preventDefault();
                const ffiles = e.target.files

                for (let i = 0; i < ffiles.length;  i++) {
                    const f = ffiles[i];
                    // console.log(f);
                    file = f;
                    filedate = new Date(f.lastModified);
                    filename = escape(f.name);
                    filesize = f.size;
                    filetype = f.type;
                }

                if (allowedmime.includes(filetype.toLowerCase())) {
                    console.log(`kirimFile`)
                    kirimFile()
                    return {
                        result: 200,
                        filename: filename,
                        filesize: filesize,
                        filetype: filetype,
                        filedate: filedate
                    }
                }

                return { result: 400 }
            }, false)
        } else {
            alert('The File APIs are not fully supported in this browser.')
        }
    }

    const init = (opt) => {

        if (opt.idinput) idInput = opt.idinput
        if (opt.userId) userId = opt.userId
        if (opt.clientId) clientId = opt.clientId
        if (opt.folder) folder = opt.folder
        if (opt.allowedmime) allowedmime = opt.allowedmime
        if (opt.onStart) onStart = opt.onStart
        if (opt.onEnd) onEnd = opt.onEnd
        if (opt.onProgress) onProgress = opt.onProgress

        load(fileProto, (err, root) => {
            if (err) throw err

            Header = root.lookupType("kirimfile.DataHeaders")
            filesend = root.lookupType("kirimfile.FileSend")

            console.log(Header)
            console.log(filesend)
            getInfo(opt)
            koneksi()
        })
    }

    init(opt);
}