import {
  GET_HISTORY,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAIL,
  DELETE_HISTORY,
  DELETE_HISTORY_SUCCESS,
  DELETE_HISTORY_FAIL,
  UPDATE_HISTORY,
  UPDATE_HISTORY_SUCCESS,
  UPDATE_HISTORY_FAIL,
  ADD_NEW_HISTORY,
  ADD_HISTORY_SUCCESS,
  ADD_HISTORY_FAIL,
  HISTORY_LOADING,
  HISTORY_RESET,
  GET_HISTORY_PUBLIC,
} from "./actionType";

export const resetHistoryState = (actionType) => ({
  type: HISTORY_RESET,
  payload: { actionType },
});

export const getHistorySuccess = (actionType, data) => ({
  type: GET_HISTORY_SUCCESS,
  payload: { actionType, data },
});

export const getHistoryFail = (actionType, error) => ({
  type: GET_HISTORY_FAIL,
  payload: { actionType, error },
});

export const getHistory = (data) => ({
  type: GET_HISTORY,
  payload: data,
});

export const getHistoryPublic = (data) => ({
  type: GET_HISTORY_PUBLIC,
  payload: data,
});

export const getHistoryLoading = () => ({
  type: HISTORY_LOADING,
});

export const deleteHistory = (data) => ({
  type: DELETE_HISTORY,
  payload: data,
});

export const deleteHistorySuccess = (data) => ({
  type: DELETE_HISTORY_SUCCESS,
  payload: data,
});

export const deleteHistoryFail = (error) => ({
  type: DELETE_HISTORY_FAIL,
  payload: error,
});

export const updateHistory = (data) => ({
  type: UPDATE_HISTORY,
  payload: data,
});

export const updateHistoryFail = (error) => ({
  type: UPDATE_HISTORY_FAIL,
  payload: error,
});

export const updateHistorySuccess = (data) => ({
  type: UPDATE_HISTORY_SUCCESS,
  payload: data,
});

export const addNewHistory = (data) => ({
  type: ADD_NEW_HISTORY,
  payload: data,
});

export const addHistorySuccess = (data) => ({
  type: ADD_HISTORY_SUCCESS,
  payload: data,
});

export const addHistoryFail = (error) => ({
  type: ADD_HISTORY_FAIL,
  payload: error,
});
