import {
  DEVICE_LOADING,
  GET_DEVICE,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAIL,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAIL,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAIL,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAIL,
  DEVICE_RESET,
  GET_DEVICE_ONE,
} from "./actionType";

const INIT_STATE = {
  devices: [],
  error: {},
  deviceOnes : []
};

const Device = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_DEVICE:
          return {
            ...state,
            devices: action.payload.data,
            isDeviceCreated: false,
            isDeviceSuccess: true,
            loading: false,
          };

          case GET_DEVICE_ONE:
          return {
            ...state,
            deviceOnes: action.payload.data,
            isDeviceSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_DEVICE_FAIL:
      switch (action.payload.actionType) {
        case GET_DEVICE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isDeviceCreated: false,
            isDeviceSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_DEVICE: {
      return {
        ...state,
        isDeviceCreated: false,
        loading: true,
      };
    }

    case GET_DEVICE_ONE: {
      return {
        ...state,
        loading: true,
      };
    }

    case DEVICE_LOADING: {
      return {
        ...state,
        isDeviceCreated: false,
        loading: true,
      };
    }

    case ADD_DEVICE_SUCCESS:
      return {
        ...state,
        isDeviceCreated: true,
        loading: false,
        devices: [...state.devices, action.payload.data],
      };

    case ADD_DEVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        devices: state.devices.filter(
          (device) => device.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DEVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        devices: state.devices.map((device) =>
          device.id.toString() === action.payload.data.id.toString()
            ? { ...device, ...action.payload.data }
            : device
        ),
      };

    case UPDATE_DEVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DEVICE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Device;
