import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  Table,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";

import Flatpickr from "react-flatpickr";
import Select from "react-select";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
  getSensor as onGetSensors,
  addNewSensor as onAddNewSensor,
  updateSensor as onUpdateSensor,
  deleteSensor as onDeleteSensor,
  getSensorLoading,
} from "../../../store/sensor/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import defaultImg from "../../../assets/images/upload.png";

import { getClient, resetClientState } from "../../../store/client/action";
import { getBranch, resetBranchState } from "../../../store/branch/action";
import {
  getSensorType,
  resetSensorTypeState,
} from "../../../store/sensorType/action";
import { getDevice, resetDeviceState } from "../../../store/device/action";
import { getUnit, resetUnitState } from "../../../store/unit/action";
import { getCompany, resetCompanyState } from "../../../store/company/action";
import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const Sensor = (props) => {
  const { t } = props;
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [logo, setLogo] = useState(defaultImg);
  const [branchList, setBranchList] = useState([]);
  const dispatch = useDispatch();
  const [pIndex, setPIndex] = useState(0);

  const {
    sensors,
    isSensorCreated,
    isSensorSuccess,
    loading,
    error,
    clients,
    sensorTypes,
    units,
    companiesLoading,
    branchesLoading,
    unitLoading,
    companies,
    branches,
    devices,
    devicesLoading,
  } = useSelector((state) => ({
    sensors: state.Sensor.sensors,
    isSensorCreated: state.Sensor.isSensorCreated,
    isSensorSuccess: state.Sensor.isSensorSuccess,
    loading: state.Sensor.loading,
    error: state.Sensor.error,
    clients: state.Client.clients,
    sensorTypes: state.SensorType.sensorTypes,
    devices: state.Device.devices,
    units: state.Unit.units,
    companiesLoading: state.Company.loading,
    branchesLoading: state.Branch.loading,
    unitLoading: state.Unit.loading,
    companies: state.Company.companies,
    branches: state.Branch.branches,
    devicesLoading: state.Device.loading,
  }));

  const [sensorList, setSensorList] = useState([]);
  const [sensor, setSensor] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: 1 },
        { label: "Active", value: 2 },
        { label: "Deactivated", value: 3 },
      ],
    },
  ];

  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [userType, setUserType] = useState("user");
  const [companyList, setCompanyList] = useState([]);

  const [clientList, setClientList] = useState([]);
  const [sensorTypeList, setSensorTypeList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [clientId, setClientId] = useState();
  const [unitId, setUnitId] = useState(-1);

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(sensorTypes)) {
      var data = [];
      var opt = [];
      sensorTypes.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setSensorTypeList(data);
    }
  }, [sensorTypes]);

  useEffect(() => {
    if (!isEmpty(companies)) {
      var data = [];
      var opt = [];
      companies.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setCompanyList(data);
    }
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(branches)) {
      var data = [];
      var opt = [];
      branches.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setBranchList(data);
    }
  }, [branches]);

  useEffect(() => {
    if (!isEmpty(devices)) {
      var data = [];
      var opt = [];
      devices.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = {
          label: name,
          value: id,
          unitId: item.device_device_unit[0]
            ? item.device_device_unit[0].unitId
            : "",
        };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setDeviceList(data);
    }
  }, [devices]);

  useEffect(() => {
    if (!isEmpty(units)) {
      var data = [];
      var opt = [];
      units.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id, unitId: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setUnitList(data);
    }
  }, [units]);

  useEffect(() => {
    dispatch(resetClientState());
    dispatch(resetSensorTypeState());
    dispatch(resetDeviceState());
    dispatch(resetUnitState());
    dispatch(getClient({ status: 2 }));
    dispatch(getSensorType({ status: 2 }));
  }, [dispatch]);

  const onClickDelete = (sensor) => {
    setSensor(sensor);
    setDeleteModal(true);
  };

  const handleDeleteSensor = () => {
    if (sensor) {
      dispatch(onDeleteSensor(sensor));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setSensorList(sensors);
  }, [sensors]);

  useEffect(() => {
    if (!isEmpty(sensors)) setSensorList(sensors);
  }, [sensors]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredSensors = sensors;
      if (type !== "all") {
        filteredSensors = sensors.filter(
          (sensor) => sensor.sensor_status.name === type
        );
      }
      setSensorList(filteredSensors);
    }
  };

  useEffect(() => {
    if (sensors && !sensors.length && !isSearch) {
      dispatch(onGetSensors());
      dispatch(resetDeviceState());
      dispatch(getDevice({ status: 2 }));
    }
    setIsSearch(true);
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      if (obj.data && obj.data.userType) setUserType(obj.data.userType);
      if (obj.data.userType === "company") {
        setCompanyId(obj.data.companyId);
        setClientId(obj.data.clientId);
      } else if (obj.data.userType === "client") {
        setClientId(obj.data.clientId);
      }
    } else {
      window.location = "/logout";
    }
  }, [dispatch, sensors, isSearch]);

  useEffect(() => {
    setSensor(sensors);
  }, [sensors]);

  useEffect(() => {
    if (!isEmpty(sensors)) {
      setSensor(sensors);
      setIsEdit(false);
    }
  }, [sensors]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setSensor(null);
      setLogo(defaultImg);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleSensorClick = useCallback(
    (arg) => {
      const sensor = arg;

      setSensor({
        id: sensor.id,
        name: sensor.name,
        code: sensor.code,
        model: sensor.model,
        imei: sensor.imei,
        slaveId: sensor.slaveId,
        baudrate: sensor.baudrate,
        client: {
          label: sensor.sensor_client.name,
          value: sensor.sensor_client.id,
        },
        sensorType: sensor.sensor_type
          ? {
            label: sensor.sensor_type.name,
            value: sensor.sensor_type.id,
          }
          : "",
        device: sensor.sensor_device
          ? {
            label: sensor.sensor_device.name,
            value: sensor.sensor_device.id,
          }
          : "",
        unit: {
          label: sensor.sensor_unit ? sensor.sensor_unit.name : "",
          value: sensor.sensor_unit ? sensor.sensor_unit.id : "",
        },
        description: sensor.description,
        status: {
          label: sensor.sensor_status.name,
          value: sensor.sensor_status.id,
        },
        company: {
          label: sensor.sensor_company ? sensor.sensor_company.name : "",
          value: sensor.sensor_company ? sensor.sensor_company.id : "",
        },
        branch: {
          label: sensor.sensor_branch ? sensor.sensor_branch.name : "",
          value: sensor.sensor_branch ? sensor.sensor_branch.id : "",
        },
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const handleYup = () => {
    let datas = {
      client: Yup.object().required("Please Select Client"),
      company: Yup.object().required("Please Select Company"),
      branch: Yup.object().required("Please Select Branch"),
      unit: Yup.object().required("Please Select Unit"),
      device: Yup.object().required("Please Select Device"),
      sensorType: Yup.object().required("Please Select Sensor Type"),
      status: Yup.object().required("Please Select Status"),
      name: Yup.string().required("Please Enter Name"),
    }
    if (userType === 'company') {
      delete datas.client
      delete datas.company
    } else if (userType === "client") {
      delete datas.client
    }
    return datas
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (sensor && sensor.name) || "",
      code: (sensor && sensor.code) || "",
      model: (sensor && sensor.model) || "",
      imei: (sensor && sensor.imei) || "",
      slaveId: (sensor && sensor.slaveId) || 0,
      baudrate: (sensor && sensor.baudrate) || 0,
      description: (sensor && sensor.description) || "",
      client: (sensor && sensor.client) || "",
      status: (sensor && sensor.status) || "",
      sensorType: (sensor && sensor.sensorType) || "",
      device: (sensor && sensor.device) || "",
      unit: (sensor && sensor.unit) || "",
      company: (sensor && sensor.company) || "",
      branch: (sensor && sensor.branch) || "",
    },
    validationSchema: Yup.object(handleYup()),
    onSubmit: (values) => {
      dispatch(getSensorLoading());
      if (isEdit) {
        const editSensor = {
          id: sensor ? sensor.id : 0,
          name: values.name,
          code: values.code,
          model: values.model,
          imei: values.imei,
          slaveId: values.slaveId,
          baudrate: values.baudrate,
          description: values.description,
          clientId: clientId ? clientId : values.client.value,
          statusId: values.status.value,
          sensorTypeId: values.sensorType.value,
          deviceId: values.device.value,
          unitId: values.unit.value,
          companyId: companyId ? companyId : values.company.value,
          branchId: values.branch.value,
        };
        dispatch(onUpdateSensor(editSensor));
        validation.resetForm();
      } else {
        const newSensor = {
          name: values.name,
          code: values.code,
          model: values.model,
          imei: values.imei,
          slaveId: values.slaveId,
          baudrate: values.baudrate,
          description: values.description,
          clientId: clientId ? clientId : values.client.value,
          statusId: values.status.value,
          sensorTypeId: values.sensorType.value,
          deviceId: values.device.value,
          unitId: values.unit.value,
          companyId: companyId ? companyId : values.company.value,
          branchId: values.branch.value,
        };
        dispatch(onAddNewSensor(newSensor));
        validation.resetForm();
      }
      toggle();
    },
  });

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const handleCompany = (data) => {
    dispatch(resetCompanyState());
    dispatch(getCompany({ clientId: data.value }));
  };

  const handleUnit = (data) => {
    dispatch(resetUnitState());
    dispatch(getUnit({ branchId: data.value }));
  };

  const handleBranch = (data) => {
    dispatch(resetBranchState());
    dispatch(getBranch({ companyId: data.value }));
  };

  useEffect(() => {
    if (companyId) {
      dispatch(resetBranchState());
      dispatch(getBranch({ companyId: companyId }));
    }
  }, [companyId, dispatch]);

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteSensor(element.value));
    });
    checkall.checked = false;
  };

  const getDeviceSelectList = useMemo(() => {
    if (unitId > -1) {
      const newOptDevice = deviceList[0].options.filter((itm) => {
        return itm.unitId === unitId;
      });
      return [{ options: newOptDevice }];
    } else {
      return deviceList;
    }
  }, [deviceList, unitId]);

  // able Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type='checkbox'
            id='checkBoxAll'
            className='form-check-input'
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type='checkbox'
              className='dataCheckbox form-check-input'
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className='hstack gap-2 mt-4 mt-sm-0'>
              <Link
                to='#'
                className='btn-icon btn btn-sm btn-soft-primary edit-item-btn'
                onClick={() => {
                  const sensorData = cellProps.row.original;
                  handleSensorClick(sensorData);
                }}>
                <i className='ri-pencil-line fs-16'></i>
              </Link>
              <Link
                to='#'
                className='btn-icon btn btn-sm btn-soft-danger remove-item-btn'
                onClick={() => {
                  const sensorData = cellProps.row.original;
                  onClickDelete(sensorData);
                }}>
                <i className=' ri-delete-bin-line fs-15'></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: `${t("Device Name")}`,
        accessor: "sensor_device.name",
        filterable: true,
      },
      {
        Header: `${t("Sensor Name")}`,
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Model",
        accessor: "model",
        filterable: true,
      },
      {
        Header: "Imei",
        accessor: "imei",
        filterable: true,
      },
      {
        Header: "Slave Id",
        accessor: "slaveId",
        filterable: true,
      },
      {
        Header: "Baudrate",
        accessor: "baudrate",
        filterable: true,
      },

      {
        Header: `${t("Description")}`,
        accessor: "description",
        filterable: true,
      },
      {
        Header: `${t("Status")}`,
        accessor: "sensor_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "Activated":
              return (
                <span className='badge text-uppercase badge-soft-secondary'>
                  {t(cell.value)}
                </span>
              );
            case "Deactivated":
              return (
                <span className='badge text-uppercase badge-soft-danger'>
                  {t(cell.value)}
                </span>
              );
            case "Draft":
              return (
                <span className='badge text-uppercase badge-soft-warning'>
                  {t(cell.value)}
                </span>
              );
            default:
              return (
                <span className='badge text-uppercase badge-soft-warning'>
                  {t(cell.value)}
                </span>
              );
          }
        },
      },
    ],
    [handleSensorClick, t]
  );

  const defaultdate = () => {
    return moment().tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const [filterDate, setFilterDate] = useState(defaultdate());

  const handleSubscribedAt = (date) => {
    validation.setFieldValue("subscribedat", date);
  };

  const handleExpirededAt = (date) => {
    validation.setFieldValue("expiredat", date);
  };

  const handleValidDate = (date) => {
    return moment(new Date(date), "YYYY/MM/DD")
      .tz("Asia/Jakarta")
      .format("MMM D, YYYY");
  };

  const handleValidTime = (time) => {
    return moment(new Date(time), "YYYY/MM/DD")
      .tz("Asia/Jakarta")
      .format("HH:mm");
  };

  //TABLE
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: sensorList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: pIndex,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable
      ? column.isSorted
        ? column.isSortedDesc
          ? " sorting_desc"
          : " sorting_asc"
        : " sort"
      : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState([]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    if (filterDate) {
      let datearr = filterDate.split(" to ");
      if (datearr[1]) {
        startat = datearr[0];
        endat = datearr[1];
      }
    }
    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetSensors(param));
  }

  function handleLogoChange(event) {
    if (event.target.files && event.target.files[0]) {
      setLogo(URL.createObjectURL(event.target.files[0]));
      validation.setFieldValue("img", event.target.files[0]);
    }
  }

  useEffect(() => {
    setPIndex(pageIndex);
  }, [pageIndex]);

  document.title = "Sensor | TELKOMSEL - IOT Monitoring";
  return (
    <div className='page-content'>
      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={sensorList}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSensor}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title='Sensor' pageTitle={t("Master Data")} />
        <Row>
          <Col lg={12}>
            <Card id='sensorList'>
              <CardHeader className='card-header border-0'>
                <div className='d-flex align-items-center'>
                  <h5 className='card-title mb-0 flex-grow-1'>
                    Sensor &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color='primary' type='grow' size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color='success' type='grow' size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className='flex-shrink-0'>
                    <button
                      type='button'
                      className='btn btn-primary add-btn'
                      id='create-btn'
                      onClick={() => {
                        setSensor("");
                        setIsEdit(false);
                        toggle();
                      }}>
                      <i className='ri-router-line me-1 align-bottom fs-14'></i>{" "}
                      {t("Add")}
                    </button>{" "}
                    <button
                      type='button'
                      className='btn btn-success'
                      onClick={() => setIsExportCSV(true)}>
                      <i className='ri-file-download-line me-1 align-bottom fs-14'></i>{" "}
                      {t("Export")}
                    </button>{" "}
                    <button
                      className='btn btn-soft-danger'
                      onClick={() => setDeleteModalMulti(true)}>
                      <i className='ri-delete-bin-5-line me-1 align-bottom fs-15'></i>{" "}
                      {t("Delete")}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className='pt-0'>
                <div>
                  <Nav
                    className='nav-tabs nav-tabs-custom nav-success'
                    role='tablist'>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href='#'>
                        <i className='ri-router-line me-1 align-bottom fs-14'></i>{" "}
                        {`${t("All")} Sensor`}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href='#'>
                        <i className='ri-draft-line me-1 align-bottom fs-14'></i>{" "}
                        {t("Draft")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "3" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href='#'>
                        <i className='ri-checkbox-circle-line me-1 align-bottom fs-14'></i>{" "}
                        {t("Activated")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "4" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("4", "Deactivated");
                        }}
                        href='#'>
                        <i className='ri-indeterminate-circle-line me-1 align-bottom fs-14'></i>{" "}
                        {t("Deactivated")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className='mb-3'>
                    <CardBody className='border border-top-0 border-end-0 border-start-0'>
                      <form>
                        <Row>
                          <Col sm={1} className='col-xxl-1'>
                            <div className='me-2 mb-2 col-12'>
                              <select
                                className='form-select'
                                value={pageSize}
                                onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className='search-box me-2 mb-2 d-inline-block col-12'>
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id='search-bar-0'
                                type='text'
                                className='form-control search'
                                placeholder={`${count} ${t("Search")}...`}
                                value={searchValue || ""}
                              />
                              <i className='bx bx-search-alt search-icon'></i>
                            </div>
                          </Col>
                          <Col sm={6} className='col-xxl-3'>
                            <div className='search-box me-2 mb-2 col-12'>
                              <Flatpickr
                                className='form-control'
                                id='datepicker-publish-input'
                                placeholder={t("Select created date")}
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(obj, str) => {
                                  setFilterDate(str);
                                }}
                              />
                              <i className='bx bx-calendar-alt search-icon'></i>
                            </div>
                          </Col>
                          <Col sm={4} className='col-xxl-2'>
                            <div className='me-2 mb-2 col-12'>
                              <Select
                                value={statusActive}
                                placeholder={t("Select Status")}
                                onChange={(e) => {
                                  setStatusActive(e);
                                }}
                                options={statusActiveData}
                                name='choices-single-default'
                                id='idStatus'></Select>
                            </div>
                          </Col>
                          <Col className='col-xxl-2'>
                            <div>
                              <button
                                type='button'
                                className='btn btn-light w-100'
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleFilter();
                                }}>
                                <i className='ri-search-line me-1 align-bottom fs-14'></i>{" "}
                                {t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className='table-responsive table-card mb-1'>
                    <Table
                      hover
                      {...getTableProps()}
                      className='align-middle table-nowrap'>
                      <thead className='table-light text-muted'>
                        {headerGroups.map((headerGroup) => (
                          <tr
                            className={""}
                            key={headerGroup.id}
                            {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                key={column.id}
                                className={"" + generateFilterable(column)}
                                {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                    <Col className='col-md-auto'>
                      <div className='d-flex gap-1'>
                        <Button
                          className='btn btn-light'
                          onClick={previousPage}
                          disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className='col-md-auto d-none d-md-block'>
                      {`${t("Page")} `}
                      <strong>
                        {pageIndex + 1} {t("of")} {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className='col-md-auto'>
                      <Input
                        type='number'
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        value={pIndex + 1}
                        onChange={onChangeInInput}
                      />
                    </Col>

                    <Col className='col-md-auto'>
                      <div className='d-flex gap-1'>
                        <Button
                          className='btn btn-light'
                          onClick={nextPage}
                          disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal
                  id='showModal'
                  isOpen={modal}
                  centered
                  fullscreen={false}
                  size='lg'>
                  <ModalHeader className='bg-light p-3' toggle={toggle}>
                    {!!isEdit
                      ? `${t("Edit")}  ${t("Sensor")}`
                      : `${t("Add")}  ${t("Sensor")}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <ModalBody>
                      <Row>
                        <Col lg={6}>
                          <div className='mb-3'>
                            <Label htmlFor='name-field' className='form-label'>
                              {t("Name")} <span className='text-danger'>*</span>
                            </Label>
                            <Input
                              name='name'
                              id='name-field'
                              className='form-control'
                              placeholder={`${t("Enter")} ${t("Name")}`}
                              type='text'
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                  validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                              validation.errors.name ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className='mb-3'>
                            <Label
                              htmlFor='devicename-field'
                              className='form-label'>
                              {t("Code")}
                            </Label>
                            <Input
                              name='code'
                              id='code-field'
                              className='form-control'
                              placeholder={`${t("Enter")} ${t("Code")}`}
                              type='text'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.code || ""}
                            />
                          </div>
                          <div className='mb-3'>
                            <Label htmlFor='model-field' className='form-label'>
                              Model
                            </Label>
                            <Input
                              name='model'
                              id='model-field'
                              className='form-control'
                              placeholder={`${t("Enter")} ${t("Model")}`}
                              type='text'
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.model || ""}
                              invalid={
                                validation.touched.model &&
                                  validation.errors.model
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.model &&
                              validation.errors.model ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.model}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className='mb-3'>
                            <Label htmlFor='imei-field' className='form-label'>
                              Imei{" "}
                            </Label>
                            <Input
                              name='imei'
                              id='imei-field'
                              className='form-control'
                              placeholder={`${t("Enter")} ${t("Imei")}`}
                              type='text'
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.imei || ""}
                              invalid={
                                validation.touched.imei &&
                                  validation.errors.imei
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.imei &&
                              validation.errors.imei ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.imei}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className='mb-3'>
                            <Label
                              htmlFor='slaveId-field'
                              className='form-label'>
                              Slave Id{" "}
                            </Label>
                            <Input
                              name='slaveId'
                              id='slaveId-field'
                              className='form-control'
                              placeholder={`${t("Enter")} ${t("Slave Id")}`}
                              type='number'
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.slaveId || ""}
                              invalid={
                                validation.touched.slaveId &&
                                  validation.errors.slaveId
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.slaveId &&
                              validation.errors.slaveId ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.slaveId}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className='mb-3'>
                            <Label
                              htmlFor='baudrate-field'
                              className='form-label'>
                              Baudrate{" "}
                            </Label>
                            <Input
                              name='baudrate'
                              id='baudrate-field'
                              className='form-control'
                              placeholder={`${t("Enter")} ${t("Baudrate")}`}
                              type='number'
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.baudrate || ""}
                              invalid={
                                validation.touched.baudrate &&
                                  validation.errors.baudrate
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.baudrate &&
                              validation.errors.baudrate ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.baudrate}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          {userType === "admin" || userType === "user" ? (
                            <>
                              <div className='mb-3'>
                                <Label
                                  htmlFor='password-field'
                                  className='form-label'>
                                  {t("Client")} <span className='text-danger'>*</span>
                                </Label>
                                <Select
                                  name='client'
                                  value={validation.values.client}
                                  aria-invalid={validation.touched.client}
                                  aria-errormessage={validation.errors.client}
                                  placeholder={`${t("Select")} ${t("Client")}`}
                                  onChange={(e) => {
                                    validation.setFieldValue("client", e);
                                    handleCompany(e);
                                  }}
                                  options={clientList}></Select>
                                {validation.touched.client && (
                                  <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                    {validation.errors.client}
                                  </p>
                                )}
                              </div>
                              <div className='mb-3'>
                                <Label
                                  htmlFor='password-field'
                                  className='form-label'>
                                  {t("Company")} <span className='text-danger'>*</span>
                                </Label>
                                {companiesLoading ? (
                                  <>
                                    <Spinner
                                      color='primary'
                                      type='grow'
                                      size={"sm"}
                                      className='ms-2'></Spinner>
                                    &nbsp;
                                    <Spinner
                                      color='success'
                                      type='grow'
                                      size={"sm"}>
                                      Loading...
                                    </Spinner>
                                    &nbsp;
                                  </>
                                ) : (
                                  ""
                                )}
                                <Select
                                  name='company'
                                  value={validation.values.company}
                                  aria-invalid={validation.touched.company}
                                  aria-errormessage={validation.errors.company}
                                  placeholder={`${t("Select")} ${t("Company")}`}
                                  onChange={(e) => {
                                    validation.setFieldValue("company", e);
                                    handleBranch(e);
                                  }}
                                  options={companyList}></Select>
                                {validation.touched.company && (
                                  <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                    {validation.errors.company}
                                  </p>
                                )}
                              </div>
                            </>
                          ) : userType === "client" ? (
                            <>
                              <div className='mb-3'>
                                <Label
                                  htmlFor='password-field'
                                  className='form-label'>
                                  {t("Company")} <span className='text-danger'>*</span>
                                </Label>
                                {companiesLoading ? (
                                  <>
                                    <Spinner
                                      color='primary'
                                      type='grow'
                                      size={"sm"}
                                      className='ms-2'></Spinner>
                                    &nbsp;
                                    <Spinner
                                      color='success'
                                      type='grow'
                                      size={"sm"}>
                                      Loading...
                                    </Spinner>
                                    &nbsp;
                                  </>
                                ) : (
                                  ""
                                )}
                                <Select
                                  name='company'
                                  value={validation.values.company}
                                  aria-invalid={validation.touched.company}
                                  aria-errormessage={validation.errors.company}
                                  placeholder={`${t("Select")} ${t("Company")}`}
                                  onChange={(e) => {
                                    validation.setFieldValue("company", e);
                                    handleBranch(e);
                                  }}
                                  options={companyList}></Select>
                                {validation.touched.company && (
                                  <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                    {validation.errors.company}
                                  </p>
                                )}
                              </div>
                            </>
                          ) : null}
                          <div className='mb-3'>
                            <Label
                              htmlFor='password-field'
                              className='form-label'>
                              {t("Branch")} <span className='text-danger'>*</span>
                            </Label>
                            {branchesLoading ? (
                              <>
                                <Spinner
                                  color='primary'
                                  type='grow'
                                  size={"sm"}
                                  className='ms-2'>
                                  Loading...
                                </Spinner>
                                &nbsp;
                                <Spinner
                                  color='success'
                                  type='grow'
                                  size={"sm"}>
                                  Loading...
                                </Spinner>
                                &nbsp;
                              </>
                            ) : (
                              ""
                            )}
                            <Select
                              name='branch'
                              value={validation.values.branch}
                              aria-invalid={validation.touched.branch}
                              aria-errormessage={validation.errors.branch}
                              placeholder={`${t("Select")} ${t("Branch")}`}
                              onChange={(e) => {
                                validation.setFieldValue("branch", e);
                                handleUnit(e);
                              }}
                              options={branchList}></Select>
                            {validation.touched.branch && (
                              <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                {validation.errors.branch}
                              </p>
                            )}
                          </div>
                          <div className='mb-3'>
                            <Label
                              htmlFor='password-field'
                              className='form-label'>
                              Unit <span className='text-danger'>*</span>
                            </Label>
                            {unitLoading ? (
                              <>
                                <Spinner
                                  color='primary'
                                  type='grow'
                                  size={"sm"}
                                  className='ms-2'>
                                  Loading...
                                </Spinner>
                                &nbsp;
                                <Spinner
                                  color='success'
                                  type='grow'
                                  size={"sm"}>
                                  Loading...
                                </Spinner>
                                &nbsp;
                              </>
                            ) : (
                              ""
                            )}
                            <Select
                              name='unit'
                              value={validation.values.unit}
                              aria-invalid={validation.touched.unit}
                              aria-errormessage={validation.errors.unit}
                              placeholder={`${t("Select")} ${t("Unit")}`}
                              onChange={(e) => {
                                validation.setFieldValue("unit", e);
                                setUnitId(e.value);
                              }}
                              options={unitList}></Select>
                            {validation.touched.unit && (
                              <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                {validation.errors.unit}
                              </p>
                            )}
                          </div>
                          <div className='mb-3'>
                            <Label
                              htmlFor='password-field'
                              className='form-label'>
                              {t("Device")} <span className='text-danger'>*</span>
                            </Label>
                            {devicesLoading ? (
                              <>
                                <Spinner
                                  color='primary'
                                  type='grow'
                                  size={"sm"}
                                  className='ms-2'>
                                  Loading...
                                </Spinner>
                                &nbsp;
                                <Spinner
                                  color='success'
                                  type='grow'
                                  size={"sm"}>
                                  Loading...
                                </Spinner>
                                &nbsp;
                              </>
                            ) : (
                              ""
                            )}
                            <Select
                              name='device'
                              value={validation.values.device}
                              aria-invalid={validation.touched.device}
                              aria-errormessage={validation.errors.device}
                              placeholder={`${t("Select")} ${t("Device")}`}
                              onChange={(e) => {
                                validation.setFieldValue("device", e);
                              }}
                              options={getDeviceSelectList}></Select>
                            {validation.touched.device && (
                              <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                {validation.errors.device}
                              </p>
                            )}
                          </div>
                          <div className='mb-3'>
                            <Label
                              htmlFor='sensorType-field'
                              className='form-label'>
                              {t("Sensor Type")} <span className='text-danger'>*</span>
                            </Label>
                            <Select
                              name='sensorType'
                              value={validation.values.sensorType}
                              aria-invalid={validation.touched.sensorType}
                              aria-errormessage={validation.errors.sensorType}
                              validate={{ required: { value: true } }}
                              placeholder={`${t("Select")} ${t("Sensor Type")}`}
                              onChange={(e) => {
                                validation.setFieldValue("sensorType", e);
                              }}
                              options={sensorTypeList}></Select>
                            {validation.touched.sensorType && (
                              <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                {validation.errors.sensorType}
                              </p>
                            )}
                          </div>
                          <div className='mb-3'>
                            <Label
                              htmlFor='status-field'
                              className='form-label'>
                              Status <span className='text-danger'>*</span>
                            </Label>
                            <Select
                              name='status'
                              value={validation.values.status}
                              aria-invalid={validation.touched.status}
                              aria-errormessage={validation.errors.status}
                              validate={{ required: { value: true } }}
                              placeholder={`${t("Select")} ${t("Status")}`}
                              onChange={(e) => {
                                validation.setFieldValue("status", e);
                              }}
                              options={statusActiveData}></Select>
                            {validation.touched.status && (
                              <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                {validation.errors.status}
                              </p>
                            )}
                          </div>
                          <div className='mb-3'>
                            <Label htmlFor='des-field' className='form-label'>
                              {t("Description")}
                            </Label>
                            <Input
                              name='description'
                              id='des-field'
                              className='form-control'
                              rows={5}
                              placeholder={`${t("Enter")} ${t("Description")}`}
                              type='textarea'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.description || ""}
                            />
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <div className='modal-footer mb3'>
                      <div className='hstack gap-2 justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-light'
                          onClick={() => {
                            setModal(false);
                            validation.resetForm();
                          }}>
                          {t("Close")}
                        </button>
                        <button type='submit' className='btn btn-success'>
                          <span className='d-flex align-items-center'>
                            <span className='flex-grow-1 me-2'>
                              {!!isEdit
                                ? `${t("Update")}  ${t("Sensor")}`
                                : `${t("Add")}  ${t("Sensor")}`}
                            </span>
                            {loading ? (
                              <Spinner
                                size='sm'
                                className='flex-shrink-0'
                                role='status'>
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Sensor.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Sensor));
