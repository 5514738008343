import {
  DASHBOARDTEMPLATEDETAIL_LOADING,
  GET_DASHBOARDTEMPLATEDETAIL,
  GET_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  GET_DASHBOARDTEMPLATEDETAIL_FAIL,
  ADD_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  ADD_DASHBOARDTEMPLATEDETAIL_FAIL,
  DELETE_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  DELETE_DASHBOARDTEMPLATEDETAIL_FAIL,
  UPDATE_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  UPDATE_DASHBOARDTEMPLATEDETAIL_FAIL,
  DASHBOARDTEMPLATEDETAIL_RESET,
} from "./actionType";

const INIT_STATE = {
  dashboardTemplateDetails: [],
  error: {},
};

const DashboardTemplateDetail = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARDTEMPLATEDETAIL_SUCCESS:
      switch (action.payload.actionType) {
        case GET_DASHBOARDTEMPLATEDETAIL:
          return {
            ...state,
            dashboardTemplateDetails: action.payload.data,
            isDashboardTemplateDetailCreated: false,
            isDashboardTemplateDetailSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_DASHBOARDTEMPLATEDETAIL_FAIL:
      switch (action.payload.actionType) {
        case GET_DASHBOARDTEMPLATEDETAIL_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isDashboardTemplateDetailCreated: false,
            isDashboardTemplateDetailSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_DASHBOARDTEMPLATEDETAIL: {
      return {
        ...state,
        isDashboardTemplateDetailCreated: false,
        loading: true,
      };
    }

    case DASHBOARDTEMPLATEDETAIL_LOADING: {
      return {
        ...state,
        isDashboardTemplateDetailCreated: false,
        loading: true,
      };
    }

    case ADD_DASHBOARDTEMPLATEDETAIL_SUCCESS:
      return {
        ...state,
        isDashboardTemplateDetailCreated: true,
        loading: false,
        dashboardTemplateDetails: [
          ...state.dashboardTemplateDetails,
          action.payload.data,
        ],
      };

    case ADD_DASHBOARDTEMPLATEDETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_DASHBOARDTEMPLATEDETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isDashboardTemplateDetailDeleted: true,
        dashboardTemplateDetails: state.dashboardTemplateDetails.filter(
          (dashboardTemplateDetail) =>
            dashboardTemplateDetail.id.toString() !==
            action.payload.id.toString()
        ),
      };

    case DELETE_DASHBOARDTEMPLATEDETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_DASHBOARDTEMPLATEDETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isDashboardTemplateDetailCreated: true,
        dashboardTemplateDetails: state.dashboardTemplateDetails.map(
          (dashboardTemplateDetail) =>
            dashboardTemplateDetail.id.toString() ===
            action.payload.data.id.toString()
              ? { ...dashboardTemplateDetail, ...action.payload.data }
              : dashboardTemplateDetail
        ),
      };

    case UPDATE_DASHBOARDTEMPLATEDETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DASHBOARDTEMPLATEDETAIL_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default DashboardTemplateDetail;
