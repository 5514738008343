import {
  GET_DOWNLOADAPP,
  GET_DOWNLOADAPP_SUCCESS,
  GET_DOWNLOADAPP_FAIL,
  DELETE_DOWNLOADAPP,
  DELETE_DOWNLOADAPP_SUCCESS,
  DELETE_DOWNLOADAPP_FAIL,
  UPDATE_DOWNLOADAPP,
  UPDATE_DOWNLOADAPP_SUCCESS,
  UPDATE_DOWNLOADAPP_FAIL,
  ADD_NEW_DOWNLOADAPP,
  ADD_DOWNLOADAPP_SUCCESS,
  ADD_DOWNLOADAPP_FAIL,
  DOWNLOADAPP_LOADING,
  DOWNLOADAPP_RESET,
} from "./actionType";

export const resetDownloadAppState = (actionType) => ({
  type: DOWNLOADAPP_RESET,
  payload: { actionType },
});

export const getDownloadAppSuccess = (actionType, data) => ({
  type: GET_DOWNLOADAPP_SUCCESS,
  payload: { actionType, data },
});

export const getDownloadAppFail = (actionType, error) => ({
  type: GET_DOWNLOADAPP_FAIL,
  payload: { actionType, error },
});

export const getDownloadApp = (data) => ({
  type: GET_DOWNLOADAPP,
  payload: data,
});

export const getDownloadAppLoading = () => ({
  type: DOWNLOADAPP_LOADING,
});

export const deleteDownloadApp = (data) => ({
  type: DELETE_DOWNLOADAPP,
  payload: data,
});

export const deleteDownloadAppSuccess = (data) => ({
  type: DELETE_DOWNLOADAPP_SUCCESS,
  payload: data,
});

export const deleteDownloadAppFail = (error) => ({
  type: DELETE_DOWNLOADAPP_FAIL,
  payload: error,
});

export const updateDownloadApp = (data) => ({
  type: UPDATE_DOWNLOADAPP,
  payload: data,
});

export const updateDownloadAppFail = (error) => ({
  type: UPDATE_DOWNLOADAPP_FAIL,
  payload: error,
});

export const updateDownloadAppSuccess = (data) => ({
  type: UPDATE_DOWNLOADAPP_SUCCESS,
  payload: data,
});

export const addNewDownloadApp = (data) => ({
  type: ADD_NEW_DOWNLOADAPP,
  payload: data,
});

export const addDownloadAppSuccess = (data) => ({
  type: ADD_DOWNLOADAPP_SUCCESS,
  payload: data,
});

export const addDownloadAppFail = (error) => ({
  type: ADD_DOWNLOADAPP_FAIL,
  payload: error,
});
