import {
  GET_UNIT,
  GET_DEPTSTRUCTURE,
  GET_UNIT_SUCCESS,
  GET_UNIT_FAIL,
  DELETE_UNIT,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAIL,
  UPDATE_UNIT,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAIL,
  ADD_NEW_UNIT,
  ADD_UNIT_SUCCESS,
  ADD_UNIT_FAIL,
  UNIT_LOADING,
  UNIT_RESET
} from "./actionType";

export const resetUnitState = (actionType) => ({
  type: UNIT_RESET,
  payload: { actionType },
});

export const getUnitSuccess = (actionType, data) => ({
  type: GET_UNIT_SUCCESS,
  payload: { actionType, data },
});

export const getUnitFail = (actionType, error) => ({
  type: GET_UNIT_FAIL,
  payload: { actionType, error },
});

export const getUnit = (data, path) => ({
  type: GET_UNIT,
  payload: data, path
});

export const getDeptStructure = (data, path) => ({
  type: GET_DEPTSTRUCTURE,
  payload: data, path
});

export const getUnitLoading = () => ({
  type: UNIT_LOADING
});

export const deleteUnit = data => ({
  type: DELETE_UNIT,
  payload: data,
});

export const deleteUnitSuccess = data => ({
  type: DELETE_UNIT_SUCCESS,
  payload: data,
});

export const deleteUnitFail = error => ({
  type: DELETE_UNIT_FAIL,
  payload: error,
});

export const updateUnit = data => ({
  type: UPDATE_UNIT,
  payload: data,
});

export const updateUnitFail = error => ({
  type: UPDATE_UNIT_FAIL,
  payload: error,
});

export const updateUnitSuccess = data => ({
  type: UPDATE_UNIT_SUCCESS,
  payload: data,
});

export const addNewUnit = data => ({
  type: ADD_NEW_UNIT,
  payload: data,
});

export const addUnitSuccess = data => ({
  type: ADD_UNIT_SUCCESS,
  payload: data,
});

export const addUnitFail = error => ({
  type: ADD_UNIT_FAIL,
  payload: error,
});