import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useCallback, Fragment, useRef } from "react";
import { Button, Card, CardBody, CardHeader, Col, Input, Container, Row, Modal, ModalHeader, ModalBody, Form, Spinner, AccordionItem, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, AccordionHeader, AccordionBody, UncontrolledAccordion, Label, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import "moment-timezone";
import { extendMoment } from "moment-range";
import { isEmpty } from "lodash";
import { getClient, resetClientState } from "../../store/client/action";
import { getNotification, resetNotificationState } from "../../store/notification/action";
import { getHistory, getHistoryPublic, resetHistoryState } from "../../store/history/action";
import { getParameter, resetParameterState } from "../../store/parameter/action";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import { Link } from "react-router-dom";

import { GoogleMap, useLoadScript, Marker, InfoWindow, Polyline, MarkerClusterer } from "@react-google-maps/api";

import ReactHtmlParser from "react-html-parser";

import { MdOutlineWifi, MdOutlineWifiOff } from "react-icons/md";
import { BsDeviceSsdFill } from "react-icons/bs";

import dummyImg from "../../assets/images/iot_device.png";
import Flatpickr from "react-flatpickr";
import { api } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import socketIO from "../../helpers/socket";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import AlertWidget from "../../Components/Common/AlertWidget";
import TaskDeviceComponent from "../../Components/Common/TaskDeviceComponent";
import { getDevice, getDeviceOne, resetDeviceState } from "../../store/device/action";
import CardParamsComponent from "../../Components/Common/CardParamsComponent";
import StatusWidget from "../../Components/Common/StatusWidget";
import ParamsHistoryComponent from "../../Components/Common/ParamsHistoryComponent";
import StatisticComponent from "../../Components/Common/StatisticComponent";

import { APIProvider, Map } from "@vis.gl/react-google-maps";
import PoiMarkers from "../../Components/Common/PoiMarkers";
import GoogleComponent from "./GoogleComponent";
import { resetCompanyState } from "../../store/company/action";

const dummyData = {
  status: "success",
  data: [
    {
      datestring: "2024-06-06",
      data: [
        { timestamp: "2024-06-06T09:27:22.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 58, REG20005: 46, REG20006: 58 },
        { timestamp: "2024-06-06T09:27:22.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 58, REG20005: 46, REG20006: 58 },
        { timestamp: "2024-06-06T09:27:42.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 58, REG20005: 46, REG20006: 58 },
        { timestamp: "2024-06-06T09:27:42.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 58, REG20005: 46, REG20006: 58 },
        { timestamp: "2024-06-06T09:27:42.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 58, REG20005: 46, REG20006: 58 },
        { timestamp: "2024-06-06T09:27:42.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 58, REG20005: 46, REG20006: 58 },
        { timestamp: "2024-06-06T09:28:02.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 58, REG20005: 46, REG20006: 57 },
        { timestamp: "2024-06-06T09:28:02.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 58, REG20005: 46, REG20006: 57 },
        { timestamp: "2024-06-06T09:28:02.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 58, REG20005: 46, REG20006: 57 },
        { timestamp: "2024-06-06T09:28:02.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 58, REG20005: 46, REG20006: 57 },
        { timestamp: "2024-06-06T09:28:22.000Z", REG20001: 85, REG20002: 0, REG20003: 55, REG20004: 57, REG20005: 46, REG20006: 57 },
        { timestamp: "2024-06-06T09:28:22.000Z", REG20001: 85, REG20002: 0, REG20003: 55, REG20004: 57, REG20005: 46, REG20006: 57 },
        { timestamp: "2024-06-06T09:28:22.000Z", REG20001: 85, REG20002: 0, REG20003: 55, REG20004: 57, REG20005: 46, REG20006: 57 },
        { timestamp: "2024-06-06T09:28:22.000Z", REG20001: 85, REG20002: 0, REG20003: 55, REG20004: 57, REG20005: 46, REG20006: 57 },
        { timestamp: "2024-06-06T09:28:42.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 57, REG20005: 46, REG20006: 57 },
        { timestamp: "2024-06-06T09:42:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:42:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:43:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:44:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:45:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 60 },
        { timestamp: "2024-06-06T09:51:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:52:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:53:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:53:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:53:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:53:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:54:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:55:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 61, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:56:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:57:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:58:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T09:59:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:00:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:01:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:02:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:03:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:04:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:05:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:06:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:07:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:07:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:07:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:07:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:07:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:07:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:07:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:07:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 59 },
        { timestamp: "2024-06-06T10:08:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:08:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:08:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:08:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:08:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:08:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:08:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:08:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:08:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:08:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 60, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:09:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:10:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:11:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:12:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:12:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:12:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:12:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:12:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:12:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:12:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:12:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 59, REG20005: 0, REG20006: 58 },
        { timestamp: "2024-06-06T10:12:43.000Z", REG20003: 52 },
        { timestamp: "2024-06-06T10:12:43.000Z", REG20005: 48 },
        { timestamp: "2024-06-06T10:12:44.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 59, REG20005: 48, REG20006: 57 },
        { timestamp: "2024-06-06T10:12:44.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 59, REG20005: 48, REG20006: 57 },
        { timestamp: "2024-06-06T10:12:44.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 59, REG20005: 48, REG20006: 57 },
        { timestamp: "2024-06-06T10:12:44.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 59, REG20005: 48, REG20006: 57 },
        { timestamp: "2024-06-06T10:13:04.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 58, REG20005: 48, REG20006: 57 },
        { timestamp: "2024-06-06T10:13:04.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 58, REG20005: 48, REG20006: 57 },
        { timestamp: "2024-06-06T10:13:04.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 58, REG20005: 48, REG20006: 57 },
        { timestamp: "2024-06-06T10:13:04.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 58, REG20005: 48, REG20006: 57 },
        { timestamp: "2024-06-06T10:13:24.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 57, REG20005: 48, REG20006: 56 },
        { timestamp: "2024-06-06T10:13:24.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 57, REG20005: 48, REG20006: 56 },
        { timestamp: "2024-06-06T10:13:24.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 57, REG20005: 48, REG20006: 56 },
        { timestamp: "2024-06-06T10:13:24.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 57, REG20005: 48, REG20006: 56 },
        { timestamp: "2024-06-06T10:13:44.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 56 },
        { timestamp: "2024-06-06T10:13:44.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 56 },
        { timestamp: "2024-06-06T10:13:44.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 56 },
        { timestamp: "2024-06-06T10:13:44.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 56 },
        { timestamp: "2024-06-06T10:14:04.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:04.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:04.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:04.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:24.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:24.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:24.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:24.000Z", REG20001: 63, REG20002: 0, REG20003: 52, REG20004: 56, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:44.000Z", REG20001: 64, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:44.000Z", REG20001: 64, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:44.000Z", REG20001: 64, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:14:44.000Z", REG20001: 64, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:15:04.000Z", REG20001: 62, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:15:04.000Z", REG20001: 62, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:15:04.000Z", REG20001: 62, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:15:04.000Z", REG20001: 62, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 48, REG20006: 55 },
        { timestamp: "2024-06-06T10:15:24.000Z", REG20001: 76, REG20002: 0, REG20003: 54, REG20004: 55, REG20005: 50, REG20006: 54 },
        { timestamp: "2024-06-06T10:15:24.000Z", REG20001: 76, REG20002: 0, REG20003: 54, REG20004: 55, REG20005: 50, REG20006: 54 },
        { timestamp: "2024-06-06T10:15:24.000Z", REG20001: 76, REG20002: 0, REG20003: 54, REG20004: 55, REG20005: 50, REG20006: 54 },
        { timestamp: "2024-06-06T10:15:24.000Z", REG20001: 76, REG20002: 0, REG20003: 54, REG20004: 55, REG20005: 50, REG20006: 54 },
        { timestamp: "2024-06-06T10:15:44.000Z", REG20001: 62, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 50, REG20006: 54 },
        { timestamp: "2024-06-06T10:15:44.000Z", REG20001: 62, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 50, REG20006: 54 },
        { timestamp: "2024-06-06T10:15:44.000Z", REG20001: 62, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 50, REG20006: 54 },
        { timestamp: "2024-06-06T10:15:44.000Z", REG20001: 62, REG20002: 0, REG20003: 52, REG20004: 55, REG20005: 50, REG20006: 54 },
        { timestamp: "2024-06-06T10:16:04.000Z", REG20001: 92, REG20002: 0, REG20003: 57, REG20004: 55, REG20005: 52, REG20006: 55 },
        { timestamp: "2024-06-06T10:16:04.000Z", REG20001: 92, REG20002: 0, REG20003: 57, REG20004: 55, REG20005: 52, REG20006: 55 },
        { timestamp: "2024-06-06T10:16:04.000Z", REG20001: 92, REG20002: 0, REG20003: 57, REG20004: 55, REG20005: 52, REG20006: 55 },
        { timestamp: "2024-06-06T10:16:04.000Z", REG20001: 92, REG20002: 0, REG20003: 57, REG20004: 55, REG20005: 52, REG20006: 55 },
        { timestamp: "2024-06-06T10:16:24.000Z", REG20001: 92, REG20002: 0, REG20003: 57, REG20004: 55, REG20005: 52, REG20006: 55 },
        { timestamp: "2024-06-06T10:16:24.000Z", REG20001: 92, REG20002: 0, REG20003: 57, REG20004: 55, REG20005: 52, REG20006: 55 },
        { timestamp: "2024-06-06T10:16:24.000Z", REG20001: 92, REG20002: 0, REG20003: 57, REG20004: 55, REG20005: 52, REG20006: 55 },
        { timestamp: "2024-06-06T10:16:24.000Z", REG20001: 92, REG20002: 0, REG20003: 57, REG20004: 55, REG20005: 52, REG20006: 55 },
        { timestamp: "2024-06-06T10:16:44.000Z", REG20001: 103, REG20002: 0, REG20003: 59, REG20004: 56, REG20005: 53, REG20006: 56 },
        { timestamp: "2024-06-06T10:16:44.000Z", REG20001: 103, REG20002: 0, REG20003: 59, REG20004: 56, REG20005: 53, REG20006: 56 },
        { timestamp: "2024-06-06T10:16:44.000Z", REG20001: 103, REG20002: 0, REG20003: 59, REG20004: 56, REG20005: 53, REG20006: 56 },
        { timestamp: "2024-06-06T10:16:44.000Z", REG20001: 103, REG20002: 0, REG20003: 59, REG20004: 56, REG20005: 53, REG20006: 56 },
        { timestamp: "2024-06-06T10:17:04.000Z", REG20001: 107, REG20002: 0, REG20003: 60, REG20004: 57, REG20005: 55, REG20006: 57 },
        { timestamp: "2024-06-06T10:17:04.000Z", REG20001: 107, REG20002: 0, REG20003: 60, REG20004: 57, REG20005: 55, REG20006: 57 },
        { timestamp: "2024-06-06T10:17:04.000Z", REG20001: 107, REG20002: 0, REG20003: 60, REG20004: 57, REG20005: 55, REG20006: 57 },
        { timestamp: "2024-06-06T10:17:04.000Z", REG20001: 107, REG20002: 0, REG20003: 60, REG20004: 57, REG20005: 55, REG20006: 57 },
        { timestamp: "2024-06-06T10:17:24.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 58, REG20005: 57, REG20006: 58 },
        { timestamp: "2024-06-06T10:17:24.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 58, REG20005: 57, REG20006: 58 },
        { timestamp: "2024-06-06T10:17:24.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 58, REG20005: 57, REG20006: 58 },
        { timestamp: "2024-06-06T10:17:24.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 58, REG20005: 57, REG20006: 58 },
        { timestamp: "2024-06-06T10:17:44.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 60, REG20005: 57, REG20006: 59 },
        { timestamp: "2024-06-06T10:17:44.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 60, REG20005: 57, REG20006: 59 },
        { timestamp: "2024-06-06T10:17:44.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 60, REG20005: 57, REG20006: 59 },
        { timestamp: "2024-06-06T10:17:44.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 60, REG20005: 57, REG20006: 59 },
        { timestamp: "2024-06-06T10:18:04.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 62, REG20005: 57, REG20006: 60 },
        { timestamp: "2024-06-06T10:18:04.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 62, REG20005: 57, REG20006: 60 },
        { timestamp: "2024-06-06T10:18:04.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 62, REG20005: 57, REG20006: 60 },
        { timestamp: "2024-06-06T10:18:04.000Z", REG20001: 118, REG20002: 0, REG20003: 61, REG20004: 62, REG20005: 57, REG20006: 60 },
        { timestamp: "2024-06-06T10:18:24.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 63, REG20005: 56, REG20006: 62 },
        { timestamp: "2024-06-06T10:18:24.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 63, REG20005: 56, REG20006: 62 },
        { timestamp: "2024-06-06T10:18:24.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 63, REG20005: 56, REG20006: 62 },
        { timestamp: "2024-06-06T10:18:24.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 63, REG20005: 56, REG20006: 62 },
        { timestamp: "2024-06-06T10:18:44.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 65, REG20005: 56, REG20006: 63 },
        { timestamp: "2024-06-06T10:18:44.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 65, REG20005: 56, REG20006: 63 },
        { timestamp: "2024-06-06T10:18:44.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 65, REG20005: 56, REG20006: 63 },
        { timestamp: "2024-06-06T10:18:44.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 65, REG20005: 56, REG20006: 63 },
        { timestamp: "2024-06-06T10:19:04.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 66, REG20005: 56, REG20006: 64 },
        { timestamp: "2024-06-06T10:19:04.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 66, REG20005: 56, REG20006: 64 },
        { timestamp: "2024-06-06T10:19:04.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 66, REG20005: 56, REG20006: 64 },
        { timestamp: "2024-06-06T10:19:04.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 66, REG20005: 56, REG20006: 64 },
        { timestamp: "2024-06-06T10:19:24.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 67, REG20005: 56, REG20006: 65 },
        { timestamp: "2024-06-06T10:19:24.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 67, REG20005: 56, REG20006: 65 },
        { timestamp: "2024-06-06T10:19:24.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 67, REG20005: 56, REG20006: 65 },
        { timestamp: "2024-06-06T10:19:24.000Z", REG20001: 119, REG20002: 0, REG20003: 61, REG20004: 67, REG20005: 56, REG20006: 65 },
        { timestamp: "2024-06-06T10:19:44.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 68, REG20005: 56, REG20006: 66 },
        { timestamp: "2024-06-06T10:19:44.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 68, REG20005: 56, REG20006: 66 },
        { timestamp: "2024-06-06T10:19:44.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 68, REG20005: 56, REG20006: 66 },
        { timestamp: "2024-06-06T10:20:04.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 69, REG20005: 56, REG20006: 67 },
        { timestamp: "2024-06-06T10:20:04.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 69, REG20005: 56, REG20006: 67 },
        { timestamp: "2024-06-06T10:20:04.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 69, REG20005: 56, REG20006: 67 },
        { timestamp: "2024-06-06T10:20:04.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 69, REG20005: 56, REG20006: 67 },
        { timestamp: "2024-06-06T10:20:04.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 69, REG20005: 56, REG20006: 67 },
        { timestamp: "2024-06-06T10:20:24.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 70, REG20005: 56, REG20006: 68 },
        { timestamp: "2024-06-06T10:20:24.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 70, REG20005: 56, REG20006: 68 },
        { timestamp: "2024-06-06T10:20:24.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 70, REG20005: 56, REG20006: 68 },
        { timestamp: "2024-06-06T10:20:24.000Z", REG20001: 119, REG20002: 0, REG20003: 60, REG20004: 70, REG20005: 56, REG20006: 68 },
        { timestamp: "2024-06-06T10:20:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 71, REG20005: 56, REG20006: 69 },
        { timestamp: "2024-06-06T10:20:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 71, REG20005: 56, REG20006: 69 },
        { timestamp: "2024-06-06T10:20:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 71, REG20005: 56, REG20006: 69 },
        { timestamp: "2024-06-06T10:20:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 71, REG20005: 56, REG20006: 69 },
        { timestamp: "2024-06-06T10:21:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 72, REG20005: 56, REG20006: 70 },
        { timestamp: "2024-06-06T10:21:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 72, REG20005: 56, REG20006: 70 },
        { timestamp: "2024-06-06T10:21:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 72, REG20005: 56, REG20006: 70 },
        { timestamp: "2024-06-06T10:21:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 72, REG20005: 56, REG20006: 70 },
        { timestamp: "2024-06-06T10:21:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 72, REG20005: 56, REG20006: 70 },
        { timestamp: "2024-06-06T10:21:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 72, REG20005: 56, REG20006: 70 },
        { timestamp: "2024-06-06T10:21:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 72, REG20005: 56, REG20006: 70 },
        { timestamp: "2024-06-06T10:21:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 72, REG20005: 56, REG20006: 70 },
        { timestamp: "2024-06-06T10:21:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 73, REG20005: 56, REG20006: 71 },
        { timestamp: "2024-06-06T10:21:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 73, REG20005: 56, REG20006: 71 },
        { timestamp: "2024-06-06T10:21:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 73, REG20005: 56, REG20006: 71 },
        { timestamp: "2024-06-06T10:21:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 73, REG20005: 56, REG20006: 71 },
        { timestamp: "2024-06-06T10:22:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 71 },
        { timestamp: "2024-06-06T10:22:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 71 },
        { timestamp: "2024-06-06T10:22:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 71 },
        { timestamp: "2024-06-06T10:22:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 71 },
        { timestamp: "2024-06-06T10:22:25.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 72 },
        { timestamp: "2024-06-06T10:22:25.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 72 },
        { timestamp: "2024-06-06T10:22:25.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 72 },
        { timestamp: "2024-06-06T10:22:25.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 72 },
        { timestamp: "2024-06-06T10:22:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 72 },
        { timestamp: "2024-06-06T10:22:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 72 },
        { timestamp: "2024-06-06T10:22:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 72 },
        { timestamp: "2024-06-06T10:22:44.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 72 },
        { timestamp: "2024-06-06T10:23:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:04.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 74, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:25.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 75, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:25.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 75, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:25.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 75, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:25.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 75, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:23:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 56, REG20006: 73 },
        { timestamp: "2024-06-06T10:26:05.000Z", REG20001: 119, REG20002: 0, REG20003: 59, REG20004: 76, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T10:26:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T10:26:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T10:26:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T10:26:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T10:26:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:26:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:26:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:26:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:05.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:05.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:05.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:05.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:45.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:45.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:45.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:27:45.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:28:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:28:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:28:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:28:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T10:28:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:28:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:28:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:28:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:28:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:28:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:28:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:28:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:29:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:29:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:29:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:29:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:29:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:29:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:29:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:29:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T10:29:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T10:29:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T10:29:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T10:29:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:05.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:05.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:05.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:05.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:25.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:25.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:25.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:25.000Z", REG20001: 119, REG20002: 0, REG20003: 58, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:30:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 78 },
        { timestamp: "2024-06-06T10:31:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:31:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:32:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:32:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:32:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:32:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T10:32:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:32:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:32:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:32:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:32:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:32:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:32:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:32:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 80, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:33:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 80 },
        { timestamp: "2024-06-06T10:34:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:34:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:35:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:36:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T10:37:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:37:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:38:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:39:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:40:45.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:05.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:25.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:41:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:42:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T10:43:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:43:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:44:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:45:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:46:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:47:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:48:45.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:49:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:50:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:51:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:51:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:51:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:51:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T10:51:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:51:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:51:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:51:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:51:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:51:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:51:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:51:45.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:52:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:52:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:52:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:52:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:52:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:52:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:52:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:52:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:52:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:25.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:53:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:05.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:25.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:46.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:46.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:46.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:54:46.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:05.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:05.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:05.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:05.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:55:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:05.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:56:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:57:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:58:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T10:59:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:00:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:06.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:06.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:06.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:06.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:01:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:02:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:46.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:46.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:46.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:03:46.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:04:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:06.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:06.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:06.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:06.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:26.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:05:46.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:06:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:07:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:08:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:09:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:10:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:11:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:12:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:13:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:14:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T11:15:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:15:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:16:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:17:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:18:46.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:26.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:19:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:26.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:20:46.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:07.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:07.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:07.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:07.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:21:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:06.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:22:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:06.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:23:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:06.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:24:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:25:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:27.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:47.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:47.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:47.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:26:47.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:07.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:27:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:28:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:29:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:27.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:30:47.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:07.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:31:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:32:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:33:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:34:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:35:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:36:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:37:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:37:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:37:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:37:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:37:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:37:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:37:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:37:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:37:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:37:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:38:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:27.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:39:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:40:47.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:07.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:27.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 83, REG20005: 53, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:47.000Z", REG20001: 87, REG20002: 0, REG20003: 52, REG20004: 83, REG20005: 48, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:47.000Z", REG20001: 87, REG20002: 0, REG20003: 52, REG20004: 83, REG20005: 48, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:47.000Z", REG20001: 87, REG20002: 0, REG20003: 52, REG20004: 83, REG20005: 48, REG20006: 85 },
        { timestamp: "2024-06-06T11:41:47.000Z", REG20001: 87, REG20002: 0, REG20003: 52, REG20004: 83, REG20005: 48, REG20006: 85 },
        { timestamp: "2024-06-06T11:42:07.000Z", REG20001: 87, REG20002: 0, REG20003: 52, REG20004: 83, REG20005: 48, REG20006: 85 },
        { timestamp: "2024-06-06T11:42:07.000Z", REG20001: 87, REG20002: 0, REG20003: 52, REG20004: 83, REG20005: 48, REG20006: 85 },
        { timestamp: "2024-06-06T11:42:07.000Z", REG20001: 87, REG20002: 0, REG20003: 52, REG20004: 83, REG20005: 48, REG20006: 85 },
        { timestamp: "2024-06-06T11:42:07.000Z", REG20001: 87, REG20002: 0, REG20003: 52, REG20004: 83, REG20005: 48, REG20006: 85 },
        { timestamp: "2024-06-06T11:42:27.000Z", REG20001: 62, REG20002: 0, REG20003: 47, REG20004: 83, REG20005: 30, REG20006: 84 },
        { timestamp: "2024-06-06T11:42:27.000Z", REG20001: 62, REG20002: 0, REG20003: 47, REG20004: 83, REG20005: 30, REG20006: 84 },
        { timestamp: "2024-06-06T11:42:27.000Z", REG20001: 62, REG20002: 0, REG20003: 47, REG20004: 83, REG20005: 30, REG20006: 84 },
        { timestamp: "2024-06-06T11:42:27.000Z", REG20001: 62, REG20002: 0, REG20003: 47, REG20004: 83, REG20005: 30, REG20006: 84 },
        { timestamp: "2024-06-06T11:42:47.000Z", REG20001: 63, REG20002: 0, REG20003: 48, REG20004: 82, REG20005: 31, REG20006: 84 },
        { timestamp: "2024-06-06T11:42:47.000Z", REG20001: 63, REG20002: 0, REG20003: 48, REG20004: 82, REG20005: 31, REG20006: 84 },
        { timestamp: "2024-06-06T11:42:47.000Z", REG20001: 63, REG20002: 0, REG20003: 48, REG20004: 82, REG20005: 31, REG20006: 84 },
        { timestamp: "2024-06-06T11:42:47.000Z", REG20001: 63, REG20002: 0, REG20003: 48, REG20004: 82, REG20005: 31, REG20006: 84 },
        { timestamp: "2024-06-06T11:43:07.000Z", REG20001: 63, REG20002: 0, REG20003: 48, REG20004: 81, REG20005: 31, REG20006: 83 },
        { timestamp: "2024-06-06T11:43:07.000Z", REG20001: 63, REG20002: 0, REG20003: 48, REG20004: 81, REG20005: 31, REG20006: 83 },
        { timestamp: "2024-06-06T11:43:07.000Z", REG20001: 63, REG20002: 0, REG20003: 48, REG20004: 81, REG20005: 31, REG20006: 83 },
        { timestamp: "2024-06-06T11:43:07.000Z", REG20001: 63, REG20002: 0, REG20003: 48, REG20004: 81, REG20005: 31, REG20006: 83 },
        { timestamp: "2024-06-06T11:43:27.000Z", REG20001: 62, REG20002: 0, REG20003: 48, REG20004: 80, REG20005: 37, REG20006: 82 },
        { timestamp: "2024-06-06T11:43:27.000Z", REG20001: 62, REG20002: 0, REG20003: 48, REG20004: 80, REG20005: 37, REG20006: 82 },
        { timestamp: "2024-06-06T11:43:27.000Z", REG20001: 62, REG20002: 0, REG20003: 48, REG20004: 80, REG20005: 37, REG20006: 82 },
        { timestamp: "2024-06-06T11:43:27.000Z", REG20001: 62, REG20002: 0, REG20003: 48, REG20004: 80, REG20005: 37, REG20006: 82 },
        { timestamp: "2024-06-06T11:43:47.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 52, REG20006: 80 },
        { timestamp: "2024-06-06T11:43:47.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 52, REG20006: 80 },
        { timestamp: "2024-06-06T11:43:47.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 52, REG20006: 80 },
        { timestamp: "2024-06-06T11:43:47.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 52, REG20006: 80 },
        { timestamp: "2024-06-06T11:44:07.000Z", REG20001: 60, REG20002: 0, REG20003: 48, REG20004: 78, REG20005: 49, REG20006: 80 },
        { timestamp: "2024-06-06T11:44:07.000Z", REG20001: 60, REG20002: 0, REG20003: 48, REG20004: 78, REG20005: 49, REG20006: 80 },
        { timestamp: "2024-06-06T11:44:07.000Z", REG20001: 60, REG20002: 0, REG20003: 48, REG20004: 78, REG20005: 49, REG20006: 80 },
        { timestamp: "2024-06-06T11:44:07.000Z", REG20001: 60, REG20002: 0, REG20003: 48, REG20004: 78, REG20005: 49, REG20006: 80 },
        { timestamp: "2024-06-06T11:44:28.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 77, REG20005: 31, REG20006: 80 },
        { timestamp: "2024-06-06T11:44:28.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 77, REG20005: 31, REG20006: 80 },
        { timestamp: "2024-06-06T11:44:28.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 77, REG20005: 31, REG20006: 80 },
        { timestamp: "2024-06-06T11:44:28.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 77, REG20005: 31, REG20006: 80 },
        { timestamp: "2024-06-06T11:44:47.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 76, REG20005: 49, REG20006: 79 },
        { timestamp: "2024-06-06T11:44:47.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 76, REG20005: 49, REG20006: 79 },
        { timestamp: "2024-06-06T11:44:47.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 76, REG20005: 49, REG20006: 79 },
        { timestamp: "2024-06-06T11:44:47.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 76, REG20005: 49, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:07.000Z", REG20001: 93, REG20002: 0, REG20003: 53, REG20004: 75, REG20005: 49, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:07.000Z", REG20001: 93, REG20002: 0, REG20003: 53, REG20004: 75, REG20005: 49, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:07.000Z", REG20001: 93, REG20002: 0, REG20003: 53, REG20004: 75, REG20005: 49, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:07.000Z", REG20001: 93, REG20002: 0, REG20003: 53, REG20004: 75, REG20005: 49, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:28.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 31, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:28.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 31, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:28.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 31, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:28.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 31, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:48.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 32, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:48.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 32, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:48.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 32, REG20006: 79 },
        { timestamp: "2024-06-06T11:45:48.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 32, REG20006: 79 },
        { timestamp: "2024-06-06T11:46:07.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 78 },
        { timestamp: "2024-06-06T11:46:07.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 78 },
        { timestamp: "2024-06-06T11:46:07.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 78 },
        { timestamp: "2024-06-06T11:46:07.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 78 },
        { timestamp: "2024-06-06T11:46:28.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 78 },
        { timestamp: "2024-06-06T11:46:28.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 78 },
        { timestamp: "2024-06-06T11:46:28.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 78 },
        { timestamp: "2024-06-06T11:46:28.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 78 },
        { timestamp: "2024-06-06T11:46:48.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 32, REG20006: 77 },
        { timestamp: "2024-06-06T11:46:48.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 32, REG20006: 77 },
        { timestamp: "2024-06-06T11:46:48.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 32, REG20006: 77 },
        { timestamp: "2024-06-06T11:46:48.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 32, REG20006: 77 },
        { timestamp: "2024-06-06T11:47:07.000Z", REG20001: 1, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:07.000Z", REG20001: 1, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:07.000Z", REG20001: 1, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:07.000Z", REG20001: 1, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:10.000Z", REG20003: 0 },
        { timestamp: "2024-06-06T11:47:10.000Z", REG20005: 0 },
        { timestamp: "2024-06-06T11:47:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:47:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:48:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:48:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:48:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:48:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:48:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:48:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:48:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:48:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T11:48:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T11:48:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T11:48:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T11:48:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T11:49:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:49:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T11:50:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T11:50:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T11:50:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T11:50:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T11:50:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T11:50:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T11:50:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T11:50:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T11:50:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T11:50:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T11:50:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T11:50:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T11:51:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T11:51:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T11:51:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T11:51:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T11:51:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:51:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:51:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:51:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:51:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:51:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:51:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:51:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:52:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:53:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:53:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:53:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:53:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T11:53:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:53:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:53:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:53:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:53:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:53:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:53:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:53:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:54:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:55:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:56:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:57:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:58:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T11:59:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 78, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:00:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:01:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:01:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:01:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:01:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 82 },
        { timestamp: "2024-06-06T12:01:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:01:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:01:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:01:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:01:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:01:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:01:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:01:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:02:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:03:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:04:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:05:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:06:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:07:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:07:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:07:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:07:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:07:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:07:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:07:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:07:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 81 },
        { timestamp: "2024-06-06T12:07:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:07:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:07:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:07:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 77, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:08:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:09:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:10:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:11:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:12:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:13:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:13:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:13:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:13:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:13:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:13:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:13:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:13:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 80 },
        { timestamp: "2024-06-06T12:13:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:13:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:13:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:13:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:14:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 76, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:15:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:16:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:17:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:18:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:19:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:20:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:20:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:20:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:20:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 79 },
        { timestamp: "2024-06-06T12:20:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:20:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:20:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:20:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:20:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:20:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:20:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:20:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:21:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:22:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:23:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:24:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:24:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:24:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:24:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:24:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:24:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:24:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:24:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:24:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:24:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:25:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:26:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:27:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:27:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:27:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:27:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 78 },
        { timestamp: "2024-06-06T12:27:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:27:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:27:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:27:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:27:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:27:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:27:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:27:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:28:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:29:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:30:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:31:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:32:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:33:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:34:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:35:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:35:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:35:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:35:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T12:35:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:35:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:35:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:35:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:35:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:35:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:35:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:35:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:36:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:37:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:38:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:39:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:40:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:41:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:42:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T12:43:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:43:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:44:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:45:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:45:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:45:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:45:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:45:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:45:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:45:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:45:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T12:45:46.000Z", REG20003: 51 },
        { timestamp: "2024-06-06T12:45:47.000Z", REG20005: 40 },
        { timestamp: "2024-06-06T12:45:50.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 71, REG20005: 40, REG20006: 74 },
        { timestamp: "2024-06-06T12:45:50.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 71, REG20005: 40, REG20006: 74 },
        { timestamp: "2024-06-06T12:45:50.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 71, REG20005: 40, REG20006: 74 },
        { timestamp: "2024-06-06T12:45:50.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 71, REG20005: 40, REG20006: 74 },
        { timestamp: "2024-06-06T12:46:11.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 70, REG20005: 38, REG20006: 72 },
        { timestamp: "2024-06-06T12:46:11.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 70, REG20005: 38, REG20006: 72 },
        { timestamp: "2024-06-06T12:46:11.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 70, REG20005: 38, REG20006: 72 },
        { timestamp: "2024-06-06T12:46:11.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 70, REG20005: 38, REG20006: 72 },
        { timestamp: "2024-06-06T12:46:30.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 68, REG20005: 38, REG20006: 70 },
        { timestamp: "2024-06-06T12:46:30.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 68, REG20005: 38, REG20006: 70 },
        { timestamp: "2024-06-06T12:46:30.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 68, REG20005: 38, REG20006: 70 },
        { timestamp: "2024-06-06T12:46:30.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 68, REG20005: 38, REG20006: 70 },
        { timestamp: "2024-06-06T12:46:51.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 67, REG20005: 35, REG20006: 69 },
        { timestamp: "2024-06-06T12:46:51.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 67, REG20005: 35, REG20006: 69 },
        { timestamp: "2024-06-06T12:46:51.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 67, REG20005: 35, REG20006: 69 },
        { timestamp: "2024-06-06T12:46:51.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 67, REG20005: 35, REG20006: 69 },
        { timestamp: "2024-06-06T12:47:11.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 66, REG20005: 34, REG20006: 68 },
        { timestamp: "2024-06-06T12:47:11.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 66, REG20005: 34, REG20006: 68 },
        { timestamp: "2024-06-06T12:47:11.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 66, REG20005: 34, REG20006: 68 },
        { timestamp: "2024-06-06T12:47:11.000Z", REG20001: 63, REG20002: 0, REG20003: 51, REG20004: 66, REG20005: 34, REG20006: 68 },
        { timestamp: "2024-06-06T12:47:30.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 65, REG20005: 49, REG20006: 67 },
        { timestamp: "2024-06-06T12:47:30.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 65, REG20005: 49, REG20006: 67 },
        { timestamp: "2024-06-06T12:47:30.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 65, REG20005: 49, REG20006: 67 },
        { timestamp: "2024-06-06T12:47:30.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 65, REG20005: 49, REG20006: 67 },
        { timestamp: "2024-06-06T12:47:51.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 65, REG20005: 49, REG20006: 67 },
        { timestamp: "2024-06-06T12:47:51.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 65, REG20005: 49, REG20006: 67 },
        { timestamp: "2024-06-06T12:47:51.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 65, REG20005: 49, REG20006: 67 },
        { timestamp: "2024-06-06T12:47:51.000Z", REG20001: 62, REG20002: 0, REG20003: 51, REG20004: 65, REG20005: 49, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:11.000Z", REG20001: 83, REG20002: 0, REG20003: 53, REG20004: 64, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:11.000Z", REG20001: 83, REG20002: 0, REG20003: 53, REG20004: 64, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:11.000Z", REG20001: 83, REG20002: 0, REG20003: 53, REG20004: 64, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:11.000Z", REG20001: 83, REG20002: 0, REG20003: 53, REG20004: 64, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:31.000Z", REG20001: 91, REG20002: 0, REG20003: 54, REG20004: 63, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:31.000Z", REG20001: 91, REG20002: 0, REG20003: 54, REG20004: 63, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:31.000Z", REG20001: 91, REG20002: 0, REG20003: 54, REG20004: 63, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:31.000Z", REG20001: 91, REG20002: 0, REG20003: 54, REG20004: 63, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:51.000Z", REG20001: 92, REG20002: 0, REG20003: 54, REG20004: 63, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:51.000Z", REG20001: 92, REG20002: 0, REG20003: 54, REG20004: 63, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:51.000Z", REG20001: 92, REG20002: 0, REG20003: 54, REG20004: 63, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:48:51.000Z", REG20001: 92, REG20002: 0, REG20003: 54, REG20004: 63, REG20005: 36, REG20006: 67 },
        { timestamp: "2024-06-06T12:49:11.000Z", REG20001: 95, REG20002: 0, REG20003: 55, REG20004: 64, REG20005: 36, REG20006: 66 },
        { timestamp: "2024-06-06T12:49:11.000Z", REG20001: 95, REG20002: 0, REG20003: 55, REG20004: 64, REG20005: 36, REG20006: 66 },
        { timestamp: "2024-06-06T12:49:11.000Z", REG20001: 95, REG20002: 0, REG20003: 55, REG20004: 64, REG20005: 36, REG20006: 66 },
        { timestamp: "2024-06-06T12:49:11.000Z", REG20001: 95, REG20002: 0, REG20003: 55, REG20004: 64, REG20005: 36, REG20006: 66 },
        { timestamp: "2024-06-06T12:49:31.000Z", REG20001: 95, REG20002: 0, REG20003: 55, REG20004: 64, REG20005: 36, REG20006: 66 },
        { timestamp: "2024-06-06T12:49:31.000Z", REG20001: 95, REG20002: 0, REG20003: 55, REG20004: 64, REG20005: 36, REG20006: 66 },
        { timestamp: "2024-06-06T12:49:31.000Z", REG20001: 95, REG20002: 0, REG20003: 55, REG20004: 64, REG20005: 36, REG20006: 66 },
        { timestamp: "2024-06-06T12:49:31.000Z", REG20001: 95, REG20002: 0, REG20003: 55, REG20004: 64, REG20005: 36, REG20006: 66 },
        { timestamp: "2024-06-06T12:49:51.000Z", REG20001: 60, REG20002: 0, REG20003: 50, REG20004: 65, REG20005: 51, REG20006: 65 },
        { timestamp: "2024-06-06T12:49:51.000Z", REG20001: 60, REG20002: 0, REG20003: 50, REG20004: 65, REG20005: 51, REG20006: 65 },
        { timestamp: "2024-06-06T12:49:51.000Z", REG20001: 60, REG20002: 0, REG20003: 50, REG20004: 65, REG20005: 51, REG20006: 65 },
        { timestamp: "2024-06-06T12:49:51.000Z", REG20001: 60, REG20002: 0, REG20003: 50, REG20004: 65, REG20005: 51, REG20006: 65 },
        { timestamp: "2024-06-06T12:50:11.000Z", REG20001: 108, REG20002: 0, REG20003: 57, REG20004: 66, REG20005: 51, REG20006: 65 },
        { timestamp: "2024-06-06T12:50:11.000Z", REG20001: 108, REG20002: 0, REG20003: 57, REG20004: 66, REG20005: 51, REG20006: 65 },
        { timestamp: "2024-06-06T12:50:11.000Z", REG20001: 108, REG20002: 0, REG20003: 57, REG20004: 66, REG20005: 51, REG20006: 65 },
        { timestamp: "2024-06-06T12:50:11.000Z", REG20001: 108, REG20002: 0, REG20003: 57, REG20004: 66, REG20005: 51, REG20006: 65 },
        { timestamp: "2024-06-06T12:50:31.000Z", REG20001: 109, REG20002: 0, REG20003: 57, REG20004: 66, REG20005: 52, REG20006: 65 },
        { timestamp: "2024-06-06T12:50:31.000Z", REG20001: 109, REG20002: 0, REG20003: 57, REG20004: 66, REG20005: 52, REG20006: 65 },
        { timestamp: "2024-06-06T12:50:31.000Z", REG20001: 109, REG20002: 0, REG20003: 57, REG20004: 66, REG20005: 52, REG20006: 65 },
        { timestamp: "2024-06-06T12:50:31.000Z", REG20001: 109, REG20002: 0, REG20003: 57, REG20004: 66, REG20005: 52, REG20006: 65 },
        { timestamp: "2024-06-06T12:50:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 67, REG20005: 52, REG20006: 66 },
        { timestamp: "2024-06-06T12:50:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 67, REG20005: 52, REG20006: 66 },
        { timestamp: "2024-06-06T12:50:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 67, REG20005: 52, REG20006: 66 },
        { timestamp: "2024-06-06T12:50:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 67, REG20005: 52, REG20006: 66 },
        { timestamp: "2024-06-06T12:51:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 68, REG20005: 55, REG20006: 67 },
        { timestamp: "2024-06-06T12:51:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 68, REG20005: 55, REG20006: 67 },
        { timestamp: "2024-06-06T12:51:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 68, REG20005: 55, REG20006: 67 },
        { timestamp: "2024-06-06T12:51:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 68, REG20005: 55, REG20006: 67 },
        { timestamp: "2024-06-06T12:51:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 69, REG20005: 55, REG20006: 68 },
        { timestamp: "2024-06-06T12:51:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 69, REG20005: 55, REG20006: 68 },
        { timestamp: "2024-06-06T12:51:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 69, REG20005: 55, REG20006: 68 },
        { timestamp: "2024-06-06T12:51:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 69, REG20005: 55, REG20006: 68 },
        { timestamp: "2024-06-06T12:51:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 70, REG20005: 55, REG20006: 69 },
        { timestamp: "2024-06-06T12:51:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 70, REG20005: 55, REG20006: 69 },
        { timestamp: "2024-06-06T12:51:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 70, REG20005: 55, REG20006: 69 },
        { timestamp: "2024-06-06T12:51:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 70, REG20005: 55, REG20006: 69 },
        { timestamp: "2024-06-06T12:52:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 71, REG20005: 55, REG20006: 70 },
        { timestamp: "2024-06-06T12:52:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 71, REG20005: 55, REG20006: 70 },
        { timestamp: "2024-06-06T12:52:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 71, REG20005: 55, REG20006: 70 },
        { timestamp: "2024-06-06T12:52:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 71, REG20005: 55, REG20006: 70 },
        { timestamp: "2024-06-06T12:52:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 72, REG20005: 55, REG20006: 71 },
        { timestamp: "2024-06-06T12:52:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 72, REG20005: 55, REG20006: 71 },
        { timestamp: "2024-06-06T12:52:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 72, REG20005: 55, REG20006: 71 },
        { timestamp: "2024-06-06T12:52:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 72, REG20005: 55, REG20006: 71 },
        { timestamp: "2024-06-06T12:52:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 73, REG20005: 55, REG20006: 72 },
        { timestamp: "2024-06-06T12:52:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 73, REG20005: 55, REG20006: 72 },
        { timestamp: "2024-06-06T12:52:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 73, REG20005: 55, REG20006: 72 },
        { timestamp: "2024-06-06T12:52:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 73, REG20005: 55, REG20006: 72 },
        { timestamp: "2024-06-06T12:53:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 73, REG20005: 55, REG20006: 73 },
        { timestamp: "2024-06-06T12:53:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 73, REG20005: 55, REG20006: 73 },
        { timestamp: "2024-06-06T12:53:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 73, REG20005: 55, REG20006: 73 },
        { timestamp: "2024-06-06T12:53:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 73, REG20005: 55, REG20006: 73 },
        { timestamp: "2024-06-06T12:53:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 74 },
        { timestamp: "2024-06-06T12:53:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 74 },
        { timestamp: "2024-06-06T12:53:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 74 },
        { timestamp: "2024-06-06T12:53:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 74 },
        { timestamp: "2024-06-06T12:53:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 74 },
        { timestamp: "2024-06-06T12:53:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 74 },
        { timestamp: "2024-06-06T12:53:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 74 },
        { timestamp: "2024-06-06T12:53:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 74 },
        { timestamp: "2024-06-06T12:54:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T12:54:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T12:54:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T12:54:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 74, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T12:54:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T12:54:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T12:54:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T12:54:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 75 },
        { timestamp: "2024-06-06T12:54:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T12:54:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T12:54:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T12:54:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T12:55:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T12:55:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T12:55:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T12:55:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 75, REG20005: 55, REG20006: 76 },
        { timestamp: "2024-06-06T12:55:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T12:55:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T12:55:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T12:55:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T12:55:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T12:55:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T12:55:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T12:55:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 77 },
        { timestamp: "2024-06-06T12:56:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 76, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:56:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 78 },
        { timestamp: "2024-06-06T12:57:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 54, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 77, REG20005: 55, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 79 },
        { timestamp: "2024-06-06T12:57:51.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 79 },
        { timestamp: "2024-06-06T12:58:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:11.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 78, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 78, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 78, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 78, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 78, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:58:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 80 },
        { timestamp: "2024-06-06T12:59:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T12:59:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 54, REG20006: 81 },
        { timestamp: "2024-06-06T13:00:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 81 },
        { timestamp: "2024-06-06T13:00:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 81 },
        { timestamp: "2024-06-06T13:00:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 81 },
        { timestamp: "2024-06-06T13:00:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 81 },
        { timestamp: "2024-06-06T13:00:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:00:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:00:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:00:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:00:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:00:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:00:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:00:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 79, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:01:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 82 },
        { timestamp: "2024-06-06T13:02:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T13:02:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T13:02:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T13:02:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 82 },
        { timestamp: "2024-06-06T13:02:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:02:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:02:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:02:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:02:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:02:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:02:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:02:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:03:51.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:04:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:04:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:04:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:04:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 54, REG20006: 83 },
        { timestamp: "2024-06-06T13:04:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:04:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:04:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:04:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:04:52.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 80, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:05:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:05:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:05:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:05:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 55, REG20006: 83 },
        { timestamp: "2024-06-06T13:05:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:05:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:05:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:05:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:05:52.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:05:52.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:05:52.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:05:52.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:11.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:31.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:52.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:52.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:52.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:06:52.000Z", REG20001: 118, REG20002: 0, REG20003: 59, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:31.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:07:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 84 },
        { timestamp: "2024-06-06T13:11:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:10:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:10:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:10:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:10:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:10:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:10:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:10:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:10:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:11:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:11:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:11:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:11:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:11:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:11:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:11:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:12:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:13:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:14:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:14:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:14:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:14:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:14:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:14:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:14:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:14:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:15:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 85 },
        { timestamp: "2024-06-06T13:20:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:20:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:17:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:18:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:19:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:20:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:20:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:20:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:20:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:20:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:20:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:20:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:20:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:21:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:22:52.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:12.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:23:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:12.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:24:52.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:27:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:27:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:27:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:27:32.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:30:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:30:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:30:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:30:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:30:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:30:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:30:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:30:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:30:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:30:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:31:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:31:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:31:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:31:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 86 },
        { timestamp: "2024-06-06T13:31:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:31:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:31:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:31:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:31:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:31:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:31:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:31:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:32:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:33:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 81, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:36:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:36:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:36:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:36:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:36:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:37:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:38:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:39:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:40:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:41:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:13.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:42:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:43:53.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:33.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:44:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:33.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:13.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:13.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:13.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:45:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:33.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:33.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:33.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:33.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:46:53.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:13.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:53.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:53.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:53.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:47:53.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:13.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:13.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:13.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:13.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:53.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:53.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:53.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:48:53.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:49:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:50:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:51:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:52:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:53:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:34.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:54:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:55:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:56:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:14.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:14.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:14.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:14.000Z", REG20001: 118, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:34.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:34.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:34.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:34.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:54.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:54.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:54.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:57:54.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:14.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:14.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:14.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:14.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:58:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T13:59:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:00:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:01:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 53, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:02:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:03:54.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:34.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:04:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:05:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:05:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:05:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:05:14.000Z", REG20001: 118, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:07:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:07:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:07:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:07:54.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:08:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:08:14.000Z", REG20001: 117, REG20002: 0, REG20003: 57, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:08:35.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:08:35.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:08:35.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:08:35.000Z", REG20001: 117, REG20002: 0, REG20003: 58, REG20004: 82, REG20005: 54, REG20006: 87 },
        { timestamp: "2024-06-06T14:08:54.000Z", REG20001: 96, REG20002: 0, REG20003: 54, REG20004: 82, REG20005: 44, REG20006: 87 },
        { timestamp: "2024-06-06T14:08:54.000Z", REG20001: 96, REG20002: 0, REG20003: 54, REG20004: 82, REG20005: 44, REG20006: 87 },
        { timestamp: "2024-06-06T14:08:54.000Z", REG20001: 96, REG20002: 0, REG20003: 54, REG20004: 82, REG20005: 44, REG20006: 87 },
        { timestamp: "2024-06-06T14:08:54.000Z", REG20001: 96, REG20002: 0, REG20003: 54, REG20004: 82, REG20005: 44, REG20006: 87 },
        { timestamp: "2024-06-06T14:09:15.000Z", REG20001: 95, REG20002: 0, REG20003: 54, REG20004: 82, REG20005: 35, REG20006: 88 },
        { timestamp: "2024-06-06T14:09:15.000Z", REG20001: 95, REG20002: 0, REG20003: 54, REG20004: 82, REG20005: 35, REG20006: 88 },
        { timestamp: "2024-06-06T14:09:15.000Z", REG20001: 95, REG20002: 0, REG20003: 54, REG20004: 82, REG20005: 35, REG20006: 88 },
        { timestamp: "2024-06-06T14:09:15.000Z", REG20001: 95, REG20002: 0, REG20003: 54, REG20004: 82, REG20005: 35, REG20006: 88 },
        { timestamp: "2024-06-06T14:09:35.000Z", REG20001: 62, REG20002: 0, REG20003: 47, REG20004: 82, REG20005: 30, REG20006: 87 },
        { timestamp: "2024-06-06T14:09:35.000Z", REG20001: 62, REG20002: 0, REG20003: 47, REG20004: 82, REG20005: 30, REG20006: 87 },
        { timestamp: "2024-06-06T14:09:35.000Z", REG20001: 62, REG20002: 0, REG20003: 47, REG20004: 82, REG20005: 30, REG20006: 87 },
        { timestamp: "2024-06-06T14:09:35.000Z", REG20001: 62, REG20002: 0, REG20003: 47, REG20004: 82, REG20005: 30, REG20006: 87 },
        { timestamp: "2024-06-06T14:09:55.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 81, REG20005: 30, REG20006: 86 },
        { timestamp: "2024-06-06T14:09:55.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 81, REG20005: 30, REG20006: 86 },
        { timestamp: "2024-06-06T14:09:55.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 81, REG20005: 30, REG20006: 86 },
        { timestamp: "2024-06-06T14:09:55.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 81, REG20005: 30, REG20006: 86 },
        { timestamp: "2024-06-06T14:10:15.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 80, REG20005: 30, REG20006: 85 },
        { timestamp: "2024-06-06T14:10:15.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 80, REG20005: 30, REG20006: 85 },
        { timestamp: "2024-06-06T14:10:15.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 80, REG20005: 30, REG20006: 85 },
        { timestamp: "2024-06-06T14:10:15.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 80, REG20005: 30, REG20006: 85 },
        { timestamp: "2024-06-06T14:10:35.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 30, REG20006: 84 },
        { timestamp: "2024-06-06T14:10:35.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 30, REG20006: 84 },
        { timestamp: "2024-06-06T14:10:35.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 30, REG20006: 84 },
        { timestamp: "2024-06-06T14:10:35.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 30, REG20006: 84 },
        { timestamp: "2024-06-06T14:10:55.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 31, REG20006: 83 },
        { timestamp: "2024-06-06T14:10:55.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 31, REG20006: 83 },
        { timestamp: "2024-06-06T14:10:55.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 31, REG20006: 83 },
        { timestamp: "2024-06-06T14:10:55.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 79, REG20005: 31, REG20006: 83 },
        { timestamp: "2024-06-06T14:11:15.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 78, REG20005: 31, REG20006: 82 },
        { timestamp: "2024-06-06T14:11:15.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 78, REG20005: 31, REG20006: 82 },
        { timestamp: "2024-06-06T14:11:15.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 78, REG20005: 31, REG20006: 82 },
        { timestamp: "2024-06-06T14:11:15.000Z", REG20001: 61, REG20002: 0, REG20003: 48, REG20004: 78, REG20005: 31, REG20006: 82 },
        { timestamp: "2024-06-06T14:11:35.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 77, REG20005: 30, REG20006: 81 },
        { timestamp: "2024-06-06T14:11:35.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 77, REG20005: 30, REG20006: 81 },
        { timestamp: "2024-06-06T14:11:35.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 77, REG20005: 30, REG20006: 81 },
        { timestamp: "2024-06-06T14:11:35.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 77, REG20005: 30, REG20006: 81 },
        { timestamp: "2024-06-06T14:11:55.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 76, REG20005: 31, REG20006: 80 },
        { timestamp: "2024-06-06T14:11:55.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 76, REG20005: 31, REG20006: 80 },
        { timestamp: "2024-06-06T14:11:55.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 76, REG20005: 31, REG20006: 80 },
        { timestamp: "2024-06-06T14:11:55.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 76, REG20005: 31, REG20006: 80 },
        { timestamp: "2024-06-06T14:12:15.000Z", REG20001: 61, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 52, REG20006: 79 },
        { timestamp: "2024-06-06T14:12:15.000Z", REG20001: 61, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 52, REG20006: 79 },
        { timestamp: "2024-06-06T14:12:15.000Z", REG20001: 61, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 52, REG20006: 79 },
        { timestamp: "2024-06-06T14:12:15.000Z", REG20001: 61, REG20002: 0, REG20003: 49, REG20004: 75, REG20005: 52, REG20006: 79 },
        { timestamp: "2024-06-06T14:12:35.000Z", REG20001: 104, REG20002: 0, REG20003: 56, REG20004: 75, REG20005: 31, REG20006: 78 },
        { timestamp: "2024-06-06T14:12:35.000Z", REG20001: 104, REG20002: 0, REG20003: 56, REG20004: 75, REG20005: 31, REG20006: 78 },
        { timestamp: "2024-06-06T14:12:35.000Z", REG20001: 104, REG20002: 0, REG20003: 56, REG20004: 75, REG20005: 31, REG20006: 78 },
        { timestamp: "2024-06-06T14:12:35.000Z", REG20001: 104, REG20002: 0, REG20003: 56, REG20004: 75, REG20005: 31, REG20006: 78 },
        { timestamp: "2024-06-06T14:14:35.000Z", REG20001: 64, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 76 },
        { timestamp: "2024-06-06T14:14:35.000Z", REG20001: 64, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 76 },
        { timestamp: "2024-06-06T14:14:35.000Z", REG20001: 64, REG20002: 0, REG20003: 49, REG20004: 74, REG20005: 32, REG20006: 76 },
        { timestamp: "2024-06-06T14:14:55.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 30, REG20006: 76 },
        { timestamp: "2024-06-06T14:14:55.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 30, REG20006: 76 },
        { timestamp: "2024-06-06T14:14:55.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 30, REG20006: 76 },
        { timestamp: "2024-06-06T14:14:55.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 30, REG20006: 76 },
        { timestamp: "2024-06-06T14:15:15.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 32, REG20006: 75 },
        { timestamp: "2024-06-06T14:15:15.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 32, REG20006: 75 },
        { timestamp: "2024-06-06T14:15:15.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 32, REG20006: 75 },
        { timestamp: "2024-06-06T14:15:15.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 73, REG20005: 32, REG20006: 75 },
        { timestamp: "2024-06-06T14:15:35.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 72, REG20005: 33, REG20006: 75 },
        { timestamp: "2024-06-06T14:15:35.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 72, REG20005: 33, REG20006: 75 },
        { timestamp: "2024-06-06T14:15:35.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 72, REG20005: 33, REG20006: 75 },
        { timestamp: "2024-06-06T14:15:35.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 72, REG20005: 33, REG20006: 75 },
        { timestamp: "2024-06-06T14:15:55.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 72, REG20005: 33, REG20006: 74 },
        { timestamp: "2024-06-06T14:15:55.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 72, REG20005: 33, REG20006: 74 },
        { timestamp: "2024-06-06T14:15:55.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 72, REG20005: 33, REG20006: 74 },
        { timestamp: "2024-06-06T14:15:55.000Z", REG20001: 63, REG20002: 0, REG20003: 49, REG20004: 72, REG20005: 33, REG20006: 74 },
        { timestamp: "2024-06-06T14:16:15.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 71, REG20005: 33, REG20006: 73 },
        { timestamp: "2024-06-06T14:16:15.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 71, REG20005: 33, REG20006: 73 },
        { timestamp: "2024-06-06T14:16:15.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 71, REG20005: 33, REG20006: 73 },
        { timestamp: "2024-06-06T14:16:15.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 71, REG20005: 33, REG20006: 73 },
        { timestamp: "2024-06-06T14:16:35.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 71, REG20005: 33, REG20006: 73 },
        { timestamp: "2024-06-06T14:16:35.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 71, REG20005: 33, REG20006: 73 },
        { timestamp: "2024-06-06T14:16:35.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 71, REG20005: 33, REG20006: 73 },
        { timestamp: "2024-06-06T14:16:35.000Z", REG20001: 62, REG20002: 0, REG20003: 49, REG20004: 71, REG20005: 33, REG20006: 73 },
        { timestamp: "2024-06-06T14:16:55.000Z", REG20001: 35, REG20002: 0, REG20003: 1, REG20004: 70, REG20005: 6, REG20006: 72 },
        { timestamp: "2024-06-06T14:16:55.000Z", REG20001: 35, REG20002: 0, REG20003: 1, REG20004: 70, REG20005: 6, REG20006: 72 },
        { timestamp: "2024-06-06T14:16:55.000Z", REG20001: 35, REG20002: 0, REG20003: 1, REG20004: 70, REG20005: 6, REG20006: 72 },
        { timestamp: "2024-06-06T14:16:55.000Z", REG20001: 35, REG20002: 0, REG20003: 1, REG20004: 70, REG20005: 6, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:12.000Z", REG20003: 0 },
        { timestamp: "2024-06-06T14:17:13.000Z", REG20005: 0 },
        { timestamp: "2024-06-06T14:17:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:17:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:18:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:18:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:18:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:18:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:18:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:18:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:18:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:18:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T14:18:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T14:18:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T14:18:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T14:18:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T14:19:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T14:19:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T14:19:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T14:19:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T14:19:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:19:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:19:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:19:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:19:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:19:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:19:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:19:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:20:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:20:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:20:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:20:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:20:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:20:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:20:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:20:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:20:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:20:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:20:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:20:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:21:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:21:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:21:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:21:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:21:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:21:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:21:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:21:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:21:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:21:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:21:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:21:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:22:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:23:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:24:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:24:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:25:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:15.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:26:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:27:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:27:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:27:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:27:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:27:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:27:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:27:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:27:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:27:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:27:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:35.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:28:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:29:55.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:30:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:31:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:32:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:33:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:33:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:33:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:33:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 77 },
        { timestamp: "2024-06-06T14:33:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:33:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:33:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:33:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:33:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:33:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:33:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:33:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 75, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:34:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:35:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:36:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:37:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:38:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:39:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:40:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:41:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 74, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:42:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 76 },
        { timestamp: "2024-06-06T14:43:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:43:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:44:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:45:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:46:56.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:47:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:48:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:16.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:36.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:49:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:50:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:51:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:51:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:51:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:51:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 73, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:51:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:51:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:51:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:51:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 75 },
        { timestamp: "2024-06-06T14:51:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:51:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:51:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:51:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:52:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:53:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:54:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:55:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:56:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:57:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:58:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T14:59:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T15:00:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T15:00:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T15:00:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T15:00:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T15:00:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T15:00:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T15:00:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T15:00:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 72, REG20005: 0, REG20006: 74 },
        { timestamp: "2024-06-06T15:00:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:00:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:00:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:00:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:01:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:02:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:03:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:04:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:05:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:06:57.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:07:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:37.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:08:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:17.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:09:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 71, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:10:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:11:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:11:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:11:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:11:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 73 },
        { timestamp: "2024-06-06T15:11:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:11:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:11:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:11:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:11:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:11:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:11:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:11:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:12:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:13:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:14:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:15:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:16:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:17:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:18:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:19:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:20:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:21:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:21:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:21:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:21:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 72 },
        { timestamp: "2024-06-06T15:21:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:21:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:21:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:21:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:21:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:21:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:21:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:21:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:22:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 70, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:23:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:24:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:25:58.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:26:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:38.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:27:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:18.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:28:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:29:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:30:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:31:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:32:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:32:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:32:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:32:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 71 },
        { timestamp: "2024-06-06T15:32:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:32:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:32:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:32:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:32:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:32:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:32:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:32:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:33:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:34:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 69, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:35:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:36:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:37:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:38:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:39:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:40:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:41:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:42:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:43:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:43:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:43:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:43:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:43:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:43:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:43:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:43:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 70 },
        { timestamp: "2024-06-06T15:43:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:43:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:43:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:43:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:44:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:45:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:46:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:47:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 68, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:48:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:49:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:49:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:49:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:49:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:49:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:49:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:49:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:49:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:50:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:51:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:52:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:53:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:54:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:55:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 69 },
        { timestamp: "2024-06-06T15:56:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:56:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:57:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:58:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T15:59:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T16:00:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T16:00:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T16:00:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T16:00:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T16:00:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T16:00:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T16:00:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T16:00:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 67, REG20005: 0, REG20006: 68 },
        { timestamp: "2024-06-06T16:02:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:02:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:02:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:02:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:02:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:02:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:02.000Z", REG20003: 0 },
        { timestamp: "2024-06-06T16:03:03.000Z", REG20005: 0 },
        { timestamp: "2024-06-06T16:03:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:03:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:04:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:05:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:06:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:06:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:06:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:06:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:06:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:06:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:06:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:06:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:19.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:07:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:39.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:08:59.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:09:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:09:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:09:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:09:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:09:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:09:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:09:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:09:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:10:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:11:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:12:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:12:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:12:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:12:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:12:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:12:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:12:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:12:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:12:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:12:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:14:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:13:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:15:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:15:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:15:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:15:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:15:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:15:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:16:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:17:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:18:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:19:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:20:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:21:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:22:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:23:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:20.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:24:40.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:25:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:00.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:26:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:27:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:28:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:29:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:30:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:31:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:32:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:33:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:34:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:35:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:36:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:37:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:38:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:39:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:40:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:41:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:42:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:43:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:44:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:45:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:46:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:47:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:48:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:49:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:50:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:01.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:51:41.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:21.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:52:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:53:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:54:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:55:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:56:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:57:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:58:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T16:59:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:00:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:01:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:02:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:03:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:04:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:05:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:06:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:07:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:08:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:09:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:10:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:11:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:12:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:13:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:14:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:15:42.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:02.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:22.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:16:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:17:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:18:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:19:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:20:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:21:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:22:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:22:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:22:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:22:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:22:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:22:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:22:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:22:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:22:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:22:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:23:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:24:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:25:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:26:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:26:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:26:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:26:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:26:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:26:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:26:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:26:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:26:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:26:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:27:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:28:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:29:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:30:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:31:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:32:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:32:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:32:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:32:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:32:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:32:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:32:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:32:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:32:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:32:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:33:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:34:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:34:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:34:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:34:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:34:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:34:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:34:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:34:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:34:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:34:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:35:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:36:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:37:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:38:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:39:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:39:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:39:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:39:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:39:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:39:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:39:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:39:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:39:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:39:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:40:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:03.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:23.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:41:43.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:42:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:43:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:44:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:45:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:46:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:47:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:48:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:49:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:50:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:51:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:52:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:53:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:54:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:55:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:56:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:57:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:58:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T17:59:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:00:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:01:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:02:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:03:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:04:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:05:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:06:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:07:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:08:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:09:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:10:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:11:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:12:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:13:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:14:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:15:44.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:16:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:17:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:24.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:18:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:04.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:19:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:20:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:21:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:22:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:23:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:24:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:25:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:26:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:27:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:28:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:29:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:30:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:31:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:32:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:33:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:34:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:35:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:36:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:37:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:38:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:39:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:40:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:41:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:25.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:42:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:43:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:44:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:05.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:45:45.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:46:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:47:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:48:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:49:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:50:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:51:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:52:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:53:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:54:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:55:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:56:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:57:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:58:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T18:59:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:00:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:01:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:02:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:03:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:04:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:05:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:06:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:07:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:07:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:07:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:07:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:07:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:07:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:07:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:07:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:08:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:08:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:09:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:06.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:26.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:10:46.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:11:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:12:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:13:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:14:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:15:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:16:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:17:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:18:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:19:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:20:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:21:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:22:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:23:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:24:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:25:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:26:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:27:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:28:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:29:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:30:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:31:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:32:47.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:27.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:33:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:34:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:07.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:35:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:36:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:37:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:38:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:39:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:40:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:41:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:42:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:43:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:44:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:45:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:46:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:47:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:48:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:49:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:50:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:51:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:52:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:53:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:54:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:55:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:56:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:57:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:58:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:28.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T19:59:48.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:00:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:08.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:01:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:02:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:03:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:04:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:05:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:06:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:07:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:08:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:09:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:10:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:11:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:12:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:13:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:14:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:15:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:16:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:17:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:18:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:19:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:20:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:21:49.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:29.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:22:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:23:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:09.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:24:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:25:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:26:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:27:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:28:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:29:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:30:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:31:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:32:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:33:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:34:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:35:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:36:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:37:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:38:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:39:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:40:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:41:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:42:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:30.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:43:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:44:50.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:45:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:10.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:46:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:47:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:48:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:11.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:31.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
        { timestamp: "2024-06-06T20:49:51.000Z", REG20001: 0, REG20002: 0, REG20003: 0, REG20004: 0, REG20005: 0, REG20006: 0 },
      ],
      gps: [
        { speed: "0.0", course: "346.4", altitude: "10.1", satellites: "10", hdop: "0.7", lat: "0045.843211", lon: "12722.600623", latD: "N", lonD: "E", latitude: 0.7640535166666667, longitude: 127.37667705, timestamp: "2024-06-06T09:27:22.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.1", satellites: "10", hdop: "0.7", lat: "0045.843211", lon: "12722.600623", latD: "N", lonD: "E", latitude: 0.7640535166666667, longitude: 127.37667705, timestamp: "2024-06-06T09:27:22.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.1", satellites: "10", hdop: "0.7", lat: "0045.843211", lon: "12722.600623", latD: "N", lonD: "E", latitude: 0.7640535166666667, longitude: 127.37667705, timestamp: "2024-06-06T09:27:22.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.1", satellites: "10", hdop: "0.7", lat: "0045.843211", lon: "12722.600623", latD: "N", lonD: "E", latitude: 0.7640535166666667, longitude: 127.37667705, timestamp: "2024-06-06T09:27:22.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.1", satellites: "10", hdop: "0.7", lat: "0045.843211", lon: "12722.600623", latD: "N", lonD: "E", latitude: 0.7640535166666667, longitude: 127.37667705, timestamp: "2024-06-06T09:27:22.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.9", satellites: "10", hdop: "0.7", lat: "0045.843185", lon: "12722.600586", latD: "N", lonD: "E", latitude: 0.7640530833333333, longitude: 127.37667643333333, timestamp: "2024-06-06T09:27:42.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.9", satellites: "10", hdop: "0.7", lat: "0045.843156", lon: "12722.600558", latD: "N", lonD: "E", latitude: 0.7640526, longitude: 127.37667596666667, timestamp: "2024-06-06T09:28:02.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.9", satellites: "10", hdop: "0.7", lat: "0045.843185", lon: "12722.600586", latD: "N", lonD: "E", latitude: 0.7640530833333333, longitude: 127.37667643333333, timestamp: "2024-06-06T09:27:42.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.9", satellites: "10", hdop: "0.7", lat: "0045.843185", lon: "12722.600586", latD: "N", lonD: "E", latitude: 0.7640530833333333, longitude: 127.37667643333333, timestamp: "2024-06-06T09:27:42.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.9", satellites: "10", hdop: "0.7", lat: "0045.843185", lon: "12722.600586", latD: "N", lonD: "E", latitude: 0.7640530833333333, longitude: 127.37667643333333, timestamp: "2024-06-06T09:27:42.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.9", satellites: "10", hdop: "0.7", lat: "0045.843156", lon: "12722.600558", latD: "N", lonD: "E", latitude: 0.7640526, longitude: 127.37667596666667, timestamp: "2024-06-06T09:28:02.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.9", satellites: "10", hdop: "0.7", lat: "0045.843156", lon: "12722.600558", latD: "N", lonD: "E", latitude: 0.7640526, longitude: 127.37667596666667, timestamp: "2024-06-06T09:28:02.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.9", satellites: "10", hdop: "0.7", lat: "0045.843156", lon: "12722.600558", latD: "N", lonD: "E", latitude: 0.7640526, longitude: 127.37667596666667, timestamp: "2024-06-06T09:28:02.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0045.843146", lon: "12722.600530", latD: "N", lonD: "E", latitude: 0.7640524333333333, longitude: 127.3766755, timestamp: "2024-06-06T09:28:22.000Z" },
        { speed: "0.0", course: "346.4", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0045.843107", lon: "12722.600442", latD: "N", lonD: "E", latitude: 0.7640517833333333, longitude: 127.37667403333333, timestamp: "2024-06-06T09:28:42.000Z" },
        { speed: "0.0", course: "32.6", altitude: "-6.1", satellites: "10", hdop: "0.6", lat: "0045.841168", lon: "12722.596125", latD: "N", lonD: "E", latitude: 0.7640194666666668, longitude: 127.37660208333334, timestamp: "2024-06-06T09:42:43.000Z" },
        { speed: "0.0", course: "32.6", altitude: "-6.1", satellites: "10", hdop: "0.6", lat: "0045.841168", lon: "12722.596125", latD: "N", lonD: "E", latitude: 0.7640194666666668, longitude: 127.37660208333334, timestamp: "2024-06-06T09:42:43.000Z" },
        { speed: "0.0", course: "32.6", altitude: "-6.1", satellites: "10", hdop: "0.6", lat: "0045.841168", lon: "12722.596125", latD: "N", lonD: "E", latitude: 0.7640194666666668, longitude: 127.37660208333334, timestamp: "2024-06-06T09:42:43.000Z" },
        { speed: "0.0", course: "32.6", altitude: "-6.7", satellites: "10", hdop: "0.7", lat: "0045.841032", lon: "12722.596275", latD: "N", lonD: "E", latitude: 0.7640172, longitude: 127.37660458333333, timestamp: "2024-06-06T09:43:03.000Z" },
        { speed: "0.0", course: "32.6", altitude: "-6.1", satellites: "10", hdop: "0.6", lat: "0045.841168", lon: "12722.596125", latD: "N", lonD: "E", latitude: 0.7640194666666668, longitude: 127.37660208333334, timestamp: "2024-06-06T09:42:43.000Z" },
        { speed: "0.0", course: "32.6", altitude: "-6.1", satellites: "10", hdop: "0.6", lat: "0045.841168", lon: "12722.596125", latD: "N", lonD: "E", latitude: 0.7640194666666668, longitude: 127.37660208333334, timestamp: "2024-06-06T09:42:43.000Z" },
        { speed: "0.0", course: "32.6", altitude: "-6.7", satellites: "10", hdop: "0.7", lat: "0045.841032", lon: "12722.596275", latD: "N", lonD: "E", latitude: 0.7640172, longitude: 127.37660458333333, timestamp: "2024-06-06T09:43:03.000Z" },
        { speed: "0.0", course: "32.6", altitude: "-6.7", satellites: "10", hdop: "0.7", lat: "0045.841032", lon: "12722.596275", latD: "N", lonD: "E", latitude: 0.7640172, longitude: 127.37660458333333, timestamp: "2024-06-06T09:43:03.000Z" },
        { speed: "0.0", course: "32.6", altitude: "-6.7", satellites: "10", hdop: "0.7", lat: "0045.841032", lon: "12722.596275", latD: "N", lonD: "E", latitude: 0.7640172, longitude: 127.37660458333333, timestamp: "2024-06-06T09:43:03.000Z" },
        { speed: "0.0", course: "37.3", altitude: "-6.8", satellites: "10", hdop: "0.6", lat: "0045.840471", lon: "12722.596946", latD: "N", lonD: "E", latitude: 0.76400785, longitude: 127.37661576666666, timestamp: "2024-06-06T09:43:23.000Z" },
        { speed: "0.0", course: "37.3", altitude: "-6.8", satellites: "10", hdop: "0.6", lat: "0045.840471", lon: "12722.596946", latD: "N", lonD: "E", latitude: 0.76400785, longitude: 127.37661576666666, timestamp: "2024-06-06T09:43:23.000Z" },
        { speed: "0.0", course: "37.3", altitude: "-6.8", satellites: "10", hdop: "0.6", lat: "0045.840471", lon: "12722.596946", latD: "N", lonD: "E", latitude: 0.76400785, longitude: 127.37661576666666, timestamp: "2024-06-06T09:43:23.000Z" },
        { speed: "0.0", course: "37.3", altitude: "-6.8", satellites: "10", hdop: "0.6", lat: "0045.840471", lon: "12722.596946", latD: "N", lonD: "E", latitude: 0.76400785, longitude: 127.37661576666666, timestamp: "2024-06-06T09:43:23.000Z" },
        { speed: "0.0", course: "256.2", altitude: "-5.1", satellites: "08", hdop: "1.0", lat: "0045.841511", lon: "12722.596982", latD: "N", lonD: "E", latitude: 0.7640251833333332, longitude: 127.37661636666667, timestamp: "2024-06-06T09:43:43.000Z" },
        { speed: "0.0", course: "256.2", altitude: "-5.1", satellites: "08", hdop: "1.0", lat: "0045.841511", lon: "12722.596982", latD: "N", lonD: "E", latitude: 0.7640251833333332, longitude: 127.37661636666667, timestamp: "2024-06-06T09:43:43.000Z" },
        { speed: "0.0", course: "256.2", altitude: "-4.8", satellites: "09", hdop: "0.7", lat: "0045.841518", lon: "12722.597138", latD: "N", lonD: "E", latitude: 0.7640253, longitude: 127.37661896666667, timestamp: "2024-06-06T09:44:03.000Z" },
        { speed: "0.0", course: "256.2", altitude: "-5.1", satellites: "08", hdop: "1.0", lat: "0045.841511", lon: "12722.596982", latD: "N", lonD: "E", latitude: 0.7640251833333332, longitude: 127.37661636666667, timestamp: "2024-06-06T09:43:43.000Z" },
        { speed: "0.0", course: "256.2", altitude: "-5.1", satellites: "08", hdop: "1.0", lat: "0045.841511", lon: "12722.596982", latD: "N", lonD: "E", latitude: 0.7640251833333332, longitude: 127.37661636666667, timestamp: "2024-06-06T09:43:43.000Z" },
        { speed: "0.0", course: "256.2", altitude: "-4.8", satellites: "09", hdop: "0.7", lat: "0045.841518", lon: "12722.597138", latD: "N", lonD: "E", latitude: 0.7640253, longitude: 127.37661896666667, timestamp: "2024-06-06T09:44:03.000Z" },
        { speed: "0.0", course: "256.2", altitude: "-4.8", satellites: "09", hdop: "0.7", lat: "0045.841518", lon: "12722.597138", latD: "N", lonD: "E", latitude: 0.7640253, longitude: 127.37661896666667, timestamp: "2024-06-06T09:44:03.000Z" },
        { speed: "0.0", course: "83.0", altitude: "-7.4", satellites: "10", hdop: "0.6", lat: "0045.842043", lon: "12722.599611", latD: "N", lonD: "E", latitude: 0.7640340499999999, longitude: 127.37666018333333, timestamp: "2024-06-06T09:44:23.000Z" },
        { speed: "0.0", course: "83.0", altitude: "-7.4", satellites: "10", hdop: "0.6", lat: "0045.842043", lon: "12722.599611", latD: "N", lonD: "E", latitude: 0.7640340499999999, longitude: 127.37666018333333, timestamp: "2024-06-06T09:44:23.000Z" },
        { speed: "0.0", course: "83.0", altitude: "-7.4", satellites: "10", hdop: "0.6", lat: "0045.842043", lon: "12722.599611", latD: "N", lonD: "E", latitude: 0.7640340499999999, longitude: 127.37666018333333, timestamp: "2024-06-06T09:44:23.000Z" },
        { speed: "0.0", course: "83.0", altitude: "-7.4", satellites: "10", hdop: "0.6", lat: "0045.842052", lon: "12722.599447", latD: "N", lonD: "E", latitude: 0.7640342, longitude: 127.37665745, timestamp: "2024-06-06T09:44:43.000Z" },
        { speed: "0.0", course: "83.0", altitude: "-7.4", satellites: "10", hdop: "0.6", lat: "0045.842043", lon: "12722.599611", latD: "N", lonD: "E", latitude: 0.7640340499999999, longitude: 127.37666018333333, timestamp: "2024-06-06T09:44:23.000Z" },
        { speed: "0.0", course: "83.0", altitude: "-7.4", satellites: "10", hdop: "0.6", lat: "0045.842043", lon: "12722.599611", latD: "N", lonD: "E", latitude: 0.7640340499999999, longitude: 127.37666018333333, timestamp: "2024-06-06T09:44:23.000Z" },
        { speed: "0.0", course: "83.0", altitude: "-7.4", satellites: "10", hdop: "0.6", lat: "0045.842052", lon: "12722.599447", latD: "N", lonD: "E", latitude: 0.7640342, longitude: 127.37665745, timestamp: "2024-06-06T09:44:43.000Z" },
        { speed: "0.0", course: "83.0", altitude: "-7.4", satellites: "10", hdop: "0.6", lat: "0045.842052", lon: "12722.599447", latD: "N", lonD: "E", latitude: 0.7640342, longitude: 127.37665745, timestamp: "2024-06-06T09:44:43.000Z" },
        { speed: "0.0", course: "98.1", altitude: "-5.9", satellites: "09", hdop: "0.7", lat: "0045.841926", lon: "12722.598668", latD: "N", lonD: "E", latitude: 0.7640321, longitude: 127.37664446666666, timestamp: "2024-06-06T09:45:03.000Z" },
        { speed: "0.0", course: "98.1", altitude: "-5.9", satellites: "09", hdop: "0.7", lat: "0045.841926", lon: "12722.598668", latD: "N", lonD: "E", latitude: 0.7640321, longitude: 127.37664446666666, timestamp: "2024-06-06T09:45:03.000Z" },
        { speed: "0.0", course: "98.1", altitude: "-5.9", satellites: "09", hdop: "0.7", lat: "0045.841926", lon: "12722.598668", latD: "N", lonD: "E", latitude: 0.7640321, longitude: 127.37664446666666, timestamp: "2024-06-06T09:45:03.000Z" },
        { speed: "0.0", course: "98.1", altitude: "-5.9", satellites: "09", hdop: "0.7", lat: "0045.841926", lon: "12722.598668", latD: "N", lonD: "E", latitude: 0.7640321, longitude: 127.37664446666666, timestamp: "2024-06-06T09:45:03.000Z" },
        { speed: "0.0", course: "98.1", altitude: "-5.9", satellites: "09", hdop: "0.7", lat: "0045.841926", lon: "12722.598668", latD: "N", lonD: "E", latitude: 0.7640321, longitude: 127.37664446666666, timestamp: "2024-06-06T09:45:03.000Z" },
        { speed: "0.0", course: "237.4", altitude: "-6.3", satellites: "10", hdop: "0.6", lat: "0045.842217", lon: "12722.598444", latD: "N", lonD: "E", latitude: 0.7640369499999999, longitude: 127.37664073333333, timestamp: "2024-06-06T09:45:23.000Z" },
        { speed: "0.0", course: "237.4", altitude: "-6.3", satellites: "10", hdop: "0.6", lat: "0045.842217", lon: "12722.598444", latD: "N", lonD: "E", latitude: 0.7640369499999999, longitude: 127.37664073333333, timestamp: "2024-06-06T09:45:23.000Z" },
        { speed: "0.0", course: "239.8", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.842083", lon: "12722.598494", latD: "N", lonD: "E", latitude: 0.7640347166666667, longitude: 127.37664156666666, timestamp: "2024-06-06T09:45:43.000Z" },
        { speed: "0.0", course: "237.4", altitude: "-6.3", satellites: "10", hdop: "0.6", lat: "0045.842217", lon: "12722.598444", latD: "N", lonD: "E", latitude: 0.7640369499999999, longitude: 127.37664073333333, timestamp: "2024-06-06T09:45:23.000Z" },
        { speed: "0.0", course: "237.4", altitude: "-6.3", satellites: "10", hdop: "0.6", lat: "0045.842217", lon: "12722.598444", latD: "N", lonD: "E", latitude: 0.7640369499999999, longitude: 127.37664073333333, timestamp: "2024-06-06T09:45:23.000Z" },
        { speed: "0.0", course: "19.8", altitude: "2.5", satellites: "11", hdop: "0.7", lat: "0045.841841", lon: "12722.597952", latD: "N", lonD: "E", latitude: 0.7640306833333333, longitude: 127.37663253333334, timestamp: "2024-06-06T09:51:43.000Z" },
        { speed: "0.0", course: "19.8", altitude: "2.5", satellites: "11", hdop: "0.7", lat: "0045.841841", lon: "12722.597952", latD: "N", lonD: "E", latitude: 0.7640306833333333, longitude: 127.37663253333334, timestamp: "2024-06-06T09:51:43.000Z" },
        { speed: "0.0", course: "19.8", altitude: "2.3", satellites: "11", hdop: "0.7", lat: "0045.841834", lon: "12722.597967", latD: "N", lonD: "E", latitude: 0.7640305666666667, longitude: 127.37663278333333, timestamp: "2024-06-06T09:52:03.000Z" },
        { speed: "0.0", course: "19.8", altitude: "2.3", satellites: "11", hdop: "0.7", lat: "0045.841834", lon: "12722.597967", latD: "N", lonD: "E", latitude: 0.7640305666666667, longitude: 127.37663278333333, timestamp: "2024-06-06T09:52:03.000Z" },
        { speed: "0.0", course: "19.8", altitude: "2.3", satellites: "11", hdop: "0.7", lat: "0045.841834", lon: "12722.597967", latD: "N", lonD: "E", latitude: 0.7640305666666667, longitude: 127.37663278333333, timestamp: "2024-06-06T09:52:03.000Z" },
        { speed: "0.0", course: "19.8", altitude: "3.9", satellites: "11", hdop: "0.7", lat: "0045.841827", lon: "12722.597972", latD: "N", lonD: "E", latitude: 0.76403045, longitude: 127.37663286666667, timestamp: "2024-06-06T09:52:23.000Z" },
        { speed: "0.0", course: "19.8", altitude: "3.9", satellites: "11", hdop: "0.7", lat: "0045.841827", lon: "12722.597972", latD: "N", lonD: "E", latitude: 0.76403045, longitude: 127.37663286666667, timestamp: "2024-06-06T09:52:23.000Z" },
        { speed: "0.0", course: "19.8", altitude: "3.9", satellites: "11", hdop: "0.7", lat: "0045.841827", lon: "12722.597972", latD: "N", lonD: "E", latitude: 0.76403045, longitude: 127.37663286666667, timestamp: "2024-06-06T09:52:23.000Z" },
        { speed: "0.0", course: "19.8", altitude: "3.9", satellites: "11", hdop: "0.7", lat: "0045.841827", lon: "12722.597972", latD: "N", lonD: "E", latitude: 0.76403045, longitude: 127.37663286666667, timestamp: "2024-06-06T09:52:23.000Z" },
        { speed: "0.0", course: "19.8", altitude: "3.9", satellites: "11", hdop: "0.7", lat: "0045.841827", lon: "12722.597972", latD: "N", lonD: "E", latitude: 0.76403045, longitude: 127.37663286666667, timestamp: "2024-06-06T09:52:23.000Z" },
        { speed: "0.0", course: "19.8", altitude: "3.9", satellites: "11", hdop: "0.7", lat: "0045.841827", lon: "12722.597972", latD: "N", lonD: "E", latitude: 0.76403045, longitude: 127.37663286666667, timestamp: "2024-06-06T09:52:23.000Z" },
        { speed: "0.0", course: "19.8", altitude: "2.4", satellites: "10", hdop: "0.7", lat: "0045.841820", lon: "12722.597967", latD: "N", lonD: "E", latitude: 0.7640303333333333, longitude: 127.37663278333333, timestamp: "2024-06-06T09:52:43.000Z" },
        { speed: "0.0", course: "19.8", altitude: "2.4", satellites: "10", hdop: "0.7", lat: "0045.841820", lon: "12722.597967", latD: "N", lonD: "E", latitude: 0.7640303333333333, longitude: 127.37663278333333, timestamp: "2024-06-06T09:52:43.000Z" },
        { speed: "0.0", course: "19.8", altitude: "2.3", satellites: "11", hdop: "0.7", lat: "0045.841816", lon: "12722.597953", latD: "N", lonD: "E", latitude: 0.7640302666666667, longitude: 127.37663255, timestamp: "2024-06-06T09:53:03.000Z" },
        { speed: "0.0", course: "19.8", altitude: "2.3", satellites: "11", hdop: "0.7", lat: "0045.841816", lon: "12722.597953", latD: "N", lonD: "E", latitude: 0.7640302666666667, longitude: 127.37663255, timestamp: "2024-06-06T09:53:03.000Z" },
        { speed: "0.0", course: "19.8", altitude: "2.3", satellites: "11", hdop: "0.7", lat: "0045.841816", lon: "12722.597953", latD: "N", lonD: "E", latitude: 0.7640302666666667, longitude: 127.37663255, timestamp: "2024-06-06T09:53:03.000Z" },
        { speed: "0.0", course: "32.7", altitude: "2.8", satellites: "11", hdop: "0.6", lat: "0045.840891", lon: "12722.598025", latD: "N", lonD: "E", latitude: 0.76401485, longitude: 127.37663375, timestamp: "2024-06-06T09:54:03.000Z" },
        { speed: "0.0", course: "32.7", altitude: "2.8", satellites: "11", hdop: "0.6", lat: "0045.840891", lon: "12722.598025", latD: "N", lonD: "E", latitude: 0.76401485, longitude: 127.37663375, timestamp: "2024-06-06T09:54:03.000Z" },
        { speed: "0.0", course: "32.7", altitude: "2.8", satellites: "11", hdop: "0.6", lat: "0045.840891", lon: "12722.598025", latD: "N", lonD: "E", latitude: 0.76401485, longitude: 127.37663375, timestamp: "2024-06-06T09:54:03.000Z" },
        { speed: "0.0", course: "32.7", altitude: "2.8", satellites: "11", hdop: "0.6", lat: "0045.840891", lon: "12722.598025", latD: "N", lonD: "E", latitude: 0.76401485, longitude: 127.37663375, timestamp: "2024-06-06T09:54:03.000Z" },
        { speed: "0.0", course: "32.7", altitude: "2.8", satellites: "11", hdop: "0.6", lat: "0045.840891", lon: "12722.598025", latD: "N", lonD: "E", latitude: 0.76401485, longitude: 127.37663375, timestamp: "2024-06-06T09:54:03.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.0", satellites: "10", hdop: "0.7", lat: "0045.841551", lon: "12722.597289", latD: "N", lonD: "E", latitude: 0.76402585, longitude: 127.37662148333334, timestamp: "2024-06-06T09:54:23.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.0", satellites: "10", hdop: "0.7", lat: "0045.841551", lon: "12722.597289", latD: "N", lonD: "E", latitude: 0.76402585, longitude: 127.37662148333334, timestamp: "2024-06-06T09:54:23.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.0", satellites: "10", hdop: "0.7", lat: "0045.841551", lon: "12722.597289", latD: "N", lonD: "E", latitude: 0.76402585, longitude: 127.37662148333334, timestamp: "2024-06-06T09:54:23.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.0", satellites: "11", hdop: "0.7", lat: "0045.841553", lon: "12722.597245", latD: "N", lonD: "E", latitude: 0.7640258833333333, longitude: 127.37662075, timestamp: "2024-06-06T09:54:43.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.0", satellites: "10", hdop: "0.7", lat: "0045.841551", lon: "12722.597289", latD: "N", lonD: "E", latitude: 0.76402585, longitude: 127.37662148333334, timestamp: "2024-06-06T09:54:23.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.0", satellites: "10", hdop: "0.7", lat: "0045.841551", lon: "12722.597289", latD: "N", lonD: "E", latitude: 0.76402585, longitude: 127.37662148333334, timestamp: "2024-06-06T09:54:23.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.0", satellites: "11", hdop: "0.7", lat: "0045.841553", lon: "12722.597245", latD: "N", lonD: "E", latitude: 0.7640258833333333, longitude: 127.37662075, timestamp: "2024-06-06T09:54:43.000Z" },
        { speed: "0.0", course: "341.2", altitude: "4.9", satellites: "11", hdop: "0.7", lat: "0045.841557", lon: "12722.597221", latD: "N", lonD: "E", latitude: 0.7640259500000001, longitude: 127.37662035, timestamp: "2024-06-06T09:55:03.000Z" },
        { speed: "0.0", course: "341.2", altitude: "4.9", satellites: "11", hdop: "0.7", lat: "0045.841557", lon: "12722.597221", latD: "N", lonD: "E", latitude: 0.7640259500000001, longitude: 127.37662035, timestamp: "2024-06-06T09:55:03.000Z" },
        { speed: "0.0", course: "341.2", altitude: "4.9", satellites: "11", hdop: "0.7", lat: "0045.841557", lon: "12722.597221", latD: "N", lonD: "E", latitude: 0.7640259500000001, longitude: 127.37662035, timestamp: "2024-06-06T09:55:03.000Z" },
        { speed: "0.0", course: "341.2", altitude: "4.9", satellites: "11", hdop: "0.7", lat: "0045.841557", lon: "12722.597221", latD: "N", lonD: "E", latitude: 0.7640259500000001, longitude: 127.37662035, timestamp: "2024-06-06T09:55:03.000Z" },
        { speed: "0.0", course: "341.2", altitude: "4.9", satellites: "11", hdop: "0.7", lat: "0045.841557", lon: "12722.597221", latD: "N", lonD: "E", latitude: 0.7640259500000001, longitude: 127.37662035, timestamp: "2024-06-06T09:55:03.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.4", satellites: "11", hdop: "0.7", lat: "0045.841593", lon: "12722.597197", latD: "N", lonD: "E", latitude: 0.7640265500000001, longitude: 127.37661995, timestamp: "2024-06-06T09:55:23.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.4", satellites: "11", hdop: "0.7", lat: "0045.841593", lon: "12722.597197", latD: "N", lonD: "E", latitude: 0.7640265500000001, longitude: 127.37661995, timestamp: "2024-06-06T09:55:23.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.4", satellites: "11", hdop: "0.7", lat: "0045.841593", lon: "12722.597197", latD: "N", lonD: "E", latitude: 0.7640265500000001, longitude: 127.37661995, timestamp: "2024-06-06T09:55:23.000Z" },
        { speed: "0.0", course: "341.2", altitude: "4.9", satellites: "10", hdop: "0.7", lat: "0045.841622", lon: "12722.597204", latD: "N", lonD: "E", latitude: 0.7640270333333333, longitude: 127.37662006666666, timestamp: "2024-06-06T09:55:43.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.4", satellites: "11", hdop: "0.7", lat: "0045.841593", lon: "12722.597197", latD: "N", lonD: "E", latitude: 0.7640265500000001, longitude: 127.37661995, timestamp: "2024-06-06T09:55:23.000Z" },
        { speed: "0.0", course: "341.2", altitude: "5.4", satellites: "11", hdop: "0.7", lat: "0045.841593", lon: "12722.597197", latD: "N", lonD: "E", latitude: 0.7640265500000001, longitude: 127.37661995, timestamp: "2024-06-06T09:55:23.000Z" },
        { speed: "0.0", course: "341.2", altitude: "4.9", satellites: "10", hdop: "0.7", lat: "0045.841622", lon: "12722.597204", latD: "N", lonD: "E", latitude: 0.7640270333333333, longitude: 127.37662006666666, timestamp: "2024-06-06T09:55:43.000Z" },
        { speed: "0.0", course: "341.2", altitude: "4.9", satellites: "10", hdop: "0.7", lat: "0045.841622", lon: "12722.597204", latD: "N", lonD: "E", latitude: 0.7640270333333333, longitude: 127.37662006666666, timestamp: "2024-06-06T09:55:43.000Z" },
        { speed: "0.0", course: "13.2", altitude: "3.2", satellites: "10", hdop: "0.7", lat: "0045.841205", lon: "12722.596545", latD: "N", lonD: "E", latitude: 0.7640200833333334, longitude: 127.37660908333334, timestamp: "2024-06-06T09:56:03.000Z" },
        { speed: "0.0", course: "13.2", altitude: "3.2", satellites: "10", hdop: "0.7", lat: "0045.841205", lon: "12722.596545", latD: "N", lonD: "E", latitude: 0.7640200833333334, longitude: 127.37660908333334, timestamp: "2024-06-06T09:56:03.000Z" },
        { speed: "0.0", course: "333.5", altitude: "1.3", satellites: "10", hdop: "0.7", lat: "0045.841058", lon: "12722.596311", latD: "N", lonD: "E", latitude: 0.7640176333333333, longitude: 127.37660518333334, timestamp: "2024-06-06T09:56:23.000Z" },
        { speed: "0.0", course: "333.5", altitude: "1.3", satellites: "10", hdop: "0.7", lat: "0045.841058", lon: "12722.596311", latD: "N", lonD: "E", latitude: 0.7640176333333333, longitude: 127.37660518333334, timestamp: "2024-06-06T09:56:23.000Z" },
        { speed: "0.0", course: "333.5", altitude: "1.3", satellites: "10", hdop: "0.7", lat: "0045.841058", lon: "12722.596311", latD: "N", lonD: "E", latitude: 0.7640176333333333, longitude: 127.37660518333334, timestamp: "2024-06-06T09:56:23.000Z" },
        { speed: "0.0", course: "13.2", altitude: "3.2", satellites: "10", hdop: "0.7", lat: "0045.841205", lon: "12722.596545", latD: "N", lonD: "E", latitude: 0.7640200833333334, longitude: 127.37660908333334, timestamp: "2024-06-06T09:56:03.000Z" },
        { speed: "0.0", course: "333.5", altitude: "1.3", satellites: "10", hdop: "0.7", lat: "0045.841058", lon: "12722.596311", latD: "N", lonD: "E", latitude: 0.7640176333333333, longitude: 127.37660518333334, timestamp: "2024-06-06T09:56:23.000Z" },
        { speed: "0.0", course: "333.5", altitude: "1.3", satellites: "10", hdop: "0.7", lat: "0045.841058", lon: "12722.596311", latD: "N", lonD: "E", latitude: 0.7640176333333333, longitude: 127.37660518333334, timestamp: "2024-06-06T09:56:23.000Z" },
        { speed: "0.0", course: "333.5", altitude: "1.3", satellites: "10", hdop: "0.7", lat: "0045.841058", lon: "12722.596311", latD: "N", lonD: "E", latitude: 0.7640176333333333, longitude: 127.37660518333334, timestamp: "2024-06-06T09:56:23.000Z" },
        { speed: "0.6", course: "68.8", altitude: "4.7", satellites: "10", hdop: "0.7", lat: "0045.841077", lon: "12722.595720", latD: "N", lonD: "E", latitude: 0.76401795, longitude: 127.37659533333333, timestamp: "2024-06-06T09:56:43.000Z" },
        { speed: "0.6", course: "68.8", altitude: "4.7", satellites: "10", hdop: "0.7", lat: "0045.841077", lon: "12722.595720", latD: "N", lonD: "E", latitude: 0.76401795, longitude: 127.37659533333333, timestamp: "2024-06-06T09:56:43.000Z" },
        { speed: "0.6", course: "68.8", altitude: "4.7", satellites: "10", hdop: "0.7", lat: "0045.841077", lon: "12722.595720", latD: "N", lonD: "E", latitude: 0.76401795, longitude: 127.37659533333333, timestamp: "2024-06-06T09:56:43.000Z" },
        { speed: "0.0", course: "77.2", altitude: "5.6", satellites: "10", hdop: "0.7", lat: "0045.841425", lon: "12722.595053", latD: "N", lonD: "E", latitude: 0.76402375, longitude: 127.37658421666667, timestamp: "2024-06-06T09:57:03.000Z" },
        { speed: "0.0", course: "77.2", altitude: "5.6", satellites: "10", hdop: "0.7", lat: "0045.841425", lon: "12722.595053", latD: "N", lonD: "E", latitude: 0.76402375, longitude: 127.37658421666667, timestamp: "2024-06-06T09:57:03.000Z" },
        { speed: "0.0", course: "77.2", altitude: "5.6", satellites: "10", hdop: "0.7", lat: "0045.841425", lon: "12722.595053", latD: "N", lonD: "E", latitude: 0.76402375, longitude: 127.37658421666667, timestamp: "2024-06-06T09:57:03.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.4", satellites: "10", hdop: "0.7", lat: "0045.841577", lon: "12722.595814", latD: "N", lonD: "E", latitude: 0.7640262833333333, longitude: 127.3765969, timestamp: "2024-06-06T09:57:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.4", satellites: "10", hdop: "0.7", lat: "0045.841577", lon: "12722.595814", latD: "N", lonD: "E", latitude: 0.7640262833333333, longitude: 127.3765969, timestamp: "2024-06-06T09:57:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.4", satellites: "10", hdop: "0.7", lat: "0045.841577", lon: "12722.595814", latD: "N", lonD: "E", latitude: 0.7640262833333333, longitude: 127.3765969, timestamp: "2024-06-06T09:57:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.4", satellites: "10", hdop: "0.7", lat: "0045.841577", lon: "12722.595814", latD: "N", lonD: "E", latitude: 0.7640262833333333, longitude: 127.3765969, timestamp: "2024-06-06T09:57:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.3", satellites: "10", hdop: "0.7", lat: "0045.841607", lon: "12722.595856", latD: "N", lonD: "E", latitude: 0.7640267833333334, longitude: 127.3765976, timestamp: "2024-06-06T09:57:43.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.3", satellites: "10", hdop: "0.7", lat: "0045.841607", lon: "12722.595856", latD: "N", lonD: "E", latitude: 0.7640267833333334, longitude: 127.3765976, timestamp: "2024-06-06T09:57:43.000Z" },
        { speed: "0.0", course: "81.9", altitude: "4.7", satellites: "10", hdop: "0.7", lat: "0045.841592", lon: "12722.595864", latD: "N", lonD: "E", latitude: 0.7640265333333333, longitude: 127.37659773333333, timestamp: "2024-06-06T09:58:03.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.3", satellites: "10", hdop: "0.7", lat: "0045.841607", lon: "12722.595856", latD: "N", lonD: "E", latitude: 0.7640267833333334, longitude: 127.3765976, timestamp: "2024-06-06T09:57:43.000Z" },
        { speed: "0.0", course: "81.9", altitude: "4.7", satellites: "10", hdop: "0.7", lat: "0045.841592", lon: "12722.595864", latD: "N", lonD: "E", latitude: 0.7640265333333333, longitude: 127.37659773333333, timestamp: "2024-06-06T09:58:03.000Z" },
        { speed: "0.0", course: "81.9", altitude: "4.7", satellites: "10", hdop: "0.7", lat: "0045.841592", lon: "12722.595864", latD: "N", lonD: "E", latitude: 0.7640265333333333, longitude: 127.37659773333333, timestamp: "2024-06-06T09:58:03.000Z" },
        { speed: "0.0", course: "81.9", altitude: "4.7", satellites: "10", hdop: "0.7", lat: "0045.841592", lon: "12722.595864", latD: "N", lonD: "E", latitude: 0.7640265333333333, longitude: 127.37659773333333, timestamp: "2024-06-06T09:58:03.000Z" },
        { speed: "0.0", course: "81.9", altitude: "4.7", satellites: "10", hdop: "0.7", lat: "0045.841592", lon: "12722.595864", latD: "N", lonD: "E", latitude: 0.7640265333333333, longitude: 127.37659773333333, timestamp: "2024-06-06T09:58:03.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.1", satellites: "09", hdop: "0.8", lat: "0045.841585", lon: "12722.595870", latD: "N", lonD: "E", latitude: 0.7640264166666667, longitude: 127.37659783333334, timestamp: "2024-06-06T09:58:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.1", satellites: "09", hdop: "0.8", lat: "0045.841585", lon: "12722.595870", latD: "N", lonD: "E", latitude: 0.7640264166666667, longitude: 127.37659783333334, timestamp: "2024-06-06T09:58:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.1", satellites: "09", hdop: "0.8", lat: "0045.841585", lon: "12722.595870", latD: "N", lonD: "E", latitude: 0.7640264166666667, longitude: 127.37659783333334, timestamp: "2024-06-06T09:58:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.1", satellites: "09", hdop: "0.8", lat: "0045.841585", lon: "12722.595870", latD: "N", lonD: "E", latitude: 0.7640264166666667, longitude: 127.37659783333334, timestamp: "2024-06-06T09:58:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.1", satellites: "09", hdop: "0.7", lat: "0045.841582", lon: "12722.595869", latD: "N", lonD: "E", latitude: 0.7640263666666667, longitude: 127.37659781666666, timestamp: "2024-06-06T09:58:43.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.1", satellites: "09", hdop: "0.7", lat: "0045.841582", lon: "12722.595869", latD: "N", lonD: "E", latitude: 0.7640263666666667, longitude: 127.37659781666666, timestamp: "2024-06-06T09:58:43.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.1", satellites: "09", hdop: "0.7", lat: "0045.841582", lon: "12722.595869", latD: "N", lonD: "E", latitude: 0.7640263666666667, longitude: 127.37659781666666, timestamp: "2024-06-06T09:58:43.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.0", satellites: "10", hdop: "0.7", lat: "0045.841588", lon: "12722.595859", latD: "N", lonD: "E", latitude: 0.7640264666666667, longitude: 127.37659765, timestamp: "2024-06-06T09:59:03.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.0", satellites: "10", hdop: "0.7", lat: "0045.841588", lon: "12722.595859", latD: "N", lonD: "E", latitude: 0.7640264666666667, longitude: 127.37659765, timestamp: "2024-06-06T09:59:03.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.1", satellites: "09", hdop: "0.7", lat: "0045.841582", lon: "12722.595869", latD: "N", lonD: "E", latitude: 0.7640263666666667, longitude: 127.37659781666666, timestamp: "2024-06-06T09:58:43.000Z" },
        { speed: "0.0", course: "81.9", altitude: "3.0", satellites: "10", hdop: "0.7", lat: "0045.841588", lon: "12722.595859", latD: "N", lonD: "E", latitude: 0.7640264666666667, longitude: 127.37659765, timestamp: "2024-06-06T09:59:03.000Z" },
        { speed: "0.0", course: "81.9", altitude: "2.9", satellites: "10", hdop: "0.7", lat: "0045.841599", lon: "12722.595881", latD: "N", lonD: "E", latitude: 0.76402665, longitude: 127.37659801666666, timestamp: "2024-06-06T09:59:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "2.9", satellites: "10", hdop: "0.7", lat: "0045.841599", lon: "12722.595881", latD: "N", lonD: "E", latitude: 0.76402665, longitude: 127.37659801666666, timestamp: "2024-06-06T09:59:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "2.9", satellites: "10", hdop: "0.7", lat: "0045.841599", lon: "12722.595881", latD: "N", lonD: "E", latitude: 0.76402665, longitude: 127.37659801666666, timestamp: "2024-06-06T09:59:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "2.9", satellites: "10", hdop: "0.7", lat: "0045.841599", lon: "12722.595881", latD: "N", lonD: "E", latitude: 0.76402665, longitude: 127.37659801666666, timestamp: "2024-06-06T09:59:23.000Z" },
        { speed: "0.0", course: "81.9", altitude: "2.8", satellites: "09", hdop: "0.7", lat: "0045.841599", lon: "12722.595902", latD: "N", lonD: "E", latitude: 0.76402665, longitude: 127.37659836666667, timestamp: "2024-06-06T09:59:43.000Z" },
        { speed: "0.0", course: "81.9", altitude: "2.8", satellites: "09", hdop: "0.7", lat: "0045.841599", lon: "12722.595902", latD: "N", lonD: "E", latitude: 0.76402665, longitude: 127.37659836666667, timestamp: "2024-06-06T09:59:43.000Z" },
        { speed: "0.0", course: "81.9", altitude: "2.8", satellites: "09", hdop: "0.7", lat: "0045.841599", lon: "12722.595902", latD: "N", lonD: "E", latitude: 0.76402665, longitude: 127.37659836666667, timestamp: "2024-06-06T09:59:43.000Z" },
        { speed: "0.0", course: "81.9", altitude: "2.8", satellites: "09", hdop: "0.7", lat: "0045.841599", lon: "12722.595902", latD: "N", lonD: "E", latitude: 0.76402665, longitude: 127.37659836666667, timestamp: "2024-06-06T09:59:43.000Z" },
        { speed: "0.0", course: "307.2", altitude: "2.8", satellites: "10", hdop: "0.7", lat: "0045.841732", lon: "12722.595786", latD: "N", lonD: "E", latitude: 0.7640288666666667, longitude: 127.37659643333333, timestamp: "2024-06-06T10:00:03.000Z" },
        { speed: "0.0", course: "307.2", altitude: "2.8", satellites: "10", hdop: "0.7", lat: "0045.841732", lon: "12722.595786", latD: "N", lonD: "E", latitude: 0.7640288666666667, longitude: 127.37659643333333, timestamp: "2024-06-06T10:00:03.000Z" },
        { speed: "0.0", course: "307.2", altitude: "2.8", satellites: "10", hdop: "0.7", lat: "0045.841732", lon: "12722.595786", latD: "N", lonD: "E", latitude: 0.7640288666666667, longitude: 127.37659643333333, timestamp: "2024-06-06T10:00:03.000Z" },
        { speed: "0.0", course: "307.2", altitude: "2.8", satellites: "10", hdop: "0.7", lat: "0045.841732", lon: "12722.595786", latD: "N", lonD: "E", latitude: 0.7640288666666667, longitude: 127.37659643333333, timestamp: "2024-06-06T10:00:03.000Z" },
        { speed: "0.0", course: "307.2", altitude: "2.8", satellites: "10", hdop: "0.7", lat: "0045.841732", lon: "12722.595786", latD: "N", lonD: "E", latitude: 0.7640288666666667, longitude: 127.37659643333333, timestamp: "2024-06-06T10:00:03.000Z" },
        { speed: "0.0", course: "307.2", altitude: "2.8", satellites: "10", hdop: "0.7", lat: "0045.841732", lon: "12722.595786", latD: "N", lonD: "E", latitude: 0.7640288666666667, longitude: 127.37659643333333, timestamp: "2024-06-06T10:00:03.000Z" },
        { speed: "0.0", course: "229.3", altitude: "-0.2", satellites: "10", hdop: "0.7", lat: "0045.841285", lon: "12722.596534", latD: "N", lonD: "E", latitude: 0.7640214166666667, longitude: 127.3766089, timestamp: "2024-06-06T10:00:23.000Z" },
        { speed: "0.0", course: "229.3", altitude: "-0.2", satellites: "10", hdop: "0.7", lat: "0045.841285", lon: "12722.596534", latD: "N", lonD: "E", latitude: 0.7640214166666667, longitude: 127.3766089, timestamp: "2024-06-06T10:00:23.000Z" },
        { speed: "0.0", course: "229.3", altitude: "-0.2", satellites: "10", hdop: "0.7", lat: "0045.841285", lon: "12722.596534", latD: "N", lonD: "E", latitude: 0.7640214166666667, longitude: 127.3766089, timestamp: "2024-06-06T10:00:23.000Z" },
        { speed: "0.0", course: "229.3", altitude: "-0.2", satellites: "10", hdop: "0.7", lat: "0045.841285", lon: "12722.596534", latD: "N", lonD: "E", latitude: 0.7640214166666667, longitude: 127.3766089, timestamp: "2024-06-06T10:00:23.000Z" },
        { speed: "0.0", course: "175.8", altitude: "2.3", satellites: "10", hdop: "0.7", lat: "0045.840945", lon: "12722.596259", latD: "N", lonD: "E", latitude: 0.76401575, longitude: 127.37660431666667, timestamp: "2024-06-06T10:00:43.000Z" },
        { speed: "0.0", course: "175.8", altitude: "2.3", satellites: "10", hdop: "0.7", lat: "0045.840945", lon: "12722.596259", latD: "N", lonD: "E", latitude: 0.76401575, longitude: 127.37660431666667, timestamp: "2024-06-06T10:00:43.000Z" },
        { speed: "0.0", course: "175.8", altitude: "1.2", satellites: "10", hdop: "0.7", lat: "0045.840942", lon: "12722.596256", latD: "N", lonD: "E", latitude: 0.7640157, longitude: 127.37660426666666, timestamp: "2024-06-06T10:01:03.000Z" },
        { speed: "0.0", course: "175.8", altitude: "2.3", satellites: "10", hdop: "0.7", lat: "0045.840945", lon: "12722.596259", latD: "N", lonD: "E", latitude: 0.76401575, longitude: 127.37660431666667, timestamp: "2024-06-06T10:00:43.000Z" },
        { speed: "0.0", course: "175.8", altitude: "2.3", satellites: "10", hdop: "0.7", lat: "0045.840945", lon: "12722.596259", latD: "N", lonD: "E", latitude: 0.76401575, longitude: 127.37660431666667, timestamp: "2024-06-06T10:00:43.000Z" },
        { speed: "0.0", course: "175.8", altitude: "1.2", satellites: "10", hdop: "0.7", lat: "0045.840942", lon: "12722.596256", latD: "N", lonD: "E", latitude: 0.7640157, longitude: 127.37660426666666, timestamp: "2024-06-06T10:01:03.000Z" },
        { speed: "0.0", course: "175.8", altitude: "1.2", satellites: "10", hdop: "0.7", lat: "0045.840942", lon: "12722.596256", latD: "N", lonD: "E", latitude: 0.7640157, longitude: 127.37660426666666, timestamp: "2024-06-06T10:01:03.000Z" },
        { speed: "0.0", course: "175.8", altitude: "1.2", satellites: "10", hdop: "0.7", lat: "0045.840942", lon: "12722.596256", latD: "N", lonD: "E", latitude: 0.7640157, longitude: 127.37660426666666, timestamp: "2024-06-06T10:01:03.000Z" },
        { speed: "0.0", course: "227.4", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.840071", lon: "12722.595509", latD: "N", lonD: "E", latitude: 0.7640011833333333, longitude: 127.37659181666666, timestamp: "2024-06-06T10:01:23.000Z" },
        { speed: "0.0", course: "227.4", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.840071", lon: "12722.595509", latD: "N", lonD: "E", latitude: 0.7640011833333333, longitude: 127.37659181666666, timestamp: "2024-06-06T10:01:23.000Z" },
        { speed: "0.0", course: "173.0", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.840271", lon: "12722.596329", latD: "N", lonD: "E", latitude: 0.7640045166666667, longitude: 127.37660548333334, timestamp: "2024-06-06T10:01:43.000Z" },
        { speed: "0.0", course: "173.0", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.840271", lon: "12722.596329", latD: "N", lonD: "E", latitude: 0.7640045166666667, longitude: 127.37660548333334, timestamp: "2024-06-06T10:01:43.000Z" },
        { speed: "0.0", course: "173.0", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.840271", lon: "12722.596329", latD: "N", lonD: "E", latitude: 0.7640045166666667, longitude: 127.37660548333334, timestamp: "2024-06-06T10:01:43.000Z" },
        { speed: "0.0", course: "173.0", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.840271", lon: "12722.596329", latD: "N", lonD: "E", latitude: 0.7640045166666667, longitude: 127.37660548333334, timestamp: "2024-06-06T10:01:43.000Z" },
        { speed: "0.0", course: "197.7", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.840250", lon: "12722.596341", latD: "N", lonD: "E", latitude: 0.7640041666666666, longitude: 127.37660568333334, timestamp: "2024-06-06T10:02:03.000Z" },
        { speed: "0.0", course: "197.7", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.840250", lon: "12722.596341", latD: "N", lonD: "E", latitude: 0.7640041666666666, longitude: 127.37660568333334, timestamp: "2024-06-06T10:02:03.000Z" },
        { speed: "0.0", course: "173.0", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.840271", lon: "12722.596329", latD: "N", lonD: "E", latitude: 0.7640045166666667, longitude: 127.37660548333334, timestamp: "2024-06-06T10:01:43.000Z" },
        { speed: "0.0", course: "197.7", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.840250", lon: "12722.596341", latD: "N", lonD: "E", latitude: 0.7640041666666666, longitude: 127.37660568333334, timestamp: "2024-06-06T10:02:03.000Z" },
        { speed: "0.0", course: "220.7", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.840409", lon: "12722.596205", latD: "N", lonD: "E", latitude: 0.7640068166666667, longitude: 127.37660341666667, timestamp: "2024-06-06T10:02:23.000Z" },
        { speed: "0.0", course: "220.7", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.840409", lon: "12722.596205", latD: "N", lonD: "E", latitude: 0.7640068166666667, longitude: 127.37660341666667, timestamp: "2024-06-06T10:02:23.000Z" },
        { speed: "0.0", course: "220.7", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.840409", lon: "12722.596205", latD: "N", lonD: "E", latitude: 0.7640068166666667, longitude: 127.37660341666667, timestamp: "2024-06-06T10:02:23.000Z" },
        { speed: "0.0", course: "220.7", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.840409", lon: "12722.596205", latD: "N", lonD: "E", latitude: 0.7640068166666667, longitude: 127.37660341666667, timestamp: "2024-06-06T10:02:23.000Z" },
        { speed: "0.0", course: "224.6", altitude: "2.9", satellites: "10", hdop: "0.6", lat: "0045.841010", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.7640168333333333, longitude: 127.37660785, timestamp: "2024-06-06T10:02:43.000Z" },
        { speed: "0.0", course: "220.7", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.840409", lon: "12722.596205", latD: "N", lonD: "E", latitude: 0.7640068166666667, longitude: 127.37660341666667, timestamp: "2024-06-06T10:02:23.000Z" },
        { speed: "0.0", course: "220.7", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.840409", lon: "12722.596205", latD: "N", lonD: "E", latitude: 0.7640068166666667, longitude: 127.37660341666667, timestamp: "2024-06-06T10:02:23.000Z" },
        { speed: "0.0", course: "224.6", altitude: "2.9", satellites: "10", hdop: "0.6", lat: "0045.841010", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.7640168333333333, longitude: 127.37660785, timestamp: "2024-06-06T10:02:43.000Z" },
        { speed: "0.0", course: "224.6", altitude: "2.9", satellites: "10", hdop: "0.6", lat: "0045.841010", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.7640168333333333, longitude: 127.37660785, timestamp: "2024-06-06T10:02:43.000Z" },
        { speed: "0.0", course: "224.6", altitude: "2.9", satellites: "10", hdop: "0.6", lat: "0045.841010", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.7640168333333333, longitude: 127.37660785, timestamp: "2024-06-06T10:02:43.000Z" },
        { speed: "0.0", course: "232.2", altitude: "3.0", satellites: "10", hdop: "0.6", lat: "0045.841040", lon: "12722.596908", latD: "N", lonD: "E", latitude: 0.7640173333333333, longitude: 127.37661513333333, timestamp: "2024-06-06T10:03:03.000Z" },
        { speed: "0.0", course: "232.2", altitude: "3.0", satellites: "10", hdop: "0.6", lat: "0045.841040", lon: "12722.596908", latD: "N", lonD: "E", latitude: 0.7640173333333333, longitude: 127.37661513333333, timestamp: "2024-06-06T10:03:03.000Z" },
        { speed: "0.0", course: "232.2", altitude: "3.0", satellites: "10", hdop: "0.6", lat: "0045.841040", lon: "12722.596908", latD: "N", lonD: "E", latitude: 0.7640173333333333, longitude: 127.37661513333333, timestamp: "2024-06-06T10:03:03.000Z" },
        { speed: "0.0", course: "232.2", altitude: "3.0", satellites: "10", hdop: "0.6", lat: "0045.841040", lon: "12722.596908", latD: "N", lonD: "E", latitude: 0.7640173333333333, longitude: 127.37661513333333, timestamp: "2024-06-06T10:03:03.000Z" },
        { speed: "0.0", course: "18.9", altitude: "1.1", satellites: "10", hdop: "0.6", lat: "0045.841691", lon: "12722.597648", latD: "N", lonD: "E", latitude: 0.7640281833333333, longitude: 127.37662746666666, timestamp: "2024-06-06T10:03:23.000Z" },
        { speed: "0.0", course: "18.9", altitude: "1.1", satellites: "10", hdop: "0.6", lat: "0045.841691", lon: "12722.597648", latD: "N", lonD: "E", latitude: 0.7640281833333333, longitude: 127.37662746666666, timestamp: "2024-06-06T10:03:23.000Z" },
        { speed: "0.0", course: "190.9", altitude: "-4.7", satellites: "10", hdop: "0.6", lat: "0045.840551", lon: "12722.598017", latD: "N", lonD: "E", latitude: 0.7640091833333333, longitude: 127.37663361666667, timestamp: "2024-06-06T10:03:43.000Z" },
        { speed: "0.0", course: "190.9", altitude: "-4.7", satellites: "10", hdop: "0.6", lat: "0045.840551", lon: "12722.598017", latD: "N", lonD: "E", latitude: 0.7640091833333333, longitude: 127.37663361666667, timestamp: "2024-06-06T10:03:43.000Z" },
        { speed: "0.0", course: "190.9", altitude: "-4.7", satellites: "10", hdop: "0.6", lat: "0045.840551", lon: "12722.598017", latD: "N", lonD: "E", latitude: 0.7640091833333333, longitude: 127.37663361666667, timestamp: "2024-06-06T10:03:43.000Z" },
        { speed: "0.0", course: "216.9", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840932", lon: "12722.598596", latD: "N", lonD: "E", latitude: 0.7640155333333334, longitude: 127.37664326666666, timestamp: "2024-06-06T10:04:03.000Z" },
        { speed: "0.0", course: "216.9", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840932", lon: "12722.598596", latD: "N", lonD: "E", latitude: 0.7640155333333334, longitude: 127.37664326666666, timestamp: "2024-06-06T10:04:03.000Z" },
        { speed: "0.0", course: "190.9", altitude: "-4.7", satellites: "10", hdop: "0.6", lat: "0045.840551", lon: "12722.598017", latD: "N", lonD: "E", latitude: 0.7640091833333333, longitude: 127.37663361666667, timestamp: "2024-06-06T10:03:43.000Z" },
        { speed: "0.0", course: "190.9", altitude: "-4.7", satellites: "10", hdop: "0.6", lat: "0045.840551", lon: "12722.598017", latD: "N", lonD: "E", latitude: 0.7640091833333333, longitude: 127.37663361666667, timestamp: "2024-06-06T10:03:43.000Z" },
        { speed: "0.0", course: "216.9", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840932", lon: "12722.598596", latD: "N", lonD: "E", latitude: 0.7640155333333334, longitude: 127.37664326666666, timestamp: "2024-06-06T10:04:03.000Z" },
        { speed: "0.0", course: "216.9", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840932", lon: "12722.598596", latD: "N", lonD: "E", latitude: 0.7640155333333334, longitude: 127.37664326666666, timestamp: "2024-06-06T10:04:03.000Z" },
        { speed: "0.0", course: "216.9", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840932", lon: "12722.598596", latD: "N", lonD: "E", latitude: 0.7640155333333334, longitude: 127.37664326666666, timestamp: "2024-06-06T10:04:03.000Z" },
        { speed: "0.0", course: "216.9", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840932", lon: "12722.598596", latD: "N", lonD: "E", latitude: 0.7640155333333334, longitude: 127.37664326666666, timestamp: "2024-06-06T10:04:03.000Z" },
        { speed: "0.0", course: "67.1", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840929", lon: "12722.600235", latD: "N", lonD: "E", latitude: 0.7640154833333334, longitude: 127.37667058333334, timestamp: "2024-06-06T10:04:23.000Z" },
        { speed: "0.0", course: "67.1", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840929", lon: "12722.600235", latD: "N", lonD: "E", latitude: 0.7640154833333334, longitude: 127.37667058333334, timestamp: "2024-06-06T10:04:23.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-4.6", satellites: "09", hdop: "0.6", lat: "0045.840747", lon: "12722.600063", latD: "N", lonD: "E", latitude: 0.76401245, longitude: 127.37666771666666, timestamp: "2024-06-06T10:04:43.000Z" },
        { speed: "0.0", course: "67.1", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840929", lon: "12722.600235", latD: "N", lonD: "E", latitude: 0.7640154833333334, longitude: 127.37667058333334, timestamp: "2024-06-06T10:04:23.000Z" },
        { speed: "0.0", course: "67.1", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840929", lon: "12722.600235", latD: "N", lonD: "E", latitude: 0.7640154833333334, longitude: 127.37667058333334, timestamp: "2024-06-06T10:04:23.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-4.6", satellites: "09", hdop: "0.6", lat: "0045.840747", lon: "12722.600063", latD: "N", lonD: "E", latitude: 0.76401245, longitude: 127.37666771666666, timestamp: "2024-06-06T10:04:43.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-4.6", satellites: "09", hdop: "0.6", lat: "0045.840747", lon: "12722.600063", latD: "N", lonD: "E", latitude: 0.76401245, longitude: 127.37666771666666, timestamp: "2024-06-06T10:04:43.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-4.6", satellites: "09", hdop: "0.6", lat: "0045.840747", lon: "12722.600063", latD: "N", lonD: "E", latitude: 0.76401245, longitude: 127.37666771666666, timestamp: "2024-06-06T10:04:43.000Z" },
        { speed: "0.0", course: "294.5", altitude: "-4.8", satellites: "09", hdop: "0.6", lat: "0045.840165", lon: "12722.599087", latD: "N", lonD: "E", latitude: 0.76400275, longitude: 127.37665145, timestamp: "2024-06-06T10:05:03.000Z" },
        { speed: "0.3", course: "68.2", altitude: "0.6", satellites: "10", hdop: "0.6", lat: "0045.840722", lon: "12722.598223", latD: "N", lonD: "E", latitude: 0.7640120333333333, longitude: 127.37663705, timestamp: "2024-06-06T10:05:23.000Z" },
        { speed: "0.3", course: "68.2", altitude: "0.6", satellites: "10", hdop: "0.6", lat: "0045.840722", lon: "12722.598223", latD: "N", lonD: "E", latitude: 0.7640120333333333, longitude: 127.37663705, timestamp: "2024-06-06T10:05:23.000Z" },
        { speed: "0.3", course: "68.2", altitude: "0.6", satellites: "10", hdop: "0.6", lat: "0045.840722", lon: "12722.598223", latD: "N", lonD: "E", latitude: 0.7640120333333333, longitude: 127.37663705, timestamp: "2024-06-06T10:05:23.000Z" },
        { speed: "0.0", course: "294.5", altitude: "-4.8", satellites: "09", hdop: "0.6", lat: "0045.840165", lon: "12722.599087", latD: "N", lonD: "E", latitude: 0.76400275, longitude: 127.37665145, timestamp: "2024-06-06T10:05:03.000Z" },
        { speed: "0.3", course: "68.2", altitude: "0.6", satellites: "10", hdop: "0.6", lat: "0045.840722", lon: "12722.598223", latD: "N", lonD: "E", latitude: 0.7640120333333333, longitude: 127.37663705, timestamp: "2024-06-06T10:05:23.000Z" },
        { speed: "0.3", course: "68.2", altitude: "0.6", satellites: "10", hdop: "0.6", lat: "0045.840722", lon: "12722.598223", latD: "N", lonD: "E", latitude: 0.7640120333333333, longitude: 127.37663705, timestamp: "2024-06-06T10:05:23.000Z" },
        { speed: "0.0", course: "80.0", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839945", lon: "12722.596909", latD: "N", lonD: "E", latitude: 0.7639990833333333, longitude: 127.37661515, timestamp: "2024-06-06T10:05:43.000Z" },
        { speed: "0.3", course: "68.2", altitude: "0.6", satellites: "10", hdop: "0.6", lat: "0045.840722", lon: "12722.598223", latD: "N", lonD: "E", latitude: 0.7640120333333333, longitude: 127.37663705, timestamp: "2024-06-06T10:05:23.000Z" },
        { speed: "0.0", course: "80.0", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839945", lon: "12722.596909", latD: "N", lonD: "E", latitude: 0.7639990833333333, longitude: 127.37661515, timestamp: "2024-06-06T10:05:43.000Z" },
        { speed: "0.0", course: "80.0", altitude: "4.8", satellites: "09", hdop: "0.7", lat: "0045.839954", lon: "12722.597027", latD: "N", lonD: "E", latitude: 0.7639992333333333, longitude: 127.37661711666667, timestamp: "2024-06-06T10:06:03.000Z" },
        { speed: "0.0", course: "80.0", altitude: "4.8", satellites: "09", hdop: "0.7", lat: "0045.839954", lon: "12722.597027", latD: "N", lonD: "E", latitude: 0.7639992333333333, longitude: 127.37661711666667, timestamp: "2024-06-06T10:06:03.000Z" },
        { speed: "0.0", course: "80.0", altitude: "4.8", satellites: "09", hdop: "0.7", lat: "0045.839954", lon: "12722.597027", latD: "N", lonD: "E", latitude: 0.7639992333333333, longitude: 127.37661711666667, timestamp: "2024-06-06T10:06:03.000Z" },
        { speed: "0.0", course: "80.0", altitude: "4.8", satellites: "09", hdop: "0.7", lat: "0045.839954", lon: "12722.597027", latD: "N", lonD: "E", latitude: 0.7639992333333333, longitude: 127.37661711666667, timestamp: "2024-06-06T10:06:03.000Z" },
        { speed: "0.0", course: "80.0", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839965", lon: "12722.597013", latD: "N", lonD: "E", latitude: 0.7639994166666667, longitude: 127.37661688333333, timestamp: "2024-06-06T10:06:23.000Z" },
        { speed: "0.0", course: "80.0", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839965", lon: "12722.597013", latD: "N", lonD: "E", latitude: 0.7639994166666667, longitude: 127.37661688333333, timestamp: "2024-06-06T10:06:23.000Z" },
        { speed: "0.0", course: "80.0", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839965", lon: "12722.597013", latD: "N", lonD: "E", latitude: 0.7639994166666667, longitude: 127.37661688333333, timestamp: "2024-06-06T10:06:23.000Z" },
        { speed: "0.0", course: "80.0", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839965", lon: "12722.597013", latD: "N", lonD: "E", latitude: 0.7639994166666667, longitude: 127.37661688333333, timestamp: "2024-06-06T10:06:23.000Z" },
        { speed: "0.0", course: "80.0", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839965", lon: "12722.597013", latD: "N", lonD: "E", latitude: 0.7639994166666667, longitude: 127.37661688333333, timestamp: "2024-06-06T10:06:23.000Z" },
        { speed: "0.0", course: "80.0", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839965", lon: "12722.597013", latD: "N", lonD: "E", latitude: 0.7639994166666667, longitude: 127.37661688333333, timestamp: "2024-06-06T10:06:23.000Z" },
        { speed: "0.0", course: "80.0", altitude: "0.5", satellites: "10", hdop: "0.6", lat: "0045.840190", lon: "12722.596689", latD: "N", lonD: "E", latitude: 0.7640031666666667, longitude: 127.37661148333333, timestamp: "2024-06-06T10:06:43.000Z" },
        { speed: "0.0", course: "80.0", altitude: "0.5", satellites: "10", hdop: "0.6", lat: "0045.840190", lon: "12722.596689", latD: "N", lonD: "E", latitude: 0.7640031666666667, longitude: 127.37661148333333, timestamp: "2024-06-06T10:06:43.000Z" },
        { speed: "0.0", course: "79.9", altitude: "2.5", satellites: "09", hdop: "0.6", lat: "0045.840444", lon: "12722.596924", latD: "N", lonD: "E", latitude: 0.7640074, longitude: 127.3766154, timestamp: "2024-06-06T10:07:03.000Z" },
        { speed: "0.0", course: "80.0", altitude: "0.5", satellites: "10", hdop: "0.6", lat: "0045.840190", lon: "12722.596689", latD: "N", lonD: "E", latitude: 0.7640031666666667, longitude: 127.37661148333333, timestamp: "2024-06-06T10:06:43.000Z" },
        { speed: "0.0", course: "80.0", altitude: "0.5", satellites: "10", hdop: "0.6", lat: "0045.840190", lon: "12722.596689", latD: "N", lonD: "E", latitude: 0.7640031666666667, longitude: 127.37661148333333, timestamp: "2024-06-06T10:06:43.000Z" },
        { speed: "0.0", course: "79.9", altitude: "2.5", satellites: "09", hdop: "0.6", lat: "0045.840444", lon: "12722.596924", latD: "N", lonD: "E", latitude: 0.7640074, longitude: 127.3766154, timestamp: "2024-06-06T10:07:03.000Z" },
        { speed: "0.0", course: "79.9", altitude: "2.5", satellites: "09", hdop: "0.6", lat: "0045.840444", lon: "12722.596924", latD: "N", lonD: "E", latitude: 0.7640074, longitude: 127.3766154, timestamp: "2024-06-06T10:07:03.000Z" },
        { speed: "0.0", course: "91.3", altitude: "2.0", satellites: "10", hdop: "0.6", lat: "0045.840229", lon: "12722.596918", latD: "N", lonD: "E", latitude: 0.7640038166666667, longitude: 127.3766153, timestamp: "2024-06-06T10:07:23.000Z" },
        { speed: "0.0", course: "79.9", altitude: "2.5", satellites: "09", hdop: "0.6", lat: "0045.840444", lon: "12722.596924", latD: "N", lonD: "E", latitude: 0.7640074, longitude: 127.3766154, timestamp: "2024-06-06T10:07:03.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.840278", lon: "12722.596987", latD: "N", lonD: "E", latitude: 0.7640046333333333, longitude: 127.37661645, timestamp: "2024-06-06T10:08:03.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.840278", lon: "12722.596987", latD: "N", lonD: "E", latitude: 0.7640046333333333, longitude: 127.37661645, timestamp: "2024-06-06T10:08:03.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.840278", lon: "12722.596987", latD: "N", lonD: "E", latitude: 0.7640046333333333, longitude: 127.37661645, timestamp: "2024-06-06T10:08:03.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.840278", lon: "12722.596987", latD: "N", lonD: "E", latitude: 0.7640046333333333, longitude: 127.37661645, timestamp: "2024-06-06T10:08:03.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840269", lon: "12722.596991", latD: "N", lonD: "E", latitude: 0.7640044833333334, longitude: 127.37661651666667, timestamp: "2024-06-06T10:08:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840269", lon: "12722.596991", latD: "N", lonD: "E", latitude: 0.7640044833333334, longitude: 127.37661651666667, timestamp: "2024-06-06T10:08:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840269", lon: "12722.596991", latD: "N", lonD: "E", latitude: 0.7640044833333334, longitude: 127.37661651666667, timestamp: "2024-06-06T10:08:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840276", lon: "12722.597015", latD: "N", lonD: "E", latitude: 0.7640046, longitude: 127.37661691666666, timestamp: "2024-06-06T10:08:43.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840269", lon: "12722.596991", latD: "N", lonD: "E", latitude: 0.7640044833333334, longitude: 127.37661651666667, timestamp: "2024-06-06T10:08:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840269", lon: "12722.596991", latD: "N", lonD: "E", latitude: 0.7640044833333334, longitude: 127.37661651666667, timestamp: "2024-06-06T10:08:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840276", lon: "12722.597015", latD: "N", lonD: "E", latitude: 0.7640046, longitude: 127.37661691666666, timestamp: "2024-06-06T10:08:43.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840276", lon: "12722.597015", latD: "N", lonD: "E", latitude: 0.7640046, longitude: 127.37661691666666, timestamp: "2024-06-06T10:08:43.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840276", lon: "12722.597015", latD: "N", lonD: "E", latitude: 0.7640046, longitude: 127.37661691666666, timestamp: "2024-06-06T10:08:43.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840266", lon: "12722.597020", latD: "N", lonD: "E", latitude: 0.7640044333333333, longitude: 127.376617, timestamp: "2024-06-06T10:09:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840266", lon: "12722.597020", latD: "N", lonD: "E", latitude: 0.7640044333333333, longitude: 127.376617, timestamp: "2024-06-06T10:09:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "4.4", satellites: "09", hdop: "0.7", lat: "0045.840265", lon: "12722.597041", latD: "N", lonD: "E", latitude: 0.7640044166666667, longitude: 127.37661735, timestamp: "2024-06-06T10:09:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840266", lon: "12722.597020", latD: "N", lonD: "E", latitude: 0.7640044333333333, longitude: 127.376617, timestamp: "2024-06-06T10:09:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.840266", lon: "12722.597020", latD: "N", lonD: "E", latitude: 0.7640044333333333, longitude: 127.376617, timestamp: "2024-06-06T10:09:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "4.4", satellites: "09", hdop: "0.7", lat: "0045.840265", lon: "12722.597041", latD: "N", lonD: "E", latitude: 0.7640044166666667, longitude: 127.37661735, timestamp: "2024-06-06T10:09:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "4.4", satellites: "09", hdop: "0.7", lat: "0045.840265", lon: "12722.597041", latD: "N", lonD: "E", latitude: 0.7640044166666667, longitude: 127.37661735, timestamp: "2024-06-06T10:09:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "4.4", satellites: "09", hdop: "0.7", lat: "0045.840265", lon: "12722.597041", latD: "N", lonD: "E", latitude: 0.7640044166666667, longitude: 127.37661735, timestamp: "2024-06-06T10:09:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.6", satellites: "10", hdop: "0.6", lat: "0045.840258", lon: "12722.597066", latD: "N", lonD: "E", latitude: 0.7640043, longitude: 127.37661776666667, timestamp: "2024-06-06T10:09:43.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.6", satellites: "10", hdop: "0.6", lat: "0045.840258", lon: "12722.597066", latD: "N", lonD: "E", latitude: 0.7640043, longitude: 127.37661776666667, timestamp: "2024-06-06T10:09:43.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.6", satellites: "10", hdop: "0.6", lat: "0045.840258", lon: "12722.597066", latD: "N", lonD: "E", latitude: 0.7640043, longitude: 127.37661776666667, timestamp: "2024-06-06T10:09:43.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840263", lon: "12722.597102", latD: "N", lonD: "E", latitude: 0.7640043833333333, longitude: 127.37661836666666, timestamp: "2024-06-06T10:10:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840263", lon: "12722.597102", latD: "N", lonD: "E", latitude: 0.7640043833333333, longitude: 127.37661836666666, timestamp: "2024-06-06T10:10:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840263", lon: "12722.597102", latD: "N", lonD: "E", latitude: 0.7640043833333333, longitude: 127.37661836666666, timestamp: "2024-06-06T10:10:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840263", lon: "12722.597102", latD: "N", lonD: "E", latitude: 0.7640043833333333, longitude: 127.37661836666666, timestamp: "2024-06-06T10:10:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840263", lon: "12722.597102", latD: "N", lonD: "E", latitude: 0.7640043833333333, longitude: 127.37661836666666, timestamp: "2024-06-06T10:10:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840287", lon: "12722.597152", latD: "N", lonD: "E", latitude: 0.7640047833333333, longitude: 127.3766192, timestamp: "2024-06-06T10:10:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.7", lat: "0045.840271", lon: "12722.597150", latD: "N", lonD: "E", latitude: 0.7640045166666667, longitude: 127.37661916666667, timestamp: "2024-06-06T10:10:43.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840287", lon: "12722.597152", latD: "N", lonD: "E", latitude: 0.7640047833333333, longitude: 127.3766192, timestamp: "2024-06-06T10:10:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840287", lon: "12722.597152", latD: "N", lonD: "E", latitude: 0.7640047833333333, longitude: 127.3766192, timestamp: "2024-06-06T10:10:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840287", lon: "12722.597152", latD: "N", lonD: "E", latitude: 0.7640047833333333, longitude: 127.3766192, timestamp: "2024-06-06T10:10:24.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.7", lat: "0045.840271", lon: "12722.597150", latD: "N", lonD: "E", latitude: 0.7640045166666667, longitude: 127.37661916666667, timestamp: "2024-06-06T10:10:43.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840289", lon: "12722.597181", latD: "N", lonD: "E", latitude: 0.7640048166666666, longitude: 127.37661968333333, timestamp: "2024-06-06T10:11:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840289", lon: "12722.597181", latD: "N", lonD: "E", latitude: 0.7640048166666666, longitude: 127.37661968333333, timestamp: "2024-06-06T10:11:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840289", lon: "12722.597181", latD: "N", lonD: "E", latitude: 0.7640048166666666, longitude: 127.37661968333333, timestamp: "2024-06-06T10:11:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840289", lon: "12722.597181", latD: "N", lonD: "E", latitude: 0.7640048166666666, longitude: 127.37661968333333, timestamp: "2024-06-06T10:11:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840289", lon: "12722.597181", latD: "N", lonD: "E", latitude: 0.7640048166666666, longitude: 127.37661968333333, timestamp: "2024-06-06T10:11:04.000Z" },
        { speed: "0.0", course: "91.3", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.840289", lon: "12722.597181", latD: "N", lonD: "E", latitude: 0.7640048166666666, longitude: 127.37661968333333, timestamp: "2024-06-06T10:11:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.840308", lon: "12722.598230", latD: "N", lonD: "E", latitude: 0.7640051333333333, longitude: 127.37663716666667, timestamp: "2024-06-06T10:11:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.840308", lon: "12722.598230", latD: "N", lonD: "E", latitude: 0.7640051333333333, longitude: 127.37663716666667, timestamp: "2024-06-06T10:11:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "07", hdop: "0.7", lat: "0045.840278", lon: "12722.598261", latD: "N", lonD: "E", latitude: 0.7640046333333333, longitude: 127.37663768333333, timestamp: "2024-06-06T10:11:43.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.840308", lon: "12722.598230", latD: "N", lonD: "E", latitude: 0.7640051333333333, longitude: 127.37663716666667, timestamp: "2024-06-06T10:11:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.840308", lon: "12722.598230", latD: "N", lonD: "E", latitude: 0.7640051333333333, longitude: 127.37663716666667, timestamp: "2024-06-06T10:11:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "07", hdop: "0.7", lat: "0045.840278", lon: "12722.598261", latD: "N", lonD: "E", latitude: 0.7640046333333333, longitude: 127.37663768333333, timestamp: "2024-06-06T10:11:43.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.840277", lon: "12722.598251", latD: "N", lonD: "E", latitude: 0.7640046166666666, longitude: 127.37663751666666, timestamp: "2024-06-06T10:12:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.840277", lon: "12722.598251", latD: "N", lonD: "E", latitude: 0.7640046166666666, longitude: 127.37663751666666, timestamp: "2024-06-06T10:12:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.840277", lon: "12722.598251", latD: "N", lonD: "E", latitude: 0.7640046166666666, longitude: 127.37663751666666, timestamp: "2024-06-06T10:12:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "09", hdop: "0.7", lat: "0045.840248", lon: "12722.598243", latD: "N", lonD: "E", latitude: 0.7640041333333334, longitude: 127.37663738333333, timestamp: "2024-06-06T10:12:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.840277", lon: "12722.598251", latD: "N", lonD: "E", latitude: 0.7640046166666666, longitude: 127.37663751666666, timestamp: "2024-06-06T10:12:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.840277", lon: "12722.598251", latD: "N", lonD: "E", latitude: 0.7640046166666666, longitude: 127.37663751666666, timestamp: "2024-06-06T10:12:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.840277", lon: "12722.598251", latD: "N", lonD: "E", latitude: 0.7640046166666666, longitude: 127.37663751666666, timestamp: "2024-06-06T10:12:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "09", hdop: "0.7", lat: "0045.840248", lon: "12722.598243", latD: "N", lonD: "E", latitude: 0.7640041333333334, longitude: 127.37663738333333, timestamp: "2024-06-06T10:12:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "09", hdop: "0.7", lat: "0045.840248", lon: "12722.598243", latD: "N", lonD: "E", latitude: 0.7640041333333334, longitude: 127.37663738333333, timestamp: "2024-06-06T10:12:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "09", hdop: "0.7", lat: "0045.840248", lon: "12722.598243", latD: "N", lonD: "E", latitude: 0.7640041333333334, longitude: 127.37663738333333, timestamp: "2024-06-06T10:12:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "09", hdop: "0.7", lat: "0045.840248", lon: "12722.598243", latD: "N", lonD: "E", latitude: 0.7640041333333334, longitude: 127.37663738333333, timestamp: "2024-06-06T10:12:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "09", hdop: "0.7", lat: "0045.840248", lon: "12722.598243", latD: "N", lonD: "E", latitude: 0.7640041333333334, longitude: 127.37663738333333, timestamp: "2024-06-06T10:12:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "09", hdop: "0.6", lat: "0045.840242", lon: "12722.598244", latD: "N", lonD: "E", latitude: 0.7640040333333334, longitude: 127.3766374, timestamp: "2024-06-06T10:12:44.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.840268", lon: "12722.598244", latD: "N", lonD: "E", latitude: 0.7640044666666667, longitude: 127.3766374, timestamp: "2024-06-06T10:13:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.840268", lon: "12722.598244", latD: "N", lonD: "E", latitude: 0.7640044666666667, longitude: 127.3766374, timestamp: "2024-06-06T10:13:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.840268", lon: "12722.598244", latD: "N", lonD: "E", latitude: 0.7640044666666667, longitude: 127.3766374, timestamp: "2024-06-06T10:13:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "09", hdop: "0.6", lat: "0045.840242", lon: "12722.598244", latD: "N", lonD: "E", latitude: 0.7640040333333334, longitude: 127.3766374, timestamp: "2024-06-06T10:12:44.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.840268", lon: "12722.598244", latD: "N", lonD: "E", latitude: 0.7640044666666667, longitude: 127.3766374, timestamp: "2024-06-06T10:13:04.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.840243", lon: "12722.598215", latD: "N", lonD: "E", latitude: 0.76400405, longitude: 127.37663691666667, timestamp: "2024-06-06T10:13:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.840243", lon: "12722.598215", latD: "N", lonD: "E", latitude: 0.76400405, longitude: 127.37663691666667, timestamp: "2024-06-06T10:13:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.840243", lon: "12722.598215", latD: "N", lonD: "E", latitude: 0.76400405, longitude: 127.37663691666667, timestamp: "2024-06-06T10:13:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.840243", lon: "12722.598215", latD: "N", lonD: "E", latitude: 0.76400405, longitude: 127.37663691666667, timestamp: "2024-06-06T10:13:24.000Z" },
        { speed: "0.0", course: "119.3", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.840243", lon: "12722.598215", latD: "N", lonD: "E", latitude: 0.76400405, longitude: 127.37663691666667, timestamp: "2024-06-06T10:13:24.000Z" },
        { speed: "0.0", course: "265.7", altitude: "1.3", satellites: "09", hdop: "0.6", lat: "0045.840205", lon: "12722.598034", latD: "N", lonD: "E", latitude: 0.7640034166666666, longitude: 127.3766339, timestamp: "2024-06-06T10:13:44.000Z" },
        { speed: "0.0", course: "265.7", altitude: "1.3", satellites: "09", hdop: "0.6", lat: "0045.840205", lon: "12722.598034", latD: "N", lonD: "E", latitude: 0.7640034166666666, longitude: 127.3766339, timestamp: "2024-06-06T10:13:44.000Z" },
        { speed: "0.0", course: "265.7", altitude: "1.3", satellites: "09", hdop: "0.6", lat: "0045.840205", lon: "12722.598034", latD: "N", lonD: "E", latitude: 0.7640034166666666, longitude: 127.3766339, timestamp: "2024-06-06T10:13:44.000Z" },
        { speed: "0.0", course: "265.7", altitude: "1.3", satellites: "09", hdop: "0.6", lat: "0045.840205", lon: "12722.598034", latD: "N", lonD: "E", latitude: 0.7640034166666666, longitude: 127.3766339, timestamp: "2024-06-06T10:13:44.000Z" },
        { speed: "0.0", course: "265.7", altitude: "1.3", satellites: "09", hdop: "0.6", lat: "0045.840205", lon: "12722.598034", latD: "N", lonD: "E", latitude: 0.7640034166666666, longitude: 127.3766339, timestamp: "2024-06-06T10:13:44.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839845", lon: "12722.598113", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37663521666667, timestamp: "2024-06-06T10:14:04.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839845", lon: "12722.598113", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37663521666667, timestamp: "2024-06-06T10:14:04.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839845", lon: "12722.598113", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37663521666667, timestamp: "2024-06-06T10:14:04.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839845", lon: "12722.598113", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37663521666667, timestamp: "2024-06-06T10:14:04.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839863", lon: "12722.598117", latD: "N", lonD: "E", latitude: 0.7639977166666667, longitude: 127.37663528333333, timestamp: "2024-06-06T10:14:24.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839863", lon: "12722.598117", latD: "N", lonD: "E", latitude: 0.7639977166666667, longitude: 127.37663528333333, timestamp: "2024-06-06T10:14:24.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839988", lon: "12722.598132", latD: "N", lonD: "E", latitude: 0.7639998, longitude: 127.37663553333333, timestamp: "2024-06-06T10:14:44.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839863", lon: "12722.598117", latD: "N", lonD: "E", latitude: 0.7639977166666667, longitude: 127.37663528333333, timestamp: "2024-06-06T10:14:24.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839863", lon: "12722.598117", latD: "N", lonD: "E", latitude: 0.7639977166666667, longitude: 127.37663528333333, timestamp: "2024-06-06T10:14:24.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839988", lon: "12722.598132", latD: "N", lonD: "E", latitude: 0.7639998, longitude: 127.37663553333333, timestamp: "2024-06-06T10:14:44.000Z" },
        { speed: "0.0", course: "265.7", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839992", lon: "12722.598147", latD: "N", lonD: "E", latitude: 0.7639998666666667, longitude: 127.37663578333333, timestamp: "2024-06-06T10:15:04.000Z" },
        { speed: "0.0", course: "265.7", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839992", lon: "12722.598147", latD: "N", lonD: "E", latitude: 0.7639998666666667, longitude: 127.37663578333333, timestamp: "2024-06-06T10:15:04.000Z" },
        { speed: "0.0", course: "265.7", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839992", lon: "12722.598147", latD: "N", lonD: "E", latitude: 0.7639998666666667, longitude: 127.37663578333333, timestamp: "2024-06-06T10:15:04.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839990", lon: "12722.598140", latD: "N", lonD: "E", latitude: 0.7639998333333333, longitude: 127.37663566666667, timestamp: "2024-06-06T10:15:24.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839990", lon: "12722.598140", latD: "N", lonD: "E", latitude: 0.7639998333333333, longitude: 127.37663566666667, timestamp: "2024-06-06T10:15:24.000Z" },
        { speed: "0.0", course: "265.7", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839992", lon: "12722.598147", latD: "N", lonD: "E", latitude: 0.7639998666666667, longitude: 127.37663578333333, timestamp: "2024-06-06T10:15:04.000Z" },
        { speed: "0.0", course: "265.7", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839992", lon: "12722.598147", latD: "N", lonD: "E", latitude: 0.7639998666666667, longitude: 127.37663578333333, timestamp: "2024-06-06T10:15:04.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839990", lon: "12722.598140", latD: "N", lonD: "E", latitude: 0.7639998333333333, longitude: 127.37663566666667, timestamp: "2024-06-06T10:15:24.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839990", lon: "12722.598140", latD: "N", lonD: "E", latitude: 0.7639998333333333, longitude: 127.37663566666667, timestamp: "2024-06-06T10:15:24.000Z" },
        { speed: "0.0", course: "265.7", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839990", lon: "12722.598140", latD: "N", lonD: "E", latitude: 0.7639998333333333, longitude: 127.37663566666667, timestamp: "2024-06-06T10:15:24.000Z" },
        { speed: "1.1", course: "67.2", altitude: "-2.5", satellites: "09", hdop: "0.7", lat: "0045.840734", lon: "12722.599771", latD: "N", lonD: "E", latitude: 0.7640122333333333, longitude: 127.37666285, timestamp: "2024-06-06T10:15:44.000Z" },
        { speed: "1.1", course: "67.2", altitude: "-2.5", satellites: "09", hdop: "0.7", lat: "0045.840734", lon: "12722.599771", latD: "N", lonD: "E", latitude: 0.7640122333333333, longitude: 127.37666285, timestamp: "2024-06-06T10:15:44.000Z" },
        { speed: "1.1", course: "67.2", altitude: "-2.5", satellites: "09", hdop: "0.7", lat: "0045.840734", lon: "12722.599771", latD: "N", lonD: "E", latitude: 0.7640122333333333, longitude: 127.37666285, timestamp: "2024-06-06T10:15:44.000Z" },
        { speed: "1.1", course: "67.2", altitude: "-2.5", satellites: "09", hdop: "0.7", lat: "0045.840734", lon: "12722.599771", latD: "N", lonD: "E", latitude: 0.7640122333333333, longitude: 127.37666285, timestamp: "2024-06-06T10:15:44.000Z" },
        { speed: "2.5", course: "65.2", altitude: "2.9", satellites: "09", hdop: "0.7", lat: "0045.844884", lon: "12722.609435", latD: "N", lonD: "E", latitude: 0.7640814, longitude: 127.37682391666667, timestamp: "2024-06-06T10:16:04.000Z" },
        { speed: "2.5", course: "65.2", altitude: "2.9", satellites: "09", hdop: "0.7", lat: "0045.844884", lon: "12722.609435", latD: "N", lonD: "E", latitude: 0.7640814, longitude: 127.37682391666667, timestamp: "2024-06-06T10:16:04.000Z" },
        { speed: "4.0", course: "78.7", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.851384", lon: "12722.627230", latD: "N", lonD: "E", latitude: 0.7641897333333334, longitude: 127.3771205, timestamp: "2024-06-06T10:16:24.000Z" },
        { speed: "2.5", course: "65.2", altitude: "2.9", satellites: "09", hdop: "0.7", lat: "0045.844884", lon: "12722.609435", latD: "N", lonD: "E", latitude: 0.7640814, longitude: 127.37682391666667, timestamp: "2024-06-06T10:16:04.000Z" },
        { speed: "2.5", course: "65.2", altitude: "2.9", satellites: "09", hdop: "0.7", lat: "0045.844884", lon: "12722.609435", latD: "N", lonD: "E", latitude: 0.7640814, longitude: 127.37682391666667, timestamp: "2024-06-06T10:16:04.000Z" },
        { speed: "4.0", course: "78.7", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.851384", lon: "12722.627230", latD: "N", lonD: "E", latitude: 0.7641897333333334, longitude: 127.3771205, timestamp: "2024-06-06T10:16:24.000Z" },
        { speed: "4.0", course: "78.7", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.851384", lon: "12722.627230", latD: "N", lonD: "E", latitude: 0.7641897333333334, longitude: 127.3771205, timestamp: "2024-06-06T10:16:24.000Z" },
        { speed: "4.0", course: "78.7", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.851384", lon: "12722.627230", latD: "N", lonD: "E", latitude: 0.7641897333333334, longitude: 127.3771205, timestamp: "2024-06-06T10:16:24.000Z" },
        { speed: "5.0", course: "92.0", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.852728", lon: "12722.652344", latD: "N", lonD: "E", latitude: 0.7642121333333333, longitude: 127.37753906666667, timestamp: "2024-06-06T10:16:44.000Z" },
        { speed: "5.0", course: "92.0", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.852728", lon: "12722.652344", latD: "N", lonD: "E", latitude: 0.7642121333333333, longitude: 127.37753906666667, timestamp: "2024-06-06T10:16:44.000Z" },
        { speed: "5.2", course: "100.9", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.848300", lon: "12722.680818", latD: "N", lonD: "E", latitude: 0.7641383333333334, longitude: 127.37801363333334, timestamp: "2024-06-06T10:17:04.000Z" },
        { speed: "5.0", course: "92.0", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.852728", lon: "12722.652344", latD: "N", lonD: "E", latitude: 0.7642121333333333, longitude: 127.37753906666667, timestamp: "2024-06-06T10:16:44.000Z" },
        { speed: "5.2", course: "100.9", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.848300", lon: "12722.680818", latD: "N", lonD: "E", latitude: 0.7641383333333334, longitude: 127.37801363333334, timestamp: "2024-06-06T10:17:04.000Z" },
        { speed: "5.2", course: "100.9", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.848300", lon: "12722.680818", latD: "N", lonD: "E", latitude: 0.7641383333333334, longitude: 127.37801363333334, timestamp: "2024-06-06T10:17:04.000Z" },
        { speed: "5.2", course: "100.9", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.848300", lon: "12722.680818", latD: "N", lonD: "E", latitude: 0.7641383333333334, longitude: 127.37801363333334, timestamp: "2024-06-06T10:17:04.000Z" },
        { speed: "7.7", course: "109.3", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.837968", lon: "12722.716063", latD: "N", lonD: "E", latitude: 0.7639661333333333, longitude: 127.37860105, timestamp: "2024-06-06T10:17:25.000Z" },
        { speed: "7.7", course: "109.3", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.837968", lon: "12722.716063", latD: "N", lonD: "E", latitude: 0.7639661333333333, longitude: 127.37860105, timestamp: "2024-06-06T10:17:25.000Z" },
        { speed: "7.7", course: "109.3", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.837968", lon: "12722.716063", latD: "N", lonD: "E", latitude: 0.7639661333333333, longitude: 127.37860105, timestamp: "2024-06-06T10:17:25.000Z" },
        { speed: "7.6", course: "112.5", altitude: "1.9", satellites: "09", hdop: "0.7", lat: "0045.824476", lon: "12722.751409", latD: "N", lonD: "E", latitude: 0.7637412666666666, longitude: 127.37919015, timestamp: "2024-06-06T10:17:44.000Z" },
        { speed: "7.6", course: "112.5", altitude: "1.9", satellites: "09", hdop: "0.7", lat: "0045.824476", lon: "12722.751409", latD: "N", lonD: "E", latitude: 0.7637412666666666, longitude: 127.37919015, timestamp: "2024-06-06T10:17:44.000Z" },
        { speed: "7.6", course: "112.5", altitude: "1.9", satellites: "09", hdop: "0.7", lat: "0045.824476", lon: "12722.751409", latD: "N", lonD: "E", latitude: 0.7637412666666666, longitude: 127.37919015, timestamp: "2024-06-06T10:17:44.000Z" },
        { speed: "7.6", course: "112.5", altitude: "1.9", satellites: "09", hdop: "0.7", lat: "0045.824476", lon: "12722.751409", latD: "N", lonD: "E", latitude: 0.7637412666666666, longitude: 127.37919015, timestamp: "2024-06-06T10:17:44.000Z" },
        { speed: "8.2", course: "112.8", altitude: "-1.5", satellites: "09", hdop: "0.6", lat: "0045.803792", lon: "12722.792237", latD: "N", lonD: "E", latitude: 0.7633965333333333, longitude: 127.37987061666666, timestamp: "2024-06-06T10:18:04.000Z" },
        { speed: "8.2", course: "112.8", altitude: "-1.5", satellites: "09", hdop: "0.6", lat: "0045.803792", lon: "12722.792237", latD: "N", lonD: "E", latitude: 0.7633965333333333, longitude: 127.37987061666666, timestamp: "2024-06-06T10:18:04.000Z" },
        { speed: "7.6", course: "112.5", altitude: "1.9", satellites: "09", hdop: "0.7", lat: "0045.824476", lon: "12722.751409", latD: "N", lonD: "E", latitude: 0.7637412666666666, longitude: 127.37919015, timestamp: "2024-06-06T10:17:44.000Z" },
        { speed: "8.2", course: "112.8", altitude: "-1.5", satellites: "09", hdop: "0.6", lat: "0045.803792", lon: "12722.792237", latD: "N", lonD: "E", latitude: 0.7633965333333333, longitude: 127.37987061666666, timestamp: "2024-06-06T10:18:04.000Z" },
        { speed: "8.2", course: "112.8", altitude: "-1.5", satellites: "09", hdop: "0.6", lat: "0045.803792", lon: "12722.792237", latD: "N", lonD: "E", latitude: 0.7633965333333333, longitude: 127.37987061666666, timestamp: "2024-06-06T10:18:04.000Z" },
        { speed: "8.2", course: "112.8", altitude: "-1.5", satellites: "09", hdop: "0.6", lat: "0045.803792", lon: "12722.792237", latD: "N", lonD: "E", latitude: 0.7633965333333333, longitude: 127.37987061666666, timestamp: "2024-06-06T10:18:04.000Z" },
        { speed: "8.4", course: "105.6", altitude: "5.9", satellites: "10", hdop: "0.6", lat: "0045.790069", lon: "12722.838848", latD: "N", lonD: "E", latitude: 0.7631678166666667, longitude: 127.38064746666667, timestamp: "2024-06-06T10:18:25.000Z" },
        { speed: "8.4", course: "105.6", altitude: "5.9", satellites: "10", hdop: "0.6", lat: "0045.790069", lon: "12722.838848", latD: "N", lonD: "E", latitude: 0.7631678166666667, longitude: 127.38064746666667, timestamp: "2024-06-06T10:18:25.000Z" },
        { speed: "8.5", course: "98.0", altitude: "5.2", satellites: "10", hdop: "0.6", lat: "0045.779395", lon: "12722.881800", latD: "N", lonD: "E", latitude: 0.7629899166666667, longitude: 127.38136333333334, timestamp: "2024-06-06T10:18:44.000Z" },
        { speed: "8.5", course: "98.0", altitude: "5.2", satellites: "10", hdop: "0.6", lat: "0045.779395", lon: "12722.881800", latD: "N", lonD: "E", latitude: 0.7629899166666667, longitude: 127.38136333333334, timestamp: "2024-06-06T10:18:44.000Z" },
        { speed: "8.5", course: "98.0", altitude: "5.2", satellites: "10", hdop: "0.6", lat: "0045.779395", lon: "12722.881800", latD: "N", lonD: "E", latitude: 0.7629899166666667, longitude: 127.38136333333334, timestamp: "2024-06-06T10:18:44.000Z" },
        { speed: "8.5", course: "98.0", altitude: "5.2", satellites: "10", hdop: "0.6", lat: "0045.779395", lon: "12722.881800", latD: "N", lonD: "E", latitude: 0.7629899166666667, longitude: 127.38136333333334, timestamp: "2024-06-06T10:18:44.000Z" },
        { speed: "8.4", course: "90.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.776424", lon: "12722.928946", latD: "N", lonD: "E", latitude: 0.7629404, longitude: 127.3821491, timestamp: "2024-06-06T10:19:04.000Z" },
        { speed: "8.4", course: "90.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.776424", lon: "12722.928946", latD: "N", lonD: "E", latitude: 0.7629404, longitude: 127.3821491, timestamp: "2024-06-06T10:19:04.000Z" },
        { speed: "8.4", course: "90.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.776424", lon: "12722.928946", latD: "N", lonD: "E", latitude: 0.7629404, longitude: 127.3821491, timestamp: "2024-06-06T10:19:04.000Z" },
        { speed: "8.4", course: "90.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.776424", lon: "12722.928946", latD: "N", lonD: "E", latitude: 0.7629404, longitude: 127.3821491, timestamp: "2024-06-06T10:19:04.000Z" },
        { speed: "8.4", course: "90.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.776424", lon: "12722.928946", latD: "N", lonD: "E", latitude: 0.7629404, longitude: 127.3821491, timestamp: "2024-06-06T10:19:04.000Z" },
        { speed: "8.4", course: "90.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.776424", lon: "12722.928946", latD: "N", lonD: "E", latitude: 0.7629404, longitude: 127.3821491, timestamp: "2024-06-06T10:19:04.000Z" },
        { speed: "8.2", course: "84.8", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.777653", lon: "12722.976852", latD: "N", lonD: "E", latitude: 0.7629608833333333, longitude: 127.38294753333334, timestamp: "2024-06-06T10:19:25.000Z" },
        { speed: "8.2", course: "84.8", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.777653", lon: "12722.976852", latD: "N", lonD: "E", latitude: 0.7629608833333333, longitude: 127.38294753333334, timestamp: "2024-06-06T10:19:25.000Z" },
        { speed: "8.2", course: "84.8", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.777653", lon: "12722.976852", latD: "N", lonD: "E", latitude: 0.7629608833333333, longitude: 127.38294753333334, timestamp: "2024-06-06T10:19:25.000Z" },
        { speed: "8.5", course: "79.8", altitude: "5.0", satellites: "10", hdop: "0.6", lat: "0045.783408", lon: "12723.020496", latD: "N", lonD: "E", latitude: 0.7630568, longitude: 127.38367493333334, timestamp: "2024-06-06T10:19:44.000Z" },
        { speed: "8.5", course: "79.8", altitude: "5.0", satellites: "10", hdop: "0.6", lat: "0045.783408", lon: "12723.020496", latD: "N", lonD: "E", latitude: 0.7630568, longitude: 127.38367493333334, timestamp: "2024-06-06T10:19:44.000Z" },
        { speed: "8.5", course: "79.8", altitude: "5.0", satellites: "10", hdop: "0.6", lat: "0045.783408", lon: "12723.020496", latD: "N", lonD: "E", latitude: 0.7630568, longitude: 127.38367493333334, timestamp: "2024-06-06T10:19:44.000Z" },
        { speed: "8.4", course: "75.3", altitude: "3.2", satellites: "10", hdop: "0.6", lat: "0045.794860", lon: "12723.065226", latD: "N", lonD: "E", latitude: 0.7632476666666667, longitude: 127.38442043333333, timestamp: "2024-06-06T10:20:04.000Z" },
        { speed: "8.5", course: "79.8", altitude: "5.0", satellites: "10", hdop: "0.6", lat: "0045.783408", lon: "12723.020496", latD: "N", lonD: "E", latitude: 0.7630568, longitude: 127.38367493333334, timestamp: "2024-06-06T10:19:44.000Z" },
        { speed: "8.5", course: "79.8", altitude: "5.0", satellites: "10", hdop: "0.6", lat: "0045.783408", lon: "12723.020496", latD: "N", lonD: "E", latitude: 0.7630568, longitude: 127.38367493333334, timestamp: "2024-06-06T10:19:44.000Z" },
        { speed: "8.4", course: "75.3", altitude: "3.2", satellites: "10", hdop: "0.6", lat: "0045.794860", lon: "12723.065226", latD: "N", lonD: "E", latitude: 0.7632476666666667, longitude: 127.38442043333333, timestamp: "2024-06-06T10:20:04.000Z" },
        { speed: "8.4", course: "75.3", altitude: "3.2", satellites: "10", hdop: "0.6", lat: "0045.794860", lon: "12723.065226", latD: "N", lonD: "E", latitude: 0.7632476666666667, longitude: 127.38442043333333, timestamp: "2024-06-06T10:20:04.000Z" },
        { speed: "9.3", course: "72.7", altitude: "7.2", satellites: "10", hdop: "0.6", lat: "0045.808806", lon: "12723.110233", latD: "N", lonD: "E", latitude: 0.7634801, longitude: 127.38517055, timestamp: "2024-06-06T10:20:25.000Z" },
        { speed: "8.4", course: "75.3", altitude: "3.2", satellites: "10", hdop: "0.6", lat: "0045.794860", lon: "12723.065226", latD: "N", lonD: "E", latitude: 0.7632476666666667, longitude: 127.38442043333333, timestamp: "2024-06-06T10:20:04.000Z" },
        { speed: "9.3", course: "72.7", altitude: "7.2", satellites: "10", hdop: "0.6", lat: "0045.808806", lon: "12723.110233", latD: "N", lonD: "E", latitude: 0.7634801, longitude: 127.38517055, timestamp: "2024-06-06T10:20:25.000Z" },
        { speed: "9.3", course: "72.7", altitude: "7.2", satellites: "10", hdop: "0.6", lat: "0045.808806", lon: "12723.110233", latD: "N", lonD: "E", latitude: 0.7634801, longitude: 127.38517055, timestamp: "2024-06-06T10:20:25.000Z" },
        { speed: "9.3", course: "72.7", altitude: "7.2", satellites: "10", hdop: "0.6", lat: "0045.808806", lon: "12723.110233", latD: "N", lonD: "E", latitude: 0.7634801, longitude: 127.38517055, timestamp: "2024-06-06T10:20:25.000Z" },
        { speed: "8.2", course: "69.5", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.822928", lon: "12723.151997", latD: "N", lonD: "E", latitude: 0.7637154666666667, longitude: 127.38586661666666, timestamp: "2024-06-06T10:20:44.000Z" },
        { speed: "8.2", course: "69.5", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.822928", lon: "12723.151997", latD: "N", lonD: "E", latitude: 0.7637154666666667, longitude: 127.38586661666666, timestamp: "2024-06-06T10:20:44.000Z" },
        { speed: "8.2", course: "69.5", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.822928", lon: "12723.151997", latD: "N", lonD: "E", latitude: 0.7637154666666667, longitude: 127.38586661666666, timestamp: "2024-06-06T10:20:44.000Z" },
        { speed: "8.5", course: "69.0", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.840883", lon: "12723.197237", latD: "N", lonD: "E", latitude: 0.7640147166666666, longitude: 127.38662061666666, timestamp: "2024-06-06T10:21:05.000Z" },
        { speed: "8.5", course: "69.0", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.840883", lon: "12723.197237", latD: "N", lonD: "E", latitude: 0.7640147166666666, longitude: 127.38662061666666, timestamp: "2024-06-06T10:21:05.000Z" },
        { speed: "8.5", course: "69.0", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.840883", lon: "12723.197237", latD: "N", lonD: "E", latitude: 0.7640147166666666, longitude: 127.38662061666666, timestamp: "2024-06-06T10:21:05.000Z" },
        { speed: "8.5", course: "69.0", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.840883", lon: "12723.197237", latD: "N", lonD: "E", latitude: 0.7640147166666666, longitude: 127.38662061666666, timestamp: "2024-06-06T10:21:05.000Z" },
        { speed: "8.5", course: "69.0", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.840883", lon: "12723.197237", latD: "N", lonD: "E", latitude: 0.7640147166666666, longitude: 127.38662061666666, timestamp: "2024-06-06T10:21:05.000Z" },
        { speed: "8.4", course: "70.6", altitude: "6.1", satellites: "10", hdop: "0.6", lat: "0045.857468", lon: "12723.240140", latD: "N", lonD: "E", latitude: 0.7642911333333333, longitude: 127.38733566666667, timestamp: "2024-06-06T10:21:25.000Z" },
        { speed: "8.8", course: "72.4", altitude: "6.7", satellites: "09", hdop: "0.6", lat: "0045.872705", lon: "12723.283440", latD: "N", lonD: "E", latitude: 0.7645450833333334, longitude: 127.38805733333334, timestamp: "2024-06-06T10:21:44.000Z" },
        { speed: "8.8", course: "72.4", altitude: "6.7", satellites: "09", hdop: "0.6", lat: "0045.872705", lon: "12723.283440", latD: "N", lonD: "E", latitude: 0.7645450833333334, longitude: 127.38805733333334, timestamp: "2024-06-06T10:21:44.000Z" },
        { speed: "8.4", course: "70.6", altitude: "6.1", satellites: "10", hdop: "0.6", lat: "0045.857468", lon: "12723.240140", latD: "N", lonD: "E", latitude: 0.7642911333333333, longitude: 127.38733566666667, timestamp: "2024-06-06T10:21:25.000Z" },
        { speed: "8.4", course: "70.6", altitude: "6.1", satellites: "10", hdop: "0.6", lat: "0045.857468", lon: "12723.240140", latD: "N", lonD: "E", latitude: 0.7642911333333333, longitude: 127.38733566666667, timestamp: "2024-06-06T10:21:25.000Z" },
        { speed: "8.8", course: "72.4", altitude: "6.7", satellites: "09", hdop: "0.6", lat: "0045.872705", lon: "12723.283440", latD: "N", lonD: "E", latitude: 0.7645450833333334, longitude: 127.38805733333334, timestamp: "2024-06-06T10:21:44.000Z" },
        { speed: "8.3", course: "75.9", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.886322", lon: "12723.331904", latD: "N", lonD: "E", latitude: 0.7647720333333333, longitude: 127.38886506666667, timestamp: "2024-06-06T10:22:05.000Z" },
        { speed: "8.3", course: "75.9", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.886322", lon: "12723.331904", latD: "N", lonD: "E", latitude: 0.7647720333333333, longitude: 127.38886506666667, timestamp: "2024-06-06T10:22:05.000Z" },
        { speed: "8.3", course: "75.9", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.886322", lon: "12723.331904", latD: "N", lonD: "E", latitude: 0.7647720333333333, longitude: 127.38886506666667, timestamp: "2024-06-06T10:22:05.000Z" },
        { speed: "8.3", course: "75.9", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.886322", lon: "12723.331904", latD: "N", lonD: "E", latitude: 0.7647720333333333, longitude: 127.38886506666667, timestamp: "2024-06-06T10:22:05.000Z" },
        { speed: "8.3", course: "75.9", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.886322", lon: "12723.331904", latD: "N", lonD: "E", latitude: 0.7647720333333333, longitude: 127.38886506666667, timestamp: "2024-06-06T10:22:05.000Z" },
        { speed: "8.3", course: "75.9", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.886322", lon: "12723.331904", latD: "N", lonD: "E", latitude: 0.7647720333333333, longitude: 127.38886506666667, timestamp: "2024-06-06T10:22:05.000Z" },
        { speed: "8.2", course: "77.5", altitude: "4.8", satellites: "10", hdop: "0.6", lat: "0045.896729", lon: "12723.377231", latD: "N", lonD: "E", latitude: 0.7649454833333333, longitude: 127.38962051666667, timestamp: "2024-06-06T10:22:25.000Z" },
        { speed: "8.2", course: "77.5", altitude: "4.8", satellites: "10", hdop: "0.6", lat: "0045.896729", lon: "12723.377231", latD: "N", lonD: "E", latitude: 0.7649454833333333, longitude: 127.38962051666667, timestamp: "2024-06-06T10:22:25.000Z" },
        { speed: "8.6", course: "78.1", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0045.907902", lon: "12723.421883", latD: "N", lonD: "E", latitude: 0.7651317, longitude: 127.39036471666667, timestamp: "2024-06-06T10:22:44.000Z" },
        { speed: "8.6", course: "78.1", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0045.907902", lon: "12723.421883", latD: "N", lonD: "E", latitude: 0.7651317, longitude: 127.39036471666667, timestamp: "2024-06-06T10:22:44.000Z" },
        { speed: "8.6", course: "78.1", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0045.907902", lon: "12723.421883", latD: "N", lonD: "E", latitude: 0.7651317, longitude: 127.39036471666667, timestamp: "2024-06-06T10:22:44.000Z" },
        { speed: "8.6", course: "78.1", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0045.907902", lon: "12723.421883", latD: "N", lonD: "E", latitude: 0.7651317, longitude: 127.39036471666667, timestamp: "2024-06-06T10:22:44.000Z" },
        { speed: "8.2", course: "71.4", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.918070", lon: "12723.468252", latD: "N", lonD: "E", latitude: 0.7653011666666667, longitude: 127.39113753333334, timestamp: "2024-06-06T10:23:05.000Z" },
        { speed: "8.6", course: "78.1", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0045.907902", lon: "12723.421883", latD: "N", lonD: "E", latitude: 0.7651317, longitude: 127.39036471666667, timestamp: "2024-06-06T10:22:44.000Z" },
        { speed: "8.6", course: "78.1", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0045.907902", lon: "12723.421883", latD: "N", lonD: "E", latitude: 0.7651317, longitude: 127.39036471666667, timestamp: "2024-06-06T10:22:44.000Z" },
        { speed: "8.2", course: "71.4", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.918070", lon: "12723.468252", latD: "N", lonD: "E", latitude: 0.7653011666666667, longitude: 127.39113753333334, timestamp: "2024-06-06T10:23:05.000Z" },
        { speed: "8.2", course: "71.4", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.918070", lon: "12723.468252", latD: "N", lonD: "E", latitude: 0.7653011666666667, longitude: 127.39113753333334, timestamp: "2024-06-06T10:23:05.000Z" },
        { speed: "8.5", course: "69.3", altitude: "5.8", satellites: "10", hdop: "0.6", lat: "0045.934526", lon: "12723.511450", latD: "N", lonD: "E", latitude: 0.7655754333333333, longitude: 127.3918575, timestamp: "2024-06-06T10:23:25.000Z" },
        { speed: "8.5", course: "69.3", altitude: "5.8", satellites: "10", hdop: "0.6", lat: "0045.934526", lon: "12723.511450", latD: "N", lonD: "E", latitude: 0.7655754333333333, longitude: 127.3918575, timestamp: "2024-06-06T10:23:25.000Z" },
        { speed: "8.5", course: "69.3", altitude: "5.8", satellites: "10", hdop: "0.6", lat: "0045.934526", lon: "12723.511450", latD: "N", lonD: "E", latitude: 0.7655754333333333, longitude: 127.3918575, timestamp: "2024-06-06T10:23:25.000Z" },
        { speed: "8.6", course: "68.5", altitude: "8.5", satellites: "10", hdop: "0.6", lat: "0045.951402", lon: "12723.553953", latD: "N", lonD: "E", latitude: 0.7658567000000001, longitude: 127.39256588333333, timestamp: "2024-06-06T10:23:45.000Z" },
        { speed: "8.6", course: "68.5", altitude: "8.5", satellites: "10", hdop: "0.6", lat: "0045.951402", lon: "12723.553953", latD: "N", lonD: "E", latitude: 0.7658567000000001, longitude: 127.39256588333333, timestamp: "2024-06-06T10:23:45.000Z" },
        { speed: "8.6", course: "68.5", altitude: "8.5", satellites: "10", hdop: "0.6", lat: "0045.951402", lon: "12723.553953", latD: "N", lonD: "E", latitude: 0.7658567000000001, longitude: 127.39256588333333, timestamp: "2024-06-06T10:23:45.000Z" },
        { speed: "8.6", course: "68.5", altitude: "8.5", satellites: "10", hdop: "0.6", lat: "0045.951402", lon: "12723.553953", latD: "N", lonD: "E", latitude: 0.7658567000000001, longitude: 127.39256588333333, timestamp: "2024-06-06T10:23:45.000Z" },
        { speed: "8.2", course: "89.2", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0046.007305", lon: "12723.868264", latD: "N", lonD: "E", latitude: 0.7667884166666668, longitude: 127.3978044, timestamp: "2024-06-06T10:26:05.000Z" },
        { speed: "8.2", course: "89.2", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0046.007305", lon: "12723.868264", latD: "N", lonD: "E", latitude: 0.7667884166666668, longitude: 127.3978044, timestamp: "2024-06-06T10:26:05.000Z" },
        { speed: "8.2", course: "89.2", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0046.007305", lon: "12723.868264", latD: "N", lonD: "E", latitude: 0.7667884166666668, longitude: 127.3978044, timestamp: "2024-06-06T10:26:05.000Z" },
        { speed: "8.2", course: "89.2", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0046.007305", lon: "12723.868264", latD: "N", lonD: "E", latitude: 0.7667884166666668, longitude: 127.3978044, timestamp: "2024-06-06T10:26:05.000Z" },
        { speed: "8.1", course: "95.4", altitude: "11.6", satellites: "10", hdop: "0.6", lat: "0046.005023", lon: "12723.912961", latD: "N", lonD: "E", latitude: 0.7667503833333333, longitude: 127.39854935, timestamp: "2024-06-06T10:26:25.000Z" },
        { speed: "8.6", course: "96.9", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0046.000709", lon: "12723.958343", latD: "N", lonD: "E", latitude: 0.7666784833333333, longitude: 127.39930571666666, timestamp: "2024-06-06T10:26:45.000Z" },
        { speed: "8.6", course: "96.9", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0046.000709", lon: "12723.958343", latD: "N", lonD: "E", latitude: 0.7666784833333333, longitude: 127.39930571666666, timestamp: "2024-06-06T10:26:45.000Z" },
        { speed: "8.1", course: "95.4", altitude: "11.6", satellites: "10", hdop: "0.6", lat: "0046.005023", lon: "12723.912961", latD: "N", lonD: "E", latitude: 0.7667503833333333, longitude: 127.39854935, timestamp: "2024-06-06T10:26:25.000Z" },
        { speed: "8.1", course: "95.4", altitude: "11.6", satellites: "10", hdop: "0.6", lat: "0046.005023", lon: "12723.912961", latD: "N", lonD: "E", latitude: 0.7667503833333333, longitude: 127.39854935, timestamp: "2024-06-06T10:26:25.000Z" },
        { speed: "8.6", course: "96.9", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0046.000709", lon: "12723.958343", latD: "N", lonD: "E", latitude: 0.7666784833333333, longitude: 127.39930571666666, timestamp: "2024-06-06T10:26:45.000Z" },
        { speed: "7.7", course: "95.3", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.996614", lon: "12724.004139", latD: "N", lonD: "E", latitude: 0.7666102333333333, longitude: 127.40006898333333, timestamp: "2024-06-06T10:27:05.000Z" },
        { speed: "7.7", course: "95.3", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.996614", lon: "12724.004139", latD: "N", lonD: "E", latitude: 0.7666102333333333, longitude: 127.40006898333333, timestamp: "2024-06-06T10:27:05.000Z" },
        { speed: "7.7", course: "95.3", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.996614", lon: "12724.004139", latD: "N", lonD: "E", latitude: 0.7666102333333333, longitude: 127.40006898333333, timestamp: "2024-06-06T10:27:05.000Z" },
        { speed: "7.9", course: "93.9", altitude: "14.5", satellites: "10", hdop: "0.6", lat: "0045.993274", lon: "12724.050154", latD: "N", lonD: "E", latitude: 0.7665545666666667, longitude: 127.4008359, timestamp: "2024-06-06T10:27:25.000Z" },
        { speed: "7.9", course: "93.9", altitude: "14.5", satellites: "10", hdop: "0.6", lat: "0045.993274", lon: "12724.050154", latD: "N", lonD: "E", latitude: 0.7665545666666667, longitude: 127.4008359, timestamp: "2024-06-06T10:27:25.000Z" },
        { speed: "7.9", course: "93.9", altitude: "14.5", satellites: "10", hdop: "0.6", lat: "0045.993274", lon: "12724.050154", latD: "N", lonD: "E", latitude: 0.7665545666666667, longitude: 127.4008359, timestamp: "2024-06-06T10:27:25.000Z" },
        { speed: "7.7", course: "95.3", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.996614", lon: "12724.004139", latD: "N", lonD: "E", latitude: 0.7666102333333333, longitude: 127.40006898333333, timestamp: "2024-06-06T10:27:05.000Z" },
        { speed: "7.9", course: "93.9", altitude: "14.5", satellites: "10", hdop: "0.6", lat: "0045.993274", lon: "12724.050154", latD: "N", lonD: "E", latitude: 0.7665545666666667, longitude: 127.4008359, timestamp: "2024-06-06T10:27:25.000Z" },
        { speed: "8.0", course: "89.7", altitude: "14.2", satellites: "10", hdop: "0.6", lat: "0045.992597", lon: "12724.093387", latD: "N", lonD: "E", latitude: 0.7665432833333334, longitude: 127.40155645, timestamp: "2024-06-06T10:27:45.000Z" },
        { speed: "8.0", course: "89.7", altitude: "14.2", satellites: "10", hdop: "0.6", lat: "0045.992597", lon: "12724.093387", latD: "N", lonD: "E", latitude: 0.7665432833333334, longitude: 127.40155645, timestamp: "2024-06-06T10:27:45.000Z" },
        { speed: "8.0", course: "89.7", altitude: "14.2", satellites: "10", hdop: "0.6", lat: "0045.992597", lon: "12724.093387", latD: "N", lonD: "E", latitude: 0.7665432833333334, longitude: 127.40155645, timestamp: "2024-06-06T10:27:45.000Z" },
        { speed: "8.0", course: "89.7", altitude: "14.2", satellites: "10", hdop: "0.6", lat: "0045.992597", lon: "12724.093387", latD: "N", lonD: "E", latitude: 0.7665432833333334, longitude: 127.40155645, timestamp: "2024-06-06T10:27:45.000Z" },
        { speed: "8.0", course: "89.7", altitude: "14.2", satellites: "10", hdop: "0.6", lat: "0045.992597", lon: "12724.093387", latD: "N", lonD: "E", latitude: 0.7665432833333334, longitude: 127.40155645, timestamp: "2024-06-06T10:27:45.000Z" },
        { speed: "8.0", course: "89.7", altitude: "14.2", satellites: "10", hdop: "0.6", lat: "0045.992597", lon: "12724.093387", latD: "N", lonD: "E", latitude: 0.7665432833333334, longitude: 127.40155645, timestamp: "2024-06-06T10:27:45.000Z" },
        { speed: "8.3", course: "88.7", altitude: "14.7", satellites: "10", hdop: "0.6", lat: "0045.991840", lon: "12724.137495", latD: "N", lonD: "E", latitude: 0.7665306666666667, longitude: 127.40229158333334, timestamp: "2024-06-06T10:28:05.000Z" },
        { speed: "8.3", course: "88.7", altitude: "14.7", satellites: "10", hdop: "0.6", lat: "0045.991840", lon: "12724.137495", latD: "N", lonD: "E", latitude: 0.7665306666666667, longitude: 127.40229158333334, timestamp: "2024-06-06T10:28:05.000Z" },
        { speed: "7.7", course: "91.1", altitude: "17.3", satellites: "10", hdop: "0.6", lat: "0045.990990", lon: "12724.181882", latD: "N", lonD: "E", latitude: 0.7665164999999999, longitude: 127.40303136666667, timestamp: "2024-06-06T10:28:25.000Z" },
        { speed: "8.3", course: "88.7", altitude: "14.7", satellites: "10", hdop: "0.6", lat: "0045.991840", lon: "12724.137495", latD: "N", lonD: "E", latitude: 0.7665306666666667, longitude: 127.40229158333334, timestamp: "2024-06-06T10:28:05.000Z" },
        { speed: "8.3", course: "88.7", altitude: "14.7", satellites: "10", hdop: "0.6", lat: "0045.991840", lon: "12724.137495", latD: "N", lonD: "E", latitude: 0.7665306666666667, longitude: 127.40229158333334, timestamp: "2024-06-06T10:28:05.000Z" },
        { speed: "7.7", course: "91.1", altitude: "17.3", satellites: "10", hdop: "0.6", lat: "0045.990990", lon: "12724.181882", latD: "N", lonD: "E", latitude: 0.7665164999999999, longitude: 127.40303136666667, timestamp: "2024-06-06T10:28:25.000Z" },
        { speed: "7.7", course: "91.1", altitude: "17.3", satellites: "10", hdop: "0.6", lat: "0045.990990", lon: "12724.181882", latD: "N", lonD: "E", latitude: 0.7665164999999999, longitude: 127.40303136666667, timestamp: "2024-06-06T10:28:25.000Z" },
        { speed: "8.1", course: "88.6", altitude: "17.8", satellites: "10", hdop: "0.6", lat: "0045.988839", lon: "12724.227585", latD: "N", lonD: "E", latitude: 0.76648065, longitude: 127.40379308333333, timestamp: "2024-06-06T10:28:45.000Z" },
        { speed: "7.7", course: "91.1", altitude: "17.3", satellites: "10", hdop: "0.6", lat: "0045.990990", lon: "12724.181882", latD: "N", lonD: "E", latitude: 0.7665164999999999, longitude: 127.40303136666667, timestamp: "2024-06-06T10:28:25.000Z" },
        { speed: "8.1", course: "88.6", altitude: "17.8", satellites: "10", hdop: "0.6", lat: "0045.988839", lon: "12724.227585", latD: "N", lonD: "E", latitude: 0.76648065, longitude: 127.40379308333333, timestamp: "2024-06-06T10:28:45.000Z" },
        { speed: "8.1", course: "88.6", altitude: "17.8", satellites: "10", hdop: "0.6", lat: "0045.988839", lon: "12724.227585", latD: "N", lonD: "E", latitude: 0.76648065, longitude: 127.40379308333333, timestamp: "2024-06-06T10:28:45.000Z" },
        { speed: "8.1", course: "88.6", altitude: "17.8", satellites: "10", hdop: "0.6", lat: "0045.988839", lon: "12724.227585", latD: "N", lonD: "E", latitude: 0.76648065, longitude: 127.40379308333333, timestamp: "2024-06-06T10:28:45.000Z" },
        { speed: "8.1", course: "91.2", altitude: "15.2", satellites: "10", hdop: "0.6", lat: "0045.987514", lon: "12724.271823", latD: "N", lonD: "E", latitude: 0.7664585666666667, longitude: 127.40453038333334, timestamp: "2024-06-06T10:29:05.000Z" },
        { speed: "8.1", course: "91.2", altitude: "15.2", satellites: "10", hdop: "0.6", lat: "0045.987514", lon: "12724.271823", latD: "N", lonD: "E", latitude: 0.7664585666666667, longitude: 127.40453038333334, timestamp: "2024-06-06T10:29:05.000Z" },
        { speed: "8.1", course: "91.2", altitude: "15.2", satellites: "10", hdop: "0.6", lat: "0045.987514", lon: "12724.271823", latD: "N", lonD: "E", latitude: 0.7664585666666667, longitude: 127.40453038333334, timestamp: "2024-06-06T10:29:05.000Z" },
        { speed: "8.0", course: "92.3", altitude: "12.0", satellites: "09", hdop: "0.6", lat: "0045.987338", lon: "12724.317586", latD: "N", lonD: "E", latitude: 0.7664556333333333, longitude: 127.4052931, timestamp: "2024-06-06T10:29:25.000Z" },
        { speed: "8.0", course: "92.3", altitude: "12.0", satellites: "09", hdop: "0.6", lat: "0045.987338", lon: "12724.317586", latD: "N", lonD: "E", latitude: 0.7664556333333333, longitude: 127.4052931, timestamp: "2024-06-06T10:29:25.000Z" },
        { speed: "8.0", course: "92.3", altitude: "12.0", satellites: "09", hdop: "0.6", lat: "0045.987338", lon: "12724.317586", latD: "N", lonD: "E", latitude: 0.7664556333333333, longitude: 127.4052931, timestamp: "2024-06-06T10:29:25.000Z" },
        { speed: "8.5", course: "91.4", altitude: "15.4", satellites: "10", hdop: "0.6", lat: "0045.985200", lon: "12724.362648", latD: "N", lonD: "E", latitude: 0.76642, longitude: 127.40604413333334, timestamp: "2024-06-06T10:29:45.000Z" },
        { speed: "8.0", course: "92.3", altitude: "12.0", satellites: "09", hdop: "0.6", lat: "0045.987338", lon: "12724.317586", latD: "N", lonD: "E", latitude: 0.7664556333333333, longitude: 127.4052931, timestamp: "2024-06-06T10:29:25.000Z" },
        { speed: "8.0", course: "92.3", altitude: "12.0", satellites: "09", hdop: "0.6", lat: "0045.987338", lon: "12724.317586", latD: "N", lonD: "E", latitude: 0.7664556333333333, longitude: 127.4052931, timestamp: "2024-06-06T10:29:25.000Z" },
        { speed: "8.5", course: "91.4", altitude: "15.4", satellites: "10", hdop: "0.6", lat: "0045.985200", lon: "12724.362648", latD: "N", lonD: "E", latitude: 0.76642, longitude: 127.40604413333334, timestamp: "2024-06-06T10:29:45.000Z" },
        { speed: "8.3", course: "86.2", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.986176", lon: "12724.406083", latD: "N", lonD: "E", latitude: 0.7664362666666666, longitude: 127.40676805, timestamp: "2024-06-06T10:30:05.000Z" },
        { speed: "8.3", course: "86.2", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.986176", lon: "12724.406083", latD: "N", lonD: "E", latitude: 0.7664362666666666, longitude: 127.40676805, timestamp: "2024-06-06T10:30:05.000Z" },
        { speed: "8.3", course: "86.2", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.986176", lon: "12724.406083", latD: "N", lonD: "E", latitude: 0.7664362666666666, longitude: 127.40676805, timestamp: "2024-06-06T10:30:05.000Z" },
        { speed: "8.3", course: "86.2", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.986176", lon: "12724.406083", latD: "N", lonD: "E", latitude: 0.7664362666666666, longitude: 127.40676805, timestamp: "2024-06-06T10:30:05.000Z" },
        { speed: "8.6", course: "84.7", altitude: "18.8", satellites: "10", hdop: "0.6", lat: "0045.988416", lon: "12724.450451", latD: "N", lonD: "E", latitude: 0.7664736, longitude: 127.40750751666667, timestamp: "2024-06-06T10:30:25.000Z" },
        { speed: "8.6", course: "84.7", altitude: "18.8", satellites: "10", hdop: "0.6", lat: "0045.988416", lon: "12724.450451", latD: "N", lonD: "E", latitude: 0.7664736, longitude: 127.40750751666667, timestamp: "2024-06-06T10:30:25.000Z" },
        { speed: "8.3", course: "86.2", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.986176", lon: "12724.406083", latD: "N", lonD: "E", latitude: 0.7664362666666666, longitude: 127.40676805, timestamp: "2024-06-06T10:30:05.000Z" },
        { speed: "8.6", course: "84.7", altitude: "18.8", satellites: "10", hdop: "0.6", lat: "0045.988416", lon: "12724.450451", latD: "N", lonD: "E", latitude: 0.7664736, longitude: 127.40750751666667, timestamp: "2024-06-06T10:30:25.000Z" },
        { speed: "8.6", course: "84.7", altitude: "18.8", satellites: "10", hdop: "0.6", lat: "0045.988416", lon: "12724.450451", latD: "N", lonD: "E", latitude: 0.7664736, longitude: 127.40750751666667, timestamp: "2024-06-06T10:30:25.000Z" },
        { speed: "8.5", course: "86.5", altitude: "12.7", satellites: "10", hdop: "0.6", lat: "0045.991487", lon: "12724.495965", latD: "N", lonD: "E", latitude: 0.7665247833333333, longitude: 127.40826608333333, timestamp: "2024-06-06T10:30:45.000Z" },
        { speed: "8.5", course: "86.5", altitude: "12.7", satellites: "10", hdop: "0.6", lat: "0045.991487", lon: "12724.495965", latD: "N", lonD: "E", latitude: 0.7665247833333333, longitude: 127.40826608333333, timestamp: "2024-06-06T10:30:45.000Z" },
        { speed: "8.5", course: "86.5", altitude: "12.7", satellites: "10", hdop: "0.6", lat: "0045.991487", lon: "12724.495965", latD: "N", lonD: "E", latitude: 0.7665247833333333, longitude: 127.40826608333333, timestamp: "2024-06-06T10:30:45.000Z" },
        { speed: "8.5", course: "86.5", altitude: "12.7", satellites: "10", hdop: "0.6", lat: "0045.991487", lon: "12724.495965", latD: "N", lonD: "E", latitude: 0.7665247833333333, longitude: 127.40826608333333, timestamp: "2024-06-06T10:30:45.000Z" },
        { speed: "8.5", course: "86.5", altitude: "12.7", satellites: "10", hdop: "0.6", lat: "0045.991487", lon: "12724.495965", latD: "N", lonD: "E", latitude: 0.7665247833333333, longitude: 127.40826608333333, timestamp: "2024-06-06T10:30:45.000Z" },
        { speed: "8.2", course: "86.3", altitude: "8.5", satellites: "10", hdop: "0.6", lat: "0045.993466", lon: "12724.541645", latD: "N", lonD: "E", latitude: 0.7665577666666666, longitude: 127.40902741666666, timestamp: "2024-06-06T10:31:05.000Z" },
        { speed: "8.2", course: "86.3", altitude: "8.5", satellites: "10", hdop: "0.6", lat: "0045.993466", lon: "12724.541645", latD: "N", lonD: "E", latitude: 0.7665577666666666, longitude: 127.40902741666666, timestamp: "2024-06-06T10:31:05.000Z" },
        { speed: "8.2", course: "86.3", altitude: "8.5", satellites: "10", hdop: "0.6", lat: "0045.993466", lon: "12724.541645", latD: "N", lonD: "E", latitude: 0.7665577666666666, longitude: 127.40902741666666, timestamp: "2024-06-06T10:31:05.000Z" },
        { speed: "8.2", course: "86.3", altitude: "8.5", satellites: "10", hdop: "0.6", lat: "0045.993466", lon: "12724.541645", latD: "N", lonD: "E", latitude: 0.7665577666666666, longitude: 127.40902741666666, timestamp: "2024-06-06T10:31:05.000Z" },
        { speed: "8.3", course: "84.8", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.997515", lon: "12724.588324", latD: "N", lonD: "E", latitude: 0.76662525, longitude: 127.4098054, timestamp: "2024-06-06T10:31:25.000Z" },
        { speed: "8.2", course: "85.1", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0046.001747", lon: "12724.635413", latD: "N", lonD: "E", latitude: 0.7666957833333333, longitude: 127.41059021666666, timestamp: "2024-06-06T10:31:45.000Z" },
        { speed: "8.3", course: "84.8", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.997515", lon: "12724.588324", latD: "N", lonD: "E", latitude: 0.76662525, longitude: 127.4098054, timestamp: "2024-06-06T10:31:25.000Z" },
        { speed: "8.3", course: "84.8", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.997515", lon: "12724.588324", latD: "N", lonD: "E", latitude: 0.76662525, longitude: 127.4098054, timestamp: "2024-06-06T10:31:25.000Z" },
        { speed: "8.3", course: "84.8", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.997515", lon: "12724.588324", latD: "N", lonD: "E", latitude: 0.76662525, longitude: 127.4098054, timestamp: "2024-06-06T10:31:25.000Z" },
        { speed: "8.2", course: "85.1", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0046.001747", lon: "12724.635413", latD: "N", lonD: "E", latitude: 0.7666957833333333, longitude: 127.41059021666666, timestamp: "2024-06-06T10:31:45.000Z" },
        { speed: "8.1", course: "84.9", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0046.006525", lon: "12724.680680", latD: "N", lonD: "E", latitude: 0.7667754166666667, longitude: 127.41134466666666, timestamp: "2024-06-06T10:32:05.000Z" },
        { speed: "8.1", course: "84.9", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0046.006525", lon: "12724.680680", latD: "N", lonD: "E", latitude: 0.7667754166666667, longitude: 127.41134466666666, timestamp: "2024-06-06T10:32:05.000Z" },
        { speed: "8.1", course: "84.9", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0046.006525", lon: "12724.680680", latD: "N", lonD: "E", latitude: 0.7667754166666667, longitude: 127.41134466666666, timestamp: "2024-06-06T10:32:05.000Z" },
        { speed: "8.1", course: "84.9", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0046.006525", lon: "12724.680680", latD: "N", lonD: "E", latitude: 0.7667754166666667, longitude: 127.41134466666666, timestamp: "2024-06-06T10:32:05.000Z" },
        { speed: "8.1", course: "84.9", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0046.006525", lon: "12724.680680", latD: "N", lonD: "E", latitude: 0.7667754166666667, longitude: 127.41134466666666, timestamp: "2024-06-06T10:32:05.000Z" },
        { speed: "8.1", course: "84.9", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0046.006525", lon: "12724.680680", latD: "N", lonD: "E", latitude: 0.7667754166666667, longitude: 127.41134466666666, timestamp: "2024-06-06T10:32:05.000Z" },
        { speed: "8.1", course: "88.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0046.006996", lon: "12724.727594", latD: "N", lonD: "E", latitude: 0.7667832666666666, longitude: 127.41212656666667, timestamp: "2024-06-06T10:32:25.000Z" },
        { speed: "8.1", course: "88.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0046.006996", lon: "12724.727594", latD: "N", lonD: "E", latitude: 0.7667832666666666, longitude: 127.41212656666667, timestamp: "2024-06-06T10:32:25.000Z" },
        { speed: "8.3", course: "91.4", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0046.006493", lon: "12724.773437", latD: "N", lonD: "E", latitude: 0.7667748833333333, longitude: 127.41289061666667, timestamp: "2024-06-06T10:32:45.000Z" },
        { speed: "8.3", course: "91.4", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0046.006493", lon: "12724.773437", latD: "N", lonD: "E", latitude: 0.7667748833333333, longitude: 127.41289061666667, timestamp: "2024-06-06T10:32:45.000Z" },
        { speed: "8.1", course: "88.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0046.006996", lon: "12724.727594", latD: "N", lonD: "E", latitude: 0.7667832666666666, longitude: 127.41212656666667, timestamp: "2024-06-06T10:32:25.000Z" },
        { speed: "8.3", course: "91.4", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0046.006493", lon: "12724.773437", latD: "N", lonD: "E", latitude: 0.7667748833333333, longitude: 127.41289061666667, timestamp: "2024-06-06T10:32:45.000Z" },
        { speed: "8.3", course: "91.4", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0046.006493", lon: "12724.773437", latD: "N", lonD: "E", latitude: 0.7667748833333333, longitude: 127.41289061666667, timestamp: "2024-06-06T10:32:45.000Z" },
        { speed: "8.3", course: "91.4", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0046.006493", lon: "12724.773437", latD: "N", lonD: "E", latitude: 0.7667748833333333, longitude: 127.41289061666667, timestamp: "2024-06-06T10:32:45.000Z" },
        { speed: "8.0", course: "96.0", altitude: "3.2", satellites: "10", hdop: "0.6", lat: "0046.002190", lon: "12724.819249", latD: "N", lonD: "E", latitude: 0.7667031666666666, longitude: 127.41365415, timestamp: "2024-06-06T10:33:05.000Z" },
        { speed: "8.0", course: "96.0", altitude: "3.2", satellites: "10", hdop: "0.6", lat: "0046.002190", lon: "12724.819249", latD: "N", lonD: "E", latitude: 0.7667031666666666, longitude: 127.41365415, timestamp: "2024-06-06T10:33:05.000Z" },
        { speed: "8.4", course: "97.9", altitude: "6.9", satellites: "10", hdop: "0.6", lat: "0045.997014", lon: "12724.864566", latD: "N", lonD: "E", latitude: 0.7666169, longitude: 127.41440943333333, timestamp: "2024-06-06T10:33:25.000Z" },
        { speed: "8.4", course: "97.9", altitude: "6.9", satellites: "10", hdop: "0.6", lat: "0045.997014", lon: "12724.864566", latD: "N", lonD: "E", latitude: 0.7666169, longitude: 127.41440943333333, timestamp: "2024-06-06T10:33:25.000Z" },
        { speed: "8.0", course: "96.0", altitude: "3.2", satellites: "10", hdop: "0.6", lat: "0046.002190", lon: "12724.819249", latD: "N", lonD: "E", latitude: 0.7667031666666666, longitude: 127.41365415, timestamp: "2024-06-06T10:33:05.000Z" },
        { speed: "8.4", course: "97.9", altitude: "6.9", satellites: "10", hdop: "0.6", lat: "0045.997014", lon: "12724.864566", latD: "N", lonD: "E", latitude: 0.7666169, longitude: 127.41440943333333, timestamp: "2024-06-06T10:33:25.000Z" },
        { speed: "8.1", course: "97.0", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.990771", lon: "12724.910095", latD: "N", lonD: "E", latitude: 0.76651285, longitude: 127.41516825, timestamp: "2024-06-06T10:33:45.000Z" },
        { speed: "8.1", course: "97.0", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.990771", lon: "12724.910095", latD: "N", lonD: "E", latitude: 0.76651285, longitude: 127.41516825, timestamp: "2024-06-06T10:33:45.000Z" },
        { speed: "8.1", course: "97.0", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.990771", lon: "12724.910095", latD: "N", lonD: "E", latitude: 0.76651285, longitude: 127.41516825, timestamp: "2024-06-06T10:33:45.000Z" },
        { speed: "8.1", course: "97.0", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.990771", lon: "12724.910095", latD: "N", lonD: "E", latitude: 0.76651285, longitude: 127.41516825, timestamp: "2024-06-06T10:33:45.000Z" },
        { speed: "8.1", course: "97.0", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.990771", lon: "12724.910095", latD: "N", lonD: "E", latitude: 0.76651285, longitude: 127.41516825, timestamp: "2024-06-06T10:33:45.000Z" },
        { speed: "8.1", course: "97.0", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.990771", lon: "12724.910095", latD: "N", lonD: "E", latitude: 0.76651285, longitude: 127.41516825, timestamp: "2024-06-06T10:33:45.000Z" },
        { speed: "8.0", course: "96.5", altitude: "7.4", satellites: "10", hdop: "0.6", lat: "0045.986712", lon: "12724.954155", latD: "N", lonD: "E", latitude: 0.7664451999999999, longitude: 127.41590258333333, timestamp: "2024-06-06T10:34:05.000Z" },
        { speed: "8.0", course: "96.5", altitude: "7.4", satellites: "10", hdop: "0.6", lat: "0045.986712", lon: "12724.954155", latD: "N", lonD: "E", latitude: 0.7664451999999999, longitude: 127.41590258333333, timestamp: "2024-06-06T10:34:05.000Z" },
        { speed: "8.5", course: "94.2", altitude: "13.5", satellites: "08", hdop: "0.6", lat: "0045.981351", lon: "12724.998718", latD: "N", lonD: "E", latitude: 0.76635585, longitude: 127.4166453, timestamp: "2024-06-06T10:34:25.000Z" },
        { speed: "8.5", course: "94.2", altitude: "13.5", satellites: "08", hdop: "0.6", lat: "0045.981351", lon: "12724.998718", latD: "N", lonD: "E", latitude: 0.76635585, longitude: 127.4166453, timestamp: "2024-06-06T10:34:25.000Z" },
        { speed: "8.5", course: "94.2", altitude: "13.5", satellites: "08", hdop: "0.6", lat: "0045.981351", lon: "12724.998718", latD: "N", lonD: "E", latitude: 0.76635585, longitude: 127.4166453, timestamp: "2024-06-06T10:34:25.000Z" },
        { speed: "8.1", course: "94.5", altitude: "13.8", satellites: "09", hdop: "0.6", lat: "0045.977825", lon: "12725.043367", latD: "N", lonD: "E", latitude: 0.7662970833333334, longitude: 127.41738945, timestamp: "2024-06-06T10:34:45.000Z" },
        { speed: "8.1", course: "94.5", altitude: "13.8", satellites: "09", hdop: "0.6", lat: "0045.977825", lon: "12725.043367", latD: "N", lonD: "E", latitude: 0.7662970833333334, longitude: 127.41738945, timestamp: "2024-06-06T10:34:45.000Z" },
        { speed: "8.5", course: "94.2", altitude: "13.5", satellites: "08", hdop: "0.6", lat: "0045.981351", lon: "12724.998718", latD: "N", lonD: "E", latitude: 0.76635585, longitude: 127.4166453, timestamp: "2024-06-06T10:34:25.000Z" },
        { speed: "8.5", course: "94.2", altitude: "13.5", satellites: "08", hdop: "0.6", lat: "0045.981351", lon: "12724.998718", latD: "N", lonD: "E", latitude: 0.76635585, longitude: 127.4166453, timestamp: "2024-06-06T10:34:25.000Z" },
        { speed: "8.1", course: "94.5", altitude: "13.8", satellites: "09", hdop: "0.6", lat: "0045.977825", lon: "12725.043367", latD: "N", lonD: "E", latitude: 0.7662970833333334, longitude: 127.41738945, timestamp: "2024-06-06T10:34:45.000Z" },
        { speed: "8.1", course: "94.5", altitude: "13.8", satellites: "09", hdop: "0.6", lat: "0045.977825", lon: "12725.043367", latD: "N", lonD: "E", latitude: 0.7662970833333334, longitude: 127.41738945, timestamp: "2024-06-06T10:34:45.000Z" },
        { speed: "8.2", course: "93.9", altitude: "14.2", satellites: "09", hdop: "0.6", lat: "0045.975005", lon: "12725.087456", latD: "N", lonD: "E", latitude: 0.7662500833333333, longitude: 127.41812426666667, timestamp: "2024-06-06T10:35:05.000Z" },
        { speed: "8.2", course: "93.9", altitude: "14.2", satellites: "09", hdop: "0.6", lat: "0045.975005", lon: "12725.087456", latD: "N", lonD: "E", latitude: 0.7662500833333333, longitude: 127.41812426666667, timestamp: "2024-06-06T10:35:05.000Z" },
        { speed: "8.2", course: "93.9", altitude: "14.2", satellites: "09", hdop: "0.6", lat: "0045.975005", lon: "12725.087456", latD: "N", lonD: "E", latitude: 0.7662500833333333, longitude: 127.41812426666667, timestamp: "2024-06-06T10:35:05.000Z" },
        { speed: "8.2", course: "93.9", altitude: "14.2", satellites: "09", hdop: "0.6", lat: "0045.975005", lon: "12725.087456", latD: "N", lonD: "E", latitude: 0.7662500833333333, longitude: 127.41812426666667, timestamp: "2024-06-06T10:35:05.000Z" },
        { speed: "8.2", course: "93.9", altitude: "14.2", satellites: "09", hdop: "0.6", lat: "0045.975005", lon: "12725.087456", latD: "N", lonD: "E", latitude: 0.7662500833333333, longitude: 127.41812426666667, timestamp: "2024-06-06T10:35:05.000Z" },
        { speed: "8.5", course: "97.3", altitude: "15.6", satellites: "10", hdop: "0.6", lat: "0045.969971", lon: "12725.131270", latD: "N", lonD: "E", latitude: 0.7661661833333333, longitude: 127.4188545, timestamp: "2024-06-06T10:35:25.000Z" },
        { speed: "8.5", course: "97.3", altitude: "15.6", satellites: "10", hdop: "0.6", lat: "0045.969971", lon: "12725.131270", latD: "N", lonD: "E", latitude: 0.7661661833333333, longitude: 127.4188545, timestamp: "2024-06-06T10:35:25.000Z" },
        { speed: "8.1", course: "99.1", altitude: "17.6", satellites: "07", hdop: "0.6", lat: "0045.963486", lon: "12725.177215", latD: "N", lonD: "E", latitude: 0.7660581000000001, longitude: 127.41962025, timestamp: "2024-06-06T10:35:45.000Z" },
        { speed: "8.5", course: "97.3", altitude: "15.6", satellites: "10", hdop: "0.6", lat: "0045.969971", lon: "12725.131270", latD: "N", lonD: "E", latitude: 0.7661661833333333, longitude: 127.4188545, timestamp: "2024-06-06T10:35:25.000Z" },
        { speed: "8.5", course: "97.3", altitude: "15.6", satellites: "10", hdop: "0.6", lat: "0045.969971", lon: "12725.131270", latD: "N", lonD: "E", latitude: 0.7661661833333333, longitude: 127.4188545, timestamp: "2024-06-06T10:35:25.000Z" },
        { speed: "8.1", course: "99.1", altitude: "17.6", satellites: "07", hdop: "0.6", lat: "0045.963486", lon: "12725.177215", latD: "N", lonD: "E", latitude: 0.7660581000000001, longitude: 127.41962025, timestamp: "2024-06-06T10:35:45.000Z" },
        { speed: "8.1", course: "99.1", altitude: "17.6", satellites: "07", hdop: "0.6", lat: "0045.963486", lon: "12725.177215", latD: "N", lonD: "E", latitude: 0.7660581000000001, longitude: 127.41962025, timestamp: "2024-06-06T10:35:45.000Z" },
        { speed: "8.1", course: "99.1", altitude: "17.6", satellites: "07", hdop: "0.6", lat: "0045.963486", lon: "12725.177215", latD: "N", lonD: "E", latitude: 0.7660581000000001, longitude: 127.41962025, timestamp: "2024-06-06T10:35:45.000Z" },
        { speed: "7.9", course: "96.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.954009", lon: "12725.224297", latD: "N", lonD: "E", latitude: 0.76590015, longitude: 127.42040495, timestamp: "2024-06-06T10:36:05.000Z" },
        { speed: "8.7", course: "99.5", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.949791", lon: "12725.266748", latD: "N", lonD: "E", latitude: 0.7658298499999999, longitude: 127.42111246666667, timestamp: "2024-06-06T10:36:25.000Z" },
        { speed: "8.7", course: "99.5", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.949791", lon: "12725.266748", latD: "N", lonD: "E", latitude: 0.7658298499999999, longitude: 127.42111246666667, timestamp: "2024-06-06T10:36:25.000Z" },
        { speed: "7.9", course: "96.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.954009", lon: "12725.224297", latD: "N", lonD: "E", latitude: 0.76590015, longitude: 127.42040495, timestamp: "2024-06-06T10:36:05.000Z" },
        { speed: "7.9", course: "96.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.954009", lon: "12725.224297", latD: "N", lonD: "E", latitude: 0.76590015, longitude: 127.42040495, timestamp: "2024-06-06T10:36:05.000Z" },
        { speed: "8.7", course: "99.5", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.949791", lon: "12725.266748", latD: "N", lonD: "E", latitude: 0.7658298499999999, longitude: 127.42111246666667, timestamp: "2024-06-06T10:36:25.000Z" },
        { speed: "8.7", course: "99.5", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.949791", lon: "12725.266748", latD: "N", lonD: "E", latitude: 0.7658298499999999, longitude: 127.42111246666667, timestamp: "2024-06-06T10:36:25.000Z" },
        { speed: "8.7", course: "99.5", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.949791", lon: "12725.266748", latD: "N", lonD: "E", latitude: 0.7658298499999999, longitude: 127.42111246666667, timestamp: "2024-06-06T10:36:25.000Z" },
        { speed: "8.4", course: "99.3", altitude: "14.0", satellites: "09", hdop: "0.6", lat: "0045.943342", lon: "12725.310617", latD: "N", lonD: "E", latitude: 0.7657223666666667, longitude: 127.42184361666666, timestamp: "2024-06-06T10:36:45.000Z" },
        { speed: "8.4", course: "99.3", altitude: "14.0", satellites: "09", hdop: "0.6", lat: "0045.943342", lon: "12725.310617", latD: "N", lonD: "E", latitude: 0.7657223666666667, longitude: 127.42184361666666, timestamp: "2024-06-06T10:36:45.000Z" },
        { speed: "8.4", course: "99.3", altitude: "14.0", satellites: "09", hdop: "0.6", lat: "0045.943342", lon: "12725.310617", latD: "N", lonD: "E", latitude: 0.7657223666666667, longitude: 127.42184361666666, timestamp: "2024-06-06T10:36:45.000Z" },
        { speed: "8.4", course: "99.3", altitude: "14.0", satellites: "09", hdop: "0.6", lat: "0045.943342", lon: "12725.310617", latD: "N", lonD: "E", latitude: 0.7657223666666667, longitude: 127.42184361666666, timestamp: "2024-06-06T10:36:45.000Z" },
        { speed: "8.0", course: "102.1", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.935449", lon: "12725.356444", latD: "N", lonD: "E", latitude: 0.7655908166666666, longitude: 127.4226074, timestamp: "2024-06-06T10:37:05.000Z" },
        { speed: "8.0", course: "102.1", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.935449", lon: "12725.356444", latD: "N", lonD: "E", latitude: 0.7655908166666666, longitude: 127.4226074, timestamp: "2024-06-06T10:37:05.000Z" },
        { speed: "8.1", course: "102.3", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.922448", lon: "12725.401588", latD: "N", lonD: "E", latitude: 0.7653741333333334, longitude: 127.4233598, timestamp: "2024-06-06T10:37:25.000Z" },
        { speed: "8.0", course: "102.1", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.935449", lon: "12725.356444", latD: "N", lonD: "E", latitude: 0.7655908166666666, longitude: 127.4226074, timestamp: "2024-06-06T10:37:05.000Z" },
        { speed: "8.0", course: "102.1", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.935449", lon: "12725.356444", latD: "N", lonD: "E", latitude: 0.7655908166666666, longitude: 127.4226074, timestamp: "2024-06-06T10:37:05.000Z" },
        { speed: "8.1", course: "102.3", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.922448", lon: "12725.401588", latD: "N", lonD: "E", latitude: 0.7653741333333334, longitude: 127.4233598, timestamp: "2024-06-06T10:37:25.000Z" },
        { speed: "8.1", course: "102.3", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.922448", lon: "12725.401588", latD: "N", lonD: "E", latitude: 0.7653741333333334, longitude: 127.4233598, timestamp: "2024-06-06T10:37:25.000Z" },
        { speed: "7.9", course: "101.9", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.913770", lon: "12725.445791", latD: "N", lonD: "E", latitude: 0.7652295, longitude: 127.42409651666667, timestamp: "2024-06-06T10:37:45.000Z" },
        { speed: "7.9", course: "101.9", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.913770", lon: "12725.445791", latD: "N", lonD: "E", latitude: 0.7652295, longitude: 127.42409651666667, timestamp: "2024-06-06T10:37:45.000Z" },
        { speed: "7.9", course: "101.9", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.913770", lon: "12725.445791", latD: "N", lonD: "E", latitude: 0.7652295, longitude: 127.42409651666667, timestamp: "2024-06-06T10:37:45.000Z" },
        { speed: "8.1", course: "101.5", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0045.905216", lon: "12725.486642", latD: "N", lonD: "E", latitude: 0.7650869333333333, longitude: 127.42477736666666, timestamp: "2024-06-06T10:38:05.000Z" },
        { speed: "7.9", course: "101.9", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.913770", lon: "12725.445791", latD: "N", lonD: "E", latitude: 0.7652295, longitude: 127.42409651666667, timestamp: "2024-06-06T10:37:45.000Z" },
        { speed: "8.1", course: "101.5", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0045.905216", lon: "12725.486642", latD: "N", lonD: "E", latitude: 0.7650869333333333, longitude: 127.42477736666666, timestamp: "2024-06-06T10:38:05.000Z" },
        { speed: "8.1", course: "101.5", altitude: "3.3", satellites: "10", hdop: "0.6", lat: "0045.905216", lon: "12725.486642", latD: "N", lonD: "E", latitude: 0.7650869333333333, longitude: 127.42477736666666, timestamp: "2024-06-06T10:38:05.000Z" },
        { speed: "8.1", course: "101.4", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.897384", lon: "12725.530733", latD: "N", lonD: "E", latitude: 0.7649564000000001, longitude: 127.42551221666666, timestamp: "2024-06-06T10:38:25.000Z" },
        { speed: "8.1", course: "101.4", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.897384", lon: "12725.530733", latD: "N", lonD: "E", latitude: 0.7649564000000001, longitude: 127.42551221666666, timestamp: "2024-06-06T10:38:25.000Z" },
        { speed: "8.1", course: "101.4", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.897384", lon: "12725.530733", latD: "N", lonD: "E", latitude: 0.7649564000000001, longitude: 127.42551221666666, timestamp: "2024-06-06T10:38:25.000Z" },
        { speed: "8.1", course: "101.4", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.897384", lon: "12725.530733", latD: "N", lonD: "E", latitude: 0.7649564000000001, longitude: 127.42551221666666, timestamp: "2024-06-06T10:38:25.000Z" },
        { speed: "8.1", course: "101.4", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.897384", lon: "12725.530733", latD: "N", lonD: "E", latitude: 0.7649564000000001, longitude: 127.42551221666666, timestamp: "2024-06-06T10:38:25.000Z" },
        { speed: "8.1", course: "101.4", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.897384", lon: "12725.530733", latD: "N", lonD: "E", latitude: 0.7649564000000001, longitude: 127.42551221666666, timestamp: "2024-06-06T10:38:25.000Z" },
        { speed: "8.1", course: "100.8", altitude: "13.9", satellites: "10", hdop: "0.6", lat: "0045.889926", lon: "12725.574140", latD: "N", lonD: "E", latitude: 0.7648321, longitude: 127.42623566666667, timestamp: "2024-06-06T10:38:45.000Z" },
        { speed: "8.1", course: "100.8", altitude: "13.9", satellites: "10", hdop: "0.6", lat: "0045.889926", lon: "12725.574140", latD: "N", lonD: "E", latitude: 0.7648321, longitude: 127.42623566666667, timestamp: "2024-06-06T10:38:45.000Z" },
        { speed: "8.1", course: "102.0", altitude: "14.3", satellites: "09", hdop: "0.6", lat: "0045.879920", lon: "12725.617174", latD: "N", lonD: "E", latitude: 0.7646653333333333, longitude: 127.4269529, timestamp: "2024-06-06T10:39:05.000Z" },
        { speed: "8.1", course: "100.8", altitude: "13.9", satellites: "10", hdop: "0.6", lat: "0045.889926", lon: "12725.574140", latD: "N", lonD: "E", latitude: 0.7648321, longitude: 127.42623566666667, timestamp: "2024-06-06T10:38:45.000Z" },
        { speed: "8.1", course: "100.8", altitude: "13.9", satellites: "10", hdop: "0.6", lat: "0045.889926", lon: "12725.574140", latD: "N", lonD: "E", latitude: 0.7648321, longitude: 127.42623566666667, timestamp: "2024-06-06T10:38:45.000Z" },
        { speed: "8.1", course: "102.0", altitude: "14.3", satellites: "09", hdop: "0.6", lat: "0045.879920", lon: "12725.617174", latD: "N", lonD: "E", latitude: 0.7646653333333333, longitude: 127.4269529, timestamp: "2024-06-06T10:39:05.000Z" },
        { speed: "8.2", course: "101.7", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.869505", lon: "12725.661972", latD: "N", lonD: "E", latitude: 0.7644917499999999, longitude: 127.42769953333334, timestamp: "2024-06-06T10:39:25.000Z" },
        { speed: "8.2", course: "101.7", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.869505", lon: "12725.661972", latD: "N", lonD: "E", latitude: 0.7644917499999999, longitude: 127.42769953333334, timestamp: "2024-06-06T10:39:25.000Z" },
        { speed: "8.2", course: "101.7", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.869505", lon: "12725.661972", latD: "N", lonD: "E", latitude: 0.7644917499999999, longitude: 127.42769953333334, timestamp: "2024-06-06T10:39:25.000Z" },
        { speed: "8.2", course: "101.7", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.869505", lon: "12725.661972", latD: "N", lonD: "E", latitude: 0.7644917499999999, longitude: 127.42769953333334, timestamp: "2024-06-06T10:39:25.000Z" },
        { speed: "8.3", course: "101.5", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.860650", lon: "12725.703513", latD: "N", lonD: "E", latitude: 0.7643441666666667, longitude: 127.42839188333333, timestamp: "2024-06-06T10:39:45.000Z" },
        { speed: "8.3", course: "101.5", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.860650", lon: "12725.703513", latD: "N", lonD: "E", latitude: 0.7643441666666667, longitude: 127.42839188333333, timestamp: "2024-06-06T10:39:45.000Z" },
        { speed: "8.3", course: "101.5", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.860650", lon: "12725.703513", latD: "N", lonD: "E", latitude: 0.7643441666666667, longitude: 127.42839188333333, timestamp: "2024-06-06T10:39:45.000Z" },
        { speed: "8.3", course: "101.5", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.860650", lon: "12725.703513", latD: "N", lonD: "E", latitude: 0.7643441666666667, longitude: 127.42839188333333, timestamp: "2024-06-06T10:39:45.000Z" },
        { speed: "8.3", course: "101.5", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.860650", lon: "12725.703513", latD: "N", lonD: "E", latitude: 0.7643441666666667, longitude: 127.42839188333333, timestamp: "2024-06-06T10:39:45.000Z" },
        { speed: "8.4", course: "102.2", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.851080", lon: "12725.749120", latD: "N", lonD: "E", latitude: 0.7641846666666667, longitude: 127.429152, timestamp: "2024-06-06T10:40:05.000Z" },
        { speed: "8.4", course: "102.2", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.851080", lon: "12725.749120", latD: "N", lonD: "E", latitude: 0.7641846666666667, longitude: 127.429152, timestamp: "2024-06-06T10:40:05.000Z" },
        { speed: "8.4", course: "102.2", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.851080", lon: "12725.749120", latD: "N", lonD: "E", latitude: 0.7641846666666667, longitude: 127.429152, timestamp: "2024-06-06T10:40:05.000Z" },
        { speed: "8.4", course: "102.2", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.851080", lon: "12725.749120", latD: "N", lonD: "E", latitude: 0.7641846666666667, longitude: 127.429152, timestamp: "2024-06-06T10:40:05.000Z" },
        { speed: "8.4", course: "102.2", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.851080", lon: "12725.749120", latD: "N", lonD: "E", latitude: 0.7641846666666667, longitude: 127.429152, timestamp: "2024-06-06T10:40:05.000Z" },
        { speed: "8.0", course: "102.4", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.843160", lon: "12725.795133", latD: "N", lonD: "E", latitude: 0.7640526666666666, longitude: 127.42991888333333, timestamp: "2024-06-06T10:40:25.000Z" },
        { speed: "8.0", course: "102.4", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.843160", lon: "12725.795133", latD: "N", lonD: "E", latitude: 0.7640526666666666, longitude: 127.42991888333333, timestamp: "2024-06-06T10:40:25.000Z" },
        { speed: "8.0", course: "102.4", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.843160", lon: "12725.795133", latD: "N", lonD: "E", latitude: 0.7640526666666666, longitude: 127.42991888333333, timestamp: "2024-06-06T10:40:25.000Z" },
        { speed: "8.0", course: "102.4", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.843160", lon: "12725.795133", latD: "N", lonD: "E", latitude: 0.7640526666666666, longitude: 127.42991888333333, timestamp: "2024-06-06T10:40:25.000Z" },
        { speed: "7.9", course: "104.6", altitude: "8.5", satellites: "09", hdop: "0.6", lat: "0045.832894", lon: "12725.838521", latD: "N", lonD: "E", latitude: 0.7638815666666667, longitude: 127.43064201666667, timestamp: "2024-06-06T10:40:45.000Z" },
        { speed: "7.9", course: "104.6", altitude: "8.5", satellites: "09", hdop: "0.6", lat: "0045.832894", lon: "12725.838521", latD: "N", lonD: "E", latitude: 0.7638815666666667, longitude: 127.43064201666667, timestamp: "2024-06-06T10:40:45.000Z" },
        { speed: "7.9", course: "104.6", altitude: "8.5", satellites: "09", hdop: "0.6", lat: "0045.832894", lon: "12725.838521", latD: "N", lonD: "E", latitude: 0.7638815666666667, longitude: 127.43064201666667, timestamp: "2024-06-06T10:40:45.000Z" },
        { speed: "8.1", course: "106.1", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.822777", lon: "12725.880754", latD: "N", lonD: "E", latitude: 0.7637129500000001, longitude: 127.4313459, timestamp: "2024-06-06T10:41:05.000Z" },
        { speed: "7.9", course: "104.6", altitude: "8.5", satellites: "09", hdop: "0.6", lat: "0045.832894", lon: "12725.838521", latD: "N", lonD: "E", latitude: 0.7638815666666667, longitude: 127.43064201666667, timestamp: "2024-06-06T10:40:45.000Z" },
        { speed: "8.1", course: "106.1", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.822777", lon: "12725.880754", latD: "N", lonD: "E", latitude: 0.7637129500000001, longitude: 127.4313459, timestamp: "2024-06-06T10:41:05.000Z" },
        { speed: "8.1", course: "106.1", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.822777", lon: "12725.880754", latD: "N", lonD: "E", latitude: 0.7637129500000001, longitude: 127.4313459, timestamp: "2024-06-06T10:41:05.000Z" },
        { speed: "8.0", course: "107.0", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.809345", lon: "12725.924770", latD: "N", lonD: "E", latitude: 0.7634890833333333, longitude: 127.4320795, timestamp: "2024-06-06T10:41:25.000Z" },
        { speed: "8.0", course: "107.0", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.809345", lon: "12725.924770", latD: "N", lonD: "E", latitude: 0.7634890833333333, longitude: 127.4320795, timestamp: "2024-06-06T10:41:25.000Z" },
        { speed: "8.0", course: "107.0", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.809345", lon: "12725.924770", latD: "N", lonD: "E", latitude: 0.7634890833333333, longitude: 127.4320795, timestamp: "2024-06-06T10:41:25.000Z" },
        { speed: "8.0", course: "107.0", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.809345", lon: "12725.924770", latD: "N", lonD: "E", latitude: 0.7634890833333333, longitude: 127.4320795, timestamp: "2024-06-06T10:41:25.000Z" },
        { speed: "8.0", course: "107.0", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.809345", lon: "12725.924770", latD: "N", lonD: "E", latitude: 0.7634890833333333, longitude: 127.4320795, timestamp: "2024-06-06T10:41:25.000Z" },
        { speed: "8.5", course: "103.3", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.797277", lon: "12725.968120", latD: "N", lonD: "E", latitude: 0.76328795, longitude: 127.432802, timestamp: "2024-06-06T10:41:45.000Z" },
        { speed: "8.5", course: "103.3", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.797277", lon: "12725.968120", latD: "N", lonD: "E", latitude: 0.76328795, longitude: 127.432802, timestamp: "2024-06-06T10:41:45.000Z" },
        { speed: "8.2", course: "103.5", altitude: "11.5", satellites: "09", hdop: "0.7", lat: "0045.784656", lon: "12726.011717", latD: "N", lonD: "E", latitude: 0.7630776, longitude: 127.43352861666666, timestamp: "2024-06-06T10:42:05.000Z" },
        { speed: "8.2", course: "103.5", altitude: "11.5", satellites: "09", hdop: "0.7", lat: "0045.784656", lon: "12726.011717", latD: "N", lonD: "E", latitude: 0.7630776, longitude: 127.43352861666666, timestamp: "2024-06-06T10:42:05.000Z" },
        { speed: "8.5", course: "103.3", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.797277", lon: "12725.968120", latD: "N", lonD: "E", latitude: 0.76328795, longitude: 127.432802, timestamp: "2024-06-06T10:41:45.000Z" },
        { speed: "8.2", course: "103.5", altitude: "11.5", satellites: "09", hdop: "0.7", lat: "0045.784656", lon: "12726.011717", latD: "N", lonD: "E", latitude: 0.7630776, longitude: 127.43352861666666, timestamp: "2024-06-06T10:42:05.000Z" },
        { speed: "8.2", course: "103.5", altitude: "11.5", satellites: "09", hdop: "0.7", lat: "0045.784656", lon: "12726.011717", latD: "N", lonD: "E", latitude: 0.7630776, longitude: 127.43352861666666, timestamp: "2024-06-06T10:42:05.000Z" },
        { speed: "8.2", course: "103.5", altitude: "11.5", satellites: "09", hdop: "0.7", lat: "0045.784656", lon: "12726.011717", latD: "N", lonD: "E", latitude: 0.7630776, longitude: 127.43352861666666, timestamp: "2024-06-06T10:42:05.000Z" },
        { speed: "7.9", course: "102.1", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.774592", lon: "12726.052865", latD: "N", lonD: "E", latitude: 0.7629098666666666, longitude: 127.43421441666666, timestamp: "2024-06-06T10:42:25.000Z" },
        { speed: "7.9", course: "102.1", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.774592", lon: "12726.052865", latD: "N", lonD: "E", latitude: 0.7629098666666666, longitude: 127.43421441666666, timestamp: "2024-06-06T10:42:25.000Z" },
        { speed: "8.4", course: "101.3", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.764315", lon: "12726.098810", latD: "N", lonD: "E", latitude: 0.7627385833333334, longitude: 127.43498016666666, timestamp: "2024-06-06T10:42:45.000Z" },
        { speed: "7.9", course: "102.1", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.774592", lon: "12726.052865", latD: "N", lonD: "E", latitude: 0.7629098666666666, longitude: 127.43421441666666, timestamp: "2024-06-06T10:42:25.000Z" },
        { speed: "7.9", course: "102.1", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.774592", lon: "12726.052865", latD: "N", lonD: "E", latitude: 0.7629098666666666, longitude: 127.43421441666666, timestamp: "2024-06-06T10:42:25.000Z" },
        { speed: "8.4", course: "101.3", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.764315", lon: "12726.098810", latD: "N", lonD: "E", latitude: 0.7627385833333334, longitude: 127.43498016666666, timestamp: "2024-06-06T10:42:45.000Z" },
        { speed: "8.4", course: "101.3", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.764315", lon: "12726.098810", latD: "N", lonD: "E", latitude: 0.7627385833333334, longitude: 127.43498016666666, timestamp: "2024-06-06T10:42:45.000Z" },
        { speed: "8.4", course: "101.3", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.764315", lon: "12726.098810", latD: "N", lonD: "E", latitude: 0.7627385833333334, longitude: 127.43498016666666, timestamp: "2024-06-06T10:42:45.000Z" },
        { speed: "8.2", course: "100.4", altitude: "8.2", satellites: "09", hdop: "0.7", lat: "0045.755092", lon: "12726.142897", latD: "N", lonD: "E", latitude: 0.7625848666666666, longitude: 127.43571495, timestamp: "2024-06-06T10:43:05.000Z" },
        { speed: "8.2", course: "100.4", altitude: "8.2", satellites: "09", hdop: "0.7", lat: "0045.755092", lon: "12726.142897", latD: "N", lonD: "E", latitude: 0.7625848666666666, longitude: 127.43571495, timestamp: "2024-06-06T10:43:05.000Z" },
        { speed: "8.2", course: "100.4", altitude: "8.2", satellites: "09", hdop: "0.7", lat: "0045.755092", lon: "12726.142897", latD: "N", lonD: "E", latitude: 0.7625848666666666, longitude: 127.43571495, timestamp: "2024-06-06T10:43:05.000Z" },
        { speed: "8.2", course: "99.6", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.748153", lon: "12726.188047", latD: "N", lonD: "E", latitude: 0.7624692166666667, longitude: 127.43646745, timestamp: "2024-06-06T10:43:25.000Z" },
        { speed: "8.2", course: "99.6", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.748153", lon: "12726.188047", latD: "N", lonD: "E", latitude: 0.7624692166666667, longitude: 127.43646745, timestamp: "2024-06-06T10:43:25.000Z" },
        { speed: "8.2", course: "99.6", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.748153", lon: "12726.188047", latD: "N", lonD: "E", latitude: 0.7624692166666667, longitude: 127.43646745, timestamp: "2024-06-06T10:43:25.000Z" },
        { speed: "8.2", course: "99.7", altitude: "4.3", satellites: "10", hdop: "0.6", lat: "0045.740773", lon: "12726.231885", latD: "N", lonD: "E", latitude: 0.7623462166666666, longitude: 127.43719808333333, timestamp: "2024-06-06T10:43:45.000Z" },
        { speed: "8.2", course: "99.6", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.748153", lon: "12726.188047", latD: "N", lonD: "E", latitude: 0.7624692166666667, longitude: 127.43646745, timestamp: "2024-06-06T10:43:25.000Z" },
        { speed: "8.2", course: "99.6", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.748153", lon: "12726.188047", latD: "N", lonD: "E", latitude: 0.7624692166666667, longitude: 127.43646745, timestamp: "2024-06-06T10:43:25.000Z" },
        { speed: "8.2", course: "99.7", altitude: "4.3", satellites: "10", hdop: "0.6", lat: "0045.740773", lon: "12726.231885", latD: "N", lonD: "E", latitude: 0.7623462166666666, longitude: 127.43719808333333, timestamp: "2024-06-06T10:43:45.000Z" },
        { speed: "8.2", course: "99.7", altitude: "4.3", satellites: "10", hdop: "0.6", lat: "0045.740773", lon: "12726.231885", latD: "N", lonD: "E", latitude: 0.7623462166666666, longitude: 127.43719808333333, timestamp: "2024-06-06T10:43:45.000Z" },
        { speed: "8.2", course: "99.7", altitude: "4.3", satellites: "10", hdop: "0.6", lat: "0045.740773", lon: "12726.231885", latD: "N", lonD: "E", latitude: 0.7623462166666666, longitude: 127.43719808333333, timestamp: "2024-06-06T10:43:45.000Z" },
        { speed: "8.1", course: "97.4", altitude: "-7.6", satellites: "10", hdop: "0.6", lat: "0045.733734", lon: "12726.278576", latD: "N", lonD: "E", latitude: 0.7622289, longitude: 127.43797626666667, timestamp: "2024-06-06T10:44:05.000Z" },
        { speed: "8.1", course: "97.4", altitude: "-7.6", satellites: "10", hdop: "0.6", lat: "0045.733734", lon: "12726.278576", latD: "N", lonD: "E", latitude: 0.7622289, longitude: 127.43797626666667, timestamp: "2024-06-06T10:44:05.000Z" },
        { speed: "8.0", course: "96.2", altitude: "-6.8", satellites: "10", hdop: "0.6", lat: "0045.728396", lon: "12726.323371", latD: "N", lonD: "E", latitude: 0.7621399333333333, longitude: 127.43872285, timestamp: "2024-06-06T10:44:25.000Z" },
        { speed: "8.1", course: "97.4", altitude: "-7.6", satellites: "10", hdop: "0.6", lat: "0045.733734", lon: "12726.278576", latD: "N", lonD: "E", latitude: 0.7622289, longitude: 127.43797626666667, timestamp: "2024-06-06T10:44:05.000Z" },
        { speed: "8.1", course: "97.4", altitude: "-7.6", satellites: "10", hdop: "0.6", lat: "0045.733734", lon: "12726.278576", latD: "N", lonD: "E", latitude: 0.7622289, longitude: 127.43797626666667, timestamp: "2024-06-06T10:44:05.000Z" },
        { speed: "8.0", course: "96.2", altitude: "-6.8", satellites: "10", hdop: "0.6", lat: "0045.728396", lon: "12726.323371", latD: "N", lonD: "E", latitude: 0.7621399333333333, longitude: 127.43872285, timestamp: "2024-06-06T10:44:25.000Z" },
        { speed: "8.0", course: "96.2", altitude: "-6.8", satellites: "10", hdop: "0.6", lat: "0045.728396", lon: "12726.323371", latD: "N", lonD: "E", latitude: 0.7621399333333333, longitude: 127.43872285, timestamp: "2024-06-06T10:44:25.000Z" },
        { speed: "8.4", course: "95.8", altitude: "-7.9", satellites: "10", hdop: "0.6", lat: "0045.722500", lon: "12726.368015", latD: "N", lonD: "E", latitude: 0.7620416666666666, longitude: 127.43946691666666, timestamp: "2024-06-06T10:44:45.000Z" },
        { speed: "8.4", course: "95.8", altitude: "-7.9", satellites: "10", hdop: "0.6", lat: "0045.722500", lon: "12726.368015", latD: "N", lonD: "E", latitude: 0.7620416666666666, longitude: 127.43946691666666, timestamp: "2024-06-06T10:44:45.000Z" },
        { speed: "8.4", course: "95.8", altitude: "-7.9", satellites: "10", hdop: "0.6", lat: "0045.722500", lon: "12726.368015", latD: "N", lonD: "E", latitude: 0.7620416666666666, longitude: 127.43946691666666, timestamp: "2024-06-06T10:44:45.000Z" },
        { speed: "8.3", course: "96.8", altitude: "-2.9", satellites: "10", hdop: "0.6", lat: "0045.718483", lon: "12726.412044", latD: "N", lonD: "E", latitude: 0.7619747166666666, longitude: 127.44020073333333, timestamp: "2024-06-06T10:45:05.000Z" },
        { speed: "8.3", course: "96.8", altitude: "-2.9", satellites: "10", hdop: "0.6", lat: "0045.718483", lon: "12726.412044", latD: "N", lonD: "E", latitude: 0.7619747166666666, longitude: 127.44020073333333, timestamp: "2024-06-06T10:45:05.000Z" },
        { speed: "8.3", course: "96.8", altitude: "-2.9", satellites: "10", hdop: "0.6", lat: "0045.718483", lon: "12726.412044", latD: "N", lonD: "E", latitude: 0.7619747166666666, longitude: 127.44020073333333, timestamp: "2024-06-06T10:45:05.000Z" },
        { speed: "8.3", course: "96.8", altitude: "-2.9", satellites: "10", hdop: "0.6", lat: "0045.718483", lon: "12726.412044", latD: "N", lonD: "E", latitude: 0.7619747166666666, longitude: 127.44020073333333, timestamp: "2024-06-06T10:45:05.000Z" },
        { speed: "8.8", course: "97.6", altitude: "11.5", satellites: "10", hdop: "0.7", lat: "0045.713290", lon: "12726.457966", latD: "N", lonD: "E", latitude: 0.7618881666666667, longitude: 127.4409661, timestamp: "2024-06-06T10:45:25.000Z" },
        { speed: "8.8", course: "97.6", altitude: "11.5", satellites: "10", hdop: "0.7", lat: "0045.713290", lon: "12726.457966", latD: "N", lonD: "E", latitude: 0.7618881666666667, longitude: 127.4409661, timestamp: "2024-06-06T10:45:25.000Z" },
        { speed: "8.3", course: "96.8", altitude: "-2.9", satellites: "10", hdop: "0.6", lat: "0045.718483", lon: "12726.412044", latD: "N", lonD: "E", latitude: 0.7619747166666666, longitude: 127.44020073333333, timestamp: "2024-06-06T10:45:05.000Z" },
        { speed: "8.8", course: "97.6", altitude: "11.5", satellites: "10", hdop: "0.7", lat: "0045.713290", lon: "12726.457966", latD: "N", lonD: "E", latitude: 0.7618881666666667, longitude: 127.4409661, timestamp: "2024-06-06T10:45:25.000Z" },
        { speed: "8.8", course: "97.6", altitude: "11.5", satellites: "10", hdop: "0.7", lat: "0045.713290", lon: "12726.457966", latD: "N", lonD: "E", latitude: 0.7618881666666667, longitude: 127.4409661, timestamp: "2024-06-06T10:45:25.000Z" },
        { speed: "8.8", course: "97.6", altitude: "11.5", satellites: "10", hdop: "0.7", lat: "0045.713290", lon: "12726.457966", latD: "N", lonD: "E", latitude: 0.7618881666666667, longitude: 127.4409661, timestamp: "2024-06-06T10:45:25.000Z" },
        { speed: "8.3", course: "94.5", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.707734", lon: "12726.502936", latD: "N", lonD: "E", latitude: 0.7617955666666667, longitude: 127.4417156, timestamp: "2024-06-06T10:45:45.000Z" },
        { speed: "8.3", course: "94.5", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.707734", lon: "12726.502936", latD: "N", lonD: "E", latitude: 0.7617955666666667, longitude: 127.4417156, timestamp: "2024-06-06T10:45:45.000Z" },
        { speed: "8.1", course: "93.9", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.705881", lon: "12726.550120", latD: "N", lonD: "E", latitude: 0.7617646833333332, longitude: 127.442502, timestamp: "2024-06-06T10:46:05.000Z" },
        { speed: "8.3", course: "94.5", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.707734", lon: "12726.502936", latD: "N", lonD: "E", latitude: 0.7617955666666667, longitude: 127.4417156, timestamp: "2024-06-06T10:45:45.000Z" },
        { speed: "8.3", course: "94.5", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.707734", lon: "12726.502936", latD: "N", lonD: "E", latitude: 0.7617955666666667, longitude: 127.4417156, timestamp: "2024-06-06T10:45:45.000Z" },
        { speed: "8.1", course: "93.9", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.705881", lon: "12726.550120", latD: "N", lonD: "E", latitude: 0.7617646833333332, longitude: 127.442502, timestamp: "2024-06-06T10:46:05.000Z" },
        { speed: "8.1", course: "93.9", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.705881", lon: "12726.550120", latD: "N", lonD: "E", latitude: 0.7617646833333332, longitude: 127.442502, timestamp: "2024-06-06T10:46:05.000Z" },
        { speed: "8.1", course: "93.9", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.705881", lon: "12726.550120", latD: "N", lonD: "E", latitude: 0.7617646833333332, longitude: 127.442502, timestamp: "2024-06-06T10:46:05.000Z" },
        { speed: "8.3", course: "91.4", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.703089", lon: "12726.596536", latD: "N", lonD: "E", latitude: 0.76171815, longitude: 127.4432756, timestamp: "2024-06-06T10:46:25.000Z" },
        { speed: "8.3", course: "91.4", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.703089", lon: "12726.596536", latD: "N", lonD: "E", latitude: 0.76171815, longitude: 127.4432756, timestamp: "2024-06-06T10:46:25.000Z" },
        { speed: "8.3", course: "91.4", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.703089", lon: "12726.596536", latD: "N", lonD: "E", latitude: 0.76171815, longitude: 127.4432756, timestamp: "2024-06-06T10:46:25.000Z" },
        { speed: "8.2", course: "93.6", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.701811", lon: "12726.641542", latD: "N", lonD: "E", latitude: 0.76169685, longitude: 127.4440257, timestamp: "2024-06-06T10:46:45.000Z" },
        { speed: "8.2", course: "93.6", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.701811", lon: "12726.641542", latD: "N", lonD: "E", latitude: 0.76169685, longitude: 127.4440257, timestamp: "2024-06-06T10:46:45.000Z" },
        { speed: "8.2", course: "93.6", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.701811", lon: "12726.641542", latD: "N", lonD: "E", latitude: 0.76169685, longitude: 127.4440257, timestamp: "2024-06-06T10:46:45.000Z" },
        { speed: "8.2", course: "93.6", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.701811", lon: "12726.641542", latD: "N", lonD: "E", latitude: 0.76169685, longitude: 127.4440257, timestamp: "2024-06-06T10:46:45.000Z" },
        { speed: "8.2", course: "93.6", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.701811", lon: "12726.641542", latD: "N", lonD: "E", latitude: 0.76169685, longitude: 127.4440257, timestamp: "2024-06-06T10:46:45.000Z" },
        { speed: "8.0", course: "97.7", altitude: "15.6", satellites: "10", hdop: "0.6", lat: "0045.697532", lon: "12726.687485", latD: "N", lonD: "E", latitude: 0.7616255333333334, longitude: 127.44479141666666, timestamp: "2024-06-06T10:47:05.000Z" },
        { speed: "8.0", course: "97.7", altitude: "15.6", satellites: "10", hdop: "0.6", lat: "0045.697532", lon: "12726.687485", latD: "N", lonD: "E", latitude: 0.7616255333333334, longitude: 127.44479141666666, timestamp: "2024-06-06T10:47:05.000Z" },
        { speed: "8.4", course: "97.6", altitude: "13.9", satellites: "10", hdop: "0.6", lat: "0045.691972", lon: "12726.734991", latD: "N", lonD: "E", latitude: 0.7615328666666666, longitude: 127.44558318333333, timestamp: "2024-06-06T10:47:25.000Z" },
        { speed: "8.0", course: "97.7", altitude: "15.6", satellites: "10", hdop: "0.6", lat: "0045.697532", lon: "12726.687485", latD: "N", lonD: "E", latitude: 0.7616255333333334, longitude: 127.44479141666666, timestamp: "2024-06-06T10:47:05.000Z" },
        { speed: "8.0", course: "97.7", altitude: "15.6", satellites: "10", hdop: "0.6", lat: "0045.697532", lon: "12726.687485", latD: "N", lonD: "E", latitude: 0.7616255333333334, longitude: 127.44479141666666, timestamp: "2024-06-06T10:47:05.000Z" },
        { speed: "8.4", course: "97.6", altitude: "13.9", satellites: "10", hdop: "0.6", lat: "0045.691972", lon: "12726.734991", latD: "N", lonD: "E", latitude: 0.7615328666666666, longitude: 127.44558318333333, timestamp: "2024-06-06T10:47:25.000Z" },
        { speed: "8.4", course: "97.6", altitude: "13.9", satellites: "10", hdop: "0.6", lat: "0045.691972", lon: "12726.734991", latD: "N", lonD: "E", latitude: 0.7615328666666666, longitude: 127.44558318333333, timestamp: "2024-06-06T10:47:25.000Z" },
        { speed: "8.4", course: "97.4", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.683796", lon: "12726.778793", latD: "N", lonD: "E", latitude: 0.7613966, longitude: 127.44631321666667, timestamp: "2024-06-06T10:47:45.000Z" },
        { speed: "8.4", course: "97.6", altitude: "13.9", satellites: "10", hdop: "0.6", lat: "0045.691972", lon: "12726.734991", latD: "N", lonD: "E", latitude: 0.7615328666666666, longitude: 127.44558318333333, timestamp: "2024-06-06T10:47:25.000Z" },
        { speed: "8.4", course: "97.4", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.683796", lon: "12726.778793", latD: "N", lonD: "E", latitude: 0.7613966, longitude: 127.44631321666667, timestamp: "2024-06-06T10:47:45.000Z" },
        { speed: "8.4", course: "97.4", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.683796", lon: "12726.778793", latD: "N", lonD: "E", latitude: 0.7613966, longitude: 127.44631321666667, timestamp: "2024-06-06T10:47:45.000Z" },
        { speed: "8.4", course: "97.4", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.683796", lon: "12726.778793", latD: "N", lonD: "E", latitude: 0.7613966, longitude: 127.44631321666667, timestamp: "2024-06-06T10:47:45.000Z" },
        { speed: "8.5", course: "95.8", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.679523", lon: "12726.822027", latD: "N", lonD: "E", latitude: 0.7613253833333334, longitude: 127.44703378333334, timestamp: "2024-06-06T10:48:05.000Z" },
        { speed: "8.5", course: "95.8", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.679523", lon: "12726.822027", latD: "N", lonD: "E", latitude: 0.7613253833333334, longitude: 127.44703378333334, timestamp: "2024-06-06T10:48:05.000Z" },
        { speed: "8.5", course: "95.8", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.679523", lon: "12726.822027", latD: "N", lonD: "E", latitude: 0.7613253833333334, longitude: 127.44703378333334, timestamp: "2024-06-06T10:48:05.000Z" },
        { speed: "8.5", course: "95.8", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.679523", lon: "12726.822027", latD: "N", lonD: "E", latitude: 0.7613253833333334, longitude: 127.44703378333334, timestamp: "2024-06-06T10:48:05.000Z" },
        { speed: "8.1", course: "97.9", altitude: "15.6", satellites: "08", hdop: "0.6", lat: "0045.673352", lon: "12726.867559", latD: "N", lonD: "E", latitude: 0.7612225333333333, longitude: 127.44779265, timestamp: "2024-06-06T10:48:25.000Z" },
        { speed: "8.1", course: "97.9", altitude: "15.6", satellites: "08", hdop: "0.6", lat: "0045.673352", lon: "12726.867559", latD: "N", lonD: "E", latitude: 0.7612225333333333, longitude: 127.44779265, timestamp: "2024-06-06T10:48:25.000Z" },
        { speed: "8.1", course: "97.9", altitude: "15.6", satellites: "08", hdop: "0.6", lat: "0045.673352", lon: "12726.867559", latD: "N", lonD: "E", latitude: 0.7612225333333333, longitude: 127.44779265, timestamp: "2024-06-06T10:48:25.000Z" },
        { speed: "8.1", course: "97.9", altitude: "15.6", satellites: "08", hdop: "0.6", lat: "0045.673352", lon: "12726.867559", latD: "N", lonD: "E", latitude: 0.7612225333333333, longitude: 127.44779265, timestamp: "2024-06-06T10:48:25.000Z" },
        { speed: "8.1", course: "97.5", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.666755", lon: "12726.913116", latD: "N", lonD: "E", latitude: 0.7611125833333333, longitude: 127.44855193333333, timestamp: "2024-06-06T10:48:45.000Z" },
        { speed: "8.3", course: "96.6", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.659134", lon: "12726.960179", latD: "N", lonD: "E", latitude: 0.7609855666666667, longitude: 127.44933631666666, timestamp: "2024-06-06T10:49:05.000Z" },
        { speed: "8.1", course: "97.5", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.666755", lon: "12726.913116", latD: "N", lonD: "E", latitude: 0.7611125833333333, longitude: 127.44855193333333, timestamp: "2024-06-06T10:48:45.000Z" },
        { speed: "8.1", course: "97.5", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.666755", lon: "12726.913116", latD: "N", lonD: "E", latitude: 0.7611125833333333, longitude: 127.44855193333333, timestamp: "2024-06-06T10:48:45.000Z" },
        { speed: "8.1", course: "97.5", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.666755", lon: "12726.913116", latD: "N", lonD: "E", latitude: 0.7611125833333333, longitude: 127.44855193333333, timestamp: "2024-06-06T10:48:45.000Z" },
        { speed: "8.3", course: "96.6", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.659134", lon: "12726.960179", latD: "N", lonD: "E", latitude: 0.7609855666666667, longitude: 127.44933631666666, timestamp: "2024-06-06T10:49:05.000Z" },
        { speed: "8.3", course: "96.6", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.659134", lon: "12726.960179", latD: "N", lonD: "E", latitude: 0.7609855666666667, longitude: 127.44933631666666, timestamp: "2024-06-06T10:49:05.000Z" },
        { speed: "8.3", course: "96.4", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.653259", lon: "12727.006034", latD: "N", lonD: "E", latitude: 0.76088765, longitude: 127.45010056666666, timestamp: "2024-06-06T10:49:25.000Z" },
        { speed: "8.3", course: "96.4", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.653259", lon: "12727.006034", latD: "N", lonD: "E", latitude: 0.76088765, longitude: 127.45010056666666, timestamp: "2024-06-06T10:49:25.000Z" },
        { speed: "8.2", course: "96.5", altitude: "5.2", satellites: "09", hdop: "0.6", lat: "0045.647828", lon: "12727.053104", latD: "N", lonD: "E", latitude: 0.7607971333333333, longitude: 127.45088506666667, timestamp: "2024-06-06T10:49:45.000Z" },
        { speed: "8.2", course: "96.5", altitude: "5.2", satellites: "09", hdop: "0.6", lat: "0045.647828", lon: "12727.053104", latD: "N", lonD: "E", latitude: 0.7607971333333333, longitude: 127.45088506666667, timestamp: "2024-06-06T10:49:45.000Z" },
        { speed: "8.3", course: "96.4", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.653259", lon: "12727.006034", latD: "N", lonD: "E", latitude: 0.76088765, longitude: 127.45010056666666, timestamp: "2024-06-06T10:49:25.000Z" },
        { speed: "8.3", course: "96.4", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.653259", lon: "12727.006034", latD: "N", lonD: "E", latitude: 0.76088765, longitude: 127.45010056666666, timestamp: "2024-06-06T10:49:25.000Z" },
        { speed: "8.2", course: "96.5", altitude: "5.2", satellites: "09", hdop: "0.6", lat: "0045.647828", lon: "12727.053104", latD: "N", lonD: "E", latitude: 0.7607971333333333, longitude: 127.45088506666667, timestamp: "2024-06-06T10:49:45.000Z" },
        { speed: "8.2", course: "96.5", altitude: "5.2", satellites: "09", hdop: "0.6", lat: "0045.647828", lon: "12727.053104", latD: "N", lonD: "E", latitude: 0.7607971333333333, longitude: 127.45088506666667, timestamp: "2024-06-06T10:49:45.000Z" },
        { speed: "8.5", course: "97.4", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.643281", lon: "12727.098558", latD: "N", lonD: "E", latitude: 0.7607213500000001, longitude: 127.45164263333334, timestamp: "2024-06-06T10:50:05.000Z" },
        { speed: "8.5", course: "97.4", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.643281", lon: "12727.098558", latD: "N", lonD: "E", latitude: 0.7607213500000001, longitude: 127.45164263333334, timestamp: "2024-06-06T10:50:05.000Z" },
        { speed: "8.5", course: "97.4", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.643281", lon: "12727.098558", latD: "N", lonD: "E", latitude: 0.7607213500000001, longitude: 127.45164263333334, timestamp: "2024-06-06T10:50:05.000Z" },
        { speed: "8.5", course: "97.4", altitude: "4.5", satellites: "10", hdop: "0.6", lat: "0045.643281", lon: "12727.098558", latD: "N", lonD: "E", latitude: 0.7607213500000001, longitude: 127.45164263333334, timestamp: "2024-06-06T10:50:05.000Z" },
        { speed: "8.5", course: "97.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.637934", lon: "12727.142703", latD: "N", lonD: "E", latitude: 0.7606322333333334, longitude: 127.45237838333334, timestamp: "2024-06-06T10:50:25.000Z" },
        { speed: "8.5", course: "97.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.637934", lon: "12727.142703", latD: "N", lonD: "E", latitude: 0.7606322333333334, longitude: 127.45237838333334, timestamp: "2024-06-06T10:50:25.000Z" },
        { speed: "8.5", course: "97.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.637934", lon: "12727.142703", latD: "N", lonD: "E", latitude: 0.7606322333333334, longitude: 127.45237838333334, timestamp: "2024-06-06T10:50:25.000Z" },
        { speed: "8.4", course: "94.9", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.633565", lon: "12727.192075", latD: "N", lonD: "E", latitude: 0.7605594166666666, longitude: 127.45320125, timestamp: "2024-06-06T10:50:46.000Z" },
        { speed: "8.4", course: "94.9", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.633565", lon: "12727.192075", latD: "N", lonD: "E", latitude: 0.7605594166666666, longitude: 127.45320125, timestamp: "2024-06-06T10:50:46.000Z" },
        { speed: "8.4", course: "94.9", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.633565", lon: "12727.192075", latD: "N", lonD: "E", latitude: 0.7605594166666666, longitude: 127.45320125, timestamp: "2024-06-06T10:50:46.000Z" },
        { speed: "8.4", course: "94.9", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.633565", lon: "12727.192075", latD: "N", lonD: "E", latitude: 0.7605594166666666, longitude: 127.45320125, timestamp: "2024-06-06T10:50:46.000Z" },
        { speed: "8.4", course: "94.9", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.633565", lon: "12727.192075", latD: "N", lonD: "E", latitude: 0.7605594166666666, longitude: 127.45320125, timestamp: "2024-06-06T10:50:46.000Z" },
        { speed: "8.4", course: "91.8", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.630761", lon: "12727.235620", latD: "N", lonD: "E", latitude: 0.7605126833333333, longitude: 127.453927, timestamp: "2024-06-06T10:51:05.000Z" },
        { speed: "8.4", course: "91.8", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.630761", lon: "12727.235620", latD: "N", lonD: "E", latitude: 0.7605126833333333, longitude: 127.453927, timestamp: "2024-06-06T10:51:05.000Z" },
        { speed: "8.5", course: "89.7", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.630120", lon: "12727.281590", latD: "N", lonD: "E", latitude: 0.760502, longitude: 127.45469316666667, timestamp: "2024-06-06T10:51:25.000Z" },
        { speed: "8.4", course: "91.8", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.630761", lon: "12727.235620", latD: "N", lonD: "E", latitude: 0.7605126833333333, longitude: 127.453927, timestamp: "2024-06-06T10:51:05.000Z" },
        { speed: "8.4", course: "91.8", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.630761", lon: "12727.235620", latD: "N", lonD: "E", latitude: 0.7605126833333333, longitude: 127.453927, timestamp: "2024-06-06T10:51:05.000Z" },
        { speed: "8.4", course: "91.8", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.630761", lon: "12727.235620", latD: "N", lonD: "E", latitude: 0.7605126833333333, longitude: 127.453927, timestamp: "2024-06-06T10:51:05.000Z" },
        { speed: "8.5", course: "89.7", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.630120", lon: "12727.281590", latD: "N", lonD: "E", latitude: 0.760502, longitude: 127.45469316666667, timestamp: "2024-06-06T10:51:25.000Z" },
        { speed: "8.5", course: "89.7", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.630120", lon: "12727.281590", latD: "N", lonD: "E", latitude: 0.760502, longitude: 127.45469316666667, timestamp: "2024-06-06T10:51:25.000Z" },
        { speed: "8.5", course: "89.7", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.630120", lon: "12727.281590", latD: "N", lonD: "E", latitude: 0.760502, longitude: 127.45469316666667, timestamp: "2024-06-06T10:51:25.000Z" },
        { speed: "8.5", course: "90.0", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.631090", lon: "12727.330173", latD: "N", lonD: "E", latitude: 0.7605181666666667, longitude: 127.45550288333334, timestamp: "2024-06-06T10:51:46.000Z" },
        { speed: "8.5", course: "90.0", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.631090", lon: "12727.330173", latD: "N", lonD: "E", latitude: 0.7605181666666667, longitude: 127.45550288333334, timestamp: "2024-06-06T10:51:46.000Z" },
        { speed: "8.5", course: "90.0", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.631090", lon: "12727.330173", latD: "N", lonD: "E", latitude: 0.7605181666666667, longitude: 127.45550288333334, timestamp: "2024-06-06T10:51:46.000Z" },
        { speed: "8.5", course: "89.4", altitude: "2.4", satellites: "10", hdop: "0.6", lat: "0045.632213", lon: "12727.374817", latD: "N", lonD: "E", latitude: 0.7605368833333334, longitude: 127.45624695, timestamp: "2024-06-06T10:52:05.000Z" },
        { speed: "8.5", course: "89.4", altitude: "2.4", satellites: "10", hdop: "0.6", lat: "0045.632213", lon: "12727.374817", latD: "N", lonD: "E", latitude: 0.7605368833333334, longitude: 127.45624695, timestamp: "2024-06-06T10:52:05.000Z" },
        { speed: "8.5", course: "89.4", altitude: "2.4", satellites: "10", hdop: "0.6", lat: "0045.632213", lon: "12727.374817", latD: "N", lonD: "E", latitude: 0.7605368833333334, longitude: 127.45624695, timestamp: "2024-06-06T10:52:05.000Z" },
        { speed: "8.5", course: "90.7", altitude: "1.0", satellites: "10", hdop: "0.6", lat: "0045.632174", lon: "12727.421575", latD: "N", lonD: "E", latitude: 0.7605362333333333, longitude: 127.45702625, timestamp: "2024-06-06T10:52:25.000Z" },
        { speed: "8.5", course: "89.4", altitude: "2.4", satellites: "10", hdop: "0.6", lat: "0045.632213", lon: "12727.374817", latD: "N", lonD: "E", latitude: 0.7605368833333334, longitude: 127.45624695, timestamp: "2024-06-06T10:52:05.000Z" },
        { speed: "8.5", course: "89.4", altitude: "2.4", satellites: "10", hdop: "0.6", lat: "0045.632213", lon: "12727.374817", latD: "N", lonD: "E", latitude: 0.7605368833333334, longitude: 127.45624695, timestamp: "2024-06-06T10:52:05.000Z" },
        { speed: "8.4", course: "93.6", altitude: "3.5", satellites: "09", hdop: "0.6", lat: "0045.630375", lon: "12727.470793", latD: "N", lonD: "E", latitude: 0.76050625, longitude: 127.45784655, timestamp: "2024-06-06T10:52:46.000Z" },
        { speed: "8.4", course: "93.6", altitude: "3.5", satellites: "09", hdop: "0.6", lat: "0045.630375", lon: "12727.470793", latD: "N", lonD: "E", latitude: 0.76050625, longitude: 127.45784655, timestamp: "2024-06-06T10:52:46.000Z" },
        { speed: "8.4", course: "93.6", altitude: "3.5", satellites: "09", hdop: "0.6", lat: "0045.630375", lon: "12727.470793", latD: "N", lonD: "E", latitude: 0.76050625, longitude: 127.45784655, timestamp: "2024-06-06T10:52:46.000Z" },
        { speed: "8.4", course: "93.6", altitude: "3.5", satellites: "09", hdop: "0.6", lat: "0045.630375", lon: "12727.470793", latD: "N", lonD: "E", latitude: 0.76050625, longitude: 127.45784655, timestamp: "2024-06-06T10:52:46.000Z" },
        { speed: "8.5", course: "95.7", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.626588", lon: "12727.516282", latD: "N", lonD: "E", latitude: 0.7604431333333334, longitude: 127.4586047, timestamp: "2024-06-06T10:53:05.000Z" },
        { speed: "8.5", course: "95.7", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.626588", lon: "12727.516282", latD: "N", lonD: "E", latitude: 0.7604431333333334, longitude: 127.4586047, timestamp: "2024-06-06T10:53:05.000Z" },
        { speed: "8.5", course: "95.7", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.626588", lon: "12727.516282", latD: "N", lonD: "E", latitude: 0.7604431333333334, longitude: 127.4586047, timestamp: "2024-06-06T10:53:05.000Z" },
        { speed: "8.1", course: "98.1", altitude: "17.5", satellites: "10", hdop: "0.6", lat: "0045.620141", lon: "12727.565667", latD: "N", lonD: "E", latitude: 0.7603356833333332, longitude: 127.45942778333334, timestamp: "2024-06-06T10:53:26.000Z" },
        { speed: "8.5", course: "95.7", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.626588", lon: "12727.516282", latD: "N", lonD: "E", latitude: 0.7604431333333334, longitude: 127.4586047, timestamp: "2024-06-06T10:53:05.000Z" },
        { speed: "8.1", course: "98.1", altitude: "17.5", satellites: "10", hdop: "0.6", lat: "0045.620141", lon: "12727.565667", latD: "N", lonD: "E", latitude: 0.7603356833333332, longitude: 127.45942778333334, timestamp: "2024-06-06T10:53:26.000Z" },
        { speed: "8.1", course: "98.1", altitude: "17.5", satellites: "10", hdop: "0.6", lat: "0045.620141", lon: "12727.565667", latD: "N", lonD: "E", latitude: 0.7603356833333332, longitude: 127.45942778333334, timestamp: "2024-06-06T10:53:26.000Z" },
        { speed: "8.1", course: "98.1", altitude: "17.5", satellites: "10", hdop: "0.6", lat: "0045.620141", lon: "12727.565667", latD: "N", lonD: "E", latitude: 0.7603356833333332, longitude: 127.45942778333334, timestamp: "2024-06-06T10:53:26.000Z" },
        { speed: "8.7", course: "94.1", altitude: "16.7", satellites: "10", hdop: "0.6", lat: "0045.615447", lon: "12727.612706", latD: "N", lonD: "E", latitude: 0.76025745, longitude: 127.46021176666666, timestamp: "2024-06-06T10:53:46.000Z" },
        { speed: "8.7", course: "94.1", altitude: "16.7", satellites: "10", hdop: "0.6", lat: "0045.615447", lon: "12727.612706", latD: "N", lonD: "E", latitude: 0.76025745, longitude: 127.46021176666666, timestamp: "2024-06-06T10:53:46.000Z" },
        { speed: "8.7", course: "88.0", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.614834", lon: "12727.657374", latD: "N", lonD: "E", latitude: 0.7602472333333333, longitude: 127.46095623333333, timestamp: "2024-06-06T10:54:05.000Z" },
        { speed: "8.7", course: "94.1", altitude: "16.7", satellites: "10", hdop: "0.6", lat: "0045.615447", lon: "12727.612706", latD: "N", lonD: "E", latitude: 0.76025745, longitude: 127.46021176666666, timestamp: "2024-06-06T10:53:46.000Z" },
        { speed: "8.7", course: "88.0", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.614834", lon: "12727.657374", latD: "N", lonD: "E", latitude: 0.7602472333333333, longitude: 127.46095623333333, timestamp: "2024-06-06T10:54:05.000Z" },
        { speed: "8.7", course: "88.0", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.614834", lon: "12727.657374", latD: "N", lonD: "E", latitude: 0.7602472333333333, longitude: 127.46095623333333, timestamp: "2024-06-06T10:54:05.000Z" },
        { speed: "8.7", course: "88.0", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.614834", lon: "12727.657374", latD: "N", lonD: "E", latitude: 0.7602472333333333, longitude: 127.46095623333333, timestamp: "2024-06-06T10:54:05.000Z" },
        { speed: "8.6", course: "87.7", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.616323", lon: "12727.705666", latD: "N", lonD: "E", latitude: 0.76027205, longitude: 127.4617611, timestamp: "2024-06-06T10:54:26.000Z" },
        { speed: "8.6", course: "87.7", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.616323", lon: "12727.705666", latD: "N", lonD: "E", latitude: 0.76027205, longitude: 127.4617611, timestamp: "2024-06-06T10:54:26.000Z" },
        { speed: "8.6", course: "87.7", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.616323", lon: "12727.705666", latD: "N", lonD: "E", latitude: 0.76027205, longitude: 127.4617611, timestamp: "2024-06-06T10:54:26.000Z" },
        { speed: "8.6", course: "87.7", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.616323", lon: "12727.705666", latD: "N", lonD: "E", latitude: 0.76027205, longitude: 127.4617611, timestamp: "2024-06-06T10:54:26.000Z" },
        { speed: "8.6", course: "87.7", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.616323", lon: "12727.705666", latD: "N", lonD: "E", latitude: 0.76027205, longitude: 127.4617611, timestamp: "2024-06-06T10:54:26.000Z" },
        { speed: "8.8", course: "90.3", altitude: "11.6", satellites: "10", hdop: "0.6", lat: "0045.618485", lon: "12727.754698", latD: "N", lonD: "E", latitude: 0.7603080833333333, longitude: 127.4625783, timestamp: "2024-06-06T10:54:46.000Z" },
        { speed: "8.8", course: "90.3", altitude: "11.6", satellites: "10", hdop: "0.6", lat: "0045.618485", lon: "12727.754698", latD: "N", lonD: "E", latitude: 0.7603080833333333, longitude: 127.4625783, timestamp: "2024-06-06T10:54:46.000Z" },
        { speed: "8.8", course: "90.3", altitude: "11.6", satellites: "10", hdop: "0.6", lat: "0045.618485", lon: "12727.754698", latD: "N", lonD: "E", latitude: 0.7603080833333333, longitude: 127.4625783, timestamp: "2024-06-06T10:54:46.000Z" },
        { speed: "8.8", course: "90.3", altitude: "11.6", satellites: "10", hdop: "0.6", lat: "0045.618485", lon: "12727.754698", latD: "N", lonD: "E", latitude: 0.7603080833333333, longitude: 127.4625783, timestamp: "2024-06-06T10:54:46.000Z" },
        { speed: "8.5", course: "93.9", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.616962", lon: "12727.801922", latD: "N", lonD: "E", latitude: 0.7602827, longitude: 127.46336536666666, timestamp: "2024-06-06T10:55:06.000Z" },
        { speed: "8.5", course: "93.9", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.616962", lon: "12727.801922", latD: "N", lonD: "E", latitude: 0.7602827, longitude: 127.46336536666666, timestamp: "2024-06-06T10:55:06.000Z" },
        { speed: "8.5", course: "93.9", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.616962", lon: "12727.801922", latD: "N", lonD: "E", latitude: 0.7602827, longitude: 127.46336536666666, timestamp: "2024-06-06T10:55:06.000Z" },
        { speed: "8.7", course: "95.5", altitude: "21.9", satellites: "09", hdop: "0.6", lat: "0045.614877", lon: "12727.852781", latD: "N", lonD: "E", latitude: 0.76024795, longitude: 127.46421301666666, timestamp: "2024-06-06T10:55:26.000Z" },
        { speed: "8.7", course: "95.5", altitude: "21.9", satellites: "09", hdop: "0.6", lat: "0045.614877", lon: "12727.852781", latD: "N", lonD: "E", latitude: 0.76024795, longitude: 127.46421301666666, timestamp: "2024-06-06T10:55:26.000Z" },
        { speed: "8.6", course: "92.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.610217", lon: "12727.900139", latD: "N", lonD: "E", latitude: 0.7601702833333334, longitude: 127.46500231666667, timestamp: "2024-06-06T10:55:46.000Z" },
        { speed: "8.6", course: "92.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.610217", lon: "12727.900139", latD: "N", lonD: "E", latitude: 0.7601702833333334, longitude: 127.46500231666667, timestamp: "2024-06-06T10:55:46.000Z" },
        { speed: "8.6", course: "92.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.610217", lon: "12727.900139", latD: "N", lonD: "E", latitude: 0.7601702833333334, longitude: 127.46500231666667, timestamp: "2024-06-06T10:55:46.000Z" },
        { speed: "8.7", course: "95.5", altitude: "21.9", satellites: "09", hdop: "0.6", lat: "0045.614877", lon: "12727.852781", latD: "N", lonD: "E", latitude: 0.76024795, longitude: 127.46421301666666, timestamp: "2024-06-06T10:55:26.000Z" },
        { speed: "8.6", course: "92.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.610217", lon: "12727.900139", latD: "N", lonD: "E", latitude: 0.7601702833333334, longitude: 127.46500231666667, timestamp: "2024-06-06T10:55:46.000Z" },
        { speed: "8.6", course: "92.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.610217", lon: "12727.900139", latD: "N", lonD: "E", latitude: 0.7601702833333334, longitude: 127.46500231666667, timestamp: "2024-06-06T10:55:46.000Z" },
        { speed: "8.5", course: "91.6", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.607378", lon: "12727.947691", latD: "N", lonD: "E", latitude: 0.7601229666666666, longitude: 127.46579485, timestamp: "2024-06-06T10:56:06.000Z" },
        { speed: "8.5", course: "91.6", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.607378", lon: "12727.947691", latD: "N", lonD: "E", latitude: 0.7601229666666666, longitude: 127.46579485, timestamp: "2024-06-06T10:56:06.000Z" },
        { speed: "8.5", course: "91.6", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.607378", lon: "12727.947691", latD: "N", lonD: "E", latitude: 0.7601229666666666, longitude: 127.46579485, timestamp: "2024-06-06T10:56:06.000Z" },
        { speed: "8.5", course: "91.6", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.607378", lon: "12727.947691", latD: "N", lonD: "E", latitude: 0.7601229666666666, longitude: 127.46579485, timestamp: "2024-06-06T10:56:06.000Z" },
        { speed: "8.5", course: "91.6", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.607378", lon: "12727.947691", latD: "N", lonD: "E", latitude: 0.7601229666666666, longitude: 127.46579485, timestamp: "2024-06-06T10:56:06.000Z" },
        { speed: "8.5", course: "89.3", altitude: "11.3", satellites: "09", hdop: "0.6", lat: "0045.608019", lon: "12727.995816", latD: "N", lonD: "E", latitude: 0.76013365, longitude: 127.46659693333334, timestamp: "2024-06-06T10:56:26.000Z" },
        { speed: "8.5", course: "89.3", altitude: "11.3", satellites: "09", hdop: "0.6", lat: "0045.608019", lon: "12727.995816", latD: "N", lonD: "E", latitude: 0.76013365, longitude: 127.46659693333334, timestamp: "2024-06-06T10:56:26.000Z" },
        { speed: "8.3", course: "91.6", altitude: "19.4", satellites: "09", hdop: "0.6", lat: "0045.609584", lon: "12728.040984", latD: "N", lonD: "E", latitude: 0.7601597333333333, longitude: 127.46734973333334, timestamp: "2024-06-06T10:56:46.000Z" },
        { speed: "8.3", course: "91.6", altitude: "19.4", satellites: "09", hdop: "0.6", lat: "0045.609584", lon: "12728.040984", latD: "N", lonD: "E", latitude: 0.7601597333333333, longitude: 127.46734973333334, timestamp: "2024-06-06T10:56:46.000Z" },
        { speed: "8.5", course: "89.3", altitude: "11.3", satellites: "09", hdop: "0.6", lat: "0045.608019", lon: "12727.995816", latD: "N", lonD: "E", latitude: 0.76013365, longitude: 127.46659693333334, timestamp: "2024-06-06T10:56:26.000Z" },
        { speed: "8.3", course: "91.6", altitude: "19.4", satellites: "09", hdop: "0.6", lat: "0045.609584", lon: "12728.040984", latD: "N", lonD: "E", latitude: 0.7601597333333333, longitude: 127.46734973333334, timestamp: "2024-06-06T10:56:46.000Z" },
        { speed: "8.3", course: "91.6", altitude: "19.4", satellites: "09", hdop: "0.6", lat: "0045.609584", lon: "12728.040984", latD: "N", lonD: "E", latitude: 0.7601597333333333, longitude: 127.46734973333334, timestamp: "2024-06-06T10:56:46.000Z" },
        { speed: "8.3", course: "91.6", altitude: "19.4", satellites: "09", hdop: "0.6", lat: "0045.609584", lon: "12728.040984", latD: "N", lonD: "E", latitude: 0.7601597333333333, longitude: 127.46734973333334, timestamp: "2024-06-06T10:56:46.000Z" },
        { speed: "8.3", course: "90.7", altitude: "19.2", satellites: "09", hdop: "0.6", lat: "0045.610529", lon: "12728.091144", latD: "N", lonD: "E", latitude: 0.7601754833333333, longitude: 127.46818573333333, timestamp: "2024-06-06T10:57:06.000Z" },
        { speed: "8.3", course: "90.7", altitude: "19.2", satellites: "09", hdop: "0.6", lat: "0045.610529", lon: "12728.091144", latD: "N", lonD: "E", latitude: 0.7601754833333333, longitude: 127.46818573333333, timestamp: "2024-06-06T10:57:06.000Z" },
        { speed: "8.6", course: "90.7", altitude: "19.3", satellites: "09", hdop: "0.6", lat: "0045.608905", lon: "12728.137804", latD: "N", lonD: "E", latitude: 0.7601484166666667, longitude: 127.4689634, timestamp: "2024-06-06T10:57:26.000Z" },
        { speed: "8.3", course: "90.7", altitude: "19.2", satellites: "09", hdop: "0.6", lat: "0045.610529", lon: "12728.091144", latD: "N", lonD: "E", latitude: 0.7601754833333333, longitude: 127.46818573333333, timestamp: "2024-06-06T10:57:06.000Z" },
        { speed: "8.6", course: "90.7", altitude: "19.3", satellites: "09", hdop: "0.6", lat: "0045.608905", lon: "12728.137804", latD: "N", lonD: "E", latitude: 0.7601484166666667, longitude: 127.4689634, timestamp: "2024-06-06T10:57:26.000Z" },
        { speed: "8.6", course: "90.7", altitude: "19.3", satellites: "09", hdop: "0.6", lat: "0045.608905", lon: "12728.137804", latD: "N", lonD: "E", latitude: 0.7601484166666667, longitude: 127.4689634, timestamp: "2024-06-06T10:57:26.000Z" },
        { speed: "8.6", course: "90.7", altitude: "19.3", satellites: "09", hdop: "0.6", lat: "0045.608905", lon: "12728.137804", latD: "N", lonD: "E", latitude: 0.7601484166666667, longitude: 127.4689634, timestamp: "2024-06-06T10:57:26.000Z" },
        { speed: "8.6", course: "90.0", altitude: "8.4", satellites: "09", hdop: "0.6", lat: "0045.608682", lon: "12728.184413", latD: "N", lonD: "E", latitude: 0.7601447, longitude: 127.46974021666666, timestamp: "2024-06-06T10:57:46.000Z" },
        { speed: "8.6", course: "90.0", altitude: "8.4", satellites: "09", hdop: "0.6", lat: "0045.608682", lon: "12728.184413", latD: "N", lonD: "E", latitude: 0.7601447, longitude: 127.46974021666666, timestamp: "2024-06-06T10:57:46.000Z" },
        { speed: "8.6", course: "90.0", altitude: "8.4", satellites: "09", hdop: "0.6", lat: "0045.608682", lon: "12728.184413", latD: "N", lonD: "E", latitude: 0.7601447, longitude: 127.46974021666666, timestamp: "2024-06-06T10:57:46.000Z" },
        { speed: "8.6", course: "90.0", altitude: "8.4", satellites: "09", hdop: "0.6", lat: "0045.608682", lon: "12728.184413", latD: "N", lonD: "E", latitude: 0.7601447, longitude: 127.46974021666666, timestamp: "2024-06-06T10:57:46.000Z" },
        { speed: "8.7", course: "87.0", altitude: "18.6", satellites: "09", hdop: "0.6", lat: "0045.609313", lon: "12728.232979", latD: "N", lonD: "E", latitude: 0.7601552166666666, longitude: 127.47054965, timestamp: "2024-06-06T10:58:06.000Z" },
        { speed: "8.7", course: "87.0", altitude: "18.6", satellites: "09", hdop: "0.6", lat: "0045.609313", lon: "12728.232979", latD: "N", lonD: "E", latitude: 0.7601552166666666, longitude: 127.47054965, timestamp: "2024-06-06T10:58:06.000Z" },
        { speed: "8.7", course: "87.0", altitude: "18.6", satellites: "09", hdop: "0.6", lat: "0045.609313", lon: "12728.232979", latD: "N", lonD: "E", latitude: 0.7601552166666666, longitude: 127.47054965, timestamp: "2024-06-06T10:58:06.000Z" },
        { speed: "8.8", course: "88.1", altitude: "17.4", satellites: "09", hdop: "0.6", lat: "0045.611486", lon: "12728.281001", latD: "N", lonD: "E", latitude: 0.7601914333333333, longitude: 127.47135001666666, timestamp: "2024-06-06T10:58:26.000Z" },
        { speed: "8.8", course: "88.1", altitude: "17.4", satellites: "09", hdop: "0.6", lat: "0045.611486", lon: "12728.281001", latD: "N", lonD: "E", latitude: 0.7601914333333333, longitude: 127.47135001666666, timestamp: "2024-06-06T10:58:26.000Z" },
        { speed: "8.7", course: "87.0", altitude: "18.6", satellites: "09", hdop: "0.6", lat: "0045.609313", lon: "12728.232979", latD: "N", lonD: "E", latitude: 0.7601552166666666, longitude: 127.47054965, timestamp: "2024-06-06T10:58:06.000Z" },
        { speed: "8.8", course: "88.1", altitude: "17.4", satellites: "09", hdop: "0.6", lat: "0045.611486", lon: "12728.281001", latD: "N", lonD: "E", latitude: 0.7601914333333333, longitude: 127.47135001666666, timestamp: "2024-06-06T10:58:26.000Z" },
        { speed: "8.8", course: "88.1", altitude: "17.4", satellites: "09", hdop: "0.6", lat: "0045.611486", lon: "12728.281001", latD: "N", lonD: "E", latitude: 0.7601914333333333, longitude: 127.47135001666666, timestamp: "2024-06-06T10:58:26.000Z" },
        { speed: "8.8", course: "88.1", altitude: "17.4", satellites: "09", hdop: "0.6", lat: "0045.611486", lon: "12728.281001", latD: "N", lonD: "E", latitude: 0.7601914333333333, longitude: 127.47135001666666, timestamp: "2024-06-06T10:58:26.000Z" },
        { speed: "8.6", course: "84.5", altitude: "16.5", satellites: "09", hdop: "0.6", lat: "0045.616661", lon: "12728.330678", latD: "N", lonD: "E", latitude: 0.7602776833333333, longitude: 127.47217796666666, timestamp: "2024-06-06T10:58:46.000Z" },
        { speed: "8.6", course: "84.5", altitude: "16.5", satellites: "09", hdop: "0.6", lat: "0045.616661", lon: "12728.330678", latD: "N", lonD: "E", latitude: 0.7602776833333333, longitude: 127.47217796666666, timestamp: "2024-06-06T10:58:46.000Z" },
        { speed: "8.6", course: "84.4", altitude: "8.3", satellites: "09", hdop: "0.6", lat: "0045.619131", lon: "12728.377843", latD: "N", lonD: "E", latitude: 0.7603188500000001, longitude: 127.47296405, timestamp: "2024-06-06T10:59:06.000Z" },
        { speed: "8.6", course: "84.5", altitude: "16.5", satellites: "09", hdop: "0.6", lat: "0045.616661", lon: "12728.330678", latD: "N", lonD: "E", latitude: 0.7602776833333333, longitude: 127.47217796666666, timestamp: "2024-06-06T10:58:46.000Z" },
        { speed: "8.6", course: "84.5", altitude: "16.5", satellites: "09", hdop: "0.6", lat: "0045.616661", lon: "12728.330678", latD: "N", lonD: "E", latitude: 0.7602776833333333, longitude: 127.47217796666666, timestamp: "2024-06-06T10:58:46.000Z" },
        { speed: "8.6", course: "84.4", altitude: "8.3", satellites: "09", hdop: "0.6", lat: "0045.619131", lon: "12728.377843", latD: "N", lonD: "E", latitude: 0.7603188500000001, longitude: 127.47296405, timestamp: "2024-06-06T10:59:06.000Z" },
        { speed: "9.0", course: "85.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.622425", lon: "12728.425415", latD: "N", lonD: "E", latitude: 0.76037375, longitude: 127.47375691666667, timestamp: "2024-06-06T10:59:26.000Z" },
        { speed: "9.0", course: "85.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.622425", lon: "12728.425415", latD: "N", lonD: "E", latitude: 0.76037375, longitude: 127.47375691666667, timestamp: "2024-06-06T10:59:26.000Z" },
        { speed: "9.0", course: "85.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.622425", lon: "12728.425415", latD: "N", lonD: "E", latitude: 0.76037375, longitude: 127.47375691666667, timestamp: "2024-06-06T10:59:26.000Z" },
        { speed: "9.0", course: "85.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.622425", lon: "12728.425415", latD: "N", lonD: "E", latitude: 0.76037375, longitude: 127.47375691666667, timestamp: "2024-06-06T10:59:26.000Z" },
        { speed: "8.5", course: "90.3", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.624053", lon: "12728.472640", latD: "N", lonD: "E", latitude: 0.7604008833333334, longitude: 127.474544, timestamp: "2024-06-06T10:59:46.000Z" },
        { speed: "8.5", course: "90.3", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.624053", lon: "12728.472640", latD: "N", lonD: "E", latitude: 0.7604008833333334, longitude: 127.474544, timestamp: "2024-06-06T10:59:46.000Z" },
        { speed: "8.5", course: "90.3", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.624053", lon: "12728.472640", latD: "N", lonD: "E", latitude: 0.7604008833333334, longitude: 127.474544, timestamp: "2024-06-06T10:59:46.000Z" },
        { speed: "8.5", course: "90.3", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.624053", lon: "12728.472640", latD: "N", lonD: "E", latitude: 0.7604008833333334, longitude: 127.474544, timestamp: "2024-06-06T10:59:46.000Z" },
        { speed: "8.5", course: "90.3", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.624053", lon: "12728.472640", latD: "N", lonD: "E", latitude: 0.7604008833333334, longitude: 127.474544, timestamp: "2024-06-06T10:59:46.000Z" },
        { speed: "8.8", course: "91.2", altitude: "19.5", satellites: "09", hdop: "0.6", lat: "0045.624289", lon: "12728.518595", latD: "N", lonD: "E", latitude: 0.7604048166666666, longitude: 127.47530991666666, timestamp: "2024-06-06T11:00:06.000Z" },
        { speed: "8.8", course: "91.2", altitude: "19.5", satellites: "09", hdop: "0.6", lat: "0045.624289", lon: "12728.518595", latD: "N", lonD: "E", latitude: 0.7604048166666666, longitude: 127.47530991666666, timestamp: "2024-06-06T11:00:06.000Z" },
        { speed: "8.8", course: "91.2", altitude: "19.5", satellites: "09", hdop: "0.6", lat: "0045.624289", lon: "12728.518595", latD: "N", lonD: "E", latitude: 0.7604048166666666, longitude: 127.47530991666666, timestamp: "2024-06-06T11:00:06.000Z" },
        { speed: "8.5", course: "87.9", altitude: "11.9", satellites: "09", hdop: "0.6", lat: "0045.624627", lon: "12728.567629", latD: "N", lonD: "E", latitude: 0.76041045, longitude: 127.47612715, timestamp: "2024-06-06T11:00:26.000Z" },
        { speed: "8.5", course: "87.9", altitude: "11.9", satellites: "09", hdop: "0.6", lat: "0045.624627", lon: "12728.567629", latD: "N", lonD: "E", latitude: 0.76041045, longitude: 127.47612715, timestamp: "2024-06-06T11:00:26.000Z" },
        { speed: "8.5", course: "87.9", altitude: "11.9", satellites: "09", hdop: "0.6", lat: "0045.624627", lon: "12728.567629", latD: "N", lonD: "E", latitude: 0.76041045, longitude: 127.47612715, timestamp: "2024-06-06T11:00:26.000Z" },
        { speed: "8.5", course: "87.9", altitude: "11.9", satellites: "09", hdop: "0.6", lat: "0045.624627", lon: "12728.567629", latD: "N", lonD: "E", latitude: 0.76041045, longitude: 127.47612715, timestamp: "2024-06-06T11:00:26.000Z" },
        { speed: "8.5", course: "87.9", altitude: "11.9", satellites: "09", hdop: "0.6", lat: "0045.624627", lon: "12728.567629", latD: "N", lonD: "E", latitude: 0.76041045, longitude: 127.47612715, timestamp: "2024-06-06T11:00:26.000Z" },
        { speed: "8.6", course: "84.9", altitude: "9.6", satellites: "09", hdop: "0.6", lat: "0045.627110", lon: "12728.615581", latD: "N", lonD: "E", latitude: 0.7604518333333333, longitude: 127.47692635, timestamp: "2024-06-06T11:00:46.000Z" },
        { speed: "8.5", course: "87.9", altitude: "11.9", satellites: "09", hdop: "0.6", lat: "0045.624627", lon: "12728.567629", latD: "N", lonD: "E", latitude: 0.76041045, longitude: 127.47612715, timestamp: "2024-06-06T11:00:26.000Z" },
        { speed: "8.6", course: "84.9", altitude: "9.6", satellites: "09", hdop: "0.6", lat: "0045.627110", lon: "12728.615581", latD: "N", lonD: "E", latitude: 0.7604518333333333, longitude: 127.47692635, timestamp: "2024-06-06T11:00:46.000Z" },
        { speed: "8.6", course: "83.9", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.630339", lon: "12728.662577", latD: "N", lonD: "E", latitude: 0.76050565, longitude: 127.47770961666667, timestamp: "2024-06-06T11:01:06.000Z" },
        { speed: "8.6", course: "83.9", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.630339", lon: "12728.662577", latD: "N", lonD: "E", latitude: 0.76050565, longitude: 127.47770961666667, timestamp: "2024-06-06T11:01:06.000Z" },
        { speed: "8.6", course: "83.9", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.630339", lon: "12728.662577", latD: "N", lonD: "E", latitude: 0.76050565, longitude: 127.47770961666667, timestamp: "2024-06-06T11:01:06.000Z" },
        { speed: "8.6", course: "83.9", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.630339", lon: "12728.662577", latD: "N", lonD: "E", latitude: 0.76050565, longitude: 127.47770961666667, timestamp: "2024-06-06T11:01:06.000Z" },
        { speed: "8.6", course: "83.9", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.630339", lon: "12728.662577", latD: "N", lonD: "E", latitude: 0.76050565, longitude: 127.47770961666667, timestamp: "2024-06-06T11:01:06.000Z" },
        { speed: "8.9", course: "84.5", altitude: "5.8", satellites: "09", hdop: "0.5", lat: "0045.635669", lon: "12728.710293", latD: "N", lonD: "E", latitude: 0.7605944833333333, longitude: 127.47850488333333, timestamp: "2024-06-06T11:01:26.000Z" },
        { speed: "8.9", course: "84.5", altitude: "5.8", satellites: "09", hdop: "0.5", lat: "0045.635669", lon: "12728.710293", latD: "N", lonD: "E", latitude: 0.7605944833333333, longitude: 127.47850488333333, timestamp: "2024-06-06T11:01:26.000Z" },
        { speed: "8.9", course: "84.5", altitude: "5.8", satellites: "09", hdop: "0.5", lat: "0045.635669", lon: "12728.710293", latD: "N", lonD: "E", latitude: 0.7605944833333333, longitude: 127.47850488333333, timestamp: "2024-06-06T11:01:26.000Z" },
        { speed: "8.5", course: "85.4", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.642476", lon: "12728.757982", latD: "N", lonD: "E", latitude: 0.7607079333333334, longitude: 127.4792997, timestamp: "2024-06-06T11:01:46.000Z" },
        { speed: "8.5", course: "85.4", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.642476", lon: "12728.757982", latD: "N", lonD: "E", latitude: 0.7607079333333334, longitude: 127.4792997, timestamp: "2024-06-06T11:01:46.000Z" },
        { speed: "8.5", course: "85.4", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.642476", lon: "12728.757982", latD: "N", lonD: "E", latitude: 0.7607079333333334, longitude: 127.4792997, timestamp: "2024-06-06T11:01:46.000Z" },
        { speed: "7.5", course: "88.8", altitude: "5.3", satellites: "09", hdop: "0.6", lat: "0045.643866", lon: "12728.803788", latD: "N", lonD: "E", latitude: 0.7607311, longitude: 127.48006313333333, timestamp: "2024-06-06T11:02:06.000Z" },
        { speed: "8.5", course: "85.4", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.642476", lon: "12728.757982", latD: "N", lonD: "E", latitude: 0.7607079333333334, longitude: 127.4792997, timestamp: "2024-06-06T11:01:46.000Z" },
        { speed: "7.5", course: "88.8", altitude: "5.3", satellites: "09", hdop: "0.6", lat: "0045.643866", lon: "12728.803788", latD: "N", lonD: "E", latitude: 0.7607311, longitude: 127.48006313333333, timestamp: "2024-06-06T11:02:06.000Z" },
        { speed: "8.5", course: "85.4", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.642476", lon: "12728.757982", latD: "N", lonD: "E", latitude: 0.7607079333333334, longitude: 127.4792997, timestamp: "2024-06-06T11:01:46.000Z" },
        { speed: "7.5", course: "88.8", altitude: "5.3", satellites: "09", hdop: "0.6", lat: "0045.643866", lon: "12728.803788", latD: "N", lonD: "E", latitude: 0.7607311, longitude: 127.48006313333333, timestamp: "2024-06-06T11:02:06.000Z" },
        { speed: "8.5", course: "88.8", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.645851", lon: "12728.849555", latD: "N", lonD: "E", latitude: 0.7607641833333333, longitude: 127.48082591666666, timestamp: "2024-06-06T11:02:26.000Z" },
        { speed: "8.5", course: "88.8", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.645851", lon: "12728.849555", latD: "N", lonD: "E", latitude: 0.7607641833333333, longitude: 127.48082591666666, timestamp: "2024-06-06T11:02:26.000Z" },
        { speed: "8.5", course: "88.8", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.645851", lon: "12728.849555", latD: "N", lonD: "E", latitude: 0.7607641833333333, longitude: 127.48082591666666, timestamp: "2024-06-06T11:02:26.000Z" },
        { speed: "8.5", course: "88.8", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.645851", lon: "12728.849555", latD: "N", lonD: "E", latitude: 0.7607641833333333, longitude: 127.48082591666666, timestamp: "2024-06-06T11:02:26.000Z" },
        { speed: "8.5", course: "88.8", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.645851", lon: "12728.849555", latD: "N", lonD: "E", latitude: 0.7607641833333333, longitude: 127.48082591666666, timestamp: "2024-06-06T11:02:26.000Z" },
        { speed: "8.5", course: "88.8", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.645851", lon: "12728.849555", latD: "N", lonD: "E", latitude: 0.7607641833333333, longitude: 127.48082591666666, timestamp: "2024-06-06T11:02:26.000Z" },
        { speed: "8.7", course: "87.1", altitude: "7.3", satellites: "09", hdop: "0.6", lat: "0045.649216", lon: "12728.894283", latD: "N", lonD: "E", latitude: 0.7608202666666667, longitude: 127.48157138333333, timestamp: "2024-06-06T11:02:46.000Z" },
        { speed: "8.7", course: "87.1", altitude: "7.3", satellites: "09", hdop: "0.6", lat: "0045.649216", lon: "12728.894283", latD: "N", lonD: "E", latitude: 0.7608202666666667, longitude: 127.48157138333333, timestamp: "2024-06-06T11:02:46.000Z" },
        { speed: "8.7", course: "87.1", altitude: "7.3", satellites: "09", hdop: "0.6", lat: "0045.649216", lon: "12728.894283", latD: "N", lonD: "E", latitude: 0.7608202666666667, longitude: 127.48157138333333, timestamp: "2024-06-06T11:02:46.000Z" },
        { speed: "8.7", course: "87.1", altitude: "7.3", satellites: "09", hdop: "0.6", lat: "0045.649216", lon: "12728.894283", latD: "N", lonD: "E", latitude: 0.7608202666666667, longitude: 127.48157138333333, timestamp: "2024-06-06T11:02:46.000Z" },
        { speed: "8.5", course: "88.2", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.650401", lon: "12728.941710", latD: "N", lonD: "E", latitude: 0.7608400166666667, longitude: 127.48236183333333, timestamp: "2024-06-06T11:03:06.000Z" },
        { speed: "8.5", course: "88.6", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.650864", lon: "12728.989560", latD: "N", lonD: "E", latitude: 0.7608477333333333, longitude: 127.48315933333333, timestamp: "2024-06-06T11:03:26.000Z" },
        { speed: "8.5", course: "88.6", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.650864", lon: "12728.989560", latD: "N", lonD: "E", latitude: 0.7608477333333333, longitude: 127.48315933333333, timestamp: "2024-06-06T11:03:26.000Z" },
        { speed: "8.5", course: "88.2", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.650401", lon: "12728.941710", latD: "N", lonD: "E", latitude: 0.7608400166666667, longitude: 127.48236183333333, timestamp: "2024-06-06T11:03:06.000Z" },
        { speed: "8.5", course: "88.2", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.650401", lon: "12728.941710", latD: "N", lonD: "E", latitude: 0.7608400166666667, longitude: 127.48236183333333, timestamp: "2024-06-06T11:03:06.000Z" },
        { speed: "8.5", course: "88.6", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.650864", lon: "12728.989560", latD: "N", lonD: "E", latitude: 0.7608477333333333, longitude: 127.48315933333333, timestamp: "2024-06-06T11:03:26.000Z" },
        { speed: "8.5", course: "88.6", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.650864", lon: "12728.989560", latD: "N", lonD: "E", latitude: 0.7608477333333333, longitude: 127.48315933333333, timestamp: "2024-06-06T11:03:26.000Z" },
        { speed: "8.5", course: "88.6", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.650864", lon: "12728.989560", latD: "N", lonD: "E", latitude: 0.7608477333333333, longitude: 127.48315933333333, timestamp: "2024-06-06T11:03:26.000Z" },
        { speed: "8.3", course: "86.8", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.652467", lon: "12729.034793", latD: "N", lonD: "E", latitude: 0.76087445, longitude: 127.48391321666666, timestamp: "2024-06-06T11:03:46.000Z" },
        { speed: "8.3", course: "86.8", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.652467", lon: "12729.034793", latD: "N", lonD: "E", latitude: 0.76087445, longitude: 127.48391321666666, timestamp: "2024-06-06T11:03:46.000Z" },
        { speed: "8.1", course: "87.1", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.653947", lon: "12729.080130", latD: "N", lonD: "E", latitude: 0.7608991166666667, longitude: 127.48466883333333, timestamp: "2024-06-06T11:04:06.000Z" },
        { speed: "8.1", course: "87.1", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.653947", lon: "12729.080130", latD: "N", lonD: "E", latitude: 0.7608991166666667, longitude: 127.48466883333333, timestamp: "2024-06-06T11:04:06.000Z" },
        { speed: "8.1", course: "87.1", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.653947", lon: "12729.080130", latD: "N", lonD: "E", latitude: 0.7608991166666667, longitude: 127.48466883333333, timestamp: "2024-06-06T11:04:06.000Z" },
        { speed: "8.1", course: "87.1", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.653947", lon: "12729.080130", latD: "N", lonD: "E", latitude: 0.7608991166666667, longitude: 127.48466883333333, timestamp: "2024-06-06T11:04:06.000Z" },
        { speed: "8.5", course: "83.8", altitude: "7.4", satellites: "10", hdop: "0.6", lat: "0045.657562", lon: "12729.127978", latD: "N", lonD: "E", latitude: 0.7609593666666666, longitude: 127.4854663, timestamp: "2024-06-06T11:04:26.000Z" },
        { speed: "8.1", course: "87.1", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.653947", lon: "12729.080130", latD: "N", lonD: "E", latitude: 0.7608991166666667, longitude: 127.48466883333333, timestamp: "2024-06-06T11:04:06.000Z" },
        { speed: "8.1", course: "87.1", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.653947", lon: "12729.080130", latD: "N", lonD: "E", latitude: 0.7608991166666667, longitude: 127.48466883333333, timestamp: "2024-06-06T11:04:06.000Z" },
        { speed: "8.5", course: "83.8", altitude: "7.4", satellites: "10", hdop: "0.6", lat: "0045.657562", lon: "12729.127978", latD: "N", lonD: "E", latitude: 0.7609593666666666, longitude: 127.4854663, timestamp: "2024-06-06T11:04:26.000Z" },
        { speed: "8.5", course: "83.8", altitude: "7.4", satellites: "10", hdop: "0.6", lat: "0045.657562", lon: "12729.127978", latD: "N", lonD: "E", latitude: 0.7609593666666666, longitude: 127.4854663, timestamp: "2024-06-06T11:04:26.000Z" },
        { speed: "8.6", course: "85.5", altitude: "6.8", satellites: "10", hdop: "0.5", lat: "0045.662026", lon: "12729.176787", latD: "N", lonD: "E", latitude: 0.7610337666666667, longitude: 127.48627978333333, timestamp: "2024-06-06T11:04:46.000Z" },
        { speed: "8.6", course: "85.5", altitude: "6.8", satellites: "10", hdop: "0.5", lat: "0045.662026", lon: "12729.176787", latD: "N", lonD: "E", latitude: 0.7610337666666667, longitude: 127.48627978333333, timestamp: "2024-06-06T11:04:46.000Z" },
        { speed: "8.6", course: "85.5", altitude: "6.8", satellites: "10", hdop: "0.5", lat: "0045.662026", lon: "12729.176787", latD: "N", lonD: "E", latitude: 0.7610337666666667, longitude: 127.48627978333333, timestamp: "2024-06-06T11:04:46.000Z" },
        { speed: "8.3", course: "86.9", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.665336", lon: "12729.223302", latD: "N", lonD: "E", latitude: 0.7610889333333334, longitude: 127.48705503333333, timestamp: "2024-06-06T11:05:06.000Z" },
        { speed: "8.3", course: "86.9", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.665336", lon: "12729.223302", latD: "N", lonD: "E", latitude: 0.7610889333333334, longitude: 127.48705503333333, timestamp: "2024-06-06T11:05:06.000Z" },
        { speed: "8.3", course: "86.9", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.665336", lon: "12729.223302", latD: "N", lonD: "E", latitude: 0.7610889333333334, longitude: 127.48705503333333, timestamp: "2024-06-06T11:05:06.000Z" },
        { speed: "8.3", course: "86.9", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.665336", lon: "12729.223302", latD: "N", lonD: "E", latitude: 0.7610889333333334, longitude: 127.48705503333333, timestamp: "2024-06-06T11:05:06.000Z" },
        { speed: "8.3", course: "86.9", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.665336", lon: "12729.223302", latD: "N", lonD: "E", latitude: 0.7610889333333334, longitude: 127.48705503333333, timestamp: "2024-06-06T11:05:06.000Z" },
        { speed: "8.3", course: "86.9", altitude: "3.7", satellites: "10", hdop: "0.6", lat: "0045.665336", lon: "12729.223302", latD: "N", lonD: "E", latitude: 0.7610889333333334, longitude: 127.48705503333333, timestamp: "2024-06-06T11:05:06.000Z" },
        { speed: "8.4", course: "85.0", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.667767", lon: "12729.268818", latD: "N", lonD: "E", latitude: 0.76112945, longitude: 127.48781363333333, timestamp: "2024-06-06T11:05:26.000Z" },
        { speed: "8.4", course: "85.0", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.667767", lon: "12729.268818", latD: "N", lonD: "E", latitude: 0.76112945, longitude: 127.48781363333333, timestamp: "2024-06-06T11:05:26.000Z" },
        { speed: "8.3", course: "82.6", altitude: "10.5", satellites: "10", hdop: "0.5", lat: "0045.673359", lon: "12729.318901", latD: "N", lonD: "E", latitude: 0.76122265, longitude: 127.48864835, timestamp: "2024-06-06T11:05:46.000Z" },
        { speed: "8.4", course: "85.0", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.667767", lon: "12729.268818", latD: "N", lonD: "E", latitude: 0.76112945, longitude: 127.48781363333333, timestamp: "2024-06-06T11:05:26.000Z" },
        { speed: "8.4", course: "85.0", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.667767", lon: "12729.268818", latD: "N", lonD: "E", latitude: 0.76112945, longitude: 127.48781363333333, timestamp: "2024-06-06T11:05:26.000Z" },
        { speed: "8.3", course: "82.6", altitude: "10.5", satellites: "10", hdop: "0.5", lat: "0045.673359", lon: "12729.318901", latD: "N", lonD: "E", latitude: 0.76122265, longitude: 127.48864835, timestamp: "2024-06-06T11:05:46.000Z" },
        { speed: "8.3", course: "82.6", altitude: "10.5", satellites: "10", hdop: "0.5", lat: "0045.673359", lon: "12729.318901", latD: "N", lonD: "E", latitude: 0.76122265, longitude: 127.48864835, timestamp: "2024-06-06T11:05:46.000Z" },
        { speed: "8.3", course: "82.6", altitude: "10.5", satellites: "10", hdop: "0.5", lat: "0045.673359", lon: "12729.318901", latD: "N", lonD: "E", latitude: 0.76122265, longitude: 127.48864835, timestamp: "2024-06-06T11:05:46.000Z" },
        { speed: "8.2", course: "83.7", altitude: "12.4", satellites: "10", hdop: "0.5", lat: "0045.678869", lon: "12729.365439", latD: "N", lonD: "E", latitude: 0.7613144833333333, longitude: 127.48942398333334, timestamp: "2024-06-06T11:06:06.000Z" },
        { speed: "8.2", course: "83.7", altitude: "12.4", satellites: "10", hdop: "0.5", lat: "0045.678869", lon: "12729.365439", latD: "N", lonD: "E", latitude: 0.7613144833333333, longitude: 127.48942398333334, timestamp: "2024-06-06T11:06:06.000Z" },
        { speed: "8.2", course: "83.7", altitude: "12.4", satellites: "10", hdop: "0.5", lat: "0045.678869", lon: "12729.365439", latD: "N", lonD: "E", latitude: 0.7613144833333333, longitude: 127.48942398333334, timestamp: "2024-06-06T11:06:06.000Z" },
        { speed: "8.2", course: "83.7", altitude: "12.4", satellites: "10", hdop: "0.5", lat: "0045.678869", lon: "12729.365439", latD: "N", lonD: "E", latitude: 0.7613144833333333, longitude: 127.48942398333334, timestamp: "2024-06-06T11:06:06.000Z" },
        { speed: "8.6", course: "84.6", altitude: "16.0", satellites: "10", hdop: "0.5", lat: "0045.685195", lon: "12729.411514", latD: "N", lonD: "E", latitude: 0.7614199166666666, longitude: 127.4901919, timestamp: "2024-06-06T11:06:26.000Z" },
        { speed: "8.6", course: "84.6", altitude: "16.0", satellites: "10", hdop: "0.5", lat: "0045.685195", lon: "12729.411514", latD: "N", lonD: "E", latitude: 0.7614199166666666, longitude: 127.4901919, timestamp: "2024-06-06T11:06:26.000Z" },
        { speed: "8.3", course: "86.6", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.687415", lon: "12729.458731", latD: "N", lonD: "E", latitude: 0.7614569166666667, longitude: 127.49097885, timestamp: "2024-06-06T11:06:46.000Z" },
        { speed: "8.3", course: "86.6", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.687415", lon: "12729.458731", latD: "N", lonD: "E", latitude: 0.7614569166666667, longitude: 127.49097885, timestamp: "2024-06-06T11:06:46.000Z" },
        { speed: "8.3", course: "86.6", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.687415", lon: "12729.458731", latD: "N", lonD: "E", latitude: 0.7614569166666667, longitude: 127.49097885, timestamp: "2024-06-06T11:06:46.000Z" },
        { speed: "8.3", course: "86.6", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.687415", lon: "12729.458731", latD: "N", lonD: "E", latitude: 0.7614569166666667, longitude: 127.49097885, timestamp: "2024-06-06T11:06:46.000Z" },
        { speed: "8.3", course: "86.6", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.687415", lon: "12729.458731", latD: "N", lonD: "E", latitude: 0.7614569166666667, longitude: 127.49097885, timestamp: "2024-06-06T11:06:46.000Z" },
        { speed: "8.8", course: "88.0", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.687540", lon: "12729.505238", latD: "N", lonD: "E", latitude: 0.761459, longitude: 127.49175396666666, timestamp: "2024-06-06T11:07:06.000Z" },
        { speed: "8.8", course: "88.0", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.687540", lon: "12729.505238", latD: "N", lonD: "E", latitude: 0.761459, longitude: 127.49175396666666, timestamp: "2024-06-06T11:07:06.000Z" },
        { speed: "8.8", course: "88.0", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.687540", lon: "12729.505238", latD: "N", lonD: "E", latitude: 0.761459, longitude: 127.49175396666666, timestamp: "2024-06-06T11:07:06.000Z" },
        { speed: "8.8", course: "88.0", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.687540", lon: "12729.505238", latD: "N", lonD: "E", latitude: 0.761459, longitude: 127.49175396666666, timestamp: "2024-06-06T11:07:06.000Z" },
        { speed: "8.6", course: "87.2", altitude: "4.3", satellites: "10", hdop: "0.5", lat: "0045.691489", lon: "12729.552161", latD: "N", lonD: "E", latitude: 0.7615248166666666, longitude: 127.49253601666666, timestamp: "2024-06-06T11:07:26.000Z" },
        { speed: "8.6", course: "87.2", altitude: "4.3", satellites: "10", hdop: "0.5", lat: "0045.691489", lon: "12729.552161", latD: "N", lonD: "E", latitude: 0.7615248166666666, longitude: 127.49253601666666, timestamp: "2024-06-06T11:07:26.000Z" },
        { speed: "8.6", course: "87.2", altitude: "4.3", satellites: "10", hdop: "0.5", lat: "0045.691489", lon: "12729.552161", latD: "N", lonD: "E", latitude: 0.7615248166666666, longitude: 127.49253601666666, timestamp: "2024-06-06T11:07:26.000Z" },
        { speed: "8.8", course: "87.2", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.697894", lon: "12729.598147", latD: "N", lonD: "E", latitude: 0.7616315666666666, longitude: 127.49330245, timestamp: "2024-06-06T11:07:46.000Z" },
        { speed: "8.8", course: "87.2", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.697894", lon: "12729.598147", latD: "N", lonD: "E", latitude: 0.7616315666666666, longitude: 127.49330245, timestamp: "2024-06-06T11:07:46.000Z" },
        { speed: "8.6", course: "87.2", altitude: "4.3", satellites: "10", hdop: "0.5", lat: "0045.691489", lon: "12729.552161", latD: "N", lonD: "E", latitude: 0.7615248166666666, longitude: 127.49253601666666, timestamp: "2024-06-06T11:07:26.000Z" },
        { speed: "8.8", course: "87.2", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.697894", lon: "12729.598147", latD: "N", lonD: "E", latitude: 0.7616315666666666, longitude: 127.49330245, timestamp: "2024-06-06T11:07:46.000Z" },
        { speed: "8.8", course: "87.2", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.697894", lon: "12729.598147", latD: "N", lonD: "E", latitude: 0.7616315666666666, longitude: 127.49330245, timestamp: "2024-06-06T11:07:46.000Z" },
        { speed: "8.8", course: "86.8", altitude: "8.5", satellites: "10", hdop: "0.5", lat: "0045.701476", lon: "12729.645066", latD: "N", lonD: "E", latitude: 0.7616912666666666, longitude: 127.49408443333333, timestamp: "2024-06-06T11:08:06.000Z" },
        { speed: "8.8", course: "86.8", altitude: "8.5", satellites: "10", hdop: "0.5", lat: "0045.701476", lon: "12729.645066", latD: "N", lonD: "E", latitude: 0.7616912666666666, longitude: 127.49408443333333, timestamp: "2024-06-06T11:08:06.000Z" },
        { speed: "8.8", course: "86.8", altitude: "8.5", satellites: "10", hdop: "0.5", lat: "0045.701476", lon: "12729.645066", latD: "N", lonD: "E", latitude: 0.7616912666666666, longitude: 127.49408443333333, timestamp: "2024-06-06T11:08:06.000Z" },
        { speed: "8.3", course: "89.8", altitude: "8.1", satellites: "10", hdop: "0.5", lat: "0045.699376", lon: "12729.694756", latD: "N", lonD: "E", latitude: 0.7616562666666666, longitude: 127.4949126, timestamp: "2024-06-06T11:08:26.000Z" },
        { speed: "8.8", course: "86.8", altitude: "8.5", satellites: "10", hdop: "0.5", lat: "0045.701476", lon: "12729.645066", latD: "N", lonD: "E", latitude: 0.7616912666666666, longitude: 127.49408443333333, timestamp: "2024-06-06T11:08:06.000Z" },
        { speed: "8.8", course: "86.8", altitude: "8.5", satellites: "10", hdop: "0.5", lat: "0045.701476", lon: "12729.645066", latD: "N", lonD: "E", latitude: 0.7616912666666666, longitude: 127.49408443333333, timestamp: "2024-06-06T11:08:06.000Z" },
        { speed: "8.3", course: "89.8", altitude: "8.1", satellites: "10", hdop: "0.5", lat: "0045.699376", lon: "12729.694756", latD: "N", lonD: "E", latitude: 0.7616562666666666, longitude: 127.4949126, timestamp: "2024-06-06T11:08:26.000Z" },
        { speed: "8.3", course: "89.8", altitude: "8.1", satellites: "10", hdop: "0.5", lat: "0045.699376", lon: "12729.694756", latD: "N", lonD: "E", latitude: 0.7616562666666666, longitude: 127.4949126, timestamp: "2024-06-06T11:08:26.000Z" },
        { speed: "8.7", course: "90.9", altitude: "5.8", satellites: "10", hdop: "0.5", lat: "0045.698381", lon: "12729.742534", latD: "N", lonD: "E", latitude: 0.7616396833333333, longitude: 127.4957089, timestamp: "2024-06-06T11:08:46.000Z" },
        { speed: "8.7", course: "90.9", altitude: "5.8", satellites: "10", hdop: "0.5", lat: "0045.698381", lon: "12729.742534", latD: "N", lonD: "E", latitude: 0.7616396833333333, longitude: 127.4957089, timestamp: "2024-06-06T11:08:46.000Z" },
        { speed: "8.7", course: "90.9", altitude: "5.8", satellites: "10", hdop: "0.5", lat: "0045.698381", lon: "12729.742534", latD: "N", lonD: "E", latitude: 0.7616396833333333, longitude: 127.4957089, timestamp: "2024-06-06T11:08:46.000Z" },
        { speed: "8.7", course: "90.9", altitude: "5.8", satellites: "10", hdop: "0.5", lat: "0045.698381", lon: "12729.742534", latD: "N", lonD: "E", latitude: 0.7616396833333333, longitude: 127.4957089, timestamp: "2024-06-06T11:08:46.000Z" },
        { speed: "8.6", course: "92.0", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.695660", lon: "12729.788959", latD: "N", lonD: "E", latitude: 0.7615943333333333, longitude: 127.49648265, timestamp: "2024-06-06T11:09:06.000Z" },
        { speed: "8.6", course: "92.0", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.695660", lon: "12729.788959", latD: "N", lonD: "E", latitude: 0.7615943333333333, longitude: 127.49648265, timestamp: "2024-06-06T11:09:06.000Z" },
        { speed: "8.6", course: "92.0", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.695660", lon: "12729.788959", latD: "N", lonD: "E", latitude: 0.7615943333333333, longitude: 127.49648265, timestamp: "2024-06-06T11:09:06.000Z" },
        { speed: "8.7", course: "92.1", altitude: "12.8", satellites: "09", hdop: "0.6", lat: "0045.693591", lon: "12729.835348", latD: "N", lonD: "E", latitude: 0.7615598499999999, longitude: 127.4972558, timestamp: "2024-06-06T11:09:26.000Z" },
        { speed: "8.7", course: "92.1", altitude: "12.8", satellites: "09", hdop: "0.6", lat: "0045.693591", lon: "12729.835348", latD: "N", lonD: "E", latitude: 0.7615598499999999, longitude: 127.4972558, timestamp: "2024-06-06T11:09:26.000Z" },
        { speed: "8.7", course: "92.1", altitude: "12.8", satellites: "09", hdop: "0.6", lat: "0045.693591", lon: "12729.835348", latD: "N", lonD: "E", latitude: 0.7615598499999999, longitude: 127.4972558, timestamp: "2024-06-06T11:09:26.000Z" },
        { speed: "8.7", course: "92.1", altitude: "12.8", satellites: "09", hdop: "0.6", lat: "0045.693591", lon: "12729.835348", latD: "N", lonD: "E", latitude: 0.7615598499999999, longitude: 127.4972558, timestamp: "2024-06-06T11:09:26.000Z" },
        { speed: "8.7", course: "92.1", altitude: "12.8", satellites: "09", hdop: "0.6", lat: "0045.693591", lon: "12729.835348", latD: "N", lonD: "E", latitude: 0.7615598499999999, longitude: 127.4972558, timestamp: "2024-06-06T11:09:26.000Z" },
        { speed: "8.4", course: "92.6", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.691905", lon: "12729.882768", latD: "N", lonD: "E", latitude: 0.76153175, longitude: 127.49804613333333, timestamp: "2024-06-06T11:09:46.000Z" },
        { speed: "8.4", course: "92.6", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.691905", lon: "12729.882768", latD: "N", lonD: "E", latitude: 0.76153175, longitude: 127.49804613333333, timestamp: "2024-06-06T11:09:46.000Z" },
        { speed: "8.4", course: "92.6", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.691905", lon: "12729.882768", latD: "N", lonD: "E", latitude: 0.76153175, longitude: 127.49804613333333, timestamp: "2024-06-06T11:09:46.000Z" },
        { speed: "8.4", course: "89.9", altitude: "7.1", satellites: "10", hdop: "0.6", lat: "0045.690591", lon: "12729.930886", latD: "N", lonD: "E", latitude: 0.7615098499999999, longitude: 127.4988481, timestamp: "2024-06-06T11:10:06.000Z" },
        { speed: "8.4", course: "92.6", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.691905", lon: "12729.882768", latD: "N", lonD: "E", latitude: 0.76153175, longitude: 127.49804613333333, timestamp: "2024-06-06T11:09:46.000Z" },
        { speed: "8.4", course: "89.9", altitude: "7.1", satellites: "10", hdop: "0.6", lat: "0045.690591", lon: "12729.930886", latD: "N", lonD: "E", latitude: 0.7615098499999999, longitude: 127.4988481, timestamp: "2024-06-06T11:10:06.000Z" },
        { speed: "8.4", course: "89.9", altitude: "7.1", satellites: "10", hdop: "0.6", lat: "0045.690591", lon: "12729.930886", latD: "N", lonD: "E", latitude: 0.7615098499999999, longitude: 127.4988481, timestamp: "2024-06-06T11:10:06.000Z" },
        { speed: "8.4", course: "89.9", altitude: "7.1", satellites: "10", hdop: "0.6", lat: "0045.690591", lon: "12729.930886", latD: "N", lonD: "E", latitude: 0.7615098499999999, longitude: 127.4988481, timestamp: "2024-06-06T11:10:06.000Z" },
        { speed: "8.6", course: "90.6", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.690596", lon: "12729.979068", latD: "N", lonD: "E", latitude: 0.7615099333333333, longitude: 127.49965113333333, timestamp: "2024-06-06T11:10:26.000Z" },
        { speed: "8.6", course: "90.6", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.690596", lon: "12729.979068", latD: "N", lonD: "E", latitude: 0.7615099333333333, longitude: 127.49965113333333, timestamp: "2024-06-06T11:10:26.000Z" },
        { speed: "8.6", course: "90.6", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.690596", lon: "12729.979068", latD: "N", lonD: "E", latitude: 0.7615099333333333, longitude: 127.49965113333333, timestamp: "2024-06-06T11:10:26.000Z" },
        { speed: "8.6", course: "90.6", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.690596", lon: "12729.979068", latD: "N", lonD: "E", latitude: 0.7615099333333333, longitude: 127.49965113333333, timestamp: "2024-06-06T11:10:26.000Z" },
        { speed: "8.6", course: "90.6", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.690596", lon: "12729.979068", latD: "N", lonD: "E", latitude: 0.7615099333333333, longitude: 127.49965113333333, timestamp: "2024-06-06T11:10:26.000Z" },
        { speed: "8.4", course: "90.2", altitude: "6.9", satellites: "09", hdop: "0.6", lat: "0045.690291", lon: "12730.025862", latD: "N", lonD: "E", latitude: 0.76150485, longitude: 127.50043103333333, timestamp: "2024-06-06T11:10:46.000Z" },
        { speed: "8.4", course: "90.2", altitude: "6.9", satellites: "09", hdop: "0.6", lat: "0045.690291", lon: "12730.025862", latD: "N", lonD: "E", latitude: 0.76150485, longitude: 127.50043103333333, timestamp: "2024-06-06T11:10:46.000Z" },
        { speed: "8.3", course: "88.9", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.689565", lon: "12730.072181", latD: "N", lonD: "E", latitude: 0.76149275, longitude: 127.50120301666666, timestamp: "2024-06-06T11:11:06.000Z" },
        { speed: "8.3", course: "88.9", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.689565", lon: "12730.072181", latD: "N", lonD: "E", latitude: 0.76149275, longitude: 127.50120301666666, timestamp: "2024-06-06T11:11:06.000Z" },
        { speed: "8.4", course: "90.2", altitude: "6.9", satellites: "09", hdop: "0.6", lat: "0045.690291", lon: "12730.025862", latD: "N", lonD: "E", latitude: 0.76150485, longitude: 127.50043103333333, timestamp: "2024-06-06T11:10:46.000Z" },
        { speed: "8.3", course: "88.9", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.689565", lon: "12730.072181", latD: "N", lonD: "E", latitude: 0.76149275, longitude: 127.50120301666666, timestamp: "2024-06-06T11:11:06.000Z" },
        { speed: "8.3", course: "88.9", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.689565", lon: "12730.072181", latD: "N", lonD: "E", latitude: 0.76149275, longitude: 127.50120301666666, timestamp: "2024-06-06T11:11:06.000Z" },
        { speed: "8.3", course: "88.9", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.689565", lon: "12730.072181", latD: "N", lonD: "E", latitude: 0.76149275, longitude: 127.50120301666666, timestamp: "2024-06-06T11:11:06.000Z" },
        { speed: "8.6", course: "88.0", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.689910", lon: "12730.116783", latD: "N", lonD: "E", latitude: 0.7614985, longitude: 127.50194638333333, timestamp: "2024-06-06T11:11:26.000Z" },
        { speed: "8.6", course: "88.0", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.689910", lon: "12730.116783", latD: "N", lonD: "E", latitude: 0.7614985, longitude: 127.50194638333333, timestamp: "2024-06-06T11:11:26.000Z" },
        { speed: "8.6", course: "91.2", altitude: "5.2", satellites: "10", hdop: "0.6", lat: "0045.690889", lon: "12730.162011", latD: "N", lonD: "E", latitude: 0.7615148166666666, longitude: 127.50270018333333, timestamp: "2024-06-06T11:11:46.000Z" },
        { speed: "8.6", course: "88.0", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.689910", lon: "12730.116783", latD: "N", lonD: "E", latitude: 0.7614985, longitude: 127.50194638333333, timestamp: "2024-06-06T11:11:26.000Z" },
        { speed: "8.6", course: "88.0", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.689910", lon: "12730.116783", latD: "N", lonD: "E", latitude: 0.7614985, longitude: 127.50194638333333, timestamp: "2024-06-06T11:11:26.000Z" },
        { speed: "8.6", course: "91.2", altitude: "5.2", satellites: "10", hdop: "0.6", lat: "0045.690889", lon: "12730.162011", latD: "N", lonD: "E", latitude: 0.7615148166666666, longitude: 127.50270018333333, timestamp: "2024-06-06T11:11:46.000Z" },
        { speed: "8.6", course: "91.2", altitude: "5.2", satellites: "10", hdop: "0.6", lat: "0045.690889", lon: "12730.162011", latD: "N", lonD: "E", latitude: 0.7615148166666666, longitude: 127.50270018333333, timestamp: "2024-06-06T11:11:46.000Z" },
        { speed: "8.3", course: "89.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.690166", lon: "12730.206869", latD: "N", lonD: "E", latitude: 0.7615027666666666, longitude: 127.50344781666666, timestamp: "2024-06-06T11:12:06.000Z" },
        { speed: "8.3", course: "89.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.690166", lon: "12730.206869", latD: "N", lonD: "E", latitude: 0.7615027666666666, longitude: 127.50344781666666, timestamp: "2024-06-06T11:12:06.000Z" },
        { speed: "8.3", course: "89.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.690166", lon: "12730.206869", latD: "N", lonD: "E", latitude: 0.7615027666666666, longitude: 127.50344781666666, timestamp: "2024-06-06T11:12:06.000Z" },
        { speed: "8.3", course: "89.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.690166", lon: "12730.206869", latD: "N", lonD: "E", latitude: 0.7615027666666666, longitude: 127.50344781666666, timestamp: "2024-06-06T11:12:06.000Z" },
        { speed: "8.3", course: "89.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.690166", lon: "12730.206869", latD: "N", lonD: "E", latitude: 0.7615027666666666, longitude: 127.50344781666666, timestamp: "2024-06-06T11:12:06.000Z" },
        { speed: "8.7", course: "88.3", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.691213", lon: "12730.253242", latD: "N", lonD: "E", latitude: 0.7615202166666666, longitude: 127.5042207, timestamp: "2024-06-06T11:12:26.000Z" },
        { speed: "8.7", course: "88.3", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.691213", lon: "12730.253242", latD: "N", lonD: "E", latitude: 0.7615202166666666, longitude: 127.5042207, timestamp: "2024-06-06T11:12:26.000Z" },
        { speed: "8.7", course: "88.3", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.691213", lon: "12730.253242", latD: "N", lonD: "E", latitude: 0.7615202166666666, longitude: 127.5042207, timestamp: "2024-06-06T11:12:26.000Z" },
        { speed: "8.7", course: "88.3", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.691213", lon: "12730.253242", latD: "N", lonD: "E", latitude: 0.7615202166666666, longitude: 127.5042207, timestamp: "2024-06-06T11:12:26.000Z" },
        { speed: "9.0", course: "87.0", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.693698", lon: "12730.297691", latD: "N", lonD: "E", latitude: 0.7615616333333333, longitude: 127.50496151666667, timestamp: "2024-06-06T11:12:46.000Z" },
        { speed: "9.0", course: "87.0", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.693698", lon: "12730.297691", latD: "N", lonD: "E", latitude: 0.7615616333333333, longitude: 127.50496151666667, timestamp: "2024-06-06T11:12:46.000Z" },
        { speed: "8.1", course: "89.8", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.693844", lon: "12730.346099", latD: "N", lonD: "E", latitude: 0.7615640666666667, longitude: 127.50576831666666, timestamp: "2024-06-06T11:13:06.000Z" },
        { speed: "9.0", course: "87.0", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.693698", lon: "12730.297691", latD: "N", lonD: "E", latitude: 0.7615616333333333, longitude: 127.50496151666667, timestamp: "2024-06-06T11:12:46.000Z" },
        { speed: "9.0", course: "87.0", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.693698", lon: "12730.297691", latD: "N", lonD: "E", latitude: 0.7615616333333333, longitude: 127.50496151666667, timestamp: "2024-06-06T11:12:46.000Z" },
        { speed: "8.1", course: "89.8", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.693844", lon: "12730.346099", latD: "N", lonD: "E", latitude: 0.7615640666666667, longitude: 127.50576831666666, timestamp: "2024-06-06T11:13:06.000Z" },
        { speed: "8.1", course: "89.8", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.693844", lon: "12730.346099", latD: "N", lonD: "E", latitude: 0.7615640666666667, longitude: 127.50576831666666, timestamp: "2024-06-06T11:13:06.000Z" },
        { speed: "8.6", course: "90.6", altitude: "21.3", satellites: "09", hdop: "0.6", lat: "0045.693869", lon: "12730.390724", latD: "N", lonD: "E", latitude: 0.7615644833333334, longitude: 127.50651206666667, timestamp: "2024-06-06T11:13:26.000Z" },
        { speed: "8.1", course: "89.8", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.693844", lon: "12730.346099", latD: "N", lonD: "E", latitude: 0.7615640666666667, longitude: 127.50576831666666, timestamp: "2024-06-06T11:13:06.000Z" },
        { speed: "8.6", course: "90.6", altitude: "21.3", satellites: "09", hdop: "0.6", lat: "0045.693869", lon: "12730.390724", latD: "N", lonD: "E", latitude: 0.7615644833333334, longitude: 127.50651206666667, timestamp: "2024-06-06T11:13:26.000Z" },
        { speed: "8.6", course: "90.6", altitude: "21.3", satellites: "09", hdop: "0.6", lat: "0045.693869", lon: "12730.390724", latD: "N", lonD: "E", latitude: 0.7615644833333334, longitude: 127.50651206666667, timestamp: "2024-06-06T11:13:26.000Z" },
        { speed: "8.6", course: "90.6", altitude: "21.3", satellites: "09", hdop: "0.6", lat: "0045.693869", lon: "12730.390724", latD: "N", lonD: "E", latitude: 0.7615644833333334, longitude: 127.50651206666667, timestamp: "2024-06-06T11:13:26.000Z" },
        { speed: "8.3", course: "90.0", altitude: "16.3", satellites: "10", hdop: "0.6", lat: "0045.693021", lon: "12730.436961", latD: "N", lonD: "E", latitude: 0.76155035, longitude: 127.50728268333333, timestamp: "2024-06-06T11:13:46.000Z" },
        { speed: "8.3", course: "90.0", altitude: "16.3", satellites: "10", hdop: "0.6", lat: "0045.693021", lon: "12730.436961", latD: "N", lonD: "E", latitude: 0.76155035, longitude: 127.50728268333333, timestamp: "2024-06-06T11:13:46.000Z" },
        { speed: "8.3", course: "90.0", altitude: "16.3", satellites: "10", hdop: "0.6", lat: "0045.693021", lon: "12730.436961", latD: "N", lonD: "E", latitude: 0.76155035, longitude: 127.50728268333333, timestamp: "2024-06-06T11:13:46.000Z" },
        { speed: "8.3", course: "90.0", altitude: "16.3", satellites: "10", hdop: "0.6", lat: "0045.693021", lon: "12730.436961", latD: "N", lonD: "E", latitude: 0.76155035, longitude: 127.50728268333333, timestamp: "2024-06-06T11:13:46.000Z" },
        { speed: "8.3", course: "91.9", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.694880", lon: "12730.483420", latD: "N", lonD: "E", latitude: 0.7615813333333333, longitude: 127.508057, timestamp: "2024-06-06T11:14:06.000Z" },
        { speed: "8.3", course: "91.9", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.694880", lon: "12730.483420", latD: "N", lonD: "E", latitude: 0.7615813333333333, longitude: 127.508057, timestamp: "2024-06-06T11:14:06.000Z" },
        { speed: "8.3", course: "91.9", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.694880", lon: "12730.483420", latD: "N", lonD: "E", latitude: 0.7615813333333333, longitude: 127.508057, timestamp: "2024-06-06T11:14:06.000Z" },
        { speed: "8.3", course: "91.9", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.694880", lon: "12730.483420", latD: "N", lonD: "E", latitude: 0.7615813333333333, longitude: 127.508057, timestamp: "2024-06-06T11:14:06.000Z" },
        { speed: "8.0", course: "86.5", altitude: "6.2", satellites: "10", hdop: "0.6", lat: "0045.695630", lon: "12730.528765", latD: "N", lonD: "E", latitude: 0.7615938333333333, longitude: 127.50881275, timestamp: "2024-06-06T11:14:26.000Z" },
        { speed: "8.6", course: "88.4", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.695025", lon: "12730.575071", latD: "N", lonD: "E", latitude: 0.76158375, longitude: 127.50958451666666, timestamp: "2024-06-06T11:14:46.000Z" },
        { speed: "8.0", course: "86.5", altitude: "6.2", satellites: "10", hdop: "0.6", lat: "0045.695630", lon: "12730.528765", latD: "N", lonD: "E", latitude: 0.7615938333333333, longitude: 127.50881275, timestamp: "2024-06-06T11:14:26.000Z" },
        { speed: "8.0", course: "86.5", altitude: "6.2", satellites: "10", hdop: "0.6", lat: "0045.695630", lon: "12730.528765", latD: "N", lonD: "E", latitude: 0.7615938333333333, longitude: 127.50881275, timestamp: "2024-06-06T11:14:26.000Z" },
        { speed: "8.0", course: "86.5", altitude: "6.2", satellites: "10", hdop: "0.6", lat: "0045.695630", lon: "12730.528765", latD: "N", lonD: "E", latitude: 0.7615938333333333, longitude: 127.50881275, timestamp: "2024-06-06T11:14:26.000Z" },
        { speed: "8.6", course: "88.4", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.695025", lon: "12730.575071", latD: "N", lonD: "E", latitude: 0.76158375, longitude: 127.50958451666666, timestamp: "2024-06-06T11:14:46.000Z" },
        { speed: "8.6", course: "88.4", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.695025", lon: "12730.575071", latD: "N", lonD: "E", latitude: 0.76158375, longitude: 127.50958451666666, timestamp: "2024-06-06T11:14:46.000Z" },
        { speed: "8.5", course: "87.8", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.695948", lon: "12730.622743", latD: "N", lonD: "E", latitude: 0.7615991333333334, longitude: 127.51037905, timestamp: "2024-06-06T11:15:06.000Z" },
        { speed: "8.5", course: "87.8", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.695948", lon: "12730.622743", latD: "N", lonD: "E", latitude: 0.7615991333333334, longitude: 127.51037905, timestamp: "2024-06-06T11:15:06.000Z" },
        { speed: "8.5", course: "87.8", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.695948", lon: "12730.622743", latD: "N", lonD: "E", latitude: 0.7615991333333334, longitude: 127.51037905, timestamp: "2024-06-06T11:15:06.000Z" },
        { speed: "8.5", course: "87.8", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.695948", lon: "12730.622743", latD: "N", lonD: "E", latitude: 0.7615991333333334, longitude: 127.51037905, timestamp: "2024-06-06T11:15:06.000Z" },
        { speed: "8.5", course: "87.8", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.695948", lon: "12730.622743", latD: "N", lonD: "E", latitude: 0.7615991333333334, longitude: 127.51037905, timestamp: "2024-06-06T11:15:06.000Z" },
        { speed: "8.2", course: "88.4", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.696978", lon: "12730.669456", latD: "N", lonD: "E", latitude: 0.7616163, longitude: 127.5111576, timestamp: "2024-06-06T11:15:26.000Z" },
        { speed: "8.2", course: "88.4", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.696978", lon: "12730.669456", latD: "N", lonD: "E", latitude: 0.7616163, longitude: 127.5111576, timestamp: "2024-06-06T11:15:26.000Z" },
        { speed: "8.2", course: "88.4", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.696978", lon: "12730.669456", latD: "N", lonD: "E", latitude: 0.7616163, longitude: 127.5111576, timestamp: "2024-06-06T11:15:26.000Z" },
        { speed: "8.2", course: "88.4", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.696978", lon: "12730.669456", latD: "N", lonD: "E", latitude: 0.7616163, longitude: 127.5111576, timestamp: "2024-06-06T11:15:26.000Z" },
        { speed: "8.8", course: "86.4", altitude: "11.1", satellites: "10", hdop: "0.6", lat: "0045.700193", lon: "12730.715706", latD: "N", lonD: "E", latitude: 0.7616698833333333, longitude: 127.51192843333334, timestamp: "2024-06-06T11:15:46.000Z" },
        { speed: "8.4", course: "88.4", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.702583", lon: "12730.762447", latD: "N", lonD: "E", latitude: 0.7617097166666666, longitude: 127.51270745, timestamp: "2024-06-06T11:16:06.000Z" },
        { speed: "8.4", course: "88.4", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.702583", lon: "12730.762447", latD: "N", lonD: "E", latitude: 0.7617097166666666, longitude: 127.51270745, timestamp: "2024-06-06T11:16:06.000Z" },
        { speed: "8.8", course: "86.4", altitude: "11.1", satellites: "10", hdop: "0.6", lat: "0045.700193", lon: "12730.715706", latD: "N", lonD: "E", latitude: 0.7616698833333333, longitude: 127.51192843333334, timestamp: "2024-06-06T11:15:46.000Z" },
        { speed: "8.4", course: "88.4", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.702583", lon: "12730.762447", latD: "N", lonD: "E", latitude: 0.7617097166666666, longitude: 127.51270745, timestamp: "2024-06-06T11:16:06.000Z" },
        { speed: "8.4", course: "88.4", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.702583", lon: "12730.762447", latD: "N", lonD: "E", latitude: 0.7617097166666666, longitude: 127.51270745, timestamp: "2024-06-06T11:16:06.000Z" },
        { speed: "8.4", course: "88.4", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.702583", lon: "12730.762447", latD: "N", lonD: "E", latitude: 0.7617097166666666, longitude: 127.51270745, timestamp: "2024-06-06T11:16:06.000Z" },
        { speed: "8.3", course: "90.7", altitude: "13.9", satellites: "09", hdop: "0.6", lat: "0045.703493", lon: "12730.807286", latD: "N", lonD: "E", latitude: 0.7617248833333333, longitude: 127.51345476666667, timestamp: "2024-06-06T11:16:26.000Z" },
        { speed: "8.3", course: "90.7", altitude: "13.9", satellites: "09", hdop: "0.6", lat: "0045.703493", lon: "12730.807286", latD: "N", lonD: "E", latitude: 0.7617248833333333, longitude: 127.51345476666667, timestamp: "2024-06-06T11:16:26.000Z" },
        { speed: "8.3", course: "90.7", altitude: "13.9", satellites: "09", hdop: "0.6", lat: "0045.703493", lon: "12730.807286", latD: "N", lonD: "E", latitude: 0.7617248833333333, longitude: 127.51345476666667, timestamp: "2024-06-06T11:16:26.000Z" },
        { speed: "8.3", course: "90.7", altitude: "13.9", satellites: "09", hdop: "0.6", lat: "0045.703493", lon: "12730.807286", latD: "N", lonD: "E", latitude: 0.7617248833333333, longitude: 127.51345476666667, timestamp: "2024-06-06T11:16:26.000Z" },
        { speed: "8.3", course: "90.7", altitude: "13.9", satellites: "09", hdop: "0.6", lat: "0045.703493", lon: "12730.807286", latD: "N", lonD: "E", latitude: 0.7617248833333333, longitude: 127.51345476666667, timestamp: "2024-06-06T11:16:26.000Z" },
        { speed: "8.7", course: "88.7", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.704760", lon: "12730.852309", latD: "N", lonD: "E", latitude: 0.761746, longitude: 127.51420515, timestamp: "2024-06-06T11:16:46.000Z" },
        { speed: "8.7", course: "88.7", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.704760", lon: "12730.852309", latD: "N", lonD: "E", latitude: 0.761746, longitude: 127.51420515, timestamp: "2024-06-06T11:16:46.000Z" },
        { speed: "8.2", course: "89.4", altitude: "5.8", satellites: "10", hdop: "0.6", lat: "0045.703382", lon: "12730.901589", latD: "N", lonD: "E", latitude: 0.7617230333333332, longitude: 127.51502648333333, timestamp: "2024-06-06T11:17:06.000Z" },
        { speed: "8.2", course: "89.4", altitude: "5.8", satellites: "10", hdop: "0.6", lat: "0045.703382", lon: "12730.901589", latD: "N", lonD: "E", latitude: 0.7617230333333332, longitude: 127.51502648333333, timestamp: "2024-06-06T11:17:06.000Z" },
        { speed: "8.2", course: "89.4", altitude: "5.8", satellites: "10", hdop: "0.6", lat: "0045.703382", lon: "12730.901589", latD: "N", lonD: "E", latitude: 0.7617230333333332, longitude: 127.51502648333333, timestamp: "2024-06-06T11:17:06.000Z" },
        { speed: "8.2", course: "89.4", altitude: "5.8", satellites: "10", hdop: "0.6", lat: "0045.703382", lon: "12730.901589", latD: "N", lonD: "E", latitude: 0.7617230333333332, longitude: 127.51502648333333, timestamp: "2024-06-06T11:17:06.000Z" },
        { speed: "8.2", course: "89.4", altitude: "5.8", satellites: "10", hdop: "0.6", lat: "0045.703382", lon: "12730.901589", latD: "N", lonD: "E", latitude: 0.7617230333333332, longitude: 127.51502648333333, timestamp: "2024-06-06T11:17:06.000Z" },
        { speed: "8.2", course: "89.4", altitude: "5.8", satellites: "10", hdop: "0.6", lat: "0045.703382", lon: "12730.901589", latD: "N", lonD: "E", latitude: 0.7617230333333332, longitude: 127.51502648333333, timestamp: "2024-06-06T11:17:06.000Z" },
        { speed: "8.4", course: "84.1", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.703839", lon: "12730.950562", latD: "N", lonD: "E", latitude: 0.7617306500000001, longitude: 127.5158427, timestamp: "2024-06-06T11:17:26.000Z" },
        { speed: "8.4", course: "84.1", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.703839", lon: "12730.950562", latD: "N", lonD: "E", latitude: 0.7617306500000001, longitude: 127.5158427, timestamp: "2024-06-06T11:17:26.000Z" },
        { speed: "8.5", course: "87.1", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.704169", lon: "12730.995547", latD: "N", lonD: "E", latitude: 0.7617361500000001, longitude: 127.51659245, timestamp: "2024-06-06T11:17:46.000Z" },
        { speed: "8.4", course: "84.1", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.703839", lon: "12730.950562", latD: "N", lonD: "E", latitude: 0.7617306500000001, longitude: 127.5158427, timestamp: "2024-06-06T11:17:26.000Z" },
        { speed: "8.4", course: "84.1", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.703839", lon: "12730.950562", latD: "N", lonD: "E", latitude: 0.7617306500000001, longitude: 127.5158427, timestamp: "2024-06-06T11:17:26.000Z" },
        { speed: "8.5", course: "87.1", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.704169", lon: "12730.995547", latD: "N", lonD: "E", latitude: 0.7617361500000001, longitude: 127.51659245, timestamp: "2024-06-06T11:17:46.000Z" },
        { speed: "8.5", course: "87.1", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.704169", lon: "12730.995547", latD: "N", lonD: "E", latitude: 0.7617361500000001, longitude: 127.51659245, timestamp: "2024-06-06T11:17:46.000Z" },
        { speed: "8.7", course: "91.6", altitude: "15.3", satellites: "09", hdop: "0.6", lat: "0045.701750", lon: "12731.041577", latD: "N", lonD: "E", latitude: 0.7616958333333332, longitude: 127.51735961666667, timestamp: "2024-06-06T11:18:06.000Z" },
        { speed: "8.7", course: "91.6", altitude: "15.3", satellites: "09", hdop: "0.6", lat: "0045.701750", lon: "12731.041577", latD: "N", lonD: "E", latitude: 0.7616958333333332, longitude: 127.51735961666667, timestamp: "2024-06-06T11:18:06.000Z" },
        { speed: "8.7", course: "91.6", altitude: "15.3", satellites: "09", hdop: "0.6", lat: "0045.701750", lon: "12731.041577", latD: "N", lonD: "E", latitude: 0.7616958333333332, longitude: 127.51735961666667, timestamp: "2024-06-06T11:18:06.000Z" },
        { speed: "8.7", course: "91.6", altitude: "15.3", satellites: "09", hdop: "0.6", lat: "0045.701750", lon: "12731.041577", latD: "N", lonD: "E", latitude: 0.7616958333333332, longitude: 127.51735961666667, timestamp: "2024-06-06T11:18:06.000Z" },
        { speed: "8.5", course: "91.0", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.699594", lon: "12731.089554", latD: "N", lonD: "E", latitude: 0.7616599, longitude: 127.51815923333334, timestamp: "2024-06-06T11:18:26.000Z" },
        { speed: "8.5", course: "91.0", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.699594", lon: "12731.089554", latD: "N", lonD: "E", latitude: 0.7616599, longitude: 127.51815923333334, timestamp: "2024-06-06T11:18:26.000Z" },
        { speed: "8.5", course: "91.0", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.699594", lon: "12731.089554", latD: "N", lonD: "E", latitude: 0.7616599, longitude: 127.51815923333334, timestamp: "2024-06-06T11:18:26.000Z" },
        { speed: "8.5", course: "91.0", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.699594", lon: "12731.089554", latD: "N", lonD: "E", latitude: 0.7616599, longitude: 127.51815923333334, timestamp: "2024-06-06T11:18:26.000Z" },
        { speed: "8.5", course: "91.0", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.699594", lon: "12731.089554", latD: "N", lonD: "E", latitude: 0.7616599, longitude: 127.51815923333334, timestamp: "2024-06-06T11:18:26.000Z" },
        { speed: "9.3", course: "91.2", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.696829", lon: "12731.136301", latD: "N", lonD: "E", latitude: 0.7616138166666667, longitude: 127.51893835, timestamp: "2024-06-06T11:18:47.000Z" },
        { speed: "8.8", course: "92.6", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.695193", lon: "12731.184217", latD: "N", lonD: "E", latitude: 0.7615865500000001, longitude: 127.51973695, timestamp: "2024-06-06T11:19:06.000Z" },
        { speed: "9.3", course: "91.2", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.696829", lon: "12731.136301", latD: "N", lonD: "E", latitude: 0.7616138166666667, longitude: 127.51893835, timestamp: "2024-06-06T11:18:47.000Z" },
        { speed: "9.3", course: "91.2", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.696829", lon: "12731.136301", latD: "N", lonD: "E", latitude: 0.7616138166666667, longitude: 127.51893835, timestamp: "2024-06-06T11:18:47.000Z" },
        { speed: "9.3", course: "91.2", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.696829", lon: "12731.136301", latD: "N", lonD: "E", latitude: 0.7616138166666667, longitude: 127.51893835, timestamp: "2024-06-06T11:18:47.000Z" },
        { speed: "8.8", course: "92.6", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.695193", lon: "12731.184217", latD: "N", lonD: "E", latitude: 0.7615865500000001, longitude: 127.51973695, timestamp: "2024-06-06T11:19:06.000Z" },
        { speed: "8.8", course: "92.6", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.695193", lon: "12731.184217", latD: "N", lonD: "E", latitude: 0.7615865500000001, longitude: 127.51973695, timestamp: "2024-06-06T11:19:06.000Z" },
        { speed: "9.1", course: "95.7", altitude: "4.6", satellites: "10", hdop: "0.6", lat: "0045.691738", lon: "12731.230388", latD: "N", lonD: "E", latitude: 0.7615289666666667, longitude: 127.52050646666666, timestamp: "2024-06-06T11:19:26.000Z" },
        { speed: "8.8", course: "92.6", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.695193", lon: "12731.184217", latD: "N", lonD: "E", latitude: 0.7615865500000001, longitude: 127.51973695, timestamp: "2024-06-06T11:19:06.000Z" },
        { speed: "9.1", course: "95.7", altitude: "4.6", satellites: "10", hdop: "0.6", lat: "0045.691738", lon: "12731.230388", latD: "N", lonD: "E", latitude: 0.7615289666666667, longitude: 127.52050646666666, timestamp: "2024-06-06T11:19:26.000Z" },
        { speed: "9.1", course: "95.7", altitude: "4.6", satellites: "10", hdop: "0.6", lat: "0045.691738", lon: "12731.230388", latD: "N", lonD: "E", latitude: 0.7615289666666667, longitude: 127.52050646666666, timestamp: "2024-06-06T11:19:26.000Z" },
        { speed: "8.8", course: "95.0", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.685440", lon: "12731.284300", latD: "N", lonD: "E", latitude: 0.761424, longitude: 127.521405, timestamp: "2024-06-06T11:19:47.000Z" },
        { speed: "8.8", course: "95.0", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.685440", lon: "12731.284300", latD: "N", lonD: "E", latitude: 0.761424, longitude: 127.521405, timestamp: "2024-06-06T11:19:47.000Z" },
        { speed: "8.8", course: "95.0", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.685440", lon: "12731.284300", latD: "N", lonD: "E", latitude: 0.761424, longitude: 127.521405, timestamp: "2024-06-06T11:19:47.000Z" },
        { speed: "8.8", course: "95.0", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.685440", lon: "12731.284300", latD: "N", lonD: "E", latitude: 0.761424, longitude: 127.521405, timestamp: "2024-06-06T11:19:47.000Z" },
        { speed: "8.8", course: "95.0", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.685440", lon: "12731.284300", latD: "N", lonD: "E", latitude: 0.761424, longitude: 127.521405, timestamp: "2024-06-06T11:19:47.000Z" },
        { speed: "8.6", course: "92.8", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.682792", lon: "12731.329821", latD: "N", lonD: "E", latitude: 0.7613798666666667, longitude: 127.52216368333333, timestamp: "2024-06-06T11:20:06.000Z" },
        { speed: "8.6", course: "92.8", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.682792", lon: "12731.329821", latD: "N", lonD: "E", latitude: 0.7613798666666667, longitude: 127.52216368333333, timestamp: "2024-06-06T11:20:06.000Z" },
        { speed: "8.1", course: "92.0", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.679052", lon: "12731.377063", latD: "N", lonD: "E", latitude: 0.7613175333333333, longitude: 127.52295105, timestamp: "2024-06-06T11:20:26.000Z" },
        { speed: "8.1", course: "92.0", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.679052", lon: "12731.377063", latD: "N", lonD: "E", latitude: 0.7613175333333333, longitude: 127.52295105, timestamp: "2024-06-06T11:20:26.000Z" },
        { speed: "8.6", course: "92.8", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.682792", lon: "12731.329821", latD: "N", lonD: "E", latitude: 0.7613798666666667, longitude: 127.52216368333333, timestamp: "2024-06-06T11:20:06.000Z" },
        { speed: "8.1", course: "92.0", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.679052", lon: "12731.377063", latD: "N", lonD: "E", latitude: 0.7613175333333333, longitude: 127.52295105, timestamp: "2024-06-06T11:20:26.000Z" },
        { speed: "8.1", course: "92.0", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.679052", lon: "12731.377063", latD: "N", lonD: "E", latitude: 0.7613175333333333, longitude: 127.52295105, timestamp: "2024-06-06T11:20:26.000Z" },
        { speed: "8.5", course: "88.5", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.678082", lon: "12731.427287", latD: "N", lonD: "E", latitude: 0.7613013666666667, longitude: 127.52378811666667, timestamp: "2024-06-06T11:20:47.000Z" },
        { speed: "8.5", course: "88.5", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.678082", lon: "12731.427287", latD: "N", lonD: "E", latitude: 0.7613013666666667, longitude: 127.52378811666667, timestamp: "2024-06-06T11:20:47.000Z" },
        { speed: "8.5", course: "88.5", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.678082", lon: "12731.427287", latD: "N", lonD: "E", latitude: 0.7613013666666667, longitude: 127.52378811666667, timestamp: "2024-06-06T11:20:47.000Z" },
        { speed: "9.2", course: "88.1", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.679637", lon: "12731.476062", latD: "N", lonD: "E", latitude: 0.7613272833333333, longitude: 127.52460103333334, timestamp: "2024-06-06T11:21:07.000Z" },
        { speed: "9.2", course: "88.1", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.679637", lon: "12731.476062", latD: "N", lonD: "E", latitude: 0.7613272833333333, longitude: 127.52460103333334, timestamp: "2024-06-06T11:21:07.000Z" },
        { speed: "8.5", course: "88.5", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.678082", lon: "12731.427287", latD: "N", lonD: "E", latitude: 0.7613013666666667, longitude: 127.52378811666667, timestamp: "2024-06-06T11:20:47.000Z" },
        { speed: "9.2", course: "88.1", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.679637", lon: "12731.476062", latD: "N", lonD: "E", latitude: 0.7613272833333333, longitude: 127.52460103333334, timestamp: "2024-06-06T11:21:07.000Z" },
        { speed: "9.2", course: "88.1", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.679637", lon: "12731.476062", latD: "N", lonD: "E", latitude: 0.7613272833333333, longitude: 127.52460103333334, timestamp: "2024-06-06T11:21:07.000Z" },
        { speed: "8.8", course: "89.5", altitude: "14.6", satellites: "09", hdop: "0.6", lat: "0045.680480", lon: "12731.523550", latD: "N", lonD: "E", latitude: 0.7613413333333334, longitude: 127.5253925, timestamp: "2024-06-06T11:21:27.000Z" },
        { speed: "8.8", course: "89.5", altitude: "14.6", satellites: "09", hdop: "0.6", lat: "0045.680480", lon: "12731.523550", latD: "N", lonD: "E", latitude: 0.7613413333333334, longitude: 127.5253925, timestamp: "2024-06-06T11:21:27.000Z" },
        { speed: "8.8", course: "89.5", altitude: "14.6", satellites: "09", hdop: "0.6", lat: "0045.680480", lon: "12731.523550", latD: "N", lonD: "E", latitude: 0.7613413333333334, longitude: 127.5253925, timestamp: "2024-06-06T11:21:27.000Z" },
        { speed: "8.8", course: "89.5", altitude: "14.6", satellites: "09", hdop: "0.6", lat: "0045.680480", lon: "12731.523550", latD: "N", lonD: "E", latitude: 0.7613413333333334, longitude: 127.5253925, timestamp: "2024-06-06T11:21:27.000Z" },
        { speed: "8.8", course: "89.5", altitude: "14.6", satellites: "09", hdop: "0.6", lat: "0045.680480", lon: "12731.523550", latD: "N", lonD: "E", latitude: 0.7613413333333334, longitude: 127.5253925, timestamp: "2024-06-06T11:21:27.000Z" },
        { speed: "8.7", course: "90.1", altitude: "7.2", satellites: "09", hdop: "0.6", lat: "0045.679238", lon: "12731.571093", latD: "N", lonD: "E", latitude: 0.7613206333333333, longitude: 127.52618488333333, timestamp: "2024-06-06T11:21:47.000Z" },
        { speed: "8.7", course: "90.1", altitude: "7.2", satellites: "09", hdop: "0.6", lat: "0045.679238", lon: "12731.571093", latD: "N", lonD: "E", latitude: 0.7613206333333333, longitude: 127.52618488333333, timestamp: "2024-06-06T11:21:47.000Z" },
        { speed: "8.7", course: "90.1", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.680287", lon: "12731.619757", latD: "N", lonD: "E", latitude: 0.7613381166666666, longitude: 127.52699595, timestamp: "2024-06-06T11:22:07.000Z" },
        { speed: "8.7", course: "90.1", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.680287", lon: "12731.619757", latD: "N", lonD: "E", latitude: 0.7613381166666666, longitude: 127.52699595, timestamp: "2024-06-06T11:22:07.000Z" },
        { speed: "8.7", course: "90.1", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.680287", lon: "12731.619757", latD: "N", lonD: "E", latitude: 0.7613381166666666, longitude: 127.52699595, timestamp: "2024-06-06T11:22:07.000Z" },
        { speed: "8.7", course: "90.7", altitude: "11.6", satellites: "10", hdop: "0.6", lat: "0045.680044", lon: "12731.667531", latD: "N", lonD: "E", latitude: 0.7613340666666667, longitude: 127.52779218333333, timestamp: "2024-06-06T11:22:27.000Z" },
        { speed: "8.7", course: "90.1", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.680287", lon: "12731.619757", latD: "N", lonD: "E", latitude: 0.7613381166666666, longitude: 127.52699595, timestamp: "2024-06-06T11:22:07.000Z" },
        { speed: "8.7", course: "90.1", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.680287", lon: "12731.619757", latD: "N", lonD: "E", latitude: 0.7613381166666666, longitude: 127.52699595, timestamp: "2024-06-06T11:22:07.000Z" },
        { speed: "8.7", course: "90.1", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.680287", lon: "12731.619757", latD: "N", lonD: "E", latitude: 0.7613381166666666, longitude: 127.52699595, timestamp: "2024-06-06T11:22:07.000Z" },
        { speed: "8.7", course: "90.7", altitude: "11.6", satellites: "10", hdop: "0.6", lat: "0045.680044", lon: "12731.667531", latD: "N", lonD: "E", latitude: 0.7613340666666667, longitude: 127.52779218333333, timestamp: "2024-06-06T11:22:27.000Z" },
        { speed: "8.7", course: "89.8", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.678507", lon: "12731.715827", latD: "N", lonD: "E", latitude: 0.76130845, longitude: 127.52859711666666, timestamp: "2024-06-06T11:22:47.000Z" },
        { speed: "8.7", course: "89.8", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.678507", lon: "12731.715827", latD: "N", lonD: "E", latitude: 0.76130845, longitude: 127.52859711666666, timestamp: "2024-06-06T11:22:47.000Z" },
        { speed: "8.7", course: "90.7", altitude: "11.6", satellites: "10", hdop: "0.6", lat: "0045.680044", lon: "12731.667531", latD: "N", lonD: "E", latitude: 0.7613340666666667, longitude: 127.52779218333333, timestamp: "2024-06-06T11:22:27.000Z" },
        { speed: "8.7", course: "89.8", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.678507", lon: "12731.715827", latD: "N", lonD: "E", latitude: 0.76130845, longitude: 127.52859711666666, timestamp: "2024-06-06T11:22:47.000Z" },
        { speed: "8.7", course: "89.8", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.678507", lon: "12731.715827", latD: "N", lonD: "E", latitude: 0.76130845, longitude: 127.52859711666666, timestamp: "2024-06-06T11:22:47.000Z" },
        { speed: "8.7", course: "89.8", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.678507", lon: "12731.715827", latD: "N", lonD: "E", latitude: 0.76130845, longitude: 127.52859711666666, timestamp: "2024-06-06T11:22:47.000Z" },
        { speed: "8.6", course: "87.4", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.678503", lon: "12731.764810", latD: "N", lonD: "E", latitude: 0.7613083833333333, longitude: 127.5294135, timestamp: "2024-06-06T11:23:07.000Z" },
        { speed: "8.6", course: "87.4", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.678503", lon: "12731.764810", latD: "N", lonD: "E", latitude: 0.7613083833333333, longitude: 127.5294135, timestamp: "2024-06-06T11:23:07.000Z" },
        { speed: "9.1", course: "85.5", altitude: "5.4", satellites: "10", hdop: "0.6", lat: "0045.678923", lon: "12731.813340", latD: "N", lonD: "E", latitude: 0.7613153833333333, longitude: 127.53022233333333, timestamp: "2024-06-06T11:23:27.000Z" },
        { speed: "8.6", course: "87.4", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.678503", lon: "12731.764810", latD: "N", lonD: "E", latitude: 0.7613083833333333, longitude: 127.5294135, timestamp: "2024-06-06T11:23:07.000Z" },
        { speed: "8.6", course: "87.4", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.678503", lon: "12731.764810", latD: "N", lonD: "E", latitude: 0.7613083833333333, longitude: 127.5294135, timestamp: "2024-06-06T11:23:07.000Z" },
        { speed: "9.1", course: "85.5", altitude: "5.4", satellites: "10", hdop: "0.6", lat: "0045.678923", lon: "12731.813340", latD: "N", lonD: "E", latitude: 0.7613153833333333, longitude: 127.53022233333333, timestamp: "2024-06-06T11:23:27.000Z" },
        { speed: "11.2", course: "84.8", altitude: "14.8", satellites: "10", hdop: "0.6", lat: "0045.685138", lon: "12731.863670", latD: "N", lonD: "E", latitude: 0.7614189666666668, longitude: 127.53106116666666, timestamp: "2024-06-06T11:23:47.000Z" },
        { speed: "11.2", course: "84.8", altitude: "14.8", satellites: "10", hdop: "0.6", lat: "0045.685138", lon: "12731.863670", latD: "N", lonD: "E", latitude: 0.7614189666666668, longitude: 127.53106116666666, timestamp: "2024-06-06T11:23:47.000Z" },
        { speed: "11.2", course: "84.8", altitude: "14.8", satellites: "10", hdop: "0.6", lat: "0045.685138", lon: "12731.863670", latD: "N", lonD: "E", latitude: 0.7614189666666668, longitude: 127.53106116666666, timestamp: "2024-06-06T11:23:47.000Z" },
        { speed: "11.2", course: "84.8", altitude: "14.8", satellites: "10", hdop: "0.6", lat: "0045.685138", lon: "12731.863670", latD: "N", lonD: "E", latitude: 0.7614189666666668, longitude: 127.53106116666666, timestamp: "2024-06-06T11:23:47.000Z" },
        { speed: "11.2", course: "84.8", altitude: "14.8", satellites: "10", hdop: "0.6", lat: "0045.685138", lon: "12731.863670", latD: "N", lonD: "E", latitude: 0.7614189666666668, longitude: 127.53106116666666, timestamp: "2024-06-06T11:23:47.000Z" },
        { speed: "9.2", course: "83.1", altitude: "16.2", satellites: "10", hdop: "0.6", lat: "0045.691547", lon: "12731.909739", latD: "N", lonD: "E", latitude: 0.7615257833333333, longitude: 127.53182898333333, timestamp: "2024-06-06T11:24:07.000Z" },
        { speed: "11.2", course: "84.8", altitude: "14.8", satellites: "10", hdop: "0.6", lat: "0045.685138", lon: "12731.863670", latD: "N", lonD: "E", latitude: 0.7614189666666668, longitude: 127.53106116666666, timestamp: "2024-06-06T11:23:47.000Z" },
        { speed: "9.2", course: "83.1", altitude: "16.2", satellites: "10", hdop: "0.6", lat: "0045.691547", lon: "12731.909739", latD: "N", lonD: "E", latitude: 0.7615257833333333, longitude: 127.53182898333333, timestamp: "2024-06-06T11:24:07.000Z" },
        { speed: "9.2", course: "83.1", altitude: "16.2", satellites: "10", hdop: "0.6", lat: "0045.691547", lon: "12731.909739", latD: "N", lonD: "E", latitude: 0.7615257833333333, longitude: 127.53182898333333, timestamp: "2024-06-06T11:24:07.000Z" },
        { speed: "9.2", course: "83.1", altitude: "16.2", satellites: "10", hdop: "0.6", lat: "0045.691547", lon: "12731.909739", latD: "N", lonD: "E", latitude: 0.7615257833333333, longitude: 127.53182898333333, timestamp: "2024-06-06T11:24:07.000Z" },
        { speed: "9.0", course: "86.4", altitude: "13.4", satellites: "09", hdop: "0.6", lat: "0045.695554", lon: "12731.961332", latD: "N", lonD: "E", latitude: 0.7615925666666666, longitude: 127.53268886666666, timestamp: "2024-06-06T11:24:27.000Z" },
        { speed: "9.0", course: "86.4", altitude: "13.4", satellites: "09", hdop: "0.6", lat: "0045.695554", lon: "12731.961332", latD: "N", lonD: "E", latitude: 0.7615925666666666, longitude: 127.53268886666666, timestamp: "2024-06-06T11:24:27.000Z" },
        { speed: "9.1", course: "85.9", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.699458", lon: "12732.011707", latD: "N", lonD: "E", latitude: 0.7616576333333334, longitude: 127.53352845, timestamp: "2024-06-06T11:24:47.000Z" },
        { speed: "9.1", course: "85.9", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.699458", lon: "12732.011707", latD: "N", lonD: "E", latitude: 0.7616576333333334, longitude: 127.53352845, timestamp: "2024-06-06T11:24:47.000Z" },
        { speed: "9.0", course: "86.4", altitude: "13.4", satellites: "09", hdop: "0.6", lat: "0045.695554", lon: "12731.961332", latD: "N", lonD: "E", latitude: 0.7615925666666666, longitude: 127.53268886666666, timestamp: "2024-06-06T11:24:27.000Z" },
        { speed: "9.1", course: "85.9", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.699458", lon: "12732.011707", latD: "N", lonD: "E", latitude: 0.7616576333333334, longitude: 127.53352845, timestamp: "2024-06-06T11:24:47.000Z" },
        { speed: "9.0", course: "87.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.700807", lon: "12732.062121", latD: "N", lonD: "E", latitude: 0.7616801166666666, longitude: 127.53436868333333, timestamp: "2024-06-06T11:25:07.000Z" },
        { speed: "9.0", course: "87.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.700807", lon: "12732.062121", latD: "N", lonD: "E", latitude: 0.7616801166666666, longitude: 127.53436868333333, timestamp: "2024-06-06T11:25:07.000Z" },
        { speed: "9.0", course: "87.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.700807", lon: "12732.062121", latD: "N", lonD: "E", latitude: 0.7616801166666666, longitude: 127.53436868333333, timestamp: "2024-06-06T11:25:07.000Z" },
        { speed: "8.4", course: "89.8", altitude: "9.5", satellites: "10", hdop: "0.6", lat: "0045.701382", lon: "12732.110726", latD: "N", lonD: "E", latitude: 0.7616897, longitude: 127.53517876666666, timestamp: "2024-06-06T11:25:27.000Z" },
        { speed: "9.0", course: "87.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.700807", lon: "12732.062121", latD: "N", lonD: "E", latitude: 0.7616801166666666, longitude: 127.53436868333333, timestamp: "2024-06-06T11:25:07.000Z" },
        { speed: "9.0", course: "87.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.700807", lon: "12732.062121", latD: "N", lonD: "E", latitude: 0.7616801166666666, longitude: 127.53436868333333, timestamp: "2024-06-06T11:25:07.000Z" },
        { speed: "9.0", course: "87.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.700807", lon: "12732.062121", latD: "N", lonD: "E", latitude: 0.7616801166666666, longitude: 127.53436868333333, timestamp: "2024-06-06T11:25:07.000Z" },
        { speed: "8.4", course: "89.8", altitude: "9.5", satellites: "10", hdop: "0.6", lat: "0045.701382", lon: "12732.110726", latD: "N", lonD: "E", latitude: 0.7616897, longitude: 127.53517876666666, timestamp: "2024-06-06T11:25:27.000Z" },
        { speed: "9.0", course: "92.2", altitude: "10.3", satellites: "10", hdop: "0.6", lat: "0045.702496", lon: "12732.158581", latD: "N", lonD: "E", latitude: 0.7617082666666666, longitude: 127.53597635, timestamp: "2024-06-06T11:25:47.000Z" },
        { speed: "9.0", course: "92.2", altitude: "10.3", satellites: "10", hdop: "0.6", lat: "0045.702496", lon: "12732.158581", latD: "N", lonD: "E", latitude: 0.7617082666666666, longitude: 127.53597635, timestamp: "2024-06-06T11:25:47.000Z" },
        { speed: "9.0", course: "92.2", altitude: "10.3", satellites: "10", hdop: "0.6", lat: "0045.702496", lon: "12732.158581", latD: "N", lonD: "E", latitude: 0.7617082666666666, longitude: 127.53597635, timestamp: "2024-06-06T11:25:47.000Z" },
        { speed: "9.0", course: "92.2", altitude: "10.3", satellites: "10", hdop: "0.6", lat: "0045.702496", lon: "12732.158581", latD: "N", lonD: "E", latitude: 0.7617082666666666, longitude: 127.53597635, timestamp: "2024-06-06T11:25:47.000Z" },
        { speed: "8.6", course: "94.3", altitude: "16.0", satellites: "10", hdop: "0.6", lat: "0045.699653", lon: "12732.208988", latD: "N", lonD: "E", latitude: 0.7616608833333333, longitude: 127.53681646666666, timestamp: "2024-06-06T11:26:07.000Z" },
        { speed: "8.6", course: "94.3", altitude: "16.0", satellites: "10", hdop: "0.6", lat: "0045.699653", lon: "12732.208988", latD: "N", lonD: "E", latitude: 0.7616608833333333, longitude: 127.53681646666666, timestamp: "2024-06-06T11:26:07.000Z" },
        { speed: "8.6", course: "94.3", altitude: "16.0", satellites: "10", hdop: "0.6", lat: "0045.699653", lon: "12732.208988", latD: "N", lonD: "E", latitude: 0.7616608833333333, longitude: 127.53681646666666, timestamp: "2024-06-06T11:26:07.000Z" },
        { speed: "8.6", course: "94.3", altitude: "16.0", satellites: "10", hdop: "0.6", lat: "0045.699653", lon: "12732.208988", latD: "N", lonD: "E", latitude: 0.7616608833333333, longitude: 127.53681646666666, timestamp: "2024-06-06T11:26:07.000Z" },
        { speed: "9.0", course: "93.8", altitude: "15.1", satellites: "10", hdop: "0.6", lat: "0045.695891", lon: "12732.259156", latD: "N", lonD: "E", latitude: 0.7615981833333334, longitude: 127.5376526, timestamp: "2024-06-06T11:26:27.000Z" },
        { speed: "9.0", course: "93.8", altitude: "15.1", satellites: "10", hdop: "0.6", lat: "0045.695891", lon: "12732.259156", latD: "N", lonD: "E", latitude: 0.7615981833333334, longitude: 127.5376526, timestamp: "2024-06-06T11:26:27.000Z" },
        { speed: "8.6", course: "94.3", altitude: "16.0", satellites: "10", hdop: "0.6", lat: "0045.699653", lon: "12732.208988", latD: "N", lonD: "E", latitude: 0.7616608833333333, longitude: 127.53681646666666, timestamp: "2024-06-06T11:26:07.000Z" },
        { speed: "9.0", course: "93.8", altitude: "15.1", satellites: "10", hdop: "0.6", lat: "0045.695891", lon: "12732.259156", latD: "N", lonD: "E", latitude: 0.7615981833333334, longitude: 127.5376526, timestamp: "2024-06-06T11:26:27.000Z" },
        { speed: "9.0", course: "93.8", altitude: "15.1", satellites: "10", hdop: "0.6", lat: "0045.695891", lon: "12732.259156", latD: "N", lonD: "E", latitude: 0.7615981833333334, longitude: 127.5376526, timestamp: "2024-06-06T11:26:27.000Z" },
        { speed: "9.0", course: "93.8", altitude: "15.1", satellites: "10", hdop: "0.6", lat: "0045.695891", lon: "12732.259156", latD: "N", lonD: "E", latitude: 0.7615981833333334, longitude: 127.5376526, timestamp: "2024-06-06T11:26:27.000Z" },
        { speed: "9.0", course: "91.5", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.693272", lon: "12732.309390", latD: "N", lonD: "E", latitude: 0.7615545333333333, longitude: 127.53848983333333, timestamp: "2024-06-06T11:26:47.000Z" },
        { speed: "9.0", course: "91.5", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.693272", lon: "12732.309390", latD: "N", lonD: "E", latitude: 0.7615545333333333, longitude: 127.53848983333333, timestamp: "2024-06-06T11:26:47.000Z" },
        { speed: "8.6", course: "92.9", altitude: "6.7", satellites: "10", hdop: "0.6", lat: "0045.691390", lon: "12732.358776", latD: "N", lonD: "E", latitude: 0.7615231666666666, longitude: 127.53931293333333, timestamp: "2024-06-06T11:27:07.000Z" },
        { speed: "9.0", course: "91.5", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.693272", lon: "12732.309390", latD: "N", lonD: "E", latitude: 0.7615545333333333, longitude: 127.53848983333333, timestamp: "2024-06-06T11:26:47.000Z" },
        { speed: "9.0", course: "91.5", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.693272", lon: "12732.309390", latD: "N", lonD: "E", latitude: 0.7615545333333333, longitude: 127.53848983333333, timestamp: "2024-06-06T11:26:47.000Z" },
        { speed: "8.6", course: "92.9", altitude: "6.7", satellites: "10", hdop: "0.6", lat: "0045.691390", lon: "12732.358776", latD: "N", lonD: "E", latitude: 0.7615231666666666, longitude: 127.53931293333333, timestamp: "2024-06-06T11:27:07.000Z" },
        { speed: "8.6", course: "92.9", altitude: "6.7", satellites: "10", hdop: "0.6", lat: "0045.691390", lon: "12732.358776", latD: "N", lonD: "E", latitude: 0.7615231666666666, longitude: 127.53931293333333, timestamp: "2024-06-06T11:27:07.000Z" },
        { speed: "8.6", course: "92.9", altitude: "6.7", satellites: "10", hdop: "0.6", lat: "0045.691390", lon: "12732.358776", latD: "N", lonD: "E", latitude: 0.7615231666666666, longitude: 127.53931293333333, timestamp: "2024-06-06T11:27:07.000Z" },
        { speed: "8.7", course: "94.5", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.689206", lon: "12732.407237", latD: "N", lonD: "E", latitude: 0.7614867666666666, longitude: 127.54012061666667, timestamp: "2024-06-06T11:27:27.000Z" },
        { speed: "8.7", course: "94.5", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.689206", lon: "12732.407237", latD: "N", lonD: "E", latitude: 0.7614867666666666, longitude: 127.54012061666667, timestamp: "2024-06-06T11:27:27.000Z" },
        { speed: "8.7", course: "94.5", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.689206", lon: "12732.407237", latD: "N", lonD: "E", latitude: 0.7614867666666666, longitude: 127.54012061666667, timestamp: "2024-06-06T11:27:27.000Z" },
        { speed: "8.9", course: "95.9", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.684116", lon: "12732.455377", latD: "N", lonD: "E", latitude: 0.7614019333333334, longitude: 127.54092295, timestamp: "2024-06-06T11:27:47.000Z" },
        { speed: "8.9", course: "95.9", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.684116", lon: "12732.455377", latD: "N", lonD: "E", latitude: 0.7614019333333334, longitude: 127.54092295, timestamp: "2024-06-06T11:27:47.000Z" },
        { speed: "8.9", course: "95.9", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.684116", lon: "12732.455377", latD: "N", lonD: "E", latitude: 0.7614019333333334, longitude: 127.54092295, timestamp: "2024-06-06T11:27:47.000Z" },
        { speed: "8.9", course: "95.9", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.684116", lon: "12732.455377", latD: "N", lonD: "E", latitude: 0.7614019333333334, longitude: 127.54092295, timestamp: "2024-06-06T11:27:47.000Z" },
        { speed: "8.9", course: "95.9", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.684116", lon: "12732.455377", latD: "N", lonD: "E", latitude: 0.7614019333333334, longitude: 127.54092295, timestamp: "2024-06-06T11:27:47.000Z" },
        { speed: "8.4", course: "97.2", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.677921", lon: "12732.503959", latD: "N", lonD: "E", latitude: 0.7612986833333333, longitude: 127.54173265, timestamp: "2024-06-06T11:28:07.000Z" },
        { speed: "8.7", course: "97.8", altitude: "16.0", satellites: "10", hdop: "0.6", lat: "0045.672431", lon: "12732.552795", latD: "N", lonD: "E", latitude: 0.7612071833333334, longitude: 127.54254658333333, timestamp: "2024-06-06T11:28:27.000Z" },
        { speed: "8.4", course: "97.2", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.677921", lon: "12732.503959", latD: "N", lonD: "E", latitude: 0.7612986833333333, longitude: 127.54173265, timestamp: "2024-06-06T11:28:07.000Z" },
        { speed: "8.4", course: "97.2", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.677921", lon: "12732.503959", latD: "N", lonD: "E", latitude: 0.7612986833333333, longitude: 127.54173265, timestamp: "2024-06-06T11:28:07.000Z" },
        { speed: "8.4", course: "97.2", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.677921", lon: "12732.503959", latD: "N", lonD: "E", latitude: 0.7612986833333333, longitude: 127.54173265, timestamp: "2024-06-06T11:28:07.000Z" },
        { speed: "8.7", course: "97.8", altitude: "16.0", satellites: "10", hdop: "0.6", lat: "0045.672431", lon: "12732.552795", latD: "N", lonD: "E", latitude: 0.7612071833333334, longitude: 127.54254658333333, timestamp: "2024-06-06T11:28:27.000Z" },
        { speed: "9.0", course: "98.1", altitude: "19.4", satellites: "10", hdop: "0.6", lat: "0045.664983", lon: "12732.602550", latD: "N", lonD: "E", latitude: 0.76108305, longitude: 127.54337583333333, timestamp: "2024-06-06T11:28:47.000Z" },
        { speed: "9.0", course: "98.1", altitude: "19.4", satellites: "10", hdop: "0.6", lat: "0045.664983", lon: "12732.602550", latD: "N", lonD: "E", latitude: 0.76108305, longitude: 127.54337583333333, timestamp: "2024-06-06T11:28:47.000Z" },
        { speed: "8.7", course: "97.8", altitude: "16.0", satellites: "10", hdop: "0.6", lat: "0045.672431", lon: "12732.552795", latD: "N", lonD: "E", latitude: 0.7612071833333334, longitude: 127.54254658333333, timestamp: "2024-06-06T11:28:27.000Z" },
        { speed: "9.0", course: "98.1", altitude: "19.4", satellites: "10", hdop: "0.6", lat: "0045.664983", lon: "12732.602550", latD: "N", lonD: "E", latitude: 0.76108305, longitude: 127.54337583333333, timestamp: "2024-06-06T11:28:47.000Z" },
        { speed: "9.0", course: "94.7", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0045.661320", lon: "12732.652833", latD: "N", lonD: "E", latitude: 0.7610220000000001, longitude: 127.54421388333333, timestamp: "2024-06-06T11:29:07.000Z" },
        { speed: "9.0", course: "94.7", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0045.661320", lon: "12732.652833", latD: "N", lonD: "E", latitude: 0.7610220000000001, longitude: 127.54421388333333, timestamp: "2024-06-06T11:29:07.000Z" },
        { speed: "9.0", course: "94.7", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0045.661320", lon: "12732.652833", latD: "N", lonD: "E", latitude: 0.7610220000000001, longitude: 127.54421388333333, timestamp: "2024-06-06T11:29:07.000Z" },
        { speed: "9.0", course: "94.7", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0045.661320", lon: "12732.652833", latD: "N", lonD: "E", latitude: 0.7610220000000001, longitude: 127.54421388333333, timestamp: "2024-06-06T11:29:07.000Z" },
        { speed: "9.0", course: "92.8", altitude: "11.2", satellites: "08", hdop: "0.6", lat: "0045.656694", lon: "12732.701000", latD: "N", lonD: "E", latitude: 0.7609449, longitude: 127.54501666666667, timestamp: "2024-06-06T11:29:27.000Z" },
        { speed: "9.0", course: "92.8", altitude: "11.2", satellites: "08", hdop: "0.6", lat: "0045.656694", lon: "12732.701000", latD: "N", lonD: "E", latitude: 0.7609449, longitude: 127.54501666666667, timestamp: "2024-06-06T11:29:27.000Z" },
        { speed: "9.0", course: "94.7", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0045.661320", lon: "12732.652833", latD: "N", lonD: "E", latitude: 0.7610220000000001, longitude: 127.54421388333333, timestamp: "2024-06-06T11:29:07.000Z" },
        { speed: "9.0", course: "92.8", altitude: "11.2", satellites: "08", hdop: "0.6", lat: "0045.656694", lon: "12732.701000", latD: "N", lonD: "E", latitude: 0.7609449, longitude: 127.54501666666667, timestamp: "2024-06-06T11:29:27.000Z" },
        { speed: "9.0", course: "92.8", altitude: "11.2", satellites: "08", hdop: "0.6", lat: "0045.656694", lon: "12732.701000", latD: "N", lonD: "E", latitude: 0.7609449, longitude: 127.54501666666667, timestamp: "2024-06-06T11:29:27.000Z" },
        { speed: "9.0", course: "92.8", altitude: "11.2", satellites: "08", hdop: "0.6", lat: "0045.656694", lon: "12732.701000", latD: "N", lonD: "E", latitude: 0.7609449, longitude: 127.54501666666667, timestamp: "2024-06-06T11:29:27.000Z" },
        { speed: "9.0", course: "94.0", altitude: "8.6", satellites: "08", hdop: "0.6", lat: "0045.654323", lon: "12732.749135", latD: "N", lonD: "E", latitude: 0.7609053833333334, longitude: 127.54581891666666, timestamp: "2024-06-06T11:29:47.000Z" },
        { speed: "9.1", course: "92.1", altitude: "6.7", satellites: "09", hdop: "0.6", lat: "0045.652323", lon: "12732.796926", latD: "N", lonD: "E", latitude: 0.76087205, longitude: 127.54661543333333, timestamp: "2024-06-06T11:30:07.000Z" },
        { speed: "9.0", course: "94.0", altitude: "8.6", satellites: "08", hdop: "0.6", lat: "0045.654323", lon: "12732.749135", latD: "N", lonD: "E", latitude: 0.7609053833333334, longitude: 127.54581891666666, timestamp: "2024-06-06T11:29:47.000Z" },
        { speed: "9.0", course: "94.0", altitude: "8.6", satellites: "08", hdop: "0.6", lat: "0045.654323", lon: "12732.749135", latD: "N", lonD: "E", latitude: 0.7609053833333334, longitude: 127.54581891666666, timestamp: "2024-06-06T11:29:47.000Z" },
        { speed: "9.1", course: "92.1", altitude: "6.7", satellites: "09", hdop: "0.6", lat: "0045.652323", lon: "12732.796926", latD: "N", lonD: "E", latitude: 0.76087205, longitude: 127.54661543333333, timestamp: "2024-06-06T11:30:07.000Z" },
        { speed: "9.1", course: "92.1", altitude: "6.7", satellites: "09", hdop: "0.6", lat: "0045.652323", lon: "12732.796926", latD: "N", lonD: "E", latitude: 0.76087205, longitude: 127.54661543333333, timestamp: "2024-06-06T11:30:07.000Z" },
        { speed: "9.4", course: "91.1", altitude: "7.4", satellites: "09", hdop: "0.6", lat: "0045.651133", lon: "12732.848785", latD: "N", lonD: "E", latitude: 0.7608522166666667, longitude: 127.54747975, timestamp: "2024-06-06T11:30:27.000Z" },
        { speed: "9.4", course: "91.1", altitude: "7.4", satellites: "09", hdop: "0.6", lat: "0045.651133", lon: "12732.848785", latD: "N", lonD: "E", latitude: 0.7608522166666667, longitude: 127.54747975, timestamp: "2024-06-06T11:30:27.000Z" },
        { speed: "9.4", course: "91.1", altitude: "7.4", satellites: "09", hdop: "0.6", lat: "0045.651133", lon: "12732.848785", latD: "N", lonD: "E", latitude: 0.7608522166666667, longitude: 127.54747975, timestamp: "2024-06-06T11:30:27.000Z" },
        { speed: "9.2", course: "92.8", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.648588", lon: "12732.898913", latD: "N", lonD: "E", latitude: 0.7608098, longitude: 127.54831521666667, timestamp: "2024-06-06T11:30:47.000Z" },
        { speed: "9.2", course: "92.8", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.648588", lon: "12732.898913", latD: "N", lonD: "E", latitude: 0.7608098, longitude: 127.54831521666667, timestamp: "2024-06-06T11:30:47.000Z" },
        { speed: "9.4", course: "91.1", altitude: "7.4", satellites: "09", hdop: "0.6", lat: "0045.651133", lon: "12732.848785", latD: "N", lonD: "E", latitude: 0.7608522166666667, longitude: 127.54747975, timestamp: "2024-06-06T11:30:27.000Z" },
        { speed: "9.4", course: "91.1", altitude: "7.4", satellites: "09", hdop: "0.6", lat: "0045.651133", lon: "12732.848785", latD: "N", lonD: "E", latitude: 0.7608522166666667, longitude: 127.54747975, timestamp: "2024-06-06T11:30:27.000Z" },
        { speed: "9.2", course: "92.8", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.648588", lon: "12732.898913", latD: "N", lonD: "E", latitude: 0.7608098, longitude: 127.54831521666667, timestamp: "2024-06-06T11:30:47.000Z" },
        { speed: "9.2", course: "92.8", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.648588", lon: "12732.898913", latD: "N", lonD: "E", latitude: 0.7608098, longitude: 127.54831521666667, timestamp: "2024-06-06T11:30:47.000Z" },
        { speed: "9.2", course: "92.8", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.648588", lon: "12732.898913", latD: "N", lonD: "E", latitude: 0.7608098, longitude: 127.54831521666667, timestamp: "2024-06-06T11:30:47.000Z" },
        { speed: "9.0", course: "92.8", altitude: "0.7", satellites: "09", hdop: "0.6", lat: "0045.646680", lon: "12732.947486", latD: "N", lonD: "E", latitude: 0.7607780000000001, longitude: 127.54912476666667, timestamp: "2024-06-06T11:31:07.000Z" },
        { speed: "9.0", course: "92.8", altitude: "0.7", satellites: "09", hdop: "0.6", lat: "0045.646680", lon: "12732.947486", latD: "N", lonD: "E", latitude: 0.7607780000000001, longitude: 127.54912476666667, timestamp: "2024-06-06T11:31:07.000Z" },
        { speed: "9.0", course: "92.8", altitude: "0.7", satellites: "09", hdop: "0.6", lat: "0045.646680", lon: "12732.947486", latD: "N", lonD: "E", latitude: 0.7607780000000001, longitude: 127.54912476666667, timestamp: "2024-06-06T11:31:07.000Z" },
        { speed: "8.6", course: "93.0", altitude: "3.1", satellites: "10", hdop: "0.5", lat: "0045.644547", lon: "12732.996952", latD: "N", lonD: "E", latitude: 0.7607424500000001, longitude: 127.5499492, timestamp: "2024-06-06T11:31:27.000Z" },
        { speed: "8.6", course: "93.0", altitude: "3.1", satellites: "10", hdop: "0.5", lat: "0045.644547", lon: "12732.996952", latD: "N", lonD: "E", latitude: 0.7607424500000001, longitude: 127.5499492, timestamp: "2024-06-06T11:31:27.000Z" },
        { speed: "8.6", course: "93.0", altitude: "3.1", satellites: "10", hdop: "0.5", lat: "0045.644547", lon: "12732.996952", latD: "N", lonD: "E", latitude: 0.7607424500000001, longitude: 127.5499492, timestamp: "2024-06-06T11:31:27.000Z" },
        { speed: "8.8", course: "92.4", altitude: "6.2", satellites: "10", hdop: "0.5", lat: "0045.641299", lon: "12733.045853", latD: "N", lonD: "E", latitude: 0.7606883166666666, longitude: 127.55076421666666, timestamp: "2024-06-06T11:31:47.000Z" },
        { speed: "8.8", course: "92.4", altitude: "6.2", satellites: "10", hdop: "0.5", lat: "0045.641299", lon: "12733.045853", latD: "N", lonD: "E", latitude: 0.7606883166666666, longitude: 127.55076421666666, timestamp: "2024-06-06T11:31:47.000Z" },
        { speed: "8.8", course: "92.4", altitude: "6.2", satellites: "10", hdop: "0.5", lat: "0045.641299", lon: "12733.045853", latD: "N", lonD: "E", latitude: 0.7606883166666666, longitude: 127.55076421666666, timestamp: "2024-06-06T11:31:47.000Z" },
        { speed: "8.8", course: "92.4", altitude: "6.2", satellites: "10", hdop: "0.5", lat: "0045.641299", lon: "12733.045853", latD: "N", lonD: "E", latitude: 0.7606883166666666, longitude: 127.55076421666666, timestamp: "2024-06-06T11:31:47.000Z" },
        { speed: "8.8", course: "92.4", altitude: "6.2", satellites: "10", hdop: "0.5", lat: "0045.641299", lon: "12733.045853", latD: "N", lonD: "E", latitude: 0.7606883166666666, longitude: 127.55076421666666, timestamp: "2024-06-06T11:31:47.000Z" },
        { speed: "8.7", course: "86.5", altitude: "5.2", satellites: "10", hdop: "0.5", lat: "0045.641324", lon: "12733.094999", latD: "N", lonD: "E", latitude: 0.7606887333333333, longitude: 127.55158331666667, timestamp: "2024-06-06T11:32:07.000Z" },
        { speed: "8.7", course: "86.5", altitude: "5.2", satellites: "10", hdop: "0.5", lat: "0045.641324", lon: "12733.094999", latD: "N", lonD: "E", latitude: 0.7606887333333333, longitude: 127.55158331666667, timestamp: "2024-06-06T11:32:07.000Z" },
        { speed: "8.7", course: "86.5", altitude: "5.2", satellites: "10", hdop: "0.5", lat: "0045.641324", lon: "12733.094999", latD: "N", lonD: "E", latitude: 0.7606887333333333, longitude: 127.55158331666667, timestamp: "2024-06-06T11:32:07.000Z" },
        { speed: "8.7", course: "86.5", altitude: "5.2", satellites: "10", hdop: "0.5", lat: "0045.641324", lon: "12733.094999", latD: "N", lonD: "E", latitude: 0.7606887333333333, longitude: 127.55158331666667, timestamp: "2024-06-06T11:32:07.000Z" },
        { speed: "9.2", course: "85.4", altitude: "4.8", satellites: "10", hdop: "0.6", lat: "0045.645687", lon: "12733.144425", latD: "N", lonD: "E", latitude: 0.7607614500000001, longitude: 127.55240708333334, timestamp: "2024-06-06T11:32:27.000Z" },
        { speed: "9.2", course: "85.4", altitude: "4.8", satellites: "10", hdop: "0.6", lat: "0045.645687", lon: "12733.144425", latD: "N", lonD: "E", latitude: 0.7607614500000001, longitude: 127.55240708333334, timestamp: "2024-06-06T11:32:27.000Z" },
        { speed: "9.2", course: "85.4", altitude: "4.8", satellites: "10", hdop: "0.6", lat: "0045.645687", lon: "12733.144425", latD: "N", lonD: "E", latitude: 0.7607614500000001, longitude: 127.55240708333334, timestamp: "2024-06-06T11:32:27.000Z" },
        { speed: "8.8", course: "85.8", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0045.650324", lon: "12733.191851", latD: "N", lonD: "E", latitude: 0.7608387333333333, longitude: 127.55319751666667, timestamp: "2024-06-06T11:32:47.000Z" },
        { speed: "8.8", course: "85.8", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0045.650324", lon: "12733.191851", latD: "N", lonD: "E", latitude: 0.7608387333333333, longitude: 127.55319751666667, timestamp: "2024-06-06T11:32:47.000Z" },
        { speed: "9.2", course: "85.4", altitude: "4.8", satellites: "10", hdop: "0.6", lat: "0045.645687", lon: "12733.144425", latD: "N", lonD: "E", latitude: 0.7607614500000001, longitude: 127.55240708333334, timestamp: "2024-06-06T11:32:27.000Z" },
        { speed: "8.8", course: "85.8", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0045.650324", lon: "12733.191851", latD: "N", lonD: "E", latitude: 0.7608387333333333, longitude: 127.55319751666667, timestamp: "2024-06-06T11:32:47.000Z" },
        { speed: "8.8", course: "85.8", altitude: "10.9", satellites: "10", hdop: "0.6", lat: "0045.650324", lon: "12733.191851", latD: "N", lonD: "E", latitude: 0.7608387333333333, longitude: 127.55319751666667, timestamp: "2024-06-06T11:32:47.000Z" },
        { speed: "9.0", course: "90.6", altitude: "9.8", satellites: "08", hdop: "0.6", lat: "0045.649816", lon: "12733.241278", latD: "N", lonD: "E", latitude: 0.7608302666666666, longitude: 127.5540213, timestamp: "2024-06-06T11:33:07.000Z" },
        { speed: "9.0", course: "90.6", altitude: "9.8", satellites: "08", hdop: "0.6", lat: "0045.649816", lon: "12733.241278", latD: "N", lonD: "E", latitude: 0.7608302666666666, longitude: 127.5540213, timestamp: "2024-06-06T11:33:07.000Z" },
        { speed: "9.0", course: "90.6", altitude: "9.8", satellites: "08", hdop: "0.6", lat: "0045.649816", lon: "12733.241278", latD: "N", lonD: "E", latitude: 0.7608302666666666, longitude: 127.5540213, timestamp: "2024-06-06T11:33:07.000Z" },
        { speed: "8.4", course: "92.9", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.647224", lon: "12733.289862", latD: "N", lonD: "E", latitude: 0.7607870666666667, longitude: 127.55483103333333, timestamp: "2024-06-06T11:33:27.000Z" },
        { speed: "9.0", course: "90.6", altitude: "9.8", satellites: "08", hdop: "0.6", lat: "0045.649816", lon: "12733.241278", latD: "N", lonD: "E", latitude: 0.7608302666666666, longitude: 127.5540213, timestamp: "2024-06-06T11:33:07.000Z" },
        { speed: "9.0", course: "90.6", altitude: "9.8", satellites: "08", hdop: "0.6", lat: "0045.649816", lon: "12733.241278", latD: "N", lonD: "E", latitude: 0.7608302666666666, longitude: 127.5540213, timestamp: "2024-06-06T11:33:07.000Z" },
        { speed: "8.4", course: "92.9", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.647224", lon: "12733.289862", latD: "N", lonD: "E", latitude: 0.7607870666666667, longitude: 127.55483103333333, timestamp: "2024-06-06T11:33:27.000Z" },
        { speed: "8.4", course: "92.9", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.647224", lon: "12733.289862", latD: "N", lonD: "E", latitude: 0.7607870666666667, longitude: 127.55483103333333, timestamp: "2024-06-06T11:33:27.000Z" },
        { speed: "8.8", course: "95.2", altitude: "9.6", satellites: "10", hdop: "0.6", lat: "0045.641848", lon: "12733.340332", latD: "N", lonD: "E", latitude: 0.7606974666666667, longitude: 127.5556722, timestamp: "2024-06-06T11:33:47.000Z" },
        { speed: "8.8", course: "95.2", altitude: "9.6", satellites: "10", hdop: "0.6", lat: "0045.641848", lon: "12733.340332", latD: "N", lonD: "E", latitude: 0.7606974666666667, longitude: 127.5556722, timestamp: "2024-06-06T11:33:47.000Z" },
        { speed: "8.8", course: "95.2", altitude: "9.6", satellites: "10", hdop: "0.6", lat: "0045.641848", lon: "12733.340332", latD: "N", lonD: "E", latitude: 0.7606974666666667, longitude: 127.5556722, timestamp: "2024-06-06T11:33:47.000Z" },
        { speed: "9.3", course: "97.7", altitude: "9.6", satellites: "10", hdop: "0.5", lat: "0045.638057", lon: "12733.388659", latD: "N", lonD: "E", latitude: 0.7606342833333334, longitude: 127.55647765, timestamp: "2024-06-06T11:34:07.000Z" },
        { speed: "9.3", course: "97.7", altitude: "9.6", satellites: "10", hdop: "0.5", lat: "0045.638057", lon: "12733.388659", latD: "N", lonD: "E", latitude: 0.7606342833333334, longitude: 127.55647765, timestamp: "2024-06-06T11:34:07.000Z" },
        { speed: "9.3", course: "97.7", altitude: "9.6", satellites: "10", hdop: "0.5", lat: "0045.638057", lon: "12733.388659", latD: "N", lonD: "E", latitude: 0.7606342833333334, longitude: 127.55647765, timestamp: "2024-06-06T11:34:07.000Z" },
        { speed: "9.3", course: "97.7", altitude: "9.6", satellites: "10", hdop: "0.5", lat: "0045.638057", lon: "12733.388659", latD: "N", lonD: "E", latitude: 0.7606342833333334, longitude: 127.55647765, timestamp: "2024-06-06T11:34:07.000Z" },
        { speed: "9.3", course: "97.7", altitude: "9.6", satellites: "10", hdop: "0.5", lat: "0045.638057", lon: "12733.388659", latD: "N", lonD: "E", latitude: 0.7606342833333334, longitude: 127.55647765, timestamp: "2024-06-06T11:34:07.000Z" },
        { speed: "8.7", course: "97.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.631821", lon: "12733.437560", latD: "N", lonD: "E", latitude: 0.76053035, longitude: 127.55729266666667, timestamp: "2024-06-06T11:34:27.000Z" },
        { speed: "8.7", course: "97.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.631821", lon: "12733.437560", latD: "N", lonD: "E", latitude: 0.76053035, longitude: 127.55729266666667, timestamp: "2024-06-06T11:34:27.000Z" },
        { speed: "8.3", course: "97.5", altitude: "0.5", satellites: "10", hdop: "0.6", lat: "0045.627172", lon: "12733.488311", latD: "N", lonD: "E", latitude: 0.7604528666666667, longitude: 127.55813851666667, timestamp: "2024-06-06T11:34:47.000Z" },
        { speed: "8.7", course: "97.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.631821", lon: "12733.437560", latD: "N", lonD: "E", latitude: 0.76053035, longitude: 127.55729266666667, timestamp: "2024-06-06T11:34:27.000Z" },
        { speed: "8.7", course: "97.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.631821", lon: "12733.437560", latD: "N", lonD: "E", latitude: 0.76053035, longitude: 127.55729266666667, timestamp: "2024-06-06T11:34:27.000Z" },
        { speed: "8.7", course: "97.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.631821", lon: "12733.437560", latD: "N", lonD: "E", latitude: 0.76053035, longitude: 127.55729266666667, timestamp: "2024-06-06T11:34:27.000Z" },
        { speed: "8.3", course: "97.5", altitude: "0.5", satellites: "10", hdop: "0.6", lat: "0045.627172", lon: "12733.488311", latD: "N", lonD: "E", latitude: 0.7604528666666667, longitude: 127.55813851666667, timestamp: "2024-06-06T11:34:47.000Z" },
        { speed: "8.3", course: "97.5", altitude: "0.5", satellites: "10", hdop: "0.6", lat: "0045.627172", lon: "12733.488311", latD: "N", lonD: "E", latitude: 0.7604528666666667, longitude: 127.55813851666667, timestamp: "2024-06-06T11:34:47.000Z" },
        { speed: "8.7", course: "95.1", altitude: "0.1", satellites: "10", hdop: "0.6", lat: "0045.622567", lon: "12733.538462", latD: "N", lonD: "E", latitude: 0.7603761166666666, longitude: 127.55897436666666, timestamp: "2024-06-06T11:35:07.000Z" },
        { speed: "8.3", course: "97.5", altitude: "0.5", satellites: "10", hdop: "0.6", lat: "0045.627172", lon: "12733.488311", latD: "N", lonD: "E", latitude: 0.7604528666666667, longitude: 127.55813851666667, timestamp: "2024-06-06T11:34:47.000Z" },
        { speed: "8.7", course: "95.1", altitude: "0.1", satellites: "10", hdop: "0.6", lat: "0045.622567", lon: "12733.538462", latD: "N", lonD: "E", latitude: 0.7603761166666666, longitude: 127.55897436666666, timestamp: "2024-06-06T11:35:07.000Z" },
        { speed: "9.0", course: "99.0", altitude: "3.1", satellites: "09", hdop: "0.6", lat: "0045.617089", lon: "12733.585937", latD: "N", lonD: "E", latitude: 0.7602848166666667, longitude: 127.55976561666667, timestamp: "2024-06-06T11:35:27.000Z" },
        { speed: "9.0", course: "99.0", altitude: "3.1", satellites: "09", hdop: "0.6", lat: "0045.617089", lon: "12733.585937", latD: "N", lonD: "E", latitude: 0.7602848166666667, longitude: 127.55976561666667, timestamp: "2024-06-06T11:35:27.000Z" },
        { speed: "9.0", course: "99.0", altitude: "3.1", satellites: "09", hdop: "0.6", lat: "0045.617089", lon: "12733.585937", latD: "N", lonD: "E", latitude: 0.7602848166666667, longitude: 127.55976561666667, timestamp: "2024-06-06T11:35:27.000Z" },
        { speed: "9.0", course: "99.0", altitude: "3.1", satellites: "09", hdop: "0.6", lat: "0045.617089", lon: "12733.585937", latD: "N", lonD: "E", latitude: 0.7602848166666667, longitude: 127.55976561666667, timestamp: "2024-06-06T11:35:27.000Z" },
        { speed: "9.0", course: "99.0", altitude: "3.1", satellites: "09", hdop: "0.6", lat: "0045.617089", lon: "12733.585937", latD: "N", lonD: "E", latitude: 0.7602848166666667, longitude: 127.55976561666667, timestamp: "2024-06-06T11:35:27.000Z" },
        { speed: "8.9", course: "98.0", altitude: "7.0", satellites: "10", hdop: "0.6", lat: "0045.612254", lon: "12733.635453", latD: "N", lonD: "E", latitude: 0.7602042333333333, longitude: 127.56059088333333, timestamp: "2024-06-06T11:35:47.000Z" },
        { speed: "8.9", course: "98.0", altitude: "7.0", satellites: "10", hdop: "0.6", lat: "0045.612254", lon: "12733.635453", latD: "N", lonD: "E", latitude: 0.7602042333333333, longitude: 127.56059088333333, timestamp: "2024-06-06T11:35:47.000Z" },
        { speed: "8.9", course: "98.0", altitude: "7.0", satellites: "10", hdop: "0.6", lat: "0045.612254", lon: "12733.635453", latD: "N", lonD: "E", latitude: 0.7602042333333333, longitude: 127.56059088333333, timestamp: "2024-06-06T11:35:47.000Z" },
        { speed: "8.9", course: "98.0", altitude: "7.0", satellites: "10", hdop: "0.6", lat: "0045.612254", lon: "12733.635453", latD: "N", lonD: "E", latitude: 0.7602042333333333, longitude: 127.56059088333333, timestamp: "2024-06-06T11:35:47.000Z" },
        { speed: "8.9", course: "98.0", altitude: "7.0", satellites: "10", hdop: "0.6", lat: "0045.612254", lon: "12733.635453", latD: "N", lonD: "E", latitude: 0.7602042333333333, longitude: 127.56059088333333, timestamp: "2024-06-06T11:35:47.000Z" },
        { speed: "8.9", course: "96.0", altitude: "7.1", satellites: "10", hdop: "0.6", lat: "0045.605242", lon: "12733.684330", latD: "N", lonD: "E", latitude: 0.7600873666666667, longitude: 127.5614055, timestamp: "2024-06-06T11:36:07.000Z" },
        { speed: "9.1", course: "93.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.600744", lon: "12733.733143", latD: "N", lonD: "E", latitude: 0.7600124, longitude: 127.56221905, timestamp: "2024-06-06T11:36:27.000Z" },
        { speed: "8.9", course: "96.0", altitude: "7.1", satellites: "10", hdop: "0.6", lat: "0045.605242", lon: "12733.684330", latD: "N", lonD: "E", latitude: 0.7600873666666667, longitude: 127.5614055, timestamp: "2024-06-06T11:36:07.000Z" },
        { speed: "8.9", course: "96.0", altitude: "7.1", satellites: "10", hdop: "0.6", lat: "0045.605242", lon: "12733.684330", latD: "N", lonD: "E", latitude: 0.7600873666666667, longitude: 127.5614055, timestamp: "2024-06-06T11:36:07.000Z" },
        { speed: "8.9", course: "96.0", altitude: "7.1", satellites: "10", hdop: "0.6", lat: "0045.605242", lon: "12733.684330", latD: "N", lonD: "E", latitude: 0.7600873666666667, longitude: 127.5614055, timestamp: "2024-06-06T11:36:07.000Z" },
        { speed: "9.1", course: "93.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.600744", lon: "12733.733143", latD: "N", lonD: "E", latitude: 0.7600124, longitude: 127.56221905, timestamp: "2024-06-06T11:36:27.000Z" },
        { speed: "9.1", course: "93.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.600744", lon: "12733.733143", latD: "N", lonD: "E", latitude: 0.7600124, longitude: 127.56221905, timestamp: "2024-06-06T11:36:27.000Z" },
        { speed: "9.1", course: "93.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.600744", lon: "12733.733143", latD: "N", lonD: "E", latitude: 0.7600124, longitude: 127.56221905, timestamp: "2024-06-06T11:36:27.000Z" },
        { speed: "9.3", course: "93.7", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.596187", lon: "12733.781332", latD: "N", lonD: "E", latitude: 0.75993645, longitude: 127.5630222, timestamp: "2024-06-06T11:36:47.000Z" },
        { speed: "9.1", course: "92.8", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.593549", lon: "12733.831570", latD: "N", lonD: "E", latitude: 0.7598924833333334, longitude: 127.5638595, timestamp: "2024-06-06T11:37:07.000Z" },
        { speed: "9.1", course: "92.8", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.593549", lon: "12733.831570", latD: "N", lonD: "E", latitude: 0.7598924833333334, longitude: 127.5638595, timestamp: "2024-06-06T11:37:07.000Z" },
        { speed: "9.1", course: "92.8", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.593549", lon: "12733.831570", latD: "N", lonD: "E", latitude: 0.7598924833333334, longitude: 127.5638595, timestamp: "2024-06-06T11:37:07.000Z" },
        { speed: "9.0", course: "90.3", altitude: "11.1", satellites: "10", hdop: "0.6", lat: "0045.592259", lon: "12733.880910", latD: "N", lonD: "E", latitude: 0.7598709833333334, longitude: 127.56468183333334, timestamp: "2024-06-06T11:37:27.000Z" },
        { speed: "9.1", course: "92.8", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.593549", lon: "12733.831570", latD: "N", lonD: "E", latitude: 0.7598924833333334, longitude: 127.5638595, timestamp: "2024-06-06T11:37:07.000Z" },
        { speed: "9.1", course: "92.8", altitude: "9.4", satellites: "10", hdop: "0.6", lat: "0045.593549", lon: "12733.831570", latD: "N", lonD: "E", latitude: 0.7598924833333334, longitude: 127.5638595, timestamp: "2024-06-06T11:37:07.000Z" },
        { speed: "9.0", course: "90.3", altitude: "11.1", satellites: "10", hdop: "0.6", lat: "0045.592259", lon: "12733.880910", latD: "N", lonD: "E", latitude: 0.7598709833333334, longitude: 127.56468183333334, timestamp: "2024-06-06T11:37:27.000Z" },
        { speed: "9.0", course: "90.3", altitude: "11.1", satellites: "10", hdop: "0.6", lat: "0045.592259", lon: "12733.880910", latD: "N", lonD: "E", latitude: 0.7598709833333334, longitude: 127.56468183333334, timestamp: "2024-06-06T11:37:27.000Z" },
        { speed: "8.1", course: "93.7", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.591653", lon: "12733.929138", latD: "N", lonD: "E", latitude: 0.7598608833333333, longitude: 127.56548563333334, timestamp: "2024-06-06T11:37:47.000Z" },
        { speed: "8.1", course: "93.7", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.591653", lon: "12733.929138", latD: "N", lonD: "E", latitude: 0.7598608833333333, longitude: 127.56548563333334, timestamp: "2024-06-06T11:37:47.000Z" },
        { speed: "8.1", course: "93.7", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.591653", lon: "12733.929138", latD: "N", lonD: "E", latitude: 0.7598608833333333, longitude: 127.56548563333334, timestamp: "2024-06-06T11:37:47.000Z" },
        { speed: "8.7", course: "94.6", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.589117", lon: "12733.977014", latD: "N", lonD: "E", latitude: 0.7598186166666667, longitude: 127.56628356666667, timestamp: "2024-06-06T11:38:07.000Z" },
        { speed: "8.1", course: "93.7", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.591653", lon: "12733.929138", latD: "N", lonD: "E", latitude: 0.7598608833333333, longitude: 127.56548563333334, timestamp: "2024-06-06T11:37:47.000Z" },
        { speed: "8.1", course: "93.7", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.591653", lon: "12733.929138", latD: "N", lonD: "E", latitude: 0.7598608833333333, longitude: 127.56548563333334, timestamp: "2024-06-06T11:37:47.000Z" },
        { speed: "8.7", course: "94.6", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.589117", lon: "12733.977014", latD: "N", lonD: "E", latitude: 0.7598186166666667, longitude: 127.56628356666667, timestamp: "2024-06-06T11:38:07.000Z" },
        { speed: "8.7", course: "94.6", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.589117", lon: "12733.977014", latD: "N", lonD: "E", latitude: 0.7598186166666667, longitude: 127.56628356666667, timestamp: "2024-06-06T11:38:07.000Z" },
        { speed: "8.7", course: "94.6", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.589117", lon: "12733.977014", latD: "N", lonD: "E", latitude: 0.7598186166666667, longitude: 127.56628356666667, timestamp: "2024-06-06T11:38:07.000Z" },
        { speed: "9.0", course: "97.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.584350", lon: "12734.025921", latD: "N", lonD: "E", latitude: 0.7597391666666666, longitude: 127.56709868333333, timestamp: "2024-06-06T11:38:27.000Z" },
        { speed: "9.0", course: "97.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.584350", lon: "12734.025921", latD: "N", lonD: "E", latitude: 0.7597391666666666, longitude: 127.56709868333333, timestamp: "2024-06-06T11:38:27.000Z" },
        { speed: "9.0", course: "97.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.584350", lon: "12734.025921", latD: "N", lonD: "E", latitude: 0.7597391666666666, longitude: 127.56709868333333, timestamp: "2024-06-06T11:38:27.000Z" },
        { speed: "8.8", course: "103.4", altitude: "4.1", satellites: "10", hdop: "0.6", lat: "0045.574512", lon: "12734.072474", latD: "N", lonD: "E", latitude: 0.7595752, longitude: 127.56787456666666, timestamp: "2024-06-06T11:38:47.000Z" },
        { speed: "8.8", course: "103.4", altitude: "4.1", satellites: "10", hdop: "0.6", lat: "0045.574512", lon: "12734.072474", latD: "N", lonD: "E", latitude: 0.7595752, longitude: 127.56787456666666, timestamp: "2024-06-06T11:38:47.000Z" },
        { speed: "8.8", course: "103.4", altitude: "4.1", satellites: "10", hdop: "0.6", lat: "0045.574512", lon: "12734.072474", latD: "N", lonD: "E", latitude: 0.7595752, longitude: 127.56787456666666, timestamp: "2024-06-06T11:38:47.000Z" },
        { speed: "8.9", course: "104.9", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.564587", lon: "12734.117635", latD: "N", lonD: "E", latitude: 0.7594097833333334, longitude: 127.56862725, timestamp: "2024-06-06T11:39:07.000Z" },
        { speed: "8.9", course: "104.9", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.564587", lon: "12734.117635", latD: "N", lonD: "E", latitude: 0.7594097833333334, longitude: 127.56862725, timestamp: "2024-06-06T11:39:07.000Z" },
        { speed: "8.8", course: "103.4", altitude: "4.1", satellites: "10", hdop: "0.6", lat: "0045.574512", lon: "12734.072474", latD: "N", lonD: "E", latitude: 0.7595752, longitude: 127.56787456666666, timestamp: "2024-06-06T11:38:47.000Z" },
        { speed: "8.9", course: "104.9", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.564587", lon: "12734.117635", latD: "N", lonD: "E", latitude: 0.7594097833333334, longitude: 127.56862725, timestamp: "2024-06-06T11:39:07.000Z" },
        { speed: "8.9", course: "104.9", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.564587", lon: "12734.117635", latD: "N", lonD: "E", latitude: 0.7594097833333334, longitude: 127.56862725, timestamp: "2024-06-06T11:39:07.000Z" },
        { speed: "8.9", course: "104.9", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.564587", lon: "12734.117635", latD: "N", lonD: "E", latitude: 0.7594097833333334, longitude: 127.56862725, timestamp: "2024-06-06T11:39:07.000Z" },
        { speed: "8.8", course: "106.9", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.549472", lon: "12734.165834", latD: "N", lonD: "E", latitude: 0.7591578666666667, longitude: 127.56943056666667, timestamp: "2024-06-06T11:39:27.000Z" },
        { speed: "8.8", course: "106.9", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.549472", lon: "12734.165834", latD: "N", lonD: "E", latitude: 0.7591578666666667, longitude: 127.56943056666667, timestamp: "2024-06-06T11:39:27.000Z" },
        { speed: "8.5", course: "106.5", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.536169", lon: "12734.213574", latD: "N", lonD: "E", latitude: 0.75893615, longitude: 127.57022623333333, timestamp: "2024-06-06T11:39:47.000Z" },
        { speed: "8.8", course: "106.9", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.549472", lon: "12734.165834", latD: "N", lonD: "E", latitude: 0.7591578666666667, longitude: 127.56943056666667, timestamp: "2024-06-06T11:39:27.000Z" },
        { speed: "8.8", course: "106.9", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.549472", lon: "12734.165834", latD: "N", lonD: "E", latitude: 0.7591578666666667, longitude: 127.56943056666667, timestamp: "2024-06-06T11:39:27.000Z" },
        { speed: "8.5", course: "106.5", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.536169", lon: "12734.213574", latD: "N", lonD: "E", latitude: 0.75893615, longitude: 127.57022623333333, timestamp: "2024-06-06T11:39:47.000Z" },
        { speed: "8.5", course: "106.5", altitude: "5.6", satellites: "10", hdop: "0.6", lat: "0045.536169", lon: "12734.213574", latD: "N", lonD: "E", latitude: 0.75893615, longitude: 127.57022623333333, timestamp: "2024-06-06T11:39:47.000Z" },
        { speed: "8.4", course: "109.9", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.519290", lon: "12734.258912", latD: "N", lonD: "E", latitude: 0.7586548333333333, longitude: 127.57098186666667, timestamp: "2024-06-06T11:40:07.000Z" },
        { speed: "8.4", course: "109.9", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.519290", lon: "12734.258912", latD: "N", lonD: "E", latitude: 0.7586548333333333, longitude: 127.57098186666667, timestamp: "2024-06-06T11:40:07.000Z" },
        { speed: "8.4", course: "109.9", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.519290", lon: "12734.258912", latD: "N", lonD: "E", latitude: 0.7586548333333333, longitude: 127.57098186666667, timestamp: "2024-06-06T11:40:07.000Z" },
        { speed: "9.1", course: "110.9", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.501623", lon: "12734.303685", latD: "N", lonD: "E", latitude: 0.7583603833333333, longitude: 127.57172808333334, timestamp: "2024-06-06T11:40:27.000Z" },
        { speed: "9.1", course: "110.9", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.501623", lon: "12734.303685", latD: "N", lonD: "E", latitude: 0.7583603833333333, longitude: 127.57172808333334, timestamp: "2024-06-06T11:40:27.000Z" },
        { speed: "9.1", course: "110.9", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.501623", lon: "12734.303685", latD: "N", lonD: "E", latitude: 0.7583603833333333, longitude: 127.57172808333334, timestamp: "2024-06-06T11:40:27.000Z" },
        { speed: "9.1", course: "110.9", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.501623", lon: "12734.303685", latD: "N", lonD: "E", latitude: 0.7583603833333333, longitude: 127.57172808333334, timestamp: "2024-06-06T11:40:27.000Z" },
        { speed: "8.5", course: "112.9", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.484090", lon: "12734.347524", latD: "N", lonD: "E", latitude: 0.7580681666666667, longitude: 127.57245873333333, timestamp: "2024-06-06T11:40:47.000Z" },
        { speed: "9.1", course: "110.9", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.501623", lon: "12734.303685", latD: "N", lonD: "E", latitude: 0.7583603833333333, longitude: 127.57172808333334, timestamp: "2024-06-06T11:40:27.000Z" },
        { speed: "9.1", course: "110.9", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.501623", lon: "12734.303685", latD: "N", lonD: "E", latitude: 0.7583603833333333, longitude: 127.57172808333334, timestamp: "2024-06-06T11:40:27.000Z" },
        { speed: "8.5", course: "112.9", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.484090", lon: "12734.347524", latD: "N", lonD: "E", latitude: 0.7580681666666667, longitude: 127.57245873333333, timestamp: "2024-06-06T11:40:47.000Z" },
        { speed: "8.5", course: "112.9", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.484090", lon: "12734.347524", latD: "N", lonD: "E", latitude: 0.7580681666666667, longitude: 127.57245873333333, timestamp: "2024-06-06T11:40:47.000Z" },
        { speed: "8.5", course: "112.9", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.484090", lon: "12734.347524", latD: "N", lonD: "E", latitude: 0.7580681666666667, longitude: 127.57245873333333, timestamp: "2024-06-06T11:40:47.000Z" },
        { speed: "8.5", course: "115.3", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.464915", lon: "12734.391914", latD: "N", lonD: "E", latitude: 0.7577485833333333, longitude: 127.57319856666666, timestamp: "2024-06-06T11:41:07.000Z" },
        { speed: "8.5", course: "115.3", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.464915", lon: "12734.391914", latD: "N", lonD: "E", latitude: 0.7577485833333333, longitude: 127.57319856666666, timestamp: "2024-06-06T11:41:07.000Z" },
        { speed: "8.2", course: "123.0", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.441689", lon: "12734.431576", latD: "N", lonD: "E", latitude: 0.7573614833333333, longitude: 127.5738596, timestamp: "2024-06-06T11:41:27.000Z" },
        { speed: "8.5", course: "115.3", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.464915", lon: "12734.391914", latD: "N", lonD: "E", latitude: 0.7577485833333333, longitude: 127.57319856666666, timestamp: "2024-06-06T11:41:07.000Z" },
        { speed: "8.5", course: "115.3", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.464915", lon: "12734.391914", latD: "N", lonD: "E", latitude: 0.7577485833333333, longitude: 127.57319856666666, timestamp: "2024-06-06T11:41:07.000Z" },
        { speed: "8.2", course: "123.0", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.441689", lon: "12734.431576", latD: "N", lonD: "E", latitude: 0.7573614833333333, longitude: 127.5738596, timestamp: "2024-06-06T11:41:27.000Z" },
        { speed: "8.2", course: "123.0", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.441689", lon: "12734.431576", latD: "N", lonD: "E", latitude: 0.7573614833333333, longitude: 127.5738596, timestamp: "2024-06-06T11:41:27.000Z" },
        { speed: "8.2", course: "123.0", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.441689", lon: "12734.431576", latD: "N", lonD: "E", latitude: 0.7573614833333333, longitude: 127.5738596, timestamp: "2024-06-06T11:41:27.000Z" },
        { speed: "7.7", course: "127.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.416760", lon: "12734.466629", latD: "N", lonD: "E", latitude: 0.7569459999999999, longitude: 127.57444381666667, timestamp: "2024-06-06T11:41:47.000Z" },
        { speed: "7.7", course: "127.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.416760", lon: "12734.466629", latD: "N", lonD: "E", latitude: 0.7569459999999999, longitude: 127.57444381666667, timestamp: "2024-06-06T11:41:47.000Z" },
        { speed: "7.7", course: "127.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.416760", lon: "12734.466629", latD: "N", lonD: "E", latitude: 0.7569459999999999, longitude: 127.57444381666667, timestamp: "2024-06-06T11:41:47.000Z" },
        { speed: "7.7", course: "127.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.416760", lon: "12734.466629", latD: "N", lonD: "E", latitude: 0.7569459999999999, longitude: 127.57444381666667, timestamp: "2024-06-06T11:41:47.000Z" },
        { speed: "7.1", course: "130.4", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.390859", lon: "12734.498824", latD: "N", lonD: "E", latitude: 0.7565143166666667, longitude: 127.5749804, timestamp: "2024-06-06T11:42:07.000Z" },
        { speed: "7.1", course: "130.4", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.390859", lon: "12734.498824", latD: "N", lonD: "E", latitude: 0.7565143166666667, longitude: 127.5749804, timestamp: "2024-06-06T11:42:07.000Z" },
        { speed: "6.0", course: "130.7", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.366843", lon: "12734.527027", latD: "N", lonD: "E", latitude: 0.7561140500000001, longitude: 127.57545045, timestamp: "2024-06-06T11:42:27.000Z" },
        { speed: "7.1", course: "130.4", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.390859", lon: "12734.498824", latD: "N", lonD: "E", latitude: 0.7565143166666667, longitude: 127.5749804, timestamp: "2024-06-06T11:42:07.000Z" },
        { speed: "7.1", course: "130.4", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.390859", lon: "12734.498824", latD: "N", lonD: "E", latitude: 0.7565143166666667, longitude: 127.5749804, timestamp: "2024-06-06T11:42:07.000Z" },
        { speed: "6.0", course: "130.7", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.366843", lon: "12734.527027", latD: "N", lonD: "E", latitude: 0.7561140500000001, longitude: 127.57545045, timestamp: "2024-06-06T11:42:27.000Z" },
        { speed: "6.0", course: "130.7", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.366843", lon: "12734.527027", latD: "N", lonD: "E", latitude: 0.7561140500000001, longitude: 127.57545045, timestamp: "2024-06-06T11:42:27.000Z" },
        { speed: "6.0", course: "130.7", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.366843", lon: "12734.527027", latD: "N", lonD: "E", latitude: 0.7561140500000001, longitude: 127.57545045, timestamp: "2024-06-06T11:42:27.000Z" },
        { speed: "5.2", course: "128.4", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.345111", lon: "12734.551781", latD: "N", lonD: "E", latitude: 0.75575185, longitude: 127.57586301666667, timestamp: "2024-06-06T11:42:47.000Z" },
        { speed: "5.2", course: "128.4", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.345111", lon: "12734.551781", latD: "N", lonD: "E", latitude: 0.75575185, longitude: 127.57586301666667, timestamp: "2024-06-06T11:42:47.000Z" },
        { speed: "4.7", course: "132.0", altitude: "7.2", satellites: "10", hdop: "0.6", lat: "0045.327728", lon: "12734.572460", latD: "N", lonD: "E", latitude: 0.7554621333333333, longitude: 127.57620766666666, timestamp: "2024-06-06T11:43:07.000Z" },
        { speed: "4.7", course: "132.0", altitude: "7.2", satellites: "10", hdop: "0.6", lat: "0045.327728", lon: "12734.572460", latD: "N", lonD: "E", latitude: 0.7554621333333333, longitude: 127.57620766666666, timestamp: "2024-06-06T11:43:07.000Z" },
        { speed: "4.7", course: "132.0", altitude: "7.2", satellites: "10", hdop: "0.6", lat: "0045.327728", lon: "12734.572460", latD: "N", lonD: "E", latitude: 0.7554621333333333, longitude: 127.57620766666666, timestamp: "2024-06-06T11:43:07.000Z" },
        { speed: "4.7", course: "132.0", altitude: "7.2", satellites: "10", hdop: "0.6", lat: "0045.327728", lon: "12734.572460", latD: "N", lonD: "E", latitude: 0.7554621333333333, longitude: 127.57620766666666, timestamp: "2024-06-06T11:43:07.000Z" },
        { speed: "4.7", course: "132.0", altitude: "7.2", satellites: "10", hdop: "0.6", lat: "0045.327728", lon: "12734.572460", latD: "N", lonD: "E", latitude: 0.7554621333333333, longitude: 127.57620766666666, timestamp: "2024-06-06T11:43:07.000Z" },
        { speed: "4.1", course: "135.9", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.311113", lon: "12734.588768", latD: "N", lonD: "E", latitude: 0.7551852166666666, longitude: 127.57647946666667, timestamp: "2024-06-06T11:43:28.000Z" },
        { speed: "4.1", course: "135.9", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.311113", lon: "12734.588768", latD: "N", lonD: "E", latitude: 0.7551852166666666, longitude: 127.57647946666667, timestamp: "2024-06-06T11:43:28.000Z" },
        { speed: "4.1", course: "135.9", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.311113", lon: "12734.588768", latD: "N", lonD: "E", latitude: 0.7551852166666666, longitude: 127.57647946666667, timestamp: "2024-06-06T11:43:28.000Z" },
        { speed: "4.1", course: "135.9", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.311113", lon: "12734.588768", latD: "N", lonD: "E", latitude: 0.7551852166666666, longitude: 127.57647946666667, timestamp: "2024-06-06T11:43:28.000Z" },
        { speed: "2.7", course: "138.2", altitude: "9.3", satellites: "10", hdop: "0.6", lat: "0045.297553", lon: "12734.600294", latD: "N", lonD: "E", latitude: 0.7549592166666667, longitude: 127.57667156666666, timestamp: "2024-06-06T11:43:47.000Z" },
        { speed: "2.7", course: "138.2", altitude: "9.3", satellites: "10", hdop: "0.6", lat: "0045.297553", lon: "12734.600294", latD: "N", lonD: "E", latitude: 0.7549592166666667, longitude: 127.57667156666666, timestamp: "2024-06-06T11:43:47.000Z" },
        { speed: "2.7", course: "138.2", altitude: "9.3", satellites: "10", hdop: "0.6", lat: "0045.297553", lon: "12734.600294", latD: "N", lonD: "E", latitude: 0.7549592166666667, longitude: 127.57667156666666, timestamp: "2024-06-06T11:43:47.000Z" },
        { speed: "2.7", course: "138.2", altitude: "9.3", satellites: "10", hdop: "0.6", lat: "0045.297553", lon: "12734.600294", latD: "N", lonD: "E", latitude: 0.7549592166666667, longitude: 127.57667156666666, timestamp: "2024-06-06T11:43:47.000Z" },
        { speed: "2.7", course: "138.2", altitude: "9.3", satellites: "10", hdop: "0.6", lat: "0045.297553", lon: "12734.600294", latD: "N", lonD: "E", latitude: 0.7549592166666667, longitude: 127.57667156666666, timestamp: "2024-06-06T11:43:47.000Z" },
        { speed: "1.5", course: "140.1", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.289078", lon: "12734.607539", latD: "N", lonD: "E", latitude: 0.7548179666666667, longitude: 127.57679231666667, timestamp: "2024-06-06T11:44:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.283892", lon: "12734.611857", latD: "N", lonD: "E", latitude: 0.7547315333333333, longitude: 127.57686428333334, timestamp: "2024-06-06T11:44:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.283892", lon: "12734.611857", latD: "N", lonD: "E", latitude: 0.7547315333333333, longitude: 127.57686428333334, timestamp: "2024-06-06T11:44:28.000Z" },
        { speed: "1.5", course: "140.1", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.289078", lon: "12734.607539", latD: "N", lonD: "E", latitude: 0.7548179666666667, longitude: 127.57679231666667, timestamp: "2024-06-06T11:44:08.000Z" },
        { speed: "1.5", course: "140.1", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.289078", lon: "12734.607539", latD: "N", lonD: "E", latitude: 0.7548179666666667, longitude: 127.57679231666667, timestamp: "2024-06-06T11:44:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.283892", lon: "12734.611857", latD: "N", lonD: "E", latitude: 0.7547315333333333, longitude: 127.57686428333334, timestamp: "2024-06-06T11:44:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.283011", lon: "12734.613202", latD: "N", lonD: "E", latitude: 0.75471685, longitude: 127.5768867, timestamp: "2024-06-06T11:44:47.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.283011", lon: "12734.613202", latD: "N", lonD: "E", latitude: 0.75471685, longitude: 127.5768867, timestamp: "2024-06-06T11:44:47.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.283011", lon: "12734.613202", latD: "N", lonD: "E", latitude: 0.75471685, longitude: 127.5768867, timestamp: "2024-06-06T11:44:47.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.283011", lon: "12734.613202", latD: "N", lonD: "E", latitude: 0.75471685, longitude: 127.5768867, timestamp: "2024-06-06T11:44:47.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.283011", lon: "12734.613202", latD: "N", lonD: "E", latitude: 0.75471685, longitude: 127.5768867, timestamp: "2024-06-06T11:44:47.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282965", lon: "12734.613291", latD: "N", lonD: "E", latitude: 0.7547160833333333, longitude: 127.57688818333334, timestamp: "2024-06-06T11:45:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282965", lon: "12734.613291", latD: "N", lonD: "E", latitude: 0.7547160833333333, longitude: 127.57688818333334, timestamp: "2024-06-06T11:45:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282965", lon: "12734.613291", latD: "N", lonD: "E", latitude: 0.7547160833333333, longitude: 127.57688818333334, timestamp: "2024-06-06T11:45:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.282872", lon: "12734.613334", latD: "N", lonD: "E", latitude: 0.7547145333333333, longitude: 127.5768889, timestamp: "2024-06-06T11:45:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.282872", lon: "12734.613334", latD: "N", lonD: "E", latitude: 0.7547145333333333, longitude: 127.5768889, timestamp: "2024-06-06T11:45:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282965", lon: "12734.613291", latD: "N", lonD: "E", latitude: 0.7547160833333333, longitude: 127.57688818333334, timestamp: "2024-06-06T11:45:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.282872", lon: "12734.613334", latD: "N", lonD: "E", latitude: 0.7547145333333333, longitude: 127.5768889, timestamp: "2024-06-06T11:45:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.282872", lon: "12734.613334", latD: "N", lonD: "E", latitude: 0.7547145333333333, longitude: 127.5768889, timestamp: "2024-06-06T11:45:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282834", lon: "12734.613367", latD: "N", lonD: "E", latitude: 0.7547139, longitude: 127.57688945, timestamp: "2024-06-06T11:45:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282834", lon: "12734.613367", latD: "N", lonD: "E", latitude: 0.7547139, longitude: 127.57688945, timestamp: "2024-06-06T11:45:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282834", lon: "12734.613367", latD: "N", lonD: "E", latitude: 0.7547139, longitude: 127.57688945, timestamp: "2024-06-06T11:45:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282834", lon: "12734.613367", latD: "N", lonD: "E", latitude: 0.7547139, longitude: 127.57688945, timestamp: "2024-06-06T11:45:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282786", lon: "12734.613416", latD: "N", lonD: "E", latitude: 0.7547131, longitude: 127.57689026666667, timestamp: "2024-06-06T11:46:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282786", lon: "12734.613416", latD: "N", lonD: "E", latitude: 0.7547131, longitude: 127.57689026666667, timestamp: "2024-06-06T11:46:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282786", lon: "12734.613416", latD: "N", lonD: "E", latitude: 0.7547131, longitude: 127.57689026666667, timestamp: "2024-06-06T11:46:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282786", lon: "12734.613416", latD: "N", lonD: "E", latitude: 0.7547131, longitude: 127.57689026666667, timestamp: "2024-06-06T11:46:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.282653", lon: "12734.613463", latD: "N", lonD: "E", latitude: 0.7547108833333334, longitude: 127.57689105, timestamp: "2024-06-06T11:46:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.282653", lon: "12734.613463", latD: "N", lonD: "E", latitude: 0.7547108833333334, longitude: 127.57689105, timestamp: "2024-06-06T11:46:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282646", lon: "12734.613531", latD: "N", lonD: "E", latitude: 0.7547107666666667, longitude: 127.57689218333333, timestamp: "2024-06-06T11:46:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.282653", lon: "12734.613463", latD: "N", lonD: "E", latitude: 0.7547108833333334, longitude: 127.57689105, timestamp: "2024-06-06T11:46:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.282653", lon: "12734.613463", latD: "N", lonD: "E", latitude: 0.7547108833333334, longitude: 127.57689105, timestamp: "2024-06-06T11:46:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.282653", lon: "12734.613463", latD: "N", lonD: "E", latitude: 0.7547108833333334, longitude: 127.57689105, timestamp: "2024-06-06T11:46:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282646", lon: "12734.613531", latD: "N", lonD: "E", latitude: 0.7547107666666667, longitude: 127.57689218333333, timestamp: "2024-06-06T11:46:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.282646", lon: "12734.613531", latD: "N", lonD: "E", latitude: 0.7547107666666667, longitude: 127.57689218333333, timestamp: "2024-06-06T11:46:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.282586", lon: "12734.613565", latD: "N", lonD: "E", latitude: 0.7547097666666667, longitude: 127.57689275, timestamp: "2024-06-06T11:47:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.282586", lon: "12734.613565", latD: "N", lonD: "E", latitude: 0.7547097666666667, longitude: 127.57689275, timestamp: "2024-06-06T11:47:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.282586", lon: "12734.613565", latD: "N", lonD: "E", latitude: 0.7547097666666667, longitude: 127.57689275, timestamp: "2024-06-06T11:47:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.282586", lon: "12734.613565", latD: "N", lonD: "E", latitude: 0.7547097666666667, longitude: 127.57689275, timestamp: "2024-06-06T11:47:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.282586", lon: "12734.613565", latD: "N", lonD: "E", latitude: 0.7547097666666667, longitude: 127.57689275, timestamp: "2024-06-06T11:47:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.282504", lon: "12734.613606", latD: "N", lonD: "E", latitude: 0.7547084000000001, longitude: 127.57689343333334, timestamp: "2024-06-06T11:47:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.282504", lon: "12734.613606", latD: "N", lonD: "E", latitude: 0.7547084000000001, longitude: 127.57689343333334, timestamp: "2024-06-06T11:47:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.282586", lon: "12734.613565", latD: "N", lonD: "E", latitude: 0.7547097666666667, longitude: 127.57689275, timestamp: "2024-06-06T11:47:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.282504", lon: "12734.613606", latD: "N", lonD: "E", latitude: 0.7547084000000001, longitude: 127.57689343333334, timestamp: "2024-06-06T11:47:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.282504", lon: "12734.613606", latD: "N", lonD: "E", latitude: 0.7547084000000001, longitude: 127.57689343333334, timestamp: "2024-06-06T11:47:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.282414", lon: "12734.613652", latD: "N", lonD: "E", latitude: 0.7547069000000001, longitude: 127.5768942, timestamp: "2024-06-06T11:47:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.282504", lon: "12734.613606", latD: "N", lonD: "E", latitude: 0.7547084000000001, longitude: 127.57689343333334, timestamp: "2024-06-06T11:47:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.282414", lon: "12734.613652", latD: "N", lonD: "E", latitude: 0.7547069000000001, longitude: 127.5768942, timestamp: "2024-06-06T11:47:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.282414", lon: "12734.613652", latD: "N", lonD: "E", latitude: 0.7547069000000001, longitude: 127.5768942, timestamp: "2024-06-06T11:47:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.282414", lon: "12734.613652", latD: "N", lonD: "E", latitude: 0.7547069000000001, longitude: 127.5768942, timestamp: "2024-06-06T11:47:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.282348", lon: "12734.613699", latD: "N", lonD: "E", latitude: 0.7547058, longitude: 127.57689498333333, timestamp: "2024-06-06T11:48:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.282348", lon: "12734.613699", latD: "N", lonD: "E", latitude: 0.7547058, longitude: 127.57689498333333, timestamp: "2024-06-06T11:48:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.7", lat: "0045.282299", lon: "12734.613730", latD: "N", lonD: "E", latitude: 0.7547049833333334, longitude: 127.5768955, timestamp: "2024-06-06T11:48:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.7", lat: "0045.282299", lon: "12734.613730", latD: "N", lonD: "E", latitude: 0.7547049833333334, longitude: 127.5768955, timestamp: "2024-06-06T11:48:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.282348", lon: "12734.613699", latD: "N", lonD: "E", latitude: 0.7547058, longitude: 127.57689498333333, timestamp: "2024-06-06T11:48:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.7", lat: "0045.282299", lon: "12734.613730", latD: "N", lonD: "E", latitude: 0.7547049833333334, longitude: 127.5768955, timestamp: "2024-06-06T11:48:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.7", lat: "0045.282299", lon: "12734.613730", latD: "N", lonD: "E", latitude: 0.7547049833333334, longitude: 127.5768955, timestamp: "2024-06-06T11:48:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.2", satellites: "09", hdop: "0.7", lat: "0045.282299", lon: "12734.613730", latD: "N", lonD: "E", latitude: 0.7547049833333334, longitude: 127.5768955, timestamp: "2024-06-06T11:48:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.4", satellites: "09", hdop: "0.6", lat: "0045.282243", lon: "12734.613771", latD: "N", lonD: "E", latitude: 0.7547040500000001, longitude: 127.57689618333333, timestamp: "2024-06-06T11:48:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.4", satellites: "09", hdop: "0.6", lat: "0045.282243", lon: "12734.613771", latD: "N", lonD: "E", latitude: 0.7547040500000001, longitude: 127.57689618333333, timestamp: "2024-06-06T11:48:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.5", satellites: "09", hdop: "0.6", lat: "0045.282160", lon: "12734.613805", latD: "N", lonD: "E", latitude: 0.7547026666666666, longitude: 127.57689675, timestamp: "2024-06-06T11:49:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.4", satellites: "09", hdop: "0.6", lat: "0045.282243", lon: "12734.613771", latD: "N", lonD: "E", latitude: 0.7547040500000001, longitude: 127.57689618333333, timestamp: "2024-06-06T11:48:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.4", satellites: "09", hdop: "0.6", lat: "0045.282243", lon: "12734.613771", latD: "N", lonD: "E", latitude: 0.7547040500000001, longitude: 127.57689618333333, timestamp: "2024-06-06T11:48:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.5", satellites: "09", hdop: "0.6", lat: "0045.282160", lon: "12734.613805", latD: "N", lonD: "E", latitude: 0.7547026666666666, longitude: 127.57689675, timestamp: "2024-06-06T11:49:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.5", satellites: "09", hdop: "0.6", lat: "0045.282160", lon: "12734.613805", latD: "N", lonD: "E", latitude: 0.7547026666666666, longitude: 127.57689675, timestamp: "2024-06-06T11:49:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.282095", lon: "12734.613829", latD: "N", lonD: "E", latitude: 0.7547015833333333, longitude: 127.57689715, timestamp: "2024-06-06T11:49:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.282095", lon: "12734.613829", latD: "N", lonD: "E", latitude: 0.7547015833333333, longitude: 127.57689715, timestamp: "2024-06-06T11:49:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.282095", lon: "12734.613829", latD: "N", lonD: "E", latitude: 0.7547015833333333, longitude: 127.57689715, timestamp: "2024-06-06T11:49:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.282095", lon: "12734.613829", latD: "N", lonD: "E", latitude: 0.7547015833333333, longitude: 127.57689715, timestamp: "2024-06-06T11:49:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.8", satellites: "09", hdop: "0.7", lat: "0045.281936", lon: "12734.613833", latD: "N", lonD: "E", latitude: 0.7546989333333334, longitude: 127.57689721666667, timestamp: "2024-06-06T11:49:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.8", satellites: "09", hdop: "0.7", lat: "0045.281936", lon: "12734.613833", latD: "N", lonD: "E", latitude: 0.7546989333333334, longitude: 127.57689721666667, timestamp: "2024-06-06T11:49:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.8", satellites: "09", hdop: "0.7", lat: "0045.281936", lon: "12734.613833", latD: "N", lonD: "E", latitude: 0.7546989333333334, longitude: 127.57689721666667, timestamp: "2024-06-06T11:49:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.8", satellites: "09", hdop: "0.7", lat: "0045.281936", lon: "12734.613833", latD: "N", lonD: "E", latitude: 0.7546989333333334, longitude: 127.57689721666667, timestamp: "2024-06-06T11:49:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "10.8", satellites: "09", hdop: "0.7", lat: "0045.281936", lon: "12734.613833", latD: "N", lonD: "E", latitude: 0.7546989333333334, longitude: 127.57689721666667, timestamp: "2024-06-06T11:49:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.0", satellites: "08", hdop: "0.7", lat: "0045.281867", lon: "12734.613864", latD: "N", lonD: "E", latitude: 0.7546977833333333, longitude: 127.57689773333334, timestamp: "2024-06-06T11:50:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.281735", lon: "12734.613871", latD: "N", lonD: "E", latitude: 0.7546955833333333, longitude: 127.57689785, timestamp: "2024-06-06T11:50:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.281735", lon: "12734.613871", latD: "N", lonD: "E", latitude: 0.7546955833333333, longitude: 127.57689785, timestamp: "2024-06-06T11:50:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.0", satellites: "08", hdop: "0.7", lat: "0045.281867", lon: "12734.613864", latD: "N", lonD: "E", latitude: 0.7546977833333333, longitude: 127.57689773333334, timestamp: "2024-06-06T11:50:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.0", satellites: "08", hdop: "0.7", lat: "0045.281867", lon: "12734.613864", latD: "N", lonD: "E", latitude: 0.7546977833333333, longitude: 127.57689773333334, timestamp: "2024-06-06T11:50:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.281735", lon: "12734.613871", latD: "N", lonD: "E", latitude: 0.7546955833333333, longitude: 127.57689785, timestamp: "2024-06-06T11:50:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.281673", lon: "12734.613874", latD: "N", lonD: "E", latitude: 0.75469455, longitude: 127.5768979, timestamp: "2024-06-06T11:50:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.281673", lon: "12734.613874", latD: "N", lonD: "E", latitude: 0.75469455, longitude: 127.5768979, timestamp: "2024-06-06T11:50:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.281673", lon: "12734.613874", latD: "N", lonD: "E", latitude: 0.75469455, longitude: 127.5768979, timestamp: "2024-06-06T11:50:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.281673", lon: "12734.613874", latD: "N", lonD: "E", latitude: 0.75469455, longitude: 127.5768979, timestamp: "2024-06-06T11:50:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.281586", lon: "12734.613911", latD: "N", lonD: "E", latitude: 0.7546931, longitude: 127.57689851666666, timestamp: "2024-06-06T11:51:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.281586", lon: "12734.613911", latD: "N", lonD: "E", latitude: 0.7546931, longitude: 127.57689851666666, timestamp: "2024-06-06T11:51:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.281586", lon: "12734.613911", latD: "N", lonD: "E", latitude: 0.7546931, longitude: 127.57689851666666, timestamp: "2024-06-06T11:51:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.281586", lon: "12734.613911", latD: "N", lonD: "E", latitude: 0.7546931, longitude: 127.57689851666666, timestamp: "2024-06-06T11:51:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.281573", lon: "12734.613944", latD: "N", lonD: "E", latitude: 0.7546928833333334, longitude: 127.57689906666667, timestamp: "2024-06-06T11:51:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.281573", lon: "12734.613944", latD: "N", lonD: "E", latitude: 0.7546928833333334, longitude: 127.57689906666667, timestamp: "2024-06-06T11:51:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.281573", lon: "12734.613944", latD: "N", lonD: "E", latitude: 0.7546928833333334, longitude: 127.57689906666667, timestamp: "2024-06-06T11:51:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.281573", lon: "12734.613944", latD: "N", lonD: "E", latitude: 0.7546928833333334, longitude: 127.57689906666667, timestamp: "2024-06-06T11:51:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.281573", lon: "12734.613944", latD: "N", lonD: "E", latitude: 0.7546928833333334, longitude: 127.57689906666667, timestamp: "2024-06-06T11:51:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "10", hdop: "0.6", lat: "0045.281496", lon: "12734.613973", latD: "N", lonD: "E", latitude: 0.7546915999999999, longitude: 127.57689955, timestamp: "2024-06-06T11:51:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "10", hdop: "0.6", lat: "0045.281496", lon: "12734.613973", latD: "N", lonD: "E", latitude: 0.7546915999999999, longitude: 127.57689955, timestamp: "2024-06-06T11:51:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.281498", lon: "12734.614001", latD: "N", lonD: "E", latitude: 0.7546916333333333, longitude: 127.57690001666667, timestamp: "2024-06-06T11:52:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.281498", lon: "12734.614001", latD: "N", lonD: "E", latitude: 0.7546916333333333, longitude: 127.57690001666667, timestamp: "2024-06-06T11:52:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.281498", lon: "12734.614001", latD: "N", lonD: "E", latitude: 0.7546916333333333, longitude: 127.57690001666667, timestamp: "2024-06-06T11:52:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "10", hdop: "0.6", lat: "0045.281496", lon: "12734.613973", latD: "N", lonD: "E", latitude: 0.7546915999999999, longitude: 127.57689955, timestamp: "2024-06-06T11:51:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.281498", lon: "12734.614001", latD: "N", lonD: "E", latitude: 0.7546916333333333, longitude: 127.57690001666667, timestamp: "2024-06-06T11:52:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.281498", lon: "12734.614001", latD: "N", lonD: "E", latitude: 0.7546916333333333, longitude: 127.57690001666667, timestamp: "2024-06-06T11:52:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.281463", lon: "12734.614025", latD: "N", lonD: "E", latitude: 0.75469105, longitude: 127.57690041666666, timestamp: "2024-06-06T11:52:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.281463", lon: "12734.614025", latD: "N", lonD: "E", latitude: 0.75469105, longitude: 127.57690041666666, timestamp: "2024-06-06T11:52:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.281463", lon: "12734.614025", latD: "N", lonD: "E", latitude: 0.75469105, longitude: 127.57690041666666, timestamp: "2024-06-06T11:52:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.281463", lon: "12734.614025", latD: "N", lonD: "E", latitude: 0.75469105, longitude: 127.57690041666666, timestamp: "2024-06-06T11:52:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.8", satellites: "10", hdop: "0.6", lat: "0045.281419", lon: "12734.614038", latD: "N", lonD: "E", latitude: 0.7546903166666666, longitude: 127.57690063333334, timestamp: "2024-06-06T11:52:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.8", satellites: "10", hdop: "0.6", lat: "0045.281419", lon: "12734.614038", latD: "N", lonD: "E", latitude: 0.7546903166666666, longitude: 127.57690063333334, timestamp: "2024-06-06T11:52:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.8", satellites: "10", hdop: "0.6", lat: "0045.281419", lon: "12734.614038", latD: "N", lonD: "E", latitude: 0.7546903166666666, longitude: 127.57690063333334, timestamp: "2024-06-06T11:52:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.8", satellites: "08", hdop: "0.7", lat: "0045.281390", lon: "12734.614097", latD: "N", lonD: "E", latitude: 0.7546898333333334, longitude: 127.57690161666666, timestamp: "2024-06-06T11:53:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.8", satellites: "10", hdop: "0.6", lat: "0045.281419", lon: "12734.614038", latD: "N", lonD: "E", latitude: 0.7546903166666666, longitude: 127.57690063333334, timestamp: "2024-06-06T11:52:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.8", satellites: "10", hdop: "0.6", lat: "0045.281419", lon: "12734.614038", latD: "N", lonD: "E", latitude: 0.7546903166666666, longitude: 127.57690063333334, timestamp: "2024-06-06T11:52:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.8", satellites: "08", hdop: "0.7", lat: "0045.281390", lon: "12734.614097", latD: "N", lonD: "E", latitude: 0.7546898333333334, longitude: 127.57690161666666, timestamp: "2024-06-06T11:53:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.8", satellites: "08", hdop: "0.7", lat: "0045.281390", lon: "12734.614097", latD: "N", lonD: "E", latitude: 0.7546898333333334, longitude: 127.57690161666666, timestamp: "2024-06-06T11:53:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.8", satellites: "08", hdop: "0.7", lat: "0045.281390", lon: "12734.614097", latD: "N", lonD: "E", latitude: 0.7546898333333334, longitude: 127.57690161666666, timestamp: "2024-06-06T11:53:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.9", satellites: "09", hdop: "0.6", lat: "0045.281340", lon: "12734.614137", latD: "N", lonD: "E", latitude: 0.754689, longitude: 127.57690228333334, timestamp: "2024-06-06T11:53:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.9", satellites: "09", hdop: "0.6", lat: "0045.281340", lon: "12734.614137", latD: "N", lonD: "E", latitude: 0.754689, longitude: 127.57690228333334, timestamp: "2024-06-06T11:53:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.1", satellites: "08", hdop: "0.7", lat: "0045.281268", lon: "12734.614154", latD: "N", lonD: "E", latitude: 0.7546877999999999, longitude: 127.57690256666666, timestamp: "2024-06-06T11:53:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "11.9", satellites: "09", hdop: "0.6", lat: "0045.281340", lon: "12734.614137", latD: "N", lonD: "E", latitude: 0.754689, longitude: 127.57690228333334, timestamp: "2024-06-06T11:53:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.1", satellites: "08", hdop: "0.7", lat: "0045.281268", lon: "12734.614154", latD: "N", lonD: "E", latitude: 0.7546877999999999, longitude: 127.57690256666666, timestamp: "2024-06-06T11:53:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.1", satellites: "08", hdop: "0.7", lat: "0045.281268", lon: "12734.614154", latD: "N", lonD: "E", latitude: 0.7546877999999999, longitude: 127.57690256666666, timestamp: "2024-06-06T11:53:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.1", satellites: "08", hdop: "0.7", lat: "0045.281268", lon: "12734.614154", latD: "N", lonD: "E", latitude: 0.7546877999999999, longitude: 127.57690256666666, timestamp: "2024-06-06T11:53:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "08", hdop: "0.7", lat: "0045.281217", lon: "12734.614160", latD: "N", lonD: "E", latitude: 0.75468695, longitude: 127.57690266666667, timestamp: "2024-06-06T11:54:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "08", hdop: "0.7", lat: "0045.281217", lon: "12734.614160", latD: "N", lonD: "E", latitude: 0.75468695, longitude: 127.57690266666667, timestamp: "2024-06-06T11:54:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "08", hdop: "0.7", lat: "0045.281217", lon: "12734.614160", latD: "N", lonD: "E", latitude: 0.75468695, longitude: 127.57690266666667, timestamp: "2024-06-06T11:54:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "08", hdop: "0.7", lat: "0045.281217", lon: "12734.614160", latD: "N", lonD: "E", latitude: 0.75468695, longitude: 127.57690266666667, timestamp: "2024-06-06T11:54:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "07", hdop: "0.7", lat: "0045.281146", lon: "12734.614155", latD: "N", lonD: "E", latitude: 0.7546857666666666, longitude: 127.57690258333334, timestamp: "2024-06-06T11:54:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "07", hdop: "0.7", lat: "0045.281146", lon: "12734.614155", latD: "N", lonD: "E", latitude: 0.7546857666666666, longitude: 127.57690258333334, timestamp: "2024-06-06T11:54:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "07", hdop: "0.7", lat: "0045.281146", lon: "12734.614155", latD: "N", lonD: "E", latitude: 0.7546857666666666, longitude: 127.57690258333334, timestamp: "2024-06-06T11:54:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "09", hdop: "0.6", lat: "0045.281113", lon: "12734.614184", latD: "N", lonD: "E", latitude: 0.7546852166666667, longitude: 127.57690306666667, timestamp: "2024-06-06T11:54:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "09", hdop: "0.6", lat: "0045.281113", lon: "12734.614184", latD: "N", lonD: "E", latitude: 0.7546852166666667, longitude: 127.57690306666667, timestamp: "2024-06-06T11:54:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "07", hdop: "0.7", lat: "0045.281146", lon: "12734.614155", latD: "N", lonD: "E", latitude: 0.7546857666666666, longitude: 127.57690258333334, timestamp: "2024-06-06T11:54:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "09", hdop: "0.6", lat: "0045.281113", lon: "12734.614184", latD: "N", lonD: "E", latitude: 0.7546852166666667, longitude: 127.57690306666667, timestamp: "2024-06-06T11:54:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "09", hdop: "0.6", lat: "0045.281113", lon: "12734.614184", latD: "N", lonD: "E", latitude: 0.7546852166666667, longitude: 127.57690306666667, timestamp: "2024-06-06T11:54:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.281075", lon: "12734.614210", latD: "N", lonD: "E", latitude: 0.7546845833333333, longitude: 127.5769035, timestamp: "2024-06-06T11:55:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.281075", lon: "12734.614210", latD: "N", lonD: "E", latitude: 0.7546845833333333, longitude: 127.5769035, timestamp: "2024-06-06T11:55:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.281075", lon: "12734.614210", latD: "N", lonD: "E", latitude: 0.7546845833333333, longitude: 127.5769035, timestamp: "2024-06-06T11:55:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.281029", lon: "12734.614242", latD: "N", lonD: "E", latitude: 0.7546838166666666, longitude: 127.57690403333334, timestamp: "2024-06-06T11:55:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.281075", lon: "12734.614210", latD: "N", lonD: "E", latitude: 0.7546845833333333, longitude: 127.5769035, timestamp: "2024-06-06T11:55:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.281029", lon: "12734.614242", latD: "N", lonD: "E", latitude: 0.7546838166666666, longitude: 127.57690403333334, timestamp: "2024-06-06T11:55:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.281029", lon: "12734.614242", latD: "N", lonD: "E", latitude: 0.7546838166666666, longitude: 127.57690403333334, timestamp: "2024-06-06T11:55:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.281029", lon: "12734.614242", latD: "N", lonD: "E", latitude: 0.7546838166666666, longitude: 127.57690403333334, timestamp: "2024-06-06T11:55:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "09", hdop: "0.6", lat: "0045.280988", lon: "12734.614234", latD: "N", lonD: "E", latitude: 0.7546831333333334, longitude: 127.5769039, timestamp: "2024-06-06T11:55:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "09", hdop: "0.6", lat: "0045.280988", lon: "12734.614234", latD: "N", lonD: "E", latitude: 0.7546831333333334, longitude: 127.5769039, timestamp: "2024-06-06T11:55:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.280988", lon: "12734.614238", latD: "N", lonD: "E", latitude: 0.7546831333333334, longitude: 127.57690396666666, timestamp: "2024-06-06T11:56:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.280988", lon: "12734.614238", latD: "N", lonD: "E", latitude: 0.7546831333333334, longitude: 127.57690396666666, timestamp: "2024-06-06T11:56:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "09", hdop: "0.6", lat: "0045.280988", lon: "12734.614234", latD: "N", lonD: "E", latitude: 0.7546831333333334, longitude: 127.5769039, timestamp: "2024-06-06T11:55:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "09", hdop: "0.6", lat: "0045.280988", lon: "12734.614234", latD: "N", lonD: "E", latitude: 0.7546831333333334, longitude: 127.5769039, timestamp: "2024-06-06T11:55:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.280988", lon: "12734.614238", latD: "N", lonD: "E", latitude: 0.7546831333333334, longitude: 127.57690396666666, timestamp: "2024-06-06T11:56:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.280988", lon: "12734.614238", latD: "N", lonD: "E", latitude: 0.7546831333333334, longitude: 127.57690396666666, timestamp: "2024-06-06T11:56:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.280988", lon: "12734.614238", latD: "N", lonD: "E", latitude: 0.7546831333333334, longitude: 127.57690396666666, timestamp: "2024-06-06T11:56:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.280942", lon: "12734.614236", latD: "N", lonD: "E", latitude: 0.7546823666666668, longitude: 127.57690393333333, timestamp: "2024-06-06T11:56:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.280942", lon: "12734.614236", latD: "N", lonD: "E", latitude: 0.7546823666666668, longitude: 127.57690393333333, timestamp: "2024-06-06T11:56:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.280942", lon: "12734.614236", latD: "N", lonD: "E", latitude: 0.7546823666666668, longitude: 127.57690393333333, timestamp: "2024-06-06T11:56:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.280906", lon: "12734.614236", latD: "N", lonD: "E", latitude: 0.7546817666666666, longitude: 127.57690393333333, timestamp: "2024-06-06T11:56:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.280906", lon: "12734.614236", latD: "N", lonD: "E", latitude: 0.7546817666666666, longitude: 127.57690393333333, timestamp: "2024-06-06T11:56:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.280906", lon: "12734.614236", latD: "N", lonD: "E", latitude: 0.7546817666666666, longitude: 127.57690393333333, timestamp: "2024-06-06T11:56:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.280890", lon: "12734.614246", latD: "N", lonD: "E", latitude: 0.7546815, longitude: 127.5769041, timestamp: "2024-06-06T11:57:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.280906", lon: "12734.614236", latD: "N", lonD: "E", latitude: 0.7546817666666666, longitude: 127.57690393333333, timestamp: "2024-06-06T11:56:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.280906", lon: "12734.614236", latD: "N", lonD: "E", latitude: 0.7546817666666666, longitude: 127.57690393333333, timestamp: "2024-06-06T11:56:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.280890", lon: "12734.614246", latD: "N", lonD: "E", latitude: 0.7546815, longitude: 127.5769041, timestamp: "2024-06-06T11:57:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.280720", lon: "12734.614223", latD: "N", lonD: "E", latitude: 0.7546786666666667, longitude: 127.57690371666666, timestamp: "2024-06-06T11:57:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.280720", lon: "12734.614223", latD: "N", lonD: "E", latitude: 0.7546786666666667, longitude: 127.57690371666666, timestamp: "2024-06-06T11:57:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.280890", lon: "12734.614246", latD: "N", lonD: "E", latitude: 0.7546815, longitude: 127.5769041, timestamp: "2024-06-06T11:57:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.280720", lon: "12734.614223", latD: "N", lonD: "E", latitude: 0.7546786666666667, longitude: 127.57690371666666, timestamp: "2024-06-06T11:57:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.280720", lon: "12734.614223", latD: "N", lonD: "E", latitude: 0.7546786666666667, longitude: 127.57690371666666, timestamp: "2024-06-06T11:57:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280562", lon: "12734.614241", latD: "N", lonD: "E", latitude: 0.7546760333333334, longitude: 127.57690401666666, timestamp: "2024-06-06T11:57:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280562", lon: "12734.614241", latD: "N", lonD: "E", latitude: 0.7546760333333334, longitude: 127.57690401666666, timestamp: "2024-06-06T11:57:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280562", lon: "12734.614241", latD: "N", lonD: "E", latitude: 0.7546760333333334, longitude: 127.57690401666666, timestamp: "2024-06-06T11:57:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280526", lon: "12734.614239", latD: "N", lonD: "E", latitude: 0.7546754333333333, longitude: 127.57690398333334, timestamp: "2024-06-06T11:58:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280562", lon: "12734.614241", latD: "N", lonD: "E", latitude: 0.7546760333333334, longitude: 127.57690401666666, timestamp: "2024-06-06T11:57:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280562", lon: "12734.614241", latD: "N", lonD: "E", latitude: 0.7546760333333334, longitude: 127.57690401666666, timestamp: "2024-06-06T11:57:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280526", lon: "12734.614239", latD: "N", lonD: "E", latitude: 0.7546754333333333, longitude: 127.57690398333334, timestamp: "2024-06-06T11:58:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280526", lon: "12734.614239", latD: "N", lonD: "E", latitude: 0.7546754333333333, longitude: 127.57690398333334, timestamp: "2024-06-06T11:58:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280526", lon: "12734.614239", latD: "N", lonD: "E", latitude: 0.7546754333333333, longitude: 127.57690398333334, timestamp: "2024-06-06T11:58:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280507", lon: "12734.614233", latD: "N", lonD: "E", latitude: 0.7546751166666666, longitude: 127.57690388333333, timestamp: "2024-06-06T11:58:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.1", satellites: "10", hdop: "0.6", lat: "0045.280463", lon: "12734.614227", latD: "N", lonD: "E", latitude: 0.7546743833333333, longitude: 127.57690378333334, timestamp: "2024-06-06T11:58:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280507", lon: "12734.614233", latD: "N", lonD: "E", latitude: 0.7546751166666666, longitude: 127.57690388333333, timestamp: "2024-06-06T11:58:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280507", lon: "12734.614233", latD: "N", lonD: "E", latitude: 0.7546751166666666, longitude: 127.57690388333333, timestamp: "2024-06-06T11:58:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280507", lon: "12734.614233", latD: "N", lonD: "E", latitude: 0.7546751166666666, longitude: 127.57690388333333, timestamp: "2024-06-06T11:58:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.1", satellites: "10", hdop: "0.6", lat: "0045.280463", lon: "12734.614227", latD: "N", lonD: "E", latitude: 0.7546743833333333, longitude: 127.57690378333334, timestamp: "2024-06-06T11:58:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280424", lon: "12734.614241", latD: "N", lonD: "E", latitude: 0.7546737333333333, longitude: 127.57690401666666, timestamp: "2024-06-06T11:59:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280424", lon: "12734.614241", latD: "N", lonD: "E", latitude: 0.7546737333333333, longitude: 127.57690401666666, timestamp: "2024-06-06T11:59:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.1", satellites: "10", hdop: "0.6", lat: "0045.280463", lon: "12734.614227", latD: "N", lonD: "E", latitude: 0.7546743833333333, longitude: 127.57690378333334, timestamp: "2024-06-06T11:58:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280424", lon: "12734.614241", latD: "N", lonD: "E", latitude: 0.7546737333333333, longitude: 127.57690401666666, timestamp: "2024-06-06T11:59:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280424", lon: "12734.614241", latD: "N", lonD: "E", latitude: 0.7546737333333333, longitude: 127.57690401666666, timestamp: "2024-06-06T11:59:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280394", lon: "12734.614253", latD: "N", lonD: "E", latitude: 0.7546732333333334, longitude: 127.57690421666666, timestamp: "2024-06-06T11:59:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280394", lon: "12734.614253", latD: "N", lonD: "E", latitude: 0.7546732333333334, longitude: 127.57690421666666, timestamp: "2024-06-06T11:59:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280394", lon: "12734.614253", latD: "N", lonD: "E", latitude: 0.7546732333333334, longitude: 127.57690421666666, timestamp: "2024-06-06T11:59:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280380", lon: "12734.614269", latD: "N", lonD: "E", latitude: 0.754673, longitude: 127.57690448333334, timestamp: "2024-06-06T11:59:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280380", lon: "12734.614269", latD: "N", lonD: "E", latitude: 0.754673, longitude: 127.57690448333334, timestamp: "2024-06-06T11:59:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280394", lon: "12734.614253", latD: "N", lonD: "E", latitude: 0.7546732333333334, longitude: 127.57690421666666, timestamp: "2024-06-06T11:59:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280380", lon: "12734.614269", latD: "N", lonD: "E", latitude: 0.754673, longitude: 127.57690448333334, timestamp: "2024-06-06T11:59:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "13.0", satellites: "10", hdop: "0.6", lat: "0045.280380", lon: "12734.614269", latD: "N", lonD: "E", latitude: 0.754673, longitude: 127.57690448333334, timestamp: "2024-06-06T11:59:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.280401", lon: "12734.614254", latD: "N", lonD: "E", latitude: 0.7546733499999999, longitude: 127.57690423333334, timestamp: "2024-06-06T12:00:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.280401", lon: "12734.614254", latD: "N", lonD: "E", latitude: 0.7546733499999999, longitude: 127.57690423333334, timestamp: "2024-06-06T12:00:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.7", satellites: "10", hdop: "0.6", lat: "0045.280356", lon: "12734.614266", latD: "N", lonD: "E", latitude: 0.7546725999999999, longitude: 127.57690443333334, timestamp: "2024-06-06T12:00:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.280401", lon: "12734.614254", latD: "N", lonD: "E", latitude: 0.7546733499999999, longitude: 127.57690423333334, timestamp: "2024-06-06T12:00:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.280401", lon: "12734.614254", latD: "N", lonD: "E", latitude: 0.7546733499999999, longitude: 127.57690423333334, timestamp: "2024-06-06T12:00:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.280401", lon: "12734.614254", latD: "N", lonD: "E", latitude: 0.7546733499999999, longitude: 127.57690423333334, timestamp: "2024-06-06T12:00:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.7", satellites: "10", hdop: "0.6", lat: "0045.280356", lon: "12734.614266", latD: "N", lonD: "E", latitude: 0.7546725999999999, longitude: 127.57690443333334, timestamp: "2024-06-06T12:00:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.7", satellites: "10", hdop: "0.6", lat: "0045.280356", lon: "12734.614266", latD: "N", lonD: "E", latitude: 0.7546725999999999, longitude: 127.57690443333334, timestamp: "2024-06-06T12:00:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.7", satellites: "10", hdop: "0.6", lat: "0045.280356", lon: "12734.614266", latD: "N", lonD: "E", latitude: 0.7546725999999999, longitude: 127.57690443333334, timestamp: "2024-06-06T12:00:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.5", lat: "0045.280371", lon: "12734.614322", latD: "N", lonD: "E", latitude: 0.7546728500000001, longitude: 127.57690536666667, timestamp: "2024-06-06T12:00:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.5", lat: "0045.280371", lon: "12734.614322", latD: "N", lonD: "E", latitude: 0.7546728500000001, longitude: 127.57690536666667, timestamp: "2024-06-06T12:00:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.5", lat: "0045.280371", lon: "12734.614322", latD: "N", lonD: "E", latitude: 0.7546728500000001, longitude: 127.57690536666667, timestamp: "2024-06-06T12:00:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.3", satellites: "10", hdop: "0.5", lat: "0045.280371", lon: "12734.614322", latD: "N", lonD: "E", latitude: 0.7546728500000001, longitude: 127.57690536666667, timestamp: "2024-06-06T12:00:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.1", satellites: "08", hdop: "0.7", lat: "0045.280385", lon: "12734.614368", latD: "N", lonD: "E", latitude: 0.7546730833333334, longitude: 127.57690613333334, timestamp: "2024-06-06T12:01:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.1", satellites: "08", hdop: "0.7", lat: "0045.280385", lon: "12734.614368", latD: "N", lonD: "E", latitude: 0.7546730833333334, longitude: 127.57690613333334, timestamp: "2024-06-06T12:01:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.1", satellites: "08", hdop: "0.7", lat: "0045.280385", lon: "12734.614368", latD: "N", lonD: "E", latitude: 0.7546730833333334, longitude: 127.57690613333334, timestamp: "2024-06-06T12:01:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.1", satellites: "08", hdop: "0.7", lat: "0045.280385", lon: "12734.614368", latD: "N", lonD: "E", latitude: 0.7546730833333334, longitude: 127.57690613333334, timestamp: "2024-06-06T12:01:08.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.280339", lon: "12734.614368", latD: "N", lonD: "E", latitude: 0.7546723166666667, longitude: 127.57690613333334, timestamp: "2024-06-06T12:01:28.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279623", lon: "12734.615663", latD: "N", lonD: "E", latitude: 0.7546603833333333, longitude: 127.57692771666666, timestamp: "2024-06-06T12:01:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279623", lon: "12734.615663", latD: "N", lonD: "E", latitude: 0.7546603833333333, longitude: 127.57692771666666, timestamp: "2024-06-06T12:01:48.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.280339", lon: "12734.614368", latD: "N", lonD: "E", latitude: 0.7546723166666667, longitude: 127.57690613333334, timestamp: "2024-06-06T12:01:28.000Z" },
        { speed: "0.0", course: "136.7", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.280339", lon: "12734.614368", latD: "N", lonD: "E", latitude: 0.7546723166666667, longitude: 127.57690613333334, timestamp: "2024-06-06T12:01:28.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279623", lon: "12734.615663", latD: "N", lonD: "E", latitude: 0.7546603833333333, longitude: 127.57692771666666, timestamp: "2024-06-06T12:01:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279623", lon: "12734.615663", latD: "N", lonD: "E", latitude: 0.7546603833333333, longitude: 127.57692771666666, timestamp: "2024-06-06T12:01:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279579", lon: "12734.615660", latD: "N", lonD: "E", latitude: 0.75465965, longitude: 127.57692766666666, timestamp: "2024-06-06T12:02:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279579", lon: "12734.615660", latD: "N", lonD: "E", latitude: 0.75465965, longitude: 127.57692766666666, timestamp: "2024-06-06T12:02:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279727", lon: "12734.615743", latD: "N", lonD: "E", latitude: 0.7546621166666667, longitude: 127.57692905, timestamp: "2024-06-06T12:02:28.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279727", lon: "12734.615743", latD: "N", lonD: "E", latitude: 0.7546621166666667, longitude: 127.57692905, timestamp: "2024-06-06T12:02:28.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279579", lon: "12734.615660", latD: "N", lonD: "E", latitude: 0.75465965, longitude: 127.57692766666666, timestamp: "2024-06-06T12:02:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279579", lon: "12734.615660", latD: "N", lonD: "E", latitude: 0.75465965, longitude: 127.57692766666666, timestamp: "2024-06-06T12:02:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279727", lon: "12734.615743", latD: "N", lonD: "E", latitude: 0.7546621166666667, longitude: 127.57692905, timestamp: "2024-06-06T12:02:28.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279727", lon: "12734.615743", latD: "N", lonD: "E", latitude: 0.7546621166666667, longitude: 127.57692905, timestamp: "2024-06-06T12:02:28.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279727", lon: "12734.615743", latD: "N", lonD: "E", latitude: 0.7546621166666667, longitude: 127.57692905, timestamp: "2024-06-06T12:02:28.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.279731", lon: "12734.615744", latD: "N", lonD: "E", latitude: 0.7546621833333333, longitude: 127.57692906666666, timestamp: "2024-06-06T12:02:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.279731", lon: "12734.615744", latD: "N", lonD: "E", latitude: 0.7546621833333333, longitude: 127.57692906666666, timestamp: "2024-06-06T12:02:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.279731", lon: "12734.615744", latD: "N", lonD: "E", latitude: 0.7546621833333333, longitude: 127.57692906666666, timestamp: "2024-06-06T12:02:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279672", lon: "12734.615751", latD: "N", lonD: "E", latitude: 0.7546611999999999, longitude: 127.57692918333333, timestamp: "2024-06-06T12:03:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279672", lon: "12734.615751", latD: "N", lonD: "E", latitude: 0.7546611999999999, longitude: 127.57692918333333, timestamp: "2024-06-06T12:03:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279672", lon: "12734.615751", latD: "N", lonD: "E", latitude: 0.7546611999999999, longitude: 127.57692918333333, timestamp: "2024-06-06T12:03:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.279625", lon: "12734.615762", latD: "N", lonD: "E", latitude: 0.7546604166666667, longitude: 127.57692936666666, timestamp: "2024-06-06T12:03:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.279625", lon: "12734.615762", latD: "N", lonD: "E", latitude: 0.7546604166666667, longitude: 127.57692936666666, timestamp: "2024-06-06T12:03:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.279625", lon: "12734.615762", latD: "N", lonD: "E", latitude: 0.7546604166666667, longitude: 127.57692936666666, timestamp: "2024-06-06T12:03:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.279625", lon: "12734.615762", latD: "N", lonD: "E", latitude: 0.7546604166666667, longitude: 127.57692936666666, timestamp: "2024-06-06T12:03:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.279625", lon: "12734.615762", latD: "N", lonD: "E", latitude: 0.7546604166666667, longitude: 127.57692936666666, timestamp: "2024-06-06T12:03:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.279625", lon: "12734.615762", latD: "N", lonD: "E", latitude: 0.7546604166666667, longitude: 127.57692936666666, timestamp: "2024-06-06T12:03:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.279537", lon: "12734.615779", latD: "N", lonD: "E", latitude: 0.7546589499999999, longitude: 127.57692965, timestamp: "2024-06-06T12:03:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.279537", lon: "12734.615779", latD: "N", lonD: "E", latitude: 0.7546589499999999, longitude: 127.57692965, timestamp: "2024-06-06T12:03:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.279537", lon: "12734.615779", latD: "N", lonD: "E", latitude: 0.7546589499999999, longitude: 127.57692965, timestamp: "2024-06-06T12:03:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.279485", lon: "12734.615796", latD: "N", lonD: "E", latitude: 0.7546580833333334, longitude: 127.57692993333333, timestamp: "2024-06-06T12:04:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.279485", lon: "12734.615796", latD: "N", lonD: "E", latitude: 0.7546580833333334, longitude: 127.57692993333333, timestamp: "2024-06-06T12:04:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.279485", lon: "12734.615796", latD: "N", lonD: "E", latitude: 0.7546580833333334, longitude: 127.57692993333333, timestamp: "2024-06-06T12:04:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.279485", lon: "12734.615796", latD: "N", lonD: "E", latitude: 0.7546580833333334, longitude: 127.57692993333333, timestamp: "2024-06-06T12:04:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.279485", lon: "12734.615796", latD: "N", lonD: "E", latitude: 0.7546580833333334, longitude: 127.57692993333333, timestamp: "2024-06-06T12:04:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.279493", lon: "12734.615806", latD: "N", lonD: "E", latitude: 0.7546582166666667, longitude: 127.5769301, timestamp: "2024-06-06T12:04:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.279493", lon: "12734.615806", latD: "N", lonD: "E", latitude: 0.7546582166666667, longitude: 127.5769301, timestamp: "2024-06-06T12:04:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.279493", lon: "12734.615806", latD: "N", lonD: "E", latitude: 0.7546582166666667, longitude: 127.5769301, timestamp: "2024-06-06T12:04:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279525", lon: "12734.615808", latD: "N", lonD: "E", latitude: 0.75465875, longitude: 127.57693013333333, timestamp: "2024-06-06T12:04:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279525", lon: "12734.615808", latD: "N", lonD: "E", latitude: 0.75465875, longitude: 127.57693013333333, timestamp: "2024-06-06T12:04:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279525", lon: "12734.615808", latD: "N", lonD: "E", latitude: 0.75465875, longitude: 127.57693013333333, timestamp: "2024-06-06T12:04:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279516", lon: "12734.615818", latD: "N", lonD: "E", latitude: 0.7546586000000001, longitude: 127.5769303, timestamp: "2024-06-06T12:05:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279525", lon: "12734.615808", latD: "N", lonD: "E", latitude: 0.75465875, longitude: 127.57693013333333, timestamp: "2024-06-06T12:04:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279525", lon: "12734.615808", latD: "N", lonD: "E", latitude: 0.75465875, longitude: 127.57693013333333, timestamp: "2024-06-06T12:04:48.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279516", lon: "12734.615818", latD: "N", lonD: "E", latitude: 0.7546586000000001, longitude: 127.5769303, timestamp: "2024-06-06T12:05:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279516", lon: "12734.615818", latD: "N", lonD: "E", latitude: 0.7546586000000001, longitude: 127.5769303, timestamp: "2024-06-06T12:05:08.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.279511", lon: "12734.615816", latD: "N", lonD: "E", latitude: 0.7546585166666666, longitude: 127.57693026666666, timestamp: "2024-06-06T12:05:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.279511", lon: "12734.615816", latD: "N", lonD: "E", latitude: 0.7546585166666666, longitude: 127.57693026666666, timestamp: "2024-06-06T12:05:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.279511", lon: "12734.615816", latD: "N", lonD: "E", latitude: 0.7546585166666666, longitude: 127.57693026666666, timestamp: "2024-06-06T12:05:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "09", hdop: "0.6", lat: "0045.279528", lon: "12734.615822", latD: "N", lonD: "E", latitude: 0.7546588, longitude: 127.57693036666667, timestamp: "2024-06-06T12:05:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "09", hdop: "0.6", lat: "0045.279528", lon: "12734.615822", latD: "N", lonD: "E", latitude: 0.7546588, longitude: 127.57693036666667, timestamp: "2024-06-06T12:05:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "09", hdop: "0.6", lat: "0045.279528", lon: "12734.615822", latD: "N", lonD: "E", latitude: 0.7546588, longitude: 127.57693036666667, timestamp: "2024-06-06T12:05:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "09", hdop: "0.6", lat: "0045.279528", lon: "12734.615822", latD: "N", lonD: "E", latitude: 0.7546588, longitude: 127.57693036666667, timestamp: "2024-06-06T12:05:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "09", hdop: "0.6", lat: "0045.279528", lon: "12734.615822", latD: "N", lonD: "E", latitude: 0.7546588, longitude: 127.57693036666667, timestamp: "2024-06-06T12:05:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "09", hdop: "0.6", lat: "0045.279475", lon: "12734.615821", latD: "N", lonD: "E", latitude: 0.7546579166666666, longitude: 127.57693035, timestamp: "2024-06-06T12:06:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "09", hdop: "0.6", lat: "0045.279475", lon: "12734.615821", latD: "N", lonD: "E", latitude: 0.7546579166666666, longitude: 127.57693035, timestamp: "2024-06-06T12:06:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "09", hdop: "0.7", lat: "0045.279425", lon: "12734.615806", latD: "N", lonD: "E", latitude: 0.7546570833333334, longitude: 127.5769301, timestamp: "2024-06-06T12:06:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "09", hdop: "0.6", lat: "0045.279475", lon: "12734.615821", latD: "N", lonD: "E", latitude: 0.7546579166666666, longitude: 127.57693035, timestamp: "2024-06-06T12:06:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "09", hdop: "0.6", lat: "0045.279475", lon: "12734.615821", latD: "N", lonD: "E", latitude: 0.7546579166666666, longitude: 127.57693035, timestamp: "2024-06-06T12:06:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "09", hdop: "0.7", lat: "0045.279425", lon: "12734.615806", latD: "N", lonD: "E", latitude: 0.7546570833333334, longitude: 127.5769301, timestamp: "2024-06-06T12:06:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "09", hdop: "0.7", lat: "0045.279425", lon: "12734.615806", latD: "N", lonD: "E", latitude: 0.7546570833333334, longitude: 127.5769301, timestamp: "2024-06-06T12:06:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "09", hdop: "0.7", lat: "0045.279425", lon: "12734.615806", latD: "N", lonD: "E", latitude: 0.7546570833333334, longitude: 127.5769301, timestamp: "2024-06-06T12:06:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "09", hdop: "0.7", lat: "0045.279425", lon: "12734.615806", latD: "N", lonD: "E", latitude: 0.7546570833333334, longitude: 127.5769301, timestamp: "2024-06-06T12:06:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.279447", lon: "12734.615805", latD: "N", lonD: "E", latitude: 0.75465745, longitude: 127.57693008333334, timestamp: "2024-06-06T12:06:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.279447", lon: "12734.615805", latD: "N", lonD: "E", latitude: 0.75465745, longitude: 127.57693008333334, timestamp: "2024-06-06T12:06:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.279447", lon: "12734.615805", latD: "N", lonD: "E", latitude: 0.75465745, longitude: 127.57693008333334, timestamp: "2024-06-06T12:06:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.279447", lon: "12734.615805", latD: "N", lonD: "E", latitude: 0.75465745, longitude: 127.57693008333334, timestamp: "2024-06-06T12:06:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279438", lon: "12734.615793", latD: "N", lonD: "E", latitude: 0.7546573, longitude: 127.57692988333334, timestamp: "2024-06-06T12:07:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279438", lon: "12734.615793", latD: "N", lonD: "E", latitude: 0.7546573, longitude: 127.57692988333334, timestamp: "2024-06-06T12:07:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279431", lon: "12734.615764", latD: "N", lonD: "E", latitude: 0.7546571833333334, longitude: 127.5769294, timestamp: "2024-06-06T12:07:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279431", lon: "12734.615764", latD: "N", lonD: "E", latitude: 0.7546571833333334, longitude: 127.5769294, timestamp: "2024-06-06T12:07:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279438", lon: "12734.615793", latD: "N", lonD: "E", latitude: 0.7546573, longitude: 127.57692988333334, timestamp: "2024-06-06T12:07:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279431", lon: "12734.615764", latD: "N", lonD: "E", latitude: 0.7546571833333334, longitude: 127.5769294, timestamp: "2024-06-06T12:07:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279431", lon: "12734.615764", latD: "N", lonD: "E", latitude: 0.7546571833333334, longitude: 127.5769294, timestamp: "2024-06-06T12:07:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279420", lon: "12734.615736", latD: "N", lonD: "E", latitude: 0.754657, longitude: 127.57692893333333, timestamp: "2024-06-06T12:07:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279420", lon: "12734.615736", latD: "N", lonD: "E", latitude: 0.754657, longitude: 127.57692893333333, timestamp: "2024-06-06T12:07:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279420", lon: "12734.615736", latD: "N", lonD: "E", latitude: 0.754657, longitude: 127.57692893333333, timestamp: "2024-06-06T12:07:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.279394", lon: "12734.615725", latD: "N", lonD: "E", latitude: 0.7546565666666667, longitude: 127.57692875, timestamp: "2024-06-06T12:08:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279420", lon: "12734.615736", latD: "N", lonD: "E", latitude: 0.754657, longitude: 127.57692893333333, timestamp: "2024-06-06T12:07:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "10", hdop: "0.6", lat: "0045.279420", lon: "12734.615736", latD: "N", lonD: "E", latitude: 0.754657, longitude: 127.57692893333333, timestamp: "2024-06-06T12:07:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.279394", lon: "12734.615725", latD: "N", lonD: "E", latitude: 0.7546565666666667, longitude: 127.57692875, timestamp: "2024-06-06T12:08:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279319", lon: "12734.615733", latD: "N", lonD: "E", latitude: 0.7546553166666666, longitude: 127.57692888333334, timestamp: "2024-06-06T12:08:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279319", lon: "12734.615733", latD: "N", lonD: "E", latitude: 0.7546553166666666, longitude: 127.57692888333334, timestamp: "2024-06-06T12:08:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279319", lon: "12734.615733", latD: "N", lonD: "E", latitude: 0.7546553166666666, longitude: 127.57692888333334, timestamp: "2024-06-06T12:08:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279319", lon: "12734.615733", latD: "N", lonD: "E", latitude: 0.7546553166666666, longitude: 127.57692888333334, timestamp: "2024-06-06T12:08:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279307", lon: "12734.615726", latD: "N", lonD: "E", latitude: 0.7546551166666667, longitude: 127.57692876666667, timestamp: "2024-06-06T12:08:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279307", lon: "12734.615726", latD: "N", lonD: "E", latitude: 0.7546551166666667, longitude: 127.57692876666667, timestamp: "2024-06-06T12:08:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279307", lon: "12734.615726", latD: "N", lonD: "E", latitude: 0.7546551166666667, longitude: 127.57692876666667, timestamp: "2024-06-06T12:08:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279307", lon: "12734.615726", latD: "N", lonD: "E", latitude: 0.7546551166666667, longitude: 127.57692876666667, timestamp: "2024-06-06T12:08:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279307", lon: "12734.615726", latD: "N", lonD: "E", latitude: 0.7546551166666667, longitude: 127.57692876666667, timestamp: "2024-06-06T12:08:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.2", satellites: "10", hdop: "0.6", lat: "0045.279307", lon: "12734.615726", latD: "N", lonD: "E", latitude: 0.7546551166666667, longitude: 127.57692876666667, timestamp: "2024-06-06T12:08:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "09", hdop: "0.6", lat: "0045.279303", lon: "12734.615759", latD: "N", lonD: "E", latitude: 0.75465505, longitude: 127.57692931666666, timestamp: "2024-06-06T12:09:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.279303", lon: "12734.615746", latD: "N", lonD: "E", latitude: 0.75465505, longitude: 127.5769291, timestamp: "2024-06-06T12:09:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "09", hdop: "0.6", lat: "0045.279303", lon: "12734.615759", latD: "N", lonD: "E", latitude: 0.75465505, longitude: 127.57692931666666, timestamp: "2024-06-06T12:09:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "09", hdop: "0.6", lat: "0045.279303", lon: "12734.615759", latD: "N", lonD: "E", latitude: 0.75465505, longitude: 127.57692931666666, timestamp: "2024-06-06T12:09:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.9", satellites: "09", hdop: "0.6", lat: "0045.279303", lon: "12734.615759", latD: "N", lonD: "E", latitude: 0.75465505, longitude: 127.57692931666666, timestamp: "2024-06-06T12:09:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.279303", lon: "12734.615746", latD: "N", lonD: "E", latitude: 0.75465505, longitude: 127.5769291, timestamp: "2024-06-06T12:09:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.279303", lon: "12734.615746", latD: "N", lonD: "E", latitude: 0.75465505, longitude: 127.5769291, timestamp: "2024-06-06T12:09:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.279290", lon: "12734.615742", latD: "N", lonD: "E", latitude: 0.7546548333333334, longitude: 127.57692903333333, timestamp: "2024-06-06T12:09:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.279303", lon: "12734.615746", latD: "N", lonD: "E", latitude: 0.75465505, longitude: 127.5769291, timestamp: "2024-06-06T12:09:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.279290", lon: "12734.615742", latD: "N", lonD: "E", latitude: 0.7546548333333334, longitude: 127.57692903333333, timestamp: "2024-06-06T12:09:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.279290", lon: "12734.615742", latD: "N", lonD: "E", latitude: 0.7546548333333334, longitude: 127.57692903333333, timestamp: "2024-06-06T12:09:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.279246", lon: "12734.615717", latD: "N", lonD: "E", latitude: 0.7546541, longitude: 127.57692861666666, timestamp: "2024-06-06T12:10:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.279246", lon: "12734.615717", latD: "N", lonD: "E", latitude: 0.7546541, longitude: 127.57692861666666, timestamp: "2024-06-06T12:10:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.279246", lon: "12734.615717", latD: "N", lonD: "E", latitude: 0.7546541, longitude: 127.57692861666666, timestamp: "2024-06-06T12:10:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.279246", lon: "12734.615717", latD: "N", lonD: "E", latitude: 0.7546541, longitude: 127.57692861666666, timestamp: "2024-06-06T12:10:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.279246", lon: "12734.615717", latD: "N", lonD: "E", latitude: 0.7546541, longitude: 127.57692861666666, timestamp: "2024-06-06T12:10:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.279225", lon: "12734.615703", latD: "N", lonD: "E", latitude: 0.7546537499999999, longitude: 127.57692838333334, timestamp: "2024-06-06T12:10:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.279225", lon: "12734.615703", latD: "N", lonD: "E", latitude: 0.7546537499999999, longitude: 127.57692838333334, timestamp: "2024-06-06T12:10:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.279166", lon: "12734.615698", latD: "N", lonD: "E", latitude: 0.7546527666666666, longitude: 127.5769283, timestamp: "2024-06-06T12:10:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.279166", lon: "12734.615698", latD: "N", lonD: "E", latitude: 0.7546527666666666, longitude: 127.5769283, timestamp: "2024-06-06T12:10:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.279225", lon: "12734.615703", latD: "N", lonD: "E", latitude: 0.7546537499999999, longitude: 127.57692838333334, timestamp: "2024-06-06T12:10:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.279166", lon: "12734.615698", latD: "N", lonD: "E", latitude: 0.7546527666666666, longitude: 127.5769283, timestamp: "2024-06-06T12:10:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.279166", lon: "12734.615698", latD: "N", lonD: "E", latitude: 0.7546527666666666, longitude: 127.5769283, timestamp: "2024-06-06T12:10:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.1", satellites: "09", hdop: "0.6", lat: "0045.279166", lon: "12734.615698", latD: "N", lonD: "E", latitude: 0.7546527666666666, longitude: 127.5769283, timestamp: "2024-06-06T12:10:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.279148", lon: "12734.615690", latD: "N", lonD: "E", latitude: 0.7546524666666666, longitude: 127.57692816666666, timestamp: "2024-06-06T12:11:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.279148", lon: "12734.615690", latD: "N", lonD: "E", latitude: 0.7546524666666666, longitude: 127.57692816666666, timestamp: "2024-06-06T12:11:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279100", lon: "12734.615669", latD: "N", lonD: "E", latitude: 0.7546516666666666, longitude: 127.57692781666667, timestamp: "2024-06-06T12:11:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279100", lon: "12734.615669", latD: "N", lonD: "E", latitude: 0.7546516666666666, longitude: 127.57692781666667, timestamp: "2024-06-06T12:11:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.3", satellites: "09", hdop: "0.6", lat: "0045.279148", lon: "12734.615690", latD: "N", lonD: "E", latitude: 0.7546524666666666, longitude: 127.57692816666666, timestamp: "2024-06-06T12:11:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279100", lon: "12734.615669", latD: "N", lonD: "E", latitude: 0.7546516666666666, longitude: 127.57692781666667, timestamp: "2024-06-06T12:11:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.279100", lon: "12734.615669", latD: "N", lonD: "E", latitude: 0.7546516666666666, longitude: 127.57692781666667, timestamp: "2024-06-06T12:11:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.279017", lon: "12734.615638", latD: "N", lonD: "E", latitude: 0.7546502833333334, longitude: 127.5769273, timestamp: "2024-06-06T12:11:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.279017", lon: "12734.615638", latD: "N", lonD: "E", latitude: 0.7546502833333334, longitude: 127.5769273, timestamp: "2024-06-06T12:11:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.279017", lon: "12734.615638", latD: "N", lonD: "E", latitude: 0.7546502833333334, longitude: 127.5769273, timestamp: "2024-06-06T12:11:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.279017", lon: "12734.615638", latD: "N", lonD: "E", latitude: 0.7546502833333334, longitude: 127.5769273, timestamp: "2024-06-06T12:11:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.279017", lon: "12734.615638", latD: "N", lonD: "E", latitude: 0.7546502833333334, longitude: 127.5769273, timestamp: "2024-06-06T12:11:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278991", lon: "12734.615614", latD: "N", lonD: "E", latitude: 0.75464985, longitude: 127.5769269, timestamp: "2024-06-06T12:12:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278991", lon: "12734.615614", latD: "N", lonD: "E", latitude: 0.75464985, longitude: 127.5769269, timestamp: "2024-06-06T12:12:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278991", lon: "12734.615614", latD: "N", lonD: "E", latitude: 0.75464985, longitude: 127.5769269, timestamp: "2024-06-06T12:12:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278991", lon: "12734.615614", latD: "N", lonD: "E", latitude: 0.75464985, longitude: 127.5769269, timestamp: "2024-06-06T12:12:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.5", lat: "0045.278949", lon: "12734.615600", latD: "N", lonD: "E", latitude: 0.75464915, longitude: 127.57692666666667, timestamp: "2024-06-06T12:12:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.5", lat: "0045.278872", lon: "12734.615595", latD: "N", lonD: "E", latitude: 0.7546478666666666, longitude: 127.57692658333333, timestamp: "2024-06-06T12:12:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.5", lat: "0045.278949", lon: "12734.615600", latD: "N", lonD: "E", latitude: 0.75464915, longitude: 127.57692666666667, timestamp: "2024-06-06T12:12:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.5", lat: "0045.278949", lon: "12734.615600", latD: "N", lonD: "E", latitude: 0.75464915, longitude: 127.57692666666667, timestamp: "2024-06-06T12:12:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.5", lat: "0045.278949", lon: "12734.615600", latD: "N", lonD: "E", latitude: 0.75464915, longitude: 127.57692666666667, timestamp: "2024-06-06T12:12:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.5", lat: "0045.278872", lon: "12734.615595", latD: "N", lonD: "E", latitude: 0.7546478666666666, longitude: 127.57692658333333, timestamp: "2024-06-06T12:12:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.5", lat: "0045.278872", lon: "12734.615595", latD: "N", lonD: "E", latitude: 0.7546478666666666, longitude: 127.57692658333333, timestamp: "2024-06-06T12:12:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.5", lat: "0045.278872", lon: "12734.615595", latD: "N", lonD: "E", latitude: 0.7546478666666666, longitude: 127.57692658333333, timestamp: "2024-06-06T12:12:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.278872", lon: "12734.615581", latD: "N", lonD: "E", latitude: 0.7546478666666666, longitude: 127.57692635, timestamp: "2024-06-06T12:13:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.278872", lon: "12734.615581", latD: "N", lonD: "E", latitude: 0.7546478666666666, longitude: 127.57692635, timestamp: "2024-06-06T12:13:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278860", lon: "12734.615554", latD: "N", lonD: "E", latitude: 0.7546476666666667, longitude: 127.5769259, timestamp: "2024-06-06T12:13:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278860", lon: "12734.615554", latD: "N", lonD: "E", latitude: 0.7546476666666667, longitude: 127.5769259, timestamp: "2024-06-06T12:13:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278860", lon: "12734.615554", latD: "N", lonD: "E", latitude: 0.7546476666666667, longitude: 127.5769259, timestamp: "2024-06-06T12:13:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278860", lon: "12734.615554", latD: "N", lonD: "E", latitude: 0.7546476666666667, longitude: 127.5769259, timestamp: "2024-06-06T12:13:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.5", lat: "0045.278859", lon: "12734.615530", latD: "N", lonD: "E", latitude: 0.75464765, longitude: 127.5769255, timestamp: "2024-06-06T12:13:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278860", lon: "12734.615554", latD: "N", lonD: "E", latitude: 0.7546476666666667, longitude: 127.5769259, timestamp: "2024-06-06T12:13:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278860", lon: "12734.615554", latD: "N", lonD: "E", latitude: 0.7546476666666667, longitude: 127.5769259, timestamp: "2024-06-06T12:13:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.5", lat: "0045.278859", lon: "12734.615530", latD: "N", lonD: "E", latitude: 0.75464765, longitude: 127.5769255, timestamp: "2024-06-06T12:13:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.5", lat: "0045.278859", lon: "12734.615530", latD: "N", lonD: "E", latitude: 0.75464765, longitude: 127.5769255, timestamp: "2024-06-06T12:13:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.5", lat: "0045.278844", lon: "12734.615493", latD: "N", lonD: "E", latitude: 0.7546474, longitude: 127.57692488333333, timestamp: "2024-06-06T12:14:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.5", lat: "0045.278844", lon: "12734.615493", latD: "N", lonD: "E", latitude: 0.7546474, longitude: 127.57692488333333, timestamp: "2024-06-06T12:14:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.5", lat: "0045.278844", lon: "12734.615493", latD: "N", lonD: "E", latitude: 0.7546474, longitude: 127.57692488333333, timestamp: "2024-06-06T12:14:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278812", lon: "12734.615455", latD: "N", lonD: "E", latitude: 0.7546468666666667, longitude: 127.57692425, timestamp: "2024-06-06T12:14:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.5", lat: "0045.278844", lon: "12734.615493", latD: "N", lonD: "E", latitude: 0.7546474, longitude: 127.57692488333333, timestamp: "2024-06-06T12:14:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.5", lat: "0045.278844", lon: "12734.615493", latD: "N", lonD: "E", latitude: 0.7546474, longitude: 127.57692488333333, timestamp: "2024-06-06T12:14:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278812", lon: "12734.615455", latD: "N", lonD: "E", latitude: 0.7546468666666667, longitude: 127.57692425, timestamp: "2024-06-06T12:14:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.6", satellites: "10", hdop: "0.6", lat: "0045.278812", lon: "12734.615455", latD: "N", lonD: "E", latitude: 0.7546468666666667, longitude: 127.57692425, timestamp: "2024-06-06T12:14:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.278786", lon: "12734.615410", latD: "N", lonD: "E", latitude: 0.7546464333333333, longitude: 127.5769235, timestamp: "2024-06-06T12:14:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.278786", lon: "12734.615410", latD: "N", lonD: "E", latitude: 0.7546464333333333, longitude: 127.5769235, timestamp: "2024-06-06T12:14:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.278786", lon: "12734.615410", latD: "N", lonD: "E", latitude: 0.7546464333333333, longitude: 127.5769235, timestamp: "2024-06-06T12:14:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.278786", lon: "12734.615410", latD: "N", lonD: "E", latitude: 0.7546464333333333, longitude: 127.5769235, timestamp: "2024-06-06T12:14:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.7", satellites: "10", hdop: "0.6", lat: "0045.278786", lon: "12734.615410", latD: "N", lonD: "E", latitude: 0.7546464333333333, longitude: 127.5769235, timestamp: "2024-06-06T12:14:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.278809", lon: "12734.615384", latD: "N", lonD: "E", latitude: 0.7546468166666667, longitude: 127.57692306666667, timestamp: "2024-06-06T12:15:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.278809", lon: "12734.615384", latD: "N", lonD: "E", latitude: 0.7546468166666667, longitude: 127.57692306666667, timestamp: "2024-06-06T12:15:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.278809", lon: "12734.615384", latD: "N", lonD: "E", latitude: 0.7546468166666667, longitude: 127.57692306666667, timestamp: "2024-06-06T12:15:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.278809", lon: "12734.615384", latD: "N", lonD: "E", latitude: 0.7546468166666667, longitude: 127.57692306666667, timestamp: "2024-06-06T12:15:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.278819", lon: "12734.615380", latD: "N", lonD: "E", latitude: 0.7546469833333334, longitude: 127.576923, timestamp: "2024-06-06T12:15:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.0", satellites: "10", hdop: "0.6", lat: "0045.278760", lon: "12734.615365", latD: "N", lonD: "E", latitude: 0.7546459999999999, longitude: 127.57692275, timestamp: "2024-06-06T12:15:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.278819", lon: "12734.615380", latD: "N", lonD: "E", latitude: 0.7546469833333334, longitude: 127.576923, timestamp: "2024-06-06T12:15:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.278819", lon: "12734.615380", latD: "N", lonD: "E", latitude: 0.7546469833333334, longitude: 127.576923, timestamp: "2024-06-06T12:15:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.0", satellites: "10", hdop: "0.6", lat: "0045.278760", lon: "12734.615365", latD: "N", lonD: "E", latitude: 0.7546459999999999, longitude: 127.57692275, timestamp: "2024-06-06T12:15:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.0", satellites: "10", hdop: "0.6", lat: "0045.278760", lon: "12734.615365", latD: "N", lonD: "E", latitude: 0.7546459999999999, longitude: 127.57692275, timestamp: "2024-06-06T12:15:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.0", satellites: "10", hdop: "0.6", lat: "0045.278760", lon: "12734.615365", latD: "N", lonD: "E", latitude: 0.7546459999999999, longitude: 127.57692275, timestamp: "2024-06-06T12:15:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.1", satellites: "10", hdop: "0.6", lat: "0045.278709", lon: "12734.615353", latD: "N", lonD: "E", latitude: 0.75464515, longitude: 127.57692255, timestamp: "2024-06-06T12:16:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.1", satellites: "10", hdop: "0.6", lat: "0045.278709", lon: "12734.615353", latD: "N", lonD: "E", latitude: 0.75464515, longitude: 127.57692255, timestamp: "2024-06-06T12:16:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.1", satellites: "10", hdop: "0.6", lat: "0045.278709", lon: "12734.615353", latD: "N", lonD: "E", latitude: 0.75464515, longitude: 127.57692255, timestamp: "2024-06-06T12:16:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.1", satellites: "10", hdop: "0.6", lat: "0045.278709", lon: "12734.615353", latD: "N", lonD: "E", latitude: 0.75464515, longitude: 127.57692255, timestamp: "2024-06-06T12:16:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.1", satellites: "10", hdop: "0.6", lat: "0045.278709", lon: "12734.615353", latD: "N", lonD: "E", latitude: 0.75464515, longitude: 127.57692255, timestamp: "2024-06-06T12:16:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.278662", lon: "12734.615348", latD: "N", lonD: "E", latitude: 0.7546443666666666, longitude: 127.57692246666667, timestamp: "2024-06-06T12:16:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.278662", lon: "12734.615348", latD: "N", lonD: "E", latitude: 0.7546443666666666, longitude: 127.57692246666667, timestamp: "2024-06-06T12:16:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.278620", lon: "12734.615356", latD: "N", lonD: "E", latitude: 0.7546436666666666, longitude: 127.5769226, timestamp: "2024-06-06T12:16:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.278662", lon: "12734.615348", latD: "N", lonD: "E", latitude: 0.7546443666666666, longitude: 127.57692246666667, timestamp: "2024-06-06T12:16:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.278662", lon: "12734.615348", latD: "N", lonD: "E", latitude: 0.7546443666666666, longitude: 127.57692246666667, timestamp: "2024-06-06T12:16:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.278620", lon: "12734.615356", latD: "N", lonD: "E", latitude: 0.7546436666666666, longitude: 127.5769226, timestamp: "2024-06-06T12:16:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.278620", lon: "12734.615356", latD: "N", lonD: "E", latitude: 0.7546436666666666, longitude: 127.5769226, timestamp: "2024-06-06T12:16:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.278602", lon: "12734.615356", latD: "N", lonD: "E", latitude: 0.7546433666666666, longitude: 127.5769226, timestamp: "2024-06-06T12:17:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.278602", lon: "12734.615356", latD: "N", lonD: "E", latitude: 0.7546433666666666, longitude: 127.5769226, timestamp: "2024-06-06T12:17:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.278602", lon: "12734.615356", latD: "N", lonD: "E", latitude: 0.7546433666666666, longitude: 127.5769226, timestamp: "2024-06-06T12:17:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.278564", lon: "12734.615352", latD: "N", lonD: "E", latitude: 0.7546427333333334, longitude: 127.57692253333333, timestamp: "2024-06-06T12:17:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.278564", lon: "12734.615352", latD: "N", lonD: "E", latitude: 0.7546427333333334, longitude: 127.57692253333333, timestamp: "2024-06-06T12:17:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.3", satellites: "10", hdop: "0.6", lat: "0045.278602", lon: "12734.615356", latD: "N", lonD: "E", latitude: 0.7546433666666666, longitude: 127.5769226, timestamp: "2024-06-06T12:17:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.278564", lon: "12734.615352", latD: "N", lonD: "E", latitude: 0.7546427333333334, longitude: 127.57692253333333, timestamp: "2024-06-06T12:17:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.278564", lon: "12734.615352", latD: "N", lonD: "E", latitude: 0.7546427333333334, longitude: 127.57692253333333, timestamp: "2024-06-06T12:17:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.278564", lon: "12734.615352", latD: "N", lonD: "E", latitude: 0.7546427333333334, longitude: 127.57692253333333, timestamp: "2024-06-06T12:17:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.278532", lon: "12734.615366", latD: "N", lonD: "E", latitude: 0.7546421999999999, longitude: 127.57692276666667, timestamp: "2024-06-06T12:17:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.278532", lon: "12734.615366", latD: "N", lonD: "E", latitude: 0.7546421999999999, longitude: 127.57692276666667, timestamp: "2024-06-06T12:17:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.278532", lon: "12734.615366", latD: "N", lonD: "E", latitude: 0.7546421999999999, longitude: 127.57692276666667, timestamp: "2024-06-06T12:17:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "10", hdop: "0.6", lat: "0045.278532", lon: "12734.615366", latD: "N", lonD: "E", latitude: 0.7546421999999999, longitude: 127.57692276666667, timestamp: "2024-06-06T12:17:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "09", hdop: "0.6", lat: "0045.278480", lon: "12734.615375", latD: "N", lonD: "E", latitude: 0.7546413333333334, longitude: 127.57692291666666, timestamp: "2024-06-06T12:18:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "09", hdop: "0.6", lat: "0045.278480", lon: "12734.615375", latD: "N", lonD: "E", latitude: 0.7546413333333334, longitude: 127.57692291666666, timestamp: "2024-06-06T12:18:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "09", hdop: "0.6", lat: "0045.278480", lon: "12734.615375", latD: "N", lonD: "E", latitude: 0.7546413333333334, longitude: 127.57692291666666, timestamp: "2024-06-06T12:18:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "09", hdop: "0.6", lat: "0045.278480", lon: "12734.615375", latD: "N", lonD: "E", latitude: 0.7546413333333334, longitude: 127.57692291666666, timestamp: "2024-06-06T12:18:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278421", lon: "12734.615379", latD: "N", lonD: "E", latitude: 0.7546403500000001, longitude: 127.57692298333333, timestamp: "2024-06-06T12:18:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "09", hdop: "0.6", lat: "0045.278403", lon: "12734.615366", latD: "N", lonD: "E", latitude: 0.75464005, longitude: 127.57692276666667, timestamp: "2024-06-06T12:18:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278421", lon: "12734.615379", latD: "N", lonD: "E", latitude: 0.7546403500000001, longitude: 127.57692298333333, timestamp: "2024-06-06T12:18:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278421", lon: "12734.615379", latD: "N", lonD: "E", latitude: 0.7546403500000001, longitude: 127.57692298333333, timestamp: "2024-06-06T12:18:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278421", lon: "12734.615379", latD: "N", lonD: "E", latitude: 0.7546403500000001, longitude: 127.57692298333333, timestamp: "2024-06-06T12:18:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278383", lon: "12734.615367", latD: "N", lonD: "E", latitude: 0.7546397166666666, longitude: 127.57692278333333, timestamp: "2024-06-06T12:19:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278383", lon: "12734.615367", latD: "N", lonD: "E", latitude: 0.7546397166666666, longitude: 127.57692278333333, timestamp: "2024-06-06T12:19:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278383", lon: "12734.615367", latD: "N", lonD: "E", latitude: 0.7546397166666666, longitude: 127.57692278333333, timestamp: "2024-06-06T12:19:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.4", satellites: "09", hdop: "0.6", lat: "0045.278403", lon: "12734.615366", latD: "N", lonD: "E", latitude: 0.75464005, longitude: 127.57692276666667, timestamp: "2024-06-06T12:18:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278383", lon: "12734.615367", latD: "N", lonD: "E", latitude: 0.7546397166666666, longitude: 127.57692278333333, timestamp: "2024-06-06T12:19:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278383", lon: "12734.615367", latD: "N", lonD: "E", latitude: 0.7546397166666666, longitude: 127.57692278333333, timestamp: "2024-06-06T12:19:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278361", lon: "12734.615368", latD: "N", lonD: "E", latitude: 0.7546393499999999, longitude: 127.5769228, timestamp: "2024-06-06T12:19:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278361", lon: "12734.615368", latD: "N", lonD: "E", latitude: 0.7546393499999999, longitude: 127.5769228, timestamp: "2024-06-06T12:19:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278361", lon: "12734.615368", latD: "N", lonD: "E", latitude: 0.7546393499999999, longitude: 127.5769228, timestamp: "2024-06-06T12:19:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.278330", lon: "12734.615373", latD: "N", lonD: "E", latitude: 0.7546388333333333, longitude: 127.57692288333334, timestamp: "2024-06-06T12:19:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278361", lon: "12734.615368", latD: "N", lonD: "E", latitude: 0.7546393499999999, longitude: 127.5769228, timestamp: "2024-06-06T12:19:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "10", hdop: "0.6", lat: "0045.278361", lon: "12734.615368", latD: "N", lonD: "E", latitude: 0.7546393499999999, longitude: 127.5769228, timestamp: "2024-06-06T12:19:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.278330", lon: "12734.615373", latD: "N", lonD: "E", latitude: 0.7546388333333333, longitude: 127.57692288333334, timestamp: "2024-06-06T12:19:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.5", satellites: "09", hdop: "0.6", lat: "0045.278330", lon: "12734.615373", latD: "N", lonD: "E", latitude: 0.7546388333333333, longitude: 127.57692288333334, timestamp: "2024-06-06T12:19:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.6", satellites: "09", hdop: "0.6", lat: "0045.278321", lon: "12734.615378", latD: "N", lonD: "E", latitude: 0.7546386833333333, longitude: 127.57692296666667, timestamp: "2024-06-06T12:20:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.6", satellites: "09", hdop: "0.6", lat: "0045.278321", lon: "12734.615378", latD: "N", lonD: "E", latitude: 0.7546386833333333, longitude: 127.57692296666667, timestamp: "2024-06-06T12:20:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.6", satellites: "09", hdop: "0.6", lat: "0045.278303", lon: "12734.615386", latD: "N", lonD: "E", latitude: 0.7546383833333333, longitude: 127.5769231, timestamp: "2024-06-06T12:20:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.6", satellites: "09", hdop: "0.6", lat: "0045.278321", lon: "12734.615378", latD: "N", lonD: "E", latitude: 0.7546386833333333, longitude: 127.57692296666667, timestamp: "2024-06-06T12:20:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.6", satellites: "09", hdop: "0.6", lat: "0045.278321", lon: "12734.615378", latD: "N", lonD: "E", latitude: 0.7546386833333333, longitude: 127.57692296666667, timestamp: "2024-06-06T12:20:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.6", satellites: "09", hdop: "0.6", lat: "0045.278321", lon: "12734.615378", latD: "N", lonD: "E", latitude: 0.7546386833333333, longitude: 127.57692296666667, timestamp: "2024-06-06T12:20:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.6", satellites: "09", hdop: "0.6", lat: "0045.278303", lon: "12734.615386", latD: "N", lonD: "E", latitude: 0.7546383833333333, longitude: 127.5769231, timestamp: "2024-06-06T12:20:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.6", satellites: "09", hdop: "0.6", lat: "0045.278303", lon: "12734.615386", latD: "N", lonD: "E", latitude: 0.7546383833333333, longitude: 127.5769231, timestamp: "2024-06-06T12:20:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.6", satellites: "09", hdop: "0.6", lat: "0045.278303", lon: "12734.615386", latD: "N", lonD: "E", latitude: 0.7546383833333333, longitude: 127.5769231, timestamp: "2024-06-06T12:20:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.278300", lon: "12734.615395", latD: "N", lonD: "E", latitude: 0.7546383333333334, longitude: 127.57692325, timestamp: "2024-06-06T12:20:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.7", satellites: "10", hdop: "0.5", lat: "0045.278287", lon: "12734.615404", latD: "N", lonD: "E", latitude: 0.7546381166666667, longitude: 127.5769234, timestamp: "2024-06-06T12:21:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.278300", lon: "12734.615395", latD: "N", lonD: "E", latitude: 0.7546383333333334, longitude: 127.57692325, timestamp: "2024-06-06T12:20:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.278300", lon: "12734.615395", latD: "N", lonD: "E", latitude: 0.7546383333333334, longitude: 127.57692325, timestamp: "2024-06-06T12:20:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.7", satellites: "09", hdop: "0.6", lat: "0045.278300", lon: "12734.615395", latD: "N", lonD: "E", latitude: 0.7546383333333334, longitude: 127.57692325, timestamp: "2024-06-06T12:20:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.7", satellites: "10", hdop: "0.5", lat: "0045.278287", lon: "12734.615404", latD: "N", lonD: "E", latitude: 0.7546381166666667, longitude: 127.5769234, timestamp: "2024-06-06T12:21:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278246", lon: "12734.615424", latD: "N", lonD: "E", latitude: 0.7546374333333333, longitude: 127.57692373333333, timestamp: "2024-06-06T12:21:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278246", lon: "12734.615424", latD: "N", lonD: "E", latitude: 0.7546374333333333, longitude: 127.57692373333333, timestamp: "2024-06-06T12:21:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278246", lon: "12734.615424", latD: "N", lonD: "E", latitude: 0.7546374333333333, longitude: 127.57692373333333, timestamp: "2024-06-06T12:21:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278246", lon: "12734.615424", latD: "N", lonD: "E", latitude: 0.7546374333333333, longitude: 127.57692373333333, timestamp: "2024-06-06T12:21:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278246", lon: "12734.615424", latD: "N", lonD: "E", latitude: 0.7546374333333333, longitude: 127.57692373333333, timestamp: "2024-06-06T12:21:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278246", lon: "12734.615424", latD: "N", lonD: "E", latitude: 0.7546374333333333, longitude: 127.57692373333333, timestamp: "2024-06-06T12:21:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "08", hdop: "0.6", lat: "0045.278219", lon: "12734.615443", latD: "N", lonD: "E", latitude: 0.7546369833333333, longitude: 127.57692405, timestamp: "2024-06-06T12:21:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "08", hdop: "0.6", lat: "0045.278219", lon: "12734.615443", latD: "N", lonD: "E", latitude: 0.7546369833333333, longitude: 127.57692405, timestamp: "2024-06-06T12:21:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "08", hdop: "0.6", lat: "0045.278219", lon: "12734.615443", latD: "N", lonD: "E", latitude: 0.7546369833333333, longitude: 127.57692405, timestamp: "2024-06-06T12:21:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278180", lon: "12734.615455", latD: "N", lonD: "E", latitude: 0.7546363333333334, longitude: 127.57692425, timestamp: "2024-06-06T12:22:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278180", lon: "12734.615455", latD: "N", lonD: "E", latitude: 0.7546363333333334, longitude: 127.57692425, timestamp: "2024-06-06T12:22:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278180", lon: "12734.615455", latD: "N", lonD: "E", latitude: 0.7546363333333334, longitude: 127.57692425, timestamp: "2024-06-06T12:22:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.278142", lon: "12734.615483", latD: "N", lonD: "E", latitude: 0.7546357, longitude: 127.57692471666667, timestamp: "2024-06-06T12:22:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278180", lon: "12734.615455", latD: "N", lonD: "E", latitude: 0.7546363333333334, longitude: 127.57692425, timestamp: "2024-06-06T12:22:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "11.9", satellites: "10", hdop: "0.6", lat: "0045.278180", lon: "12734.615455", latD: "N", lonD: "E", latitude: 0.7546363333333334, longitude: 127.57692425, timestamp: "2024-06-06T12:22:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.278142", lon: "12734.615483", latD: "N", lonD: "E", latitude: 0.7546357, longitude: 127.57692471666667, timestamp: "2024-06-06T12:22:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.278142", lon: "12734.615483", latD: "N", lonD: "E", latitude: 0.7546357, longitude: 127.57692471666667, timestamp: "2024-06-06T12:22:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.278142", lon: "12734.615483", latD: "N", lonD: "E", latitude: 0.7546357, longitude: 127.57692471666667, timestamp: "2024-06-06T12:22:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.278104", lon: "12734.615500", latD: "N", lonD: "E", latitude: 0.7546350666666667, longitude: 127.576925, timestamp: "2024-06-06T12:22:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.278104", lon: "12734.615500", latD: "N", lonD: "E", latitude: 0.7546350666666667, longitude: 127.576925, timestamp: "2024-06-06T12:22:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.278104", lon: "12734.615500", latD: "N", lonD: "E", latitude: 0.7546350666666667, longitude: 127.576925, timestamp: "2024-06-06T12:22:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.278081", lon: "12734.615529", latD: "N", lonD: "E", latitude: 0.7546346833333334, longitude: 127.57692548333333, timestamp: "2024-06-06T12:23:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.278081", lon: "12734.615529", latD: "N", lonD: "E", latitude: 0.7546346833333334, longitude: 127.57692548333333, timestamp: "2024-06-06T12:23:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.278081", lon: "12734.615529", latD: "N", lonD: "E", latitude: 0.7546346833333334, longitude: 127.57692548333333, timestamp: "2024-06-06T12:23:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.278081", lon: "12734.615529", latD: "N", lonD: "E", latitude: 0.7546346833333334, longitude: 127.57692548333333, timestamp: "2024-06-06T12:23:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.278081", lon: "12734.615529", latD: "N", lonD: "E", latitude: 0.7546346833333334, longitude: 127.57692548333333, timestamp: "2024-06-06T12:23:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.278073", lon: "12734.615534", latD: "N", lonD: "E", latitude: 0.75463455, longitude: 127.57692556666666, timestamp: "2024-06-06T12:23:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "08", hdop: "0.6", lat: "0045.278030", lon: "12734.615563", latD: "N", lonD: "E", latitude: 0.7546338333333333, longitude: 127.57692605, timestamp: "2024-06-06T12:23:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "08", hdop: "0.6", lat: "0045.278030", lon: "12734.615563", latD: "N", lonD: "E", latitude: 0.7546338333333333, longitude: 127.57692605, timestamp: "2024-06-06T12:23:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.278073", lon: "12734.615534", latD: "N", lonD: "E", latitude: 0.75463455, longitude: 127.57692556666666, timestamp: "2024-06-06T12:23:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "08", hdop: "0.6", lat: "0045.278030", lon: "12734.615563", latD: "N", lonD: "E", latitude: 0.7546338333333333, longitude: 127.57692605, timestamp: "2024-06-06T12:23:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "08", hdop: "0.6", lat: "0045.278030", lon: "12734.615563", latD: "N", lonD: "E", latitude: 0.7546338333333333, longitude: 127.57692605, timestamp: "2024-06-06T12:23:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "08", hdop: "0.6", lat: "0045.278030", lon: "12734.615563", latD: "N", lonD: "E", latitude: 0.7546338333333333, longitude: 127.57692605, timestamp: "2024-06-06T12:23:49.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "09", hdop: "0.6", lat: "0045.277989", lon: "12734.615579", latD: "N", lonD: "E", latitude: 0.75463315, longitude: 127.57692631666667, timestamp: "2024-06-06T12:24:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "09", hdop: "0.6", lat: "0045.277989", lon: "12734.615579", latD: "N", lonD: "E", latitude: 0.75463315, longitude: 127.57692631666667, timestamp: "2024-06-06T12:24:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.277955", lon: "12734.615593", latD: "N", lonD: "E", latitude: 0.7546325833333333, longitude: 127.57692655, timestamp: "2024-06-06T12:24:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.277955", lon: "12734.615593", latD: "N", lonD: "E", latitude: 0.7546325833333333, longitude: 127.57692655, timestamp: "2024-06-06T12:24:29.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "09", hdop: "0.6", lat: "0045.277989", lon: "12734.615579", latD: "N", lonD: "E", latitude: 0.75463315, longitude: 127.57692631666667, timestamp: "2024-06-06T12:24:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.1", satellites: "09", hdop: "0.6", lat: "0045.277989", lon: "12734.615579", latD: "N", lonD: "E", latitude: 0.75463315, longitude: 127.57692631666667, timestamp: "2024-06-06T12:24:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.277919", lon: "12734.615609", latD: "N", lonD: "E", latitude: 0.7546319833333333, longitude: 127.57692681666667, timestamp: "2024-06-06T12:24:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.277919", lon: "12734.615609", latD: "N", lonD: "E", latitude: 0.7546319833333333, longitude: 127.57692681666667, timestamp: "2024-06-06T12:24:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.277919", lon: "12734.615609", latD: "N", lonD: "E", latitude: 0.7546319833333333, longitude: 127.57692681666667, timestamp: "2024-06-06T12:24:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.277919", lon: "12734.615609", latD: "N", lonD: "E", latitude: 0.7546319833333333, longitude: 127.57692681666667, timestamp: "2024-06-06T12:24:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.277891", lon: "12734.615630", latD: "N", lonD: "E", latitude: 0.7546315166666666, longitude: 127.57692716666666, timestamp: "2024-06-06T12:25:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.277891", lon: "12734.615630", latD: "N", lonD: "E", latitude: 0.7546315166666666, longitude: 127.57692716666666, timestamp: "2024-06-06T12:25:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.277891", lon: "12734.615630", latD: "N", lonD: "E", latitude: 0.7546315166666666, longitude: 127.57692716666666, timestamp: "2024-06-06T12:25:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.7", satellites: "09", hdop: "0.6", lat: "0045.277856", lon: "12734.615660", latD: "N", lonD: "E", latitude: 0.7546309333333333, longitude: 127.57692766666666, timestamp: "2024-06-06T12:25:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.7", satellites: "09", hdop: "0.6", lat: "0045.277856", lon: "12734.615660", latD: "N", lonD: "E", latitude: 0.7546309333333333, longitude: 127.57692766666666, timestamp: "2024-06-06T12:25:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.7", satellites: "09", hdop: "0.6", lat: "0045.277856", lon: "12734.615660", latD: "N", lonD: "E", latitude: 0.7546309333333333, longitude: 127.57692766666666, timestamp: "2024-06-06T12:25:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.7", satellites: "09", hdop: "0.6", lat: "0045.277856", lon: "12734.615660", latD: "N", lonD: "E", latitude: 0.7546309333333333, longitude: 127.57692766666666, timestamp: "2024-06-06T12:25:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.7", satellites: "09", hdop: "0.6", lat: "0045.277856", lon: "12734.615660", latD: "N", lonD: "E", latitude: 0.7546309333333333, longitude: 127.57692766666666, timestamp: "2024-06-06T12:25:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.7", satellites: "09", hdop: "0.6", lat: "0045.277856", lon: "12734.615660", latD: "N", lonD: "E", latitude: 0.7546309333333333, longitude: 127.57692766666666, timestamp: "2024-06-06T12:25:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.8", satellites: "09", hdop: "0.6", lat: "0045.277833", lon: "12734.615697", latD: "N", lonD: "E", latitude: 0.75463055, longitude: 127.57692828333333, timestamp: "2024-06-06T12:25:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.8", satellites: "09", hdop: "0.6", lat: "0045.277833", lon: "12734.615697", latD: "N", lonD: "E", latitude: 0.75463055, longitude: 127.57692828333333, timestamp: "2024-06-06T12:25:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0045.277790", lon: "12734.615738", latD: "N", lonD: "E", latitude: 0.7546298333333333, longitude: 127.57692896666667, timestamp: "2024-06-06T12:26:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0045.277790", lon: "12734.615738", latD: "N", lonD: "E", latitude: 0.7546298333333333, longitude: 127.57692896666667, timestamp: "2024-06-06T12:26:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "12.8", satellites: "09", hdop: "0.6", lat: "0045.277833", lon: "12734.615697", latD: "N", lonD: "E", latitude: 0.75463055, longitude: 127.57692828333333, timestamp: "2024-06-06T12:25:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0045.277790", lon: "12734.615738", latD: "N", lonD: "E", latitude: 0.7546298333333333, longitude: 127.57692896666667, timestamp: "2024-06-06T12:26:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0045.277790", lon: "12734.615738", latD: "N", lonD: "E", latitude: 0.7546298333333333, longitude: 127.57692896666667, timestamp: "2024-06-06T12:26:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.0", satellites: "09", hdop: "0.6", lat: "0045.277790", lon: "12734.615738", latD: "N", lonD: "E", latitude: 0.7546298333333333, longitude: 127.57692896666667, timestamp: "2024-06-06T12:26:09.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.277760", lon: "12734.615772", latD: "N", lonD: "E", latitude: 0.7546293333333334, longitude: 127.57692953333333, timestamp: "2024-06-06T12:26:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.3", satellites: "09", hdop: "0.6", lat: "0045.277739", lon: "12734.615793", latD: "N", lonD: "E", latitude: 0.7546289833333333, longitude: 127.57692988333334, timestamp: "2024-06-06T12:26:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.3", satellites: "09", hdop: "0.6", lat: "0045.277739", lon: "12734.615793", latD: "N", lonD: "E", latitude: 0.7546289833333333, longitude: 127.57692988333334, timestamp: "2024-06-06T12:26:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.277760", lon: "12734.615772", latD: "N", lonD: "E", latitude: 0.7546293333333334, longitude: 127.57692953333333, timestamp: "2024-06-06T12:26:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.277760", lon: "12734.615772", latD: "N", lonD: "E", latitude: 0.7546293333333334, longitude: 127.57692953333333, timestamp: "2024-06-06T12:26:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.3", satellites: "09", hdop: "0.6", lat: "0045.277739", lon: "12734.615793", latD: "N", lonD: "E", latitude: 0.7546289833333333, longitude: 127.57692988333334, timestamp: "2024-06-06T12:26:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.3", satellites: "09", hdop: "0.6", lat: "0045.277739", lon: "12734.615793", latD: "N", lonD: "E", latitude: 0.7546289833333333, longitude: 127.57692988333334, timestamp: "2024-06-06T12:26:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.277729", lon: "12734.615802", latD: "N", lonD: "E", latitude: 0.7546288166666667, longitude: 127.57693003333333, timestamp: "2024-06-06T12:27:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.277729", lon: "12734.615802", latD: "N", lonD: "E", latitude: 0.7546288166666667, longitude: 127.57693003333333, timestamp: "2024-06-06T12:27:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.277729", lon: "12734.615802", latD: "N", lonD: "E", latitude: 0.7546288166666667, longitude: 127.57693003333333, timestamp: "2024-06-06T12:27:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.277729", lon: "12734.615802", latD: "N", lonD: "E", latitude: 0.7546288166666667, longitude: 127.57693003333333, timestamp: "2024-06-06T12:27:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.277720", lon: "12734.615813", latD: "N", lonD: "E", latitude: 0.7546286666666667, longitude: 127.57693021666667, timestamp: "2024-06-06T12:27:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.277720", lon: "12734.615813", latD: "N", lonD: "E", latitude: 0.7546286666666667, longitude: 127.57693021666667, timestamp: "2024-06-06T12:27:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.277720", lon: "12734.615813", latD: "N", lonD: "E", latitude: 0.7546286666666667, longitude: 127.57693021666667, timestamp: "2024-06-06T12:27:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.277680", lon: "12734.615824", latD: "N", lonD: "E", latitude: 0.754628, longitude: 127.5769304, timestamp: "2024-06-06T12:27:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.277680", lon: "12734.615824", latD: "N", lonD: "E", latitude: 0.754628, longitude: 127.5769304, timestamp: "2024-06-06T12:27:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.277720", lon: "12734.615813", latD: "N", lonD: "E", latitude: 0.7546286666666667, longitude: 127.57693021666667, timestamp: "2024-06-06T12:27:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.277680", lon: "12734.615824", latD: "N", lonD: "E", latitude: 0.754628, longitude: 127.5769304, timestamp: "2024-06-06T12:27:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.277680", lon: "12734.615824", latD: "N", lonD: "E", latitude: 0.754628, longitude: 127.5769304, timestamp: "2024-06-06T12:27:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.4", satellites: "10", hdop: "0.6", lat: "0045.277680", lon: "12734.615824", latD: "N", lonD: "E", latitude: 0.754628, longitude: 127.5769304, timestamp: "2024-06-06T12:27:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.5", satellites: "09", hdop: "0.6", lat: "0045.277686", lon: "12734.615835", latD: "N", lonD: "E", latitude: 0.7546281, longitude: 127.57693058333334, timestamp: "2024-06-06T12:28:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.277665", lon: "12734.615835", latD: "N", lonD: "E", latitude: 0.7546277499999999, longitude: 127.57693058333334, timestamp: "2024-06-06T12:28:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.277665", lon: "12734.615835", latD: "N", lonD: "E", latitude: 0.7546277499999999, longitude: 127.57693058333334, timestamp: "2024-06-06T12:28:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.5", satellites: "09", hdop: "0.6", lat: "0045.277686", lon: "12734.615835", latD: "N", lonD: "E", latitude: 0.7546281, longitude: 127.57693058333334, timestamp: "2024-06-06T12:28:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.5", satellites: "09", hdop: "0.6", lat: "0045.277686", lon: "12734.615835", latD: "N", lonD: "E", latitude: 0.7546281, longitude: 127.57693058333334, timestamp: "2024-06-06T12:28:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.277665", lon: "12734.615835", latD: "N", lonD: "E", latitude: 0.7546277499999999, longitude: 127.57693058333334, timestamp: "2024-06-06T12:28:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.277632", lon: "12734.615838", latD: "N", lonD: "E", latitude: 0.7546271999999999, longitude: 127.57693063333333, timestamp: "2024-06-06T12:28:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.277632", lon: "12734.615838", latD: "N", lonD: "E", latitude: 0.7546271999999999, longitude: 127.57693063333333, timestamp: "2024-06-06T12:28:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.277632", lon: "12734.615838", latD: "N", lonD: "E", latitude: 0.7546271999999999, longitude: 127.57693063333333, timestamp: "2024-06-06T12:28:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.277632", lon: "12734.615838", latD: "N", lonD: "E", latitude: 0.7546271999999999, longitude: 127.57693063333333, timestamp: "2024-06-06T12:28:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.277632", lon: "12734.615838", latD: "N", lonD: "E", latitude: 0.7546271999999999, longitude: 127.57693063333333, timestamp: "2024-06-06T12:28:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.277632", lon: "12734.615838", latD: "N", lonD: "E", latitude: 0.7546271999999999, longitude: 127.57693063333333, timestamp: "2024-06-06T12:28:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277598", lon: "12734.615839", latD: "N", lonD: "E", latitude: 0.7546266333333332, longitude: 127.57693065, timestamp: "2024-06-06T12:29:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277598", lon: "12734.615839", latD: "N", lonD: "E", latitude: 0.7546266333333332, longitude: 127.57693065, timestamp: "2024-06-06T12:29:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277589", lon: "12734.615833", latD: "N", lonD: "E", latitude: 0.7546264833333333, longitude: 127.57693055, timestamp: "2024-06-06T12:29:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277589", lon: "12734.615833", latD: "N", lonD: "E", latitude: 0.7546264833333333, longitude: 127.57693055, timestamp: "2024-06-06T12:29:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277598", lon: "12734.615839", latD: "N", lonD: "E", latitude: 0.7546266333333332, longitude: 127.57693065, timestamp: "2024-06-06T12:29:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277589", lon: "12734.615833", latD: "N", lonD: "E", latitude: 0.7546264833333333, longitude: 127.57693055, timestamp: "2024-06-06T12:29:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277589", lon: "12734.615833", latD: "N", lonD: "E", latitude: 0.7546264833333333, longitude: 127.57693055, timestamp: "2024-06-06T12:29:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277570", lon: "12734.615839", latD: "N", lonD: "E", latitude: 0.7546261666666666, longitude: 127.57693065, timestamp: "2024-06-06T12:29:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277570", lon: "12734.615839", latD: "N", lonD: "E", latitude: 0.7546261666666666, longitude: 127.57693065, timestamp: "2024-06-06T12:29:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277570", lon: "12734.615839", latD: "N", lonD: "E", latitude: 0.7546261666666666, longitude: 127.57693065, timestamp: "2024-06-06T12:29:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.5", lat: "0045.277553", lon: "12734.615831", latD: "N", lonD: "E", latitude: 0.7546258833333332, longitude: 127.57693051666666, timestamp: "2024-06-06T12:30:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277570", lon: "12734.615839", latD: "N", lonD: "E", latitude: 0.7546261666666666, longitude: 127.57693065, timestamp: "2024-06-06T12:29:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.6", lat: "0045.277570", lon: "12734.615839", latD: "N", lonD: "E", latitude: 0.7546261666666666, longitude: 127.57693065, timestamp: "2024-06-06T12:29:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.5", lat: "0045.277553", lon: "12734.615831", latD: "N", lonD: "E", latitude: 0.7546258833333332, longitude: 127.57693051666666, timestamp: "2024-06-06T12:30:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.8", satellites: "10", hdop: "0.5", lat: "0045.277553", lon: "12734.615831", latD: "N", lonD: "E", latitude: 0.7546258833333332, longitude: 127.57693051666666, timestamp: "2024-06-06T12:30:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.9", satellites: "10", hdop: "0.5", lat: "0045.277528", lon: "12734.615828", latD: "N", lonD: "E", latitude: 0.7546254666666666, longitude: 127.57693046666667, timestamp: "2024-06-06T12:30:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.9", satellites: "10", hdop: "0.5", lat: "0045.277528", lon: "12734.615828", latD: "N", lonD: "E", latitude: 0.7546254666666666, longitude: 127.57693046666667, timestamp: "2024-06-06T12:30:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.9", satellites: "10", hdop: "0.5", lat: "0045.277528", lon: "12734.615828", latD: "N", lonD: "E", latitude: 0.7546254666666666, longitude: 127.57693046666667, timestamp: "2024-06-06T12:30:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.9", satellites: "10", hdop: "0.5", lat: "0045.277528", lon: "12734.615828", latD: "N", lonD: "E", latitude: 0.7546254666666666, longitude: 127.57693046666667, timestamp: "2024-06-06T12:30:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.9", satellites: "09", hdop: "0.6", lat: "0045.277525", lon: "12734.615828", latD: "N", lonD: "E", latitude: 0.7546254166666666, longitude: 127.57693046666667, timestamp: "2024-06-06T12:30:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.9", satellites: "09", hdop: "0.6", lat: "0045.277525", lon: "12734.615828", latD: "N", lonD: "E", latitude: 0.7546254166666666, longitude: 127.57693046666667, timestamp: "2024-06-06T12:30:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.9", satellites: "09", hdop: "0.6", lat: "0045.277525", lon: "12734.615828", latD: "N", lonD: "E", latitude: 0.7546254166666666, longitude: 127.57693046666667, timestamp: "2024-06-06T12:30:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.0", satellites: "10", hdop: "0.6", lat: "0045.277524", lon: "12734.615821", latD: "N", lonD: "E", latitude: 0.7546254, longitude: 127.57693035, timestamp: "2024-06-06T12:31:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.0", satellites: "10", hdop: "0.6", lat: "0045.277524", lon: "12734.615821", latD: "N", lonD: "E", latitude: 0.7546254, longitude: 127.57693035, timestamp: "2024-06-06T12:31:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "13.9", satellites: "09", hdop: "0.6", lat: "0045.277525", lon: "12734.615828", latD: "N", lonD: "E", latitude: 0.7546254166666666, longitude: 127.57693046666667, timestamp: "2024-06-06T12:30:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.0", satellites: "10", hdop: "0.6", lat: "0045.277524", lon: "12734.615821", latD: "N", lonD: "E", latitude: 0.7546254, longitude: 127.57693035, timestamp: "2024-06-06T12:31:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.0", satellites: "10", hdop: "0.6", lat: "0045.277524", lon: "12734.615821", latD: "N", lonD: "E", latitude: 0.7546254, longitude: 127.57693035, timestamp: "2024-06-06T12:31:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.0", satellites: "09", hdop: "0.6", lat: "0045.277503", lon: "12734.615800", latD: "N", lonD: "E", latitude: 0.75462505, longitude: 127.57693, timestamp: "2024-06-06T12:31:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.0", satellites: "09", hdop: "0.6", lat: "0045.277503", lon: "12734.615800", latD: "N", lonD: "E", latitude: 0.75462505, longitude: 127.57693, timestamp: "2024-06-06T12:31:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.0", satellites: "09", hdop: "0.6", lat: "0045.277503", lon: "12734.615800", latD: "N", lonD: "E", latitude: 0.75462505, longitude: 127.57693, timestamp: "2024-06-06T12:31:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.277504", lon: "12734.615795", latD: "N", lonD: "E", latitude: 0.7546250666666666, longitude: 127.57692991666667, timestamp: "2024-06-06T12:31:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.277504", lon: "12734.615795", latD: "N", lonD: "E", latitude: 0.7546250666666666, longitude: 127.57692991666667, timestamp: "2024-06-06T12:31:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.277504", lon: "12734.615795", latD: "N", lonD: "E", latitude: 0.7546250666666666, longitude: 127.57692991666667, timestamp: "2024-06-06T12:31:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.277504", lon: "12734.615795", latD: "N", lonD: "E", latitude: 0.7546250666666666, longitude: 127.57692991666667, timestamp: "2024-06-06T12:31:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.277504", lon: "12734.615795", latD: "N", lonD: "E", latitude: 0.7546250666666666, longitude: 127.57692991666667, timestamp: "2024-06-06T12:31:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "09", hdop: "0.5", lat: "0045.277515", lon: "12734.615797", latD: "N", lonD: "E", latitude: 0.75462525, longitude: 127.57692995, timestamp: "2024-06-06T12:32:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "09", hdop: "0.5", lat: "0045.277515", lon: "12734.615797", latD: "N", lonD: "E", latitude: 0.75462525, longitude: 127.57692995, timestamp: "2024-06-06T12:32:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "09", hdop: "0.5", lat: "0045.277515", lon: "12734.615797", latD: "N", lonD: "E", latitude: 0.75462525, longitude: 127.57692995, timestamp: "2024-06-06T12:32:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "09", hdop: "0.5", lat: "0045.277515", lon: "12734.615797", latD: "N", lonD: "E", latitude: 0.75462525, longitude: 127.57692995, timestamp: "2024-06-06T12:32:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "09", hdop: "0.5", lat: "0045.277515", lon: "12734.615797", latD: "N", lonD: "E", latitude: 0.75462525, longitude: 127.57692995, timestamp: "2024-06-06T12:32:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.277520", lon: "12734.615798", latD: "N", lonD: "E", latitude: 0.7546253333333334, longitude: 127.57692996666667, timestamp: "2024-06-06T12:32:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.277520", lon: "12734.615798", latD: "N", lonD: "E", latitude: 0.7546253333333334, longitude: 127.57692996666667, timestamp: "2024-06-06T12:32:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.2", satellites: "09", hdop: "0.6", lat: "0045.277519", lon: "12734.615797", latD: "N", lonD: "E", latitude: 0.7546253166666667, longitude: 127.57692995, timestamp: "2024-06-06T12:32:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.2", satellites: "09", hdop: "0.6", lat: "0045.277519", lon: "12734.615797", latD: "N", lonD: "E", latitude: 0.7546253166666667, longitude: 127.57692995, timestamp: "2024-06-06T12:32:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.277520", lon: "12734.615798", latD: "N", lonD: "E", latitude: 0.7546253333333334, longitude: 127.57692996666667, timestamp: "2024-06-06T12:32:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.2", satellites: "09", hdop: "0.6", lat: "0045.277519", lon: "12734.615797", latD: "N", lonD: "E", latitude: 0.7546253166666667, longitude: 127.57692995, timestamp: "2024-06-06T12:32:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.2", satellites: "09", hdop: "0.6", lat: "0045.277519", lon: "12734.615797", latD: "N", lonD: "E", latitude: 0.7546253166666667, longitude: 127.57692995, timestamp: "2024-06-06T12:32:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.2", satellites: "07", hdop: "0.7", lat: "0045.277524", lon: "12734.615801", latD: "N", lonD: "E", latitude: 0.7546254, longitude: 127.57693001666667, timestamp: "2024-06-06T12:33:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.2", satellites: "07", hdop: "0.7", lat: "0045.277524", lon: "12734.615801", latD: "N", lonD: "E", latitude: 0.7546254, longitude: 127.57693001666667, timestamp: "2024-06-06T12:33:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.2", satellites: "07", hdop: "0.7", lat: "0045.277524", lon: "12734.615801", latD: "N", lonD: "E", latitude: 0.7546254, longitude: 127.57693001666667, timestamp: "2024-06-06T12:33:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277517", lon: "12734.615807", latD: "N", lonD: "E", latitude: 0.7546252833333333, longitude: 127.57693011666667, timestamp: "2024-06-06T12:33:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277517", lon: "12734.615807", latD: "N", lonD: "E", latitude: 0.7546252833333333, longitude: 127.57693011666667, timestamp: "2024-06-06T12:33:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.2", satellites: "07", hdop: "0.7", lat: "0045.277524", lon: "12734.615801", latD: "N", lonD: "E", latitude: 0.7546254, longitude: 127.57693001666667, timestamp: "2024-06-06T12:33:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277517", lon: "12734.615807", latD: "N", lonD: "E", latitude: 0.7546252833333333, longitude: 127.57693011666667, timestamp: "2024-06-06T12:33:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277517", lon: "12734.615807", latD: "N", lonD: "E", latitude: 0.7546252833333333, longitude: 127.57693011666667, timestamp: "2024-06-06T12:33:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "09", hdop: "0.6", lat: "0045.277485", lon: "12734.615801", latD: "N", lonD: "E", latitude: 0.75462475, longitude: 127.57693001666667, timestamp: "2024-06-06T12:33:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "09", hdop: "0.6", lat: "0045.277485", lon: "12734.615801", latD: "N", lonD: "E", latitude: 0.75462475, longitude: 127.57693001666667, timestamp: "2024-06-06T12:33:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "09", hdop: "0.6", lat: "0045.277485", lon: "12734.615801", latD: "N", lonD: "E", latitude: 0.75462475, longitude: 127.57693001666667, timestamp: "2024-06-06T12:33:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277464", lon: "12734.615804", latD: "N", lonD: "E", latitude: 0.7546244000000001, longitude: 127.57693006666666, timestamp: "2024-06-06T12:34:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277464", lon: "12734.615804", latD: "N", lonD: "E", latitude: 0.7546244000000001, longitude: 127.57693006666666, timestamp: "2024-06-06T12:34:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277464", lon: "12734.615804", latD: "N", lonD: "E", latitude: 0.7546244000000001, longitude: 127.57693006666666, timestamp: "2024-06-06T12:34:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277464", lon: "12734.615804", latD: "N", lonD: "E", latitude: 0.7546244000000001, longitude: 127.57693006666666, timestamp: "2024-06-06T12:34:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277464", lon: "12734.615804", latD: "N", lonD: "E", latitude: 0.7546244000000001, longitude: 127.57693006666666, timestamp: "2024-06-06T12:34:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277455", lon: "12734.615802", latD: "N", lonD: "E", latitude: 0.7546242500000001, longitude: 127.57693003333333, timestamp: "2024-06-06T12:34:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277455", lon: "12734.615802", latD: "N", lonD: "E", latitude: 0.7546242500000001, longitude: 127.57693003333333, timestamp: "2024-06-06T12:34:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.3", satellites: "10", hdop: "0.6", lat: "0045.277455", lon: "12734.615802", latD: "N", lonD: "E", latitude: 0.7546242500000001, longitude: 127.57693003333333, timestamp: "2024-06-06T12:34:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.277451", lon: "12734.615805", latD: "N", lonD: "E", latitude: 0.7546241833333334, longitude: 127.57693008333334, timestamp: "2024-06-06T12:34:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.277451", lon: "12734.615805", latD: "N", lonD: "E", latitude: 0.7546241833333334, longitude: 127.57693008333334, timestamp: "2024-06-06T12:34:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.277451", lon: "12734.615805", latD: "N", lonD: "E", latitude: 0.7546241833333334, longitude: 127.57693008333334, timestamp: "2024-06-06T12:34:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.277451", lon: "12734.615805", latD: "N", lonD: "E", latitude: 0.7546241833333334, longitude: 127.57693008333334, timestamp: "2024-06-06T12:34:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.277451", lon: "12734.615805", latD: "N", lonD: "E", latitude: 0.7546241833333334, longitude: 127.57693008333334, timestamp: "2024-06-06T12:34:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "10", hdop: "0.6", lat: "0045.277435", lon: "12734.615808", latD: "N", lonD: "E", latitude: 0.7546239166666666, longitude: 127.57693013333333, timestamp: "2024-06-06T12:35:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "10", hdop: "0.6", lat: "0045.277435", lon: "12734.615808", latD: "N", lonD: "E", latitude: 0.7546239166666666, longitude: 127.57693013333333, timestamp: "2024-06-06T12:35:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "10", hdop: "0.6", lat: "0045.277435", lon: "12734.615808", latD: "N", lonD: "E", latitude: 0.7546239166666666, longitude: 127.57693013333333, timestamp: "2024-06-06T12:35:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "10", hdop: "0.6", lat: "0045.277435", lon: "12734.615808", latD: "N", lonD: "E", latitude: 0.7546239166666666, longitude: 127.57693013333333, timestamp: "2024-06-06T12:35:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.277414", lon: "12734.615814", latD: "N", lonD: "E", latitude: 0.7546235666666666, longitude: 127.57693023333333, timestamp: "2024-06-06T12:35:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.277414", lon: "12734.615814", latD: "N", lonD: "E", latitude: 0.7546235666666666, longitude: 127.57693023333333, timestamp: "2024-06-06T12:35:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.277414", lon: "12734.615814", latD: "N", lonD: "E", latitude: 0.7546235666666666, longitude: 127.57693023333333, timestamp: "2024-06-06T12:35:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "09", hdop: "0.6", lat: "0045.277406", lon: "12734.615820", latD: "N", lonD: "E", latitude: 0.7546234333333334, longitude: 127.57693033333334, timestamp: "2024-06-06T12:35:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "09", hdop: "0.6", lat: "0045.277406", lon: "12734.615820", latD: "N", lonD: "E", latitude: 0.7546234333333334, longitude: 127.57693033333334, timestamp: "2024-06-06T12:35:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.277414", lon: "12734.615814", latD: "N", lonD: "E", latitude: 0.7546235666666666, longitude: 127.57693023333333, timestamp: "2024-06-06T12:35:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "09", hdop: "0.6", lat: "0045.277406", lon: "12734.615820", latD: "N", lonD: "E", latitude: 0.7546234333333334, longitude: 127.57693033333334, timestamp: "2024-06-06T12:35:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "09", hdop: "0.6", lat: "0045.277406", lon: "12734.615820", latD: "N", lonD: "E", latitude: 0.7546234333333334, longitude: 127.57693033333334, timestamp: "2024-06-06T12:35:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "10", hdop: "0.6", lat: "0045.277409", lon: "12734.615829", latD: "N", lonD: "E", latitude: 0.7546234833333333, longitude: 127.57693048333333, timestamp: "2024-06-06T12:36:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "10", hdop: "0.6", lat: "0045.277409", lon: "12734.615829", latD: "N", lonD: "E", latitude: 0.7546234833333333, longitude: 127.57693048333333, timestamp: "2024-06-06T12:36:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "11", hdop: "0.6", lat: "0045.277422", lon: "12734.615841", latD: "N", lonD: "E", latitude: 0.7546237, longitude: 127.57693068333333, timestamp: "2024-06-06T12:36:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "11", hdop: "0.6", lat: "0045.277422", lon: "12734.615841", latD: "N", lonD: "E", latitude: 0.7546237, longitude: 127.57693068333333, timestamp: "2024-06-06T12:36:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "10", hdop: "0.6", lat: "0045.277409", lon: "12734.615829", latD: "N", lonD: "E", latitude: 0.7546234833333333, longitude: 127.57693048333333, timestamp: "2024-06-06T12:36:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "10", hdop: "0.6", lat: "0045.277409", lon: "12734.615829", latD: "N", lonD: "E", latitude: 0.7546234833333333, longitude: 127.57693048333333, timestamp: "2024-06-06T12:36:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "11", hdop: "0.6", lat: "0045.277422", lon: "12734.615841", latD: "N", lonD: "E", latitude: 0.7546237, longitude: 127.57693068333333, timestamp: "2024-06-06T12:36:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "11", hdop: "0.6", lat: "0045.277422", lon: "12734.615841", latD: "N", lonD: "E", latitude: 0.7546237, longitude: 127.57693068333333, timestamp: "2024-06-06T12:36:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.5", satellites: "11", hdop: "0.6", lat: "0045.277422", lon: "12734.615841", latD: "N", lonD: "E", latitude: 0.7546237, longitude: 127.57693068333333, timestamp: "2024-06-06T12:36:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.277417", lon: "12734.615849", latD: "N", lonD: "E", latitude: 0.7546236166666667, longitude: 127.57693081666666, timestamp: "2024-06-06T12:36:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.277417", lon: "12734.615849", latD: "N", lonD: "E", latitude: 0.7546236166666667, longitude: 127.57693081666666, timestamp: "2024-06-06T12:36:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.277417", lon: "12734.615849", latD: "N", lonD: "E", latitude: 0.7546236166666667, longitude: 127.57693081666666, timestamp: "2024-06-06T12:36:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.6", lat: "0045.277397", lon: "12734.615857", latD: "N", lonD: "E", latitude: 0.7546232833333334, longitude: 127.57693095, timestamp: "2024-06-06T12:37:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.6", lat: "0045.277397", lon: "12734.615857", latD: "N", lonD: "E", latitude: 0.7546232833333334, longitude: 127.57693095, timestamp: "2024-06-06T12:37:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.6", lat: "0045.277397", lon: "12734.615857", latD: "N", lonD: "E", latitude: 0.7546232833333334, longitude: 127.57693095, timestamp: "2024-06-06T12:37:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.5", lat: "0045.277360", lon: "12734.615869", latD: "N", lonD: "E", latitude: 0.7546226666666667, longitude: 127.57693115000001, timestamp: "2024-06-06T12:37:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.6", lat: "0045.277397", lon: "12734.615857", latD: "N", lonD: "E", latitude: 0.7546232833333334, longitude: 127.57693095, timestamp: "2024-06-06T12:37:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.6", lat: "0045.277397", lon: "12734.615857", latD: "N", lonD: "E", latitude: 0.7546232833333334, longitude: 127.57693095, timestamp: "2024-06-06T12:37:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.5", lat: "0045.277360", lon: "12734.615869", latD: "N", lonD: "E", latitude: 0.7546226666666667, longitude: 127.57693115000001, timestamp: "2024-06-06T12:37:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.5", lat: "0045.277360", lon: "12734.615869", latD: "N", lonD: "E", latitude: 0.7546226666666667, longitude: 127.57693115000001, timestamp: "2024-06-06T12:37:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.5", lat: "0045.277360", lon: "12734.615869", latD: "N", lonD: "E", latitude: 0.7546226666666667, longitude: 127.57693115000001, timestamp: "2024-06-06T12:37:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.277334", lon: "12734.615874", latD: "N", lonD: "E", latitude: 0.7546222333333333, longitude: 127.57693123333334, timestamp: "2024-06-06T12:37:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.277334", lon: "12734.615874", latD: "N", lonD: "E", latitude: 0.7546222333333333, longitude: 127.57693123333334, timestamp: "2024-06-06T12:37:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.5", lat: "0045.277289", lon: "12734.615884", latD: "N", lonD: "E", latitude: 0.7546214833333333, longitude: 127.5769314, timestamp: "2024-06-06T12:38:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.277334", lon: "12734.615874", latD: "N", lonD: "E", latitude: 0.7546222333333333, longitude: 127.57693123333334, timestamp: "2024-06-06T12:37:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "10", hdop: "0.6", lat: "0045.277334", lon: "12734.615874", latD: "N", lonD: "E", latitude: 0.7546222333333333, longitude: 127.57693123333334, timestamp: "2024-06-06T12:37:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.6", satellites: "11", hdop: "0.5", lat: "0045.277289", lon: "12734.615884", latD: "N", lonD: "E", latitude: 0.7546214833333333, longitude: 127.5769314, timestamp: "2024-06-06T12:38:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.7", satellites: "11", hdop: "0.6", lat: "0045.277261", lon: "12734.615887", latD: "N", lonD: "E", latitude: 0.7546210166666667, longitude: 127.57693145, timestamp: "2024-06-06T12:38:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.7", satellites: "11", hdop: "0.6", lat: "0045.277261", lon: "12734.615887", latD: "N", lonD: "E", latitude: 0.7546210166666667, longitude: 127.57693145, timestamp: "2024-06-06T12:38:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.7", satellites: "11", hdop: "0.6", lat: "0045.277261", lon: "12734.615887", latD: "N", lonD: "E", latitude: 0.7546210166666667, longitude: 127.57693145, timestamp: "2024-06-06T12:38:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.7", satellites: "11", hdop: "0.6", lat: "0045.277261", lon: "12734.615887", latD: "N", lonD: "E", latitude: 0.7546210166666667, longitude: 127.57693145, timestamp: "2024-06-06T12:38:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.7", satellites: "11", hdop: "0.6", lat: "0045.277261", lon: "12734.615887", latD: "N", lonD: "E", latitude: 0.7546210166666667, longitude: 127.57693145, timestamp: "2024-06-06T12:38:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.7", satellites: "11", hdop: "0.6", lat: "0045.277261", lon: "12734.615887", latD: "N", lonD: "E", latitude: 0.7546210166666667, longitude: 127.57693145, timestamp: "2024-06-06T12:38:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "10", hdop: "0.6", lat: "0045.277241", lon: "12734.615888", latD: "N", lonD: "E", latitude: 0.7546206833333333, longitude: 127.57693146666666, timestamp: "2024-06-06T12:38:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "10", hdop: "0.6", lat: "0045.277241", lon: "12734.615888", latD: "N", lonD: "E", latitude: 0.7546206833333333, longitude: 127.57693146666666, timestamp: "2024-06-06T12:38:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "09", hdop: "0.6", lat: "0045.277225", lon: "12734.615898", latD: "N", lonD: "E", latitude: 0.7546204166666667, longitude: 127.57693163333333, timestamp: "2024-06-06T12:39:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "10", hdop: "0.6", lat: "0045.277241", lon: "12734.615888", latD: "N", lonD: "E", latitude: 0.7546206833333333, longitude: 127.57693146666666, timestamp: "2024-06-06T12:38:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "10", hdop: "0.6", lat: "0045.277241", lon: "12734.615888", latD: "N", lonD: "E", latitude: 0.7546206833333333, longitude: 127.57693146666666, timestamp: "2024-06-06T12:38:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "09", hdop: "0.6", lat: "0045.277225", lon: "12734.615898", latD: "N", lonD: "E", latitude: 0.7546204166666667, longitude: 127.57693163333333, timestamp: "2024-06-06T12:39:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "09", hdop: "0.6", lat: "0045.277225", lon: "12734.615898", latD: "N", lonD: "E", latitude: 0.7546204166666667, longitude: 127.57693163333333, timestamp: "2024-06-06T12:39:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277195", lon: "12734.615915", latD: "N", lonD: "E", latitude: 0.7546199166666666, longitude: 127.57693191666667, timestamp: "2024-06-06T12:39:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277195", lon: "12734.615915", latD: "N", lonD: "E", latitude: 0.7546199166666666, longitude: 127.57693191666667, timestamp: "2024-06-06T12:39:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277195", lon: "12734.615915", latD: "N", lonD: "E", latitude: 0.7546199166666666, longitude: 127.57693191666667, timestamp: "2024-06-06T12:39:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.277164", lon: "12734.615930", latD: "N", lonD: "E", latitude: 0.7546193999999999, longitude: 127.57693216666667, timestamp: "2024-06-06T12:39:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.277164", lon: "12734.615930", latD: "N", lonD: "E", latitude: 0.7546193999999999, longitude: 127.57693216666667, timestamp: "2024-06-06T12:39:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.277164", lon: "12734.615930", latD: "N", lonD: "E", latitude: 0.7546193999999999, longitude: 127.57693216666667, timestamp: "2024-06-06T12:39:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.277164", lon: "12734.615930", latD: "N", lonD: "E", latitude: 0.7546193999999999, longitude: 127.57693216666667, timestamp: "2024-06-06T12:39:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.277164", lon: "12734.615930", latD: "N", lonD: "E", latitude: 0.7546193999999999, longitude: 127.57693216666667, timestamp: "2024-06-06T12:39:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.277164", lon: "12734.615930", latD: "N", lonD: "E", latitude: 0.7546193999999999, longitude: 127.57693216666667, timestamp: "2024-06-06T12:39:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277148", lon: "12734.615932", latD: "N", lonD: "E", latitude: 0.7546191333333333, longitude: 127.5769322, timestamp: "2024-06-06T12:40:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277148", lon: "12734.615932", latD: "N", lonD: "E", latitude: 0.7546191333333333, longitude: 127.5769322, timestamp: "2024-06-06T12:40:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277148", lon: "12734.615932", latD: "N", lonD: "E", latitude: 0.7546191333333333, longitude: 127.5769322, timestamp: "2024-06-06T12:40:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.277131", lon: "12734.615939", latD: "N", lonD: "E", latitude: 0.75461885, longitude: 127.57693231666667, timestamp: "2024-06-06T12:40:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.277131", lon: "12734.615939", latD: "N", lonD: "E", latitude: 0.75461885, longitude: 127.57693231666667, timestamp: "2024-06-06T12:40:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.277131", lon: "12734.615939", latD: "N", lonD: "E", latitude: 0.75461885, longitude: 127.57693231666667, timestamp: "2024-06-06T12:40:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.277131", lon: "12734.615939", latD: "N", lonD: "E", latitude: 0.75461885, longitude: 127.57693231666667, timestamp: "2024-06-06T12:40:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.277131", lon: "12734.615939", latD: "N", lonD: "E", latitude: 0.75461885, longitude: 127.57693231666667, timestamp: "2024-06-06T12:40:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.277109", lon: "12734.615949", latD: "N", lonD: "E", latitude: 0.7546184833333334, longitude: 127.57693248333334, timestamp: "2024-06-06T12:40:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.277109", lon: "12734.615949", latD: "N", lonD: "E", latitude: 0.7546184833333334, longitude: 127.57693248333334, timestamp: "2024-06-06T12:40:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277100", lon: "12734.615954", latD: "N", lonD: "E", latitude: 0.7546183333333333, longitude: 127.57693256666667, timestamp: "2024-06-06T12:41:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.277109", lon: "12734.615949", latD: "N", lonD: "E", latitude: 0.7546184833333334, longitude: 127.57693248333334, timestamp: "2024-06-06T12:40:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277100", lon: "12734.615954", latD: "N", lonD: "E", latitude: 0.7546183333333333, longitude: 127.57693256666667, timestamp: "2024-06-06T12:41:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277100", lon: "12734.615954", latD: "N", lonD: "E", latitude: 0.7546183333333333, longitude: 127.57693256666667, timestamp: "2024-06-06T12:41:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277100", lon: "12734.615954", latD: "N", lonD: "E", latitude: 0.7546183333333333, longitude: 127.57693256666667, timestamp: "2024-06-06T12:41:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.277079", lon: "12734.615962", latD: "N", lonD: "E", latitude: 0.7546179833333333, longitude: 127.5769327, timestamp: "2024-06-06T12:41:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277100", lon: "12734.615954", latD: "N", lonD: "E", latitude: 0.7546183333333333, longitude: 127.57693256666667, timestamp: "2024-06-06T12:41:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.277079", lon: "12734.615962", latD: "N", lonD: "E", latitude: 0.7546179833333333, longitude: 127.5769327, timestamp: "2024-06-06T12:41:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.277079", lon: "12734.615962", latD: "N", lonD: "E", latitude: 0.7546179833333333, longitude: 127.5769327, timestamp: "2024-06-06T12:41:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.277079", lon: "12734.615962", latD: "N", lonD: "E", latitude: 0.7546179833333333, longitude: 127.5769327, timestamp: "2024-06-06T12:41:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277049", lon: "12734.615965", latD: "N", lonD: "E", latitude: 0.7546174833333333, longitude: 127.57693275, timestamp: "2024-06-06T12:41:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277049", lon: "12734.615965", latD: "N", lonD: "E", latitude: 0.7546174833333333, longitude: 127.57693275, timestamp: "2024-06-06T12:41:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277049", lon: "12734.615965", latD: "N", lonD: "E", latitude: 0.7546174833333333, longitude: 127.57693275, timestamp: "2024-06-06T12:41:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277020", lon: "12734.615976", latD: "N", lonD: "E", latitude: 0.754617, longitude: 127.57693293333334, timestamp: "2024-06-06T12:42:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277020", lon: "12734.615976", latD: "N", lonD: "E", latitude: 0.754617, longitude: 127.57693293333334, timestamp: "2024-06-06T12:42:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277020", lon: "12734.615976", latD: "N", lonD: "E", latitude: 0.754617, longitude: 127.57693293333334, timestamp: "2024-06-06T12:42:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277020", lon: "12734.615976", latD: "N", lonD: "E", latitude: 0.754617, longitude: 127.57693293333334, timestamp: "2024-06-06T12:42:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277020", lon: "12734.615976", latD: "N", lonD: "E", latitude: 0.754617, longitude: 127.57693293333334, timestamp: "2024-06-06T12:42:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277008", lon: "12734.615984", latD: "N", lonD: "E", latitude: 0.7546168000000001, longitude: 127.57693306666667, timestamp: "2024-06-06T12:42:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.277002", lon: "12734.615993", latD: "N", lonD: "E", latitude: 0.7546167, longitude: 127.57693321666666, timestamp: "2024-06-06T12:42:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277008", lon: "12734.615984", latD: "N", lonD: "E", latitude: 0.7546168000000001, longitude: 127.57693306666667, timestamp: "2024-06-06T12:42:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.277002", lon: "12734.615993", latD: "N", lonD: "E", latitude: 0.7546167, longitude: 127.57693321666666, timestamp: "2024-06-06T12:42:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.277008", lon: "12734.615984", latD: "N", lonD: "E", latitude: 0.7546168000000001, longitude: 127.57693306666667, timestamp: "2024-06-06T12:42:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.277002", lon: "12734.615993", latD: "N", lonD: "E", latitude: 0.7546167, longitude: 127.57693321666666, timestamp: "2024-06-06T12:42:50.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276991", lon: "12734.615999", latD: "N", lonD: "E", latitude: 0.7546165166666667, longitude: 127.57693331666667, timestamp: "2024-06-06T12:43:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276991", lon: "12734.615999", latD: "N", lonD: "E", latitude: 0.7546165166666667, longitude: 127.57693331666667, timestamp: "2024-06-06T12:43:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276991", lon: "12734.615999", latD: "N", lonD: "E", latitude: 0.7546165166666667, longitude: 127.57693331666667, timestamp: "2024-06-06T12:43:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276991", lon: "12734.615999", latD: "N", lonD: "E", latitude: 0.7546165166666667, longitude: 127.57693331666667, timestamp: "2024-06-06T12:43:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276991", lon: "12734.615999", latD: "N", lonD: "E", latitude: 0.7546165166666667, longitude: 127.57693331666667, timestamp: "2024-06-06T12:43:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276991", lon: "12734.615999", latD: "N", lonD: "E", latitude: 0.7546165166666667, longitude: 127.57693331666667, timestamp: "2024-06-06T12:43:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "09", hdop: "0.6", lat: "0045.276970", lon: "12734.616003", latD: "N", lonD: "E", latitude: 0.7546161666666666, longitude: 127.57693338333333, timestamp: "2024-06-06T12:43:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "09", hdop: "0.6", lat: "0045.276970", lon: "12734.616003", latD: "N", lonD: "E", latitude: 0.7546161666666666, longitude: 127.57693338333333, timestamp: "2024-06-06T12:43:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "09", hdop: "0.6", lat: "0045.276970", lon: "12734.616003", latD: "N", lonD: "E", latitude: 0.7546161666666666, longitude: 127.57693338333333, timestamp: "2024-06-06T12:43:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276954", lon: "12734.615996", latD: "N", lonD: "E", latitude: 0.7546159, longitude: 127.57693326666667, timestamp: "2024-06-06T12:43:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276954", lon: "12734.615996", latD: "N", lonD: "E", latitude: 0.7546159, longitude: 127.57693326666667, timestamp: "2024-06-06T12:43:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276945", lon: "12734.615994", latD: "N", lonD: "E", latitude: 0.7546157499999999, longitude: 127.57693323333334, timestamp: "2024-06-06T12:44:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276945", lon: "12734.615994", latD: "N", lonD: "E", latitude: 0.7546157499999999, longitude: 127.57693323333334, timestamp: "2024-06-06T12:44:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276954", lon: "12734.615996", latD: "N", lonD: "E", latitude: 0.7546159, longitude: 127.57693326666667, timestamp: "2024-06-06T12:43:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276954", lon: "12734.615996", latD: "N", lonD: "E", latitude: 0.7546159, longitude: 127.57693326666667, timestamp: "2024-06-06T12:43:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276945", lon: "12734.615994", latD: "N", lonD: "E", latitude: 0.7546157499999999, longitude: 127.57693323333334, timestamp: "2024-06-06T12:44:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276945", lon: "12734.615994", latD: "N", lonD: "E", latitude: 0.7546157499999999, longitude: 127.57693323333334, timestamp: "2024-06-06T12:44:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276935", lon: "12734.615988", latD: "N", lonD: "E", latitude: 0.7546155833333333, longitude: 127.57693313333333, timestamp: "2024-06-06T12:44:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276945", lon: "12734.615994", latD: "N", lonD: "E", latitude: 0.7546157499999999, longitude: 127.57693323333334, timestamp: "2024-06-06T12:44:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276935", lon: "12734.615988", latD: "N", lonD: "E", latitude: 0.7546155833333333, longitude: 127.57693313333333, timestamp: "2024-06-06T12:44:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276935", lon: "12734.615988", latD: "N", lonD: "E", latitude: 0.7546155833333333, longitude: 127.57693313333333, timestamp: "2024-06-06T12:44:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.6", lat: "0045.276935", lon: "12734.615988", latD: "N", lonD: "E", latitude: 0.7546155833333333, longitude: 127.57693313333333, timestamp: "2024-06-06T12:44:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.5", lat: "0045.276920", lon: "12734.615983", latD: "N", lonD: "E", latitude: 0.7546153333333333, longitude: 127.57693305, timestamp: "2024-06-06T12:44:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.5", lat: "0045.276920", lon: "12734.615983", latD: "N", lonD: "E", latitude: 0.7546153333333333, longitude: 127.57693305, timestamp: "2024-06-06T12:44:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "11", hdop: "0.5", lat: "0045.276910", lon: "12734.615981", latD: "N", lonD: "E", latitude: 0.7546151666666667, longitude: 127.57693301666667, timestamp: "2024-06-06T12:45:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.5", lat: "0045.276920", lon: "12734.615983", latD: "N", lonD: "E", latitude: 0.7546153333333333, longitude: 127.57693305, timestamp: "2024-06-06T12:44:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "10", hdop: "0.5", lat: "0045.276920", lon: "12734.615983", latD: "N", lonD: "E", latitude: 0.7546153333333333, longitude: 127.57693305, timestamp: "2024-06-06T12:44:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "11", hdop: "0.5", lat: "0045.276910", lon: "12734.615981", latD: "N", lonD: "E", latitude: 0.7546151666666667, longitude: 127.57693301666667, timestamp: "2024-06-06T12:45:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "11", hdop: "0.5", lat: "0045.276910", lon: "12734.615981", latD: "N", lonD: "E", latitude: 0.7546151666666667, longitude: 127.57693301666667, timestamp: "2024-06-06T12:45:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "15.0", satellites: "11", hdop: "0.5", lat: "0045.276910", lon: "12734.615981", latD: "N", lonD: "E", latitude: 0.7546151666666667, longitude: 127.57693301666667, timestamp: "2024-06-06T12:45:10.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276910", lon: "12734.615980", latD: "N", lonD: "E", latitude: 0.7546151666666667, longitude: 127.576933, timestamp: "2024-06-06T12:45:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276910", lon: "12734.615980", latD: "N", lonD: "E", latitude: 0.7546151666666667, longitude: 127.576933, timestamp: "2024-06-06T12:45:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276910", lon: "12734.615980", latD: "N", lonD: "E", latitude: 0.7546151666666667, longitude: 127.576933, timestamp: "2024-06-06T12:45:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276910", lon: "12734.615980", latD: "N", lonD: "E", latitude: 0.7546151666666667, longitude: 127.576933, timestamp: "2024-06-06T12:45:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276910", lon: "12734.615980", latD: "N", lonD: "E", latitude: 0.7546151666666667, longitude: 127.576933, timestamp: "2024-06-06T12:45:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276910", lon: "12734.615980", latD: "N", lonD: "E", latitude: 0.7546151666666667, longitude: 127.576933, timestamp: "2024-06-06T12:45:30.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276913", lon: "12734.615986", latD: "N", lonD: "E", latitude: 0.7546152166666666, longitude: 127.5769331, timestamp: "2024-06-06T12:45:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276913", lon: "12734.615986", latD: "N", lonD: "E", latitude: 0.7546152166666666, longitude: 127.5769331, timestamp: "2024-06-06T12:45:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276913", lon: "12734.615986", latD: "N", lonD: "E", latitude: 0.7546152166666666, longitude: 127.5769331, timestamp: "2024-06-06T12:45:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276913", lon: "12734.615986", latD: "N", lonD: "E", latitude: 0.7546152166666666, longitude: 127.5769331, timestamp: "2024-06-06T12:45:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.5", lat: "0045.276913", lon: "12734.615990", latD: "N", lonD: "E", latitude: 0.7546152166666666, longitude: 127.57693316666666, timestamp: "2024-06-06T12:46:11.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.5", lat: "0045.276913", lon: "12734.615990", latD: "N", lonD: "E", latitude: 0.7546152166666666, longitude: 127.57693316666666, timestamp: "2024-06-06T12:46:11.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.5", lat: "0045.276913", lon: "12734.615990", latD: "N", lonD: "E", latitude: 0.7546152166666666, longitude: 127.57693316666666, timestamp: "2024-06-06T12:46:11.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.5", lat: "0045.276913", lon: "12734.615990", latD: "N", lonD: "E", latitude: 0.7546152166666666, longitude: 127.57693316666666, timestamp: "2024-06-06T12:46:11.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.276914", lon: "12734.615997", latD: "N", lonD: "E", latitude: 0.7546152333333332, longitude: 127.57693328333333, timestamp: "2024-06-06T12:46:31.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.276914", lon: "12734.615997", latD: "N", lonD: "E", latitude: 0.7546152333333332, longitude: 127.57693328333333, timestamp: "2024-06-06T12:46:31.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.276914", lon: "12734.615997", latD: "N", lonD: "E", latitude: 0.7546152333333332, longitude: 127.57693328333333, timestamp: "2024-06-06T12:46:31.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "10", hdop: "0.6", lat: "0045.276914", lon: "12734.615997", latD: "N", lonD: "E", latitude: 0.7546152333333332, longitude: 127.57693328333333, timestamp: "2024-06-06T12:46:31.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.6", lat: "0045.276921", lon: "12734.616000", latD: "N", lonD: "E", latitude: 0.75461535, longitude: 127.57693333333333, timestamp: "2024-06-06T12:46:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.6", lat: "0045.276921", lon: "12734.616000", latD: "N", lonD: "E", latitude: 0.75461535, longitude: 127.57693333333333, timestamp: "2024-06-06T12:46:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.6", lat: "0045.276921", lon: "12734.616000", latD: "N", lonD: "E", latitude: 0.75461535, longitude: 127.57693333333333, timestamp: "2024-06-06T12:46:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276917", lon: "12734.616003", latD: "N", lonD: "E", latitude: 0.7546152833333333, longitude: 127.57693338333333, timestamp: "2024-06-06T12:47:11.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.6", lat: "0045.276921", lon: "12734.616000", latD: "N", lonD: "E", latitude: 0.75461535, longitude: 127.57693333333333, timestamp: "2024-06-06T12:46:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276917", lon: "12734.616003", latD: "N", lonD: "E", latitude: 0.7546152833333333, longitude: 127.57693338333333, timestamp: "2024-06-06T12:47:11.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276917", lon: "12734.616003", latD: "N", lonD: "E", latitude: 0.7546152833333333, longitude: 127.57693338333333, timestamp: "2024-06-06T12:47:11.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276917", lon: "12734.616003", latD: "N", lonD: "E", latitude: 0.7546152833333333, longitude: 127.57693338333333, timestamp: "2024-06-06T12:47:11.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "11", hdop: "0.5", lat: "0045.276896", lon: "12734.616015", latD: "N", lonD: "E", latitude: 0.7546149333333333, longitude: 127.57693358333333, timestamp: "2024-06-06T12:47:31.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "11", hdop: "0.5", lat: "0045.276896", lon: "12734.616015", latD: "N", lonD: "E", latitude: 0.7546149333333333, longitude: 127.57693358333333, timestamp: "2024-06-06T12:47:31.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276887", lon: "12734.616022", latD: "N", lonD: "E", latitude: 0.7546147833333333, longitude: 127.5769337, timestamp: "2024-06-06T12:47:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276887", lon: "12734.616022", latD: "N", lonD: "E", latitude: 0.7546147833333333, longitude: 127.5769337, timestamp: "2024-06-06T12:47:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "11", hdop: "0.5", lat: "0045.276896", lon: "12734.616015", latD: "N", lonD: "E", latitude: 0.7546149333333333, longitude: 127.57693358333333, timestamp: "2024-06-06T12:47:31.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276887", lon: "12734.616022", latD: "N", lonD: "E", latitude: 0.7546147833333333, longitude: 127.5769337, timestamp: "2024-06-06T12:47:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276887", lon: "12734.616022", latD: "N", lonD: "E", latitude: 0.7546147833333333, longitude: 127.5769337, timestamp: "2024-06-06T12:47:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.9", satellites: "11", hdop: "0.5", lat: "0045.276887", lon: "12734.616022", latD: "N", lonD: "E", latitude: 0.7546147833333333, longitude: 127.5769337, timestamp: "2024-06-06T12:47:51.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "11", hdop: "0.5", lat: "0045.276882", lon: "12734.616028", latD: "N", lonD: "E", latitude: 0.7546147, longitude: 127.5769338, timestamp: "2024-06-06T12:48:11.000Z" },
        { speed: "1.4", course: "324.7", altitude: "7.3", satellites: "11", hdop: "0.5", lat: "0045.278811", lon: "12734.614579", latD: "N", lonD: "E", latitude: 0.75464685, longitude: 127.57690965, timestamp: "2024-06-06T12:48:31.000Z" },
        { speed: "1.4", course: "324.7", altitude: "7.3", satellites: "11", hdop: "0.5", lat: "0045.278811", lon: "12734.614579", latD: "N", lonD: "E", latitude: 0.75464685, longitude: 127.57690965, timestamp: "2024-06-06T12:48:31.000Z" },
        { speed: "1.4", course: "324.7", altitude: "7.3", satellites: "11", hdop: "0.5", lat: "0045.278811", lon: "12734.614579", latD: "N", lonD: "E", latitude: 0.75464685, longitude: 127.57690965, timestamp: "2024-06-06T12:48:31.000Z" },
        { speed: "0.0", course: "119.3", altitude: "14.8", satellites: "11", hdop: "0.5", lat: "0045.276882", lon: "12734.616028", latD: "N", lonD: "E", latitude: 0.7546147, longitude: 127.5769338, timestamp: "2024-06-06T12:48:11.000Z" },
        { speed: "1.4", course: "324.7", altitude: "7.3", satellites: "11", hdop: "0.5", lat: "0045.278811", lon: "12734.614579", latD: "N", lonD: "E", latitude: 0.75464685, longitude: 127.57690965, timestamp: "2024-06-06T12:48:31.000Z" },
        { speed: "2.1", course: "329.1", altitude: "5.2", satellites: "11", hdop: "0.5", lat: "0045.287505", lon: "12734.610261", latD: "N", lonD: "E", latitude: 0.7547917500000001, longitude: 127.57683768333334, timestamp: "2024-06-06T12:48:51.000Z" },
        { speed: "2.1", course: "329.1", altitude: "5.2", satellites: "11", hdop: "0.5", lat: "0045.287505", lon: "12734.610261", latD: "N", lonD: "E", latitude: 0.7547917500000001, longitude: 127.57683768333334, timestamp: "2024-06-06T12:48:51.000Z" },
        { speed: "1.4", course: "324.7", altitude: "7.3", satellites: "11", hdop: "0.5", lat: "0045.278811", lon: "12734.614579", latD: "N", lonD: "E", latitude: 0.75464685, longitude: 127.57690965, timestamp: "2024-06-06T12:48:31.000Z" },
        { speed: "2.1", course: "329.1", altitude: "5.2", satellites: "11", hdop: "0.5", lat: "0045.287505", lon: "12734.610261", latD: "N", lonD: "E", latitude: 0.7547917500000001, longitude: 127.57683768333334, timestamp: "2024-06-06T12:48:51.000Z" },
        { speed: "2.1", course: "329.1", altitude: "5.2", satellites: "11", hdop: "0.5", lat: "0045.287505", lon: "12734.610261", latD: "N", lonD: "E", latitude: 0.7547917500000001, longitude: 127.57683768333334, timestamp: "2024-06-06T12:48:51.000Z" },
        { speed: "2.4", course: "337.6", altitude: "-1.1", satellites: "11", hdop: "0.5", lat: "0045.299150", lon: "12734.604259", latD: "N", lonD: "E", latitude: 0.7549858333333332, longitude: 127.57673765, timestamp: "2024-06-06T12:49:11.000Z" },
        { speed: "2.4", course: "337.6", altitude: "-1.1", satellites: "11", hdop: "0.5", lat: "0045.299150", lon: "12734.604259", latD: "N", lonD: "E", latitude: 0.7549858333333332, longitude: 127.57673765, timestamp: "2024-06-06T12:49:11.000Z" },
        { speed: "2.4", course: "337.6", altitude: "-1.1", satellites: "11", hdop: "0.5", lat: "0045.299150", lon: "12734.604259", latD: "N", lonD: "E", latitude: 0.7549858333333332, longitude: 127.57673765, timestamp: "2024-06-06T12:49:11.000Z" },
        { speed: "3.2", course: "356.8", altitude: "3.8", satellites: "11", hdop: "0.5", lat: "0045.317643", lon: "12734.600809", latD: "N", lonD: "E", latitude: 0.7552940499999999, longitude: 127.57668015, timestamp: "2024-06-06T12:49:31.000Z" },
        { speed: "2.4", course: "337.6", altitude: "-1.1", satellites: "11", hdop: "0.5", lat: "0045.299150", lon: "12734.604259", latD: "N", lonD: "E", latitude: 0.7549858333333332, longitude: 127.57673765, timestamp: "2024-06-06T12:49:11.000Z" },
        { speed: "2.4", course: "337.6", altitude: "-1.1", satellites: "11", hdop: "0.5", lat: "0045.299150", lon: "12734.604259", latD: "N", lonD: "E", latitude: 0.7549858333333332, longitude: 127.57673765, timestamp: "2024-06-06T12:49:11.000Z" },
        { speed: "3.2", course: "356.8", altitude: "3.8", satellites: "11", hdop: "0.5", lat: "0045.317643", lon: "12734.600809", latD: "N", lonD: "E", latitude: 0.7552940499999999, longitude: 127.57668015, timestamp: "2024-06-06T12:49:31.000Z" },
        { speed: "2.5", course: "14.8", altitude: "4.4", satellites: "11", hdop: "0.5", lat: "0045.335504", lon: "12734.605056", latD: "N", lonD: "E", latitude: 0.7555917333333333, longitude: 127.57675093333333, timestamp: "2024-06-06T12:49:51.000Z" },
        { speed: "2.5", course: "14.8", altitude: "4.4", satellites: "11", hdop: "0.5", lat: "0045.335504", lon: "12734.605056", latD: "N", lonD: "E", latitude: 0.7555917333333333, longitude: 127.57675093333333, timestamp: "2024-06-06T12:49:51.000Z" },
        { speed: "2.5", course: "14.8", altitude: "4.4", satellites: "11", hdop: "0.5", lat: "0045.335504", lon: "12734.605056", latD: "N", lonD: "E", latitude: 0.7555917333333333, longitude: 127.57675093333333, timestamp: "2024-06-06T12:49:51.000Z" },
        { speed: "3.2", course: "348.4", altitude: "6.6", satellites: "11", hdop: "0.5", lat: "0045.347867", lon: "12734.606217", latD: "N", lonD: "E", latitude: 0.7557977833333334, longitude: 127.57677028333333, timestamp: "2024-06-06T12:50:11.000Z" },
        { speed: "2.5", course: "14.8", altitude: "4.4", satellites: "11", hdop: "0.5", lat: "0045.335504", lon: "12734.605056", latD: "N", lonD: "E", latitude: 0.7555917333333333, longitude: 127.57675093333333, timestamp: "2024-06-06T12:49:51.000Z" },
        { speed: "2.5", course: "14.8", altitude: "4.4", satellites: "11", hdop: "0.5", lat: "0045.335504", lon: "12734.605056", latD: "N", lonD: "E", latitude: 0.7555917333333333, longitude: 127.57675093333333, timestamp: "2024-06-06T12:49:51.000Z" },
        { speed: "2.5", course: "14.8", altitude: "4.4", satellites: "11", hdop: "0.5", lat: "0045.335504", lon: "12734.605056", latD: "N", lonD: "E", latitude: 0.7555917333333333, longitude: 127.57675093333333, timestamp: "2024-06-06T12:49:51.000Z" },
        { speed: "4.9", course: "336.4", altitude: "6.2", satellites: "11", hdop: "0.5", lat: "0045.372024", lon: "12734.597310", latD: "N", lonD: "E", latitude: 0.7562004000000001, longitude: 127.57662183333333, timestamp: "2024-06-06T12:50:31.000Z" },
        { speed: "4.9", course: "336.4", altitude: "6.2", satellites: "11", hdop: "0.5", lat: "0045.372024", lon: "12734.597310", latD: "N", lonD: "E", latitude: 0.7562004000000001, longitude: 127.57662183333333, timestamp: "2024-06-06T12:50:31.000Z" },
        { speed: "4.9", course: "336.4", altitude: "6.2", satellites: "11", hdop: "0.5", lat: "0045.372024", lon: "12734.597310", latD: "N", lonD: "E", latitude: 0.7562004000000001, longitude: 127.57662183333333, timestamp: "2024-06-06T12:50:31.000Z" },
        { speed: "3.2", course: "348.4", altitude: "6.6", satellites: "11", hdop: "0.5", lat: "0045.347867", lon: "12734.606217", latD: "N", lonD: "E", latitude: 0.7557977833333334, longitude: 127.57677028333333, timestamp: "2024-06-06T12:50:11.000Z" },
        { speed: "4.9", course: "336.4", altitude: "6.2", satellites: "11", hdop: "0.5", lat: "0045.372024", lon: "12734.597310", latD: "N", lonD: "E", latitude: 0.7562004000000001, longitude: 127.57662183333333, timestamp: "2024-06-06T12:50:31.000Z" },
        { speed: "4.9", course: "336.4", altitude: "6.2", satellites: "11", hdop: "0.5", lat: "0045.372024", lon: "12734.597310", latD: "N", lonD: "E", latitude: 0.7562004000000001, longitude: 127.57662183333333, timestamp: "2024-06-06T12:50:31.000Z" },
        { speed: "6.1", course: "326.0", altitude: "9.2", satellites: "11", hdop: "0.5", lat: "0045.399448", lon: "12734.581144", latD: "N", lonD: "E", latitude: 0.7566574666666667, longitude: 127.5763524, timestamp: "2024-06-06T12:50:51.000Z" },
        { speed: "6.1", course: "326.0", altitude: "9.2", satellites: "11", hdop: "0.5", lat: "0045.399448", lon: "12734.581144", latD: "N", lonD: "E", latitude: 0.7566574666666667, longitude: 127.5763524, timestamp: "2024-06-06T12:50:51.000Z" },
        { speed: "6.1", course: "326.0", altitude: "9.2", satellites: "11", hdop: "0.5", lat: "0045.399448", lon: "12734.581144", latD: "N", lonD: "E", latitude: 0.7566574666666667, longitude: 127.5763524, timestamp: "2024-06-06T12:50:51.000Z" },
        { speed: "7.2", course: "310.7", altitude: "10.7", satellites: "11", hdop: "0.5", lat: "0045.428378", lon: "12734.556282", latD: "N", lonD: "E", latitude: 0.7571396333333333, longitude: 127.57593803333333, timestamp: "2024-06-06T12:51:11.000Z" },
        { speed: "6.1", course: "326.0", altitude: "9.2", satellites: "11", hdop: "0.5", lat: "0045.399448", lon: "12734.581144", latD: "N", lonD: "E", latitude: 0.7566574666666667, longitude: 127.5763524, timestamp: "2024-06-06T12:50:51.000Z" },
        { speed: "6.1", course: "326.0", altitude: "9.2", satellites: "11", hdop: "0.5", lat: "0045.399448", lon: "12734.581144", latD: "N", lonD: "E", latitude: 0.7566574666666667, longitude: 127.5763524, timestamp: "2024-06-06T12:50:51.000Z" },
        { speed: "7.2", course: "310.7", altitude: "10.7", satellites: "11", hdop: "0.5", lat: "0045.428378", lon: "12734.556282", latD: "N", lonD: "E", latitude: 0.7571396333333333, longitude: 127.57593803333333, timestamp: "2024-06-06T12:51:11.000Z" },
        { speed: "7.2", course: "310.7", altitude: "10.7", satellites: "11", hdop: "0.5", lat: "0045.428378", lon: "12734.556282", latD: "N", lonD: "E", latitude: 0.7571396333333333, longitude: 127.57593803333333, timestamp: "2024-06-06T12:51:11.000Z" },
        { speed: "7.2", course: "310.7", altitude: "10.7", satellites: "11", hdop: "0.5", lat: "0045.428378", lon: "12734.556282", latD: "N", lonD: "E", latitude: 0.7571396333333333, longitude: 127.57593803333333, timestamp: "2024-06-06T12:51:11.000Z" },
        { speed: "8.1", course: "293.3", altitude: "8.1", satellites: "11", hdop: "0.5", lat: "0045.449333", lon: "12734.520671", latD: "N", lonD: "E", latitude: 0.7574888833333334, longitude: 127.57534451666666, timestamp: "2024-06-06T12:51:31.000Z" },
        { speed: "8.1", course: "293.3", altitude: "8.1", satellites: "11", hdop: "0.5", lat: "0045.449333", lon: "12734.520671", latD: "N", lonD: "E", latitude: 0.7574888833333334, longitude: 127.57534451666666, timestamp: "2024-06-06T12:51:31.000Z" },
        { speed: "8.6", course: "282.0", altitude: "6.0", satellites: "10", hdop: "0.5", lat: "0045.462021", lon: "12734.478028", latD: "N", lonD: "E", latitude: 0.75770035, longitude: 127.5746338, timestamp: "2024-06-06T12:51:51.000Z" },
        { speed: "8.1", course: "293.3", altitude: "8.1", satellites: "11", hdop: "0.5", lat: "0045.449333", lon: "12734.520671", latD: "N", lonD: "E", latitude: 0.7574888833333334, longitude: 127.57534451666666, timestamp: "2024-06-06T12:51:31.000Z" },
        { speed: "8.1", course: "293.3", altitude: "8.1", satellites: "11", hdop: "0.5", lat: "0045.449333", lon: "12734.520671", latD: "N", lonD: "E", latitude: 0.7574888833333334, longitude: 127.57534451666666, timestamp: "2024-06-06T12:51:31.000Z" },
        { speed: "8.6", course: "282.0", altitude: "6.0", satellites: "10", hdop: "0.5", lat: "0045.462021", lon: "12734.478028", latD: "N", lonD: "E", latitude: 0.75770035, longitude: 127.5746338, timestamp: "2024-06-06T12:51:51.000Z" },
        { speed: "8.6", course: "282.0", altitude: "6.0", satellites: "10", hdop: "0.5", lat: "0045.462021", lon: "12734.478028", latD: "N", lonD: "E", latitude: 0.75770035, longitude: 127.5746338, timestamp: "2024-06-06T12:51:51.000Z" },
        { speed: "8.7", course: "278.2", altitude: "2.7", satellites: "11", hdop: "0.5", lat: "0045.468628", lon: "12734.431417", latD: "N", lonD: "E", latitude: 0.7578104666666667, longitude: 127.57385695, timestamp: "2024-06-06T12:52:11.000Z" },
        { speed: "8.7", course: "278.2", altitude: "2.7", satellites: "11", hdop: "0.5", lat: "0045.468628", lon: "12734.431417", latD: "N", lonD: "E", latitude: 0.7578104666666667, longitude: 127.57385695, timestamp: "2024-06-06T12:52:11.000Z" },
        { speed: "8.7", course: "278.2", altitude: "2.7", satellites: "11", hdop: "0.5", lat: "0045.468628", lon: "12734.431417", latD: "N", lonD: "E", latitude: 0.7578104666666667, longitude: 127.57385695, timestamp: "2024-06-06T12:52:11.000Z" },
        { speed: "8.7", course: "277.2", altitude: "3.1", satellites: "11", hdop: "0.6", lat: "0045.474834", lon: "12734.385210", latD: "N", lonD: "E", latitude: 0.7579139, longitude: 127.57308683333333, timestamp: "2024-06-06T12:52:31.000Z" },
        { speed: "8.7", course: "277.2", altitude: "3.1", satellites: "11", hdop: "0.6", lat: "0045.474834", lon: "12734.385210", latD: "N", lonD: "E", latitude: 0.7579139, longitude: 127.57308683333333, timestamp: "2024-06-06T12:52:31.000Z" },
        { speed: "8.7", course: "277.2", altitude: "3.1", satellites: "11", hdop: "0.6", lat: "0045.474834", lon: "12734.385210", latD: "N", lonD: "E", latitude: 0.7579139, longitude: 127.57308683333333, timestamp: "2024-06-06T12:52:31.000Z" },
        { speed: "8.7", course: "277.2", altitude: "3.1", satellites: "11", hdop: "0.6", lat: "0045.474834", lon: "12734.385210", latD: "N", lonD: "E", latitude: 0.7579139, longitude: 127.57308683333333, timestamp: "2024-06-06T12:52:31.000Z" },
        { speed: "8.7", course: "277.2", altitude: "3.1", satellites: "11", hdop: "0.6", lat: "0045.474834", lon: "12734.385210", latD: "N", lonD: "E", latitude: 0.7579139, longitude: 127.57308683333333, timestamp: "2024-06-06T12:52:31.000Z" },
        { speed: "8.7", course: "277.2", altitude: "3.1", satellites: "11", hdop: "0.6", lat: "0045.474834", lon: "12734.385210", latD: "N", lonD: "E", latitude: 0.7579139, longitude: 127.57308683333333, timestamp: "2024-06-06T12:52:31.000Z" },
        { speed: "8.8", course: "280.9", altitude: "3.3", satellites: "11", hdop: "0.5", lat: "0045.483296", lon: "12734.337148", latD: "N", lonD: "E", latitude: 0.7580549333333334, longitude: 127.5722858, timestamp: "2024-06-06T12:52:51.000Z" },
        { speed: "8.8", course: "280.9", altitude: "3.3", satellites: "11", hdop: "0.5", lat: "0045.483296", lon: "12734.337148", latD: "N", lonD: "E", latitude: 0.7580549333333334, longitude: 127.5722858, timestamp: "2024-06-06T12:52:51.000Z" },
        { speed: "8.6", course: "284.4", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.494966", lon: "12734.289953", latD: "N", lonD: "E", latitude: 0.7582494333333333, longitude: 127.57149921666667, timestamp: "2024-06-06T12:53:11.000Z" },
        { speed: "8.8", course: "280.9", altitude: "3.3", satellites: "11", hdop: "0.5", lat: "0045.483296", lon: "12734.337148", latD: "N", lonD: "E", latitude: 0.7580549333333334, longitude: 127.5722858, timestamp: "2024-06-06T12:52:51.000Z" },
        { speed: "8.8", course: "280.9", altitude: "3.3", satellites: "11", hdop: "0.5", lat: "0045.483296", lon: "12734.337148", latD: "N", lonD: "E", latitude: 0.7580549333333334, longitude: 127.5722858, timestamp: "2024-06-06T12:52:51.000Z" },
        { speed: "8.6", course: "284.4", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.494966", lon: "12734.289953", latD: "N", lonD: "E", latitude: 0.7582494333333333, longitude: 127.57149921666667, timestamp: "2024-06-06T12:53:11.000Z" },
        { speed: "8.6", course: "284.4", altitude: "0.6", satellites: "09", hdop: "0.5", lat: "0045.507765", lon: "12734.244092", latD: "N", lonD: "E", latitude: 0.75846275, longitude: 127.57073486666667, timestamp: "2024-06-06T12:53:31.000Z" },
        { speed: "8.6", course: "284.4", altitude: "0.6", satellites: "09", hdop: "0.5", lat: "0045.507765", lon: "12734.244092", latD: "N", lonD: "E", latitude: 0.75846275, longitude: 127.57073486666667, timestamp: "2024-06-06T12:53:31.000Z" },
        { speed: "8.6", course: "284.4", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.494966", lon: "12734.289953", latD: "N", lonD: "E", latitude: 0.7582494333333333, longitude: 127.57149921666667, timestamp: "2024-06-06T12:53:11.000Z" },
        { speed: "8.6", course: "284.4", altitude: "0.6", satellites: "09", hdop: "0.5", lat: "0045.507765", lon: "12734.244092", latD: "N", lonD: "E", latitude: 0.75846275, longitude: 127.57073486666667, timestamp: "2024-06-06T12:53:31.000Z" },
        { speed: "8.6", course: "284.4", altitude: "0.6", satellites: "09", hdop: "0.5", lat: "0045.507765", lon: "12734.244092", latD: "N", lonD: "E", latitude: 0.75846275, longitude: 127.57073486666667, timestamp: "2024-06-06T12:53:31.000Z" },
        { speed: "8.5", course: "281.1", altitude: "2.3", satellites: "11", hdop: "0.5", lat: "0045.518118", lon: "12734.196990", latD: "N", lonD: "E", latitude: 0.7586353, longitude: 127.56994983333334, timestamp: "2024-06-06T12:53:51.000Z" },
        { speed: "8.5", course: "281.1", altitude: "2.3", satellites: "11", hdop: "0.5", lat: "0045.518118", lon: "12734.196990", latD: "N", lonD: "E", latitude: 0.7586353, longitude: 127.56994983333334, timestamp: "2024-06-06T12:53:51.000Z" },
        { speed: "8.5", course: "281.1", altitude: "2.3", satellites: "11", hdop: "0.5", lat: "0045.518118", lon: "12734.196990", latD: "N", lonD: "E", latitude: 0.7586353, longitude: 127.56994983333334, timestamp: "2024-06-06T12:53:51.000Z" },
        { speed: "8.6", course: "279.5", altitude: "-0.4", satellites: "11", hdop: "0.5", lat: "0045.525762", lon: "12734.150622", latD: "N", lonD: "E", latitude: 0.7587627, longitude: 127.56917703333333, timestamp: "2024-06-06T12:54:11.000Z" },
        { speed: "8.5", course: "281.1", altitude: "2.3", satellites: "11", hdop: "0.5", lat: "0045.518118", lon: "12734.196990", latD: "N", lonD: "E", latitude: 0.7586353, longitude: 127.56994983333334, timestamp: "2024-06-06T12:53:51.000Z" },
        { speed: "8.5", course: "281.1", altitude: "2.3", satellites: "11", hdop: "0.5", lat: "0045.518118", lon: "12734.196990", latD: "N", lonD: "E", latitude: 0.7586353, longitude: 127.56994983333334, timestamp: "2024-06-06T12:53:51.000Z" },
        { speed: "8.6", course: "279.5", altitude: "-0.4", satellites: "11", hdop: "0.5", lat: "0045.525762", lon: "12734.150622", latD: "N", lonD: "E", latitude: 0.7587627, longitude: 127.56917703333333, timestamp: "2024-06-06T12:54:11.000Z" },
        { speed: "8.9", course: "279.2", altitude: "1.7", satellites: "10", hdop: "0.5", lat: "0045.533585", lon: "12734.104427", latD: "N", lonD: "E", latitude: 0.7588930833333334, longitude: 127.56840711666666, timestamp: "2024-06-06T12:54:31.000Z" },
        { speed: "8.9", course: "279.2", altitude: "1.7", satellites: "10", hdop: "0.5", lat: "0045.533585", lon: "12734.104427", latD: "N", lonD: "E", latitude: 0.7588930833333334, longitude: 127.56840711666666, timestamp: "2024-06-06T12:54:31.000Z" },
        { speed: "8.9", course: "279.2", altitude: "1.7", satellites: "10", hdop: "0.5", lat: "0045.533585", lon: "12734.104427", latD: "N", lonD: "E", latitude: 0.7588930833333334, longitude: 127.56840711666666, timestamp: "2024-06-06T12:54:31.000Z" },
        { speed: "8.6", course: "278.2", altitude: "3.7", satellites: "11", hdop: "0.5", lat: "0045.541439", lon: "12734.057728", latD: "N", lonD: "E", latitude: 0.7590239833333333, longitude: 127.5676288, timestamp: "2024-06-06T12:54:51.000Z" },
        { speed: "8.9", course: "279.2", altitude: "1.7", satellites: "10", hdop: "0.5", lat: "0045.533585", lon: "12734.104427", latD: "N", lonD: "E", latitude: 0.7588930833333334, longitude: 127.56840711666666, timestamp: "2024-06-06T12:54:31.000Z" },
        { speed: "8.9", course: "279.2", altitude: "1.7", satellites: "10", hdop: "0.5", lat: "0045.533585", lon: "12734.104427", latD: "N", lonD: "E", latitude: 0.7588930833333334, longitude: 127.56840711666666, timestamp: "2024-06-06T12:54:31.000Z" },
        { speed: "8.9", course: "279.2", altitude: "1.7", satellites: "10", hdop: "0.5", lat: "0045.533585", lon: "12734.104427", latD: "N", lonD: "E", latitude: 0.7588930833333334, longitude: 127.56840711666666, timestamp: "2024-06-06T12:54:31.000Z" },
        { speed: "8.6", course: "278.2", altitude: "3.7", satellites: "11", hdop: "0.5", lat: "0045.541439", lon: "12734.057728", latD: "N", lonD: "E", latitude: 0.7590239833333333, longitude: 127.5676288, timestamp: "2024-06-06T12:54:51.000Z" },
        { speed: "8.5", course: "279.3", altitude: "3.3", satellites: "11", hdop: "0.6", lat: "0045.547750", lon: "12734.009740", latD: "N", lonD: "E", latitude: 0.7591291666666666, longitude: 127.566829, timestamp: "2024-06-06T12:55:11.000Z" },
        { speed: "8.5", course: "279.3", altitude: "3.3", satellites: "11", hdop: "0.6", lat: "0045.547750", lon: "12734.009740", latD: "N", lonD: "E", latitude: 0.7591291666666666, longitude: 127.566829, timestamp: "2024-06-06T12:55:11.000Z" },
        { speed: "8.6", course: "278.2", altitude: "3.7", satellites: "11", hdop: "0.5", lat: "0045.541439", lon: "12734.057728", latD: "N", lonD: "E", latitude: 0.7590239833333333, longitude: 127.5676288, timestamp: "2024-06-06T12:54:51.000Z" },
        { speed: "8.5", course: "279.3", altitude: "3.3", satellites: "11", hdop: "0.6", lat: "0045.547750", lon: "12734.009740", latD: "N", lonD: "E", latitude: 0.7591291666666666, longitude: 127.566829, timestamp: "2024-06-06T12:55:11.000Z" },
        { speed: "8.5", course: "277.4", altitude: "5.5", satellites: "11", hdop: "0.6", lat: "0045.554634", lon: "12733.961658", latD: "N", lonD: "E", latitude: 0.7592439, longitude: 127.56602763333333, timestamp: "2024-06-06T12:55:31.000Z" },
        { speed: "8.5", course: "277.4", altitude: "5.5", satellites: "11", hdop: "0.6", lat: "0045.554634", lon: "12733.961658", latD: "N", lonD: "E", latitude: 0.7592439, longitude: 127.56602763333333, timestamp: "2024-06-06T12:55:31.000Z" },
        { speed: "8.5", course: "277.4", altitude: "5.5", satellites: "11", hdop: "0.6", lat: "0045.554634", lon: "12733.961658", latD: "N", lonD: "E", latitude: 0.7592439, longitude: 127.56602763333333, timestamp: "2024-06-06T12:55:31.000Z" },
        { speed: "8.5", course: "277.4", altitude: "5.5", satellites: "11", hdop: "0.6", lat: "0045.554634", lon: "12733.961658", latD: "N", lonD: "E", latitude: 0.7592439, longitude: 127.56602763333333, timestamp: "2024-06-06T12:55:31.000Z" },
        { speed: "8.6", course: "274.2", altitude: "10.5", satellites: "11", hdop: "0.5", lat: "0045.560876", lon: "12733.913693", latD: "N", lonD: "E", latitude: 0.7593479333333334, longitude: 127.56522821666667, timestamp: "2024-06-06T12:55:51.000Z" },
        { speed: "8.6", course: "274.2", altitude: "10.5", satellites: "11", hdop: "0.5", lat: "0045.560876", lon: "12733.913693", latD: "N", lonD: "E", latitude: 0.7593479333333334, longitude: 127.56522821666667, timestamp: "2024-06-06T12:55:51.000Z" },
        { speed: "8.5", course: "277.4", altitude: "5.5", satellites: "11", hdop: "0.6", lat: "0045.554634", lon: "12733.961658", latD: "N", lonD: "E", latitude: 0.7592439, longitude: 127.56602763333333, timestamp: "2024-06-06T12:55:31.000Z" },
        { speed: "8.6", course: "274.2", altitude: "10.5", satellites: "11", hdop: "0.5", lat: "0045.560876", lon: "12733.913693", latD: "N", lonD: "E", latitude: 0.7593479333333334, longitude: 127.56522821666667, timestamp: "2024-06-06T12:55:51.000Z" },
        { speed: "8.6", course: "274.2", altitude: "10.5", satellites: "11", hdop: "0.5", lat: "0045.560876", lon: "12733.913693", latD: "N", lonD: "E", latitude: 0.7593479333333334, longitude: 127.56522821666667, timestamp: "2024-06-06T12:55:51.000Z" },
        { speed: "8.5", course: "275.0", altitude: "10.6", satellites: "11", hdop: "0.5", lat: "0045.567447", lon: "12733.864149", latD: "N", lonD: "E", latitude: 0.75945745, longitude: 127.56440248333334, timestamp: "2024-06-06T12:56:11.000Z" },
        { speed: "8.5", course: "275.0", altitude: "10.6", satellites: "11", hdop: "0.5", lat: "0045.567447", lon: "12733.864149", latD: "N", lonD: "E", latitude: 0.75945745, longitude: 127.56440248333334, timestamp: "2024-06-06T12:56:11.000Z" },
        { speed: "8.4", course: "276.6", altitude: "7.3", satellites: "11", hdop: "0.6", lat: "0045.572017", lon: "12733.817090", latD: "N", lonD: "E", latitude: 0.7595336166666667, longitude: 127.56361816666667, timestamp: "2024-06-06T12:56:31.000Z" },
        { speed: "8.5", course: "275.0", altitude: "10.6", satellites: "11", hdop: "0.5", lat: "0045.567447", lon: "12733.864149", latD: "N", lonD: "E", latitude: 0.75945745, longitude: 127.56440248333334, timestamp: "2024-06-06T12:56:11.000Z" },
        { speed: "8.5", course: "275.0", altitude: "10.6", satellites: "11", hdop: "0.5", lat: "0045.567447", lon: "12733.864149", latD: "N", lonD: "E", latitude: 0.75945745, longitude: 127.56440248333334, timestamp: "2024-06-06T12:56:11.000Z" },
        { speed: "8.4", course: "276.6", altitude: "7.3", satellites: "11", hdop: "0.6", lat: "0045.572017", lon: "12733.817090", latD: "N", lonD: "E", latitude: 0.7595336166666667, longitude: 127.56361816666667, timestamp: "2024-06-06T12:56:31.000Z" },
        { speed: "8.4", course: "276.6", altitude: "7.3", satellites: "11", hdop: "0.6", lat: "0045.572017", lon: "12733.817090", latD: "N", lonD: "E", latitude: 0.7595336166666667, longitude: 127.56361816666667, timestamp: "2024-06-06T12:56:31.000Z" },
        { speed: "8.4", course: "276.6", altitude: "7.3", satellites: "11", hdop: "0.6", lat: "0045.572017", lon: "12733.817090", latD: "N", lonD: "E", latitude: 0.7595336166666667, longitude: 127.56361816666667, timestamp: "2024-06-06T12:56:31.000Z" },
        { speed: "8.4", course: "276.6", altitude: "7.3", satellites: "11", hdop: "0.6", lat: "0045.572017", lon: "12733.817090", latD: "N", lonD: "E", latitude: 0.7595336166666667, longitude: 127.56361816666667, timestamp: "2024-06-06T12:56:31.000Z" },
        { speed: "8.7", course: "279.4", altitude: "3.8", satellites: "11", hdop: "0.5", lat: "0045.577632", lon: "12733.772102", latD: "N", lonD: "E", latitude: 0.7596272000000001, longitude: 127.56286836666666, timestamp: "2024-06-06T12:56:51.000Z" },
        { speed: "8.7", course: "279.4", altitude: "3.8", satellites: "11", hdop: "0.5", lat: "0045.577632", lon: "12733.772102", latD: "N", lonD: "E", latitude: 0.7596272000000001, longitude: 127.56286836666666, timestamp: "2024-06-06T12:56:51.000Z" },
        { speed: "8.7", course: "279.4", altitude: "3.8", satellites: "11", hdop: "0.5", lat: "0045.577632", lon: "12733.772102", latD: "N", lonD: "E", latitude: 0.7596272000000001, longitude: 127.56286836666666, timestamp: "2024-06-06T12:56:51.000Z" },
        { speed: "8.7", course: "279.4", altitude: "3.8", satellites: "11", hdop: "0.5", lat: "0045.577632", lon: "12733.772102", latD: "N", lonD: "E", latitude: 0.7596272000000001, longitude: 127.56286836666666, timestamp: "2024-06-06T12:56:51.000Z" },
        { speed: "8.7", course: "278.6", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.585193", lon: "12733.723284", latD: "N", lonD: "E", latitude: 0.7597532166666666, longitude: 127.56205473333333, timestamp: "2024-06-06T12:57:11.000Z" },
        { speed: "8.7", course: "278.6", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.585193", lon: "12733.723284", latD: "N", lonD: "E", latitude: 0.7597532166666666, longitude: 127.56205473333333, timestamp: "2024-06-06T12:57:11.000Z" },
        { speed: "8.7", course: "278.6", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.585193", lon: "12733.723284", latD: "N", lonD: "E", latitude: 0.7597532166666666, longitude: 127.56205473333333, timestamp: "2024-06-06T12:57:11.000Z" },
        { speed: "8.9", course: "277.7", altitude: "1.5", satellites: "11", hdop: "0.6", lat: "0045.591060", lon: "12733.675052", latD: "N", lonD: "E", latitude: 0.7598509999999999, longitude: 127.56125086666667, timestamp: "2024-06-06T12:57:31.000Z" },
        { speed: "8.9", course: "277.7", altitude: "1.5", satellites: "11", hdop: "0.6", lat: "0045.591060", lon: "12733.675052", latD: "N", lonD: "E", latitude: 0.7598509999999999, longitude: 127.56125086666667, timestamp: "2024-06-06T12:57:31.000Z" },
        { speed: "8.5", course: "273.2", altitude: "6.8", satellites: "11", hdop: "0.5", lat: "0045.596723", lon: "12733.627021", latD: "N", lonD: "E", latitude: 0.7599453833333333, longitude: 127.56045035, timestamp: "2024-06-06T12:57:51.000Z" },
        { speed: "8.9", course: "277.7", altitude: "1.5", satellites: "11", hdop: "0.6", lat: "0045.591060", lon: "12733.675052", latD: "N", lonD: "E", latitude: 0.7598509999999999, longitude: 127.56125086666667, timestamp: "2024-06-06T12:57:31.000Z" },
        { speed: "8.9", course: "277.7", altitude: "1.5", satellites: "11", hdop: "0.6", lat: "0045.591060", lon: "12733.675052", latD: "N", lonD: "E", latitude: 0.7598509999999999, longitude: 127.56125086666667, timestamp: "2024-06-06T12:57:31.000Z" },
        { speed: "8.9", course: "277.7", altitude: "1.5", satellites: "11", hdop: "0.6", lat: "0045.591060", lon: "12733.675052", latD: "N", lonD: "E", latitude: 0.7598509999999999, longitude: 127.56125086666667, timestamp: "2024-06-06T12:57:31.000Z" },
        { speed: "8.5", course: "273.2", altitude: "6.8", satellites: "11", hdop: "0.5", lat: "0045.596723", lon: "12733.627021", latD: "N", lonD: "E", latitude: 0.7599453833333333, longitude: 127.56045035, timestamp: "2024-06-06T12:57:51.000Z" },
        { speed: "8.5", course: "273.2", altitude: "6.8", satellites: "11", hdop: "0.5", lat: "0045.596723", lon: "12733.627021", latD: "N", lonD: "E", latitude: 0.7599453833333333, longitude: 127.56045035, timestamp: "2024-06-06T12:57:51.000Z" },
        { speed: "8.5", course: "273.2", altitude: "6.8", satellites: "11", hdop: "0.5", lat: "0045.596723", lon: "12733.627021", latD: "N", lonD: "E", latitude: 0.7599453833333333, longitude: 127.56045035, timestamp: "2024-06-06T12:57:51.000Z" },
        { speed: "8.6", course: "272.3", altitude: "7.1", satellites: "11", hdop: "0.6", lat: "0045.598909", lon: "12733.578156", latD: "N", lonD: "E", latitude: 0.7599818166666666, longitude: 127.55963593333334, timestamp: "2024-06-06T12:58:11.000Z" },
        { speed: "8.7", course: "271.8", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.600490", lon: "12733.531089", latD: "N", lonD: "E", latitude: 0.7600081666666667, longitude: 127.55885148333333, timestamp: "2024-06-06T12:58:31.000Z" },
        { speed: "8.7", course: "271.8", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.600490", lon: "12733.531089", latD: "N", lonD: "E", latitude: 0.7600081666666667, longitude: 127.55885148333333, timestamp: "2024-06-06T12:58:31.000Z" },
        { speed: "8.7", course: "271.8", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.600490", lon: "12733.531089", latD: "N", lonD: "E", latitude: 0.7600081666666667, longitude: 127.55885148333333, timestamp: "2024-06-06T12:58:31.000Z" },
        { speed: "8.6", course: "272.3", altitude: "7.1", satellites: "11", hdop: "0.6", lat: "0045.598909", lon: "12733.578156", latD: "N", lonD: "E", latitude: 0.7599818166666666, longitude: 127.55963593333334, timestamp: "2024-06-06T12:58:11.000Z" },
        { speed: "8.7", course: "271.8", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.600490", lon: "12733.531089", latD: "N", lonD: "E", latitude: 0.7600081666666667, longitude: 127.55885148333333, timestamp: "2024-06-06T12:58:31.000Z" },
        { speed: "8.7", course: "271.8", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.600490", lon: "12733.531089", latD: "N", lonD: "E", latitude: 0.7600081666666667, longitude: 127.55885148333333, timestamp: "2024-06-06T12:58:31.000Z" },
        { speed: "8.7", course: "271.8", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.600490", lon: "12733.531089", latD: "N", lonD: "E", latitude: 0.7600081666666667, longitude: 127.55885148333333, timestamp: "2024-06-06T12:58:31.000Z" },
        { speed: "8.7", course: "272.5", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.602052", lon: "12733.482761", latD: "N", lonD: "E", latitude: 0.7600342, longitude: 127.55804601666667, timestamp: "2024-06-06T12:58:51.000Z" },
        { speed: "8.7", course: "272.5", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.602052", lon: "12733.482761", latD: "N", lonD: "E", latitude: 0.7600342, longitude: 127.55804601666667, timestamp: "2024-06-06T12:58:51.000Z" },
        { speed: "8.7", course: "272.5", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.602052", lon: "12733.482761", latD: "N", lonD: "E", latitude: 0.7600342, longitude: 127.55804601666667, timestamp: "2024-06-06T12:58:51.000Z" },
        { speed: "8.7", course: "272.5", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.602052", lon: "12733.482761", latD: "N", lonD: "E", latitude: 0.7600342, longitude: 127.55804601666667, timestamp: "2024-06-06T12:58:51.000Z" },
        { speed: "8.6", course: "273.7", altitude: "8.3", satellites: "10", hdop: "0.5", lat: "0045.604417", lon: "12733.434391", latD: "N", lonD: "E", latitude: 0.7600736166666666, longitude: 127.55723985, timestamp: "2024-06-06T12:59:11.000Z" },
        { speed: "8.5", course: "274.6", altitude: "12.6", satellites: "11", hdop: "0.6", lat: "0045.608022", lon: "12733.386679", latD: "N", lonD: "E", latitude: 0.7601337, longitude: 127.55644465, timestamp: "2024-06-06T12:59:31.000Z" },
        { speed: "8.5", course: "274.6", altitude: "12.6", satellites: "11", hdop: "0.6", lat: "0045.608022", lon: "12733.386679", latD: "N", lonD: "E", latitude: 0.7601337, longitude: 127.55644465, timestamp: "2024-06-06T12:59:31.000Z" },
        { speed: "8.6", course: "273.7", altitude: "8.3", satellites: "10", hdop: "0.5", lat: "0045.604417", lon: "12733.434391", latD: "N", lonD: "E", latitude: 0.7600736166666666, longitude: 127.55723985, timestamp: "2024-06-06T12:59:11.000Z" },
        { speed: "8.6", course: "273.7", altitude: "8.3", satellites: "10", hdop: "0.5", lat: "0045.604417", lon: "12733.434391", latD: "N", lonD: "E", latitude: 0.7600736166666666, longitude: 127.55723985, timestamp: "2024-06-06T12:59:11.000Z" },
        { speed: "8.6", course: "274.5", altitude: "11.0", satellites: "11", hdop: "0.6", lat: "0045.612027", lon: "12733.338220", latD: "N", lonD: "E", latitude: 0.7602004499999999, longitude: 127.555637, timestamp: "2024-06-06T12:59:51.000Z" },
        { speed: "8.5", course: "274.6", altitude: "12.6", satellites: "11", hdop: "0.6", lat: "0045.608022", lon: "12733.386679", latD: "N", lonD: "E", latitude: 0.7601337, longitude: 127.55644465, timestamp: "2024-06-06T12:59:31.000Z" },
        { speed: "8.6", course: "274.5", altitude: "11.0", satellites: "11", hdop: "0.6", lat: "0045.612027", lon: "12733.338220", latD: "N", lonD: "E", latitude: 0.7602004499999999, longitude: 127.555637, timestamp: "2024-06-06T12:59:51.000Z" },
        { speed: "8.6", course: "274.5", altitude: "11.0", satellites: "11", hdop: "0.6", lat: "0045.612027", lon: "12733.338220", latD: "N", lonD: "E", latitude: 0.7602004499999999, longitude: 127.555637, timestamp: "2024-06-06T12:59:51.000Z" },
        { speed: "8.6", course: "274.5", altitude: "11.0", satellites: "11", hdop: "0.6", lat: "0045.612027", lon: "12733.338220", latD: "N", lonD: "E", latitude: 0.7602004499999999, longitude: 127.555637, timestamp: "2024-06-06T12:59:51.000Z" },
        { speed: "8.6", course: "274.5", altitude: "11.0", satellites: "11", hdop: "0.6", lat: "0045.612027", lon: "12733.338220", latD: "N", lonD: "E", latitude: 0.7602004499999999, longitude: 127.555637, timestamp: "2024-06-06T12:59:51.000Z" },
        { speed: "8.6", course: "272.6", altitude: "6.6", satellites: "11", hdop: "0.4", lat: "0045.614866", lon: "12733.291597", latD: "N", lonD: "E", latitude: 0.7602477666666666, longitude: 127.55485995, timestamp: "2024-06-06T13:00:11.000Z" },
        { speed: "8.6", course: "272.6", altitude: "6.6", satellites: "11", hdop: "0.4", lat: "0045.614866", lon: "12733.291597", latD: "N", lonD: "E", latitude: 0.7602477666666666, longitude: 127.55485995, timestamp: "2024-06-06T13:00:11.000Z" },
        { speed: "8.6", course: "272.6", altitude: "6.6", satellites: "11", hdop: "0.4", lat: "0045.614866", lon: "12733.291597", latD: "N", lonD: "E", latitude: 0.7602477666666666, longitude: 127.55485995, timestamp: "2024-06-06T13:00:11.000Z" },
        { speed: "8.7", course: "272.5", altitude: "4.2", satellites: "11", hdop: "0.4", lat: "0045.615533", lon: "12733.244212", latD: "N", lonD: "E", latitude: 0.7602588833333334, longitude: 127.5540702, timestamp: "2024-06-06T13:00:31.000Z" },
        { speed: "8.7", course: "272.5", altitude: "4.2", satellites: "11", hdop: "0.4", lat: "0045.615533", lon: "12733.244212", latD: "N", lonD: "E", latitude: 0.7602588833333334, longitude: 127.5540702, timestamp: "2024-06-06T13:00:31.000Z" },
        { speed: "8.7", course: "272.5", altitude: "4.2", satellites: "11", hdop: "0.4", lat: "0045.615533", lon: "12733.244212", latD: "N", lonD: "E", latitude: 0.7602588833333334, longitude: 127.5540702, timestamp: "2024-06-06T13:00:31.000Z" },
        { speed: "8.7", course: "272.5", altitude: "4.2", satellites: "11", hdop: "0.4", lat: "0045.615533", lon: "12733.244212", latD: "N", lonD: "E", latitude: 0.7602588833333334, longitude: 127.5540702, timestamp: "2024-06-06T13:00:31.000Z" },
        { speed: "8.5", course: "276.1", altitude: "3.5", satellites: "11", hdop: "0.5", lat: "0045.617420", lon: "12733.197043", latD: "N", lonD: "E", latitude: 0.7602903333333334, longitude: 127.55328405, timestamp: "2024-06-06T13:00:51.000Z" },
        { speed: "8.7", course: "272.5", altitude: "4.2", satellites: "11", hdop: "0.4", lat: "0045.615533", lon: "12733.244212", latD: "N", lonD: "E", latitude: 0.7602588833333334, longitude: 127.5540702, timestamp: "2024-06-06T13:00:31.000Z" },
        { speed: "8.7", course: "272.5", altitude: "4.2", satellites: "11", hdop: "0.4", lat: "0045.615533", lon: "12733.244212", latD: "N", lonD: "E", latitude: 0.7602588833333334, longitude: 127.5540702, timestamp: "2024-06-06T13:00:31.000Z" },
        { speed: "8.5", course: "276.1", altitude: "3.5", satellites: "11", hdop: "0.5", lat: "0045.617420", lon: "12733.197043", latD: "N", lonD: "E", latitude: 0.7602903333333334, longitude: 127.55328405, timestamp: "2024-06-06T13:00:51.000Z" },
        { speed: "8.5", course: "276.1", altitude: "3.5", satellites: "11", hdop: "0.5", lat: "0045.617420", lon: "12733.197043", latD: "N", lonD: "E", latitude: 0.7602903333333334, longitude: 127.55328405, timestamp: "2024-06-06T13:00:51.000Z" },
        { speed: "8.6", course: "277.4", altitude: "4.4", satellites: "11", hdop: "0.5", lat: "0045.624158", lon: "12733.149646", latD: "N", lonD: "E", latitude: 0.7604026333333334, longitude: 127.5524941, timestamp: "2024-06-06T13:01:11.000Z" },
        { speed: "8.6", course: "277.4", altitude: "4.4", satellites: "11", hdop: "0.5", lat: "0045.624158", lon: "12733.149646", latD: "N", lonD: "E", latitude: 0.7604026333333334, longitude: 127.5524941, timestamp: "2024-06-06T13:01:11.000Z" },
        { speed: "8.6", course: "277.4", altitude: "4.4", satellites: "11", hdop: "0.5", lat: "0045.624158", lon: "12733.149646", latD: "N", lonD: "E", latitude: 0.7604026333333334, longitude: 127.5524941, timestamp: "2024-06-06T13:01:11.000Z" },
        { speed: "8.4", course: "275.9", altitude: "2.8", satellites: "11", hdop: "0.5", lat: "0045.629073", lon: "12733.104306", latD: "N", lonD: "E", latitude: 0.7604845499999999, longitude: 127.55173843333333, timestamp: "2024-06-06T13:01:31.000Z" },
        { speed: "8.4", course: "275.9", altitude: "2.8", satellites: "11", hdop: "0.5", lat: "0045.629073", lon: "12733.104306", latD: "N", lonD: "E", latitude: 0.7604845499999999, longitude: 127.55173843333333, timestamp: "2024-06-06T13:01:31.000Z" },
        { speed: "8.6", course: "277.4", altitude: "4.4", satellites: "11", hdop: "0.5", lat: "0045.624158", lon: "12733.149646", latD: "N", lonD: "E", latitude: 0.7604026333333334, longitude: 127.5524941, timestamp: "2024-06-06T13:01:11.000Z" },
        { speed: "8.4", course: "275.9", altitude: "2.8", satellites: "11", hdop: "0.5", lat: "0045.629073", lon: "12733.104306", latD: "N", lonD: "E", latitude: 0.7604845499999999, longitude: 127.55173843333333, timestamp: "2024-06-06T13:01:31.000Z" },
        { speed: "8.4", course: "275.9", altitude: "2.8", satellites: "11", hdop: "0.5", lat: "0045.629073", lon: "12733.104306", latD: "N", lonD: "E", latitude: 0.7604845499999999, longitude: 127.55173843333333, timestamp: "2024-06-06T13:01:31.000Z" },
        { speed: "8.4", course: "275.9", altitude: "2.8", satellites: "11", hdop: "0.5", lat: "0045.629073", lon: "12733.104306", latD: "N", lonD: "E", latitude: 0.7604845499999999, longitude: 127.55173843333333, timestamp: "2024-06-06T13:01:31.000Z" },
        { speed: "8.6", course: "274.9", altitude: "0.1", satellites: "10", hdop: "0.5", lat: "0045.633664", lon: "12733.055713", latD: "N", lonD: "E", latitude: 0.7605610666666667, longitude: 127.55092855, timestamp: "2024-06-06T13:01:51.000Z" },
        { speed: "8.6", course: "274.9", altitude: "0.1", satellites: "10", hdop: "0.5", lat: "0045.633664", lon: "12733.055713", latD: "N", lonD: "E", latitude: 0.7605610666666667, longitude: 127.55092855, timestamp: "2024-06-06T13:01:51.000Z" },
        { speed: "8.6", course: "274.9", altitude: "0.1", satellites: "10", hdop: "0.5", lat: "0045.633664", lon: "12733.055713", latD: "N", lonD: "E", latitude: 0.7605610666666667, longitude: 127.55092855, timestamp: "2024-06-06T13:01:51.000Z" },
        { speed: "8.6", course: "272.1", altitude: "3.3", satellites: "11", hdop: "0.5", lat: "0045.637419", lon: "12733.007226", latD: "N", lonD: "E", latitude: 0.76062365, longitude: 127.55012043333333, timestamp: "2024-06-06T13:02:11.000Z" },
        { speed: "8.6", course: "272.1", altitude: "3.3", satellites: "11", hdop: "0.5", lat: "0045.637419", lon: "12733.007226", latD: "N", lonD: "E", latitude: 0.76062365, longitude: 127.55012043333333, timestamp: "2024-06-06T13:02:11.000Z" },
        { speed: "8.6", course: "272.1", altitude: "3.3", satellites: "11", hdop: "0.5", lat: "0045.637419", lon: "12733.007226", latD: "N", lonD: "E", latitude: 0.76062365, longitude: 127.55012043333333, timestamp: "2024-06-06T13:02:11.000Z" },
        { speed: "8.6", course: "272.1", altitude: "3.3", satellites: "11", hdop: "0.5", lat: "0045.637419", lon: "12733.007226", latD: "N", lonD: "E", latitude: 0.76062365, longitude: 127.55012043333333, timestamp: "2024-06-06T13:02:11.000Z" },
        { speed: "8.6", course: "273.1", altitude: "7.6", satellites: "11", hdop: "0.5", lat: "0045.640098", lon: "12732.959619", latD: "N", lonD: "E", latitude: 0.7606683000000001, longitude: 127.54932698333333, timestamp: "2024-06-06T13:02:31.000Z" },
        { speed: "8.6", course: "273.1", altitude: "7.6", satellites: "11", hdop: "0.5", lat: "0045.640098", lon: "12732.959619", latD: "N", lonD: "E", latitude: 0.7606683000000001, longitude: 127.54932698333333, timestamp: "2024-06-06T13:02:31.000Z" },
        { speed: "8.6", course: "274.4", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.641544", lon: "12732.912727", latD: "N", lonD: "E", latitude: 0.7606924, longitude: 127.54854545, timestamp: "2024-06-06T13:02:51.000Z" },
        { speed: "8.6", course: "273.1", altitude: "7.6", satellites: "11", hdop: "0.5", lat: "0045.640098", lon: "12732.959619", latD: "N", lonD: "E", latitude: 0.7606683000000001, longitude: 127.54932698333333, timestamp: "2024-06-06T13:02:31.000Z" },
        { speed: "8.6", course: "273.1", altitude: "7.6", satellites: "11", hdop: "0.5", lat: "0045.640098", lon: "12732.959619", latD: "N", lonD: "E", latitude: 0.7606683000000001, longitude: 127.54932698333333, timestamp: "2024-06-06T13:02:31.000Z" },
        { speed: "8.6", course: "274.4", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.641544", lon: "12732.912727", latD: "N", lonD: "E", latitude: 0.7606924, longitude: 127.54854545, timestamp: "2024-06-06T13:02:51.000Z" },
        { speed: "8.6", course: "274.4", altitude: "3.5", satellites: "10", hdop: "0.6", lat: "0045.641544", lon: "12732.912727", latD: "N", lonD: "E", latitude: 0.7606924, longitude: 127.54854545, timestamp: "2024-06-06T13:02:51.000Z" },
        { speed: "8.7", course: "272.6", altitude: "7.1", satellites: "11", hdop: "0.5", lat: "0045.644073", lon: "12732.864790", latD: "N", lonD: "E", latitude: 0.76073455, longitude: 127.5477465, timestamp: "2024-06-06T13:03:11.000Z" },
        { speed: "8.7", course: "272.6", altitude: "7.1", satellites: "11", hdop: "0.5", lat: "0045.644073", lon: "12732.864790", latD: "N", lonD: "E", latitude: 0.76073455, longitude: 127.5477465, timestamp: "2024-06-06T13:03:11.000Z" },
        { speed: "8.7", course: "272.6", altitude: "7.1", satellites: "11", hdop: "0.5", lat: "0045.644073", lon: "12732.864790", latD: "N", lonD: "E", latitude: 0.76073455, longitude: 127.5477465, timestamp: "2024-06-06T13:03:11.000Z" },
        { speed: "8.7", course: "272.6", altitude: "7.1", satellites: "11", hdop: "0.5", lat: "0045.644073", lon: "12732.864790", latD: "N", lonD: "E", latitude: 0.76073455, longitude: 127.5477465, timestamp: "2024-06-06T13:03:11.000Z" },
        { speed: "8.7", course: "272.6", altitude: "7.1", satellites: "11", hdop: "0.5", lat: "0045.644073", lon: "12732.864790", latD: "N", lonD: "E", latitude: 0.76073455, longitude: 127.5477465, timestamp: "2024-06-06T13:03:11.000Z" },
        { speed: "8.7", course: "272.6", altitude: "7.1", satellites: "11", hdop: "0.5", lat: "0045.644073", lon: "12732.864790", latD: "N", lonD: "E", latitude: 0.76073455, longitude: 127.5477465, timestamp: "2024-06-06T13:03:11.000Z" },
        { speed: "8.5", course: "271.1", altitude: "6.7", satellites: "11", hdop: "0.5", lat: "0045.646006", lon: "12732.817182", latD: "N", lonD: "E", latitude: 0.7607667666666666, longitude: 127.54695303333334, timestamp: "2024-06-06T13:03:31.000Z" },
        { speed: "8.5", course: "271.1", altitude: "6.7", satellites: "11", hdop: "0.5", lat: "0045.646006", lon: "12732.817182", latD: "N", lonD: "E", latitude: 0.7607667666666666, longitude: 127.54695303333334, timestamp: "2024-06-06T13:03:31.000Z" },
        { speed: "8.4", course: "273.9", altitude: "9.2", satellites: "11", hdop: "0.5", lat: "0045.648667", lon: "12732.770060", latD: "N", lonD: "E", latitude: 0.7608111166666667, longitude: 127.54616766666666, timestamp: "2024-06-06T13:03:51.000Z" },
        { speed: "8.5", course: "271.1", altitude: "6.7", satellites: "11", hdop: "0.5", lat: "0045.646006", lon: "12732.817182", latD: "N", lonD: "E", latitude: 0.7607667666666666, longitude: 127.54695303333334, timestamp: "2024-06-06T13:03:31.000Z" },
        { speed: "8.5", course: "271.1", altitude: "6.7", satellites: "11", hdop: "0.5", lat: "0045.646006", lon: "12732.817182", latD: "N", lonD: "E", latitude: 0.7607667666666666, longitude: 127.54695303333334, timestamp: "2024-06-06T13:03:31.000Z" },
        { speed: "8.4", course: "273.9", altitude: "9.2", satellites: "11", hdop: "0.5", lat: "0045.648667", lon: "12732.770060", latD: "N", lonD: "E", latitude: 0.7608111166666667, longitude: 127.54616766666666, timestamp: "2024-06-06T13:03:51.000Z" },
        { speed: "8.4", course: "275.6", altitude: "9.5", satellites: "11", hdop: "0.6", lat: "0045.654443", lon: "12732.720328", latD: "N", lonD: "E", latitude: 0.7609073833333333, longitude: 127.5453388, timestamp: "2024-06-06T13:04:11.000Z" },
        { speed: "8.4", course: "275.6", altitude: "9.5", satellites: "11", hdop: "0.6", lat: "0045.654443", lon: "12732.720328", latD: "N", lonD: "E", latitude: 0.7609073833333333, longitude: 127.5453388, timestamp: "2024-06-06T13:04:11.000Z" },
        { speed: "8.4", course: "275.6", altitude: "9.5", satellites: "11", hdop: "0.6", lat: "0045.654443", lon: "12732.720328", latD: "N", lonD: "E", latitude: 0.7609073833333333, longitude: 127.5453388, timestamp: "2024-06-06T13:04:11.000Z" },
        { speed: "8.4", course: "275.6", altitude: "9.5", satellites: "11", hdop: "0.6", lat: "0045.654443", lon: "12732.720328", latD: "N", lonD: "E", latitude: 0.7609073833333333, longitude: 127.5453388, timestamp: "2024-06-06T13:04:11.000Z" },
        { speed: "8.7", course: "276.1", altitude: "9.7", satellites: "11", hdop: "0.6", lat: "0045.658564", lon: "12732.672065", latD: "N", lonD: "E", latitude: 0.7609760666666666, longitude: 127.54453441666666, timestamp: "2024-06-06T13:04:31.000Z" },
        { speed: "8.7", course: "276.1", altitude: "9.7", satellites: "11", hdop: "0.6", lat: "0045.658564", lon: "12732.672065", latD: "N", lonD: "E", latitude: 0.7609760666666666, longitude: 127.54453441666666, timestamp: "2024-06-06T13:04:31.000Z" },
        { speed: "8.7", course: "276.1", altitude: "9.7", satellites: "11", hdop: "0.6", lat: "0045.658564", lon: "12732.672065", latD: "N", lonD: "E", latitude: 0.7609760666666666, longitude: 127.54453441666666, timestamp: "2024-06-06T13:04:31.000Z" },
        { speed: "8.2", course: "272.2", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.663164", lon: "12732.623692", latD: "N", lonD: "E", latitude: 0.7610527333333333, longitude: 127.5437282, timestamp: "2024-06-06T13:04:52.000Z" },
        { speed: "8.2", course: "272.2", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.663164", lon: "12732.623692", latD: "N", lonD: "E", latitude: 0.7610527333333333, longitude: 127.5437282, timestamp: "2024-06-06T13:04:52.000Z" },
        { speed: "8.2", course: "272.2", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.663164", lon: "12732.623692", latD: "N", lonD: "E", latitude: 0.7610527333333333, longitude: 127.5437282, timestamp: "2024-06-06T13:04:52.000Z" },
        { speed: "8.2", course: "272.2", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.663164", lon: "12732.623692", latD: "N", lonD: "E", latitude: 0.7610527333333333, longitude: 127.5437282, timestamp: "2024-06-06T13:04:52.000Z" },
        { speed: "8.6", course: "272.0", altitude: "13.7", satellites: "11", hdop: "0.6", lat: "0045.665351", lon: "12732.577067", latD: "N", lonD: "E", latitude: 0.7610891833333333, longitude: 127.54295111666667, timestamp: "2024-06-06T13:05:11.000Z" },
        { speed: "8.6", course: "272.0", altitude: "13.7", satellites: "11", hdop: "0.6", lat: "0045.665351", lon: "12732.577067", latD: "N", lonD: "E", latitude: 0.7610891833333333, longitude: 127.54295111666667, timestamp: "2024-06-06T13:05:11.000Z" },
        { speed: "8.6", course: "272.0", altitude: "13.7", satellites: "11", hdop: "0.6", lat: "0045.665351", lon: "12732.577067", latD: "N", lonD: "E", latitude: 0.7610891833333333, longitude: 127.54295111666667, timestamp: "2024-06-06T13:05:11.000Z" },
        { speed: "9.0", course: "273.6", altitude: "15.3", satellites: "10", hdop: "0.6", lat: "0045.667220", lon: "12732.527197", latD: "N", lonD: "E", latitude: 0.7611203333333333, longitude: 127.54211995, timestamp: "2024-06-06T13:05:32.000Z" },
        { speed: "9.0", course: "273.6", altitude: "15.3", satellites: "10", hdop: "0.6", lat: "0045.667220", lon: "12732.527197", latD: "N", lonD: "E", latitude: 0.7611203333333333, longitude: 127.54211995, timestamp: "2024-06-06T13:05:32.000Z" },
        { speed: "9.0", course: "273.6", altitude: "15.3", satellites: "10", hdop: "0.6", lat: "0045.667220", lon: "12732.527197", latD: "N", lonD: "E", latitude: 0.7611203333333333, longitude: 127.54211995, timestamp: "2024-06-06T13:05:32.000Z" },
        { speed: "9.0", course: "273.6", altitude: "15.3", satellites: "10", hdop: "0.6", lat: "0045.667220", lon: "12732.527197", latD: "N", lonD: "E", latitude: 0.7611203333333333, longitude: 127.54211995, timestamp: "2024-06-06T13:05:32.000Z" },
        { speed: "9.0", course: "273.6", altitude: "15.3", satellites: "10", hdop: "0.6", lat: "0045.667220", lon: "12732.527197", latD: "N", lonD: "E", latitude: 0.7611203333333333, longitude: 127.54211995, timestamp: "2024-06-06T13:05:32.000Z" },
        { speed: "8.9", course: "272.6", altitude: "11.8", satellites: "11", hdop: "0.6", lat: "0045.671465", lon: "12732.474839", latD: "N", lonD: "E", latitude: 0.7611910833333333, longitude: 127.54124731666667, timestamp: "2024-06-06T13:05:52.000Z" },
        { speed: "9.2", course: "273.7", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.673549", lon: "12732.428151", latD: "N", lonD: "E", latitude: 0.7612258166666667, longitude: 127.54046918333333, timestamp: "2024-06-06T13:06:11.000Z" },
        { speed: "8.9", course: "272.6", altitude: "11.8", satellites: "11", hdop: "0.6", lat: "0045.671465", lon: "12732.474839", latD: "N", lonD: "E", latitude: 0.7611910833333333, longitude: 127.54124731666667, timestamp: "2024-06-06T13:05:52.000Z" },
        { speed: "8.9", course: "272.6", altitude: "11.8", satellites: "11", hdop: "0.6", lat: "0045.671465", lon: "12732.474839", latD: "N", lonD: "E", latitude: 0.7611910833333333, longitude: 127.54124731666667, timestamp: "2024-06-06T13:05:52.000Z" },
        { speed: "8.9", course: "272.6", altitude: "11.8", satellites: "11", hdop: "0.6", lat: "0045.671465", lon: "12732.474839", latD: "N", lonD: "E", latitude: 0.7611910833333333, longitude: 127.54124731666667, timestamp: "2024-06-06T13:05:52.000Z" },
        { speed: "9.2", course: "273.7", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.673549", lon: "12732.428151", latD: "N", lonD: "E", latitude: 0.7612258166666667, longitude: 127.54046918333333, timestamp: "2024-06-06T13:06:11.000Z" },
        { speed: "9.2", course: "273.7", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.673549", lon: "12732.428151", latD: "N", lonD: "E", latitude: 0.7612258166666667, longitude: 127.54046918333333, timestamp: "2024-06-06T13:06:11.000Z" },
        { speed: "9.0", course: "274.1", altitude: "10.2", satellites: "11", hdop: "0.6", lat: "0045.676631", lon: "12732.375997", latD: "N", lonD: "E", latitude: 0.7612771833333334, longitude: 127.53959995, timestamp: "2024-06-06T13:06:32.000Z" },
        { speed: "9.0", course: "274.1", altitude: "10.2", satellites: "11", hdop: "0.6", lat: "0045.676631", lon: "12732.375997", latD: "N", lonD: "E", latitude: 0.7612771833333334, longitude: 127.53959995, timestamp: "2024-06-06T13:06:32.000Z" },
        { speed: "9.0", course: "274.1", altitude: "10.2", satellites: "11", hdop: "0.6", lat: "0045.676631", lon: "12732.375997", latD: "N", lonD: "E", latitude: 0.7612771833333334, longitude: 127.53959995, timestamp: "2024-06-06T13:06:32.000Z" },
        { speed: "9.0", course: "274.1", altitude: "10.2", satellites: "11", hdop: "0.6", lat: "0045.676631", lon: "12732.375997", latD: "N", lonD: "E", latitude: 0.7612771833333334, longitude: 127.53959995, timestamp: "2024-06-06T13:06:32.000Z" },
        { speed: "9.1", course: "273.6", altitude: "12.3", satellites: "11", hdop: "0.6", lat: "0045.679995", lon: "12732.326038", latD: "N", lonD: "E", latitude: 0.76133325, longitude: 127.5387673, timestamp: "2024-06-06T13:06:52.000Z" },
        { speed: "9.1", course: "273.6", altitude: "12.3", satellites: "11", hdop: "0.6", lat: "0045.679995", lon: "12732.326038", latD: "N", lonD: "E", latitude: 0.76133325, longitude: 127.5387673, timestamp: "2024-06-06T13:06:52.000Z" },
        { speed: "9.1", course: "273.6", altitude: "12.3", satellites: "11", hdop: "0.6", lat: "0045.679995", lon: "12732.326038", latD: "N", lonD: "E", latitude: 0.76133325, longitude: 127.5387673, timestamp: "2024-06-06T13:06:52.000Z" },
        { speed: "9.1", course: "273.6", altitude: "12.3", satellites: "11", hdop: "0.6", lat: "0045.679995", lon: "12732.326038", latD: "N", lonD: "E", latitude: 0.76133325, longitude: 127.5387673, timestamp: "2024-06-06T13:06:52.000Z" },
        { speed: "9.3", course: "274.3", altitude: "11.2", satellites: "11", hdop: "0.6", lat: "0045.683204", lon: "12732.274666", latD: "N", lonD: "E", latitude: 0.7613867333333334, longitude: 127.5379111, timestamp: "2024-06-06T13:07:12.000Z" },
        { speed: "9.3", course: "274.3", altitude: "11.2", satellites: "11", hdop: "0.6", lat: "0045.683204", lon: "12732.274666", latD: "N", lonD: "E", latitude: 0.7613867333333334, longitude: 127.5379111, timestamp: "2024-06-06T13:07:12.000Z" },
        { speed: "9.3", course: "274.3", altitude: "11.2", satellites: "11", hdop: "0.6", lat: "0045.683204", lon: "12732.274666", latD: "N", lonD: "E", latitude: 0.7613867333333334, longitude: 127.5379111, timestamp: "2024-06-06T13:07:12.000Z" },
        { speed: "9.3", course: "274.3", altitude: "11.2", satellites: "11", hdop: "0.6", lat: "0045.683204", lon: "12732.274666", latD: "N", lonD: "E", latitude: 0.7613867333333334, longitude: 127.5379111, timestamp: "2024-06-06T13:07:12.000Z" },
        { speed: "9.1", course: "273.4", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.686832", lon: "12732.223908", latD: "N", lonD: "E", latitude: 0.7614472, longitude: 127.53706513333333, timestamp: "2024-06-06T13:07:32.000Z" },
        { speed: "9.1", course: "273.4", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.686832", lon: "12732.223908", latD: "N", lonD: "E", latitude: 0.7614472, longitude: 127.53706513333333, timestamp: "2024-06-06T13:07:32.000Z" },
        { speed: "9.1", course: "270.4", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.687470", lon: "12732.173455", latD: "N", lonD: "E", latitude: 0.7614578333333333, longitude: 127.53622425, timestamp: "2024-06-06T13:07:52.000Z" },
        { speed: "9.1", course: "273.4", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.686832", lon: "12732.223908", latD: "N", lonD: "E", latitude: 0.7614472, longitude: 127.53706513333333, timestamp: "2024-06-06T13:07:32.000Z" },
        { speed: "9.1", course: "273.4", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.686832", lon: "12732.223908", latD: "N", lonD: "E", latitude: 0.7614472, longitude: 127.53706513333333, timestamp: "2024-06-06T13:07:32.000Z" },
        { speed: "9.1", course: "270.4", altitude: "3.4", satellites: "10", hdop: "0.6", lat: "0045.687470", lon: "12732.173455", latD: "N", lonD: "E", latitude: 0.7614578333333333, longitude: 127.53622425, timestamp: "2024-06-06T13:07:52.000Z" },
        { speed: "9.1", course: "269.5", altitude: "14.3", satellites: "12", hdop: "0.6", lat: "0045.725928", lon: "12731.611795", latD: "N", lonD: "E", latitude: 0.7620988000000001, longitude: 127.52686325, timestamp: "2024-06-06T13:11:32.000Z" },
        { speed: "9.2", course: "275.5", altitude: "6.1", satellites: "12", hdop: "0.5", lat: "0045.711520", lon: "12731.816914", latD: "N", lonD: "E", latitude: 0.7618586666666667, longitude: 127.5302819, timestamp: "2024-06-06T13:10:12.000Z" },
        { speed: "9.2", course: "275.5", altitude: "6.1", satellites: "12", hdop: "0.5", lat: "0045.711520", lon: "12731.816914", latD: "N", lonD: "E", latitude: 0.7618586666666667, longitude: 127.5302819, timestamp: "2024-06-06T13:10:12.000Z" },
        { speed: "9.1", course: "276.1", altitude: "9.2", satellites: "12", hdop: "0.6", lat: "0045.716054", lon: "12731.764309", latD: "N", lonD: "E", latitude: 0.7619342333333333, longitude: 127.52940515, timestamp: "2024-06-06T13:10:32.000Z" },
        { speed: "9.1", course: "276.1", altitude: "9.2", satellites: "12", hdop: "0.6", lat: "0045.716054", lon: "12731.764309", latD: "N", lonD: "E", latitude: 0.7619342333333333, longitude: 127.52940515, timestamp: "2024-06-06T13:10:32.000Z" },
        { speed: "9.1", course: "276.1", altitude: "9.2", satellites: "12", hdop: "0.6", lat: "0045.716054", lon: "12731.764309", latD: "N", lonD: "E", latitude: 0.7619342333333333, longitude: 127.52940515, timestamp: "2024-06-06T13:10:32.000Z" },
        { speed: "9.2", course: "274.7", altitude: "7.8", satellites: "12", hdop: "0.5", lat: "0045.722265", lon: "12731.715306", latD: "N", lonD: "E", latitude: 0.76203775, longitude: 127.52858843333334, timestamp: "2024-06-06T13:10:52.000Z" },
        { speed: "9.2", course: "274.7", altitude: "7.8", satellites: "12", hdop: "0.5", lat: "0045.722265", lon: "12731.715306", latD: "N", lonD: "E", latitude: 0.76203775, longitude: 127.52858843333334, timestamp: "2024-06-06T13:10:52.000Z" },
        { speed: "9.2", course: "274.7", altitude: "7.8", satellites: "12", hdop: "0.5", lat: "0045.722265", lon: "12731.715306", latD: "N", lonD: "E", latitude: 0.76203775, longitude: 127.52858843333334, timestamp: "2024-06-06T13:10:52.000Z" },
        { speed: "9.2", course: "274.7", altitude: "7.8", satellites: "12", hdop: "0.5", lat: "0045.722265", lon: "12731.715306", latD: "N", lonD: "E", latitude: 0.76203775, longitude: 127.52858843333334, timestamp: "2024-06-06T13:10:52.000Z" },
        { speed: "9.2", course: "274.7", altitude: "7.8", satellites: "12", hdop: "0.5", lat: "0045.722265", lon: "12731.715306", latD: "N", lonD: "E", latitude: 0.76203775, longitude: 127.52858843333334, timestamp: "2024-06-06T13:10:52.000Z" },
        { speed: "9.2", course: "272.1", altitude: "10.6", satellites: "12", hdop: "0.5", lat: "0045.725365", lon: "12731.663709", latD: "N", lonD: "E", latitude: 0.7620894166666666, longitude: 127.52772848333333, timestamp: "2024-06-06T13:11:12.000Z" },
        { speed: "9.2", course: "272.1", altitude: "10.6", satellites: "12", hdop: "0.5", lat: "0045.725365", lon: "12731.663709", latD: "N", lonD: "E", latitude: 0.7620894166666666, longitude: 127.52772848333333, timestamp: "2024-06-06T13:11:12.000Z" },
        { speed: "9.1", course: "269.5", altitude: "14.3", satellites: "12", hdop: "0.6", lat: "0045.725928", lon: "12731.611795", latD: "N", lonD: "E", latitude: 0.7620988000000001, longitude: 127.52686325, timestamp: "2024-06-06T13:11:32.000Z" },
        { speed: "9.2", course: "271.6", altitude: "16.2", satellites: "12", hdop: "0.5", lat: "0045.726612", lon: "12731.560384", latD: "N", lonD: "E", latitude: 0.7621102000000001, longitude: 127.5260064, timestamp: "2024-06-06T13:11:52.000Z" },
        { speed: "9.2", course: "271.6", altitude: "16.2", satellites: "12", hdop: "0.5", lat: "0045.726612", lon: "12731.560384", latD: "N", lonD: "E", latitude: 0.7621102000000001, longitude: 127.5260064, timestamp: "2024-06-06T13:11:52.000Z" },
        { speed: "9.2", course: "271.6", altitude: "16.2", satellites: "12", hdop: "0.5", lat: "0045.726612", lon: "12731.560384", latD: "N", lonD: "E", latitude: 0.7621102000000001, longitude: 127.5260064, timestamp: "2024-06-06T13:11:52.000Z" },
        { speed: "9.7", course: "272.3", altitude: "14.8", satellites: "12", hdop: "0.5", lat: "0045.730538", lon: "12731.508379", latD: "N", lonD: "E", latitude: 0.7621756333333334, longitude: 127.52513965, timestamp: "2024-06-06T13:12:12.000Z" },
        { speed: "9.2", course: "271.6", altitude: "16.2", satellites: "12", hdop: "0.5", lat: "0045.726612", lon: "12731.560384", latD: "N", lonD: "E", latitude: 0.7621102000000001, longitude: 127.5260064, timestamp: "2024-06-06T13:11:52.000Z" },
        { speed: "9.2", course: "271.6", altitude: "16.2", satellites: "12", hdop: "0.5", lat: "0045.726612", lon: "12731.560384", latD: "N", lonD: "E", latitude: 0.7621102000000001, longitude: 127.5260064, timestamp: "2024-06-06T13:11:52.000Z" },
        { speed: "9.7", course: "272.3", altitude: "14.8", satellites: "12", hdop: "0.5", lat: "0045.730538", lon: "12731.508379", latD: "N", lonD: "E", latitude: 0.7621756333333334, longitude: 127.52513965, timestamp: "2024-06-06T13:12:12.000Z" },
        { speed: "9.7", course: "272.3", altitude: "14.8", satellites: "12", hdop: "0.5", lat: "0045.730538", lon: "12731.508379", latD: "N", lonD: "E", latitude: 0.7621756333333334, longitude: 127.52513965, timestamp: "2024-06-06T13:12:12.000Z" },
        { speed: "9.3", course: "274.7", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.732723", lon: "12731.455879", latD: "N", lonD: "E", latitude: 0.76221205, longitude: 127.52426465, timestamp: "2024-06-06T13:12:32.000Z" },
        { speed: "9.3", course: "274.7", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.732723", lon: "12731.455879", latD: "N", lonD: "E", latitude: 0.76221205, longitude: 127.52426465, timestamp: "2024-06-06T13:12:32.000Z" },
        { speed: "9.3", course: "274.7", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.732723", lon: "12731.455879", latD: "N", lonD: "E", latitude: 0.76221205, longitude: 127.52426465, timestamp: "2024-06-06T13:12:32.000Z" },
        { speed: "9.3", course: "274.7", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.732723", lon: "12731.455879", latD: "N", lonD: "E", latitude: 0.76221205, longitude: 127.52426465, timestamp: "2024-06-06T13:12:32.000Z" },
        { speed: "9.3", course: "274.7", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.732723", lon: "12731.455879", latD: "N", lonD: "E", latitude: 0.76221205, longitude: 127.52426465, timestamp: "2024-06-06T13:12:32.000Z" },
        { speed: "9.3", course: "271.9", altitude: "6.0", satellites: "12", hdop: "0.5", lat: "0045.735314", lon: "12731.404775", latD: "N", lonD: "E", latitude: 0.7622552333333333, longitude: 127.52341291666667, timestamp: "2024-06-06T13:12:52.000Z" },
        { speed: "9.3", course: "271.9", altitude: "6.0", satellites: "12", hdop: "0.5", lat: "0045.735314", lon: "12731.404775", latD: "N", lonD: "E", latitude: 0.7622552333333333, longitude: 127.52341291666667, timestamp: "2024-06-06T13:12:52.000Z" },
        { speed: "9.3", course: "271.9", altitude: "6.0", satellites: "12", hdop: "0.5", lat: "0045.735314", lon: "12731.404775", latD: "N", lonD: "E", latitude: 0.7622552333333333, longitude: 127.52341291666667, timestamp: "2024-06-06T13:12:52.000Z" },
        { speed: "9.3", course: "271.9", altitude: "6.0", satellites: "12", hdop: "0.5", lat: "0045.735314", lon: "12731.404775", latD: "N", lonD: "E", latitude: 0.7622552333333333, longitude: 127.52341291666667, timestamp: "2024-06-06T13:12:52.000Z" },
        { speed: "9.3", course: "270.7", altitude: "7.4", satellites: "12", hdop: "0.5", lat: "0045.735665", lon: "12731.354728", latD: "N", lonD: "E", latitude: 0.7622610833333333, longitude: 127.5225788, timestamp: "2024-06-06T13:13:12.000Z" },
        { speed: "9.5", course: "273.4", altitude: "12.0", satellites: "12", hdop: "0.5", lat: "0045.736784", lon: "12731.302742", latD: "N", lonD: "E", latitude: 0.7622797333333333, longitude: 127.52171236666666, timestamp: "2024-06-06T13:13:32.000Z" },
        { speed: "9.5", course: "273.4", altitude: "12.0", satellites: "12", hdop: "0.5", lat: "0045.736784", lon: "12731.302742", latD: "N", lonD: "E", latitude: 0.7622797333333333, longitude: 127.52171236666666, timestamp: "2024-06-06T13:13:32.000Z" },
        { speed: "9.3", course: "270.7", altitude: "7.4", satellites: "12", hdop: "0.5", lat: "0045.735665", lon: "12731.354728", latD: "N", lonD: "E", latitude: 0.7622610833333333, longitude: 127.5225788, timestamp: "2024-06-06T13:13:12.000Z" },
        { speed: "9.3", course: "270.7", altitude: "7.4", satellites: "12", hdop: "0.5", lat: "0045.735665", lon: "12731.354728", latD: "N", lonD: "E", latitude: 0.7622610833333333, longitude: 127.5225788, timestamp: "2024-06-06T13:13:12.000Z" },
        { speed: "9.5", course: "273.4", altitude: "12.0", satellites: "12", hdop: "0.5", lat: "0045.736784", lon: "12731.302742", latD: "N", lonD: "E", latitude: 0.7622797333333333, longitude: 127.52171236666666, timestamp: "2024-06-06T13:13:32.000Z" },
        { speed: "9.5", course: "273.4", altitude: "12.0", satellites: "12", hdop: "0.5", lat: "0045.736784", lon: "12731.302742", latD: "N", lonD: "E", latitude: 0.7622797333333333, longitude: 127.52171236666666, timestamp: "2024-06-06T13:13:32.000Z" },
        { speed: "9.3", course: "271.1", altitude: "12.2", satellites: "12", hdop: "0.5", lat: "0045.737421", lon: "12731.251962", latD: "N", lonD: "E", latitude: 0.76229035, longitude: 127.52086603333333, timestamp: "2024-06-06T13:13:52.000Z" },
        { speed: "9.3", course: "271.1", altitude: "12.2", satellites: "12", hdop: "0.5", lat: "0045.737421", lon: "12731.251962", latD: "N", lonD: "E", latitude: 0.76229035, longitude: 127.52086603333333, timestamp: "2024-06-06T13:13:52.000Z" },
        { speed: "9.0", course: "269.7", altitude: "11.4", satellites: "12", hdop: "0.5", lat: "0045.737927", lon: "12731.150850", latD: "N", lonD: "E", latitude: 0.7622987833333333, longitude: 127.51918083333334, timestamp: "2024-06-06T13:14:32.000Z" },
        { speed: "9.1", course: "271.1", altitude: "9.6", satellites: "12", hdop: "0.5", lat: "0045.738380", lon: "12731.200811", latD: "N", lonD: "E", latitude: 0.7623063333333333, longitude: 127.52001351666667, timestamp: "2024-06-06T13:14:12.000Z" },
        { speed: "9.1", course: "271.1", altitude: "9.6", satellites: "12", hdop: "0.5", lat: "0045.738380", lon: "12731.200811", latD: "N", lonD: "E", latitude: 0.7623063333333333, longitude: 127.52001351666667, timestamp: "2024-06-06T13:14:12.000Z" },
        { speed: "9.3", course: "271.1", altitude: "12.2", satellites: "12", hdop: "0.5", lat: "0045.737421", lon: "12731.251962", latD: "N", lonD: "E", latitude: 0.76229035, longitude: 127.52086603333333, timestamp: "2024-06-06T13:13:52.000Z" },
        { speed: "9.0", course: "269.7", altitude: "11.4", satellites: "12", hdop: "0.5", lat: "0045.737927", lon: "12731.150850", latD: "N", lonD: "E", latitude: 0.7622987833333333, longitude: 127.51918083333334, timestamp: "2024-06-06T13:14:32.000Z" },
        { speed: "9.0", course: "269.7", altitude: "11.4", satellites: "12", hdop: "0.5", lat: "0045.737927", lon: "12731.150850", latD: "N", lonD: "E", latitude: 0.7622987833333333, longitude: 127.51918083333334, timestamp: "2024-06-06T13:14:32.000Z" },
        { speed: "9.2", course: "270.8", altitude: "9.6", satellites: "12", hdop: "0.6", lat: "0045.737575", lon: "12731.101112", latD: "N", lonD: "E", latitude: 0.7622929166666667, longitude: 127.51835186666666, timestamp: "2024-06-06T13:14:52.000Z" },
        { speed: "9.2", course: "270.8", altitude: "9.6", satellites: "12", hdop: "0.6", lat: "0045.737575", lon: "12731.101112", latD: "N", lonD: "E", latitude: 0.7622929166666667, longitude: 127.51835186666666, timestamp: "2024-06-06T13:14:52.000Z" },
        { speed: "9.2", course: "270.8", altitude: "9.6", satellites: "12", hdop: "0.6", lat: "0045.737575", lon: "12731.101112", latD: "N", lonD: "E", latitude: 0.7622929166666667, longitude: 127.51835186666666, timestamp: "2024-06-06T13:14:52.000Z" },
        { speed: "9.2", course: "270.8", altitude: "9.6", satellites: "12", hdop: "0.6", lat: "0045.737575", lon: "12731.101112", latD: "N", lonD: "E", latitude: 0.7622929166666667, longitude: 127.51835186666666, timestamp: "2024-06-06T13:14:52.000Z" },
        { speed: "9.2", course: "270.8", altitude: "9.6", satellites: "12", hdop: "0.6", lat: "0045.737575", lon: "12731.101112", latD: "N", lonD: "E", latitude: 0.7622929166666667, longitude: 127.51835186666666, timestamp: "2024-06-06T13:14:52.000Z" },
        { speed: "9.1", course: "271.8", altitude: "7.2", satellites: "12", hdop: "0.5", lat: "0045.741454", lon: "12731.048981", latD: "N", lonD: "E", latitude: 0.7623575666666667, longitude: 127.51748301666667, timestamp: "2024-06-06T13:15:12.000Z" },
        { speed: "9.1", course: "271.8", altitude: "7.2", satellites: "12", hdop: "0.5", lat: "0045.741454", lon: "12731.048981", latD: "N", lonD: "E", latitude: 0.7623575666666667, longitude: 127.51748301666667, timestamp: "2024-06-06T13:15:12.000Z" },
        { speed: "9.2", course: "269.0", altitude: "11.0", satellites: "11", hdop: "0.5", lat: "0045.740846", lon: "12731.001314", latD: "N", lonD: "E", latitude: 0.7623474333333333, longitude: 127.51668856666667, timestamp: "2024-06-06T13:15:32.000Z" },
        { speed: "9.2", course: "269.0", altitude: "11.0", satellites: "11", hdop: "0.5", lat: "0045.740846", lon: "12731.001314", latD: "N", lonD: "E", latitude: 0.7623474333333333, longitude: 127.51668856666667, timestamp: "2024-06-06T13:15:32.000Z" },
        { speed: "9.1", course: "271.8", altitude: "7.2", satellites: "12", hdop: "0.5", lat: "0045.741454", lon: "12731.048981", latD: "N", lonD: "E", latitude: 0.7623575666666667, longitude: 127.51748301666667, timestamp: "2024-06-06T13:15:12.000Z" },
        { speed: "9.2", course: "269.0", altitude: "11.0", satellites: "11", hdop: "0.5", lat: "0045.740846", lon: "12731.001314", latD: "N", lonD: "E", latitude: 0.7623474333333333, longitude: 127.51668856666667, timestamp: "2024-06-06T13:15:32.000Z" },
        { speed: "8.7", course: "269.3", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.738043", lon: "12730.952551", latD: "N", lonD: "E", latitude: 0.7623007166666667, longitude: 127.51587585, timestamp: "2024-06-06T13:15:52.000Z" },
        { speed: "8.7", course: "269.3", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.738043", lon: "12730.952551", latD: "N", lonD: "E", latitude: 0.7623007166666667, longitude: 127.51587585, timestamp: "2024-06-06T13:15:52.000Z" },
        { speed: "8.7", course: "269.3", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.738043", lon: "12730.952551", latD: "N", lonD: "E", latitude: 0.7623007166666667, longitude: 127.51587585, timestamp: "2024-06-06T13:15:52.000Z" },
        { speed: "9.6", course: "271.0", altitude: "4.8", satellites: "12", hdop: "0.5", lat: "0045.750845", lon: "12730.225473", latD: "N", lonD: "E", latitude: 0.7625140833333333, longitude: 127.50375788333334, timestamp: "2024-06-06T13:20:32.000Z" },
        { speed: "9.6", course: "271.0", altitude: "4.8", satellites: "12", hdop: "0.5", lat: "0045.750845", lon: "12730.225473", latD: "N", lonD: "E", latitude: 0.7625140833333333, longitude: 127.50375788333334, timestamp: "2024-06-06T13:20:32.000Z" },
        { speed: "9.3", course: "271.4", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.745042", lon: "12730.798214", latD: "N", lonD: "E", latitude: 0.7624173666666666, longitude: 127.51330356666666, timestamp: "2024-06-06T13:16:52.000Z" },
        { speed: "9.3", course: "271.4", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.745042", lon: "12730.798214", latD: "N", lonD: "E", latitude: 0.7624173666666666, longitude: 127.51330356666666, timestamp: "2024-06-06T13:16:52.000Z" },
        { speed: "9.2", course: "271.1", altitude: "10.3", satellites: "12", hdop: "0.5", lat: "0045.747055", lon: "12730.747103", latD: "N", lonD: "E", latitude: 0.7624509166666668, longitude: 127.51245171666666, timestamp: "2024-06-06T13:17:12.000Z" },
        { speed: "9.2", course: "271.1", altitude: "10.3", satellites: "12", hdop: "0.5", lat: "0045.747055", lon: "12730.747103", latD: "N", lonD: "E", latitude: 0.7624509166666668, longitude: 127.51245171666666, timestamp: "2024-06-06T13:17:12.000Z" },
        { speed: "9.4", course: "269.6", altitude: "11.3", satellites: "12", hdop: "0.5", lat: "0045.745849", lon: "12730.696556", latD: "N", lonD: "E", latitude: 0.7624308166666667, longitude: 127.51160926666667, timestamp: "2024-06-06T13:17:32.000Z" },
        { speed: "9.4", course: "269.6", altitude: "11.3", satellites: "12", hdop: "0.5", lat: "0045.745849", lon: "12730.696556", latD: "N", lonD: "E", latitude: 0.7624308166666667, longitude: 127.51160926666667, timestamp: "2024-06-06T13:17:32.000Z" },
        { speed: "9.2", course: "271.1", altitude: "10.3", satellites: "12", hdop: "0.5", lat: "0045.747055", lon: "12730.747103", latD: "N", lonD: "E", latitude: 0.7624509166666668, longitude: 127.51245171666666, timestamp: "2024-06-06T13:17:12.000Z" },
        { speed: "9.2", course: "271.1", altitude: "10.3", satellites: "12", hdop: "0.5", lat: "0045.747055", lon: "12730.747103", latD: "N", lonD: "E", latitude: 0.7624509166666668, longitude: 127.51245171666666, timestamp: "2024-06-06T13:17:12.000Z" },
        { speed: "9.4", course: "269.6", altitude: "11.3", satellites: "12", hdop: "0.5", lat: "0045.745849", lon: "12730.696556", latD: "N", lonD: "E", latitude: 0.7624308166666667, longitude: 127.51160926666667, timestamp: "2024-06-06T13:17:32.000Z" },
        { speed: "9.4", course: "269.6", altitude: "11.3", satellites: "12", hdop: "0.5", lat: "0045.745849", lon: "12730.696556", latD: "N", lonD: "E", latitude: 0.7624308166666667, longitude: 127.51160926666667, timestamp: "2024-06-06T13:17:32.000Z" },
        { speed: "9.5", course: "269.9", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.744380", lon: "12730.645419", latD: "N", lonD: "E", latitude: 0.7624063333333333, longitude: 127.51075698333334, timestamp: "2024-06-06T13:17:52.000Z" },
        { speed: "9.5", course: "269.9", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.744380", lon: "12730.645419", latD: "N", lonD: "E", latitude: 0.7624063333333333, longitude: 127.51075698333334, timestamp: "2024-06-06T13:17:52.000Z" },
        { speed: "9.8", course: "269.8", altitude: "9.1", satellites: "12", hdop: "0.5", lat: "0045.743677", lon: "12730.593110", latD: "N", lonD: "E", latitude: 0.7623946166666666, longitude: 127.50988516666666, timestamp: "2024-06-06T13:18:12.000Z" },
        { speed: "9.8", course: "269.8", altitude: "9.1", satellites: "12", hdop: "0.5", lat: "0045.743677", lon: "12730.593110", latD: "N", lonD: "E", latitude: 0.7623946166666666, longitude: 127.50988516666666, timestamp: "2024-06-06T13:18:12.000Z" },
        { speed: "9.8", course: "269.8", altitude: "9.1", satellites: "12", hdop: "0.5", lat: "0045.743677", lon: "12730.593110", latD: "N", lonD: "E", latitude: 0.7623946166666666, longitude: 127.50988516666666, timestamp: "2024-06-06T13:18:12.000Z" },
        { speed: "9.5", course: "269.9", altitude: "10.1", satellites: "12", hdop: "0.5", lat: "0045.744380", lon: "12730.645419", latD: "N", lonD: "E", latitude: 0.7624063333333333, longitude: 127.51075698333334, timestamp: "2024-06-06T13:17:52.000Z" },
        { speed: "9.8", course: "269.8", altitude: "9.1", satellites: "12", hdop: "0.5", lat: "0045.743677", lon: "12730.593110", latD: "N", lonD: "E", latitude: 0.7623946166666666, longitude: 127.50988516666666, timestamp: "2024-06-06T13:18:12.000Z" },
        { speed: "9.8", course: "269.8", altitude: "9.1", satellites: "12", hdop: "0.5", lat: "0045.743677", lon: "12730.593110", latD: "N", lonD: "E", latitude: 0.7623946166666666, longitude: 127.50988516666666, timestamp: "2024-06-06T13:18:12.000Z" },
        { speed: "9.3", course: "270.1", altitude: "11.7", satellites: "12", hdop: "0.5", lat: "0045.743660", lon: "12730.538743", latD: "N", lonD: "E", latitude: 0.7623943333333333, longitude: 127.50897905, timestamp: "2024-06-06T13:18:32.000Z" },
        { speed: "9.3", course: "270.1", altitude: "11.7", satellites: "12", hdop: "0.5", lat: "0045.743660", lon: "12730.538743", latD: "N", lonD: "E", latitude: 0.7623943333333333, longitude: 127.50897905, timestamp: "2024-06-06T13:18:32.000Z" },
        { speed: "9.4", course: "270.4", altitude: "13.0", satellites: "12", hdop: "0.5", lat: "0045.744314", lon: "12730.485855", latD: "N", lonD: "E", latitude: 0.7624052333333334, longitude: 127.50809758333334, timestamp: "2024-06-06T13:18:52.000Z" },
        { speed: "9.3", course: "270.1", altitude: "11.7", satellites: "12", hdop: "0.5", lat: "0045.743660", lon: "12730.538743", latD: "N", lonD: "E", latitude: 0.7623943333333333, longitude: 127.50897905, timestamp: "2024-06-06T13:18:32.000Z" },
        { speed: "9.3", course: "270.1", altitude: "11.7", satellites: "12", hdop: "0.5", lat: "0045.743660", lon: "12730.538743", latD: "N", lonD: "E", latitude: 0.7623943333333333, longitude: 127.50897905, timestamp: "2024-06-06T13:18:32.000Z" },
        { speed: "9.3", course: "270.1", altitude: "11.7", satellites: "12", hdop: "0.5", lat: "0045.743660", lon: "12730.538743", latD: "N", lonD: "E", latitude: 0.7623943333333333, longitude: 127.50897905, timestamp: "2024-06-06T13:18:32.000Z" },
        { speed: "9.4", course: "270.4", altitude: "13.0", satellites: "12", hdop: "0.5", lat: "0045.744314", lon: "12730.485855", latD: "N", lonD: "E", latitude: 0.7624052333333334, longitude: 127.50809758333334, timestamp: "2024-06-06T13:18:52.000Z" },
        { speed: "9.5", course: "272.1", altitude: "7.2", satellites: "12", hdop: "0.5", lat: "0045.745086", lon: "12730.434591", latD: "N", lonD: "E", latitude: 0.7624181, longitude: 127.50724318333333, timestamp: "2024-06-06T13:19:12.000Z" },
        { speed: "9.5", course: "272.1", altitude: "7.2", satellites: "12", hdop: "0.5", lat: "0045.745086", lon: "12730.434591", latD: "N", lonD: "E", latitude: 0.7624181, longitude: 127.50724318333333, timestamp: "2024-06-06T13:19:12.000Z" },
        { speed: "9.4", course: "270.4", altitude: "13.0", satellites: "12", hdop: "0.5", lat: "0045.744314", lon: "12730.485855", latD: "N", lonD: "E", latitude: 0.7624052333333334, longitude: 127.50809758333334, timestamp: "2024-06-06T13:18:52.000Z" },
        { speed: "9.5", course: "272.1", altitude: "7.2", satellites: "12", hdop: "0.5", lat: "0045.745086", lon: "12730.434591", latD: "N", lonD: "E", latitude: 0.7624181, longitude: 127.50724318333333, timestamp: "2024-06-06T13:19:12.000Z" },
        { speed: "9.5", course: "272.1", altitude: "7.2", satellites: "12", hdop: "0.5", lat: "0045.745086", lon: "12730.434591", latD: "N", lonD: "E", latitude: 0.7624181, longitude: 127.50724318333333, timestamp: "2024-06-06T13:19:12.000Z" },
        { speed: "9.6", course: "272.4", altitude: "9.4", satellites: "12", hdop: "0.5", lat: "0045.747024", lon: "12730.382474", latD: "N", lonD: "E", latitude: 0.7624504000000001, longitude: 127.50637456666666, timestamp: "2024-06-06T13:19:32.000Z" },
        { speed: "9.6", course: "272.4", altitude: "9.4", satellites: "12", hdop: "0.5", lat: "0045.747024", lon: "12730.382474", latD: "N", lonD: "E", latitude: 0.7624504000000001, longitude: 127.50637456666666, timestamp: "2024-06-06T13:19:32.000Z" },
        { speed: "9.6", course: "272.4", altitude: "9.4", satellites: "12", hdop: "0.5", lat: "0045.747024", lon: "12730.382474", latD: "N", lonD: "E", latitude: 0.7624504000000001, longitude: 127.50637456666666, timestamp: "2024-06-06T13:19:32.000Z" },
        { speed: "9.6", course: "272.4", altitude: "9.4", satellites: "12", hdop: "0.5", lat: "0045.747024", lon: "12730.382474", latD: "N", lonD: "E", latitude: 0.7624504000000001, longitude: 127.50637456666666, timestamp: "2024-06-06T13:19:32.000Z" },
        { speed: "9.4", course: "271.9", altitude: "6.7", satellites: "12", hdop: "0.5", lat: "0045.749165", lon: "12730.330442", latD: "N", lonD: "E", latitude: 0.7624860833333333, longitude: 127.50550736666666, timestamp: "2024-06-06T13:19:52.000Z" },
        { speed: "9.4", course: "271.9", altitude: "6.7", satellites: "12", hdop: "0.5", lat: "0045.749165", lon: "12730.330442", latD: "N", lonD: "E", latitude: 0.7624860833333333, longitude: 127.50550736666666, timestamp: "2024-06-06T13:19:52.000Z" },
        { speed: "9.5", course: "272.2", altitude: "4.4", satellites: "12", hdop: "0.5", lat: "0045.751344", lon: "12730.278540", latD: "N", lonD: "E", latitude: 0.7625224, longitude: 127.50464233333334, timestamp: "2024-06-06T13:20:12.000Z" },
        { speed: "9.4", course: "271.9", altitude: "6.7", satellites: "12", hdop: "0.5", lat: "0045.749165", lon: "12730.330442", latD: "N", lonD: "E", latitude: 0.7624860833333333, longitude: 127.50550736666666, timestamp: "2024-06-06T13:19:52.000Z" },
        { speed: "9.4", course: "271.9", altitude: "6.7", satellites: "12", hdop: "0.5", lat: "0045.749165", lon: "12730.330442", latD: "N", lonD: "E", latitude: 0.7624860833333333, longitude: 127.50550736666666, timestamp: "2024-06-06T13:19:52.000Z" },
        { speed: "9.5", course: "272.2", altitude: "4.4", satellites: "12", hdop: "0.5", lat: "0045.751344", lon: "12730.278540", latD: "N", lonD: "E", latitude: 0.7625224, longitude: 127.50464233333334, timestamp: "2024-06-06T13:20:12.000Z" },
        { speed: "9.6", course: "271.0", altitude: "4.8", satellites: "12", hdop: "0.5", lat: "0045.750845", lon: "12730.225473", latD: "N", lonD: "E", latitude: 0.7625140833333333, longitude: 127.50375788333334, timestamp: "2024-06-06T13:20:32.000Z" },
        { speed: "9.6", course: "271.0", altitude: "4.8", satellites: "12", hdop: "0.5", lat: "0045.750845", lon: "12730.225473", latD: "N", lonD: "E", latitude: 0.7625140833333333, longitude: 127.50375788333334, timestamp: "2024-06-06T13:20:32.000Z" },
        { speed: "9.5", course: "269.6", altitude: "5.1", satellites: "11", hdop: "0.6", lat: "0045.751433", lon: "12730.174334", latD: "N", lonD: "E", latitude: 0.7625238833333333, longitude: 127.50290556666667, timestamp: "2024-06-06T13:20:52.000Z" },
        { speed: "9.5", course: "269.6", altitude: "5.1", satellites: "11", hdop: "0.6", lat: "0045.751433", lon: "12730.174334", latD: "N", lonD: "E", latitude: 0.7625238833333333, longitude: 127.50290556666667, timestamp: "2024-06-06T13:20:52.000Z" },
        { speed: "9.5", course: "269.6", altitude: "5.1", satellites: "11", hdop: "0.6", lat: "0045.751433", lon: "12730.174334", latD: "N", lonD: "E", latitude: 0.7625238833333333, longitude: 127.50290556666667, timestamp: "2024-06-06T13:20:52.000Z" },
        { speed: "9.5", course: "269.6", altitude: "5.1", satellites: "11", hdop: "0.6", lat: "0045.751433", lon: "12730.174334", latD: "N", lonD: "E", latitude: 0.7625238833333333, longitude: 127.50290556666667, timestamp: "2024-06-06T13:20:52.000Z" },
        { speed: "9.5", course: "270.3", altitude: "6.6", satellites: "12", hdop: "0.5", lat: "0045.751124", lon: "12730.122608", latD: "N", lonD: "E", latitude: 0.7625187333333333, longitude: 127.50204346666666, timestamp: "2024-06-06T13:21:12.000Z" },
        { speed: "9.5", course: "270.3", altitude: "6.6", satellites: "12", hdop: "0.5", lat: "0045.751124", lon: "12730.122608", latD: "N", lonD: "E", latitude: 0.7625187333333333, longitude: 127.50204346666666, timestamp: "2024-06-06T13:21:12.000Z" },
        { speed: "9.5", course: "270.3", altitude: "6.6", satellites: "12", hdop: "0.5", lat: "0045.751124", lon: "12730.122608", latD: "N", lonD: "E", latitude: 0.7625187333333333, longitude: 127.50204346666666, timestamp: "2024-06-06T13:21:12.000Z" },
        { speed: "9.5", course: "270.3", altitude: "6.6", satellites: "12", hdop: "0.5", lat: "0045.751124", lon: "12730.122608", latD: "N", lonD: "E", latitude: 0.7625187333333333, longitude: 127.50204346666666, timestamp: "2024-06-06T13:21:12.000Z" },
        { speed: "9.5", course: "270.3", altitude: "6.6", satellites: "12", hdop: "0.5", lat: "0045.751124", lon: "12730.122608", latD: "N", lonD: "E", latitude: 0.7625187333333333, longitude: 127.50204346666666, timestamp: "2024-06-06T13:21:12.000Z" },
        { speed: "9.5", course: "268.9", altitude: "11.6", satellites: "12", hdop: "0.5", lat: "0045.750963", lon: "12730.071780", latD: "N", lonD: "E", latitude: 0.76251605, longitude: 127.50119633333334, timestamp: "2024-06-06T13:21:32.000Z" },
        { speed: "9.5", course: "268.9", altitude: "11.6", satellites: "12", hdop: "0.5", lat: "0045.750963", lon: "12730.071780", latD: "N", lonD: "E", latitude: 0.76251605, longitude: 127.50119633333334, timestamp: "2024-06-06T13:21:32.000Z" },
        { speed: "9.5", course: "268.7", altitude: "14.4", satellites: "12", hdop: "0.5", lat: "0045.748343", lon: "12730.018979", latD: "N", lonD: "E", latitude: 0.7624723833333333, longitude: 127.50031631666667, timestamp: "2024-06-06T13:21:52.000Z" },
        { speed: "9.5", course: "268.7", altitude: "14.4", satellites: "12", hdop: "0.5", lat: "0045.748343", lon: "12730.018979", latD: "N", lonD: "E", latitude: 0.7624723833333333, longitude: 127.50031631666667, timestamp: "2024-06-06T13:21:52.000Z" },
        { speed: "9.5", course: "268.9", altitude: "11.6", satellites: "12", hdop: "0.5", lat: "0045.750963", lon: "12730.071780", latD: "N", lonD: "E", latitude: 0.76251605, longitude: 127.50119633333334, timestamp: "2024-06-06T13:21:32.000Z" },
        { speed: "9.5", course: "268.7", altitude: "14.4", satellites: "12", hdop: "0.5", lat: "0045.748343", lon: "12730.018979", latD: "N", lonD: "E", latitude: 0.7624723833333333, longitude: 127.50031631666667, timestamp: "2024-06-06T13:21:52.000Z" },
        { speed: "9.6", course: "268.9", altitude: "16.5", satellites: "12", hdop: "0.5", lat: "0045.747137", lon: "12729.964900", latD: "N", lonD: "E", latitude: 0.7624522833333334, longitude: 127.499415, timestamp: "2024-06-06T13:22:12.000Z" },
        { speed: "9.6", course: "268.9", altitude: "16.5", satellites: "12", hdop: "0.5", lat: "0045.747137", lon: "12729.964900", latD: "N", lonD: "E", latitude: 0.7624522833333334, longitude: 127.499415, timestamp: "2024-06-06T13:22:12.000Z" },
        { speed: "9.6", course: "268.9", altitude: "16.5", satellites: "12", hdop: "0.5", lat: "0045.747137", lon: "12729.964900", latD: "N", lonD: "E", latitude: 0.7624522833333334, longitude: 127.499415, timestamp: "2024-06-06T13:22:12.000Z" },
        { speed: "9.6", course: "268.9", altitude: "16.5", satellites: "12", hdop: "0.5", lat: "0045.747137", lon: "12729.964900", latD: "N", lonD: "E", latitude: 0.7624522833333334, longitude: 127.499415, timestamp: "2024-06-06T13:22:12.000Z" },
        { speed: "9.2", course: "269.0", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.747927", lon: "12729.910554", latD: "N", lonD: "E", latitude: 0.76246545, longitude: 127.49850923333334, timestamp: "2024-06-06T13:22:32.000Z" },
        { speed: "9.2", course: "269.0", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.747927", lon: "12729.910554", latD: "N", lonD: "E", latitude: 0.76246545, longitude: 127.49850923333334, timestamp: "2024-06-06T13:22:32.000Z" },
        { speed: "9.2", course: "269.0", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.747927", lon: "12729.910554", latD: "N", lonD: "E", latitude: 0.76246545, longitude: 127.49850923333334, timestamp: "2024-06-06T13:22:32.000Z" },
        { speed: "9.4", course: "270.8", altitude: "1.7", satellites: "11", hdop: "0.6", lat: "0045.747842", lon: "12729.860368", latD: "N", lonD: "E", latitude: 0.7624640333333333, longitude: 127.4976728, timestamp: "2024-06-06T13:22:52.000Z" },
        { speed: "9.2", course: "269.0", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.747927", lon: "12729.910554", latD: "N", lonD: "E", latitude: 0.76246545, longitude: 127.49850923333334, timestamp: "2024-06-06T13:22:32.000Z" },
        { speed: "9.4", course: "270.8", altitude: "1.7", satellites: "11", hdop: "0.6", lat: "0045.747842", lon: "12729.860368", latD: "N", lonD: "E", latitude: 0.7624640333333333, longitude: 127.4976728, timestamp: "2024-06-06T13:22:52.000Z" },
        { speed: "9.2", course: "269.0", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.747927", lon: "12729.910554", latD: "N", lonD: "E", latitude: 0.76246545, longitude: 127.49850923333334, timestamp: "2024-06-06T13:22:32.000Z" },
        { speed: "9.4", course: "270.8", altitude: "1.7", satellites: "11", hdop: "0.6", lat: "0045.747842", lon: "12729.860368", latD: "N", lonD: "E", latitude: 0.7624640333333333, longitude: 127.4976728, timestamp: "2024-06-06T13:22:52.000Z" },
        { speed: "9.4", course: "270.8", altitude: "1.7", satellites: "11", hdop: "0.6", lat: "0045.747842", lon: "12729.860368", latD: "N", lonD: "E", latitude: 0.7624640333333333, longitude: 127.4976728, timestamp: "2024-06-06T13:22:52.000Z" },
        { speed: "9.6", course: "270.8", altitude: "-1.8", satellites: "12", hdop: "0.5", lat: "0045.747328", lon: "12729.810472", latD: "N", lonD: "E", latitude: 0.7624554666666667, longitude: 127.4968412, timestamp: "2024-06-06T13:23:12.000Z" },
        { speed: "9.6", course: "270.8", altitude: "-1.8", satellites: "12", hdop: "0.5", lat: "0045.747328", lon: "12729.810472", latD: "N", lonD: "E", latitude: 0.7624554666666667, longitude: 127.4968412, timestamp: "2024-06-06T13:23:12.000Z" },
        { speed: "9.6", course: "270.8", altitude: "-1.8", satellites: "12", hdop: "0.5", lat: "0045.747328", lon: "12729.810472", latD: "N", lonD: "E", latitude: 0.7624554666666667, longitude: 127.4968412, timestamp: "2024-06-06T13:23:12.000Z" },
        { speed: "9.6", course: "270.8", altitude: "-1.8", satellites: "12", hdop: "0.5", lat: "0045.747328", lon: "12729.810472", latD: "N", lonD: "E", latitude: 0.7624554666666667, longitude: 127.4968412, timestamp: "2024-06-06T13:23:12.000Z" },
        { speed: "9.6", course: "270.2", altitude: "-0.8", satellites: "11", hdop: "0.6", lat: "0045.747732", lon: "12729.757093", latD: "N", lonD: "E", latitude: 0.7624622, longitude: 127.49595155, timestamp: "2024-06-06T13:23:32.000Z" },
        { speed: "9.6", course: "270.2", altitude: "-0.8", satellites: "11", hdop: "0.6", lat: "0045.747732", lon: "12729.757093", latD: "N", lonD: "E", latitude: 0.7624622, longitude: 127.49595155, timestamp: "2024-06-06T13:23:32.000Z" },
        { speed: "9.6", course: "270.2", altitude: "-0.8", satellites: "11", hdop: "0.6", lat: "0045.747732", lon: "12729.757093", latD: "N", lonD: "E", latitude: 0.7624622, longitude: 127.49595155, timestamp: "2024-06-06T13:23:32.000Z" },
        { speed: "9.5", course: "269.4", altitude: "4.1", satellites: "12", hdop: "0.5", lat: "0045.748646", lon: "12729.700364", latD: "N", lonD: "E", latitude: 0.7624774333333334, longitude: 127.49500606666666, timestamp: "2024-06-06T13:23:52.000Z" },
        { speed: "9.5", course: "269.4", altitude: "4.1", satellites: "12", hdop: "0.5", lat: "0045.748646", lon: "12729.700364", latD: "N", lonD: "E", latitude: 0.7624774333333334, longitude: 127.49500606666666, timestamp: "2024-06-06T13:23:52.000Z" },
        { speed: "9.5", course: "269.4", altitude: "4.1", satellites: "12", hdop: "0.5", lat: "0045.748646", lon: "12729.700364", latD: "N", lonD: "E", latitude: 0.7624774333333334, longitude: 127.49500606666666, timestamp: "2024-06-06T13:23:52.000Z" },
        { speed: "9.5", course: "269.4", altitude: "4.1", satellites: "12", hdop: "0.5", lat: "0045.748646", lon: "12729.700364", latD: "N", lonD: "E", latitude: 0.7624774333333334, longitude: 127.49500606666666, timestamp: "2024-06-06T13:23:52.000Z" },
        { speed: "9.5", course: "269.4", altitude: "4.1", satellites: "12", hdop: "0.5", lat: "0045.748646", lon: "12729.700364", latD: "N", lonD: "E", latitude: 0.7624774333333334, longitude: 127.49500606666666, timestamp: "2024-06-06T13:23:52.000Z" },
        { speed: "9.4", course: "268.1", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.748000", lon: "12729.648542", latD: "N", lonD: "E", latitude: 0.7624666666666666, longitude: 127.49414236666667, timestamp: "2024-06-06T13:24:12.000Z" },
        { speed: "9.4", course: "268.1", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.748000", lon: "12729.648542", latD: "N", lonD: "E", latitude: 0.7624666666666666, longitude: 127.49414236666667, timestamp: "2024-06-06T13:24:12.000Z" },
        { speed: "9.3", course: "266.4", altitude: "8.5", satellites: "12", hdop: "0.6", lat: "0045.745129", lon: "12729.595835", latD: "N", lonD: "E", latitude: 0.7624188166666667, longitude: 127.49326391666666, timestamp: "2024-06-06T13:24:32.000Z" },
        { speed: "9.3", course: "266.4", altitude: "8.5", satellites: "12", hdop: "0.6", lat: "0045.745129", lon: "12729.595835", latD: "N", lonD: "E", latitude: 0.7624188166666667, longitude: 127.49326391666666, timestamp: "2024-06-06T13:24:32.000Z" },
        { speed: "9.4", course: "268.1", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.748000", lon: "12729.648542", latD: "N", lonD: "E", latitude: 0.7624666666666666, longitude: 127.49414236666667, timestamp: "2024-06-06T13:24:12.000Z" },
        { speed: "9.4", course: "268.1", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.748000", lon: "12729.648542", latD: "N", lonD: "E", latitude: 0.7624666666666666, longitude: 127.49414236666667, timestamp: "2024-06-06T13:24:12.000Z" },
        { speed: "9.3", course: "266.4", altitude: "8.5", satellites: "12", hdop: "0.6", lat: "0045.745129", lon: "12729.595835", latD: "N", lonD: "E", latitude: 0.7624188166666667, longitude: 127.49326391666666, timestamp: "2024-06-06T13:24:32.000Z" },
        { speed: "9.3", course: "266.4", altitude: "8.5", satellites: "12", hdop: "0.6", lat: "0045.745129", lon: "12729.595835", latD: "N", lonD: "E", latitude: 0.7624188166666667, longitude: 127.49326391666666, timestamp: "2024-06-06T13:24:32.000Z" },
        { speed: "9.4", course: "267.5", altitude: "7.0", satellites: "12", hdop: "0.6", lat: "0045.741969", lon: "12729.544427", latD: "N", lonD: "E", latitude: 0.76236615, longitude: 127.49240711666667, timestamp: "2024-06-06T13:24:52.000Z" },
        { speed: "9.4", course: "267.5", altitude: "7.0", satellites: "12", hdop: "0.6", lat: "0045.741969", lon: "12729.544427", latD: "N", lonD: "E", latitude: 0.76236615, longitude: 127.49240711666667, timestamp: "2024-06-06T13:24:52.000Z" },
        { speed: "9.3", course: "267.7", altitude: "4.7", satellites: "11", hdop: "0.6", lat: "0045.747307", lon: "12729.178244", latD: "N", lonD: "E", latitude: 0.7624551166666667, longitude: 127.48630406666666, timestamp: "2024-06-06T13:27:12.000Z" },
        { speed: "9.3", course: "267.7", altitude: "4.7", satellites: "11", hdop: "0.6", lat: "0045.747307", lon: "12729.178244", latD: "N", lonD: "E", latitude: 0.7624551166666667, longitude: 127.48630406666666, timestamp: "2024-06-06T13:27:12.000Z" },
        { speed: "9.4", course: "267.4", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.744268", lon: "12729.122702", latD: "N", lonD: "E", latitude: 0.7624044666666666, longitude: 127.48537836666667, timestamp: "2024-06-06T13:27:33.000Z" },
        { speed: "9.4", course: "267.4", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.744268", lon: "12729.122702", latD: "N", lonD: "E", latitude: 0.7624044666666666, longitude: 127.48537836666667, timestamp: "2024-06-06T13:27:33.000Z" },
        { speed: "9.2", course: "272.3", altitude: "9.0", satellites: "12", hdop: "0.5", lat: "0045.749757", lon: "12728.718655", latD: "N", lonD: "E", latitude: 0.76249595, longitude: 127.47864425, timestamp: "2024-06-06T13:30:13.000Z" },
        { speed: "9.2", course: "272.3", altitude: "9.0", satellites: "12", hdop: "0.5", lat: "0045.749757", lon: "12728.718655", latD: "N", lonD: "E", latitude: 0.76249595, longitude: 127.47864425, timestamp: "2024-06-06T13:30:13.000Z" },
        { speed: "9.0", course: "268.4", altitude: "10.9", satellites: "11", hdop: "0.6", lat: "0045.749631", lon: "12728.768027", latD: "N", lonD: "E", latitude: 0.76249385, longitude: 127.47946711666667, timestamp: "2024-06-06T13:29:53.000Z" },
        { speed: "9.2", course: "272.3", altitude: "9.0", satellites: "12", hdop: "0.5", lat: "0045.749757", lon: "12728.718655", latD: "N", lonD: "E", latitude: 0.76249595, longitude: 127.47864425, timestamp: "2024-06-06T13:30:13.000Z" },
        { speed: "9.2", course: "272.3", altitude: "9.0", satellites: "12", hdop: "0.5", lat: "0045.749757", lon: "12728.718655", latD: "N", lonD: "E", latitude: 0.76249595, longitude: 127.47864425, timestamp: "2024-06-06T13:30:13.000Z" },
        { speed: "9.3", course: "272.9", altitude: "3.9", satellites: "12", hdop: "0.5", lat: "0045.752114", lon: "12728.668192", latD: "N", lonD: "E", latitude: 0.7625352333333333, longitude: 127.4778032, timestamp: "2024-06-06T13:30:33.000Z" },
        { speed: "9.1", course: "270.3", altitude: "7.0", satellites: "12", hdop: "0.5", lat: "0045.753264", lon: "12728.615991", latD: "N", lonD: "E", latitude: 0.7625544000000001, longitude: 127.47693318333333, timestamp: "2024-06-06T13:30:53.000Z" },
        { speed: "9.3", course: "272.9", altitude: "3.9", satellites: "12", hdop: "0.5", lat: "0045.752114", lon: "12728.668192", latD: "N", lonD: "E", latitude: 0.7625352333333333, longitude: 127.4778032, timestamp: "2024-06-06T13:30:33.000Z" },
        { speed: "9.3", course: "272.9", altitude: "3.9", satellites: "12", hdop: "0.5", lat: "0045.752114", lon: "12728.668192", latD: "N", lonD: "E", latitude: 0.7625352333333333, longitude: 127.4778032, timestamp: "2024-06-06T13:30:33.000Z" },
        { speed: "9.3", course: "272.9", altitude: "3.9", satellites: "12", hdop: "0.5", lat: "0045.752114", lon: "12728.668192", latD: "N", lonD: "E", latitude: 0.7625352333333333, longitude: 127.4778032, timestamp: "2024-06-06T13:30:33.000Z" },
        { speed: "9.1", course: "270.3", altitude: "7.0", satellites: "12", hdop: "0.5", lat: "0045.753264", lon: "12728.615991", latD: "N", lonD: "E", latitude: 0.7625544000000001, longitude: 127.47693318333333, timestamp: "2024-06-06T13:30:53.000Z" },
        { speed: "9.1", course: "270.3", altitude: "7.0", satellites: "12", hdop: "0.5", lat: "0045.753264", lon: "12728.615991", latD: "N", lonD: "E", latitude: 0.7625544000000001, longitude: 127.47693318333333, timestamp: "2024-06-06T13:30:53.000Z" },
        { speed: "9.3", course: "269.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.753306", lon: "12728.564095", latD: "N", lonD: "E", latitude: 0.7625551, longitude: 127.47606825, timestamp: "2024-06-06T13:31:13.000Z" },
        { speed: "9.3", course: "269.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.753306", lon: "12728.564095", latD: "N", lonD: "E", latitude: 0.7625551, longitude: 127.47606825, timestamp: "2024-06-06T13:31:13.000Z" },
        { speed: "9.3", course: "269.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.753306", lon: "12728.564095", latD: "N", lonD: "E", latitude: 0.7625551, longitude: 127.47606825, timestamp: "2024-06-06T13:31:13.000Z" },
        { speed: "9.3", course: "269.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.753306", lon: "12728.564095", latD: "N", lonD: "E", latitude: 0.7625551, longitude: 127.47606825, timestamp: "2024-06-06T13:31:13.000Z" },
        { speed: "9.0", course: "267.0", altitude: "8.2", satellites: "12", hdop: "0.6", lat: "0045.752365", lon: "12728.513076", latD: "N", lonD: "E", latitude: 0.7625394166666666, longitude: 127.47521793333334, timestamp: "2024-06-06T13:31:33.000Z" },
        { speed: "9.0", course: "267.0", altitude: "8.2", satellites: "12", hdop: "0.6", lat: "0045.752365", lon: "12728.513076", latD: "N", lonD: "E", latitude: 0.7625394166666666, longitude: 127.47521793333334, timestamp: "2024-06-06T13:31:33.000Z" },
        { speed: "9.0", course: "267.0", altitude: "8.2", satellites: "12", hdop: "0.6", lat: "0045.752365", lon: "12728.513076", latD: "N", lonD: "E", latitude: 0.7625394166666666, longitude: 127.47521793333334, timestamp: "2024-06-06T13:31:33.000Z" },
        { speed: "9.2", course: "267.2", altitude: "7.3", satellites: "12", hdop: "0.5", lat: "0045.749588", lon: "12728.462332", latD: "N", lonD: "E", latitude: 0.7624931333333334, longitude: 127.4743722, timestamp: "2024-06-06T13:31:53.000Z" },
        { speed: "9.2", course: "267.2", altitude: "7.3", satellites: "12", hdop: "0.5", lat: "0045.749588", lon: "12728.462332", latD: "N", lonD: "E", latitude: 0.7624931333333334, longitude: 127.4743722, timestamp: "2024-06-06T13:31:53.000Z" },
        { speed: "9.2", course: "267.2", altitude: "7.3", satellites: "12", hdop: "0.5", lat: "0045.749588", lon: "12728.462332", latD: "N", lonD: "E", latitude: 0.7624931333333334, longitude: 127.4743722, timestamp: "2024-06-06T13:31:53.000Z" },
        { speed: "8.9", course: "265.2", altitude: "3.6", satellites: "12", hdop: "0.6", lat: "0045.745116", lon: "12728.413504", latD: "N", lonD: "E", latitude: 0.7624186000000001, longitude: 127.4735584, timestamp: "2024-06-06T13:32:13.000Z" },
        { speed: "8.9", course: "265.2", altitude: "3.6", satellites: "12", hdop: "0.6", lat: "0045.745116", lon: "12728.413504", latD: "N", lonD: "E", latitude: 0.7624186000000001, longitude: 127.4735584, timestamp: "2024-06-06T13:32:13.000Z" },
        { speed: "9.2", course: "267.2", altitude: "7.3", satellites: "12", hdop: "0.5", lat: "0045.749588", lon: "12728.462332", latD: "N", lonD: "E", latitude: 0.7624931333333334, longitude: 127.4743722, timestamp: "2024-06-06T13:31:53.000Z" },
        { speed: "9.2", course: "267.2", altitude: "7.3", satellites: "12", hdop: "0.5", lat: "0045.749588", lon: "12728.462332", latD: "N", lonD: "E", latitude: 0.7624931333333334, longitude: 127.4743722, timestamp: "2024-06-06T13:31:53.000Z" },
        { speed: "8.9", course: "265.2", altitude: "3.6", satellites: "12", hdop: "0.6", lat: "0045.745116", lon: "12728.413504", latD: "N", lonD: "E", latitude: 0.7624186000000001, longitude: 127.4735584, timestamp: "2024-06-06T13:32:13.000Z" },
        { speed: "9.2", course: "267.2", altitude: "6.8", satellites: "11", hdop: "0.6", lat: "0045.742254", lon: "12728.362039", latD: "N", lonD: "E", latitude: 0.7623709000000001, longitude: 127.47270065, timestamp: "2024-06-06T13:32:33.000Z" },
        { speed: "9.2", course: "267.2", altitude: "6.8", satellites: "11", hdop: "0.6", lat: "0045.742254", lon: "12728.362039", latD: "N", lonD: "E", latitude: 0.7623709000000001, longitude: 127.47270065, timestamp: "2024-06-06T13:32:33.000Z" },
        { speed: "9.2", course: "267.2", altitude: "6.8", satellites: "11", hdop: "0.6", lat: "0045.742254", lon: "12728.362039", latD: "N", lonD: "E", latitude: 0.7623709000000001, longitude: 127.47270065, timestamp: "2024-06-06T13:32:33.000Z" },
        { speed: "9.2", course: "265.5", altitude: "5.1", satellites: "12", hdop: "0.6", lat: "0045.738740", lon: "12728.311106", latD: "N", lonD: "E", latitude: 0.7623123333333334, longitude: 127.47185176666666, timestamp: "2024-06-06T13:32:53.000Z" },
        { speed: "9.2", course: "265.5", altitude: "5.1", satellites: "12", hdop: "0.6", lat: "0045.738740", lon: "12728.311106", latD: "N", lonD: "E", latitude: 0.7623123333333334, longitude: 127.47185176666666, timestamp: "2024-06-06T13:32:53.000Z" },
        { speed: "9.2", course: "267.2", altitude: "6.8", satellites: "11", hdop: "0.6", lat: "0045.742254", lon: "12728.362039", latD: "N", lonD: "E", latitude: 0.7623709000000001, longitude: 127.47270065, timestamp: "2024-06-06T13:32:33.000Z" },
        { speed: "9.2", course: "267.2", altitude: "6.8", satellites: "11", hdop: "0.6", lat: "0045.742254", lon: "12728.362039", latD: "N", lonD: "E", latitude: 0.7623709000000001, longitude: 127.47270065, timestamp: "2024-06-06T13:32:33.000Z" },
        { speed: "9.2", course: "265.5", altitude: "5.1", satellites: "12", hdop: "0.6", lat: "0045.738740", lon: "12728.311106", latD: "N", lonD: "E", latitude: 0.7623123333333334, longitude: 127.47185176666666, timestamp: "2024-06-06T13:32:53.000Z" },
        { speed: "9.2", course: "265.5", altitude: "5.1", satellites: "12", hdop: "0.6", lat: "0045.738740", lon: "12728.311106", latD: "N", lonD: "E", latitude: 0.7623123333333334, longitude: 127.47185176666666, timestamp: "2024-06-06T13:32:53.000Z" },
        { speed: "9.2", course: "264.0", altitude: "2.1", satellites: "12", hdop: "0.6", lat: "0045.733515", lon: "12728.261576", latD: "N", lonD: "E", latitude: 0.76222525, longitude: 127.47102626666667, timestamp: "2024-06-06T13:33:13.000Z" },
        { speed: "9.2", course: "264.0", altitude: "2.1", satellites: "12", hdop: "0.6", lat: "0045.733515", lon: "12728.261576", latD: "N", lonD: "E", latitude: 0.76222525, longitude: 127.47102626666667, timestamp: "2024-06-06T13:33:13.000Z" },
        { speed: "9.2", course: "264.0", altitude: "2.1", satellites: "12", hdop: "0.6", lat: "0045.733515", lon: "12728.261576", latD: "N", lonD: "E", latitude: 0.76222525, longitude: 127.47102626666667, timestamp: "2024-06-06T13:33:13.000Z" },
        { speed: "9.3", course: "264.7", altitude: "-0.2", satellites: "12", hdop: "0.6", lat: "0045.727575", lon: "12728.210293", latD: "N", lonD: "E", latitude: 0.76212625, longitude: 127.47017155, timestamp: "2024-06-06T13:33:33.000Z" },
        { speed: "9.3", course: "264.7", altitude: "-0.2", satellites: "12", hdop: "0.6", lat: "0045.727575", lon: "12728.210293", latD: "N", lonD: "E", latitude: 0.76212625, longitude: 127.47017155, timestamp: "2024-06-06T13:33:33.000Z" },
        { speed: "9.3", course: "264.7", altitude: "-0.2", satellites: "12", hdop: "0.6", lat: "0045.727575", lon: "12728.210293", latD: "N", lonD: "E", latitude: 0.76212625, longitude: 127.47017155, timestamp: "2024-06-06T13:33:33.000Z" },
        { speed: "9.2", course: "270.2", altitude: "5.0", satellites: "12", hdop: "0.5", lat: "0045.725326", lon: "12728.157665", latD: "N", lonD: "E", latitude: 0.7620887666666667, longitude: 127.46929441666667, timestamp: "2024-06-06T13:33:53.000Z" },
        { speed: "9.2", course: "270.2", altitude: "5.0", satellites: "12", hdop: "0.5", lat: "0045.725326", lon: "12728.157665", latD: "N", lonD: "E", latitude: 0.7620887666666667, longitude: 127.46929441666667, timestamp: "2024-06-06T13:33:53.000Z" },
        { speed: "9.3", course: "264.7", altitude: "-0.2", satellites: "12", hdop: "0.6", lat: "0045.727575", lon: "12728.210293", latD: "N", lonD: "E", latitude: 0.76212625, longitude: 127.47017155, timestamp: "2024-06-06T13:33:33.000Z" },
        { speed: "9.3", course: "264.7", altitude: "-0.2", satellites: "12", hdop: "0.6", lat: "0045.727575", lon: "12728.210293", latD: "N", lonD: "E", latitude: 0.76212625, longitude: 127.47017155, timestamp: "2024-06-06T13:33:33.000Z" },
        { speed: "9.0", course: "276.3", altitude: "7.3", satellites: "12", hdop: "0.6", lat: "0045.756420", lon: "12727.754827", latD: "N", lonD: "E", latitude: 0.7626069999999999, longitude: 127.46258045, timestamp: "2024-06-06T13:36:33.000Z" },
        { speed: "9.2", course: "278.2", altitude: "3.5", satellites: "12", hdop: "0.6", lat: "0045.750893", lon: "12727.806578", latD: "N", lonD: "E", latitude: 0.7625148833333333, longitude: 127.46344296666666, timestamp: "2024-06-06T13:36:13.000Z" },
        { speed: "9.2", course: "278.2", altitude: "3.5", satellites: "12", hdop: "0.6", lat: "0045.750893", lon: "12727.806578", latD: "N", lonD: "E", latitude: 0.7625148833333333, longitude: 127.46344296666666, timestamp: "2024-06-06T13:36:13.000Z" },
        { speed: "9.0", course: "276.3", altitude: "7.3", satellites: "12", hdop: "0.6", lat: "0045.756420", lon: "12727.754827", latD: "N", lonD: "E", latitude: 0.7626069999999999, longitude: 127.46258045, timestamp: "2024-06-06T13:36:33.000Z" },
        { speed: "9.5", course: "275.0", altitude: "9.1", satellites: "12", hdop: "0.6", lat: "0045.760826", lon: "12727.703191", latD: "N", lonD: "E", latitude: 0.7626804333333334, longitude: 127.46171985, timestamp: "2024-06-06T13:36:53.000Z" },
        { speed: "9.5", course: "275.0", altitude: "9.1", satellites: "12", hdop: "0.6", lat: "0045.760826", lon: "12727.703191", latD: "N", lonD: "E", latitude: 0.7626804333333334, longitude: 127.46171985, timestamp: "2024-06-06T13:36:53.000Z" },
        { speed: "9.2", course: "271.7", altitude: "9.0", satellites: "12", hdop: "0.5", lat: "0045.764596", lon: "12727.650266", latD: "N", lonD: "E", latitude: 0.7627432666666666, longitude: 127.46083776666667, timestamp: "2024-06-06T13:37:13.000Z" },
        { speed: "9.2", course: "271.7", altitude: "9.0", satellites: "12", hdop: "0.5", lat: "0045.764596", lon: "12727.650266", latD: "N", lonD: "E", latitude: 0.7627432666666666, longitude: 127.46083776666667, timestamp: "2024-06-06T13:37:13.000Z" },
        { speed: "9.2", course: "271.7", altitude: "9.0", satellites: "12", hdop: "0.5", lat: "0045.764596", lon: "12727.650266", latD: "N", lonD: "E", latitude: 0.7627432666666666, longitude: 127.46083776666667, timestamp: "2024-06-06T13:37:13.000Z" },
        { speed: "9.2", course: "271.7", altitude: "9.0", satellites: "12", hdop: "0.5", lat: "0045.764596", lon: "12727.650266", latD: "N", lonD: "E", latitude: 0.7627432666666666, longitude: 127.46083776666667, timestamp: "2024-06-06T13:37:13.000Z" },
        { speed: "9.4", course: "272.8", altitude: "7.6", satellites: "12", hdop: "0.5", lat: "0045.767948", lon: "12727.597761", latD: "N", lonD: "E", latitude: 0.7627991333333333, longitude: 127.45996268333333, timestamp: "2024-06-06T13:37:33.000Z" },
        { speed: "9.4", course: "272.8", altitude: "7.6", satellites: "12", hdop: "0.5", lat: "0045.767948", lon: "12727.597761", latD: "N", lonD: "E", latitude: 0.7627991333333333, longitude: 127.45996268333333, timestamp: "2024-06-06T13:37:33.000Z" },
        { speed: "9.4", course: "272.8", altitude: "7.6", satellites: "12", hdop: "0.5", lat: "0045.767948", lon: "12727.597761", latD: "N", lonD: "E", latitude: 0.7627991333333333, longitude: 127.45996268333333, timestamp: "2024-06-06T13:37:33.000Z" },
        { speed: "9.4", course: "272.8", altitude: "7.6", satellites: "12", hdop: "0.5", lat: "0045.767948", lon: "12727.597761", latD: "N", lonD: "E", latitude: 0.7627991333333333, longitude: 127.45996268333333, timestamp: "2024-06-06T13:37:33.000Z" },
        { speed: "9.2", course: "271.9", altitude: "9.4", satellites: "12", hdop: "0.5", lat: "0045.770049", lon: "12727.546659", latD: "N", lonD: "E", latitude: 0.76283415, longitude: 127.45911098333333, timestamp: "2024-06-06T13:37:53.000Z" },
        { speed: "9.2", course: "271.9", altitude: "9.4", satellites: "12", hdop: "0.5", lat: "0045.770049", lon: "12727.546659", latD: "N", lonD: "E", latitude: 0.76283415, longitude: 127.45911098333333, timestamp: "2024-06-06T13:37:53.000Z" },
        { speed: "9.2", course: "271.9", altitude: "9.4", satellites: "12", hdop: "0.5", lat: "0045.770049", lon: "12727.546659", latD: "N", lonD: "E", latitude: 0.76283415, longitude: 127.45911098333333, timestamp: "2024-06-06T13:37:53.000Z" },
        { speed: "9.4", course: "276.5", altitude: "11.1", satellites: "12", hdop: "0.4", lat: "0045.774997", lon: "12727.495812", latD: "N", lonD: "E", latitude: 0.7629166166666667, longitude: 127.45826353333334, timestamp: "2024-06-06T13:38:13.000Z" },
        { speed: "9.4", course: "276.5", altitude: "11.1", satellites: "12", hdop: "0.4", lat: "0045.774997", lon: "12727.495812", latD: "N", lonD: "E", latitude: 0.7629166166666667, longitude: 127.45826353333334, timestamp: "2024-06-06T13:38:13.000Z" },
        { speed: "9.2", course: "271.9", altitude: "9.4", satellites: "12", hdop: "0.5", lat: "0045.770049", lon: "12727.546659", latD: "N", lonD: "E", latitude: 0.76283415, longitude: 127.45911098333333, timestamp: "2024-06-06T13:37:53.000Z" },
        { speed: "9.2", course: "271.9", altitude: "9.4", satellites: "12", hdop: "0.5", lat: "0045.770049", lon: "12727.546659", latD: "N", lonD: "E", latitude: 0.76283415, longitude: 127.45911098333333, timestamp: "2024-06-06T13:37:53.000Z" },
        { speed: "9.4", course: "276.5", altitude: "11.1", satellites: "12", hdop: "0.4", lat: "0045.774997", lon: "12727.495812", latD: "N", lonD: "E", latitude: 0.7629166166666667, longitude: 127.45826353333334, timestamp: "2024-06-06T13:38:13.000Z" },
        { speed: "9.4", course: "276.5", altitude: "11.1", satellites: "12", hdop: "0.4", lat: "0045.774997", lon: "12727.495812", latD: "N", lonD: "E", latitude: 0.7629166166666667, longitude: 127.45826353333334, timestamp: "2024-06-06T13:38:13.000Z" },
        { speed: "9.4", course: "276.5", altitude: "11.1", satellites: "12", hdop: "0.4", lat: "0045.774997", lon: "12727.495812", latD: "N", lonD: "E", latitude: 0.7629166166666667, longitude: 127.45826353333334, timestamp: "2024-06-06T13:38:13.000Z" },
        { speed: "9.4", course: "277.4", altitude: "9.4", satellites: "12", hdop: "0.4", lat: "0045.782661", lon: "12727.445986", latD: "N", lonD: "E", latitude: 0.7630443499999999, longitude: 127.4574331, timestamp: "2024-06-06T13:38:33.000Z" },
        { speed: "9.4", course: "277.4", altitude: "9.4", satellites: "12", hdop: "0.4", lat: "0045.782661", lon: "12727.445986", latD: "N", lonD: "E", latitude: 0.7630443499999999, longitude: 127.4574331, timestamp: "2024-06-06T13:38:33.000Z" },
        { speed: "9.4", course: "277.4", altitude: "9.4", satellites: "12", hdop: "0.4", lat: "0045.782661", lon: "12727.445986", latD: "N", lonD: "E", latitude: 0.7630443499999999, longitude: 127.4574331, timestamp: "2024-06-06T13:38:33.000Z" },
        { speed: "9.4", course: "277.4", altitude: "9.4", satellites: "12", hdop: "0.4", lat: "0045.782661", lon: "12727.445986", latD: "N", lonD: "E", latitude: 0.7630443499999999, longitude: 127.4574331, timestamp: "2024-06-06T13:38:33.000Z" },
        { speed: "9.3", course: "275.7", altitude: "10.9", satellites: "12", hdop: "0.4", lat: "0045.788005", lon: "12727.394499", latD: "N", lonD: "E", latitude: 0.7631334166666667, longitude: 127.45657498333334, timestamp: "2024-06-06T13:38:53.000Z" },
        { speed: "9.3", course: "275.7", altitude: "10.9", satellites: "12", hdop: "0.4", lat: "0045.788005", lon: "12727.394499", latD: "N", lonD: "E", latitude: 0.7631334166666667, longitude: 127.45657498333334, timestamp: "2024-06-06T13:38:53.000Z" },
        { speed: "9.6", course: "273.5", altitude: "11.6", satellites: "12", hdop: "0.4", lat: "0045.792625", lon: "12727.342994", latD: "N", lonD: "E", latitude: 0.7632104166666667, longitude: 127.45571656666667, timestamp: "2024-06-06T13:39:13.000Z" },
        { speed: "9.6", course: "273.5", altitude: "11.6", satellites: "12", hdop: "0.4", lat: "0045.792625", lon: "12727.342994", latD: "N", lonD: "E", latitude: 0.7632104166666667, longitude: 127.45571656666667, timestamp: "2024-06-06T13:39:13.000Z" },
        { speed: "9.3", course: "275.7", altitude: "10.9", satellites: "12", hdop: "0.4", lat: "0045.788005", lon: "12727.394499", latD: "N", lonD: "E", latitude: 0.7631334166666667, longitude: 127.45657498333334, timestamp: "2024-06-06T13:38:53.000Z" },
        { speed: "9.6", course: "273.5", altitude: "11.6", satellites: "12", hdop: "0.4", lat: "0045.792625", lon: "12727.342994", latD: "N", lonD: "E", latitude: 0.7632104166666667, longitude: 127.45571656666667, timestamp: "2024-06-06T13:39:13.000Z" },
        { speed: "9.6", course: "273.5", altitude: "11.6", satellites: "12", hdop: "0.4", lat: "0045.792625", lon: "12727.342994", latD: "N", lonD: "E", latitude: 0.7632104166666667, longitude: 127.45571656666667, timestamp: "2024-06-06T13:39:13.000Z" },
        { speed: "9.3", course: "272.2", altitude: "12.7", satellites: "12", hdop: "0.5", lat: "0045.794199", lon: "12727.289286", latD: "N", lonD: "E", latitude: 0.76323665, longitude: 127.45482143333334, timestamp: "2024-06-06T13:39:33.000Z" },
        { speed: "9.3", course: "272.2", altitude: "12.7", satellites: "12", hdop: "0.5", lat: "0045.794199", lon: "12727.289286", latD: "N", lonD: "E", latitude: 0.76323665, longitude: 127.45482143333334, timestamp: "2024-06-06T13:39:33.000Z" },
        { speed: "9.3", course: "272.2", altitude: "12.7", satellites: "12", hdop: "0.5", lat: "0045.794199", lon: "12727.289286", latD: "N", lonD: "E", latitude: 0.76323665, longitude: 127.45482143333334, timestamp: "2024-06-06T13:39:33.000Z" },
        { speed: "9.2", course: "272.4", altitude: "11.7", satellites: "12", hdop: "0.5", lat: "0045.796941", lon: "12727.238132", latD: "N", lonD: "E", latitude: 0.76328235, longitude: 127.45396886666667, timestamp: "2024-06-06T13:39:53.000Z" },
        { speed: "9.3", course: "272.2", altitude: "12.7", satellites: "12", hdop: "0.5", lat: "0045.794199", lon: "12727.289286", latD: "N", lonD: "E", latitude: 0.76323665, longitude: 127.45482143333334, timestamp: "2024-06-06T13:39:33.000Z" },
        { speed: "9.2", course: "272.4", altitude: "11.7", satellites: "12", hdop: "0.5", lat: "0045.796941", lon: "12727.238132", latD: "N", lonD: "E", latitude: 0.76328235, longitude: 127.45396886666667, timestamp: "2024-06-06T13:39:53.000Z" },
        { speed: "9.2", course: "272.4", altitude: "11.7", satellites: "12", hdop: "0.5", lat: "0045.796941", lon: "12727.238132", latD: "N", lonD: "E", latitude: 0.76328235, longitude: 127.45396886666667, timestamp: "2024-06-06T13:39:53.000Z" },
        { speed: "9.2", course: "272.4", altitude: "11.7", satellites: "12", hdop: "0.5", lat: "0045.796941", lon: "12727.238132", latD: "N", lonD: "E", latitude: 0.76328235, longitude: 127.45396886666667, timestamp: "2024-06-06T13:39:53.000Z" },
        { speed: "9.2", course: "269.7", altitude: "12.7", satellites: "12", hdop: "0.5", lat: "0045.797780", lon: "12727.187296", latD: "N", lonD: "E", latitude: 0.7632963333333334, longitude: 127.4531216, timestamp: "2024-06-06T13:40:13.000Z" },
        { speed: "9.2", course: "269.7", altitude: "12.7", satellites: "12", hdop: "0.5", lat: "0045.797780", lon: "12727.187296", latD: "N", lonD: "E", latitude: 0.7632963333333334, longitude: 127.4531216, timestamp: "2024-06-06T13:40:13.000Z" },
        { speed: "9.2", course: "269.7", altitude: "12.7", satellites: "12", hdop: "0.5", lat: "0045.797780", lon: "12727.187296", latD: "N", lonD: "E", latitude: 0.7632963333333334, longitude: 127.4531216, timestamp: "2024-06-06T13:40:13.000Z" },
        { speed: "9.2", course: "269.7", altitude: "12.7", satellites: "12", hdop: "0.5", lat: "0045.797780", lon: "12727.187296", latD: "N", lonD: "E", latitude: 0.7632963333333334, longitude: 127.4531216, timestamp: "2024-06-06T13:40:13.000Z" },
        { speed: "9.2", course: "270.6", altitude: "13.4", satellites: "12", hdop: "0.5", lat: "0045.797123", lon: "12727.136185", latD: "N", lonD: "E", latitude: 0.7632853833333333, longitude: 127.45226975, timestamp: "2024-06-06T13:40:33.000Z" },
        { speed: "9.2", course: "270.6", altitude: "13.4", satellites: "12", hdop: "0.5", lat: "0045.797123", lon: "12727.136185", latD: "N", lonD: "E", latitude: 0.7632853833333333, longitude: 127.45226975, timestamp: "2024-06-06T13:40:33.000Z" },
        { speed: "9.2", course: "270.6", altitude: "13.4", satellites: "12", hdop: "0.5", lat: "0045.797123", lon: "12727.136185", latD: "N", lonD: "E", latitude: 0.7632853833333333, longitude: 127.45226975, timestamp: "2024-06-06T13:40:33.000Z" },
        { speed: "9.2", course: "268.9", altitude: "12.8", satellites: "12", hdop: "0.5", lat: "0045.796783", lon: "12727.086620", latD: "N", lonD: "E", latitude: 0.7632797166666666, longitude: 127.45144366666666, timestamp: "2024-06-06T13:40:53.000Z" },
        { speed: "9.2", course: "268.9", altitude: "12.8", satellites: "12", hdop: "0.5", lat: "0045.796783", lon: "12727.086620", latD: "N", lonD: "E", latitude: 0.7632797166666666, longitude: 127.45144366666666, timestamp: "2024-06-06T13:40:53.000Z" },
        { speed: "9.2", course: "270.6", altitude: "13.4", satellites: "12", hdop: "0.5", lat: "0045.797123", lon: "12727.136185", latD: "N", lonD: "E", latitude: 0.7632853833333333, longitude: 127.45226975, timestamp: "2024-06-06T13:40:33.000Z" },
        { speed: "9.2", course: "268.9", altitude: "12.8", satellites: "12", hdop: "0.5", lat: "0045.796783", lon: "12727.086620", latD: "N", lonD: "E", latitude: 0.7632797166666666, longitude: 127.45144366666666, timestamp: "2024-06-06T13:40:53.000Z" },
        { speed: "9.2", course: "268.9", altitude: "12.8", satellites: "12", hdop: "0.5", lat: "0045.796783", lon: "12727.086620", latD: "N", lonD: "E", latitude: 0.7632797166666666, longitude: 127.45144366666666, timestamp: "2024-06-06T13:40:53.000Z" },
        { speed: "9.1", course: "269.6", altitude: "11.3", satellites: "12", hdop: "0.5", lat: "0045.797720", lon: "12727.034089", latD: "N", lonD: "E", latitude: 0.7632953333333333, longitude: 127.45056815, timestamp: "2024-06-06T13:41:13.000Z" },
        { speed: "9.1", course: "269.6", altitude: "11.3", satellites: "12", hdop: "0.5", lat: "0045.797720", lon: "12727.034089", latD: "N", lonD: "E", latitude: 0.7632953333333333, longitude: 127.45056815, timestamp: "2024-06-06T13:41:13.000Z" },
        { speed: "9.1", course: "269.6", altitude: "11.3", satellites: "12", hdop: "0.5", lat: "0045.797720", lon: "12727.034089", latD: "N", lonD: "E", latitude: 0.7632953333333333, longitude: 127.45056815, timestamp: "2024-06-06T13:41:13.000Z" },
        { speed: "8.9", course: "268.3", altitude: "12.0", satellites: "12", hdop: "0.5", lat: "0045.798800", lon: "12726.982324", latD: "N", lonD: "E", latitude: 0.7633133333333333, longitude: 127.4497054, timestamp: "2024-06-06T13:41:33.000Z" },
        { speed: "9.1", course: "269.6", altitude: "11.3", satellites: "12", hdop: "0.5", lat: "0045.797720", lon: "12727.034089", latD: "N", lonD: "E", latitude: 0.7632953333333333, longitude: 127.45056815, timestamp: "2024-06-06T13:41:13.000Z" },
        { speed: "9.1", course: "269.6", altitude: "11.3", satellites: "12", hdop: "0.5", lat: "0045.797720", lon: "12727.034089", latD: "N", lonD: "E", latitude: 0.7632953333333333, longitude: 127.45056815, timestamp: "2024-06-06T13:41:13.000Z" },
        { speed: "8.9", course: "268.3", altitude: "12.0", satellites: "12", hdop: "0.5", lat: "0045.798800", lon: "12726.982324", latD: "N", lonD: "E", latitude: 0.7633133333333333, longitude: 127.4497054, timestamp: "2024-06-06T13:41:33.000Z" },
        { speed: "8.9", course: "268.3", altitude: "12.0", satellites: "12", hdop: "0.5", lat: "0045.798800", lon: "12726.982324", latD: "N", lonD: "E", latitude: 0.7633133333333333, longitude: 127.4497054, timestamp: "2024-06-06T13:41:33.000Z" },
        { speed: "9.2", course: "268.4", altitude: "9.8", satellites: "12", hdop: "0.4", lat: "0045.797424", lon: "12726.930165", latD: "N", lonD: "E", latitude: 0.7632904, longitude: 127.44883608333333, timestamp: "2024-06-06T13:41:53.000Z" },
        { speed: "9.2", course: "268.4", altitude: "9.8", satellites: "12", hdop: "0.4", lat: "0045.797424", lon: "12726.930165", latD: "N", lonD: "E", latitude: 0.7632904, longitude: 127.44883608333333, timestamp: "2024-06-06T13:41:53.000Z" },
        { speed: "9.2", course: "268.4", altitude: "9.8", satellites: "12", hdop: "0.4", lat: "0045.797424", lon: "12726.930165", latD: "N", lonD: "E", latitude: 0.7632904, longitude: 127.44883608333333, timestamp: "2024-06-06T13:41:53.000Z" },
        { speed: "9.2", course: "268.4", altitude: "9.8", satellites: "12", hdop: "0.4", lat: "0045.797424", lon: "12726.930165", latD: "N", lonD: "E", latitude: 0.7632904, longitude: 127.44883608333333, timestamp: "2024-06-06T13:41:53.000Z" },
        { speed: "9.2", course: "270.2", altitude: "13.9", satellites: "12", hdop: "0.5", lat: "0045.797056", lon: "12726.879299", latD: "N", lonD: "E", latitude: 0.7632842666666666, longitude: 127.44798831666667, timestamp: "2024-06-06T13:42:13.000Z" },
        { speed: "9.2", course: "270.2", altitude: "13.9", satellites: "12", hdop: "0.5", lat: "0045.797056", lon: "12726.879299", latD: "N", lonD: "E", latitude: 0.7632842666666666, longitude: 127.44798831666667, timestamp: "2024-06-06T13:42:13.000Z" },
        { speed: "9.2", course: "270.2", altitude: "13.9", satellites: "12", hdop: "0.5", lat: "0045.797056", lon: "12726.879299", latD: "N", lonD: "E", latitude: 0.7632842666666666, longitude: 127.44798831666667, timestamp: "2024-06-06T13:42:13.000Z" },
        { speed: "9.2", course: "270.2", altitude: "13.9", satellites: "12", hdop: "0.5", lat: "0045.797056", lon: "12726.879299", latD: "N", lonD: "E", latitude: 0.7632842666666666, longitude: 127.44798831666667, timestamp: "2024-06-06T13:42:13.000Z" },
        { speed: "9.3", course: "271.0", altitude: "14.4", satellites: "12", hdop: "0.5", lat: "0045.797211", lon: "12726.828125", latD: "N", lonD: "E", latitude: 0.76328685, longitude: 127.44713541666667, timestamp: "2024-06-06T13:42:33.000Z" },
        { speed: "9.3", course: "271.0", altitude: "14.4", satellites: "12", hdop: "0.5", lat: "0045.797211", lon: "12726.828125", latD: "N", lonD: "E", latitude: 0.76328685, longitude: 127.44713541666667, timestamp: "2024-06-06T13:42:33.000Z" },
        { speed: "9.1", course: "272.4", altitude: "15.5", satellites: "12", hdop: "0.6", lat: "0045.798192", lon: "12726.777558", latD: "N", lonD: "E", latitude: 0.7633032, longitude: 127.44629263333333, timestamp: "2024-06-06T13:42:53.000Z" },
        { speed: "9.3", course: "271.0", altitude: "14.4", satellites: "12", hdop: "0.5", lat: "0045.797211", lon: "12726.828125", latD: "N", lonD: "E", latitude: 0.76328685, longitude: 127.44713541666667, timestamp: "2024-06-06T13:42:33.000Z" },
        { speed: "9.3", course: "271.0", altitude: "14.4", satellites: "12", hdop: "0.5", lat: "0045.797211", lon: "12726.828125", latD: "N", lonD: "E", latitude: 0.76328685, longitude: 127.44713541666667, timestamp: "2024-06-06T13:42:33.000Z" },
        { speed: "9.1", course: "272.4", altitude: "15.5", satellites: "12", hdop: "0.6", lat: "0045.798192", lon: "12726.777558", latD: "N", lonD: "E", latitude: 0.7633032, longitude: 127.44629263333333, timestamp: "2024-06-06T13:42:53.000Z" },
        { speed: "9.1", course: "272.4", altitude: "15.5", satellites: "12", hdop: "0.6", lat: "0045.798192", lon: "12726.777558", latD: "N", lonD: "E", latitude: 0.7633032, longitude: 127.44629263333333, timestamp: "2024-06-06T13:42:53.000Z" },
        { speed: "9.1", course: "272.4", altitude: "15.5", satellites: "12", hdop: "0.6", lat: "0045.798192", lon: "12726.777558", latD: "N", lonD: "E", latitude: 0.7633032, longitude: 127.44629263333333, timestamp: "2024-06-06T13:42:53.000Z" },
        { speed: "9.2", course: "273.2", altitude: "12.6", satellites: "12", hdop: "0.6", lat: "0045.800664", lon: "12726.728512", latD: "N", lonD: "E", latitude: 0.7633443999999999, longitude: 127.4454752, timestamp: "2024-06-06T13:43:13.000Z" },
        { speed: "9.2", course: "273.2", altitude: "12.6", satellites: "12", hdop: "0.6", lat: "0045.800664", lon: "12726.728512", latD: "N", lonD: "E", latitude: 0.7633443999999999, longitude: 127.4454752, timestamp: "2024-06-06T13:43:13.000Z" },
        { speed: "9.2", course: "273.2", altitude: "12.6", satellites: "12", hdop: "0.6", lat: "0045.800664", lon: "12726.728512", latD: "N", lonD: "E", latitude: 0.7633443999999999, longitude: 127.4454752, timestamp: "2024-06-06T13:43:13.000Z" },
        { speed: "9.2", course: "273.2", altitude: "12.6", satellites: "12", hdop: "0.6", lat: "0045.800664", lon: "12726.728512", latD: "N", lonD: "E", latitude: 0.7633443999999999, longitude: 127.4454752, timestamp: "2024-06-06T13:43:13.000Z" },
        { speed: "9.2", course: "273.2", altitude: "12.6", satellites: "12", hdop: "0.6", lat: "0045.800664", lon: "12726.728512", latD: "N", lonD: "E", latitude: 0.7633443999999999, longitude: 127.4454752, timestamp: "2024-06-06T13:43:13.000Z" },
        { speed: "9.3", course: "269.5", altitude: "13.1", satellites: "12", hdop: "0.6", lat: "0045.801295", lon: "12726.677614", latD: "N", lonD: "E", latitude: 0.7633549166666668, longitude: 127.4446269, timestamp: "2024-06-06T13:43:33.000Z" },
        { speed: "9.3", course: "269.5", altitude: "13.1", satellites: "12", hdop: "0.6", lat: "0045.801295", lon: "12726.677614", latD: "N", lonD: "E", latitude: 0.7633549166666668, longitude: 127.4446269, timestamp: "2024-06-06T13:43:33.000Z" },
        { speed: "9.1", course: "266.3", altitude: "14.4", satellites: "12", hdop: "0.6", lat: "0045.799663", lon: "12726.628011", latD: "N", lonD: "E", latitude: 0.7633277166666667, longitude: 127.44380018333334, timestamp: "2024-06-06T13:43:53.000Z" },
        { speed: "9.1", course: "266.3", altitude: "14.4", satellites: "12", hdop: "0.6", lat: "0045.799663", lon: "12726.628011", latD: "N", lonD: "E", latitude: 0.7633277166666667, longitude: 127.44380018333334, timestamp: "2024-06-06T13:43:53.000Z" },
        { speed: "9.3", course: "269.5", altitude: "13.1", satellites: "12", hdop: "0.6", lat: "0045.801295", lon: "12726.677614", latD: "N", lonD: "E", latitude: 0.7633549166666668, longitude: 127.4446269, timestamp: "2024-06-06T13:43:33.000Z" },
        { speed: "9.1", course: "266.3", altitude: "14.4", satellites: "12", hdop: "0.6", lat: "0045.799663", lon: "12726.628011", latD: "N", lonD: "E", latitude: 0.7633277166666667, longitude: 127.44380018333334, timestamp: "2024-06-06T13:43:53.000Z" },
        { speed: "9.1", course: "266.3", altitude: "14.4", satellites: "12", hdop: "0.6", lat: "0045.799663", lon: "12726.628011", latD: "N", lonD: "E", latitude: 0.7633277166666667, longitude: 127.44380018333334, timestamp: "2024-06-06T13:43:53.000Z" },
        { speed: "8.9", course: "266.1", altitude: "12.6", satellites: "12", hdop: "0.6", lat: "0045.795841", lon: "12726.579621", latD: "N", lonD: "E", latitude: 0.7632640166666668, longitude: 127.44299368333333, timestamp: "2024-06-06T13:44:13.000Z" },
        { speed: "8.9", course: "266.1", altitude: "12.6", satellites: "12", hdop: "0.6", lat: "0045.795841", lon: "12726.579621", latD: "N", lonD: "E", latitude: 0.7632640166666668, longitude: 127.44299368333333, timestamp: "2024-06-06T13:44:13.000Z" },
        { speed: "8.9", course: "266.1", altitude: "12.6", satellites: "12", hdop: "0.6", lat: "0045.795841", lon: "12726.579621", latD: "N", lonD: "E", latitude: 0.7632640166666668, longitude: 127.44299368333333, timestamp: "2024-06-06T13:44:13.000Z" },
        { speed: "8.7", course: "268.4", altitude: "15.5", satellites: "12", hdop: "0.6", lat: "0045.794832", lon: "12726.529571", latD: "N", lonD: "E", latitude: 0.7632472, longitude: 127.44215951666666, timestamp: "2024-06-06T13:44:33.000Z" },
        { speed: "8.7", course: "268.4", altitude: "15.5", satellites: "12", hdop: "0.6", lat: "0045.794832", lon: "12726.529571", latD: "N", lonD: "E", latitude: 0.7632472, longitude: 127.44215951666666, timestamp: "2024-06-06T13:44:33.000Z" },
        { speed: "8.9", course: "266.1", altitude: "12.6", satellites: "12", hdop: "0.6", lat: "0045.795841", lon: "12726.579621", latD: "N", lonD: "E", latitude: 0.7632640166666668, longitude: 127.44299368333333, timestamp: "2024-06-06T13:44:13.000Z" },
        { speed: "8.7", course: "268.4", altitude: "15.5", satellites: "12", hdop: "0.6", lat: "0045.794832", lon: "12726.529571", latD: "N", lonD: "E", latitude: 0.7632472, longitude: 127.44215951666666, timestamp: "2024-06-06T13:44:33.000Z" },
        { speed: "8.7", course: "268.4", altitude: "15.5", satellites: "12", hdop: "0.6", lat: "0045.794832", lon: "12726.529571", latD: "N", lonD: "E", latitude: 0.7632472, longitude: 127.44215951666666, timestamp: "2024-06-06T13:44:33.000Z" },
        { speed: "9.1", course: "271.8", altitude: "18.1", satellites: "11", hdop: "0.6", lat: "0045.794384", lon: "12726.480392", latD: "N", lonD: "E", latitude: 0.7632397333333334, longitude: 127.44133986666667, timestamp: "2024-06-06T13:44:53.000Z" },
        { speed: "9.1", course: "271.8", altitude: "18.1", satellites: "11", hdop: "0.6", lat: "0045.794384", lon: "12726.480392", latD: "N", lonD: "E", latitude: 0.7632397333333334, longitude: 127.44133986666667, timestamp: "2024-06-06T13:44:53.000Z" },
        { speed: "9.1", course: "271.8", altitude: "18.1", satellites: "11", hdop: "0.6", lat: "0045.794384", lon: "12726.480392", latD: "N", lonD: "E", latitude: 0.7632397333333334, longitude: 127.44133986666667, timestamp: "2024-06-06T13:44:53.000Z" },
        { speed: "9.1", course: "271.8", altitude: "18.1", satellites: "11", hdop: "0.6", lat: "0045.794384", lon: "12726.480392", latD: "N", lonD: "E", latitude: 0.7632397333333334, longitude: 127.44133986666667, timestamp: "2024-06-06T13:44:53.000Z" },
        { speed: "8.8", course: "273.8", altitude: "14.8", satellites: "11", hdop: "0.6", lat: "0045.795219", lon: "12726.433944", latD: "N", lonD: "E", latitude: 0.76325365, longitude: 127.44056573333333, timestamp: "2024-06-06T13:45:13.000Z" },
        { speed: "8.8", course: "273.8", altitude: "14.8", satellites: "11", hdop: "0.6", lat: "0045.795219", lon: "12726.433944", latD: "N", lonD: "E", latitude: 0.76325365, longitude: 127.44056573333333, timestamp: "2024-06-06T13:45:13.000Z" },
        { speed: "8.8", course: "273.8", altitude: "14.8", satellites: "11", hdop: "0.6", lat: "0045.795219", lon: "12726.433944", latD: "N", lonD: "E", latitude: 0.76325365, longitude: 127.44056573333333, timestamp: "2024-06-06T13:45:13.000Z" },
        { speed: "8.8", course: "273.8", altitude: "14.8", satellites: "11", hdop: "0.6", lat: "0045.795219", lon: "12726.433944", latD: "N", lonD: "E", latitude: 0.76325365, longitude: 127.44056573333333, timestamp: "2024-06-06T13:45:13.000Z" },
        { speed: "8.4", course: "273.4", altitude: "11.1", satellites: "10", hdop: "0.7", lat: "0045.798605", lon: "12726.387037", latD: "N", lonD: "E", latitude: 0.7633100833333334, longitude: 127.43978395, timestamp: "2024-06-06T13:45:33.000Z" },
        { speed: "8.4", course: "273.4", altitude: "11.1", satellites: "10", hdop: "0.7", lat: "0045.798605", lon: "12726.387037", latD: "N", lonD: "E", latitude: 0.7633100833333334, longitude: 127.43978395, timestamp: "2024-06-06T13:45:33.000Z" },
        { speed: "8.3", course: "274.1", altitude: "9.0", satellites: "11", hdop: "0.6", lat: "0045.803159", lon: "12726.339250", latD: "N", lonD: "E", latitude: 0.7633859833333333, longitude: 127.4389875, timestamp: "2024-06-06T13:45:53.000Z" },
        { speed: "8.3", course: "274.1", altitude: "9.0", satellites: "11", hdop: "0.6", lat: "0045.803159", lon: "12726.339250", latD: "N", lonD: "E", latitude: 0.7633859833333333, longitude: 127.4389875, timestamp: "2024-06-06T13:45:53.000Z" },
        { speed: "8.4", course: "273.4", altitude: "11.1", satellites: "10", hdop: "0.7", lat: "0045.798605", lon: "12726.387037", latD: "N", lonD: "E", latitude: 0.7633100833333334, longitude: 127.43978395, timestamp: "2024-06-06T13:45:33.000Z" },
        { speed: "8.4", course: "273.4", altitude: "11.1", satellites: "10", hdop: "0.7", lat: "0045.798605", lon: "12726.387037", latD: "N", lonD: "E", latitude: 0.7633100833333334, longitude: 127.43978395, timestamp: "2024-06-06T13:45:33.000Z" },
        { speed: "8.3", course: "274.1", altitude: "9.0", satellites: "11", hdop: "0.6", lat: "0045.803159", lon: "12726.339250", latD: "N", lonD: "E", latitude: 0.7633859833333333, longitude: 127.4389875, timestamp: "2024-06-06T13:45:53.000Z" },
        { speed: "8.7", course: "272.3", altitude: "9.8", satellites: "11", hdop: "0.5", lat: "0045.805173", lon: "12726.292064", latD: "N", lonD: "E", latitude: 0.7634195500000001, longitude: 127.43820106666666, timestamp: "2024-06-06T13:46:14.000Z" },
        { speed: "8.7", course: "272.3", altitude: "9.8", satellites: "11", hdop: "0.5", lat: "0045.805173", lon: "12726.292064", latD: "N", lonD: "E", latitude: 0.7634195500000001, longitude: 127.43820106666666, timestamp: "2024-06-06T13:46:14.000Z" },
        { speed: "8.3", course: "274.1", altitude: "9.0", satellites: "11", hdop: "0.6", lat: "0045.803159", lon: "12726.339250", latD: "N", lonD: "E", latitude: 0.7633859833333333, longitude: 127.4389875, timestamp: "2024-06-06T13:45:53.000Z" },
        { speed: "8.7", course: "272.3", altitude: "9.8", satellites: "11", hdop: "0.5", lat: "0045.805173", lon: "12726.292064", latD: "N", lonD: "E", latitude: 0.7634195500000001, longitude: 127.43820106666666, timestamp: "2024-06-06T13:46:14.000Z" },
        { speed: "8.7", course: "272.3", altitude: "9.8", satellites: "11", hdop: "0.5", lat: "0045.805173", lon: "12726.292064", latD: "N", lonD: "E", latitude: 0.7634195500000001, longitude: 127.43820106666666, timestamp: "2024-06-06T13:46:14.000Z" },
        { speed: "8.8", course: "272.8", altitude: "12.6", satellites: "11", hdop: "0.6", lat: "0045.807411", lon: "12726.240832", latD: "N", lonD: "E", latitude: 0.7634568500000001, longitude: 127.4373472, timestamp: "2024-06-06T13:46:33.000Z" },
        { speed: "8.8", course: "272.8", altitude: "12.6", satellites: "11", hdop: "0.6", lat: "0045.807411", lon: "12726.240832", latD: "N", lonD: "E", latitude: 0.7634568500000001, longitude: 127.4373472, timestamp: "2024-06-06T13:46:33.000Z" },
        { speed: "8.8", course: "272.8", altitude: "12.6", satellites: "11", hdop: "0.6", lat: "0045.807411", lon: "12726.240832", latD: "N", lonD: "E", latitude: 0.7634568500000001, longitude: 127.4373472, timestamp: "2024-06-06T13:46:33.000Z" },
        { speed: "8.9", course: "273.8", altitude: "11.5", satellites: "11", hdop: "0.6", lat: "0045.810797", lon: "12726.193999", latD: "N", lonD: "E", latitude: 0.7635132833333333, longitude: 127.43656665, timestamp: "2024-06-06T13:46:53.000Z" },
        { speed: "8.9", course: "273.8", altitude: "11.5", satellites: "11", hdop: "0.6", lat: "0045.810797", lon: "12726.193999", latD: "N", lonD: "E", latitude: 0.7635132833333333, longitude: 127.43656665, timestamp: "2024-06-06T13:46:53.000Z" },
        { speed: "8.8", course: "272.8", altitude: "12.6", satellites: "11", hdop: "0.6", lat: "0045.807411", lon: "12726.240832", latD: "N", lonD: "E", latitude: 0.7634568500000001, longitude: 127.4373472, timestamp: "2024-06-06T13:46:33.000Z" },
        { speed: "8.9", course: "273.8", altitude: "11.5", satellites: "11", hdop: "0.6", lat: "0045.810797", lon: "12726.193999", latD: "N", lonD: "E", latitude: 0.7635132833333333, longitude: 127.43656665, timestamp: "2024-06-06T13:46:53.000Z" },
        { speed: "9.0", course: "273.1", altitude: "12.0", satellites: "11", hdop: "0.5", lat: "0045.813928", lon: "12726.142485", latD: "N", lonD: "E", latitude: 0.7635654666666666, longitude: 127.43570808333334, timestamp: "2024-06-06T13:47:14.000Z" },
        { speed: "9.0", course: "273.1", altitude: "12.0", satellites: "11", hdop: "0.5", lat: "0045.813928", lon: "12726.142485", latD: "N", lonD: "E", latitude: 0.7635654666666666, longitude: 127.43570808333334, timestamp: "2024-06-06T13:47:14.000Z" },
        { speed: "9.0", course: "273.1", altitude: "12.0", satellites: "11", hdop: "0.5", lat: "0045.813928", lon: "12726.142485", latD: "N", lonD: "E", latitude: 0.7635654666666666, longitude: 127.43570808333334, timestamp: "2024-06-06T13:47:14.000Z" },
        { speed: "8.6", course: "273.6", altitude: "10.4", satellites: "11", hdop: "0.6", lat: "0045.817616", lon: "12726.093669", latD: "N", lonD: "E", latitude: 0.7636269333333333, longitude: 127.43489448333334, timestamp: "2024-06-06T13:47:34.000Z" },
        { speed: "9.0", course: "273.1", altitude: "12.0", satellites: "11", hdop: "0.5", lat: "0045.813928", lon: "12726.142485", latD: "N", lonD: "E", latitude: 0.7635654666666666, longitude: 127.43570808333334, timestamp: "2024-06-06T13:47:14.000Z" },
        { speed: "9.0", course: "273.1", altitude: "12.0", satellites: "11", hdop: "0.5", lat: "0045.813928", lon: "12726.142485", latD: "N", lonD: "E", latitude: 0.7635654666666666, longitude: 127.43570808333334, timestamp: "2024-06-06T13:47:14.000Z" },
        { speed: "8.6", course: "273.6", altitude: "10.4", satellites: "11", hdop: "0.6", lat: "0045.817616", lon: "12726.093669", latD: "N", lonD: "E", latitude: 0.7636269333333333, longitude: 127.43489448333334, timestamp: "2024-06-06T13:47:34.000Z" },
        { speed: "8.6", course: "273.6", altitude: "10.4", satellites: "11", hdop: "0.6", lat: "0045.817616", lon: "12726.093669", latD: "N", lonD: "E", latitude: 0.7636269333333333, longitude: 127.43489448333334, timestamp: "2024-06-06T13:47:34.000Z" },
        { speed: "8.6", course: "273.6", altitude: "10.4", satellites: "11", hdop: "0.6", lat: "0045.817616", lon: "12726.093669", latD: "N", lonD: "E", latitude: 0.7636269333333333, longitude: 127.43489448333334, timestamp: "2024-06-06T13:47:34.000Z" },
        { speed: "8.8", course: "274.7", altitude: "9.2", satellites: "11", hdop: "0.6", lat: "0045.822636", lon: "12726.049403", latD: "N", lonD: "E", latitude: 0.7637106, longitude: 127.43415671666666, timestamp: "2024-06-06T13:47:53.000Z" },
        { speed: "8.8", course: "274.7", altitude: "9.2", satellites: "11", hdop: "0.6", lat: "0045.822636", lon: "12726.049403", latD: "N", lonD: "E", latitude: 0.7637106, longitude: 127.43415671666666, timestamp: "2024-06-06T13:47:53.000Z" },
        { speed: "8.8", course: "274.7", altitude: "9.2", satellites: "11", hdop: "0.6", lat: "0045.822636", lon: "12726.049403", latD: "N", lonD: "E", latitude: 0.7637106, longitude: 127.43415671666666, timestamp: "2024-06-06T13:47:53.000Z" },
        { speed: "8.6", course: "273.8", altitude: "8.9", satellites: "11", hdop: "0.6", lat: "0045.824387", lon: "12725.999292", latD: "N", lonD: "E", latitude: 0.7637397833333334, longitude: 127.43332153333333, timestamp: "2024-06-06T13:48:14.000Z" },
        { speed: "8.6", course: "273.8", altitude: "8.9", satellites: "11", hdop: "0.6", lat: "0045.824387", lon: "12725.999292", latD: "N", lonD: "E", latitude: 0.7637397833333334, longitude: 127.43332153333333, timestamp: "2024-06-06T13:48:14.000Z" },
        { speed: "8.6", course: "273.8", altitude: "8.9", satellites: "11", hdop: "0.6", lat: "0045.824387", lon: "12725.999292", latD: "N", lonD: "E", latitude: 0.7637397833333334, longitude: 127.43332153333333, timestamp: "2024-06-06T13:48:14.000Z" },
        { speed: "8.6", course: "273.8", altitude: "8.9", satellites: "11", hdop: "0.6", lat: "0045.824387", lon: "12725.999292", latD: "N", lonD: "E", latitude: 0.7637397833333334, longitude: 127.43332153333333, timestamp: "2024-06-06T13:48:14.000Z" },
        { speed: "8.6", course: "273.8", altitude: "8.9", satellites: "11", hdop: "0.6", lat: "0045.824387", lon: "12725.999292", latD: "N", lonD: "E", latitude: 0.7637397833333334, longitude: 127.43332153333333, timestamp: "2024-06-06T13:48:14.000Z" },
        { speed: "8.6", course: "270.1", altitude: "14.4", satellites: "11", hdop: "0.6", lat: "0045.826673", lon: "12725.949210", latD: "N", lonD: "E", latitude: 0.7637778833333333, longitude: 127.43248683333333, timestamp: "2024-06-06T13:48:34.000Z" },
        { speed: "8.6", course: "270.1", altitude: "14.4", satellites: "11", hdop: "0.6", lat: "0045.826673", lon: "12725.949210", latD: "N", lonD: "E", latitude: 0.7637778833333333, longitude: 127.43248683333333, timestamp: "2024-06-06T13:48:34.000Z" },
        { speed: "8.6", course: "270.1", altitude: "14.4", satellites: "11", hdop: "0.6", lat: "0045.826673", lon: "12725.949210", latD: "N", lonD: "E", latitude: 0.7637778833333333, longitude: 127.43248683333333, timestamp: "2024-06-06T13:48:34.000Z" },
        { speed: "8.6", course: "270.1", altitude: "14.4", satellites: "11", hdop: "0.6", lat: "0045.826673", lon: "12725.949210", latD: "N", lonD: "E", latitude: 0.7637778833333333, longitude: 127.43248683333333, timestamp: "2024-06-06T13:48:34.000Z" },
        { speed: "8.5", course: "273.8", altitude: "15.4", satellites: "11", hdop: "0.6", lat: "0045.828292", lon: "12725.903812", latD: "N", lonD: "E", latitude: 0.7638048666666666, longitude: 127.4317302, timestamp: "2024-06-06T13:48:54.000Z" },
        { speed: "8.5", course: "273.8", altitude: "15.4", satellites: "11", hdop: "0.6", lat: "0045.828292", lon: "12725.903812", latD: "N", lonD: "E", latitude: 0.7638048666666666, longitude: 127.4317302, timestamp: "2024-06-06T13:48:54.000Z" },
        { speed: "8.5", course: "273.8", altitude: "15.4", satellites: "11", hdop: "0.6", lat: "0045.828292", lon: "12725.903812", latD: "N", lonD: "E", latitude: 0.7638048666666666, longitude: 127.4317302, timestamp: "2024-06-06T13:48:54.000Z" },
        { speed: "8.5", course: "273.8", altitude: "15.4", satellites: "11", hdop: "0.6", lat: "0045.828292", lon: "12725.903812", latD: "N", lonD: "E", latitude: 0.7638048666666666, longitude: 127.4317302, timestamp: "2024-06-06T13:48:54.000Z" },
        { speed: "9.0", course: "276.4", altitude: "14.7", satellites: "11", hdop: "0.6", lat: "0045.830805", lon: "12725.854339", latD: "N", lonD: "E", latitude: 0.76384675, longitude: 127.43090565, timestamp: "2024-06-06T13:49:14.000Z" },
        { speed: "8.5", course: "273.8", altitude: "15.4", satellites: "11", hdop: "0.6", lat: "0045.828292", lon: "12725.903812", latD: "N", lonD: "E", latitude: 0.7638048666666666, longitude: 127.4317302, timestamp: "2024-06-06T13:48:54.000Z" },
        { speed: "9.0", course: "276.4", altitude: "14.7", satellites: "11", hdop: "0.6", lat: "0045.830805", lon: "12725.854339", latD: "N", lonD: "E", latitude: 0.76384675, longitude: 127.43090565, timestamp: "2024-06-06T13:49:14.000Z" },
        { speed: "9.0", course: "276.4", altitude: "14.7", satellites: "11", hdop: "0.6", lat: "0045.830805", lon: "12725.854339", latD: "N", lonD: "E", latitude: 0.76384675, longitude: 127.43090565, timestamp: "2024-06-06T13:49:14.000Z" },
        { speed: "8.6", course: "275.2", altitude: "7.2", satellites: "10", hdop: "0.7", lat: "0045.836445", lon: "12725.806283", latD: "N", lonD: "E", latitude: 0.76394075, longitude: 127.43010471666666, timestamp: "2024-06-06T13:49:34.000Z" },
        { speed: "8.6", course: "275.2", altitude: "7.2", satellites: "10", hdop: "0.7", lat: "0045.836445", lon: "12725.806283", latD: "N", lonD: "E", latitude: 0.76394075, longitude: 127.43010471666666, timestamp: "2024-06-06T13:49:34.000Z" },
        { speed: "8.6", course: "275.2", altitude: "7.2", satellites: "10", hdop: "0.7", lat: "0045.836445", lon: "12725.806283", latD: "N", lonD: "E", latitude: 0.76394075, longitude: 127.43010471666666, timestamp: "2024-06-06T13:49:34.000Z" },
        { speed: "9.1", course: "274.6", altitude: "12.4", satellites: "11", hdop: "0.6", lat: "0045.840935", lon: "12725.757025", latD: "N", lonD: "E", latitude: 0.7640155833333334, longitude: 127.42928375, timestamp: "2024-06-06T13:49:54.000Z" },
        { speed: "8.6", course: "275.2", altitude: "7.2", satellites: "10", hdop: "0.7", lat: "0045.836445", lon: "12725.806283", latD: "N", lonD: "E", latitude: 0.76394075, longitude: 127.43010471666666, timestamp: "2024-06-06T13:49:34.000Z" },
        { speed: "8.6", course: "275.2", altitude: "7.2", satellites: "10", hdop: "0.7", lat: "0045.836445", lon: "12725.806283", latD: "N", lonD: "E", latitude: 0.76394075, longitude: 127.43010471666666, timestamp: "2024-06-06T13:49:34.000Z" },
        { speed: "9.1", course: "274.6", altitude: "12.4", satellites: "11", hdop: "0.6", lat: "0045.840935", lon: "12725.757025", latD: "N", lonD: "E", latitude: 0.7640155833333334, longitude: 127.42928375, timestamp: "2024-06-06T13:49:54.000Z" },
        { speed: "9.1", course: "274.6", altitude: "12.4", satellites: "11", hdop: "0.6", lat: "0045.840935", lon: "12725.757025", latD: "N", lonD: "E", latitude: 0.7640155833333334, longitude: 127.42928375, timestamp: "2024-06-06T13:49:54.000Z" },
        { speed: "9.2", course: "274.3", altitude: "12.9", satellites: "10", hdop: "0.7", lat: "0045.844553", lon: "12725.707818", latD: "N", lonD: "E", latitude: 0.7640758833333333, longitude: 127.42846363333334, timestamp: "2024-06-06T13:50:14.000Z" },
        { speed: "9.2", course: "274.3", altitude: "12.9", satellites: "10", hdop: "0.7", lat: "0045.844553", lon: "12725.707818", latD: "N", lonD: "E", latitude: 0.7640758833333333, longitude: 127.42846363333334, timestamp: "2024-06-06T13:50:14.000Z" },
        { speed: "9.1", course: "275.0", altitude: "10.3", satellites: "11", hdop: "0.5", lat: "0045.848943", lon: "12725.658120", latD: "N", lonD: "E", latitude: 0.76414905, longitude: 127.42763533333333, timestamp: "2024-06-06T13:50:34.000Z" },
        { speed: "9.1", course: "275.0", altitude: "10.3", satellites: "11", hdop: "0.5", lat: "0045.848943", lon: "12725.658120", latD: "N", lonD: "E", latitude: 0.76414905, longitude: 127.42763533333333, timestamp: "2024-06-06T13:50:34.000Z" },
        { speed: "9.2", course: "274.3", altitude: "12.9", satellites: "10", hdop: "0.7", lat: "0045.844553", lon: "12725.707818", latD: "N", lonD: "E", latitude: 0.7640758833333333, longitude: 127.42846363333334, timestamp: "2024-06-06T13:50:14.000Z" },
        { speed: "9.2", course: "274.3", altitude: "12.9", satellites: "10", hdop: "0.7", lat: "0045.844553", lon: "12725.707818", latD: "N", lonD: "E", latitude: 0.7640758833333333, longitude: 127.42846363333334, timestamp: "2024-06-06T13:50:14.000Z" },
        { speed: "9.1", course: "275.0", altitude: "10.3", satellites: "11", hdop: "0.5", lat: "0045.848943", lon: "12725.658120", latD: "N", lonD: "E", latitude: 0.76414905, longitude: 127.42763533333333, timestamp: "2024-06-06T13:50:34.000Z" },
        { speed: "9.0", course: "274.4", altitude: "9.1", satellites: "11", hdop: "0.5", lat: "0045.851977", lon: "12725.611025", latD: "N", lonD: "E", latitude: 0.7641996166666666, longitude: 127.42685041666667, timestamp: "2024-06-06T13:50:54.000Z" },
        { speed: "9.0", course: "274.4", altitude: "9.1", satellites: "11", hdop: "0.5", lat: "0045.851977", lon: "12725.611025", latD: "N", lonD: "E", latitude: 0.7641996166666666, longitude: 127.42685041666667, timestamp: "2024-06-06T13:50:54.000Z" },
        { speed: "9.1", course: "275.0", altitude: "10.3", satellites: "11", hdop: "0.5", lat: "0045.848943", lon: "12725.658120", latD: "N", lonD: "E", latitude: 0.76414905, longitude: 127.42763533333333, timestamp: "2024-06-06T13:50:34.000Z" },
        { speed: "9.0", course: "274.4", altitude: "9.1", satellites: "11", hdop: "0.5", lat: "0045.851977", lon: "12725.611025", latD: "N", lonD: "E", latitude: 0.7641996166666666, longitude: 127.42685041666667, timestamp: "2024-06-06T13:50:54.000Z" },
        { speed: "9.0", course: "274.4", altitude: "9.1", satellites: "11", hdop: "0.5", lat: "0045.851977", lon: "12725.611025", latD: "N", lonD: "E", latitude: 0.7641996166666666, longitude: 127.42685041666667, timestamp: "2024-06-06T13:50:54.000Z" },
        { speed: "9.1", course: "275.4", altitude: "11.8", satellites: "11", hdop: "0.5", lat: "0045.857462", lon: "12725.562259", latD: "N", lonD: "E", latitude: 0.7642910333333333, longitude: 127.42603765, timestamp: "2024-06-06T13:51:14.000Z" },
        { speed: "9.1", course: "275.4", altitude: "11.8", satellites: "11", hdop: "0.5", lat: "0045.857462", lon: "12725.562259", latD: "N", lonD: "E", latitude: 0.7642910333333333, longitude: 127.42603765, timestamp: "2024-06-06T13:51:14.000Z" },
        { speed: "9.1", course: "275.4", altitude: "11.8", satellites: "11", hdop: "0.5", lat: "0045.857462", lon: "12725.562259", latD: "N", lonD: "E", latitude: 0.7642910333333333, longitude: 127.42603765, timestamp: "2024-06-06T13:51:14.000Z" },
        { speed: "8.7", course: "273.9", altitude: "11.4", satellites: "10", hdop: "0.7", lat: "0045.861998", lon: "12725.513413", latD: "N", lonD: "E", latitude: 0.7643666333333333, longitude: 127.42522355, timestamp: "2024-06-06T13:51:34.000Z" },
        { speed: "8.7", course: "273.9", altitude: "11.4", satellites: "10", hdop: "0.7", lat: "0045.861998", lon: "12725.513413", latD: "N", lonD: "E", latitude: 0.7643666333333333, longitude: 127.42522355, timestamp: "2024-06-06T13:51:34.000Z" },
        { speed: "9.1", course: "275.4", altitude: "11.8", satellites: "11", hdop: "0.5", lat: "0045.857462", lon: "12725.562259", latD: "N", lonD: "E", latitude: 0.7642910333333333, longitude: 127.42603765, timestamp: "2024-06-06T13:51:14.000Z" },
        { speed: "8.7", course: "273.9", altitude: "11.4", satellites: "10", hdop: "0.7", lat: "0045.861998", lon: "12725.513413", latD: "N", lonD: "E", latitude: 0.7643666333333333, longitude: 127.42522355, timestamp: "2024-06-06T13:51:34.000Z" },
        { speed: "8.7", course: "273.9", altitude: "11.4", satellites: "10", hdop: "0.7", lat: "0045.861998", lon: "12725.513413", latD: "N", lonD: "E", latitude: 0.7643666333333333, longitude: 127.42522355, timestamp: "2024-06-06T13:51:34.000Z" },
        { speed: "8.8", course: "270.8", altitude: "11.1", satellites: "11", hdop: "0.6", lat: "0045.864929", lon: "12725.464786", latD: "N", lonD: "E", latitude: 0.7644154833333333, longitude: 127.4244131, timestamp: "2024-06-06T13:51:54.000Z" },
        { speed: "8.8", course: "270.8", altitude: "11.1", satellites: "11", hdop: "0.6", lat: "0045.864929", lon: "12725.464786", latD: "N", lonD: "E", latitude: 0.7644154833333333, longitude: 127.4244131, timestamp: "2024-06-06T13:51:54.000Z" },
        { speed: "8.7", course: "272.5", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.865789", lon: "12725.416386", latD: "N", lonD: "E", latitude: 0.7644298166666667, longitude: 127.42360643333333, timestamp: "2024-06-06T13:52:14.000Z" },
        { speed: "8.7", course: "272.5", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.865789", lon: "12725.416386", latD: "N", lonD: "E", latitude: 0.7644298166666667, longitude: 127.42360643333333, timestamp: "2024-06-06T13:52:14.000Z" },
        { speed: "8.7", course: "272.5", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.865789", lon: "12725.416386", latD: "N", lonD: "E", latitude: 0.7644298166666667, longitude: 127.42360643333333, timestamp: "2024-06-06T13:52:14.000Z" },
        { speed: "8.8", course: "270.8", altitude: "11.1", satellites: "11", hdop: "0.6", lat: "0045.864929", lon: "12725.464786", latD: "N", lonD: "E", latitude: 0.7644154833333333, longitude: 127.4244131, timestamp: "2024-06-06T13:51:54.000Z" },
        { speed: "8.7", course: "272.5", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.865789", lon: "12725.416386", latD: "N", lonD: "E", latitude: 0.7644298166666667, longitude: 127.42360643333333, timestamp: "2024-06-06T13:52:14.000Z" },
        { speed: "8.7", course: "272.5", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.865789", lon: "12725.416386", latD: "N", lonD: "E", latitude: 0.7644298166666667, longitude: 127.42360643333333, timestamp: "2024-06-06T13:52:14.000Z" },
        { speed: "8.8", course: "271.8", altitude: "5.5", satellites: "11", hdop: "0.6", lat: "0045.865617", lon: "12725.367714", latD: "N", lonD: "E", latitude: 0.76442695, longitude: 127.42279523333333, timestamp: "2024-06-06T13:52:34.000Z" },
        { speed: "8.8", course: "271.8", altitude: "5.5", satellites: "11", hdop: "0.6", lat: "0045.865617", lon: "12725.367714", latD: "N", lonD: "E", latitude: 0.76442695, longitude: 127.42279523333333, timestamp: "2024-06-06T13:52:34.000Z" },
        { speed: "8.8", course: "271.8", altitude: "5.5", satellites: "11", hdop: "0.6", lat: "0045.865617", lon: "12725.367714", latD: "N", lonD: "E", latitude: 0.76442695, longitude: 127.42279523333333, timestamp: "2024-06-06T13:52:34.000Z" },
        { speed: "8.8", course: "271.8", altitude: "5.5", satellites: "11", hdop: "0.6", lat: "0045.865617", lon: "12725.367714", latD: "N", lonD: "E", latitude: 0.76442695, longitude: 127.42279523333333, timestamp: "2024-06-06T13:52:34.000Z" },
        { speed: "8.8", course: "270.1", altitude: "1.2", satellites: "11", hdop: "0.6", lat: "0045.867103", lon: "12725.318598", latD: "N", lonD: "E", latitude: 0.7644517166666667, longitude: 127.42197663333333, timestamp: "2024-06-06T13:52:54.000Z" },
        { speed: "8.8", course: "270.1", altitude: "1.2", satellites: "11", hdop: "0.6", lat: "0045.867103", lon: "12725.318598", latD: "N", lonD: "E", latitude: 0.7644517166666667, longitude: 127.42197663333333, timestamp: "2024-06-06T13:52:54.000Z" },
        { speed: "8.8", course: "270.1", altitude: "1.2", satellites: "11", hdop: "0.6", lat: "0045.867103", lon: "12725.318598", latD: "N", lonD: "E", latitude: 0.7644517166666667, longitude: 127.42197663333333, timestamp: "2024-06-06T13:52:54.000Z" },
        { speed: "8.9", course: "271.7", altitude: "-2.1", satellites: "10", hdop: "0.6", lat: "0045.869810", lon: "12725.269445", latD: "N", lonD: "E", latitude: 0.7644968333333334, longitude: 127.42115741666667, timestamp: "2024-06-06T13:53:14.000Z" },
        { speed: "8.9", course: "271.7", altitude: "-2.1", satellites: "10", hdop: "0.6", lat: "0045.869810", lon: "12725.269445", latD: "N", lonD: "E", latitude: 0.7644968333333334, longitude: 127.42115741666667, timestamp: "2024-06-06T13:53:14.000Z" },
        { speed: "8.9", course: "271.7", altitude: "-2.1", satellites: "10", hdop: "0.6", lat: "0045.869810", lon: "12725.269445", latD: "N", lonD: "E", latitude: 0.7644968333333334, longitude: 127.42115741666667, timestamp: "2024-06-06T13:53:14.000Z" },
        { speed: "8.9", course: "271.7", altitude: "-2.1", satellites: "10", hdop: "0.6", lat: "0045.869810", lon: "12725.269445", latD: "N", lonD: "E", latitude: 0.7644968333333334, longitude: 127.42115741666667, timestamp: "2024-06-06T13:53:14.000Z" },
        { speed: "8.9", course: "271.7", altitude: "-2.1", satellites: "10", hdop: "0.6", lat: "0045.869810", lon: "12725.269445", latD: "N", lonD: "E", latitude: 0.7644968333333334, longitude: 127.42115741666667, timestamp: "2024-06-06T13:53:14.000Z" },
        { speed: "8.6", course: "272.9", altitude: "2.6", satellites: "11", hdop: "0.5", lat: "0045.870832", lon: "12725.220126", latD: "N", lonD: "E", latitude: 0.7645138666666667, longitude: 127.42033543333334, timestamp: "2024-06-06T13:53:34.000Z" },
        { speed: "8.6", course: "272.9", altitude: "2.6", satellites: "11", hdop: "0.5", lat: "0045.870832", lon: "12725.220126", latD: "N", lonD: "E", latitude: 0.7645138666666667, longitude: 127.42033543333334, timestamp: "2024-06-06T13:53:34.000Z" },
        { speed: "8.8", course: "275.1", altitude: "4.7", satellites: "11", hdop: "0.5", lat: "0045.876391", lon: "12725.171278", latD: "N", lonD: "E", latitude: 0.7646065166666667, longitude: 127.4195213, timestamp: "2024-06-06T13:53:54.000Z" },
        { speed: "8.8", course: "275.1", altitude: "4.7", satellites: "11", hdop: "0.5", lat: "0045.876391", lon: "12725.171278", latD: "N", lonD: "E", latitude: 0.7646065166666667, longitude: 127.4195213, timestamp: "2024-06-06T13:53:54.000Z" },
        { speed: "8.6", course: "272.9", altitude: "2.6", satellites: "11", hdop: "0.5", lat: "0045.870832", lon: "12725.220126", latD: "N", lonD: "E", latitude: 0.7645138666666667, longitude: 127.42033543333334, timestamp: "2024-06-06T13:53:34.000Z" },
        { speed: "8.6", course: "272.9", altitude: "2.6", satellites: "11", hdop: "0.5", lat: "0045.870832", lon: "12725.220126", latD: "N", lonD: "E", latitude: 0.7645138666666667, longitude: 127.42033543333334, timestamp: "2024-06-06T13:53:34.000Z" },
        { speed: "8.8", course: "275.1", altitude: "4.7", satellites: "11", hdop: "0.5", lat: "0045.876391", lon: "12725.171278", latD: "N", lonD: "E", latitude: 0.7646065166666667, longitude: 127.4195213, timestamp: "2024-06-06T13:53:54.000Z" },
        { speed: "8.8", course: "275.1", altitude: "4.7", satellites: "11", hdop: "0.5", lat: "0045.876391", lon: "12725.171278", latD: "N", lonD: "E", latitude: 0.7646065166666667, longitude: 127.4195213, timestamp: "2024-06-06T13:53:54.000Z" },
        { speed: "9.1", course: "276.0", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.880471", lon: "12725.122832", latD: "N", lonD: "E", latitude: 0.7646745166666666, longitude: 127.41871386666666, timestamp: "2024-06-06T13:54:14.000Z" },
        { speed: "9.1", course: "276.0", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.880471", lon: "12725.122832", latD: "N", lonD: "E", latitude: 0.7646745166666666, longitude: 127.41871386666666, timestamp: "2024-06-06T13:54:14.000Z" },
        { speed: "8.9", course: "276.6", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.886182", lon: "12725.073988", latD: "N", lonD: "E", latitude: 0.7647697, longitude: 127.4178998, timestamp: "2024-06-06T13:54:34.000Z" },
        { speed: "8.9", course: "276.6", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.886182", lon: "12725.073988", latD: "N", lonD: "E", latitude: 0.7647697, longitude: 127.4178998, timestamp: "2024-06-06T13:54:34.000Z" },
        { speed: "8.9", course: "276.6", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.886182", lon: "12725.073988", latD: "N", lonD: "E", latitude: 0.7647697, longitude: 127.4178998, timestamp: "2024-06-06T13:54:34.000Z" },
        { speed: "9.1", course: "276.0", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.880471", lon: "12725.122832", latD: "N", lonD: "E", latitude: 0.7646745166666666, longitude: 127.41871386666666, timestamp: "2024-06-06T13:54:14.000Z" },
        { speed: "8.9", course: "276.6", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.886182", lon: "12725.073988", latD: "N", lonD: "E", latitude: 0.7647697, longitude: 127.4178998, timestamp: "2024-06-06T13:54:34.000Z" },
        { speed: "8.9", course: "276.6", altitude: "6.9", satellites: "11", hdop: "0.6", lat: "0045.886182", lon: "12725.073988", latD: "N", lonD: "E", latitude: 0.7647697, longitude: 127.4178998, timestamp: "2024-06-06T13:54:34.000Z" },
        { speed: "8.7", course: "277.7", altitude: "6.5", satellites: "11", hdop: "0.6", lat: "0045.891592", lon: "12725.024261", latD: "N", lonD: "E", latitude: 0.7648598666666667, longitude: 127.41707101666667, timestamp: "2024-06-06T13:54:54.000Z" },
        { speed: "8.7", course: "277.7", altitude: "6.5", satellites: "11", hdop: "0.6", lat: "0045.891592", lon: "12725.024261", latD: "N", lonD: "E", latitude: 0.7648598666666667, longitude: 127.41707101666667, timestamp: "2024-06-06T13:54:54.000Z" },
        { speed: "8.7", course: "277.7", altitude: "6.5", satellites: "11", hdop: "0.6", lat: "0045.891592", lon: "12725.024261", latD: "N", lonD: "E", latitude: 0.7648598666666667, longitude: 127.41707101666667, timestamp: "2024-06-06T13:54:54.000Z" },
        { speed: "8.7", course: "277.7", altitude: "6.5", satellites: "11", hdop: "0.6", lat: "0045.891592", lon: "12725.024261", latD: "N", lonD: "E", latitude: 0.7648598666666667, longitude: 127.41707101666667, timestamp: "2024-06-06T13:54:54.000Z" },
        { speed: "8.7", course: "277.7", altitude: "6.5", satellites: "11", hdop: "0.6", lat: "0045.891592", lon: "12725.024261", latD: "N", lonD: "E", latitude: 0.7648598666666667, longitude: 127.41707101666667, timestamp: "2024-06-06T13:54:54.000Z" },
        { speed: "9.4", course: "277.6", altitude: "4.9", satellites: "11", hdop: "0.6", lat: "0045.900347", lon: "12724.975181", latD: "N", lonD: "E", latitude: 0.7650057833333332, longitude: 127.41625301666667, timestamp: "2024-06-06T13:55:14.000Z" },
        { speed: "9.1", course: "278.1", altitude: "5.8", satellites: "11", hdop: "0.5", lat: "0045.908129", lon: "12724.926701", latD: "N", lonD: "E", latitude: 0.7651354833333334, longitude: 127.41544501666667, timestamp: "2024-06-06T13:55:34.000Z" },
        { speed: "9.4", course: "277.6", altitude: "4.9", satellites: "11", hdop: "0.6", lat: "0045.900347", lon: "12724.975181", latD: "N", lonD: "E", latitude: 0.7650057833333332, longitude: 127.41625301666667, timestamp: "2024-06-06T13:55:14.000Z" },
        { speed: "9.1", course: "278.1", altitude: "5.8", satellites: "11", hdop: "0.5", lat: "0045.908129", lon: "12724.926701", latD: "N", lonD: "E", latitude: 0.7651354833333334, longitude: 127.41544501666667, timestamp: "2024-06-06T13:55:34.000Z" },
        { speed: "9.4", course: "277.6", altitude: "4.9", satellites: "11", hdop: "0.6", lat: "0045.900347", lon: "12724.975181", latD: "N", lonD: "E", latitude: 0.7650057833333332, longitude: 127.41625301666667, timestamp: "2024-06-06T13:55:14.000Z" },
        { speed: "9.1", course: "278.1", altitude: "5.8", satellites: "11", hdop: "0.5", lat: "0045.908129", lon: "12724.926701", latD: "N", lonD: "E", latitude: 0.7651354833333334, longitude: 127.41544501666667, timestamp: "2024-06-06T13:55:34.000Z" },
        { speed: "9.7", course: "277.3", altitude: "7.0", satellites: "11", hdop: "0.5", lat: "0045.915248", lon: "12724.876683", latD: "N", lonD: "E", latitude: 0.7652541333333333, longitude: 127.41461138333334, timestamp: "2024-06-06T13:55:54.000Z" },
        { speed: "9.7", course: "277.3", altitude: "7.0", satellites: "11", hdop: "0.5", lat: "0045.915248", lon: "12724.876683", latD: "N", lonD: "E", latitude: 0.7652541333333333, longitude: 127.41461138333334, timestamp: "2024-06-06T13:55:54.000Z" },
        { speed: "9.1", course: "278.1", altitude: "5.8", satellites: "11", hdop: "0.5", lat: "0045.908129", lon: "12724.926701", latD: "N", lonD: "E", latitude: 0.7651354833333334, longitude: 127.41544501666667, timestamp: "2024-06-06T13:55:34.000Z" },
        { speed: "9.7", course: "277.3", altitude: "7.0", satellites: "11", hdop: "0.5", lat: "0045.915248", lon: "12724.876683", latD: "N", lonD: "E", latitude: 0.7652541333333333, longitude: 127.41461138333334, timestamp: "2024-06-06T13:55:54.000Z" },
        { speed: "9.2", course: "276.1", altitude: "6.3", satellites: "11", hdop: "0.5", lat: "0045.920484", lon: "12724.825836", latD: "N", lonD: "E", latitude: 0.7653414000000001, longitude: 127.41376393333333, timestamp: "2024-06-06T13:56:14.000Z" },
        { speed: "9.2", course: "276.1", altitude: "6.3", satellites: "11", hdop: "0.5", lat: "0045.920484", lon: "12724.825836", latD: "N", lonD: "E", latitude: 0.7653414000000001, longitude: 127.41376393333333, timestamp: "2024-06-06T13:56:14.000Z" },
        { speed: "9.2", course: "276.1", altitude: "6.3", satellites: "11", hdop: "0.5", lat: "0045.920484", lon: "12724.825836", latD: "N", lonD: "E", latitude: 0.7653414000000001, longitude: 127.41376393333333, timestamp: "2024-06-06T13:56:14.000Z" },
        { speed: "9.1", course: "276.1", altitude: "5.8", satellites: "11", hdop: "0.6", lat: "0045.925470", lon: "12724.776684", latD: "N", lonD: "E", latitude: 0.7654245, longitude: 127.41294473333333, timestamp: "2024-06-06T13:56:34.000Z" },
        { speed: "9.1", course: "276.1", altitude: "5.8", satellites: "11", hdop: "0.6", lat: "0045.925470", lon: "12724.776684", latD: "N", lonD: "E", latitude: 0.7654245, longitude: 127.41294473333333, timestamp: "2024-06-06T13:56:34.000Z" },
        { speed: "9.1", course: "276.1", altitude: "5.8", satellites: "11", hdop: "0.6", lat: "0045.925470", lon: "12724.776684", latD: "N", lonD: "E", latitude: 0.7654245, longitude: 127.41294473333333, timestamp: "2024-06-06T13:56:34.000Z" },
        { speed: "9.2", course: "276.1", altitude: "6.3", satellites: "11", hdop: "0.5", lat: "0045.920484", lon: "12724.825836", latD: "N", lonD: "E", latitude: 0.7653414000000001, longitude: 127.41376393333333, timestamp: "2024-06-06T13:56:14.000Z" },
        { speed: "9.1", course: "276.1", altitude: "5.8", satellites: "11", hdop: "0.6", lat: "0045.925470", lon: "12724.776684", latD: "N", lonD: "E", latitude: 0.7654245, longitude: 127.41294473333333, timestamp: "2024-06-06T13:56:34.000Z" },
        { speed: "9.2", course: "277.3", altitude: "4.3", satellites: "10", hdop: "0.7", lat: "0045.933148", lon: "12724.728283", latD: "N", lonD: "E", latitude: 0.7655524666666668, longitude: 127.41213805, timestamp: "2024-06-06T13:56:54.000Z" },
        { speed: "9.2", course: "277.3", altitude: "4.3", satellites: "10", hdop: "0.7", lat: "0045.933148", lon: "12724.728283", latD: "N", lonD: "E", latitude: 0.7655524666666668, longitude: 127.41213805, timestamp: "2024-06-06T13:56:54.000Z" },
        { speed: "9.2", course: "277.3", altitude: "4.3", satellites: "10", hdop: "0.7", lat: "0045.933148", lon: "12724.728283", latD: "N", lonD: "E", latitude: 0.7655524666666668, longitude: 127.41213805, timestamp: "2024-06-06T13:56:54.000Z" },
        { speed: "9.1", course: "276.8", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.939539", lon: "12724.678808", latD: "N", lonD: "E", latitude: 0.7656589833333334, longitude: 127.41131346666667, timestamp: "2024-06-06T13:57:14.000Z" },
        { speed: "9.2", course: "277.3", altitude: "4.3", satellites: "10", hdop: "0.7", lat: "0045.933148", lon: "12724.728283", latD: "N", lonD: "E", latitude: 0.7655524666666668, longitude: 127.41213805, timestamp: "2024-06-06T13:56:54.000Z" },
        { speed: "9.2", course: "277.3", altitude: "4.3", satellites: "10", hdop: "0.7", lat: "0045.933148", lon: "12724.728283", latD: "N", lonD: "E", latitude: 0.7655524666666668, longitude: 127.41213805, timestamp: "2024-06-06T13:56:54.000Z" },
        { speed: "9.2", course: "277.3", altitude: "4.3", satellites: "10", hdop: "0.7", lat: "0045.933148", lon: "12724.728283", latD: "N", lonD: "E", latitude: 0.7655524666666668, longitude: 127.41213805, timestamp: "2024-06-06T13:56:54.000Z" },
        { speed: "9.1", course: "276.8", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.939539", lon: "12724.678808", latD: "N", lonD: "E", latitude: 0.7656589833333334, longitude: 127.41131346666667, timestamp: "2024-06-06T13:57:14.000Z" },
        { speed: "9.2", course: "275.8", altitude: "4.8", satellites: "11", hdop: "0.6", lat: "0045.944096", lon: "12724.628270", latD: "N", lonD: "E", latitude: 0.7657349333333333, longitude: 127.41047116666667, timestamp: "2024-06-06T13:57:34.000Z" },
        { speed: "9.2", course: "275.8", altitude: "4.8", satellites: "11", hdop: "0.6", lat: "0045.944096", lon: "12724.628270", latD: "N", lonD: "E", latitude: 0.7657349333333333, longitude: 127.41047116666667, timestamp: "2024-06-06T13:57:34.000Z" },
        { speed: "9.1", course: "276.8", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.939539", lon: "12724.678808", latD: "N", lonD: "E", latitude: 0.7656589833333334, longitude: 127.41131346666667, timestamp: "2024-06-06T13:57:14.000Z" },
        { speed: "9.2", course: "275.8", altitude: "4.8", satellites: "11", hdop: "0.6", lat: "0045.944096", lon: "12724.628270", latD: "N", lonD: "E", latitude: 0.7657349333333333, longitude: 127.41047116666667, timestamp: "2024-06-06T13:57:34.000Z" },
        { speed: "9.2", course: "275.8", altitude: "4.8", satellites: "11", hdop: "0.6", lat: "0045.944096", lon: "12724.628270", latD: "N", lonD: "E", latitude: 0.7657349333333333, longitude: 127.41047116666667, timestamp: "2024-06-06T13:57:34.000Z" },
        { speed: "10.0", course: "276.1", altitude: "4.2", satellites: "11", hdop: "0.5", lat: "0045.946384", lon: "12724.578110", latD: "N", lonD: "E", latitude: 0.7657730666666667, longitude: 127.40963516666666, timestamp: "2024-06-06T13:57:54.000Z" },
        { speed: "10.0", course: "276.1", altitude: "4.2", satellites: "11", hdop: "0.5", lat: "0045.946384", lon: "12724.578110", latD: "N", lonD: "E", latitude: 0.7657730666666667, longitude: 127.40963516666666, timestamp: "2024-06-06T13:57:54.000Z" },
        { speed: "10.0", course: "276.1", altitude: "4.2", satellites: "11", hdop: "0.5", lat: "0045.946384", lon: "12724.578110", latD: "N", lonD: "E", latitude: 0.7657730666666667, longitude: 127.40963516666666, timestamp: "2024-06-06T13:57:54.000Z" },
        { speed: "8.9", course: "269.9", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.947213", lon: "12724.526882", latD: "N", lonD: "E", latitude: 0.7657868833333333, longitude: 127.40878136666667, timestamp: "2024-06-06T13:58:14.000Z" },
        { speed: "8.9", course: "269.9", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.947213", lon: "12724.526882", latD: "N", lonD: "E", latitude: 0.7657868833333333, longitude: 127.40878136666667, timestamp: "2024-06-06T13:58:14.000Z" },
        { speed: "10.0", course: "276.1", altitude: "4.2", satellites: "11", hdop: "0.5", lat: "0045.946384", lon: "12724.578110", latD: "N", lonD: "E", latitude: 0.7657730666666667, longitude: 127.40963516666666, timestamp: "2024-06-06T13:57:54.000Z" },
        { speed: "8.9", course: "269.9", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.947213", lon: "12724.526882", latD: "N", lonD: "E", latitude: 0.7657868833333333, longitude: 127.40878136666667, timestamp: "2024-06-06T13:58:14.000Z" },
        { speed: "8.9", course: "269.9", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.947213", lon: "12724.526882", latD: "N", lonD: "E", latitude: 0.7657868833333333, longitude: 127.40878136666667, timestamp: "2024-06-06T13:58:14.000Z" },
        { speed: "9.3", course: "269.3", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.947740", lon: "12724.475862", latD: "N", lonD: "E", latitude: 0.7657956666666668, longitude: 127.40793103333333, timestamp: "2024-06-06T13:58:34.000Z" },
        { speed: "9.3", course: "269.3", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.947740", lon: "12724.475862", latD: "N", lonD: "E", latitude: 0.7657956666666668, longitude: 127.40793103333333, timestamp: "2024-06-06T13:58:34.000Z" },
        { speed: "9.3", course: "267.8", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.947554", lon: "12724.424614", latD: "N", lonD: "E", latitude: 0.7657925666666666, longitude: 127.4070769, timestamp: "2024-06-06T13:58:54.000Z" },
        { speed: "9.3", course: "269.3", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.947740", lon: "12724.475862", latD: "N", lonD: "E", latitude: 0.7657956666666668, longitude: 127.40793103333333, timestamp: "2024-06-06T13:58:34.000Z" },
        { speed: "9.3", course: "269.3", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.947740", lon: "12724.475862", latD: "N", lonD: "E", latitude: 0.7657956666666668, longitude: 127.40793103333333, timestamp: "2024-06-06T13:58:34.000Z" },
        { speed: "9.3", course: "267.8", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.947554", lon: "12724.424614", latD: "N", lonD: "E", latitude: 0.7657925666666666, longitude: 127.4070769, timestamp: "2024-06-06T13:58:54.000Z" },
        { speed: "9.3", course: "267.8", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.947554", lon: "12724.424614", latD: "N", lonD: "E", latitude: 0.7657925666666666, longitude: 127.4070769, timestamp: "2024-06-06T13:58:54.000Z" },
        { speed: "9.3", course: "267.8", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.947554", lon: "12724.424614", latD: "N", lonD: "E", latitude: 0.7657925666666666, longitude: 127.4070769, timestamp: "2024-06-06T13:58:54.000Z" },
        { speed: "9.4", course: "266.9", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.943480", lon: "12724.373051", latD: "N", lonD: "E", latitude: 0.7657246666666667, longitude: 127.40621751666667, timestamp: "2024-06-06T13:59:14.000Z" },
        { speed: "9.4", course: "266.9", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.943480", lon: "12724.373051", latD: "N", lonD: "E", latitude: 0.7657246666666667, longitude: 127.40621751666667, timestamp: "2024-06-06T13:59:14.000Z" },
        { speed: "9.4", course: "266.9", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.943480", lon: "12724.373051", latD: "N", lonD: "E", latitude: 0.7657246666666667, longitude: 127.40621751666667, timestamp: "2024-06-06T13:59:14.000Z" },
        { speed: "9.4", course: "266.9", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.943480", lon: "12724.373051", latD: "N", lonD: "E", latitude: 0.7657246666666667, longitude: 127.40621751666667, timestamp: "2024-06-06T13:59:14.000Z" },
        { speed: "9.4", course: "266.9", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.943480", lon: "12724.373051", latD: "N", lonD: "E", latitude: 0.7657246666666667, longitude: 127.40621751666667, timestamp: "2024-06-06T13:59:14.000Z" },
        { speed: "9.7", course: "266.0", altitude: "6.8", satellites: "10", hdop: "0.6", lat: "0045.941088", lon: "12724.322250", latD: "N", lonD: "E", latitude: 0.7656848, longitude: 127.40537083333334, timestamp: "2024-06-06T13:59:34.000Z" },
        { speed: "9.7", course: "266.0", altitude: "6.8", satellites: "10", hdop: "0.6", lat: "0045.941088", lon: "12724.322250", latD: "N", lonD: "E", latitude: 0.7656848, longitude: 127.40537083333334, timestamp: "2024-06-06T13:59:34.000Z" },
        { speed: "9.7", course: "266.0", altitude: "6.8", satellites: "10", hdop: "0.6", lat: "0045.941088", lon: "12724.322250", latD: "N", lonD: "E", latitude: 0.7656848, longitude: 127.40537083333334, timestamp: "2024-06-06T13:59:34.000Z" },
        { speed: "9.5", course: "266.8", altitude: "11.0", satellites: "09", hdop: "0.7", lat: "0045.939460", lon: "12724.269664", latD: "N", lonD: "E", latitude: 0.7656576666666666, longitude: 127.4044944, timestamp: "2024-06-06T13:59:54.000Z" },
        { speed: "9.5", course: "266.8", altitude: "11.0", satellites: "09", hdop: "0.7", lat: "0045.939460", lon: "12724.269664", latD: "N", lonD: "E", latitude: 0.7656576666666666, longitude: 127.4044944, timestamp: "2024-06-06T13:59:54.000Z" },
        { speed: "9.2", course: "265.6", altitude: "5.9", satellites: "11", hdop: "0.5", lat: "0045.936210", lon: "12724.216713", latD: "N", lonD: "E", latitude: 0.7656035, longitude: 127.40361188333333, timestamp: "2024-06-06T14:00:14.000Z" },
        { speed: "9.2", course: "265.6", altitude: "5.9", satellites: "11", hdop: "0.5", lat: "0045.936210", lon: "12724.216713", latD: "N", lonD: "E", latitude: 0.7656035, longitude: 127.40361188333333, timestamp: "2024-06-06T14:00:14.000Z" },
        { speed: "9.5", course: "266.8", altitude: "11.0", satellites: "09", hdop: "0.7", lat: "0045.939460", lon: "12724.269664", latD: "N", lonD: "E", latitude: 0.7656576666666666, longitude: 127.4044944, timestamp: "2024-06-06T13:59:54.000Z" },
        { speed: "9.5", course: "266.8", altitude: "11.0", satellites: "09", hdop: "0.7", lat: "0045.939460", lon: "12724.269664", latD: "N", lonD: "E", latitude: 0.7656576666666666, longitude: 127.4044944, timestamp: "2024-06-06T13:59:54.000Z" },
        { speed: "9.2", course: "265.6", altitude: "5.9", satellites: "11", hdop: "0.5", lat: "0045.936210", lon: "12724.216713", latD: "N", lonD: "E", latitude: 0.7656035, longitude: 127.40361188333333, timestamp: "2024-06-06T14:00:14.000Z" },
        { speed: "9.2", course: "265.6", altitude: "5.9", satellites: "11", hdop: "0.5", lat: "0045.936210", lon: "12724.216713", latD: "N", lonD: "E", latitude: 0.7656035, longitude: 127.40361188333333, timestamp: "2024-06-06T14:00:14.000Z" },
        { speed: "9.3", course: "262.1", altitude: "1.1", satellites: "11", hdop: "0.5", lat: "0045.930093", lon: "12724.163763", latD: "N", lonD: "E", latitude: 0.76550155, longitude: 127.40272938333334, timestamp: "2024-06-06T14:00:34.000Z" },
        { speed: "9.3", course: "262.1", altitude: "1.1", satellites: "11", hdop: "0.5", lat: "0045.930093", lon: "12724.163763", latD: "N", lonD: "E", latitude: 0.76550155, longitude: 127.40272938333334, timestamp: "2024-06-06T14:00:34.000Z" },
        { speed: "9.3", course: "265.5", altitude: "6.9", satellites: "10", hdop: "0.5", lat: "0045.925356", lon: "12724.111989", latD: "N", lonD: "E", latitude: 0.7654226000000001, longitude: 127.40186648333334, timestamp: "2024-06-06T14:00:54.000Z" },
        { speed: "9.3", course: "265.5", altitude: "6.9", satellites: "10", hdop: "0.5", lat: "0045.925356", lon: "12724.111989", latD: "N", lonD: "E", latitude: 0.7654226000000001, longitude: 127.40186648333334, timestamp: "2024-06-06T14:00:54.000Z" },
        { speed: "9.3", course: "262.1", altitude: "1.1", satellites: "11", hdop: "0.5", lat: "0045.930093", lon: "12724.163763", latD: "N", lonD: "E", latitude: 0.76550155, longitude: 127.40272938333334, timestamp: "2024-06-06T14:00:34.000Z" },
        { speed: "9.3", course: "262.1", altitude: "1.1", satellites: "11", hdop: "0.5", lat: "0045.930093", lon: "12724.163763", latD: "N", lonD: "E", latitude: 0.76550155, longitude: 127.40272938333334, timestamp: "2024-06-06T14:00:34.000Z" },
        { speed: "9.3", course: "265.5", altitude: "6.9", satellites: "10", hdop: "0.5", lat: "0045.925356", lon: "12724.111989", latD: "N", lonD: "E", latitude: 0.7654226000000001, longitude: 127.40186648333334, timestamp: "2024-06-06T14:00:54.000Z" },
        { speed: "9.3", course: "265.5", altitude: "6.9", satellites: "10", hdop: "0.5", lat: "0045.925356", lon: "12724.111989", latD: "N", lonD: "E", latitude: 0.7654226000000001, longitude: 127.40186648333334, timestamp: "2024-06-06T14:00:54.000Z" },
        { speed: "9.6", course: "266.8", altitude: "6.6", satellites: "10", hdop: "0.5", lat: "0045.921884", lon: "12724.059651", latD: "N", lonD: "E", latitude: 0.7653647333333333, longitude: 127.40099418333334, timestamp: "2024-06-06T14:01:14.000Z" },
        { speed: "9.6", course: "266.8", altitude: "6.6", satellites: "10", hdop: "0.5", lat: "0045.921884", lon: "12724.059651", latD: "N", lonD: "E", latitude: 0.7653647333333333, longitude: 127.40099418333334, timestamp: "2024-06-06T14:01:14.000Z" },
        { speed: "9.6", course: "266.8", altitude: "6.6", satellites: "10", hdop: "0.5", lat: "0045.921884", lon: "12724.059651", latD: "N", lonD: "E", latitude: 0.7653647333333333, longitude: 127.40099418333334, timestamp: "2024-06-06T14:01:14.000Z" },
        { speed: "9.6", course: "266.8", altitude: "6.6", satellites: "10", hdop: "0.5", lat: "0045.921884", lon: "12724.059651", latD: "N", lonD: "E", latitude: 0.7653647333333333, longitude: 127.40099418333334, timestamp: "2024-06-06T14:01:14.000Z" },
        { speed: "9.6", course: "266.8", altitude: "6.6", satellites: "10", hdop: "0.5", lat: "0045.921884", lon: "12724.059651", latD: "N", lonD: "E", latitude: 0.7653647333333333, longitude: 127.40099418333334, timestamp: "2024-06-06T14:01:14.000Z" },
        { speed: "9.7", course: "266.2", altitude: "7.0", satellites: "11", hdop: "0.5", lat: "0045.917904", lon: "12724.007055", latD: "N", lonD: "E", latitude: 0.7652984, longitude: 127.40011758333333, timestamp: "2024-06-06T14:01:34.000Z" },
        { speed: "9.3", course: "266.3", altitude: "6.5", satellites: "10", hdop: "0.5", lat: "0045.913907", lon: "12723.955064", latD: "N", lonD: "E", latitude: 0.7652317833333334, longitude: 127.39925106666666, timestamp: "2024-06-06T14:01:54.000Z" },
        { speed: "9.7", course: "266.2", altitude: "7.0", satellites: "11", hdop: "0.5", lat: "0045.917904", lon: "12724.007055", latD: "N", lonD: "E", latitude: 0.7652984, longitude: 127.40011758333333, timestamp: "2024-06-06T14:01:34.000Z" },
        { speed: "9.7", course: "266.2", altitude: "7.0", satellites: "11", hdop: "0.5", lat: "0045.917904", lon: "12724.007055", latD: "N", lonD: "E", latitude: 0.7652984, longitude: 127.40011758333333, timestamp: "2024-06-06T14:01:34.000Z" },
        { speed: "9.3", course: "266.3", altitude: "6.5", satellites: "10", hdop: "0.5", lat: "0045.913907", lon: "12723.955064", latD: "N", lonD: "E", latitude: 0.7652317833333334, longitude: 127.39925106666666, timestamp: "2024-06-06T14:01:54.000Z" },
        { speed: "9.3", course: "266.3", altitude: "6.5", satellites: "10", hdop: "0.5", lat: "0045.913907", lon: "12723.955064", latD: "N", lonD: "E", latitude: 0.7652317833333334, longitude: 127.39925106666666, timestamp: "2024-06-06T14:01:54.000Z" },
        { speed: "9.7", course: "266.8", altitude: "6.1", satellites: "11", hdop: "0.5", lat: "0045.909011", lon: "12723.901838", latD: "N", lonD: "E", latitude: 0.7651501833333333, longitude: 127.39836396666666, timestamp: "2024-06-06T14:02:14.000Z" },
        { speed: "9.7", course: "266.8", altitude: "6.1", satellites: "11", hdop: "0.5", lat: "0045.909011", lon: "12723.901838", latD: "N", lonD: "E", latitude: 0.7651501833333333, longitude: 127.39836396666666, timestamp: "2024-06-06T14:02:14.000Z" },
        { speed: "9.3", course: "266.3", altitude: "6.5", satellites: "10", hdop: "0.5", lat: "0045.913907", lon: "12723.955064", latD: "N", lonD: "E", latitude: 0.7652317833333334, longitude: 127.39925106666666, timestamp: "2024-06-06T14:01:54.000Z" },
        { speed: "9.7", course: "266.8", altitude: "6.1", satellites: "11", hdop: "0.5", lat: "0045.909011", lon: "12723.901838", latD: "N", lonD: "E", latitude: 0.7651501833333333, longitude: 127.39836396666666, timestamp: "2024-06-06T14:02:14.000Z" },
        { speed: "9.8", course: "263.9", altitude: "5.7", satellites: "11", hdop: "0.5", lat: "0045.902442", lon: "12723.848997", latD: "N", lonD: "E", latitude: 0.7650407, longitude: 127.39748328333333, timestamp: "2024-06-06T14:02:34.000Z" },
        { speed: "9.8", course: "263.9", altitude: "5.7", satellites: "11", hdop: "0.5", lat: "0045.902442", lon: "12723.848997", latD: "N", lonD: "E", latitude: 0.7650407, longitude: 127.39748328333333, timestamp: "2024-06-06T14:02:34.000Z" },
        { speed: "9.8", course: "263.9", altitude: "5.7", satellites: "11", hdop: "0.5", lat: "0045.902442", lon: "12723.848997", latD: "N", lonD: "E", latitude: 0.7650407, longitude: 127.39748328333333, timestamp: "2024-06-06T14:02:34.000Z" },
        { speed: "9.8", course: "263.9", altitude: "5.7", satellites: "11", hdop: "0.5", lat: "0045.902442", lon: "12723.848997", latD: "N", lonD: "E", latitude: 0.7650407, longitude: 127.39748328333333, timestamp: "2024-06-06T14:02:34.000Z" },
        { speed: "9.1", course: "262.0", altitude: "3.8", satellites: "11", hdop: "0.5", lat: "0045.896752", lon: "12723.796910", latD: "N", lonD: "E", latitude: 0.7649458666666666, longitude: 127.39661516666666, timestamp: "2024-06-06T14:02:54.000Z" },
        { speed: "9.1", course: "262.0", altitude: "3.8", satellites: "11", hdop: "0.5", lat: "0045.896752", lon: "12723.796910", latD: "N", lonD: "E", latitude: 0.7649458666666666, longitude: 127.39661516666666, timestamp: "2024-06-06T14:02:54.000Z" },
        { speed: "9.8", course: "263.9", altitude: "5.7", satellites: "11", hdop: "0.5", lat: "0045.902442", lon: "12723.848997", latD: "N", lonD: "E", latitude: 0.7650407, longitude: 127.39748328333333, timestamp: "2024-06-06T14:02:34.000Z" },
        { speed: "9.1", course: "262.0", altitude: "3.8", satellites: "11", hdop: "0.5", lat: "0045.896752", lon: "12723.796910", latD: "N", lonD: "E", latitude: 0.7649458666666666, longitude: 127.39661516666666, timestamp: "2024-06-06T14:02:54.000Z" },
        { speed: "9.1", course: "262.0", altitude: "3.8", satellites: "11", hdop: "0.5", lat: "0045.896752", lon: "12723.796910", latD: "N", lonD: "E", latitude: 0.7649458666666666, longitude: 127.39661516666666, timestamp: "2024-06-06T14:02:54.000Z" },
        { speed: "9.6", course: "263.6", altitude: "4.0", satellites: "11", hdop: "0.5", lat: "0045.889521", lon: "12723.745435", latD: "N", lonD: "E", latitude: 0.7648253500000001, longitude: 127.39575725, timestamp: "2024-06-06T14:03:14.000Z" },
        { speed: "9.6", course: "263.6", altitude: "4.0", satellites: "11", hdop: "0.5", lat: "0045.889521", lon: "12723.745435", latD: "N", lonD: "E", latitude: 0.7648253500000001, longitude: 127.39575725, timestamp: "2024-06-06T14:03:14.000Z" },
        { speed: "9.1", course: "260.8", altitude: "1.8", satellites: "11", hdop: "0.5", lat: "0045.882641", lon: "12723.694484", latD: "N", lonD: "E", latitude: 0.7647106833333334, longitude: 127.39490806666667, timestamp: "2024-06-06T14:03:34.000Z" },
        { speed: "9.1", course: "260.8", altitude: "1.8", satellites: "11", hdop: "0.5", lat: "0045.882641", lon: "12723.694484", latD: "N", lonD: "E", latitude: 0.7647106833333334, longitude: 127.39490806666667, timestamp: "2024-06-06T14:03:34.000Z" },
        { speed: "9.6", course: "263.6", altitude: "4.0", satellites: "11", hdop: "0.5", lat: "0045.889521", lon: "12723.745435", latD: "N", lonD: "E", latitude: 0.7648253500000001, longitude: 127.39575725, timestamp: "2024-06-06T14:03:14.000Z" },
        { speed: "9.6", course: "263.6", altitude: "4.0", satellites: "11", hdop: "0.5", lat: "0045.889521", lon: "12723.745435", latD: "N", lonD: "E", latitude: 0.7648253500000001, longitude: 127.39575725, timestamp: "2024-06-06T14:03:14.000Z" },
        { speed: "9.1", course: "260.8", altitude: "1.8", satellites: "11", hdop: "0.5", lat: "0045.882641", lon: "12723.694484", latD: "N", lonD: "E", latitude: 0.7647106833333334, longitude: 127.39490806666667, timestamp: "2024-06-06T14:03:34.000Z" },
        { speed: "9.5", course: "264.5", altitude: "1.6", satellites: "11", hdop: "0.5", lat: "0045.877544", lon: "12723.641724", latD: "N", lonD: "E", latitude: 0.7646257333333334, longitude: 127.39402873333333, timestamp: "2024-06-06T14:03:54.000Z" },
        { speed: "9.5", course: "264.5", altitude: "1.6", satellites: "11", hdop: "0.5", lat: "0045.877544", lon: "12723.641724", latD: "N", lonD: "E", latitude: 0.7646257333333334, longitude: 127.39402873333333, timestamp: "2024-06-06T14:03:54.000Z" },
        { speed: "9.1", course: "260.8", altitude: "1.8", satellites: "11", hdop: "0.5", lat: "0045.882641", lon: "12723.694484", latD: "N", lonD: "E", latitude: 0.7647106833333334, longitude: 127.39490806666667, timestamp: "2024-06-06T14:03:34.000Z" },
        { speed: "9.5", course: "264.5", altitude: "1.6", satellites: "11", hdop: "0.5", lat: "0045.877544", lon: "12723.641724", latD: "N", lonD: "E", latitude: 0.7646257333333334, longitude: 127.39402873333333, timestamp: "2024-06-06T14:03:54.000Z" },
        { speed: "9.5", course: "265.0", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.872442", lon: "12723.589511", latD: "N", lonD: "E", latitude: 0.7645407, longitude: 127.39315851666667, timestamp: "2024-06-06T14:04:14.000Z" },
        { speed: "9.5", course: "265.0", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.872442", lon: "12723.589511", latD: "N", lonD: "E", latitude: 0.7645407, longitude: 127.39315851666667, timestamp: "2024-06-06T14:04:14.000Z" },
        { speed: "9.5", course: "265.0", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.872442", lon: "12723.589511", latD: "N", lonD: "E", latitude: 0.7645407, longitude: 127.39315851666667, timestamp: "2024-06-06T14:04:14.000Z" },
        { speed: "9.5", course: "265.0", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.872442", lon: "12723.589511", latD: "N", lonD: "E", latitude: 0.7645407, longitude: 127.39315851666667, timestamp: "2024-06-06T14:04:14.000Z" },
        { speed: "9.3", course: "265.2", altitude: "9.1", satellites: "10", hdop: "0.6", lat: "0045.867592", lon: "12723.537655", latD: "N", lonD: "E", latitude: 0.7644598666666667, longitude: 127.39229425, timestamp: "2024-06-06T14:04:34.000Z" },
        { speed: "9.5", course: "265.0", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.872442", lon: "12723.589511", latD: "N", lonD: "E", latitude: 0.7645407, longitude: 127.39315851666667, timestamp: "2024-06-06T14:04:14.000Z" },
        { speed: "9.5", course: "265.0", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.872442", lon: "12723.589511", latD: "N", lonD: "E", latitude: 0.7645407, longitude: 127.39315851666667, timestamp: "2024-06-06T14:04:14.000Z" },
        { speed: "9.3", course: "265.2", altitude: "9.1", satellites: "10", hdop: "0.6", lat: "0045.867592", lon: "12723.537655", latD: "N", lonD: "E", latitude: 0.7644598666666667, longitude: 127.39229425, timestamp: "2024-06-06T14:04:34.000Z" },
        { speed: "9.5", course: "265.5", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.862404", lon: "12723.486685", latD: "N", lonD: "E", latitude: 0.7643734, longitude: 127.39144475, timestamp: "2024-06-06T14:04:54.000Z" },
        { speed: "9.5", course: "265.5", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.862404", lon: "12723.486685", latD: "N", lonD: "E", latitude: 0.7643734, longitude: 127.39144475, timestamp: "2024-06-06T14:04:54.000Z" },
        { speed: "9.5", course: "265.5", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.862404", lon: "12723.486685", latD: "N", lonD: "E", latitude: 0.7643734, longitude: 127.39144475, timestamp: "2024-06-06T14:04:54.000Z" },
        { speed: "9.5", course: "263.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.854864", lon: "12723.436246", latD: "N", lonD: "E", latitude: 0.7642477333333333, longitude: 127.3906041, timestamp: "2024-06-06T14:05:14.000Z" },
        { speed: "9.5", course: "265.5", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.862404", lon: "12723.486685", latD: "N", lonD: "E", latitude: 0.7643734, longitude: 127.39144475, timestamp: "2024-06-06T14:04:54.000Z" },
        { speed: "9.5", course: "265.5", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.862404", lon: "12723.486685", latD: "N", lonD: "E", latitude: 0.7643734, longitude: 127.39144475, timestamp: "2024-06-06T14:04:54.000Z" },
        { speed: "9.5", course: "263.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.854864", lon: "12723.436246", latD: "N", lonD: "E", latitude: 0.7642477333333333, longitude: 127.3906041, timestamp: "2024-06-06T14:05:14.000Z" },
        { speed: "9.1", course: "260.6", altitude: "11.7", satellites: "11", hdop: "0.5", lat: "0045.813715", lon: "12723.022263", latD: "N", lonD: "E", latitude: 0.7635619166666667, longitude: 127.38370438333334, timestamp: "2024-06-06T14:07:54.000Z" },
        { speed: "9.0", course: "262.3", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.819677", lon: "12723.070848", latD: "N", lonD: "E", latitude: 0.7636612833333333, longitude: 127.38451413333334, timestamp: "2024-06-06T14:07:35.000Z" },
        { speed: "9.0", course: "262.3", altitude: "11.2", satellites: "10", hdop: "0.6", lat: "0045.819677", lon: "12723.070848", latD: "N", lonD: "E", latitude: 0.7636612833333333, longitude: 127.38451413333334, timestamp: "2024-06-06T14:07:35.000Z" },
        { speed: "9.1", course: "260.6", altitude: "11.7", satellites: "11", hdop: "0.5", lat: "0045.813715", lon: "12723.022263", latD: "N", lonD: "E", latitude: 0.7635619166666667, longitude: 127.38370438333334, timestamp: "2024-06-06T14:07:54.000Z" },
        { speed: "8.6", course: "263.9", altitude: "11.9", satellites: "11", hdop: "0.6", lat: "0045.808804", lon: "12722.968574", latD: "N", lonD: "E", latitude: 0.7634800666666667, longitude: 127.38280956666667, timestamp: "2024-06-06T14:08:15.000Z" },
        { speed: "8.6", course: "263.9", altitude: "11.9", satellites: "11", hdop: "0.6", lat: "0045.808804", lon: "12722.968574", latD: "N", lonD: "E", latitude: 0.7634800666666667, longitude: 127.38280956666667, timestamp: "2024-06-06T14:08:15.000Z" },
        { speed: "8.6", course: "263.9", altitude: "11.9", satellites: "11", hdop: "0.6", lat: "0045.808804", lon: "12722.968574", latD: "N", lonD: "E", latitude: 0.7634800666666667, longitude: 127.38280956666667, timestamp: "2024-06-06T14:08:15.000Z" },
        { speed: "8.6", course: "263.9", altitude: "11.9", satellites: "11", hdop: "0.6", lat: "0045.808804", lon: "12722.968574", latD: "N", lonD: "E", latitude: 0.7634800666666667, longitude: 127.38280956666667, timestamp: "2024-06-06T14:08:15.000Z" },
        { speed: "8.6", course: "263.9", altitude: "11.9", satellites: "11", hdop: "0.6", lat: "0045.808804", lon: "12722.968574", latD: "N", lonD: "E", latitude: 0.7634800666666667, longitude: 127.38280956666667, timestamp: "2024-06-06T14:08:15.000Z" },
        { speed: "9.2", course: "266.5", altitude: "9.4", satellites: "11", hdop: "0.6", lat: "0045.805166", lon: "12722.918924", latD: "N", lonD: "E", latitude: 0.7634194333333333, longitude: 127.38198206666667, timestamp: "2024-06-06T14:08:35.000Z" },
        { speed: "9.2", course: "266.5", altitude: "9.4", satellites: "11", hdop: "0.6", lat: "0045.805166", lon: "12722.918924", latD: "N", lonD: "E", latitude: 0.7634194333333333, longitude: 127.38198206666667, timestamp: "2024-06-06T14:08:35.000Z" },
        { speed: "9.2", course: "266.5", altitude: "9.4", satellites: "11", hdop: "0.6", lat: "0045.805166", lon: "12722.918924", latD: "N", lonD: "E", latitude: 0.7634194333333333, longitude: 127.38198206666667, timestamp: "2024-06-06T14:08:35.000Z" },
        { speed: "9.2", course: "266.5", altitude: "9.4", satellites: "11", hdop: "0.6", lat: "0045.805166", lon: "12722.918924", latD: "N", lonD: "E", latitude: 0.7634194333333333, longitude: 127.38198206666667, timestamp: "2024-06-06T14:08:35.000Z" },
        { speed: "8.9", course: "266.8", altitude: "11.2", satellites: "11", hdop: "0.6", lat: "0045.801422", lon: "12722.872433", latD: "N", lonD: "E", latitude: 0.7633570333333334, longitude: 127.38120721666667, timestamp: "2024-06-06T14:08:55.000Z" },
        { speed: "7.5", course: "265.5", altitude: "11.7", satellites: "11", hdop: "0.5", lat: "0045.797458", lon: "12722.826385", latD: "N", lonD: "E", latitude: 0.7632909666666666, longitude: 127.38043975, timestamp: "2024-06-06T14:09:15.000Z" },
        { speed: "7.5", course: "265.5", altitude: "11.7", satellites: "11", hdop: "0.5", lat: "0045.797458", lon: "12722.826385", latD: "N", lonD: "E", latitude: 0.7632909666666666, longitude: 127.38043975, timestamp: "2024-06-06T14:09:15.000Z" },
        { speed: "7.5", course: "265.5", altitude: "11.7", satellites: "11", hdop: "0.5", lat: "0045.797458", lon: "12722.826385", latD: "N", lonD: "E", latitude: 0.7632909666666666, longitude: 127.38043975, timestamp: "2024-06-06T14:09:15.000Z" },
        { speed: "8.9", course: "266.8", altitude: "11.2", satellites: "11", hdop: "0.6", lat: "0045.801422", lon: "12722.872433", latD: "N", lonD: "E", latitude: 0.7633570333333334, longitude: 127.38120721666667, timestamp: "2024-06-06T14:08:55.000Z" },
        { speed: "7.5", course: "265.5", altitude: "11.7", satellites: "11", hdop: "0.5", lat: "0045.797458", lon: "12722.826385", latD: "N", lonD: "E", latitude: 0.7632909666666666, longitude: 127.38043975, timestamp: "2024-06-06T14:09:15.000Z" },
        { speed: "7.5", course: "265.5", altitude: "11.7", satellites: "11", hdop: "0.5", lat: "0045.797458", lon: "12722.826385", latD: "N", lonD: "E", latitude: 0.7632909666666666, longitude: 127.38043975, timestamp: "2024-06-06T14:09:15.000Z" },
        { speed: "6.6", course: "266.7", altitude: "11.9", satellites: "11", hdop: "0.6", lat: "0045.795306", lon: "12722.789540", latD: "N", lonD: "E", latitude: 0.7632551, longitude: 127.37982566666666, timestamp: "2024-06-06T14:09:35.000Z" },
        { speed: "6.6", course: "266.7", altitude: "11.9", satellites: "11", hdop: "0.6", lat: "0045.795306", lon: "12722.789540", latD: "N", lonD: "E", latitude: 0.7632551, longitude: 127.37982566666666, timestamp: "2024-06-06T14:09:35.000Z" },
        { speed: "6.6", course: "266.7", altitude: "11.9", satellites: "11", hdop: "0.6", lat: "0045.795306", lon: "12722.789540", latD: "N", lonD: "E", latitude: 0.7632551, longitude: 127.37982566666666, timestamp: "2024-06-06T14:09:35.000Z" },
        { speed: "6.6", course: "266.7", altitude: "11.9", satellites: "11", hdop: "0.6", lat: "0045.795306", lon: "12722.789540", latD: "N", lonD: "E", latitude: 0.7632551, longitude: 127.37982566666666, timestamp: "2024-06-06T14:09:35.000Z" },
        { speed: "5.9", course: "269.1", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.793147", lon: "12722.754664", latD: "N", lonD: "E", latitude: 0.7632191166666666, longitude: 127.3792444, timestamp: "2024-06-06T14:09:55.000Z" },
        { speed: "5.9", course: "269.1", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.793147", lon: "12722.754664", latD: "N", lonD: "E", latitude: 0.7632191166666666, longitude: 127.3792444, timestamp: "2024-06-06T14:09:55.000Z" },
        { speed: "5.9", course: "269.1", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.793147", lon: "12722.754664", latD: "N", lonD: "E", latitude: 0.7632191166666666, longitude: 127.3792444, timestamp: "2024-06-06T14:09:55.000Z" },
        { speed: "5.9", course: "269.1", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.793147", lon: "12722.754664", latD: "N", lonD: "E", latitude: 0.7632191166666666, longitude: 127.3792444, timestamp: "2024-06-06T14:09:55.000Z" },
        { speed: "5.9", course: "269.1", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.793147", lon: "12722.754664", latD: "N", lonD: "E", latitude: 0.7632191166666666, longitude: 127.3792444, timestamp: "2024-06-06T14:09:55.000Z" },
        { speed: "5.1", course: "274.0", altitude: "11.5", satellites: "11", hdop: "0.6", lat: "0045.794854", lon: "12722.721865", latD: "N", lonD: "E", latitude: 0.7632475666666667, longitude: 127.37869775, timestamp: "2024-06-06T14:10:15.000Z" },
        { speed: "5.1", course: "283.7", altitude: "6.2", satellites: "11", hdop: "0.6", lat: "0045.798749", lon: "12722.695341", latD: "N", lonD: "E", latitude: 0.7633124833333333, longitude: 127.37825568333334, timestamp: "2024-06-06T14:10:35.000Z" },
        { speed: "5.1", course: "283.7", altitude: "6.2", satellites: "11", hdop: "0.6", lat: "0045.798749", lon: "12722.695341", latD: "N", lonD: "E", latitude: 0.7633124833333333, longitude: 127.37825568333334, timestamp: "2024-06-06T14:10:35.000Z" },
        { speed: "5.1", course: "274.0", altitude: "11.5", satellites: "11", hdop: "0.6", lat: "0045.794854", lon: "12722.721865", latD: "N", lonD: "E", latitude: 0.7632475666666667, longitude: 127.37869775, timestamp: "2024-06-06T14:10:15.000Z" },
        { speed: "5.1", course: "274.0", altitude: "11.5", satellites: "11", hdop: "0.6", lat: "0045.794854", lon: "12722.721865", latD: "N", lonD: "E", latitude: 0.7632475666666667, longitude: 127.37869775, timestamp: "2024-06-06T14:10:15.000Z" },
        { speed: "5.1", course: "283.7", altitude: "6.2", satellites: "11", hdop: "0.6", lat: "0045.798749", lon: "12722.695341", latD: "N", lonD: "E", latitude: 0.7633124833333333, longitude: 127.37825568333334, timestamp: "2024-06-06T14:10:35.000Z" },
        { speed: "5.1", course: "283.7", altitude: "6.2", satellites: "11", hdop: "0.6", lat: "0045.798749", lon: "12722.695341", latD: "N", lonD: "E", latitude: 0.7633124833333333, longitude: 127.37825568333334, timestamp: "2024-06-06T14:10:35.000Z" },
        { speed: "5.1", course: "283.7", altitude: "6.2", satellites: "11", hdop: "0.6", lat: "0045.798749", lon: "12722.695341", latD: "N", lonD: "E", latitude: 0.7633124833333333, longitude: 127.37825568333334, timestamp: "2024-06-06T14:10:35.000Z" },
        { speed: "4.4", course: "292.3", altitude: "6.9", satellites: "11", hdop: "0.5", lat: "0045.806048", lon: "12722.671666", latD: "N", lonD: "E", latitude: 0.7634341333333333, longitude: 127.3778611, timestamp: "2024-06-06T14:10:55.000Z" },
        { speed: "4.4", course: "292.3", altitude: "6.9", satellites: "11", hdop: "0.5", lat: "0045.806048", lon: "12722.671666", latD: "N", lonD: "E", latitude: 0.7634341333333333, longitude: 127.3778611, timestamp: "2024-06-06T14:10:55.000Z" },
        { speed: "4.4", course: "292.3", altitude: "6.9", satellites: "11", hdop: "0.5", lat: "0045.806048", lon: "12722.671666", latD: "N", lonD: "E", latitude: 0.7634341333333333, longitude: 127.3778611, timestamp: "2024-06-06T14:10:55.000Z" },
        { speed: "4.4", course: "292.3", altitude: "6.9", satellites: "11", hdop: "0.5", lat: "0045.806048", lon: "12722.671666", latD: "N", lonD: "E", latitude: 0.7634341333333333, longitude: 127.3778611, timestamp: "2024-06-06T14:10:55.000Z" },
        { speed: "4.1", course: "296.8", altitude: "6.7", satellites: "11", hdop: "0.5", lat: "0045.817495", lon: "12722.649050", latD: "N", lonD: "E", latitude: 0.7636249166666667, longitude: 127.37748416666666, timestamp: "2024-06-06T14:11:15.000Z" },
        { speed: "4.1", course: "296.8", altitude: "6.7", satellites: "11", hdop: "0.5", lat: "0045.817495", lon: "12722.649050", latD: "N", lonD: "E", latitude: 0.7636249166666667, longitude: 127.37748416666666, timestamp: "2024-06-06T14:11:15.000Z" },
        { speed: "3.7", course: "304.7", altitude: "7.2", satellites: "11", hdop: "0.5", lat: "0045.830052", lon: "12722.630553", latD: "N", lonD: "E", latitude: 0.7638342, longitude: 127.37717588333334, timestamp: "2024-06-06T14:11:35.000Z" },
        { speed: "3.7", course: "304.7", altitude: "7.2", satellites: "11", hdop: "0.5", lat: "0045.830052", lon: "12722.630553", latD: "N", lonD: "E", latitude: 0.7638342, longitude: 127.37717588333334, timestamp: "2024-06-06T14:11:35.000Z" },
        { speed: "3.7", course: "304.7", altitude: "7.2", satellites: "11", hdop: "0.5", lat: "0045.830052", lon: "12722.630553", latD: "N", lonD: "E", latitude: 0.7638342, longitude: 127.37717588333334, timestamp: "2024-06-06T14:11:35.000Z" },
        { speed: "3.7", course: "304.7", altitude: "7.2", satellites: "11", hdop: "0.5", lat: "0045.830052", lon: "12722.630553", latD: "N", lonD: "E", latitude: 0.7638342, longitude: 127.37717588333334, timestamp: "2024-06-06T14:11:35.000Z" },
        { speed: "3.7", course: "304.7", altitude: "7.2", satellites: "11", hdop: "0.5", lat: "0045.830052", lon: "12722.630553", latD: "N", lonD: "E", latitude: 0.7638342, longitude: 127.37717588333334, timestamp: "2024-06-06T14:11:35.000Z" },
        { speed: "2.7", course: "311.7", altitude: "6.5", satellites: "11", hdop: "0.6", lat: "0045.842314", lon: "12722.615489", latD: "N", lonD: "E", latitude: 0.7640385666666667, longitude: 127.37692481666667, timestamp: "2024-06-06T14:11:55.000Z" },
        { speed: "2.7", course: "311.7", altitude: "6.5", satellites: "11", hdop: "0.6", lat: "0045.842314", lon: "12722.615489", latD: "N", lonD: "E", latitude: 0.7640385666666667, longitude: 127.37692481666667, timestamp: "2024-06-06T14:11:55.000Z" },
        { speed: "2.7", course: "311.7", altitude: "6.5", satellites: "11", hdop: "0.6", lat: "0045.842314", lon: "12722.615489", latD: "N", lonD: "E", latitude: 0.7640385666666667, longitude: 127.37692481666667, timestamp: "2024-06-06T14:11:55.000Z" },
        { speed: "1.7", course: "321.3", altitude: "5.4", satellites: "11", hdop: "0.6", lat: "0045.851672", lon: "12722.606711", latD: "N", lonD: "E", latitude: 0.7641945333333333, longitude: 127.37677851666666, timestamp: "2024-06-06T14:12:15.000Z" },
        { speed: "1.7", course: "321.3", altitude: "5.4", satellites: "11", hdop: "0.6", lat: "0045.851672", lon: "12722.606711", latD: "N", lonD: "E", latitude: 0.7641945333333333, longitude: 127.37677851666666, timestamp: "2024-06-06T14:12:15.000Z" },
        { speed: "2.7", course: "311.7", altitude: "6.5", satellites: "11", hdop: "0.6", lat: "0045.842314", lon: "12722.615489", latD: "N", lonD: "E", latitude: 0.7640385666666667, longitude: 127.37692481666667, timestamp: "2024-06-06T14:11:55.000Z" },
        { speed: "1.7", course: "321.3", altitude: "5.4", satellites: "11", hdop: "0.6", lat: "0045.851672", lon: "12722.606711", latD: "N", lonD: "E", latitude: 0.7641945333333333, longitude: 127.37677851666666, timestamp: "2024-06-06T14:12:15.000Z" },
        { speed: "1.7", course: "321.3", altitude: "5.4", satellites: "11", hdop: "0.6", lat: "0045.851672", lon: "12722.606711", latD: "N", lonD: "E", latitude: 0.7641945333333333, longitude: 127.37677851666666, timestamp: "2024-06-06T14:12:15.000Z" },
        { speed: "0.0", course: "315.4", altitude: "5.9", satellites: "11", hdop: "0.6", lat: "0045.855280", lon: "12722.604546", latD: "N", lonD: "E", latitude: 0.7642546666666666, longitude: 127.37674243333333, timestamp: "2024-06-06T14:12:35.000Z" },
        { speed: "0.0", course: "315.4", altitude: "5.9", satellites: "11", hdop: "0.6", lat: "0045.855280", lon: "12722.604546", latD: "N", lonD: "E", latitude: 0.7642546666666666, longitude: 127.37674243333333, timestamp: "2024-06-06T14:12:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.843401", lon: "12722.599011", latD: "N", lonD: "E", latitude: 0.7640566833333333, longitude: 127.37665018333334, timestamp: "2024-06-06T14:14:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.843401", lon: "12722.599011", latD: "N", lonD: "E", latitude: 0.7640566833333333, longitude: 127.37665018333334, timestamp: "2024-06-06T14:14:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.843401", lon: "12722.599011", latD: "N", lonD: "E", latitude: 0.7640566833333333, longitude: 127.37665018333334, timestamp: "2024-06-06T14:14:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.843401", lon: "12722.599011", latD: "N", lonD: "E", latitude: 0.7640566833333333, longitude: 127.37665018333334, timestamp: "2024-06-06T14:14:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.843401", lon: "12722.599011", latD: "N", lonD: "E", latitude: 0.7640566833333333, longitude: 127.37665018333334, timestamp: "2024-06-06T14:14:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.843401", lon: "12722.599011", latD: "N", lonD: "E", latitude: 0.7640566833333333, longitude: 127.37665018333334, timestamp: "2024-06-06T14:14:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.843340", lon: "12722.598954", latD: "N", lonD: "E", latitude: 0.7640556666666666, longitude: 127.37664923333334, timestamp: "2024-06-06T14:14:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.843340", lon: "12722.598954", latD: "N", lonD: "E", latitude: 0.7640556666666666, longitude: 127.37664923333334, timestamp: "2024-06-06T14:14:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.843340", lon: "12722.598954", latD: "N", lonD: "E", latitude: 0.7640556666666666, longitude: 127.37664923333334, timestamp: "2024-06-06T14:14:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.843340", lon: "12722.598954", latD: "N", lonD: "E", latitude: 0.7640556666666666, longitude: 127.37664923333334, timestamp: "2024-06-06T14:14:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "09", hdop: "0.6", lat: "0045.843321", lon: "12722.598900", latD: "N", lonD: "E", latitude: 0.76405535, longitude: 127.37664833333334, timestamp: "2024-06-06T14:15:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.843254", lon: "12722.598842", latD: "N", lonD: "E", latitude: 0.7640542333333333, longitude: 127.37664736666666, timestamp: "2024-06-06T14:15:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "09", hdop: "0.6", lat: "0045.843321", lon: "12722.598900", latD: "N", lonD: "E", latitude: 0.76405535, longitude: 127.37664833333334, timestamp: "2024-06-06T14:15:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "09", hdop: "0.6", lat: "0045.843321", lon: "12722.598900", latD: "N", lonD: "E", latitude: 0.76405535, longitude: 127.37664833333334, timestamp: "2024-06-06T14:15:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "09", hdop: "0.6", lat: "0045.843321", lon: "12722.598900", latD: "N", lonD: "E", latitude: 0.76405535, longitude: 127.37664833333334, timestamp: "2024-06-06T14:15:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.843254", lon: "12722.598842", latD: "N", lonD: "E", latitude: 0.7640542333333333, longitude: 127.37664736666666, timestamp: "2024-06-06T14:15:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.843254", lon: "12722.598842", latD: "N", lonD: "E", latitude: 0.7640542333333333, longitude: 127.37664736666666, timestamp: "2024-06-06T14:15:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.843184", lon: "12722.598766", latD: "N", lonD: "E", latitude: 0.7640530666666667, longitude: 127.3766461, timestamp: "2024-06-06T14:15:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.843254", lon: "12722.598842", latD: "N", lonD: "E", latitude: 0.7640542333333333, longitude: 127.37664736666666, timestamp: "2024-06-06T14:15:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.843184", lon: "12722.598766", latD: "N", lonD: "E", latitude: 0.7640530666666667, longitude: 127.3766461, timestamp: "2024-06-06T14:15:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.843184", lon: "12722.598766", latD: "N", lonD: "E", latitude: 0.7640530666666667, longitude: 127.3766461, timestamp: "2024-06-06T14:15:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "11", hdop: "0.6", lat: "0045.843110", lon: "12722.598701", latD: "N", lonD: "E", latitude: 0.7640518333333334, longitude: 127.37664501666667, timestamp: "2024-06-06T14:16:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "11", hdop: "0.6", lat: "0045.843110", lon: "12722.598701", latD: "N", lonD: "E", latitude: 0.7640518333333334, longitude: 127.37664501666667, timestamp: "2024-06-06T14:16:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "11", hdop: "0.6", lat: "0045.843110", lon: "12722.598701", latD: "N", lonD: "E", latitude: 0.7640518333333334, longitude: 127.37664501666667, timestamp: "2024-06-06T14:16:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "11", hdop: "0.6", lat: "0045.843049", lon: "12722.598637", latD: "N", lonD: "E", latitude: 0.7640508166666666, longitude: 127.37664395, timestamp: "2024-06-06T14:16:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "11", hdop: "0.6", lat: "0045.843110", lon: "12722.598701", latD: "N", lonD: "E", latitude: 0.7640518333333334, longitude: 127.37664501666667, timestamp: "2024-06-06T14:16:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "11", hdop: "0.6", lat: "0045.843110", lon: "12722.598701", latD: "N", lonD: "E", latitude: 0.7640518333333334, longitude: 127.37664501666667, timestamp: "2024-06-06T14:16:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "11", hdop: "0.6", lat: "0045.843049", lon: "12722.598637", latD: "N", lonD: "E", latitude: 0.7640508166666666, longitude: 127.37664395, timestamp: "2024-06-06T14:16:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "11", hdop: "0.6", lat: "0045.843049", lon: "12722.598637", latD: "N", lonD: "E", latitude: 0.7640508166666666, longitude: 127.37664395, timestamp: "2024-06-06T14:16:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "11", hdop: "0.6", lat: "0045.843049", lon: "12722.598637", latD: "N", lonD: "E", latitude: 0.7640508166666666, longitude: 127.37664395, timestamp: "2024-06-06T14:16:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842973", lon: "12722.598564", latD: "N", lonD: "E", latitude: 0.76404955, longitude: 127.37664273333333, timestamp: "2024-06-06T14:16:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842973", lon: "12722.598564", latD: "N", lonD: "E", latitude: 0.76404955, longitude: 127.37664273333333, timestamp: "2024-06-06T14:16:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842973", lon: "12722.598564", latD: "N", lonD: "E", latitude: 0.76404955, longitude: 127.37664273333333, timestamp: "2024-06-06T14:16:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842973", lon: "12722.598564", latD: "N", lonD: "E", latitude: 0.76404955, longitude: 127.37664273333333, timestamp: "2024-06-06T14:16:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842973", lon: "12722.598564", latD: "N", lonD: "E", latitude: 0.76404955, longitude: 127.37664273333333, timestamp: "2024-06-06T14:16:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842973", lon: "12722.598564", latD: "N", lonD: "E", latitude: 0.76404955, longitude: 127.37664273333333, timestamp: "2024-06-06T14:16:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842946", lon: "12722.598522", latD: "N", lonD: "E", latitude: 0.7640490999999999, longitude: 127.37664203333334, timestamp: "2024-06-06T14:17:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842929", lon: "12722.598514", latD: "N", lonD: "E", latitude: 0.7640488166666667, longitude: 127.3766419, timestamp: "2024-06-06T14:17:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842946", lon: "12722.598522", latD: "N", lonD: "E", latitude: 0.7640490999999999, longitude: 127.37664203333334, timestamp: "2024-06-06T14:17:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842946", lon: "12722.598522", latD: "N", lonD: "E", latitude: 0.7640490999999999, longitude: 127.37664203333334, timestamp: "2024-06-06T14:17:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842946", lon: "12722.598522", latD: "N", lonD: "E", latitude: 0.7640490999999999, longitude: 127.37664203333334, timestamp: "2024-06-06T14:17:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842929", lon: "12722.598514", latD: "N", lonD: "E", latitude: 0.7640488166666667, longitude: 127.3766419, timestamp: "2024-06-06T14:17:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842929", lon: "12722.598514", latD: "N", lonD: "E", latitude: 0.7640488166666667, longitude: 127.3766419, timestamp: "2024-06-06T14:17:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "11", hdop: "0.6", lat: "0045.842902", lon: "12722.598484", latD: "N", lonD: "E", latitude: 0.7640483666666668, longitude: 127.3766414, timestamp: "2024-06-06T14:17:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "11", hdop: "0.6", lat: "0045.842902", lon: "12722.598484", latD: "N", lonD: "E", latitude: 0.7640483666666668, longitude: 127.3766414, timestamp: "2024-06-06T14:17:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "11", hdop: "0.6", lat: "0045.842902", lon: "12722.598484", latD: "N", lonD: "E", latitude: 0.7640483666666668, longitude: 127.3766414, timestamp: "2024-06-06T14:17:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "11", hdop: "0.6", lat: "0045.842902", lon: "12722.598484", latD: "N", lonD: "E", latitude: 0.7640483666666668, longitude: 127.3766414, timestamp: "2024-06-06T14:17:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "11", hdop: "0.6", lat: "0045.842902", lon: "12722.598484", latD: "N", lonD: "E", latitude: 0.7640483666666668, longitude: 127.3766414, timestamp: "2024-06-06T14:17:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842887", lon: "12722.598451", latD: "N", lonD: "E", latitude: 0.7640481166666666, longitude: 127.37664085, timestamp: "2024-06-06T14:18:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842887", lon: "12722.598451", latD: "N", lonD: "E", latitude: 0.7640481166666666, longitude: 127.37664085, timestamp: "2024-06-06T14:18:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842887", lon: "12722.598451", latD: "N", lonD: "E", latitude: 0.7640481166666666, longitude: 127.37664085, timestamp: "2024-06-06T14:18:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842872", lon: "12722.598439", latD: "N", lonD: "E", latitude: 0.7640478666666667, longitude: 127.37664065, timestamp: "2024-06-06T14:18:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842872", lon: "12722.598439", latD: "N", lonD: "E", latitude: 0.7640478666666667, longitude: 127.37664065, timestamp: "2024-06-06T14:18:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842872", lon: "12722.598439", latD: "N", lonD: "E", latitude: 0.7640478666666667, longitude: 127.37664065, timestamp: "2024-06-06T14:18:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842861", lon: "12722.598423", latD: "N", lonD: "E", latitude: 0.7640476833333333, longitude: 127.37664038333334, timestamp: "2024-06-06T14:18:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842872", lon: "12722.598439", latD: "N", lonD: "E", latitude: 0.7640478666666667, longitude: 127.37664065, timestamp: "2024-06-06T14:18:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842861", lon: "12722.598423", latD: "N", lonD: "E", latitude: 0.7640476833333333, longitude: 127.37664038333334, timestamp: "2024-06-06T14:18:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842861", lon: "12722.598423", latD: "N", lonD: "E", latitude: 0.7640476833333333, longitude: 127.37664038333334, timestamp: "2024-06-06T14:18:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842861", lon: "12722.598423", latD: "N", lonD: "E", latitude: 0.7640476833333333, longitude: 127.37664038333334, timestamp: "2024-06-06T14:18:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842859", lon: "12722.598434", latD: "N", lonD: "E", latitude: 0.76404765, longitude: 127.37664056666667, timestamp: "2024-06-06T14:19:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842861", lon: "12722.598423", latD: "N", lonD: "E", latitude: 0.7640476833333333, longitude: 127.37664038333334, timestamp: "2024-06-06T14:18:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842859", lon: "12722.598434", latD: "N", lonD: "E", latitude: 0.76404765, longitude: 127.37664056666667, timestamp: "2024-06-06T14:19:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842859", lon: "12722.598434", latD: "N", lonD: "E", latitude: 0.76404765, longitude: 127.37664056666667, timestamp: "2024-06-06T14:19:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "09", hdop: "0.7", lat: "0045.842864", lon: "12722.598430", latD: "N", lonD: "E", latitude: 0.7640477333333333, longitude: 127.3766405, timestamp: "2024-06-06T14:19:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "09", hdop: "0.7", lat: "0045.842864", lon: "12722.598430", latD: "N", lonD: "E", latitude: 0.7640477333333333, longitude: 127.3766405, timestamp: "2024-06-06T14:19:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "09", hdop: "0.7", lat: "0045.842864", lon: "12722.598430", latD: "N", lonD: "E", latitude: 0.7640477333333333, longitude: 127.3766405, timestamp: "2024-06-06T14:19:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "09", hdop: "0.7", lat: "0045.842864", lon: "12722.598430", latD: "N", lonD: "E", latitude: 0.7640477333333333, longitude: 127.3766405, timestamp: "2024-06-06T14:19:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "09", hdop: "0.7", lat: "0045.842864", lon: "12722.598430", latD: "N", lonD: "E", latitude: 0.7640477333333333, longitude: 127.3766405, timestamp: "2024-06-06T14:19:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842865", lon: "12722.598409", latD: "N", lonD: "E", latitude: 0.76404775, longitude: 127.37664015, timestamp: "2024-06-06T14:19:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842865", lon: "12722.598409", latD: "N", lonD: "E", latitude: 0.76404775, longitude: 127.37664015, timestamp: "2024-06-06T14:19:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842865", lon: "12722.598409", latD: "N", lonD: "E", latitude: 0.76404775, longitude: 127.37664015, timestamp: "2024-06-06T14:19:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842865", lon: "12722.598409", latD: "N", lonD: "E", latitude: 0.76404775, longitude: 127.37664015, timestamp: "2024-06-06T14:19:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.842871", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7640478500000001, longitude: 127.37664046666667, timestamp: "2024-06-06T14:20:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.842883", lon: "12722.598423", latD: "N", lonD: "E", latitude: 0.76404805, longitude: 127.37664038333334, timestamp: "2024-06-06T14:20:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.842871", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7640478500000001, longitude: 127.37664046666667, timestamp: "2024-06-06T14:20:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.842871", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7640478500000001, longitude: 127.37664046666667, timestamp: "2024-06-06T14:20:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.842871", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7640478500000001, longitude: 127.37664046666667, timestamp: "2024-06-06T14:20:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.842883", lon: "12722.598423", latD: "N", lonD: "E", latitude: 0.76404805, longitude: 127.37664038333334, timestamp: "2024-06-06T14:20:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.842883", lon: "12722.598423", latD: "N", lonD: "E", latitude: 0.76404805, longitude: 127.37664038333334, timestamp: "2024-06-06T14:20:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "10", hdop: "0.6", lat: "0045.842883", lon: "12722.598423", latD: "N", lonD: "E", latitude: 0.76404805, longitude: 127.37664038333334, timestamp: "2024-06-06T14:20:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842884", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7640480666666666, longitude: 127.37664046666667, timestamp: "2024-06-06T14:20:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842884", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7640480666666666, longitude: 127.37664046666667, timestamp: "2024-06-06T14:20:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.9", satellites: "11", hdop: "0.6", lat: "0045.842884", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7640480666666666, longitude: 127.37664046666667, timestamp: "2024-06-06T14:20:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842781", lon: "12722.598301", latD: "N", lonD: "E", latitude: 0.76404635, longitude: 127.37663835, timestamp: "2024-06-06T14:21:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842781", lon: "12722.598301", latD: "N", lonD: "E", latitude: 0.76404635, longitude: 127.37663835, timestamp: "2024-06-06T14:21:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842781", lon: "12722.598301", latD: "N", lonD: "E", latitude: 0.76404635, longitude: 127.37663835, timestamp: "2024-06-06T14:21:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842781", lon: "12722.598301", latD: "N", lonD: "E", latitude: 0.76404635, longitude: 127.37663835, timestamp: "2024-06-06T14:21:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842772", lon: "12722.598292", latD: "N", lonD: "E", latitude: 0.7640461999999999, longitude: 127.3766382, timestamp: "2024-06-06T14:21:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842772", lon: "12722.598292", latD: "N", lonD: "E", latitude: 0.7640461999999999, longitude: 127.3766382, timestamp: "2024-06-06T14:21:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "11", hdop: "0.6", lat: "0045.842768", lon: "12722.598285", latD: "N", lonD: "E", latitude: 0.7640461333333334, longitude: 127.37663808333333, timestamp: "2024-06-06T14:21:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842772", lon: "12722.598292", latD: "N", lonD: "E", latitude: 0.7640461999999999, longitude: 127.3766382, timestamp: "2024-06-06T14:21:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842772", lon: "12722.598292", latD: "N", lonD: "E", latitude: 0.7640461999999999, longitude: 127.3766382, timestamp: "2024-06-06T14:21:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.842772", lon: "12722.598292", latD: "N", lonD: "E", latitude: 0.7640461999999999, longitude: 127.3766382, timestamp: "2024-06-06T14:21:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "11", hdop: "0.6", lat: "0045.842768", lon: "12722.598285", latD: "N", lonD: "E", latitude: 0.7640461333333334, longitude: 127.37663808333333, timestamp: "2024-06-06T14:21:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "11", hdop: "0.6", lat: "0045.842768", lon: "12722.598285", latD: "N", lonD: "E", latitude: 0.7640461333333334, longitude: 127.37663808333333, timestamp: "2024-06-06T14:21:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.8", satellites: "11", hdop: "0.6", lat: "0045.842768", lon: "12722.598285", latD: "N", lonD: "E", latitude: 0.7640461333333334, longitude: 127.37663808333333, timestamp: "2024-06-06T14:21:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.842758", lon: "12722.598290", latD: "N", lonD: "E", latitude: 0.7640459666666667, longitude: 127.37663816666667, timestamp: "2024-06-06T14:22:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.842758", lon: "12722.598290", latD: "N", lonD: "E", latitude: 0.7640459666666667, longitude: 127.37663816666667, timestamp: "2024-06-06T14:22:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.842758", lon: "12722.598290", latD: "N", lonD: "E", latitude: 0.7640459666666667, longitude: 127.37663816666667, timestamp: "2024-06-06T14:22:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.842758", lon: "12722.598290", latD: "N", lonD: "E", latitude: 0.7640459666666667, longitude: 127.37663816666667, timestamp: "2024-06-06T14:22:15.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.7", satellites: "11", hdop: "0.6", lat: "0045.842757", lon: "12722.598301", latD: "N", lonD: "E", latitude: 0.76404595, longitude: 127.37663835, timestamp: "2024-06-06T14:22:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.7", satellites: "11", hdop: "0.6", lat: "0045.842757", lon: "12722.598301", latD: "N", lonD: "E", latitude: 0.76404595, longitude: 127.37663835, timestamp: "2024-06-06T14:22:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.6", satellites: "11", hdop: "0.5", lat: "0045.842754", lon: "12722.598298", latD: "N", lonD: "E", latitude: 0.7640459, longitude: 127.3766383, timestamp: "2024-06-06T14:22:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.7", satellites: "11", hdop: "0.6", lat: "0045.842757", lon: "12722.598301", latD: "N", lonD: "E", latitude: 0.76404595, longitude: 127.37663835, timestamp: "2024-06-06T14:22:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.7", satellites: "11", hdop: "0.6", lat: "0045.842757", lon: "12722.598301", latD: "N", lonD: "E", latitude: 0.76404595, longitude: 127.37663835, timestamp: "2024-06-06T14:22:35.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.6", satellites: "11", hdop: "0.5", lat: "0045.842754", lon: "12722.598298", latD: "N", lonD: "E", latitude: 0.7640459, longitude: 127.3766383, timestamp: "2024-06-06T14:22:55.000Z" },
        { speed: "0.0", course: "180.2", altitude: "9.6", satellites: "11", hdop: "0.5", lat: "0045.842754", lon: "12722.598298", latD: "N", lonD: "E", latitude: 0.7640459, longitude: 127.3766383, timestamp: "2024-06-06T14:22:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "4.0", satellites: "11", hdop: "0.5", lat: "0045.841212", lon: "12722.595055", latD: "N", lonD: "E", latitude: 0.7640201999999999, longitude: 127.37658425, timestamp: "2024-06-06T14:23:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "4.0", satellites: "11", hdop: "0.5", lat: "0045.841212", lon: "12722.595055", latD: "N", lonD: "E", latitude: 0.7640201999999999, longitude: 127.37658425, timestamp: "2024-06-06T14:23:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "4.0", satellites: "11", hdop: "0.5", lat: "0045.841212", lon: "12722.595055", latD: "N", lonD: "E", latitude: 0.7640201999999999, longitude: 127.37658425, timestamp: "2024-06-06T14:23:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.841351", lon: "12722.595118", latD: "N", lonD: "E", latitude: 0.7640225166666668, longitude: 127.3765853, timestamp: "2024-06-06T14:23:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "4.0", satellites: "11", hdop: "0.5", lat: "0045.841212", lon: "12722.595055", latD: "N", lonD: "E", latitude: 0.7640201999999999, longitude: 127.37658425, timestamp: "2024-06-06T14:23:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "4.0", satellites: "11", hdop: "0.5", lat: "0045.841212", lon: "12722.595055", latD: "N", lonD: "E", latitude: 0.7640201999999999, longitude: 127.37658425, timestamp: "2024-06-06T14:23:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.841351", lon: "12722.595118", latD: "N", lonD: "E", latitude: 0.7640225166666668, longitude: 127.3765853, timestamp: "2024-06-06T14:23:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "3.0", satellites: "11", hdop: "0.6", lat: "0045.841351", lon: "12722.595118", latD: "N", lonD: "E", latitude: 0.7640225166666668, longitude: 127.3765853, timestamp: "2024-06-06T14:23:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "2.3", satellites: "11", hdop: "0.6", lat: "0045.841767", lon: "12722.595540", latD: "N", lonD: "E", latitude: 0.76402945, longitude: 127.37659233333333, timestamp: "2024-06-06T14:23:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "2.3", satellites: "11", hdop: "0.6", lat: "0045.841767", lon: "12722.595540", latD: "N", lonD: "E", latitude: 0.76402945, longitude: 127.37659233333333, timestamp: "2024-06-06T14:23:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "2.3", satellites: "11", hdop: "0.6", lat: "0045.841767", lon: "12722.595540", latD: "N", lonD: "E", latitude: 0.76402945, longitude: 127.37659233333333, timestamp: "2024-06-06T14:23:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "2.3", satellites: "11", hdop: "0.6", lat: "0045.841767", lon: "12722.595540", latD: "N", lonD: "E", latitude: 0.76402945, longitude: 127.37659233333333, timestamp: "2024-06-06T14:23:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.841463", lon: "12722.595675", latD: "N", lonD: "E", latitude: 0.7640243833333333, longitude: 127.37659458333333, timestamp: "2024-06-06T14:24:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.841463", lon: "12722.595675", latD: "N", lonD: "E", latitude: 0.7640243833333333, longitude: 127.37659458333333, timestamp: "2024-06-06T14:24:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.841463", lon: "12722.595675", latD: "N", lonD: "E", latitude: 0.7640243833333333, longitude: 127.37659458333333, timestamp: "2024-06-06T14:24:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.841463", lon: "12722.595675", latD: "N", lonD: "E", latitude: 0.7640243833333333, longitude: 127.37659458333333, timestamp: "2024-06-06T14:24:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.841409", lon: "12722.595543", latD: "N", lonD: "E", latitude: 0.7640234833333334, longitude: 127.37659238333333, timestamp: "2024-06-06T14:24:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.841409", lon: "12722.595543", latD: "N", lonD: "E", latitude: 0.7640234833333334, longitude: 127.37659238333333, timestamp: "2024-06-06T14:24:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.841403", lon: "12722.595544", latD: "N", lonD: "E", latitude: 0.7640233833333333, longitude: 127.3765924, timestamp: "2024-06-06T14:24:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.841409", lon: "12722.595543", latD: "N", lonD: "E", latitude: 0.7640234833333334, longitude: 127.37659238333333, timestamp: "2024-06-06T14:24:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.841409", lon: "12722.595543", latD: "N", lonD: "E", latitude: 0.7640234833333334, longitude: 127.37659238333333, timestamp: "2024-06-06T14:24:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.841409", lon: "12722.595543", latD: "N", lonD: "E", latitude: 0.7640234833333334, longitude: 127.37659238333333, timestamp: "2024-06-06T14:24:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.841403", lon: "12722.595544", latD: "N", lonD: "E", latitude: 0.7640233833333333, longitude: 127.3765924, timestamp: "2024-06-06T14:24:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.841403", lon: "12722.595544", latD: "N", lonD: "E", latitude: 0.7640233833333333, longitude: 127.3765924, timestamp: "2024-06-06T14:24:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.4", satellites: "09", hdop: "0.6", lat: "0045.841425", lon: "12722.595568", latD: "N", lonD: "E", latitude: 0.76402375, longitude: 127.3765928, timestamp: "2024-06-06T14:25:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.4", satellites: "09", hdop: "0.6", lat: "0045.841425", lon: "12722.595568", latD: "N", lonD: "E", latitude: 0.76402375, longitude: 127.3765928, timestamp: "2024-06-06T14:25:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.4", satellites: "09", hdop: "0.6", lat: "0045.841425", lon: "12722.595568", latD: "N", lonD: "E", latitude: 0.76402375, longitude: 127.3765928, timestamp: "2024-06-06T14:25:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.4", satellites: "09", hdop: "0.6", lat: "0045.841425", lon: "12722.595568", latD: "N", lonD: "E", latitude: 0.76402375, longitude: 127.3765928, timestamp: "2024-06-06T14:25:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.6", lat: "0045.841388", lon: "12722.595548", latD: "N", lonD: "E", latitude: 0.7640231333333334, longitude: 127.37659246666666, timestamp: "2024-06-06T14:25:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.6", lat: "0045.841388", lon: "12722.595548", latD: "N", lonD: "E", latitude: 0.7640231333333334, longitude: 127.37659246666666, timestamp: "2024-06-06T14:25:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.6", lat: "0045.841388", lon: "12722.595548", latD: "N", lonD: "E", latitude: 0.7640231333333334, longitude: 127.37659246666666, timestamp: "2024-06-06T14:25:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.6", lat: "0045.841388", lon: "12722.595548", latD: "N", lonD: "E", latitude: 0.7640231333333334, longitude: 127.37659246666666, timestamp: "2024-06-06T14:25:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.6", lat: "0045.841388", lon: "12722.595548", latD: "N", lonD: "E", latitude: 0.7640231333333334, longitude: 127.37659246666666, timestamp: "2024-06-06T14:25:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.6", lat: "0045.841362", lon: "12722.595547", latD: "N", lonD: "E", latitude: 0.7640226999999999, longitude: 127.37659245, timestamp: "2024-06-06T14:25:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.6", lat: "0045.841362", lon: "12722.595547", latD: "N", lonD: "E", latitude: 0.7640226999999999, longitude: 127.37659245, timestamp: "2024-06-06T14:25:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.6", lat: "0045.841362", lon: "12722.595547", latD: "N", lonD: "E", latitude: 0.7640226999999999, longitude: 127.37659245, timestamp: "2024-06-06T14:25:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.1", satellites: "09", hdop: "0.6", lat: "0045.841351", lon: "12722.595558", latD: "N", lonD: "E", latitude: 0.7640225166666668, longitude: 127.37659263333333, timestamp: "2024-06-06T14:26:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.1", satellites: "09", hdop: "0.6", lat: "0045.841351", lon: "12722.595558", latD: "N", lonD: "E", latitude: 0.7640225166666668, longitude: 127.37659263333333, timestamp: "2024-06-06T14:26:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.841322", lon: "12722.595555", latD: "N", lonD: "E", latitude: 0.7640220333333333, longitude: 127.37659258333333, timestamp: "2024-06-06T14:26:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.841322", lon: "12722.595555", latD: "N", lonD: "E", latitude: 0.7640220333333333, longitude: 127.37659258333333, timestamp: "2024-06-06T14:26:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.1", satellites: "09", hdop: "0.6", lat: "0045.841351", lon: "12722.595558", latD: "N", lonD: "E", latitude: 0.7640225166666668, longitude: 127.37659263333333, timestamp: "2024-06-06T14:26:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.1", satellites: "09", hdop: "0.6", lat: "0045.841351", lon: "12722.595558", latD: "N", lonD: "E", latitude: 0.7640225166666668, longitude: 127.37659263333333, timestamp: "2024-06-06T14:26:15.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.841322", lon: "12722.595555", latD: "N", lonD: "E", latitude: 0.7640220333333333, longitude: 127.37659258333333, timestamp: "2024-06-06T14:26:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.841322", lon: "12722.595555", latD: "N", lonD: "E", latitude: 0.7640220333333333, longitude: 127.37659258333333, timestamp: "2024-06-06T14:26:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.841322", lon: "12722.595555", latD: "N", lonD: "E", latitude: 0.7640220333333333, longitude: 127.37659258333333, timestamp: "2024-06-06T14:26:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.8", satellites: "09", hdop: "0.7", lat: "0045.841316", lon: "12722.595554", latD: "N", lonD: "E", latitude: 0.7640219333333333, longitude: 127.37659256666667, timestamp: "2024-06-06T14:26:55.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.7", lat: "0045.841315", lon: "12722.595577", latD: "N", lonD: "E", latitude: 0.7640219166666667, longitude: 127.37659295, timestamp: "2024-06-06T14:27:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.7", lat: "0045.841315", lon: "12722.595577", latD: "N", lonD: "E", latitude: 0.7640219166666667, longitude: 127.37659295, timestamp: "2024-06-06T14:27:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.7", lat: "0045.841315", lon: "12722.595577", latD: "N", lonD: "E", latitude: 0.7640219166666667, longitude: 127.37659295, timestamp: "2024-06-06T14:27:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.841332", lon: "12722.595626", latD: "N", lonD: "E", latitude: 0.7640222, longitude: 127.37659376666667, timestamp: "2024-06-06T14:27:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.841332", lon: "12722.595626", latD: "N", lonD: "E", latitude: 0.7640222, longitude: 127.37659376666667, timestamp: "2024-06-06T14:27:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.7", lat: "0045.841315", lon: "12722.595577", latD: "N", lonD: "E", latitude: 0.7640219166666667, longitude: 127.37659295, timestamp: "2024-06-06T14:27:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.841332", lon: "12722.595626", latD: "N", lonD: "E", latitude: 0.7640222, longitude: 127.37659376666667, timestamp: "2024-06-06T14:27:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.841332", lon: "12722.595626", latD: "N", lonD: "E", latitude: 0.7640222, longitude: 127.37659376666667, timestamp: "2024-06-06T14:27:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.841332", lon: "12722.595626", latD: "N", lonD: "E", latitude: 0.7640222, longitude: 127.37659376666667, timestamp: "2024-06-06T14:27:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.841343", lon: "12722.595664", latD: "N", lonD: "E", latitude: 0.7640223833333334, longitude: 127.3765944, timestamp: "2024-06-06T14:27:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.841365", lon: "12722.595716", latD: "N", lonD: "E", latitude: 0.7640227500000001, longitude: 127.37659526666667, timestamp: "2024-06-06T14:28:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.841343", lon: "12722.595664", latD: "N", lonD: "E", latitude: 0.7640223833333334, longitude: 127.3765944, timestamp: "2024-06-06T14:27:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.841343", lon: "12722.595664", latD: "N", lonD: "E", latitude: 0.7640223833333334, longitude: 127.3765944, timestamp: "2024-06-06T14:27:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.841343", lon: "12722.595664", latD: "N", lonD: "E", latitude: 0.7640223833333334, longitude: 127.3765944, timestamp: "2024-06-06T14:27:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.841365", lon: "12722.595716", latD: "N", lonD: "E", latitude: 0.7640227500000001, longitude: 127.37659526666667, timestamp: "2024-06-06T14:28:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.841365", lon: "12722.595716", latD: "N", lonD: "E", latitude: 0.7640227500000001, longitude: 127.37659526666667, timestamp: "2024-06-06T14:28:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "11", hdop: "0.6", lat: "0045.841369", lon: "12722.595740", latD: "N", lonD: "E", latitude: 0.7640228166666667, longitude: 127.37659566666666, timestamp: "2024-06-06T14:28:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.841365", lon: "12722.595716", latD: "N", lonD: "E", latitude: 0.7640227500000001, longitude: 127.37659526666667, timestamp: "2024-06-06T14:28:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "11", hdop: "0.6", lat: "0045.841369", lon: "12722.595740", latD: "N", lonD: "E", latitude: 0.7640228166666667, longitude: 127.37659566666666, timestamp: "2024-06-06T14:28:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "0.9", satellites: "11", hdop: "0.6", lat: "0045.841369", lon: "12722.595740", latD: "N", lonD: "E", latitude: 0.7640228166666667, longitude: 127.37659566666666, timestamp: "2024-06-06T14:28:35.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.1", satellites: "09", hdop: "0.6", lat: "0045.841392", lon: "12722.595800", latD: "N", lonD: "E", latitude: 0.7640232, longitude: 127.37659666666667, timestamp: "2024-06-06T14:28:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.1", satellites: "09", hdop: "0.6", lat: "0045.841392", lon: "12722.595800", latD: "N", lonD: "E", latitude: 0.7640232, longitude: 127.37659666666667, timestamp: "2024-06-06T14:28:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.1", satellites: "09", hdop: "0.6", lat: "0045.841392", lon: "12722.595800", latD: "N", lonD: "E", latitude: 0.7640232, longitude: 127.37659666666667, timestamp: "2024-06-06T14:28:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "11", hdop: "0.6", lat: "0045.841419", lon: "12722.595871", latD: "N", lonD: "E", latitude: 0.76402365, longitude: 127.37659785, timestamp: "2024-06-06T14:29:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.1", satellites: "09", hdop: "0.6", lat: "0045.841392", lon: "12722.595800", latD: "N", lonD: "E", latitude: 0.7640232, longitude: 127.37659666666667, timestamp: "2024-06-06T14:28:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.1", satellites: "09", hdop: "0.6", lat: "0045.841392", lon: "12722.595800", latD: "N", lonD: "E", latitude: 0.7640232, longitude: 127.37659666666667, timestamp: "2024-06-06T14:28:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "11", hdop: "0.6", lat: "0045.841419", lon: "12722.595871", latD: "N", lonD: "E", latitude: 0.76402365, longitude: 127.37659785, timestamp: "2024-06-06T14:29:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "11", hdop: "0.6", lat: "0045.841419", lon: "12722.595871", latD: "N", lonD: "E", latitude: 0.76402365, longitude: 127.37659785, timestamp: "2024-06-06T14:29:16.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.5", lat: "0045.841411", lon: "12722.595904", latD: "N", lonD: "E", latitude: 0.7640235166666667, longitude: 127.3765984, timestamp: "2024-06-06T14:29:36.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.5", lat: "0045.841411", lon: "12722.595904", latD: "N", lonD: "E", latitude: 0.7640235166666667, longitude: 127.3765984, timestamp: "2024-06-06T14:29:36.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.5", lat: "0045.841411", lon: "12722.595904", latD: "N", lonD: "E", latitude: 0.7640235166666667, longitude: 127.3765984, timestamp: "2024-06-06T14:29:36.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.841415", lon: "12722.595924", latD: "N", lonD: "E", latitude: 0.7640235833333333, longitude: 127.37659873333334, timestamp: "2024-06-06T14:29:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.841415", lon: "12722.595924", latD: "N", lonD: "E", latitude: 0.7640235833333333, longitude: 127.37659873333334, timestamp: "2024-06-06T14:29:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.2", satellites: "10", hdop: "0.5", lat: "0045.841411", lon: "12722.595904", latD: "N", lonD: "E", latitude: 0.7640235166666667, longitude: 127.3765984, timestamp: "2024-06-06T14:29:36.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.841415", lon: "12722.595924", latD: "N", lonD: "E", latitude: 0.7640235833333333, longitude: 127.37659873333334, timestamp: "2024-06-06T14:29:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.841415", lon: "12722.595924", latD: "N", lonD: "E", latitude: 0.7640235833333333, longitude: 127.37659873333334, timestamp: "2024-06-06T14:29:56.000Z" },
        { speed: "0.0", course: "167.0", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.841415", lon: "12722.595924", latD: "N", lonD: "E", latitude: 0.7640235833333333, longitude: 127.37659873333334, timestamp: "2024-06-06T14:29:56.000Z" },
        { speed: "0.0", course: "213.2", altitude: "0.7", satellites: "11", hdop: "0.5", lat: "0045.841358", lon: "12722.595915", latD: "N", lonD: "E", latitude: 0.7640226333333333, longitude: 127.37659858333333, timestamp: "2024-06-06T14:30:16.000Z" },
        { speed: "0.0", course: "213.2", altitude: "0.7", satellites: "11", hdop: "0.5", lat: "0045.841358", lon: "12722.595915", latD: "N", lonD: "E", latitude: 0.7640226333333333, longitude: 127.37659858333333, timestamp: "2024-06-06T14:30:16.000Z" },
        { speed: "0.0", course: "184.8", altitude: "-0.8", satellites: "11", hdop: "0.5", lat: "0045.840031", lon: "12722.595395", latD: "N", lonD: "E", latitude: 0.7640005166666667, longitude: 127.37658991666666, timestamp: "2024-06-06T14:30:36.000Z" },
        { speed: "0.0", course: "184.8", altitude: "-0.8", satellites: "11", hdop: "0.5", lat: "0045.840031", lon: "12722.595395", latD: "N", lonD: "E", latitude: 0.7640005166666667, longitude: 127.37658991666666, timestamp: "2024-06-06T14:30:36.000Z" },
        { speed: "0.0", course: "213.2", altitude: "0.7", satellites: "11", hdop: "0.5", lat: "0045.841358", lon: "12722.595915", latD: "N", lonD: "E", latitude: 0.7640226333333333, longitude: 127.37659858333333, timestamp: "2024-06-06T14:30:16.000Z" },
        { speed: "0.0", course: "184.8", altitude: "-0.8", satellites: "11", hdop: "0.5", lat: "0045.840031", lon: "12722.595395", latD: "N", lonD: "E", latitude: 0.7640005166666667, longitude: 127.37658991666666, timestamp: "2024-06-06T14:30:36.000Z" },
        { speed: "0.0", course: "184.8", altitude: "-0.8", satellites: "11", hdop: "0.5", lat: "0045.840031", lon: "12722.595395", latD: "N", lonD: "E", latitude: 0.7640005166666667, longitude: 127.37658991666666, timestamp: "2024-06-06T14:30:36.000Z" },
        { speed: "0.0", course: "96.1", altitude: "-0.4", satellites: "11", hdop: "0.5", lat: "0045.839564", lon: "12722.594515", latD: "N", lonD: "E", latitude: 0.7639927333333334, longitude: 127.37657525, timestamp: "2024-06-06T14:30:56.000Z" },
        { speed: "0.0", course: "96.1", altitude: "-0.4", satellites: "11", hdop: "0.5", lat: "0045.839564", lon: "12722.594515", latD: "N", lonD: "E", latitude: 0.7639927333333334, longitude: 127.37657525, timestamp: "2024-06-06T14:30:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "1.2", satellites: "11", hdop: "0.5", lat: "0045.840669", lon: "12722.593771", latD: "N", lonD: "E", latitude: 0.76401115, longitude: 127.37656285, timestamp: "2024-06-06T14:31:16.000Z" },
        { speed: "0.0", course: "96.1", altitude: "-0.4", satellites: "11", hdop: "0.5", lat: "0045.839564", lon: "12722.594515", latD: "N", lonD: "E", latitude: 0.7639927333333334, longitude: 127.37657525, timestamp: "2024-06-06T14:30:56.000Z" },
        { speed: "0.0", course: "96.1", altitude: "-0.4", satellites: "11", hdop: "0.5", lat: "0045.839564", lon: "12722.594515", latD: "N", lonD: "E", latitude: 0.7639927333333334, longitude: 127.37657525, timestamp: "2024-06-06T14:30:56.000Z" },
        { speed: "0.0", course: "96.1", altitude: "-0.4", satellites: "11", hdop: "0.5", lat: "0045.839564", lon: "12722.594515", latD: "N", lonD: "E", latitude: 0.7639927333333334, longitude: 127.37657525, timestamp: "2024-06-06T14:30:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "1.2", satellites: "11", hdop: "0.5", lat: "0045.840669", lon: "12722.593771", latD: "N", lonD: "E", latitude: 0.76401115, longitude: 127.37656285, timestamp: "2024-06-06T14:31:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "1.2", satellites: "11", hdop: "0.5", lat: "0045.840669", lon: "12722.593771", latD: "N", lonD: "E", latitude: 0.76401115, longitude: 127.37656285, timestamp: "2024-06-06T14:31:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.1", satellites: "11", hdop: "0.5", lat: "0045.840769", lon: "12722.593806", latD: "N", lonD: "E", latitude: 0.7640128166666666, longitude: 127.37656343333333, timestamp: "2024-06-06T14:31:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "1.2", satellites: "11", hdop: "0.5", lat: "0045.840669", lon: "12722.593771", latD: "N", lonD: "E", latitude: 0.76401115, longitude: 127.37656285, timestamp: "2024-06-06T14:31:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.1", satellites: "11", hdop: "0.5", lat: "0045.840769", lon: "12722.593806", latD: "N", lonD: "E", latitude: 0.7640128166666666, longitude: 127.37656343333333, timestamp: "2024-06-06T14:31:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.1", satellites: "11", hdop: "0.5", lat: "0045.840769", lon: "12722.593806", latD: "N", lonD: "E", latitude: 0.7640128166666666, longitude: 127.37656343333333, timestamp: "2024-06-06T14:31:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.1", satellites: "11", hdop: "0.5", lat: "0045.840769", lon: "12722.593806", latD: "N", lonD: "E", latitude: 0.7640128166666666, longitude: 127.37656343333333, timestamp: "2024-06-06T14:31:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.1", satellites: "11", hdop: "0.6", lat: "0045.840790", lon: "12722.593831", latD: "N", lonD: "E", latitude: 0.7640131666666666, longitude: 127.37656385, timestamp: "2024-06-06T14:31:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.1", satellites: "11", hdop: "0.6", lat: "0045.840790", lon: "12722.593831", latD: "N", lonD: "E", latitude: 0.7640131666666666, longitude: 127.37656385, timestamp: "2024-06-06T14:31:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.1", satellites: "11", hdop: "0.6", lat: "0045.840790", lon: "12722.593831", latD: "N", lonD: "E", latitude: 0.7640131666666666, longitude: 127.37656385, timestamp: "2024-06-06T14:31:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "11", hdop: "0.6", lat: "0045.840800", lon: "12722.593892", latD: "N", lonD: "E", latitude: 0.7640133333333333, longitude: 127.37656486666667, timestamp: "2024-06-06T14:32:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "11", hdop: "0.6", lat: "0045.840800", lon: "12722.593892", latD: "N", lonD: "E", latitude: 0.7640133333333333, longitude: 127.37656486666667, timestamp: "2024-06-06T14:32:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "11", hdop: "0.6", lat: "0045.840800", lon: "12722.593892", latD: "N", lonD: "E", latitude: 0.7640133333333333, longitude: 127.37656486666667, timestamp: "2024-06-06T14:32:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840825", lon: "12722.593921", latD: "N", lonD: "E", latitude: 0.76401375, longitude: 127.37656535, timestamp: "2024-06-06T14:32:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840825", lon: "12722.593921", latD: "N", lonD: "E", latitude: 0.76401375, longitude: 127.37656535, timestamp: "2024-06-06T14:32:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "11", hdop: "0.6", lat: "0045.840800", lon: "12722.593892", latD: "N", lonD: "E", latitude: 0.7640133333333333, longitude: 127.37656486666667, timestamp: "2024-06-06T14:32:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840825", lon: "12722.593921", latD: "N", lonD: "E", latitude: 0.76401375, longitude: 127.37656535, timestamp: "2024-06-06T14:32:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840825", lon: "12722.593921", latD: "N", lonD: "E", latitude: 0.76401375, longitude: 127.37656535, timestamp: "2024-06-06T14:32:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840825", lon: "12722.593921", latD: "N", lonD: "E", latitude: 0.76401375, longitude: 127.37656535, timestamp: "2024-06-06T14:32:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840832", lon: "12722.593953", latD: "N", lonD: "E", latitude: 0.7640138666666666, longitude: 127.37656588333333, timestamp: "2024-06-06T14:32:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840832", lon: "12722.593953", latD: "N", lonD: "E", latitude: 0.7640138666666666, longitude: 127.37656588333333, timestamp: "2024-06-06T14:32:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840836", lon: "12722.593972", latD: "N", lonD: "E", latitude: 0.7640139333333333, longitude: 127.3765662, timestamp: "2024-06-06T14:33:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840832", lon: "12722.593953", latD: "N", lonD: "E", latitude: 0.7640138666666666, longitude: 127.37656588333333, timestamp: "2024-06-06T14:32:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840832", lon: "12722.593953", latD: "N", lonD: "E", latitude: 0.7640138666666666, longitude: 127.37656588333333, timestamp: "2024-06-06T14:32:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840836", lon: "12722.593972", latD: "N", lonD: "E", latitude: 0.7640139333333333, longitude: 127.3765662, timestamp: "2024-06-06T14:33:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840836", lon: "12722.593972", latD: "N", lonD: "E", latitude: 0.7640139333333333, longitude: 127.3765662, timestamp: "2024-06-06T14:33:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840836", lon: "12722.593972", latD: "N", lonD: "E", latitude: 0.7640139333333333, longitude: 127.3765662, timestamp: "2024-06-06T14:33:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.840847", lon: "12722.594000", latD: "N", lonD: "E", latitude: 0.7640141166666666, longitude: 127.37656666666666, timestamp: "2024-06-06T14:33:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.840847", lon: "12722.594000", latD: "N", lonD: "E", latitude: 0.7640141166666666, longitude: 127.37656666666666, timestamp: "2024-06-06T14:33:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.840847", lon: "12722.594000", latD: "N", lonD: "E", latitude: 0.7640141166666666, longitude: 127.37656666666666, timestamp: "2024-06-06T14:33:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.840848", lon: "12722.594011", latD: "N", lonD: "E", latitude: 0.7640141333333333, longitude: 127.37656685, timestamp: "2024-06-06T14:33:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.840848", lon: "12722.594011", latD: "N", lonD: "E", latitude: 0.7640141333333333, longitude: 127.37656685, timestamp: "2024-06-06T14:33:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.840848", lon: "12722.594011", latD: "N", lonD: "E", latitude: 0.7640141333333333, longitude: 127.37656685, timestamp: "2024-06-06T14:33:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.840848", lon: "12722.594011", latD: "N", lonD: "E", latitude: 0.7640141333333333, longitude: 127.37656685, timestamp: "2024-06-06T14:33:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.4", satellites: "10", hdop: "0.6", lat: "0045.840848", lon: "12722.594011", latD: "N", lonD: "E", latitude: 0.7640141333333333, longitude: 127.37656685, timestamp: "2024-06-06T14:33:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840838", lon: "12722.594013", latD: "N", lonD: "E", latitude: 0.7640139666666667, longitude: 127.37656688333334, timestamp: "2024-06-06T14:34:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840821", lon: "12722.594010", latD: "N", lonD: "E", latitude: 0.7640136833333333, longitude: 127.37656683333333, timestamp: "2024-06-06T14:34:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840838", lon: "12722.594013", latD: "N", lonD: "E", latitude: 0.7640139666666667, longitude: 127.37656688333334, timestamp: "2024-06-06T14:34:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840838", lon: "12722.594013", latD: "N", lonD: "E", latitude: 0.7640139666666667, longitude: 127.37656688333334, timestamp: "2024-06-06T14:34:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840821", lon: "12722.594010", latD: "N", lonD: "E", latitude: 0.7640136833333333, longitude: 127.37656683333333, timestamp: "2024-06-06T14:34:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840821", lon: "12722.594010", latD: "N", lonD: "E", latitude: 0.7640136833333333, longitude: 127.37656683333333, timestamp: "2024-06-06T14:34:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840821", lon: "12722.594010", latD: "N", lonD: "E", latitude: 0.7640136833333333, longitude: 127.37656683333333, timestamp: "2024-06-06T14:34:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840800", lon: "12722.594004", latD: "N", lonD: "E", latitude: 0.7640133333333333, longitude: 127.37656673333333, timestamp: "2024-06-06T14:34:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840800", lon: "12722.594004", latD: "N", lonD: "E", latitude: 0.7640133333333333, longitude: 127.37656673333333, timestamp: "2024-06-06T14:34:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840800", lon: "12722.594004", latD: "N", lonD: "E", latitude: 0.7640133333333333, longitude: 127.37656673333333, timestamp: "2024-06-06T14:34:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840800", lon: "12722.594004", latD: "N", lonD: "E", latitude: 0.7640133333333333, longitude: 127.37656673333333, timestamp: "2024-06-06T14:34:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840800", lon: "12722.594004", latD: "N", lonD: "E", latitude: 0.7640133333333333, longitude: 127.37656673333333, timestamp: "2024-06-06T14:34:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.5", lat: "0045.840781", lon: "12722.593983", latD: "N", lonD: "E", latitude: 0.7640130166666667, longitude: 127.37656638333333, timestamp: "2024-06-06T14:35:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.5", lat: "0045.840781", lon: "12722.593983", latD: "N", lonD: "E", latitude: 0.7640130166666667, longitude: 127.37656638333333, timestamp: "2024-06-06T14:35:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.840760", lon: "12722.594007", latD: "N", lonD: "E", latitude: 0.7640126666666667, longitude: 127.37656678333333, timestamp: "2024-06-06T14:35:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.840760", lon: "12722.594007", latD: "N", lonD: "E", latitude: 0.7640126666666667, longitude: 127.37656678333333, timestamp: "2024-06-06T14:35:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "11", hdop: "0.5", lat: "0045.840781", lon: "12722.593983", latD: "N", lonD: "E", latitude: 0.7640130166666667, longitude: 127.37656638333333, timestamp: "2024-06-06T14:35:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.840760", lon: "12722.594007", latD: "N", lonD: "E", latitude: 0.7640126666666667, longitude: 127.37656678333333, timestamp: "2024-06-06T14:35:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.840760", lon: "12722.594007", latD: "N", lonD: "E", latitude: 0.7640126666666667, longitude: 127.37656678333333, timestamp: "2024-06-06T14:35:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "11", hdop: "0.5", lat: "0045.840739", lon: "12722.594036", latD: "N", lonD: "E", latitude: 0.7640123166666667, longitude: 127.37656726666667, timestamp: "2024-06-06T14:35:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "11", hdop: "0.5", lat: "0045.840739", lon: "12722.594036", latD: "N", lonD: "E", latitude: 0.7640123166666667, longitude: 127.37656726666667, timestamp: "2024-06-06T14:35:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "11", hdop: "0.5", lat: "0045.840739", lon: "12722.594036", latD: "N", lonD: "E", latitude: 0.7640123166666667, longitude: 127.37656726666667, timestamp: "2024-06-06T14:35:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.5", lat: "0045.840712", lon: "12722.594053", latD: "N", lonD: "E", latitude: 0.7640118666666668, longitude: 127.37656755, timestamp: "2024-06-06T14:36:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.5", lat: "0045.840712", lon: "12722.594053", latD: "N", lonD: "E", latitude: 0.7640118666666668, longitude: 127.37656755, timestamp: "2024-06-06T14:36:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "11", hdop: "0.5", lat: "0045.840739", lon: "12722.594036", latD: "N", lonD: "E", latitude: 0.7640123166666667, longitude: 127.37656726666667, timestamp: "2024-06-06T14:35:56.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.5", lat: "0045.840712", lon: "12722.594053", latD: "N", lonD: "E", latitude: 0.7640118666666668, longitude: 127.37656755, timestamp: "2024-06-06T14:36:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.3", satellites: "10", hdop: "0.5", lat: "0045.840712", lon: "12722.594053", latD: "N", lonD: "E", latitude: 0.7640118666666668, longitude: 127.37656755, timestamp: "2024-06-06T14:36:16.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.840685", lon: "12722.594086", latD: "N", lonD: "E", latitude: 0.7640114166666667, longitude: 127.3765681, timestamp: "2024-06-06T14:36:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.840685", lon: "12722.594086", latD: "N", lonD: "E", latitude: 0.7640114166666667, longitude: 127.3765681, timestamp: "2024-06-06T14:36:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.840685", lon: "12722.594086", latD: "N", lonD: "E", latitude: 0.7640114166666667, longitude: 127.3765681, timestamp: "2024-06-06T14:36:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.840685", lon: "12722.594086", latD: "N", lonD: "E", latitude: 0.7640114166666667, longitude: 127.3765681, timestamp: "2024-06-06T14:36:36.000Z" },
        { speed: "0.0", course: "324.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.840685", lon: "12722.594086", latD: "N", lonD: "E", latitude: 0.7640114166666667, longitude: 127.3765681, timestamp: "2024-06-06T14:36:36.000Z" },
        { speed: "0.0", course: "48.0", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839848", lon: "12722.593920", latD: "N", lonD: "E", latitude: 0.7639974666666667, longitude: 127.37656533333333, timestamp: "2024-06-06T14:36:56.000Z" },
        { speed: "0.0", course: "48.0", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839848", lon: "12722.593920", latD: "N", lonD: "E", latitude: 0.7639974666666667, longitude: 127.37656533333333, timestamp: "2024-06-06T14:36:56.000Z" },
        { speed: "0.0", course: "48.0", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839848", lon: "12722.593920", latD: "N", lonD: "E", latitude: 0.7639974666666667, longitude: 127.37656533333333, timestamp: "2024-06-06T14:36:56.000Z" },
        { speed: "0.0", course: "48.0", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839848", lon: "12722.593920", latD: "N", lonD: "E", latitude: 0.7639974666666667, longitude: 127.37656533333333, timestamp: "2024-06-06T14:36:56.000Z" },
        { speed: "0.0", course: "122.9", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.839808", lon: "12722.594623", latD: "N", lonD: "E", latitude: 0.7639967999999999, longitude: 127.37657705, timestamp: "2024-06-06T14:37:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.6", lat: "0045.839479", lon: "12722.594884", latD: "N", lonD: "E", latitude: 0.7639913166666666, longitude: 127.3765814, timestamp: "2024-06-06T14:37:36.000Z" },
        { speed: "0.0", course: "122.9", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.839808", lon: "12722.594623", latD: "N", lonD: "E", latitude: 0.7639967999999999, longitude: 127.37657705, timestamp: "2024-06-06T14:37:16.000Z" },
        { speed: "0.0", course: "122.9", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.839808", lon: "12722.594623", latD: "N", lonD: "E", latitude: 0.7639967999999999, longitude: 127.37657705, timestamp: "2024-06-06T14:37:16.000Z" },
        { speed: "0.0", course: "122.9", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.839808", lon: "12722.594623", latD: "N", lonD: "E", latitude: 0.7639967999999999, longitude: 127.37657705, timestamp: "2024-06-06T14:37:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.6", lat: "0045.839479", lon: "12722.594884", latD: "N", lonD: "E", latitude: 0.7639913166666666, longitude: 127.3765814, timestamp: "2024-06-06T14:37:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.6", lat: "0045.839479", lon: "12722.594884", latD: "N", lonD: "E", latitude: 0.7639913166666666, longitude: 127.3765814, timestamp: "2024-06-06T14:37:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.6", lat: "0045.839479", lon: "12722.594884", latD: "N", lonD: "E", latitude: 0.7639913166666666, longitude: 127.3765814, timestamp: "2024-06-06T14:37:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839537", lon: "12722.594815", latD: "N", lonD: "E", latitude: 0.7639922833333334, longitude: 127.37658025, timestamp: "2024-06-06T14:37:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839537", lon: "12722.594815", latD: "N", lonD: "E", latitude: 0.7639922833333334, longitude: 127.37658025, timestamp: "2024-06-06T14:37:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839537", lon: "12722.594815", latD: "N", lonD: "E", latitude: 0.7639922833333334, longitude: 127.37658025, timestamp: "2024-06-06T14:37:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839557", lon: "12722.594796", latD: "N", lonD: "E", latitude: 0.7639926166666666, longitude: 127.37657993333333, timestamp: "2024-06-06T14:38:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839557", lon: "12722.594796", latD: "N", lonD: "E", latitude: 0.7639926166666666, longitude: 127.37657993333333, timestamp: "2024-06-06T14:38:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839557", lon: "12722.594796", latD: "N", lonD: "E", latitude: 0.7639926166666666, longitude: 127.37657993333333, timestamp: "2024-06-06T14:38:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839578", lon: "12722.594796", latD: "N", lonD: "E", latitude: 0.7639929666666667, longitude: 127.37657993333333, timestamp: "2024-06-06T14:38:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839557", lon: "12722.594796", latD: "N", lonD: "E", latitude: 0.7639926166666666, longitude: 127.37657993333333, timestamp: "2024-06-06T14:38:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839557", lon: "12722.594796", latD: "N", lonD: "E", latitude: 0.7639926166666666, longitude: 127.37657993333333, timestamp: "2024-06-06T14:38:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839578", lon: "12722.594796", latD: "N", lonD: "E", latitude: 0.7639929666666667, longitude: 127.37657993333333, timestamp: "2024-06-06T14:38:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839578", lon: "12722.594796", latD: "N", lonD: "E", latitude: 0.7639929666666667, longitude: 127.37657993333333, timestamp: "2024-06-06T14:38:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839574", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639929, longitude: 127.37657975, timestamp: "2024-06-06T14:38:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839574", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639929, longitude: 127.37657975, timestamp: "2024-06-06T14:38:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839574", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639929, longitude: 127.37657975, timestamp: "2024-06-06T14:38:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839586", lon: "12722.594776", latD: "N", lonD: "E", latitude: 0.7639931, longitude: 127.3765796, timestamp: "2024-06-06T14:39:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839586", lon: "12722.594776", latD: "N", lonD: "E", latitude: 0.7639931, longitude: 127.3765796, timestamp: "2024-06-06T14:39:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839574", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639929, longitude: 127.37657975, timestamp: "2024-06-06T14:38:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839586", lon: "12722.594776", latD: "N", lonD: "E", latitude: 0.7639931, longitude: 127.3765796, timestamp: "2024-06-06T14:39:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839586", lon: "12722.594776", latD: "N", lonD: "E", latitude: 0.7639931, longitude: 127.3765796, timestamp: "2024-06-06T14:39:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839581", lon: "12722.594778", latD: "N", lonD: "E", latitude: 0.7639930166666667, longitude: 127.37657963333334, timestamp: "2024-06-06T14:39:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839581", lon: "12722.594778", latD: "N", lonD: "E", latitude: 0.7639930166666667, longitude: 127.37657963333334, timestamp: "2024-06-06T14:39:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839581", lon: "12722.594778", latD: "N", lonD: "E", latitude: 0.7639930166666667, longitude: 127.37657963333334, timestamp: "2024-06-06T14:39:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "10", hdop: "0.6", lat: "0045.839581", lon: "12722.594778", latD: "N", lonD: "E", latitude: 0.7639930166666667, longitude: 127.37657963333334, timestamp: "2024-06-06T14:39:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839560", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639926666666667, longitude: 127.37657975, timestamp: "2024-06-06T14:39:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839560", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639926666666667, longitude: 127.37657975, timestamp: "2024-06-06T14:39:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839560", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639926666666667, longitude: 127.37657975, timestamp: "2024-06-06T14:39:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839560", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639926666666667, longitude: 127.37657975, timestamp: "2024-06-06T14:39:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839538", lon: "12722.594797", latD: "N", lonD: "E", latitude: 0.7639923, longitude: 127.37657995, timestamp: "2024-06-06T14:40:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839538", lon: "12722.594797", latD: "N", lonD: "E", latitude: 0.7639923, longitude: 127.37657995, timestamp: "2024-06-06T14:40:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.839535", lon: "12722.594801", latD: "N", lonD: "E", latitude: 0.7639922499999999, longitude: 127.37658001666667, timestamp: "2024-06-06T14:40:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.839535", lon: "12722.594801", latD: "N", lonD: "E", latitude: 0.7639922499999999, longitude: 127.37658001666667, timestamp: "2024-06-06T14:40:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839538", lon: "12722.594797", latD: "N", lonD: "E", latitude: 0.7639923, longitude: 127.37657995, timestamp: "2024-06-06T14:40:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839538", lon: "12722.594797", latD: "N", lonD: "E", latitude: 0.7639923, longitude: 127.37657995, timestamp: "2024-06-06T14:40:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839549", lon: "12722.594811", latD: "N", lonD: "E", latitude: 0.7639924833333332, longitude: 127.37658018333333, timestamp: "2024-06-06T14:40:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839549", lon: "12722.594811", latD: "N", lonD: "E", latitude: 0.7639924833333332, longitude: 127.37658018333333, timestamp: "2024-06-06T14:40:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839549", lon: "12722.594811", latD: "N", lonD: "E", latitude: 0.7639924833333332, longitude: 127.37658018333333, timestamp: "2024-06-06T14:40:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.839535", lon: "12722.594801", latD: "N", lonD: "E", latitude: 0.7639922499999999, longitude: 127.37658001666667, timestamp: "2024-06-06T14:40:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839549", lon: "12722.594811", latD: "N", lonD: "E", latitude: 0.7639924833333332, longitude: 127.37658018333333, timestamp: "2024-06-06T14:40:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.839549", lon: "12722.594811", latD: "N", lonD: "E", latitude: 0.7639924833333332, longitude: 127.37658018333333, timestamp: "2024-06-06T14:40:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "09", hdop: "0.6", lat: "0045.839573", lon: "12722.594812", latD: "N", lonD: "E", latitude: 0.7639928833333334, longitude: 127.3765802, timestamp: "2024-06-06T14:41:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "09", hdop: "0.6", lat: "0045.839573", lon: "12722.594812", latD: "N", lonD: "E", latitude: 0.7639928833333334, longitude: 127.3765802, timestamp: "2024-06-06T14:41:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "09", hdop: "0.6", lat: "0045.839573", lon: "12722.594812", latD: "N", lonD: "E", latitude: 0.7639928833333334, longitude: 127.3765802, timestamp: "2024-06-06T14:41:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "11", hdop: "0.6", lat: "0045.839601", lon: "12722.594805", latD: "N", lonD: "E", latitude: 0.76399335, longitude: 127.37658008333334, timestamp: "2024-06-06T14:41:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "09", hdop: "0.6", lat: "0045.839573", lon: "12722.594812", latD: "N", lonD: "E", latitude: 0.7639928833333334, longitude: 127.3765802, timestamp: "2024-06-06T14:41:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "09", hdop: "0.6", lat: "0045.839573", lon: "12722.594812", latD: "N", lonD: "E", latitude: 0.7639928833333334, longitude: 127.3765802, timestamp: "2024-06-06T14:41:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "11", hdop: "0.6", lat: "0045.839601", lon: "12722.594805", latD: "N", lonD: "E", latitude: 0.76399335, longitude: 127.37658008333334, timestamp: "2024-06-06T14:41:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.6", satellites: "11", hdop: "0.6", lat: "0045.839601", lon: "12722.594805", latD: "N", lonD: "E", latitude: 0.76399335, longitude: 127.37658008333334, timestamp: "2024-06-06T14:41:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839610", lon: "12722.594826", latD: "N", lonD: "E", latitude: 0.7639935, longitude: 127.37658043333333, timestamp: "2024-06-06T14:41:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839610", lon: "12722.594826", latD: "N", lonD: "E", latitude: 0.7639935, longitude: 127.37658043333333, timestamp: "2024-06-06T14:41:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.8", satellites: "11", hdop: "0.6", lat: "0045.839633", lon: "12722.594852", latD: "N", lonD: "E", latitude: 0.7639938833333333, longitude: 127.37658086666667, timestamp: "2024-06-06T14:42:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839610", lon: "12722.594826", latD: "N", lonD: "E", latitude: 0.7639935, longitude: 127.37658043333333, timestamp: "2024-06-06T14:41:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.7", satellites: "11", hdop: "0.5", lat: "0045.839610", lon: "12722.594826", latD: "N", lonD: "E", latitude: 0.7639935, longitude: 127.37658043333333, timestamp: "2024-06-06T14:41:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.8", satellites: "11", hdop: "0.6", lat: "0045.839633", lon: "12722.594852", latD: "N", lonD: "E", latitude: 0.7639938833333333, longitude: 127.37658086666667, timestamp: "2024-06-06T14:42:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.8", satellites: "11", hdop: "0.6", lat: "0045.839633", lon: "12722.594852", latD: "N", lonD: "E", latitude: 0.7639938833333333, longitude: 127.37658086666667, timestamp: "2024-06-06T14:42:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.8", satellites: "11", hdop: "0.6", lat: "0045.839633", lon: "12722.594852", latD: "N", lonD: "E", latitude: 0.7639938833333333, longitude: 127.37658086666667, timestamp: "2024-06-06T14:42:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.8", satellites: "11", hdop: "0.6", lat: "0045.839633", lon: "12722.594852", latD: "N", lonD: "E", latitude: 0.7639938833333333, longitude: 127.37658086666667, timestamp: "2024-06-06T14:42:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.9", satellites: "11", hdop: "0.6", lat: "0045.839689", lon: "12722.594864", latD: "N", lonD: "E", latitude: 0.7639948166666667, longitude: 127.37658106666666, timestamp: "2024-06-06T14:42:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.0", satellites: "11", hdop: "0.6", lat: "0045.839722", lon: "12722.594898", latD: "N", lonD: "E", latitude: 0.7639953666666667, longitude: 127.37658163333333, timestamp: "2024-06-06T14:42:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.0", satellites: "11", hdop: "0.6", lat: "0045.839722", lon: "12722.594898", latD: "N", lonD: "E", latitude: 0.7639953666666667, longitude: 127.37658163333333, timestamp: "2024-06-06T14:42:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.9", satellites: "11", hdop: "0.6", lat: "0045.839689", lon: "12722.594864", latD: "N", lonD: "E", latitude: 0.7639948166666667, longitude: 127.37658106666666, timestamp: "2024-06-06T14:42:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "1.9", satellites: "11", hdop: "0.6", lat: "0045.839689", lon: "12722.594864", latD: "N", lonD: "E", latitude: 0.7639948166666667, longitude: 127.37658106666666, timestamp: "2024-06-06T14:42:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.0", satellites: "11", hdop: "0.6", lat: "0045.839722", lon: "12722.594898", latD: "N", lonD: "E", latitude: 0.7639953666666667, longitude: 127.37658163333333, timestamp: "2024-06-06T14:42:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.0", satellites: "11", hdop: "0.6", lat: "0045.839722", lon: "12722.594898", latD: "N", lonD: "E", latitude: 0.7639953666666667, longitude: 127.37658163333333, timestamp: "2024-06-06T14:42:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.0", satellites: "11", hdop: "0.6", lat: "0045.839722", lon: "12722.594898", latD: "N", lonD: "E", latitude: 0.7639953666666667, longitude: 127.37658163333333, timestamp: "2024-06-06T14:42:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.0", satellites: "11", hdop: "0.5", lat: "0045.839758", lon: "12722.594903", latD: "N", lonD: "E", latitude: 0.7639959666666667, longitude: 127.37658171666666, timestamp: "2024-06-06T14:43:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.0", satellites: "11", hdop: "0.5", lat: "0045.839758", lon: "12722.594903", latD: "N", lonD: "E", latitude: 0.7639959666666667, longitude: 127.37658171666666, timestamp: "2024-06-06T14:43:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.0", satellites: "11", hdop: "0.5", lat: "0045.839758", lon: "12722.594903", latD: "N", lonD: "E", latitude: 0.7639959666666667, longitude: 127.37658171666666, timestamp: "2024-06-06T14:43:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.0", satellites: "11", hdop: "0.5", lat: "0045.839758", lon: "12722.594903", latD: "N", lonD: "E", latitude: 0.7639959666666667, longitude: 127.37658171666666, timestamp: "2024-06-06T14:43:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.1", satellites: "11", hdop: "0.5", lat: "0045.839795", lon: "12722.594925", latD: "N", lonD: "E", latitude: 0.7639965833333334, longitude: 127.37658208333333, timestamp: "2024-06-06T14:43:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.1", satellites: "11", hdop: "0.5", lat: "0045.839795", lon: "12722.594925", latD: "N", lonD: "E", latitude: 0.7639965833333334, longitude: 127.37658208333333, timestamp: "2024-06-06T14:43:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.1", satellites: "11", hdop: "0.5", lat: "0045.839795", lon: "12722.594925", latD: "N", lonD: "E", latitude: 0.7639965833333334, longitude: 127.37658208333333, timestamp: "2024-06-06T14:43:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.1", satellites: "11", hdop: "0.6", lat: "0045.839843", lon: "12722.594924", latD: "N", lonD: "E", latitude: 0.7639973833333333, longitude: 127.37658206666667, timestamp: "2024-06-06T14:43:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.1", satellites: "11", hdop: "0.5", lat: "0045.839795", lon: "12722.594925", latD: "N", lonD: "E", latitude: 0.7639965833333334, longitude: 127.37658208333333, timestamp: "2024-06-06T14:43:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.1", satellites: "11", hdop: "0.6", lat: "0045.839843", lon: "12722.594924", latD: "N", lonD: "E", latitude: 0.7639973833333333, longitude: 127.37658206666667, timestamp: "2024-06-06T14:43:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.1", satellites: "11", hdop: "0.6", lat: "0045.839843", lon: "12722.594924", latD: "N", lonD: "E", latitude: 0.7639973833333333, longitude: 127.37658206666667, timestamp: "2024-06-06T14:43:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.1", satellites: "11", hdop: "0.6", lat: "0045.839843", lon: "12722.594924", latD: "N", lonD: "E", latitude: 0.7639973833333333, longitude: 127.37658206666667, timestamp: "2024-06-06T14:43:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.2", satellites: "11", hdop: "0.5", lat: "0045.839856", lon: "12722.594915", latD: "N", lonD: "E", latitude: 0.7639975999999999, longitude: 127.37658191666667, timestamp: "2024-06-06T14:44:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.2", satellites: "11", hdop: "0.5", lat: "0045.839856", lon: "12722.594915", latD: "N", lonD: "E", latitude: 0.7639975999999999, longitude: 127.37658191666667, timestamp: "2024-06-06T14:44:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839866", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37658201666666, timestamp: "2024-06-06T14:44:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839866", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37658201666666, timestamp: "2024-06-06T14:44:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.2", satellites: "11", hdop: "0.5", lat: "0045.839856", lon: "12722.594915", latD: "N", lonD: "E", latitude: 0.7639975999999999, longitude: 127.37658191666667, timestamp: "2024-06-06T14:44:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839866", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37658201666666, timestamp: "2024-06-06T14:44:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839866", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37658201666666, timestamp: "2024-06-06T14:44:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839866", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37658201666666, timestamp: "2024-06-06T14:44:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839878", lon: "12722.594937", latD: "N", lonD: "E", latitude: 0.7639979666666666, longitude: 127.37658228333333, timestamp: "2024-06-06T14:44:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839896", lon: "12722.594966", latD: "N", lonD: "E", latitude: 0.7639982666666667, longitude: 127.37658276666667, timestamp: "2024-06-06T14:45:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839896", lon: "12722.594966", latD: "N", lonD: "E", latitude: 0.7639982666666667, longitude: 127.37658276666667, timestamp: "2024-06-06T14:45:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839878", lon: "12722.594937", latD: "N", lonD: "E", latitude: 0.7639979666666666, longitude: 127.37658228333333, timestamp: "2024-06-06T14:44:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839878", lon: "12722.594937", latD: "N", lonD: "E", latitude: 0.7639979666666666, longitude: 127.37658228333333, timestamp: "2024-06-06T14:44:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839896", lon: "12722.594966", latD: "N", lonD: "E", latitude: 0.7639982666666667, longitude: 127.37658276666667, timestamp: "2024-06-06T14:45:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839896", lon: "12722.594966", latD: "N", lonD: "E", latitude: 0.7639982666666667, longitude: 127.37658276666667, timestamp: "2024-06-06T14:45:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.839914", lon: "12722.594995", latD: "N", lonD: "E", latitude: 0.7639985666666667, longitude: 127.37658325, timestamp: "2024-06-06T14:45:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.839896", lon: "12722.594966", latD: "N", lonD: "E", latitude: 0.7639982666666667, longitude: 127.37658276666667, timestamp: "2024-06-06T14:45:16.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.839914", lon: "12722.594995", latD: "N", lonD: "E", latitude: 0.7639985666666667, longitude: 127.37658325, timestamp: "2024-06-06T14:45:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.839914", lon: "12722.594995", latD: "N", lonD: "E", latitude: 0.7639985666666667, longitude: 127.37658325, timestamp: "2024-06-06T14:45:36.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.839909", lon: "12722.594998", latD: "N", lonD: "E", latitude: 0.7639984833333333, longitude: 127.3765833, timestamp: "2024-06-06T14:45:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.839909", lon: "12722.594998", latD: "N", lonD: "E", latitude: 0.7639984833333333, longitude: 127.3765833, timestamp: "2024-06-06T14:45:56.000Z" },
        { speed: "0.0", course: "126.9", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.839909", lon: "12722.594998", latD: "N", lonD: "E", latitude: 0.7639984833333333, longitude: 127.3765833, timestamp: "2024-06-06T14:45:56.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.4", satellites: "09", hdop: "0.7", lat: "0045.840138", lon: "12722.593570", latD: "N", lonD: "E", latitude: 0.7640023, longitude: 127.3765595, timestamp: "2024-06-06T14:46:16.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.4", satellites: "09", hdop: "0.7", lat: "0045.840138", lon: "12722.593570", latD: "N", lonD: "E", latitude: 0.7640023, longitude: 127.3765595, timestamp: "2024-06-06T14:46:16.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.4", satellites: "09", hdop: "0.7", lat: "0045.840138", lon: "12722.593570", latD: "N", lonD: "E", latitude: 0.7640023, longitude: 127.3765595, timestamp: "2024-06-06T14:46:16.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.4", satellites: "09", hdop: "0.7", lat: "0045.840138", lon: "12722.593570", latD: "N", lonD: "E", latitude: 0.7640023, longitude: 127.3765595, timestamp: "2024-06-06T14:46:16.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.4", satellites: "09", hdop: "0.7", lat: "0045.840138", lon: "12722.593570", latD: "N", lonD: "E", latitude: 0.7640023, longitude: 127.3765595, timestamp: "2024-06-06T14:46:16.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.4", satellites: "09", hdop: "0.7", lat: "0045.840138", lon: "12722.593570", latD: "N", lonD: "E", latitude: 0.7640023, longitude: 127.3765595, timestamp: "2024-06-06T14:46:16.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.840113", lon: "12722.593672", latD: "N", lonD: "E", latitude: 0.7640018833333334, longitude: 127.3765612, timestamp: "2024-06-06T14:46:37.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.3", satellites: "11", hdop: "0.5", lat: "0045.840066", lon: "12722.593784", latD: "N", lonD: "E", latitude: 0.7640011, longitude: 127.37656306666666, timestamp: "2024-06-06T14:46:56.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.840113", lon: "12722.593672", latD: "N", lonD: "E", latitude: 0.7640018833333334, longitude: 127.3765612, timestamp: "2024-06-06T14:46:37.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.3", satellites: "11", hdop: "0.5", lat: "0045.840066", lon: "12722.593784", latD: "N", lonD: "E", latitude: 0.7640011, longitude: 127.37656306666666, timestamp: "2024-06-06T14:46:56.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.4", satellites: "11", hdop: "0.6", lat: "0045.840113", lon: "12722.593672", latD: "N", lonD: "E", latitude: 0.7640018833333334, longitude: 127.3765612, timestamp: "2024-06-06T14:46:37.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.3", satellites: "11", hdop: "0.5", lat: "0045.840066", lon: "12722.593784", latD: "N", lonD: "E", latitude: 0.7640011, longitude: 127.37656306666666, timestamp: "2024-06-06T14:46:56.000Z" },
        { speed: "0.0", course: "130.1", altitude: "2.3", satellites: "11", hdop: "0.5", lat: "0045.840066", lon: "12722.593784", latD: "N", lonD: "E", latitude: 0.7640011, longitude: 127.37656306666666, timestamp: "2024-06-06T14:46:56.000Z" },
        { speed: "0.0", course: "92.8", altitude: "-1.4", satellites: "11", hdop: "0.6", lat: "0045.840892", lon: "12722.592852", latD: "N", lonD: "E", latitude: 0.7640148666666666, longitude: 127.37654753333334, timestamp: "2024-06-06T14:47:16.000Z" },
        { speed: "0.0", course: "92.8", altitude: "-1.4", satellites: "11", hdop: "0.6", lat: "0045.840892", lon: "12722.592852", latD: "N", lonD: "E", latitude: 0.7640148666666666, longitude: 127.37654753333334, timestamp: "2024-06-06T14:47:16.000Z" },
        { speed: "0.0", course: "92.8", altitude: "-1.4", satellites: "11", hdop: "0.6", lat: "0045.840892", lon: "12722.592852", latD: "N", lonD: "E", latitude: 0.7640148666666666, longitude: 127.37654753333334, timestamp: "2024-06-06T14:47:16.000Z" },
        { speed: "0.0", course: "92.8", altitude: "-1.4", satellites: "11", hdop: "0.6", lat: "0045.840892", lon: "12722.592852", latD: "N", lonD: "E", latitude: 0.7640148666666666, longitude: 127.37654753333334, timestamp: "2024-06-06T14:47:16.000Z" },
        { speed: "0.0", course: "92.8", altitude: "-1.4", satellites: "11", hdop: "0.6", lat: "0045.840892", lon: "12722.592852", latD: "N", lonD: "E", latitude: 0.7640148666666666, longitude: 127.37654753333334, timestamp: "2024-06-06T14:47:16.000Z" },
        { speed: "0.0", course: "92.8", altitude: "-3.4", satellites: "11", hdop: "0.6", lat: "0045.841274", lon: "12722.592429", latD: "N", lonD: "E", latitude: 0.7640212333333333, longitude: 127.37654048333333, timestamp: "2024-06-06T14:47:37.000Z" },
        { speed: "0.0", course: "92.8", altitude: "-3.4", satellites: "11", hdop: "0.6", lat: "0045.841274", lon: "12722.592429", latD: "N", lonD: "E", latitude: 0.7640212333333333, longitude: 127.37654048333333, timestamp: "2024-06-06T14:47:37.000Z" },
        { speed: "0.0", course: "92.8", altitude: "-3.4", satellites: "11", hdop: "0.6", lat: "0045.841274", lon: "12722.592429", latD: "N", lonD: "E", latitude: 0.7640212333333333, longitude: 127.37654048333333, timestamp: "2024-06-06T14:47:37.000Z" },
        { speed: "0.0", course: "92.8", altitude: "-3.4", satellites: "11", hdop: "0.6", lat: "0045.841274", lon: "12722.592429", latD: "N", lonD: "E", latitude: 0.7640212333333333, longitude: 127.37654048333333, timestamp: "2024-06-06T14:47:37.000Z" },
        { speed: "0.0", course: "69.5", altitude: "-4.1", satellites: "10", hdop: "0.6", lat: "0045.839476", lon: "12722.590815", latD: "N", lonD: "E", latitude: 0.7639912666666666, longitude: 127.37651358333333, timestamp: "2024-06-06T14:47:57.000Z" },
        { speed: "0.0", course: "69.5", altitude: "-4.1", satellites: "10", hdop: "0.6", lat: "0045.839476", lon: "12722.590815", latD: "N", lonD: "E", latitude: 0.7639912666666666, longitude: 127.37651358333333, timestamp: "2024-06-06T14:47:57.000Z" },
        { speed: "0.0", course: "69.5", altitude: "-4.1", satellites: "10", hdop: "0.6", lat: "0045.839476", lon: "12722.590815", latD: "N", lonD: "E", latitude: 0.7639912666666666, longitude: 127.37651358333333, timestamp: "2024-06-06T14:47:57.000Z" },
        { speed: "0.0", course: "69.5", altitude: "-4.3", satellites: "11", hdop: "0.5", lat: "0045.839334", lon: "12722.590612", latD: "N", lonD: "E", latitude: 0.7639889, longitude: 127.3765102, timestamp: "2024-06-06T14:48:16.000Z" },
        { speed: "0.0", course: "69.5", altitude: "-4.3", satellites: "11", hdop: "0.5", lat: "0045.839334", lon: "12722.590612", latD: "N", lonD: "E", latitude: 0.7639889, longitude: 127.3765102, timestamp: "2024-06-06T14:48:16.000Z" },
        { speed: "0.0", course: "69.5", altitude: "-4.3", satellites: "11", hdop: "0.5", lat: "0045.839334", lon: "12722.590612", latD: "N", lonD: "E", latitude: 0.7639889, longitude: 127.3765102, timestamp: "2024-06-06T14:48:16.000Z" },
        { speed: "0.0", course: "69.5", altitude: "-4.3", satellites: "11", hdop: "0.5", lat: "0045.839334", lon: "12722.590612", latD: "N", lonD: "E", latitude: 0.7639889, longitude: 127.3765102, timestamp: "2024-06-06T14:48:16.000Z" },
        { speed: "0.0", course: "246.4", altitude: "2.1", satellites: "11", hdop: "0.5", lat: "0045.840148", lon: "12722.589988", latD: "N", lonD: "E", latitude: 0.7640024666666666, longitude: 127.3764998, timestamp: "2024-06-06T14:48:37.000Z" },
        { speed: "0.0", course: "246.4", altitude: "2.1", satellites: "11", hdop: "0.5", lat: "0045.840148", lon: "12722.589988", latD: "N", lonD: "E", latitude: 0.7640024666666666, longitude: 127.3764998, timestamp: "2024-06-06T14:48:37.000Z" },
        { speed: "0.0", course: "246.4", altitude: "2.1", satellites: "11", hdop: "0.5", lat: "0045.840148", lon: "12722.589988", latD: "N", lonD: "E", latitude: 0.7640024666666666, longitude: 127.3764998, timestamp: "2024-06-06T14:48:37.000Z" },
        { speed: "0.0", course: "246.4", altitude: "2.1", satellites: "11", hdop: "0.5", lat: "0045.840148", lon: "12722.589988", latD: "N", lonD: "E", latitude: 0.7640024666666666, longitude: 127.3764998, timestamp: "2024-06-06T14:48:37.000Z" },
        { speed: "0.0", course: "246.4", altitude: "2.1", satellites: "11", hdop: "0.5", lat: "0045.840148", lon: "12722.589988", latD: "N", lonD: "E", latitude: 0.7640024666666666, longitude: 127.3764998, timestamp: "2024-06-06T14:48:37.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.2", satellites: "11", hdop: "0.6", lat: "0045.840145", lon: "12722.589604", latD: "N", lonD: "E", latitude: 0.7640024166666667, longitude: 127.3764934, timestamp: "2024-06-06T14:48:57.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.2", satellites: "11", hdop: "0.6", lat: "0045.840145", lon: "12722.589604", latD: "N", lonD: "E", latitude: 0.7640024166666667, longitude: 127.3764934, timestamp: "2024-06-06T14:48:57.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.2", satellites: "11", hdop: "0.6", lat: "0045.840145", lon: "12722.589604", latD: "N", lonD: "E", latitude: 0.7640024166666667, longitude: 127.3764934, timestamp: "2024-06-06T14:48:57.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.2", satellites: "11", hdop: "0.6", lat: "0045.840145", lon: "12722.589604", latD: "N", lonD: "E", latitude: 0.7640024166666667, longitude: 127.3764934, timestamp: "2024-06-06T14:48:57.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.4", satellites: "11", hdop: "0.6", lat: "0045.840183", lon: "12722.589674", latD: "N", lonD: "E", latitude: 0.76400305, longitude: 127.37649456666666, timestamp: "2024-06-06T14:49:16.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.4", satellites: "11", hdop: "0.6", lat: "0045.840183", lon: "12722.589674", latD: "N", lonD: "E", latitude: 0.76400305, longitude: 127.37649456666666, timestamp: "2024-06-06T14:49:16.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.4", satellites: "11", hdop: "0.6", lat: "0045.840183", lon: "12722.589674", latD: "N", lonD: "E", latitude: 0.76400305, longitude: 127.37649456666666, timestamp: "2024-06-06T14:49:16.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.4", satellites: "11", hdop: "0.6", lat: "0045.840183", lon: "12722.589674", latD: "N", lonD: "E", latitude: 0.76400305, longitude: 127.37649456666666, timestamp: "2024-06-06T14:49:16.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "11", hdop: "0.5", lat: "0045.840184", lon: "12722.589729", latD: "N", lonD: "E", latitude: 0.7640030666666667, longitude: 127.37649548333333, timestamp: "2024-06-06T14:49:37.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.840204", lon: "12722.589785", latD: "N", lonD: "E", latitude: 0.7640034, longitude: 127.37649641666667, timestamp: "2024-06-06T14:49:57.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.840204", lon: "12722.589785", latD: "N", lonD: "E", latitude: 0.7640034, longitude: 127.37649641666667, timestamp: "2024-06-06T14:49:57.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "11", hdop: "0.5", lat: "0045.840184", lon: "12722.589729", latD: "N", lonD: "E", latitude: 0.7640030666666667, longitude: 127.37649548333333, timestamp: "2024-06-06T14:49:37.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "11", hdop: "0.5", lat: "0045.840184", lon: "12722.589729", latD: "N", lonD: "E", latitude: 0.7640030666666667, longitude: 127.37649548333333, timestamp: "2024-06-06T14:49:37.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.840204", lon: "12722.589785", latD: "N", lonD: "E", latitude: 0.7640034, longitude: 127.37649641666667, timestamp: "2024-06-06T14:49:57.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.840204", lon: "12722.589785", latD: "N", lonD: "E", latitude: 0.7640034, longitude: 127.37649641666667, timestamp: "2024-06-06T14:49:57.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.840201", lon: "12722.589817", latD: "N", lonD: "E", latitude: 0.76400335, longitude: 127.37649695, timestamp: "2024-06-06T14:50:17.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.840204", lon: "12722.589785", latD: "N", lonD: "E", latitude: 0.7640034, longitude: 127.37649641666667, timestamp: "2024-06-06T14:49:57.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.840201", lon: "12722.589817", latD: "N", lonD: "E", latitude: 0.76400335, longitude: 127.37649695, timestamp: "2024-06-06T14:50:17.000Z" },
        { speed: "0.0", course: "246.4", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.840201", lon: "12722.589817", latD: "N", lonD: "E", latitude: 0.76400335, longitude: 127.37649695, timestamp: "2024-06-06T14:50:17.000Z" },
        { speed: "0.0", course: "103.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840116", lon: "12722.589506", latD: "N", lonD: "E", latitude: 0.7640019333333333, longitude: 127.37649176666666, timestamp: "2024-06-06T14:50:37.000Z" },
        { speed: "0.0", course: "103.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840116", lon: "12722.589506", latD: "N", lonD: "E", latitude: 0.7640019333333333, longitude: 127.37649176666666, timestamp: "2024-06-06T14:50:37.000Z" },
        { speed: "0.0", course: "103.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840116", lon: "12722.589506", latD: "N", lonD: "E", latitude: 0.7640019333333333, longitude: 127.37649176666666, timestamp: "2024-06-06T14:50:37.000Z" },
        { speed: "0.0", course: "103.9", altitude: "-0.3", satellites: "11", hdop: "0.6", lat: "0045.839858", lon: "12722.589167", latD: "N", lonD: "E", latitude: 0.7639976333333334, longitude: 127.37648611666667, timestamp: "2024-06-06T14:50:57.000Z" },
        { speed: "0.0", course: "103.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840116", lon: "12722.589506", latD: "N", lonD: "E", latitude: 0.7640019333333333, longitude: 127.37649176666666, timestamp: "2024-06-06T14:50:37.000Z" },
        { speed: "0.0", course: "103.9", altitude: "0.3", satellites: "10", hdop: "0.6", lat: "0045.840116", lon: "12722.589506", latD: "N", lonD: "E", latitude: 0.7640019333333333, longitude: 127.37649176666666, timestamp: "2024-06-06T14:50:37.000Z" },
        { speed: "0.0", course: "103.9", altitude: "-0.3", satellites: "11", hdop: "0.6", lat: "0045.839858", lon: "12722.589167", latD: "N", lonD: "E", latitude: 0.7639976333333334, longitude: 127.37648611666667, timestamp: "2024-06-06T14:50:57.000Z" },
        { speed: "0.0", course: "103.9", altitude: "-0.3", satellites: "11", hdop: "0.6", lat: "0045.839858", lon: "12722.589167", latD: "N", lonD: "E", latitude: 0.7639976333333334, longitude: 127.37648611666667, timestamp: "2024-06-06T14:50:57.000Z" },
        { speed: "0.0", course: "103.9", altitude: "-0.2", satellites: "11", hdop: "0.6", lat: "0045.839786", lon: "12722.589453", latD: "N", lonD: "E", latitude: 0.7639964333333332, longitude: 127.37649088333333, timestamp: "2024-06-06T14:51:17.000Z" },
        { speed: "0.0", course: "103.9", altitude: "-0.2", satellites: "11", hdop: "0.6", lat: "0045.839786", lon: "12722.589453", latD: "N", lonD: "E", latitude: 0.7639964333333332, longitude: 127.37649088333333, timestamp: "2024-06-06T14:51:17.000Z" },
        { speed: "0.0", course: "8.7", altitude: "-0.6", satellites: "10", hdop: "0.5", lat: "0045.839040", lon: "12722.590925", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.37651541666666, timestamp: "2024-06-06T14:51:37.000Z" },
        { speed: "0.0", course: "8.7", altitude: "-0.6", satellites: "10", hdop: "0.5", lat: "0045.839040", lon: "12722.590925", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.37651541666666, timestamp: "2024-06-06T14:51:37.000Z" },
        { speed: "0.0", course: "8.7", altitude: "-0.6", satellites: "10", hdop: "0.5", lat: "0045.839040", lon: "12722.590925", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.37651541666666, timestamp: "2024-06-06T14:51:37.000Z" },
        { speed: "0.0", course: "103.9", altitude: "-0.2", satellites: "11", hdop: "0.6", lat: "0045.839786", lon: "12722.589453", latD: "N", lonD: "E", latitude: 0.7639964333333332, longitude: 127.37649088333333, timestamp: "2024-06-06T14:51:17.000Z" },
        { speed: "0.0", course: "8.7", altitude: "-0.6", satellites: "10", hdop: "0.5", lat: "0045.839040", lon: "12722.590925", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.37651541666666, timestamp: "2024-06-06T14:51:37.000Z" },
        { speed: "0.0", course: "230.4", altitude: "5.2", satellites: "11", hdop: "0.5", lat: "0045.839683", lon: "12722.593322", latD: "N", lonD: "E", latitude: 0.7639947166666666, longitude: 127.37655536666666, timestamp: "2024-06-06T14:51:57.000Z" },
        { speed: "0.0", course: "230.4", altitude: "5.2", satellites: "11", hdop: "0.5", lat: "0045.839683", lon: "12722.593322", latD: "N", lonD: "E", latitude: 0.7639947166666666, longitude: 127.37655536666666, timestamp: "2024-06-06T14:51:57.000Z" },
        { speed: "0.0", course: "8.7", altitude: "-0.6", satellites: "10", hdop: "0.5", lat: "0045.839040", lon: "12722.590925", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.37651541666666, timestamp: "2024-06-06T14:51:37.000Z" },
        { speed: "0.0", course: "230.4", altitude: "5.2", satellites: "11", hdop: "0.5", lat: "0045.839683", lon: "12722.593322", latD: "N", lonD: "E", latitude: 0.7639947166666666, longitude: 127.37655536666666, timestamp: "2024-06-06T14:51:57.000Z" },
        { speed: "0.0", course: "230.4", altitude: "5.2", satellites: "11", hdop: "0.5", lat: "0045.839683", lon: "12722.593322", latD: "N", lonD: "E", latitude: 0.7639947166666666, longitude: 127.37655536666666, timestamp: "2024-06-06T14:51:57.000Z" },
        { speed: "0.0", course: "217.8", altitude: "4.8", satellites: "11", hdop: "0.5", lat: "0045.840609", lon: "12722.593820", latD: "N", lonD: "E", latitude: 0.76401015, longitude: 127.37656366666667, timestamp: "2024-06-06T14:52:17.000Z" },
        { speed: "0.0", course: "217.8", altitude: "4.8", satellites: "11", hdop: "0.5", lat: "0045.840609", lon: "12722.593820", latD: "N", lonD: "E", latitude: 0.76401015, longitude: 127.37656366666667, timestamp: "2024-06-06T14:52:17.000Z" },
        { speed: "0.0", course: "217.8", altitude: "4.8", satellites: "11", hdop: "0.5", lat: "0045.840609", lon: "12722.593820", latD: "N", lonD: "E", latitude: 0.76401015, longitude: 127.37656366666667, timestamp: "2024-06-06T14:52:17.000Z" },
        { speed: "0.0", course: "269.4", altitude: "0.9", satellites: "11", hdop: "0.6", lat: "0045.839752", lon: "12722.594367", latD: "N", lonD: "E", latitude: 0.7639958666666666, longitude: 127.37657278333333, timestamp: "2024-06-06T14:52:37.000Z" },
        { speed: "0.0", course: "217.8", altitude: "4.8", satellites: "11", hdop: "0.5", lat: "0045.840609", lon: "12722.593820", latD: "N", lonD: "E", latitude: 0.76401015, longitude: 127.37656366666667, timestamp: "2024-06-06T14:52:17.000Z" },
        { speed: "0.0", course: "217.8", altitude: "4.8", satellites: "11", hdop: "0.5", lat: "0045.840609", lon: "12722.593820", latD: "N", lonD: "E", latitude: 0.76401015, longitude: 127.37656366666667, timestamp: "2024-06-06T14:52:17.000Z" },
        { speed: "0.0", course: "269.4", altitude: "0.9", satellites: "11", hdop: "0.6", lat: "0045.839752", lon: "12722.594367", latD: "N", lonD: "E", latitude: 0.7639958666666666, longitude: 127.37657278333333, timestamp: "2024-06-06T14:52:37.000Z" },
        { speed: "0.0", course: "269.4", altitude: "0.9", satellites: "11", hdop: "0.6", lat: "0045.839752", lon: "12722.594367", latD: "N", lonD: "E", latitude: 0.7639958666666666, longitude: 127.37657278333333, timestamp: "2024-06-06T14:52:37.000Z" },
        { speed: "0.0", course: "269.4", altitude: "0.1", satellites: "10", hdop: "0.6", lat: "0045.839526", lon: "12722.594461", latD: "N", lonD: "E", latitude: 0.7639921, longitude: 127.37657435, timestamp: "2024-06-06T14:52:57.000Z" },
        { speed: "0.0", course: "269.4", altitude: "0.1", satellites: "10", hdop: "0.6", lat: "0045.839526", lon: "12722.594461", latD: "N", lonD: "E", latitude: 0.7639921, longitude: 127.37657435, timestamp: "2024-06-06T14:52:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839671", lon: "12722.594078", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.37656796666667, timestamp: "2024-06-06T14:53:17.000Z" },
        { speed: "0.0", course: "269.4", altitude: "0.1", satellites: "10", hdop: "0.6", lat: "0045.839526", lon: "12722.594461", latD: "N", lonD: "E", latitude: 0.7639921, longitude: 127.37657435, timestamp: "2024-06-06T14:52:57.000Z" },
        { speed: "0.0", course: "269.4", altitude: "0.1", satellites: "10", hdop: "0.6", lat: "0045.839526", lon: "12722.594461", latD: "N", lonD: "E", latitude: 0.7639921, longitude: 127.37657435, timestamp: "2024-06-06T14:52:57.000Z" },
        { speed: "0.0", course: "269.4", altitude: "0.1", satellites: "10", hdop: "0.6", lat: "0045.839526", lon: "12722.594461", latD: "N", lonD: "E", latitude: 0.7639921, longitude: 127.37657435, timestamp: "2024-06-06T14:52:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839671", lon: "12722.594078", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.37656796666667, timestamp: "2024-06-06T14:53:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.2", satellites: "10", hdop: "0.6", lat: "0045.839671", lon: "12722.594078", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.37656796666667, timestamp: "2024-06-06T14:53:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.839866", lon: "12722.593501", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37655835, timestamp: "2024-06-06T14:53:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.839866", lon: "12722.593501", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37655835, timestamp: "2024-06-06T14:53:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.839866", lon: "12722.593501", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37655835, timestamp: "2024-06-06T14:53:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.839866", lon: "12722.593501", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37655835, timestamp: "2024-06-06T14:53:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "10", hdop: "0.6", lat: "0045.839836", lon: "12722.593577", latD: "N", lonD: "E", latitude: 0.7639972666666667, longitude: 127.37655961666667, timestamp: "2024-06-06T14:53:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "10", hdop: "0.6", lat: "0045.839836", lon: "12722.593577", latD: "N", lonD: "E", latitude: 0.7639972666666667, longitude: 127.37655961666667, timestamp: "2024-06-06T14:53:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "10", hdop: "0.6", lat: "0045.839836", lon: "12722.593577", latD: "N", lonD: "E", latitude: 0.7639972666666667, longitude: 127.37655961666667, timestamp: "2024-06-06T14:53:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "10", hdop: "0.6", lat: "0045.839836", lon: "12722.593577", latD: "N", lonD: "E", latitude: 0.7639972666666667, longitude: 127.37655961666667, timestamp: "2024-06-06T14:53:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.839824", lon: "12722.593595", latD: "N", lonD: "E", latitude: 0.7639970666666667, longitude: 127.37655991666666, timestamp: "2024-06-06T14:54:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.839824", lon: "12722.593595", latD: "N", lonD: "E", latitude: 0.7639970666666667, longitude: 127.37655991666666, timestamp: "2024-06-06T14:54:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839815", lon: "12722.593614", latD: "N", lonD: "E", latitude: 0.7639969166666667, longitude: 127.37656023333334, timestamp: "2024-06-06T14:54:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839815", lon: "12722.593614", latD: "N", lonD: "E", latitude: 0.7639969166666667, longitude: 127.37656023333334, timestamp: "2024-06-06T14:54:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.839824", lon: "12722.593595", latD: "N", lonD: "E", latitude: 0.7639970666666667, longitude: 127.37655991666666, timestamp: "2024-06-06T14:54:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.839824", lon: "12722.593595", latD: "N", lonD: "E", latitude: 0.7639970666666667, longitude: 127.37655991666666, timestamp: "2024-06-06T14:54:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839815", lon: "12722.593614", latD: "N", lonD: "E", latitude: 0.7639969166666667, longitude: 127.37656023333334, timestamp: "2024-06-06T14:54:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839815", lon: "12722.593614", latD: "N", lonD: "E", latitude: 0.7639969166666667, longitude: 127.37656023333334, timestamp: "2024-06-06T14:54:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839804", lon: "12722.593653", latD: "N", lonD: "E", latitude: 0.7639967333333334, longitude: 127.37656088333334, timestamp: "2024-06-06T14:54:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839804", lon: "12722.593653", latD: "N", lonD: "E", latitude: 0.7639967333333334, longitude: 127.37656088333334, timestamp: "2024-06-06T14:54:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "10", hdop: "0.6", lat: "0045.839805", lon: "12722.593740", latD: "N", lonD: "E", latitude: 0.76399675, longitude: 127.37656233333334, timestamp: "2024-06-06T14:55:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "10", hdop: "0.6", lat: "0045.839805", lon: "12722.593740", latD: "N", lonD: "E", latitude: 0.76399675, longitude: 127.37656233333334, timestamp: "2024-06-06T14:55:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839804", lon: "12722.593653", latD: "N", lonD: "E", latitude: 0.7639967333333334, longitude: 127.37656088333334, timestamp: "2024-06-06T14:54:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839804", lon: "12722.593653", latD: "N", lonD: "E", latitude: 0.7639967333333334, longitude: 127.37656088333334, timestamp: "2024-06-06T14:54:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "10", hdop: "0.6", lat: "0045.839805", lon: "12722.593740", latD: "N", lonD: "E", latitude: 0.76399675, longitude: 127.37656233333334, timestamp: "2024-06-06T14:55:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "10", hdop: "0.6", lat: "0045.839805", lon: "12722.593740", latD: "N", lonD: "E", latitude: 0.76399675, longitude: 127.37656233333334, timestamp: "2024-06-06T14:55:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.7", satellites: "11", hdop: "0.6", lat: "0045.839791", lon: "12722.593842", latD: "N", lonD: "E", latitude: 0.7639965166666667, longitude: 127.37656403333334, timestamp: "2024-06-06T14:55:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.7", satellites: "11", hdop: "0.6", lat: "0045.839791", lon: "12722.593842", latD: "N", lonD: "E", latitude: 0.7639965166666667, longitude: 127.37656403333334, timestamp: "2024-06-06T14:55:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.7", satellites: "11", hdop: "0.6", lat: "0045.839791", lon: "12722.593842", latD: "N", lonD: "E", latitude: 0.7639965166666667, longitude: 127.37656403333334, timestamp: "2024-06-06T14:55:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.7", satellites: "11", hdop: "0.6", lat: "0045.839791", lon: "12722.593842", latD: "N", lonD: "E", latitude: 0.7639965166666667, longitude: 127.37656403333334, timestamp: "2024-06-06T14:55:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839810", lon: "12722.593846", latD: "N", lonD: "E", latitude: 0.7639968333333333, longitude: 127.3765641, timestamp: "2024-06-06T14:55:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839810", lon: "12722.593846", latD: "N", lonD: "E", latitude: 0.7639968333333333, longitude: 127.3765641, timestamp: "2024-06-06T14:55:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839810", lon: "12722.593846", latD: "N", lonD: "E", latitude: 0.7639968333333333, longitude: 127.3765641, timestamp: "2024-06-06T14:55:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.4", satellites: "11", hdop: "0.6", lat: "0045.839866", lon: "12722.593809", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37656348333333, timestamp: "2024-06-06T14:56:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839810", lon: "12722.593846", latD: "N", lonD: "E", latitude: 0.7639968333333333, longitude: 127.3765641, timestamp: "2024-06-06T14:55:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.6", satellites: "11", hdop: "0.6", lat: "0045.839810", lon: "12722.593846", latD: "N", lonD: "E", latitude: 0.7639968333333333, longitude: 127.3765641, timestamp: "2024-06-06T14:55:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.4", satellites: "11", hdop: "0.6", lat: "0045.839866", lon: "12722.593809", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37656348333333, timestamp: "2024-06-06T14:56:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.4", satellites: "11", hdop: "0.6", lat: "0045.839866", lon: "12722.593809", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37656348333333, timestamp: "2024-06-06T14:56:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.3", satellites: "11", hdop: "0.6", lat: "0045.839880", lon: "12722.593837", latD: "N", lonD: "E", latitude: 0.7639980000000001, longitude: 127.37656395, timestamp: "2024-06-06T14:56:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.4", satellites: "11", hdop: "0.6", lat: "0045.839866", lon: "12722.593809", latD: "N", lonD: "E", latitude: 0.7639977666666666, longitude: 127.37656348333333, timestamp: "2024-06-06T14:56:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.3", satellites: "11", hdop: "0.6", lat: "0045.839880", lon: "12722.593837", latD: "N", lonD: "E", latitude: 0.7639980000000001, longitude: 127.37656395, timestamp: "2024-06-06T14:56:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "-0.3", satellites: "11", hdop: "0.6", lat: "0045.839880", lon: "12722.593837", latD: "N", lonD: "E", latitude: 0.7639980000000001, longitude: 127.37656395, timestamp: "2024-06-06T14:56:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.0", satellites: "11", hdop: "0.6", lat: "0045.839952", lon: "12722.593803", latD: "N", lonD: "E", latitude: 0.7639992, longitude: 127.37656338333333, timestamp: "2024-06-06T14:56:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.0", satellites: "11", hdop: "0.6", lat: "0045.839952", lon: "12722.593803", latD: "N", lonD: "E", latitude: 0.7639992, longitude: 127.37656338333333, timestamp: "2024-06-06T14:56:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.0", satellites: "11", hdop: "0.6", lat: "0045.839952", lon: "12722.593803", latD: "N", lonD: "E", latitude: 0.7639992, longitude: 127.37656338333333, timestamp: "2024-06-06T14:56:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.0", satellites: "11", hdop: "0.6", lat: "0045.839952", lon: "12722.593803", latD: "N", lonD: "E", latitude: 0.7639992, longitude: 127.37656338333333, timestamp: "2024-06-06T14:56:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.0", satellites: "11", hdop: "0.6", lat: "0045.839952", lon: "12722.593803", latD: "N", lonD: "E", latitude: 0.7639992, longitude: 127.37656338333333, timestamp: "2024-06-06T14:56:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840010", lon: "12722.593781", latD: "N", lonD: "E", latitude: 0.7640001666666667, longitude: 127.37656301666667, timestamp: "2024-06-06T14:57:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840010", lon: "12722.593781", latD: "N", lonD: "E", latitude: 0.7640001666666667, longitude: 127.37656301666667, timestamp: "2024-06-06T14:57:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.4", satellites: "11", hdop: "0.6", lat: "0045.840038", lon: "12722.593772", latD: "N", lonD: "E", latitude: 0.7640006333333333, longitude: 127.37656286666666, timestamp: "2024-06-06T14:57:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840010", lon: "12722.593781", latD: "N", lonD: "E", latitude: 0.7640001666666667, longitude: 127.37656301666667, timestamp: "2024-06-06T14:57:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.3", satellites: "11", hdop: "0.6", lat: "0045.840010", lon: "12722.593781", latD: "N", lonD: "E", latitude: 0.7640001666666667, longitude: 127.37656301666667, timestamp: "2024-06-06T14:57:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.4", satellites: "11", hdop: "0.6", lat: "0045.840038", lon: "12722.593772", latD: "N", lonD: "E", latitude: 0.7640006333333333, longitude: 127.37656286666666, timestamp: "2024-06-06T14:57:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.4", satellites: "11", hdop: "0.6", lat: "0045.840038", lon: "12722.593772", latD: "N", lonD: "E", latitude: 0.7640006333333333, longitude: 127.37656286666666, timestamp: "2024-06-06T14:57:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.6", satellites: "11", hdop: "0.6", lat: "0045.840057", lon: "12722.593737", latD: "N", lonD: "E", latitude: 0.76400095, longitude: 127.37656228333333, timestamp: "2024-06-06T14:57:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.6", satellites: "11", hdop: "0.6", lat: "0045.840057", lon: "12722.593737", latD: "N", lonD: "E", latitude: 0.76400095, longitude: 127.37656228333333, timestamp: "2024-06-06T14:57:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.6", satellites: "11", hdop: "0.6", lat: "0045.840057", lon: "12722.593737", latD: "N", lonD: "E", latitude: 0.76400095, longitude: 127.37656228333333, timestamp: "2024-06-06T14:57:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.5", satellites: "11", hdop: "0.6", lat: "0045.840042", lon: "12722.593802", latD: "N", lonD: "E", latitude: 0.7640007, longitude: 127.37656336666667, timestamp: "2024-06-06T14:58:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.6", satellites: "11", hdop: "0.6", lat: "0045.840057", lon: "12722.593737", latD: "N", lonD: "E", latitude: 0.76400095, longitude: 127.37656228333333, timestamp: "2024-06-06T14:57:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.6", satellites: "11", hdop: "0.6", lat: "0045.840057", lon: "12722.593737", latD: "N", lonD: "E", latitude: 0.76400095, longitude: 127.37656228333333, timestamp: "2024-06-06T14:57:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.5", satellites: "11", hdop: "0.6", lat: "0045.840042", lon: "12722.593802", latD: "N", lonD: "E", latitude: 0.7640007, longitude: 127.37656336666667, timestamp: "2024-06-06T14:58:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.5", satellites: "11", hdop: "0.6", lat: "0045.840042", lon: "12722.593802", latD: "N", lonD: "E", latitude: 0.7640007, longitude: 127.37656336666667, timestamp: "2024-06-06T14:58:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.5", satellites: "11", hdop: "0.6", lat: "0045.840033", lon: "12722.593843", latD: "N", lonD: "E", latitude: 0.76400055, longitude: 127.37656405, timestamp: "2024-06-06T14:58:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.5", satellites: "11", hdop: "0.6", lat: "0045.840033", lon: "12722.593843", latD: "N", lonD: "E", latitude: 0.76400055, longitude: 127.37656405, timestamp: "2024-06-06T14:58:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.5", satellites: "11", hdop: "0.6", lat: "0045.840033", lon: "12722.593843", latD: "N", lonD: "E", latitude: 0.76400055, longitude: 127.37656405, timestamp: "2024-06-06T14:58:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.5", satellites: "11", hdop: "0.6", lat: "0045.840033", lon: "12722.593843", latD: "N", lonD: "E", latitude: 0.76400055, longitude: 127.37656405, timestamp: "2024-06-06T14:58:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.6", satellites: "11", hdop: "0.6", lat: "0045.840051", lon: "12722.593859", latD: "N", lonD: "E", latitude: 0.7640008500000001, longitude: 127.37656431666667, timestamp: "2024-06-06T14:58:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.6", satellites: "11", hdop: "0.6", lat: "0045.840051", lon: "12722.593859", latD: "N", lonD: "E", latitude: 0.7640008500000001, longitude: 127.37656431666667, timestamp: "2024-06-06T14:58:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.6", satellites: "11", hdop: "0.6", lat: "0045.840051", lon: "12722.593859", latD: "N", lonD: "E", latitude: 0.7640008500000001, longitude: 127.37656431666667, timestamp: "2024-06-06T14:58:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.6", satellites: "11", hdop: "0.6", lat: "0045.840051", lon: "12722.593859", latD: "N", lonD: "E", latitude: 0.7640008500000001, longitude: 127.37656431666667, timestamp: "2024-06-06T14:58:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.6", satellites: "11", hdop: "0.6", lat: "0045.840051", lon: "12722.593859", latD: "N", lonD: "E", latitude: 0.7640008500000001, longitude: 127.37656431666667, timestamp: "2024-06-06T14:58:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.9", satellites: "11", hdop: "0.6", lat: "0045.840084", lon: "12722.593820", latD: "N", lonD: "E", latitude: 0.7640013999999999, longitude: 127.37656366666667, timestamp: "2024-06-06T14:59:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.840108", lon: "12722.593807", latD: "N", lonD: "E", latitude: 0.7640018000000001, longitude: 127.37656345, timestamp: "2024-06-06T14:59:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.9", satellites: "11", hdop: "0.6", lat: "0045.840084", lon: "12722.593820", latD: "N", lonD: "E", latitude: 0.7640013999999999, longitude: 127.37656366666667, timestamp: "2024-06-06T14:59:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "0.9", satellites: "11", hdop: "0.6", lat: "0045.840084", lon: "12722.593820", latD: "N", lonD: "E", latitude: 0.7640013999999999, longitude: 127.37656366666667, timestamp: "2024-06-06T14:59:17.000Z" },
        { speed: "0.0", course: "305.9", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.840108", lon: "12722.593807", latD: "N", lonD: "E", latitude: 0.7640018000000001, longitude: 127.37656345, timestamp: "2024-06-06T14:59:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.840108", lon: "12722.593807", latD: "N", lonD: "E", latitude: 0.7640018000000001, longitude: 127.37656345, timestamp: "2024-06-06T14:59:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "1.0", satellites: "11", hdop: "0.6", lat: "0045.840108", lon: "12722.593807", latD: "N", lonD: "E", latitude: 0.7640018000000001, longitude: 127.37656345, timestamp: "2024-06-06T14:59:37.000Z" },
        { speed: "0.0", course: "305.9", altitude: "1.0", satellites: "10", hdop: "0.6", lat: "0045.840100", lon: "12722.593830", latD: "N", lonD: "E", latitude: 0.7640016666666667, longitude: 127.37656383333334, timestamp: "2024-06-06T14:59:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "1.0", satellites: "10", hdop: "0.6", lat: "0045.840100", lon: "12722.593830", latD: "N", lonD: "E", latitude: 0.7640016666666667, longitude: 127.37656383333334, timestamp: "2024-06-06T14:59:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "1.0", satellites: "10", hdop: "0.6", lat: "0045.840100", lon: "12722.593830", latD: "N", lonD: "E", latitude: 0.7640016666666667, longitude: 127.37656383333334, timestamp: "2024-06-06T14:59:57.000Z" },
        { speed: "0.0", course: "305.9", altitude: "1.0", satellites: "10", hdop: "0.6", lat: "0045.840100", lon: "12722.593830", latD: "N", lonD: "E", latitude: 0.7640016666666667, longitude: 127.37656383333334, timestamp: "2024-06-06T14:59:57.000Z" },
        { speed: "0.0", course: "303.4", altitude: "3.2", satellites: "11", hdop: "0.5", lat: "0045.841017", lon: "12722.593962", latD: "N", lonD: "E", latitude: 0.76401695, longitude: 127.37656603333333, timestamp: "2024-06-06T15:00:17.000Z" },
        { speed: "0.0", course: "303.4", altitude: "3.2", satellites: "11", hdop: "0.5", lat: "0045.841017", lon: "12722.593962", latD: "N", lonD: "E", latitude: 0.76401695, longitude: 127.37656603333333, timestamp: "2024-06-06T15:00:17.000Z" },
        { speed: "0.0", course: "303.4", altitude: "3.2", satellites: "11", hdop: "0.5", lat: "0045.841017", lon: "12722.593962", latD: "N", lonD: "E", latitude: 0.76401695, longitude: 127.37656603333333, timestamp: "2024-06-06T15:00:17.000Z" },
        { speed: "0.0", course: "141.6", altitude: "7.2", satellites: "11", hdop: "0.5", lat: "0045.842395", lon: "12722.596008", latD: "N", lonD: "E", latitude: 0.7640399166666667, longitude: 127.37660013333333, timestamp: "2024-06-06T15:00:37.000Z" },
        { speed: "0.0", course: "303.4", altitude: "3.2", satellites: "11", hdop: "0.5", lat: "0045.841017", lon: "12722.593962", latD: "N", lonD: "E", latitude: 0.76401695, longitude: 127.37656603333333, timestamp: "2024-06-06T15:00:17.000Z" },
        { speed: "0.0", course: "303.4", altitude: "3.2", satellites: "11", hdop: "0.5", lat: "0045.841017", lon: "12722.593962", latD: "N", lonD: "E", latitude: 0.76401695, longitude: 127.37656603333333, timestamp: "2024-06-06T15:00:17.000Z" },
        { speed: "0.0", course: "141.6", altitude: "7.2", satellites: "11", hdop: "0.5", lat: "0045.842395", lon: "12722.596008", latD: "N", lonD: "E", latitude: 0.7640399166666667, longitude: 127.37660013333333, timestamp: "2024-06-06T15:00:37.000Z" },
        { speed: "0.0", course: "141.6", altitude: "7.2", satellites: "11", hdop: "0.5", lat: "0045.842395", lon: "12722.596008", latD: "N", lonD: "E", latitude: 0.7640399166666667, longitude: 127.37660013333333, timestamp: "2024-06-06T15:00:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842939", lon: "12722.597020", latD: "N", lonD: "E", latitude: 0.7640489833333334, longitude: 127.376617, timestamp: "2024-06-06T15:00:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842939", lon: "12722.597020", latD: "N", lonD: "E", latitude: 0.7640489833333334, longitude: 127.376617, timestamp: "2024-06-06T15:00:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842939", lon: "12722.597020", latD: "N", lonD: "E", latitude: 0.7640489833333334, longitude: 127.376617, timestamp: "2024-06-06T15:00:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842872", lon: "12722.596962", latD: "N", lonD: "E", latitude: 0.7640478666666667, longitude: 127.37661603333333, timestamp: "2024-06-06T15:01:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842872", lon: "12722.596962", latD: "N", lonD: "E", latitude: 0.7640478666666667, longitude: 127.37661603333333, timestamp: "2024-06-06T15:01:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842939", lon: "12722.597020", latD: "N", lonD: "E", latitude: 0.7640489833333334, longitude: 127.376617, timestamp: "2024-06-06T15:00:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842872", lon: "12722.596962", latD: "N", lonD: "E", latitude: 0.7640478666666667, longitude: 127.37661603333333, timestamp: "2024-06-06T15:01:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842872", lon: "12722.596962", latD: "N", lonD: "E", latitude: 0.7640478666666667, longitude: 127.37661603333333, timestamp: "2024-06-06T15:01:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842874", lon: "12722.596965", latD: "N", lonD: "E", latitude: 0.7640479, longitude: 127.37661608333333, timestamp: "2024-06-06T15:01:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842874", lon: "12722.596965", latD: "N", lonD: "E", latitude: 0.7640479, longitude: 127.37661608333333, timestamp: "2024-06-06T15:01:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842874", lon: "12722.596965", latD: "N", lonD: "E", latitude: 0.7640479, longitude: 127.37661608333333, timestamp: "2024-06-06T15:01:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842874", lon: "12722.596965", latD: "N", lonD: "E", latitude: 0.7640479, longitude: 127.37661608333333, timestamp: "2024-06-06T15:01:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842874", lon: "12722.596965", latD: "N", lonD: "E", latitude: 0.7640479, longitude: 127.37661608333333, timestamp: "2024-06-06T15:01:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842866", lon: "12722.596965", latD: "N", lonD: "E", latitude: 0.7640477666666666, longitude: 127.37661608333333, timestamp: "2024-06-06T15:01:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842866", lon: "12722.596965", latD: "N", lonD: "E", latitude: 0.7640477666666666, longitude: 127.37661608333333, timestamp: "2024-06-06T15:01:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842866", lon: "12722.596965", latD: "N", lonD: "E", latitude: 0.7640477666666666, longitude: 127.37661608333333, timestamp: "2024-06-06T15:01:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842870", lon: "12722.596959", latD: "N", lonD: "E", latitude: 0.7640478333333333, longitude: 127.37661598333334, timestamp: "2024-06-06T15:02:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842870", lon: "12722.596959", latD: "N", lonD: "E", latitude: 0.7640478333333333, longitude: 127.37661598333334, timestamp: "2024-06-06T15:02:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842868", lon: "12722.596917", latD: "N", lonD: "E", latitude: 0.7640478, longitude: 127.37661528333334, timestamp: "2024-06-06T15:02:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842870", lon: "12722.596959", latD: "N", lonD: "E", latitude: 0.7640478333333333, longitude: 127.37661598333334, timestamp: "2024-06-06T15:02:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842870", lon: "12722.596959", latD: "N", lonD: "E", latitude: 0.7640478333333333, longitude: 127.37661598333334, timestamp: "2024-06-06T15:02:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842870", lon: "12722.596959", latD: "N", lonD: "E", latitude: 0.7640478333333333, longitude: 127.37661598333334, timestamp: "2024-06-06T15:02:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842868", lon: "12722.596917", latD: "N", lonD: "E", latitude: 0.7640478, longitude: 127.37661528333334, timestamp: "2024-06-06T15:02:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842868", lon: "12722.596917", latD: "N", lonD: "E", latitude: 0.7640478, longitude: 127.37661528333334, timestamp: "2024-06-06T15:02:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842865", lon: "12722.596922", latD: "N", lonD: "E", latitude: 0.76404775, longitude: 127.37661536666667, timestamp: "2024-06-06T15:02:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842865", lon: "12722.596922", latD: "N", lonD: "E", latitude: 0.76404775, longitude: 127.37661536666667, timestamp: "2024-06-06T15:02:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842865", lon: "12722.596922", latD: "N", lonD: "E", latitude: 0.76404775, longitude: 127.37661536666667, timestamp: "2024-06-06T15:02:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842865", lon: "12722.596922", latD: "N", lonD: "E", latitude: 0.76404775, longitude: 127.37661536666667, timestamp: "2024-06-06T15:02:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842865", lon: "12722.596922", latD: "N", lonD: "E", latitude: 0.76404775, longitude: 127.37661536666667, timestamp: "2024-06-06T15:02:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842859", lon: "12722.596896", latD: "N", lonD: "E", latitude: 0.76404765, longitude: 127.37661493333333, timestamp: "2024-06-06T15:03:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842859", lon: "12722.596896", latD: "N", lonD: "E", latitude: 0.76404765, longitude: 127.37661493333333, timestamp: "2024-06-06T15:03:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842851", lon: "12722.596884", latD: "N", lonD: "E", latitude: 0.7640475166666667, longitude: 127.37661473333333, timestamp: "2024-06-06T15:03:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842851", lon: "12722.596884", latD: "N", lonD: "E", latitude: 0.7640475166666667, longitude: 127.37661473333333, timestamp: "2024-06-06T15:03:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842859", lon: "12722.596896", latD: "N", lonD: "E", latitude: 0.76404765, longitude: 127.37661493333333, timestamp: "2024-06-06T15:03:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842851", lon: "12722.596884", latD: "N", lonD: "E", latitude: 0.7640475166666667, longitude: 127.37661473333333, timestamp: "2024-06-06T15:03:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842851", lon: "12722.596884", latD: "N", lonD: "E", latitude: 0.7640475166666667, longitude: 127.37661473333333, timestamp: "2024-06-06T15:03:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842843", lon: "12722.596872", latD: "N", lonD: "E", latitude: 0.7640473833333333, longitude: 127.37661453333334, timestamp: "2024-06-06T15:03:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842843", lon: "12722.596872", latD: "N", lonD: "E", latitude: 0.7640473833333333, longitude: 127.37661453333334, timestamp: "2024-06-06T15:03:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842836", lon: "12722.596863", latD: "N", lonD: "E", latitude: 0.7640472666666667, longitude: 127.37661438333333, timestamp: "2024-06-06T15:04:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842836", lon: "12722.596863", latD: "N", lonD: "E", latitude: 0.7640472666666667, longitude: 127.37661438333333, timestamp: "2024-06-06T15:04:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842836", lon: "12722.596863", latD: "N", lonD: "E", latitude: 0.7640472666666667, longitude: 127.37661438333333, timestamp: "2024-06-06T15:04:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842843", lon: "12722.596872", latD: "N", lonD: "E", latitude: 0.7640473833333333, longitude: 127.37661453333334, timestamp: "2024-06-06T15:03:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842836", lon: "12722.596863", latD: "N", lonD: "E", latitude: 0.7640472666666667, longitude: 127.37661438333333, timestamp: "2024-06-06T15:04:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842836", lon: "12722.596863", latD: "N", lonD: "E", latitude: 0.7640472666666667, longitude: 127.37661438333333, timestamp: "2024-06-06T15:04:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842836", lon: "12722.596863", latD: "N", lonD: "E", latitude: 0.7640472666666667, longitude: 127.37661438333333, timestamp: "2024-06-06T15:04:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842828", lon: "12722.596844", latD: "N", lonD: "E", latitude: 0.7640471333333333, longitude: 127.37661406666666, timestamp: "2024-06-06T15:04:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842828", lon: "12722.596844", latD: "N", lonD: "E", latitude: 0.7640471333333333, longitude: 127.37661406666666, timestamp: "2024-06-06T15:04:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842828", lon: "12722.596844", latD: "N", lonD: "E", latitude: 0.7640471333333333, longitude: 127.37661406666666, timestamp: "2024-06-06T15:04:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842813", lon: "12722.596827", latD: "N", lonD: "E", latitude: 0.7640468833333334, longitude: 127.37661378333334, timestamp: "2024-06-06T15:04:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842813", lon: "12722.596827", latD: "N", lonD: "E", latitude: 0.7640468833333334, longitude: 127.37661378333334, timestamp: "2024-06-06T15:04:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842813", lon: "12722.596827", latD: "N", lonD: "E", latitude: 0.7640468833333334, longitude: 127.37661378333334, timestamp: "2024-06-06T15:04:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842810", lon: "12722.596781", latD: "N", lonD: "E", latitude: 0.7640468333333333, longitude: 127.37661301666667, timestamp: "2024-06-06T15:05:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842813", lon: "12722.596827", latD: "N", lonD: "E", latitude: 0.7640468833333334, longitude: 127.37661378333334, timestamp: "2024-06-06T15:04:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842813", lon: "12722.596827", latD: "N", lonD: "E", latitude: 0.7640468833333334, longitude: 127.37661378333334, timestamp: "2024-06-06T15:04:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842810", lon: "12722.596781", latD: "N", lonD: "E", latitude: 0.7640468333333333, longitude: 127.37661301666667, timestamp: "2024-06-06T15:05:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842810", lon: "12722.596781", latD: "N", lonD: "E", latitude: 0.7640468333333333, longitude: 127.37661301666667, timestamp: "2024-06-06T15:05:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842810", lon: "12722.596781", latD: "N", lonD: "E", latitude: 0.7640468333333333, longitude: 127.37661301666667, timestamp: "2024-06-06T15:05:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842799", lon: "12722.596756", latD: "N", lonD: "E", latitude: 0.7640466499999999, longitude: 127.3766126, timestamp: "2024-06-06T15:05:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842799", lon: "12722.596756", latD: "N", lonD: "E", latitude: 0.7640466499999999, longitude: 127.3766126, timestamp: "2024-06-06T15:05:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842794", lon: "12722.596728", latD: "N", lonD: "E", latitude: 0.7640465666666666, longitude: 127.37661213333334, timestamp: "2024-06-06T15:05:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842799", lon: "12722.596756", latD: "N", lonD: "E", latitude: 0.7640466499999999, longitude: 127.3766126, timestamp: "2024-06-06T15:05:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842799", lon: "12722.596756", latD: "N", lonD: "E", latitude: 0.7640466499999999, longitude: 127.3766126, timestamp: "2024-06-06T15:05:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842794", lon: "12722.596728", latD: "N", lonD: "E", latitude: 0.7640465666666666, longitude: 127.37661213333334, timestamp: "2024-06-06T15:05:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842769", lon: "12722.596723", latD: "N", lonD: "E", latitude: 0.76404615, longitude: 127.37661205, timestamp: "2024-06-06T15:06:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842769", lon: "12722.596723", latD: "N", lonD: "E", latitude: 0.76404615, longitude: 127.37661205, timestamp: "2024-06-06T15:06:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842794", lon: "12722.596728", latD: "N", lonD: "E", latitude: 0.7640465666666666, longitude: 127.37661213333334, timestamp: "2024-06-06T15:05:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842769", lon: "12722.596723", latD: "N", lonD: "E", latitude: 0.76404615, longitude: 127.37661205, timestamp: "2024-06-06T15:06:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842769", lon: "12722.596723", latD: "N", lonD: "E", latitude: 0.76404615, longitude: 127.37661205, timestamp: "2024-06-06T15:06:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842769", lon: "12722.596723", latD: "N", lonD: "E", latitude: 0.76404615, longitude: 127.37661205, timestamp: "2024-06-06T15:06:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842741", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640456833333332, longitude: 127.37661195, timestamp: "2024-06-06T15:06:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842741", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640456833333332, longitude: 127.37661195, timestamp: "2024-06-06T15:06:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842754", lon: "12722.596686", latD: "N", lonD: "E", latitude: 0.7640459, longitude: 127.37661143333334, timestamp: "2024-06-06T15:06:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842741", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640456833333332, longitude: 127.37661195, timestamp: "2024-06-06T15:06:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842741", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640456833333332, longitude: 127.37661195, timestamp: "2024-06-06T15:06:37.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842754", lon: "12722.596686", latD: "N", lonD: "E", latitude: 0.7640459, longitude: 127.37661143333334, timestamp: "2024-06-06T15:06:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842754", lon: "12722.596686", latD: "N", lonD: "E", latitude: 0.7640459, longitude: 127.37661143333334, timestamp: "2024-06-06T15:06:57.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842742", lon: "12722.596685", latD: "N", lonD: "E", latitude: 0.7640457, longitude: 127.37661141666666, timestamp: "2024-06-06T15:07:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842742", lon: "12722.596685", latD: "N", lonD: "E", latitude: 0.7640457, longitude: 127.37661141666666, timestamp: "2024-06-06T15:07:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842742", lon: "12722.596685", latD: "N", lonD: "E", latitude: 0.7640457, longitude: 127.37661141666666, timestamp: "2024-06-06T15:07:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842743", lon: "12722.596661", latD: "N", lonD: "E", latitude: 0.7640457166666667, longitude: 127.37661101666667, timestamp: "2024-06-06T15:07:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842743", lon: "12722.596661", latD: "N", lonD: "E", latitude: 0.7640457166666667, longitude: 127.37661101666667, timestamp: "2024-06-06T15:07:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842742", lon: "12722.596685", latD: "N", lonD: "E", latitude: 0.7640457, longitude: 127.37661141666666, timestamp: "2024-06-06T15:07:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842743", lon: "12722.596661", latD: "N", lonD: "E", latitude: 0.7640457166666667, longitude: 127.37661101666667, timestamp: "2024-06-06T15:07:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842743", lon: "12722.596661", latD: "N", lonD: "E", latitude: 0.7640457166666667, longitude: 127.37661101666667, timestamp: "2024-06-06T15:07:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842744", lon: "12722.596626", latD: "N", lonD: "E", latitude: 0.7640457333333334, longitude: 127.37661043333334, timestamp: "2024-06-06T15:07:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842744", lon: "12722.596626", latD: "N", lonD: "E", latitude: 0.7640457333333334, longitude: 127.37661043333334, timestamp: "2024-06-06T15:07:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842748", lon: "12722.596605", latD: "N", lonD: "E", latitude: 0.7640458, longitude: 127.37661008333333, timestamp: "2024-06-06T15:08:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842748", lon: "12722.596605", latD: "N", lonD: "E", latitude: 0.7640458, longitude: 127.37661008333333, timestamp: "2024-06-06T15:08:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842748", lon: "12722.596605", latD: "N", lonD: "E", latitude: 0.7640458, longitude: 127.37661008333333, timestamp: "2024-06-06T15:08:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.842744", lon: "12722.596626", latD: "N", lonD: "E", latitude: 0.7640457333333334, longitude: 127.37661043333334, timestamp: "2024-06-06T15:07:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842748", lon: "12722.596605", latD: "N", lonD: "E", latitude: 0.7640458, longitude: 127.37661008333333, timestamp: "2024-06-06T15:08:17.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842738", lon: "12722.596620", latD: "N", lonD: "E", latitude: 0.7640456333333333, longitude: 127.37661033333333, timestamp: "2024-06-06T15:08:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842738", lon: "12722.596620", latD: "N", lonD: "E", latitude: 0.7640456333333333, longitude: 127.37661033333333, timestamp: "2024-06-06T15:08:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842738", lon: "12722.596620", latD: "N", lonD: "E", latitude: 0.7640456333333333, longitude: 127.37661033333333, timestamp: "2024-06-06T15:08:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842738", lon: "12722.596620", latD: "N", lonD: "E", latitude: 0.7640456333333333, longitude: 127.37661033333333, timestamp: "2024-06-06T15:08:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842738", lon: "12722.596620", latD: "N", lonD: "E", latitude: 0.7640456333333333, longitude: 127.37661033333333, timestamp: "2024-06-06T15:08:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842724", lon: "12722.596623", latD: "N", lonD: "E", latitude: 0.7640454, longitude: 127.37661038333333, timestamp: "2024-06-06T15:08:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842738", lon: "12722.596620", latD: "N", lonD: "E", latitude: 0.7640456333333333, longitude: 127.37661033333333, timestamp: "2024-06-06T15:08:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842724", lon: "12722.596623", latD: "N", lonD: "E", latitude: 0.7640454, longitude: 127.37661038333333, timestamp: "2024-06-06T15:08:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842719", lon: "12722.596634", latD: "N", lonD: "E", latitude: 0.7640453166666668, longitude: 127.37661056666667, timestamp: "2024-06-06T15:09:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842719", lon: "12722.596634", latD: "N", lonD: "E", latitude: 0.7640453166666668, longitude: 127.37661056666667, timestamp: "2024-06-06T15:09:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842719", lon: "12722.596634", latD: "N", lonD: "E", latitude: 0.7640453166666668, longitude: 127.37661056666667, timestamp: "2024-06-06T15:09:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842719", lon: "12722.596634", latD: "N", lonD: "E", latitude: 0.7640453166666668, longitude: 127.37661056666667, timestamp: "2024-06-06T15:09:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842719", lon: "12722.596634", latD: "N", lonD: "E", latitude: 0.7640453166666668, longitude: 127.37661056666667, timestamp: "2024-06-06T15:09:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842719", lon: "12722.596634", latD: "N", lonD: "E", latitude: 0.7640453166666668, longitude: 127.37661056666667, timestamp: "2024-06-06T15:09:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842704", lon: "12722.596643", latD: "N", lonD: "E", latitude: 0.7640450666666666, longitude: 127.37661071666666, timestamp: "2024-06-06T15:09:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842704", lon: "12722.596643", latD: "N", lonD: "E", latitude: 0.7640450666666666, longitude: 127.37661071666666, timestamp: "2024-06-06T15:09:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842695", lon: "12722.596645", latD: "N", lonD: "E", latitude: 0.7640449166666666, longitude: 127.37661075, timestamp: "2024-06-06T15:09:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842695", lon: "12722.596645", latD: "N", lonD: "E", latitude: 0.7640449166666666, longitude: 127.37661075, timestamp: "2024-06-06T15:09:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842704", lon: "12722.596643", latD: "N", lonD: "E", latitude: 0.7640450666666666, longitude: 127.37661071666666, timestamp: "2024-06-06T15:09:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842695", lon: "12722.596645", latD: "N", lonD: "E", latitude: 0.7640449166666666, longitude: 127.37661075, timestamp: "2024-06-06T15:09:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842695", lon: "12722.596645", latD: "N", lonD: "E", latitude: 0.7640449166666666, longitude: 127.37661075, timestamp: "2024-06-06T15:09:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842677", lon: "12722.596655", latD: "N", lonD: "E", latitude: 0.7640446166666667, longitude: 127.37661091666666, timestamp: "2024-06-06T15:10:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842677", lon: "12722.596655", latD: "N", lonD: "E", latitude: 0.7640446166666667, longitude: 127.37661091666666, timestamp: "2024-06-06T15:10:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842676", lon: "12722.596633", latD: "N", lonD: "E", latitude: 0.7640446, longitude: 127.37661055, timestamp: "2024-06-06T15:10:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842676", lon: "12722.596633", latD: "N", lonD: "E", latitude: 0.7640446, longitude: 127.37661055, timestamp: "2024-06-06T15:10:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842677", lon: "12722.596655", latD: "N", lonD: "E", latitude: 0.7640446166666667, longitude: 127.37661091666666, timestamp: "2024-06-06T15:10:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842677", lon: "12722.596655", latD: "N", lonD: "E", latitude: 0.7640446166666667, longitude: 127.37661091666666, timestamp: "2024-06-06T15:10:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842676", lon: "12722.596633", latD: "N", lonD: "E", latitude: 0.7640446, longitude: 127.37661055, timestamp: "2024-06-06T15:10:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842676", lon: "12722.596633", latD: "N", lonD: "E", latitude: 0.7640446, longitude: 127.37661055, timestamp: "2024-06-06T15:10:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.842659", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640443166666666, longitude: 127.37661065, timestamp: "2024-06-06T15:10:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842676", lon: "12722.596633", latD: "N", lonD: "E", latitude: 0.7640446, longitude: 127.37661055, timestamp: "2024-06-06T15:10:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.842659", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640443166666666, longitude: 127.37661065, timestamp: "2024-06-06T15:10:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.842659", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640443166666666, longitude: 127.37661065, timestamp: "2024-06-06T15:10:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.842659", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640443166666666, longitude: 127.37661065, timestamp: "2024-06-06T15:10:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842635", lon: "12722.596635", latD: "N", lonD: "E", latitude: 0.7640439166666667, longitude: 127.37661058333333, timestamp: "2024-06-06T15:11:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842635", lon: "12722.596635", latD: "N", lonD: "E", latitude: 0.7640439166666667, longitude: 127.37661058333333, timestamp: "2024-06-06T15:11:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "09", hdop: "0.7", lat: "0045.842609", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640434833333334, longitude: 127.37661065, timestamp: "2024-06-06T15:11:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842635", lon: "12722.596635", latD: "N", lonD: "E", latitude: 0.7640439166666667, longitude: 127.37661058333333, timestamp: "2024-06-06T15:11:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842635", lon: "12722.596635", latD: "N", lonD: "E", latitude: 0.7640439166666667, longitude: 127.37661058333333, timestamp: "2024-06-06T15:11:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "09", hdop: "0.7", lat: "0045.842609", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640434833333334, longitude: 127.37661065, timestamp: "2024-06-06T15:11:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "09", hdop: "0.7", lat: "0045.842609", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640434833333334, longitude: 127.37661065, timestamp: "2024-06-06T15:11:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842591", lon: "12722.596641", latD: "N", lonD: "E", latitude: 0.7640431833333333, longitude: 127.37661068333334, timestamp: "2024-06-06T15:11:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842591", lon: "12722.596641", latD: "N", lonD: "E", latitude: 0.7640431833333333, longitude: 127.37661068333334, timestamp: "2024-06-06T15:11:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842561", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7640426833333334, longitude: 127.37661086666667, timestamp: "2024-06-06T15:12:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842591", lon: "12722.596641", latD: "N", lonD: "E", latitude: 0.7640431833333333, longitude: 127.37661068333334, timestamp: "2024-06-06T15:11:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842561", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7640426833333334, longitude: 127.37661086666667, timestamp: "2024-06-06T15:12:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842591", lon: "12722.596641", latD: "N", lonD: "E", latitude: 0.7640431833333333, longitude: 127.37661068333334, timestamp: "2024-06-06T15:11:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842561", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7640426833333334, longitude: 127.37661086666667, timestamp: "2024-06-06T15:12:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842561", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7640426833333334, longitude: 127.37661086666667, timestamp: "2024-06-06T15:12:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842536", lon: "12722.596653", latD: "N", lonD: "E", latitude: 0.7640422666666667, longitude: 127.37661088333333, timestamp: "2024-06-06T15:12:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.842561", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7640426833333334, longitude: 127.37661086666667, timestamp: "2024-06-06T15:12:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842536", lon: "12722.596653", latD: "N", lonD: "E", latitude: 0.7640422666666667, longitude: 127.37661088333333, timestamp: "2024-06-06T15:12:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842536", lon: "12722.596653", latD: "N", lonD: "E", latitude: 0.7640422666666667, longitude: 127.37661088333333, timestamp: "2024-06-06T15:12:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842524", lon: "12722.596662", latD: "N", lonD: "E", latitude: 0.7640420666666666, longitude: 127.37661103333333, timestamp: "2024-06-06T15:12:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842524", lon: "12722.596662", latD: "N", lonD: "E", latitude: 0.7640420666666666, longitude: 127.37661103333333, timestamp: "2024-06-06T15:12:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842524", lon: "12722.596662", latD: "N", lonD: "E", latitude: 0.7640420666666666, longitude: 127.37661103333333, timestamp: "2024-06-06T15:12:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842509", lon: "12722.596666", latD: "N", lonD: "E", latitude: 0.7640418166666667, longitude: 127.3766111, timestamp: "2024-06-06T15:13:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842509", lon: "12722.596666", latD: "N", lonD: "E", latitude: 0.7640418166666667, longitude: 127.3766111, timestamp: "2024-06-06T15:13:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842524", lon: "12722.596662", latD: "N", lonD: "E", latitude: 0.7640420666666666, longitude: 127.37661103333333, timestamp: "2024-06-06T15:12:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842509", lon: "12722.596666", latD: "N", lonD: "E", latitude: 0.7640418166666667, longitude: 127.3766111, timestamp: "2024-06-06T15:13:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842509", lon: "12722.596666", latD: "N", lonD: "E", latitude: 0.7640418166666667, longitude: 127.3766111, timestamp: "2024-06-06T15:13:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842492", lon: "12722.596668", latD: "N", lonD: "E", latitude: 0.7640415333333334, longitude: 127.37661113333333, timestamp: "2024-06-06T15:13:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842492", lon: "12722.596668", latD: "N", lonD: "E", latitude: 0.7640415333333334, longitude: 127.37661113333333, timestamp: "2024-06-06T15:13:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842492", lon: "12722.596668", latD: "N", lonD: "E", latitude: 0.7640415333333334, longitude: 127.37661113333333, timestamp: "2024-06-06T15:13:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842479", lon: "12722.596661", latD: "N", lonD: "E", latitude: 0.7640413166666666, longitude: 127.37661101666667, timestamp: "2024-06-06T15:13:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842492", lon: "12722.596668", latD: "N", lonD: "E", latitude: 0.7640415333333334, longitude: 127.37661113333333, timestamp: "2024-06-06T15:13:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842492", lon: "12722.596668", latD: "N", lonD: "E", latitude: 0.7640415333333334, longitude: 127.37661113333333, timestamp: "2024-06-06T15:13:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842479", lon: "12722.596661", latD: "N", lonD: "E", latitude: 0.7640413166666666, longitude: 127.37661101666667, timestamp: "2024-06-06T15:13:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.842479", lon: "12722.596661", latD: "N", lonD: "E", latitude: 0.7640413166666666, longitude: 127.37661101666667, timestamp: "2024-06-06T15:13:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842455", lon: "12722.596680", latD: "N", lonD: "E", latitude: 0.7640409166666667, longitude: 127.37661133333333, timestamp: "2024-06-06T15:14:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842455", lon: "12722.596680", latD: "N", lonD: "E", latitude: 0.7640409166666667, longitude: 127.37661133333333, timestamp: "2024-06-06T15:14:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842455", lon: "12722.596680", latD: "N", lonD: "E", latitude: 0.7640409166666667, longitude: 127.37661133333333, timestamp: "2024-06-06T15:14:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842455", lon: "12722.596680", latD: "N", lonD: "E", latitude: 0.7640409166666667, longitude: 127.37661133333333, timestamp: "2024-06-06T15:14:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842441", lon: "12722.596692", latD: "N", lonD: "E", latitude: 0.7640406833333333, longitude: 127.37661153333333, timestamp: "2024-06-06T15:14:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842441", lon: "12722.596692", latD: "N", lonD: "E", latitude: 0.7640406833333333, longitude: 127.37661153333333, timestamp: "2024-06-06T15:14:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842441", lon: "12722.596692", latD: "N", lonD: "E", latitude: 0.7640406833333333, longitude: 127.37661153333333, timestamp: "2024-06-06T15:14:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842441", lon: "12722.596692", latD: "N", lonD: "E", latitude: 0.7640406833333333, longitude: 127.37661153333333, timestamp: "2024-06-06T15:14:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.842441", lon: "12722.596692", latD: "N", lonD: "E", latitude: 0.7640406833333333, longitude: 127.37661153333333, timestamp: "2024-06-06T15:14:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842410", lon: "12722.596704", latD: "N", lonD: "E", latitude: 0.7640401666666666, longitude: 127.37661173333333, timestamp: "2024-06-06T15:14:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842410", lon: "12722.596704", latD: "N", lonD: "E", latitude: 0.7640401666666666, longitude: 127.37661173333333, timestamp: "2024-06-06T15:14:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "09", hdop: "0.7", lat: "0045.842378", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640396333333332, longitude: 127.37661195, timestamp: "2024-06-06T15:15:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842410", lon: "12722.596704", latD: "N", lonD: "E", latitude: 0.7640401666666666, longitude: 127.37661173333333, timestamp: "2024-06-06T15:14:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842410", lon: "12722.596704", latD: "N", lonD: "E", latitude: 0.7640401666666666, longitude: 127.37661173333333, timestamp: "2024-06-06T15:14:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "09", hdop: "0.7", lat: "0045.842378", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640396333333332, longitude: 127.37661195, timestamp: "2024-06-06T15:15:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "09", hdop: "0.7", lat: "0045.842378", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640396333333332, longitude: 127.37661195, timestamp: "2024-06-06T15:15:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.842344", lon: "12722.596718", latD: "N", lonD: "E", latitude: 0.7640390666666667, longitude: 127.37661196666667, timestamp: "2024-06-06T15:15:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.842344", lon: "12722.596718", latD: "N", lonD: "E", latitude: 0.7640390666666667, longitude: 127.37661196666667, timestamp: "2024-06-06T15:15:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.842344", lon: "12722.596718", latD: "N", lonD: "E", latitude: 0.7640390666666667, longitude: 127.37661196666667, timestamp: "2024-06-06T15:15:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.842344", lon: "12722.596718", latD: "N", lonD: "E", latitude: 0.7640390666666667, longitude: 127.37661196666667, timestamp: "2024-06-06T15:15:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.842344", lon: "12722.596718", latD: "N", lonD: "E", latitude: 0.7640390666666667, longitude: 127.37661196666667, timestamp: "2024-06-06T15:15:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842291", lon: "12722.596697", latD: "N", lonD: "E", latitude: 0.7640381833333334, longitude: 127.37661161666666, timestamp: "2024-06-06T15:15:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842291", lon: "12722.596697", latD: "N", lonD: "E", latitude: 0.7640381833333334, longitude: 127.37661161666666, timestamp: "2024-06-06T15:15:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842250", lon: "12722.596695", latD: "N", lonD: "E", latitude: 0.7640375, longitude: 127.37661158333333, timestamp: "2024-06-06T15:16:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842250", lon: "12722.596695", latD: "N", lonD: "E", latitude: 0.7640375, longitude: 127.37661158333333, timestamp: "2024-06-06T15:16:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.2", satellites: "10", hdop: "0.6", lat: "0045.842291", lon: "12722.596697", latD: "N", lonD: "E", latitude: 0.7640381833333334, longitude: 127.37661161666666, timestamp: "2024-06-06T15:15:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842250", lon: "12722.596695", latD: "N", lonD: "E", latitude: 0.7640375, longitude: 127.37661158333333, timestamp: "2024-06-06T15:16:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842250", lon: "12722.596695", latD: "N", lonD: "E", latitude: 0.7640375, longitude: 127.37661158333333, timestamp: "2024-06-06T15:16:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842234", lon: "12722.596690", latD: "N", lonD: "E", latitude: 0.7640372333333333, longitude: 127.3766115, timestamp: "2024-06-06T15:16:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842234", lon: "12722.596690", latD: "N", lonD: "E", latitude: 0.7640372333333333, longitude: 127.3766115, timestamp: "2024-06-06T15:16:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842223", lon: "12722.596702", latD: "N", lonD: "E", latitude: 0.76403705, longitude: 127.3766117, timestamp: "2024-06-06T15:16:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842223", lon: "12722.596702", latD: "N", lonD: "E", latitude: 0.76403705, longitude: 127.3766117, timestamp: "2024-06-06T15:16:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842234", lon: "12722.596690", latD: "N", lonD: "E", latitude: 0.7640372333333333, longitude: 127.3766115, timestamp: "2024-06-06T15:16:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842234", lon: "12722.596690", latD: "N", lonD: "E", latitude: 0.7640372333333333, longitude: 127.3766115, timestamp: "2024-06-06T15:16:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842223", lon: "12722.596702", latD: "N", lonD: "E", latitude: 0.76403705, longitude: 127.3766117, timestamp: "2024-06-06T15:16:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842223", lon: "12722.596702", latD: "N", lonD: "E", latitude: 0.76403705, longitude: 127.3766117, timestamp: "2024-06-06T15:16:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.842223", lon: "12722.596702", latD: "N", lonD: "E", latitude: 0.76403705, longitude: 127.3766117, timestamp: "2024-06-06T15:16:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.842207", lon: "12722.596713", latD: "N", lonD: "E", latitude: 0.7640367833333334, longitude: 127.37661188333334, timestamp: "2024-06-06T15:17:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.842207", lon: "12722.596713", latD: "N", lonD: "E", latitude: 0.7640367833333334, longitude: 127.37661188333334, timestamp: "2024-06-06T15:17:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.842207", lon: "12722.596713", latD: "N", lonD: "E", latitude: 0.7640367833333334, longitude: 127.37661188333334, timestamp: "2024-06-06T15:17:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "08", hdop: "0.7", lat: "0045.842196", lon: "12722.596725", latD: "N", lonD: "E", latitude: 0.7640366000000001, longitude: 127.37661208333333, timestamp: "2024-06-06T15:17:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "08", hdop: "0.7", lat: "0045.842196", lon: "12722.596725", latD: "N", lonD: "E", latitude: 0.7640366000000001, longitude: 127.37661208333333, timestamp: "2024-06-06T15:17:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "08", hdop: "0.7", lat: "0045.842196", lon: "12722.596725", latD: "N", lonD: "E", latitude: 0.7640366000000001, longitude: 127.37661208333333, timestamp: "2024-06-06T15:17:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "08", hdop: "0.7", lat: "0045.842182", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640363666666666, longitude: 127.3766122, timestamp: "2024-06-06T15:17:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "08", hdop: "0.7", lat: "0045.842182", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640363666666666, longitude: 127.3766122, timestamp: "2024-06-06T15:17:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "08", hdop: "0.7", lat: "0045.842196", lon: "12722.596725", latD: "N", lonD: "E", latitude: 0.7640366000000001, longitude: 127.37661208333333, timestamp: "2024-06-06T15:17:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "08", hdop: "0.7", lat: "0045.842182", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640363666666666, longitude: 127.3766122, timestamp: "2024-06-06T15:17:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "08", hdop: "0.7", lat: "0045.842182", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640363666666666, longitude: 127.3766122, timestamp: "2024-06-06T15:17:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "08", hdop: "0.7", lat: "0045.842182", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640363666666666, longitude: 127.3766122, timestamp: "2024-06-06T15:17:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842152", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640358666666667, longitude: 127.3766122, timestamp: "2024-06-06T15:18:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842152", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640358666666667, longitude: 127.3766122, timestamp: "2024-06-06T15:18:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842129", lon: "12722.596739", latD: "N", lonD: "E", latitude: 0.7640354833333334, longitude: 127.37661231666667, timestamp: "2024-06-06T15:18:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842152", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640358666666667, longitude: 127.3766122, timestamp: "2024-06-06T15:18:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842152", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640358666666667, longitude: 127.3766122, timestamp: "2024-06-06T15:18:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842129", lon: "12722.596739", latD: "N", lonD: "E", latitude: 0.7640354833333334, longitude: 127.37661231666667, timestamp: "2024-06-06T15:18:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842129", lon: "12722.596739", latD: "N", lonD: "E", latitude: 0.7640354833333334, longitude: 127.37661231666667, timestamp: "2024-06-06T15:18:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842109", lon: "12722.596753", latD: "N", lonD: "E", latitude: 0.76403515, longitude: 127.37661255, timestamp: "2024-06-06T15:18:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842109", lon: "12722.596753", latD: "N", lonD: "E", latitude: 0.76403515, longitude: 127.37661255, timestamp: "2024-06-06T15:18:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842109", lon: "12722.596753", latD: "N", lonD: "E", latitude: 0.76403515, longitude: 127.37661255, timestamp: "2024-06-06T15:18:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842109", lon: "12722.596753", latD: "N", lonD: "E", latitude: 0.76403515, longitude: 127.37661255, timestamp: "2024-06-06T15:18:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842109", lon: "12722.596753", latD: "N", lonD: "E", latitude: 0.76403515, longitude: 127.37661255, timestamp: "2024-06-06T15:18:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842105", lon: "12722.596768", latD: "N", lonD: "E", latitude: 0.7640350833333333, longitude: 127.3766128, timestamp: "2024-06-06T15:19:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842105", lon: "12722.596768", latD: "N", lonD: "E", latitude: 0.7640350833333333, longitude: 127.3766128, timestamp: "2024-06-06T15:19:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.7", lat: "0045.842103", lon: "12722.596766", latD: "N", lonD: "E", latitude: 0.76403505, longitude: 127.37661276666667, timestamp: "2024-06-06T15:19:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.7", lat: "0045.842103", lon: "12722.596766", latD: "N", lonD: "E", latitude: 0.76403505, longitude: 127.37661276666667, timestamp: "2024-06-06T15:19:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842105", lon: "12722.596768", latD: "N", lonD: "E", latitude: 0.7640350833333333, longitude: 127.3766128, timestamp: "2024-06-06T15:19:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.7", lat: "0045.842103", lon: "12722.596766", latD: "N", lonD: "E", latitude: 0.76403505, longitude: 127.37661276666667, timestamp: "2024-06-06T15:19:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.7", lat: "0045.842103", lon: "12722.596766", latD: "N", lonD: "E", latitude: 0.76403505, longitude: 127.37661276666667, timestamp: "2024-06-06T15:19:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.7", lat: "0045.842103", lon: "12722.596766", latD: "N", lonD: "E", latitude: 0.76403505, longitude: 127.37661276666667, timestamp: "2024-06-06T15:19:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.7", lat: "0045.842086", lon: "12722.596742", latD: "N", lonD: "E", latitude: 0.7640347666666667, longitude: 127.37661236666666, timestamp: "2024-06-06T15:19:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.7", lat: "0045.842086", lon: "12722.596742", latD: "N", lonD: "E", latitude: 0.7640347666666667, longitude: 127.37661236666666, timestamp: "2024-06-06T15:19:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "08", hdop: "0.7", lat: "0045.842081", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640346833333334, longitude: 127.3766122, timestamp: "2024-06-06T15:20:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "08", hdop: "0.7", lat: "0045.842081", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640346833333334, longitude: 127.3766122, timestamp: "2024-06-06T15:20:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.1", satellites: "09", hdop: "0.7", lat: "0045.842086", lon: "12722.596742", latD: "N", lonD: "E", latitude: 0.7640347666666667, longitude: 127.37661236666666, timestamp: "2024-06-06T15:19:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "08", hdop: "0.7", lat: "0045.842081", lon: "12722.596732", latD: "N", lonD: "E", latitude: 0.7640346833333334, longitude: 127.3766122, timestamp: "2024-06-06T15:20:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "08", hdop: "0.7", lat: "0045.842071", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640345166666667, longitude: 127.37661195, timestamp: "2024-06-06T15:20:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "08", hdop: "0.7", lat: "0045.842071", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640345166666667, longitude: 127.37661195, timestamp: "2024-06-06T15:20:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "08", hdop: "0.7", lat: "0045.842071", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640345166666667, longitude: 127.37661195, timestamp: "2024-06-06T15:20:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "08", hdop: "0.7", lat: "0045.842071", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640345166666667, longitude: 127.37661195, timestamp: "2024-06-06T15:20:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "08", hdop: "0.7", lat: "0045.842071", lon: "12722.596717", latD: "N", lonD: "E", latitude: 0.7640345166666667, longitude: 127.37661195, timestamp: "2024-06-06T15:20:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842058", lon: "12722.596720", latD: "N", lonD: "E", latitude: 0.7640343, longitude: 127.376612, timestamp: "2024-06-06T15:20:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842058", lon: "12722.596720", latD: "N", lonD: "E", latitude: 0.7640343, longitude: 127.376612, timestamp: "2024-06-06T15:20:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842058", lon: "12722.596720", latD: "N", lonD: "E", latitude: 0.7640343, longitude: 127.376612, timestamp: "2024-06-06T15:20:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.6", lat: "0045.842058", lon: "12722.596720", latD: "N", lonD: "E", latitude: 0.7640343, longitude: 127.376612, timestamp: "2024-06-06T15:20:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842049", lon: "12722.596707", latD: "N", lonD: "E", latitude: 0.7640341500000001, longitude: 127.37661178333333, timestamp: "2024-06-06T15:21:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842049", lon: "12722.596707", latD: "N", lonD: "E", latitude: 0.7640341500000001, longitude: 127.37661178333333, timestamp: "2024-06-06T15:21:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842037", lon: "12722.596702", latD: "N", lonD: "E", latitude: 0.76403395, longitude: 127.3766117, timestamp: "2024-06-06T15:21:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842049", lon: "12722.596707", latD: "N", lonD: "E", latitude: 0.7640341500000001, longitude: 127.37661178333333, timestamp: "2024-06-06T15:21:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842049", lon: "12722.596707", latD: "N", lonD: "E", latitude: 0.7640341500000001, longitude: 127.37661178333333, timestamp: "2024-06-06T15:21:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842037", lon: "12722.596702", latD: "N", lonD: "E", latitude: 0.76403395, longitude: 127.3766117, timestamp: "2024-06-06T15:21:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842037", lon: "12722.596702", latD: "N", lonD: "E", latitude: 0.76403395, longitude: 127.3766117, timestamp: "2024-06-06T15:21:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842037", lon: "12722.596702", latD: "N", lonD: "E", latitude: 0.76403395, longitude: 127.3766117, timestamp: "2024-06-06T15:21:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.842031", lon: "12722.596694", latD: "N", lonD: "E", latitude: 0.76403385, longitude: 127.37661156666667, timestamp: "2024-06-06T15:21:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "8.0", satellites: "09", hdop: "0.7", lat: "0045.842037", lon: "12722.596702", latD: "N", lonD: "E", latitude: 0.76403395, longitude: 127.3766117, timestamp: "2024-06-06T15:21:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.842031", lon: "12722.596694", latD: "N", lonD: "E", latitude: 0.76403385, longitude: 127.37661156666667, timestamp: "2024-06-06T15:21:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.842031", lon: "12722.596694", latD: "N", lonD: "E", latitude: 0.76403385, longitude: 127.37661156666667, timestamp: "2024-06-06T15:21:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.842031", lon: "12722.596694", latD: "N", lonD: "E", latitude: 0.76403385, longitude: 127.37661156666667, timestamp: "2024-06-06T15:21:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.842018", lon: "12722.596693", latD: "N", lonD: "E", latitude: 0.7640336333333334, longitude: 127.37661155, timestamp: "2024-06-06T15:22:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.842018", lon: "12722.596693", latD: "N", lonD: "E", latitude: 0.7640336333333334, longitude: 127.37661155, timestamp: "2024-06-06T15:22:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.842018", lon: "12722.596693", latD: "N", lonD: "E", latitude: 0.7640336333333334, longitude: 127.37661155, timestamp: "2024-06-06T15:22:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "6.2", satellites: "09", hdop: "0.6", lat: "0045.841996", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7640332666666667, longitude: 127.37661136666667, timestamp: "2024-06-06T15:22:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "6.2", satellites: "09", hdop: "0.6", lat: "0045.841996", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7640332666666667, longitude: 127.37661136666667, timestamp: "2024-06-06T15:22:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "6.2", satellites: "09", hdop: "0.6", lat: "0045.841996", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7640332666666667, longitude: 127.37661136666667, timestamp: "2024-06-06T15:22:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841978", lon: "12722.596673", latD: "N", lonD: "E", latitude: 0.7640329666666666, longitude: 127.37661121666666, timestamp: "2024-06-06T15:22:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "6.2", satellites: "09", hdop: "0.6", lat: "0045.841996", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7640332666666667, longitude: 127.37661136666667, timestamp: "2024-06-06T15:22:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "6.2", satellites: "09", hdop: "0.6", lat: "0045.841996", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7640332666666667, longitude: 127.37661136666667, timestamp: "2024-06-06T15:22:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841978", lon: "12722.596673", latD: "N", lonD: "E", latitude: 0.7640329666666666, longitude: 127.37661121666666, timestamp: "2024-06-06T15:22:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841978", lon: "12722.596673", latD: "N", lonD: "E", latitude: 0.7640329666666666, longitude: 127.37661121666666, timestamp: "2024-06-06T15:22:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841958", lon: "12722.596691", latD: "N", lonD: "E", latitude: 0.7640326333333333, longitude: 127.37661151666667, timestamp: "2024-06-06T15:23:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841958", lon: "12722.596691", latD: "N", lonD: "E", latitude: 0.7640326333333333, longitude: 127.37661151666667, timestamp: "2024-06-06T15:23:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841958", lon: "12722.596691", latD: "N", lonD: "E", latitude: 0.7640326333333333, longitude: 127.37661151666667, timestamp: "2024-06-06T15:23:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841947", lon: "12722.596694", latD: "N", lonD: "E", latitude: 0.76403245, longitude: 127.37661156666667, timestamp: "2024-06-06T15:23:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841958", lon: "12722.596691", latD: "N", lonD: "E", latitude: 0.7640326333333333, longitude: 127.37661151666667, timestamp: "2024-06-06T15:23:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841958", lon: "12722.596691", latD: "N", lonD: "E", latitude: 0.7640326333333333, longitude: 127.37661151666667, timestamp: "2024-06-06T15:23:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841947", lon: "12722.596694", latD: "N", lonD: "E", latitude: 0.76403245, longitude: 127.37661156666667, timestamp: "2024-06-06T15:23:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841947", lon: "12722.596694", latD: "N", lonD: "E", latitude: 0.76403245, longitude: 127.37661156666667, timestamp: "2024-06-06T15:23:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841931", lon: "12722.596698", latD: "N", lonD: "E", latitude: 0.7640321833333333, longitude: 127.37661163333334, timestamp: "2024-06-06T15:23:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841931", lon: "12722.596698", latD: "N", lonD: "E", latitude: 0.7640321833333333, longitude: 127.37661163333334, timestamp: "2024-06-06T15:23:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841931", lon: "12722.596698", latD: "N", lonD: "E", latitude: 0.7640321833333333, longitude: 127.37661163333334, timestamp: "2024-06-06T15:23:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841931", lon: "12722.596698", latD: "N", lonD: "E", latitude: 0.7640321833333333, longitude: 127.37661163333334, timestamp: "2024-06-06T15:23:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841931", lon: "12722.596698", latD: "N", lonD: "E", latitude: 0.7640321833333333, longitude: 127.37661163333334, timestamp: "2024-06-06T15:23:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841916", lon: "12722.596687", latD: "N", lonD: "E", latitude: 0.7640319333333333, longitude: 127.37661145, timestamp: "2024-06-06T15:24:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841916", lon: "12722.596687", latD: "N", lonD: "E", latitude: 0.7640319333333333, longitude: 127.37661145, timestamp: "2024-06-06T15:24:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841916", lon: "12722.596687", latD: "N", lonD: "E", latitude: 0.7640319333333333, longitude: 127.37661145, timestamp: "2024-06-06T15:24:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841916", lon: "12722.596687", latD: "N", lonD: "E", latitude: 0.7640319333333333, longitude: 127.37661145, timestamp: "2024-06-06T15:24:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841914", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.7640319000000001, longitude: 127.37661128333333, timestamp: "2024-06-06T15:24:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "09", hdop: "0.7", lat: "0045.841908", lon: "12722.596679", latD: "N", lonD: "E", latitude: 0.7640317999999999, longitude: 127.37661131666667, timestamp: "2024-06-06T15:24:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841914", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.7640319000000001, longitude: 127.37661128333333, timestamp: "2024-06-06T15:24:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.841914", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.7640319000000001, longitude: 127.37661128333333, timestamp: "2024-06-06T15:24:38.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "09", hdop: "0.7", lat: "0045.841908", lon: "12722.596679", latD: "N", lonD: "E", latitude: 0.7640317999999999, longitude: 127.37661131666667, timestamp: "2024-06-06T15:24:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "09", hdop: "0.7", lat: "0045.841908", lon: "12722.596679", latD: "N", lonD: "E", latitude: 0.7640317999999999, longitude: 127.37661131666667, timestamp: "2024-06-06T15:24:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "09", hdop: "0.7", lat: "0045.841908", lon: "12722.596679", latD: "N", lonD: "E", latitude: 0.7640317999999999, longitude: 127.37661131666667, timestamp: "2024-06-06T15:24:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.841895", lon: "12722.596681", latD: "N", lonD: "E", latitude: 0.7640315833333333, longitude: 127.37661135, timestamp: "2024-06-06T15:25:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.7", satellites: "09", hdop: "0.7", lat: "0045.841908", lon: "12722.596679", latD: "N", lonD: "E", latitude: 0.7640317999999999, longitude: 127.37661131666667, timestamp: "2024-06-06T15:24:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.841895", lon: "12722.596681", latD: "N", lonD: "E", latitude: 0.7640315833333333, longitude: 127.37661135, timestamp: "2024-06-06T15:25:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.841895", lon: "12722.596681", latD: "N", lonD: "E", latitude: 0.7640315833333333, longitude: 127.37661135, timestamp: "2024-06-06T15:25:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.841895", lon: "12722.596681", latD: "N", lonD: "E", latitude: 0.7640315833333333, longitude: 127.37661135, timestamp: "2024-06-06T15:25:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.841883", lon: "12722.596697", latD: "N", lonD: "E", latitude: 0.7640313833333334, longitude: 127.37661161666666, timestamp: "2024-06-06T15:25:39.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.841883", lon: "12722.596697", latD: "N", lonD: "E", latitude: 0.7640313833333334, longitude: 127.37661161666666, timestamp: "2024-06-06T15:25:39.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.841861", lon: "12722.596688", latD: "N", lonD: "E", latitude: 0.7640310166666667, longitude: 127.37661146666667, timestamp: "2024-06-06T15:25:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.841861", lon: "12722.596688", latD: "N", lonD: "E", latitude: 0.7640310166666667, longitude: 127.37661146666667, timestamp: "2024-06-06T15:25:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.841883", lon: "12722.596697", latD: "N", lonD: "E", latitude: 0.7640313833333334, longitude: 127.37661161666666, timestamp: "2024-06-06T15:25:39.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.841861", lon: "12722.596688", latD: "N", lonD: "E", latitude: 0.7640310166666667, longitude: 127.37661146666667, timestamp: "2024-06-06T15:25:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.841861", lon: "12722.596688", latD: "N", lonD: "E", latitude: 0.7640310166666667, longitude: 127.37661146666667, timestamp: "2024-06-06T15:25:58.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.841846", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7640307666666666, longitude: 127.37661136666667, timestamp: "2024-06-06T15:26:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.841846", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7640307666666666, longitude: 127.37661136666667, timestamp: "2024-06-06T15:26:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.841846", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7640307666666666, longitude: 127.37661136666667, timestamp: "2024-06-06T15:26:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.841827", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.76403045, longitude: 127.37661128333333, timestamp: "2024-06-06T15:26:39.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.841827", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.76403045, longitude: 127.37661128333333, timestamp: "2024-06-06T15:26:39.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.841846", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7640307666666666, longitude: 127.37661136666667, timestamp: "2024-06-06T15:26:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.841827", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.76403045, longitude: 127.37661128333333, timestamp: "2024-06-06T15:26:39.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.841827", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.76403045, longitude: 127.37661128333333, timestamp: "2024-06-06T15:26:39.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.4", satellites: "09", hdop: "0.6", lat: "0045.841807", lon: "12722.596683", latD: "N", lonD: "E", latitude: 0.7640301166666668, longitude: 127.37661138333333, timestamp: "2024-06-06T15:26:59.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.4", satellites: "09", hdop: "0.6", lat: "0045.841807", lon: "12722.596683", latD: "N", lonD: "E", latitude: 0.7640301166666668, longitude: 127.37661138333333, timestamp: "2024-06-06T15:26:59.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.4", satellites: "09", hdop: "0.6", lat: "0045.841807", lon: "12722.596683", latD: "N", lonD: "E", latitude: 0.7640301166666668, longitude: 127.37661138333333, timestamp: "2024-06-06T15:26:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "8.2", satellites: "10", hdop: "0.5", lat: "0045.840813", lon: "12722.596125", latD: "N", lonD: "E", latitude: 0.7640135499999999, longitude: 127.37660208333334, timestamp: "2024-06-06T15:27:18.000Z" },
        { speed: "0.0", course: "8.9", altitude: "8.2", satellites: "10", hdop: "0.5", lat: "0045.840813", lon: "12722.596125", latD: "N", lonD: "E", latitude: 0.7640135499999999, longitude: 127.37660208333334, timestamp: "2024-06-06T15:27:18.000Z" },
        { speed: "0.0", course: "271.1", altitude: "7.4", satellites: "09", hdop: "0.6", lat: "0045.841807", lon: "12722.596683", latD: "N", lonD: "E", latitude: 0.7640301166666668, longitude: 127.37661138333333, timestamp: "2024-06-06T15:26:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "8.2", satellites: "10", hdop: "0.5", lat: "0045.840813", lon: "12722.596125", latD: "N", lonD: "E", latitude: 0.7640135499999999, longitude: 127.37660208333334, timestamp: "2024-06-06T15:27:18.000Z" },
        { speed: "0.0", course: "8.9", altitude: "8.2", satellites: "10", hdop: "0.5", lat: "0045.840813", lon: "12722.596125", latD: "N", lonD: "E", latitude: 0.7640135499999999, longitude: 127.37660208333334, timestamp: "2024-06-06T15:27:18.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.840182", lon: "12722.596628", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37661046666666, timestamp: "2024-06-06T15:27:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.840182", lon: "12722.596628", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37661046666666, timestamp: "2024-06-06T15:27:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "10", hdop: "0.7", lat: "0045.840163", lon: "12722.596614", latD: "N", lonD: "E", latitude: 0.7640027166666666, longitude: 127.37661023333334, timestamp: "2024-06-06T15:27:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.840182", lon: "12722.596628", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37661046666666, timestamp: "2024-06-06T15:27:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.840182", lon: "12722.596628", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37661046666666, timestamp: "2024-06-06T15:27:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "10", hdop: "0.7", lat: "0045.840163", lon: "12722.596614", latD: "N", lonD: "E", latitude: 0.7640027166666666, longitude: 127.37661023333334, timestamp: "2024-06-06T15:27:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "10", hdop: "0.7", lat: "0045.840163", lon: "12722.596614", latD: "N", lonD: "E", latitude: 0.7640027166666666, longitude: 127.37661023333334, timestamp: "2024-06-06T15:27:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "10", hdop: "0.7", lat: "0045.840163", lon: "12722.596614", latD: "N", lonD: "E", latitude: 0.7640027166666666, longitude: 127.37661023333334, timestamp: "2024-06-06T15:27:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.4", satellites: "09", hdop: "0.6", lat: "0045.840157", lon: "12722.596606", latD: "N", lonD: "E", latitude: 0.7640026166666666, longitude: 127.3766101, timestamp: "2024-06-06T15:28:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.4", satellites: "09", hdop: "0.6", lat: "0045.840157", lon: "12722.596606", latD: "N", lonD: "E", latitude: 0.7640026166666666, longitude: 127.3766101, timestamp: "2024-06-06T15:28:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.4", satellites: "09", hdop: "0.6", lat: "0045.840157", lon: "12722.596606", latD: "N", lonD: "E", latitude: 0.7640026166666666, longitude: 127.3766101, timestamp: "2024-06-06T15:28:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.4", satellites: "09", hdop: "0.6", lat: "0045.840157", lon: "12722.596606", latD: "N", lonD: "E", latitude: 0.7640026166666666, longitude: 127.3766101, timestamp: "2024-06-06T15:28:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.840168", lon: "12722.596601", latD: "N", lonD: "E", latitude: 0.7640028, longitude: 127.37661001666666, timestamp: "2024-06-06T15:28:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.840168", lon: "12722.596601", latD: "N", lonD: "E", latitude: 0.7640028, longitude: 127.37661001666666, timestamp: "2024-06-06T15:28:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.840168", lon: "12722.596601", latD: "N", lonD: "E", latitude: 0.7640028, longitude: 127.37661001666666, timestamp: "2024-06-06T15:28:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.840164", lon: "12722.596597", latD: "N", lonD: "E", latitude: 0.7640027333333334, longitude: 127.37660995, timestamp: "2024-06-06T15:28:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.840164", lon: "12722.596597", latD: "N", lonD: "E", latitude: 0.7640027333333334, longitude: 127.37660995, timestamp: "2024-06-06T15:28:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.840168", lon: "12722.596601", latD: "N", lonD: "E", latitude: 0.7640028, longitude: 127.37661001666666, timestamp: "2024-06-06T15:28:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.840164", lon: "12722.596597", latD: "N", lonD: "E", latitude: 0.7640027333333334, longitude: 127.37660995, timestamp: "2024-06-06T15:28:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.840164", lon: "12722.596597", latD: "N", lonD: "E", latitude: 0.7640027333333334, longitude: 127.37660995, timestamp: "2024-06-06T15:28:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.840182", lon: "12722.596605", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37661008333333, timestamp: "2024-06-06T15:29:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.840182", lon: "12722.596605", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37661008333333, timestamp: "2024-06-06T15:29:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.840182", lon: "12722.596605", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37661008333333, timestamp: "2024-06-06T15:29:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.840189", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7640031500000001, longitude: 127.37661034999999, timestamp: "2024-06-06T15:29:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.840189", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7640031500000001, longitude: 127.37661034999999, timestamp: "2024-06-06T15:29:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.840182", lon: "12722.596605", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37661008333333, timestamp: "2024-06-06T15:29:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.840189", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7640031500000001, longitude: 127.37661034999999, timestamp: "2024-06-06T15:29:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.840189", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7640031500000001, longitude: 127.37661034999999, timestamp: "2024-06-06T15:29:39.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.840196", lon: "12722.596623", latD: "N", lonD: "E", latitude: 0.7640032666666666, longitude: 127.37661038333333, timestamp: "2024-06-06T15:29:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.840196", lon: "12722.596623", latD: "N", lonD: "E", latitude: 0.7640032666666666, longitude: 127.37661038333333, timestamp: "2024-06-06T15:29:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.840196", lon: "12722.596623", latD: "N", lonD: "E", latitude: 0.7640032666666666, longitude: 127.37661038333333, timestamp: "2024-06-06T15:29:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.840196", lon: "12722.596623", latD: "N", lonD: "E", latitude: 0.7640032666666666, longitude: 127.37661038333333, timestamp: "2024-06-06T15:29:59.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.6", satellites: "10", hdop: "0.5", lat: "0045.840152", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640025333333333, longitude: 127.37661065, timestamp: "2024-06-06T15:30:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.6", satellites: "10", hdop: "0.5", lat: "0045.840152", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640025333333333, longitude: 127.37661065, timestamp: "2024-06-06T15:30:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.6", satellites: "10", hdop: "0.5", lat: "0045.840152", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640025333333333, longitude: 127.37661065, timestamp: "2024-06-06T15:30:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.6", satellites: "10", hdop: "0.5", lat: "0045.840152", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640025333333333, longitude: 127.37661065, timestamp: "2024-06-06T15:30:19.000Z" },
        { speed: "0.0", course: "8.9", altitude: "6.6", satellites: "10", hdop: "0.5", lat: "0045.840152", lon: "12722.596639", latD: "N", lonD: "E", latitude: 0.7640025333333333, longitude: 127.37661065, timestamp: "2024-06-06T15:30:19.000Z" },
        { speed: "0.0", course: "262.6", altitude: "8.4", satellites: "10", hdop: "0.5", lat: "0045.840584", lon: "12722.596853", latD: "N", lonD: "E", latitude: 0.7640097333333333, longitude: 127.37661421666667, timestamp: "2024-06-06T15:30:39.000Z" },
        { speed: "0.0", course: "102.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.840408", lon: "12722.596363", latD: "N", lonD: "E", latitude: 0.7640068, longitude: 127.37660605, timestamp: "2024-06-06T15:30:59.000Z" },
        { speed: "0.0", course: "262.6", altitude: "8.4", satellites: "10", hdop: "0.5", lat: "0045.840584", lon: "12722.596853", latD: "N", lonD: "E", latitude: 0.7640097333333333, longitude: 127.37661421666667, timestamp: "2024-06-06T15:30:39.000Z" },
        { speed: "0.0", course: "262.6", altitude: "8.4", satellites: "10", hdop: "0.5", lat: "0045.840584", lon: "12722.596853", latD: "N", lonD: "E", latitude: 0.7640097333333333, longitude: 127.37661421666667, timestamp: "2024-06-06T15:30:39.000Z" },
        { speed: "0.0", course: "262.6", altitude: "8.4", satellites: "10", hdop: "0.5", lat: "0045.840584", lon: "12722.596853", latD: "N", lonD: "E", latitude: 0.7640097333333333, longitude: 127.37661421666667, timestamp: "2024-06-06T15:30:39.000Z" },
        { speed: "0.0", course: "102.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.840408", lon: "12722.596363", latD: "N", lonD: "E", latitude: 0.7640068, longitude: 127.37660605, timestamp: "2024-06-06T15:30:59.000Z" },
        { speed: "0.0", course: "102.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.840408", lon: "12722.596363", latD: "N", lonD: "E", latitude: 0.7640068, longitude: 127.37660605, timestamp: "2024-06-06T15:30:59.000Z" },
        { speed: "0.0", course: "126.9", altitude: "5.9", satellites: "10", hdop: "0.6", lat: "0045.839965", lon: "12722.596562", latD: "N", lonD: "E", latitude: 0.7639994166666667, longitude: 127.37660936666667, timestamp: "2024-06-06T15:31:19.000Z" },
        { speed: "0.0", course: "102.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.840408", lon: "12722.596363", latD: "N", lonD: "E", latitude: 0.7640068, longitude: 127.37660605, timestamp: "2024-06-06T15:30:59.000Z" },
        { speed: "0.0", course: "126.9", altitude: "5.9", satellites: "10", hdop: "0.6", lat: "0045.839965", lon: "12722.596562", latD: "N", lonD: "E", latitude: 0.7639994166666667, longitude: 127.37660936666667, timestamp: "2024-06-06T15:31:19.000Z" },
        { speed: "0.0", course: "126.9", altitude: "5.9", satellites: "10", hdop: "0.6", lat: "0045.839965", lon: "12722.596562", latD: "N", lonD: "E", latitude: 0.7639994166666667, longitude: 127.37660936666667, timestamp: "2024-06-06T15:31:19.000Z" },
        { speed: "0.0", course: "126.9", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.839968", lon: "12722.596606", latD: "N", lonD: "E", latitude: 0.7639994666666666, longitude: 127.3766101, timestamp: "2024-06-06T15:31:39.000Z" },
        { speed: "0.0", course: "126.9", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.839968", lon: "12722.596606", latD: "N", lonD: "E", latitude: 0.7639994666666666, longitude: 127.3766101, timestamp: "2024-06-06T15:31:39.000Z" },
        { speed: "0.0", course: "126.9", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.839968", lon: "12722.596606", latD: "N", lonD: "E", latitude: 0.7639994666666666, longitude: 127.3766101, timestamp: "2024-06-06T15:31:39.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.9", satellites: "10", hdop: "0.5", lat: "0045.840343", lon: "12722.595437", latD: "N", lonD: "E", latitude: 0.7640057166666666, longitude: 127.37659061666666, timestamp: "2024-06-06T15:31:59.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.9", satellites: "10", hdop: "0.5", lat: "0045.840343", lon: "12722.595437", latD: "N", lonD: "E", latitude: 0.7640057166666666, longitude: 127.37659061666666, timestamp: "2024-06-06T15:31:59.000Z" },
        { speed: "0.0", course: "126.9", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.839968", lon: "12722.596606", latD: "N", lonD: "E", latitude: 0.7639994666666666, longitude: 127.3766101, timestamp: "2024-06-06T15:31:39.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.9", satellites: "10", hdop: "0.5", lat: "0045.840343", lon: "12722.595437", latD: "N", lonD: "E", latitude: 0.7640057166666666, longitude: 127.37659061666666, timestamp: "2024-06-06T15:31:59.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.9", satellites: "10", hdop: "0.5", lat: "0045.840343", lon: "12722.595437", latD: "N", lonD: "E", latitude: 0.7640057166666666, longitude: 127.37659061666666, timestamp: "2024-06-06T15:31:59.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.9", satellites: "10", hdop: "0.5", lat: "0045.840343", lon: "12722.595437", latD: "N", lonD: "E", latitude: 0.7640057166666666, longitude: 127.37659061666666, timestamp: "2024-06-06T15:31:59.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.0", satellites: "10", hdop: "0.6", lat: "0045.840369", lon: "12722.595797", latD: "N", lonD: "E", latitude: 0.76400615, longitude: 127.37659661666666, timestamp: "2024-06-06T15:32:19.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.7", satellites: "10", hdop: "0.5", lat: "0045.840556", lon: "12722.595912", latD: "N", lonD: "E", latitude: 0.7640092666666667, longitude: 127.37659853333334, timestamp: "2024-06-06T15:32:39.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.0", satellites: "10", hdop: "0.6", lat: "0045.840369", lon: "12722.595797", latD: "N", lonD: "E", latitude: 0.76400615, longitude: 127.37659661666666, timestamp: "2024-06-06T15:32:19.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.0", satellites: "10", hdop: "0.6", lat: "0045.840369", lon: "12722.595797", latD: "N", lonD: "E", latitude: 0.76400615, longitude: 127.37659661666666, timestamp: "2024-06-06T15:32:19.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.7", satellites: "10", hdop: "0.5", lat: "0045.840556", lon: "12722.595912", latD: "N", lonD: "E", latitude: 0.7640092666666667, longitude: 127.37659853333334, timestamp: "2024-06-06T15:32:39.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.7", satellites: "10", hdop: "0.5", lat: "0045.840556", lon: "12722.595912", latD: "N", lonD: "E", latitude: 0.7640092666666667, longitude: 127.37659853333334, timestamp: "2024-06-06T15:32:39.000Z" },
        { speed: "0.0", course: "132.6", altitude: "4.7", satellites: "10", hdop: "0.5", lat: "0045.840556", lon: "12722.595912", latD: "N", lonD: "E", latitude: 0.7640092666666667, longitude: 127.37659853333334, timestamp: "2024-06-06T15:32:39.000Z" },
        { speed: "0.0", course: "171.6", altitude: "2.5", satellites: "10", hdop: "0.5", lat: "0045.840302", lon: "12722.597366", latD: "N", lonD: "E", latitude: 0.7640050333333334, longitude: 127.37662276666667, timestamp: "2024-06-06T15:32:59.000Z" },
        { speed: "0.0", course: "171.6", altitude: "2.5", satellites: "10", hdop: "0.5", lat: "0045.840302", lon: "12722.597366", latD: "N", lonD: "E", latitude: 0.7640050333333334, longitude: 127.37662276666667, timestamp: "2024-06-06T15:32:59.000Z" },
        { speed: "0.0", course: "135.6", altitude: "0.7", satellites: "09", hdop: "0.6", lat: "0045.840272", lon: "12722.597604", latD: "N", lonD: "E", latitude: 0.7640045333333333, longitude: 127.37662673333334, timestamp: "2024-06-06T15:33:19.000Z" },
        { speed: "0.0", course: "135.6", altitude: "0.7", satellites: "09", hdop: "0.6", lat: "0045.840272", lon: "12722.597604", latD: "N", lonD: "E", latitude: 0.7640045333333333, longitude: 127.37662673333334, timestamp: "2024-06-06T15:33:19.000Z" },
        { speed: "0.0", course: "171.6", altitude: "2.5", satellites: "10", hdop: "0.5", lat: "0045.840302", lon: "12722.597366", latD: "N", lonD: "E", latitude: 0.7640050333333334, longitude: 127.37662276666667, timestamp: "2024-06-06T15:32:59.000Z" },
        { speed: "0.0", course: "171.6", altitude: "2.5", satellites: "10", hdop: "0.5", lat: "0045.840302", lon: "12722.597366", latD: "N", lonD: "E", latitude: 0.7640050333333334, longitude: 127.37662276666667, timestamp: "2024-06-06T15:32:59.000Z" },
        { speed: "0.0", course: "135.6", altitude: "0.7", satellites: "09", hdop: "0.6", lat: "0045.840272", lon: "12722.597604", latD: "N", lonD: "E", latitude: 0.7640045333333333, longitude: 127.37662673333334, timestamp: "2024-06-06T15:33:19.000Z" },
        { speed: "0.0", course: "135.6", altitude: "0.7", satellites: "09", hdop: "0.6", lat: "0045.840272", lon: "12722.597604", latD: "N", lonD: "E", latitude: 0.7640045333333333, longitude: 127.37662673333334, timestamp: "2024-06-06T15:33:19.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.840492", lon: "12722.597654", latD: "N", lonD: "E", latitude: 0.7640081999999999, longitude: 127.37662756666667, timestamp: "2024-06-06T15:33:39.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.840492", lon: "12722.597654", latD: "N", lonD: "E", latitude: 0.7640081999999999, longitude: 127.37662756666667, timestamp: "2024-06-06T15:33:39.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.840492", lon: "12722.597654", latD: "N", lonD: "E", latitude: 0.7640081999999999, longitude: 127.37662756666667, timestamp: "2024-06-06T15:33:39.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.840492", lon: "12722.597654", latD: "N", lonD: "E", latitude: 0.7640081999999999, longitude: 127.37662756666667, timestamp: "2024-06-06T15:33:39.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.840492", lon: "12722.597654", latD: "N", lonD: "E", latitude: 0.7640081999999999, longitude: 127.37662756666667, timestamp: "2024-06-06T15:33:39.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.840481", lon: "12722.597654", latD: "N", lonD: "E", latitude: 0.7640080166666666, longitude: 127.37662756666667, timestamp: "2024-06-06T15:33:59.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.840481", lon: "12722.597654", latD: "N", lonD: "E", latitude: 0.7640080166666666, longitude: 127.37662756666667, timestamp: "2024-06-06T15:33:59.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.840481", lon: "12722.597654", latD: "N", lonD: "E", latitude: 0.7640080166666666, longitude: 127.37662756666667, timestamp: "2024-06-06T15:33:59.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.5", lat: "0045.840507", lon: "12722.597609", latD: "N", lonD: "E", latitude: 0.7640084500000001, longitude: 127.37662681666667, timestamp: "2024-06-06T15:34:19.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.5", lat: "0045.840507", lon: "12722.597609", latD: "N", lonD: "E", latitude: 0.7640084500000001, longitude: 127.37662681666667, timestamp: "2024-06-06T15:34:19.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.5", lat: "0045.840507", lon: "12722.597609", latD: "N", lonD: "E", latitude: 0.7640084500000001, longitude: 127.37662681666667, timestamp: "2024-06-06T15:34:19.000Z" },
        { speed: "0.0", course: "135.6", altitude: "-0.2", satellites: "09", hdop: "0.5", lat: "0045.840507", lon: "12722.597609", latD: "N", lonD: "E", latitude: 0.7640084500000001, longitude: 127.37662681666667, timestamp: "2024-06-06T15:34:19.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.8", satellites: "10", hdop: "0.5", lat: "0045.840863", lon: "12722.596925", latD: "N", lonD: "E", latitude: 0.7640143833333333, longitude: 127.37661541666667, timestamp: "2024-06-06T15:34:39.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.8", satellites: "10", hdop: "0.5", lat: "0045.840863", lon: "12722.596925", latD: "N", lonD: "E", latitude: 0.7640143833333333, longitude: 127.37661541666667, timestamp: "2024-06-06T15:34:39.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.8", satellites: "10", hdop: "0.5", lat: "0045.840863", lon: "12722.596925", latD: "N", lonD: "E", latitude: 0.7640143833333333, longitude: 127.37661541666667, timestamp: "2024-06-06T15:34:39.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.8", satellites: "10", hdop: "0.5", lat: "0045.840863", lon: "12722.596925", latD: "N", lonD: "E", latitude: 0.7640143833333333, longitude: 127.37661541666667, timestamp: "2024-06-06T15:34:39.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.840880", lon: "12722.596911", latD: "N", lonD: "E", latitude: 0.7640146666666666, longitude: 127.37661518333333, timestamp: "2024-06-06T15:34:59.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.840880", lon: "12722.596911", latD: "N", lonD: "E", latitude: 0.7640146666666666, longitude: 127.37661518333333, timestamp: "2024-06-06T15:34:59.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.840880", lon: "12722.596911", latD: "N", lonD: "E", latitude: 0.7640146666666666, longitude: 127.37661518333333, timestamp: "2024-06-06T15:34:59.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.840879", lon: "12722.596910", latD: "N", lonD: "E", latitude: 0.76401465, longitude: 127.37661516666667, timestamp: "2024-06-06T15:35:19.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.840880", lon: "12722.596911", latD: "N", lonD: "E", latitude: 0.7640146666666666, longitude: 127.37661518333333, timestamp: "2024-06-06T15:34:59.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "09", hdop: "0.6", lat: "0045.840880", lon: "12722.596911", latD: "N", lonD: "E", latitude: 0.7640146666666666, longitude: 127.37661518333333, timestamp: "2024-06-06T15:34:59.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.840879", lon: "12722.596910", latD: "N", lonD: "E", latitude: 0.76401465, longitude: 127.37661516666667, timestamp: "2024-06-06T15:35:19.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "10", hdop: "0.6", lat: "0045.840879", lon: "12722.596910", latD: "N", lonD: "E", latitude: 0.76401465, longitude: 127.37661516666667, timestamp: "2024-06-06T15:35:19.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "10", hdop: "0.5", lat: "0045.840876", lon: "12722.596896", latD: "N", lonD: "E", latitude: 0.7640146, longitude: 127.37661493333333, timestamp: "2024-06-06T15:35:39.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "10", hdop: "0.5", lat: "0045.840876", lon: "12722.596896", latD: "N", lonD: "E", latitude: 0.7640146, longitude: 127.37661493333333, timestamp: "2024-06-06T15:35:39.000Z" },
        { speed: "0.0", course: "115.7", altitude: "1.1", satellites: "10", hdop: "0.5", lat: "0045.840226", lon: "12722.596392", latD: "N", lonD: "E", latitude: 0.7640037666666667, longitude: 127.37660653333333, timestamp: "2024-06-06T15:35:59.000Z" },
        { speed: "0.0", course: "115.7", altitude: "1.1", satellites: "10", hdop: "0.5", lat: "0045.840226", lon: "12722.596392", latD: "N", lonD: "E", latitude: 0.7640037666666667, longitude: 127.37660653333333, timestamp: "2024-06-06T15:35:59.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "10", hdop: "0.5", lat: "0045.840876", lon: "12722.596896", latD: "N", lonD: "E", latitude: 0.7640146, longitude: 127.37661493333333, timestamp: "2024-06-06T15:35:39.000Z" },
        { speed: "0.0", course: "100.5", altitude: "0.9", satellites: "10", hdop: "0.5", lat: "0045.840876", lon: "12722.596896", latD: "N", lonD: "E", latitude: 0.7640146, longitude: 127.37661493333333, timestamp: "2024-06-06T15:35:39.000Z" },
        { speed: "0.0", course: "115.7", altitude: "1.1", satellites: "10", hdop: "0.5", lat: "0045.840226", lon: "12722.596392", latD: "N", lonD: "E", latitude: 0.7640037666666667, longitude: 127.37660653333333, timestamp: "2024-06-06T15:35:59.000Z" },
        { speed: "0.0", course: "115.7", altitude: "1.1", satellites: "10", hdop: "0.5", lat: "0045.840226", lon: "12722.596392", latD: "N", lonD: "E", latitude: 0.7640037666666667, longitude: 127.37660653333333, timestamp: "2024-06-06T15:35:59.000Z" },
        { speed: "0.0", course: "140.0", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839526", lon: "12722.596976", latD: "N", lonD: "E", latitude: 0.7639921, longitude: 127.37661626666667, timestamp: "2024-06-06T15:36:19.000Z" },
        { speed: "0.0", course: "140.0", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839526", lon: "12722.596976", latD: "N", lonD: "E", latitude: 0.7639921, longitude: 127.37661626666667, timestamp: "2024-06-06T15:36:19.000Z" },
        { speed: "0.0", course: "140.0", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839526", lon: "12722.596976", latD: "N", lonD: "E", latitude: 0.7639921, longitude: 127.37661626666667, timestamp: "2024-06-06T15:36:19.000Z" },
        { speed: "0.0", course: "140.0", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839526", lon: "12722.596976", latD: "N", lonD: "E", latitude: 0.7639921, longitude: 127.37661626666667, timestamp: "2024-06-06T15:36:19.000Z" },
        { speed: "0.0", course: "140.0", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839526", lon: "12722.596976", latD: "N", lonD: "E", latitude: 0.7639921, longitude: 127.37661626666667, timestamp: "2024-06-06T15:36:19.000Z" },
        { speed: "0.0", course: "140.0", altitude: "0.0", satellites: "09", hdop: "0.6", lat: "0045.839462", lon: "12722.596977", latD: "N", lonD: "E", latitude: 0.7639910333333333, longitude: 127.37661628333333, timestamp: "2024-06-06T15:36:39.000Z" },
        { speed: "0.0", course: "140.0", altitude: "0.0", satellites: "09", hdop: "0.6", lat: "0045.839462", lon: "12722.596977", latD: "N", lonD: "E", latitude: 0.7639910333333333, longitude: 127.37661628333333, timestamp: "2024-06-06T15:36:39.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839459", lon: "12722.596985", latD: "N", lonD: "E", latitude: 0.7639909833333333, longitude: 127.37661641666666, timestamp: "2024-06-06T15:36:59.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839459", lon: "12722.596985", latD: "N", lonD: "E", latitude: 0.7639909833333333, longitude: 127.37661641666666, timestamp: "2024-06-06T15:36:59.000Z" },
        { speed: "0.0", course: "140.0", altitude: "0.0", satellites: "09", hdop: "0.6", lat: "0045.839462", lon: "12722.596977", latD: "N", lonD: "E", latitude: 0.7639910333333333, longitude: 127.37661628333333, timestamp: "2024-06-06T15:36:39.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839459", lon: "12722.596985", latD: "N", lonD: "E", latitude: 0.7639909833333333, longitude: 127.37661641666666, timestamp: "2024-06-06T15:36:59.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839459", lon: "12722.596985", latD: "N", lonD: "E", latitude: 0.7639909833333333, longitude: 127.37661641666666, timestamp: "2024-06-06T15:36:59.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839459", lon: "12722.596985", latD: "N", lonD: "E", latitude: 0.7639909833333333, longitude: 127.37661641666666, timestamp: "2024-06-06T15:36:59.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839457", lon: "12722.596982", latD: "N", lonD: "E", latitude: 0.7639909500000001, longitude: 127.37661636666667, timestamp: "2024-06-06T15:37:19.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839457", lon: "12722.596982", latD: "N", lonD: "E", latitude: 0.7639909500000001, longitude: 127.37661636666667, timestamp: "2024-06-06T15:37:19.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839457", lon: "12722.596975", latD: "N", lonD: "E", latitude: 0.7639909500000001, longitude: 127.37661625, timestamp: "2024-06-06T15:37:39.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839457", lon: "12722.596982", latD: "N", lonD: "E", latitude: 0.7639909500000001, longitude: 127.37661636666667, timestamp: "2024-06-06T15:37:19.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839457", lon: "12722.596975", latD: "N", lonD: "E", latitude: 0.7639909500000001, longitude: 127.37661625, timestamp: "2024-06-06T15:37:39.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839457", lon: "12722.596975", latD: "N", lonD: "E", latitude: 0.7639909500000001, longitude: 127.37661625, timestamp: "2024-06-06T15:37:39.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839457", lon: "12722.596975", latD: "N", lonD: "E", latitude: 0.7639909500000001, longitude: 127.37661625, timestamp: "2024-06-06T15:37:39.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.3", satellites: "08", hdop: "0.6", lat: "0045.839427", lon: "12722.596970", latD: "N", lonD: "E", latitude: 0.76399045, longitude: 127.37661616666666, timestamp: "2024-06-06T15:37:59.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.3", satellites: "08", hdop: "0.6", lat: "0045.839427", lon: "12722.596970", latD: "N", lonD: "E", latitude: 0.76399045, longitude: 127.37661616666666, timestamp: "2024-06-06T15:37:59.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.3", satellites: "08", hdop: "0.6", lat: "0045.839427", lon: "12722.596970", latD: "N", lonD: "E", latitude: 0.76399045, longitude: 127.37661616666666, timestamp: "2024-06-06T15:37:59.000Z" },
        { speed: "0.0", course: "140.0", altitude: "-0.3", satellites: "08", hdop: "0.6", lat: "0045.839427", lon: "12722.596970", latD: "N", lonD: "E", latitude: 0.76399045, longitude: 127.37661616666666, timestamp: "2024-06-06T15:37:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "0.4", satellites: "09", hdop: "0.5", lat: "0045.839292", lon: "12722.596904", latD: "N", lonD: "E", latitude: 0.7639882, longitude: 127.37661506666667, timestamp: "2024-06-06T15:38:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "0.4", satellites: "09", hdop: "0.5", lat: "0045.839292", lon: "12722.596904", latD: "N", lonD: "E", latitude: 0.7639882, longitude: 127.37661506666667, timestamp: "2024-06-06T15:38:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "0.4", satellites: "09", hdop: "0.5", lat: "0045.839292", lon: "12722.596904", latD: "N", lonD: "E", latitude: 0.7639882, longitude: 127.37661506666667, timestamp: "2024-06-06T15:38:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-5.3", satellites: "10", hdop: "0.6", lat: "0045.838703", lon: "12722.596387", latD: "N", lonD: "E", latitude: 0.7639783833333333, longitude: 127.37660645, timestamp: "2024-06-06T15:38:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "0.4", satellites: "09", hdop: "0.5", lat: "0045.839292", lon: "12722.596904", latD: "N", lonD: "E", latitude: 0.7639882, longitude: 127.37661506666667, timestamp: "2024-06-06T15:38:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "0.4", satellites: "09", hdop: "0.5", lat: "0045.839292", lon: "12722.596904", latD: "N", lonD: "E", latitude: 0.7639882, longitude: 127.37661506666667, timestamp: "2024-06-06T15:38:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-5.3", satellites: "10", hdop: "0.6", lat: "0045.838703", lon: "12722.596387", latD: "N", lonD: "E", latitude: 0.7639783833333333, longitude: 127.37660645, timestamp: "2024-06-06T15:38:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-5.3", satellites: "10", hdop: "0.6", lat: "0045.838703", lon: "12722.596387", latD: "N", lonD: "E", latitude: 0.7639783833333333, longitude: 127.37660645, timestamp: "2024-06-06T15:38:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.8", satellites: "10", hdop: "0.6", lat: "0045.838819", lon: "12722.596404", latD: "N", lonD: "E", latitude: 0.7639803166666667, longitude: 127.37660673333333, timestamp: "2024-06-06T15:38:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.8", satellites: "10", hdop: "0.6", lat: "0045.838819", lon: "12722.596404", latD: "N", lonD: "E", latitude: 0.7639803166666667, longitude: 127.37660673333333, timestamp: "2024-06-06T15:38:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.8", satellites: "10", hdop: "0.6", lat: "0045.838819", lon: "12722.596404", latD: "N", lonD: "E", latitude: 0.7639803166666667, longitude: 127.37660673333333, timestamp: "2024-06-06T15:38:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.8", satellites: "09", hdop: "0.6", lat: "0045.838842", lon: "12722.596456", latD: "N", lonD: "E", latitude: 0.7639807, longitude: 127.3766076, timestamp: "2024-06-06T15:39:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.8", satellites: "09", hdop: "0.6", lat: "0045.838842", lon: "12722.596456", latD: "N", lonD: "E", latitude: 0.7639807, longitude: 127.3766076, timestamp: "2024-06-06T15:39:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.8", satellites: "10", hdop: "0.6", lat: "0045.838819", lon: "12722.596404", latD: "N", lonD: "E", latitude: 0.7639803166666667, longitude: 127.37660673333333, timestamp: "2024-06-06T15:38:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.8", satellites: "09", hdop: "0.6", lat: "0045.838842", lon: "12722.596456", latD: "N", lonD: "E", latitude: 0.7639807, longitude: 127.3766076, timestamp: "2024-06-06T15:39:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.8", satellites: "09", hdop: "0.6", lat: "0045.838842", lon: "12722.596456", latD: "N", lonD: "E", latitude: 0.7639807, longitude: 127.3766076, timestamp: "2024-06-06T15:39:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.8", satellites: "09", hdop: "0.6", lat: "0045.838842", lon: "12722.596456", latD: "N", lonD: "E", latitude: 0.7639807, longitude: 127.3766076, timestamp: "2024-06-06T15:39:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.6", satellites: "09", hdop: "0.6", lat: "0045.838883", lon: "12722.596415", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37660691666666, timestamp: "2024-06-06T15:39:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.6", satellites: "09", hdop: "0.6", lat: "0045.838883", lon: "12722.596415", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37660691666666, timestamp: "2024-06-06T15:39:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.6", satellites: "09", hdop: "0.6", lat: "0045.838883", lon: "12722.596415", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37660691666666, timestamp: "2024-06-06T15:39:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.3", satellites: "09", hdop: "0.6", lat: "0045.838871", lon: "12722.596364", latD: "N", lonD: "E", latitude: 0.7639811833333333, longitude: 127.37660606666667, timestamp: "2024-06-06T15:39:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.3", satellites: "09", hdop: "0.6", lat: "0045.838871", lon: "12722.596364", latD: "N", lonD: "E", latitude: 0.7639811833333333, longitude: 127.37660606666667, timestamp: "2024-06-06T15:39:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.3", satellites: "09", hdop: "0.6", lat: "0045.838871", lon: "12722.596364", latD: "N", lonD: "E", latitude: 0.7639811833333333, longitude: 127.37660606666667, timestamp: "2024-06-06T15:39:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.3", satellites: "09", hdop: "0.6", lat: "0045.838871", lon: "12722.596364", latD: "N", lonD: "E", latitude: 0.7639811833333333, longitude: 127.37660606666667, timestamp: "2024-06-06T15:39:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.5", satellites: "09", hdop: "0.6", lat: "0045.838836", lon: "12722.596365", latD: "N", lonD: "E", latitude: 0.7639806, longitude: 127.37660608333333, timestamp: "2024-06-06T15:40:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.5", satellites: "09", hdop: "0.6", lat: "0045.838836", lon: "12722.596365", latD: "N", lonD: "E", latitude: 0.7639806, longitude: 127.37660608333333, timestamp: "2024-06-06T15:40:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.5", satellites: "09", hdop: "0.6", lat: "0045.838836", lon: "12722.596365", latD: "N", lonD: "E", latitude: 0.7639806, longitude: 127.37660608333333, timestamp: "2024-06-06T15:40:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838839", lon: "12722.596352", latD: "N", lonD: "E", latitude: 0.76398065, longitude: 127.37660586666667, timestamp: "2024-06-06T15:40:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.5", satellites: "09", hdop: "0.6", lat: "0045.838836", lon: "12722.596365", latD: "N", lonD: "E", latitude: 0.7639806, longitude: 127.37660608333333, timestamp: "2024-06-06T15:40:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838839", lon: "12722.596352", latD: "N", lonD: "E", latitude: 0.76398065, longitude: 127.37660586666667, timestamp: "2024-06-06T15:40:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838839", lon: "12722.596352", latD: "N", lonD: "E", latitude: 0.76398065, longitude: 127.37660586666667, timestamp: "2024-06-06T15:40:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838839", lon: "12722.596352", latD: "N", lonD: "E", latitude: 0.76398065, longitude: 127.37660586666667, timestamp: "2024-06-06T15:40:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838837", lon: "12722.596348", latD: "N", lonD: "E", latitude: 0.7639806166666666, longitude: 127.3766058, timestamp: "2024-06-06T15:40:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838837", lon: "12722.596348", latD: "N", lonD: "E", latitude: 0.7639806166666666, longitude: 127.3766058, timestamp: "2024-06-06T15:40:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838837", lon: "12722.596348", latD: "N", lonD: "E", latitude: 0.7639806166666666, longitude: 127.3766058, timestamp: "2024-06-06T15:40:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838837", lon: "12722.596348", latD: "N", lonD: "E", latitude: 0.7639806166666666, longitude: 127.3766058, timestamp: "2024-06-06T15:40:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838825", lon: "12722.596365", latD: "N", lonD: "E", latitude: 0.7639804166666667, longitude: 127.37660608333333, timestamp: "2024-06-06T15:41:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838825", lon: "12722.596365", latD: "N", lonD: "E", latitude: 0.7639804166666667, longitude: 127.37660608333333, timestamp: "2024-06-06T15:41:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838825", lon: "12722.596365", latD: "N", lonD: "E", latitude: 0.7639804166666667, longitude: 127.37660608333333, timestamp: "2024-06-06T15:41:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838825", lon: "12722.596365", latD: "N", lonD: "E", latitude: 0.7639804166666667, longitude: 127.37660608333333, timestamp: "2024-06-06T15:41:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.6", satellites: "10", hdop: "0.6", lat: "0045.838825", lon: "12722.596365", latD: "N", lonD: "E", latitude: 0.7639804166666667, longitude: 127.37660608333333, timestamp: "2024-06-06T15:41:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.5", satellites: "10", hdop: "0.6", lat: "0045.838837", lon: "12722.596369", latD: "N", lonD: "E", latitude: 0.7639806166666666, longitude: 127.37660615, timestamp: "2024-06-06T15:41:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.5", satellites: "10", hdop: "0.6", lat: "0045.838837", lon: "12722.596369", latD: "N", lonD: "E", latitude: 0.7639806166666666, longitude: 127.37660615, timestamp: "2024-06-06T15:41:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.5", satellites: "10", hdop: "0.6", lat: "0045.838837", lon: "12722.596369", latD: "N", lonD: "E", latitude: 0.7639806166666666, longitude: 127.37660615, timestamp: "2024-06-06T15:41:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.4", satellites: "10", hdop: "0.6", lat: "0045.838843", lon: "12722.596361", latD: "N", lonD: "E", latitude: 0.7639807166666667, longitude: 127.37660601666667, timestamp: "2024-06-06T15:41:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.4", satellites: "10", hdop: "0.6", lat: "0045.838843", lon: "12722.596361", latD: "N", lonD: "E", latitude: 0.7639807166666667, longitude: 127.37660601666667, timestamp: "2024-06-06T15:41:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.3", satellites: "10", hdop: "0.6", lat: "0045.838874", lon: "12722.596375", latD: "N", lonD: "E", latitude: 0.7639812333333332, longitude: 127.37660625, timestamp: "2024-06-06T15:42:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.4", satellites: "10", hdop: "0.6", lat: "0045.838843", lon: "12722.596361", latD: "N", lonD: "E", latitude: 0.7639807166666667, longitude: 127.37660601666667, timestamp: "2024-06-06T15:41:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.3", satellites: "10", hdop: "0.6", lat: "0045.838874", lon: "12722.596375", latD: "N", lonD: "E", latitude: 0.7639812333333332, longitude: 127.37660625, timestamp: "2024-06-06T15:42:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.4", satellites: "10", hdop: "0.6", lat: "0045.838843", lon: "12722.596361", latD: "N", lonD: "E", latitude: 0.7639807166666667, longitude: 127.37660601666667, timestamp: "2024-06-06T15:41:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.3", satellites: "10", hdop: "0.6", lat: "0045.838874", lon: "12722.596375", latD: "N", lonD: "E", latitude: 0.7639812333333332, longitude: 127.37660625, timestamp: "2024-06-06T15:42:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.3", satellites: "10", hdop: "0.6", lat: "0045.838874", lon: "12722.596375", latD: "N", lonD: "E", latitude: 0.7639812333333332, longitude: 127.37660625, timestamp: "2024-06-06T15:42:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.3", satellites: "10", hdop: "0.6", lat: "0045.838874", lon: "12722.596375", latD: "N", lonD: "E", latitude: 0.7639812333333332, longitude: 127.37660625, timestamp: "2024-06-06T15:42:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.2", satellites: "10", hdop: "0.6", lat: "0045.838887", lon: "12722.596376", latD: "N", lonD: "E", latitude: 0.76398145, longitude: 127.37660626666667, timestamp: "2024-06-06T15:42:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.2", satellites: "10", hdop: "0.6", lat: "0045.838887", lon: "12722.596376", latD: "N", lonD: "E", latitude: 0.76398145, longitude: 127.37660626666667, timestamp: "2024-06-06T15:42:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.2", satellites: "10", hdop: "0.6", lat: "0045.838887", lon: "12722.596376", latD: "N", lonD: "E", latitude: 0.76398145, longitude: 127.37660626666667, timestamp: "2024-06-06T15:42:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-4.2", satellites: "10", hdop: "0.6", lat: "0045.838887", lon: "12722.596376", latD: "N", lonD: "E", latitude: 0.76398145, longitude: 127.37660626666667, timestamp: "2024-06-06T15:42:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.9", satellites: "09", hdop: "0.7", lat: "0045.838916", lon: "12722.596398", latD: "N", lonD: "E", latitude: 0.7639819333333333, longitude: 127.37660663333334, timestamp: "2024-06-06T15:42:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.9", satellites: "09", hdop: "0.7", lat: "0045.838916", lon: "12722.596398", latD: "N", lonD: "E", latitude: 0.7639819333333333, longitude: 127.37660663333334, timestamp: "2024-06-06T15:42:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.9", satellites: "09", hdop: "0.7", lat: "0045.838916", lon: "12722.596398", latD: "N", lonD: "E", latitude: 0.7639819333333333, longitude: 127.37660663333334, timestamp: "2024-06-06T15:42:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.9", satellites: "10", hdop: "0.6", lat: "0045.838893", lon: "12722.596398", latD: "N", lonD: "E", latitude: 0.76398155, longitude: 127.37660663333334, timestamp: "2024-06-06T15:43:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.9", satellites: "10", hdop: "0.6", lat: "0045.838893", lon: "12722.596398", latD: "N", lonD: "E", latitude: 0.76398155, longitude: 127.37660663333334, timestamp: "2024-06-06T15:43:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.7", satellites: "09", hdop: "0.6", lat: "0045.838908", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37660715, timestamp: "2024-06-06T15:43:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.7", satellites: "09", hdop: "0.6", lat: "0045.838908", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37660715, timestamp: "2024-06-06T15:43:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.9", satellites: "10", hdop: "0.6", lat: "0045.838893", lon: "12722.596398", latD: "N", lonD: "E", latitude: 0.76398155, longitude: 127.37660663333334, timestamp: "2024-06-06T15:43:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.9", satellites: "10", hdop: "0.6", lat: "0045.838893", lon: "12722.596398", latD: "N", lonD: "E", latitude: 0.76398155, longitude: 127.37660663333334, timestamp: "2024-06-06T15:43:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.7", satellites: "09", hdop: "0.6", lat: "0045.838908", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37660715, timestamp: "2024-06-06T15:43:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.7", satellites: "09", hdop: "0.6", lat: "0045.838908", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37660715, timestamp: "2024-06-06T15:43:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.6", satellites: "10", hdop: "0.6", lat: "0045.838909", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639818166666666, longitude: 127.37660715, timestamp: "2024-06-06T15:43:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.6", satellites: "10", hdop: "0.6", lat: "0045.838909", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639818166666666, longitude: 127.37660715, timestamp: "2024-06-06T15:43:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.6", satellites: "10", hdop: "0.6", lat: "0045.838909", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639818166666666, longitude: 127.37660715, timestamp: "2024-06-06T15:43:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.6", satellites: "10", hdop: "0.6", lat: "0045.838909", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639818166666666, longitude: 127.37660715, timestamp: "2024-06-06T15:43:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.838903", lon: "12722.596438", latD: "N", lonD: "E", latitude: 0.7639817166666667, longitude: 127.3766073, timestamp: "2024-06-06T15:44:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.838903", lon: "12722.596438", latD: "N", lonD: "E", latitude: 0.7639817166666667, longitude: 127.3766073, timestamp: "2024-06-06T15:44:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.838903", lon: "12722.596438", latD: "N", lonD: "E", latitude: 0.7639817166666667, longitude: 127.3766073, timestamp: "2024-06-06T15:44:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.838906", lon: "12722.596412", latD: "N", lonD: "E", latitude: 0.7639817666666667, longitude: 127.37660686666666, timestamp: "2024-06-06T15:44:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.838906", lon: "12722.596412", latD: "N", lonD: "E", latitude: 0.7639817666666667, longitude: 127.37660686666666, timestamp: "2024-06-06T15:44:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.838903", lon: "12722.596438", latD: "N", lonD: "E", latitude: 0.7639817166666667, longitude: 127.3766073, timestamp: "2024-06-06T15:44:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.838906", lon: "12722.596412", latD: "N", lonD: "E", latitude: 0.7639817666666667, longitude: 127.37660686666666, timestamp: "2024-06-06T15:44:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.838906", lon: "12722.596412", latD: "N", lonD: "E", latitude: 0.7639817666666667, longitude: 127.37660686666666, timestamp: "2024-06-06T15:44:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.3", satellites: "09", hdop: "0.6", lat: "0045.838917", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.76398195, longitude: 127.37660695, timestamp: "2024-06-06T15:44:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.3", satellites: "09", hdop: "0.6", lat: "0045.838917", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.76398195, longitude: 127.37660695, timestamp: "2024-06-06T15:44:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.3", satellites: "09", hdop: "0.6", lat: "0045.838917", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.76398195, longitude: 127.37660695, timestamp: "2024-06-06T15:44:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.838908", lon: "12722.596412", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37660686666666, timestamp: "2024-06-06T15:45:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.838908", lon: "12722.596412", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37660686666666, timestamp: "2024-06-06T15:45:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.838908", lon: "12722.596412", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37660686666666, timestamp: "2024-06-06T15:45:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.838908", lon: "12722.596412", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37660686666666, timestamp: "2024-06-06T15:45:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.838908", lon: "12722.596412", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37660686666666, timestamp: "2024-06-06T15:45:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.2", satellites: "09", hdop: "0.6", lat: "0045.838905", lon: "12722.596391", latD: "N", lonD: "E", latitude: 0.7639817499999999, longitude: 127.37660651666667, timestamp: "2024-06-06T15:45:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.2", satellites: "09", hdop: "0.6", lat: "0045.838905", lon: "12722.596391", latD: "N", lonD: "E", latitude: 0.7639817499999999, longitude: 127.37660651666667, timestamp: "2024-06-06T15:45:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.2", satellites: "09", hdop: "0.6", lat: "0045.838905", lon: "12722.596391", latD: "N", lonD: "E", latitude: 0.7639817499999999, longitude: 127.37660651666667, timestamp: "2024-06-06T15:45:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-3.2", satellites: "09", hdop: "0.6", lat: "0045.838905", lon: "12722.596391", latD: "N", lonD: "E", latitude: 0.7639817499999999, longitude: 127.37660651666667, timestamp: "2024-06-06T15:45:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.9", satellites: "09", hdop: "0.6", lat: "0045.838942", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639823666666667, longitude: 127.37660648333333, timestamp: "2024-06-06T15:45:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.9", satellites: "09", hdop: "0.6", lat: "0045.838942", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639823666666667, longitude: 127.37660648333333, timestamp: "2024-06-06T15:45:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.9", satellites: "09", hdop: "0.6", lat: "0045.838942", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639823666666667, longitude: 127.37660648333333, timestamp: "2024-06-06T15:45:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.9", satellites: "09", hdop: "0.6", lat: "0045.838942", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639823666666667, longitude: 127.37660648333333, timestamp: "2024-06-06T15:45:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.8", satellites: "09", hdop: "0.6", lat: "0045.838950", lon: "12722.596387", latD: "N", lonD: "E", latitude: 0.7639824999999999, longitude: 127.37660645, timestamp: "2024-06-06T15:46:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.8", satellites: "09", hdop: "0.6", lat: "0045.838950", lon: "12722.596387", latD: "N", lonD: "E", latitude: 0.7639824999999999, longitude: 127.37660645, timestamp: "2024-06-06T15:46:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.838966", lon: "12722.596422", latD: "N", lonD: "E", latitude: 0.7639827666666666, longitude: 127.37660703333333, timestamp: "2024-06-06T15:46:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.838966", lon: "12722.596422", latD: "N", lonD: "E", latitude: 0.7639827666666666, longitude: 127.37660703333333, timestamp: "2024-06-06T15:46:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.8", satellites: "09", hdop: "0.6", lat: "0045.838950", lon: "12722.596387", latD: "N", lonD: "E", latitude: 0.7639824999999999, longitude: 127.37660645, timestamp: "2024-06-06T15:46:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.8", satellites: "09", hdop: "0.6", lat: "0045.838950", lon: "12722.596387", latD: "N", lonD: "E", latitude: 0.7639824999999999, longitude: 127.37660645, timestamp: "2024-06-06T15:46:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.838966", lon: "12722.596422", latD: "N", lonD: "E", latitude: 0.7639827666666666, longitude: 127.37660703333333, timestamp: "2024-06-06T15:46:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.838966", lon: "12722.596422", latD: "N", lonD: "E", latitude: 0.7639827666666666, longitude: 127.37660703333333, timestamp: "2024-06-06T15:46:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.7", satellites: "10", hdop: "0.6", lat: "0045.838988", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.7639831333333333, longitude: 127.37660726666667, timestamp: "2024-06-06T15:46:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.838966", lon: "12722.596422", latD: "N", lonD: "E", latitude: 0.7639827666666666, longitude: 127.37660703333333, timestamp: "2024-06-06T15:46:39.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.7", satellites: "10", hdop: "0.6", lat: "0045.838988", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.7639831333333333, longitude: 127.37660726666667, timestamp: "2024-06-06T15:46:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.839012", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639835333333332, longitude: 127.37660758333334, timestamp: "2024-06-06T15:47:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.839012", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639835333333332, longitude: 127.37660758333334, timestamp: "2024-06-06T15:47:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.7", satellites: "10", hdop: "0.6", lat: "0045.838988", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.7639831333333333, longitude: 127.37660726666667, timestamp: "2024-06-06T15:46:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.839012", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639835333333332, longitude: 127.37660758333334, timestamp: "2024-06-06T15:47:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.839012", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639835333333332, longitude: 127.37660758333334, timestamp: "2024-06-06T15:47:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.3", satellites: "10", hdop: "0.6", lat: "0045.839021", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639836833333333, longitude: 127.37660758333334, timestamp: "2024-06-06T15:47:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.3", satellites: "10", hdop: "0.6", lat: "0045.839021", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639836833333333, longitude: 127.37660758333334, timestamp: "2024-06-06T15:47:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.3", satellites: "10", hdop: "0.6", lat: "0045.839021", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639836833333333, longitude: 127.37660758333334, timestamp: "2024-06-06T15:47:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.3", satellites: "10", hdop: "0.6", lat: "0045.839021", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639836833333333, longitude: 127.37660758333334, timestamp: "2024-06-06T15:47:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.1", satellites: "10", hdop: "0.6", lat: "0045.839029", lon: "12722.596449", latD: "N", lonD: "E", latitude: 0.7639838166666666, longitude: 127.37660748333333, timestamp: "2024-06-06T15:47:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.1", satellites: "10", hdop: "0.6", lat: "0045.839029", lon: "12722.596449", latD: "N", lonD: "E", latitude: 0.7639838166666666, longitude: 127.37660748333333, timestamp: "2024-06-06T15:47:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.1", satellites: "10", hdop: "0.6", lat: "0045.839029", lon: "12722.596449", latD: "N", lonD: "E", latitude: 0.7639838166666666, longitude: 127.37660748333333, timestamp: "2024-06-06T15:47:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.1", satellites: "10", hdop: "0.6", lat: "0045.839029", lon: "12722.596449", latD: "N", lonD: "E", latitude: 0.7639838166666666, longitude: 127.37660748333333, timestamp: "2024-06-06T15:47:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.0", satellites: "10", hdop: "0.6", lat: "0045.839043", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639840499999999, longitude: 127.37660758333334, timestamp: "2024-06-06T15:48:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.0", satellites: "10", hdop: "0.6", lat: "0045.839043", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639840499999999, longitude: 127.37660758333334, timestamp: "2024-06-06T15:48:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.0", satellites: "10", hdop: "0.6", lat: "0045.839043", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639840499999999, longitude: 127.37660758333334, timestamp: "2024-06-06T15:48:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.9", satellites: "10", hdop: "0.6", lat: "0045.839049", lon: "12722.596454", latD: "N", lonD: "E", latitude: 0.7639841500000001, longitude: 127.37660756666666, timestamp: "2024-06-06T15:48:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.9", satellites: "10", hdop: "0.6", lat: "0045.839049", lon: "12722.596454", latD: "N", lonD: "E", latitude: 0.7639841500000001, longitude: 127.37660756666666, timestamp: "2024-06-06T15:48:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-2.0", satellites: "10", hdop: "0.6", lat: "0045.839043", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639840499999999, longitude: 127.37660758333334, timestamp: "2024-06-06T15:48:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.9", satellites: "10", hdop: "0.6", lat: "0045.839049", lon: "12722.596454", latD: "N", lonD: "E", latitude: 0.7639841500000001, longitude: 127.37660756666666, timestamp: "2024-06-06T15:48:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.9", satellites: "10", hdop: "0.6", lat: "0045.839049", lon: "12722.596454", latD: "N", lonD: "E", latitude: 0.7639841500000001, longitude: 127.37660756666666, timestamp: "2024-06-06T15:48:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839057", lon: "12722.596457", latD: "N", lonD: "E", latitude: 0.7639842833333332, longitude: 127.37660761666666, timestamp: "2024-06-06T15:48:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839057", lon: "12722.596457", latD: "N", lonD: "E", latitude: 0.7639842833333332, longitude: 127.37660761666666, timestamp: "2024-06-06T15:48:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839057", lon: "12722.596457", latD: "N", lonD: "E", latitude: 0.7639842833333332, longitude: 127.37660761666666, timestamp: "2024-06-06T15:48:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839077", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639846166666667, longitude: 127.37660776666667, timestamp: "2024-06-06T15:49:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839077", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639846166666667, longitude: 127.37660776666667, timestamp: "2024-06-06T15:49:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839057", lon: "12722.596457", latD: "N", lonD: "E", latitude: 0.7639842833333332, longitude: 127.37660761666666, timestamp: "2024-06-06T15:48:59.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839077", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639846166666667, longitude: 127.37660776666667, timestamp: "2024-06-06T15:49:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839077", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639846166666667, longitude: 127.37660776666667, timestamp: "2024-06-06T15:49:19.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839105", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.7639850833333334, longitude: 127.37660798333333, timestamp: "2024-06-06T15:49:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839105", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.7639850833333334, longitude: 127.37660798333333, timestamp: "2024-06-06T15:49:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596478", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37660796666667, timestamp: "2024-06-06T15:50:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839105", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.7639850833333334, longitude: 127.37660798333333, timestamp: "2024-06-06T15:49:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839105", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.7639850833333334, longitude: 127.37660798333333, timestamp: "2024-06-06T15:49:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596478", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37660796666667, timestamp: "2024-06-06T15:50:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596478", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37660796666667, timestamp: "2024-06-06T15:50:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596478", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37660796666667, timestamp: "2024-06-06T15:50:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596478", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37660796666667, timestamp: "2024-06-06T15:50:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839141", lon: "12722.596463", latD: "N", lonD: "E", latitude: 0.7639856833333333, longitude: 127.37660771666667, timestamp: "2024-06-06T15:50:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839141", lon: "12722.596463", latD: "N", lonD: "E", latitude: 0.7639856833333333, longitude: 127.37660771666667, timestamp: "2024-06-06T15:50:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839141", lon: "12722.596463", latD: "N", lonD: "E", latitude: 0.7639856833333333, longitude: 127.37660771666667, timestamp: "2024-06-06T15:50:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839142", lon: "12722.596458", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660763333334, timestamp: "2024-06-06T15:50:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839142", lon: "12722.596458", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660763333334, timestamp: "2024-06-06T15:50:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839142", lon: "12722.596458", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660763333334, timestamp: "2024-06-06T15:50:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839142", lon: "12722.596457", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660761666666, timestamp: "2024-06-06T15:51:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839142", lon: "12722.596457", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660761666666, timestamp: "2024-06-06T15:51:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839142", lon: "12722.596458", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660763333334, timestamp: "2024-06-06T15:50:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839142", lon: "12722.596457", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660761666666, timestamp: "2024-06-06T15:51:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839142", lon: "12722.596457", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660761666666, timestamp: "2024-06-06T15:51:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "09", hdop: "0.7", lat: "0045.839145", lon: "12722.596444", latD: "N", lonD: "E", latitude: 0.76398575, longitude: 127.3766074, timestamp: "2024-06-06T15:51:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "09", hdop: "0.7", lat: "0045.839145", lon: "12722.596444", latD: "N", lonD: "E", latitude: 0.76398575, longitude: 127.3766074, timestamp: "2024-06-06T15:51:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "09", hdop: "0.7", lat: "0045.839145", lon: "12722.596444", latD: "N", lonD: "E", latitude: 0.76398575, longitude: 127.3766074, timestamp: "2024-06-06T15:51:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "08", hdop: "0.7", lat: "0045.839118", lon: "12722.596421", latD: "N", lonD: "E", latitude: 0.7639853, longitude: 127.37660701666667, timestamp: "2024-06-06T15:51:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "08", hdop: "0.7", lat: "0045.839118", lon: "12722.596421", latD: "N", lonD: "E", latitude: 0.7639853, longitude: 127.37660701666667, timestamp: "2024-06-06T15:51:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "09", hdop: "0.7", lat: "0045.839145", lon: "12722.596444", latD: "N", lonD: "E", latitude: 0.76398575, longitude: 127.3766074, timestamp: "2024-06-06T15:51:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "08", hdop: "0.7", lat: "0045.839118", lon: "12722.596421", latD: "N", lonD: "E", latitude: 0.7639853, longitude: 127.37660701666667, timestamp: "2024-06-06T15:51:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "08", hdop: "0.7", lat: "0045.839118", lon: "12722.596421", latD: "N", lonD: "E", latitude: 0.7639853, longitude: 127.37660701666667, timestamp: "2024-06-06T15:51:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.7", lat: "0045.839105", lon: "12722.596418", latD: "N", lonD: "E", latitude: 0.7639850833333334, longitude: 127.37660696666667, timestamp: "2024-06-06T15:52:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.7", lat: "0045.839105", lon: "12722.596418", latD: "N", lonD: "E", latitude: 0.7639850833333334, longitude: 127.37660696666667, timestamp: "2024-06-06T15:52:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.7", lat: "0045.839105", lon: "12722.596418", latD: "N", lonD: "E", latitude: 0.7639850833333334, longitude: 127.37660696666667, timestamp: "2024-06-06T15:52:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.7", lat: "0045.839105", lon: "12722.596418", latD: "N", lonD: "E", latitude: 0.7639850833333334, longitude: 127.37660696666667, timestamp: "2024-06-06T15:52:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596410", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660683333333, timestamp: "2024-06-06T15:52:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596410", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660683333333, timestamp: "2024-06-06T15:52:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596410", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660683333333, timestamp: "2024-06-06T15:52:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596410", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660683333333, timestamp: "2024-06-06T15:52:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596410", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660683333333, timestamp: "2024-06-06T15:52:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839117", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.7639852833333334, longitude: 127.37660693333333, timestamp: "2024-06-06T15:52:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839126", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639854333333334, longitude: 127.37660715, timestamp: "2024-06-06T15:53:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839117", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.7639852833333334, longitude: 127.37660693333333, timestamp: "2024-06-06T15:52:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839117", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.7639852833333334, longitude: 127.37660693333333, timestamp: "2024-06-06T15:52:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839126", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639854333333334, longitude: 127.37660715, timestamp: "2024-06-06T15:53:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839126", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639854333333334, longitude: 127.37660715, timestamp: "2024-06-06T15:53:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839126", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639854333333334, longitude: 127.37660715, timestamp: "2024-06-06T15:53:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "10", hdop: "0.6", lat: "0045.839126", lon: "12722.596429", latD: "N", lonD: "E", latitude: 0.7639854333333334, longitude: 127.37660715, timestamp: "2024-06-06T15:53:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596438", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.3766073, timestamp: "2024-06-06T15:53:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596438", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.3766073, timestamp: "2024-06-06T15:53:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596438", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.3766073, timestamp: "2024-06-06T15:53:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "09", hdop: "0.6", lat: "0045.839131", lon: "12722.596433", latD: "N", lonD: "E", latitude: 0.7639855166666667, longitude: 127.37660721666667, timestamp: "2024-06-06T15:53:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "09", hdop: "0.6", lat: "0045.839131", lon: "12722.596433", latD: "N", lonD: "E", latitude: 0.7639855166666667, longitude: 127.37660721666667, timestamp: "2024-06-06T15:53:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "09", hdop: "0.6", lat: "0045.839131", lon: "12722.596433", latD: "N", lonD: "E", latitude: 0.7639855166666667, longitude: 127.37660721666667, timestamp: "2024-06-06T15:53:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839136", lon: "12722.596452", latD: "N", lonD: "E", latitude: 0.7639856, longitude: 127.37660753333333, timestamp: "2024-06-06T15:54:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "09", hdop: "0.6", lat: "0045.839131", lon: "12722.596433", latD: "N", lonD: "E", latitude: 0.7639855166666667, longitude: 127.37660721666667, timestamp: "2024-06-06T15:53:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "09", hdop: "0.6", lat: "0045.839131", lon: "12722.596433", latD: "N", lonD: "E", latitude: 0.7639855166666667, longitude: 127.37660721666667, timestamp: "2024-06-06T15:53:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839136", lon: "12722.596452", latD: "N", lonD: "E", latitude: 0.7639856, longitude: 127.37660753333333, timestamp: "2024-06-06T15:54:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839136", lon: "12722.596452", latD: "N", lonD: "E", latitude: 0.7639856, longitude: 127.37660753333333, timestamp: "2024-06-06T15:54:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.5", satellites: "10", hdop: "0.6", lat: "0045.839136", lon: "12722.596452", latD: "N", lonD: "E", latitude: 0.7639856, longitude: 127.37660753333333, timestamp: "2024-06-06T15:54:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839138", lon: "12722.596464", latD: "N", lonD: "E", latitude: 0.7639856333333334, longitude: 127.37660773333333, timestamp: "2024-06-06T15:54:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839138", lon: "12722.596464", latD: "N", lonD: "E", latitude: 0.7639856333333334, longitude: 127.37660773333333, timestamp: "2024-06-06T15:54:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839131", lon: "12722.596481", latD: "N", lonD: "E", latitude: 0.7639855166666667, longitude: 127.37660801666667, timestamp: "2024-06-06T15:54:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839131", lon: "12722.596481", latD: "N", lonD: "E", latitude: 0.7639855166666667, longitude: 127.37660801666667, timestamp: "2024-06-06T15:54:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839138", lon: "12722.596464", latD: "N", lonD: "E", latitude: 0.7639856333333334, longitude: 127.37660773333333, timestamp: "2024-06-06T15:54:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839131", lon: "12722.596481", latD: "N", lonD: "E", latitude: 0.7639855166666667, longitude: 127.37660801666667, timestamp: "2024-06-06T15:54:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839131", lon: "12722.596481", latD: "N", lonD: "E", latitude: 0.7639855166666667, longitude: 127.37660801666667, timestamp: "2024-06-06T15:54:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839116", lon: "12722.596487", latD: "N", lonD: "E", latitude: 0.7639852666666667, longitude: 127.37660811666667, timestamp: "2024-06-06T15:55:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839116", lon: "12722.596487", latD: "N", lonD: "E", latitude: 0.7639852666666667, longitude: 127.37660811666667, timestamp: "2024-06-06T15:55:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839116", lon: "12722.596487", latD: "N", lonD: "E", latitude: 0.7639852666666667, longitude: 127.37660811666667, timestamp: "2024-06-06T15:55:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839116", lon: "12722.596487", latD: "N", lonD: "E", latitude: 0.7639852666666667, longitude: 127.37660811666667, timestamp: "2024-06-06T15:55:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839109", lon: "12722.596482", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660803333333, timestamp: "2024-06-06T15:55:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839109", lon: "12722.596482", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660803333333, timestamp: "2024-06-06T15:55:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839109", lon: "12722.596482", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660803333333, timestamp: "2024-06-06T15:55:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839109", lon: "12722.596482", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660803333333, timestamp: "2024-06-06T15:55:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839116", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.7639852666666667, longitude: 127.37660798333333, timestamp: "2024-06-06T15:55:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839116", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.7639852666666667, longitude: 127.37660798333333, timestamp: "2024-06-06T15:55:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839104", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660776666667, timestamp: "2024-06-06T15:56:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839104", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660776666667, timestamp: "2024-06-06T15:56:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839116", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.7639852666666667, longitude: 127.37660798333333, timestamp: "2024-06-06T15:55:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839116", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.7639852666666667, longitude: 127.37660798333333, timestamp: "2024-06-06T15:55:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839104", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660776666667, timestamp: "2024-06-06T15:56:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839104", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660776666667, timestamp: "2024-06-06T15:56:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839094", lon: "12722.596468", latD: "N", lonD: "E", latitude: 0.7639849000000001, longitude: 127.3766078, timestamp: "2024-06-06T15:56:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.839104", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660776666667, timestamp: "2024-06-06T15:56:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839094", lon: "12722.596468", latD: "N", lonD: "E", latitude: 0.7639849000000001, longitude: 127.3766078, timestamp: "2024-06-06T15:56:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839094", lon: "12722.596468", latD: "N", lonD: "E", latitude: 0.7639849000000001, longitude: 127.3766078, timestamp: "2024-06-06T15:56:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839094", lon: "12722.596468", latD: "N", lonD: "E", latitude: 0.7639849000000001, longitude: 127.3766078, timestamp: "2024-06-06T15:56:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.3", satellites: "10", hdop: "0.6", lat: "0045.839087", lon: "12722.596463", latD: "N", lonD: "E", latitude: 0.7639847833333333, longitude: 127.37660771666667, timestamp: "2024-06-06T15:56:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.3", satellites: "10", hdop: "0.6", lat: "0045.839087", lon: "12722.596463", latD: "N", lonD: "E", latitude: 0.7639847833333333, longitude: 127.37660771666667, timestamp: "2024-06-06T15:56:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839085", lon: "12722.596461", latD: "N", lonD: "E", latitude: 0.76398475, longitude: 127.37660768333333, timestamp: "2024-06-06T15:57:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839085", lon: "12722.596461", latD: "N", lonD: "E", latitude: 0.76398475, longitude: 127.37660768333333, timestamp: "2024-06-06T15:57:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.3", satellites: "10", hdop: "0.6", lat: "0045.839087", lon: "12722.596463", latD: "N", lonD: "E", latitude: 0.7639847833333333, longitude: 127.37660771666667, timestamp: "2024-06-06T15:56:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839085", lon: "12722.596461", latD: "N", lonD: "E", latitude: 0.76398475, longitude: 127.37660768333333, timestamp: "2024-06-06T15:57:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839085", lon: "12722.596461", latD: "N", lonD: "E", latitude: 0.76398475, longitude: 127.37660768333333, timestamp: "2024-06-06T15:57:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.4", satellites: "10", hdop: "0.6", lat: "0045.839085", lon: "12722.596461", latD: "N", lonD: "E", latitude: 0.76398475, longitude: 127.37660768333333, timestamp: "2024-06-06T15:57:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "09", hdop: "0.6", lat: "0045.839075", lon: "12722.596443", latD: "N", lonD: "E", latitude: 0.7639845833333333, longitude: 127.37660738333334, timestamp: "2024-06-06T15:57:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.839065", lon: "12722.596435", latD: "N", lonD: "E", latitude: 0.7639844166666666, longitude: 127.37660725, timestamp: "2024-06-06T15:57:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "09", hdop: "0.6", lat: "0045.839075", lon: "12722.596443", latD: "N", lonD: "E", latitude: 0.7639845833333333, longitude: 127.37660738333334, timestamp: "2024-06-06T15:57:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.6", satellites: "09", hdop: "0.6", lat: "0045.839075", lon: "12722.596443", latD: "N", lonD: "E", latitude: 0.7639845833333333, longitude: 127.37660738333334, timestamp: "2024-06-06T15:57:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.839065", lon: "12722.596435", latD: "N", lonD: "E", latitude: 0.7639844166666666, longitude: 127.37660725, timestamp: "2024-06-06T15:57:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.839065", lon: "12722.596435", latD: "N", lonD: "E", latitude: 0.7639844166666666, longitude: 127.37660725, timestamp: "2024-06-06T15:57:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.839065", lon: "12722.596435", latD: "N", lonD: "E", latitude: 0.7639844166666666, longitude: 127.37660725, timestamp: "2024-06-06T15:57:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.839040", lon: "12722.596433", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.37660721666667, timestamp: "2024-06-06T15:58:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.839040", lon: "12722.596433", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.37660721666667, timestamp: "2024-06-06T15:58:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.839014", lon: "12722.596431", latD: "N", lonD: "E", latitude: 0.7639835666666667, longitude: 127.37660718333333, timestamp: "2024-06-06T15:58:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.839014", lon: "12722.596431", latD: "N", lonD: "E", latitude: 0.7639835666666667, longitude: 127.37660718333333, timestamp: "2024-06-06T15:58:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.839040", lon: "12722.596433", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.37660721666667, timestamp: "2024-06-06T15:58:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.839040", lon: "12722.596433", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.37660721666667, timestamp: "2024-06-06T15:58:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.839014", lon: "12722.596431", latD: "N", lonD: "E", latitude: 0.7639835666666667, longitude: 127.37660718333333, timestamp: "2024-06-06T15:58:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839015", lon: "12722.596430", latD: "N", lonD: "E", latitude: 0.7639835833333334, longitude: 127.37660716666667, timestamp: "2024-06-06T15:58:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839015", lon: "12722.596430", latD: "N", lonD: "E", latitude: 0.7639835833333334, longitude: 127.37660716666667, timestamp: "2024-06-06T15:58:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839015", lon: "12722.596430", latD: "N", lonD: "E", latitude: 0.7639835833333334, longitude: 127.37660716666667, timestamp: "2024-06-06T15:58:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839015", lon: "12722.596430", latD: "N", lonD: "E", latitude: 0.7639835833333334, longitude: 127.37660716666667, timestamp: "2024-06-06T15:58:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839015", lon: "12722.596430", latD: "N", lonD: "E", latitude: 0.7639835833333334, longitude: 127.37660716666667, timestamp: "2024-06-06T15:58:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839015", lon: "12722.596430", latD: "N", lonD: "E", latitude: 0.7639835833333334, longitude: 127.37660716666667, timestamp: "2024-06-06T15:58:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.839010", lon: "12722.596419", latD: "N", lonD: "E", latitude: 0.7639835, longitude: 127.37660698333333, timestamp: "2024-06-06T15:59:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.839010", lon: "12722.596419", latD: "N", lonD: "E", latitude: 0.7639835, longitude: 127.37660698333333, timestamp: "2024-06-06T15:59:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.838997", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.7639832833333333, longitude: 127.37660695, timestamp: "2024-06-06T15:59:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.839010", lon: "12722.596419", latD: "N", lonD: "E", latitude: 0.7639835, longitude: 127.37660698333333, timestamp: "2024-06-06T15:59:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.838997", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.7639832833333333, longitude: 127.37660695, timestamp: "2024-06-06T15:59:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.838997", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.7639832833333333, longitude: 127.37660695, timestamp: "2024-06-06T15:59:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.838987", lon: "12722.596397", latD: "N", lonD: "E", latitude: 0.7639831166666667, longitude: 127.37660661666666, timestamp: "2024-06-06T15:59:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.838997", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.7639832833333333, longitude: 127.37660695, timestamp: "2024-06-06T15:59:20.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.838987", lon: "12722.596397", latD: "N", lonD: "E", latitude: 0.7639831166666667, longitude: 127.37660661666666, timestamp: "2024-06-06T15:59:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.838987", lon: "12722.596397", latD: "N", lonD: "E", latitude: 0.7639831166666667, longitude: 127.37660661666666, timestamp: "2024-06-06T15:59:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "08", hdop: "0.7", lat: "0045.838987", lon: "12722.596397", latD: "N", lonD: "E", latitude: 0.7639831166666667, longitude: 127.37660661666666, timestamp: "2024-06-06T15:59:40.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.838987", lon: "12722.596390", latD: "N", lonD: "E", latitude: 0.7639831166666667, longitude: 127.3766065, timestamp: "2024-06-06T16:00:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.838987", lon: "12722.596390", latD: "N", lonD: "E", latitude: 0.7639831166666667, longitude: 127.3766065, timestamp: "2024-06-06T16:00:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.838987", lon: "12722.596390", latD: "N", lonD: "E", latitude: 0.7639831166666667, longitude: 127.3766065, timestamp: "2024-06-06T16:00:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.838987", lon: "12722.596390", latD: "N", lonD: "E", latitude: 0.7639831166666667, longitude: 127.3766065, timestamp: "2024-06-06T16:00:00.000Z" },
        { speed: "0.0", course: "171.4", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.838987", lon: "12722.596390", latD: "N", lonD: "E", latitude: 0.7639831166666667, longitude: 127.3766065, timestamp: "2024-06-06T16:00:00.000Z" },
        { speed: "0.0", course: "200.9", altitude: "-0.8", satellites: "10", hdop: "0.5", lat: "0045.840070", lon: "12722.595606", latD: "N", lonD: "E", latitude: 0.7640011666666666, longitude: 127.37659343333333, timestamp: "2024-06-06T16:00:20.000Z" },
        { speed: "0.0", course: "252.1", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838077", lon: "12722.594769", latD: "N", lonD: "E", latitude: 0.76396795, longitude: 127.37657948333333, timestamp: "2024-06-06T16:02:41.000Z" },
        { speed: "0.0", course: "252.1", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838077", lon: "12722.594769", latD: "N", lonD: "E", latitude: 0.76396795, longitude: 127.37657948333333, timestamp: "2024-06-06T16:02:41.000Z" },
        { speed: "0.0", course: "252.1", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838077", lon: "12722.594769", latD: "N", lonD: "E", latitude: 0.76396795, longitude: 127.37657948333333, timestamp: "2024-06-06T16:02:41.000Z" },
        { speed: "0.0", course: "252.1", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838077", lon: "12722.594769", latD: "N", lonD: "E", latitude: 0.76396795, longitude: 127.37657948333333, timestamp: "2024-06-06T16:02:41.000Z" },
        { speed: "0.0", course: "251.6", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839528", lon: "12722.593271", latD: "N", lonD: "E", latitude: 0.7639921333333334, longitude: 127.37655451666667, timestamp: "2024-06-06T16:03:00.000Z" },
        { speed: "0.0", course: "251.6", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839528", lon: "12722.593271", latD: "N", lonD: "E", latitude: 0.7639921333333334, longitude: 127.37655451666667, timestamp: "2024-06-06T16:03:00.000Z" },
        { speed: "0.0", course: "251.6", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839528", lon: "12722.593271", latD: "N", lonD: "E", latitude: 0.7639921333333334, longitude: 127.37655451666667, timestamp: "2024-06-06T16:03:00.000Z" },
        { speed: "0.0", course: "251.6", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839528", lon: "12722.593271", latD: "N", lonD: "E", latitude: 0.7639921333333334, longitude: 127.37655451666667, timestamp: "2024-06-06T16:03:00.000Z" },
        { speed: "0.0", course: "251.6", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839528", lon: "12722.593271", latD: "N", lonD: "E", latitude: 0.7639921333333334, longitude: 127.37655451666667, timestamp: "2024-06-06T16:03:00.000Z" },
        { speed: "0.0", course: "251.6", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839528", lon: "12722.593271", latD: "N", lonD: "E", latitude: 0.7639921333333334, longitude: 127.37655451666667, timestamp: "2024-06-06T16:03:00.000Z" },
        { speed: "0.0", course: "251.6", altitude: "-2.7", satellites: "09", hdop: "0.6", lat: "0045.839244", lon: "12722.592325", latD: "N", lonD: "E", latitude: 0.7639874, longitude: 127.37653875, timestamp: "2024-06-06T16:03:20.000Z" },
        { speed: "0.0", course: "251.6", altitude: "-2.7", satellites: "09", hdop: "0.6", lat: "0045.839244", lon: "12722.592325", latD: "N", lonD: "E", latitude: 0.7639874, longitude: 127.37653875, timestamp: "2024-06-06T16:03:20.000Z" },
        { speed: "0.0", course: "251.6", altitude: "-2.7", satellites: "09", hdop: "0.6", lat: "0045.839244", lon: "12722.592325", latD: "N", lonD: "E", latitude: 0.7639874, longitude: 127.37653875, timestamp: "2024-06-06T16:03:20.000Z" },
        { speed: "0.0", course: "251.6", altitude: "-2.7", satellites: "09", hdop: "0.6", lat: "0045.839244", lon: "12722.592325", latD: "N", lonD: "E", latitude: 0.7639874, longitude: 127.37653875, timestamp: "2024-06-06T16:03:20.000Z" },
        { speed: "0.0", course: "251.6", altitude: "-2.9", satellites: "11", hdop: "0.5", lat: "0045.839254", lon: "12722.592252", latD: "N", lonD: "E", latitude: 0.7639875666666666, longitude: 127.37653753333333, timestamp: "2024-06-06T16:03:40.000Z" },
        { speed: "0.0", course: "251.6", altitude: "-2.9", satellites: "11", hdop: "0.5", lat: "0045.839254", lon: "12722.592252", latD: "N", lonD: "E", latitude: 0.7639875666666666, longitude: 127.37653753333333, timestamp: "2024-06-06T16:03:40.000Z" },
        { speed: "0.0", course: "251.6", altitude: "-2.9", satellites: "11", hdop: "0.5", lat: "0045.839254", lon: "12722.592252", latD: "N", lonD: "E", latitude: 0.7639875666666666, longitude: 127.37653753333333, timestamp: "2024-06-06T16:03:40.000Z" },
        { speed: "0.0", course: "251.6", altitude: "-2.9", satellites: "11", hdop: "0.5", lat: "0045.839254", lon: "12722.592252", latD: "N", lonD: "E", latitude: 0.7639875666666666, longitude: 127.37653753333333, timestamp: "2024-06-06T16:03:40.000Z" },
        { speed: "0.0", course: "36.9", altitude: "-4.6", satellites: "11", hdop: "0.5", lat: "0045.837728", lon: "12722.592541", latD: "N", lonD: "E", latitude: 0.7639621333333333, longitude: 127.37654235, timestamp: "2024-06-06T16:04:01.000Z" },
        { speed: "0.0", course: "36.9", altitude: "-4.6", satellites: "11", hdop: "0.5", lat: "0045.837728", lon: "12722.592541", latD: "N", lonD: "E", latitude: 0.7639621333333333, longitude: 127.37654235, timestamp: "2024-06-06T16:04:01.000Z" },
        { speed: "0.0", course: "36.9", altitude: "-4.6", satellites: "11", hdop: "0.5", lat: "0045.837728", lon: "12722.592541", latD: "N", lonD: "E", latitude: 0.7639621333333333, longitude: 127.37654235, timestamp: "2024-06-06T16:04:01.000Z" },
        { speed: "0.0", course: "36.9", altitude: "-4.6", satellites: "11", hdop: "0.5", lat: "0045.837728", lon: "12722.592541", latD: "N", lonD: "E", latitude: 0.7639621333333333, longitude: 127.37654235, timestamp: "2024-06-06T16:04:01.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.6", satellites: "11", hdop: "0.5", lat: "0045.837181", lon: "12722.592389", latD: "N", lonD: "E", latitude: 0.7639530166666667, longitude: 127.37653981666666, timestamp: "2024-06-06T16:04:20.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.6", satellites: "11", hdop: "0.5", lat: "0045.837181", lon: "12722.592389", latD: "N", lonD: "E", latitude: 0.7639530166666667, longitude: 127.37653981666666, timestamp: "2024-06-06T16:04:20.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.6", satellites: "11", hdop: "0.5", lat: "0045.837181", lon: "12722.592389", latD: "N", lonD: "E", latitude: 0.7639530166666667, longitude: 127.37653981666666, timestamp: "2024-06-06T16:04:20.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.6", satellites: "11", hdop: "0.5", lat: "0045.837181", lon: "12722.592389", latD: "N", lonD: "E", latitude: 0.7639530166666667, longitude: 127.37653981666666, timestamp: "2024-06-06T16:04:20.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.5", satellites: "11", hdop: "0.5", lat: "0045.837165", lon: "12722.592430", latD: "N", lonD: "E", latitude: 0.76395275, longitude: 127.3765405, timestamp: "2024-06-06T16:04:41.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.5", satellites: "11", hdop: "0.5", lat: "0045.837165", lon: "12722.592430", latD: "N", lonD: "E", latitude: 0.76395275, longitude: 127.3765405, timestamp: "2024-06-06T16:04:41.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.5", satellites: "11", hdop: "0.5", lat: "0045.837165", lon: "12722.592430", latD: "N", lonD: "E", latitude: 0.76395275, longitude: 127.3765405, timestamp: "2024-06-06T16:04:41.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.5", satellites: "11", hdop: "0.5", lat: "0045.837165", lon: "12722.592430", latD: "N", lonD: "E", latitude: 0.76395275, longitude: 127.3765405, timestamp: "2024-06-06T16:04:41.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.5", satellites: "10", hdop: "0.5", lat: "0045.837160", lon: "12722.592473", latD: "N", lonD: "E", latitude: 0.7639526666666666, longitude: 127.37654121666667, timestamp: "2024-06-06T16:05:00.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.5", satellites: "10", hdop: "0.5", lat: "0045.837160", lon: "12722.592473", latD: "N", lonD: "E", latitude: 0.7639526666666666, longitude: 127.37654121666667, timestamp: "2024-06-06T16:05:00.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.5", satellites: "10", hdop: "0.5", lat: "0045.837160", lon: "12722.592473", latD: "N", lonD: "E", latitude: 0.7639526666666666, longitude: 127.37654121666667, timestamp: "2024-06-06T16:05:00.000Z" },
        { speed: "0.0", course: "275.0", altitude: "-5.5", satellites: "10", hdop: "0.5", lat: "0045.837160", lon: "12722.592473", latD: "N", lonD: "E", latitude: 0.7639526666666666, longitude: 127.37654121666667, timestamp: "2024-06-06T16:05:00.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.4", satellites: "10", hdop: "0.6", lat: "0045.838193", lon: "12722.592598", latD: "N", lonD: "E", latitude: 0.7639698833333333, longitude: 127.3765433, timestamp: "2024-06-06T16:05:20.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.4", satellites: "10", hdop: "0.6", lat: "0045.838193", lon: "12722.592598", latD: "N", lonD: "E", latitude: 0.7639698833333333, longitude: 127.3765433, timestamp: "2024-06-06T16:05:20.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.4", satellites: "10", hdop: "0.6", lat: "0045.838193", lon: "12722.592598", latD: "N", lonD: "E", latitude: 0.7639698833333333, longitude: 127.3765433, timestamp: "2024-06-06T16:05:20.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.4", satellites: "10", hdop: "0.6", lat: "0045.838193", lon: "12722.592598", latD: "N", lonD: "E", latitude: 0.7639698833333333, longitude: 127.3765433, timestamp: "2024-06-06T16:05:20.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.4", satellites: "08", hdop: "0.6", lat: "0045.838287", lon: "12722.592685", latD: "N", lonD: "E", latitude: 0.76397145, longitude: 127.37654475, timestamp: "2024-06-06T16:05:40.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.4", satellites: "08", hdop: "0.6", lat: "0045.838287", lon: "12722.592685", latD: "N", lonD: "E", latitude: 0.76397145, longitude: 127.37654475, timestamp: "2024-06-06T16:05:40.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.4", satellites: "08", hdop: "0.6", lat: "0045.838287", lon: "12722.592685", latD: "N", lonD: "E", latitude: 0.76397145, longitude: 127.37654475, timestamp: "2024-06-06T16:05:40.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.3", satellites: "10", hdop: "0.6", lat: "0045.838298", lon: "12722.592789", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37654648333333, timestamp: "2024-06-06T16:06:00.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.4", satellites: "08", hdop: "0.6", lat: "0045.838287", lon: "12722.592685", latD: "N", lonD: "E", latitude: 0.76397145, longitude: 127.37654475, timestamp: "2024-06-06T16:05:40.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.3", satellites: "10", hdop: "0.6", lat: "0045.838298", lon: "12722.592789", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37654648333333, timestamp: "2024-06-06T16:06:00.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.3", satellites: "10", hdop: "0.6", lat: "0045.838298", lon: "12722.592789", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37654648333333, timestamp: "2024-06-06T16:06:00.000Z" },
        { speed: "0.0", course: "76.2", altitude: "-4.3", satellites: "10", hdop: "0.6", lat: "0045.838298", lon: "12722.592789", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37654648333333, timestamp: "2024-06-06T16:06:00.000Z" },
        { speed: "0.0", course: "52.4", altitude: "2.6", satellites: "09", hdop: "0.5", lat: "0045.838409", lon: "12722.595252", latD: "N", lonD: "E", latitude: 0.7639734833333333, longitude: 127.37658753333334, timestamp: "2024-06-06T16:06:20.000Z" },
        { speed: "0.0", course: "52.4", altitude: "2.6", satellites: "09", hdop: "0.5", lat: "0045.838409", lon: "12722.595252", latD: "N", lonD: "E", latitude: 0.7639734833333333, longitude: 127.37658753333334, timestamp: "2024-06-06T16:06:20.000Z" },
        { speed: "0.0", course: "52.4", altitude: "2.6", satellites: "09", hdop: "0.5", lat: "0045.838409", lon: "12722.595252", latD: "N", lonD: "E", latitude: 0.7639734833333333, longitude: 127.37658753333334, timestamp: "2024-06-06T16:06:20.000Z" },
        { speed: "0.0", course: "52.4", altitude: "2.6", satellites: "09", hdop: "0.5", lat: "0045.838409", lon: "12722.595252", latD: "N", lonD: "E", latitude: 0.7639734833333333, longitude: 127.37658753333334, timestamp: "2024-06-06T16:06:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "09", hdop: "0.5", lat: "0045.838814", lon: "12722.595417", latD: "N", lonD: "E", latitude: 0.7639802333333333, longitude: 127.37659028333333, timestamp: "2024-06-06T16:06:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "09", hdop: "0.5", lat: "0045.838814", lon: "12722.595417", latD: "N", lonD: "E", latitude: 0.7639802333333333, longitude: 127.37659028333333, timestamp: "2024-06-06T16:06:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "09", hdop: "0.5", lat: "0045.838814", lon: "12722.595417", latD: "N", lonD: "E", latitude: 0.7639802333333333, longitude: 127.37659028333333, timestamp: "2024-06-06T16:06:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "09", hdop: "0.5", lat: "0045.838814", lon: "12722.595417", latD: "N", lonD: "E", latitude: 0.7639802333333333, longitude: 127.37659028333333, timestamp: "2024-06-06T16:06:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.838895", lon: "12722.594902", latD: "N", lonD: "E", latitude: 0.7639815833333333, longitude: 127.3765817, timestamp: "2024-06-06T16:07:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.838895", lon: "12722.594902", latD: "N", lonD: "E", latitude: 0.7639815833333333, longitude: 127.3765817, timestamp: "2024-06-06T16:07:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.838895", lon: "12722.594902", latD: "N", lonD: "E", latitude: 0.7639815833333333, longitude: 127.3765817, timestamp: "2024-06-06T16:07:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.838895", lon: "12722.594902", latD: "N", lonD: "E", latitude: 0.7639815833333333, longitude: 127.3765817, timestamp: "2024-06-06T16:07:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.838890", lon: "12722.594935", latD: "N", lonD: "E", latitude: 0.7639815, longitude: 127.37658225, timestamp: "2024-06-06T16:07:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.838890", lon: "12722.594935", latD: "N", lonD: "E", latitude: 0.7639815, longitude: 127.37658225, timestamp: "2024-06-06T16:07:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.838890", lon: "12722.594935", latD: "N", lonD: "E", latitude: 0.7639815, longitude: 127.37658225, timestamp: "2024-06-06T16:07:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.838890", lon: "12722.594935", latD: "N", lonD: "E", latitude: 0.7639815, longitude: 127.37658225, timestamp: "2024-06-06T16:07:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "08", hdop: "0.7", lat: "0045.838909", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639818166666666, longitude: 127.37658201666666, timestamp: "2024-06-06T16:07:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "08", hdop: "0.7", lat: "0045.838909", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639818166666666, longitude: 127.37658201666666, timestamp: "2024-06-06T16:07:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "08", hdop: "0.7", lat: "0045.838909", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639818166666666, longitude: 127.37658201666666, timestamp: "2024-06-06T16:07:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.6", satellites: "08", hdop: "0.7", lat: "0045.838909", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639818166666666, longitude: 127.37658201666666, timestamp: "2024-06-06T16:07:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.9", satellites: "09", hdop: "0.6", lat: "0045.838917", lon: "12722.595025", latD: "N", lonD: "E", latitude: 0.76398195, longitude: 127.37658375, timestamp: "2024-06-06T16:08:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.9", satellites: "09", hdop: "0.6", lat: "0045.838917", lon: "12722.595025", latD: "N", lonD: "E", latitude: 0.76398195, longitude: 127.37658375, timestamp: "2024-06-06T16:08:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.9", satellites: "09", hdop: "0.6", lat: "0045.838917", lon: "12722.595025", latD: "N", lonD: "E", latitude: 0.76398195, longitude: 127.37658375, timestamp: "2024-06-06T16:08:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "1.9", satellites: "09", hdop: "0.6", lat: "0045.838917", lon: "12722.595025", latD: "N", lonD: "E", latitude: 0.76398195, longitude: 127.37658375, timestamp: "2024-06-06T16:08:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.838908", lon: "12722.594943", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37658238333333, timestamp: "2024-06-06T16:08:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.838908", lon: "12722.594943", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37658238333333, timestamp: "2024-06-06T16:08:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.838908", lon: "12722.594943", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37658238333333, timestamp: "2024-06-06T16:08:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.838908", lon: "12722.594943", latD: "N", lonD: "E", latitude: 0.7639818, longitude: 127.37658238333333, timestamp: "2024-06-06T16:08:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.6", lat: "0045.838883", lon: "12722.594981", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37658301666667, timestamp: "2024-06-06T16:08:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.6", lat: "0045.838883", lon: "12722.594981", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37658301666667, timestamp: "2024-06-06T16:08:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.6", lat: "0045.838883", lon: "12722.594981", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37658301666667, timestamp: "2024-06-06T16:08:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.6", lat: "0045.838883", lon: "12722.594981", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37658301666667, timestamp: "2024-06-06T16:08:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "11", hdop: "0.6", lat: "0045.838876", lon: "12722.595001", latD: "N", lonD: "E", latitude: 0.7639812666666667, longitude: 127.37658335, timestamp: "2024-06-06T16:09:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "11", hdop: "0.6", lat: "0045.838876", lon: "12722.595001", latD: "N", lonD: "E", latitude: 0.7639812666666667, longitude: 127.37658335, timestamp: "2024-06-06T16:09:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "11", hdop: "0.6", lat: "0045.838876", lon: "12722.595001", latD: "N", lonD: "E", latitude: 0.7639812666666667, longitude: 127.37658335, timestamp: "2024-06-06T16:09:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "11", hdop: "0.6", lat: "0045.838876", lon: "12722.595001", latD: "N", lonD: "E", latitude: 0.7639812666666667, longitude: 127.37658335, timestamp: "2024-06-06T16:09:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838886", lon: "12722.595017", latD: "N", lonD: "E", latitude: 0.7639814333333333, longitude: 127.37658361666666, timestamp: "2024-06-06T16:09:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838886", lon: "12722.595017", latD: "N", lonD: "E", latitude: 0.7639814333333333, longitude: 127.37658361666666, timestamp: "2024-06-06T16:09:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838886", lon: "12722.595017", latD: "N", lonD: "E", latitude: 0.7639814333333333, longitude: 127.37658361666666, timestamp: "2024-06-06T16:09:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838886", lon: "12722.595017", latD: "N", lonD: "E", latitude: 0.7639814333333333, longitude: 127.37658361666666, timestamp: "2024-06-06T16:09:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.7", lat: "0045.838892", lon: "12722.595018", latD: "N", lonD: "E", latitude: 0.7639815333333334, longitude: 127.37658363333334, timestamp: "2024-06-06T16:09:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.7", lat: "0045.838892", lon: "12722.595018", latD: "N", lonD: "E", latitude: 0.7639815333333334, longitude: 127.37658363333334, timestamp: "2024-06-06T16:09:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.7", lat: "0045.838892", lon: "12722.595018", latD: "N", lonD: "E", latitude: 0.7639815333333334, longitude: 127.37658363333334, timestamp: "2024-06-06T16:09:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "09", hdop: "0.7", lat: "0045.838887", lon: "12722.595012", latD: "N", lonD: "E", latitude: 0.76398145, longitude: 127.37658353333333, timestamp: "2024-06-06T16:10:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.7", lat: "0045.838892", lon: "12722.595018", latD: "N", lonD: "E", latitude: 0.7639815333333334, longitude: 127.37658363333334, timestamp: "2024-06-06T16:09:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "09", hdop: "0.7", lat: "0045.838887", lon: "12722.595012", latD: "N", lonD: "E", latitude: 0.76398145, longitude: 127.37658353333333, timestamp: "2024-06-06T16:10:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "09", hdop: "0.7", lat: "0045.838887", lon: "12722.595012", latD: "N", lonD: "E", latitude: 0.76398145, longitude: 127.37658353333333, timestamp: "2024-06-06T16:10:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "09", hdop: "0.7", lat: "0045.838887", lon: "12722.595012", latD: "N", lonD: "E", latitude: 0.76398145, longitude: 127.37658353333333, timestamp: "2024-06-06T16:10:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.838883", lon: "12722.595013", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37658355, timestamp: "2024-06-06T16:10:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.838883", lon: "12722.595013", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37658355, timestamp: "2024-06-06T16:10:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.838883", lon: "12722.595013", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37658355, timestamp: "2024-06-06T16:10:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.838883", lon: "12722.595013", latD: "N", lonD: "E", latitude: 0.7639813833333334, longitude: 127.37658355, timestamp: "2024-06-06T16:10:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838889", lon: "12722.595023", latD: "N", lonD: "E", latitude: 0.7639814833333334, longitude: 127.37658371666667, timestamp: "2024-06-06T16:10:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838889", lon: "12722.595023", latD: "N", lonD: "E", latitude: 0.7639814833333334, longitude: 127.37658371666667, timestamp: "2024-06-06T16:10:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838889", lon: "12722.595023", latD: "N", lonD: "E", latitude: 0.7639814833333334, longitude: 127.37658371666667, timestamp: "2024-06-06T16:10:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838889", lon: "12722.595023", latD: "N", lonD: "E", latitude: 0.7639814833333334, longitude: 127.37658371666667, timestamp: "2024-06-06T16:10:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "11", hdop: "0.5", lat: "0045.838889", lon: "12722.595025", latD: "N", lonD: "E", latitude: 0.7639814833333334, longitude: 127.37658375, timestamp: "2024-06-06T16:11:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "11", hdop: "0.5", lat: "0045.838889", lon: "12722.595025", latD: "N", lonD: "E", latitude: 0.7639814833333334, longitude: 127.37658375, timestamp: "2024-06-06T16:11:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "11", hdop: "0.5", lat: "0045.838889", lon: "12722.595025", latD: "N", lonD: "E", latitude: 0.7639814833333334, longitude: 127.37658375, timestamp: "2024-06-06T16:11:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "11", hdop: "0.5", lat: "0045.838889", lon: "12722.595025", latD: "N", lonD: "E", latitude: 0.7639814833333334, longitude: 127.37658375, timestamp: "2024-06-06T16:11:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838880", lon: "12722.595035", latD: "N", lonD: "E", latitude: 0.7639813333333334, longitude: 127.37658391666666, timestamp: "2024-06-06T16:11:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838880", lon: "12722.595035", latD: "N", lonD: "E", latitude: 0.7639813333333334, longitude: 127.37658391666666, timestamp: "2024-06-06T16:11:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838880", lon: "12722.595035", latD: "N", lonD: "E", latitude: 0.7639813333333334, longitude: 127.37658391666666, timestamp: "2024-06-06T16:11:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838880", lon: "12722.595035", latD: "N", lonD: "E", latitude: 0.7639813333333334, longitude: 127.37658391666666, timestamp: "2024-06-06T16:11:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838854", lon: "12722.595030", latD: "N", lonD: "E", latitude: 0.7639809, longitude: 127.37658383333333, timestamp: "2024-06-06T16:11:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838854", lon: "12722.595030", latD: "N", lonD: "E", latitude: 0.7639809, longitude: 127.37658383333333, timestamp: "2024-06-06T16:11:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838854", lon: "12722.595030", latD: "N", lonD: "E", latitude: 0.7639809, longitude: 127.37658383333333, timestamp: "2024-06-06T16:11:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "10", hdop: "0.6", lat: "0045.838854", lon: "12722.595030", latD: "N", lonD: "E", latitude: 0.7639809, longitude: 127.37658383333333, timestamp: "2024-06-06T16:11:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.6", lat: "0045.838851", lon: "12722.595038", latD: "N", lonD: "E", latitude: 0.7639808499999999, longitude: 127.37658396666667, timestamp: "2024-06-06T16:12:02.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.6", lat: "0045.838851", lon: "12722.595038", latD: "N", lonD: "E", latitude: 0.7639808499999999, longitude: 127.37658396666667, timestamp: "2024-06-06T16:12:02.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.6", lat: "0045.838851", lon: "12722.595038", latD: "N", lonD: "E", latitude: 0.7639808499999999, longitude: 127.37658396666667, timestamp: "2024-06-06T16:12:02.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.2", satellites: "09", hdop: "0.6", lat: "0045.838851", lon: "12722.595038", latD: "N", lonD: "E", latitude: 0.7639808499999999, longitude: 127.37658396666667, timestamp: "2024-06-06T16:12:02.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.838856", lon: "12722.595068", latD: "N", lonD: "E", latitude: 0.7639809333333333, longitude: 127.37658446666667, timestamp: "2024-06-06T16:12:22.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.838856", lon: "12722.595068", latD: "N", lonD: "E", latitude: 0.7639809333333333, longitude: 127.37658446666667, timestamp: "2024-06-06T16:12:22.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.838856", lon: "12722.595068", latD: "N", lonD: "E", latitude: 0.7639809333333333, longitude: 127.37658446666667, timestamp: "2024-06-06T16:12:22.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.3", satellites: "10", hdop: "0.6", lat: "0045.838856", lon: "12722.595068", latD: "N", lonD: "E", latitude: 0.7639809333333333, longitude: 127.37658446666667, timestamp: "2024-06-06T16:12:22.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.838800", lon: "12722.595107", latD: "N", lonD: "E", latitude: 0.76398, longitude: 127.37658511666666, timestamp: "2024-06-06T16:14:44.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.838854", lon: "12722.595111", latD: "N", lonD: "E", latitude: 0.7639809, longitude: 127.37658518333333, timestamp: "2024-06-06T16:13:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.838854", lon: "12722.595111", latD: "N", lonD: "E", latitude: 0.7639809, longitude: 127.37658518333333, timestamp: "2024-06-06T16:13:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.838854", lon: "12722.595088", latD: "N", lonD: "E", latitude: 0.7639809, longitude: 127.3765848, timestamp: "2024-06-06T16:12:45.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.838854", lon: "12722.595088", latD: "N", lonD: "E", latitude: 0.7639809, longitude: 127.3765848, timestamp: "2024-06-06T16:12:45.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.838854", lon: "12722.595111", latD: "N", lonD: "E", latitude: 0.7639809, longitude: 127.37658518333333, timestamp: "2024-06-06T16:13:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.838854", lon: "12722.595111", latD: "N", lonD: "E", latitude: 0.7639809, longitude: 127.37658518333333, timestamp: "2024-06-06T16:13:00.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.838846", lon: "12722.595099", latD: "N", lonD: "E", latitude: 0.7639807666666666, longitude: 127.37658498333333, timestamp: "2024-06-06T16:13:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.838846", lon: "12722.595099", latD: "N", lonD: "E", latitude: 0.7639807666666666, longitude: 127.37658498333333, timestamp: "2024-06-06T16:13:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.838837", lon: "12722.595080", latD: "N", lonD: "E", latitude: 0.7639806166666666, longitude: 127.37658466666667, timestamp: "2024-06-06T16:13:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.838837", lon: "12722.595080", latD: "N", lonD: "E", latitude: 0.7639806166666666, longitude: 127.37658466666667, timestamp: "2024-06-06T16:13:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.838837", lon: "12722.595080", latD: "N", lonD: "E", latitude: 0.7639806166666666, longitude: 127.37658466666667, timestamp: "2024-06-06T16:13:40.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.838829", lon: "12722.595081", latD: "N", lonD: "E", latitude: 0.7639804833333332, longitude: 127.37658468333333, timestamp: "2024-06-06T16:14:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.838829", lon: "12722.595081", latD: "N", lonD: "E", latitude: 0.7639804833333332, longitude: 127.37658468333333, timestamp: "2024-06-06T16:14:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.838829", lon: "12722.595081", latD: "N", lonD: "E", latitude: 0.7639804833333332, longitude: 127.37658468333333, timestamp: "2024-06-06T16:14:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.6", satellites: "10", hdop: "0.6", lat: "0045.838829", lon: "12722.595081", latD: "N", lonD: "E", latitude: 0.7639804833333332, longitude: 127.37658468333333, timestamp: "2024-06-06T16:14:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.838818", lon: "12722.595090", latD: "N", lonD: "E", latitude: 0.7639803000000001, longitude: 127.37658483333334, timestamp: "2024-06-06T16:14:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.838818", lon: "12722.595090", latD: "N", lonD: "E", latitude: 0.7639803000000001, longitude: 127.37658483333334, timestamp: "2024-06-06T16:14:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.838818", lon: "12722.595090", latD: "N", lonD: "E", latitude: 0.7639803000000001, longitude: 127.37658483333334, timestamp: "2024-06-06T16:14:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.838818", lon: "12722.595090", latD: "N", lonD: "E", latitude: 0.7639803000000001, longitude: 127.37658483333334, timestamp: "2024-06-06T16:14:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.838818", lon: "12722.595090", latD: "N", lonD: "E", latitude: 0.7639803000000001, longitude: 127.37658483333334, timestamp: "2024-06-06T16:14:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.838800", lon: "12722.595107", latD: "N", lonD: "E", latitude: 0.76398, longitude: 127.37658511666666, timestamp: "2024-06-06T16:14:44.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.838800", lon: "12722.595107", latD: "N", lonD: "E", latitude: 0.76398, longitude: 127.37658511666666, timestamp: "2024-06-06T16:14:44.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.5", satellites: "10", hdop: "0.6", lat: "0045.838846", lon: "12722.595099", latD: "N", lonD: "E", latitude: 0.7639807666666666, longitude: 127.37658498333333, timestamp: "2024-06-06T16:13:20.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "09", hdop: "0.6", lat: "0045.838778", lon: "12722.595108", latD: "N", lonD: "E", latitude: 0.7639796333333333, longitude: 127.37658513333334, timestamp: "2024-06-06T16:15:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "09", hdop: "0.6", lat: "0045.838778", lon: "12722.595108", latD: "N", lonD: "E", latitude: 0.7639796333333333, longitude: 127.37658513333334, timestamp: "2024-06-06T16:15:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "09", hdop: "0.6", lat: "0045.838778", lon: "12722.595108", latD: "N", lonD: "E", latitude: 0.7639796333333333, longitude: 127.37658513333334, timestamp: "2024-06-06T16:15:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "09", hdop: "0.6", lat: "0045.838778", lon: "12722.595108", latD: "N", lonD: "E", latitude: 0.7639796333333333, longitude: 127.37658513333334, timestamp: "2024-06-06T16:15:21.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.838768", lon: "12722.595145", latD: "N", lonD: "E", latitude: 0.7639794666666667, longitude: 127.37658575, timestamp: "2024-06-06T16:15:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.838768", lon: "12722.595145", latD: "N", lonD: "E", latitude: 0.7639794666666667, longitude: 127.37658575, timestamp: "2024-06-06T16:15:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.838768", lon: "12722.595145", latD: "N", lonD: "E", latitude: 0.7639794666666667, longitude: 127.37658575, timestamp: "2024-06-06T16:15:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.838768", lon: "12722.595145", latD: "N", lonD: "E", latitude: 0.7639794666666667, longitude: 127.37658575, timestamp: "2024-06-06T16:15:41.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.838755", lon: "12722.595110", latD: "N", lonD: "E", latitude: 0.76397925, longitude: 127.37658516666667, timestamp: "2024-06-06T16:16:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.838755", lon: "12722.595110", latD: "N", lonD: "E", latitude: 0.76397925, longitude: 127.37658516666667, timestamp: "2024-06-06T16:16:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.838755", lon: "12722.595110", latD: "N", lonD: "E", latitude: 0.76397925, longitude: 127.37658516666667, timestamp: "2024-06-06T16:16:01.000Z" },
        { speed: "0.0", course: "179.2", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.838755", lon: "12722.595110", latD: "N", lonD: "E", latitude: 0.76397925, longitude: 127.37658516666667, timestamp: "2024-06-06T16:16:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.3", satellites: "11", hdop: "0.5", lat: "0045.836903", lon: "12722.591987", latD: "N", lonD: "E", latitude: 0.7639483833333334, longitude: 127.37653311666666, timestamp: "2024-06-06T16:16:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.3", satellites: "11", hdop: "0.5", lat: "0045.836903", lon: "12722.591987", latD: "N", lonD: "E", latitude: 0.7639483833333334, longitude: 127.37653311666666, timestamp: "2024-06-06T16:16:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.3", satellites: "11", hdop: "0.5", lat: "0045.836903", lon: "12722.591987", latD: "N", lonD: "E", latitude: 0.7639483833333334, longitude: 127.37653311666666, timestamp: "2024-06-06T16:16:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.3", satellites: "11", hdop: "0.5", lat: "0045.836903", lon: "12722.591987", latD: "N", lonD: "E", latitude: 0.7639483833333334, longitude: 127.37653311666666, timestamp: "2024-06-06T16:16:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.8", satellites: "11", hdop: "0.5", lat: "0045.836974", lon: "12722.591657", latD: "N", lonD: "E", latitude: 0.7639495666666666, longitude: 127.37652761666666, timestamp: "2024-06-06T16:16:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.8", satellites: "11", hdop: "0.5", lat: "0045.836974", lon: "12722.591657", latD: "N", lonD: "E", latitude: 0.7639495666666666, longitude: 127.37652761666666, timestamp: "2024-06-06T16:16:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.8", satellites: "11", hdop: "0.5", lat: "0045.836974", lon: "12722.591657", latD: "N", lonD: "E", latitude: 0.7639495666666666, longitude: 127.37652761666666, timestamp: "2024-06-06T16:16:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.8", satellites: "11", hdop: "0.5", lat: "0045.836974", lon: "12722.591657", latD: "N", lonD: "E", latitude: 0.7639495666666666, longitude: 127.37652761666666, timestamp: "2024-06-06T16:16:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.836987", lon: "12722.591745", latD: "N", lonD: "E", latitude: 0.7639497833333333, longitude: 127.37652908333334, timestamp: "2024-06-06T16:17:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.836987", lon: "12722.591745", latD: "N", lonD: "E", latitude: 0.7639497833333333, longitude: 127.37652908333334, timestamp: "2024-06-06T16:17:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.836987", lon: "12722.591745", latD: "N", lonD: "E", latitude: 0.7639497833333333, longitude: 127.37652908333334, timestamp: "2024-06-06T16:17:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "1.9", satellites: "10", hdop: "0.6", lat: "0045.836987", lon: "12722.591745", latD: "N", lonD: "E", latitude: 0.7639497833333333, longitude: 127.37652908333334, timestamp: "2024-06-06T16:17:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.836996", lon: "12722.591819", latD: "N", lonD: "E", latitude: 0.7639499333333333, longitude: 127.37653031666666, timestamp: "2024-06-06T16:17:22.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.836996", lon: "12722.591819", latD: "N", lonD: "E", latitude: 0.7639499333333333, longitude: 127.37653031666666, timestamp: "2024-06-06T16:17:22.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.836996", lon: "12722.591819", latD: "N", lonD: "E", latitude: 0.7639499333333333, longitude: 127.37653031666666, timestamp: "2024-06-06T16:17:22.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.1", satellites: "10", hdop: "0.6", lat: "0045.836996", lon: "12722.591819", latD: "N", lonD: "E", latitude: 0.7639499333333333, longitude: 127.37653031666666, timestamp: "2024-06-06T16:17:22.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.837080", lon: "12722.591888", latD: "N", lonD: "E", latitude: 0.7639513333333333, longitude: 127.37653146666666, timestamp: "2024-06-06T16:17:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.837080", lon: "12722.591888", latD: "N", lonD: "E", latitude: 0.7639513333333333, longitude: 127.37653146666666, timestamp: "2024-06-06T16:17:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.837080", lon: "12722.591888", latD: "N", lonD: "E", latitude: 0.7639513333333333, longitude: 127.37653146666666, timestamp: "2024-06-06T16:17:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.837080", lon: "12722.591888", latD: "N", lonD: "E", latitude: 0.7639513333333333, longitude: 127.37653146666666, timestamp: "2024-06-06T16:17:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "08", hdop: "0.6", lat: "0045.837134", lon: "12722.591964", latD: "N", lonD: "E", latitude: 0.7639522333333333, longitude: 127.37653273333333, timestamp: "2024-06-06T16:18:02.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "08", hdop: "0.6", lat: "0045.837134", lon: "12722.591964", latD: "N", lonD: "E", latitude: 0.7639522333333333, longitude: 127.37653273333333, timestamp: "2024-06-06T16:18:02.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "08", hdop: "0.6", lat: "0045.837134", lon: "12722.591964", latD: "N", lonD: "E", latitude: 0.7639522333333333, longitude: 127.37653273333333, timestamp: "2024-06-06T16:18:02.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "08", hdop: "0.6", lat: "0045.837134", lon: "12722.591964", latD: "N", lonD: "E", latitude: 0.7639522333333333, longitude: 127.37653273333333, timestamp: "2024-06-06T16:18:02.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837127", lon: "12722.591971", latD: "N", lonD: "E", latitude: 0.7639521166666667, longitude: 127.37653285, timestamp: "2024-06-06T16:18:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837127", lon: "12722.591971", latD: "N", lonD: "E", latitude: 0.7639521166666667, longitude: 127.37653285, timestamp: "2024-06-06T16:18:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837127", lon: "12722.591971", latD: "N", lonD: "E", latitude: 0.7639521166666667, longitude: 127.37653285, timestamp: "2024-06-06T16:18:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837127", lon: "12722.591971", latD: "N", lonD: "E", latitude: 0.7639521166666667, longitude: 127.37653285, timestamp: "2024-06-06T16:18:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837133", lon: "12722.592018", latD: "N", lonD: "E", latitude: 0.7639522166666667, longitude: 127.37653363333334, timestamp: "2024-06-06T16:18:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837133", lon: "12722.592018", latD: "N", lonD: "E", latitude: 0.7639522166666667, longitude: 127.37653363333334, timestamp: "2024-06-06T16:18:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837133", lon: "12722.592018", latD: "N", lonD: "E", latitude: 0.7639522166666667, longitude: 127.37653363333334, timestamp: "2024-06-06T16:18:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837133", lon: "12722.592018", latD: "N", lonD: "E", latitude: 0.7639522166666667, longitude: 127.37653363333334, timestamp: "2024-06-06T16:18:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837147", lon: "12722.592032", latD: "N", lonD: "E", latitude: 0.76395245, longitude: 127.37653386666666, timestamp: "2024-06-06T16:19:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837147", lon: "12722.592032", latD: "N", lonD: "E", latitude: 0.76395245, longitude: 127.37653386666666, timestamp: "2024-06-06T16:19:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837147", lon: "12722.592032", latD: "N", lonD: "E", latitude: 0.76395245, longitude: 127.37653386666666, timestamp: "2024-06-06T16:19:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.6", satellites: "09", hdop: "0.6", lat: "0045.837147", lon: "12722.592032", latD: "N", lonD: "E", latitude: 0.76395245, longitude: 127.37653386666666, timestamp: "2024-06-06T16:19:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.837157", lon: "12722.592079", latD: "N", lonD: "E", latitude: 0.7639526166666666, longitude: 127.37653465, timestamp: "2024-06-06T16:19:22.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.837157", lon: "12722.592079", latD: "N", lonD: "E", latitude: 0.7639526166666666, longitude: 127.37653465, timestamp: "2024-06-06T16:19:22.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.837157", lon: "12722.592079", latD: "N", lonD: "E", latitude: 0.7639526166666666, longitude: 127.37653465, timestamp: "2024-06-06T16:19:22.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.837157", lon: "12722.592079", latD: "N", lonD: "E", latitude: 0.7639526166666666, longitude: 127.37653465, timestamp: "2024-06-06T16:19:22.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "08", hdop: "0.6", lat: "0045.837153", lon: "12722.592118", latD: "N", lonD: "E", latitude: 0.7639525500000001, longitude: 127.3765353, timestamp: "2024-06-06T16:19:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "08", hdop: "0.6", lat: "0045.837153", lon: "12722.592118", latD: "N", lonD: "E", latitude: 0.7639525500000001, longitude: 127.3765353, timestamp: "2024-06-06T16:19:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "08", hdop: "0.6", lat: "0045.837153", lon: "12722.592118", latD: "N", lonD: "E", latitude: 0.7639525500000001, longitude: 127.3765353, timestamp: "2024-06-06T16:19:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "08", hdop: "0.6", lat: "0045.837153", lon: "12722.592118", latD: "N", lonD: "E", latitude: 0.7639525500000001, longitude: 127.3765353, timestamp: "2024-06-06T16:19:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837162", lon: "12722.592161", latD: "N", lonD: "E", latitude: 0.7639527, longitude: 127.37653601666666, timestamp: "2024-06-06T16:20:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837162", lon: "12722.592161", latD: "N", lonD: "E", latitude: 0.7639527, longitude: 127.37653601666666, timestamp: "2024-06-06T16:20:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837162", lon: "12722.592161", latD: "N", lonD: "E", latitude: 0.7639527, longitude: 127.37653601666666, timestamp: "2024-06-06T16:20:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837162", lon: "12722.592161", latD: "N", lonD: "E", latitude: 0.7639527, longitude: 127.37653601666666, timestamp: "2024-06-06T16:20:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837140", lon: "12722.592188", latD: "N", lonD: "E", latitude: 0.7639523333333333, longitude: 127.37653646666666, timestamp: "2024-06-06T16:20:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837140", lon: "12722.592188", latD: "N", lonD: "E", latitude: 0.7639523333333333, longitude: 127.37653646666666, timestamp: "2024-06-06T16:20:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837140", lon: "12722.592188", latD: "N", lonD: "E", latitude: 0.7639523333333333, longitude: 127.37653646666666, timestamp: "2024-06-06T16:20:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837140", lon: "12722.592188", latD: "N", lonD: "E", latitude: 0.7639523333333333, longitude: 127.37653646666666, timestamp: "2024-06-06T16:20:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837122", lon: "12722.592202", latD: "N", lonD: "E", latitude: 0.7639520333333334, longitude: 127.3765367, timestamp: "2024-06-06T16:20:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837122", lon: "12722.592202", latD: "N", lonD: "E", latitude: 0.7639520333333334, longitude: 127.3765367, timestamp: "2024-06-06T16:20:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837122", lon: "12722.592202", latD: "N", lonD: "E", latitude: 0.7639520333333334, longitude: 127.3765367, timestamp: "2024-06-06T16:20:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837122", lon: "12722.592202", latD: "N", lonD: "E", latitude: 0.7639520333333334, longitude: 127.3765367, timestamp: "2024-06-06T16:20:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837131", lon: "12722.592243", latD: "N", lonD: "E", latitude: 0.7639521833333334, longitude: 127.37653738333333, timestamp: "2024-06-06T16:21:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837131", lon: "12722.592243", latD: "N", lonD: "E", latitude: 0.7639521833333334, longitude: 127.37653738333333, timestamp: "2024-06-06T16:21:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837131", lon: "12722.592243", latD: "N", lonD: "E", latitude: 0.7639521833333334, longitude: 127.37653738333333, timestamp: "2024-06-06T16:21:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.7", satellites: "10", hdop: "0.6", lat: "0045.837131", lon: "12722.592243", latD: "N", lonD: "E", latitude: 0.7639521833333334, longitude: 127.37653738333333, timestamp: "2024-06-06T16:21:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.837114", lon: "12722.592301", latD: "N", lonD: "E", latitude: 0.7639519, longitude: 127.37653835, timestamp: "2024-06-06T16:21:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.837114", lon: "12722.592301", latD: "N", lonD: "E", latitude: 0.7639519, longitude: 127.37653835, timestamp: "2024-06-06T16:21:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.837114", lon: "12722.592301", latD: "N", lonD: "E", latitude: 0.7639519, longitude: 127.37653835, timestamp: "2024-06-06T16:21:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "2.8", satellites: "10", hdop: "0.6", lat: "0045.837114", lon: "12722.592301", latD: "N", lonD: "E", latitude: 0.7639519, longitude: 127.37653835, timestamp: "2024-06-06T16:21:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.0", satellites: "09", hdop: "0.6", lat: "0045.837128", lon: "12722.592360", latD: "N", lonD: "E", latitude: 0.7639521333333333, longitude: 127.37653933333333, timestamp: "2024-06-06T16:21:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.0", satellites: "09", hdop: "0.6", lat: "0045.837128", lon: "12722.592360", latD: "N", lonD: "E", latitude: 0.7639521333333333, longitude: 127.37653933333333, timestamp: "2024-06-06T16:21:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.0", satellites: "09", hdop: "0.6", lat: "0045.837128", lon: "12722.592360", latD: "N", lonD: "E", latitude: 0.7639521333333333, longitude: 127.37653933333333, timestamp: "2024-06-06T16:21:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.0", satellites: "09", hdop: "0.6", lat: "0045.837128", lon: "12722.592360", latD: "N", lonD: "E", latitude: 0.7639521333333333, longitude: 127.37653933333333, timestamp: "2024-06-06T16:21:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.0", satellites: "10", hdop: "0.6", lat: "0045.837128", lon: "12722.592428", latD: "N", lonD: "E", latitude: 0.7639521333333333, longitude: 127.37654046666667, timestamp: "2024-06-06T16:22:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.0", satellites: "10", hdop: "0.6", lat: "0045.837128", lon: "12722.592428", latD: "N", lonD: "E", latitude: 0.7639521333333333, longitude: 127.37654046666667, timestamp: "2024-06-06T16:22:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.0", satellites: "10", hdop: "0.6", lat: "0045.837128", lon: "12722.592428", latD: "N", lonD: "E", latitude: 0.7639521333333333, longitude: 127.37654046666667, timestamp: "2024-06-06T16:22:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.0", satellites: "10", hdop: "0.6", lat: "0045.837128", lon: "12722.592428", latD: "N", lonD: "E", latitude: 0.7639521333333333, longitude: 127.37654046666667, timestamp: "2024-06-06T16:22:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.1", satellites: "10", hdop: "0.6", lat: "0045.837095", lon: "12722.592475", latD: "N", lonD: "E", latitude: 0.7639515833333334, longitude: 127.37654125, timestamp: "2024-06-06T16:22:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.1", satellites: "10", hdop: "0.6", lat: "0045.837095", lon: "12722.592475", latD: "N", lonD: "E", latitude: 0.7639515833333334, longitude: 127.37654125, timestamp: "2024-06-06T16:22:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.1", satellites: "10", hdop: "0.6", lat: "0045.837095", lon: "12722.592475", latD: "N", lonD: "E", latitude: 0.7639515833333334, longitude: 127.37654125, timestamp: "2024-06-06T16:22:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.2", satellites: "08", hdop: "0.6", lat: "0045.837096", lon: "12722.592522", latD: "N", lonD: "E", latitude: 0.7639516000000001, longitude: 127.37654203333334, timestamp: "2024-06-06T16:22:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.1", satellites: "10", hdop: "0.6", lat: "0045.837095", lon: "12722.592475", latD: "N", lonD: "E", latitude: 0.7639515833333334, longitude: 127.37654125, timestamp: "2024-06-06T16:22:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.2", satellites: "08", hdop: "0.6", lat: "0045.837096", lon: "12722.592522", latD: "N", lonD: "E", latitude: 0.7639516000000001, longitude: 127.37654203333334, timestamp: "2024-06-06T16:22:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.2", satellites: "08", hdop: "0.6", lat: "0045.837096", lon: "12722.592522", latD: "N", lonD: "E", latitude: 0.7639516000000001, longitude: 127.37654203333334, timestamp: "2024-06-06T16:22:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.2", satellites: "08", hdop: "0.6", lat: "0045.837096", lon: "12722.592522", latD: "N", lonD: "E", latitude: 0.7639516000000001, longitude: 127.37654203333334, timestamp: "2024-06-06T16:22:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837099", lon: "12722.592566", latD: "N", lonD: "E", latitude: 0.76395165, longitude: 127.37654276666666, timestamp: "2024-06-06T16:23:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837099", lon: "12722.592566", latD: "N", lonD: "E", latitude: 0.76395165, longitude: 127.37654276666666, timestamp: "2024-06-06T16:23:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837099", lon: "12722.592566", latD: "N", lonD: "E", latitude: 0.76395165, longitude: 127.37654276666666, timestamp: "2024-06-06T16:23:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837099", lon: "12722.592566", latD: "N", lonD: "E", latitude: 0.76395165, longitude: 127.37654276666666, timestamp: "2024-06-06T16:23:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837104", lon: "12722.592623", latD: "N", lonD: "E", latitude: 0.7639517333333333, longitude: 127.37654371666666, timestamp: "2024-06-06T16:23:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837104", lon: "12722.592623", latD: "N", lonD: "E", latitude: 0.7639517333333333, longitude: 127.37654371666666, timestamp: "2024-06-06T16:23:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837104", lon: "12722.592623", latD: "N", lonD: "E", latitude: 0.7639517333333333, longitude: 127.37654371666666, timestamp: "2024-06-06T16:23:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837104", lon: "12722.592623", latD: "N", lonD: "E", latitude: 0.7639517333333333, longitude: 127.37654371666666, timestamp: "2024-06-06T16:23:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "08", hdop: "0.6", lat: "0045.837116", lon: "12722.592666", latD: "N", lonD: "E", latitude: 0.7639519333333333, longitude: 127.37654443333334, timestamp: "2024-06-06T16:23:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "08", hdop: "0.6", lat: "0045.837116", lon: "12722.592666", latD: "N", lonD: "E", latitude: 0.7639519333333333, longitude: 127.37654443333334, timestamp: "2024-06-06T16:23:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "08", hdop: "0.6", lat: "0045.837116", lon: "12722.592666", latD: "N", lonD: "E", latitude: 0.7639519333333333, longitude: 127.37654443333334, timestamp: "2024-06-06T16:23:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837137", lon: "12722.592687", latD: "N", lonD: "E", latitude: 0.7639522833333333, longitude: 127.37654478333333, timestamp: "2024-06-06T16:24:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "08", hdop: "0.6", lat: "0045.837116", lon: "12722.592666", latD: "N", lonD: "E", latitude: 0.7639519333333333, longitude: 127.37654443333334, timestamp: "2024-06-06T16:23:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837137", lon: "12722.592687", latD: "N", lonD: "E", latitude: 0.7639522833333333, longitude: 127.37654478333333, timestamp: "2024-06-06T16:24:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837137", lon: "12722.592687", latD: "N", lonD: "E", latitude: 0.7639522833333333, longitude: 127.37654478333333, timestamp: "2024-06-06T16:24:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837137", lon: "12722.592687", latD: "N", lonD: "E", latitude: 0.7639522833333333, longitude: 127.37654478333333, timestamp: "2024-06-06T16:24:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "08", hdop: "0.6", lat: "0045.837153", lon: "12722.592714", latD: "N", lonD: "E", latitude: 0.7639525500000001, longitude: 127.37654523333333, timestamp: "2024-06-06T16:24:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "08", hdop: "0.6", lat: "0045.837153", lon: "12722.592714", latD: "N", lonD: "E", latitude: 0.7639525500000001, longitude: 127.37654523333333, timestamp: "2024-06-06T16:24:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "08", hdop: "0.6", lat: "0045.837153", lon: "12722.592714", latD: "N", lonD: "E", latitude: 0.7639525500000001, longitude: 127.37654523333333, timestamp: "2024-06-06T16:24:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "08", hdop: "0.6", lat: "0045.837153", lon: "12722.592714", latD: "N", lonD: "E", latitude: 0.7639525500000001, longitude: 127.37654523333333, timestamp: "2024-06-06T16:24:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "09", hdop: "0.6", lat: "0045.837168", lon: "12722.592738", latD: "N", lonD: "E", latitude: 0.7639528, longitude: 127.37654563333334, timestamp: "2024-06-06T16:24:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "09", hdop: "0.6", lat: "0045.837168", lon: "12722.592738", latD: "N", lonD: "E", latitude: 0.7639528, longitude: 127.37654563333334, timestamp: "2024-06-06T16:24:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "09", hdop: "0.6", lat: "0045.837168", lon: "12722.592738", latD: "N", lonD: "E", latitude: 0.7639528, longitude: 127.37654563333334, timestamp: "2024-06-06T16:24:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "08", hdop: "0.6", lat: "0045.837191", lon: "12722.592754", latD: "N", lonD: "E", latitude: 0.7639531833333333, longitude: 127.3765459, timestamp: "2024-06-06T16:25:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "09", hdop: "0.6", lat: "0045.837168", lon: "12722.592738", latD: "N", lonD: "E", latitude: 0.7639528, longitude: 127.37654563333334, timestamp: "2024-06-06T16:24:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "08", hdop: "0.6", lat: "0045.837191", lon: "12722.592754", latD: "N", lonD: "E", latitude: 0.7639531833333333, longitude: 127.3765459, timestamp: "2024-06-06T16:25:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "08", hdop: "0.6", lat: "0045.837191", lon: "12722.592754", latD: "N", lonD: "E", latitude: 0.7639531833333333, longitude: 127.3765459, timestamp: "2024-06-06T16:25:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.5", satellites: "08", hdop: "0.6", lat: "0045.837191", lon: "12722.592754", latD: "N", lonD: "E", latitude: 0.7639531833333333, longitude: 127.3765459, timestamp: "2024-06-06T16:25:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.6", satellites: "08", hdop: "0.7", lat: "0045.837221", lon: "12722.592804", latD: "N", lonD: "E", latitude: 0.7639536833333334, longitude: 127.37654673333333, timestamp: "2024-06-06T16:25:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.6", satellites: "08", hdop: "0.7", lat: "0045.837221", lon: "12722.592804", latD: "N", lonD: "E", latitude: 0.7639536833333334, longitude: 127.37654673333333, timestamp: "2024-06-06T16:25:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.837242", lon: "12722.592849", latD: "N", lonD: "E", latitude: 0.7639540333333333, longitude: 127.37654748333334, timestamp: "2024-06-06T16:25:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.6", satellites: "08", hdop: "0.7", lat: "0045.837221", lon: "12722.592804", latD: "N", lonD: "E", latitude: 0.7639536833333334, longitude: 127.37654673333333, timestamp: "2024-06-06T16:25:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.6", satellites: "08", hdop: "0.7", lat: "0045.837221", lon: "12722.592804", latD: "N", lonD: "E", latitude: 0.7639536833333334, longitude: 127.37654673333333, timestamp: "2024-06-06T16:25:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.837242", lon: "12722.592849", latD: "N", lonD: "E", latitude: 0.7639540333333333, longitude: 127.37654748333334, timestamp: "2024-06-06T16:25:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.837242", lon: "12722.592849", latD: "N", lonD: "E", latitude: 0.7639540333333333, longitude: 127.37654748333334, timestamp: "2024-06-06T16:25:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.837242", lon: "12722.592849", latD: "N", lonD: "E", latitude: 0.7639540333333333, longitude: 127.37654748333334, timestamp: "2024-06-06T16:25:41.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.837273", lon: "12722.592906", latD: "N", lonD: "E", latitude: 0.76395455, longitude: 127.37654843333334, timestamp: "2024-06-06T16:26:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.837273", lon: "12722.592906", latD: "N", lonD: "E", latitude: 0.76395455, longitude: 127.37654843333334, timestamp: "2024-06-06T16:26:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.837273", lon: "12722.592906", latD: "N", lonD: "E", latitude: 0.76395455, longitude: 127.37654843333334, timestamp: "2024-06-06T16:26:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.837273", lon: "12722.592906", latD: "N", lonD: "E", latitude: 0.76395455, longitude: 127.37654843333334, timestamp: "2024-06-06T16:26:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.8", satellites: "08", hdop: "0.6", lat: "0045.837316", lon: "12722.593011", latD: "N", lonD: "E", latitude: 0.7639552666666667, longitude: 127.37655018333334, timestamp: "2024-06-06T16:26:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.8", satellites: "08", hdop: "0.6", lat: "0045.837316", lon: "12722.593011", latD: "N", lonD: "E", latitude: 0.7639552666666667, longitude: 127.37655018333334, timestamp: "2024-06-06T16:26:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.8", satellites: "08", hdop: "0.6", lat: "0045.837316", lon: "12722.593011", latD: "N", lonD: "E", latitude: 0.7639552666666667, longitude: 127.37655018333334, timestamp: "2024-06-06T16:26:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "3.8", satellites: "08", hdop: "0.6", lat: "0045.837316", lon: "12722.593011", latD: "N", lonD: "E", latitude: 0.7639552666666667, longitude: 127.37655018333334, timestamp: "2024-06-06T16:26:21.000Z" },
        { speed: "0.0", course: "7.5", altitude: "4.0", satellites: "10", hdop: "0.6", lat: "0045.837342", lon: "12722.593092", latD: "N", lonD: "E", latitude: 0.7639557, longitude: 127.37655153333333, timestamp: "2024-06-06T16:26:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "4.0", satellites: "10", hdop: "0.6", lat: "0045.837342", lon: "12722.593092", latD: "N", lonD: "E", latitude: 0.7639557, longitude: 127.37655153333333, timestamp: "2024-06-06T16:26:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "4.0", satellites: "10", hdop: "0.6", lat: "0045.837342", lon: "12722.593092", latD: "N", lonD: "E", latitude: 0.7639557, longitude: 127.37655153333333, timestamp: "2024-06-06T16:26:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "4.0", satellites: "10", hdop: "0.6", lat: "0045.837342", lon: "12722.593092", latD: "N", lonD: "E", latitude: 0.7639557, longitude: 127.37655153333333, timestamp: "2024-06-06T16:26:42.000Z" },
        { speed: "0.0", course: "7.5", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.837370", lon: "12722.593160", latD: "N", lonD: "E", latitude: 0.7639561666666667, longitude: 127.37655266666667, timestamp: "2024-06-06T16:27:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.837370", lon: "12722.593160", latD: "N", lonD: "E", latitude: 0.7639561666666667, longitude: 127.37655266666667, timestamp: "2024-06-06T16:27:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.837370", lon: "12722.593160", latD: "N", lonD: "E", latitude: 0.7639561666666667, longitude: 127.37655266666667, timestamp: "2024-06-06T16:27:01.000Z" },
        { speed: "0.0", course: "7.5", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.837370", lon: "12722.593160", latD: "N", lonD: "E", latitude: 0.7639561666666667, longitude: 127.37655266666667, timestamp: "2024-06-06T16:27:01.000Z" },
        { speed: "0.0", course: "71.5", altitude: "2.7", satellites: "08", hdop: "0.6", lat: "0045.838318", lon: "12722.593957", latD: "N", lonD: "E", latitude: 0.7639719666666667, longitude: 127.37656595, timestamp: "2024-06-06T16:27:22.000Z" },
        { speed: "0.0", course: "71.5", altitude: "2.7", satellites: "08", hdop: "0.6", lat: "0045.838318", lon: "12722.593957", latD: "N", lonD: "E", latitude: 0.7639719666666667, longitude: 127.37656595, timestamp: "2024-06-06T16:27:22.000Z" },
        { speed: "0.0", course: "71.5", altitude: "2.7", satellites: "08", hdop: "0.6", lat: "0045.838318", lon: "12722.593957", latD: "N", lonD: "E", latitude: 0.7639719666666667, longitude: 127.37656595, timestamp: "2024-06-06T16:27:22.000Z" },
        { speed: "0.0", course: "71.5", altitude: "2.7", satellites: "08", hdop: "0.6", lat: "0045.838318", lon: "12722.593957", latD: "N", lonD: "E", latitude: 0.7639719666666667, longitude: 127.37656595, timestamp: "2024-06-06T16:27:22.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.4", satellites: "09", hdop: "0.6", lat: "0045.838575", lon: "12722.594637", latD: "N", lonD: "E", latitude: 0.76397625, longitude: 127.37657728333333, timestamp: "2024-06-06T16:27:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.4", satellites: "09", hdop: "0.6", lat: "0045.838575", lon: "12722.594637", latD: "N", lonD: "E", latitude: 0.76397625, longitude: 127.37657728333333, timestamp: "2024-06-06T16:27:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.4", satellites: "09", hdop: "0.6", lat: "0045.838575", lon: "12722.594637", latD: "N", lonD: "E", latitude: 0.76397625, longitude: 127.37657728333333, timestamp: "2024-06-06T16:27:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.4", satellites: "09", hdop: "0.6", lat: "0045.838575", lon: "12722.594637", latD: "N", lonD: "E", latitude: 0.76397625, longitude: 127.37657728333333, timestamp: "2024-06-06T16:27:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "08", hdop: "0.6", lat: "0045.838609", lon: "12722.594693", latD: "N", lonD: "E", latitude: 0.7639768166666666, longitude: 127.37657821666667, timestamp: "2024-06-06T16:28:01.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "08", hdop: "0.6", lat: "0045.838609", lon: "12722.594693", latD: "N", lonD: "E", latitude: 0.7639768166666666, longitude: 127.37657821666667, timestamp: "2024-06-06T16:28:01.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "08", hdop: "0.6", lat: "0045.838609", lon: "12722.594693", latD: "N", lonD: "E", latitude: 0.7639768166666666, longitude: 127.37657821666667, timestamp: "2024-06-06T16:28:01.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "08", hdop: "0.6", lat: "0045.838609", lon: "12722.594693", latD: "N", lonD: "E", latitude: 0.7639768166666666, longitude: 127.37657821666667, timestamp: "2024-06-06T16:28:01.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.838617", lon: "12722.594686", latD: "N", lonD: "E", latitude: 0.76397695, longitude: 127.3765781, timestamp: "2024-06-06T16:28:22.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.838617", lon: "12722.594686", latD: "N", lonD: "E", latitude: 0.76397695, longitude: 127.3765781, timestamp: "2024-06-06T16:28:22.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.838617", lon: "12722.594686", latD: "N", lonD: "E", latitude: 0.76397695, longitude: 127.3765781, timestamp: "2024-06-06T16:28:22.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.838617", lon: "12722.594686", latD: "N", lonD: "E", latitude: 0.76397695, longitude: 127.3765781, timestamp: "2024-06-06T16:28:22.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.838619", lon: "12722.594704", latD: "N", lonD: "E", latitude: 0.7639769833333333, longitude: 127.3765784, timestamp: "2024-06-06T16:28:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.838619", lon: "12722.594704", latD: "N", lonD: "E", latitude: 0.7639769833333333, longitude: 127.3765784, timestamp: "2024-06-06T16:28:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.838619", lon: "12722.594704", latD: "N", lonD: "E", latitude: 0.7639769833333333, longitude: 127.3765784, timestamp: "2024-06-06T16:28:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.838619", lon: "12722.594704", latD: "N", lonD: "E", latitude: 0.7639769833333333, longitude: 127.3765784, timestamp: "2024-06-06T16:28:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.6", satellites: "09", hdop: "0.7", lat: "0045.838629", lon: "12722.594716", latD: "N", lonD: "E", latitude: 0.76397715, longitude: 127.3765786, timestamp: "2024-06-06T16:29:01.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.6", satellites: "09", hdop: "0.7", lat: "0045.838629", lon: "12722.594716", latD: "N", lonD: "E", latitude: 0.76397715, longitude: 127.3765786, timestamp: "2024-06-06T16:29:01.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.6", satellites: "09", hdop: "0.7", lat: "0045.838629", lon: "12722.594716", latD: "N", lonD: "E", latitude: 0.76397715, longitude: 127.3765786, timestamp: "2024-06-06T16:29:01.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "08", hdop: "0.7", lat: "0045.838652", lon: "12722.594783", latD: "N", lonD: "E", latitude: 0.7639775333333334, longitude: 127.37657971666667, timestamp: "2024-06-06T16:29:21.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.6", satellites: "09", hdop: "0.7", lat: "0045.838629", lon: "12722.594716", latD: "N", lonD: "E", latitude: 0.76397715, longitude: 127.3765786, timestamp: "2024-06-06T16:29:01.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "08", hdop: "0.7", lat: "0045.838652", lon: "12722.594783", latD: "N", lonD: "E", latitude: 0.7639775333333334, longitude: 127.37657971666667, timestamp: "2024-06-06T16:29:21.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "08", hdop: "0.7", lat: "0045.838652", lon: "12722.594783", latD: "N", lonD: "E", latitude: 0.7639775333333334, longitude: 127.37657971666667, timestamp: "2024-06-06T16:29:21.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "08", hdop: "0.7", lat: "0045.838652", lon: "12722.594783", latD: "N", lonD: "E", latitude: 0.7639775333333334, longitude: 127.37657971666667, timestamp: "2024-06-06T16:29:21.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "08", hdop: "0.7", lat: "0045.838681", lon: "12722.594805", latD: "N", lonD: "E", latitude: 0.7639780166666666, longitude: 127.37658008333334, timestamp: "2024-06-06T16:29:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "08", hdop: "0.7", lat: "0045.838681", lon: "12722.594805", latD: "N", lonD: "E", latitude: 0.7639780166666666, longitude: 127.37658008333334, timestamp: "2024-06-06T16:29:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "08", hdop: "0.7", lat: "0045.838681", lon: "12722.594805", latD: "N", lonD: "E", latitude: 0.7639780166666666, longitude: 127.37658008333334, timestamp: "2024-06-06T16:29:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "08", hdop: "0.7", lat: "0045.838681", lon: "12722.594805", latD: "N", lonD: "E", latitude: 0.7639780166666666, longitude: 127.37658008333334, timestamp: "2024-06-06T16:29:41.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "09", hdop: "0.6", lat: "0045.838694", lon: "12722.594827", latD: "N", lonD: "E", latitude: 0.7639782333333333, longitude: 127.37658045, timestamp: "2024-06-06T16:30:02.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "09", hdop: "0.6", lat: "0045.838694", lon: "12722.594827", latD: "N", lonD: "E", latitude: 0.7639782333333333, longitude: 127.37658045, timestamp: "2024-06-06T16:30:02.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "09", hdop: "0.6", lat: "0045.838694", lon: "12722.594827", latD: "N", lonD: "E", latitude: 0.7639782333333333, longitude: 127.37658045, timestamp: "2024-06-06T16:30:02.000Z" },
        { speed: "0.0", course: "71.5", altitude: "1.8", satellites: "09", hdop: "0.6", lat: "0045.838694", lon: "12722.594827", latD: "N", lonD: "E", latitude: 0.7639782333333333, longitude: 127.37658045, timestamp: "2024-06-06T16:30:02.000Z" },
        { speed: "0.0", course: "60.3", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.839540", lon: "12722.595964", latD: "N", lonD: "E", latitude: 0.7639923333333333, longitude: 127.3765994, timestamp: "2024-06-06T16:30:21.000Z" },
        { speed: "0.0", course: "60.3", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.839540", lon: "12722.595964", latD: "N", lonD: "E", latitude: 0.7639923333333333, longitude: 127.3765994, timestamp: "2024-06-06T16:30:21.000Z" },
        { speed: "0.0", course: "60.3", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.839540", lon: "12722.595964", latD: "N", lonD: "E", latitude: 0.7639923333333333, longitude: 127.3765994, timestamp: "2024-06-06T16:30:21.000Z" },
        { speed: "0.0", course: "60.3", altitude: "5.1", satellites: "10", hdop: "0.6", lat: "0045.839540", lon: "12722.595964", latD: "N", lonD: "E", latitude: 0.7639923333333333, longitude: 127.3765994, timestamp: "2024-06-06T16:30:21.000Z" },
        { speed: "0.0", course: "231.5", altitude: "8.8", satellites: "10", hdop: "0.6", lat: "0045.840080", lon: "12722.596563", latD: "N", lonD: "E", latitude: 0.7640013333333333, longitude: 127.37660938333333, timestamp: "2024-06-06T16:30:42.000Z" },
        { speed: "0.0", course: "231.5", altitude: "8.8", satellites: "10", hdop: "0.6", lat: "0045.840080", lon: "12722.596563", latD: "N", lonD: "E", latitude: 0.7640013333333333, longitude: 127.37660938333333, timestamp: "2024-06-06T16:30:42.000Z" },
        { speed: "0.0", course: "231.5", altitude: "8.8", satellites: "10", hdop: "0.6", lat: "0045.840080", lon: "12722.596563", latD: "N", lonD: "E", latitude: 0.7640013333333333, longitude: 127.37660938333333, timestamp: "2024-06-06T16:30:42.000Z" },
        { speed: "0.0", course: "231.5", altitude: "8.8", satellites: "10", hdop: "0.6", lat: "0045.840080", lon: "12722.596563", latD: "N", lonD: "E", latitude: 0.7640013333333333, longitude: 127.37660938333333, timestamp: "2024-06-06T16:30:42.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.3", satellites: "10", hdop: "0.6", lat: "0045.839375", lon: "12722.597762", latD: "N", lonD: "E", latitude: 0.7639895833333333, longitude: 127.37662936666666, timestamp: "2024-06-06T16:31:02.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.3", satellites: "10", hdop: "0.6", lat: "0045.839375", lon: "12722.597762", latD: "N", lonD: "E", latitude: 0.7639895833333333, longitude: 127.37662936666666, timestamp: "2024-06-06T16:31:02.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.3", satellites: "10", hdop: "0.6", lat: "0045.839375", lon: "12722.597762", latD: "N", lonD: "E", latitude: 0.7639895833333333, longitude: 127.37662936666666, timestamp: "2024-06-06T16:31:02.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.3", satellites: "10", hdop: "0.6", lat: "0045.839375", lon: "12722.597762", latD: "N", lonD: "E", latitude: 0.7639895833333333, longitude: 127.37662936666666, timestamp: "2024-06-06T16:31:02.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.840156", lon: "12722.597214", latD: "N", lonD: "E", latitude: 0.7640026, longitude: 127.37662023333333, timestamp: "2024-06-06T16:31:21.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.840156", lon: "12722.597214", latD: "N", lonD: "E", latitude: 0.7640026, longitude: 127.37662023333333, timestamp: "2024-06-06T16:31:21.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.840156", lon: "12722.597214", latD: "N", lonD: "E", latitude: 0.7640026, longitude: 127.37662023333333, timestamp: "2024-06-06T16:31:21.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.840156", lon: "12722.597214", latD: "N", lonD: "E", latitude: 0.7640026, longitude: 127.37662023333333, timestamp: "2024-06-06T16:31:21.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "08", hdop: "0.7", lat: "0045.840132", lon: "12722.597208", latD: "N", lonD: "E", latitude: 0.7640022, longitude: 127.37662013333333, timestamp: "2024-06-06T16:31:42.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "08", hdop: "0.7", lat: "0045.840132", lon: "12722.597208", latD: "N", lonD: "E", latitude: 0.7640022, longitude: 127.37662013333333, timestamp: "2024-06-06T16:31:42.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "08", hdop: "0.7", lat: "0045.840132", lon: "12722.597208", latD: "N", lonD: "E", latitude: 0.7640022, longitude: 127.37662013333333, timestamp: "2024-06-06T16:31:42.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "08", hdop: "0.7", lat: "0045.840132", lon: "12722.597208", latD: "N", lonD: "E", latitude: 0.7640022, longitude: 127.37662013333333, timestamp: "2024-06-06T16:31:42.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.840159", lon: "12722.597208", latD: "N", lonD: "E", latitude: 0.76400265, longitude: 127.37662013333333, timestamp: "2024-06-06T16:32:01.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.840159", lon: "12722.597208", latD: "N", lonD: "E", latitude: 0.76400265, longitude: 127.37662013333333, timestamp: "2024-06-06T16:32:01.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.840159", lon: "12722.597208", latD: "N", lonD: "E", latitude: 0.76400265, longitude: 127.37662013333333, timestamp: "2024-06-06T16:32:01.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840166", lon: "12722.597231", latD: "N", lonD: "E", latitude: 0.7640027666666668, longitude: 127.37662051666666, timestamp: "2024-06-06T16:32:21.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.840159", lon: "12722.597208", latD: "N", lonD: "E", latitude: 0.76400265, longitude: 127.37662013333333, timestamp: "2024-06-06T16:32:01.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840166", lon: "12722.597231", latD: "N", lonD: "E", latitude: 0.7640027666666668, longitude: 127.37662051666666, timestamp: "2024-06-06T16:32:21.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840166", lon: "12722.597231", latD: "N", lonD: "E", latitude: 0.7640027666666668, longitude: 127.37662051666666, timestamp: "2024-06-06T16:32:21.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840166", lon: "12722.597231", latD: "N", lonD: "E", latitude: 0.7640027666666668, longitude: 127.37662051666666, timestamp: "2024-06-06T16:32:21.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840166", lon: "12722.597230", latD: "N", lonD: "E", latitude: 0.7640027666666668, longitude: 127.3766205, timestamp: "2024-06-06T16:32:42.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840166", lon: "12722.597230", latD: "N", lonD: "E", latitude: 0.7640027666666668, longitude: 127.3766205, timestamp: "2024-06-06T16:32:42.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840166", lon: "12722.597230", latD: "N", lonD: "E", latitude: 0.7640027666666668, longitude: 127.3766205, timestamp: "2024-06-06T16:32:42.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840166", lon: "12722.597230", latD: "N", lonD: "E", latitude: 0.7640027666666668, longitude: 127.3766205, timestamp: "2024-06-06T16:32:42.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840152", lon: "12722.597247", latD: "N", lonD: "E", latitude: 0.7640025333333333, longitude: 127.37662078333334, timestamp: "2024-06-06T16:33:02.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840152", lon: "12722.597247", latD: "N", lonD: "E", latitude: 0.7640025333333333, longitude: 127.37662078333334, timestamp: "2024-06-06T16:33:02.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840152", lon: "12722.597247", latD: "N", lonD: "E", latitude: 0.7640025333333333, longitude: 127.37662078333334, timestamp: "2024-06-06T16:33:02.000Z" },
        { speed: "0.0", course: "245.9", altitude: "10.8", satellites: "10", hdop: "0.6", lat: "0045.840152", lon: "12722.597247", latD: "N", lonD: "E", latitude: 0.7640025333333333, longitude: 127.37662078333334, timestamp: "2024-06-06T16:33:02.000Z" },
        { speed: "0.0", course: "231.6", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.839220", lon: "12722.597552", latD: "N", lonD: "E", latitude: 0.763987, longitude: 127.37662586666667, timestamp: "2024-06-06T16:33:22.000Z" },
        { speed: "0.0", course: "231.6", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.839220", lon: "12722.597552", latD: "N", lonD: "E", latitude: 0.763987, longitude: 127.37662586666667, timestamp: "2024-06-06T16:33:22.000Z" },
        { speed: "0.0", course: "231.6", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.839220", lon: "12722.597552", latD: "N", lonD: "E", latitude: 0.763987, longitude: 127.37662586666667, timestamp: "2024-06-06T16:33:22.000Z" },
        { speed: "0.0", course: "231.6", altitude: "10.2", satellites: "09", hdop: "0.6", lat: "0045.839220", lon: "12722.597552", latD: "N", lonD: "E", latitude: 0.763987, longitude: 127.37662586666667, timestamp: "2024-06-06T16:33:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.838637", lon: "12722.599047", latD: "N", lonD: "E", latitude: 0.7639772833333333, longitude: 127.37665078333333, timestamp: "2024-06-06T16:33:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.838637", lon: "12722.599047", latD: "N", lonD: "E", latitude: 0.7639772833333333, longitude: 127.37665078333333, timestamp: "2024-06-06T16:33:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.838637", lon: "12722.599047", latD: "N", lonD: "E", latitude: 0.7639772833333333, longitude: 127.37665078333333, timestamp: "2024-06-06T16:33:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "9.7", satellites: "10", hdop: "0.6", lat: "0045.838637", lon: "12722.599047", latD: "N", lonD: "E", latitude: 0.7639772833333333, longitude: 127.37665078333333, timestamp: "2024-06-06T16:33:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838597", lon: "12722.598945", latD: "N", lonD: "E", latitude: 0.7639766166666667, longitude: 127.37664908333333, timestamp: "2024-06-06T16:34:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838597", lon: "12722.598945", latD: "N", lonD: "E", latitude: 0.7639766166666667, longitude: 127.37664908333333, timestamp: "2024-06-06T16:34:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838597", lon: "12722.598945", latD: "N", lonD: "E", latitude: 0.7639766166666667, longitude: 127.37664908333333, timestamp: "2024-06-06T16:34:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838597", lon: "12722.598945", latD: "N", lonD: "E", latitude: 0.7639766166666667, longitude: 127.37664908333333, timestamp: "2024-06-06T16:34:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838579", lon: "12722.598936", latD: "N", lonD: "E", latitude: 0.7639763166666668, longitude: 127.37664893333333, timestamp: "2024-06-06T16:34:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838579", lon: "12722.598936", latD: "N", lonD: "E", latitude: 0.7639763166666668, longitude: 127.37664893333333, timestamp: "2024-06-06T16:34:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838579", lon: "12722.598936", latD: "N", lonD: "E", latitude: 0.7639763166666668, longitude: 127.37664893333333, timestamp: "2024-06-06T16:34:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838579", lon: "12722.598936", latD: "N", lonD: "E", latitude: 0.7639763166666668, longitude: 127.37664893333333, timestamp: "2024-06-06T16:34:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838578", lon: "12722.598939", latD: "N", lonD: "E", latitude: 0.7639762999999999, longitude: 127.37664898333334, timestamp: "2024-06-06T16:34:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838578", lon: "12722.598939", latD: "N", lonD: "E", latitude: 0.7639762999999999, longitude: 127.37664898333334, timestamp: "2024-06-06T16:34:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838578", lon: "12722.598939", latD: "N", lonD: "E", latitude: 0.7639762999999999, longitude: 127.37664898333334, timestamp: "2024-06-06T16:34:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838578", lon: "12722.598939", latD: "N", lonD: "E", latitude: 0.7639762999999999, longitude: 127.37664898333334, timestamp: "2024-06-06T16:34:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838580", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639763333333334, longitude: 127.37664925, timestamp: "2024-06-06T16:35:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838580", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639763333333334, longitude: 127.37664925, timestamp: "2024-06-06T16:35:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838580", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639763333333334, longitude: 127.37664925, timestamp: "2024-06-06T16:35:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838580", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639763333333334, longitude: 127.37664925, timestamp: "2024-06-06T16:35:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838595", lon: "12722.598941", latD: "N", lonD: "E", latitude: 0.7639765833333333, longitude: 127.37664901666666, timestamp: "2024-06-06T16:35:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838595", lon: "12722.598941", latD: "N", lonD: "E", latitude: 0.7639765833333333, longitude: 127.37664901666666, timestamp: "2024-06-06T16:35:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838595", lon: "12722.598941", latD: "N", lonD: "E", latitude: 0.7639765833333333, longitude: 127.37664901666666, timestamp: "2024-06-06T16:35:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838595", lon: "12722.598941", latD: "N", lonD: "E", latitude: 0.7639765833333333, longitude: 127.37664901666666, timestamp: "2024-06-06T16:35:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838597", lon: "12722.598936", latD: "N", lonD: "E", latitude: 0.7639766166666667, longitude: 127.37664893333333, timestamp: "2024-06-06T16:35:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838597", lon: "12722.598936", latD: "N", lonD: "E", latitude: 0.7639766166666667, longitude: 127.37664893333333, timestamp: "2024-06-06T16:35:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838597", lon: "12722.598936", latD: "N", lonD: "E", latitude: 0.7639766166666667, longitude: 127.37664893333333, timestamp: "2024-06-06T16:35:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838597", lon: "12722.598936", latD: "N", lonD: "E", latitude: 0.7639766166666667, longitude: 127.37664893333333, timestamp: "2024-06-06T16:35:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838604", lon: "12722.598940", latD: "N", lonD: "E", latitude: 0.7639767333333333, longitude: 127.376649, timestamp: "2024-06-06T16:36:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838604", lon: "12722.598940", latD: "N", lonD: "E", latitude: 0.7639767333333333, longitude: 127.376649, timestamp: "2024-06-06T16:36:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838604", lon: "12722.598940", latD: "N", lonD: "E", latitude: 0.7639767333333333, longitude: 127.376649, timestamp: "2024-06-06T16:36:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838604", lon: "12722.598940", latD: "N", lonD: "E", latitude: 0.7639767333333333, longitude: 127.376649, timestamp: "2024-06-06T16:36:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838603", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639767166666667, longitude: 127.37664925, timestamp: "2024-06-06T16:36:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838603", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639767166666667, longitude: 127.37664925, timestamp: "2024-06-06T16:36:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838603", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639767166666667, longitude: 127.37664925, timestamp: "2024-06-06T16:36:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838603", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639767166666667, longitude: 127.37664925, timestamp: "2024-06-06T16:36:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838602", lon: "12722.598960", latD: "N", lonD: "E", latitude: 0.7639767000000001, longitude: 127.37664933333333, timestamp: "2024-06-06T16:36:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838602", lon: "12722.598960", latD: "N", lonD: "E", latitude: 0.7639767000000001, longitude: 127.37664933333333, timestamp: "2024-06-06T16:36:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838602", lon: "12722.598960", latD: "N", lonD: "E", latitude: 0.7639767000000001, longitude: 127.37664933333333, timestamp: "2024-06-06T16:36:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838602", lon: "12722.598960", latD: "N", lonD: "E", latitude: 0.7639767000000001, longitude: 127.37664933333333, timestamp: "2024-06-06T16:36:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838607", lon: "12722.598952", latD: "N", lonD: "E", latitude: 0.7639767833333334, longitude: 127.3766492, timestamp: "2024-06-06T16:37:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838607", lon: "12722.598952", latD: "N", lonD: "E", latitude: 0.7639767833333334, longitude: 127.3766492, timestamp: "2024-06-06T16:37:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838607", lon: "12722.598952", latD: "N", lonD: "E", latitude: 0.7639767833333334, longitude: 127.3766492, timestamp: "2024-06-06T16:37:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838607", lon: "12722.598952", latD: "N", lonD: "E", latitude: 0.7639767833333334, longitude: 127.3766492, timestamp: "2024-06-06T16:37:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838616", lon: "12722.598931", latD: "N", lonD: "E", latitude: 0.7639769333333334, longitude: 127.37664885, timestamp: "2024-06-06T16:37:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838616", lon: "12722.598931", latD: "N", lonD: "E", latitude: 0.7639769333333334, longitude: 127.37664885, timestamp: "2024-06-06T16:37:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838616", lon: "12722.598931", latD: "N", lonD: "E", latitude: 0.7639769333333334, longitude: 127.37664885, timestamp: "2024-06-06T16:37:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838616", lon: "12722.598931", latD: "N", lonD: "E", latitude: 0.7639769333333334, longitude: 127.37664885, timestamp: "2024-06-06T16:37:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838639", lon: "12722.598926", latD: "N", lonD: "E", latitude: 0.7639773166666667, longitude: 127.37664876666666, timestamp: "2024-06-06T16:37:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838639", lon: "12722.598926", latD: "N", lonD: "E", latitude: 0.7639773166666667, longitude: 127.37664876666666, timestamp: "2024-06-06T16:37:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838639", lon: "12722.598926", latD: "N", lonD: "E", latitude: 0.7639773166666667, longitude: 127.37664876666666, timestamp: "2024-06-06T16:37:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838639", lon: "12722.598926", latD: "N", lonD: "E", latitude: 0.7639773166666667, longitude: 127.37664876666666, timestamp: "2024-06-06T16:37:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838630", lon: "12722.598897", latD: "N", lonD: "E", latitude: 0.7639771666666667, longitude: 127.37664828333334, timestamp: "2024-06-06T16:38:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838630", lon: "12722.598897", latD: "N", lonD: "E", latitude: 0.7639771666666667, longitude: 127.37664828333334, timestamp: "2024-06-06T16:38:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838630", lon: "12722.598897", latD: "N", lonD: "E", latitude: 0.7639771666666667, longitude: 127.37664828333334, timestamp: "2024-06-06T16:38:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.7", lat: "0045.838630", lon: "12722.598897", latD: "N", lonD: "E", latitude: 0.7639771666666667, longitude: 127.37664828333334, timestamp: "2024-06-06T16:38:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838627", lon: "12722.598883", latD: "N", lonD: "E", latitude: 0.7639771166666667, longitude: 127.37664805, timestamp: "2024-06-06T16:38:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838627", lon: "12722.598883", latD: "N", lonD: "E", latitude: 0.7639771166666667, longitude: 127.37664805, timestamp: "2024-06-06T16:38:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838627", lon: "12722.598883", latD: "N", lonD: "E", latitude: 0.7639771166666667, longitude: 127.37664805, timestamp: "2024-06-06T16:38:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838627", lon: "12722.598883", latD: "N", lonD: "E", latitude: 0.7639771166666667, longitude: 127.37664805, timestamp: "2024-06-06T16:38:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838639", lon: "12722.598881", latD: "N", lonD: "E", latitude: 0.7639773166666667, longitude: 127.37664801666666, timestamp: "2024-06-06T16:38:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838639", lon: "12722.598881", latD: "N", lonD: "E", latitude: 0.7639773166666667, longitude: 127.37664801666666, timestamp: "2024-06-06T16:38:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838639", lon: "12722.598881", latD: "N", lonD: "E", latitude: 0.7639773166666667, longitude: 127.37664801666666, timestamp: "2024-06-06T16:38:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838635", lon: "12722.598874", latD: "N", lonD: "E", latitude: 0.7639772499999999, longitude: 127.3766479, timestamp: "2024-06-06T16:39:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838635", lon: "12722.598874", latD: "N", lonD: "E", latitude: 0.7639772499999999, longitude: 127.3766479, timestamp: "2024-06-06T16:39:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838639", lon: "12722.598881", latD: "N", lonD: "E", latitude: 0.7639773166666667, longitude: 127.37664801666666, timestamp: "2024-06-06T16:38:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838635", lon: "12722.598874", latD: "N", lonD: "E", latitude: 0.7639772499999999, longitude: 127.3766479, timestamp: "2024-06-06T16:39:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838635", lon: "12722.598874", latD: "N", lonD: "E", latitude: 0.7639772499999999, longitude: 127.3766479, timestamp: "2024-06-06T16:39:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838626", lon: "12722.598856", latD: "N", lonD: "E", latitude: 0.7639771, longitude: 127.3766476, timestamp: "2024-06-06T16:39:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838626", lon: "12722.598856", latD: "N", lonD: "E", latitude: 0.7639771, longitude: 127.3766476, timestamp: "2024-06-06T16:39:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838626", lon: "12722.598856", latD: "N", lonD: "E", latitude: 0.7639771, longitude: 127.3766476, timestamp: "2024-06-06T16:39:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838626", lon: "12722.598856", latD: "N", lonD: "E", latitude: 0.7639771, longitude: 127.3766476, timestamp: "2024-06-06T16:39:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.838606", lon: "12722.598826", latD: "N", lonD: "E", latitude: 0.7639767666666667, longitude: 127.3766471, timestamp: "2024-06-06T16:39:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.838606", lon: "12722.598826", latD: "N", lonD: "E", latitude: 0.7639767666666667, longitude: 127.3766471, timestamp: "2024-06-06T16:39:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.838606", lon: "12722.598826", latD: "N", lonD: "E", latitude: 0.7639767666666667, longitude: 127.3766471, timestamp: "2024-06-06T16:39:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838594", lon: "12722.598800", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.37664666666667, timestamp: "2024-06-06T16:40:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.838606", lon: "12722.598826", latD: "N", lonD: "E", latitude: 0.7639767666666667, longitude: 127.3766471, timestamp: "2024-06-06T16:39:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838594", lon: "12722.598800", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.37664666666667, timestamp: "2024-06-06T16:40:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838594", lon: "12722.598800", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.37664666666667, timestamp: "2024-06-06T16:40:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838594", lon: "12722.598800", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.37664666666667, timestamp: "2024-06-06T16:40:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838594", lon: "12722.598796", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.3766466, timestamp: "2024-06-06T16:40:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838594", lon: "12722.598796", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.3766466, timestamp: "2024-06-06T16:40:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838594", lon: "12722.598796", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.3766466, timestamp: "2024-06-06T16:40:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838594", lon: "12722.598796", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.3766466, timestamp: "2024-06-06T16:40:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838605", lon: "12722.598783", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664638333334, timestamp: "2024-06-06T16:40:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838605", lon: "12722.598783", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664638333334, timestamp: "2024-06-06T16:40:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838605", lon: "12722.598783", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664638333334, timestamp: "2024-06-06T16:40:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838605", lon: "12722.598783", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664638333334, timestamp: "2024-06-06T16:40:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.7", lat: "0045.838605", lon: "12722.598764", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664606666667, timestamp: "2024-06-06T16:41:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.7", lat: "0045.838605", lon: "12722.598764", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664606666667, timestamp: "2024-06-06T16:41:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.7", lat: "0045.838605", lon: "12722.598764", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664606666667, timestamp: "2024-06-06T16:41:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.7", lat: "0045.838605", lon: "12722.598764", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664606666667, timestamp: "2024-06-06T16:41:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "07", hdop: "0.7", lat: "0045.838605", lon: "12722.598728", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664546666667, timestamp: "2024-06-06T16:41:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "07", hdop: "0.7", lat: "0045.838605", lon: "12722.598728", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664546666667, timestamp: "2024-06-06T16:41:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "07", hdop: "0.7", lat: "0045.838605", lon: "12722.598728", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664546666667, timestamp: "2024-06-06T16:41:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.7", lat: "0045.838592", lon: "12722.598699", latD: "N", lonD: "E", latitude: 0.7639765333333333, longitude: 127.37664498333334, timestamp: "2024-06-06T16:41:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.7", lat: "0045.838592", lon: "12722.598699", latD: "N", lonD: "E", latitude: 0.7639765333333333, longitude: 127.37664498333334, timestamp: "2024-06-06T16:41:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "07", hdop: "0.7", lat: "0045.838605", lon: "12722.598728", latD: "N", lonD: "E", latitude: 0.76397675, longitude: 127.37664546666667, timestamp: "2024-06-06T16:41:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.7", lat: "0045.838592", lon: "12722.598699", latD: "N", lonD: "E", latitude: 0.7639765333333333, longitude: 127.37664498333334, timestamp: "2024-06-06T16:41:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.7", lat: "0045.838592", lon: "12722.598699", latD: "N", lonD: "E", latitude: 0.7639765333333333, longitude: 127.37664498333334, timestamp: "2024-06-06T16:41:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "07", hdop: "0.7", lat: "0045.838594", lon: "12722.598685", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.37664475, timestamp: "2024-06-06T16:42:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "07", hdop: "0.7", lat: "0045.838594", lon: "12722.598685", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.37664475, timestamp: "2024-06-06T16:42:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "07", hdop: "0.7", lat: "0045.838594", lon: "12722.598685", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.37664475, timestamp: "2024-06-06T16:42:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "07", hdop: "0.7", lat: "0045.838594", lon: "12722.598685", latD: "N", lonD: "E", latitude: 0.7639765666666667, longitude: 127.37664475, timestamp: "2024-06-06T16:42:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838596", lon: "12722.598661", latD: "N", lonD: "E", latitude: 0.7639766, longitude: 127.37664435, timestamp: "2024-06-06T16:42:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838596", lon: "12722.598661", latD: "N", lonD: "E", latitude: 0.7639766, longitude: 127.37664435, timestamp: "2024-06-06T16:42:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838596", lon: "12722.598661", latD: "N", lonD: "E", latitude: 0.7639766, longitude: 127.37664435, timestamp: "2024-06-06T16:42:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838596", lon: "12722.598661", latD: "N", lonD: "E", latitude: 0.7639766, longitude: 127.37664435, timestamp: "2024-06-06T16:42:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838585", lon: "12722.598620", latD: "N", lonD: "E", latitude: 0.7639764166666667, longitude: 127.37664366666667, timestamp: "2024-06-06T16:42:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838585", lon: "12722.598620", latD: "N", lonD: "E", latitude: 0.7639764166666667, longitude: 127.37664366666667, timestamp: "2024-06-06T16:42:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838585", lon: "12722.598620", latD: "N", lonD: "E", latitude: 0.7639764166666667, longitude: 127.37664366666667, timestamp: "2024-06-06T16:42:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838585", lon: "12722.598620", latD: "N", lonD: "E", latitude: 0.7639764166666667, longitude: 127.37664366666667, timestamp: "2024-06-06T16:42:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838569", lon: "12722.598581", latD: "N", lonD: "E", latitude: 0.76397615, longitude: 127.37664301666666, timestamp: "2024-06-06T16:43:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838569", lon: "12722.598581", latD: "N", lonD: "E", latitude: 0.76397615, longitude: 127.37664301666666, timestamp: "2024-06-06T16:43:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838569", lon: "12722.598581", latD: "N", lonD: "E", latitude: 0.76397615, longitude: 127.37664301666666, timestamp: "2024-06-06T16:43:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838569", lon: "12722.598581", latD: "N", lonD: "E", latitude: 0.76397615, longitude: 127.37664301666666, timestamp: "2024-06-06T16:43:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838540", lon: "12722.598519", latD: "N", lonD: "E", latitude: 0.7639756666666667, longitude: 127.37664198333333, timestamp: "2024-06-06T16:43:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838540", lon: "12722.598519", latD: "N", lonD: "E", latitude: 0.7639756666666667, longitude: 127.37664198333333, timestamp: "2024-06-06T16:43:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838540", lon: "12722.598519", latD: "N", lonD: "E", latitude: 0.7639756666666667, longitude: 127.37664198333333, timestamp: "2024-06-06T16:43:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838540", lon: "12722.598519", latD: "N", lonD: "E", latitude: 0.7639756666666667, longitude: 127.37664198333333, timestamp: "2024-06-06T16:43:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838545", lon: "12722.598492", latD: "N", lonD: "E", latitude: 0.7639757500000001, longitude: 127.37664153333333, timestamp: "2024-06-06T16:43:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838545", lon: "12722.598492", latD: "N", lonD: "E", latitude: 0.7639757500000001, longitude: 127.37664153333333, timestamp: "2024-06-06T16:43:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838545", lon: "12722.598492", latD: "N", lonD: "E", latitude: 0.7639757500000001, longitude: 127.37664153333333, timestamp: "2024-06-06T16:43:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.6", lat: "0045.838545", lon: "12722.598492", latD: "N", lonD: "E", latitude: 0.7639757500000001, longitude: 127.37664153333333, timestamp: "2024-06-06T16:43:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838543", lon: "12722.598478", latD: "N", lonD: "E", latitude: 0.7639757166666666, longitude: 127.3766413, timestamp: "2024-06-06T16:44:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838543", lon: "12722.598478", latD: "N", lonD: "E", latitude: 0.7639757166666666, longitude: 127.3766413, timestamp: "2024-06-06T16:44:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838543", lon: "12722.598478", latD: "N", lonD: "E", latitude: 0.7639757166666666, longitude: 127.3766413, timestamp: "2024-06-06T16:44:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838543", lon: "12722.598478", latD: "N", lonD: "E", latitude: 0.7639757166666666, longitude: 127.3766413, timestamp: "2024-06-06T16:44:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838532", lon: "12722.598473", latD: "N", lonD: "E", latitude: 0.7639755333333333, longitude: 127.37664121666667, timestamp: "2024-06-06T16:44:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838532", lon: "12722.598473", latD: "N", lonD: "E", latitude: 0.7639755333333333, longitude: 127.37664121666667, timestamp: "2024-06-06T16:44:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838532", lon: "12722.598473", latD: "N", lonD: "E", latitude: 0.7639755333333333, longitude: 127.37664121666667, timestamp: "2024-06-06T16:44:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838527", lon: "12722.598480", latD: "N", lonD: "E", latitude: 0.76397545, longitude: 127.37664133333334, timestamp: "2024-06-06T16:44:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838532", lon: "12722.598473", latD: "N", lonD: "E", latitude: 0.7639755333333333, longitude: 127.37664121666667, timestamp: "2024-06-06T16:44:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838527", lon: "12722.598480", latD: "N", lonD: "E", latitude: 0.76397545, longitude: 127.37664133333334, timestamp: "2024-06-06T16:44:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838527", lon: "12722.598480", latD: "N", lonD: "E", latitude: 0.76397545, longitude: 127.37664133333334, timestamp: "2024-06-06T16:44:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838527", lon: "12722.598480", latD: "N", lonD: "E", latitude: 0.76397545, longitude: 127.37664133333334, timestamp: "2024-06-06T16:44:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838518", lon: "12722.598469", latD: "N", lonD: "E", latitude: 0.7639753, longitude: 127.37664115, timestamp: "2024-06-06T16:45:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838518", lon: "12722.598469", latD: "N", lonD: "E", latitude: 0.7639753, longitude: 127.37664115, timestamp: "2024-06-06T16:45:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838518", lon: "12722.598469", latD: "N", lonD: "E", latitude: 0.7639753, longitude: 127.37664115, timestamp: "2024-06-06T16:45:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "09", hdop: "0.7", lat: "0045.838518", lon: "12722.598469", latD: "N", lonD: "E", latitude: 0.7639753, longitude: 127.37664115, timestamp: "2024-06-06T16:45:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838498", lon: "12722.598462", latD: "N", lonD: "E", latitude: 0.7639749666666666, longitude: 127.37664103333333, timestamp: "2024-06-06T16:45:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838498", lon: "12722.598462", latD: "N", lonD: "E", latitude: 0.7639749666666666, longitude: 127.37664103333333, timestamp: "2024-06-06T16:45:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838498", lon: "12722.598462", latD: "N", lonD: "E", latitude: 0.7639749666666666, longitude: 127.37664103333333, timestamp: "2024-06-06T16:45:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "08", hdop: "0.7", lat: "0045.838498", lon: "12722.598462", latD: "N", lonD: "E", latitude: 0.7639749666666666, longitude: 127.37664103333333, timestamp: "2024-06-06T16:45:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.838486", lon: "12722.598458", latD: "N", lonD: "E", latitude: 0.7639747666666667, longitude: 127.37664096666667, timestamp: "2024-06-06T16:45:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.838486", lon: "12722.598458", latD: "N", lonD: "E", latitude: 0.7639747666666667, longitude: 127.37664096666667, timestamp: "2024-06-06T16:45:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.838486", lon: "12722.598458", latD: "N", lonD: "E", latitude: 0.7639747666666667, longitude: 127.37664096666667, timestamp: "2024-06-06T16:45:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838479", lon: "12722.598442", latD: "N", lonD: "E", latitude: 0.76397465, longitude: 127.3766407, timestamp: "2024-06-06T16:46:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.6", satellites: "10", hdop: "0.6", lat: "0045.838486", lon: "12722.598458", latD: "N", lonD: "E", latitude: 0.7639747666666667, longitude: 127.37664096666667, timestamp: "2024-06-06T16:45:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838479", lon: "12722.598442", latD: "N", lonD: "E", latitude: 0.76397465, longitude: 127.3766407, timestamp: "2024-06-06T16:46:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838479", lon: "12722.598442", latD: "N", lonD: "E", latitude: 0.76397465, longitude: 127.3766407, timestamp: "2024-06-06T16:46:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.838479", lon: "12722.598442", latD: "N", lonD: "E", latitude: 0.76397465, longitude: 127.3766407, timestamp: "2024-06-06T16:46:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.6", lat: "0045.838478", lon: "12722.598446", latD: "N", lonD: "E", latitude: 0.7639746333333334, longitude: 127.37664076666667, timestamp: "2024-06-06T16:46:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.6", lat: "0045.838478", lon: "12722.598446", latD: "N", lonD: "E", latitude: 0.7639746333333334, longitude: 127.37664076666667, timestamp: "2024-06-06T16:46:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.6", lat: "0045.838478", lon: "12722.598446", latD: "N", lonD: "E", latitude: 0.7639746333333334, longitude: 127.37664076666667, timestamp: "2024-06-06T16:46:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "08", hdop: "0.6", lat: "0045.838478", lon: "12722.598446", latD: "N", lonD: "E", latitude: 0.7639746333333334, longitude: 127.37664076666667, timestamp: "2024-06-06T16:46:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838489", lon: "12722.598440", latD: "N", lonD: "E", latitude: 0.7639748166666667, longitude: 127.37664066666666, timestamp: "2024-06-06T16:46:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838489", lon: "12722.598440", latD: "N", lonD: "E", latitude: 0.7639748166666667, longitude: 127.37664066666666, timestamp: "2024-06-06T16:46:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838489", lon: "12722.598440", latD: "N", lonD: "E", latitude: 0.7639748166666667, longitude: 127.37664066666666, timestamp: "2024-06-06T16:46:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838489", lon: "12722.598440", latD: "N", lonD: "E", latitude: 0.7639748166666667, longitude: 127.37664066666666, timestamp: "2024-06-06T16:46:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838499", lon: "12722.598442", latD: "N", lonD: "E", latitude: 0.7639749833333334, longitude: 127.3766407, timestamp: "2024-06-06T16:47:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838499", lon: "12722.598442", latD: "N", lonD: "E", latitude: 0.7639749833333334, longitude: 127.3766407, timestamp: "2024-06-06T16:47:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838499", lon: "12722.598442", latD: "N", lonD: "E", latitude: 0.7639749833333334, longitude: 127.3766407, timestamp: "2024-06-06T16:47:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838499", lon: "12722.598442", latD: "N", lonD: "E", latitude: 0.7639749833333334, longitude: 127.3766407, timestamp: "2024-06-06T16:47:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838468", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7639744666666667, longitude: 127.37664046666667, timestamp: "2024-06-06T16:47:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838468", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7639744666666667, longitude: 127.37664046666667, timestamp: "2024-06-06T16:47:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838468", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7639744666666667, longitude: 127.37664046666667, timestamp: "2024-06-06T16:47:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838448", lon: "12722.598417", latD: "N", lonD: "E", latitude: 0.7639741333333333, longitude: 127.37664028333333, timestamp: "2024-06-06T16:47:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838468", lon: "12722.598428", latD: "N", lonD: "E", latitude: 0.7639744666666667, longitude: 127.37664046666667, timestamp: "2024-06-06T16:47:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838448", lon: "12722.598417", latD: "N", lonD: "E", latitude: 0.7639741333333333, longitude: 127.37664028333333, timestamp: "2024-06-06T16:47:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838448", lon: "12722.598417", latD: "N", lonD: "E", latitude: 0.7639741333333333, longitude: 127.37664028333333, timestamp: "2024-06-06T16:47:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838448", lon: "12722.598417", latD: "N", lonD: "E", latitude: 0.7639741333333333, longitude: 127.37664028333333, timestamp: "2024-06-06T16:47:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838440", lon: "12722.598397", latD: "N", lonD: "E", latitude: 0.7639739999999999, longitude: 127.37663995, timestamp: "2024-06-06T16:48:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838440", lon: "12722.598397", latD: "N", lonD: "E", latitude: 0.7639739999999999, longitude: 127.37663995, timestamp: "2024-06-06T16:48:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838440", lon: "12722.598397", latD: "N", lonD: "E", latitude: 0.7639739999999999, longitude: 127.37663995, timestamp: "2024-06-06T16:48:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838440", lon: "12722.598397", latD: "N", lonD: "E", latitude: 0.7639739999999999, longitude: 127.37663995, timestamp: "2024-06-06T16:48:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838437", lon: "12722.598383", latD: "N", lonD: "E", latitude: 0.76397395, longitude: 127.37663971666667, timestamp: "2024-06-06T16:48:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838437", lon: "12722.598383", latD: "N", lonD: "E", latitude: 0.76397395, longitude: 127.37663971666667, timestamp: "2024-06-06T16:48:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838437", lon: "12722.598383", latD: "N", lonD: "E", latitude: 0.76397395, longitude: 127.37663971666667, timestamp: "2024-06-06T16:48:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838437", lon: "12722.598383", latD: "N", lonD: "E", latitude: 0.76397395, longitude: 127.37663971666667, timestamp: "2024-06-06T16:48:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838440", lon: "12722.598343", latD: "N", lonD: "E", latitude: 0.7639739999999999, longitude: 127.37663905, timestamp: "2024-06-06T16:48:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838440", lon: "12722.598343", latD: "N", lonD: "E", latitude: 0.7639739999999999, longitude: 127.37663905, timestamp: "2024-06-06T16:48:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838440", lon: "12722.598343", latD: "N", lonD: "E", latitude: 0.7639739999999999, longitude: 127.37663905, timestamp: "2024-06-06T16:48:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838440", lon: "12722.598343", latD: "N", lonD: "E", latitude: 0.7639739999999999, longitude: 127.37663905, timestamp: "2024-06-06T16:48:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838450", lon: "12722.598332", latD: "N", lonD: "E", latitude: 0.7639741666666667, longitude: 127.37663886666667, timestamp: "2024-06-06T16:49:03.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838450", lon: "12722.598332", latD: "N", lonD: "E", latitude: 0.7639741666666667, longitude: 127.37663886666667, timestamp: "2024-06-06T16:49:03.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838450", lon: "12722.598332", latD: "N", lonD: "E", latitude: 0.7639741666666667, longitude: 127.37663886666667, timestamp: "2024-06-06T16:49:03.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838460", lon: "12722.598353", latD: "N", lonD: "E", latitude: 0.7639743333333333, longitude: 127.37663921666666, timestamp: "2024-06-06T16:49:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838450", lon: "12722.598332", latD: "N", lonD: "E", latitude: 0.7639741666666667, longitude: 127.37663886666667, timestamp: "2024-06-06T16:49:03.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838460", lon: "12722.598353", latD: "N", lonD: "E", latitude: 0.7639743333333333, longitude: 127.37663921666666, timestamp: "2024-06-06T16:49:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838460", lon: "12722.598353", latD: "N", lonD: "E", latitude: 0.7639743333333333, longitude: 127.37663921666666, timestamp: "2024-06-06T16:49:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838460", lon: "12722.598353", latD: "N", lonD: "E", latitude: 0.7639743333333333, longitude: 127.37663921666666, timestamp: "2024-06-06T16:49:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838467", lon: "12722.598355", latD: "N", lonD: "E", latitude: 0.76397445, longitude: 127.37663925, timestamp: "2024-06-06T16:49:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838467", lon: "12722.598355", latD: "N", lonD: "E", latitude: 0.76397445, longitude: 127.37663925, timestamp: "2024-06-06T16:49:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838467", lon: "12722.598355", latD: "N", lonD: "E", latitude: 0.76397445, longitude: 127.37663925, timestamp: "2024-06-06T16:49:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838467", lon: "12722.598355", latD: "N", lonD: "E", latitude: 0.76397445, longitude: 127.37663925, timestamp: "2024-06-06T16:49:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838475", lon: "12722.598354", latD: "N", lonD: "E", latitude: 0.7639745833333333, longitude: 127.37663923333334, timestamp: "2024-06-06T16:50:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838475", lon: "12722.598354", latD: "N", lonD: "E", latitude: 0.7639745833333333, longitude: 127.37663923333334, timestamp: "2024-06-06T16:50:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838475", lon: "12722.598354", latD: "N", lonD: "E", latitude: 0.7639745833333333, longitude: 127.37663923333334, timestamp: "2024-06-06T16:50:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "09", hdop: "0.6", lat: "0045.838475", lon: "12722.598354", latD: "N", lonD: "E", latitude: 0.7639745833333333, longitude: 127.37663923333334, timestamp: "2024-06-06T16:50:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838484", lon: "12722.598361", latD: "N", lonD: "E", latitude: 0.7639747333333333, longitude: 127.37663935, timestamp: "2024-06-06T16:50:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838484", lon: "12722.598361", latD: "N", lonD: "E", latitude: 0.7639747333333333, longitude: 127.37663935, timestamp: "2024-06-06T16:50:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838484", lon: "12722.598361", latD: "N", lonD: "E", latitude: 0.7639747333333333, longitude: 127.37663935, timestamp: "2024-06-06T16:50:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838484", lon: "12722.598361", latD: "N", lonD: "E", latitude: 0.7639747333333333, longitude: 127.37663935, timestamp: "2024-06-06T16:50:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838494", lon: "12722.598352", latD: "N", lonD: "E", latitude: 0.7639748999999999, longitude: 127.3766392, timestamp: "2024-06-06T16:50:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838494", lon: "12722.598352", latD: "N", lonD: "E", latitude: 0.7639748999999999, longitude: 127.3766392, timestamp: "2024-06-06T16:50:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838494", lon: "12722.598352", latD: "N", lonD: "E", latitude: 0.7639748999999999, longitude: 127.3766392, timestamp: "2024-06-06T16:50:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838494", lon: "12722.598352", latD: "N", lonD: "E", latitude: 0.7639748999999999, longitude: 127.3766392, timestamp: "2024-06-06T16:50:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.838504", lon: "12722.598367", latD: "N", lonD: "E", latitude: 0.7639750666666667, longitude: 127.37663945, timestamp: "2024-06-06T16:51:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.838504", lon: "12722.598367", latD: "N", lonD: "E", latitude: 0.7639750666666667, longitude: 127.37663945, timestamp: "2024-06-06T16:51:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.838504", lon: "12722.598367", latD: "N", lonD: "E", latitude: 0.7639750666666667, longitude: 127.37663945, timestamp: "2024-06-06T16:51:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.7", lat: "0045.838504", lon: "12722.598367", latD: "N", lonD: "E", latitude: 0.7639750666666667, longitude: 127.37663945, timestamp: "2024-06-06T16:51:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838508", lon: "12722.598391", latD: "N", lonD: "E", latitude: 0.7639751333333333, longitude: 127.37663985, timestamp: "2024-06-06T16:51:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838508", lon: "12722.598391", latD: "N", lonD: "E", latitude: 0.7639751333333333, longitude: 127.37663985, timestamp: "2024-06-06T16:51:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838508", lon: "12722.598391", latD: "N", lonD: "E", latitude: 0.7639751333333333, longitude: 127.37663985, timestamp: "2024-06-06T16:51:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838508", lon: "12722.598391", latD: "N", lonD: "E", latitude: 0.7639751333333333, longitude: 127.37663985, timestamp: "2024-06-06T16:51:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838516", lon: "12722.598402", latD: "N", lonD: "E", latitude: 0.7639752666666666, longitude: 127.37664003333333, timestamp: "2024-06-06T16:51:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838516", lon: "12722.598402", latD: "N", lonD: "E", latitude: 0.7639752666666666, longitude: 127.37664003333333, timestamp: "2024-06-06T16:51:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838516", lon: "12722.598402", latD: "N", lonD: "E", latitude: 0.7639752666666666, longitude: 127.37664003333333, timestamp: "2024-06-06T16:51:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838516", lon: "12722.598402", latD: "N", lonD: "E", latitude: 0.7639752666666666, longitude: 127.37664003333333, timestamp: "2024-06-06T16:51:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838512", lon: "12722.598399", latD: "N", lonD: "E", latitude: 0.7639752000000001, longitude: 127.37663998333333, timestamp: "2024-06-06T16:52:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838512", lon: "12722.598399", latD: "N", lonD: "E", latitude: 0.7639752000000001, longitude: 127.37663998333333, timestamp: "2024-06-06T16:52:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838512", lon: "12722.598399", latD: "N", lonD: "E", latitude: 0.7639752000000001, longitude: 127.37663998333333, timestamp: "2024-06-06T16:52:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838512", lon: "12722.598399", latD: "N", lonD: "E", latitude: 0.7639752000000001, longitude: 127.37663998333333, timestamp: "2024-06-06T16:52:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838509", lon: "12722.598396", latD: "N", lonD: "E", latitude: 0.76397515, longitude: 127.37663993333334, timestamp: "2024-06-06T16:52:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838509", lon: "12722.598396", latD: "N", lonD: "E", latitude: 0.76397515, longitude: 127.37663993333334, timestamp: "2024-06-06T16:52:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838509", lon: "12722.598396", latD: "N", lonD: "E", latitude: 0.76397515, longitude: 127.37663993333334, timestamp: "2024-06-06T16:52:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838509", lon: "12722.598396", latD: "N", lonD: "E", latitude: 0.76397515, longitude: 127.37663993333334, timestamp: "2024-06-06T16:52:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838514", lon: "12722.598402", latD: "N", lonD: "E", latitude: 0.7639752333333334, longitude: 127.37664003333333, timestamp: "2024-06-06T16:52:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838514", lon: "12722.598402", latD: "N", lonD: "E", latitude: 0.7639752333333334, longitude: 127.37664003333333, timestamp: "2024-06-06T16:52:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838514", lon: "12722.598402", latD: "N", lonD: "E", latitude: 0.7639752333333334, longitude: 127.37664003333333, timestamp: "2024-06-06T16:52:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838525", lon: "12722.598408", latD: "N", lonD: "E", latitude: 0.7639754166666666, longitude: 127.37664013333334, timestamp: "2024-06-06T16:53:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838514", lon: "12722.598402", latD: "N", lonD: "E", latitude: 0.7639752333333334, longitude: 127.37664003333333, timestamp: "2024-06-06T16:52:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838525", lon: "12722.598408", latD: "N", lonD: "E", latitude: 0.7639754166666666, longitude: 127.37664013333334, timestamp: "2024-06-06T16:53:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838525", lon: "12722.598408", latD: "N", lonD: "E", latitude: 0.7639754166666666, longitude: 127.37664013333334, timestamp: "2024-06-06T16:53:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "09", hdop: "0.6", lat: "0045.838525", lon: "12722.598408", latD: "N", lonD: "E", latitude: 0.7639754166666666, longitude: 127.37664013333334, timestamp: "2024-06-06T16:53:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "10", hdop: "0.6", lat: "0045.838534", lon: "12722.598408", latD: "N", lonD: "E", latitude: 0.7639755666666667, longitude: 127.37664013333334, timestamp: "2024-06-06T16:53:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "10", hdop: "0.6", lat: "0045.838534", lon: "12722.598408", latD: "N", lonD: "E", latitude: 0.7639755666666667, longitude: 127.37664013333334, timestamp: "2024-06-06T16:53:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "10", hdop: "0.6", lat: "0045.838534", lon: "12722.598408", latD: "N", lonD: "E", latitude: 0.7639755666666667, longitude: 127.37664013333334, timestamp: "2024-06-06T16:53:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838537", lon: "12722.598419", latD: "N", lonD: "E", latitude: 0.7639756166666667, longitude: 127.37664031666667, timestamp: "2024-06-06T16:53:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "10", hdop: "0.6", lat: "0045.838534", lon: "12722.598408", latD: "N", lonD: "E", latitude: 0.7639755666666667, longitude: 127.37664013333334, timestamp: "2024-06-06T16:53:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838537", lon: "12722.598419", latD: "N", lonD: "E", latitude: 0.7639756166666667, longitude: 127.37664031666667, timestamp: "2024-06-06T16:53:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838537", lon: "12722.598419", latD: "N", lonD: "E", latitude: 0.7639756166666667, longitude: 127.37664031666667, timestamp: "2024-06-06T16:53:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.8", satellites: "10", hdop: "0.6", lat: "0045.838537", lon: "12722.598419", latD: "N", lonD: "E", latitude: 0.7639756166666667, longitude: 127.37664031666667, timestamp: "2024-06-06T16:53:42.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "10", hdop: "0.6", lat: "0045.838543", lon: "12722.598418", latD: "N", lonD: "E", latitude: 0.7639757166666666, longitude: 127.3766403, timestamp: "2024-06-06T16:54:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "10", hdop: "0.6", lat: "0045.838543", lon: "12722.598418", latD: "N", lonD: "E", latitude: 0.7639757166666666, longitude: 127.3766403, timestamp: "2024-06-06T16:54:02.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "10", hdop: "0.6", lat: "0045.838543", lon: "12722.598418", latD: "N", lonD: "E", latitude: 0.7639757166666666, longitude: 127.3766403, timestamp: "2024-06-06T16:54:02.000Z" },
        { speed: "0.0", course: "213.4", altitude: "9.3", satellites: "10", hdop: "0.6", lat: "0045.838789", lon: "12722.598945", latD: "N", lonD: "E", latitude: 0.7639798166666666, longitude: 127.37664908333333, timestamp: "2024-06-06T16:54:22.000Z" },
        { speed: "0.0", course: "277.5", altitude: "7.9", satellites: "10", hdop: "0.6", lat: "0045.838543", lon: "12722.598418", latD: "N", lonD: "E", latitude: 0.7639757166666666, longitude: 127.3766403, timestamp: "2024-06-06T16:54:02.000Z" },
        { speed: "0.0", course: "213.4", altitude: "9.3", satellites: "10", hdop: "0.6", lat: "0045.838789", lon: "12722.598945", latD: "N", lonD: "E", latitude: 0.7639798166666666, longitude: 127.37664908333333, timestamp: "2024-06-06T16:54:22.000Z" },
        { speed: "0.0", course: "213.4", altitude: "9.3", satellites: "10", hdop: "0.6", lat: "0045.838789", lon: "12722.598945", latD: "N", lonD: "E", latitude: 0.7639798166666666, longitude: 127.37664908333333, timestamp: "2024-06-06T16:54:22.000Z" },
        { speed: "0.0", course: "213.4", altitude: "9.3", satellites: "10", hdop: "0.6", lat: "0045.838789", lon: "12722.598945", latD: "N", lonD: "E", latitude: 0.7639798166666666, longitude: 127.37664908333333, timestamp: "2024-06-06T16:54:22.000Z" },
        { speed: "0.0", course: "54.9", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.838536", lon: "12722.598870", latD: "N", lonD: "E", latitude: 0.7639756, longitude: 127.37664783333334, timestamp: "2024-06-06T16:54:42.000Z" },
        { speed: "0.0", course: "54.9", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.838536", lon: "12722.598870", latD: "N", lonD: "E", latitude: 0.7639756, longitude: 127.37664783333334, timestamp: "2024-06-06T16:54:42.000Z" },
        { speed: "0.0", course: "54.9", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.838536", lon: "12722.598870", latD: "N", lonD: "E", latitude: 0.7639756, longitude: 127.37664783333334, timestamp: "2024-06-06T16:54:42.000Z" },
        { speed: "0.0", course: "54.9", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.838536", lon: "12722.598870", latD: "N", lonD: "E", latitude: 0.7639756, longitude: 127.37664783333334, timestamp: "2024-06-06T16:54:42.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.2", satellites: "10", hdop: "0.7", lat: "0045.839145", lon: "12722.597410", latD: "N", lonD: "E", latitude: 0.76398575, longitude: 127.3766235, timestamp: "2024-06-06T16:55:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.2", satellites: "10", hdop: "0.7", lat: "0045.839145", lon: "12722.597410", latD: "N", lonD: "E", latitude: 0.76398575, longitude: 127.3766235, timestamp: "2024-06-06T16:55:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.2", satellites: "10", hdop: "0.7", lat: "0045.839145", lon: "12722.597410", latD: "N", lonD: "E", latitude: 0.76398575, longitude: 127.3766235, timestamp: "2024-06-06T16:55:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.2", satellites: "10", hdop: "0.7", lat: "0045.839145", lon: "12722.597410", latD: "N", lonD: "E", latitude: 0.76398575, longitude: 127.3766235, timestamp: "2024-06-06T16:55:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.839002", lon: "12722.597402", latD: "N", lonD: "E", latitude: 0.7639833666666667, longitude: 127.37662336666666, timestamp: "2024-06-06T16:55:22.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.839002", lon: "12722.597402", latD: "N", lonD: "E", latitude: 0.7639833666666667, longitude: 127.37662336666666, timestamp: "2024-06-06T16:55:22.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "09", hdop: "0.7", lat: "0045.838969", lon: "12722.597393", latD: "N", lonD: "E", latitude: 0.7639828166666667, longitude: 127.37662321666667, timestamp: "2024-06-06T16:55:42.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.839002", lon: "12722.597402", latD: "N", lonD: "E", latitude: 0.7639833666666667, longitude: 127.37662336666666, timestamp: "2024-06-06T16:55:22.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.839002", lon: "12722.597402", latD: "N", lonD: "E", latitude: 0.7639833666666667, longitude: 127.37662336666666, timestamp: "2024-06-06T16:55:22.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "09", hdop: "0.7", lat: "0045.838969", lon: "12722.597393", latD: "N", lonD: "E", latitude: 0.7639828166666667, longitude: 127.37662321666667, timestamp: "2024-06-06T16:55:42.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "09", hdop: "0.7", lat: "0045.838969", lon: "12722.597393", latD: "N", lonD: "E", latitude: 0.7639828166666667, longitude: 127.37662321666667, timestamp: "2024-06-06T16:55:42.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "09", hdop: "0.7", lat: "0045.838969", lon: "12722.597393", latD: "N", lonD: "E", latitude: 0.7639828166666667, longitude: 127.37662321666667, timestamp: "2024-06-06T16:55:42.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.7", lat: "0045.838937", lon: "12722.597378", latD: "N", lonD: "E", latitude: 0.7639822833333334, longitude: 127.37662296666667, timestamp: "2024-06-06T16:56:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.7", lat: "0045.838937", lon: "12722.597378", latD: "N", lonD: "E", latitude: 0.7639822833333334, longitude: 127.37662296666667, timestamp: "2024-06-06T16:56:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.7", lat: "0045.838937", lon: "12722.597378", latD: "N", lonD: "E", latitude: 0.7639822833333334, longitude: 127.37662296666667, timestamp: "2024-06-06T16:56:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.7", lat: "0045.838937", lon: "12722.597378", latD: "N", lonD: "E", latitude: 0.7639822833333334, longitude: 127.37662296666667, timestamp: "2024-06-06T16:56:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "08", hdop: "0.7", lat: "0045.838923", lon: "12722.597391", latD: "N", lonD: "E", latitude: 0.76398205, longitude: 127.37662318333334, timestamp: "2024-06-06T16:56:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "08", hdop: "0.7", lat: "0045.838923", lon: "12722.597391", latD: "N", lonD: "E", latitude: 0.76398205, longitude: 127.37662318333334, timestamp: "2024-06-06T16:56:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "08", hdop: "0.7", lat: "0045.838923", lon: "12722.597391", latD: "N", lonD: "E", latitude: 0.76398205, longitude: 127.37662318333334, timestamp: "2024-06-06T16:56:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "08", hdop: "0.7", lat: "0045.838923", lon: "12722.597391", latD: "N", lonD: "E", latitude: 0.76398205, longitude: 127.37662318333334, timestamp: "2024-06-06T16:56:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.838929", lon: "12722.597411", latD: "N", lonD: "E", latitude: 0.76398215, longitude: 127.37662351666667, timestamp: "2024-06-06T16:56:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.838929", lon: "12722.597411", latD: "N", lonD: "E", latitude: 0.76398215, longitude: 127.37662351666667, timestamp: "2024-06-06T16:56:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.838929", lon: "12722.597411", latD: "N", lonD: "E", latitude: 0.76398215, longitude: 127.37662351666667, timestamp: "2024-06-06T16:56:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.838929", lon: "12722.597411", latD: "N", lonD: "E", latitude: 0.76398215, longitude: 127.37662351666667, timestamp: "2024-06-06T16:56:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838929", lon: "12722.597372", latD: "N", lonD: "E", latitude: 0.76398215, longitude: 127.37662286666666, timestamp: "2024-06-06T16:57:02.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838929", lon: "12722.597372", latD: "N", lonD: "E", latitude: 0.76398215, longitude: 127.37662286666666, timestamp: "2024-06-06T16:57:02.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838929", lon: "12722.597372", latD: "N", lonD: "E", latitude: 0.76398215, longitude: 127.37662286666666, timestamp: "2024-06-06T16:57:02.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838929", lon: "12722.597372", latD: "N", lonD: "E", latitude: 0.76398215, longitude: 127.37662286666666, timestamp: "2024-06-06T16:57:02.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838948", lon: "12722.597356", latD: "N", lonD: "E", latitude: 0.7639824666666667, longitude: 127.3766226, timestamp: "2024-06-06T16:57:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838948", lon: "12722.597356", latD: "N", lonD: "E", latitude: 0.7639824666666667, longitude: 127.3766226, timestamp: "2024-06-06T16:57:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838948", lon: "12722.597356", latD: "N", lonD: "E", latitude: 0.7639824666666667, longitude: 127.3766226, timestamp: "2024-06-06T16:57:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838948", lon: "12722.597356", latD: "N", lonD: "E", latitude: 0.7639824666666667, longitude: 127.3766226, timestamp: "2024-06-06T16:57:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "08", hdop: "0.6", lat: "0045.838944", lon: "12722.597346", latD: "N", lonD: "E", latitude: 0.7639824, longitude: 127.37662243333334, timestamp: "2024-06-06T16:57:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "08", hdop: "0.6", lat: "0045.838944", lon: "12722.597346", latD: "N", lonD: "E", latitude: 0.7639824, longitude: 127.37662243333334, timestamp: "2024-06-06T16:57:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "08", hdop: "0.6", lat: "0045.838944", lon: "12722.597346", latD: "N", lonD: "E", latitude: 0.7639824, longitude: 127.37662243333334, timestamp: "2024-06-06T16:57:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "08", hdop: "0.6", lat: "0045.838944", lon: "12722.597346", latD: "N", lonD: "E", latitude: 0.7639824, longitude: 127.37662243333334, timestamp: "2024-06-06T16:57:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838928", lon: "12722.597362", latD: "N", lonD: "E", latitude: 0.7639821333333334, longitude: 127.3766227, timestamp: "2024-06-06T16:58:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838928", lon: "12722.597362", latD: "N", lonD: "E", latitude: 0.7639821333333334, longitude: 127.3766227, timestamp: "2024-06-06T16:58:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838928", lon: "12722.597362", latD: "N", lonD: "E", latitude: 0.7639821333333334, longitude: 127.3766227, timestamp: "2024-06-06T16:58:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "09", hdop: "0.6", lat: "0045.838928", lon: "12722.597362", latD: "N", lonD: "E", latitude: 0.7639821333333334, longitude: 127.3766227, timestamp: "2024-06-06T16:58:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "07", hdop: "0.8", lat: "0045.838936", lon: "12722.597356", latD: "N", lonD: "E", latitude: 0.7639822666666666, longitude: 127.3766226, timestamp: "2024-06-06T16:58:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "07", hdop: "0.8", lat: "0045.838936", lon: "12722.597356", latD: "N", lonD: "E", latitude: 0.7639822666666666, longitude: 127.3766226, timestamp: "2024-06-06T16:58:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "07", hdop: "0.8", lat: "0045.838936", lon: "12722.597356", latD: "N", lonD: "E", latitude: 0.7639822666666666, longitude: 127.3766226, timestamp: "2024-06-06T16:58:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.4", satellites: "07", hdop: "0.8", lat: "0045.838936", lon: "12722.597356", latD: "N", lonD: "E", latitude: 0.7639822666666666, longitude: 127.3766226, timestamp: "2024-06-06T16:58:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.838952", lon: "12722.597376", latD: "N", lonD: "E", latitude: 0.7639825333333333, longitude: 127.37662293333334, timestamp: "2024-06-06T16:58:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.838952", lon: "12722.597376", latD: "N", lonD: "E", latitude: 0.7639825333333333, longitude: 127.37662293333334, timestamp: "2024-06-06T16:58:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.838952", lon: "12722.597376", latD: "N", lonD: "E", latitude: 0.7639825333333333, longitude: 127.37662293333334, timestamp: "2024-06-06T16:58:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.838952", lon: "12722.597376", latD: "N", lonD: "E", latitude: 0.7639825333333333, longitude: 127.37662293333334, timestamp: "2024-06-06T16:58:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "09", hdop: "0.7", lat: "0045.838963", lon: "12722.597402", latD: "N", lonD: "E", latitude: 0.7639827166666666, longitude: 127.37662336666666, timestamp: "2024-06-06T16:59:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "09", hdop: "0.7", lat: "0045.838963", lon: "12722.597402", latD: "N", lonD: "E", latitude: 0.7639827166666666, longitude: 127.37662336666666, timestamp: "2024-06-06T16:59:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "09", hdop: "0.7", lat: "0045.838963", lon: "12722.597402", latD: "N", lonD: "E", latitude: 0.7639827166666666, longitude: 127.37662336666666, timestamp: "2024-06-06T16:59:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "09", hdop: "0.7", lat: "0045.838963", lon: "12722.597402", latD: "N", lonD: "E", latitude: 0.7639827166666666, longitude: 127.37662336666666, timestamp: "2024-06-06T16:59:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "11.9", satellites: "09", hdop: "0.7", lat: "0045.838959", lon: "12722.597430", latD: "N", lonD: "E", latitude: 0.7639826500000001, longitude: 127.37662383333333, timestamp: "2024-06-06T16:59:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "11.9", satellites: "09", hdop: "0.7", lat: "0045.838959", lon: "12722.597430", latD: "N", lonD: "E", latitude: 0.7639826500000001, longitude: 127.37662383333333, timestamp: "2024-06-06T16:59:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "11.9", satellites: "09", hdop: "0.7", lat: "0045.838959", lon: "12722.597430", latD: "N", lonD: "E", latitude: 0.7639826500000001, longitude: 127.37662383333333, timestamp: "2024-06-06T16:59:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "11.9", satellites: "09", hdop: "0.7", lat: "0045.838959", lon: "12722.597430", latD: "N", lonD: "E", latitude: 0.7639826500000001, longitude: 127.37662383333333, timestamp: "2024-06-06T16:59:23.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.838965", lon: "12722.597450", latD: "N", lonD: "E", latitude: 0.76398275, longitude: 127.37662416666667, timestamp: "2024-06-06T16:59:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.838965", lon: "12722.597450", latD: "N", lonD: "E", latitude: 0.76398275, longitude: 127.37662416666667, timestamp: "2024-06-06T16:59:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.838965", lon: "12722.597450", latD: "N", lonD: "E", latitude: 0.76398275, longitude: 127.37662416666667, timestamp: "2024-06-06T16:59:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.838965", lon: "12722.597450", latD: "N", lonD: "E", latitude: 0.76398275, longitude: 127.37662416666667, timestamp: "2024-06-06T16:59:43.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.838955", lon: "12722.597445", latD: "N", lonD: "E", latitude: 0.7639825833333334, longitude: 127.37662408333334, timestamp: "2024-06-06T17:00:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.838955", lon: "12722.597445", latD: "N", lonD: "E", latitude: 0.7639825833333334, longitude: 127.37662408333334, timestamp: "2024-06-06T17:00:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.838955", lon: "12722.597445", latD: "N", lonD: "E", latitude: 0.7639825833333334, longitude: 127.37662408333334, timestamp: "2024-06-06T17:00:03.000Z" },
        { speed: "0.0", course: "214.6", altitude: "12.6", satellites: "10", hdop: "0.6", lat: "0045.838955", lon: "12722.597445", latD: "N", lonD: "E", latitude: 0.7639825833333334, longitude: 127.37662408333334, timestamp: "2024-06-06T17:00:03.000Z" },
        { speed: "0.0", course: "261.5", altitude: "13.5", satellites: "09", hdop: "0.6", lat: "0045.838932", lon: "12722.599258", latD: "N", lonD: "E", latitude: 0.7639822, longitude: 127.3766543, timestamp: "2024-06-06T17:00:23.000Z" },
        { speed: "0.0", course: "261.5", altitude: "13.5", satellites: "09", hdop: "0.6", lat: "0045.838932", lon: "12722.599258", latD: "N", lonD: "E", latitude: 0.7639822, longitude: 127.3766543, timestamp: "2024-06-06T17:00:23.000Z" },
        { speed: "0.0", course: "261.5", altitude: "13.5", satellites: "09", hdop: "0.6", lat: "0045.838932", lon: "12722.599258", latD: "N", lonD: "E", latitude: 0.7639822, longitude: 127.3766543, timestamp: "2024-06-06T17:00:23.000Z" },
        { speed: "0.0", course: "261.5", altitude: "13.5", satellites: "09", hdop: "0.6", lat: "0045.838932", lon: "12722.599258", latD: "N", lonD: "E", latitude: 0.7639822, longitude: 127.3766543, timestamp: "2024-06-06T17:00:23.000Z" },
        { speed: "0.0", course: "234.6", altitude: "20.7", satellites: "10", hdop: "0.6", lat: "0045.838357", lon: "12722.601203", latD: "N", lonD: "E", latitude: 0.7639726166666667, longitude: 127.37668671666667, timestamp: "2024-06-06T17:00:43.000Z" },
        { speed: "0.0", course: "234.6", altitude: "20.7", satellites: "10", hdop: "0.6", lat: "0045.838357", lon: "12722.601203", latD: "N", lonD: "E", latitude: 0.7639726166666667, longitude: 127.37668671666667, timestamp: "2024-06-06T17:00:43.000Z" },
        { speed: "0.0", course: "234.6", altitude: "20.7", satellites: "10", hdop: "0.6", lat: "0045.838357", lon: "12722.601203", latD: "N", lonD: "E", latitude: 0.7639726166666667, longitude: 127.37668671666667, timestamp: "2024-06-06T17:00:43.000Z" },
        { speed: "0.0", course: "234.6", altitude: "20.7", satellites: "10", hdop: "0.6", lat: "0045.838357", lon: "12722.601203", latD: "N", lonD: "E", latitude: 0.7639726166666667, longitude: 127.37668671666667, timestamp: "2024-06-06T17:00:43.000Z" },
        { speed: "0.0", course: "295.9", altitude: "21.6", satellites: "09", hdop: "0.6", lat: "0045.838240", lon: "12722.600774", latD: "N", lonD: "E", latitude: 0.7639706666666667, longitude: 127.37667956666667, timestamp: "2024-06-06T17:01:03.000Z" },
        { speed: "0.0", course: "295.9", altitude: "21.6", satellites: "09", hdop: "0.6", lat: "0045.838240", lon: "12722.600774", latD: "N", lonD: "E", latitude: 0.7639706666666667, longitude: 127.37667956666667, timestamp: "2024-06-06T17:01:03.000Z" },
        { speed: "0.0", course: "295.9", altitude: "21.6", satellites: "09", hdop: "0.6", lat: "0045.838240", lon: "12722.600774", latD: "N", lonD: "E", latitude: 0.7639706666666667, longitude: 127.37667956666667, timestamp: "2024-06-06T17:01:03.000Z" },
        { speed: "0.0", course: "295.9", altitude: "21.6", satellites: "09", hdop: "0.6", lat: "0045.838240", lon: "12722.600774", latD: "N", lonD: "E", latitude: 0.7639706666666667, longitude: 127.37667956666667, timestamp: "2024-06-06T17:01:03.000Z" },
        { speed: "0.0", course: "295.9", altitude: "19.7", satellites: "09", hdop: "0.6", lat: "0045.837985", lon: "12722.600458", latD: "N", lonD: "E", latitude: 0.7639664166666668, longitude: 127.3766743, timestamp: "2024-06-06T17:01:23.000Z" },
        { speed: "0.0", course: "295.9", altitude: "19.7", satellites: "09", hdop: "0.6", lat: "0045.837985", lon: "12722.600458", latD: "N", lonD: "E", latitude: 0.7639664166666668, longitude: 127.3766743, timestamp: "2024-06-06T17:01:23.000Z" },
        { speed: "0.0", course: "295.9", altitude: "19.7", satellites: "09", hdop: "0.6", lat: "0045.837985", lon: "12722.600458", latD: "N", lonD: "E", latitude: 0.7639664166666668, longitude: 127.3766743, timestamp: "2024-06-06T17:01:23.000Z" },
        { speed: "0.0", course: "295.9", altitude: "19.7", satellites: "09", hdop: "0.6", lat: "0045.837985", lon: "12722.600458", latD: "N", lonD: "E", latitude: 0.7639664166666668, longitude: 127.3766743, timestamp: "2024-06-06T17:01:23.000Z" },
        { speed: "0.0", course: "228.4", altitude: "18.1", satellites: "09", hdop: "0.7", lat: "0045.838063", lon: "12722.600068", latD: "N", lonD: "E", latitude: 0.7639677166666666, longitude: 127.3766678, timestamp: "2024-06-06T17:01:43.000Z" },
        { speed: "0.0", course: "228.4", altitude: "18.1", satellites: "09", hdop: "0.7", lat: "0045.838063", lon: "12722.600068", latD: "N", lonD: "E", latitude: 0.7639677166666666, longitude: 127.3766678, timestamp: "2024-06-06T17:01:43.000Z" },
        { speed: "0.0", course: "228.4", altitude: "18.1", satellites: "09", hdop: "0.7", lat: "0045.838063", lon: "12722.600068", latD: "N", lonD: "E", latitude: 0.7639677166666666, longitude: 127.3766678, timestamp: "2024-06-06T17:01:43.000Z" },
        { speed: "0.0", course: "228.4", altitude: "18.1", satellites: "09", hdop: "0.7", lat: "0045.838063", lon: "12722.600068", latD: "N", lonD: "E", latitude: 0.7639677166666666, longitude: 127.3766678, timestamp: "2024-06-06T17:01:43.000Z" },
        { speed: "0.0", course: "183.5", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.837568", lon: "12722.597248", latD: "N", lonD: "E", latitude: 0.7639594666666666, longitude: 127.3766208, timestamp: "2024-06-06T17:02:03.000Z" },
        { speed: "0.0", course: "183.5", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.837568", lon: "12722.597248", latD: "N", lonD: "E", latitude: 0.7639594666666666, longitude: 127.3766208, timestamp: "2024-06-06T17:02:03.000Z" },
        { speed: "0.0", course: "183.5", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.837568", lon: "12722.597248", latD: "N", lonD: "E", latitude: 0.7639594666666666, longitude: 127.3766208, timestamp: "2024-06-06T17:02:03.000Z" },
        { speed: "0.0", course: "183.5", altitude: "13.5", satellites: "10", hdop: "0.6", lat: "0045.837568", lon: "12722.597248", latD: "N", lonD: "E", latitude: 0.7639594666666666, longitude: 127.3766208, timestamp: "2024-06-06T17:02:03.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837100", lon: "12722.594168", latD: "N", lonD: "E", latitude: 0.7639516666666667, longitude: 127.37656946666667, timestamp: "2024-06-06T17:02:23.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837100", lon: "12722.594168", latD: "N", lonD: "E", latitude: 0.7639516666666667, longitude: 127.37656946666667, timestamp: "2024-06-06T17:02:23.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837100", lon: "12722.594168", latD: "N", lonD: "E", latitude: 0.7639516666666667, longitude: 127.37656946666667, timestamp: "2024-06-06T17:02:23.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837100", lon: "12722.594168", latD: "N", lonD: "E", latitude: 0.7639516666666667, longitude: 127.37656946666667, timestamp: "2024-06-06T17:02:23.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837044", lon: "12722.594032", latD: "N", lonD: "E", latitude: 0.7639507333333333, longitude: 127.3765672, timestamp: "2024-06-06T17:02:43.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837044", lon: "12722.594032", latD: "N", lonD: "E", latitude: 0.7639507333333333, longitude: 127.3765672, timestamp: "2024-06-06T17:02:43.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837044", lon: "12722.594032", latD: "N", lonD: "E", latitude: 0.7639507333333333, longitude: 127.3765672, timestamp: "2024-06-06T17:02:43.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837044", lon: "12722.594032", latD: "N", lonD: "E", latitude: 0.7639507333333333, longitude: 127.3765672, timestamp: "2024-06-06T17:02:43.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837076", lon: "12722.594125", latD: "N", lonD: "E", latitude: 0.7639512666666667, longitude: 127.37656875, timestamp: "2024-06-06T17:03:03.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837076", lon: "12722.594125", latD: "N", lonD: "E", latitude: 0.7639512666666667, longitude: 127.37656875, timestamp: "2024-06-06T17:03:03.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837076", lon: "12722.594125", latD: "N", lonD: "E", latitude: 0.7639512666666667, longitude: 127.37656875, timestamp: "2024-06-06T17:03:03.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.837076", lon: "12722.594125", latD: "N", lonD: "E", latitude: 0.7639512666666667, longitude: 127.37656875, timestamp: "2024-06-06T17:03:03.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.837104", lon: "12722.594119", latD: "N", lonD: "E", latitude: 0.7639517333333333, longitude: 127.37656865, timestamp: "2024-06-06T17:03:24.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.837104", lon: "12722.594119", latD: "N", lonD: "E", latitude: 0.7639517333333333, longitude: 127.37656865, timestamp: "2024-06-06T17:03:24.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.837104", lon: "12722.594119", latD: "N", lonD: "E", latitude: 0.7639517333333333, longitude: 127.37656865, timestamp: "2024-06-06T17:03:24.000Z" },
        { speed: "0.0", course: "194.9", altitude: "13.3", satellites: "10", hdop: "0.6", lat: "0045.837104", lon: "12722.594119", latD: "N", lonD: "E", latitude: 0.7639517333333333, longitude: 127.37656865, timestamp: "2024-06-06T17:03:24.000Z" },
        { speed: "0.0", course: "230.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.837163", lon: "12722.594061", latD: "N", lonD: "E", latitude: 0.7639527166666666, longitude: 127.37656768333333, timestamp: "2024-06-06T17:03:43.000Z" },
        { speed: "0.0", course: "230.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.837163", lon: "12722.594061", latD: "N", lonD: "E", latitude: 0.7639527166666666, longitude: 127.37656768333333, timestamp: "2024-06-06T17:03:43.000Z" },
        { speed: "0.0", course: "230.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.837163", lon: "12722.594061", latD: "N", lonD: "E", latitude: 0.7639527166666666, longitude: 127.37656768333333, timestamp: "2024-06-06T17:03:43.000Z" },
        { speed: "0.0", course: "230.3", altitude: "14.1", satellites: "10", hdop: "0.6", lat: "0045.837163", lon: "12722.594061", latD: "N", lonD: "E", latitude: 0.7639527166666666, longitude: 127.37656768333333, timestamp: "2024-06-06T17:03:43.000Z" },
        { speed: "0.0", course: "167.0", altitude: "13.6", satellites: "10", hdop: "0.6", lat: "0045.836865", lon: "12722.594373", latD: "N", lonD: "E", latitude: 0.76394775, longitude: 127.37657288333334, timestamp: "2024-06-06T17:04:03.000Z" },
        { speed: "0.0", course: "167.0", altitude: "13.6", satellites: "10", hdop: "0.6", lat: "0045.836865", lon: "12722.594373", latD: "N", lonD: "E", latitude: 0.76394775, longitude: 127.37657288333334, timestamp: "2024-06-06T17:04:03.000Z" },
        { speed: "0.0", course: "167.0", altitude: "13.6", satellites: "10", hdop: "0.6", lat: "0045.836865", lon: "12722.594373", latD: "N", lonD: "E", latitude: 0.76394775, longitude: 127.37657288333334, timestamp: "2024-06-06T17:04:03.000Z" },
        { speed: "0.0", course: "167.0", altitude: "13.6", satellites: "10", hdop: "0.6", lat: "0045.836865", lon: "12722.594373", latD: "N", lonD: "E", latitude: 0.76394775, longitude: 127.37657288333334, timestamp: "2024-06-06T17:04:03.000Z" },
        { speed: "0.0", course: "163.7", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.836930", lon: "12722.595930", latD: "N", lonD: "E", latitude: 0.7639488333333334, longitude: 127.37659883333333, timestamp: "2024-06-06T17:04:23.000Z" },
        { speed: "0.0", course: "163.7", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.836930", lon: "12722.595930", latD: "N", lonD: "E", latitude: 0.7639488333333334, longitude: 127.37659883333333, timestamp: "2024-06-06T17:04:23.000Z" },
        { speed: "0.0", course: "163.7", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.836930", lon: "12722.595930", latD: "N", lonD: "E", latitude: 0.7639488333333334, longitude: 127.37659883333333, timestamp: "2024-06-06T17:04:23.000Z" },
        { speed: "0.0", course: "163.7", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.836930", lon: "12722.595930", latD: "N", lonD: "E", latitude: 0.7639488333333334, longitude: 127.37659883333333, timestamp: "2024-06-06T17:04:23.000Z" },
        { speed: "0.0", course: "130.4", altitude: "18.1", satellites: "10", hdop: "0.6", lat: "0045.837651", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.76396085, longitude: 127.37660798333333, timestamp: "2024-06-06T17:04:43.000Z" },
        { speed: "0.0", course: "130.4", altitude: "18.1", satellites: "10", hdop: "0.6", lat: "0045.837651", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.76396085, longitude: 127.37660798333333, timestamp: "2024-06-06T17:04:43.000Z" },
        { speed: "0.0", course: "130.4", altitude: "18.1", satellites: "10", hdop: "0.6", lat: "0045.837651", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.76396085, longitude: 127.37660798333333, timestamp: "2024-06-06T17:04:43.000Z" },
        { speed: "0.0", course: "130.4", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.837732", lon: "12722.596508", latD: "N", lonD: "E", latitude: 0.7639622, longitude: 127.37660846666667, timestamp: "2024-06-06T17:05:03.000Z" },
        { speed: "0.0", course: "130.4", altitude: "18.1", satellites: "10", hdop: "0.6", lat: "0045.837651", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.76396085, longitude: 127.37660798333333, timestamp: "2024-06-06T17:04:43.000Z" },
        { speed: "0.0", course: "130.4", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.837732", lon: "12722.596508", latD: "N", lonD: "E", latitude: 0.7639622, longitude: 127.37660846666667, timestamp: "2024-06-06T17:05:03.000Z" },
        { speed: "0.0", course: "130.4", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.837732", lon: "12722.596508", latD: "N", lonD: "E", latitude: 0.7639622, longitude: 127.37660846666667, timestamp: "2024-06-06T17:05:03.000Z" },
        { speed: "0.0", course: "130.4", altitude: "15.5", satellites: "10", hdop: "0.6", lat: "0045.837732", lon: "12722.596508", latD: "N", lonD: "E", latitude: 0.7639622, longitude: 127.37660846666667, timestamp: "2024-06-06T17:05:03.000Z" },
        { speed: "0.0", course: "128.1", altitude: "17.3", satellites: "10", hdop: "0.6", lat: "0045.838857", lon: "12722.595903", latD: "N", lonD: "E", latitude: 0.76398095, longitude: 127.37659838333333, timestamp: "2024-06-06T17:05:23.000Z" },
        { speed: "0.0", course: "128.1", altitude: "17.3", satellites: "10", hdop: "0.6", lat: "0045.838857", lon: "12722.595903", latD: "N", lonD: "E", latitude: 0.76398095, longitude: 127.37659838333333, timestamp: "2024-06-06T17:05:23.000Z" },
        { speed: "0.0", course: "128.1", altitude: "17.3", satellites: "10", hdop: "0.6", lat: "0045.838857", lon: "12722.595903", latD: "N", lonD: "E", latitude: 0.76398095, longitude: 127.37659838333333, timestamp: "2024-06-06T17:05:23.000Z" },
        { speed: "0.0", course: "128.1", altitude: "17.3", satellites: "10", hdop: "0.6", lat: "0045.838857", lon: "12722.595903", latD: "N", lonD: "E", latitude: 0.76398095, longitude: 127.37659838333333, timestamp: "2024-06-06T17:05:23.000Z" },
        { speed: "0.0", course: "228.1", altitude: "19.6", satellites: "10", hdop: "0.6", lat: "0045.838798", lon: "12722.595628", latD: "N", lonD: "E", latitude: 0.7639799666666666, longitude: 127.3765938, timestamp: "2024-06-06T17:05:43.000Z" },
        { speed: "0.0", course: "228.1", altitude: "19.6", satellites: "10", hdop: "0.6", lat: "0045.838798", lon: "12722.595628", latD: "N", lonD: "E", latitude: 0.7639799666666666, longitude: 127.3765938, timestamp: "2024-06-06T17:05:43.000Z" },
        { speed: "0.0", course: "228.1", altitude: "19.6", satellites: "10", hdop: "0.6", lat: "0045.838798", lon: "12722.595628", latD: "N", lonD: "E", latitude: 0.7639799666666666, longitude: 127.3765938, timestamp: "2024-06-06T17:05:43.000Z" },
        { speed: "0.0", course: "228.1", altitude: "19.6", satellites: "10", hdop: "0.6", lat: "0045.838798", lon: "12722.595628", latD: "N", lonD: "E", latitude: 0.7639799666666666, longitude: 127.3765938, timestamp: "2024-06-06T17:05:43.000Z" },
        { speed: "0.0", course: "188.5", altitude: "18.5", satellites: "10", hdop: "0.6", lat: "0045.838598", lon: "12722.596181", latD: "N", lonD: "E", latitude: 0.7639766333333333, longitude: 127.37660301666666, timestamp: "2024-06-06T17:06:03.000Z" },
        { speed: "0.0", course: "188.5", altitude: "18.5", satellites: "10", hdop: "0.6", lat: "0045.838598", lon: "12722.596181", latD: "N", lonD: "E", latitude: 0.7639766333333333, longitude: 127.37660301666666, timestamp: "2024-06-06T17:06:03.000Z" },
        { speed: "0.0", course: "188.5", altitude: "18.5", satellites: "10", hdop: "0.6", lat: "0045.838598", lon: "12722.596181", latD: "N", lonD: "E", latitude: 0.7639766333333333, longitude: 127.37660301666666, timestamp: "2024-06-06T17:06:03.000Z" },
        { speed: "0.0", course: "188.5", altitude: "18.5", satellites: "10", hdop: "0.6", lat: "0045.838598", lon: "12722.596181", latD: "N", lonD: "E", latitude: 0.7639766333333333, longitude: 127.37660301666666, timestamp: "2024-06-06T17:06:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596407", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37660678333333, timestamp: "2024-06-06T17:06:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596407", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37660678333333, timestamp: "2024-06-06T17:06:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596407", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37660678333333, timestamp: "2024-06-06T17:06:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596407", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37660678333333, timestamp: "2024-06-06T17:06:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "10", hdop: "0.6", lat: "0045.839162", lon: "12722.596529", latD: "N", lonD: "E", latitude: 0.7639860333333334, longitude: 127.37660881666666, timestamp: "2024-06-06T17:06:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "10", hdop: "0.6", lat: "0045.839162", lon: "12722.596529", latD: "N", lonD: "E", latitude: 0.7639860333333334, longitude: 127.37660881666666, timestamp: "2024-06-06T17:06:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "10", hdop: "0.6", lat: "0045.839162", lon: "12722.596529", latD: "N", lonD: "E", latitude: 0.7639860333333334, longitude: 127.37660881666666, timestamp: "2024-06-06T17:06:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "10", hdop: "0.6", lat: "0045.839162", lon: "12722.596529", latD: "N", lonD: "E", latitude: 0.7639860333333334, longitude: 127.37660881666666, timestamp: "2024-06-06T17:06:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "09", hdop: "0.7", lat: "0045.839152", lon: "12722.596525", latD: "N", lonD: "E", latitude: 0.7639858666666667, longitude: 127.37660875, timestamp: "2024-06-06T17:07:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "09", hdop: "0.7", lat: "0045.839152", lon: "12722.596525", latD: "N", lonD: "E", latitude: 0.7639858666666667, longitude: 127.37660875, timestamp: "2024-06-06T17:07:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "09", hdop: "0.7", lat: "0045.839152", lon: "12722.596525", latD: "N", lonD: "E", latitude: 0.7639858666666667, longitude: 127.37660875, timestamp: "2024-06-06T17:07:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "09", hdop: "0.7", lat: "0045.839152", lon: "12722.596525", latD: "N", lonD: "E", latitude: 0.7639858666666667, longitude: 127.37660875, timestamp: "2024-06-06T17:07:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "09", hdop: "0.7", lat: "0045.839146", lon: "12722.596532", latD: "N", lonD: "E", latitude: 0.7639857666666666, longitude: 127.37660886666667, timestamp: "2024-06-06T17:07:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "09", hdop: "0.7", lat: "0045.839146", lon: "12722.596532", latD: "N", lonD: "E", latitude: 0.7639857666666666, longitude: 127.37660886666667, timestamp: "2024-06-06T17:07:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "09", hdop: "0.7", lat: "0045.839146", lon: "12722.596532", latD: "N", lonD: "E", latitude: 0.7639857666666666, longitude: 127.37660886666667, timestamp: "2024-06-06T17:07:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "09", hdop: "0.7", lat: "0045.839146", lon: "12722.596532", latD: "N", lonD: "E", latitude: 0.7639857666666666, longitude: 127.37660886666667, timestamp: "2024-06-06T17:07:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "08", hdop: "0.7", lat: "0045.839137", lon: "12722.596530", latD: "N", lonD: "E", latitude: 0.7639856166666666, longitude: 127.37660883333334, timestamp: "2024-06-06T17:07:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "08", hdop: "0.7", lat: "0045.839137", lon: "12722.596530", latD: "N", lonD: "E", latitude: 0.7639856166666666, longitude: 127.37660883333334, timestamp: "2024-06-06T17:07:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "08", hdop: "0.7", lat: "0045.839137", lon: "12722.596530", latD: "N", lonD: "E", latitude: 0.7639856166666666, longitude: 127.37660883333334, timestamp: "2024-06-06T17:07:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "08", hdop: "0.7", lat: "0045.839137", lon: "12722.596530", latD: "N", lonD: "E", latitude: 0.7639856166666666, longitude: 127.37660883333334, timestamp: "2024-06-06T17:07:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "08", hdop: "0.7", lat: "0045.839119", lon: "12722.596527", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.37660878333334, timestamp: "2024-06-06T17:08:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "08", hdop: "0.7", lat: "0045.839119", lon: "12722.596527", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.37660878333334, timestamp: "2024-06-06T17:08:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "08", hdop: "0.7", lat: "0045.839119", lon: "12722.596527", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.37660878333334, timestamp: "2024-06-06T17:08:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.2", satellites: "09", hdop: "0.7", lat: "0045.839109", lon: "12722.596514", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660856666666, timestamp: "2024-06-06T17:08:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "08", hdop: "0.7", lat: "0045.839119", lon: "12722.596527", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.37660878333334, timestamp: "2024-06-06T17:08:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.2", satellites: "09", hdop: "0.7", lat: "0045.839109", lon: "12722.596514", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660856666666, timestamp: "2024-06-06T17:08:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.2", satellites: "09", hdop: "0.7", lat: "0045.839109", lon: "12722.596514", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660856666666, timestamp: "2024-06-06T17:08:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.2", satellites: "09", hdop: "0.7", lat: "0045.839109", lon: "12722.596514", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660856666666, timestamp: "2024-06-06T17:08:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "10", hdop: "0.6", lat: "0045.839115", lon: "12722.596515", latD: "N", lonD: "E", latitude: 0.76398525, longitude: 127.37660858333334, timestamp: "2024-06-06T17:08:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "10", hdop: "0.6", lat: "0045.839115", lon: "12722.596515", latD: "N", lonD: "E", latitude: 0.76398525, longitude: 127.37660858333334, timestamp: "2024-06-06T17:08:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "10", hdop: "0.6", lat: "0045.839115", lon: "12722.596515", latD: "N", lonD: "E", latitude: 0.76398525, longitude: 127.37660858333334, timestamp: "2024-06-06T17:08:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.1", satellites: "10", hdop: "0.6", lat: "0045.839115", lon: "12722.596515", latD: "N", lonD: "E", latitude: 0.76398525, longitude: 127.37660858333334, timestamp: "2024-06-06T17:08:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596481", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660801666667, timestamp: "2024-06-06T17:09:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596481", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660801666667, timestamp: "2024-06-06T17:09:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596481", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660801666667, timestamp: "2024-06-06T17:09:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596481", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660801666667, timestamp: "2024-06-06T17:09:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839085", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.76398475, longitude: 127.37660695, timestamp: "2024-06-06T17:09:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839085", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.76398475, longitude: 127.37660695, timestamp: "2024-06-06T17:09:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839085", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.76398475, longitude: 127.37660695, timestamp: "2024-06-06T17:09:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "17.0", satellites: "10", hdop: "0.6", lat: "0045.839085", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.76398475, longitude: 127.37660695, timestamp: "2024-06-06T17:09:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839083", lon: "12722.596402", latD: "N", lonD: "E", latitude: 0.7639847166666667, longitude: 127.3766067, timestamp: "2024-06-06T17:09:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839083", lon: "12722.596402", latD: "N", lonD: "E", latitude: 0.7639847166666667, longitude: 127.3766067, timestamp: "2024-06-06T17:09:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839083", lon: "12722.596402", latD: "N", lonD: "E", latitude: 0.7639847166666667, longitude: 127.3766067, timestamp: "2024-06-06T17:09:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839083", lon: "12722.596402", latD: "N", lonD: "E", latitude: 0.7639847166666667, longitude: 127.3766067, timestamp: "2024-06-06T17:09:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.7", lat: "0045.839098", lon: "12722.596442", latD: "N", lonD: "E", latitude: 0.7639849666666667, longitude: 127.37660736666666, timestamp: "2024-06-06T17:10:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.7", lat: "0045.839098", lon: "12722.596442", latD: "N", lonD: "E", latitude: 0.7639849666666667, longitude: 127.37660736666666, timestamp: "2024-06-06T17:10:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.7", lat: "0045.839098", lon: "12722.596442", latD: "N", lonD: "E", latitude: 0.7639849666666667, longitude: 127.37660736666666, timestamp: "2024-06-06T17:10:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.7", lat: "0045.839098", lon: "12722.596442", latD: "N", lonD: "E", latitude: 0.7639849666666667, longitude: 127.37660736666666, timestamp: "2024-06-06T17:10:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839099", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.7639849833333333, longitude: 127.37660726666667, timestamp: "2024-06-06T17:10:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839099", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.7639849833333333, longitude: 127.37660726666667, timestamp: "2024-06-06T17:10:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839099", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.7639849833333333, longitude: 127.37660726666667, timestamp: "2024-06-06T17:10:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839099", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.7639849833333333, longitude: 127.37660726666667, timestamp: "2024-06-06T17:10:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839097", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.76398495, longitude: 127.37660726666667, timestamp: "2024-06-06T17:10:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839097", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.76398495, longitude: 127.37660726666667, timestamp: "2024-06-06T17:10:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839097", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.76398495, longitude: 127.37660726666667, timestamp: "2024-06-06T17:10:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839097", lon: "12722.596436", latD: "N", lonD: "E", latitude: 0.76398495, longitude: 127.37660726666667, timestamp: "2024-06-06T17:10:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839102", lon: "12722.596453", latD: "N", lonD: "E", latitude: 0.7639850333333332, longitude: 127.37660755, timestamp: "2024-06-06T17:11:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839102", lon: "12722.596453", latD: "N", lonD: "E", latitude: 0.7639850333333332, longitude: 127.37660755, timestamp: "2024-06-06T17:11:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839102", lon: "12722.596453", latD: "N", lonD: "E", latitude: 0.7639850333333332, longitude: 127.37660755, timestamp: "2024-06-06T17:11:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596445", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660741666667, timestamp: "2024-06-06T17:11:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839102", lon: "12722.596453", latD: "N", lonD: "E", latitude: 0.7639850333333332, longitude: 127.37660755, timestamp: "2024-06-06T17:11:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596445", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660741666667, timestamp: "2024-06-06T17:11:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596445", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660741666667, timestamp: "2024-06-06T17:11:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.9", satellites: "10", hdop: "0.6", lat: "0045.839101", lon: "12722.596445", latD: "N", lonD: "E", latitude: 0.7639850166666666, longitude: 127.37660741666667, timestamp: "2024-06-06T17:11:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.8", satellites: "10", hdop: "0.6", lat: "0045.839106", lon: "12722.596450", latD: "N", lonD: "E", latitude: 0.7639851, longitude: 127.3766075, timestamp: "2024-06-06T17:11:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.8", satellites: "10", hdop: "0.6", lat: "0045.839106", lon: "12722.596450", latD: "N", lonD: "E", latitude: 0.7639851, longitude: 127.3766075, timestamp: "2024-06-06T17:11:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.8", satellites: "10", hdop: "0.6", lat: "0045.839106", lon: "12722.596450", latD: "N", lonD: "E", latitude: 0.7639851, longitude: 127.3766075, timestamp: "2024-06-06T17:11:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.8", satellites: "10", hdop: "0.6", lat: "0045.839106", lon: "12722.596450", latD: "N", lonD: "E", latitude: 0.7639851, longitude: 127.3766075, timestamp: "2024-06-06T17:11:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.7", satellites: "10", hdop: "0.6", lat: "0045.839109", lon: "12722.596453", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660755, timestamp: "2024-06-06T17:12:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.7", satellites: "10", hdop: "0.6", lat: "0045.839109", lon: "12722.596453", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660755, timestamp: "2024-06-06T17:12:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.7", satellites: "10", hdop: "0.6", lat: "0045.839109", lon: "12722.596453", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660755, timestamp: "2024-06-06T17:12:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.7", satellites: "10", hdop: "0.6", lat: "0045.839109", lon: "12722.596453", latD: "N", lonD: "E", latitude: 0.76398515, longitude: 127.37660755, timestamp: "2024-06-06T17:12:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.7", satellites: "10", hdop: "0.7", lat: "0045.839104", lon: "12722.596428", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660713333334, timestamp: "2024-06-06T17:12:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.7", satellites: "10", hdop: "0.7", lat: "0045.839104", lon: "12722.596428", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660713333334, timestamp: "2024-06-06T17:12:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.7", satellites: "10", hdop: "0.7", lat: "0045.839104", lon: "12722.596428", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660713333334, timestamp: "2024-06-06T17:12:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.7", satellites: "10", hdop: "0.7", lat: "0045.839104", lon: "12722.596428", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660713333334, timestamp: "2024-06-06T17:12:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839097", lon: "12722.596400", latD: "N", lonD: "E", latitude: 0.76398495, longitude: 127.37660666666666, timestamp: "2024-06-06T17:12:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839097", lon: "12722.596400", latD: "N", lonD: "E", latitude: 0.76398495, longitude: 127.37660666666666, timestamp: "2024-06-06T17:12:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839097", lon: "12722.596400", latD: "N", lonD: "E", latitude: 0.76398495, longitude: 127.37660666666666, timestamp: "2024-06-06T17:12:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "09", hdop: "0.7", lat: "0045.839104", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660695, timestamp: "2024-06-06T17:13:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839097", lon: "12722.596400", latD: "N", lonD: "E", latitude: 0.76398495, longitude: 127.37660666666666, timestamp: "2024-06-06T17:12:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "09", hdop: "0.7", lat: "0045.839104", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660695, timestamp: "2024-06-06T17:13:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "09", hdop: "0.7", lat: "0045.839104", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660695, timestamp: "2024-06-06T17:13:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "09", hdop: "0.7", lat: "0045.839104", lon: "12722.596417", latD: "N", lonD: "E", latitude: 0.7639850666666667, longitude: 127.37660695, timestamp: "2024-06-06T17:13:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "09", hdop: "0.6", lat: "0045.839112", lon: "12722.596449", latD: "N", lonD: "E", latitude: 0.7639852, longitude: 127.37660748333333, timestamp: "2024-06-06T17:13:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "09", hdop: "0.6", lat: "0045.839112", lon: "12722.596449", latD: "N", lonD: "E", latitude: 0.7639852, longitude: 127.37660748333333, timestamp: "2024-06-06T17:13:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "09", hdop: "0.6", lat: "0045.839112", lon: "12722.596449", latD: "N", lonD: "E", latitude: 0.7639852, longitude: 127.37660748333333, timestamp: "2024-06-06T17:13:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "09", hdop: "0.6", lat: "0045.839112", lon: "12722.596449", latD: "N", lonD: "E", latitude: 0.7639852, longitude: 127.37660748333333, timestamp: "2024-06-06T17:13:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839138", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639856333333334, longitude: 127.37660765, timestamp: "2024-06-06T17:13:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839138", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639856333333334, longitude: 127.37660765, timestamp: "2024-06-06T17:13:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839138", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639856333333334, longitude: 127.37660765, timestamp: "2024-06-06T17:13:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839138", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639856333333334, longitude: 127.37660765, timestamp: "2024-06-06T17:13:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839144", lon: "12722.596444", latD: "N", lonD: "E", latitude: 0.7639857333333333, longitude: 127.3766074, timestamp: "2024-06-06T17:14:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839144", lon: "12722.596444", latD: "N", lonD: "E", latitude: 0.7639857333333333, longitude: 127.3766074, timestamp: "2024-06-06T17:14:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839144", lon: "12722.596444", latD: "N", lonD: "E", latitude: 0.7639857333333333, longitude: 127.3766074, timestamp: "2024-06-06T17:14:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.5", satellites: "09", hdop: "0.6", lat: "0045.839137", lon: "12722.596422", latD: "N", lonD: "E", latitude: 0.7639856166666666, longitude: 127.37660703333333, timestamp: "2024-06-06T17:14:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.6", satellites: "10", hdop: "0.6", lat: "0045.839144", lon: "12722.596444", latD: "N", lonD: "E", latitude: 0.7639857333333333, longitude: 127.3766074, timestamp: "2024-06-06T17:14:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.5", satellites: "09", hdop: "0.6", lat: "0045.839137", lon: "12722.596422", latD: "N", lonD: "E", latitude: 0.7639856166666666, longitude: 127.37660703333333, timestamp: "2024-06-06T17:14:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.5", satellites: "09", hdop: "0.6", lat: "0045.839137", lon: "12722.596422", latD: "N", lonD: "E", latitude: 0.7639856166666666, longitude: 127.37660703333333, timestamp: "2024-06-06T17:14:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.5", satellites: "10", hdop: "0.7", lat: "0045.839126", lon: "12722.596395", latD: "N", lonD: "E", latitude: 0.7639854333333334, longitude: 127.37660658333333, timestamp: "2024-06-06T17:14:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.5", satellites: "09", hdop: "0.6", lat: "0045.839137", lon: "12722.596422", latD: "N", lonD: "E", latitude: 0.7639856166666666, longitude: 127.37660703333333, timestamp: "2024-06-06T17:14:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.5", satellites: "10", hdop: "0.7", lat: "0045.839126", lon: "12722.596395", latD: "N", lonD: "E", latitude: 0.7639854333333334, longitude: 127.37660658333333, timestamp: "2024-06-06T17:14:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.5", satellites: "10", hdop: "0.7", lat: "0045.839126", lon: "12722.596395", latD: "N", lonD: "E", latitude: 0.7639854333333334, longitude: 127.37660658333333, timestamp: "2024-06-06T17:14:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.5", satellites: "10", hdop: "0.7", lat: "0045.839126", lon: "12722.596395", latD: "N", lonD: "E", latitude: 0.7639854333333334, longitude: 127.37660658333333, timestamp: "2024-06-06T17:14:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.4", satellites: "09", hdop: "0.6", lat: "0045.839135", lon: "12722.596411", latD: "N", lonD: "E", latitude: 0.7639855833333333, longitude: 127.37660685, timestamp: "2024-06-06T17:15:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.4", satellites: "09", hdop: "0.6", lat: "0045.839135", lon: "12722.596411", latD: "N", lonD: "E", latitude: 0.7639855833333333, longitude: 127.37660685, timestamp: "2024-06-06T17:15:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.4", satellites: "09", hdop: "0.6", lat: "0045.839135", lon: "12722.596411", latD: "N", lonD: "E", latitude: 0.7639855833333333, longitude: 127.37660685, timestamp: "2024-06-06T17:15:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.4", satellites: "09", hdop: "0.6", lat: "0045.839142", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660648333333, timestamp: "2024-06-06T17:15:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.4", satellites: "09", hdop: "0.6", lat: "0045.839135", lon: "12722.596411", latD: "N", lonD: "E", latitude: 0.7639855833333333, longitude: 127.37660685, timestamp: "2024-06-06T17:15:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.4", satellites: "09", hdop: "0.6", lat: "0045.839142", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660648333333, timestamp: "2024-06-06T17:15:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.4", satellites: "09", hdop: "0.6", lat: "0045.839142", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660648333333, timestamp: "2024-06-06T17:15:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "16.4", satellites: "09", hdop: "0.6", lat: "0045.839142", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639857, longitude: 127.37660648333333, timestamp: "2024-06-06T17:15:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.839051", lon: "12722.596361", latD: "N", lonD: "E", latitude: 0.7639841833333333, longitude: 127.37660601666667, timestamp: "2024-06-06T17:15:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.839051", lon: "12722.596361", latD: "N", lonD: "E", latitude: 0.7639841833333333, longitude: 127.37660601666667, timestamp: "2024-06-06T17:15:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.839051", lon: "12722.596361", latD: "N", lonD: "E", latitude: 0.7639841833333333, longitude: 127.37660601666667, timestamp: "2024-06-06T17:15:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.839034", lon: "12722.596383", latD: "N", lonD: "E", latitude: 0.7639838999999999, longitude: 127.37660638333334, timestamp: "2024-06-06T17:16:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.839051", lon: "12722.596361", latD: "N", lonD: "E", latitude: 0.7639841833333333, longitude: 127.37660601666667, timestamp: "2024-06-06T17:15:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.839034", lon: "12722.596383", latD: "N", lonD: "E", latitude: 0.7639838999999999, longitude: 127.37660638333334, timestamp: "2024-06-06T17:16:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.839034", lon: "12722.596383", latD: "N", lonD: "E", latitude: 0.7639838999999999, longitude: 127.37660638333334, timestamp: "2024-06-06T17:16:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.6", lat: "0045.839034", lon: "12722.596383", latD: "N", lonD: "E", latitude: 0.7639838999999999, longitude: 127.37660638333334, timestamp: "2024-06-06T17:16:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839044", lon: "12722.596415", latD: "N", lonD: "E", latitude: 0.7639840666666667, longitude: 127.37660691666666, timestamp: "2024-06-06T17:16:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839044", lon: "12722.596415", latD: "N", lonD: "E", latitude: 0.7639840666666667, longitude: 127.37660691666666, timestamp: "2024-06-06T17:16:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.7", lat: "0045.839037", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.76398395, longitude: 127.37660693333333, timestamp: "2024-06-06T17:16:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839044", lon: "12722.596415", latD: "N", lonD: "E", latitude: 0.7639840666666667, longitude: 127.37660691666666, timestamp: "2024-06-06T17:16:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839044", lon: "12722.596415", latD: "N", lonD: "E", latitude: 0.7639840666666667, longitude: 127.37660691666666, timestamp: "2024-06-06T17:16:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.7", lat: "0045.839037", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.76398395, longitude: 127.37660693333333, timestamp: "2024-06-06T17:16:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.7", lat: "0045.839037", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.76398395, longitude: 127.37660693333333, timestamp: "2024-06-06T17:16:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.7", lat: "0045.839037", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.76398395, longitude: 127.37660693333333, timestamp: "2024-06-06T17:16:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839057", lon: "12722.596462", latD: "N", lonD: "E", latitude: 0.7639842833333332, longitude: 127.3766077, timestamp: "2024-06-06T17:17:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839057", lon: "12722.596462", latD: "N", lonD: "E", latitude: 0.7639842833333332, longitude: 127.3766077, timestamp: "2024-06-06T17:17:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839057", lon: "12722.596462", latD: "N", lonD: "E", latitude: 0.7639842833333332, longitude: 127.3766077, timestamp: "2024-06-06T17:17:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839057", lon: "12722.596462", latD: "N", lonD: "E", latitude: 0.7639842833333332, longitude: 127.3766077, timestamp: "2024-06-06T17:17:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839070", lon: "12722.596520", latD: "N", lonD: "E", latitude: 0.7639845, longitude: 127.37660866666667, timestamp: "2024-06-06T17:17:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839070", lon: "12722.596520", latD: "N", lonD: "E", latitude: 0.7639845, longitude: 127.37660866666667, timestamp: "2024-06-06T17:17:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839070", lon: "12722.596520", latD: "N", lonD: "E", latitude: 0.7639845, longitude: 127.37660866666667, timestamp: "2024-06-06T17:17:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.7", lat: "0045.839071", lon: "12722.596528", latD: "N", lonD: "E", latitude: 0.7639845166666667, longitude: 127.3766088, timestamp: "2024-06-06T17:17:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.9", satellites: "10", hdop: "0.6", lat: "0045.839070", lon: "12722.596520", latD: "N", lonD: "E", latitude: 0.7639845, longitude: 127.37660866666667, timestamp: "2024-06-06T17:17:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.7", lat: "0045.839071", lon: "12722.596528", latD: "N", lonD: "E", latitude: 0.7639845166666667, longitude: 127.3766088, timestamp: "2024-06-06T17:17:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.7", lat: "0045.839071", lon: "12722.596528", latD: "N", lonD: "E", latitude: 0.7639845166666667, longitude: 127.3766088, timestamp: "2024-06-06T17:17:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "10", hdop: "0.7", lat: "0045.839071", lon: "12722.596528", latD: "N", lonD: "E", latitude: 0.7639845166666667, longitude: 127.3766088, timestamp: "2024-06-06T17:17:43.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839079", lon: "12722.596541", latD: "N", lonD: "E", latitude: 0.7639846499999999, longitude: 127.37660901666666, timestamp: "2024-06-06T17:18:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839079", lon: "12722.596541", latD: "N", lonD: "E", latitude: 0.7639846499999999, longitude: 127.37660901666666, timestamp: "2024-06-06T17:18:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839079", lon: "12722.596541", latD: "N", lonD: "E", latitude: 0.7639846499999999, longitude: 127.37660901666666, timestamp: "2024-06-06T17:18:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839079", lon: "12722.596541", latD: "N", lonD: "E", latitude: 0.7639846499999999, longitude: 127.37660901666666, timestamp: "2024-06-06T17:18:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839096", lon: "12722.596570", latD: "N", lonD: "E", latitude: 0.7639849333333333, longitude: 127.3766095, timestamp: "2024-06-06T17:18:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839096", lon: "12722.596570", latD: "N", lonD: "E", latitude: 0.7639849333333333, longitude: 127.3766095, timestamp: "2024-06-06T17:18:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839096", lon: "12722.596570", latD: "N", lonD: "E", latitude: 0.7639849333333333, longitude: 127.3766095, timestamp: "2024-06-06T17:18:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839096", lon: "12722.596570", latD: "N", lonD: "E", latitude: 0.7639849333333333, longitude: 127.3766095, timestamp: "2024-06-06T17:18:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839100", lon: "12722.596567", latD: "N", lonD: "E", latitude: 0.763985, longitude: 127.37660945, timestamp: "2024-06-06T17:18:44.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839100", lon: "12722.596567", latD: "N", lonD: "E", latitude: 0.763985, longitude: 127.37660945, timestamp: "2024-06-06T17:18:44.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839100", lon: "12722.596567", latD: "N", lonD: "E", latitude: 0.763985, longitude: 127.37660945, timestamp: "2024-06-06T17:18:44.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.8", satellites: "09", hdop: "0.8", lat: "0045.839100", lon: "12722.596567", latD: "N", lonD: "E", latitude: 0.763985, longitude: 127.37660945, timestamp: "2024-06-06T17:18:44.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.7", satellites: "09", hdop: "0.8", lat: "0045.839083", lon: "12722.596560", latD: "N", lonD: "E", latitude: 0.7639847166666667, longitude: 127.37660933333333, timestamp: "2024-06-06T17:19:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.7", satellites: "09", hdop: "0.8", lat: "0045.839083", lon: "12722.596560", latD: "N", lonD: "E", latitude: 0.7639847166666667, longitude: 127.37660933333333, timestamp: "2024-06-06T17:19:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.7", satellites: "09", hdop: "0.8", lat: "0045.839083", lon: "12722.596560", latD: "N", lonD: "E", latitude: 0.7639847166666667, longitude: 127.37660933333333, timestamp: "2024-06-06T17:19:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.7", satellites: "09", hdop: "0.8", lat: "0045.839083", lon: "12722.596560", latD: "N", lonD: "E", latitude: 0.7639847166666667, longitude: 127.37660933333333, timestamp: "2024-06-06T17:19:03.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.7", satellites: "09", hdop: "0.8", lat: "0045.839075", lon: "12722.596569", latD: "N", lonD: "E", latitude: 0.7639845833333333, longitude: 127.37660948333334, timestamp: "2024-06-06T17:19:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.7", satellites: "09", hdop: "0.8", lat: "0045.839075", lon: "12722.596569", latD: "N", lonD: "E", latitude: 0.7639845833333333, longitude: 127.37660948333334, timestamp: "2024-06-06T17:19:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.7", satellites: "09", hdop: "0.8", lat: "0045.839075", lon: "12722.596569", latD: "N", lonD: "E", latitude: 0.7639845833333333, longitude: 127.37660948333334, timestamp: "2024-06-06T17:19:23.000Z" },
        { speed: "0.0", course: "246.5", altitude: "15.7", satellites: "09", hdop: "0.8", lat: "0045.839075", lon: "12722.596569", latD: "N", lonD: "E", latitude: 0.7639845833333333, longitude: 127.37660948333334, timestamp: "2024-06-06T17:19:23.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.2", satellites: "09", hdop: "0.7", lat: "0045.838267", lon: "12722.592950", latD: "N", lonD: "E", latitude: 0.7639711166666667, longitude: 127.37654916666666, timestamp: "2024-06-06T17:19:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.2", satellites: "09", hdop: "0.7", lat: "0045.838267", lon: "12722.592950", latD: "N", lonD: "E", latitude: 0.7639711166666667, longitude: 127.37654916666666, timestamp: "2024-06-06T17:19:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.2", satellites: "09", hdop: "0.7", lat: "0045.838267", lon: "12722.592950", latD: "N", lonD: "E", latitude: 0.7639711166666667, longitude: 127.37654916666666, timestamp: "2024-06-06T17:19:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.2", satellites: "09", hdop: "0.7", lat: "0045.838267", lon: "12722.592950", latD: "N", lonD: "E", latitude: 0.7639711166666667, longitude: 127.37654916666666, timestamp: "2024-06-06T17:19:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.0", satellites: "09", hdop: "0.7", lat: "0045.838862", lon: "12722.593123", latD: "N", lonD: "E", latitude: 0.7639810333333333, longitude: 127.37655205, timestamp: "2024-06-06T17:20:03.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.0", satellites: "09", hdop: "0.7", lat: "0045.838862", lon: "12722.593123", latD: "N", lonD: "E", latitude: 0.7639810333333333, longitude: 127.37655205, timestamp: "2024-06-06T17:20:03.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.0", satellites: "09", hdop: "0.7", lat: "0045.838862", lon: "12722.593123", latD: "N", lonD: "E", latitude: 0.7639810333333333, longitude: 127.37655205, timestamp: "2024-06-06T17:20:03.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.0", satellites: "09", hdop: "0.7", lat: "0045.838862", lon: "12722.593123", latD: "N", lonD: "E", latitude: 0.7639810333333333, longitude: 127.37655205, timestamp: "2024-06-06T17:20:03.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.9", satellites: "10", hdop: "0.6", lat: "0045.838871", lon: "12722.593125", latD: "N", lonD: "E", latitude: 0.7639811833333333, longitude: 127.37655208333334, timestamp: "2024-06-06T17:20:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.9", satellites: "10", hdop: "0.6", lat: "0045.838871", lon: "12722.593125", latD: "N", lonD: "E", latitude: 0.7639811833333333, longitude: 127.37655208333334, timestamp: "2024-06-06T17:20:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.9", satellites: "10", hdop: "0.6", lat: "0045.838871", lon: "12722.593125", latD: "N", lonD: "E", latitude: 0.7639811833333333, longitude: 127.37655208333334, timestamp: "2024-06-06T17:20:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.9", satellites: "10", hdop: "0.6", lat: "0045.838871", lon: "12722.593125", latD: "N", lonD: "E", latitude: 0.7639811833333333, longitude: 127.37655208333334, timestamp: "2024-06-06T17:20:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.838824", lon: "12722.593140", latD: "N", lonD: "E", latitude: 0.7639804, longitude: 127.37655233333334, timestamp: "2024-06-06T17:20:43.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.838824", lon: "12722.593140", latD: "N", lonD: "E", latitude: 0.7639804, longitude: 127.37655233333334, timestamp: "2024-06-06T17:20:43.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.838824", lon: "12722.593140", latD: "N", lonD: "E", latitude: 0.7639804, longitude: 127.37655233333334, timestamp: "2024-06-06T17:20:43.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.7", satellites: "10", hdop: "0.6", lat: "0045.838824", lon: "12722.593140", latD: "N", lonD: "E", latitude: 0.7639804, longitude: 127.37655233333334, timestamp: "2024-06-06T17:20:43.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838769", lon: "12722.593181", latD: "N", lonD: "E", latitude: 0.7639794833333333, longitude: 127.37655301666666, timestamp: "2024-06-06T17:21:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838769", lon: "12722.593181", latD: "N", lonD: "E", latitude: 0.7639794833333333, longitude: 127.37655301666666, timestamp: "2024-06-06T17:21:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838769", lon: "12722.593181", latD: "N", lonD: "E", latitude: 0.7639794833333333, longitude: 127.37655301666666, timestamp: "2024-06-06T17:21:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838769", lon: "12722.593181", latD: "N", lonD: "E", latitude: 0.7639794833333333, longitude: 127.37655301666666, timestamp: "2024-06-06T17:21:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838702", lon: "12722.593234", latD: "N", lonD: "E", latitude: 0.7639783666666666, longitude: 127.3765539, timestamp: "2024-06-06T17:21:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838702", lon: "12722.593234", latD: "N", lonD: "E", latitude: 0.7639783666666666, longitude: 127.3765539, timestamp: "2024-06-06T17:21:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838702", lon: "12722.593234", latD: "N", lonD: "E", latitude: 0.7639783666666666, longitude: 127.3765539, timestamp: "2024-06-06T17:21:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838702", lon: "12722.593234", latD: "N", lonD: "E", latitude: 0.7639783666666666, longitude: 127.3765539, timestamp: "2024-06-06T17:21:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.2", satellites: "10", hdop: "0.6", lat: "0045.838648", lon: "12722.593299", latD: "N", lonD: "E", latitude: 0.7639774666666667, longitude: 127.37655498333334, timestamp: "2024-06-06T17:21:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.2", satellites: "10", hdop: "0.6", lat: "0045.838648", lon: "12722.593299", latD: "N", lonD: "E", latitude: 0.7639774666666667, longitude: 127.37655498333334, timestamp: "2024-06-06T17:21:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.3", satellites: "10", hdop: "0.6", lat: "0045.838659", lon: "12722.593371", latD: "N", lonD: "E", latitude: 0.76397765, longitude: 127.37655618333334, timestamp: "2024-06-06T17:22:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.3", satellites: "10", hdop: "0.6", lat: "0045.838659", lon: "12722.593371", latD: "N", lonD: "E", latitude: 0.76397765, longitude: 127.37655618333334, timestamp: "2024-06-06T17:22:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.3", satellites: "10", hdop: "0.6", lat: "0045.838659", lon: "12722.593371", latD: "N", lonD: "E", latitude: 0.76397765, longitude: 127.37655618333334, timestamp: "2024-06-06T17:22:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.3", satellites: "10", hdop: "0.6", lat: "0045.838659", lon: "12722.593371", latD: "N", lonD: "E", latitude: 0.76397765, longitude: 127.37655618333334, timestamp: "2024-06-06T17:22:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.4", satellites: "10", hdop: "0.6", lat: "0045.838669", lon: "12722.593498", latD: "N", lonD: "E", latitude: 0.7639778166666668, longitude: 127.3765583, timestamp: "2024-06-06T17:22:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.4", satellites: "10", hdop: "0.6", lat: "0045.838669", lon: "12722.593498", latD: "N", lonD: "E", latitude: 0.7639778166666668, longitude: 127.3765583, timestamp: "2024-06-06T17:22:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.4", satellites: "10", hdop: "0.6", lat: "0045.838669", lon: "12722.593498", latD: "N", lonD: "E", latitude: 0.7639778166666668, longitude: 127.3765583, timestamp: "2024-06-06T17:22:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.4", satellites: "10", hdop: "0.6", lat: "0045.838669", lon: "12722.593498", latD: "N", lonD: "E", latitude: 0.7639778166666668, longitude: 127.3765583, timestamp: "2024-06-06T17:22:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838674", lon: "12722.593590", latD: "N", lonD: "E", latitude: 0.7639779, longitude: 127.37655983333333, timestamp: "2024-06-06T17:22:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838674", lon: "12722.593590", latD: "N", lonD: "E", latitude: 0.7639779, longitude: 127.37655983333333, timestamp: "2024-06-06T17:22:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838674", lon: "12722.593590", latD: "N", lonD: "E", latitude: 0.7639779, longitude: 127.37655983333333, timestamp: "2024-06-06T17:22:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.5", satellites: "10", hdop: "0.6", lat: "0045.838674", lon: "12722.593590", latD: "N", lonD: "E", latitude: 0.7639779, longitude: 127.37655983333333, timestamp: "2024-06-06T17:22:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.838681", lon: "12722.593666", latD: "N", lonD: "E", latitude: 0.7639780166666666, longitude: 127.3765611, timestamp: "2024-06-06T17:23:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.838681", lon: "12722.593666", latD: "N", lonD: "E", latitude: 0.7639780166666666, longitude: 127.3765611, timestamp: "2024-06-06T17:23:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.838681", lon: "12722.593666", latD: "N", lonD: "E", latitude: 0.7639780166666666, longitude: 127.3765611, timestamp: "2024-06-06T17:23:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.7", satellites: "09", hdop: "0.6", lat: "0045.838681", lon: "12722.593666", latD: "N", lonD: "E", latitude: 0.7639780166666666, longitude: 127.3765611, timestamp: "2024-06-06T17:23:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.9", satellites: "09", hdop: "0.6", lat: "0045.838691", lon: "12722.593813", latD: "N", lonD: "E", latitude: 0.7639781833333333, longitude: 127.37656355, timestamp: "2024-06-06T17:23:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.9", satellites: "09", hdop: "0.6", lat: "0045.838691", lon: "12722.593813", latD: "N", lonD: "E", latitude: 0.7639781833333333, longitude: 127.37656355, timestamp: "2024-06-06T17:23:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.9", satellites: "09", hdop: "0.6", lat: "0045.838691", lon: "12722.593813", latD: "N", lonD: "E", latitude: 0.7639781833333333, longitude: 127.37656355, timestamp: "2024-06-06T17:23:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "5.9", satellites: "09", hdop: "0.6", lat: "0045.838691", lon: "12722.593813", latD: "N", lonD: "E", latitude: 0.7639781833333333, longitude: 127.37656355, timestamp: "2024-06-06T17:23:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.0", satellites: "09", hdop: "0.6", lat: "0045.838700", lon: "12722.593907", latD: "N", lonD: "E", latitude: 0.7639783333333334, longitude: 127.37656511666667, timestamp: "2024-06-06T17:23:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.0", satellites: "09", hdop: "0.6", lat: "0045.838700", lon: "12722.593907", latD: "N", lonD: "E", latitude: 0.7639783333333334, longitude: 127.37656511666667, timestamp: "2024-06-06T17:23:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.0", satellites: "09", hdop: "0.6", lat: "0045.838700", lon: "12722.593907", latD: "N", lonD: "E", latitude: 0.7639783333333334, longitude: 127.37656511666667, timestamp: "2024-06-06T17:23:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.0", satellites: "09", hdop: "0.6", lat: "0045.838700", lon: "12722.593907", latD: "N", lonD: "E", latitude: 0.7639783333333334, longitude: 127.37656511666667, timestamp: "2024-06-06T17:23:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.1", satellites: "10", hdop: "0.6", lat: "0045.838717", lon: "12722.594003", latD: "N", lonD: "E", latitude: 0.7639786166666667, longitude: 127.37656671666667, timestamp: "2024-06-06T17:24:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.1", satellites: "10", hdop: "0.6", lat: "0045.838717", lon: "12722.594003", latD: "N", lonD: "E", latitude: 0.7639786166666667, longitude: 127.37656671666667, timestamp: "2024-06-06T17:24:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.1", satellites: "10", hdop: "0.6", lat: "0045.838717", lon: "12722.594003", latD: "N", lonD: "E", latitude: 0.7639786166666667, longitude: 127.37656671666667, timestamp: "2024-06-06T17:24:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.1", satellites: "10", hdop: "0.6", lat: "0045.838717", lon: "12722.594003", latD: "N", lonD: "E", latitude: 0.7639786166666667, longitude: 127.37656671666667, timestamp: "2024-06-06T17:24:04.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.2", satellites: "09", hdop: "0.7", lat: "0045.838748", lon: "12722.594077", latD: "N", lonD: "E", latitude: 0.7639791333333333, longitude: 127.37656795, timestamp: "2024-06-06T17:24:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.2", satellites: "09", hdop: "0.7", lat: "0045.838748", lon: "12722.594077", latD: "N", lonD: "E", latitude: 0.7639791333333333, longitude: 127.37656795, timestamp: "2024-06-06T17:24:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.2", satellites: "09", hdop: "0.7", lat: "0045.838748", lon: "12722.594077", latD: "N", lonD: "E", latitude: 0.7639791333333333, longitude: 127.37656795, timestamp: "2024-06-06T17:24:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.2", satellites: "09", hdop: "0.7", lat: "0045.838748", lon: "12722.594077", latD: "N", lonD: "E", latitude: 0.7639791333333333, longitude: 127.37656795, timestamp: "2024-06-06T17:24:24.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.3", satellites: "10", hdop: "0.6", lat: "0045.838771", lon: "12722.594140", latD: "N", lonD: "E", latitude: 0.7639795166666666, longitude: 127.376569, timestamp: "2024-06-06T17:24:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.3", satellites: "10", hdop: "0.6", lat: "0045.838771", lon: "12722.594140", latD: "N", lonD: "E", latitude: 0.7639795166666666, longitude: 127.376569, timestamp: "2024-06-06T17:24:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.3", satellites: "10", hdop: "0.6", lat: "0045.838771", lon: "12722.594140", latD: "N", lonD: "E", latitude: 0.7639795166666666, longitude: 127.376569, timestamp: "2024-06-06T17:24:44.000Z" },
        { speed: "0.0", course: "219.1", altitude: "6.3", satellites: "10", hdop: "0.6", lat: "0045.838771", lon: "12722.594140", latD: "N", lonD: "E", latitude: 0.7639795166666666, longitude: 127.376569, timestamp: "2024-06-06T17:24:44.000Z" },
        { speed: "0.0", course: "114.0", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838661", lon: "12722.595111", latD: "N", lonD: "E", latitude: 0.7639776833333334, longitude: 127.37658518333333, timestamp: "2024-06-06T17:25:04.000Z" },
        { speed: "0.0", course: "114.0", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838661", lon: "12722.595111", latD: "N", lonD: "E", latitude: 0.7639776833333334, longitude: 127.37658518333333, timestamp: "2024-06-06T17:25:04.000Z" },
        { speed: "0.0", course: "114.0", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838661", lon: "12722.595111", latD: "N", lonD: "E", latitude: 0.7639776833333334, longitude: 127.37658518333333, timestamp: "2024-06-06T17:25:04.000Z" },
        { speed: "0.0", course: "114.0", altitude: "7.7", satellites: "10", hdop: "0.6", lat: "0045.838661", lon: "12722.595111", latD: "N", lonD: "E", latitude: 0.7639776833333334, longitude: 127.37658518333333, timestamp: "2024-06-06T17:25:04.000Z" },
        { speed: "0.6", course: "266.4", altitude: "8.7", satellites: "09", hdop: "0.6", lat: "0045.838115", lon: "12722.596409", latD: "N", lonD: "E", latitude: 0.7639685833333334, longitude: 127.37660681666667, timestamp: "2024-06-06T17:25:24.000Z" },
        { speed: "0.6", course: "266.4", altitude: "8.7", satellites: "09", hdop: "0.6", lat: "0045.838115", lon: "12722.596409", latD: "N", lonD: "E", latitude: 0.7639685833333334, longitude: 127.37660681666667, timestamp: "2024-06-06T17:25:24.000Z" },
        { speed: "0.6", course: "266.4", altitude: "8.7", satellites: "09", hdop: "0.6", lat: "0045.838115", lon: "12722.596409", latD: "N", lonD: "E", latitude: 0.7639685833333334, longitude: 127.37660681666667, timestamp: "2024-06-06T17:25:24.000Z" },
        { speed: "0.6", course: "266.4", altitude: "8.7", satellites: "09", hdop: "0.6", lat: "0045.838115", lon: "12722.596409", latD: "N", lonD: "E", latitude: 0.7639685833333334, longitude: 127.37660681666667, timestamp: "2024-06-06T17:25:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.838083", lon: "12722.596240", latD: "N", lonD: "E", latitude: 0.76396805, longitude: 127.376604, timestamp: "2024-06-06T17:25:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.838083", lon: "12722.596240", latD: "N", lonD: "E", latitude: 0.76396805, longitude: 127.376604, timestamp: "2024-06-06T17:25:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.838095", lon: "12722.596278", latD: "N", lonD: "E", latitude: 0.76396825, longitude: 127.37660463333333, timestamp: "2024-06-06T17:26:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.838095", lon: "12722.596278", latD: "N", lonD: "E", latitude: 0.76396825, longitude: 127.37660463333333, timestamp: "2024-06-06T17:26:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.838095", lon: "12722.596278", latD: "N", lonD: "E", latitude: 0.76396825, longitude: 127.37660463333333, timestamp: "2024-06-06T17:26:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.1", satellites: "10", hdop: "0.6", lat: "0045.838095", lon: "12722.596278", latD: "N", lonD: "E", latitude: 0.76396825, longitude: 127.37660463333333, timestamp: "2024-06-06T17:26:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.838105", lon: "12722.596311", latD: "N", lonD: "E", latitude: 0.7639684166666666, longitude: 127.37660518333334, timestamp: "2024-06-06T17:26:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.838105", lon: "12722.596311", latD: "N", lonD: "E", latitude: 0.7639684166666666, longitude: 127.37660518333334, timestamp: "2024-06-06T17:26:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.838105", lon: "12722.596311", latD: "N", lonD: "E", latitude: 0.7639684166666666, longitude: 127.37660518333334, timestamp: "2024-06-06T17:26:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.2", satellites: "11", hdop: "0.6", lat: "0045.838105", lon: "12722.596311", latD: "N", lonD: "E", latitude: 0.7639684166666666, longitude: 127.37660518333334, timestamp: "2024-06-06T17:26:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.838128", lon: "12722.596331", latD: "N", lonD: "E", latitude: 0.7639688, longitude: 127.37660551666667, timestamp: "2024-06-06T17:26:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.838128", lon: "12722.596331", latD: "N", lonD: "E", latitude: 0.7639688, longitude: 127.37660551666667, timestamp: "2024-06-06T17:26:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.838128", lon: "12722.596331", latD: "N", lonD: "E", latitude: 0.7639688, longitude: 127.37660551666667, timestamp: "2024-06-06T17:26:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.838128", lon: "12722.596331", latD: "N", lonD: "E", latitude: 0.7639688, longitude: 127.37660551666667, timestamp: "2024-06-06T17:26:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.838144", lon: "12722.596353", latD: "N", lonD: "E", latitude: 0.7639690666666666, longitude: 127.37660588333334, timestamp: "2024-06-06T17:27:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.838144", lon: "12722.596353", latD: "N", lonD: "E", latitude: 0.7639690666666666, longitude: 127.37660588333334, timestamp: "2024-06-06T17:27:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.838144", lon: "12722.596353", latD: "N", lonD: "E", latitude: 0.7639690666666666, longitude: 127.37660588333334, timestamp: "2024-06-06T17:27:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.3", satellites: "11", hdop: "0.6", lat: "0045.838144", lon: "12722.596353", latD: "N", lonD: "E", latitude: 0.7639690666666666, longitude: 127.37660588333334, timestamp: "2024-06-06T17:27:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838152", lon: "12722.596380", latD: "N", lonD: "E", latitude: 0.7639692, longitude: 127.37660633333333, timestamp: "2024-06-06T17:27:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838152", lon: "12722.596380", latD: "N", lonD: "E", latitude: 0.7639692, longitude: 127.37660633333333, timestamp: "2024-06-06T17:27:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838152", lon: "12722.596380", latD: "N", lonD: "E", latitude: 0.7639692, longitude: 127.37660633333333, timestamp: "2024-06-06T17:27:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838152", lon: "12722.596380", latD: "N", lonD: "E", latitude: 0.7639692, longitude: 127.37660633333333, timestamp: "2024-06-06T17:27:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.7", lat: "0045.838159", lon: "12722.596369", latD: "N", lonD: "E", latitude: 0.7639693166666667, longitude: 127.37660615, timestamp: "2024-06-06T17:27:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.7", lat: "0045.838159", lon: "12722.596369", latD: "N", lonD: "E", latitude: 0.7639693166666667, longitude: 127.37660615, timestamp: "2024-06-06T17:27:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.7", lat: "0045.838159", lon: "12722.596369", latD: "N", lonD: "E", latitude: 0.7639693166666667, longitude: 127.37660615, timestamp: "2024-06-06T17:27:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.7", lat: "0045.838159", lon: "12722.596369", latD: "N", lonD: "E", latitude: 0.7639693166666667, longitude: 127.37660615, timestamp: "2024-06-06T17:27:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.5", satellites: "11", hdop: "0.6", lat: "0045.838194", lon: "12722.596364", latD: "N", lonD: "E", latitude: 0.7639699, longitude: 127.37660606666667, timestamp: "2024-06-06T17:28:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.5", satellites: "11", hdop: "0.6", lat: "0045.838194", lon: "12722.596364", latD: "N", lonD: "E", latitude: 0.7639699, longitude: 127.37660606666667, timestamp: "2024-06-06T17:28:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.5", satellites: "11", hdop: "0.6", lat: "0045.838194", lon: "12722.596364", latD: "N", lonD: "E", latitude: 0.7639699, longitude: 127.37660606666667, timestamp: "2024-06-06T17:28:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.5", satellites: "11", hdop: "0.6", lat: "0045.838194", lon: "12722.596364", latD: "N", lonD: "E", latitude: 0.7639699, longitude: 127.37660606666667, timestamp: "2024-06-06T17:28:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.5", satellites: "11", hdop: "0.6", lat: "0045.838220", lon: "12722.596377", latD: "N", lonD: "E", latitude: 0.7639703333333333, longitude: 127.37660628333333, timestamp: "2024-06-06T17:28:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.5", satellites: "11", hdop: "0.6", lat: "0045.838220", lon: "12722.596377", latD: "N", lonD: "E", latitude: 0.7639703333333333, longitude: 127.37660628333333, timestamp: "2024-06-06T17:28:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.5", satellites: "11", hdop: "0.6", lat: "0045.838220", lon: "12722.596377", latD: "N", lonD: "E", latitude: 0.7639703333333333, longitude: 127.37660628333333, timestamp: "2024-06-06T17:28:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.5", satellites: "11", hdop: "0.6", lat: "0045.838220", lon: "12722.596377", latD: "N", lonD: "E", latitude: 0.7639703333333333, longitude: 127.37660628333333, timestamp: "2024-06-06T17:28:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838272", lon: "12722.596288", latD: "N", lonD: "E", latitude: 0.7639712000000001, longitude: 127.3766048, timestamp: "2024-06-06T17:28:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838272", lon: "12722.596288", latD: "N", lonD: "E", latitude: 0.7639712000000001, longitude: 127.3766048, timestamp: "2024-06-06T17:28:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838272", lon: "12722.596288", latD: "N", lonD: "E", latitude: 0.7639712000000001, longitude: 127.3766048, timestamp: "2024-06-06T17:28:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838272", lon: "12722.596288", latD: "N", lonD: "E", latitude: 0.7639712000000001, longitude: 127.3766048, timestamp: "2024-06-06T17:28:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838290", lon: "12722.596276", latD: "N", lonD: "E", latitude: 0.7639715, longitude: 127.3766046, timestamp: "2024-06-06T17:29:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838290", lon: "12722.596276", latD: "N", lonD: "E", latitude: 0.7639715, longitude: 127.3766046, timestamp: "2024-06-06T17:29:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838290", lon: "12722.596276", latD: "N", lonD: "E", latitude: 0.7639715, longitude: 127.3766046, timestamp: "2024-06-06T17:29:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "11", hdop: "0.6", lat: "0045.838290", lon: "12722.596276", latD: "N", lonD: "E", latitude: 0.7639715, longitude: 127.3766046, timestamp: "2024-06-06T17:29:04.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.838298", lon: "12722.596267", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37660445, timestamp: "2024-06-06T17:29:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.838298", lon: "12722.596267", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37660445, timestamp: "2024-06-06T17:29:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.838298", lon: "12722.596267", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37660445, timestamp: "2024-06-06T17:29:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.838298", lon: "12722.596267", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37660445, timestamp: "2024-06-06T17:29:24.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.838304", lon: "12722.596260", latD: "N", lonD: "E", latitude: 0.7639717333333333, longitude: 127.37660433333333, timestamp: "2024-06-06T17:29:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.838304", lon: "12722.596260", latD: "N", lonD: "E", latitude: 0.7639717333333333, longitude: 127.37660433333333, timestamp: "2024-06-06T17:29:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.838304", lon: "12722.596260", latD: "N", lonD: "E", latitude: 0.7639717333333333, longitude: 127.37660433333333, timestamp: "2024-06-06T17:29:44.000Z" },
        { speed: "0.0", course: "266.4", altitude: "8.4", satellites: "10", hdop: "0.6", lat: "0045.838304", lon: "12722.596260", latD: "N", lonD: "E", latitude: 0.7639717333333333, longitude: 127.37660433333333, timestamp: "2024-06-06T17:29:44.000Z" },
        { speed: "0.0", course: "243.0", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.838580", lon: "12722.596089", latD: "N", lonD: "E", latitude: 0.7639763333333334, longitude: 127.37660148333333, timestamp: "2024-06-06T17:30:04.000Z" },
        { speed: "0.0", course: "243.0", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.838580", lon: "12722.596089", latD: "N", lonD: "E", latitude: 0.7639763333333334, longitude: 127.37660148333333, timestamp: "2024-06-06T17:30:04.000Z" },
        { speed: "0.0", course: "243.0", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.838580", lon: "12722.596089", latD: "N", lonD: "E", latitude: 0.7639763333333334, longitude: 127.37660148333333, timestamp: "2024-06-06T17:30:04.000Z" },
        { speed: "0.0", course: "243.0", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.838580", lon: "12722.596089", latD: "N", lonD: "E", latitude: 0.7639763333333334, longitude: 127.37660148333333, timestamp: "2024-06-06T17:30:04.000Z" },
        { speed: "0.0", course: "243.0", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.838986", lon: "12722.595469", latD: "N", lonD: "E", latitude: 0.7639831, longitude: 127.37659115, timestamp: "2024-06-06T17:30:24.000Z" },
        { speed: "0.0", course: "243.0", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.838986", lon: "12722.595469", latD: "N", lonD: "E", latitude: 0.7639831, longitude: 127.37659115, timestamp: "2024-06-06T17:30:24.000Z" },
        { speed: "0.0", course: "243.0", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.838986", lon: "12722.595469", latD: "N", lonD: "E", latitude: 0.7639831, longitude: 127.37659115, timestamp: "2024-06-06T17:30:24.000Z" },
        { speed: "0.0", course: "255.8", altitude: "10.8", satellites: "11", hdop: "0.6", lat: "0045.839079", lon: "12722.595420", latD: "N", lonD: "E", latitude: 0.7639846499999999, longitude: 127.37659033333334, timestamp: "2024-06-06T17:30:44.000Z" },
        { speed: "0.0", course: "243.0", altitude: "10.1", satellites: "11", hdop: "0.6", lat: "0045.838986", lon: "12722.595469", latD: "N", lonD: "E", latitude: 0.7639831, longitude: 127.37659115, timestamp: "2024-06-06T17:30:24.000Z" },
        { speed: "0.0", course: "255.8", altitude: "10.8", satellites: "11", hdop: "0.6", lat: "0045.839079", lon: "12722.595420", latD: "N", lonD: "E", latitude: 0.7639846499999999, longitude: 127.37659033333334, timestamp: "2024-06-06T17:30:44.000Z" },
        { speed: "0.0", course: "255.8", altitude: "10.8", satellites: "11", hdop: "0.6", lat: "0045.839079", lon: "12722.595420", latD: "N", lonD: "E", latitude: 0.7639846499999999, longitude: 127.37659033333334, timestamp: "2024-06-06T17:30:44.000Z" },
        { speed: "0.0", course: "255.8", altitude: "10.8", satellites: "11", hdop: "0.6", lat: "0045.839079", lon: "12722.595420", latD: "N", lonD: "E", latitude: 0.7639846499999999, longitude: 127.37659033333334, timestamp: "2024-06-06T17:30:44.000Z" },
        { speed: "0.0", course: "353.1", altitude: "12.7", satellites: "11", hdop: "0.6", lat: "0045.839246", lon: "12722.596188", latD: "N", lonD: "E", latitude: 0.7639874333333334, longitude: 127.37660313333333, timestamp: "2024-06-06T17:31:04.000Z" },
        { speed: "0.0", course: "353.1", altitude: "12.7", satellites: "11", hdop: "0.6", lat: "0045.839246", lon: "12722.596188", latD: "N", lonD: "E", latitude: 0.7639874333333334, longitude: 127.37660313333333, timestamp: "2024-06-06T17:31:04.000Z" },
        { speed: "0.0", course: "353.1", altitude: "12.7", satellites: "11", hdop: "0.6", lat: "0045.839246", lon: "12722.596188", latD: "N", lonD: "E", latitude: 0.7639874333333334, longitude: 127.37660313333333, timestamp: "2024-06-06T17:31:04.000Z" },
        { speed: "0.0", course: "353.1", altitude: "12.7", satellites: "11", hdop: "0.6", lat: "0045.839246", lon: "12722.596188", latD: "N", lonD: "E", latitude: 0.7639874333333334, longitude: 127.37660313333333, timestamp: "2024-06-06T17:31:04.000Z" },
        { speed: "0.0", course: "3.8", altitude: "12.5", satellites: "11", hdop: "0.6", lat: "0045.839321", lon: "12722.596115", latD: "N", lonD: "E", latitude: 0.7639886833333333, longitude: 127.37660191666667, timestamp: "2024-06-06T17:31:24.000Z" },
        { speed: "0.0", course: "3.8", altitude: "12.5", satellites: "11", hdop: "0.6", lat: "0045.839321", lon: "12722.596115", latD: "N", lonD: "E", latitude: 0.7639886833333333, longitude: 127.37660191666667, timestamp: "2024-06-06T17:31:24.000Z" },
        { speed: "0.0", course: "3.8", altitude: "12.5", satellites: "11", hdop: "0.6", lat: "0045.839321", lon: "12722.596115", latD: "N", lonD: "E", latitude: 0.7639886833333333, longitude: 127.37660191666667, timestamp: "2024-06-06T17:31:24.000Z" },
        { speed: "0.0", course: "3.8", altitude: "12.5", satellites: "11", hdop: "0.6", lat: "0045.839321", lon: "12722.596115", latD: "N", lonD: "E", latitude: 0.7639886833333333, longitude: 127.37660191666667, timestamp: "2024-06-06T17:31:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "12.8", satellites: "10", hdop: "0.6", lat: "0045.839630", lon: "12722.597034", latD: "N", lonD: "E", latitude: 0.7639938333333334, longitude: 127.37661723333333, timestamp: "2024-06-06T17:31:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "12.8", satellites: "10", hdop: "0.6", lat: "0045.839630", lon: "12722.597034", latD: "N", lonD: "E", latitude: 0.7639938333333334, longitude: 127.37661723333333, timestamp: "2024-06-06T17:31:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "12.8", satellites: "10", hdop: "0.6", lat: "0045.839630", lon: "12722.597034", latD: "N", lonD: "E", latitude: 0.7639938333333334, longitude: 127.37661723333333, timestamp: "2024-06-06T17:31:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "12.8", satellites: "10", hdop: "0.6", lat: "0045.839630", lon: "12722.597034", latD: "N", lonD: "E", latitude: 0.7639938333333334, longitude: 127.37661723333333, timestamp: "2024-06-06T17:31:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "12.8", satellites: "09", hdop: "0.7", lat: "0045.839661", lon: "12722.597008", latD: "N", lonD: "E", latitude: 0.76399435, longitude: 127.3766168, timestamp: "2024-06-06T17:32:04.000Z" },
        { speed: "0.0", course: "22.7", altitude: "12.8", satellites: "09", hdop: "0.7", lat: "0045.839661", lon: "12722.597008", latD: "N", lonD: "E", latitude: 0.76399435, longitude: 127.3766168, timestamp: "2024-06-06T17:32:04.000Z" },
        { speed: "0.0", course: "22.7", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.839662", lon: "12722.596956", latD: "N", lonD: "E", latitude: 0.7639943666666666, longitude: 127.37661593333333, timestamp: "2024-06-06T17:32:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.839662", lon: "12722.596956", latD: "N", lonD: "E", latitude: 0.7639943666666666, longitude: 127.37661593333333, timestamp: "2024-06-06T17:32:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.839662", lon: "12722.596956", latD: "N", lonD: "E", latitude: 0.7639943666666666, longitude: 127.37661593333333, timestamp: "2024-06-06T17:32:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "12.9", satellites: "10", hdop: "0.6", lat: "0045.839662", lon: "12722.596956", latD: "N", lonD: "E", latitude: 0.7639943666666666, longitude: 127.37661593333333, timestamp: "2024-06-06T17:32:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "08", hdop: "0.6", lat: "0045.839643", lon: "12722.597025", latD: "N", lonD: "E", latitude: 0.7639940500000001, longitude: 127.37661708333333, timestamp: "2024-06-06T17:32:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "08", hdop: "0.6", lat: "0045.839643", lon: "12722.597025", latD: "N", lonD: "E", latitude: 0.7639940500000001, longitude: 127.37661708333333, timestamp: "2024-06-06T17:32:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "08", hdop: "0.6", lat: "0045.839643", lon: "12722.597025", latD: "N", lonD: "E", latitude: 0.7639940500000001, longitude: 127.37661708333333, timestamp: "2024-06-06T17:32:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "08", hdop: "0.6", lat: "0045.839643", lon: "12722.597025", latD: "N", lonD: "E", latitude: 0.7639940500000001, longitude: 127.37661708333333, timestamp: "2024-06-06T17:32:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.2", satellites: "09", hdop: "0.6", lat: "0045.839631", lon: "12722.597008", latD: "N", lonD: "E", latitude: 0.76399385, longitude: 127.3766168, timestamp: "2024-06-06T17:33:04.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.2", satellites: "09", hdop: "0.6", lat: "0045.839631", lon: "12722.597008", latD: "N", lonD: "E", latitude: 0.76399385, longitude: 127.3766168, timestamp: "2024-06-06T17:33:04.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.2", satellites: "09", hdop: "0.6", lat: "0045.839631", lon: "12722.597008", latD: "N", lonD: "E", latitude: 0.76399385, longitude: 127.3766168, timestamp: "2024-06-06T17:33:04.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.2", satellites: "09", hdop: "0.6", lat: "0045.839631", lon: "12722.597008", latD: "N", lonD: "E", latitude: 0.76399385, longitude: 127.3766168, timestamp: "2024-06-06T17:33:04.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.839637", lon: "12722.597050", latD: "N", lonD: "E", latitude: 0.76399395, longitude: 127.3766175, timestamp: "2024-06-06T17:33:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.839637", lon: "12722.597050", latD: "N", lonD: "E", latitude: 0.76399395, longitude: 127.3766175, timestamp: "2024-06-06T17:33:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.839637", lon: "12722.597050", latD: "N", lonD: "E", latitude: 0.76399395, longitude: 127.3766175, timestamp: "2024-06-06T17:33:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.839638", lon: "12722.597080", latD: "N", lonD: "E", latitude: 0.7639939666666666, longitude: 127.376618, timestamp: "2024-06-06T17:33:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.839637", lon: "12722.597050", latD: "N", lonD: "E", latitude: 0.76399395, longitude: 127.3766175, timestamp: "2024-06-06T17:33:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.2", satellites: "10", hdop: "0.6", lat: "0045.839638", lon: "12722.597080", latD: "N", lonD: "E", latitude: 0.7639939666666666, longitude: 127.376618, timestamp: "2024-06-06T17:33:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "10", hdop: "0.5", lat: "0045.839615", lon: "12722.597057", latD: "N", lonD: "E", latitude: 0.7639935833333333, longitude: 127.37661761666666, timestamp: "2024-06-06T17:34:04.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "10", hdop: "0.5", lat: "0045.839615", lon: "12722.597057", latD: "N", lonD: "E", latitude: 0.7639935833333333, longitude: 127.37661761666666, timestamp: "2024-06-06T17:34:04.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "10", hdop: "0.5", lat: "0045.839615", lon: "12722.597057", latD: "N", lonD: "E", latitude: 0.7639935833333333, longitude: 127.37661761666666, timestamp: "2024-06-06T17:34:04.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "10", hdop: "0.5", lat: "0045.839615", lon: "12722.597057", latD: "N", lonD: "E", latitude: 0.7639935833333333, longitude: 127.37661761666666, timestamp: "2024-06-06T17:34:04.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "09", hdop: "0.6", lat: "0045.839622", lon: "12722.597083", latD: "N", lonD: "E", latitude: 0.7639937, longitude: 127.37661805, timestamp: "2024-06-06T17:34:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "09", hdop: "0.6", lat: "0045.839622", lon: "12722.597083", latD: "N", lonD: "E", latitude: 0.7639937, longitude: 127.37661805, timestamp: "2024-06-06T17:34:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "09", hdop: "0.6", lat: "0045.839622", lon: "12722.597083", latD: "N", lonD: "E", latitude: 0.7639937, longitude: 127.37661805, timestamp: "2024-06-06T17:34:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "09", hdop: "0.6", lat: "0045.839622", lon: "12722.597083", latD: "N", lonD: "E", latitude: 0.7639937, longitude: 127.37661805, timestamp: "2024-06-06T17:34:24.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "10", hdop: "0.5", lat: "0045.839616", lon: "12722.597077", latD: "N", lonD: "E", latitude: 0.7639935999999999, longitude: 127.37661795, timestamp: "2024-06-06T17:34:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "10", hdop: "0.5", lat: "0045.839616", lon: "12722.597077", latD: "N", lonD: "E", latitude: 0.7639935999999999, longitude: 127.37661795, timestamp: "2024-06-06T17:34:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "10", hdop: "0.5", lat: "0045.839616", lon: "12722.597077", latD: "N", lonD: "E", latitude: 0.7639935999999999, longitude: 127.37661795, timestamp: "2024-06-06T17:34:44.000Z" },
        { speed: "0.0", course: "22.7", altitude: "13.1", satellites: "10", hdop: "0.5", lat: "0045.839616", lon: "12722.597077", latD: "N", lonD: "E", latitude: 0.7639935999999999, longitude: 127.37661795, timestamp: "2024-06-06T17:34:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "09", hdop: "0.6", lat: "0045.839096", lon: "12722.596900", latD: "N", lonD: "E", latitude: 0.7639849333333333, longitude: 127.376615, timestamp: "2024-06-06T17:35:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "09", hdop: "0.6", lat: "0045.839096", lon: "12722.596900", latD: "N", lonD: "E", latitude: 0.7639849333333333, longitude: 127.376615, timestamp: "2024-06-06T17:35:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "09", hdop: "0.6", lat: "0045.839096", lon: "12722.596900", latD: "N", lonD: "E", latitude: 0.7639849333333333, longitude: 127.376615, timestamp: "2024-06-06T17:35:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.839893", lon: "12722.597560", latD: "N", lonD: "E", latitude: 0.7639982166666666, longitude: 127.376626, timestamp: "2024-06-06T17:35:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "09", hdop: "0.6", lat: "0045.839096", lon: "12722.596900", latD: "N", lonD: "E", latitude: 0.7639849333333333, longitude: 127.376615, timestamp: "2024-06-06T17:35:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.839893", lon: "12722.597560", latD: "N", lonD: "E", latitude: 0.7639982166666666, longitude: 127.376626, timestamp: "2024-06-06T17:35:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.839893", lon: "12722.597560", latD: "N", lonD: "E", latitude: 0.7639982166666666, longitude: 127.376626, timestamp: "2024-06-06T17:35:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.839893", lon: "12722.597560", latD: "N", lonD: "E", latitude: 0.7639982166666666, longitude: 127.376626, timestamp: "2024-06-06T17:35:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839886", lon: "12722.597562", latD: "N", lonD: "E", latitude: 0.7639981, longitude: 127.37662603333334, timestamp: "2024-06-06T17:35:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839886", lon: "12722.597562", latD: "N", lonD: "E", latitude: 0.7639981, longitude: 127.37662603333334, timestamp: "2024-06-06T17:35:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839886", lon: "12722.597562", latD: "N", lonD: "E", latitude: 0.7639981, longitude: 127.37662603333334, timestamp: "2024-06-06T17:35:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839886", lon: "12722.597562", latD: "N", lonD: "E", latitude: 0.7639981, longitude: 127.37662603333334, timestamp: "2024-06-06T17:35:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839889", lon: "12722.597556", latD: "N", lonD: "E", latitude: 0.76399815, longitude: 127.37662593333333, timestamp: "2024-06-06T17:36:05.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839889", lon: "12722.597556", latD: "N", lonD: "E", latitude: 0.76399815, longitude: 127.37662593333333, timestamp: "2024-06-06T17:36:05.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839889", lon: "12722.597556", latD: "N", lonD: "E", latitude: 0.76399815, longitude: 127.37662593333333, timestamp: "2024-06-06T17:36:05.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839889", lon: "12722.597556", latD: "N", lonD: "E", latitude: 0.76399815, longitude: 127.37662593333333, timestamp: "2024-06-06T17:36:05.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.839890", lon: "12722.597547", latD: "N", lonD: "E", latitude: 0.7639981666666666, longitude: 127.37662578333334, timestamp: "2024-06-06T17:36:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.839890", lon: "12722.597547", latD: "N", lonD: "E", latitude: 0.7639981666666666, longitude: 127.37662578333334, timestamp: "2024-06-06T17:36:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.839890", lon: "12722.597547", latD: "N", lonD: "E", latitude: 0.7639981666666666, longitude: 127.37662578333334, timestamp: "2024-06-06T17:36:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "10", hdop: "0.6", lat: "0045.839890", lon: "12722.597547", latD: "N", lonD: "E", latitude: 0.7639981666666666, longitude: 127.37662578333334, timestamp: "2024-06-06T17:36:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.839887", lon: "12722.597540", latD: "N", lonD: "E", latitude: 0.7639981166666666, longitude: 127.37662566666667, timestamp: "2024-06-06T17:36:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.839887", lon: "12722.597540", latD: "N", lonD: "E", latitude: 0.7639981166666666, longitude: 127.37662566666667, timestamp: "2024-06-06T17:36:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.839887", lon: "12722.597540", latD: "N", lonD: "E", latitude: 0.7639981166666666, longitude: 127.37662566666667, timestamp: "2024-06-06T17:36:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.839879", lon: "12722.597546", latD: "N", lonD: "E", latitude: 0.7639979833333334, longitude: 127.37662576666666, timestamp: "2024-06-06T17:37:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.5", satellites: "09", hdop: "0.6", lat: "0045.839887", lon: "12722.597540", latD: "N", lonD: "E", latitude: 0.7639981166666666, longitude: 127.37662566666667, timestamp: "2024-06-06T17:36:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.839879", lon: "12722.597546", latD: "N", lonD: "E", latitude: 0.7639979833333334, longitude: 127.37662576666666, timestamp: "2024-06-06T17:37:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.839879", lon: "12722.597546", latD: "N", lonD: "E", latitude: 0.7639979833333334, longitude: 127.37662576666666, timestamp: "2024-06-06T17:37:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.4", satellites: "10", hdop: "0.6", lat: "0045.839879", lon: "12722.597546", latD: "N", lonD: "E", latitude: 0.7639979833333334, longitude: 127.37662576666666, timestamp: "2024-06-06T17:37:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839865", lon: "12722.597525", latD: "N", lonD: "E", latitude: 0.76399775, longitude: 127.37662541666667, timestamp: "2024-06-06T17:37:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839865", lon: "12722.597525", latD: "N", lonD: "E", latitude: 0.76399775, longitude: 127.37662541666667, timestamp: "2024-06-06T17:37:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839865", lon: "12722.597525", latD: "N", lonD: "E", latitude: 0.76399775, longitude: 127.37662541666667, timestamp: "2024-06-06T17:37:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839865", lon: "12722.597525", latD: "N", lonD: "E", latitude: 0.76399775, longitude: 127.37662541666667, timestamp: "2024-06-06T17:37:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839869", lon: "12722.597524", latD: "N", lonD: "E", latitude: 0.7639978166666667, longitude: 127.3766254, timestamp: "2024-06-06T17:37:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839869", lon: "12722.597524", latD: "N", lonD: "E", latitude: 0.7639978166666667, longitude: 127.3766254, timestamp: "2024-06-06T17:37:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839869", lon: "12722.597524", latD: "N", lonD: "E", latitude: 0.7639978166666667, longitude: 127.3766254, timestamp: "2024-06-06T17:37:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839869", lon: "12722.597524", latD: "N", lonD: "E", latitude: 0.7639978166666667, longitude: 127.3766254, timestamp: "2024-06-06T17:37:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839877", lon: "12722.597520", latD: "N", lonD: "E", latitude: 0.76399795, longitude: 127.37662533333334, timestamp: "2024-06-06T17:38:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839877", lon: "12722.597520", latD: "N", lonD: "E", latitude: 0.76399795, longitude: 127.37662533333334, timestamp: "2024-06-06T17:38:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839877", lon: "12722.597520", latD: "N", lonD: "E", latitude: 0.76399795, longitude: 127.37662533333334, timestamp: "2024-06-06T17:38:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.3", satellites: "10", hdop: "0.6", lat: "0045.839877", lon: "12722.597520", latD: "N", lonD: "E", latitude: 0.76399795, longitude: 127.37662533333334, timestamp: "2024-06-06T17:38:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.839867", lon: "12722.597526", latD: "N", lonD: "E", latitude: 0.7639977833333333, longitude: 127.37662543333333, timestamp: "2024-06-06T17:38:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.839867", lon: "12722.597526", latD: "N", lonD: "E", latitude: 0.7639977833333333, longitude: 127.37662543333333, timestamp: "2024-06-06T17:38:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.839867", lon: "12722.597526", latD: "N", lonD: "E", latitude: 0.7639977833333333, longitude: 127.37662543333333, timestamp: "2024-06-06T17:38:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "09", hdop: "0.6", lat: "0045.839871", lon: "12722.597548", latD: "N", lonD: "E", latitude: 0.7639978500000001, longitude: 127.3766258, timestamp: "2024-06-06T17:38:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.839867", lon: "12722.597526", latD: "N", lonD: "E", latitude: 0.7639977833333333, longitude: 127.37662543333333, timestamp: "2024-06-06T17:38:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "09", hdop: "0.6", lat: "0045.839871", lon: "12722.597548", latD: "N", lonD: "E", latitude: 0.7639978500000001, longitude: 127.3766258, timestamp: "2024-06-06T17:38:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "09", hdop: "0.6", lat: "0045.839871", lon: "12722.597548", latD: "N", lonD: "E", latitude: 0.7639978500000001, longitude: 127.3766258, timestamp: "2024-06-06T17:38:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "09", hdop: "0.6", lat: "0045.839871", lon: "12722.597548", latD: "N", lonD: "E", latitude: 0.7639978500000001, longitude: 127.3766258, timestamp: "2024-06-06T17:38:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.839867", lon: "12722.597547", latD: "N", lonD: "E", latitude: 0.7639977833333333, longitude: 127.37662578333334, timestamp: "2024-06-06T17:39:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.839867", lon: "12722.597547", latD: "N", lonD: "E", latitude: 0.7639977833333333, longitude: 127.37662578333334, timestamp: "2024-06-06T17:39:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.839867", lon: "12722.597547", latD: "N", lonD: "E", latitude: 0.7639977833333333, longitude: 127.37662578333334, timestamp: "2024-06-06T17:39:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.2", satellites: "10", hdop: "0.6", lat: "0045.839867", lon: "12722.597547", latD: "N", lonD: "E", latitude: 0.7639977833333333, longitude: 127.37662578333334, timestamp: "2024-06-06T17:39:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839850", lon: "12722.597540", latD: "N", lonD: "E", latitude: 0.7639975, longitude: 127.37662566666667, timestamp: "2024-06-06T17:39:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839850", lon: "12722.597540", latD: "N", lonD: "E", latitude: 0.7639975, longitude: 127.37662566666667, timestamp: "2024-06-06T17:39:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839834", lon: "12722.597540", latD: "N", lonD: "E", latitude: 0.7639972333333334, longitude: 127.37662566666667, timestamp: "2024-06-06T17:39:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839834", lon: "12722.597540", latD: "N", lonD: "E", latitude: 0.7639972333333334, longitude: 127.37662566666667, timestamp: "2024-06-06T17:39:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839834", lon: "12722.597540", latD: "N", lonD: "E", latitude: 0.7639972333333334, longitude: 127.37662566666667, timestamp: "2024-06-06T17:39:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839834", lon: "12722.597540", latD: "N", lonD: "E", latitude: 0.7639972333333334, longitude: 127.37662566666667, timestamp: "2024-06-06T17:39:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839845", lon: "12722.597534", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37662556666666, timestamp: "2024-06-06T17:40:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839845", lon: "12722.597534", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37662556666666, timestamp: "2024-06-06T17:40:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839845", lon: "12722.597534", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37662556666666, timestamp: "2024-06-06T17:40:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "09", hdop: "0.6", lat: "0045.839845", lon: "12722.597535", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37662558333334, timestamp: "2024-06-06T17:40:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839845", lon: "12722.597534", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37662556666666, timestamp: "2024-06-06T17:40:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "09", hdop: "0.6", lat: "0045.839845", lon: "12722.597535", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37662558333334, timestamp: "2024-06-06T17:40:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "09", hdop: "0.6", lat: "0045.839845", lon: "12722.597535", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37662558333334, timestamp: "2024-06-06T17:40:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "09", hdop: "0.6", lat: "0045.839845", lon: "12722.597535", latD: "N", lonD: "E", latitude: 0.7639974166666667, longitude: 127.37662558333334, timestamp: "2024-06-06T17:40:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "08", hdop: "0.6", lat: "0045.839846", lon: "12722.597529", latD: "N", lonD: "E", latitude: 0.7639974333333334, longitude: 127.37662548333333, timestamp: "2024-06-06T17:40:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "08", hdop: "0.6", lat: "0045.839846", lon: "12722.597529", latD: "N", lonD: "E", latitude: 0.7639974333333334, longitude: 127.37662548333333, timestamp: "2024-06-06T17:40:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "08", hdop: "0.6", lat: "0045.839846", lon: "12722.597529", latD: "N", lonD: "E", latitude: 0.7639974333333334, longitude: 127.37662548333333, timestamp: "2024-06-06T17:40:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "08", hdop: "0.6", lat: "0045.839846", lon: "12722.597529", latD: "N", lonD: "E", latitude: 0.7639974333333334, longitude: 127.37662548333333, timestamp: "2024-06-06T17:40:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839842", lon: "12722.597524", latD: "N", lonD: "E", latitude: 0.7639973666666666, longitude: 127.3766254, timestamp: "2024-06-06T17:41:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839842", lon: "12722.597524", latD: "N", lonD: "E", latitude: 0.7639973666666666, longitude: 127.3766254, timestamp: "2024-06-06T17:41:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839842", lon: "12722.597524", latD: "N", lonD: "E", latitude: 0.7639973666666666, longitude: 127.3766254, timestamp: "2024-06-06T17:41:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839842", lon: "12722.597524", latD: "N", lonD: "E", latitude: 0.7639973666666666, longitude: 127.3766254, timestamp: "2024-06-06T17:41:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839820", lon: "12722.597506", latD: "N", lonD: "E", latitude: 0.763997, longitude: 127.3766251, timestamp: "2024-06-06T17:41:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839820", lon: "12722.597506", latD: "N", lonD: "E", latitude: 0.763997, longitude: 127.3766251, timestamp: "2024-06-06T17:41:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839820", lon: "12722.597506", latD: "N", lonD: "E", latitude: 0.763997, longitude: 127.3766251, timestamp: "2024-06-06T17:41:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839820", lon: "12722.597506", latD: "N", lonD: "E", latitude: 0.763997, longitude: 127.3766251, timestamp: "2024-06-06T17:41:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "09", hdop: "0.6", lat: "0045.839793", lon: "12722.597492", latD: "N", lonD: "E", latitude: 0.76399655, longitude: 127.37662486666666, timestamp: "2024-06-06T17:41:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "09", hdop: "0.6", lat: "0045.839793", lon: "12722.597492", latD: "N", lonD: "E", latitude: 0.76399655, longitude: 127.37662486666666, timestamp: "2024-06-06T17:41:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "09", hdop: "0.6", lat: "0045.839793", lon: "12722.597492", latD: "N", lonD: "E", latitude: 0.76399655, longitude: 127.37662486666666, timestamp: "2024-06-06T17:41:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839801", lon: "12722.597490", latD: "N", lonD: "E", latitude: 0.7639966833333334, longitude: 127.37662483333334, timestamp: "2024-06-06T17:42:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "09", hdop: "0.6", lat: "0045.839793", lon: "12722.597492", latD: "N", lonD: "E", latitude: 0.76399655, longitude: 127.37662486666666, timestamp: "2024-06-06T17:41:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839801", lon: "12722.597490", latD: "N", lonD: "E", latitude: 0.7639966833333334, longitude: 127.37662483333334, timestamp: "2024-06-06T17:42:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839801", lon: "12722.597490", latD: "N", lonD: "E", latitude: 0.7639966833333334, longitude: 127.37662483333334, timestamp: "2024-06-06T17:42:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839801", lon: "12722.597490", latD: "N", lonD: "E", latitude: 0.7639966833333334, longitude: 127.37662483333334, timestamp: "2024-06-06T17:42:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839800", lon: "12722.597489", latD: "N", lonD: "E", latitude: 0.7639966666666667, longitude: 127.37662481666666, timestamp: "2024-06-06T17:42:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839800", lon: "12722.597489", latD: "N", lonD: "E", latitude: 0.7639966666666667, longitude: 127.37662481666666, timestamp: "2024-06-06T17:42:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839800", lon: "12722.597489", latD: "N", lonD: "E", latitude: 0.7639966666666667, longitude: 127.37662481666666, timestamp: "2024-06-06T17:42:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839800", lon: "12722.597489", latD: "N", lonD: "E", latitude: 0.7639966666666667, longitude: 127.37662481666666, timestamp: "2024-06-06T17:42:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839793", lon: "12722.597487", latD: "N", lonD: "E", latitude: 0.76399655, longitude: 127.37662478333333, timestamp: "2024-06-06T17:42:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839793", lon: "12722.597487", latD: "N", lonD: "E", latitude: 0.76399655, longitude: 127.37662478333333, timestamp: "2024-06-06T17:42:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839793", lon: "12722.597487", latD: "N", lonD: "E", latitude: 0.76399655, longitude: 127.37662478333333, timestamp: "2024-06-06T17:42:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839793", lon: "12722.597487", latD: "N", lonD: "E", latitude: 0.76399655, longitude: 127.37662478333333, timestamp: "2024-06-06T17:42:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839769", lon: "12722.597496", latD: "N", lonD: "E", latitude: 0.76399615, longitude: 127.37662493333333, timestamp: "2024-06-06T17:43:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839769", lon: "12722.597496", latD: "N", lonD: "E", latitude: 0.76399615, longitude: 127.37662493333333, timestamp: "2024-06-06T17:43:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839769", lon: "12722.597496", latD: "N", lonD: "E", latitude: 0.76399615, longitude: 127.37662493333333, timestamp: "2024-06-06T17:43:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839769", lon: "12722.597496", latD: "N", lonD: "E", latitude: 0.76399615, longitude: 127.37662493333333, timestamp: "2024-06-06T17:43:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839763", lon: "12722.597498", latD: "N", lonD: "E", latitude: 0.7639960499999999, longitude: 127.37662496666667, timestamp: "2024-06-06T17:43:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839763", lon: "12722.597498", latD: "N", lonD: "E", latitude: 0.7639960499999999, longitude: 127.37662496666667, timestamp: "2024-06-06T17:43:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839763", lon: "12722.597498", latD: "N", lonD: "E", latitude: 0.7639960499999999, longitude: 127.37662496666667, timestamp: "2024-06-06T17:43:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839763", lon: "12722.597498", latD: "N", lonD: "E", latitude: 0.7639960499999999, longitude: 127.37662496666667, timestamp: "2024-06-06T17:43:24.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839763", lon: "12722.597502", latD: "N", lonD: "E", latitude: 0.7639960499999999, longitude: 127.37662503333334, timestamp: "2024-06-06T17:43:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839763", lon: "12722.597502", latD: "N", lonD: "E", latitude: 0.7639960499999999, longitude: 127.37662503333334, timestamp: "2024-06-06T17:43:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839763", lon: "12722.597502", latD: "N", lonD: "E", latitude: 0.7639960499999999, longitude: 127.37662503333334, timestamp: "2024-06-06T17:43:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.1", satellites: "10", hdop: "0.6", lat: "0045.839763", lon: "12722.597502", latD: "N", lonD: "E", latitude: 0.7639960499999999, longitude: 127.37662503333334, timestamp: "2024-06-06T17:43:44.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839760", lon: "12722.597506", latD: "N", lonD: "E", latitude: 0.763996, longitude: 127.3766251, timestamp: "2024-06-06T17:44:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839760", lon: "12722.597506", latD: "N", lonD: "E", latitude: 0.763996, longitude: 127.3766251, timestamp: "2024-06-06T17:44:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839760", lon: "12722.597506", latD: "N", lonD: "E", latitude: 0.763996, longitude: 127.3766251, timestamp: "2024-06-06T17:44:04.000Z" },
        { speed: "0.0", course: "118.2", altitude: "12.0", satellites: "10", hdop: "0.6", lat: "0045.839760", lon: "12722.597506", latD: "N", lonD: "E", latitude: 0.763996, longitude: 127.3766251, timestamp: "2024-06-06T17:44:04.000Z" },
        { speed: "0.0", course: "164.8", altitude: "10.3", satellites: "10", hdop: "0.5", lat: "0045.839374", lon: "12722.596187", latD: "N", lonD: "E", latitude: 0.7639895666666666, longitude: 127.37660311666667, timestamp: "2024-06-06T17:44:24.000Z" },
        { speed: "0.0", course: "164.8", altitude: "10.3", satellites: "10", hdop: "0.5", lat: "0045.839374", lon: "12722.596187", latD: "N", lonD: "E", latitude: 0.7639895666666666, longitude: 127.37660311666667, timestamp: "2024-06-06T17:44:24.000Z" },
        { speed: "0.0", course: "164.8", altitude: "10.3", satellites: "10", hdop: "0.5", lat: "0045.839374", lon: "12722.596187", latD: "N", lonD: "E", latitude: 0.7639895666666666, longitude: 127.37660311666667, timestamp: "2024-06-06T17:44:24.000Z" },
        { speed: "0.0", course: "164.8", altitude: "10.3", satellites: "10", hdop: "0.5", lat: "0045.839374", lon: "12722.596187", latD: "N", lonD: "E", latitude: 0.7639895666666666, longitude: 127.37660311666667, timestamp: "2024-06-06T17:44:24.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.5", lat: "0045.839002", lon: "12722.595162", latD: "N", lonD: "E", latitude: 0.7639833666666667, longitude: 127.37658603333334, timestamp: "2024-06-06T17:44:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.5", lat: "0045.839002", lon: "12722.595162", latD: "N", lonD: "E", latitude: 0.7639833666666667, longitude: 127.37658603333334, timestamp: "2024-06-06T17:44:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.5", lat: "0045.839002", lon: "12722.595162", latD: "N", lonD: "E", latitude: 0.7639833666666667, longitude: 127.37658603333334, timestamp: "2024-06-06T17:44:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.5", lat: "0045.839002", lon: "12722.595162", latD: "N", lonD: "E", latitude: 0.7639833666666667, longitude: 127.37658603333334, timestamp: "2024-06-06T17:44:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838990", lon: "12722.595110", latD: "N", lonD: "E", latitude: 0.7639831666666667, longitude: 127.37658516666667, timestamp: "2024-06-06T17:45:04.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838990", lon: "12722.595110", latD: "N", lonD: "E", latitude: 0.7639831666666667, longitude: 127.37658516666667, timestamp: "2024-06-06T17:45:04.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838990", lon: "12722.595110", latD: "N", lonD: "E", latitude: 0.7639831666666667, longitude: 127.37658516666667, timestamp: "2024-06-06T17:45:04.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838990", lon: "12722.595110", latD: "N", lonD: "E", latitude: 0.7639831666666667, longitude: 127.37658516666667, timestamp: "2024-06-06T17:45:04.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838983", lon: "12722.595094", latD: "N", lonD: "E", latitude: 0.76398305, longitude: 127.3765849, timestamp: "2024-06-06T17:45:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838983", lon: "12722.595094", latD: "N", lonD: "E", latitude: 0.76398305, longitude: 127.3765849, timestamp: "2024-06-06T17:45:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838983", lon: "12722.595094", latD: "N", lonD: "E", latitude: 0.76398305, longitude: 127.3765849, timestamp: "2024-06-06T17:45:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838983", lon: "12722.595094", latD: "N", lonD: "E", latitude: 0.76398305, longitude: 127.3765849, timestamp: "2024-06-06T17:45:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838985", lon: "12722.595099", latD: "N", lonD: "E", latitude: 0.7639830833333333, longitude: 127.37658498333333, timestamp: "2024-06-06T17:45:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838985", lon: "12722.595099", latD: "N", lonD: "E", latitude: 0.7639830833333333, longitude: 127.37658498333333, timestamp: "2024-06-06T17:45:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838986", lon: "12722.595098", latD: "N", lonD: "E", latitude: 0.7639831, longitude: 127.37658496666667, timestamp: "2024-06-06T17:46:04.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838985", lon: "12722.595099", latD: "N", lonD: "E", latitude: 0.7639830833333333, longitude: 127.37658498333333, timestamp: "2024-06-06T17:45:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838985", lon: "12722.595099", latD: "N", lonD: "E", latitude: 0.7639830833333333, longitude: 127.37658498333333, timestamp: "2024-06-06T17:45:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838986", lon: "12722.595098", latD: "N", lonD: "E", latitude: 0.7639831, longitude: 127.37658496666667, timestamp: "2024-06-06T17:46:04.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838986", lon: "12722.595098", latD: "N", lonD: "E", latitude: 0.7639831, longitude: 127.37658496666667, timestamp: "2024-06-06T17:46:04.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838986", lon: "12722.595098", latD: "N", lonD: "E", latitude: 0.7639831, longitude: 127.37658496666667, timestamp: "2024-06-06T17:46:04.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838985", lon: "12722.595129", latD: "N", lonD: "E", latitude: 0.7639830833333333, longitude: 127.37658548333333, timestamp: "2024-06-06T17:46:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838985", lon: "12722.595129", latD: "N", lonD: "E", latitude: 0.7639830833333333, longitude: 127.37658548333333, timestamp: "2024-06-06T17:46:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838985", lon: "12722.595129", latD: "N", lonD: "E", latitude: 0.7639830833333333, longitude: 127.37658548333333, timestamp: "2024-06-06T17:46:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838985", lon: "12722.595129", latD: "N", lonD: "E", latitude: 0.7639830833333333, longitude: 127.37658548333333, timestamp: "2024-06-06T17:46:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838979", lon: "12722.595173", latD: "N", lonD: "E", latitude: 0.7639829833333334, longitude: 127.37658621666667, timestamp: "2024-06-06T17:46:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838979", lon: "12722.595173", latD: "N", lonD: "E", latitude: 0.7639829833333334, longitude: 127.37658621666667, timestamp: "2024-06-06T17:46:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838979", lon: "12722.595173", latD: "N", lonD: "E", latitude: 0.7639829833333334, longitude: 127.37658621666667, timestamp: "2024-06-06T17:46:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838979", lon: "12722.595173", latD: "N", lonD: "E", latitude: 0.7639829833333334, longitude: 127.37658621666667, timestamp: "2024-06-06T17:46:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838983", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.76398305, longitude: 127.37658648333333, timestamp: "2024-06-06T17:47:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838983", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.76398305, longitude: 127.37658648333333, timestamp: "2024-06-06T17:47:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838983", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.76398305, longitude: 127.37658648333333, timestamp: "2024-06-06T17:47:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838983", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.76398305, longitude: 127.37658648333333, timestamp: "2024-06-06T17:47:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838976", lon: "12722.595194", latD: "N", lonD: "E", latitude: 0.7639829333333333, longitude: 127.37658656666666, timestamp: "2024-06-06T17:47:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838976", lon: "12722.595194", latD: "N", lonD: "E", latitude: 0.7639829333333333, longitude: 127.37658656666666, timestamp: "2024-06-06T17:47:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838976", lon: "12722.595194", latD: "N", lonD: "E", latitude: 0.7639829333333333, longitude: 127.37658656666666, timestamp: "2024-06-06T17:47:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838976", lon: "12722.595194", latD: "N", lonD: "E", latitude: 0.7639829333333333, longitude: 127.37658656666666, timestamp: "2024-06-06T17:47:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838964", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639827333333333, longitude: 127.3765865, timestamp: "2024-06-06T17:47:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838964", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639827333333333, longitude: 127.3765865, timestamp: "2024-06-06T17:47:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838964", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639827333333333, longitude: 127.3765865, timestamp: "2024-06-06T17:47:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838964", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639827333333333, longitude: 127.3765865, timestamp: "2024-06-06T17:47:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838956", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.7639826000000001, longitude: 127.37658648333333, timestamp: "2024-06-06T17:48:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838956", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.7639826000000001, longitude: 127.37658648333333, timestamp: "2024-06-06T17:48:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838956", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.7639826000000001, longitude: 127.37658648333333, timestamp: "2024-06-06T17:48:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838956", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.7639826000000001, longitude: 127.37658648333333, timestamp: "2024-06-06T17:48:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838946", lon: "12722.595183", latD: "N", lonD: "E", latitude: 0.7639824333333334, longitude: 127.37658638333333, timestamp: "2024-06-06T17:48:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838946", lon: "12722.595183", latD: "N", lonD: "E", latitude: 0.7639824333333334, longitude: 127.37658638333333, timestamp: "2024-06-06T17:48:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838946", lon: "12722.595183", latD: "N", lonD: "E", latitude: 0.7639824333333334, longitude: 127.37658638333333, timestamp: "2024-06-06T17:48:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838946", lon: "12722.595183", latD: "N", lonD: "E", latitude: 0.7639824333333334, longitude: 127.37658638333333, timestamp: "2024-06-06T17:48:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838930", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.7639821666666666, longitude: 127.37658648333333, timestamp: "2024-06-06T17:48:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838930", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.7639821666666666, longitude: 127.37658648333333, timestamp: "2024-06-06T17:48:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838930", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.7639821666666666, longitude: 127.37658648333333, timestamp: "2024-06-06T17:48:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838930", lon: "12722.595189", latD: "N", lonD: "E", latitude: 0.7639821666666666, longitude: 127.37658648333333, timestamp: "2024-06-06T17:48:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838906", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639817666666667, longitude: 127.3765865, timestamp: "2024-06-06T17:49:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838906", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639817666666667, longitude: 127.3765865, timestamp: "2024-06-06T17:49:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838906", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639817666666667, longitude: 127.3765865, timestamp: "2024-06-06T17:49:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838906", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639817666666667, longitude: 127.3765865, timestamp: "2024-06-06T17:49:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838893", lon: "12722.595172", latD: "N", lonD: "E", latitude: 0.76398155, longitude: 127.3765862, timestamp: "2024-06-06T17:49:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838893", lon: "12722.595172", latD: "N", lonD: "E", latitude: 0.76398155, longitude: 127.3765862, timestamp: "2024-06-06T17:49:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838893", lon: "12722.595172", latD: "N", lonD: "E", latitude: 0.76398155, longitude: 127.3765862, timestamp: "2024-06-06T17:49:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838893", lon: "12722.595172", latD: "N", lonD: "E", latitude: 0.76398155, longitude: 127.3765862, timestamp: "2024-06-06T17:49:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838880", lon: "12722.595156", latD: "N", lonD: "E", latitude: 0.7639813333333334, longitude: 127.37658593333333, timestamp: "2024-06-06T17:49:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838880", lon: "12722.595156", latD: "N", lonD: "E", latitude: 0.7639813333333334, longitude: 127.37658593333333, timestamp: "2024-06-06T17:49:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838880", lon: "12722.595156", latD: "N", lonD: "E", latitude: 0.7639813333333334, longitude: 127.37658593333333, timestamp: "2024-06-06T17:49:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838880", lon: "12722.595156", latD: "N", lonD: "E", latitude: 0.7639813333333334, longitude: 127.37658593333333, timestamp: "2024-06-06T17:49:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838872", lon: "12722.595135", latD: "N", lonD: "E", latitude: 0.7639812, longitude: 127.37658558333334, timestamp: "2024-06-06T17:50:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838872", lon: "12722.595135", latD: "N", lonD: "E", latitude: 0.7639812, longitude: 127.37658558333334, timestamp: "2024-06-06T17:50:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838872", lon: "12722.595135", latD: "N", lonD: "E", latitude: 0.7639812, longitude: 127.37658558333334, timestamp: "2024-06-06T17:50:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838872", lon: "12722.595135", latD: "N", lonD: "E", latitude: 0.7639812, longitude: 127.37658558333334, timestamp: "2024-06-06T17:50:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838856", lon: "12722.595116", latD: "N", lonD: "E", latitude: 0.7639809333333333, longitude: 127.37658526666667, timestamp: "2024-06-06T17:50:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838856", lon: "12722.595116", latD: "N", lonD: "E", latitude: 0.7639809333333333, longitude: 127.37658526666667, timestamp: "2024-06-06T17:50:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838856", lon: "12722.595116", latD: "N", lonD: "E", latitude: 0.7639809333333333, longitude: 127.37658526666667, timestamp: "2024-06-06T17:50:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838856", lon: "12722.595116", latD: "N", lonD: "E", latitude: 0.7639809333333333, longitude: 127.37658526666667, timestamp: "2024-06-06T17:50:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838844", lon: "12722.595106", latD: "N", lonD: "E", latitude: 0.7639807333333334, longitude: 127.3765851, timestamp: "2024-06-06T17:50:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838844", lon: "12722.595106", latD: "N", lonD: "E", latitude: 0.7639807333333334, longitude: 127.3765851, timestamp: "2024-06-06T17:50:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838844", lon: "12722.595106", latD: "N", lonD: "E", latitude: 0.7639807333333334, longitude: 127.3765851, timestamp: "2024-06-06T17:50:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.5", satellites: "10", hdop: "0.6", lat: "0045.838844", lon: "12722.595106", latD: "N", lonD: "E", latitude: 0.7639807333333334, longitude: 127.3765851, timestamp: "2024-06-06T17:50:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838828", lon: "12722.595094", latD: "N", lonD: "E", latitude: 0.7639804666666666, longitude: 127.3765849, timestamp: "2024-06-06T17:51:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838828", lon: "12722.595094", latD: "N", lonD: "E", latitude: 0.7639804666666666, longitude: 127.3765849, timestamp: "2024-06-06T17:51:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838828", lon: "12722.595094", latD: "N", lonD: "E", latitude: 0.7639804666666666, longitude: 127.3765849, timestamp: "2024-06-06T17:51:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "10", hdop: "0.6", lat: "0045.838828", lon: "12722.595094", latD: "N", lonD: "E", latitude: 0.7639804666666666, longitude: 127.3765849, timestamp: "2024-06-06T17:51:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838813", lon: "12722.595104", latD: "N", lonD: "E", latitude: 0.7639802166666667, longitude: 127.37658506666666, timestamp: "2024-06-06T17:51:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838813", lon: "12722.595104", latD: "N", lonD: "E", latitude: 0.7639802166666667, longitude: 127.37658506666666, timestamp: "2024-06-06T17:51:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838813", lon: "12722.595104", latD: "N", lonD: "E", latitude: 0.7639802166666667, longitude: 127.37658506666666, timestamp: "2024-06-06T17:51:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838813", lon: "12722.595104", latD: "N", lonD: "E", latitude: 0.7639802166666667, longitude: 127.37658506666666, timestamp: "2024-06-06T17:51:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "07", hdop: "0.7", lat: "0045.838811", lon: "12722.595098", latD: "N", lonD: "E", latitude: 0.7639801833333333, longitude: 127.37658496666667, timestamp: "2024-06-06T17:51:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "07", hdop: "0.7", lat: "0045.838811", lon: "12722.595098", latD: "N", lonD: "E", latitude: 0.7639801833333333, longitude: 127.37658496666667, timestamp: "2024-06-06T17:51:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "07", hdop: "0.7", lat: "0045.838811", lon: "12722.595098", latD: "N", lonD: "E", latitude: 0.7639801833333333, longitude: 127.37658496666667, timestamp: "2024-06-06T17:51:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "07", hdop: "0.7", lat: "0045.838811", lon: "12722.595098", latD: "N", lonD: "E", latitude: 0.7639801833333333, longitude: 127.37658496666667, timestamp: "2024-06-06T17:51:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838805", lon: "12722.595107", latD: "N", lonD: "E", latitude: 0.7639800833333333, longitude: 127.37658511666666, timestamp: "2024-06-06T17:52:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838805", lon: "12722.595107", latD: "N", lonD: "E", latitude: 0.7639800833333333, longitude: 127.37658511666666, timestamp: "2024-06-06T17:52:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838805", lon: "12722.595107", latD: "N", lonD: "E", latitude: 0.7639800833333333, longitude: 127.37658511666666, timestamp: "2024-06-06T17:52:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838805", lon: "12722.595107", latD: "N", lonD: "E", latitude: 0.7639800833333333, longitude: 127.37658511666666, timestamp: "2024-06-06T17:52:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.7", lat: "0045.838801", lon: "12722.595106", latD: "N", lonD: "E", latitude: 0.7639800166666666, longitude: 127.3765851, timestamp: "2024-06-06T17:52:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.7", lat: "0045.838801", lon: "12722.595106", latD: "N", lonD: "E", latitude: 0.7639800166666666, longitude: 127.3765851, timestamp: "2024-06-06T17:52:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.7", lat: "0045.838801", lon: "12722.595106", latD: "N", lonD: "E", latitude: 0.7639800166666666, longitude: 127.3765851, timestamp: "2024-06-06T17:52:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.7", lat: "0045.838801", lon: "12722.595106", latD: "N", lonD: "E", latitude: 0.7639800166666666, longitude: 127.3765851, timestamp: "2024-06-06T17:52:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838793", lon: "12722.595103", latD: "N", lonD: "E", latitude: 0.7639798833333333, longitude: 127.37658505, timestamp: "2024-06-06T17:52:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838793", lon: "12722.595103", latD: "N", lonD: "E", latitude: 0.7639798833333333, longitude: 127.37658505, timestamp: "2024-06-06T17:52:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838793", lon: "12722.595103", latD: "N", lonD: "E", latitude: 0.7639798833333333, longitude: 127.37658505, timestamp: "2024-06-06T17:52:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "08", hdop: "0.6", lat: "0045.838793", lon: "12722.595103", latD: "N", lonD: "E", latitude: 0.7639798833333333, longitude: 127.37658505, timestamp: "2024-06-06T17:52:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "06", hdop: "0.8", lat: "0045.838788", lon: "12722.595102", latD: "N", lonD: "E", latitude: 0.7639798, longitude: 127.37658503333333, timestamp: "2024-06-06T17:53:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "06", hdop: "0.8", lat: "0045.838788", lon: "12722.595102", latD: "N", lonD: "E", latitude: 0.7639798, longitude: 127.37658503333333, timestamp: "2024-06-06T17:53:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "06", hdop: "0.8", lat: "0045.838788", lon: "12722.595102", latD: "N", lonD: "E", latitude: 0.7639798, longitude: 127.37658503333333, timestamp: "2024-06-06T17:53:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.4", satellites: "06", hdop: "0.8", lat: "0045.838788", lon: "12722.595102", latD: "N", lonD: "E", latitude: 0.7639798, longitude: 127.37658503333333, timestamp: "2024-06-06T17:53:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.3", satellites: "06", hdop: "0.7", lat: "0045.838783", lon: "12722.595086", latD: "N", lonD: "E", latitude: 0.7639797166666666, longitude: 127.37658476666667, timestamp: "2024-06-06T17:53:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.3", satellites: "06", hdop: "0.7", lat: "0045.838783", lon: "12722.595086", latD: "N", lonD: "E", latitude: 0.7639797166666666, longitude: 127.37658476666667, timestamp: "2024-06-06T17:53:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.3", satellites: "06", hdop: "0.7", lat: "0045.838783", lon: "12722.595086", latD: "N", lonD: "E", latitude: 0.7639797166666666, longitude: 127.37658476666667, timestamp: "2024-06-06T17:53:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.3", satellites: "06", hdop: "0.7", lat: "0045.838783", lon: "12722.595086", latD: "N", lonD: "E", latitude: 0.7639797166666666, longitude: 127.37658476666667, timestamp: "2024-06-06T17:53:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "07", hdop: "0.7", lat: "0045.838776", lon: "12722.595070", latD: "N", lonD: "E", latitude: 0.7639796000000001, longitude: 127.3765845, timestamp: "2024-06-06T17:53:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "07", hdop: "0.7", lat: "0045.838776", lon: "12722.595070", latD: "N", lonD: "E", latitude: 0.7639796000000001, longitude: 127.3765845, timestamp: "2024-06-06T17:53:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "07", hdop: "0.7", lat: "0045.838776", lon: "12722.595070", latD: "N", lonD: "E", latitude: 0.7639796000000001, longitude: 127.3765845, timestamp: "2024-06-06T17:53:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "07", hdop: "0.7", lat: "0045.838776", lon: "12722.595070", latD: "N", lonD: "E", latitude: 0.7639796000000001, longitude: 127.3765845, timestamp: "2024-06-06T17:53:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "08", hdop: "0.6", lat: "0045.838771", lon: "12722.595057", latD: "N", lonD: "E", latitude: 0.7639795166666666, longitude: 127.37658428333333, timestamp: "2024-06-06T17:54:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "08", hdop: "0.6", lat: "0045.838771", lon: "12722.595057", latD: "N", lonD: "E", latitude: 0.7639795166666666, longitude: 127.37658428333333, timestamp: "2024-06-06T17:54:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "08", hdop: "0.6", lat: "0045.838771", lon: "12722.595057", latD: "N", lonD: "E", latitude: 0.7639795166666666, longitude: 127.37658428333333, timestamp: "2024-06-06T17:54:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "08", hdop: "0.6", lat: "0045.838771", lon: "12722.595057", latD: "N", lonD: "E", latitude: 0.7639795166666666, longitude: 127.37658428333333, timestamp: "2024-06-06T17:54:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "06", hdop: "0.8", lat: "0045.838766", lon: "12722.595041", latD: "N", lonD: "E", latitude: 0.7639794333333333, longitude: 127.37658401666667, timestamp: "2024-06-06T17:54:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "06", hdop: "0.8", lat: "0045.838766", lon: "12722.595041", latD: "N", lonD: "E", latitude: 0.7639794333333333, longitude: 127.37658401666667, timestamp: "2024-06-06T17:54:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "06", hdop: "0.8", lat: "0045.838766", lon: "12722.595041", latD: "N", lonD: "E", latitude: 0.7639794333333333, longitude: 127.37658401666667, timestamp: "2024-06-06T17:54:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.2", satellites: "06", hdop: "0.8", lat: "0045.838766", lon: "12722.595041", latD: "N", lonD: "E", latitude: 0.7639794333333333, longitude: 127.37658401666667, timestamp: "2024-06-06T17:54:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "07", hdop: "0.7", lat: "0045.838758", lon: "12722.595024", latD: "N", lonD: "E", latitude: 0.7639793, longitude: 127.37658373333333, timestamp: "2024-06-06T17:54:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "07", hdop: "0.7", lat: "0045.838758", lon: "12722.595024", latD: "N", lonD: "E", latitude: 0.7639793, longitude: 127.37658373333333, timestamp: "2024-06-06T17:54:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "07", hdop: "0.7", lat: "0045.838758", lon: "12722.595024", latD: "N", lonD: "E", latitude: 0.7639793, longitude: 127.37658373333333, timestamp: "2024-06-06T17:54:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "07", hdop: "0.7", lat: "0045.838758", lon: "12722.595024", latD: "N", lonD: "E", latitude: 0.7639793, longitude: 127.37658373333333, timestamp: "2024-06-06T17:54:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838753", lon: "12722.595007", latD: "N", lonD: "E", latitude: 0.7639792166666666, longitude: 127.37658345, timestamp: "2024-06-06T17:55:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838753", lon: "12722.595007", latD: "N", lonD: "E", latitude: 0.7639792166666666, longitude: 127.37658345, timestamp: "2024-06-06T17:55:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838753", lon: "12722.595007", latD: "N", lonD: "E", latitude: 0.7639792166666666, longitude: 127.37658345, timestamp: "2024-06-06T17:55:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838753", lon: "12722.595007", latD: "N", lonD: "E", latitude: 0.7639792166666666, longitude: 127.37658345, timestamp: "2024-06-06T17:55:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838752", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639792, longitude: 127.37658338333334, timestamp: "2024-06-06T17:55:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838752", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639792, longitude: 127.37658338333334, timestamp: "2024-06-06T17:55:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838752", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639792, longitude: 127.37658338333334, timestamp: "2024-06-06T17:55:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838752", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639792, longitude: 127.37658338333334, timestamp: "2024-06-06T17:55:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838751", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639791833333334, longitude: 127.37658338333334, timestamp: "2024-06-06T17:55:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838751", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639791833333334, longitude: 127.37658338333334, timestamp: "2024-06-06T17:55:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838751", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639791833333334, longitude: 127.37658338333334, timestamp: "2024-06-06T17:55:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "07", hdop: "0.7", lat: "0045.838754", lon: "12722.594981", latD: "N", lonD: "E", latitude: 0.7639792333333334, longitude: 127.37658301666667, timestamp: "2024-06-06T17:56:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "06", hdop: "0.7", lat: "0045.838751", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639791833333334, longitude: 127.37658338333334, timestamp: "2024-06-06T17:55:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "07", hdop: "0.7", lat: "0045.838754", lon: "12722.594981", latD: "N", lonD: "E", latitude: 0.7639792333333334, longitude: 127.37658301666667, timestamp: "2024-06-06T17:56:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "07", hdop: "0.7", lat: "0045.838754", lon: "12722.594981", latD: "N", lonD: "E", latitude: 0.7639792333333334, longitude: 127.37658301666667, timestamp: "2024-06-06T17:56:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "08", hdop: "0.6", lat: "0045.838761", lon: "12722.594979", latD: "N", lonD: "E", latitude: 0.76397935, longitude: 127.37658298333334, timestamp: "2024-06-06T17:56:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "07", hdop: "0.7", lat: "0045.838754", lon: "12722.594981", latD: "N", lonD: "E", latitude: 0.7639792333333334, longitude: 127.37658301666667, timestamp: "2024-06-06T17:56:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "08", hdop: "0.6", lat: "0045.838761", lon: "12722.594979", latD: "N", lonD: "E", latitude: 0.76397935, longitude: 127.37658298333334, timestamp: "2024-06-06T17:56:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "08", hdop: "0.6", lat: "0045.838761", lon: "12722.594979", latD: "N", lonD: "E", latitude: 0.76397935, longitude: 127.37658298333334, timestamp: "2024-06-06T17:56:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.1", satellites: "08", hdop: "0.6", lat: "0045.838761", lon: "12722.594979", latD: "N", lonD: "E", latitude: 0.76397935, longitude: 127.37658298333334, timestamp: "2024-06-06T17:56:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838758", lon: "12722.594975", latD: "N", lonD: "E", latitude: 0.7639793, longitude: 127.37658291666666, timestamp: "2024-06-06T17:56:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838758", lon: "12722.594975", latD: "N", lonD: "E", latitude: 0.7639793, longitude: 127.37658291666666, timestamp: "2024-06-06T17:56:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838758", lon: "12722.594975", latD: "N", lonD: "E", latitude: 0.7639793, longitude: 127.37658291666666, timestamp: "2024-06-06T17:56:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838758", lon: "12722.594975", latD: "N", lonD: "E", latitude: 0.7639793, longitude: 127.37658291666666, timestamp: "2024-06-06T17:56:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838761", lon: "12722.594991", latD: "N", lonD: "E", latitude: 0.76397935, longitude: 127.37658318333334, timestamp: "2024-06-06T17:57:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838761", lon: "12722.594991", latD: "N", lonD: "E", latitude: 0.76397935, longitude: 127.37658318333334, timestamp: "2024-06-06T17:57:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838761", lon: "12722.594991", latD: "N", lonD: "E", latitude: 0.76397935, longitude: 127.37658318333334, timestamp: "2024-06-06T17:57:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838761", lon: "12722.594991", latD: "N", lonD: "E", latitude: 0.76397935, longitude: 127.37658318333334, timestamp: "2024-06-06T17:57:05.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838766", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639794333333333, longitude: 127.37658338333334, timestamp: "2024-06-06T17:57:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838766", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639794333333333, longitude: 127.37658338333334, timestamp: "2024-06-06T17:57:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838766", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639794333333333, longitude: 127.37658338333334, timestamp: "2024-06-06T17:57:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838766", lon: "12722.595003", latD: "N", lonD: "E", latitude: 0.7639794333333333, longitude: 127.37658338333334, timestamp: "2024-06-06T17:57:25.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838767", lon: "12722.595014", latD: "N", lonD: "E", latitude: 0.76397945, longitude: 127.37658356666667, timestamp: "2024-06-06T17:57:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838767", lon: "12722.595014", latD: "N", lonD: "E", latitude: 0.76397945, longitude: 127.37658356666667, timestamp: "2024-06-06T17:57:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838767", lon: "12722.595014", latD: "N", lonD: "E", latitude: 0.76397945, longitude: 127.37658356666667, timestamp: "2024-06-06T17:57:45.000Z" },
        { speed: "0.0", course: "134.3", altitude: "10.0", satellites: "07", hdop: "0.7", lat: "0045.838767", lon: "12722.595014", latD: "N", lonD: "E", latitude: 0.76397945, longitude: 127.37658356666667, timestamp: "2024-06-06T17:57:45.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "07", hdop: "0.7", lat: "0045.837021", lon: "12722.593915", latD: "N", lonD: "E", latitude: 0.76395035, longitude: 127.37656525, timestamp: "2024-06-06T17:58:05.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "07", hdop: "0.7", lat: "0045.837021", lon: "12722.593915", latD: "N", lonD: "E", latitude: 0.76395035, longitude: 127.37656525, timestamp: "2024-06-06T17:58:05.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "07", hdop: "0.7", lat: "0045.837021", lon: "12722.593915", latD: "N", lonD: "E", latitude: 0.76395035, longitude: 127.37656525, timestamp: "2024-06-06T17:58:05.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "07", hdop: "0.7", lat: "0045.837021", lon: "12722.593915", latD: "N", lonD: "E", latitude: 0.76395035, longitude: 127.37656525, timestamp: "2024-06-06T17:58:05.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.6", satellites: "08", hdop: "0.6", lat: "0045.837016", lon: "12722.594060", latD: "N", lonD: "E", latitude: 0.7639502666666667, longitude: 127.37656766666667, timestamp: "2024-06-06T17:58:25.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.6", satellites: "08", hdop: "0.6", lat: "0045.837016", lon: "12722.594060", latD: "N", lonD: "E", latitude: 0.7639502666666667, longitude: 127.37656766666667, timestamp: "2024-06-06T17:58:25.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.6", satellites: "08", hdop: "0.6", lat: "0045.837016", lon: "12722.594060", latD: "N", lonD: "E", latitude: 0.7639502666666667, longitude: 127.37656766666667, timestamp: "2024-06-06T17:58:25.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.6", satellites: "08", hdop: "0.6", lat: "0045.837016", lon: "12722.594060", latD: "N", lonD: "E", latitude: 0.7639502666666667, longitude: 127.37656766666667, timestamp: "2024-06-06T17:58:25.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.837078", lon: "12722.594141", latD: "N", lonD: "E", latitude: 0.7639513, longitude: 127.37656901666666, timestamp: "2024-06-06T17:58:45.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.837078", lon: "12722.594141", latD: "N", lonD: "E", latitude: 0.7639513, longitude: 127.37656901666666, timestamp: "2024-06-06T17:58:45.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.837078", lon: "12722.594141", latD: "N", lonD: "E", latitude: 0.7639513, longitude: 127.37656901666666, timestamp: "2024-06-06T17:58:45.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.837078", lon: "12722.594141", latD: "N", lonD: "E", latitude: 0.7639513, longitude: 127.37656901666666, timestamp: "2024-06-06T17:58:45.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "08", hdop: "0.7", lat: "0045.837100", lon: "12722.594190", latD: "N", lonD: "E", latitude: 0.7639516666666667, longitude: 127.37656983333333, timestamp: "2024-06-06T17:59:05.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "08", hdop: "0.7", lat: "0045.837100", lon: "12722.594190", latD: "N", lonD: "E", latitude: 0.7639516666666667, longitude: 127.37656983333333, timestamp: "2024-06-06T17:59:05.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "08", hdop: "0.7", lat: "0045.837100", lon: "12722.594190", latD: "N", lonD: "E", latitude: 0.7639516666666667, longitude: 127.37656983333333, timestamp: "2024-06-06T17:59:05.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "08", hdop: "0.6", lat: "0045.837147", lon: "12722.594257", latD: "N", lonD: "E", latitude: 0.76395245, longitude: 127.37657095, timestamp: "2024-06-06T17:59:25.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "08", hdop: "0.7", lat: "0045.837100", lon: "12722.594190", latD: "N", lonD: "E", latitude: 0.7639516666666667, longitude: 127.37656983333333, timestamp: "2024-06-06T17:59:05.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "08", hdop: "0.6", lat: "0045.837147", lon: "12722.594257", latD: "N", lonD: "E", latitude: 0.76395245, longitude: 127.37657095, timestamp: "2024-06-06T17:59:25.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "08", hdop: "0.6", lat: "0045.837147", lon: "12722.594257", latD: "N", lonD: "E", latitude: 0.76395245, longitude: 127.37657095, timestamp: "2024-06-06T17:59:25.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.5", satellites: "08", hdop: "0.6", lat: "0045.837147", lon: "12722.594257", latD: "N", lonD: "E", latitude: 0.76395245, longitude: 127.37657095, timestamp: "2024-06-06T17:59:25.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.4", satellites: "08", hdop: "0.6", lat: "0045.837184", lon: "12722.594305", latD: "N", lonD: "E", latitude: 0.7639530666666666, longitude: 127.37657175, timestamp: "2024-06-06T17:59:45.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.4", satellites: "08", hdop: "0.6", lat: "0045.837184", lon: "12722.594305", latD: "N", lonD: "E", latitude: 0.7639530666666666, longitude: 127.37657175, timestamp: "2024-06-06T17:59:45.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.4", satellites: "08", hdop: "0.6", lat: "0045.837184", lon: "12722.594305", latD: "N", lonD: "E", latitude: 0.7639530666666666, longitude: 127.37657175, timestamp: "2024-06-06T17:59:45.000Z" },
        { speed: "0.0", course: "209.9", altitude: "6.4", satellites: "08", hdop: "0.6", lat: "0045.837184", lon: "12722.594305", latD: "N", lonD: "E", latitude: 0.7639530666666666, longitude: 127.37657175, timestamp: "2024-06-06T17:59:45.000Z" },
        { speed: "0.0", course: "207.0", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.836977", lon: "12722.594365", latD: "N", lonD: "E", latitude: 0.7639496166666666, longitude: 127.37657275, timestamp: "2024-06-06T18:00:05.000Z" },
        { speed: "0.0", course: "207.0", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.836977", lon: "12722.594365", latD: "N", lonD: "E", latitude: 0.7639496166666666, longitude: 127.37657275, timestamp: "2024-06-06T18:00:05.000Z" },
        { speed: "0.0", course: "207.0", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.836977", lon: "12722.594365", latD: "N", lonD: "E", latitude: 0.7639496166666666, longitude: 127.37657275, timestamp: "2024-06-06T18:00:05.000Z" },
        { speed: "0.0", course: "207.0", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.836977", lon: "12722.594365", latD: "N", lonD: "E", latitude: 0.7639496166666666, longitude: 127.37657275, timestamp: "2024-06-06T18:00:05.000Z" },
        { speed: "0.0", course: "194.8", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.837344", lon: "12722.595043", latD: "N", lonD: "E", latitude: 0.7639557333333333, longitude: 127.37658405, timestamp: "2024-06-06T18:00:25.000Z" },
        { speed: "0.0", course: "194.8", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.837344", lon: "12722.595043", latD: "N", lonD: "E", latitude: 0.7639557333333333, longitude: 127.37658405, timestamp: "2024-06-06T18:00:25.000Z" },
        { speed: "0.0", course: "194.8", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.837344", lon: "12722.595043", latD: "N", lonD: "E", latitude: 0.7639557333333333, longitude: 127.37658405, timestamp: "2024-06-06T18:00:25.000Z" },
        { speed: "0.0", course: "194.8", altitude: "2.7", satellites: "09", hdop: "0.6", lat: "0045.837344", lon: "12722.595043", latD: "N", lonD: "E", latitude: 0.7639557333333333, longitude: 127.37658405, timestamp: "2024-06-06T18:00:25.000Z" },
        { speed: "0.0", course: "187.9", altitude: "5.2", satellites: "09", hdop: "0.6", lat: "0045.837509", lon: "12722.594429", latD: "N", lonD: "E", latitude: 0.7639584833333333, longitude: 127.37657381666666, timestamp: "2024-06-06T18:00:45.000Z" },
        { speed: "0.0", course: "187.9", altitude: "5.2", satellites: "09", hdop: "0.6", lat: "0045.837509", lon: "12722.594429", latD: "N", lonD: "E", latitude: 0.7639584833333333, longitude: 127.37657381666666, timestamp: "2024-06-06T18:00:45.000Z" },
        { speed: "0.0", course: "187.9", altitude: "5.2", satellites: "09", hdop: "0.6", lat: "0045.837509", lon: "12722.594429", latD: "N", lonD: "E", latitude: 0.7639584833333333, longitude: 127.37657381666666, timestamp: "2024-06-06T18:00:45.000Z" },
        { speed: "0.0", course: "187.9", altitude: "5.2", satellites: "09", hdop: "0.6", lat: "0045.837509", lon: "12722.594429", latD: "N", lonD: "E", latitude: 0.7639584833333333, longitude: 127.37657381666666, timestamp: "2024-06-06T18:00:45.000Z" },
        { speed: "0.0", course: "59.0", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.837634", lon: "12722.594468", latD: "N", lonD: "E", latitude: 0.7639605666666667, longitude: 127.37657446666667, timestamp: "2024-06-06T18:01:05.000Z" },
        { speed: "0.0", course: "59.0", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.837634", lon: "12722.594468", latD: "N", lonD: "E", latitude: 0.7639605666666667, longitude: 127.37657446666667, timestamp: "2024-06-06T18:01:05.000Z" },
        { speed: "0.0", course: "59.0", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.837634", lon: "12722.594468", latD: "N", lonD: "E", latitude: 0.7639605666666667, longitude: 127.37657446666667, timestamp: "2024-06-06T18:01:05.000Z" },
        { speed: "0.0", course: "59.0", altitude: "6.3", satellites: "09", hdop: "0.6", lat: "0045.837634", lon: "12722.594468", latD: "N", lonD: "E", latitude: 0.7639605666666667, longitude: 127.37657446666667, timestamp: "2024-06-06T18:01:05.000Z" },
        { speed: "0.3", course: "85.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.837177", lon: "12722.594059", latD: "N", lonD: "E", latitude: 0.76395295, longitude: 127.37656765, timestamp: "2024-06-06T18:01:25.000Z" },
        { speed: "0.3", course: "85.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.837177", lon: "12722.594059", latD: "N", lonD: "E", latitude: 0.76395295, longitude: 127.37656765, timestamp: "2024-06-06T18:01:25.000Z" },
        { speed: "0.3", course: "85.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.837177", lon: "12722.594059", latD: "N", lonD: "E", latitude: 0.76395295, longitude: 127.37656765, timestamp: "2024-06-06T18:01:25.000Z" },
        { speed: "0.3", course: "85.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.837177", lon: "12722.594059", latD: "N", lonD: "E", latitude: 0.76395295, longitude: 127.37656765, timestamp: "2024-06-06T18:01:25.000Z" },
        { speed: "0.0", course: "63.6", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.837329", lon: "12722.593852", latD: "N", lonD: "E", latitude: 0.7639554833333333, longitude: 127.3765642, timestamp: "2024-06-06T18:01:45.000Z" },
        { speed: "0.0", course: "63.6", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.837329", lon: "12722.593852", latD: "N", lonD: "E", latitude: 0.7639554833333333, longitude: 127.3765642, timestamp: "2024-06-06T18:01:45.000Z" },
        { speed: "0.0", course: "63.6", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.837329", lon: "12722.593852", latD: "N", lonD: "E", latitude: 0.7639554833333333, longitude: 127.3765642, timestamp: "2024-06-06T18:01:45.000Z" },
        { speed: "0.0", course: "63.6", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.837329", lon: "12722.593852", latD: "N", lonD: "E", latitude: 0.7639554833333333, longitude: 127.3765642, timestamp: "2024-06-06T18:01:45.000Z" },
        { speed: "0.0", course: "63.6", altitude: "8.9", satellites: "08", hdop: "0.6", lat: "0045.837368", lon: "12722.593895", latD: "N", lonD: "E", latitude: 0.7639561333333333, longitude: 127.37656491666667, timestamp: "2024-06-06T18:02:05.000Z" },
        { speed: "0.0", course: "63.6", altitude: "8.9", satellites: "08", hdop: "0.6", lat: "0045.837368", lon: "12722.593895", latD: "N", lonD: "E", latitude: 0.7639561333333333, longitude: 127.37656491666667, timestamp: "2024-06-06T18:02:05.000Z" },
        { speed: "0.0", course: "63.6", altitude: "8.9", satellites: "08", hdop: "0.6", lat: "0045.837368", lon: "12722.593895", latD: "N", lonD: "E", latitude: 0.7639561333333333, longitude: 127.37656491666667, timestamp: "2024-06-06T18:02:05.000Z" },
        { speed: "0.0", course: "84.3", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.837485", lon: "12722.592924", latD: "N", lonD: "E", latitude: 0.7639580833333334, longitude: 127.37654873333334, timestamp: "2024-06-06T18:02:25.000Z" },
        { speed: "0.0", course: "63.6", altitude: "8.9", satellites: "08", hdop: "0.6", lat: "0045.837368", lon: "12722.593895", latD: "N", lonD: "E", latitude: 0.7639561333333333, longitude: 127.37656491666667, timestamp: "2024-06-06T18:02:05.000Z" },
        { speed: "0.0", course: "84.3", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.837485", lon: "12722.592924", latD: "N", lonD: "E", latitude: 0.7639580833333334, longitude: 127.37654873333334, timestamp: "2024-06-06T18:02:25.000Z" },
        { speed: "0.0", course: "84.3", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.837485", lon: "12722.592924", latD: "N", lonD: "E", latitude: 0.7639580833333334, longitude: 127.37654873333334, timestamp: "2024-06-06T18:02:25.000Z" },
        { speed: "0.0", course: "84.3", altitude: "7.5", satellites: "08", hdop: "0.7", lat: "0045.837485", lon: "12722.592924", latD: "N", lonD: "E", latitude: 0.7639580833333334, longitude: 127.37654873333334, timestamp: "2024-06-06T18:02:25.000Z" },
        { speed: "0.0", course: "84.3", altitude: "6.4", satellites: "08", hdop: "0.6", lat: "0045.837650", lon: "12722.591370", latD: "N", lonD: "E", latitude: 0.7639608333333333, longitude: 127.37652283333334, timestamp: "2024-06-06T18:02:45.000Z" },
        { speed: "0.0", course: "84.3", altitude: "6.4", satellites: "08", hdop: "0.6", lat: "0045.837650", lon: "12722.591370", latD: "N", lonD: "E", latitude: 0.7639608333333333, longitude: 127.37652283333334, timestamp: "2024-06-06T18:02:45.000Z" },
        { speed: "0.0", course: "84.3", altitude: "6.4", satellites: "08", hdop: "0.6", lat: "0045.837650", lon: "12722.591370", latD: "N", lonD: "E", latitude: 0.7639608333333333, longitude: 127.37652283333334, timestamp: "2024-06-06T18:02:45.000Z" },
        { speed: "0.0", course: "84.3", altitude: "6.4", satellites: "08", hdop: "0.6", lat: "0045.837650", lon: "12722.591370", latD: "N", lonD: "E", latitude: 0.7639608333333333, longitude: 127.37652283333334, timestamp: "2024-06-06T18:02:45.000Z" },
        { speed: "0.0", course: "118.6", altitude: "6.8", satellites: "09", hdop: "0.6", lat: "0045.837638", lon: "12722.591480", latD: "N", lonD: "E", latitude: 0.7639606333333333, longitude: 127.37652466666667, timestamp: "2024-06-06T18:03:05.000Z" },
        { speed: "0.0", course: "118.6", altitude: "6.8", satellites: "09", hdop: "0.6", lat: "0045.837638", lon: "12722.591480", latD: "N", lonD: "E", latitude: 0.7639606333333333, longitude: 127.37652466666667, timestamp: "2024-06-06T18:03:05.000Z" },
        { speed: "0.0", course: "118.6", altitude: "6.8", satellites: "09", hdop: "0.6", lat: "0045.837638", lon: "12722.591480", latD: "N", lonD: "E", latitude: 0.7639606333333333, longitude: 127.37652466666667, timestamp: "2024-06-06T18:03:05.000Z" },
        { speed: "0.0", course: "118.6", altitude: "6.8", satellites: "09", hdop: "0.6", lat: "0045.837638", lon: "12722.591480", latD: "N", lonD: "E", latitude: 0.7639606333333333, longitude: 127.37652466666667, timestamp: "2024-06-06T18:03:05.000Z" },
        { speed: "0.0", course: "118.6", altitude: "7.0", satellites: "09", hdop: "0.6", lat: "0045.837291", lon: "12722.591030", latD: "N", lonD: "E", latitude: 0.76395485, longitude: 127.37651716666667, timestamp: "2024-06-06T18:03:25.000Z" },
        { speed: "0.0", course: "118.6", altitude: "7.0", satellites: "09", hdop: "0.6", lat: "0045.837291", lon: "12722.591030", latD: "N", lonD: "E", latitude: 0.76395485, longitude: 127.37651716666667, timestamp: "2024-06-06T18:03:25.000Z" },
        { speed: "0.0", course: "118.6", altitude: "7.0", satellites: "09", hdop: "0.6", lat: "0045.837291", lon: "12722.591030", latD: "N", lonD: "E", latitude: 0.76395485, longitude: 127.37651716666667, timestamp: "2024-06-06T18:03:25.000Z" },
        { speed: "0.0", course: "118.6", altitude: "7.0", satellites: "09", hdop: "0.6", lat: "0045.837291", lon: "12722.591030", latD: "N", lonD: "E", latitude: 0.76395485, longitude: 127.37651716666667, timestamp: "2024-06-06T18:03:25.000Z" },
        { speed: "0.0", course: "118.6", altitude: "7.2", satellites: "09", hdop: "0.6", lat: "0045.837335", lon: "12722.591061", latD: "N", lonD: "E", latitude: 0.7639555833333334, longitude: 127.37651768333333, timestamp: "2024-06-06T18:03:45.000Z" },
        { speed: "0.0", course: "118.6", altitude: "7.2", satellites: "09", hdop: "0.6", lat: "0045.837335", lon: "12722.591061", latD: "N", lonD: "E", latitude: 0.7639555833333334, longitude: 127.37651768333333, timestamp: "2024-06-06T18:03:45.000Z" },
        { speed: "0.0", course: "118.6", altitude: "7.2", satellites: "09", hdop: "0.6", lat: "0045.837335", lon: "12722.591061", latD: "N", lonD: "E", latitude: 0.7639555833333334, longitude: 127.37651768333333, timestamp: "2024-06-06T18:03:45.000Z" },
        { speed: "0.0", course: "78.9", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.837651", lon: "12722.591106", latD: "N", lonD: "E", latitude: 0.76396085, longitude: 127.37651843333333, timestamp: "2024-06-06T18:04:05.000Z" },
        { speed: "0.0", course: "118.6", altitude: "7.2", satellites: "09", hdop: "0.6", lat: "0045.837335", lon: "12722.591061", latD: "N", lonD: "E", latitude: 0.7639555833333334, longitude: 127.37651768333333, timestamp: "2024-06-06T18:03:45.000Z" },
        { speed: "0.0", course: "78.9", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.837651", lon: "12722.591106", latD: "N", lonD: "E", latitude: 0.76396085, longitude: 127.37651843333333, timestamp: "2024-06-06T18:04:05.000Z" },
        { speed: "0.0", course: "78.9", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.837651", lon: "12722.591106", latD: "N", lonD: "E", latitude: 0.76396085, longitude: 127.37651843333333, timestamp: "2024-06-06T18:04:05.000Z" },
        { speed: "0.0", course: "78.9", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.837651", lon: "12722.591106", latD: "N", lonD: "E", latitude: 0.76396085, longitude: 127.37651843333333, timestamp: "2024-06-06T18:04:05.000Z" },
        { speed: "0.0", course: "28.3", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.837991", lon: "12722.591103", latD: "N", lonD: "E", latitude: 0.7639665166666667, longitude: 127.37651838333333, timestamp: "2024-06-06T18:04:25.000Z" },
        { speed: "0.0", course: "28.3", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.837991", lon: "12722.591103", latD: "N", lonD: "E", latitude: 0.7639665166666667, longitude: 127.37651838333333, timestamp: "2024-06-06T18:04:25.000Z" },
        { speed: "0.0", course: "28.3", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.837991", lon: "12722.591103", latD: "N", lonD: "E", latitude: 0.7639665166666667, longitude: 127.37651838333333, timestamp: "2024-06-06T18:04:25.000Z" },
        { speed: "0.0", course: "28.3", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.837991", lon: "12722.591103", latD: "N", lonD: "E", latitude: 0.7639665166666667, longitude: 127.37651838333333, timestamp: "2024-06-06T18:04:25.000Z" },
        { speed: "0.0", course: "150.7", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.837782", lon: "12722.591601", latD: "N", lonD: "E", latitude: 0.7639630333333333, longitude: 127.37652668333334, timestamp: "2024-06-06T18:04:45.000Z" },
        { speed: "0.0", course: "150.7", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.837782", lon: "12722.591601", latD: "N", lonD: "E", latitude: 0.7639630333333333, longitude: 127.37652668333334, timestamp: "2024-06-06T18:04:45.000Z" },
        { speed: "0.0", course: "150.7", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.837782", lon: "12722.591601", latD: "N", lonD: "E", latitude: 0.7639630333333333, longitude: 127.37652668333334, timestamp: "2024-06-06T18:04:45.000Z" },
        { speed: "0.0", course: "150.7", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.837782", lon: "12722.591601", latD: "N", lonD: "E", latitude: 0.7639630333333333, longitude: 127.37652668333334, timestamp: "2024-06-06T18:04:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.7", satellites: "09", hdop: "0.6", lat: "0045.837998", lon: "12722.592975", latD: "N", lonD: "E", latitude: 0.7639666333333334, longitude: 127.37654958333333, timestamp: "2024-06-06T18:05:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.7", satellites: "09", hdop: "0.6", lat: "0045.837998", lon: "12722.592975", latD: "N", lonD: "E", latitude: 0.7639666333333334, longitude: 127.37654958333333, timestamp: "2024-06-06T18:05:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.7", satellites: "09", hdop: "0.6", lat: "0045.837998", lon: "12722.592975", latD: "N", lonD: "E", latitude: 0.7639666333333334, longitude: 127.37654958333333, timestamp: "2024-06-06T18:05:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.838056", lon: "12722.593877", latD: "N", lonD: "E", latitude: 0.7639676000000001, longitude: 127.37656461666667, timestamp: "2024-06-06T18:05:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.7", satellites: "09", hdop: "0.6", lat: "0045.837998", lon: "12722.592975", latD: "N", lonD: "E", latitude: 0.7639666333333334, longitude: 127.37654958333333, timestamp: "2024-06-06T18:05:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.838056", lon: "12722.593877", latD: "N", lonD: "E", latitude: 0.7639676000000001, longitude: 127.37656461666667, timestamp: "2024-06-06T18:05:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.838056", lon: "12722.593877", latD: "N", lonD: "E", latitude: 0.7639676000000001, longitude: 127.37656461666667, timestamp: "2024-06-06T18:05:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.838056", lon: "12722.593877", latD: "N", lonD: "E", latitude: 0.7639676000000001, longitude: 127.37656461666667, timestamp: "2024-06-06T18:05:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.838069", lon: "12722.593947", latD: "N", lonD: "E", latitude: 0.7639678166666666, longitude: 127.37656578333333, timestamp: "2024-06-06T18:05:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.838069", lon: "12722.593947", latD: "N", lonD: "E", latitude: 0.7639678166666666, longitude: 127.37656578333333, timestamp: "2024-06-06T18:05:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.838069", lon: "12722.593947", latD: "N", lonD: "E", latitude: 0.7639678166666666, longitude: 127.37656578333333, timestamp: "2024-06-06T18:05:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.838069", lon: "12722.593947", latD: "N", lonD: "E", latitude: 0.7639678166666666, longitude: 127.37656578333333, timestamp: "2024-06-06T18:05:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.7", lat: "0045.838099", lon: "12722.594005", latD: "N", lonD: "E", latitude: 0.7639683166666666, longitude: 127.37656675, timestamp: "2024-06-06T18:06:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.7", lat: "0045.838099", lon: "12722.594005", latD: "N", lonD: "E", latitude: 0.7639683166666666, longitude: 127.37656675, timestamp: "2024-06-06T18:06:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.7", lat: "0045.838099", lon: "12722.594005", latD: "N", lonD: "E", latitude: 0.7639683166666666, longitude: 127.37656675, timestamp: "2024-06-06T18:06:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.7", lat: "0045.838099", lon: "12722.594005", latD: "N", lonD: "E", latitude: 0.7639683166666666, longitude: 127.37656675, timestamp: "2024-06-06T18:06:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.838126", lon: "12722.594048", latD: "N", lonD: "E", latitude: 0.7639687666666667, longitude: 127.37656746666667, timestamp: "2024-06-06T18:06:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.838126", lon: "12722.594048", latD: "N", lonD: "E", latitude: 0.7639687666666667, longitude: 127.37656746666667, timestamp: "2024-06-06T18:06:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.838126", lon: "12722.594048", latD: "N", lonD: "E", latitude: 0.7639687666666667, longitude: 127.37656746666667, timestamp: "2024-06-06T18:06:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.838126", lon: "12722.594048", latD: "N", lonD: "E", latitude: 0.7639687666666667, longitude: 127.37656746666667, timestamp: "2024-06-06T18:06:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.7", lat: "0045.838142", lon: "12722.594128", latD: "N", lonD: "E", latitude: 0.7639690333333333, longitude: 127.3765688, timestamp: "2024-06-06T18:06:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.7", lat: "0045.838142", lon: "12722.594128", latD: "N", lonD: "E", latitude: 0.7639690333333333, longitude: 127.3765688, timestamp: "2024-06-06T18:06:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.7", lat: "0045.838142", lon: "12722.594128", latD: "N", lonD: "E", latitude: 0.7639690333333333, longitude: 127.3765688, timestamp: "2024-06-06T18:06:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.6", lat: "0045.838177", lon: "12722.594180", latD: "N", lonD: "E", latitude: 0.7639696166666667, longitude: 127.37656966666667, timestamp: "2024-06-06T18:07:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.7", lat: "0045.838142", lon: "12722.594128", latD: "N", lonD: "E", latitude: 0.7639690333333333, longitude: 127.3765688, timestamp: "2024-06-06T18:06:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.6", lat: "0045.838177", lon: "12722.594180", latD: "N", lonD: "E", latitude: 0.7639696166666667, longitude: 127.37656966666667, timestamp: "2024-06-06T18:07:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.6", lat: "0045.838177", lon: "12722.594180", latD: "N", lonD: "E", latitude: 0.7639696166666667, longitude: 127.37656966666667, timestamp: "2024-06-06T18:07:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.4", satellites: "08", hdop: "0.6", lat: "0045.838177", lon: "12722.594180", latD: "N", lonD: "E", latitude: 0.7639696166666667, longitude: 127.37656966666667, timestamp: "2024-06-06T18:07:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.838198", lon: "12722.594197", latD: "N", lonD: "E", latitude: 0.7639699666666666, longitude: 127.37656995, timestamp: "2024-06-06T18:07:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.838198", lon: "12722.594197", latD: "N", lonD: "E", latitude: 0.7639699666666666, longitude: 127.37656995, timestamp: "2024-06-06T18:07:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.838198", lon: "12722.594197", latD: "N", lonD: "E", latitude: 0.7639699666666666, longitude: 127.37656995, timestamp: "2024-06-06T18:07:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.838211", lon: "12722.594205", latD: "N", lonD: "E", latitude: 0.7639701833333333, longitude: 127.37657008333333, timestamp: "2024-06-06T18:07:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.838198", lon: "12722.594197", latD: "N", lonD: "E", latitude: 0.7639699666666666, longitude: 127.37656995, timestamp: "2024-06-06T18:07:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.838211", lon: "12722.594205", latD: "N", lonD: "E", latitude: 0.7639701833333333, longitude: 127.37657008333333, timestamp: "2024-06-06T18:07:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.838211", lon: "12722.594205", latD: "N", lonD: "E", latitude: 0.7639701833333333, longitude: 127.37657008333333, timestamp: "2024-06-06T18:07:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.838211", lon: "12722.594205", latD: "N", lonD: "E", latitude: 0.7639701833333333, longitude: 127.37657008333333, timestamp: "2024-06-06T18:07:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.838226", lon: "12722.594233", latD: "N", lonD: "E", latitude: 0.7639704333333334, longitude: 127.37657055, timestamp: "2024-06-06T18:08:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.838226", lon: "12722.594233", latD: "N", lonD: "E", latitude: 0.7639704333333334, longitude: 127.37657055, timestamp: "2024-06-06T18:08:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.838226", lon: "12722.594233", latD: "N", lonD: "E", latitude: 0.7639704333333334, longitude: 127.37657055, timestamp: "2024-06-06T18:08:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.838226", lon: "12722.594233", latD: "N", lonD: "E", latitude: 0.7639704333333334, longitude: 127.37657055, timestamp: "2024-06-06T18:08:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "08", hdop: "0.7", lat: "0045.838239", lon: "12722.594238", latD: "N", lonD: "E", latitude: 0.76397065, longitude: 127.37657063333333, timestamp: "2024-06-06T18:08:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "08", hdop: "0.7", lat: "0045.838239", lon: "12722.594238", latD: "N", lonD: "E", latitude: 0.76397065, longitude: 127.37657063333333, timestamp: "2024-06-06T18:08:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "08", hdop: "0.7", lat: "0045.838239", lon: "12722.594238", latD: "N", lonD: "E", latitude: 0.76397065, longitude: 127.37657063333333, timestamp: "2024-06-06T18:08:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838260", lon: "12722.594248", latD: "N", lonD: "E", latitude: 0.763971, longitude: 127.3765708, timestamp: "2024-06-06T18:08:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "08", hdop: "0.7", lat: "0045.838239", lon: "12722.594238", latD: "N", lonD: "E", latitude: 0.76397065, longitude: 127.37657063333333, timestamp: "2024-06-06T18:08:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838260", lon: "12722.594248", latD: "N", lonD: "E", latitude: 0.763971, longitude: 127.3765708, timestamp: "2024-06-06T18:08:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838260", lon: "12722.594248", latD: "N", lonD: "E", latitude: 0.763971, longitude: 127.3765708, timestamp: "2024-06-06T18:08:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838272", lon: "12722.594244", latD: "N", lonD: "E", latitude: 0.7639712000000001, longitude: 127.37657073333334, timestamp: "2024-06-06T18:09:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838260", lon: "12722.594248", latD: "N", lonD: "E", latitude: 0.763971, longitude: 127.3765708, timestamp: "2024-06-06T18:08:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838272", lon: "12722.594244", latD: "N", lonD: "E", latitude: 0.7639712000000001, longitude: 127.37657073333334, timestamp: "2024-06-06T18:09:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838272", lon: "12722.594244", latD: "N", lonD: "E", latitude: 0.7639712000000001, longitude: 127.37657073333334, timestamp: "2024-06-06T18:09:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838272", lon: "12722.594244", latD: "N", lonD: "E", latitude: 0.7639712000000001, longitude: 127.37657073333334, timestamp: "2024-06-06T18:09:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838290", lon: "12722.594231", latD: "N", lonD: "E", latitude: 0.7639715, longitude: 127.37657051666666, timestamp: "2024-06-06T18:09:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838290", lon: "12722.594231", latD: "N", lonD: "E", latitude: 0.7639715, longitude: 127.37657051666666, timestamp: "2024-06-06T18:09:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838290", lon: "12722.594231", latD: "N", lonD: "E", latitude: 0.7639715, longitude: 127.37657051666666, timestamp: "2024-06-06T18:09:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838290", lon: "12722.594231", latD: "N", lonD: "E", latitude: 0.7639715, longitude: 127.37657051666666, timestamp: "2024-06-06T18:09:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838298", lon: "12722.594219", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37657031666667, timestamp: "2024-06-06T18:09:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838298", lon: "12722.594219", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37657031666667, timestamp: "2024-06-06T18:09:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838298", lon: "12722.594219", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37657031666667, timestamp: "2024-06-06T18:09:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838298", lon: "12722.594219", latD: "N", lonD: "E", latitude: 0.7639716333333334, longitude: 127.37657031666667, timestamp: "2024-06-06T18:09:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838296", lon: "12722.594245", latD: "N", lonD: "E", latitude: 0.7639716, longitude: 127.37657075, timestamp: "2024-06-06T18:10:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838296", lon: "12722.594245", latD: "N", lonD: "E", latitude: 0.7639716, longitude: 127.37657075, timestamp: "2024-06-06T18:10:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838296", lon: "12722.594245", latD: "N", lonD: "E", latitude: 0.7639716, longitude: 127.37657075, timestamp: "2024-06-06T18:10:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.838303", lon: "12722.594263", latD: "N", lonD: "E", latitude: 0.7639717166666667, longitude: 127.37657105, timestamp: "2024-06-06T18:10:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.838296", lon: "12722.594245", latD: "N", lonD: "E", latitude: 0.7639716, longitude: 127.37657075, timestamp: "2024-06-06T18:10:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.838303", lon: "12722.594263", latD: "N", lonD: "E", latitude: 0.7639717166666667, longitude: 127.37657105, timestamp: "2024-06-06T18:10:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.838303", lon: "12722.594263", latD: "N", lonD: "E", latitude: 0.7639717166666667, longitude: 127.37657105, timestamp: "2024-06-06T18:10:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.838303", lon: "12722.594263", latD: "N", lonD: "E", latitude: 0.7639717166666667, longitude: 127.37657105, timestamp: "2024-06-06T18:10:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.838321", lon: "12722.594290", latD: "N", lonD: "E", latitude: 0.7639720166666667, longitude: 127.3765715, timestamp: "2024-06-06T18:10:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.838321", lon: "12722.594290", latD: "N", lonD: "E", latitude: 0.7639720166666667, longitude: 127.3765715, timestamp: "2024-06-06T18:10:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.838321", lon: "12722.594290", latD: "N", lonD: "E", latitude: 0.7639720166666667, longitude: 127.3765715, timestamp: "2024-06-06T18:10:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.838321", lon: "12722.594290", latD: "N", lonD: "E", latitude: 0.7639720166666667, longitude: 127.3765715, timestamp: "2024-06-06T18:10:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.838335", lon: "12722.594301", latD: "N", lonD: "E", latitude: 0.76397225, longitude: 127.37657168333334, timestamp: "2024-06-06T18:11:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.838335", lon: "12722.594301", latD: "N", lonD: "E", latitude: 0.76397225, longitude: 127.37657168333334, timestamp: "2024-06-06T18:11:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.838335", lon: "12722.594301", latD: "N", lonD: "E", latitude: 0.76397225, longitude: 127.37657168333334, timestamp: "2024-06-06T18:11:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.838335", lon: "12722.594301", latD: "N", lonD: "E", latitude: 0.76397225, longitude: 127.37657168333334, timestamp: "2024-06-06T18:11:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.838355", lon: "12722.594282", latD: "N", lonD: "E", latitude: 0.7639725833333333, longitude: 127.37657136666667, timestamp: "2024-06-06T18:11:26.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.838355", lon: "12722.594282", latD: "N", lonD: "E", latitude: 0.7639725833333333, longitude: 127.37657136666667, timestamp: "2024-06-06T18:11:26.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.838355", lon: "12722.594282", latD: "N", lonD: "E", latitude: 0.7639725833333333, longitude: 127.37657136666667, timestamp: "2024-06-06T18:11:26.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.838355", lon: "12722.594282", latD: "N", lonD: "E", latitude: 0.7639725833333333, longitude: 127.37657136666667, timestamp: "2024-06-06T18:11:26.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "08", hdop: "0.7", lat: "0045.838378", lon: "12722.594283", latD: "N", lonD: "E", latitude: 0.7639729666666667, longitude: 127.37657138333333, timestamp: "2024-06-06T18:11:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "08", hdop: "0.7", lat: "0045.838378", lon: "12722.594283", latD: "N", lonD: "E", latitude: 0.7639729666666667, longitude: 127.37657138333333, timestamp: "2024-06-06T18:11:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "08", hdop: "0.7", lat: "0045.838378", lon: "12722.594283", latD: "N", lonD: "E", latitude: 0.7639729666666667, longitude: 127.37657138333333, timestamp: "2024-06-06T18:11:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "08", hdop: "0.7", lat: "0045.838378", lon: "12722.594283", latD: "N", lonD: "E", latitude: 0.7639729666666667, longitude: 127.37657138333333, timestamp: "2024-06-06T18:11:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "08", hdop: "0.7", lat: "0045.838409", lon: "12722.594294", latD: "N", lonD: "E", latitude: 0.7639734833333333, longitude: 127.37657156666667, timestamp: "2024-06-06T18:12:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "08", hdop: "0.7", lat: "0045.838409", lon: "12722.594294", latD: "N", lonD: "E", latitude: 0.7639734833333333, longitude: 127.37657156666667, timestamp: "2024-06-06T18:12:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "08", hdop: "0.7", lat: "0045.838409", lon: "12722.594294", latD: "N", lonD: "E", latitude: 0.7639734833333333, longitude: 127.37657156666667, timestamp: "2024-06-06T18:12:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.9", satellites: "08", hdop: "0.7", lat: "0045.838409", lon: "12722.594294", latD: "N", lonD: "E", latitude: 0.7639734833333333, longitude: 127.37657156666667, timestamp: "2024-06-06T18:12:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "08", hdop: "0.7", lat: "0045.838428", lon: "12722.594308", latD: "N", lonD: "E", latitude: 0.7639738, longitude: 127.3765718, timestamp: "2024-06-06T18:12:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "08", hdop: "0.7", lat: "0045.838428", lon: "12722.594308", latD: "N", lonD: "E", latitude: 0.7639738, longitude: 127.3765718, timestamp: "2024-06-06T18:12:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "08", hdop: "0.7", lat: "0045.838428", lon: "12722.594308", latD: "N", lonD: "E", latitude: 0.7639738, longitude: 127.3765718, timestamp: "2024-06-06T18:12:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "08", hdop: "0.7", lat: "0045.838428", lon: "12722.594308", latD: "N", lonD: "E", latitude: 0.7639738, longitude: 127.3765718, timestamp: "2024-06-06T18:12:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "07", hdop: "0.8", lat: "0045.838435", lon: "12722.594322", latD: "N", lonD: "E", latitude: 0.7639739166666666, longitude: 127.37657203333333, timestamp: "2024-06-06T18:12:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "07", hdop: "0.8", lat: "0045.838435", lon: "12722.594322", latD: "N", lonD: "E", latitude: 0.7639739166666666, longitude: 127.37657203333333, timestamp: "2024-06-06T18:12:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "07", hdop: "0.8", lat: "0045.838435", lon: "12722.594322", latD: "N", lonD: "E", latitude: 0.7639739166666666, longitude: 127.37657203333333, timestamp: "2024-06-06T18:12:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "07", hdop: "0.8", lat: "0045.838435", lon: "12722.594322", latD: "N", lonD: "E", latitude: 0.7639739166666666, longitude: 127.37657203333333, timestamp: "2024-06-06T18:12:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "07", hdop: "0.7", lat: "0045.838446", lon: "12722.594324", latD: "N", lonD: "E", latitude: 0.7639741, longitude: 127.37657206666667, timestamp: "2024-06-06T18:13:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "07", hdop: "0.7", lat: "0045.838446", lon: "12722.594324", latD: "N", lonD: "E", latitude: 0.7639741, longitude: 127.37657206666667, timestamp: "2024-06-06T18:13:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "07", hdop: "0.7", lat: "0045.838446", lon: "12722.594324", latD: "N", lonD: "E", latitude: 0.7639741, longitude: 127.37657206666667, timestamp: "2024-06-06T18:13:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.7", satellites: "08", hdop: "0.7", lat: "0045.838459", lon: "12722.594355", latD: "N", lonD: "E", latitude: 0.7639743166666667, longitude: 127.37657258333333, timestamp: "2024-06-06T18:13:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.8", satellites: "07", hdop: "0.7", lat: "0045.838446", lon: "12722.594324", latD: "N", lonD: "E", latitude: 0.7639741, longitude: 127.37657206666667, timestamp: "2024-06-06T18:13:05.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.7", satellites: "08", hdop: "0.7", lat: "0045.838459", lon: "12722.594355", latD: "N", lonD: "E", latitude: 0.7639743166666667, longitude: 127.37657258333333, timestamp: "2024-06-06T18:13:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.7", satellites: "08", hdop: "0.7", lat: "0045.838459", lon: "12722.594355", latD: "N", lonD: "E", latitude: 0.7639743166666667, longitude: 127.37657258333333, timestamp: "2024-06-06T18:13:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.7", satellites: "08", hdop: "0.7", lat: "0045.838459", lon: "12722.594355", latD: "N", lonD: "E", latitude: 0.7639743166666667, longitude: 127.37657258333333, timestamp: "2024-06-06T18:13:25.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.7", satellites: "08", hdop: "0.7", lat: "0045.838476", lon: "12722.594371", latD: "N", lonD: "E", latitude: 0.7639746, longitude: 127.37657285, timestamp: "2024-06-06T18:13:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.7", satellites: "08", hdop: "0.7", lat: "0045.838476", lon: "12722.594371", latD: "N", lonD: "E", latitude: 0.7639746, longitude: 127.37657285, timestamp: "2024-06-06T18:13:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.7", satellites: "08", hdop: "0.7", lat: "0045.838476", lon: "12722.594371", latD: "N", lonD: "E", latitude: 0.7639746, longitude: 127.37657285, timestamp: "2024-06-06T18:13:45.000Z" },
        { speed: "0.0", course: "122.9", altitude: "8.7", satellites: "08", hdop: "0.7", lat: "0045.838476", lon: "12722.594371", latD: "N", lonD: "E", latitude: 0.7639746, longitude: 127.37657285, timestamp: "2024-06-06T18:13:45.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-4.9", satellites: "08", hdop: "0.6", lat: "0045.838253", lon: "12722.593061", latD: "N", lonD: "E", latitude: 0.7639708833333334, longitude: 127.37655101666667, timestamp: "2024-06-06T18:14:05.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-4.9", satellites: "08", hdop: "0.6", lat: "0045.838253", lon: "12722.593061", latD: "N", lonD: "E", latitude: 0.7639708833333334, longitude: 127.37655101666667, timestamp: "2024-06-06T18:14:05.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-4.9", satellites: "08", hdop: "0.6", lat: "0045.838253", lon: "12722.593061", latD: "N", lonD: "E", latitude: 0.7639708833333334, longitude: 127.37655101666667, timestamp: "2024-06-06T18:14:05.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-4.9", satellites: "08", hdop: "0.6", lat: "0045.838253", lon: "12722.593061", latD: "N", lonD: "E", latitude: 0.7639708833333334, longitude: 127.37655101666667, timestamp: "2024-06-06T18:14:05.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "09", hdop: "0.6", lat: "0045.838436", lon: "12722.592068", latD: "N", lonD: "E", latitude: 0.7639739333333334, longitude: 127.37653446666667, timestamp: "2024-06-06T18:14:25.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "09", hdop: "0.6", lat: "0045.838436", lon: "12722.592068", latD: "N", lonD: "E", latitude: 0.7639739333333334, longitude: 127.37653446666667, timestamp: "2024-06-06T18:14:25.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "09", hdop: "0.6", lat: "0045.838436", lon: "12722.592068", latD: "N", lonD: "E", latitude: 0.7639739333333334, longitude: 127.37653446666667, timestamp: "2024-06-06T18:14:25.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "09", hdop: "0.6", lat: "0045.838436", lon: "12722.592068", latD: "N", lonD: "E", latitude: 0.7639739333333334, longitude: 127.37653446666667, timestamp: "2024-06-06T18:14:25.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.6", satellites: "08", hdop: "0.7", lat: "0045.838434", lon: "12722.592095", latD: "N", lonD: "E", latitude: 0.7639739, longitude: 127.37653491666667, timestamp: "2024-06-06T18:14:45.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.6", satellites: "08", hdop: "0.7", lat: "0045.838434", lon: "12722.592095", latD: "N", lonD: "E", latitude: 0.7639739, longitude: 127.37653491666667, timestamp: "2024-06-06T18:14:45.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.6", satellites: "08", hdop: "0.7", lat: "0045.838434", lon: "12722.592095", latD: "N", lonD: "E", latitude: 0.7639739, longitude: 127.37653491666667, timestamp: "2024-06-06T18:14:45.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.6", satellites: "08", hdop: "0.7", lat: "0045.838434", lon: "12722.592095", latD: "N", lonD: "E", latitude: 0.7639739, longitude: 127.37653491666667, timestamp: "2024-06-06T18:14:45.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "08", hdop: "0.7", lat: "0045.838423", lon: "12722.592174", latD: "N", lonD: "E", latitude: 0.7639737166666667, longitude: 127.37653623333334, timestamp: "2024-06-06T18:15:05.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "08", hdop: "0.7", lat: "0045.838423", lon: "12722.592174", latD: "N", lonD: "E", latitude: 0.7639737166666667, longitude: 127.37653623333334, timestamp: "2024-06-06T18:15:05.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "08", hdop: "0.7", lat: "0045.838423", lon: "12722.592174", latD: "N", lonD: "E", latitude: 0.7639737166666667, longitude: 127.37653623333334, timestamp: "2024-06-06T18:15:05.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "08", hdop: "0.7", lat: "0045.838423", lon: "12722.592174", latD: "N", lonD: "E", latitude: 0.7639737166666667, longitude: 127.37653623333334, timestamp: "2024-06-06T18:15:05.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "08", hdop: "0.7", lat: "0045.838434", lon: "12722.592286", latD: "N", lonD: "E", latitude: 0.7639739, longitude: 127.3765381, timestamp: "2024-06-06T18:15:25.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "08", hdop: "0.7", lat: "0045.838434", lon: "12722.592286", latD: "N", lonD: "E", latitude: 0.7639739, longitude: 127.3765381, timestamp: "2024-06-06T18:15:25.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "08", hdop: "0.7", lat: "0045.838434", lon: "12722.592286", latD: "N", lonD: "E", latitude: 0.7639739, longitude: 127.3765381, timestamp: "2024-06-06T18:15:25.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.5", satellites: "08", hdop: "0.7", lat: "0045.838434", lon: "12722.592286", latD: "N", lonD: "E", latitude: 0.7639739, longitude: 127.3765381, timestamp: "2024-06-06T18:15:25.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.4", satellites: "09", hdop: "0.6", lat: "0045.838448", lon: "12722.592361", latD: "N", lonD: "E", latitude: 0.7639741333333333, longitude: 127.37653935, timestamp: "2024-06-06T18:15:45.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.4", satellites: "09", hdop: "0.6", lat: "0045.838448", lon: "12722.592361", latD: "N", lonD: "E", latitude: 0.7639741333333333, longitude: 127.37653935, timestamp: "2024-06-06T18:15:45.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.4", satellites: "09", hdop: "0.6", lat: "0045.838448", lon: "12722.592361", latD: "N", lonD: "E", latitude: 0.7639741333333333, longitude: 127.37653935, timestamp: "2024-06-06T18:15:45.000Z" },
        { speed: "0.0", course: "122.8", altitude: "-9.4", satellites: "09", hdop: "0.6", lat: "0045.838448", lon: "12722.592361", latD: "N", lonD: "E", latitude: 0.7639741333333333, longitude: 127.37653935, timestamp: "2024-06-06T18:15:45.000Z" },
        { speed: "0.0", course: "208.0", altitude: "-2.9", satellites: "08", hdop: "0.7", lat: "0045.837558", lon: "12722.593878", latD: "N", lonD: "E", latitude: 0.7639593, longitude: 127.37656463333333, timestamp: "2024-06-06T18:16:05.000Z" },
        { speed: "0.0", course: "208.0", altitude: "-2.9", satellites: "08", hdop: "0.7", lat: "0045.837558", lon: "12722.593878", latD: "N", lonD: "E", latitude: 0.7639593, longitude: 127.37656463333333, timestamp: "2024-06-06T18:16:05.000Z" },
        { speed: "0.0", course: "208.0", altitude: "-2.9", satellites: "08", hdop: "0.7", lat: "0045.837558", lon: "12722.593878", latD: "N", lonD: "E", latitude: 0.7639593, longitude: 127.37656463333333, timestamp: "2024-06-06T18:16:05.000Z" },
        { speed: "0.0", course: "208.0", altitude: "-2.9", satellites: "08", hdop: "0.7", lat: "0045.837558", lon: "12722.593878", latD: "N", lonD: "E", latitude: 0.7639593, longitude: 127.37656463333333, timestamp: "2024-06-06T18:16:05.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.1", satellites: "08", hdop: "0.6", lat: "0045.838195", lon: "12722.596445", latD: "N", lonD: "E", latitude: 0.7639699166666667, longitude: 127.37660741666667, timestamp: "2024-06-06T18:16:25.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.1", satellites: "08", hdop: "0.6", lat: "0045.838195", lon: "12722.596445", latD: "N", lonD: "E", latitude: 0.7639699166666667, longitude: 127.37660741666667, timestamp: "2024-06-06T18:16:25.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.1", satellites: "08", hdop: "0.6", lat: "0045.838195", lon: "12722.596445", latD: "N", lonD: "E", latitude: 0.7639699166666667, longitude: 127.37660741666667, timestamp: "2024-06-06T18:16:25.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.2", satellites: "08", hdop: "0.6", lat: "0045.838167", lon: "12722.596464", latD: "N", lonD: "E", latitude: 0.7639694499999999, longitude: 127.37660773333333, timestamp: "2024-06-06T18:16:45.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.1", satellites: "08", hdop: "0.6", lat: "0045.838195", lon: "12722.596445", latD: "N", lonD: "E", latitude: 0.7639699166666667, longitude: 127.37660741666667, timestamp: "2024-06-06T18:16:25.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.2", satellites: "08", hdop: "0.6", lat: "0045.838167", lon: "12722.596464", latD: "N", lonD: "E", latitude: 0.7639694499999999, longitude: 127.37660773333333, timestamp: "2024-06-06T18:16:45.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.2", satellites: "08", hdop: "0.6", lat: "0045.838167", lon: "12722.596464", latD: "N", lonD: "E", latitude: 0.7639694499999999, longitude: 127.37660773333333, timestamp: "2024-06-06T18:16:45.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.2", satellites: "08", hdop: "0.6", lat: "0045.838167", lon: "12722.596464", latD: "N", lonD: "E", latitude: 0.7639694499999999, longitude: 127.37660773333333, timestamp: "2024-06-06T18:16:45.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.4", satellites: "08", hdop: "0.6", lat: "0045.838191", lon: "12722.596512", latD: "N", lonD: "E", latitude: 0.7639698500000001, longitude: 127.37660853333334, timestamp: "2024-06-06T18:17:05.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.4", satellites: "08", hdop: "0.6", lat: "0045.838191", lon: "12722.596512", latD: "N", lonD: "E", latitude: 0.7639698500000001, longitude: 127.37660853333334, timestamp: "2024-06-06T18:17:05.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.4", satellites: "08", hdop: "0.6", lat: "0045.838191", lon: "12722.596512", latD: "N", lonD: "E", latitude: 0.7639698500000001, longitude: 127.37660853333334, timestamp: "2024-06-06T18:17:05.000Z" },
        { speed: "0.0", course: "158.5", altitude: "5.4", satellites: "08", hdop: "0.6", lat: "0045.838191", lon: "12722.596512", latD: "N", lonD: "E", latitude: 0.7639698500000001, longitude: 127.37660853333334, timestamp: "2024-06-06T18:17:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.2", satellites: "08", hdop: "0.6", lat: "0045.841341", lon: "12722.600572", latD: "N", lonD: "E", latitude: 0.76402235, longitude: 127.3766762, timestamp: "2024-06-06T18:17:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.2", satellites: "08", hdop: "0.6", lat: "0045.841341", lon: "12722.600572", latD: "N", lonD: "E", latitude: 0.76402235, longitude: 127.3766762, timestamp: "2024-06-06T18:17:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.2", satellites: "08", hdop: "0.6", lat: "0045.841341", lon: "12722.600572", latD: "N", lonD: "E", latitude: 0.76402235, longitude: 127.3766762, timestamp: "2024-06-06T18:17:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.2", satellites: "08", hdop: "0.6", lat: "0045.841341", lon: "12722.600572", latD: "N", lonD: "E", latitude: 0.76402235, longitude: 127.3766762, timestamp: "2024-06-06T18:17:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "08", hdop: "0.6", lat: "0045.841373", lon: "12722.600711", latD: "N", lonD: "E", latitude: 0.7640228833333332, longitude: 127.37667851666667, timestamp: "2024-06-06T18:17:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "08", hdop: "0.6", lat: "0045.841373", lon: "12722.600711", latD: "N", lonD: "E", latitude: 0.7640228833333332, longitude: 127.37667851666667, timestamp: "2024-06-06T18:17:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841387", lon: "12722.600784", latD: "N", lonD: "E", latitude: 0.7640231166666667, longitude: 127.37667973333333, timestamp: "2024-06-06T18:18:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "08", hdop: "0.6", lat: "0045.841373", lon: "12722.600711", latD: "N", lonD: "E", latitude: 0.7640228833333332, longitude: 127.37667851666667, timestamp: "2024-06-06T18:17:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "08", hdop: "0.6", lat: "0045.841373", lon: "12722.600711", latD: "N", lonD: "E", latitude: 0.7640228833333332, longitude: 127.37667851666667, timestamp: "2024-06-06T18:17:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841387", lon: "12722.600784", latD: "N", lonD: "E", latitude: 0.7640231166666667, longitude: 127.37667973333333, timestamp: "2024-06-06T18:18:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841387", lon: "12722.600784", latD: "N", lonD: "E", latitude: 0.7640231166666667, longitude: 127.37667973333333, timestamp: "2024-06-06T18:18:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841387", lon: "12722.600784", latD: "N", lonD: "E", latitude: 0.7640231166666667, longitude: 127.37667973333333, timestamp: "2024-06-06T18:18:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841383", lon: "12722.600807", latD: "N", lonD: "E", latitude: 0.76402305, longitude: 127.37668011666666, timestamp: "2024-06-06T18:18:26.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841383", lon: "12722.600807", latD: "N", lonD: "E", latitude: 0.76402305, longitude: 127.37668011666666, timestamp: "2024-06-06T18:18:26.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841383", lon: "12722.600807", latD: "N", lonD: "E", latitude: 0.76402305, longitude: 127.37668011666666, timestamp: "2024-06-06T18:18:26.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841383", lon: "12722.600807", latD: "N", lonD: "E", latitude: 0.76402305, longitude: 127.37668011666666, timestamp: "2024-06-06T18:18:26.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.5", satellites: "08", hdop: "0.6", lat: "0045.841448", lon: "12722.600944", latD: "N", lonD: "E", latitude: 0.7640241333333333, longitude: 127.37668239999999, timestamp: "2024-06-06T18:18:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.5", satellites: "08", hdop: "0.6", lat: "0045.841448", lon: "12722.600944", latD: "N", lonD: "E", latitude: 0.7640241333333333, longitude: 127.37668239999999, timestamp: "2024-06-06T18:18:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.5", satellites: "08", hdop: "0.6", lat: "0045.841448", lon: "12722.600944", latD: "N", lonD: "E", latitude: 0.7640241333333333, longitude: 127.37668239999999, timestamp: "2024-06-06T18:18:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.5", satellites: "08", hdop: "0.6", lat: "0045.841448", lon: "12722.600944", latD: "N", lonD: "E", latitude: 0.7640241333333333, longitude: 127.37668239999999, timestamp: "2024-06-06T18:18:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.4", satellites: "08", hdop: "0.7", lat: "0045.841452", lon: "12722.601017", latD: "N", lonD: "E", latitude: 0.7640241999999999, longitude: 127.37668361666667, timestamp: "2024-06-06T18:19:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.4", satellites: "08", hdop: "0.7", lat: "0045.841452", lon: "12722.601017", latD: "N", lonD: "E", latitude: 0.7640241999999999, longitude: 127.37668361666667, timestamp: "2024-06-06T18:19:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.4", satellites: "08", hdop: "0.7", lat: "0045.841452", lon: "12722.601017", latD: "N", lonD: "E", latitude: 0.7640241999999999, longitude: 127.37668361666667, timestamp: "2024-06-06T18:19:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.4", satellites: "08", hdop: "0.7", lat: "0045.841452", lon: "12722.601017", latD: "N", lonD: "E", latitude: 0.7640241999999999, longitude: 127.37668361666667, timestamp: "2024-06-06T18:19:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.4", satellites: "07", hdop: "0.7", lat: "0045.841456", lon: "12722.601043", latD: "N", lonD: "E", latitude: 0.7640242666666667, longitude: 127.37668405, timestamp: "2024-06-06T18:19:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.4", satellites: "07", hdop: "0.7", lat: "0045.841456", lon: "12722.601043", latD: "N", lonD: "E", latitude: 0.7640242666666667, longitude: 127.37668405, timestamp: "2024-06-06T18:19:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.4", satellites: "07", hdop: "0.7", lat: "0045.841456", lon: "12722.601043", latD: "N", lonD: "E", latitude: 0.7640242666666667, longitude: 127.37668405, timestamp: "2024-06-06T18:19:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841465", lon: "12722.601064", latD: "N", lonD: "E", latitude: 0.7640244166666667, longitude: 127.3766844, timestamp: "2024-06-06T18:19:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.4", satellites: "07", hdop: "0.7", lat: "0045.841456", lon: "12722.601043", latD: "N", lonD: "E", latitude: 0.7640242666666667, longitude: 127.37668405, timestamp: "2024-06-06T18:19:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841465", lon: "12722.601064", latD: "N", lonD: "E", latitude: 0.7640244166666667, longitude: 127.3766844, timestamp: "2024-06-06T18:19:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841465", lon: "12722.601064", latD: "N", lonD: "E", latitude: 0.7640244166666667, longitude: 127.3766844, timestamp: "2024-06-06T18:19:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "07", hdop: "0.7", lat: "0045.841465", lon: "12722.601064", latD: "N", lonD: "E", latitude: 0.7640244166666667, longitude: 127.3766844, timestamp: "2024-06-06T18:19:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.1", satellites: "08", hdop: "0.7", lat: "0045.841443", lon: "12722.601020", latD: "N", lonD: "E", latitude: 0.76402405, longitude: 127.37668366666666, timestamp: "2024-06-06T18:20:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.1", satellites: "08", hdop: "0.7", lat: "0045.841443", lon: "12722.601020", latD: "N", lonD: "E", latitude: 0.76402405, longitude: 127.37668366666666, timestamp: "2024-06-06T18:20:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.1", satellites: "08", hdop: "0.7", lat: "0045.841443", lon: "12722.601020", latD: "N", lonD: "E", latitude: 0.76402405, longitude: 127.37668366666666, timestamp: "2024-06-06T18:20:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.1", satellites: "08", hdop: "0.7", lat: "0045.841443", lon: "12722.601020", latD: "N", lonD: "E", latitude: 0.76402405, longitude: 127.37668366666666, timestamp: "2024-06-06T18:20:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.0", satellites: "07", hdop: "0.7", lat: "0045.841415", lon: "12722.600979", latD: "N", lonD: "E", latitude: 0.7640235833333333, longitude: 127.37668298333334, timestamp: "2024-06-06T18:20:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.0", satellites: "07", hdop: "0.7", lat: "0045.841415", lon: "12722.600979", latD: "N", lonD: "E", latitude: 0.7640235833333333, longitude: 127.37668298333334, timestamp: "2024-06-06T18:20:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.0", satellites: "07", hdop: "0.7", lat: "0045.841415", lon: "12722.600979", latD: "N", lonD: "E", latitude: 0.7640235833333333, longitude: 127.37668298333334, timestamp: "2024-06-06T18:20:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.0", satellites: "07", hdop: "0.7", lat: "0045.841415", lon: "12722.600979", latD: "N", lonD: "E", latitude: 0.7640235833333333, longitude: 127.37668298333334, timestamp: "2024-06-06T18:20:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.8", satellites: "07", hdop: "0.8", lat: "0045.841342", lon: "12722.600924", latD: "N", lonD: "E", latitude: 0.7640223666666667, longitude: 127.37668206666666, timestamp: "2024-06-06T18:20:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.8", satellites: "07", hdop: "0.8", lat: "0045.841342", lon: "12722.600924", latD: "N", lonD: "E", latitude: 0.7640223666666667, longitude: 127.37668206666666, timestamp: "2024-06-06T18:20:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.7", satellites: "09", hdop: "0.6", lat: "0045.841309", lon: "12722.600927", latD: "N", lonD: "E", latitude: 0.7640218166666667, longitude: 127.37668211666667, timestamp: "2024-06-06T18:21:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.8", satellites: "07", hdop: "0.8", lat: "0045.841342", lon: "12722.600924", latD: "N", lonD: "E", latitude: 0.7640223666666667, longitude: 127.37668206666666, timestamp: "2024-06-06T18:20:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.8", satellites: "07", hdop: "0.8", lat: "0045.841342", lon: "12722.600924", latD: "N", lonD: "E", latitude: 0.7640223666666667, longitude: 127.37668206666666, timestamp: "2024-06-06T18:20:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.7", satellites: "09", hdop: "0.6", lat: "0045.841309", lon: "12722.600927", latD: "N", lonD: "E", latitude: 0.7640218166666667, longitude: 127.37668211666667, timestamp: "2024-06-06T18:21:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.7", satellites: "09", hdop: "0.6", lat: "0045.841309", lon: "12722.600927", latD: "N", lonD: "E", latitude: 0.7640218166666667, longitude: 127.37668211666667, timestamp: "2024-06-06T18:21:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.7", satellites: "09", hdop: "0.6", lat: "0045.841309", lon: "12722.600927", latD: "N", lonD: "E", latitude: 0.7640218166666667, longitude: 127.37668211666667, timestamp: "2024-06-06T18:21:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.841303", lon: "12722.600961", latD: "N", lonD: "E", latitude: 0.7640217166666667, longitude: 127.37668268333333, timestamp: "2024-06-06T18:21:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.841303", lon: "12722.600961", latD: "N", lonD: "E", latitude: 0.7640217166666667, longitude: 127.37668268333333, timestamp: "2024-06-06T18:21:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.841303", lon: "12722.600961", latD: "N", lonD: "E", latitude: 0.7640217166666667, longitude: 127.37668268333333, timestamp: "2024-06-06T18:21:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "14.9", satellites: "09", hdop: "0.6", lat: "0045.841303", lon: "12722.600961", latD: "N", lonD: "E", latitude: 0.7640217166666667, longitude: 127.37668268333333, timestamp: "2024-06-06T18:21:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.0", satellites: "09", hdop: "0.6", lat: "0045.841287", lon: "12722.601015", latD: "N", lonD: "E", latitude: 0.76402145, longitude: 127.37668358333333, timestamp: "2024-06-06T18:21:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.0", satellites: "09", hdop: "0.6", lat: "0045.841287", lon: "12722.601015", latD: "N", lonD: "E", latitude: 0.76402145, longitude: 127.37668358333333, timestamp: "2024-06-06T18:21:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.0", satellites: "09", hdop: "0.6", lat: "0045.841287", lon: "12722.601015", latD: "N", lonD: "E", latitude: 0.76402145, longitude: 127.37668358333333, timestamp: "2024-06-06T18:21:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.0", satellites: "09", hdop: "0.6", lat: "0045.841287", lon: "12722.601015", latD: "N", lonD: "E", latitude: 0.76402145, longitude: 127.37668358333333, timestamp: "2024-06-06T18:21:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.2", satellites: "09", hdop: "0.6", lat: "0045.841287", lon: "12722.601046", latD: "N", lonD: "E", latitude: 0.76402145, longitude: 127.3766841, timestamp: "2024-06-06T18:22:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.2", satellites: "09", hdop: "0.6", lat: "0045.841287", lon: "12722.601046", latD: "N", lonD: "E", latitude: 0.76402145, longitude: 127.3766841, timestamp: "2024-06-06T18:22:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.2", satellites: "09", hdop: "0.6", lat: "0045.841287", lon: "12722.601046", latD: "N", lonD: "E", latitude: 0.76402145, longitude: 127.3766841, timestamp: "2024-06-06T18:22:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.2", satellites: "09", hdop: "0.6", lat: "0045.841287", lon: "12722.601046", latD: "N", lonD: "E", latitude: 0.76402145, longitude: 127.3766841, timestamp: "2024-06-06T18:22:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.841251", lon: "12722.601014", latD: "N", lonD: "E", latitude: 0.76402085, longitude: 127.37668356666667, timestamp: "2024-06-06T18:22:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.841251", lon: "12722.601014", latD: "N", lonD: "E", latitude: 0.76402085, longitude: 127.37668356666667, timestamp: "2024-06-06T18:22:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.841251", lon: "12722.601014", latD: "N", lonD: "E", latitude: 0.76402085, longitude: 127.37668356666667, timestamp: "2024-06-06T18:22:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.1", satellites: "09", hdop: "0.6", lat: "0045.841251", lon: "12722.601014", latD: "N", lonD: "E", latitude: 0.76402085, longitude: 127.37668356666667, timestamp: "2024-06-06T18:22:25.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "08", hdop: "0.6", lat: "0045.841255", lon: "12722.601029", latD: "N", lonD: "E", latitude: 0.7640209166666666, longitude: 127.37668381666667, timestamp: "2024-06-06T18:22:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "08", hdop: "0.6", lat: "0045.841255", lon: "12722.601029", latD: "N", lonD: "E", latitude: 0.7640209166666666, longitude: 127.37668381666667, timestamp: "2024-06-06T18:22:45.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "08", hdop: "0.6", lat: "0045.841255", lon: "12722.601029", latD: "N", lonD: "E", latitude: 0.7640209166666666, longitude: 127.37668381666667, timestamp: "2024-06-06T18:22:45.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841143", lon: "12722.602407", latD: "N", lonD: "E", latitude: 0.76401905, longitude: 127.37670678333333, timestamp: "2024-06-06T18:23:05.000Z" },
        { speed: "0.0", course: "178.6", altitude: "15.3", satellites: "08", hdop: "0.6", lat: "0045.841255", lon: "12722.601029", latD: "N", lonD: "E", latitude: 0.7640209166666666, longitude: 127.37668381666667, timestamp: "2024-06-06T18:22:45.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841143", lon: "12722.602407", latD: "N", lonD: "E", latitude: 0.76401905, longitude: 127.37670678333333, timestamp: "2024-06-06T18:23:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841143", lon: "12722.602407", latD: "N", lonD: "E", latitude: 0.76401905, longitude: 127.37670678333333, timestamp: "2024-06-06T18:23:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841143", lon: "12722.602407", latD: "N", lonD: "E", latitude: 0.76401905, longitude: 127.37670678333333, timestamp: "2024-06-06T18:23:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.2", satellites: "09", hdop: "0.6", lat: "0045.841179", lon: "12722.602604", latD: "N", lonD: "E", latitude: 0.7640196499999999, longitude: 127.37671006666666, timestamp: "2024-06-06T18:23:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.2", satellites: "09", hdop: "0.6", lat: "0045.841179", lon: "12722.602604", latD: "N", lonD: "E", latitude: 0.7640196499999999, longitude: 127.37671006666666, timestamp: "2024-06-06T18:23:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.2", satellites: "09", hdop: "0.6", lat: "0045.841179", lon: "12722.602604", latD: "N", lonD: "E", latitude: 0.7640196499999999, longitude: 127.37671006666666, timestamp: "2024-06-06T18:23:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.2", satellites: "09", hdop: "0.6", lat: "0045.841179", lon: "12722.602604", latD: "N", lonD: "E", latitude: 0.7640196499999999, longitude: 127.37671006666666, timestamp: "2024-06-06T18:23:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841154", lon: "12722.602608", latD: "N", lonD: "E", latitude: 0.7640192333333334, longitude: 127.37671013333333, timestamp: "2024-06-06T18:23:46.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841154", lon: "12722.602608", latD: "N", lonD: "E", latitude: 0.7640192333333334, longitude: 127.37671013333333, timestamp: "2024-06-06T18:23:46.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841154", lon: "12722.602608", latD: "N", lonD: "E", latitude: 0.7640192333333334, longitude: 127.37671013333333, timestamp: "2024-06-06T18:23:46.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841154", lon: "12722.602608", latD: "N", lonD: "E", latitude: 0.7640192333333334, longitude: 127.37671013333333, timestamp: "2024-06-06T18:23:46.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841137", lon: "12722.602642", latD: "N", lonD: "E", latitude: 0.7640189500000001, longitude: 127.3767107, timestamp: "2024-06-06T18:24:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841137", lon: "12722.602642", latD: "N", lonD: "E", latitude: 0.7640189500000001, longitude: 127.3767107, timestamp: "2024-06-06T18:24:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841137", lon: "12722.602642", latD: "N", lonD: "E", latitude: 0.7640189500000001, longitude: 127.3767107, timestamp: "2024-06-06T18:24:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841104", lon: "12722.602654", latD: "N", lonD: "E", latitude: 0.7640184, longitude: 127.3767109, timestamp: "2024-06-06T18:24:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841137", lon: "12722.602642", latD: "N", lonD: "E", latitude: 0.7640189500000001, longitude: 127.3767107, timestamp: "2024-06-06T18:24:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841104", lon: "12722.602654", latD: "N", lonD: "E", latitude: 0.7640184, longitude: 127.3767109, timestamp: "2024-06-06T18:24:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841104", lon: "12722.602654", latD: "N", lonD: "E", latitude: 0.7640184, longitude: 127.3767109, timestamp: "2024-06-06T18:24:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841104", lon: "12722.602654", latD: "N", lonD: "E", latitude: 0.7640184, longitude: 127.3767109, timestamp: "2024-06-06T18:24:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841102", lon: "12722.602638", latD: "N", lonD: "E", latitude: 0.7640183666666667, longitude: 127.37671063333333, timestamp: "2024-06-06T18:24:45.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841102", lon: "12722.602638", latD: "N", lonD: "E", latitude: 0.7640183666666667, longitude: 127.37671063333333, timestamp: "2024-06-06T18:24:45.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841102", lon: "12722.602638", latD: "N", lonD: "E", latitude: 0.7640183666666667, longitude: 127.37671063333333, timestamp: "2024-06-06T18:24:45.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.1", satellites: "09", hdop: "0.6", lat: "0045.841102", lon: "12722.602638", latD: "N", lonD: "E", latitude: 0.7640183666666667, longitude: 127.37671063333333, timestamp: "2024-06-06T18:24:45.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.0", satellites: "09", hdop: "0.6", lat: "0045.841128", lon: "12722.602632", latD: "N", lonD: "E", latitude: 0.7640188, longitude: 127.37671053333334, timestamp: "2024-06-06T18:25:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.0", satellites: "09", hdop: "0.6", lat: "0045.841128", lon: "12722.602632", latD: "N", lonD: "E", latitude: 0.7640188, longitude: 127.37671053333334, timestamp: "2024-06-06T18:25:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.0", satellites: "09", hdop: "0.6", lat: "0045.841128", lon: "12722.602632", latD: "N", lonD: "E", latitude: 0.7640188, longitude: 127.37671053333334, timestamp: "2024-06-06T18:25:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "17.0", satellites: "09", hdop: "0.6", lat: "0045.841128", lon: "12722.602632", latD: "N", lonD: "E", latitude: 0.7640188, longitude: 127.37671053333334, timestamp: "2024-06-06T18:25:05.000Z" },
        { speed: "0.0", course: "185.2", altitude: "16.9", satellites: "09", hdop: "0.6", lat: "0045.841112", lon: "12722.602618", latD: "N", lonD: "E", latitude: 0.7640185333333334, longitude: 127.3767103, timestamp: "2024-06-06T18:25:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "16.9", satellites: "09", hdop: "0.6", lat: "0045.841112", lon: "12722.602618", latD: "N", lonD: "E", latitude: 0.7640185333333334, longitude: 127.3767103, timestamp: "2024-06-06T18:25:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "16.9", satellites: "09", hdop: "0.6", lat: "0045.841112", lon: "12722.602618", latD: "N", lonD: "E", latitude: 0.7640185333333334, longitude: 127.3767103, timestamp: "2024-06-06T18:25:25.000Z" },
        { speed: "0.0", course: "185.2", altitude: "16.9", satellites: "09", hdop: "0.6", lat: "0045.841112", lon: "12722.602618", latD: "N", lonD: "E", latitude: 0.7640185333333334, longitude: 127.3767103, timestamp: "2024-06-06T18:25:25.000Z" },
        { speed: "0.0", course: "207.0", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.840254", lon: "12722.600750", latD: "N", lonD: "E", latitude: 0.7640042333333333, longitude: 127.37667916666666, timestamp: "2024-06-06T18:25:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.840254", lon: "12722.600750", latD: "N", lonD: "E", latitude: 0.7640042333333333, longitude: 127.37667916666666, timestamp: "2024-06-06T18:25:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.840254", lon: "12722.600750", latD: "N", lonD: "E", latitude: 0.7640042333333333, longitude: 127.37667916666666, timestamp: "2024-06-06T18:25:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "14.4", satellites: "09", hdop: "0.6", lat: "0045.840254", lon: "12722.600750", latD: "N", lonD: "E", latitude: 0.7640042333333333, longitude: 127.37667916666666, timestamp: "2024-06-06T18:25:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.6", satellites: "08", hdop: "0.6", lat: "0045.840131", lon: "12722.600258", latD: "N", lonD: "E", latitude: 0.7640021833333334, longitude: 127.37667096666667, timestamp: "2024-06-06T18:26:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.6", satellites: "08", hdop: "0.6", lat: "0045.840131", lon: "12722.600258", latD: "N", lonD: "E", latitude: 0.7640021833333334, longitude: 127.37667096666667, timestamp: "2024-06-06T18:26:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.6", satellites: "08", hdop: "0.6", lat: "0045.840131", lon: "12722.600258", latD: "N", lonD: "E", latitude: 0.7640021833333334, longitude: 127.37667096666667, timestamp: "2024-06-06T18:26:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.6", satellites: "08", hdop: "0.6", lat: "0045.840131", lon: "12722.600258", latD: "N", lonD: "E", latitude: 0.7640021833333334, longitude: 127.37667096666667, timestamp: "2024-06-06T18:26:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.6", satellites: "08", hdop: "0.6", lat: "0045.840131", lon: "12722.600259", latD: "N", lonD: "E", latitude: 0.7640021833333334, longitude: 127.37667098333333, timestamp: "2024-06-06T18:26:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.6", satellites: "08", hdop: "0.6", lat: "0045.840131", lon: "12722.600259", latD: "N", lonD: "E", latitude: 0.7640021833333334, longitude: 127.37667098333333, timestamp: "2024-06-06T18:26:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.6", satellites: "08", hdop: "0.6", lat: "0045.840131", lon: "12722.600259", latD: "N", lonD: "E", latitude: 0.7640021833333334, longitude: 127.37667098333333, timestamp: "2024-06-06T18:26:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.6", satellites: "08", hdop: "0.6", lat: "0045.840131", lon: "12722.600259", latD: "N", lonD: "E", latitude: 0.7640021833333334, longitude: 127.37667098333333, timestamp: "2024-06-06T18:26:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.5", satellites: "08", hdop: "0.6", lat: "0045.840116", lon: "12722.600264", latD: "N", lonD: "E", latitude: 0.7640019333333333, longitude: 127.37667106666666, timestamp: "2024-06-06T18:26:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.5", satellites: "08", hdop: "0.6", lat: "0045.840116", lon: "12722.600264", latD: "N", lonD: "E", latitude: 0.7640019333333333, longitude: 127.37667106666666, timestamp: "2024-06-06T18:26:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.5", satellites: "08", hdop: "0.6", lat: "0045.840116", lon: "12722.600264", latD: "N", lonD: "E", latitude: 0.7640019333333333, longitude: 127.37667106666666, timestamp: "2024-06-06T18:26:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.5", satellites: "08", hdop: "0.6", lat: "0045.840116", lon: "12722.600264", latD: "N", lonD: "E", latitude: 0.7640019333333333, longitude: 127.37667106666666, timestamp: "2024-06-06T18:26:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.4", satellites: "08", hdop: "0.6", lat: "0045.840093", lon: "12722.600261", latD: "N", lonD: "E", latitude: 0.76400155, longitude: 127.37667101666666, timestamp: "2024-06-06T18:27:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.4", satellites: "08", hdop: "0.6", lat: "0045.840093", lon: "12722.600261", latD: "N", lonD: "E", latitude: 0.76400155, longitude: 127.37667101666666, timestamp: "2024-06-06T18:27:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.4", satellites: "08", hdop: "0.6", lat: "0045.840093", lon: "12722.600261", latD: "N", lonD: "E", latitude: 0.76400155, longitude: 127.37667101666666, timestamp: "2024-06-06T18:27:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.4", satellites: "08", hdop: "0.6", lat: "0045.840093", lon: "12722.600261", latD: "N", lonD: "E", latitude: 0.76400155, longitude: 127.37667101666666, timestamp: "2024-06-06T18:27:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.4", satellites: "08", hdop: "0.6", lat: "0045.840068", lon: "12722.600249", latD: "N", lonD: "E", latitude: 0.7640011333333334, longitude: 127.37667081666666, timestamp: "2024-06-06T18:27:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.4", satellites: "08", hdop: "0.6", lat: "0045.840068", lon: "12722.600249", latD: "N", lonD: "E", latitude: 0.7640011333333334, longitude: 127.37667081666666, timestamp: "2024-06-06T18:27:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.4", satellites: "08", hdop: "0.6", lat: "0045.840068", lon: "12722.600249", latD: "N", lonD: "E", latitude: 0.7640011333333334, longitude: 127.37667081666666, timestamp: "2024-06-06T18:27:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.4", satellites: "08", hdop: "0.6", lat: "0045.840068", lon: "12722.600249", latD: "N", lonD: "E", latitude: 0.7640011333333334, longitude: 127.37667081666666, timestamp: "2024-06-06T18:27:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.3", satellites: "08", hdop: "0.6", lat: "0045.840047", lon: "12722.600241", latD: "N", lonD: "E", latitude: 0.7640007833333333, longitude: 127.37667068333333, timestamp: "2024-06-06T18:27:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.3", satellites: "08", hdop: "0.6", lat: "0045.840047", lon: "12722.600241", latD: "N", lonD: "E", latitude: 0.7640007833333333, longitude: 127.37667068333333, timestamp: "2024-06-06T18:27:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.3", satellites: "08", hdop: "0.6", lat: "0045.840047", lon: "12722.600241", latD: "N", lonD: "E", latitude: 0.7640007833333333, longitude: 127.37667068333333, timestamp: "2024-06-06T18:27:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.3", satellites: "08", hdop: "0.6", lat: "0045.840047", lon: "12722.600241", latD: "N", lonD: "E", latitude: 0.7640007833333333, longitude: 127.37667068333333, timestamp: "2024-06-06T18:27:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.840029", lon: "12722.600225", latD: "N", lonD: "E", latitude: 0.7640004833333334, longitude: 127.37667041666667, timestamp: "2024-06-06T18:28:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.840029", lon: "12722.600225", latD: "N", lonD: "E", latitude: 0.7640004833333334, longitude: 127.37667041666667, timestamp: "2024-06-06T18:28:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.840029", lon: "12722.600225", latD: "N", lonD: "E", latitude: 0.7640004833333334, longitude: 127.37667041666667, timestamp: "2024-06-06T18:28:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.840029", lon: "12722.600225", latD: "N", lonD: "E", latitude: 0.7640004833333334, longitude: 127.37667041666667, timestamp: "2024-06-06T18:28:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.840007", lon: "12722.600218", latD: "N", lonD: "E", latitude: 0.7640001166666667, longitude: 127.3766703, timestamp: "2024-06-06T18:28:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.840007", lon: "12722.600218", latD: "N", lonD: "E", latitude: 0.7640001166666667, longitude: 127.3766703, timestamp: "2024-06-06T18:28:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.840007", lon: "12722.600218", latD: "N", lonD: "E", latitude: 0.7640001166666667, longitude: 127.3766703, timestamp: "2024-06-06T18:28:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.2", satellites: "08", hdop: "0.6", lat: "0045.840007", lon: "12722.600218", latD: "N", lonD: "E", latitude: 0.7640001166666667, longitude: 127.3766703, timestamp: "2024-06-06T18:28:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.1", satellites: "09", hdop: "0.6", lat: "0045.839977", lon: "12722.600219", latD: "N", lonD: "E", latitude: 0.7639996166666666, longitude: 127.37667031666666, timestamp: "2024-06-06T18:28:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.1", satellites: "09", hdop: "0.6", lat: "0045.839977", lon: "12722.600219", latD: "N", lonD: "E", latitude: 0.7639996166666666, longitude: 127.37667031666666, timestamp: "2024-06-06T18:28:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.1", satellites: "09", hdop: "0.6", lat: "0045.839977", lon: "12722.600219", latD: "N", lonD: "E", latitude: 0.7639996166666666, longitude: 127.37667031666666, timestamp: "2024-06-06T18:28:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.1", satellites: "09", hdop: "0.6", lat: "0045.839977", lon: "12722.600219", latD: "N", lonD: "E", latitude: 0.7639996166666666, longitude: 127.37667031666666, timestamp: "2024-06-06T18:28:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.0", satellites: "08", hdop: "0.6", lat: "0045.839973", lon: "12722.600237", latD: "N", lonD: "E", latitude: 0.76399955, longitude: 127.37667061666667, timestamp: "2024-06-06T18:29:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.0", satellites: "08", hdop: "0.6", lat: "0045.839973", lon: "12722.600237", latD: "N", lonD: "E", latitude: 0.76399955, longitude: 127.37667061666667, timestamp: "2024-06-06T18:29:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.0", satellites: "08", hdop: "0.6", lat: "0045.839973", lon: "12722.600237", latD: "N", lonD: "E", latitude: 0.76399955, longitude: 127.37667061666667, timestamp: "2024-06-06T18:29:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "13.0", satellites: "08", hdop: "0.6", lat: "0045.839973", lon: "12722.600237", latD: "N", lonD: "E", latitude: 0.76399955, longitude: 127.37667061666667, timestamp: "2024-06-06T18:29:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.9", satellites: "09", hdop: "0.6", lat: "0045.839963", lon: "12722.600248", latD: "N", lonD: "E", latitude: 0.7639993833333333, longitude: 127.3766708, timestamp: "2024-06-06T18:29:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.9", satellites: "09", hdop: "0.6", lat: "0045.839963", lon: "12722.600248", latD: "N", lonD: "E", latitude: 0.7639993833333333, longitude: 127.3766708, timestamp: "2024-06-06T18:29:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.9", satellites: "09", hdop: "0.6", lat: "0045.839963", lon: "12722.600248", latD: "N", lonD: "E", latitude: 0.7639993833333333, longitude: 127.3766708, timestamp: "2024-06-06T18:29:26.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.8", satellites: "08", hdop: "0.6", lat: "0045.839924", lon: "12722.600223", latD: "N", lonD: "E", latitude: 0.7639987333333333, longitude: 127.37667038333333, timestamp: "2024-06-06T18:29:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.8", satellites: "08", hdop: "0.6", lat: "0045.839924", lon: "12722.600223", latD: "N", lonD: "E", latitude: 0.7639987333333333, longitude: 127.37667038333333, timestamp: "2024-06-06T18:29:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.8", satellites: "08", hdop: "0.6", lat: "0045.839924", lon: "12722.600223", latD: "N", lonD: "E", latitude: 0.7639987333333333, longitude: 127.37667038333333, timestamp: "2024-06-06T18:29:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.8", satellites: "08", hdop: "0.6", lat: "0045.839924", lon: "12722.600223", latD: "N", lonD: "E", latitude: 0.7639987333333333, longitude: 127.37667038333333, timestamp: "2024-06-06T18:29:46.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839886", lon: "12722.600188", latD: "N", lonD: "E", latitude: 0.7639981, longitude: 127.3766698, timestamp: "2024-06-06T18:30:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839886", lon: "12722.600188", latD: "N", lonD: "E", latitude: 0.7639981, longitude: 127.3766698, timestamp: "2024-06-06T18:30:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839886", lon: "12722.600188", latD: "N", lonD: "E", latitude: 0.7639981, longitude: 127.3766698, timestamp: "2024-06-06T18:30:06.000Z" },
        { speed: "0.0", course: "207.0", altitude: "12.6", satellites: "09", hdop: "0.6", lat: "0045.839886", lon: "12722.600188", latD: "N", lonD: "E", latitude: 0.7639981, longitude: 127.3766698, timestamp: "2024-06-06T18:30:06.000Z" },
        { speed: "0.0", course: "187.4", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.838379", lon: "12722.599333", latD: "N", lonD: "E", latitude: 0.7639729833333334, longitude: 127.37665555, timestamp: "2024-06-06T18:30:26.000Z" },
        { speed: "0.0", course: "187.4", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.838379", lon: "12722.599333", latD: "N", lonD: "E", latitude: 0.7639729833333334, longitude: 127.37665555, timestamp: "2024-06-06T18:30:26.000Z" },
        { speed: "0.0", course: "187.4", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.838379", lon: "12722.599333", latD: "N", lonD: "E", latitude: 0.7639729833333334, longitude: 127.37665555, timestamp: "2024-06-06T18:30:26.000Z" },
        { speed: "0.0", course: "187.4", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.838379", lon: "12722.599333", latD: "N", lonD: "E", latitude: 0.7639729833333334, longitude: 127.37665555, timestamp: "2024-06-06T18:30:26.000Z" },
        { speed: "0.0", course: "146.7", altitude: "4.4", satellites: "09", hdop: "0.6", lat: "0045.837393", lon: "12722.598757", latD: "N", lonD: "E", latitude: 0.76395655, longitude: 127.37664595, timestamp: "2024-06-06T18:30:46.000Z" },
        { speed: "0.0", course: "146.7", altitude: "4.4", satellites: "09", hdop: "0.6", lat: "0045.837393", lon: "12722.598757", latD: "N", lonD: "E", latitude: 0.76395655, longitude: 127.37664595, timestamp: "2024-06-06T18:30:46.000Z" },
        { speed: "0.0", course: "146.7", altitude: "4.4", satellites: "09", hdop: "0.6", lat: "0045.837393", lon: "12722.598757", latD: "N", lonD: "E", latitude: 0.76395655, longitude: 127.37664595, timestamp: "2024-06-06T18:30:46.000Z" },
        { speed: "0.0", course: "146.7", altitude: "4.4", satellites: "09", hdop: "0.6", lat: "0045.837393", lon: "12722.598757", latD: "N", lonD: "E", latitude: 0.76395655, longitude: 127.37664595, timestamp: "2024-06-06T18:30:46.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837460", lon: "12722.598363", latD: "N", lonD: "E", latitude: 0.7639576666666666, longitude: 127.37663938333333, timestamp: "2024-06-06T18:31:06.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837460", lon: "12722.598363", latD: "N", lonD: "E", latitude: 0.7639576666666666, longitude: 127.37663938333333, timestamp: "2024-06-06T18:31:06.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837460", lon: "12722.598363", latD: "N", lonD: "E", latitude: 0.7639576666666666, longitude: 127.37663938333333, timestamp: "2024-06-06T18:31:06.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837460", lon: "12722.598363", latD: "N", lonD: "E", latitude: 0.7639576666666666, longitude: 127.37663938333333, timestamp: "2024-06-06T18:31:06.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837492", lon: "12722.598385", latD: "N", lonD: "E", latitude: 0.7639581999999999, longitude: 127.37663975, timestamp: "2024-06-06T18:31:26.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837492", lon: "12722.598385", latD: "N", lonD: "E", latitude: 0.7639581999999999, longitude: 127.37663975, timestamp: "2024-06-06T18:31:26.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837492", lon: "12722.598385", latD: "N", lonD: "E", latitude: 0.7639581999999999, longitude: 127.37663975, timestamp: "2024-06-06T18:31:26.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.837492", lon: "12722.598385", latD: "N", lonD: "E", latitude: 0.7639581999999999, longitude: 127.37663975, timestamp: "2024-06-06T18:31:26.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837518", lon: "12722.598380", latD: "N", lonD: "E", latitude: 0.7639586333333334, longitude: 127.37663966666666, timestamp: "2024-06-06T18:31:46.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837518", lon: "12722.598380", latD: "N", lonD: "E", latitude: 0.7639586333333334, longitude: 127.37663966666666, timestamp: "2024-06-06T18:31:46.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837518", lon: "12722.598380", latD: "N", lonD: "E", latitude: 0.7639586333333334, longitude: 127.37663966666666, timestamp: "2024-06-06T18:31:46.000Z" },
        { speed: "0.0", course: "194.9", altitude: "4.0", satellites: "09", hdop: "0.6", lat: "0045.837758", lon: "12722.599007", latD: "N", lonD: "E", latitude: 0.7639626333333334, longitude: 127.37665011666667, timestamp: "2024-06-06T18:32:06.000Z" },
        { speed: "0.0", course: "194.9", altitude: "4.0", satellites: "09", hdop: "0.6", lat: "0045.837758", lon: "12722.599007", latD: "N", lonD: "E", latitude: 0.7639626333333334, longitude: 127.37665011666667, timestamp: "2024-06-06T18:32:06.000Z" },
        { speed: "0.0", course: "144.4", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837518", lon: "12722.598380", latD: "N", lonD: "E", latitude: 0.7639586333333334, longitude: 127.37663966666666, timestamp: "2024-06-06T18:31:46.000Z" },
        { speed: "0.0", course: "194.9", altitude: "4.0", satellites: "09", hdop: "0.6", lat: "0045.837758", lon: "12722.599007", latD: "N", lonD: "E", latitude: 0.7639626333333334, longitude: 127.37665011666667, timestamp: "2024-06-06T18:32:06.000Z" },
        { speed: "0.0", course: "194.9", altitude: "4.0", satellites: "09", hdop: "0.6", lat: "0045.837758", lon: "12722.599007", latD: "N", lonD: "E", latitude: 0.7639626333333334, longitude: 127.37665011666667, timestamp: "2024-06-06T18:32:06.000Z" },
        { speed: "0.0", course: "314.0", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.838618", lon: "12722.599441", latD: "N", lonD: "E", latitude: 0.7639769666666666, longitude: 127.37665735, timestamp: "2024-06-06T18:32:26.000Z" },
        { speed: "0.0", course: "314.0", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.838618", lon: "12722.599441", latD: "N", lonD: "E", latitude: 0.7639769666666666, longitude: 127.37665735, timestamp: "2024-06-06T18:32:26.000Z" },
        { speed: "0.0", course: "314.0", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.838618", lon: "12722.599441", latD: "N", lonD: "E", latitude: 0.7639769666666666, longitude: 127.37665735, timestamp: "2024-06-06T18:32:26.000Z" },
        { speed: "0.0", course: "314.0", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.838618", lon: "12722.599441", latD: "N", lonD: "E", latitude: 0.7639769666666666, longitude: 127.37665735, timestamp: "2024-06-06T18:32:26.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838633", lon: "12722.600603", latD: "N", lonD: "E", latitude: 0.7639772166666667, longitude: 127.37667671666667, timestamp: "2024-06-06T18:32:46.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838633", lon: "12722.600603", latD: "N", lonD: "E", latitude: 0.7639772166666667, longitude: 127.37667671666667, timestamp: "2024-06-06T18:32:46.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838633", lon: "12722.600603", latD: "N", lonD: "E", latitude: 0.7639772166666667, longitude: 127.37667671666667, timestamp: "2024-06-06T18:32:46.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838633", lon: "12722.600603", latD: "N", lonD: "E", latitude: 0.7639772166666667, longitude: 127.37667671666667, timestamp: "2024-06-06T18:32:46.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838558", lon: "12722.600695", latD: "N", lonD: "E", latitude: 0.7639759666666667, longitude: 127.37667825, timestamp: "2024-06-06T18:33:06.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838558", lon: "12722.600695", latD: "N", lonD: "E", latitude: 0.7639759666666667, longitude: 127.37667825, timestamp: "2024-06-06T18:33:06.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838558", lon: "12722.600695", latD: "N", lonD: "E", latitude: 0.7639759666666667, longitude: 127.37667825, timestamp: "2024-06-06T18:33:06.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838558", lon: "12722.600695", latD: "N", lonD: "E", latitude: 0.7639759666666667, longitude: 127.37667825, timestamp: "2024-06-06T18:33:06.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838522", lon: "12722.600735", latD: "N", lonD: "E", latitude: 0.7639753666666667, longitude: 127.37667891666666, timestamp: "2024-06-06T18:33:26.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838522", lon: "12722.600735", latD: "N", lonD: "E", latitude: 0.7639753666666667, longitude: 127.37667891666666, timestamp: "2024-06-06T18:33:26.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838522", lon: "12722.600735", latD: "N", lonD: "E", latitude: 0.7639753666666667, longitude: 127.37667891666666, timestamp: "2024-06-06T18:33:26.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838522", lon: "12722.600735", latD: "N", lonD: "E", latitude: 0.7639753666666667, longitude: 127.37667891666666, timestamp: "2024-06-06T18:33:26.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838496", lon: "12722.600756", latD: "N", lonD: "E", latitude: 0.7639749333333333, longitude: 127.37667926666667, timestamp: "2024-06-06T18:33:46.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838496", lon: "12722.600756", latD: "N", lonD: "E", latitude: 0.7639749333333333, longitude: 127.37667926666667, timestamp: "2024-06-06T18:33:46.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838496", lon: "12722.600756", latD: "N", lonD: "E", latitude: 0.7639749333333333, longitude: 127.37667926666667, timestamp: "2024-06-06T18:33:46.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838467", lon: "12722.600782", latD: "N", lonD: "E", latitude: 0.76397445, longitude: 127.3766797, timestamp: "2024-06-06T18:34:06.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838496", lon: "12722.600756", latD: "N", lonD: "E", latitude: 0.7639749333333333, longitude: 127.37667926666667, timestamp: "2024-06-06T18:33:46.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838467", lon: "12722.600782", latD: "N", lonD: "E", latitude: 0.76397445, longitude: 127.3766797, timestamp: "2024-06-06T18:34:06.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838467", lon: "12722.600782", latD: "N", lonD: "E", latitude: 0.76397445, longitude: 127.3766797, timestamp: "2024-06-06T18:34:06.000Z" },
        { speed: "0.0", course: "95.0", altitude: "4.1", satellites: "09", hdop: "0.6", lat: "0045.838467", lon: "12722.600782", latD: "N", lonD: "E", latitude: 0.76397445, longitude: 127.3766797, timestamp: "2024-06-06T18:34:06.000Z" },
        { speed: "0.0", course: "34.1", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837462", lon: "12722.600589", latD: "N", lonD: "E", latitude: 0.7639577000000001, longitude: 127.37667648333333, timestamp: "2024-06-06T18:34:26.000Z" },
        { speed: "0.0", course: "34.1", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837462", lon: "12722.600589", latD: "N", lonD: "E", latitude: 0.7639577000000001, longitude: 127.37667648333333, timestamp: "2024-06-06T18:34:26.000Z" },
        { speed: "0.0", course: "34.1", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837462", lon: "12722.600589", latD: "N", lonD: "E", latitude: 0.7639577000000001, longitude: 127.37667648333333, timestamp: "2024-06-06T18:34:26.000Z" },
        { speed: "0.0", course: "34.1", altitude: "3.3", satellites: "09", hdop: "0.6", lat: "0045.837462", lon: "12722.600589", latD: "N", lonD: "E", latitude: 0.7639577000000001, longitude: 127.37667648333333, timestamp: "2024-06-06T18:34:26.000Z" },
        { speed: "0.0", course: "35.6", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.836993", lon: "12722.601134", latD: "N", lonD: "E", latitude: 0.7639498833333334, longitude: 127.37668556666667, timestamp: "2024-06-06T18:34:46.000Z" },
        { speed: "0.0", course: "35.6", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.836993", lon: "12722.601134", latD: "N", lonD: "E", latitude: 0.7639498833333334, longitude: 127.37668556666667, timestamp: "2024-06-06T18:34:46.000Z" },
        { speed: "0.0", course: "35.6", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.836993", lon: "12722.601134", latD: "N", lonD: "E", latitude: 0.7639498833333334, longitude: 127.37668556666667, timestamp: "2024-06-06T18:34:46.000Z" },
        { speed: "0.0", course: "35.6", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.836993", lon: "12722.601134", latD: "N", lonD: "E", latitude: 0.7639498833333334, longitude: 127.37668556666667, timestamp: "2024-06-06T18:34:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836894", lon: "12722.602088", latD: "N", lonD: "E", latitude: 0.7639482333333334, longitude: 127.37670146666666, timestamp: "2024-06-06T18:35:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836894", lon: "12722.602088", latD: "N", lonD: "E", latitude: 0.7639482333333334, longitude: 127.37670146666666, timestamp: "2024-06-06T18:35:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836894", lon: "12722.602088", latD: "N", lonD: "E", latitude: 0.7639482333333334, longitude: 127.37670146666666, timestamp: "2024-06-06T18:35:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836878", lon: "12722.602114", latD: "N", lonD: "E", latitude: 0.7639479666666666, longitude: 127.3767019, timestamp: "2024-06-06T18:35:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836894", lon: "12722.602088", latD: "N", lonD: "E", latitude: 0.7639482333333334, longitude: 127.37670146666666, timestamp: "2024-06-06T18:35:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836878", lon: "12722.602114", latD: "N", lonD: "E", latitude: 0.7639479666666666, longitude: 127.3767019, timestamp: "2024-06-06T18:35:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836878", lon: "12722.602114", latD: "N", lonD: "E", latitude: 0.7639479666666666, longitude: 127.3767019, timestamp: "2024-06-06T18:35:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836878", lon: "12722.602114", latD: "N", lonD: "E", latitude: 0.7639479666666666, longitude: 127.3767019, timestamp: "2024-06-06T18:35:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836881", lon: "12722.602126", latD: "N", lonD: "E", latitude: 0.7639480166666667, longitude: 127.3767021, timestamp: "2024-06-06T18:35:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836881", lon: "12722.602126", latD: "N", lonD: "E", latitude: 0.7639480166666667, longitude: 127.3767021, timestamp: "2024-06-06T18:35:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836881", lon: "12722.602126", latD: "N", lonD: "E", latitude: 0.7639480166666667, longitude: 127.3767021, timestamp: "2024-06-06T18:35:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "08", hdop: "0.6", lat: "0045.836878", lon: "12722.602147", latD: "N", lonD: "E", latitude: 0.7639479666666666, longitude: 127.37670245, timestamp: "2024-06-06T18:36:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "08", hdop: "0.6", lat: "0045.836878", lon: "12722.602147", latD: "N", lonD: "E", latitude: 0.7639479666666666, longitude: 127.37670245, timestamp: "2024-06-06T18:36:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836881", lon: "12722.602126", latD: "N", lonD: "E", latitude: 0.7639480166666667, longitude: 127.3767021, timestamp: "2024-06-06T18:35:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "08", hdop: "0.6", lat: "0045.836878", lon: "12722.602147", latD: "N", lonD: "E", latitude: 0.7639479666666666, longitude: 127.37670245, timestamp: "2024-06-06T18:36:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "08", hdop: "0.6", lat: "0045.836878", lon: "12722.602147", latD: "N", lonD: "E", latitude: 0.7639479666666666, longitude: 127.37670245, timestamp: "2024-06-06T18:36:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.3", satellites: "09", hdop: "0.6", lat: "0045.836852", lon: "12722.602170", latD: "N", lonD: "E", latitude: 0.7639475333333333, longitude: 127.37670283333334, timestamp: "2024-06-06T18:36:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.3", satellites: "09", hdop: "0.6", lat: "0045.836852", lon: "12722.602170", latD: "N", lonD: "E", latitude: 0.7639475333333333, longitude: 127.37670283333334, timestamp: "2024-06-06T18:36:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.3", satellites: "09", hdop: "0.6", lat: "0045.836852", lon: "12722.602170", latD: "N", lonD: "E", latitude: 0.7639475333333333, longitude: 127.37670283333334, timestamp: "2024-06-06T18:36:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.3", satellites: "09", hdop: "0.6", lat: "0045.836852", lon: "12722.602170", latD: "N", lonD: "E", latitude: 0.7639475333333333, longitude: 127.37670283333334, timestamp: "2024-06-06T18:36:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.3", satellites: "09", hdop: "0.6", lat: "0045.836831", lon: "12722.602197", latD: "N", lonD: "E", latitude: 0.7639471833333332, longitude: 127.37670328333333, timestamp: "2024-06-06T18:36:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.3", satellites: "09", hdop: "0.6", lat: "0045.836831", lon: "12722.602197", latD: "N", lonD: "E", latitude: 0.7639471833333332, longitude: 127.37670328333333, timestamp: "2024-06-06T18:36:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.3", satellites: "09", hdop: "0.6", lat: "0045.836831", lon: "12722.602197", latD: "N", lonD: "E", latitude: 0.7639471833333332, longitude: 127.37670328333333, timestamp: "2024-06-06T18:36:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836821", lon: "12722.602207", latD: "N", lonD: "E", latitude: 0.7639470166666666, longitude: 127.37670345, timestamp: "2024-06-06T18:37:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.3", satellites: "09", hdop: "0.6", lat: "0045.836831", lon: "12722.602197", latD: "N", lonD: "E", latitude: 0.7639471833333332, longitude: 127.37670328333333, timestamp: "2024-06-06T18:36:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836821", lon: "12722.602207", latD: "N", lonD: "E", latitude: 0.7639470166666666, longitude: 127.37670345, timestamp: "2024-06-06T18:37:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836821", lon: "12722.602207", latD: "N", lonD: "E", latitude: 0.7639470166666666, longitude: 127.37670345, timestamp: "2024-06-06T18:37:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836821", lon: "12722.602207", latD: "N", lonD: "E", latitude: 0.7639470166666666, longitude: 127.37670345, timestamp: "2024-06-06T18:37:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.7", lat: "0045.836835", lon: "12722.602192", latD: "N", lonD: "E", latitude: 0.76394725, longitude: 127.3767032, timestamp: "2024-06-06T18:37:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.7", lat: "0045.836835", lon: "12722.602192", latD: "N", lonD: "E", latitude: 0.76394725, longitude: 127.3767032, timestamp: "2024-06-06T18:37:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.7", lat: "0045.836835", lon: "12722.602192", latD: "N", lonD: "E", latitude: 0.76394725, longitude: 127.3767032, timestamp: "2024-06-06T18:37:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.7", lat: "0045.836835", lon: "12722.602192", latD: "N", lonD: "E", latitude: 0.76394725, longitude: 127.3767032, timestamp: "2024-06-06T18:37:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836860", lon: "12722.602187", latD: "N", lonD: "E", latitude: 0.7639476666666667, longitude: 127.37670311666666, timestamp: "2024-06-06T18:37:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836860", lon: "12722.602187", latD: "N", lonD: "E", latitude: 0.7639476666666667, longitude: 127.37670311666666, timestamp: "2024-06-06T18:37:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836860", lon: "12722.602187", latD: "N", lonD: "E", latitude: 0.7639476666666667, longitude: 127.37670311666666, timestamp: "2024-06-06T18:37:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836860", lon: "12722.602187", latD: "N", lonD: "E", latitude: 0.7639476666666667, longitude: 127.37670311666666, timestamp: "2024-06-06T18:37:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836882", lon: "12722.602207", latD: "N", lonD: "E", latitude: 0.7639480333333334, longitude: 127.37670345, timestamp: "2024-06-06T18:38:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836882", lon: "12722.602207", latD: "N", lonD: "E", latitude: 0.7639480333333334, longitude: 127.37670345, timestamp: "2024-06-06T18:38:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836882", lon: "12722.602207", latD: "N", lonD: "E", latitude: 0.7639480333333334, longitude: 127.37670345, timestamp: "2024-06-06T18:38:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.2", satellites: "09", hdop: "0.6", lat: "0045.836882", lon: "12722.602207", latD: "N", lonD: "E", latitude: 0.7639480333333334, longitude: 127.37670345, timestamp: "2024-06-06T18:38:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.1", satellites: "09", hdop: "0.6", lat: "0045.836890", lon: "12722.602209", latD: "N", lonD: "E", latitude: 0.7639481666666667, longitude: 127.37670348333333, timestamp: "2024-06-06T18:38:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.1", satellites: "09", hdop: "0.6", lat: "0045.836890", lon: "12722.602209", latD: "N", lonD: "E", latitude: 0.7639481666666667, longitude: 127.37670348333333, timestamp: "2024-06-06T18:38:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.1", satellites: "09", hdop: "0.6", lat: "0045.836890", lon: "12722.602209", latD: "N", lonD: "E", latitude: 0.7639481666666667, longitude: 127.37670348333333, timestamp: "2024-06-06T18:38:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.1", satellites: "09", hdop: "0.6", lat: "0045.836890", lon: "12722.602209", latD: "N", lonD: "E", latitude: 0.7639481666666667, longitude: 127.37670348333333, timestamp: "2024-06-06T18:38:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.0", satellites: "09", hdop: "0.7", lat: "0045.836895", lon: "12722.602185", latD: "N", lonD: "E", latitude: 0.76394825, longitude: 127.37670308333334, timestamp: "2024-06-06T18:38:47.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.0", satellites: "09", hdop: "0.7", lat: "0045.836895", lon: "12722.602185", latD: "N", lonD: "E", latitude: 0.76394825, longitude: 127.37670308333334, timestamp: "2024-06-06T18:38:47.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.0", satellites: "09", hdop: "0.7", lat: "0045.836895", lon: "12722.602185", latD: "N", lonD: "E", latitude: 0.76394825, longitude: 127.37670308333334, timestamp: "2024-06-06T18:38:47.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-2.0", satellites: "09", hdop: "0.7", lat: "0045.836895", lon: "12722.602185", latD: "N", lonD: "E", latitude: 0.76394825, longitude: 127.37670308333334, timestamp: "2024-06-06T18:38:47.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.9", satellites: "09", hdop: "0.6", lat: "0045.836917", lon: "12722.602117", latD: "N", lonD: "E", latitude: 0.7639486166666667, longitude: 127.37670195, timestamp: "2024-06-06T18:39:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.9", satellites: "09", hdop: "0.6", lat: "0045.836917", lon: "12722.602117", latD: "N", lonD: "E", latitude: 0.7639486166666667, longitude: 127.37670195, timestamp: "2024-06-06T18:39:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.9", satellites: "09", hdop: "0.6", lat: "0045.836917", lon: "12722.602117", latD: "N", lonD: "E", latitude: 0.7639486166666667, longitude: 127.37670195, timestamp: "2024-06-06T18:39:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.9", satellites: "09", hdop: "0.6", lat: "0045.836917", lon: "12722.602117", latD: "N", lonD: "E", latitude: 0.7639486166666667, longitude: 127.37670195, timestamp: "2024-06-06T18:39:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.836939", lon: "12722.602078", latD: "N", lonD: "E", latitude: 0.7639489833333334, longitude: 127.3767013, timestamp: "2024-06-06T18:39:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.836939", lon: "12722.602078", latD: "N", lonD: "E", latitude: 0.7639489833333334, longitude: 127.3767013, timestamp: "2024-06-06T18:39:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.836939", lon: "12722.602078", latD: "N", lonD: "E", latitude: 0.7639489833333334, longitude: 127.3767013, timestamp: "2024-06-06T18:39:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.836939", lon: "12722.602078", latD: "N", lonD: "E", latitude: 0.7639489833333334, longitude: 127.3767013, timestamp: "2024-06-06T18:39:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.836958", lon: "12722.602013", latD: "N", lonD: "E", latitude: 0.7639493, longitude: 127.37670021666666, timestamp: "2024-06-06T18:39:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.836958", lon: "12722.602013", latD: "N", lonD: "E", latitude: 0.7639493, longitude: 127.37670021666666, timestamp: "2024-06-06T18:39:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.836958", lon: "12722.602013", latD: "N", lonD: "E", latitude: 0.7639493, longitude: 127.37670021666666, timestamp: "2024-06-06T18:39:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.8", satellites: "09", hdop: "0.6", lat: "0045.836958", lon: "12722.602013", latD: "N", lonD: "E", latitude: 0.7639493, longitude: 127.37670021666666, timestamp: "2024-06-06T18:39:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.836983", lon: "12722.601917", latD: "N", lonD: "E", latitude: 0.7639497166666666, longitude: 127.37669861666667, timestamp: "2024-06-06T18:40:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.836983", lon: "12722.601917", latD: "N", lonD: "E", latitude: 0.7639497166666666, longitude: 127.37669861666667, timestamp: "2024-06-06T18:40:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.836983", lon: "12722.601917", latD: "N", lonD: "E", latitude: 0.7639497166666666, longitude: 127.37669861666667, timestamp: "2024-06-06T18:40:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.836983", lon: "12722.601917", latD: "N", lonD: "E", latitude: 0.7639497166666666, longitude: 127.37669861666667, timestamp: "2024-06-06T18:40:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.836997", lon: "12722.601826", latD: "N", lonD: "E", latitude: 0.76394995, longitude: 127.3766971, timestamp: "2024-06-06T18:40:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.836997", lon: "12722.601826", latD: "N", lonD: "E", latitude: 0.76394995, longitude: 127.3766971, timestamp: "2024-06-06T18:40:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.836997", lon: "12722.601826", latD: "N", lonD: "E", latitude: 0.76394995, longitude: 127.3766971, timestamp: "2024-06-06T18:40:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.7", satellites: "09", hdop: "0.6", lat: "0045.836997", lon: "12722.601826", latD: "N", lonD: "E", latitude: 0.76394995, longitude: 127.3766971, timestamp: "2024-06-06T18:40:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837116", lon: "12722.601531", latD: "N", lonD: "E", latitude: 0.7639519333333333, longitude: 127.37669218333333, timestamp: "2024-06-06T18:40:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837116", lon: "12722.601531", latD: "N", lonD: "E", latitude: 0.7639519333333333, longitude: 127.37669218333333, timestamp: "2024-06-06T18:40:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837116", lon: "12722.601531", latD: "N", lonD: "E", latitude: 0.7639519333333333, longitude: 127.37669218333333, timestamp: "2024-06-06T18:40:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837116", lon: "12722.601531", latD: "N", lonD: "E", latitude: 0.7639519333333333, longitude: 127.37669218333333, timestamp: "2024-06-06T18:40:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.3", satellites: "09", hdop: "0.6", lat: "0045.837130", lon: "12722.601505", latD: "N", lonD: "E", latitude: 0.7639521666666667, longitude: 127.37669175, timestamp: "2024-06-06T18:41:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.3", satellites: "09", hdop: "0.6", lat: "0045.837130", lon: "12722.601505", latD: "N", lonD: "E", latitude: 0.7639521666666667, longitude: 127.37669175, timestamp: "2024-06-06T18:41:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.3", satellites: "09", hdop: "0.6", lat: "0045.837130", lon: "12722.601505", latD: "N", lonD: "E", latitude: 0.7639521666666667, longitude: 127.37669175, timestamp: "2024-06-06T18:41:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.3", satellites: "09", hdop: "0.6", lat: "0045.837130", lon: "12722.601505", latD: "N", lonD: "E", latitude: 0.7639521666666667, longitude: 127.37669175, timestamp: "2024-06-06T18:41:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.2", satellites: "09", hdop: "0.6", lat: "0045.837149", lon: "12722.601506", latD: "N", lonD: "E", latitude: 0.7639524833333333, longitude: 127.37669176666667, timestamp: "2024-06-06T18:41:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.2", satellites: "09", hdop: "0.6", lat: "0045.837149", lon: "12722.601506", latD: "N", lonD: "E", latitude: 0.7639524833333333, longitude: 127.37669176666667, timestamp: "2024-06-06T18:41:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.2", satellites: "09", hdop: "0.6", lat: "0045.837149", lon: "12722.601506", latD: "N", lonD: "E", latitude: 0.7639524833333333, longitude: 127.37669176666667, timestamp: "2024-06-06T18:41:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.2", satellites: "09", hdop: "0.6", lat: "0045.837164", lon: "12722.601507", latD: "N", lonD: "E", latitude: 0.7639527333333334, longitude: 127.37669178333333, timestamp: "2024-06-06T18:41:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.2", satellites: "09", hdop: "0.6", lat: "0045.837149", lon: "12722.601506", latD: "N", lonD: "E", latitude: 0.7639524833333333, longitude: 127.37669176666667, timestamp: "2024-06-06T18:41:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.2", satellites: "09", hdop: "0.6", lat: "0045.837164", lon: "12722.601507", latD: "N", lonD: "E", latitude: 0.7639527333333334, longitude: 127.37669178333333, timestamp: "2024-06-06T18:41:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.2", satellites: "09", hdop: "0.6", lat: "0045.837164", lon: "12722.601507", latD: "N", lonD: "E", latitude: 0.7639527333333334, longitude: 127.37669178333333, timestamp: "2024-06-06T18:41:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.2", satellites: "09", hdop: "0.6", lat: "0045.837164", lon: "12722.601507", latD: "N", lonD: "E", latitude: 0.7639527333333334, longitude: 127.37669178333333, timestamp: "2024-06-06T18:41:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.3", satellites: "09", hdop: "0.6", lat: "0045.837165", lon: "12722.601496", latD: "N", lonD: "E", latitude: 0.76395275, longitude: 127.3766916, timestamp: "2024-06-06T18:42:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.3", satellites: "09", hdop: "0.6", lat: "0045.837165", lon: "12722.601496", latD: "N", lonD: "E", latitude: 0.76395275, longitude: 127.3766916, timestamp: "2024-06-06T18:42:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.3", satellites: "09", hdop: "0.6", lat: "0045.837165", lon: "12722.601496", latD: "N", lonD: "E", latitude: 0.76395275, longitude: 127.3766916, timestamp: "2024-06-06T18:42:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.3", satellites: "09", hdop: "0.6", lat: "0045.837165", lon: "12722.601496", latD: "N", lonD: "E", latitude: 0.76395275, longitude: 127.3766916, timestamp: "2024-06-06T18:42:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837163", lon: "12722.601512", latD: "N", lonD: "E", latitude: 0.7639527166666666, longitude: 127.37669186666666, timestamp: "2024-06-06T18:42:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837163", lon: "12722.601512", latD: "N", lonD: "E", latitude: 0.7639527166666666, longitude: 127.37669186666666, timestamp: "2024-06-06T18:42:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837163", lon: "12722.601512", latD: "N", lonD: "E", latitude: 0.7639527166666666, longitude: 127.37669186666666, timestamp: "2024-06-06T18:42:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837163", lon: "12722.601512", latD: "N", lonD: "E", latitude: 0.7639527166666666, longitude: 127.37669186666666, timestamp: "2024-06-06T18:42:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837158", lon: "12722.601529", latD: "N", lonD: "E", latitude: 0.7639526333333334, longitude: 127.37669215, timestamp: "2024-06-06T18:42:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837158", lon: "12722.601529", latD: "N", lonD: "E", latitude: 0.7639526333333334, longitude: 127.37669215, timestamp: "2024-06-06T18:42:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837158", lon: "12722.601529", latD: "N", lonD: "E", latitude: 0.7639526333333334, longitude: 127.37669215, timestamp: "2024-06-06T18:42:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837158", lon: "12722.601529", latD: "N", lonD: "E", latitude: 0.7639526333333334, longitude: 127.37669215, timestamp: "2024-06-06T18:42:46.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837183", lon: "12722.601535", latD: "N", lonD: "E", latitude: 0.76395305, longitude: 127.37669225, timestamp: "2024-06-06T18:43:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837183", lon: "12722.601535", latD: "N", lonD: "E", latitude: 0.76395305, longitude: 127.37669225, timestamp: "2024-06-06T18:43:06.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837183", lon: "12722.601535", latD: "N", lonD: "E", latitude: 0.76395305, longitude: 127.37669225, timestamp: "2024-06-06T18:43:06.000Z" },
        { speed: "0.0", course: "34.6", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.836988", lon: "12722.600957", latD: "N", lonD: "E", latitude: 0.7639498, longitude: 127.37668261666667, timestamp: "2024-06-06T18:43:26.000Z" },
        { speed: "0.0", course: "81.6", altitude: "-1.4", satellites: "09", hdop: "0.6", lat: "0045.837183", lon: "12722.601535", latD: "N", lonD: "E", latitude: 0.76395305, longitude: 127.37669225, timestamp: "2024-06-06T18:43:06.000Z" },
        { speed: "0.0", course: "34.6", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.836988", lon: "12722.600957", latD: "N", lonD: "E", latitude: 0.7639498, longitude: 127.37668261666667, timestamp: "2024-06-06T18:43:26.000Z" },
        { speed: "0.0", course: "34.6", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.836988", lon: "12722.600957", latD: "N", lonD: "E", latitude: 0.7639498, longitude: 127.37668261666667, timestamp: "2024-06-06T18:43:26.000Z" },
        { speed: "0.0", course: "34.6", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.836988", lon: "12722.600957", latD: "N", lonD: "E", latitude: 0.7639498, longitude: 127.37668261666667, timestamp: "2024-06-06T18:43:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.5", satellites: "09", hdop: "0.6", lat: "0045.837475", lon: "12722.599014", latD: "N", lonD: "E", latitude: 0.7639579166666667, longitude: 127.37665023333334, timestamp: "2024-06-06T18:43:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.5", satellites: "09", hdop: "0.6", lat: "0045.837475", lon: "12722.599014", latD: "N", lonD: "E", latitude: 0.7639579166666667, longitude: 127.37665023333334, timestamp: "2024-06-06T18:43:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.5", satellites: "09", hdop: "0.6", lat: "0045.837475", lon: "12722.599014", latD: "N", lonD: "E", latitude: 0.7639579166666667, longitude: 127.37665023333334, timestamp: "2024-06-06T18:43:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.5", satellites: "09", hdop: "0.6", lat: "0045.837475", lon: "12722.599014", latD: "N", lonD: "E", latitude: 0.7639579166666667, longitude: 127.37665023333334, timestamp: "2024-06-06T18:43:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.5", satellites: "09", hdop: "0.6", lat: "0045.837442", lon: "12722.598996", latD: "N", lonD: "E", latitude: 0.7639573666666667, longitude: 127.37664993333334, timestamp: "2024-06-06T18:44:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.5", satellites: "09", hdop: "0.6", lat: "0045.837442", lon: "12722.598996", latD: "N", lonD: "E", latitude: 0.7639573666666667, longitude: 127.37664993333334, timestamp: "2024-06-06T18:44:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.5", satellites: "09", hdop: "0.6", lat: "0045.837442", lon: "12722.598996", latD: "N", lonD: "E", latitude: 0.7639573666666667, longitude: 127.37664993333334, timestamp: "2024-06-06T18:44:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.837456", lon: "12722.598963", latD: "N", lonD: "E", latitude: 0.7639576, longitude: 127.37664938333333, timestamp: "2024-06-06T18:44:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.5", satellites: "09", hdop: "0.6", lat: "0045.837442", lon: "12722.598996", latD: "N", lonD: "E", latitude: 0.7639573666666667, longitude: 127.37664993333334, timestamp: "2024-06-06T18:44:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.837456", lon: "12722.598963", latD: "N", lonD: "E", latitude: 0.7639576, longitude: 127.37664938333333, timestamp: "2024-06-06T18:44:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.837456", lon: "12722.598963", latD: "N", lonD: "E", latitude: 0.7639576, longitude: 127.37664938333333, timestamp: "2024-06-06T18:44:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.837456", lon: "12722.598963", latD: "N", lonD: "E", latitude: 0.7639576, longitude: 127.37664938333333, timestamp: "2024-06-06T18:44:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.837470", lon: "12722.598927", latD: "N", lonD: "E", latitude: 0.7639578333333333, longitude: 127.37664878333334, timestamp: "2024-06-06T18:44:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.837470", lon: "12722.598927", latD: "N", lonD: "E", latitude: 0.7639578333333333, longitude: 127.37664878333334, timestamp: "2024-06-06T18:44:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.837470", lon: "12722.598927", latD: "N", lonD: "E", latitude: 0.7639578333333333, longitude: 127.37664878333334, timestamp: "2024-06-06T18:44:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "08", hdop: "0.6", lat: "0045.837490", lon: "12722.598910", latD: "N", lonD: "E", latitude: 0.7639581666666667, longitude: 127.3766485, timestamp: "2024-06-06T18:45:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.837470", lon: "12722.598927", latD: "N", lonD: "E", latitude: 0.7639578333333333, longitude: 127.37664878333334, timestamp: "2024-06-06T18:44:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "08", hdop: "0.6", lat: "0045.837490", lon: "12722.598910", latD: "N", lonD: "E", latitude: 0.7639581666666667, longitude: 127.3766485, timestamp: "2024-06-06T18:45:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "08", hdop: "0.6", lat: "0045.837490", lon: "12722.598910", latD: "N", lonD: "E", latitude: 0.7639581666666667, longitude: 127.3766485, timestamp: "2024-06-06T18:45:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "08", hdop: "0.6", lat: "0045.837490", lon: "12722.598910", latD: "N", lonD: "E", latitude: 0.7639581666666667, longitude: 127.3766485, timestamp: "2024-06-06T18:45:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837502", lon: "12722.598927", latD: "N", lonD: "E", latitude: 0.7639583666666667, longitude: 127.37664878333334, timestamp: "2024-06-06T18:45:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837502", lon: "12722.598927", latD: "N", lonD: "E", latitude: 0.7639583666666667, longitude: 127.37664878333334, timestamp: "2024-06-06T18:45:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837502", lon: "12722.598927", latD: "N", lonD: "E", latitude: 0.7639583666666667, longitude: 127.37664878333334, timestamp: "2024-06-06T18:45:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837502", lon: "12722.598927", latD: "N", lonD: "E", latitude: 0.7639583666666667, longitude: 127.37664878333334, timestamp: "2024-06-06T18:45:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837523", lon: "12722.598969", latD: "N", lonD: "E", latitude: 0.7639587166666666, longitude: 127.37664948333334, timestamp: "2024-06-06T18:45:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837523", lon: "12722.598969", latD: "N", lonD: "E", latitude: 0.7639587166666666, longitude: 127.37664948333334, timestamp: "2024-06-06T18:45:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837523", lon: "12722.598969", latD: "N", lonD: "E", latitude: 0.7639587166666666, longitude: 127.37664948333334, timestamp: "2024-06-06T18:45:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837523", lon: "12722.598969", latD: "N", lonD: "E", latitude: 0.7639587166666666, longitude: 127.37664948333334, timestamp: "2024-06-06T18:45:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837518", lon: "12722.599018", latD: "N", lonD: "E", latitude: 0.7639586333333334, longitude: 127.3766503, timestamp: "2024-06-06T18:46:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837518", lon: "12722.599018", latD: "N", lonD: "E", latitude: 0.7639586333333334, longitude: 127.3766503, timestamp: "2024-06-06T18:46:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837518", lon: "12722.599018", latD: "N", lonD: "E", latitude: 0.7639586333333334, longitude: 127.3766503, timestamp: "2024-06-06T18:46:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837503", lon: "12722.599047", latD: "N", lonD: "E", latitude: 0.7639583833333333, longitude: 127.37665078333333, timestamp: "2024-06-06T18:46:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837518", lon: "12722.599018", latD: "N", lonD: "E", latitude: 0.7639586333333334, longitude: 127.3766503, timestamp: "2024-06-06T18:46:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837503", lon: "12722.599047", latD: "N", lonD: "E", latitude: 0.7639583833333333, longitude: 127.37665078333333, timestamp: "2024-06-06T18:46:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837503", lon: "12722.599047", latD: "N", lonD: "E", latitude: 0.7639583833333333, longitude: 127.37665078333333, timestamp: "2024-06-06T18:46:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.837503", lon: "12722.599047", latD: "N", lonD: "E", latitude: 0.7639583833333333, longitude: 127.37665078333333, timestamp: "2024-06-06T18:46:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.9", satellites: "08", hdop: "0.7", lat: "0045.837503", lon: "12722.599087", latD: "N", lonD: "E", latitude: 0.7639583833333333, longitude: 127.37665145, timestamp: "2024-06-06T18:46:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.9", satellites: "08", hdop: "0.7", lat: "0045.837503", lon: "12722.599087", latD: "N", lonD: "E", latitude: 0.7639583833333333, longitude: 127.37665145, timestamp: "2024-06-06T18:46:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.9", satellites: "08", hdop: "0.7", lat: "0045.837503", lon: "12722.599087", latD: "N", lonD: "E", latitude: 0.7639583833333333, longitude: 127.37665145, timestamp: "2024-06-06T18:46:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.9", satellites: "08", hdop: "0.7", lat: "0045.837503", lon: "12722.599087", latD: "N", lonD: "E", latitude: 0.7639583833333333, longitude: 127.37665145, timestamp: "2024-06-06T18:46:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "5.0", satellites: "08", hdop: "0.7", lat: "0045.837496", lon: "12722.599135", latD: "N", lonD: "E", latitude: 0.7639582666666667, longitude: 127.37665225, timestamp: "2024-06-06T18:47:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "5.0", satellites: "08", hdop: "0.7", lat: "0045.837496", lon: "12722.599135", latD: "N", lonD: "E", latitude: 0.7639582666666667, longitude: 127.37665225, timestamp: "2024-06-06T18:47:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "5.0", satellites: "08", hdop: "0.7", lat: "0045.837496", lon: "12722.599135", latD: "N", lonD: "E", latitude: 0.7639582666666667, longitude: 127.37665225, timestamp: "2024-06-06T18:47:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "5.0", satellites: "08", hdop: "0.7", lat: "0045.837496", lon: "12722.599135", latD: "N", lonD: "E", latitude: 0.7639582666666667, longitude: 127.37665225, timestamp: "2024-06-06T18:47:06.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.9", satellites: "09", hdop: "0.6", lat: "0045.837473", lon: "12722.599165", latD: "N", lonD: "E", latitude: 0.7639578833333334, longitude: 127.37665275, timestamp: "2024-06-06T18:47:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.9", satellites: "09", hdop: "0.6", lat: "0045.837473", lon: "12722.599165", latD: "N", lonD: "E", latitude: 0.7639578833333334, longitude: 127.37665275, timestamp: "2024-06-06T18:47:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.9", satellites: "09", hdop: "0.6", lat: "0045.837473", lon: "12722.599165", latD: "N", lonD: "E", latitude: 0.7639578833333334, longitude: 127.37665275, timestamp: "2024-06-06T18:47:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.9", satellites: "09", hdop: "0.6", lat: "0045.837473", lon: "12722.599165", latD: "N", lonD: "E", latitude: 0.7639578833333334, longitude: 127.37665275, timestamp: "2024-06-06T18:47:26.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.7", lat: "0045.837455", lon: "12722.599196", latD: "N", lonD: "E", latitude: 0.7639575833333333, longitude: 127.37665326666666, timestamp: "2024-06-06T18:47:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.7", lat: "0045.837455", lon: "12722.599196", latD: "N", lonD: "E", latitude: 0.7639575833333333, longitude: 127.37665326666666, timestamp: "2024-06-06T18:47:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.7", lat: "0045.837455", lon: "12722.599196", latD: "N", lonD: "E", latitude: 0.7639575833333333, longitude: 127.37665326666666, timestamp: "2024-06-06T18:47:46.000Z" },
        { speed: "0.0", course: "314.4", altitude: "4.8", satellites: "09", hdop: "0.7", lat: "0045.837455", lon: "12722.599196", latD: "N", lonD: "E", latitude: 0.7639575833333333, longitude: 127.37665326666666, timestamp: "2024-06-06T18:47:46.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837677", lon: "12722.598967", latD: "N", lonD: "E", latitude: 0.7639612833333334, longitude: 127.37664945, timestamp: "2024-06-06T18:48:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837677", lon: "12722.598967", latD: "N", lonD: "E", latitude: 0.7639612833333334, longitude: 127.37664945, timestamp: "2024-06-06T18:48:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837677", lon: "12722.598967", latD: "N", lonD: "E", latitude: 0.7639612833333334, longitude: 127.37664945, timestamp: "2024-06-06T18:48:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837677", lon: "12722.598967", latD: "N", lonD: "E", latitude: 0.7639612833333334, longitude: 127.37664945, timestamp: "2024-06-06T18:48:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "09", hdop: "0.7", lat: "0045.837590", lon: "12722.598903", latD: "N", lonD: "E", latitude: 0.7639598333333333, longitude: 127.37664838333333, timestamp: "2024-06-06T18:48:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "09", hdop: "0.7", lat: "0045.837590", lon: "12722.598903", latD: "N", lonD: "E", latitude: 0.7639598333333333, longitude: 127.37664838333333, timestamp: "2024-06-06T18:48:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "09", hdop: "0.7", lat: "0045.837590", lon: "12722.598903", latD: "N", lonD: "E", latitude: 0.7639598333333333, longitude: 127.37664838333333, timestamp: "2024-06-06T18:48:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "09", hdop: "0.7", lat: "0045.837590", lon: "12722.598903", latD: "N", lonD: "E", latitude: 0.7639598333333333, longitude: 127.37664838333333, timestamp: "2024-06-06T18:48:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "08", hdop: "0.7", lat: "0045.837592", lon: "12722.598904", latD: "N", lonD: "E", latitude: 0.7639598666666667, longitude: 127.3766484, timestamp: "2024-06-06T18:48:46.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "08", hdop: "0.7", lat: "0045.837592", lon: "12722.598904", latD: "N", lonD: "E", latitude: 0.7639598666666667, longitude: 127.3766484, timestamp: "2024-06-06T18:48:46.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "08", hdop: "0.7", lat: "0045.837592", lon: "12722.598904", latD: "N", lonD: "E", latitude: 0.7639598666666667, longitude: 127.3766484, timestamp: "2024-06-06T18:48:46.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837608", lon: "12722.598913", latD: "N", lonD: "E", latitude: 0.7639601333333333, longitude: 127.37664855, timestamp: "2024-06-06T18:49:06.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "08", hdop: "0.7", lat: "0045.837592", lon: "12722.598904", latD: "N", lonD: "E", latitude: 0.7639598666666667, longitude: 127.3766484, timestamp: "2024-06-06T18:48:46.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837608", lon: "12722.598913", latD: "N", lonD: "E", latitude: 0.7639601333333333, longitude: 127.37664855, timestamp: "2024-06-06T18:49:06.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837608", lon: "12722.598913", latD: "N", lonD: "E", latitude: 0.7639601333333333, longitude: 127.37664855, timestamp: "2024-06-06T18:49:06.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837608", lon: "12722.598913", latD: "N", lonD: "E", latitude: 0.7639601333333333, longitude: 127.37664855, timestamp: "2024-06-06T18:49:06.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837628", lon: "12722.598935", latD: "N", lonD: "E", latitude: 0.7639604666666667, longitude: 127.37664891666667, timestamp: "2024-06-06T18:49:26.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837628", lon: "12722.598935", latD: "N", lonD: "E", latitude: 0.7639604666666667, longitude: 127.37664891666667, timestamp: "2024-06-06T18:49:26.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837628", lon: "12722.598935", latD: "N", lonD: "E", latitude: 0.7639604666666667, longitude: 127.37664891666667, timestamp: "2024-06-06T18:49:26.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837628", lon: "12722.598935", latD: "N", lonD: "E", latitude: 0.7639604666666667, longitude: 127.37664891666667, timestamp: "2024-06-06T18:49:26.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "08", hdop: "0.7", lat: "0045.837643", lon: "12722.598947", latD: "N", lonD: "E", latitude: 0.7639607166666667, longitude: 127.37664911666667, timestamp: "2024-06-06T18:49:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "08", hdop: "0.7", lat: "0045.837643", lon: "12722.598947", latD: "N", lonD: "E", latitude: 0.7639607166666667, longitude: 127.37664911666667, timestamp: "2024-06-06T18:49:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "08", hdop: "0.7", lat: "0045.837643", lon: "12722.598947", latD: "N", lonD: "E", latitude: 0.7639607166666667, longitude: 127.37664911666667, timestamp: "2024-06-06T18:49:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "08", hdop: "0.7", lat: "0045.837643", lon: "12722.598947", latD: "N", lonD: "E", latitude: 0.7639607166666667, longitude: 127.37664911666667, timestamp: "2024-06-06T18:49:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837657", lon: "12722.598931", latD: "N", lonD: "E", latitude: 0.76396095, longitude: 127.37664885, timestamp: "2024-06-06T18:50:06.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837657", lon: "12722.598931", latD: "N", lonD: "E", latitude: 0.76396095, longitude: 127.37664885, timestamp: "2024-06-06T18:50:06.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837657", lon: "12722.598931", latD: "N", lonD: "E", latitude: 0.76396095, longitude: 127.37664885, timestamp: "2024-06-06T18:50:06.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837657", lon: "12722.598931", latD: "N", lonD: "E", latitude: 0.76396095, longitude: 127.37664885, timestamp: "2024-06-06T18:50:06.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "08", hdop: "0.7", lat: "0045.837668", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639611333333334, longitude: 127.37664925, timestamp: "2024-06-06T18:50:26.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "08", hdop: "0.7", lat: "0045.837668", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639611333333334, longitude: 127.37664925, timestamp: "2024-06-06T18:50:26.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "08", hdop: "0.7", lat: "0045.837668", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639611333333334, longitude: 127.37664925, timestamp: "2024-06-06T18:50:26.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "08", hdop: "0.7", lat: "0045.837668", lon: "12722.598955", latD: "N", lonD: "E", latitude: 0.7639611333333334, longitude: 127.37664925, timestamp: "2024-06-06T18:50:26.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "09", hdop: "0.7", lat: "0045.837708", lon: "12722.598941", latD: "N", lonD: "E", latitude: 0.7639618, longitude: 127.37664901666666, timestamp: "2024-06-06T18:50:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "09", hdop: "0.7", lat: "0045.837708", lon: "12722.598941", latD: "N", lonD: "E", latitude: 0.7639618, longitude: 127.37664901666666, timestamp: "2024-06-06T18:50:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "09", hdop: "0.7", lat: "0045.837708", lon: "12722.598941", latD: "N", lonD: "E", latitude: 0.7639618, longitude: 127.37664901666666, timestamp: "2024-06-06T18:50:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "09", hdop: "0.7", lat: "0045.837708", lon: "12722.598941", latD: "N", lonD: "E", latitude: 0.7639618, longitude: 127.37664901666666, timestamp: "2024-06-06T18:50:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837753", lon: "12722.598853", latD: "N", lonD: "E", latitude: 0.76396255, longitude: 127.37664755, timestamp: "2024-06-06T18:51:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837753", lon: "12722.598853", latD: "N", lonD: "E", latitude: 0.76396255, longitude: 127.37664755, timestamp: "2024-06-06T18:51:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837753", lon: "12722.598853", latD: "N", lonD: "E", latitude: 0.76396255, longitude: 127.37664755, timestamp: "2024-06-06T18:51:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837753", lon: "12722.598853", latD: "N", lonD: "E", latitude: 0.76396255, longitude: 127.37664755, timestamp: "2024-06-06T18:51:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "08", hdop: "0.7", lat: "0045.837783", lon: "12722.598839", latD: "N", lonD: "E", latitude: 0.76396305, longitude: 127.37664731666666, timestamp: "2024-06-06T18:51:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "08", hdop: "0.7", lat: "0045.837783", lon: "12722.598839", latD: "N", lonD: "E", latitude: 0.76396305, longitude: 127.37664731666666, timestamp: "2024-06-06T18:51:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "08", hdop: "0.7", lat: "0045.837783", lon: "12722.598839", latD: "N", lonD: "E", latitude: 0.76396305, longitude: 127.37664731666666, timestamp: "2024-06-06T18:51:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "08", hdop: "0.7", lat: "0045.837783", lon: "12722.598839", latD: "N", lonD: "E", latitude: 0.76396305, longitude: 127.37664731666666, timestamp: "2024-06-06T18:51:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837834", lon: "12722.598761", latD: "N", lonD: "E", latitude: 0.7639639, longitude: 127.37664601666667, timestamp: "2024-06-06T18:51:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837834", lon: "12722.598761", latD: "N", lonD: "E", latitude: 0.7639639, longitude: 127.37664601666667, timestamp: "2024-06-06T18:51:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837834", lon: "12722.598761", latD: "N", lonD: "E", latitude: 0.7639639, longitude: 127.37664601666667, timestamp: "2024-06-06T18:51:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837834", lon: "12722.598761", latD: "N", lonD: "E", latitude: 0.7639639, longitude: 127.37664601666667, timestamp: "2024-06-06T18:51:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837857", lon: "12722.598761", latD: "N", lonD: "E", latitude: 0.7639642833333333, longitude: 127.37664601666667, timestamp: "2024-06-06T18:52:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837857", lon: "12722.598761", latD: "N", lonD: "E", latitude: 0.7639642833333333, longitude: 127.37664601666667, timestamp: "2024-06-06T18:52:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837857", lon: "12722.598761", latD: "N", lonD: "E", latitude: 0.7639642833333333, longitude: 127.37664601666667, timestamp: "2024-06-06T18:52:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "08", hdop: "0.7", lat: "0045.837857", lon: "12722.598761", latD: "N", lonD: "E", latitude: 0.7639642833333333, longitude: 127.37664601666667, timestamp: "2024-06-06T18:52:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "09", hdop: "0.7", lat: "0045.837874", lon: "12722.598767", latD: "N", lonD: "E", latitude: 0.7639645666666667, longitude: 127.37664611666666, timestamp: "2024-06-06T18:52:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "09", hdop: "0.7", lat: "0045.837874", lon: "12722.598767", latD: "N", lonD: "E", latitude: 0.7639645666666667, longitude: 127.37664611666666, timestamp: "2024-06-06T18:52:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "09", hdop: "0.7", lat: "0045.837874", lon: "12722.598767", latD: "N", lonD: "E", latitude: 0.7639645666666667, longitude: 127.37664611666666, timestamp: "2024-06-06T18:52:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "6.0", satellites: "09", hdop: "0.7", lat: "0045.837874", lon: "12722.598767", latD: "N", lonD: "E", latitude: 0.7639645666666667, longitude: 127.37664611666666, timestamp: "2024-06-06T18:52:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "09", hdop: "0.7", lat: "0045.837875", lon: "12722.598799", latD: "N", lonD: "E", latitude: 0.7639645833333333, longitude: 127.37664665, timestamp: "2024-06-06T18:52:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "09", hdop: "0.7", lat: "0045.837875", lon: "12722.598799", latD: "N", lonD: "E", latitude: 0.7639645833333333, longitude: 127.37664665, timestamp: "2024-06-06T18:52:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "09", hdop: "0.7", lat: "0045.837875", lon: "12722.598799", latD: "N", lonD: "E", latitude: 0.7639645833333333, longitude: 127.37664665, timestamp: "2024-06-06T18:52:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.9", satellites: "09", hdop: "0.7", lat: "0045.837875", lon: "12722.598799", latD: "N", lonD: "E", latitude: 0.7639645833333333, longitude: 127.37664665, timestamp: "2024-06-06T18:52:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837877", lon: "12722.598818", latD: "N", lonD: "E", latitude: 0.7639646166666666, longitude: 127.37664696666667, timestamp: "2024-06-06T18:53:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837877", lon: "12722.598818", latD: "N", lonD: "E", latitude: 0.7639646166666666, longitude: 127.37664696666667, timestamp: "2024-06-06T18:53:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837877", lon: "12722.598818", latD: "N", lonD: "E", latitude: 0.7639646166666666, longitude: 127.37664696666667, timestamp: "2024-06-06T18:53:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "08", hdop: "0.7", lat: "0045.837877", lon: "12722.598818", latD: "N", lonD: "E", latitude: 0.7639646166666666, longitude: 127.37664696666667, timestamp: "2024-06-06T18:53:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837873", lon: "12722.598818", latD: "N", lonD: "E", latitude: 0.7639645500000001, longitude: 127.37664696666667, timestamp: "2024-06-06T18:53:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837873", lon: "12722.598818", latD: "N", lonD: "E", latitude: 0.7639645500000001, longitude: 127.37664696666667, timestamp: "2024-06-06T18:53:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837873", lon: "12722.598818", latD: "N", lonD: "E", latitude: 0.7639645500000001, longitude: 127.37664696666667, timestamp: "2024-06-06T18:53:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837873", lon: "12722.598818", latD: "N", lonD: "E", latitude: 0.7639645500000001, longitude: 127.37664696666667, timestamp: "2024-06-06T18:53:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837870", lon: "12722.598833", latD: "N", lonD: "E", latitude: 0.7639645, longitude: 127.37664721666667, timestamp: "2024-06-06T18:53:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837870", lon: "12722.598833", latD: "N", lonD: "E", latitude: 0.7639645, longitude: 127.37664721666667, timestamp: "2024-06-06T18:53:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837870", lon: "12722.598833", latD: "N", lonD: "E", latitude: 0.7639645, longitude: 127.37664721666667, timestamp: "2024-06-06T18:53:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837870", lon: "12722.598833", latD: "N", lonD: "E", latitude: 0.7639645, longitude: 127.37664721666667, timestamp: "2024-06-06T18:53:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837889", lon: "12722.598840", latD: "N", lonD: "E", latitude: 0.7639648166666666, longitude: 127.37664733333334, timestamp: "2024-06-06T18:54:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837889", lon: "12722.598840", latD: "N", lonD: "E", latitude: 0.7639648166666666, longitude: 127.37664733333334, timestamp: "2024-06-06T18:54:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837889", lon: "12722.598840", latD: "N", lonD: "E", latitude: 0.7639648166666666, longitude: 127.37664733333334, timestamp: "2024-06-06T18:54:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.8", satellites: "09", hdop: "0.7", lat: "0045.837889", lon: "12722.598840", latD: "N", lonD: "E", latitude: 0.7639648166666666, longitude: 127.37664733333334, timestamp: "2024-06-06T18:54:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837902", lon: "12722.598853", latD: "N", lonD: "E", latitude: 0.7639650333333333, longitude: 127.37664755, timestamp: "2024-06-06T18:54:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837902", lon: "12722.598853", latD: "N", lonD: "E", latitude: 0.7639650333333333, longitude: 127.37664755, timestamp: "2024-06-06T18:54:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837902", lon: "12722.598853", latD: "N", lonD: "E", latitude: 0.7639650333333333, longitude: 127.37664755, timestamp: "2024-06-06T18:54:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837902", lon: "12722.598853", latD: "N", lonD: "E", latitude: 0.7639650333333333, longitude: 127.37664755, timestamp: "2024-06-06T18:54:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837916", lon: "12722.598861", latD: "N", lonD: "E", latitude: 0.7639652666666666, longitude: 127.37664768333333, timestamp: "2024-06-06T18:54:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837916", lon: "12722.598861", latD: "N", lonD: "E", latitude: 0.7639652666666666, longitude: 127.37664768333333, timestamp: "2024-06-06T18:54:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837916", lon: "12722.598861", latD: "N", lonD: "E", latitude: 0.7639652666666666, longitude: 127.37664768333333, timestamp: "2024-06-06T18:54:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.7", satellites: "09", hdop: "0.7", lat: "0045.837916", lon: "12722.598861", latD: "N", lonD: "E", latitude: 0.7639652666666666, longitude: 127.37664768333333, timestamp: "2024-06-06T18:54:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "09", hdop: "0.7", lat: "0045.837921", lon: "12722.598852", latD: "N", lonD: "E", latitude: 0.76396535, longitude: 127.37664753333334, timestamp: "2024-06-06T18:55:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "09", hdop: "0.7", lat: "0045.837921", lon: "12722.598852", latD: "N", lonD: "E", latitude: 0.76396535, longitude: 127.37664753333334, timestamp: "2024-06-06T18:55:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "09", hdop: "0.7", lat: "0045.837921", lon: "12722.598852", latD: "N", lonD: "E", latitude: 0.76396535, longitude: 127.37664753333334, timestamp: "2024-06-06T18:55:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "09", hdop: "0.7", lat: "0045.837921", lon: "12722.598852", latD: "N", lonD: "E", latitude: 0.76396535, longitude: 127.37664753333334, timestamp: "2024-06-06T18:55:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "07", hdop: "0.8", lat: "0045.837931", lon: "12722.598838", latD: "N", lonD: "E", latitude: 0.7639655166666667, longitude: 127.3766473, timestamp: "2024-06-06T18:55:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "07", hdop: "0.8", lat: "0045.837931", lon: "12722.598838", latD: "N", lonD: "E", latitude: 0.7639655166666667, longitude: 127.3766473, timestamp: "2024-06-06T18:55:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "07", hdop: "0.8", lat: "0045.837931", lon: "12722.598838", latD: "N", lonD: "E", latitude: 0.7639655166666667, longitude: 127.3766473, timestamp: "2024-06-06T18:55:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.6", satellites: "07", hdop: "0.8", lat: "0045.837931", lon: "12722.598838", latD: "N", lonD: "E", latitude: 0.7639655166666667, longitude: 127.3766473, timestamp: "2024-06-06T18:55:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "06", hdop: "0.8", lat: "0045.837932", lon: "12722.598822", latD: "N", lonD: "E", latitude: 0.7639655333333334, longitude: 127.37664703333333, timestamp: "2024-06-06T18:55:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "06", hdop: "0.8", lat: "0045.837932", lon: "12722.598822", latD: "N", lonD: "E", latitude: 0.7639655333333334, longitude: 127.37664703333333, timestamp: "2024-06-06T18:55:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "06", hdop: "0.8", lat: "0045.837932", lon: "12722.598822", latD: "N", lonD: "E", latitude: 0.7639655333333334, longitude: 127.37664703333333, timestamp: "2024-06-06T18:55:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "06", hdop: "0.8", lat: "0045.837932", lon: "12722.598822", latD: "N", lonD: "E", latitude: 0.7639655333333334, longitude: 127.37664703333333, timestamp: "2024-06-06T18:55:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "08", hdop: "0.7", lat: "0045.837934", lon: "12722.598801", latD: "N", lonD: "E", latitude: 0.7639655666666666, longitude: 127.37664668333333, timestamp: "2024-06-06T18:56:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "08", hdop: "0.7", lat: "0045.837934", lon: "12722.598801", latD: "N", lonD: "E", latitude: 0.7639655666666666, longitude: 127.37664668333333, timestamp: "2024-06-06T18:56:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "08", hdop: "0.7", lat: "0045.837934", lon: "12722.598801", latD: "N", lonD: "E", latitude: 0.7639655666666666, longitude: 127.37664668333333, timestamp: "2024-06-06T18:56:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "08", hdop: "0.7", lat: "0045.837934", lon: "12722.598801", latD: "N", lonD: "E", latitude: 0.7639655666666666, longitude: 127.37664668333333, timestamp: "2024-06-06T18:56:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "08", hdop: "0.7", lat: "0045.837938", lon: "12722.598765", latD: "N", lonD: "E", latitude: 0.7639656333333333, longitude: 127.37664608333333, timestamp: "2024-06-06T18:56:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "08", hdop: "0.7", lat: "0045.837938", lon: "12722.598765", latD: "N", lonD: "E", latitude: 0.7639656333333333, longitude: 127.37664608333333, timestamp: "2024-06-06T18:56:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "08", hdop: "0.7", lat: "0045.837938", lon: "12722.598765", latD: "N", lonD: "E", latitude: 0.7639656333333333, longitude: 127.37664608333333, timestamp: "2024-06-06T18:56:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.5", satellites: "08", hdop: "0.7", lat: "0045.837938", lon: "12722.598765", latD: "N", lonD: "E", latitude: 0.7639656333333333, longitude: 127.37664608333333, timestamp: "2024-06-06T18:56:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837941", lon: "12722.598737", latD: "N", lonD: "E", latitude: 0.7639656833333334, longitude: 127.37664561666666, timestamp: "2024-06-06T18:56:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837941", lon: "12722.598737", latD: "N", lonD: "E", latitude: 0.7639656833333334, longitude: 127.37664561666666, timestamp: "2024-06-06T18:56:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837941", lon: "12722.598737", latD: "N", lonD: "E", latitude: 0.7639656833333334, longitude: 127.37664561666666, timestamp: "2024-06-06T18:56:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837941", lon: "12722.598737", latD: "N", lonD: "E", latitude: 0.7639656833333334, longitude: 127.37664561666666, timestamp: "2024-06-06T18:56:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837944", lon: "12722.598711", latD: "N", lonD: "E", latitude: 0.7639657333333333, longitude: 127.37664518333334, timestamp: "2024-06-06T18:57:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837944", lon: "12722.598711", latD: "N", lonD: "E", latitude: 0.7639657333333333, longitude: 127.37664518333334, timestamp: "2024-06-06T18:57:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837944", lon: "12722.598711", latD: "N", lonD: "E", latitude: 0.7639657333333333, longitude: 127.37664518333334, timestamp: "2024-06-06T18:57:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837944", lon: "12722.598711", latD: "N", lonD: "E", latitude: 0.7639657333333333, longitude: 127.37664518333334, timestamp: "2024-06-06T18:57:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837946", lon: "12722.598683", latD: "N", lonD: "E", latitude: 0.7639657666666667, longitude: 127.37664471666666, timestamp: "2024-06-06T18:57:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837946", lon: "12722.598683", latD: "N", lonD: "E", latitude: 0.7639657666666667, longitude: 127.37664471666666, timestamp: "2024-06-06T18:57:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837946", lon: "12722.598683", latD: "N", lonD: "E", latitude: 0.7639657666666667, longitude: 127.37664471666666, timestamp: "2024-06-06T18:57:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837946", lon: "12722.598683", latD: "N", lonD: "E", latitude: 0.7639657666666667, longitude: 127.37664471666666, timestamp: "2024-06-06T18:57:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "09", hdop: "0.7", lat: "0045.837955", lon: "12722.598657", latD: "N", lonD: "E", latitude: 0.7639659166666667, longitude: 127.37664428333333, timestamp: "2024-06-06T18:57:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "09", hdop: "0.7", lat: "0045.837955", lon: "12722.598657", latD: "N", lonD: "E", latitude: 0.7639659166666667, longitude: 127.37664428333333, timestamp: "2024-06-06T18:57:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "09", hdop: "0.7", lat: "0045.837955", lon: "12722.598657", latD: "N", lonD: "E", latitude: 0.7639659166666667, longitude: 127.37664428333333, timestamp: "2024-06-06T18:57:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "09", hdop: "0.7", lat: "0045.837969", lon: "12722.598645", latD: "N", lonD: "E", latitude: 0.76396615, longitude: 127.37664408333333, timestamp: "2024-06-06T18:58:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "09", hdop: "0.7", lat: "0045.837969", lon: "12722.598645", latD: "N", lonD: "E", latitude: 0.76396615, longitude: 127.37664408333333, timestamp: "2024-06-06T18:58:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "09", hdop: "0.7", lat: "0045.837955", lon: "12722.598657", latD: "N", lonD: "E", latitude: 0.7639659166666667, longitude: 127.37664428333333, timestamp: "2024-06-06T18:57:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "09", hdop: "0.7", lat: "0045.837969", lon: "12722.598645", latD: "N", lonD: "E", latitude: 0.76396615, longitude: 127.37664408333333, timestamp: "2024-06-06T18:58:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "09", hdop: "0.7", lat: "0045.837969", lon: "12722.598645", latD: "N", lonD: "E", latitude: 0.76396615, longitude: 127.37664408333333, timestamp: "2024-06-06T18:58:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "08", hdop: "0.7", lat: "0045.837970", lon: "12722.598637", latD: "N", lonD: "E", latitude: 0.7639661666666666, longitude: 127.37664395, timestamp: "2024-06-06T18:58:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "08", hdop: "0.7", lat: "0045.837970", lon: "12722.598637", latD: "N", lonD: "E", latitude: 0.7639661666666666, longitude: 127.37664395, timestamp: "2024-06-06T18:58:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "08", hdop: "0.7", lat: "0045.837970", lon: "12722.598637", latD: "N", lonD: "E", latitude: 0.7639661666666666, longitude: 127.37664395, timestamp: "2024-06-06T18:58:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "08", hdop: "0.7", lat: "0045.837970", lon: "12722.598637", latD: "N", lonD: "E", latitude: 0.7639661666666666, longitude: 127.37664395, timestamp: "2024-06-06T18:58:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837973", lon: "12722.598600", latD: "N", lonD: "E", latitude: 0.7639662166666666, longitude: 127.37664333333333, timestamp: "2024-06-06T18:58:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837973", lon: "12722.598600", latD: "N", lonD: "E", latitude: 0.7639662166666666, longitude: 127.37664333333333, timestamp: "2024-06-06T18:58:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837973", lon: "12722.598600", latD: "N", lonD: "E", latitude: 0.7639662166666666, longitude: 127.37664333333333, timestamp: "2024-06-06T18:58:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837973", lon: "12722.598600", latD: "N", lonD: "E", latitude: 0.7639662166666666, longitude: 127.37664333333333, timestamp: "2024-06-06T18:58:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "08", hdop: "0.7", lat: "0045.837981", lon: "12722.598589", latD: "N", lonD: "E", latitude: 0.76396635, longitude: 127.37664315, timestamp: "2024-06-06T18:59:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "08", hdop: "0.7", lat: "0045.837981", lon: "12722.598589", latD: "N", lonD: "E", latitude: 0.76396635, longitude: 127.37664315, timestamp: "2024-06-06T18:59:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "08", hdop: "0.7", lat: "0045.837981", lon: "12722.598589", latD: "N", lonD: "E", latitude: 0.76396635, longitude: 127.37664315, timestamp: "2024-06-06T18:59:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "08", hdop: "0.7", lat: "0045.837981", lon: "12722.598589", latD: "N", lonD: "E", latitude: 0.76396635, longitude: 127.37664315, timestamp: "2024-06-06T18:59:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837988", lon: "12722.598581", latD: "N", lonD: "E", latitude: 0.7639664666666667, longitude: 127.37664301666666, timestamp: "2024-06-06T18:59:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837988", lon: "12722.598581", latD: "N", lonD: "E", latitude: 0.7639664666666667, longitude: 127.37664301666666, timestamp: "2024-06-06T18:59:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837988", lon: "12722.598581", latD: "N", lonD: "E", latitude: 0.7639664666666667, longitude: 127.37664301666666, timestamp: "2024-06-06T18:59:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837988", lon: "12722.598581", latD: "N", lonD: "E", latitude: 0.7639664666666667, longitude: 127.37664301666666, timestamp: "2024-06-06T18:59:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837987", lon: "12722.598574", latD: "N", lonD: "E", latitude: 0.76396645, longitude: 127.3766429, timestamp: "2024-06-06T18:59:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837987", lon: "12722.598574", latD: "N", lonD: "E", latitude: 0.76396645, longitude: 127.3766429, timestamp: "2024-06-06T18:59:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837987", lon: "12722.598574", latD: "N", lonD: "E", latitude: 0.76396645, longitude: 127.3766429, timestamp: "2024-06-06T18:59:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.837987", lon: "12722.598574", latD: "N", lonD: "E", latitude: 0.76396645, longitude: 127.3766429, timestamp: "2024-06-06T18:59:47.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.838004", lon: "12722.598602", latD: "N", lonD: "E", latitude: 0.7639667333333333, longitude: 127.37664336666667, timestamp: "2024-06-06T19:00:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.838004", lon: "12722.598602", latD: "N", lonD: "E", latitude: 0.7639667333333333, longitude: 127.37664336666667, timestamp: "2024-06-06T19:00:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.838004", lon: "12722.598602", latD: "N", lonD: "E", latitude: 0.7639667333333333, longitude: 127.37664336666667, timestamp: "2024-06-06T19:00:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.4", satellites: "09", hdop: "0.7", lat: "0045.838004", lon: "12722.598602", latD: "N", lonD: "E", latitude: 0.7639667333333333, longitude: 127.37664336666667, timestamp: "2024-06-06T19:00:07.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "10", hdop: "0.6", lat: "0045.838037", lon: "12722.598639", latD: "N", lonD: "E", latitude: 0.7639672833333333, longitude: 127.37664398333334, timestamp: "2024-06-06T19:00:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "10", hdop: "0.6", lat: "0045.838037", lon: "12722.598639", latD: "N", lonD: "E", latitude: 0.7639672833333333, longitude: 127.37664398333334, timestamp: "2024-06-06T19:00:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "10", hdop: "0.6", lat: "0045.838037", lon: "12722.598639", latD: "N", lonD: "E", latitude: 0.7639672833333333, longitude: 127.37664398333334, timestamp: "2024-06-06T19:00:27.000Z" },
        { speed: "0.0", course: "341.4", altitude: "5.3", satellites: "10", hdop: "0.6", lat: "0045.838037", lon: "12722.598639", latD: "N", lonD: "E", latitude: 0.7639672833333333, longitude: 127.37664398333334, timestamp: "2024-06-06T19:00:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.9", satellites: "10", hdop: "0.5", lat: "0045.838603", lon: "12722.597150", latD: "N", lonD: "E", latitude: 0.7639767166666667, longitude: 127.37661916666667, timestamp: "2024-06-06T19:00:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.9", satellites: "10", hdop: "0.5", lat: "0045.838603", lon: "12722.597150", latD: "N", lonD: "E", latitude: 0.7639767166666667, longitude: 127.37661916666667, timestamp: "2024-06-06T19:00:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.9", satellites: "10", hdop: "0.5", lat: "0045.838603", lon: "12722.597150", latD: "N", lonD: "E", latitude: 0.7639767166666667, longitude: 127.37661916666667, timestamp: "2024-06-06T19:00:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.9", satellites: "10", hdop: "0.5", lat: "0045.838603", lon: "12722.597150", latD: "N", lonD: "E", latitude: 0.7639767166666667, longitude: 127.37661916666667, timestamp: "2024-06-06T19:00:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.838248", lon: "12722.596831", latD: "N", lonD: "E", latitude: 0.7639708, longitude: 127.37661385, timestamp: "2024-06-06T19:01:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.838248", lon: "12722.596831", latD: "N", lonD: "E", latitude: 0.7639708, longitude: 127.37661385, timestamp: "2024-06-06T19:01:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.838248", lon: "12722.596831", latD: "N", lonD: "E", latitude: 0.7639708, longitude: 127.37661385, timestamp: "2024-06-06T19:01:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.838248", lon: "12722.596831", latD: "N", lonD: "E", latitude: 0.7639708, longitude: 127.37661385, timestamp: "2024-06-06T19:01:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.838231", lon: "12722.596827", latD: "N", lonD: "E", latitude: 0.7639705166666667, longitude: 127.37661378333334, timestamp: "2024-06-06T19:01:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.838231", lon: "12722.596827", latD: "N", lonD: "E", latitude: 0.7639705166666667, longitude: 127.37661378333334, timestamp: "2024-06-06T19:01:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.838231", lon: "12722.596827", latD: "N", lonD: "E", latitude: 0.7639705166666667, longitude: 127.37661378333334, timestamp: "2024-06-06T19:01:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.838231", lon: "12722.596827", latD: "N", lonD: "E", latitude: 0.7639705166666667, longitude: 127.37661378333334, timestamp: "2024-06-06T19:01:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.838251", lon: "12722.596807", latD: "N", lonD: "E", latitude: 0.76397085, longitude: 127.37661345, timestamp: "2024-06-06T19:01:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.838251", lon: "12722.596807", latD: "N", lonD: "E", latitude: 0.76397085, longitude: 127.37661345, timestamp: "2024-06-06T19:01:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.838251", lon: "12722.596807", latD: "N", lonD: "E", latitude: 0.76397085, longitude: 127.37661345, timestamp: "2024-06-06T19:01:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.838270", lon: "12722.596813", latD: "N", lonD: "E", latitude: 0.7639711666666666, longitude: 127.37661355, timestamp: "2024-06-06T19:02:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.4", satellites: "10", hdop: "0.6", lat: "0045.838251", lon: "12722.596807", latD: "N", lonD: "E", latitude: 0.76397085, longitude: 127.37661345, timestamp: "2024-06-06T19:01:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.838270", lon: "12722.596813", latD: "N", lonD: "E", latitude: 0.7639711666666666, longitude: 127.37661355, timestamp: "2024-06-06T19:02:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.838270", lon: "12722.596813", latD: "N", lonD: "E", latitude: 0.7639711666666666, longitude: 127.37661355, timestamp: "2024-06-06T19:02:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.5", satellites: "10", hdop: "0.6", lat: "0045.838270", lon: "12722.596813", latD: "N", lonD: "E", latitude: 0.7639711666666666, longitude: 127.37661355, timestamp: "2024-06-06T19:02:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.7", satellites: "10", hdop: "0.5", lat: "0045.838317", lon: "12722.596852", latD: "N", lonD: "E", latitude: 0.7639719500000001, longitude: 127.3766142, timestamp: "2024-06-06T19:02:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.7", satellites: "10", hdop: "0.5", lat: "0045.838317", lon: "12722.596852", latD: "N", lonD: "E", latitude: 0.7639719500000001, longitude: 127.3766142, timestamp: "2024-06-06T19:02:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.7", satellites: "10", hdop: "0.5", lat: "0045.838317", lon: "12722.596852", latD: "N", lonD: "E", latitude: 0.7639719500000001, longitude: 127.3766142, timestamp: "2024-06-06T19:02:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.7", satellites: "10", hdop: "0.5", lat: "0045.838317", lon: "12722.596852", latD: "N", lonD: "E", latitude: 0.7639719500000001, longitude: 127.3766142, timestamp: "2024-06-06T19:02:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.7", satellites: "10", hdop: "0.6", lat: "0045.838338", lon: "12722.596855", latD: "N", lonD: "E", latitude: 0.7639723, longitude: 127.37661425, timestamp: "2024-06-06T19:02:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.7", satellites: "10", hdop: "0.6", lat: "0045.838338", lon: "12722.596855", latD: "N", lonD: "E", latitude: 0.7639723, longitude: 127.37661425, timestamp: "2024-06-06T19:02:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.7", satellites: "10", hdop: "0.6", lat: "0045.838338", lon: "12722.596855", latD: "N", lonD: "E", latitude: 0.7639723, longitude: 127.37661425, timestamp: "2024-06-06T19:02:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.7", satellites: "10", hdop: "0.6", lat: "0045.838338", lon: "12722.596855", latD: "N", lonD: "E", latitude: 0.7639723, longitude: 127.37661425, timestamp: "2024-06-06T19:02:47.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.838359", lon: "12722.596870", latD: "N", lonD: "E", latitude: 0.7639726499999999, longitude: 127.3766145, timestamp: "2024-06-06T19:03:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.838359", lon: "12722.596870", latD: "N", lonD: "E", latitude: 0.7639726499999999, longitude: 127.3766145, timestamp: "2024-06-06T19:03:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.838359", lon: "12722.596870", latD: "N", lonD: "E", latitude: 0.7639726499999999, longitude: 127.3766145, timestamp: "2024-06-06T19:03:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.838359", lon: "12722.596870", latD: "N", lonD: "E", latitude: 0.7639726499999999, longitude: 127.3766145, timestamp: "2024-06-06T19:03:07.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.838350", lon: "12722.596873", latD: "N", lonD: "E", latitude: 0.7639724999999999, longitude: 127.37661455, timestamp: "2024-06-06T19:03:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.838350", lon: "12722.596873", latD: "N", lonD: "E", latitude: 0.7639724999999999, longitude: 127.37661455, timestamp: "2024-06-06T19:03:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.838350", lon: "12722.596873", latD: "N", lonD: "E", latitude: 0.7639724999999999, longitude: 127.37661455, timestamp: "2024-06-06T19:03:27.000Z" },
        { speed: "0.0", course: "214.8", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.838350", lon: "12722.596873", latD: "N", lonD: "E", latitude: 0.7639724999999999, longitude: 127.37661455, timestamp: "2024-06-06T19:03:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.838899", lon: "12722.596533", latD: "N", lonD: "E", latitude: 0.76398165, longitude: 127.37660888333333, timestamp: "2024-06-06T19:03:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.838899", lon: "12722.596533", latD: "N", lonD: "E", latitude: 0.76398165, longitude: 127.37660888333333, timestamp: "2024-06-06T19:03:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.838899", lon: "12722.596533", latD: "N", lonD: "E", latitude: 0.76398165, longitude: 127.37660888333333, timestamp: "2024-06-06T19:03:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.3", satellites: "10", hdop: "0.6", lat: "0045.838899", lon: "12722.596533", latD: "N", lonD: "E", latitude: 0.76398165, longitude: 127.37660888333333, timestamp: "2024-06-06T19:03:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596789", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37661315, timestamp: "2024-06-06T19:04:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596789", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37661315, timestamp: "2024-06-06T19:04:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596789", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37661315, timestamp: "2024-06-06T19:04:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.839132", lon: "12722.596789", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.37661315, timestamp: "2024-06-06T19:04:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.839154", lon: "12722.596788", latD: "N", lonD: "E", latitude: 0.7639859, longitude: 127.37661313333334, timestamp: "2024-06-06T19:04:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.839154", lon: "12722.596788", latD: "N", lonD: "E", latitude: 0.7639859, longitude: 127.37661313333334, timestamp: "2024-06-06T19:04:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.839154", lon: "12722.596788", latD: "N", lonD: "E", latitude: 0.7639859, longitude: 127.37661313333334, timestamp: "2024-06-06T19:04:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.839154", lon: "12722.596788", latD: "N", lonD: "E", latitude: 0.7639859, longitude: 127.37661313333334, timestamp: "2024-06-06T19:04:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.839170", lon: "12722.596770", latD: "N", lonD: "E", latitude: 0.7639861666666667, longitude: 127.37661283333334, timestamp: "2024-06-06T19:04:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.839170", lon: "12722.596770", latD: "N", lonD: "E", latitude: 0.7639861666666667, longitude: 127.37661283333334, timestamp: "2024-06-06T19:04:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.839170", lon: "12722.596770", latD: "N", lonD: "E", latitude: 0.7639861666666667, longitude: 127.37661283333334, timestamp: "2024-06-06T19:04:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.839170", lon: "12722.596770", latD: "N", lonD: "E", latitude: 0.7639861666666667, longitude: 127.37661283333334, timestamp: "2024-06-06T19:04:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.839186", lon: "12722.596743", latD: "N", lonD: "E", latitude: 0.7639864333333333, longitude: 127.37661238333334, timestamp: "2024-06-06T19:05:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.839186", lon: "12722.596743", latD: "N", lonD: "E", latitude: 0.7639864333333333, longitude: 127.37661238333334, timestamp: "2024-06-06T19:05:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.839186", lon: "12722.596743", latD: "N", lonD: "E", latitude: 0.7639864333333333, longitude: 127.37661238333334, timestamp: "2024-06-06T19:05:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.839186", lon: "12722.596743", latD: "N", lonD: "E", latitude: 0.7639864333333333, longitude: 127.37661238333334, timestamp: "2024-06-06T19:05:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839239", lon: "12722.596710", latD: "N", lonD: "E", latitude: 0.7639873166666666, longitude: 127.37661183333333, timestamp: "2024-06-06T19:05:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839239", lon: "12722.596710", latD: "N", lonD: "E", latitude: 0.7639873166666666, longitude: 127.37661183333333, timestamp: "2024-06-06T19:05:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839239", lon: "12722.596710", latD: "N", lonD: "E", latitude: 0.7639873166666666, longitude: 127.37661183333333, timestamp: "2024-06-06T19:05:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839239", lon: "12722.596710", latD: "N", lonD: "E", latitude: 0.7639873166666666, longitude: 127.37661183333333, timestamp: "2024-06-06T19:05:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839263", lon: "12722.596687", latD: "N", lonD: "E", latitude: 0.7639877166666668, longitude: 127.37661145, timestamp: "2024-06-06T19:05:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839263", lon: "12722.596687", latD: "N", lonD: "E", latitude: 0.7639877166666668, longitude: 127.37661145, timestamp: "2024-06-06T19:05:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839263", lon: "12722.596687", latD: "N", lonD: "E", latitude: 0.7639877166666668, longitude: 127.37661145, timestamp: "2024-06-06T19:05:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839263", lon: "12722.596687", latD: "N", lonD: "E", latitude: 0.7639877166666668, longitude: 127.37661145, timestamp: "2024-06-06T19:05:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839270", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7639878333333333, longitude: 127.37661086666667, timestamp: "2024-06-06T19:06:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839270", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7639878333333333, longitude: 127.37661086666667, timestamp: "2024-06-06T19:06:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839270", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7639878333333333, longitude: 127.37661086666667, timestamp: "2024-06-06T19:06:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839270", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7639878333333333, longitude: 127.37661086666667, timestamp: "2024-06-06T19:06:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839311", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7639885166666667, longitude: 127.37661034999999, timestamp: "2024-06-06T19:06:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839311", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7639885166666667, longitude: 127.37661034999999, timestamp: "2024-06-06T19:06:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839311", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7639885166666667, longitude: 127.37661034999999, timestamp: "2024-06-06T19:06:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839311", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7639885166666667, longitude: 127.37661034999999, timestamp: "2024-06-06T19:06:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839330", lon: "12722.596578", latD: "N", lonD: "E", latitude: 0.7639888333333332, longitude: 127.37660963333333, timestamp: "2024-06-06T19:06:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839330", lon: "12722.596578", latD: "N", lonD: "E", latitude: 0.7639888333333332, longitude: 127.37660963333333, timestamp: "2024-06-06T19:06:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839330", lon: "12722.596578", latD: "N", lonD: "E", latitude: 0.7639888333333332, longitude: 127.37660963333333, timestamp: "2024-06-06T19:06:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.839331", lon: "12722.596555", latD: "N", lonD: "E", latitude: 0.76398885, longitude: 127.37660925, timestamp: "2024-06-06T19:07:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839330", lon: "12722.596578", latD: "N", lonD: "E", latitude: 0.7639888333333332, longitude: 127.37660963333333, timestamp: "2024-06-06T19:06:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.839331", lon: "12722.596555", latD: "N", lonD: "E", latitude: 0.76398885, longitude: 127.37660925, timestamp: "2024-06-06T19:07:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.839331", lon: "12722.596555", latD: "N", lonD: "E", latitude: 0.76398885, longitude: 127.37660925, timestamp: "2024-06-06T19:07:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.839331", lon: "12722.596555", latD: "N", lonD: "E", latitude: 0.76398885, longitude: 127.37660925, timestamp: "2024-06-06T19:07:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.839348", lon: "12722.596546", latD: "N", lonD: "E", latitude: 0.7639891333333334, longitude: 127.3766091, timestamp: "2024-06-06T19:07:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.839348", lon: "12722.596546", latD: "N", lonD: "E", latitude: 0.7639891333333334, longitude: 127.3766091, timestamp: "2024-06-06T19:07:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.839381", lon: "12722.596541", latD: "N", lonD: "E", latitude: 0.7639896833333334, longitude: 127.37660901666666, timestamp: "2024-06-06T19:08:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.839381", lon: "12722.596541", latD: "N", lonD: "E", latitude: 0.7639896833333334, longitude: 127.37660901666666, timestamp: "2024-06-06T19:08:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.839381", lon: "12722.596541", latD: "N", lonD: "E", latitude: 0.7639896833333334, longitude: 127.37660901666666, timestamp: "2024-06-06T19:08:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "8.9", satellites: "09", hdop: "0.6", lat: "0045.839381", lon: "12722.596541", latD: "N", lonD: "E", latitude: 0.7639896833333334, longitude: 127.37660901666666, timestamp: "2024-06-06T19:08:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839368", lon: "12722.596559", latD: "N", lonD: "E", latitude: 0.7639894666666667, longitude: 127.37660931666667, timestamp: "2024-06-06T19:09:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839368", lon: "12722.596559", latD: "N", lonD: "E", latitude: 0.7639894666666667, longitude: 127.37660931666667, timestamp: "2024-06-06T19:09:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839368", lon: "12722.596559", latD: "N", lonD: "E", latitude: 0.7639894666666667, longitude: 127.37660931666667, timestamp: "2024-06-06T19:09:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.839368", lon: "12722.596559", latD: "N", lonD: "E", latitude: 0.7639894666666667, longitude: 127.37660931666667, timestamp: "2024-06-06T19:09:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839342", lon: "12722.596579", latD: "N", lonD: "E", latitude: 0.7639890333333333, longitude: 127.37660965, timestamp: "2024-06-06T19:09:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839342", lon: "12722.596579", latD: "N", lonD: "E", latitude: 0.7639890333333333, longitude: 127.37660965, timestamp: "2024-06-06T19:09:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839342", lon: "12722.596579", latD: "N", lonD: "E", latitude: 0.7639890333333333, longitude: 127.37660965, timestamp: "2024-06-06T19:09:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.839353", lon: "12722.596596", latD: "N", lonD: "E", latitude: 0.7639892166666667, longitude: 127.37660993333333, timestamp: "2024-06-06T19:09:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.839342", lon: "12722.596579", latD: "N", lonD: "E", latitude: 0.7639890333333333, longitude: 127.37660965, timestamp: "2024-06-06T19:09:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.839353", lon: "12722.596596", latD: "N", lonD: "E", latitude: 0.7639892166666667, longitude: 127.37660993333333, timestamp: "2024-06-06T19:09:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.839353", lon: "12722.596596", latD: "N", lonD: "E", latitude: 0.7639892166666667, longitude: 127.37660993333333, timestamp: "2024-06-06T19:09:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.839353", lon: "12722.596596", latD: "N", lonD: "E", latitude: 0.7639892166666667, longitude: 127.37660993333333, timestamp: "2024-06-06T19:09:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "09", hdop: "0.6", lat: "0045.839363", lon: "12722.596626", latD: "N", lonD: "E", latitude: 0.7639893833333333, longitude: 127.37661043333334, timestamp: "2024-06-06T19:10:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "09", hdop: "0.6", lat: "0045.839363", lon: "12722.596626", latD: "N", lonD: "E", latitude: 0.7639893833333333, longitude: 127.37661043333334, timestamp: "2024-06-06T19:10:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "09", hdop: "0.6", lat: "0045.839363", lon: "12722.596626", latD: "N", lonD: "E", latitude: 0.7639893833333333, longitude: 127.37661043333334, timestamp: "2024-06-06T19:10:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "09", hdop: "0.6", lat: "0045.839363", lon: "12722.596626", latD: "N", lonD: "E", latitude: 0.7639893833333333, longitude: 127.37661043333334, timestamp: "2024-06-06T19:10:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.839351", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7639891833333333, longitude: 127.37661086666667, timestamp: "2024-06-06T19:10:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.839351", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7639891833333333, longitude: 127.37661086666667, timestamp: "2024-06-06T19:10:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.839351", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7639891833333333, longitude: 127.37661086666667, timestamp: "2024-06-06T19:10:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "10", hdop: "0.6", lat: "0045.839351", lon: "12722.596652", latD: "N", lonD: "E", latitude: 0.7639891833333333, longitude: 127.37661086666667, timestamp: "2024-06-06T19:10:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839350", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.7639891666666667, longitude: 127.37661128333333, timestamp: "2024-06-06T19:10:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839350", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.7639891666666667, longitude: 127.37661128333333, timestamp: "2024-06-06T19:10:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839350", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.7639891666666667, longitude: 127.37661128333333, timestamp: "2024-06-06T19:10:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839350", lon: "12722.596677", latD: "N", lonD: "E", latitude: 0.7639891666666667, longitude: 127.37661128333333, timestamp: "2024-06-06T19:10:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.839336", lon: "12722.596678", latD: "N", lonD: "E", latitude: 0.7639889333333334, longitude: 127.3766113, timestamp: "2024-06-06T19:11:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.839336", lon: "12722.596678", latD: "N", lonD: "E", latitude: 0.7639889333333334, longitude: 127.3766113, timestamp: "2024-06-06T19:11:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.839336", lon: "12722.596678", latD: "N", lonD: "E", latitude: 0.7639889333333334, longitude: 127.3766113, timestamp: "2024-06-06T19:11:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839319", lon: "12722.596665", latD: "N", lonD: "E", latitude: 0.76398865, longitude: 127.37661108333333, timestamp: "2024-06-06T19:11:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.6", lat: "0045.839336", lon: "12722.596678", latD: "N", lonD: "E", latitude: 0.7639889333333334, longitude: 127.3766113, timestamp: "2024-06-06T19:11:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839319", lon: "12722.596665", latD: "N", lonD: "E", latitude: 0.76398865, longitude: 127.37661108333333, timestamp: "2024-06-06T19:11:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839319", lon: "12722.596665", latD: "N", lonD: "E", latitude: 0.76398865, longitude: 127.37661108333333, timestamp: "2024-06-06T19:11:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839319", lon: "12722.596665", latD: "N", lonD: "E", latitude: 0.76398865, longitude: 127.37661108333333, timestamp: "2024-06-06T19:11:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839307", lon: "12722.596673", latD: "N", lonD: "E", latitude: 0.7639884499999999, longitude: 127.37661121666666, timestamp: "2024-06-06T19:11:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839307", lon: "12722.596673", latD: "N", lonD: "E", latitude: 0.7639884499999999, longitude: 127.37661121666666, timestamp: "2024-06-06T19:11:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839307", lon: "12722.596673", latD: "N", lonD: "E", latitude: 0.7639884499999999, longitude: 127.37661121666666, timestamp: "2024-06-06T19:11:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839307", lon: "12722.596673", latD: "N", lonD: "E", latitude: 0.7639884499999999, longitude: 127.37661121666666, timestamp: "2024-06-06T19:11:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839289", lon: "12722.596706", latD: "N", lonD: "E", latitude: 0.76398815, longitude: 127.37661176666667, timestamp: "2024-06-06T19:12:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839289", lon: "12722.596706", latD: "N", lonD: "E", latitude: 0.76398815, longitude: 127.37661176666667, timestamp: "2024-06-06T19:12:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839289", lon: "12722.596706", latD: "N", lonD: "E", latitude: 0.76398815, longitude: 127.37661176666667, timestamp: "2024-06-06T19:12:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839289", lon: "12722.596706", latD: "N", lonD: "E", latitude: 0.76398815, longitude: 127.37661176666667, timestamp: "2024-06-06T19:12:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839274", lon: "12722.596721", latD: "N", lonD: "E", latitude: 0.7639879, longitude: 127.37661201666667, timestamp: "2024-06-06T19:12:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839274", lon: "12722.596721", latD: "N", lonD: "E", latitude: 0.7639879, longitude: 127.37661201666667, timestamp: "2024-06-06T19:12:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839274", lon: "12722.596721", latD: "N", lonD: "E", latitude: 0.7639879, longitude: 127.37661201666667, timestamp: "2024-06-06T19:12:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839266", lon: "12722.596731", latD: "N", lonD: "E", latitude: 0.7639877666666667, longitude: 127.37661218333334, timestamp: "2024-06-06T19:12:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839274", lon: "12722.596721", latD: "N", lonD: "E", latitude: 0.7639879, longitude: 127.37661201666667, timestamp: "2024-06-06T19:12:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839266", lon: "12722.596731", latD: "N", lonD: "E", latitude: 0.7639877666666667, longitude: 127.37661218333334, timestamp: "2024-06-06T19:12:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839266", lon: "12722.596731", latD: "N", lonD: "E", latitude: 0.7639877666666667, longitude: 127.37661218333334, timestamp: "2024-06-06T19:12:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839266", lon: "12722.596731", latD: "N", lonD: "E", latitude: 0.7639877666666667, longitude: 127.37661218333334, timestamp: "2024-06-06T19:12:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839262", lon: "12722.596748", latD: "N", lonD: "E", latitude: 0.7639876999999999, longitude: 127.37661246666667, timestamp: "2024-06-06T19:13:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839262", lon: "12722.596748", latD: "N", lonD: "E", latitude: 0.7639876999999999, longitude: 127.37661246666667, timestamp: "2024-06-06T19:13:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839262", lon: "12722.596748", latD: "N", lonD: "E", latitude: 0.7639876999999999, longitude: 127.37661246666667, timestamp: "2024-06-06T19:13:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839262", lon: "12722.596748", latD: "N", lonD: "E", latitude: 0.7639876999999999, longitude: 127.37661246666667, timestamp: "2024-06-06T19:13:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839252", lon: "12722.596756", latD: "N", lonD: "E", latitude: 0.7639875333333334, longitude: 127.3766126, timestamp: "2024-06-06T19:13:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839252", lon: "12722.596756", latD: "N", lonD: "E", latitude: 0.7639875333333334, longitude: 127.3766126, timestamp: "2024-06-06T19:13:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839252", lon: "12722.596756", latD: "N", lonD: "E", latitude: 0.7639875333333334, longitude: 127.3766126, timestamp: "2024-06-06T19:13:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839252", lon: "12722.596756", latD: "N", lonD: "E", latitude: 0.7639875333333334, longitude: 127.3766126, timestamp: "2024-06-06T19:13:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839245", lon: "12722.596768", latD: "N", lonD: "E", latitude: 0.7639874166666666, longitude: 127.3766128, timestamp: "2024-06-06T19:13:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839245", lon: "12722.596768", latD: "N", lonD: "E", latitude: 0.7639874166666666, longitude: 127.3766128, timestamp: "2024-06-06T19:13:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839245", lon: "12722.596768", latD: "N", lonD: "E", latitude: 0.7639874166666666, longitude: 127.3766128, timestamp: "2024-06-06T19:13:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839245", lon: "12722.596768", latD: "N", lonD: "E", latitude: 0.7639874166666666, longitude: 127.3766128, timestamp: "2024-06-06T19:13:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839247", lon: "12722.596780", latD: "N", lonD: "E", latitude: 0.76398745, longitude: 127.376613, timestamp: "2024-06-06T19:14:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839247", lon: "12722.596780", latD: "N", lonD: "E", latitude: 0.76398745, longitude: 127.376613, timestamp: "2024-06-06T19:14:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839247", lon: "12722.596780", latD: "N", lonD: "E", latitude: 0.76398745, longitude: 127.376613, timestamp: "2024-06-06T19:14:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839247", lon: "12722.596780", latD: "N", lonD: "E", latitude: 0.76398745, longitude: 127.376613, timestamp: "2024-06-06T19:14:07.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839237", lon: "12722.596773", latD: "N", lonD: "E", latitude: 0.7639872833333333, longitude: 127.37661288333334, timestamp: "2024-06-06T19:14:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839237", lon: "12722.596773", latD: "N", lonD: "E", latitude: 0.7639872833333333, longitude: 127.37661288333334, timestamp: "2024-06-06T19:14:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839237", lon: "12722.596773", latD: "N", lonD: "E", latitude: 0.7639872833333333, longitude: 127.37661288333334, timestamp: "2024-06-06T19:14:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839207", lon: "12722.596762", latD: "N", lonD: "E", latitude: 0.7639867833333334, longitude: 127.3766127, timestamp: "2024-06-06T19:14:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839237", lon: "12722.596773", latD: "N", lonD: "E", latitude: 0.7639872833333333, longitude: 127.37661288333334, timestamp: "2024-06-06T19:14:27.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839207", lon: "12722.596762", latD: "N", lonD: "E", latitude: 0.7639867833333334, longitude: 127.3766127, timestamp: "2024-06-06T19:14:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839207", lon: "12722.596762", latD: "N", lonD: "E", latitude: 0.7639867833333334, longitude: 127.3766127, timestamp: "2024-06-06T19:14:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839207", lon: "12722.596762", latD: "N", lonD: "E", latitude: 0.7639867833333334, longitude: 127.3766127, timestamp: "2024-06-06T19:14:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "08", hdop: "0.6", lat: "0045.839198", lon: "12722.596781", latD: "N", lonD: "E", latitude: 0.7639866333333334, longitude: 127.37661301666667, timestamp: "2024-06-06T19:15:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "08", hdop: "0.6", lat: "0045.839198", lon: "12722.596781", latD: "N", lonD: "E", latitude: 0.7639866333333334, longitude: 127.37661301666667, timestamp: "2024-06-06T19:15:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "08", hdop: "0.6", lat: "0045.839198", lon: "12722.596781", latD: "N", lonD: "E", latitude: 0.7639866333333334, longitude: 127.37661301666667, timestamp: "2024-06-06T19:15:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "08", hdop: "0.6", lat: "0045.839198", lon: "12722.596781", latD: "N", lonD: "E", latitude: 0.7639866333333334, longitude: 127.37661301666667, timestamp: "2024-06-06T19:15:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839194", lon: "12722.596779", latD: "N", lonD: "E", latitude: 0.7639865666666666, longitude: 127.37661298333333, timestamp: "2024-06-06T19:15:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839194", lon: "12722.596779", latD: "N", lonD: "E", latitude: 0.7639865666666666, longitude: 127.37661298333333, timestamp: "2024-06-06T19:15:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839194", lon: "12722.596779", latD: "N", lonD: "E", latitude: 0.7639865666666666, longitude: 127.37661298333333, timestamp: "2024-06-06T19:15:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839194", lon: "12722.596779", latD: "N", lonD: "E", latitude: 0.7639865666666666, longitude: 127.37661298333333, timestamp: "2024-06-06T19:15:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839201", lon: "12722.596799", latD: "N", lonD: "E", latitude: 0.7639866833333334, longitude: 127.37661331666666, timestamp: "2024-06-06T19:15:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839201", lon: "12722.596799", latD: "N", lonD: "E", latitude: 0.7639866833333334, longitude: 127.37661331666666, timestamp: "2024-06-06T19:15:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839201", lon: "12722.596799", latD: "N", lonD: "E", latitude: 0.7639866833333334, longitude: 127.37661331666666, timestamp: "2024-06-06T19:15:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839201", lon: "12722.596799", latD: "N", lonD: "E", latitude: 0.7639866833333334, longitude: 127.37661331666666, timestamp: "2024-06-06T19:15:47.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839184", lon: "12722.596802", latD: "N", lonD: "E", latitude: 0.7639864000000001, longitude: 127.37661336666666, timestamp: "2024-06-06T19:16:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839184", lon: "12722.596802", latD: "N", lonD: "E", latitude: 0.7639864000000001, longitude: 127.37661336666666, timestamp: "2024-06-06T19:16:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839184", lon: "12722.596802", latD: "N", lonD: "E", latitude: 0.7639864000000001, longitude: 127.37661336666666, timestamp: "2024-06-06T19:16:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839184", lon: "12722.596802", latD: "N", lonD: "E", latitude: 0.7639864000000001, longitude: 127.37661336666666, timestamp: "2024-06-06T19:16:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839176", lon: "12722.596831", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661385, timestamp: "2024-06-06T19:16:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839176", lon: "12722.596831", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661385, timestamp: "2024-06-06T19:16:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839176", lon: "12722.596831", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661385, timestamp: "2024-06-06T19:16:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839176", lon: "12722.596831", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661385, timestamp: "2024-06-06T19:16:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839168", lon: "12722.596812", latD: "N", lonD: "E", latitude: 0.7639861333333333, longitude: 127.37661353333333, timestamp: "2024-06-06T19:16:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839168", lon: "12722.596812", latD: "N", lonD: "E", latitude: 0.7639861333333333, longitude: 127.37661353333333, timestamp: "2024-06-06T19:16:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839168", lon: "12722.596812", latD: "N", lonD: "E", latitude: 0.7639861333333333, longitude: 127.37661353333333, timestamp: "2024-06-06T19:16:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.839168", lon: "12722.596812", latD: "N", lonD: "E", latitude: 0.7639861333333333, longitude: 127.37661353333333, timestamp: "2024-06-06T19:16:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839140", lon: "12722.596794", latD: "N", lonD: "E", latitude: 0.7639856666666667, longitude: 127.37661323333333, timestamp: "2024-06-06T19:17:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839140", lon: "12722.596794", latD: "N", lonD: "E", latitude: 0.7639856666666667, longitude: 127.37661323333333, timestamp: "2024-06-06T19:17:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839140", lon: "12722.596794", latD: "N", lonD: "E", latitude: 0.7639856666666667, longitude: 127.37661323333333, timestamp: "2024-06-06T19:17:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839140", lon: "12722.596794", latD: "N", lonD: "E", latitude: 0.7639856666666667, longitude: 127.37661323333333, timestamp: "2024-06-06T19:17:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839128", lon: "12722.596804", latD: "N", lonD: "E", latitude: 0.7639854666666667, longitude: 127.3766134, timestamp: "2024-06-06T19:17:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839128", lon: "12722.596804", latD: "N", lonD: "E", latitude: 0.7639854666666667, longitude: 127.3766134, timestamp: "2024-06-06T19:17:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839128", lon: "12722.596804", latD: "N", lonD: "E", latitude: 0.7639854666666667, longitude: 127.3766134, timestamp: "2024-06-06T19:17:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.839128", lon: "12722.596804", latD: "N", lonD: "E", latitude: 0.7639854666666667, longitude: 127.3766134, timestamp: "2024-06-06T19:17:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839134", lon: "12722.596818", latD: "N", lonD: "E", latitude: 0.7639855666666667, longitude: 127.37661363333333, timestamp: "2024-06-06T19:17:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839134", lon: "12722.596818", latD: "N", lonD: "E", latitude: 0.7639855666666667, longitude: 127.37661363333333, timestamp: "2024-06-06T19:17:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839134", lon: "12722.596818", latD: "N", lonD: "E", latitude: 0.7639855666666667, longitude: 127.37661363333333, timestamp: "2024-06-06T19:17:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839134", lon: "12722.596818", latD: "N", lonD: "E", latitude: 0.7639855666666667, longitude: 127.37661363333333, timestamp: "2024-06-06T19:17:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839144", lon: "12722.596820", latD: "N", lonD: "E", latitude: 0.7639857333333333, longitude: 127.37661366666667, timestamp: "2024-06-06T19:18:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839144", lon: "12722.596820", latD: "N", lonD: "E", latitude: 0.7639857333333333, longitude: 127.37661366666667, timestamp: "2024-06-06T19:18:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839144", lon: "12722.596820", latD: "N", lonD: "E", latitude: 0.7639857333333333, longitude: 127.37661366666667, timestamp: "2024-06-06T19:18:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839144", lon: "12722.596820", latD: "N", lonD: "E", latitude: 0.7639857333333333, longitude: 127.37661366666667, timestamp: "2024-06-06T19:18:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839160", lon: "12722.596815", latD: "N", lonD: "E", latitude: 0.7639859999999999, longitude: 127.37661358333334, timestamp: "2024-06-06T19:18:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839160", lon: "12722.596815", latD: "N", lonD: "E", latitude: 0.7639859999999999, longitude: 127.37661358333334, timestamp: "2024-06-06T19:18:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839160", lon: "12722.596815", latD: "N", lonD: "E", latitude: 0.7639859999999999, longitude: 127.37661358333334, timestamp: "2024-06-06T19:18:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.839160", lon: "12722.596815", latD: "N", lonD: "E", latitude: 0.7639859999999999, longitude: 127.37661358333334, timestamp: "2024-06-06T19:18:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839177", lon: "12722.596812", latD: "N", lonD: "E", latitude: 0.7639862833333333, longitude: 127.37661353333333, timestamp: "2024-06-06T19:18:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839177", lon: "12722.596812", latD: "N", lonD: "E", latitude: 0.7639862833333333, longitude: 127.37661353333333, timestamp: "2024-06-06T19:18:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839177", lon: "12722.596812", latD: "N", lonD: "E", latitude: 0.7639862833333333, longitude: 127.37661353333333, timestamp: "2024-06-06T19:18:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839177", lon: "12722.596812", latD: "N", lonD: "E", latitude: 0.7639862833333333, longitude: 127.37661353333333, timestamp: "2024-06-06T19:18:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839180", lon: "12722.596791", latD: "N", lonD: "E", latitude: 0.7639863333333333, longitude: 127.37661318333333, timestamp: "2024-06-06T19:19:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839180", lon: "12722.596791", latD: "N", lonD: "E", latitude: 0.7639863333333333, longitude: 127.37661318333333, timestamp: "2024-06-06T19:19:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839180", lon: "12722.596791", latD: "N", lonD: "E", latitude: 0.7639863333333333, longitude: 127.37661318333333, timestamp: "2024-06-06T19:19:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839180", lon: "12722.596791", latD: "N", lonD: "E", latitude: 0.7639863333333333, longitude: 127.37661318333333, timestamp: "2024-06-06T19:19:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.7", lat: "0045.839176", lon: "12722.596757", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661261666666, timestamp: "2024-06-06T19:19:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.7", lat: "0045.839176", lon: "12722.596757", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661261666666, timestamp: "2024-06-06T19:19:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.7", lat: "0045.839176", lon: "12722.596757", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661261666666, timestamp: "2024-06-06T19:19:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.4", satellites: "09", hdop: "0.7", lat: "0045.839176", lon: "12722.596757", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661261666666, timestamp: "2024-06-06T19:19:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839161", lon: "12722.596730", latD: "N", lonD: "E", latitude: 0.7639860166666667, longitude: 127.37661216666666, timestamp: "2024-06-06T19:19:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839161", lon: "12722.596730", latD: "N", lonD: "E", latitude: 0.7639860166666667, longitude: 127.37661216666666, timestamp: "2024-06-06T19:19:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839161", lon: "12722.596730", latD: "N", lonD: "E", latitude: 0.7639860166666667, longitude: 127.37661216666666, timestamp: "2024-06-06T19:19:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839161", lon: "12722.596730", latD: "N", lonD: "E", latitude: 0.7639860166666667, longitude: 127.37661216666666, timestamp: "2024-06-06T19:19:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839166", lon: "12722.596698", latD: "N", lonD: "E", latitude: 0.7639861, longitude: 127.37661163333334, timestamp: "2024-06-06T19:20:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839166", lon: "12722.596698", latD: "N", lonD: "E", latitude: 0.7639861, longitude: 127.37661163333334, timestamp: "2024-06-06T19:20:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839166", lon: "12722.596698", latD: "N", lonD: "E", latitude: 0.7639861, longitude: 127.37661163333334, timestamp: "2024-06-06T19:20:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839166", lon: "12722.596698", latD: "N", lonD: "E", latitude: 0.7639861, longitude: 127.37661163333334, timestamp: "2024-06-06T19:20:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839167", lon: "12722.596683", latD: "N", lonD: "E", latitude: 0.7639861166666667, longitude: 127.37661138333333, timestamp: "2024-06-06T19:20:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839167", lon: "12722.596683", latD: "N", lonD: "E", latitude: 0.7639861166666667, longitude: 127.37661138333333, timestamp: "2024-06-06T19:20:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839167", lon: "12722.596683", latD: "N", lonD: "E", latitude: 0.7639861166666667, longitude: 127.37661138333333, timestamp: "2024-06-06T19:20:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "09", hdop: "0.7", lat: "0045.839167", lon: "12722.596683", latD: "N", lonD: "E", latitude: 0.7639861166666667, longitude: 127.37661138333333, timestamp: "2024-06-06T19:20:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.839176", lon: "12722.596663", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661105, timestamp: "2024-06-06T19:20:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.839176", lon: "12722.596663", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661105, timestamp: "2024-06-06T19:20:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.839176", lon: "12722.596663", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661105, timestamp: "2024-06-06T19:20:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.839176", lon: "12722.596663", latD: "N", lonD: "E", latitude: 0.7639862666666667, longitude: 127.37661105, timestamp: "2024-06-06T19:20:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "07", hdop: "0.7", lat: "0045.839181", lon: "12722.596646", latD: "N", lonD: "E", latitude: 0.76398635, longitude: 127.37661076666667, timestamp: "2024-06-06T19:21:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "07", hdop: "0.7", lat: "0045.839181", lon: "12722.596646", latD: "N", lonD: "E", latitude: 0.76398635, longitude: 127.37661076666667, timestamp: "2024-06-06T19:21:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "07", hdop: "0.7", lat: "0045.839181", lon: "12722.596646", latD: "N", lonD: "E", latitude: 0.76398635, longitude: 127.37661076666667, timestamp: "2024-06-06T19:21:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "07", hdop: "0.7", lat: "0045.839181", lon: "12722.596646", latD: "N", lonD: "E", latitude: 0.76398635, longitude: 127.37661076666667, timestamp: "2024-06-06T19:21:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.839189", lon: "12722.596640", latD: "N", lonD: "E", latitude: 0.7639864833333333, longitude: 127.37661066666666, timestamp: "2024-06-06T19:21:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.839189", lon: "12722.596640", latD: "N", lonD: "E", latitude: 0.7639864833333333, longitude: 127.37661066666666, timestamp: "2024-06-06T19:21:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.839189", lon: "12722.596640", latD: "N", lonD: "E", latitude: 0.7639864833333333, longitude: 127.37661066666666, timestamp: "2024-06-06T19:21:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.3", satellites: "08", hdop: "0.7", lat: "0045.839189", lon: "12722.596640", latD: "N", lonD: "E", latitude: 0.7639864833333333, longitude: 127.37661066666666, timestamp: "2024-06-06T19:21:28.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "08", hdop: "0.6", lat: "0045.839196", lon: "12722.596630", latD: "N", lonD: "E", latitude: 0.7639866000000001, longitude: 127.3766105, timestamp: "2024-06-06T19:21:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "08", hdop: "0.6", lat: "0045.839196", lon: "12722.596630", latD: "N", lonD: "E", latitude: 0.7639866000000001, longitude: 127.3766105, timestamp: "2024-06-06T19:21:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "08", hdop: "0.6", lat: "0045.839196", lon: "12722.596630", latD: "N", lonD: "E", latitude: 0.7639866000000001, longitude: 127.3766105, timestamp: "2024-06-06T19:21:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "08", hdop: "0.6", lat: "0045.839196", lon: "12722.596630", latD: "N", lonD: "E", latitude: 0.7639866000000001, longitude: 127.3766105, timestamp: "2024-06-06T19:21:48.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.839192", lon: "12722.596624", latD: "N", lonD: "E", latitude: 0.7639865333333333, longitude: 127.3766104, timestamp: "2024-06-06T19:22:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.839192", lon: "12722.596624", latD: "N", lonD: "E", latitude: 0.7639865333333333, longitude: 127.3766104, timestamp: "2024-06-06T19:22:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.839192", lon: "12722.596624", latD: "N", lonD: "E", latitude: 0.7639865333333333, longitude: 127.3766104, timestamp: "2024-06-06T19:22:08.000Z" },
        { speed: "0.0", course: "139.7", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.839192", lon: "12722.596624", latD: "N", lonD: "E", latitude: 0.7639865333333333, longitude: 127.3766104, timestamp: "2024-06-06T19:22:08.000Z" },
        { speed: "0.0", course: "144.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.839286", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7639881000000001, longitude: 127.37661136666667, timestamp: "2024-06-06T19:22:28.000Z" },
        { speed: "0.0", course: "144.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.839286", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7639881000000001, longitude: 127.37661136666667, timestamp: "2024-06-06T19:22:28.000Z" },
        { speed: "0.0", course: "144.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.839286", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7639881000000001, longitude: 127.37661136666667, timestamp: "2024-06-06T19:22:28.000Z" },
        { speed: "0.0", course: "144.5", altitude: "7.7", satellites: "09", hdop: "0.6", lat: "0045.839286", lon: "12722.596682", latD: "N", lonD: "E", latitude: 0.7639881000000001, longitude: 127.37661136666667, timestamp: "2024-06-06T19:22:28.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.3", satellites: "10", hdop: "0.6", lat: "0045.839107", lon: "12722.597163", latD: "N", lonD: "E", latitude: 0.7639851166666667, longitude: 127.37661938333333, timestamp: "2024-06-06T19:22:48.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.3", satellites: "10", hdop: "0.6", lat: "0045.839107", lon: "12722.597163", latD: "N", lonD: "E", latitude: 0.7639851166666667, longitude: 127.37661938333333, timestamp: "2024-06-06T19:22:48.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.3", satellites: "10", hdop: "0.6", lat: "0045.839107", lon: "12722.597163", latD: "N", lonD: "E", latitude: 0.7639851166666667, longitude: 127.37661938333333, timestamp: "2024-06-06T19:22:48.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.3", satellites: "10", hdop: "0.6", lat: "0045.839107", lon: "12722.597163", latD: "N", lonD: "E", latitude: 0.7639851166666667, longitude: 127.37661938333333, timestamp: "2024-06-06T19:22:48.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.839119", lon: "12722.597296", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.3766216, timestamp: "2024-06-06T19:23:08.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.839119", lon: "12722.597296", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.3766216, timestamp: "2024-06-06T19:23:08.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.839119", lon: "12722.597296", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.3766216, timestamp: "2024-06-06T19:23:08.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.839119", lon: "12722.597296", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.3766216, timestamp: "2024-06-06T19:23:08.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839119", lon: "12722.597305", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.37662175, timestamp: "2024-06-06T19:23:28.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839119", lon: "12722.597305", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.37662175, timestamp: "2024-06-06T19:23:28.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839119", lon: "12722.597305", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.37662175, timestamp: "2024-06-06T19:23:28.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839119", lon: "12722.597305", latD: "N", lonD: "E", latitude: 0.7639853166666666, longitude: 127.37662175, timestamp: "2024-06-06T19:23:28.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839040", lon: "12722.597308", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.3766218, timestamp: "2024-06-06T19:23:48.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839040", lon: "12722.597308", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.3766218, timestamp: "2024-06-06T19:23:48.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839040", lon: "12722.597308", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.3766218, timestamp: "2024-06-06T19:23:48.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839040", lon: "12722.597308", latD: "N", lonD: "E", latitude: 0.763984, longitude: 127.3766218, timestamp: "2024-06-06T19:23:48.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839022", lon: "12722.597302", latD: "N", lonD: "E", latitude: 0.7639837, longitude: 127.3766217, timestamp: "2024-06-06T19:24:08.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839022", lon: "12722.597302", latD: "N", lonD: "E", latitude: 0.7639837, longitude: 127.3766217, timestamp: "2024-06-06T19:24:08.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839022", lon: "12722.597302", latD: "N", lonD: "E", latitude: 0.7639837, longitude: 127.3766217, timestamp: "2024-06-06T19:24:08.000Z" },
        { speed: "0.0", course: "316.0", altitude: "6.7", satellites: "09", hdop: "0.7", lat: "0045.839022", lon: "12722.597302", latD: "N", lonD: "E", latitude: 0.7639837, longitude: 127.3766217, timestamp: "2024-06-06T19:24:08.000Z" },
        { speed: "0.0", course: "245.6", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.838459", lon: "12722.598416", latD: "N", lonD: "E", latitude: 0.7639743166666667, longitude: 127.37664026666667, timestamp: "2024-06-06T19:24:28.000Z" },
        { speed: "0.0", course: "245.6", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.838459", lon: "12722.598416", latD: "N", lonD: "E", latitude: 0.7639743166666667, longitude: 127.37664026666667, timestamp: "2024-06-06T19:24:28.000Z" },
        { speed: "0.0", course: "245.6", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.838459", lon: "12722.598416", latD: "N", lonD: "E", latitude: 0.7639743166666667, longitude: 127.37664026666667, timestamp: "2024-06-06T19:24:28.000Z" },
        { speed: "0.0", course: "245.6", altitude: "8.1", satellites: "09", hdop: "0.6", lat: "0045.838459", lon: "12722.598416", latD: "N", lonD: "E", latitude: 0.7639743166666667, longitude: 127.37664026666667, timestamp: "2024-06-06T19:24:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.4", satellites: "09", hdop: "0.6", lat: "0045.839072", lon: "12722.598505", latD: "N", lonD: "E", latitude: 0.7639845333333334, longitude: 127.37664175, timestamp: "2024-06-06T19:24:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.4", satellites: "09", hdop: "0.6", lat: "0045.839072", lon: "12722.598505", latD: "N", lonD: "E", latitude: 0.7639845333333334, longitude: 127.37664175, timestamp: "2024-06-06T19:24:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.4", satellites: "09", hdop: "0.6", lat: "0045.839072", lon: "12722.598505", latD: "N", lonD: "E", latitude: 0.7639845333333334, longitude: 127.37664175, timestamp: "2024-06-06T19:24:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.4", satellites: "09", hdop: "0.6", lat: "0045.839072", lon: "12722.598505", latD: "N", lonD: "E", latitude: 0.7639845333333334, longitude: 127.37664175, timestamp: "2024-06-06T19:24:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "08", hdop: "0.7", lat: "0045.839067", lon: "12722.598212", latD: "N", lonD: "E", latitude: 0.76398445, longitude: 127.37663686666667, timestamp: "2024-06-06T19:25:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "08", hdop: "0.7", lat: "0045.839067", lon: "12722.598212", latD: "N", lonD: "E", latitude: 0.76398445, longitude: 127.37663686666667, timestamp: "2024-06-06T19:25:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "08", hdop: "0.7", lat: "0045.839067", lon: "12722.598212", latD: "N", lonD: "E", latitude: 0.76398445, longitude: 127.37663686666667, timestamp: "2024-06-06T19:25:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "09", hdop: "0.7", lat: "0045.839062", lon: "12722.598199", latD: "N", lonD: "E", latitude: 0.7639843666666667, longitude: 127.37663665, timestamp: "2024-06-06T19:25:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "08", hdop: "0.7", lat: "0045.839067", lon: "12722.598212", latD: "N", lonD: "E", latitude: 0.76398445, longitude: 127.37663686666667, timestamp: "2024-06-06T19:25:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "09", hdop: "0.7", lat: "0045.839062", lon: "12722.598199", latD: "N", lonD: "E", latitude: 0.7639843666666667, longitude: 127.37663665, timestamp: "2024-06-06T19:25:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "09", hdop: "0.7", lat: "0045.839062", lon: "12722.598199", latD: "N", lonD: "E", latitude: 0.7639843666666667, longitude: 127.37663665, timestamp: "2024-06-06T19:25:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "09", hdop: "0.6", lat: "0045.839058", lon: "12722.598192", latD: "N", lonD: "E", latitude: 0.7639843000000001, longitude: 127.37663653333334, timestamp: "2024-06-06T19:25:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "09", hdop: "0.7", lat: "0045.839062", lon: "12722.598199", latD: "N", lonD: "E", latitude: 0.7639843666666667, longitude: 127.37663665, timestamp: "2024-06-06T19:25:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "09", hdop: "0.6", lat: "0045.839058", lon: "12722.598192", latD: "N", lonD: "E", latitude: 0.7639843000000001, longitude: 127.37663653333334, timestamp: "2024-06-06T19:25:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "09", hdop: "0.6", lat: "0045.839058", lon: "12722.598192", latD: "N", lonD: "E", latitude: 0.7639843000000001, longitude: 127.37663653333334, timestamp: "2024-06-06T19:25:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "11.0", satellites: "09", hdop: "0.6", lat: "0045.839058", lon: "12722.598192", latD: "N", lonD: "E", latitude: 0.7639843000000001, longitude: 127.37663653333334, timestamp: "2024-06-06T19:25:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.9", satellites: "09", hdop: "0.6", lat: "0045.839055", lon: "12722.598183", latD: "N", lonD: "E", latitude: 0.76398425, longitude: 127.37663638333333, timestamp: "2024-06-06T19:26:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.9", satellites: "09", hdop: "0.6", lat: "0045.839055", lon: "12722.598183", latD: "N", lonD: "E", latitude: 0.76398425, longitude: 127.37663638333333, timestamp: "2024-06-06T19:26:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.9", satellites: "09", hdop: "0.6", lat: "0045.839055", lon: "12722.598183", latD: "N", lonD: "E", latitude: 0.76398425, longitude: 127.37663638333333, timestamp: "2024-06-06T19:26:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.9", satellites: "09", hdop: "0.6", lat: "0045.839055", lon: "12722.598183", latD: "N", lonD: "E", latitude: 0.76398425, longitude: 127.37663638333333, timestamp: "2024-06-06T19:26:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.839048", lon: "12722.598182", latD: "N", lonD: "E", latitude: 0.7639841333333333, longitude: 127.37663636666667, timestamp: "2024-06-06T19:26:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.839048", lon: "12722.598182", latD: "N", lonD: "E", latitude: 0.7639841333333333, longitude: 127.37663636666667, timestamp: "2024-06-06T19:26:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.839048", lon: "12722.598182", latD: "N", lonD: "E", latitude: 0.7639841333333333, longitude: 127.37663636666667, timestamp: "2024-06-06T19:26:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.8", satellites: "09", hdop: "0.6", lat: "0045.839048", lon: "12722.598182", latD: "N", lonD: "E", latitude: 0.7639841333333333, longitude: 127.37663636666667, timestamp: "2024-06-06T19:26:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "09", hdop: "0.6", lat: "0045.839051", lon: "12722.598197", latD: "N", lonD: "E", latitude: 0.7639841833333333, longitude: 127.37663661666667, timestamp: "2024-06-06T19:26:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "09", hdop: "0.6", lat: "0045.839051", lon: "12722.598197", latD: "N", lonD: "E", latitude: 0.7639841833333333, longitude: 127.37663661666667, timestamp: "2024-06-06T19:26:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "09", hdop: "0.6", lat: "0045.839051", lon: "12722.598197", latD: "N", lonD: "E", latitude: 0.7639841833333333, longitude: 127.37663661666667, timestamp: "2024-06-06T19:26:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "09", hdop: "0.6", lat: "0045.839051", lon: "12722.598197", latD: "N", lonD: "E", latitude: 0.7639841833333333, longitude: 127.37663661666667, timestamp: "2024-06-06T19:26:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839053", lon: "12722.598210", latD: "N", lonD: "E", latitude: 0.7639842166666667, longitude: 127.37663683333334, timestamp: "2024-06-06T19:27:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839053", lon: "12722.598210", latD: "N", lonD: "E", latitude: 0.7639842166666667, longitude: 127.37663683333334, timestamp: "2024-06-06T19:27:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839053", lon: "12722.598210", latD: "N", lonD: "E", latitude: 0.7639842166666667, longitude: 127.37663683333334, timestamp: "2024-06-06T19:27:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839053", lon: "12722.598210", latD: "N", lonD: "E", latitude: 0.7639842166666667, longitude: 127.37663683333334, timestamp: "2024-06-06T19:27:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "06", hdop: "0.7", lat: "0045.839052", lon: "12722.598214", latD: "N", lonD: "E", latitude: 0.7639842, longitude: 127.3766369, timestamp: "2024-06-06T19:27:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "06", hdop: "0.7", lat: "0045.839052", lon: "12722.598214", latD: "N", lonD: "E", latitude: 0.7639842, longitude: 127.3766369, timestamp: "2024-06-06T19:27:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "06", hdop: "0.7", lat: "0045.839052", lon: "12722.598214", latD: "N", lonD: "E", latitude: 0.7639842, longitude: 127.3766369, timestamp: "2024-06-06T19:27:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "06", hdop: "0.7", lat: "0045.839052", lon: "12722.598214", latD: "N", lonD: "E", latitude: 0.7639842, longitude: 127.3766369, timestamp: "2024-06-06T19:27:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "07", hdop: "0.7", lat: "0045.839074", lon: "12722.598214", latD: "N", lonD: "E", latitude: 0.7639845666666666, longitude: 127.3766369, timestamp: "2024-06-06T19:27:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "07", hdop: "0.7", lat: "0045.839074", lon: "12722.598214", latD: "N", lonD: "E", latitude: 0.7639845666666666, longitude: 127.3766369, timestamp: "2024-06-06T19:27:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "07", hdop: "0.7", lat: "0045.839074", lon: "12722.598214", latD: "N", lonD: "E", latitude: 0.7639845666666666, longitude: 127.3766369, timestamp: "2024-06-06T19:27:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "07", hdop: "0.7", lat: "0045.839074", lon: "12722.598214", latD: "N", lonD: "E", latitude: 0.7639845666666666, longitude: 127.3766369, timestamp: "2024-06-06T19:27:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "09", hdop: "0.6", lat: "0045.839084", lon: "12722.598211", latD: "N", lonD: "E", latitude: 0.7639847333333333, longitude: 127.37663685, timestamp: "2024-06-06T19:28:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "09", hdop: "0.6", lat: "0045.839084", lon: "12722.598211", latD: "N", lonD: "E", latitude: 0.7639847333333333, longitude: 127.37663685, timestamp: "2024-06-06T19:28:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "09", hdop: "0.6", lat: "0045.839084", lon: "12722.598211", latD: "N", lonD: "E", latitude: 0.7639847333333333, longitude: 127.37663685, timestamp: "2024-06-06T19:28:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839082", lon: "12722.598207", latD: "N", lonD: "E", latitude: 0.7639847, longitude: 127.37663678333334, timestamp: "2024-06-06T19:28:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "09", hdop: "0.6", lat: "0045.839084", lon: "12722.598211", latD: "N", lonD: "E", latitude: 0.7639847333333333, longitude: 127.37663685, timestamp: "2024-06-06T19:28:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839082", lon: "12722.598207", latD: "N", lonD: "E", latitude: 0.7639847, longitude: 127.37663678333334, timestamp: "2024-06-06T19:28:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839082", lon: "12722.598207", latD: "N", lonD: "E", latitude: 0.7639847, longitude: 127.37663678333334, timestamp: "2024-06-06T19:28:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839082", lon: "12722.598207", latD: "N", lonD: "E", latitude: 0.7639847, longitude: 127.37663678333334, timestamp: "2024-06-06T19:28:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "07", hdop: "0.7", lat: "0045.839088", lon: "12722.598193", latD: "N", lonD: "E", latitude: 0.7639847999999999, longitude: 127.37663655, timestamp: "2024-06-06T19:28:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "07", hdop: "0.7", lat: "0045.839088", lon: "12722.598193", latD: "N", lonD: "E", latitude: 0.7639847999999999, longitude: 127.37663655, timestamp: "2024-06-06T19:28:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "07", hdop: "0.7", lat: "0045.839088", lon: "12722.598193", latD: "N", lonD: "E", latitude: 0.7639847999999999, longitude: 127.37663655, timestamp: "2024-06-06T19:28:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "07", hdop: "0.7", lat: "0045.839088", lon: "12722.598193", latD: "N", lonD: "E", latitude: 0.7639847999999999, longitude: 127.37663655, timestamp: "2024-06-06T19:28:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839117", lon: "12722.598192", latD: "N", lonD: "E", latitude: 0.7639852833333334, longitude: 127.37663653333334, timestamp: "2024-06-06T19:29:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839117", lon: "12722.598192", latD: "N", lonD: "E", latitude: 0.7639852833333334, longitude: 127.37663653333334, timestamp: "2024-06-06T19:29:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839117", lon: "12722.598192", latD: "N", lonD: "E", latitude: 0.7639852833333334, longitude: 127.37663653333334, timestamp: "2024-06-06T19:29:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.7", lat: "0045.839117", lon: "12722.598192", latD: "N", lonD: "E", latitude: 0.7639852833333334, longitude: 127.37663653333334, timestamp: "2024-06-06T19:29:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.6", lat: "0045.839132", lon: "12722.598178", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.3766363, timestamp: "2024-06-06T19:29:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.6", lat: "0045.839132", lon: "12722.598178", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.3766363, timestamp: "2024-06-06T19:29:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.6", lat: "0045.839132", lon: "12722.598178", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.3766363, timestamp: "2024-06-06T19:29:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.7", satellites: "08", hdop: "0.6", lat: "0045.839132", lon: "12722.598178", latD: "N", lonD: "E", latitude: 0.7639855333333333, longitude: 127.3766363, timestamp: "2024-06-06T19:29:28.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.839148", lon: "12722.598164", latD: "N", lonD: "E", latitude: 0.7639858, longitude: 127.37663606666666, timestamp: "2024-06-06T19:29:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.839148", lon: "12722.598164", latD: "N", lonD: "E", latitude: 0.7639858, longitude: 127.37663606666666, timestamp: "2024-06-06T19:29:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.839148", lon: "12722.598164", latD: "N", lonD: "E", latitude: 0.7639858, longitude: 127.37663606666666, timestamp: "2024-06-06T19:29:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.839148", lon: "12722.598164", latD: "N", lonD: "E", latitude: 0.7639858, longitude: 127.37663606666666, timestamp: "2024-06-06T19:29:48.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.839168", lon: "12722.598157", latD: "N", lonD: "E", latitude: 0.7639861333333333, longitude: 127.37663595, timestamp: "2024-06-06T19:30:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.839168", lon: "12722.598157", latD: "N", lonD: "E", latitude: 0.7639861333333333, longitude: 127.37663595, timestamp: "2024-06-06T19:30:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.839168", lon: "12722.598157", latD: "N", lonD: "E", latitude: 0.7639861333333333, longitude: 127.37663595, timestamp: "2024-06-06T19:30:08.000Z" },
        { speed: "0.0", course: "173.4", altitude: "10.6", satellites: "09", hdop: "0.6", lat: "0045.839168", lon: "12722.598157", latD: "N", lonD: "E", latitude: 0.7639861333333333, longitude: 127.37663595, timestamp: "2024-06-06T19:30:08.000Z" },
        { speed: "0.0", course: "139.1", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.840986", lon: "12722.598438", latD: "N", lonD: "E", latitude: 0.7640164333333334, longitude: 127.37664063333334, timestamp: "2024-06-06T19:30:28.000Z" },
        { speed: "0.0", course: "139.1", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.840986", lon: "12722.598438", latD: "N", lonD: "E", latitude: 0.7640164333333334, longitude: 127.37664063333334, timestamp: "2024-06-06T19:30:28.000Z" },
        { speed: "0.0", course: "139.1", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.840986", lon: "12722.598438", latD: "N", lonD: "E", latitude: 0.7640164333333334, longitude: 127.37664063333334, timestamp: "2024-06-06T19:30:28.000Z" },
        { speed: "0.0", course: "139.1", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.840986", lon: "12722.598438", latD: "N", lonD: "E", latitude: 0.7640164333333334, longitude: 127.37664063333334, timestamp: "2024-06-06T19:30:28.000Z" },
        { speed: "0.0", course: "115.6", altitude: "9.2", satellites: "09", hdop: "0.6", lat: "0045.842484", lon: "12722.599250", latD: "N", lonD: "E", latitude: 0.7640414, longitude: 127.37665416666667, timestamp: "2024-06-06T19:30:48.000Z" },
        { speed: "0.0", course: "115.6", altitude: "9.2", satellites: "09", hdop: "0.6", lat: "0045.842484", lon: "12722.599250", latD: "N", lonD: "E", latitude: 0.7640414, longitude: 127.37665416666667, timestamp: "2024-06-06T19:30:48.000Z" },
        { speed: "0.0", course: "115.6", altitude: "9.2", satellites: "09", hdop: "0.6", lat: "0045.842484", lon: "12722.599250", latD: "N", lonD: "E", latitude: 0.7640414, longitude: 127.37665416666667, timestamp: "2024-06-06T19:30:48.000Z" },
        { speed: "0.0", course: "115.6", altitude: "9.2", satellites: "09", hdop: "0.6", lat: "0045.842484", lon: "12722.599250", latD: "N", lonD: "E", latitude: 0.7640414, longitude: 127.37665416666667, timestamp: "2024-06-06T19:30:48.000Z" },
        { speed: "0.0", course: "126.3", altitude: "7.5", satellites: "09", hdop: "0.6", lat: "0045.841531", lon: "12722.598845", latD: "N", lonD: "E", latitude: 0.7640255166666667, longitude: 127.37664741666667, timestamp: "2024-06-06T19:31:08.000Z" },
        { speed: "0.0", course: "126.3", altitude: "7.5", satellites: "09", hdop: "0.6", lat: "0045.841531", lon: "12722.598845", latD: "N", lonD: "E", latitude: 0.7640255166666667, longitude: 127.37664741666667, timestamp: "2024-06-06T19:31:08.000Z" },
        { speed: "0.0", course: "126.3", altitude: "7.5", satellites: "09", hdop: "0.6", lat: "0045.841531", lon: "12722.598845", latD: "N", lonD: "E", latitude: 0.7640255166666667, longitude: 127.37664741666667, timestamp: "2024-06-06T19:31:08.000Z" },
        { speed: "0.0", course: "126.3", altitude: "7.5", satellites: "09", hdop: "0.6", lat: "0045.841531", lon: "12722.598845", latD: "N", lonD: "E", latitude: 0.7640255166666667, longitude: 127.37664741666667, timestamp: "2024-06-06T19:31:08.000Z" },
        { speed: "0.0", course: "126.3", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.841017", lon: "12722.598683", latD: "N", lonD: "E", latitude: 0.76401695, longitude: 127.37664471666666, timestamp: "2024-06-06T19:31:28.000Z" },
        { speed: "0.0", course: "126.3", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.841017", lon: "12722.598683", latD: "N", lonD: "E", latitude: 0.76401695, longitude: 127.37664471666666, timestamp: "2024-06-06T19:31:28.000Z" },
        { speed: "0.0", course: "126.3", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.841017", lon: "12722.598683", latD: "N", lonD: "E", latitude: 0.76401695, longitude: 127.37664471666666, timestamp: "2024-06-06T19:31:28.000Z" },
        { speed: "0.0", course: "126.3", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.841017", lon: "12722.598683", latD: "N", lonD: "E", latitude: 0.76401695, longitude: 127.37664471666666, timestamp: "2024-06-06T19:31:28.000Z" },
        { speed: "0.0", course: "137.6", altitude: "5.5", satellites: "09", hdop: "0.6", lat: "0045.841082", lon: "12722.598276", latD: "N", lonD: "E", latitude: 0.7640180333333333, longitude: 127.37663793333333, timestamp: "2024-06-06T19:31:48.000Z" },
        { speed: "0.0", course: "137.6", altitude: "5.5", satellites: "09", hdop: "0.6", lat: "0045.841082", lon: "12722.598276", latD: "N", lonD: "E", latitude: 0.7640180333333333, longitude: 127.37663793333333, timestamp: "2024-06-06T19:31:48.000Z" },
        { speed: "0.0", course: "137.6", altitude: "5.5", satellites: "09", hdop: "0.6", lat: "0045.841082", lon: "12722.598276", latD: "N", lonD: "E", latitude: 0.7640180333333333, longitude: 127.37663793333333, timestamp: "2024-06-06T19:31:48.000Z" },
        { speed: "0.0", course: "137.6", altitude: "5.5", satellites: "09", hdop: "0.6", lat: "0045.841082", lon: "12722.598276", latD: "N", lonD: "E", latitude: 0.7640180333333333, longitude: 127.37663793333333, timestamp: "2024-06-06T19:31:48.000Z" },
        { speed: "0.0", course: "201.5", altitude: "3.1", satellites: "09", hdop: "0.6", lat: "0045.840687", lon: "12722.595894", latD: "N", lonD: "E", latitude: 0.76401145, longitude: 127.37659823333334, timestamp: "2024-06-06T19:32:08.000Z" },
        { speed: "0.0", course: "201.5", altitude: "3.1", satellites: "09", hdop: "0.6", lat: "0045.840687", lon: "12722.595894", latD: "N", lonD: "E", latitude: 0.76401145, longitude: 127.37659823333334, timestamp: "2024-06-06T19:32:08.000Z" },
        { speed: "0.0", course: "201.5", altitude: "3.1", satellites: "09", hdop: "0.6", lat: "0045.840687", lon: "12722.595894", latD: "N", lonD: "E", latitude: 0.76401145, longitude: 127.37659823333334, timestamp: "2024-06-06T19:32:08.000Z" },
        { speed: "0.0", course: "201.5", altitude: "3.1", satellites: "09", hdop: "0.6", lat: "0045.840687", lon: "12722.595894", latD: "N", lonD: "E", latitude: 0.76401145, longitude: 127.37659823333334, timestamp: "2024-06-06T19:32:08.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.8", satellites: "09", hdop: "0.6", lat: "0045.840487", lon: "12722.595338", latD: "N", lonD: "E", latitude: 0.7640081166666667, longitude: 127.37658896666667, timestamp: "2024-06-06T19:32:28.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.8", satellites: "09", hdop: "0.6", lat: "0045.840487", lon: "12722.595338", latD: "N", lonD: "E", latitude: 0.7640081166666667, longitude: 127.37658896666667, timestamp: "2024-06-06T19:32:28.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.8", satellites: "09", hdop: "0.6", lat: "0045.840487", lon: "12722.595338", latD: "N", lonD: "E", latitude: 0.7640081166666667, longitude: 127.37658896666667, timestamp: "2024-06-06T19:32:28.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.8", satellites: "09", hdop: "0.6", lat: "0045.840487", lon: "12722.595338", latD: "N", lonD: "E", latitude: 0.7640081166666667, longitude: 127.37658896666667, timestamp: "2024-06-06T19:32:28.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.840264", lon: "12722.595254", latD: "N", lonD: "E", latitude: 0.7640043999999999, longitude: 127.37658756666667, timestamp: "2024-06-06T19:32:48.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.840264", lon: "12722.595254", latD: "N", lonD: "E", latitude: 0.7640043999999999, longitude: 127.37658756666667, timestamp: "2024-06-06T19:32:48.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.840264", lon: "12722.595254", latD: "N", lonD: "E", latitude: 0.7640043999999999, longitude: 127.37658756666667, timestamp: "2024-06-06T19:32:48.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.4", satellites: "09", hdop: "0.6", lat: "0045.840264", lon: "12722.595254", latD: "N", lonD: "E", latitude: 0.7640043999999999, longitude: 127.37658756666667, timestamp: "2024-06-06T19:32:48.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.4", satellites: "09", hdop: "0.7", lat: "0045.840206", lon: "12722.595251", latD: "N", lonD: "E", latitude: 0.7640034333333333, longitude: 127.37658751666666, timestamp: "2024-06-06T19:33:08.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.4", satellites: "09", hdop: "0.7", lat: "0045.840206", lon: "12722.595251", latD: "N", lonD: "E", latitude: 0.7640034333333333, longitude: 127.37658751666666, timestamp: "2024-06-06T19:33:08.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.4", satellites: "09", hdop: "0.7", lat: "0045.840206", lon: "12722.595251", latD: "N", lonD: "E", latitude: 0.7640034333333333, longitude: 127.37658751666666, timestamp: "2024-06-06T19:33:08.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.2", satellites: "08", hdop: "0.7", lat: "0045.840060", lon: "12722.595241", latD: "N", lonD: "E", latitude: 0.764001, longitude: 127.37658735, timestamp: "2024-06-06T19:33:28.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.4", satellites: "09", hdop: "0.7", lat: "0045.840206", lon: "12722.595251", latD: "N", lonD: "E", latitude: 0.7640034333333333, longitude: 127.37658751666666, timestamp: "2024-06-06T19:33:08.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.2", satellites: "08", hdop: "0.7", lat: "0045.840060", lon: "12722.595241", latD: "N", lonD: "E", latitude: 0.764001, longitude: 127.37658735, timestamp: "2024-06-06T19:33:28.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.2", satellites: "08", hdop: "0.7", lat: "0045.840060", lon: "12722.595241", latD: "N", lonD: "E", latitude: 0.764001, longitude: 127.37658735, timestamp: "2024-06-06T19:33:28.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.2", satellites: "08", hdop: "0.7", lat: "0045.840060", lon: "12722.595241", latD: "N", lonD: "E", latitude: 0.764001, longitude: 127.37658735, timestamp: "2024-06-06T19:33:28.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.840014", lon: "12722.595349", latD: "N", lonD: "E", latitude: 0.7640002333333332, longitude: 127.37658915, timestamp: "2024-06-06T19:33:48.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.840014", lon: "12722.595349", latD: "N", lonD: "E", latitude: 0.7640002333333332, longitude: 127.37658915, timestamp: "2024-06-06T19:33:48.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.840014", lon: "12722.595349", latD: "N", lonD: "E", latitude: 0.7640002333333332, longitude: 127.37658915, timestamp: "2024-06-06T19:33:48.000Z" },
        { speed: "0.0", course: "201.5", altitude: "2.3", satellites: "09", hdop: "0.6", lat: "0045.840014", lon: "12722.595349", latD: "N", lonD: "E", latitude: 0.7640002333333332, longitude: 127.37658915, timestamp: "2024-06-06T19:33:48.000Z" },
        { speed: "0.0", course: "53.6", altitude: "2.0", satellites: "09", hdop: "0.6", lat: "0045.839954", lon: "12722.595553", latD: "N", lonD: "E", latitude: 0.7639992333333333, longitude: 127.37659255, timestamp: "2024-06-06T19:34:08.000Z" },
        { speed: "0.0", course: "53.6", altitude: "2.0", satellites: "09", hdop: "0.6", lat: "0045.839954", lon: "12722.595553", latD: "N", lonD: "E", latitude: 0.7639992333333333, longitude: 127.37659255, timestamp: "2024-06-06T19:34:08.000Z" },
        { speed: "0.0", course: "53.6", altitude: "2.0", satellites: "09", hdop: "0.6", lat: "0045.839954", lon: "12722.595553", latD: "N", lonD: "E", latitude: 0.7639992333333333, longitude: 127.37659255, timestamp: "2024-06-06T19:34:08.000Z" },
        { speed: "0.0", course: "53.6", altitude: "2.0", satellites: "09", hdop: "0.6", lat: "0045.839954", lon: "12722.595553", latD: "N", lonD: "E", latitude: 0.7639992333333333, longitude: 127.37659255, timestamp: "2024-06-06T19:34:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.839911", lon: "12722.592396", latD: "N", lonD: "E", latitude: 0.7639985166666666, longitude: 127.37653993333333, timestamp: "2024-06-06T19:34:29.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.839911", lon: "12722.592396", latD: "N", lonD: "E", latitude: 0.7639985166666666, longitude: 127.37653993333333, timestamp: "2024-06-06T19:34:29.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "08", hdop: "0.7", lat: "0045.839902", lon: "12722.592412", latD: "N", lonD: "E", latitude: 0.7639983666666667, longitude: 127.3765402, timestamp: "2024-06-06T19:34:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.839911", lon: "12722.592396", latD: "N", lonD: "E", latitude: 0.7639985166666666, longitude: 127.37653993333333, timestamp: "2024-06-06T19:34:29.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.839911", lon: "12722.592396", latD: "N", lonD: "E", latitude: 0.7639985166666666, longitude: 127.37653993333333, timestamp: "2024-06-06T19:34:29.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "08", hdop: "0.7", lat: "0045.839902", lon: "12722.592412", latD: "N", lonD: "E", latitude: 0.7639983666666667, longitude: 127.3765402, timestamp: "2024-06-06T19:34:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "08", hdop: "0.7", lat: "0045.839902", lon: "12722.592412", latD: "N", lonD: "E", latitude: 0.7639983666666667, longitude: 127.3765402, timestamp: "2024-06-06T19:34:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "08", hdop: "0.7", lat: "0045.839902", lon: "12722.592412", latD: "N", lonD: "E", latitude: 0.7639983666666667, longitude: 127.3765402, timestamp: "2024-06-06T19:34:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.9", satellites: "08", hdop: "0.6", lat: "0045.839908", lon: "12722.592432", latD: "N", lonD: "E", latitude: 0.7639984666666667, longitude: 127.37654053333333, timestamp: "2024-06-06T19:35:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.9", satellites: "08", hdop: "0.6", lat: "0045.839908", lon: "12722.592432", latD: "N", lonD: "E", latitude: 0.7639984666666667, longitude: 127.37654053333333, timestamp: "2024-06-06T19:35:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.9", satellites: "08", hdop: "0.6", lat: "0045.839908", lon: "12722.592432", latD: "N", lonD: "E", latitude: 0.7639984666666667, longitude: 127.37654053333333, timestamp: "2024-06-06T19:35:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.9", satellites: "08", hdop: "0.6", lat: "0045.839908", lon: "12722.592432", latD: "N", lonD: "E", latitude: 0.7639984666666667, longitude: 127.37654053333333, timestamp: "2024-06-06T19:35:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.8", satellites: "08", hdop: "0.6", lat: "0045.840059", lon: "12722.592508", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.3765418, timestamp: "2024-06-06T19:35:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.8", satellites: "08", hdop: "0.6", lat: "0045.840059", lon: "12722.592508", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.3765418, timestamp: "2024-06-06T19:35:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.8", satellites: "08", hdop: "0.6", lat: "0045.840059", lon: "12722.592508", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.3765418, timestamp: "2024-06-06T19:35:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.840094", lon: "12722.592613", latD: "N", lonD: "E", latitude: 0.7640015666666666, longitude: 127.37654355, timestamp: "2024-06-06T19:35:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.8", satellites: "08", hdop: "0.6", lat: "0045.840059", lon: "12722.592508", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.3765418, timestamp: "2024-06-06T19:35:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.840094", lon: "12722.592613", latD: "N", lonD: "E", latitude: 0.7640015666666666, longitude: 127.37654355, timestamp: "2024-06-06T19:35:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.840094", lon: "12722.592613", latD: "N", lonD: "E", latitude: 0.7640015666666666, longitude: 127.37654355, timestamp: "2024-06-06T19:35:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.840094", lon: "12722.592613", latD: "N", lonD: "E", latitude: 0.7640015666666666, longitude: 127.37654355, timestamp: "2024-06-06T19:35:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.840109", lon: "12722.592688", latD: "N", lonD: "E", latitude: 0.7640018166666667, longitude: 127.3765448, timestamp: "2024-06-06T19:36:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.840109", lon: "12722.592688", latD: "N", lonD: "E", latitude: 0.7640018166666667, longitude: 127.3765448, timestamp: "2024-06-06T19:36:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.840109", lon: "12722.592688", latD: "N", lonD: "E", latitude: 0.7640018166666667, longitude: 127.3765448, timestamp: "2024-06-06T19:36:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840133", lon: "12722.592751", latD: "N", lonD: "E", latitude: 0.7640022166666667, longitude: 127.37654585, timestamp: "2024-06-06T19:36:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.7", satellites: "09", hdop: "0.6", lat: "0045.840109", lon: "12722.592688", latD: "N", lonD: "E", latitude: 0.7640018166666667, longitude: 127.3765448, timestamp: "2024-06-06T19:36:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840133", lon: "12722.592751", latD: "N", lonD: "E", latitude: 0.7640022166666667, longitude: 127.37654585, timestamp: "2024-06-06T19:36:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840133", lon: "12722.592751", latD: "N", lonD: "E", latitude: 0.7640022166666667, longitude: 127.37654585, timestamp: "2024-06-06T19:36:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840133", lon: "12722.592751", latD: "N", lonD: "E", latitude: 0.7640022166666667, longitude: 127.37654585, timestamp: "2024-06-06T19:36:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840137", lon: "12722.592797", latD: "N", lonD: "E", latitude: 0.7640022833333333, longitude: 127.37654661666667, timestamp: "2024-06-06T19:36:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840137", lon: "12722.592797", latD: "N", lonD: "E", latitude: 0.7640022833333333, longitude: 127.37654661666667, timestamp: "2024-06-06T19:36:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840137", lon: "12722.592797", latD: "N", lonD: "E", latitude: 0.7640022833333333, longitude: 127.37654661666667, timestamp: "2024-06-06T19:36:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840137", lon: "12722.592797", latD: "N", lonD: "E", latitude: 0.7640022833333333, longitude: 127.37654661666667, timestamp: "2024-06-06T19:36:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840155", lon: "12722.592870", latD: "N", lonD: "E", latitude: 0.7640025833333334, longitude: 127.37654783333333, timestamp: "2024-06-06T19:37:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840155", lon: "12722.592870", latD: "N", lonD: "E", latitude: 0.7640025833333334, longitude: 127.37654783333333, timestamp: "2024-06-06T19:37:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840155", lon: "12722.592870", latD: "N", lonD: "E", latitude: 0.7640025833333334, longitude: 127.37654783333333, timestamp: "2024-06-06T19:37:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840155", lon: "12722.592870", latD: "N", lonD: "E", latitude: 0.7640025833333334, longitude: 127.37654783333333, timestamp: "2024-06-06T19:37:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "08", hdop: "0.7", lat: "0045.840169", lon: "12722.592932", latD: "N", lonD: "E", latitude: 0.7640028166666667, longitude: 127.37654886666667, timestamp: "2024-06-06T19:37:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "08", hdop: "0.7", lat: "0045.840169", lon: "12722.592932", latD: "N", lonD: "E", latitude: 0.7640028166666667, longitude: 127.37654886666667, timestamp: "2024-06-06T19:37:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "08", hdop: "0.6", lat: "0045.840175", lon: "12722.592984", latD: "N", lonD: "E", latitude: 0.7640029166666668, longitude: 127.37654973333333, timestamp: "2024-06-06T19:37:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "08", hdop: "0.7", lat: "0045.840169", lon: "12722.592932", latD: "N", lonD: "E", latitude: 0.7640028166666667, longitude: 127.37654886666667, timestamp: "2024-06-06T19:37:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "08", hdop: "0.7", lat: "0045.840169", lon: "12722.592932", latD: "N", lonD: "E", latitude: 0.7640028166666667, longitude: 127.37654886666667, timestamp: "2024-06-06T19:37:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "08", hdop: "0.6", lat: "0045.840175", lon: "12722.592984", latD: "N", lonD: "E", latitude: 0.7640029166666668, longitude: 127.37654973333333, timestamp: "2024-06-06T19:37:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "08", hdop: "0.6", lat: "0045.840175", lon: "12722.592984", latD: "N", lonD: "E", latitude: 0.7640029166666668, longitude: 127.37654973333333, timestamp: "2024-06-06T19:37:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "08", hdop: "0.6", lat: "0045.840175", lon: "12722.592984", latD: "N", lonD: "E", latitude: 0.7640029166666668, longitude: 127.37654973333333, timestamp: "2024-06-06T19:37:48.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840200", lon: "12722.593028", latD: "N", lonD: "E", latitude: 0.7640033333333334, longitude: 127.37655046666667, timestamp: "2024-06-06T19:38:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840200", lon: "12722.593028", latD: "N", lonD: "E", latitude: 0.7640033333333334, longitude: 127.37655046666667, timestamp: "2024-06-06T19:38:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840200", lon: "12722.593028", latD: "N", lonD: "E", latitude: 0.7640033333333334, longitude: 127.37655046666667, timestamp: "2024-06-06T19:38:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840200", lon: "12722.593028", latD: "N", lonD: "E", latitude: 0.7640033333333334, longitude: 127.37655046666667, timestamp: "2024-06-06T19:38:08.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840220", lon: "12722.593019", latD: "N", lonD: "E", latitude: 0.7640036666666667, longitude: 127.37655031666667, timestamp: "2024-06-06T19:38:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840220", lon: "12722.593019", latD: "N", lonD: "E", latitude: 0.7640036666666667, longitude: 127.37655031666667, timestamp: "2024-06-06T19:38:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840220", lon: "12722.593019", latD: "N", lonD: "E", latitude: 0.7640036666666667, longitude: 127.37655031666667, timestamp: "2024-06-06T19:38:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840220", lon: "12722.593019", latD: "N", lonD: "E", latitude: 0.7640036666666667, longitude: 127.37655031666667, timestamp: "2024-06-06T19:38:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840224", lon: "12722.593048", latD: "N", lonD: "E", latitude: 0.7640037333333333, longitude: 127.3765508, timestamp: "2024-06-06T19:38:49.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840224", lon: "12722.593048", latD: "N", lonD: "E", latitude: 0.7640037333333333, longitude: 127.3765508, timestamp: "2024-06-06T19:38:49.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840224", lon: "12722.593048", latD: "N", lonD: "E", latitude: 0.7640037333333333, longitude: 127.3765508, timestamp: "2024-06-06T19:38:49.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840224", lon: "12722.593048", latD: "N", lonD: "E", latitude: 0.7640037333333333, longitude: 127.3765508, timestamp: "2024-06-06T19:38:49.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840221", lon: "12722.593085", latD: "N", lonD: "E", latitude: 0.7640036833333333, longitude: 127.37655141666667, timestamp: "2024-06-06T19:39:09.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840221", lon: "12722.593085", latD: "N", lonD: "E", latitude: 0.7640036833333333, longitude: 127.37655141666667, timestamp: "2024-06-06T19:39:09.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840221", lon: "12722.593085", latD: "N", lonD: "E", latitude: 0.7640036833333333, longitude: 127.37655141666667, timestamp: "2024-06-06T19:39:09.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840221", lon: "12722.593085", latD: "N", lonD: "E", latitude: 0.7640036833333333, longitude: 127.37655141666667, timestamp: "2024-06-06T19:39:09.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840222", lon: "12722.593143", latD: "N", lonD: "E", latitude: 0.7640037, longitude: 127.37655238333333, timestamp: "2024-06-06T19:39:29.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840222", lon: "12722.593143", latD: "N", lonD: "E", latitude: 0.7640037, longitude: 127.37655238333333, timestamp: "2024-06-06T19:39:29.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840222", lon: "12722.593143", latD: "N", lonD: "E", latitude: 0.7640037, longitude: 127.37655238333333, timestamp: "2024-06-06T19:39:29.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840222", lon: "12722.593143", latD: "N", lonD: "E", latitude: 0.7640037, longitude: 127.37655238333333, timestamp: "2024-06-06T19:39:29.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840264", lon: "12722.593239", latD: "N", lonD: "E", latitude: 0.7640043999999999, longitude: 127.37655398333334, timestamp: "2024-06-06T19:39:49.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840264", lon: "12722.593239", latD: "N", lonD: "E", latitude: 0.7640043999999999, longitude: 127.37655398333334, timestamp: "2024-06-06T19:39:49.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840264", lon: "12722.593239", latD: "N", lonD: "E", latitude: 0.7640043999999999, longitude: 127.37655398333334, timestamp: "2024-06-06T19:39:49.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.840264", lon: "12722.593239", latD: "N", lonD: "E", latitude: 0.7640043999999999, longitude: 127.37655398333334, timestamp: "2024-06-06T19:39:49.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840255", lon: "12722.593297", latD: "N", lonD: "E", latitude: 0.7640042499999999, longitude: 127.37655495, timestamp: "2024-06-06T19:40:09.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840255", lon: "12722.593297", latD: "N", lonD: "E", latitude: 0.7640042499999999, longitude: 127.37655495, timestamp: "2024-06-06T19:40:09.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840255", lon: "12722.593297", latD: "N", lonD: "E", latitude: 0.7640042499999999, longitude: 127.37655495, timestamp: "2024-06-06T19:40:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.840015", lon: "12722.593014", latD: "N", lonD: "E", latitude: 0.76400025, longitude: 127.37655023333333, timestamp: "2024-06-06T19:40:28.000Z" },
        { speed: "0.0", course: "149.8", altitude: "-0.6", satellites: "09", hdop: "0.6", lat: "0045.840255", lon: "12722.593297", latD: "N", lonD: "E", latitude: 0.7640042499999999, longitude: 127.37655495, timestamp: "2024-06-06T19:40:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.840015", lon: "12722.593014", latD: "N", lonD: "E", latitude: 0.76400025, longitude: 127.37655023333333, timestamp: "2024-06-06T19:40:28.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.840015", lon: "12722.593014", latD: "N", lonD: "E", latitude: 0.76400025, longitude: 127.37655023333333, timestamp: "2024-06-06T19:40:28.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.840015", lon: "12722.593014", latD: "N", lonD: "E", latitude: 0.76400025, longitude: 127.37655023333333, timestamp: "2024-06-06T19:40:28.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839596", lon: "12722.593943", latD: "N", lonD: "E", latitude: 0.7639932666666667, longitude: 127.37656571666666, timestamp: "2024-06-06T19:40:48.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839596", lon: "12722.593943", latD: "N", lonD: "E", latitude: 0.7639932666666667, longitude: 127.37656571666666, timestamp: "2024-06-06T19:40:48.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839596", lon: "12722.593943", latD: "N", lonD: "E", latitude: 0.7639932666666667, longitude: 127.37656571666666, timestamp: "2024-06-06T19:40:48.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839597", lon: "12722.594033", latD: "N", lonD: "E", latitude: 0.7639932833333333, longitude: 127.37656721666667, timestamp: "2024-06-06T19:41:08.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839596", lon: "12722.593943", latD: "N", lonD: "E", latitude: 0.7639932666666667, longitude: 127.37656571666666, timestamp: "2024-06-06T19:40:48.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839597", lon: "12722.594033", latD: "N", lonD: "E", latitude: 0.7639932833333333, longitude: 127.37656721666667, timestamp: "2024-06-06T19:41:08.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839597", lon: "12722.594033", latD: "N", lonD: "E", latitude: 0.7639932833333333, longitude: 127.37656721666667, timestamp: "2024-06-06T19:41:08.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839597", lon: "12722.594033", latD: "N", lonD: "E", latitude: 0.7639932833333333, longitude: 127.37656721666667, timestamp: "2024-06-06T19:41:08.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839619", lon: "12722.594143", latD: "N", lonD: "E", latitude: 0.76399365, longitude: 127.37656905, timestamp: "2024-06-06T19:41:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839619", lon: "12722.594143", latD: "N", lonD: "E", latitude: 0.76399365, longitude: 127.37656905, timestamp: "2024-06-06T19:41:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839619", lon: "12722.594143", latD: "N", lonD: "E", latitude: 0.76399365, longitude: 127.37656905, timestamp: "2024-06-06T19:41:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839619", lon: "12722.594143", latD: "N", lonD: "E", latitude: 0.76399365, longitude: 127.37656905, timestamp: "2024-06-06T19:41:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839621", lon: "12722.594221", latD: "N", lonD: "E", latitude: 0.7639936833333334, longitude: 127.37657035, timestamp: "2024-06-06T19:41:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839621", lon: "12722.594221", latD: "N", lonD: "E", latitude: 0.7639936833333334, longitude: 127.37657035, timestamp: "2024-06-06T19:41:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839621", lon: "12722.594221", latD: "N", lonD: "E", latitude: 0.7639936833333334, longitude: 127.37657035, timestamp: "2024-06-06T19:41:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839621", lon: "12722.594221", latD: "N", lonD: "E", latitude: 0.7639936833333334, longitude: 127.37657035, timestamp: "2024-06-06T19:41:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839607", lon: "12722.594266", latD: "N", lonD: "E", latitude: 0.76399345, longitude: 127.3765711, timestamp: "2024-06-06T19:42:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839607", lon: "12722.594266", latD: "N", lonD: "E", latitude: 0.76399345, longitude: 127.3765711, timestamp: "2024-06-06T19:42:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839607", lon: "12722.594266", latD: "N", lonD: "E", latitude: 0.76399345, longitude: 127.3765711, timestamp: "2024-06-06T19:42:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839607", lon: "12722.594266", latD: "N", lonD: "E", latitude: 0.76399345, longitude: 127.3765711, timestamp: "2024-06-06T19:42:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839615", lon: "12722.594401", latD: "N", lonD: "E", latitude: 0.7639935833333333, longitude: 127.37657335, timestamp: "2024-06-06T19:42:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839615", lon: "12722.594401", latD: "N", lonD: "E", latitude: 0.7639935833333333, longitude: 127.37657335, timestamp: "2024-06-06T19:42:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839615", lon: "12722.594401", latD: "N", lonD: "E", latitude: 0.7639935833333333, longitude: 127.37657335, timestamp: "2024-06-06T19:42:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839615", lon: "12722.594401", latD: "N", lonD: "E", latitude: 0.7639935833333333, longitude: 127.37657335, timestamp: "2024-06-06T19:42:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839633", lon: "12722.594471", latD: "N", lonD: "E", latitude: 0.7639938833333333, longitude: 127.37657451666666, timestamp: "2024-06-06T19:42:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839633", lon: "12722.594471", latD: "N", lonD: "E", latitude: 0.7639938833333333, longitude: 127.37657451666666, timestamp: "2024-06-06T19:42:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839633", lon: "12722.594471", latD: "N", lonD: "E", latitude: 0.7639938833333333, longitude: 127.37657451666666, timestamp: "2024-06-06T19:42:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839633", lon: "12722.594471", latD: "N", lonD: "E", latitude: 0.7639938833333333, longitude: 127.37657451666666, timestamp: "2024-06-06T19:42:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839631", lon: "12722.594529", latD: "N", lonD: "E", latitude: 0.76399385, longitude: 127.37657548333334, timestamp: "2024-06-06T19:43:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839631", lon: "12722.594529", latD: "N", lonD: "E", latitude: 0.76399385, longitude: 127.37657548333334, timestamp: "2024-06-06T19:43:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839631", lon: "12722.594529", latD: "N", lonD: "E", latitude: 0.76399385, longitude: 127.37657548333334, timestamp: "2024-06-06T19:43:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.7", lat: "0045.839631", lon: "12722.594529", latD: "N", lonD: "E", latitude: 0.76399385, longitude: 127.37657548333334, timestamp: "2024-06-06T19:43:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.6", lat: "0045.839634", lon: "12722.594608", latD: "N", lonD: "E", latitude: 0.7639938999999999, longitude: 127.3765768, timestamp: "2024-06-06T19:43:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.6", lat: "0045.839634", lon: "12722.594608", latD: "N", lonD: "E", latitude: 0.7639938999999999, longitude: 127.3765768, timestamp: "2024-06-06T19:43:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.6", lat: "0045.839634", lon: "12722.594608", latD: "N", lonD: "E", latitude: 0.7639938999999999, longitude: 127.3765768, timestamp: "2024-06-06T19:43:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.6", lat: "0045.839634", lon: "12722.594608", latD: "N", lonD: "E", latitude: 0.7639938999999999, longitude: 127.3765768, timestamp: "2024-06-06T19:43:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.0", satellites: "09", hdop: "0.7", lat: "0045.839632", lon: "12722.594643", latD: "N", lonD: "E", latitude: 0.7639938666666667, longitude: 127.37657738333333, timestamp: "2024-06-06T19:43:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.0", satellites: "09", hdop: "0.7", lat: "0045.839632", lon: "12722.594643", latD: "N", lonD: "E", latitude: 0.7639938666666667, longitude: 127.37657738333333, timestamp: "2024-06-06T19:43:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.0", satellites: "09", hdop: "0.7", lat: "0045.839632", lon: "12722.594643", latD: "N", lonD: "E", latitude: 0.7639938666666667, longitude: 127.37657738333333, timestamp: "2024-06-06T19:43:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.0", satellites: "09", hdop: "0.7", lat: "0045.839632", lon: "12722.594643", latD: "N", lonD: "E", latitude: 0.7639938666666667, longitude: 127.37657738333333, timestamp: "2024-06-06T19:43:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.0", satellites: "09", hdop: "0.7", lat: "0045.839639", lon: "12722.594695", latD: "N", lonD: "E", latitude: 0.7639939833333333, longitude: 127.37657825, timestamp: "2024-06-06T19:44:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.0", satellites: "09", hdop: "0.7", lat: "0045.839639", lon: "12722.594695", latD: "N", lonD: "E", latitude: 0.7639939833333333, longitude: 127.37657825, timestamp: "2024-06-06T19:44:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.0", satellites: "09", hdop: "0.7", lat: "0045.839639", lon: "12722.594695", latD: "N", lonD: "E", latitude: 0.7639939833333333, longitude: 127.37657825, timestamp: "2024-06-06T19:44:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.0", satellites: "09", hdop: "0.7", lat: "0045.839639", lon: "12722.594695", latD: "N", lonD: "E", latitude: 0.7639939833333333, longitude: 127.37657825, timestamp: "2024-06-06T19:44:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.37657975, timestamp: "2024-06-06T19:44:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.37657975, timestamp: "2024-06-06T19:44:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.37657975, timestamp: "2024-06-06T19:44:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.594785", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.37657975, timestamp: "2024-06-06T19:44:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.594848", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.3765808, timestamp: "2024-06-06T19:44:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.594848", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.3765808, timestamp: "2024-06-06T19:44:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.594848", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.3765808, timestamp: "2024-06-06T19:44:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.594848", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.3765808, timestamp: "2024-06-06T19:44:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839660", lon: "12722.594866", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.3765811, timestamp: "2024-06-06T19:45:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839660", lon: "12722.594866", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.3765811, timestamp: "2024-06-06T19:45:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839660", lon: "12722.594866", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.3765811, timestamp: "2024-06-06T19:45:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839660", lon: "12722.594866", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.3765811, timestamp: "2024-06-06T19:45:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839666", lon: "12722.594903", latD: "N", lonD: "E", latitude: 0.7639944333333334, longitude: 127.37658171666666, timestamp: "2024-06-06T19:45:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839666", lon: "12722.594903", latD: "N", lonD: "E", latitude: 0.7639944333333334, longitude: 127.37658171666666, timestamp: "2024-06-06T19:45:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839666", lon: "12722.594903", latD: "N", lonD: "E", latitude: 0.7639944333333334, longitude: 127.37658171666666, timestamp: "2024-06-06T19:45:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839666", lon: "12722.594903", latD: "N", lonD: "E", latitude: 0.7639944333333334, longitude: 127.37658171666666, timestamp: "2024-06-06T19:45:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839669", lon: "12722.594972", latD: "N", lonD: "E", latitude: 0.7639944833333333, longitude: 127.37658286666667, timestamp: "2024-06-06T19:45:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839669", lon: "12722.594972", latD: "N", lonD: "E", latitude: 0.7639944833333333, longitude: 127.37658286666667, timestamp: "2024-06-06T19:45:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839669", lon: "12722.594972", latD: "N", lonD: "E", latitude: 0.7639944833333333, longitude: 127.37658286666667, timestamp: "2024-06-06T19:45:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839669", lon: "12722.594972", latD: "N", lonD: "E", latitude: 0.7639944833333333, longitude: 127.37658286666667, timestamp: "2024-06-06T19:45:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839673", lon: "12722.595031", latD: "N", lonD: "E", latitude: 0.7639945499999999, longitude: 127.37658385, timestamp: "2024-06-06T19:46:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839673", lon: "12722.595031", latD: "N", lonD: "E", latitude: 0.7639945499999999, longitude: 127.37658385, timestamp: "2024-06-06T19:46:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839673", lon: "12722.595031", latD: "N", lonD: "E", latitude: 0.7639945499999999, longitude: 127.37658385, timestamp: "2024-06-06T19:46:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839673", lon: "12722.595031", latD: "N", lonD: "E", latitude: 0.7639945499999999, longitude: 127.37658385, timestamp: "2024-06-06T19:46:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839682", lon: "12722.595067", latD: "N", lonD: "E", latitude: 0.7639947, longitude: 127.37658445, timestamp: "2024-06-06T19:46:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839682", lon: "12722.595067", latD: "N", lonD: "E", latitude: 0.7639947, longitude: 127.37658445, timestamp: "2024-06-06T19:46:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839682", lon: "12722.595067", latD: "N", lonD: "E", latitude: 0.7639947, longitude: 127.37658445, timestamp: "2024-06-06T19:46:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839682", lon: "12722.595067", latD: "N", lonD: "E", latitude: 0.7639947, longitude: 127.37658445, timestamp: "2024-06-06T19:46:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839685", lon: "12722.595101", latD: "N", lonD: "E", latitude: 0.7639947500000001, longitude: 127.37658501666667, timestamp: "2024-06-06T19:46:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839685", lon: "12722.595101", latD: "N", lonD: "E", latitude: 0.7639947500000001, longitude: 127.37658501666667, timestamp: "2024-06-06T19:46:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839685", lon: "12722.595101", latD: "N", lonD: "E", latitude: 0.7639947500000001, longitude: 127.37658501666667, timestamp: "2024-06-06T19:46:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839685", lon: "12722.595101", latD: "N", lonD: "E", latitude: 0.7639947500000001, longitude: 127.37658501666667, timestamp: "2024-06-06T19:46:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839696", lon: "12722.595146", latD: "N", lonD: "E", latitude: 0.7639949333333333, longitude: 127.37658576666666, timestamp: "2024-06-06T19:47:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839696", lon: "12722.595146", latD: "N", lonD: "E", latitude: 0.7639949333333333, longitude: 127.37658576666666, timestamp: "2024-06-06T19:47:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839696", lon: "12722.595146", latD: "N", lonD: "E", latitude: 0.7639949333333333, longitude: 127.37658576666666, timestamp: "2024-06-06T19:47:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839696", lon: "12722.595146", latD: "N", lonD: "E", latitude: 0.7639949333333333, longitude: 127.37658576666666, timestamp: "2024-06-06T19:47:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839705", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639950833333333, longitude: 127.3765865, timestamp: "2024-06-06T19:47:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839705", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639950833333333, longitude: 127.3765865, timestamp: "2024-06-06T19:47:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839705", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639950833333333, longitude: 127.3765865, timestamp: "2024-06-06T19:47:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839705", lon: "12722.595190", latD: "N", lonD: "E", latitude: 0.7639950833333333, longitude: 127.3765865, timestamp: "2024-06-06T19:47:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839714", lon: "12722.595251", latD: "N", lonD: "E", latitude: 0.7639952333333333, longitude: 127.37658751666666, timestamp: "2024-06-06T19:47:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839714", lon: "12722.595251", latD: "N", lonD: "E", latitude: 0.7639952333333333, longitude: 127.37658751666666, timestamp: "2024-06-06T19:47:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839714", lon: "12722.595251", latD: "N", lonD: "E", latitude: 0.7639952333333333, longitude: 127.37658751666666, timestamp: "2024-06-06T19:47:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839714", lon: "12722.595251", latD: "N", lonD: "E", latitude: 0.7639952333333333, longitude: 127.37658751666666, timestamp: "2024-06-06T19:47:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839720", lon: "12722.595301", latD: "N", lonD: "E", latitude: 0.7639953333333334, longitude: 127.37658835, timestamp: "2024-06-06T19:48:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839720", lon: "12722.595301", latD: "N", lonD: "E", latitude: 0.7639953333333334, longitude: 127.37658835, timestamp: "2024-06-06T19:48:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839720", lon: "12722.595301", latD: "N", lonD: "E", latitude: 0.7639953333333334, longitude: 127.37658835, timestamp: "2024-06-06T19:48:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839720", lon: "12722.595301", latD: "N", lonD: "E", latitude: 0.7639953333333334, longitude: 127.37658835, timestamp: "2024-06-06T19:48:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839719", lon: "12722.595355", latD: "N", lonD: "E", latitude: 0.7639953166666668, longitude: 127.37658925, timestamp: "2024-06-06T19:48:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839719", lon: "12722.595355", latD: "N", lonD: "E", latitude: 0.7639953166666668, longitude: 127.37658925, timestamp: "2024-06-06T19:48:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839719", lon: "12722.595355", latD: "N", lonD: "E", latitude: 0.7639953166666668, longitude: 127.37658925, timestamp: "2024-06-06T19:48:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839719", lon: "12722.595355", latD: "N", lonD: "E", latitude: 0.7639953166666668, longitude: 127.37658925, timestamp: "2024-06-06T19:48:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839719", lon: "12722.595378", latD: "N", lonD: "E", latitude: 0.7639953166666668, longitude: 127.37658963333334, timestamp: "2024-06-06T19:48:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839719", lon: "12722.595378", latD: "N", lonD: "E", latitude: 0.7639953166666668, longitude: 127.37658963333334, timestamp: "2024-06-06T19:48:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839719", lon: "12722.595378", latD: "N", lonD: "E", latitude: 0.7639953166666668, longitude: 127.37658963333334, timestamp: "2024-06-06T19:48:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839719", lon: "12722.595378", latD: "N", lonD: "E", latitude: 0.7639953166666668, longitude: 127.37658963333334, timestamp: "2024-06-06T19:48:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839716", lon: "12722.595380", latD: "N", lonD: "E", latitude: 0.7639952666666667, longitude: 127.37658966666666, timestamp: "2024-06-06T19:49:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839716", lon: "12722.595380", latD: "N", lonD: "E", latitude: 0.7639952666666667, longitude: 127.37658966666666, timestamp: "2024-06-06T19:49:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839716", lon: "12722.595380", latD: "N", lonD: "E", latitude: 0.7639952666666667, longitude: 127.37658966666666, timestamp: "2024-06-06T19:49:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839716", lon: "12722.595380", latD: "N", lonD: "E", latitude: 0.7639952666666667, longitude: 127.37658966666666, timestamp: "2024-06-06T19:49:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839716", lon: "12722.595386", latD: "N", lonD: "E", latitude: 0.7639952666666667, longitude: 127.37658976666667, timestamp: "2024-06-06T19:49:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839716", lon: "12722.595386", latD: "N", lonD: "E", latitude: 0.7639952666666667, longitude: 127.37658976666667, timestamp: "2024-06-06T19:49:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839716", lon: "12722.595386", latD: "N", lonD: "E", latitude: 0.7639952666666667, longitude: 127.37658976666667, timestamp: "2024-06-06T19:49:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839716", lon: "12722.595386", latD: "N", lonD: "E", latitude: 0.7639952666666667, longitude: 127.37658976666667, timestamp: "2024-06-06T19:49:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839702", lon: "12722.595402", latD: "N", lonD: "E", latitude: 0.7639950333333334, longitude: 127.37659003333333, timestamp: "2024-06-06T19:49:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839702", lon: "12722.595402", latD: "N", lonD: "E", latitude: 0.7639950333333334, longitude: 127.37659003333333, timestamp: "2024-06-06T19:49:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839702", lon: "12722.595402", latD: "N", lonD: "E", latitude: 0.7639950333333334, longitude: 127.37659003333333, timestamp: "2024-06-06T19:49:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839702", lon: "12722.595402", latD: "N", lonD: "E", latitude: 0.7639950333333334, longitude: 127.37659003333333, timestamp: "2024-06-06T19:49:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839688", lon: "12722.595439", latD: "N", lonD: "E", latitude: 0.7639948000000001, longitude: 127.37659065, timestamp: "2024-06-06T19:50:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839688", lon: "12722.595439", latD: "N", lonD: "E", latitude: 0.7639948000000001, longitude: 127.37659065, timestamp: "2024-06-06T19:50:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839688", lon: "12722.595439", latD: "N", lonD: "E", latitude: 0.7639948000000001, longitude: 127.37659065, timestamp: "2024-06-06T19:50:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839688", lon: "12722.595439", latD: "N", lonD: "E", latitude: 0.7639948000000001, longitude: 127.37659065, timestamp: "2024-06-06T19:50:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.6", lat: "0045.839690", lon: "12722.595477", latD: "N", lonD: "E", latitude: 0.7639948333333333, longitude: 127.37659128333334, timestamp: "2024-06-06T19:50:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.6", lat: "0045.839690", lon: "12722.595477", latD: "N", lonD: "E", latitude: 0.7639948333333333, longitude: 127.37659128333334, timestamp: "2024-06-06T19:50:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.6", lat: "0045.839690", lon: "12722.595477", latD: "N", lonD: "E", latitude: 0.7639948333333333, longitude: 127.37659128333334, timestamp: "2024-06-06T19:50:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.1", satellites: "09", hdop: "0.6", lat: "0045.839690", lon: "12722.595477", latD: "N", lonD: "E", latitude: 0.7639948333333333, longitude: 127.37659128333334, timestamp: "2024-06-06T19:50:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.839678", lon: "12722.595541", latD: "N", lonD: "E", latitude: 0.7639946333333333, longitude: 127.37659235, timestamp: "2024-06-06T19:50:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.839678", lon: "12722.595541", latD: "N", lonD: "E", latitude: 0.7639946333333333, longitude: 127.37659235, timestamp: "2024-06-06T19:50:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.839678", lon: "12722.595541", latD: "N", lonD: "E", latitude: 0.7639946333333333, longitude: 127.37659235, timestamp: "2024-06-06T19:50:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.839678", lon: "12722.595541", latD: "N", lonD: "E", latitude: 0.7639946333333333, longitude: 127.37659235, timestamp: "2024-06-06T19:50:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.839671", lon: "12722.595602", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.37659336666667, timestamp: "2024-06-06T19:51:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.839671", lon: "12722.595602", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.37659336666667, timestamp: "2024-06-06T19:51:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.839671", lon: "12722.595602", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.37659336666667, timestamp: "2024-06-06T19:51:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.2", satellites: "09", hdop: "0.7", lat: "0045.839671", lon: "12722.595602", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.37659336666667, timestamp: "2024-06-06T19:51:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839660", lon: "12722.595704", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.37659506666667, timestamp: "2024-06-06T19:51:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839660", lon: "12722.595704", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.37659506666667, timestamp: "2024-06-06T19:51:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839660", lon: "12722.595704", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.37659506666667, timestamp: "2024-06-06T19:51:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839660", lon: "12722.595704", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.37659506666667, timestamp: "2024-06-06T19:51:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.595761", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.37659601666667, timestamp: "2024-06-06T19:51:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.595761", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.37659601666667, timestamp: "2024-06-06T19:51:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.595761", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.37659601666667, timestamp: "2024-06-06T19:51:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839656", lon: "12722.595831", latD: "N", lonD: "E", latitude: 0.7639942666666666, longitude: 127.37659718333333, timestamp: "2024-06-06T19:52:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839657", lon: "12722.595761", latD: "N", lonD: "E", latitude: 0.7639942833333334, longitude: 127.37659601666667, timestamp: "2024-06-06T19:51:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839656", lon: "12722.595831", latD: "N", lonD: "E", latitude: 0.7639942666666666, longitude: 127.37659718333333, timestamp: "2024-06-06T19:52:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839656", lon: "12722.595831", latD: "N", lonD: "E", latitude: 0.7639942666666666, longitude: 127.37659718333333, timestamp: "2024-06-06T19:52:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839656", lon: "12722.595831", latD: "N", lonD: "E", latitude: 0.7639942666666666, longitude: 127.37659718333333, timestamp: "2024-06-06T19:52:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839663", lon: "12722.595887", latD: "N", lonD: "E", latitude: 0.7639943833333334, longitude: 127.37659811666667, timestamp: "2024-06-06T19:52:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839663", lon: "12722.595887", latD: "N", lonD: "E", latitude: 0.7639943833333334, longitude: 127.37659811666667, timestamp: "2024-06-06T19:52:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839663", lon: "12722.595887", latD: "N", lonD: "E", latitude: 0.7639943833333334, longitude: 127.37659811666667, timestamp: "2024-06-06T19:52:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839663", lon: "12722.595887", latD: "N", lonD: "E", latitude: 0.7639943833333334, longitude: 127.37659811666667, timestamp: "2024-06-06T19:52:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839667", lon: "12722.595919", latD: "N", lonD: "E", latitude: 0.76399445, longitude: 127.37659865, timestamp: "2024-06-06T19:52:50.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839667", lon: "12722.595919", latD: "N", lonD: "E", latitude: 0.76399445, longitude: 127.37659865, timestamp: "2024-06-06T19:52:50.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839667", lon: "12722.595919", latD: "N", lonD: "E", latitude: 0.76399445, longitude: 127.37659865, timestamp: "2024-06-06T19:52:50.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.3", satellites: "09", hdop: "0.7", lat: "0045.839667", lon: "12722.595919", latD: "N", lonD: "E", latitude: 0.76399445, longitude: 127.37659865, timestamp: "2024-06-06T19:52:50.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839667", lon: "12722.595961", latD: "N", lonD: "E", latitude: 0.76399445, longitude: 127.37659935, timestamp: "2024-06-06T19:53:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839667", lon: "12722.595961", latD: "N", lonD: "E", latitude: 0.76399445, longitude: 127.37659935, timestamp: "2024-06-06T19:53:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839667", lon: "12722.595961", latD: "N", lonD: "E", latitude: 0.76399445, longitude: 127.37659935, timestamp: "2024-06-06T19:53:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839667", lon: "12722.595961", latD: "N", lonD: "E", latitude: 0.76399445, longitude: 127.37659935, timestamp: "2024-06-06T19:53:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839671", lon: "12722.596018", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.3766003, timestamp: "2024-06-06T19:53:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839671", lon: "12722.596018", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.3766003, timestamp: "2024-06-06T19:53:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839671", lon: "12722.596018", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.3766003, timestamp: "2024-06-06T19:53:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839687", lon: "12722.596066", latD: "N", lonD: "E", latitude: 0.7639947833333333, longitude: 127.3766011, timestamp: "2024-06-06T19:53:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839671", lon: "12722.596018", latD: "N", lonD: "E", latitude: 0.7639945166666667, longitude: 127.3766003, timestamp: "2024-06-06T19:53:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839687", lon: "12722.596066", latD: "N", lonD: "E", latitude: 0.7639947833333333, longitude: 127.3766011, timestamp: "2024-06-06T19:53:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839687", lon: "12722.596066", latD: "N", lonD: "E", latitude: 0.7639947833333333, longitude: 127.3766011, timestamp: "2024-06-06T19:53:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839687", lon: "12722.596066", latD: "N", lonD: "E", latitude: 0.7639947833333333, longitude: 127.3766011, timestamp: "2024-06-06T19:53:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839697", lon: "12722.596096", latD: "N", lonD: "E", latitude: 0.7639949500000001, longitude: 127.3766016, timestamp: "2024-06-06T19:54:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839697", lon: "12722.596096", latD: "N", lonD: "E", latitude: 0.7639949500000001, longitude: 127.3766016, timestamp: "2024-06-06T19:54:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839697", lon: "12722.596096", latD: "N", lonD: "E", latitude: 0.7639949500000001, longitude: 127.3766016, timestamp: "2024-06-06T19:54:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.7", lat: "0045.839697", lon: "12722.596096", latD: "N", lonD: "E", latitude: 0.7639949500000001, longitude: 127.3766016, timestamp: "2024-06-06T19:54:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839697", lon: "12722.596149", latD: "N", lonD: "E", latitude: 0.7639949500000001, longitude: 127.37660248333333, timestamp: "2024-06-06T19:54:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839697", lon: "12722.596149", latD: "N", lonD: "E", latitude: 0.7639949500000001, longitude: 127.37660248333333, timestamp: "2024-06-06T19:54:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839697", lon: "12722.596149", latD: "N", lonD: "E", latitude: 0.7639949500000001, longitude: 127.37660248333333, timestamp: "2024-06-06T19:54:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839697", lon: "12722.596149", latD: "N", lonD: "E", latitude: 0.7639949500000001, longitude: 127.37660248333333, timestamp: "2024-06-06T19:54:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839701", lon: "12722.596195", latD: "N", lonD: "E", latitude: 0.7639950166666666, longitude: 127.37660325, timestamp: "2024-06-06T19:54:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839701", lon: "12722.596195", latD: "N", lonD: "E", latitude: 0.7639950166666666, longitude: 127.37660325, timestamp: "2024-06-06T19:54:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839701", lon: "12722.596195", latD: "N", lonD: "E", latitude: 0.7639950166666666, longitude: 127.37660325, timestamp: "2024-06-06T19:54:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.4", satellites: "09", hdop: "0.6", lat: "0045.839701", lon: "12722.596195", latD: "N", lonD: "E", latitude: 0.7639950166666666, longitude: 127.37660325, timestamp: "2024-06-06T19:54:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839709", lon: "12722.596227", latD: "N", lonD: "E", latitude: 0.76399515, longitude: 127.37660378333334, timestamp: "2024-06-06T19:55:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839709", lon: "12722.596227", latD: "N", lonD: "E", latitude: 0.76399515, longitude: 127.37660378333334, timestamp: "2024-06-06T19:55:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839709", lon: "12722.596227", latD: "N", lonD: "E", latitude: 0.76399515, longitude: 127.37660378333334, timestamp: "2024-06-06T19:55:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839711", lon: "12722.596281", latD: "N", lonD: "E", latitude: 0.7639951833333334, longitude: 127.37660468333333, timestamp: "2024-06-06T19:55:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839711", lon: "12722.596281", latD: "N", lonD: "E", latitude: 0.7639951833333334, longitude: 127.37660468333333, timestamp: "2024-06-06T19:55:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839709", lon: "12722.596227", latD: "N", lonD: "E", latitude: 0.76399515, longitude: 127.37660378333334, timestamp: "2024-06-06T19:55:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839711", lon: "12722.596281", latD: "N", lonD: "E", latitude: 0.7639951833333334, longitude: 127.37660468333333, timestamp: "2024-06-06T19:55:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839711", lon: "12722.596281", latD: "N", lonD: "E", latitude: 0.7639951833333334, longitude: 127.37660468333333, timestamp: "2024-06-06T19:55:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.6", lat: "0045.839704", lon: "12722.596306", latD: "N", lonD: "E", latitude: 0.7639950666666666, longitude: 127.3766051, timestamp: "2024-06-06T19:55:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.6", lat: "0045.839704", lon: "12722.596306", latD: "N", lonD: "E", latitude: 0.7639950666666666, longitude: 127.3766051, timestamp: "2024-06-06T19:55:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.6", lat: "0045.839704", lon: "12722.596306", latD: "N", lonD: "E", latitude: 0.7639950666666666, longitude: 127.3766051, timestamp: "2024-06-06T19:55:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839698", lon: "12722.596362", latD: "N", lonD: "E", latitude: 0.7639949666666667, longitude: 127.37660603333333, timestamp: "2024-06-06T19:56:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.6", lat: "0045.839704", lon: "12722.596306", latD: "N", lonD: "E", latitude: 0.7639950666666666, longitude: 127.3766051, timestamp: "2024-06-06T19:55:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839698", lon: "12722.596362", latD: "N", lonD: "E", latitude: 0.7639949666666667, longitude: 127.37660603333333, timestamp: "2024-06-06T19:56:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839698", lon: "12722.596362", latD: "N", lonD: "E", latitude: 0.7639949666666667, longitude: 127.37660603333333, timestamp: "2024-06-06T19:56:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839698", lon: "12722.596362", latD: "N", lonD: "E", latitude: 0.7639949666666667, longitude: 127.37660603333333, timestamp: "2024-06-06T19:56:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839690", lon: "12722.596399", latD: "N", lonD: "E", latitude: 0.7639948333333333, longitude: 127.37660665, timestamp: "2024-06-06T19:56:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839690", lon: "12722.596399", latD: "N", lonD: "E", latitude: 0.7639948333333333, longitude: 127.37660665, timestamp: "2024-06-06T19:56:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839690", lon: "12722.596399", latD: "N", lonD: "E", latitude: 0.7639948333333333, longitude: 127.37660665, timestamp: "2024-06-06T19:56:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "09", hdop: "0.7", lat: "0045.839690", lon: "12722.596399", latD: "N", lonD: "E", latitude: 0.7639948333333333, longitude: 127.37660665, timestamp: "2024-06-06T19:56:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "08", hdop: "0.7", lat: "0045.839674", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639945666666667, longitude: 127.37660758333334, timestamp: "2024-06-06T19:56:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "08", hdop: "0.7", lat: "0045.839674", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639945666666667, longitude: 127.37660758333334, timestamp: "2024-06-06T19:56:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "08", hdop: "0.7", lat: "0045.839674", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639945666666667, longitude: 127.37660758333334, timestamp: "2024-06-06T19:56:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.6", satellites: "09", hdop: "0.7", lat: "0045.839661", lon: "12722.596516", latD: "N", lonD: "E", latitude: 0.76399435, longitude: 127.3766086, timestamp: "2024-06-06T19:57:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.5", satellites: "08", hdop: "0.7", lat: "0045.839674", lon: "12722.596455", latD: "N", lonD: "E", latitude: 0.7639945666666667, longitude: 127.37660758333334, timestamp: "2024-06-06T19:56:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.6", satellites: "09", hdop: "0.7", lat: "0045.839661", lon: "12722.596516", latD: "N", lonD: "E", latitude: 0.76399435, longitude: 127.3766086, timestamp: "2024-06-06T19:57:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.6", satellites: "09", hdop: "0.7", lat: "0045.839661", lon: "12722.596516", latD: "N", lonD: "E", latitude: 0.76399435, longitude: 127.3766086, timestamp: "2024-06-06T19:57:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.6", satellites: "09", hdop: "0.7", lat: "0045.839661", lon: "12722.596516", latD: "N", lonD: "E", latitude: 0.76399435, longitude: 127.3766086, timestamp: "2024-06-06T19:57:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839649", lon: "12722.596562", latD: "N", lonD: "E", latitude: 0.76399415, longitude: 127.37660936666667, timestamp: "2024-06-06T19:57:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839649", lon: "12722.596562", latD: "N", lonD: "E", latitude: 0.76399415, longitude: 127.37660936666667, timestamp: "2024-06-06T19:57:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839649", lon: "12722.596562", latD: "N", lonD: "E", latitude: 0.76399415, longitude: 127.37660936666667, timestamp: "2024-06-06T19:57:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839649", lon: "12722.596562", latD: "N", lonD: "E", latitude: 0.76399415, longitude: 127.37660936666667, timestamp: "2024-06-06T19:57:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.8", lat: "0045.839651", lon: "12722.596588", latD: "N", lonD: "E", latitude: 0.7639941833333334, longitude: 127.3766098, timestamp: "2024-06-06T19:57:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.8", lat: "0045.839651", lon: "12722.596588", latD: "N", lonD: "E", latitude: 0.7639941833333334, longitude: 127.3766098, timestamp: "2024-06-06T19:57:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.8", lat: "0045.839651", lon: "12722.596588", latD: "N", lonD: "E", latitude: 0.7639941833333334, longitude: 127.3766098, timestamp: "2024-06-06T19:57:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.8", lat: "0045.839651", lon: "12722.596588", latD: "N", lonD: "E", latitude: 0.7639941833333334, longitude: 127.3766098, timestamp: "2024-06-06T19:57:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839660", lon: "12722.596592", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.37660986666667, timestamp: "2024-06-06T19:58:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839660", lon: "12722.596592", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.37660986666667, timestamp: "2024-06-06T19:58:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839660", lon: "12722.596592", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.37660986666667, timestamp: "2024-06-06T19:58:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839680", lon: "12722.596588", latD: "N", lonD: "E", latitude: 0.7639946666666667, longitude: 127.3766098, timestamp: "2024-06-06T19:58:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839660", lon: "12722.596592", latD: "N", lonD: "E", latitude: 0.7639943333333333, longitude: 127.37660986666667, timestamp: "2024-06-06T19:58:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839680", lon: "12722.596588", latD: "N", lonD: "E", latitude: 0.7639946666666667, longitude: 127.3766098, timestamp: "2024-06-06T19:58:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839680", lon: "12722.596588", latD: "N", lonD: "E", latitude: 0.7639946666666667, longitude: 127.3766098, timestamp: "2024-06-06T19:58:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839680", lon: "12722.596588", latD: "N", lonD: "E", latitude: 0.7639946666666667, longitude: 127.3766098, timestamp: "2024-06-06T19:58:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839700", lon: "12722.596580", latD: "N", lonD: "E", latitude: 0.763995, longitude: 127.37660966666667, timestamp: "2024-06-06T19:58:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839700", lon: "12722.596580", latD: "N", lonD: "E", latitude: 0.763995, longitude: 127.37660966666667, timestamp: "2024-06-06T19:58:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839700", lon: "12722.596580", latD: "N", lonD: "E", latitude: 0.763995, longitude: 127.37660966666667, timestamp: "2024-06-06T19:58:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839700", lon: "12722.596580", latD: "N", lonD: "E", latitude: 0.763995, longitude: 127.37660966666667, timestamp: "2024-06-06T19:58:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839704", lon: "12722.596590", latD: "N", lonD: "E", latitude: 0.7639950666666666, longitude: 127.37660983333333, timestamp: "2024-06-06T19:59:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839704", lon: "12722.596590", latD: "N", lonD: "E", latitude: 0.7639950666666666, longitude: 127.37660983333333, timestamp: "2024-06-06T19:59:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839704", lon: "12722.596590", latD: "N", lonD: "E", latitude: 0.7639950666666666, longitude: 127.37660983333333, timestamp: "2024-06-06T19:59:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "09", hdop: "0.7", lat: "0045.839704", lon: "12722.596590", latD: "N", lonD: "E", latitude: 0.7639950666666666, longitude: 127.37660983333333, timestamp: "2024-06-06T19:59:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "08", hdop: "0.7", lat: "0045.839715", lon: "12722.596600", latD: "N", lonD: "E", latitude: 0.76399525, longitude: 127.37661, timestamp: "2024-06-06T19:59:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "08", hdop: "0.7", lat: "0045.839715", lon: "12722.596600", latD: "N", lonD: "E", latitude: 0.76399525, longitude: 127.37661, timestamp: "2024-06-06T19:59:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "08", hdop: "0.7", lat: "0045.839715", lon: "12722.596600", latD: "N", lonD: "E", latitude: 0.76399525, longitude: 127.37661, timestamp: "2024-06-06T19:59:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.7", satellites: "08", hdop: "0.7", lat: "0045.839715", lon: "12722.596600", latD: "N", lonD: "E", latitude: 0.76399525, longitude: 127.37661, timestamp: "2024-06-06T19:59:29.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.8", satellites: "09", hdop: "0.7", lat: "0045.839736", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7639956, longitude: 127.37661034999999, timestamp: "2024-06-06T19:59:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.8", satellites: "09", hdop: "0.7", lat: "0045.839736", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7639956, longitude: 127.37661034999999, timestamp: "2024-06-06T19:59:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.8", satellites: "09", hdop: "0.7", lat: "0045.839736", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7639956, longitude: 127.37661034999999, timestamp: "2024-06-06T19:59:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.8", satellites: "09", hdop: "0.7", lat: "0045.839736", lon: "12722.596621", latD: "N", lonD: "E", latitude: 0.7639956, longitude: 127.37661034999999, timestamp: "2024-06-06T19:59:49.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.8", satellites: "09", hdop: "0.7", lat: "0045.839749", lon: "12722.596651", latD: "N", lonD: "E", latitude: 0.7639958166666666, longitude: 127.37661085, timestamp: "2024-06-06T20:00:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.8", satellites: "09", hdop: "0.7", lat: "0045.839749", lon: "12722.596651", latD: "N", lonD: "E", latitude: 0.7639958166666666, longitude: 127.37661085, timestamp: "2024-06-06T20:00:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.8", satellites: "09", hdop: "0.7", lat: "0045.839749", lon: "12722.596651", latD: "N", lonD: "E", latitude: 0.7639958166666666, longitude: 127.37661085, timestamp: "2024-06-06T20:00:09.000Z" },
        { speed: "0.0", course: "20.9", altitude: "0.8", satellites: "09", hdop: "0.7", lat: "0045.839749", lon: "12722.596651", latD: "N", lonD: "E", latitude: 0.7639958166666666, longitude: 127.37661085, timestamp: "2024-06-06T20:00:09.000Z" },
        { speed: "0.0", course: "353.9", altitude: "2.0", satellites: "09", hdop: "0.7", lat: "0045.839876", lon: "12722.598799", latD: "N", lonD: "E", latitude: 0.7639979333333333, longitude: 127.37664665, timestamp: "2024-06-06T20:00:29.000Z" },
        { speed: "0.0", course: "353.9", altitude: "2.0", satellites: "09", hdop: "0.7", lat: "0045.839876", lon: "12722.598799", latD: "N", lonD: "E", latitude: 0.7639979333333333, longitude: 127.37664665, timestamp: "2024-06-06T20:00:29.000Z" },
        { speed: "0.0", course: "353.9", altitude: "2.0", satellites: "09", hdop: "0.7", lat: "0045.839876", lon: "12722.598799", latD: "N", lonD: "E", latitude: 0.7639979333333333, longitude: 127.37664665, timestamp: "2024-06-06T20:00:29.000Z" },
        { speed: "0.0", course: "353.9", altitude: "2.0", satellites: "09", hdop: "0.7", lat: "0045.839876", lon: "12722.598799", latD: "N", lonD: "E", latitude: 0.7639979333333333, longitude: 127.37664665, timestamp: "2024-06-06T20:00:29.000Z" },
        { speed: "0.0", course: "302.8", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.840150", lon: "12722.600021", latD: "N", lonD: "E", latitude: 0.7640025, longitude: 127.37666701666667, timestamp: "2024-06-06T20:00:49.000Z" },
        { speed: "0.0", course: "302.8", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.840150", lon: "12722.600021", latD: "N", lonD: "E", latitude: 0.7640025, longitude: 127.37666701666667, timestamp: "2024-06-06T20:00:49.000Z" },
        { speed: "0.0", course: "302.8", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.840150", lon: "12722.600021", latD: "N", lonD: "E", latitude: 0.7640025, longitude: 127.37666701666667, timestamp: "2024-06-06T20:00:49.000Z" },
        { speed: "0.0", course: "302.8", altitude: "3.4", satellites: "09", hdop: "0.6", lat: "0045.840150", lon: "12722.600021", latD: "N", lonD: "E", latitude: 0.7640025, longitude: 127.37666701666667, timestamp: "2024-06-06T20:00:49.000Z" },
        { speed: "0.0", course: "221.4", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.839945", lon: "12722.600904", latD: "N", lonD: "E", latitude: 0.7639990833333333, longitude: 127.37668173333333, timestamp: "2024-06-06T20:01:09.000Z" },
        { speed: "0.0", course: "221.4", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.839945", lon: "12722.600904", latD: "N", lonD: "E", latitude: 0.7639990833333333, longitude: 127.37668173333333, timestamp: "2024-06-06T20:01:09.000Z" },
        { speed: "0.0", course: "282.5", altitude: "5.2", satellites: "08", hdop: "0.7", lat: "0045.840156", lon: "12722.601458", latD: "N", lonD: "E", latitude: 0.7640026, longitude: 127.37669096666667, timestamp: "2024-06-06T20:01:29.000Z" },
        { speed: "0.0", course: "221.4", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.839945", lon: "12722.600904", latD: "N", lonD: "E", latitude: 0.7639990833333333, longitude: 127.37668173333333, timestamp: "2024-06-06T20:01:09.000Z" },
        { speed: "0.0", course: "221.4", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.839945", lon: "12722.600904", latD: "N", lonD: "E", latitude: 0.7639990833333333, longitude: 127.37668173333333, timestamp: "2024-06-06T20:01:09.000Z" },
        { speed: "0.0", course: "282.5", altitude: "5.2", satellites: "08", hdop: "0.7", lat: "0045.840156", lon: "12722.601458", latD: "N", lonD: "E", latitude: 0.7640026, longitude: 127.37669096666667, timestamp: "2024-06-06T20:01:29.000Z" },
        { speed: "0.0", course: "282.5", altitude: "5.2", satellites: "08", hdop: "0.7", lat: "0045.840156", lon: "12722.601458", latD: "N", lonD: "E", latitude: 0.7640026, longitude: 127.37669096666667, timestamp: "2024-06-06T20:01:29.000Z" },
        { speed: "0.0", course: "282.5", altitude: "5.2", satellites: "08", hdop: "0.7", lat: "0045.840156", lon: "12722.601458", latD: "N", lonD: "E", latitude: 0.7640026, longitude: 127.37669096666667, timestamp: "2024-06-06T20:01:29.000Z" },
        { speed: "0.0", course: "282.5", altitude: "7.1", satellites: "09", hdop: "0.6", lat: "0045.840063", lon: "12722.601762", latD: "N", lonD: "E", latitude: 0.76400105, longitude: 127.37669603333333, timestamp: "2024-06-06T20:01:49.000Z" },
        { speed: "0.0", course: "282.5", altitude: "7.1", satellites: "09", hdop: "0.6", lat: "0045.840063", lon: "12722.601762", latD: "N", lonD: "E", latitude: 0.76400105, longitude: 127.37669603333333, timestamp: "2024-06-06T20:01:49.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.839957", lon: "12722.601456", latD: "N", lonD: "E", latitude: 0.7639992833333333, longitude: 127.37669093333334, timestamp: "2024-06-06T20:02:09.000Z" },
        { speed: "0.0", course: "282.5", altitude: "7.1", satellites: "09", hdop: "0.6", lat: "0045.840063", lon: "12722.601762", latD: "N", lonD: "E", latitude: 0.76400105, longitude: 127.37669603333333, timestamp: "2024-06-06T20:01:49.000Z" },
        { speed: "0.0", course: "282.5", altitude: "7.1", satellites: "09", hdop: "0.6", lat: "0045.840063", lon: "12722.601762", latD: "N", lonD: "E", latitude: 0.76400105, longitude: 127.37669603333333, timestamp: "2024-06-06T20:01:49.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.839957", lon: "12722.601456", latD: "N", lonD: "E", latitude: 0.7639992833333333, longitude: 127.37669093333334, timestamp: "2024-06-06T20:02:09.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.839957", lon: "12722.601456", latD: "N", lonD: "E", latitude: 0.7639992833333333, longitude: 127.37669093333334, timestamp: "2024-06-06T20:02:09.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.2", satellites: "09", hdop: "0.6", lat: "0045.839957", lon: "12722.601456", latD: "N", lonD: "E", latitude: 0.7639992833333333, longitude: 127.37669093333334, timestamp: "2024-06-06T20:02:09.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.840614", lon: "12722.601228", latD: "N", lonD: "E", latitude: 0.7640102333333334, longitude: 127.37668713333333, timestamp: "2024-06-06T20:02:29.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.840614", lon: "12722.601228", latD: "N", lonD: "E", latitude: 0.7640102333333334, longitude: 127.37668713333333, timestamp: "2024-06-06T20:02:29.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.840614", lon: "12722.601228", latD: "N", lonD: "E", latitude: 0.7640102333333334, longitude: 127.37668713333333, timestamp: "2024-06-06T20:02:29.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.840614", lon: "12722.601228", latD: "N", lonD: "E", latitude: 0.7640102333333334, longitude: 127.37668713333333, timestamp: "2024-06-06T20:02:29.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.840608", lon: "12722.601236", latD: "N", lonD: "E", latitude: 0.7640101333333333, longitude: 127.37668726666666, timestamp: "2024-06-06T20:02:49.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.840608", lon: "12722.601236", latD: "N", lonD: "E", latitude: 0.7640101333333333, longitude: 127.37668726666666, timestamp: "2024-06-06T20:02:49.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.840608", lon: "12722.601236", latD: "N", lonD: "E", latitude: 0.7640101333333333, longitude: 127.37668726666666, timestamp: "2024-06-06T20:02:49.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.6", satellites: "10", hdop: "0.6", lat: "0045.840608", lon: "12722.601236", latD: "N", lonD: "E", latitude: 0.7640101333333333, longitude: 127.37668726666666, timestamp: "2024-06-06T20:02:49.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.5", satellites: "09", hdop: "0.6", lat: "0045.840636", lon: "12722.601241", latD: "N", lonD: "E", latitude: 0.7640106000000001, longitude: 127.37668735, timestamp: "2024-06-06T20:03:09.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.5", satellites: "09", hdop: "0.6", lat: "0045.840636", lon: "12722.601241", latD: "N", lonD: "E", latitude: 0.7640106000000001, longitude: 127.37668735, timestamp: "2024-06-06T20:03:09.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.5", satellites: "09", hdop: "0.6", lat: "0045.840636", lon: "12722.601241", latD: "N", lonD: "E", latitude: 0.7640106000000001, longitude: 127.37668735, timestamp: "2024-06-06T20:03:09.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.5", satellites: "09", hdop: "0.6", lat: "0045.840636", lon: "12722.601241", latD: "N", lonD: "E", latitude: 0.7640106000000001, longitude: 127.37668735, timestamp: "2024-06-06T20:03:09.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.840605", lon: "12722.601141", latD: "N", lonD: "E", latitude: 0.7640100833333333, longitude: 127.37668568333333, timestamp: "2024-06-06T20:03:30.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.840605", lon: "12722.601141", latD: "N", lonD: "E", latitude: 0.7640100833333333, longitude: 127.37668568333333, timestamp: "2024-06-06T20:03:30.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.840605", lon: "12722.601141", latD: "N", lonD: "E", latitude: 0.7640100833333333, longitude: 127.37668568333333, timestamp: "2024-06-06T20:03:30.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.840605", lon: "12722.601141", latD: "N", lonD: "E", latitude: 0.7640100833333333, longitude: 127.37668568333333, timestamp: "2024-06-06T20:03:30.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.840537", lon: "12722.601044", latD: "N", lonD: "E", latitude: 0.7640089499999999, longitude: 127.37668406666667, timestamp: "2024-06-06T20:03:49.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.840537", lon: "12722.601044", latD: "N", lonD: "E", latitude: 0.7640089499999999, longitude: 127.37668406666667, timestamp: "2024-06-06T20:03:49.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.840537", lon: "12722.601044", latD: "N", lonD: "E", latitude: 0.7640089499999999, longitude: 127.37668406666667, timestamp: "2024-06-06T20:03:49.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.8", satellites: "10", hdop: "0.6", lat: "0045.840559", lon: "12722.600956", latD: "N", lonD: "E", latitude: 0.7640093166666666, longitude: 127.3766826, timestamp: "2024-06-06T20:04:09.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.7", satellites: "10", hdop: "0.6", lat: "0045.840537", lon: "12722.601044", latD: "N", lonD: "E", latitude: 0.7640089499999999, longitude: 127.37668406666667, timestamp: "2024-06-06T20:03:49.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.8", satellites: "10", hdop: "0.6", lat: "0045.840559", lon: "12722.600956", latD: "N", lonD: "E", latitude: 0.7640093166666666, longitude: 127.3766826, timestamp: "2024-06-06T20:04:09.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.8", satellites: "10", hdop: "0.6", lat: "0045.840559", lon: "12722.600956", latD: "N", lonD: "E", latitude: 0.7640093166666666, longitude: 127.3766826, timestamp: "2024-06-06T20:04:09.000Z" },
        { speed: "0.0", course: "115.7", altitude: "8.8", satellites: "10", hdop: "0.6", lat: "0045.840559", lon: "12722.600956", latD: "N", lonD: "E", latitude: 0.7640093166666666, longitude: 127.3766826, timestamp: "2024-06-06T20:04:09.000Z" },
        { speed: "0.0", course: "315.1", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.841096", lon: "12722.600961", latD: "N", lonD: "E", latitude: 0.7640182666666667, longitude: 127.37668268333333, timestamp: "2024-06-06T20:04:30.000Z" },
        { speed: "0.0", course: "315.1", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.841096", lon: "12722.600961", latD: "N", lonD: "E", latitude: 0.7640182666666667, longitude: 127.37668268333333, timestamp: "2024-06-06T20:04:30.000Z" },
        { speed: "0.0", course: "315.1", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.841096", lon: "12722.600961", latD: "N", lonD: "E", latitude: 0.7640182666666667, longitude: 127.37668268333333, timestamp: "2024-06-06T20:04:30.000Z" },
        { speed: "0.0", course: "315.1", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.841090", lon: "12722.600921", latD: "N", lonD: "E", latitude: 0.7640181666666667, longitude: 127.37668201666666, timestamp: "2024-06-06T20:04:49.000Z" },
        { speed: "0.0", course: "315.1", altitude: "10.0", satellites: "10", hdop: "0.6", lat: "0045.841096", lon: "12722.600961", latD: "N", lonD: "E", latitude: 0.7640182666666667, longitude: 127.37668268333333, timestamp: "2024-06-06T20:04:30.000Z" },
        { speed: "0.0", course: "315.1", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.841090", lon: "12722.600921", latD: "N", lonD: "E", latitude: 0.7640181666666667, longitude: 127.37668201666666, timestamp: "2024-06-06T20:04:49.000Z" },
        { speed: "0.0", course: "315.1", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.841090", lon: "12722.600921", latD: "N", lonD: "E", latitude: 0.7640181666666667, longitude: 127.37668201666666, timestamp: "2024-06-06T20:04:49.000Z" },
        { speed: "0.0", course: "315.1", altitude: "9.8", satellites: "10", hdop: "0.6", lat: "0045.841090", lon: "12722.600921", latD: "N", lonD: "E", latitude: 0.7640181666666667, longitude: 127.37668201666666, timestamp: "2024-06-06T20:04:49.000Z" },
        { speed: "0.0", course: "311.2", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.841074", lon: "12722.600704", latD: "N", lonD: "E", latitude: 0.7640179, longitude: 127.3766784, timestamp: "2024-06-06T20:05:10.000Z" },
        { speed: "0.0", course: "311.2", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.841074", lon: "12722.600704", latD: "N", lonD: "E", latitude: 0.7640179, longitude: 127.3766784, timestamp: "2024-06-06T20:05:10.000Z" },
        { speed: "0.0", course: "311.2", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.841074", lon: "12722.600704", latD: "N", lonD: "E", latitude: 0.7640179, longitude: 127.3766784, timestamp: "2024-06-06T20:05:10.000Z" },
        { speed: "0.0", course: "311.2", altitude: "6.6", satellites: "10", hdop: "0.6", lat: "0045.841074", lon: "12722.600704", latD: "N", lonD: "E", latitude: 0.7640179, longitude: 127.3766784, timestamp: "2024-06-06T20:05:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.839847", lon: "12722.598548", latD: "N", lonD: "E", latitude: 0.76399745, longitude: 127.37664246666667, timestamp: "2024-06-06T20:05:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.839847", lon: "12722.598548", latD: "N", lonD: "E", latitude: 0.76399745, longitude: 127.37664246666667, timestamp: "2024-06-06T20:05:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.839847", lon: "12722.598548", latD: "N", lonD: "E", latitude: 0.76399745, longitude: 127.37664246666667, timestamp: "2024-06-06T20:05:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "10", hdop: "0.6", lat: "0045.839847", lon: "12722.598548", latD: "N", lonD: "E", latitude: 0.76399745, longitude: 127.37664246666667, timestamp: "2024-06-06T20:05:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "07", hdop: "0.8", lat: "0045.839860", lon: "12722.598557", latD: "N", lonD: "E", latitude: 0.7639976666666667, longitude: 127.37664261666667, timestamp: "2024-06-06T20:05:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "07", hdop: "0.8", lat: "0045.839860", lon: "12722.598557", latD: "N", lonD: "E", latitude: 0.7639976666666667, longitude: 127.37664261666667, timestamp: "2024-06-06T20:05:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "07", hdop: "0.8", lat: "0045.839871", lon: "12722.598562", latD: "N", lonD: "E", latitude: 0.7639978500000001, longitude: 127.3766427, timestamp: "2024-06-06T20:06:09.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "07", hdop: "0.8", lat: "0045.839860", lon: "12722.598557", latD: "N", lonD: "E", latitude: 0.7639976666666667, longitude: 127.37664261666667, timestamp: "2024-06-06T20:05:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "07", hdop: "0.8", lat: "0045.839860", lon: "12722.598557", latD: "N", lonD: "E", latitude: 0.7639976666666667, longitude: 127.37664261666667, timestamp: "2024-06-06T20:05:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "07", hdop: "0.8", lat: "0045.839871", lon: "12722.598562", latD: "N", lonD: "E", latitude: 0.7639978500000001, longitude: 127.3766427, timestamp: "2024-06-06T20:06:09.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "07", hdop: "0.8", lat: "0045.839871", lon: "12722.598562", latD: "N", lonD: "E", latitude: 0.7639978500000001, longitude: 127.3766427, timestamp: "2024-06-06T20:06:09.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "07", hdop: "0.8", lat: "0045.839871", lon: "12722.598562", latD: "N", lonD: "E", latitude: 0.7639978500000001, longitude: 127.3766427, timestamp: "2024-06-06T20:06:09.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "08", hdop: "0.7", lat: "0045.839879", lon: "12722.598577", latD: "N", lonD: "E", latitude: 0.7639979833333334, longitude: 127.37664295, timestamp: "2024-06-06T20:06:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "08", hdop: "0.7", lat: "0045.839879", lon: "12722.598577", latD: "N", lonD: "E", latitude: 0.7639979833333334, longitude: 127.37664295, timestamp: "2024-06-06T20:06:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "08", hdop: "0.7", lat: "0045.839879", lon: "12722.598577", latD: "N", lonD: "E", latitude: 0.7639979833333334, longitude: 127.37664295, timestamp: "2024-06-06T20:06:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.2", satellites: "08", hdop: "0.7", lat: "0045.839879", lon: "12722.598577", latD: "N", lonD: "E", latitude: 0.7639979833333334, longitude: 127.37664295, timestamp: "2024-06-06T20:06:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.1", satellites: "08", hdop: "0.7", lat: "0045.839887", lon: "12722.598579", latD: "N", lonD: "E", latitude: 0.7639981166666666, longitude: 127.37664298333334, timestamp: "2024-06-06T20:06:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.1", satellites: "08", hdop: "0.7", lat: "0045.839887", lon: "12722.598579", latD: "N", lonD: "E", latitude: 0.7639981166666666, longitude: 127.37664298333334, timestamp: "2024-06-06T20:06:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.1", satellites: "08", hdop: "0.7", lat: "0045.839887", lon: "12722.598579", latD: "N", lonD: "E", latitude: 0.7639981166666666, longitude: 127.37664298333334, timestamp: "2024-06-06T20:06:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.1", satellites: "08", hdop: "0.7", lat: "0045.839887", lon: "12722.598579", latD: "N", lonD: "E", latitude: 0.7639981166666666, longitude: 127.37664298333334, timestamp: "2024-06-06T20:06:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.1", satellites: "08", hdop: "0.7", lat: "0045.839893", lon: "12722.598538", latD: "N", lonD: "E", latitude: 0.7639982166666666, longitude: 127.3766423, timestamp: "2024-06-06T20:07:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.1", satellites: "08", hdop: "0.7", lat: "0045.839893", lon: "12722.598538", latD: "N", lonD: "E", latitude: 0.7639982166666666, longitude: 127.3766423, timestamp: "2024-06-06T20:07:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.1", satellites: "08", hdop: "0.7", lat: "0045.839893", lon: "12722.598538", latD: "N", lonD: "E", latitude: 0.7639982166666666, longitude: 127.3766423, timestamp: "2024-06-06T20:07:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.1", satellites: "08", hdop: "0.7", lat: "0045.839893", lon: "12722.598538", latD: "N", lonD: "E", latitude: 0.7639982166666666, longitude: 127.3766423, timestamp: "2024-06-06T20:07:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.0", satellites: "09", hdop: "0.6", lat: "0045.839895", lon: "12722.598538", latD: "N", lonD: "E", latitude: 0.76399825, longitude: 127.3766423, timestamp: "2024-06-06T20:07:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.0", satellites: "09", hdop: "0.6", lat: "0045.839895", lon: "12722.598538", latD: "N", lonD: "E", latitude: 0.76399825, longitude: 127.3766423, timestamp: "2024-06-06T20:07:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.0", satellites: "09", hdop: "0.6", lat: "0045.839895", lon: "12722.598538", latD: "N", lonD: "E", latitude: 0.76399825, longitude: 127.3766423, timestamp: "2024-06-06T20:07:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "4.0", satellites: "09", hdop: "0.6", lat: "0045.839895", lon: "12722.598538", latD: "N", lonD: "E", latitude: 0.76399825, longitude: 127.3766423, timestamp: "2024-06-06T20:07:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.9", satellites: "09", hdop: "0.7", lat: "0045.839895", lon: "12722.598541", latD: "N", lonD: "E", latitude: 0.76399825, longitude: 127.37664235, timestamp: "2024-06-06T20:07:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.9", satellites: "09", hdop: "0.7", lat: "0045.839895", lon: "12722.598541", latD: "N", lonD: "E", latitude: 0.76399825, longitude: 127.37664235, timestamp: "2024-06-06T20:07:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.9", satellites: "09", hdop: "0.7", lat: "0045.839895", lon: "12722.598541", latD: "N", lonD: "E", latitude: 0.76399825, longitude: 127.37664235, timestamp: "2024-06-06T20:07:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.9", satellites: "09", hdop: "0.7", lat: "0045.839895", lon: "12722.598541", latD: "N", lonD: "E", latitude: 0.76399825, longitude: 127.37664235, timestamp: "2024-06-06T20:07:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.9", satellites: "09", hdop: "0.6", lat: "0045.839915", lon: "12722.598525", latD: "N", lonD: "E", latitude: 0.7639985833333333, longitude: 127.37664208333334, timestamp: "2024-06-06T20:08:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.9", satellites: "09", hdop: "0.6", lat: "0045.839915", lon: "12722.598525", latD: "N", lonD: "E", latitude: 0.7639985833333333, longitude: 127.37664208333334, timestamp: "2024-06-06T20:08:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.9", satellites: "09", hdop: "0.6", lat: "0045.839915", lon: "12722.598525", latD: "N", lonD: "E", latitude: 0.7639985833333333, longitude: 127.37664208333334, timestamp: "2024-06-06T20:08:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.9", satellites: "09", hdop: "0.6", lat: "0045.839915", lon: "12722.598525", latD: "N", lonD: "E", latitude: 0.7639985833333333, longitude: 127.37664208333334, timestamp: "2024-06-06T20:08:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.839914", lon: "12722.598529", latD: "N", lonD: "E", latitude: 0.7639985666666667, longitude: 127.37664215, timestamp: "2024-06-06T20:08:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.839914", lon: "12722.598529", latD: "N", lonD: "E", latitude: 0.7639985666666667, longitude: 127.37664215, timestamp: "2024-06-06T20:08:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.839914", lon: "12722.598529", latD: "N", lonD: "E", latitude: 0.7639985666666667, longitude: 127.37664215, timestamp: "2024-06-06T20:08:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.839914", lon: "12722.598529", latD: "N", lonD: "E", latitude: 0.7639985666666667, longitude: 127.37664215, timestamp: "2024-06-06T20:08:30.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.839943", lon: "12722.598526", latD: "N", lonD: "E", latitude: 0.76399905, longitude: 127.3766421, timestamp: "2024-06-06T20:08:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.839943", lon: "12722.598526", latD: "N", lonD: "E", latitude: 0.76399905, longitude: 127.3766421, timestamp: "2024-06-06T20:08:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.839943", lon: "12722.598526", latD: "N", lonD: "E", latitude: 0.76399905, longitude: 127.3766421, timestamp: "2024-06-06T20:08:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.8", satellites: "09", hdop: "0.6", lat: "0045.839943", lon: "12722.598526", latD: "N", lonD: "E", latitude: 0.76399905, longitude: 127.3766421, timestamp: "2024-06-06T20:08:50.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.839938", lon: "12722.598512", latD: "N", lonD: "E", latitude: 0.7639989666666666, longitude: 127.37664186666666, timestamp: "2024-06-06T20:09:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.839938", lon: "12722.598512", latD: "N", lonD: "E", latitude: 0.7639989666666666, longitude: 127.37664186666666, timestamp: "2024-06-06T20:09:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.839938", lon: "12722.598512", latD: "N", lonD: "E", latitude: 0.7639989666666666, longitude: 127.37664186666666, timestamp: "2024-06-06T20:09:10.000Z" },
        { speed: "0.0", course: "290.8", altitude: "3.7", satellites: "09", hdop: "0.6", lat: "0045.839938", lon: "12722.598512", latD: "N", lonD: "E", latitude: 0.7639989666666666, longitude: 127.37664186666666, timestamp: "2024-06-06T20:09:10.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.839385", lon: "12722.597635", latD: "N", lonD: "E", latitude: 0.76398975, longitude: 127.37662725, timestamp: "2024-06-06T20:09:30.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.839385", lon: "12722.597635", latD: "N", lonD: "E", latitude: 0.76398975, longitude: 127.37662725, timestamp: "2024-06-06T20:09:30.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.839385", lon: "12722.597635", latD: "N", lonD: "E", latitude: 0.76398975, longitude: 127.37662725, timestamp: "2024-06-06T20:09:30.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.7", satellites: "09", hdop: "0.6", lat: "0045.839385", lon: "12722.597635", latD: "N", lonD: "E", latitude: 0.76398975, longitude: 127.37662725, timestamp: "2024-06-06T20:09:30.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "08", hdop: "0.7", lat: "0045.839402", lon: "12722.597633", latD: "N", lonD: "E", latitude: 0.7639900333333334, longitude: 127.37662721666666, timestamp: "2024-06-06T20:09:50.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "08", hdop: "0.7", lat: "0045.839402", lon: "12722.597633", latD: "N", lonD: "E", latitude: 0.7639900333333334, longitude: 127.37662721666666, timestamp: "2024-06-06T20:09:50.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "08", hdop: "0.7", lat: "0045.839402", lon: "12722.597633", latD: "N", lonD: "E", latitude: 0.7639900333333334, longitude: 127.37662721666666, timestamp: "2024-06-06T20:09:50.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "08", hdop: "0.7", lat: "0045.839402", lon: "12722.597633", latD: "N", lonD: "E", latitude: 0.7639900333333334, longitude: 127.37662721666666, timestamp: "2024-06-06T20:09:50.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.839396", lon: "12722.597619", latD: "N", lonD: "E", latitude: 0.7639899333333333, longitude: 127.37662698333334, timestamp: "2024-06-06T20:10:10.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.839396", lon: "12722.597619", latD: "N", lonD: "E", latitude: 0.7639899333333333, longitude: 127.37662698333334, timestamp: "2024-06-06T20:10:10.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.839396", lon: "12722.597619", latD: "N", lonD: "E", latitude: 0.7639899333333333, longitude: 127.37662698333334, timestamp: "2024-06-06T20:10:10.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.839396", lon: "12722.597619", latD: "N", lonD: "E", latitude: 0.7639899333333333, longitude: 127.37662698333334, timestamp: "2024-06-06T20:10:10.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.839401", lon: "12722.597622", latD: "N", lonD: "E", latitude: 0.7639900166666667, longitude: 127.37662703333334, timestamp: "2024-06-06T20:10:30.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.839401", lon: "12722.597622", latD: "N", lonD: "E", latitude: 0.7639900166666667, longitude: 127.37662703333334, timestamp: "2024-06-06T20:10:30.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.839401", lon: "12722.597622", latD: "N", lonD: "E", latitude: 0.7639900166666667, longitude: 127.37662703333334, timestamp: "2024-06-06T20:10:30.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.839401", lon: "12722.597622", latD: "N", lonD: "E", latitude: 0.7639900166666667, longitude: 127.37662703333334, timestamp: "2024-06-06T20:10:30.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "08", hdop: "0.6", lat: "0045.839419", lon: "12722.597606", latD: "N", lonD: "E", latitude: 0.7639903166666666, longitude: 127.37662676666666, timestamp: "2024-06-06T20:10:50.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "08", hdop: "0.6", lat: "0045.839419", lon: "12722.597606", latD: "N", lonD: "E", latitude: 0.7639903166666666, longitude: 127.37662676666666, timestamp: "2024-06-06T20:10:50.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "08", hdop: "0.6", lat: "0045.839419", lon: "12722.597606", latD: "N", lonD: "E", latitude: 0.7639903166666666, longitude: 127.37662676666666, timestamp: "2024-06-06T20:10:50.000Z" },
        { speed: "0.0", course: "245.1", altitude: "4.8", satellites: "08", hdop: "0.6", lat: "0045.839419", lon: "12722.597606", latD: "N", lonD: "E", latitude: 0.7639903166666666, longitude: 127.37662676666666, timestamp: "2024-06-06T20:10:50.000Z" },
        { speed: "0.0", course: "272.9", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.840879", lon: "12722.596147", latD: "N", lonD: "E", latitude: 0.76401465, longitude: 127.37660245, timestamp: "2024-06-06T20:11:10.000Z" },
        { speed: "0.0", course: "272.9", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.840879", lon: "12722.596147", latD: "N", lonD: "E", latitude: 0.76401465, longitude: 127.37660245, timestamp: "2024-06-06T20:11:10.000Z" },
        { speed: "0.0", course: "272.9", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.840879", lon: "12722.596147", latD: "N", lonD: "E", latitude: 0.76401465, longitude: 127.37660245, timestamp: "2024-06-06T20:11:10.000Z" },
        { speed: "0.0", course: "272.9", altitude: "4.8", satellites: "09", hdop: "0.6", lat: "0045.840879", lon: "12722.596147", latD: "N", lonD: "E", latitude: 0.76401465, longitude: 127.37660245, timestamp: "2024-06-06T20:11:10.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.5", satellites: "09", hdop: "0.7", lat: "0045.841166", lon: "12722.595854", latD: "N", lonD: "E", latitude: 0.7640194333333333, longitude: 127.37659756666666, timestamp: "2024-06-06T20:11:30.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.5", satellites: "09", hdop: "0.7", lat: "0045.841166", lon: "12722.595854", latD: "N", lonD: "E", latitude: 0.7640194333333333, longitude: 127.37659756666666, timestamp: "2024-06-06T20:11:30.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.5", satellites: "09", hdop: "0.7", lat: "0045.841166", lon: "12722.595854", latD: "N", lonD: "E", latitude: 0.7640194333333333, longitude: 127.37659756666666, timestamp: "2024-06-06T20:11:30.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.5", satellites: "09", hdop: "0.7", lat: "0045.841166", lon: "12722.595854", latD: "N", lonD: "E", latitude: 0.7640194333333333, longitude: 127.37659756666666, timestamp: "2024-06-06T20:11:30.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.841073", lon: "12722.595977", latD: "N", lonD: "E", latitude: 0.7640178833333333, longitude: 127.37659961666667, timestamp: "2024-06-06T20:11:50.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.841073", lon: "12722.595977", latD: "N", lonD: "E", latitude: 0.7640178833333333, longitude: 127.37659961666667, timestamp: "2024-06-06T20:11:50.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.841073", lon: "12722.595977", latD: "N", lonD: "E", latitude: 0.7640178833333333, longitude: 127.37659961666667, timestamp: "2024-06-06T20:11:50.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.841073", lon: "12722.595977", latD: "N", lonD: "E", latitude: 0.7640178833333333, longitude: 127.37659961666667, timestamp: "2024-06-06T20:11:50.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.841015", lon: "12722.595989", latD: "N", lonD: "E", latitude: 0.7640169166666666, longitude: 127.37659981666667, timestamp: "2024-06-06T20:12:10.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.841015", lon: "12722.595989", latD: "N", lonD: "E", latitude: 0.7640169166666666, longitude: 127.37659981666667, timestamp: "2024-06-06T20:12:10.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.841015", lon: "12722.595989", latD: "N", lonD: "E", latitude: 0.7640169166666666, longitude: 127.37659981666667, timestamp: "2024-06-06T20:12:10.000Z" },
        { speed: "0.0", course: "272.9", altitude: "5.6", satellites: "09", hdop: "0.6", lat: "0045.841015", lon: "12722.595989", latD: "N", lonD: "E", latitude: 0.7640169166666666, longitude: 127.37659981666667, timestamp: "2024-06-06T20:12:10.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.4", satellites: "09", hdop: "0.7", lat: "0045.840299", lon: "12722.595779", latD: "N", lonD: "E", latitude: 0.7640049833333333, longitude: 127.37659631666666, timestamp: "2024-06-06T20:12:30.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.4", satellites: "09", hdop: "0.7", lat: "0045.840299", lon: "12722.595779", latD: "N", lonD: "E", latitude: 0.7640049833333333, longitude: 127.37659631666666, timestamp: "2024-06-06T20:12:30.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.4", satellites: "09", hdop: "0.7", lat: "0045.840299", lon: "12722.595779", latD: "N", lonD: "E", latitude: 0.7640049833333333, longitude: 127.37659631666666, timestamp: "2024-06-06T20:12:30.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.4", satellites: "09", hdop: "0.7", lat: "0045.840299", lon: "12722.595779", latD: "N", lonD: "E", latitude: 0.7640049833333333, longitude: 127.37659631666666, timestamp: "2024-06-06T20:12:30.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.4", satellites: "09", hdop: "0.7", lat: "0045.840241", lon: "12722.595778", latD: "N", lonD: "E", latitude: 0.7640040166666666, longitude: 127.3765963, timestamp: "2024-06-06T20:12:50.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.4", satellites: "09", hdop: "0.7", lat: "0045.840241", lon: "12722.595778", latD: "N", lonD: "E", latitude: 0.7640040166666666, longitude: 127.3765963, timestamp: "2024-06-06T20:12:50.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.4", satellites: "09", hdop: "0.7", lat: "0045.840241", lon: "12722.595778", latD: "N", lonD: "E", latitude: 0.7640040166666666, longitude: 127.3765963, timestamp: "2024-06-06T20:12:50.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.4", satellites: "09", hdop: "0.7", lat: "0045.840241", lon: "12722.595778", latD: "N", lonD: "E", latitude: 0.7640040166666666, longitude: 127.3765963, timestamp: "2024-06-06T20:12:50.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.840263", lon: "12722.595840", latD: "N", lonD: "E", latitude: 0.7640043833333333, longitude: 127.37659733333334, timestamp: "2024-06-06T20:13:10.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.840263", lon: "12722.595840", latD: "N", lonD: "E", latitude: 0.7640043833333333, longitude: 127.37659733333334, timestamp: "2024-06-06T20:13:10.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.840263", lon: "12722.595840", latD: "N", lonD: "E", latitude: 0.7640043833333333, longitude: 127.37659733333334, timestamp: "2024-06-06T20:13:10.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.840263", lon: "12722.595840", latD: "N", lonD: "E", latitude: 0.7640043833333333, longitude: 127.37659733333334, timestamp: "2024-06-06T20:13:10.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.840242", lon: "12722.595917", latD: "N", lonD: "E", latitude: 0.7640040333333334, longitude: 127.37659861666667, timestamp: "2024-06-06T20:13:30.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.840242", lon: "12722.595917", latD: "N", lonD: "E", latitude: 0.7640040333333334, longitude: 127.37659861666667, timestamp: "2024-06-06T20:13:30.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.840242", lon: "12722.595917", latD: "N", lonD: "E", latitude: 0.7640040333333334, longitude: 127.37659861666667, timestamp: "2024-06-06T20:13:30.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.6", satellites: "09", hdop: "0.6", lat: "0045.840242", lon: "12722.595917", latD: "N", lonD: "E", latitude: 0.7640040333333334, longitude: 127.37659861666667, timestamp: "2024-06-06T20:13:30.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.840245", lon: "12722.595919", latD: "N", lonD: "E", latitude: 0.7640040833333334, longitude: 127.37659865, timestamp: "2024-06-06T20:13:50.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.840245", lon: "12722.595919", latD: "N", lonD: "E", latitude: 0.7640040833333334, longitude: 127.37659865, timestamp: "2024-06-06T20:13:50.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.840245", lon: "12722.595919", latD: "N", lonD: "E", latitude: 0.7640040833333334, longitude: 127.37659865, timestamp: "2024-06-06T20:13:50.000Z" },
        { speed: "0.0", course: "109.6", altitude: "6.5", satellites: "09", hdop: "0.6", lat: "0045.840245", lon: "12722.595919", latD: "N", lonD: "E", latitude: 0.7640040833333334, longitude: 127.37659865, timestamp: "2024-06-06T20:13:50.000Z" },
        { speed: "0.0", course: "80.2", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.840242", lon: "12722.595195", latD: "N", lonD: "E", latitude: 0.7640040333333334, longitude: 127.37658658333334, timestamp: "2024-06-06T20:14:10.000Z" },
        { speed: "0.0", course: "80.2", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.840242", lon: "12722.595195", latD: "N", lonD: "E", latitude: 0.7640040333333334, longitude: 127.37658658333334, timestamp: "2024-06-06T20:14:10.000Z" },
        { speed: "0.0", course: "80.2", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.840242", lon: "12722.595195", latD: "N", lonD: "E", latitude: 0.7640040333333334, longitude: 127.37658658333334, timestamp: "2024-06-06T20:14:10.000Z" },
        { speed: "0.0", course: "80.2", altitude: "4.6", satellites: "09", hdop: "0.6", lat: "0045.840242", lon: "12722.595195", latD: "N", lonD: "E", latitude: 0.7640040333333334, longitude: 127.37658658333334, timestamp: "2024-06-06T20:14:10.000Z" },
        { speed: "0.0", course: "285.2", altitude: "-1.0", satellites: "09", hdop: "0.6", lat: "0045.839661", lon: "12722.595256", latD: "N", lonD: "E", latitude: 0.76399435, longitude: 127.3765876, timestamp: "2024-06-06T20:14:30.000Z" },
        { speed: "0.0", course: "285.2", altitude: "-1.0", satellites: "09", hdop: "0.6", lat: "0045.839661", lon: "12722.595256", latD: "N", lonD: "E", latitude: 0.76399435, longitude: 127.3765876, timestamp: "2024-06-06T20:14:30.000Z" },
        { speed: "0.0", course: "285.2", altitude: "-1.0", satellites: "09", hdop: "0.6", lat: "0045.839661", lon: "12722.595256", latD: "N", lonD: "E", latitude: 0.76399435, longitude: 127.3765876, timestamp: "2024-06-06T20:14:30.000Z" },
        { speed: "0.0", course: "285.2", altitude: "-1.0", satellites: "09", hdop: "0.6", lat: "0045.839661", lon: "12722.595256", latD: "N", lonD: "E", latitude: 0.76399435, longitude: 127.3765876, timestamp: "2024-06-06T20:14:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "10", hdop: "0.6", lat: "0045.839406", lon: "12722.596339", latD: "N", lonD: "E", latitude: 0.7639900999999999, longitude: 127.37660565, timestamp: "2024-06-06T20:14:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "10", hdop: "0.6", lat: "0045.839406", lon: "12722.596339", latD: "N", lonD: "E", latitude: 0.7639900999999999, longitude: 127.37660565, timestamp: "2024-06-06T20:14:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "10", hdop: "0.6", lat: "0045.839406", lon: "12722.596339", latD: "N", lonD: "E", latitude: 0.7639900999999999, longitude: 127.37660565, timestamp: "2024-06-06T20:14:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "10", hdop: "0.6", lat: "0045.839406", lon: "12722.596339", latD: "N", lonD: "E", latitude: 0.7639900999999999, longitude: 127.37660565, timestamp: "2024-06-06T20:14:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839422", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639903666666666, longitude: 127.37660648333333, timestamp: "2024-06-06T20:15:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839422", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639903666666666, longitude: 127.37660648333333, timestamp: "2024-06-06T20:15:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839422", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639903666666666, longitude: 127.37660648333333, timestamp: "2024-06-06T20:15:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839422", lon: "12722.596389", latD: "N", lonD: "E", latitude: 0.7639903666666666, longitude: 127.37660648333333, timestamp: "2024-06-06T20:15:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839420", lon: "12722.596414", latD: "N", lonD: "E", latitude: 0.7639903333333333, longitude: 127.3766069, timestamp: "2024-06-06T20:15:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839420", lon: "12722.596414", latD: "N", lonD: "E", latitude: 0.7639903333333333, longitude: 127.3766069, timestamp: "2024-06-06T20:15:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839420", lon: "12722.596414", latD: "N", lonD: "E", latitude: 0.7639903333333333, longitude: 127.3766069, timestamp: "2024-06-06T20:15:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839420", lon: "12722.596414", latD: "N", lonD: "E", latitude: 0.7639903333333333, longitude: 127.3766069, timestamp: "2024-06-06T20:15:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839415", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.7639902500000001, longitude: 127.37660693333333, timestamp: "2024-06-06T20:15:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839415", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.7639902500000001, longitude: 127.37660693333333, timestamp: "2024-06-06T20:15:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839415", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.7639902500000001, longitude: 127.37660693333333, timestamp: "2024-06-06T20:15:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "08", hdop: "0.7", lat: "0045.839415", lon: "12722.596416", latD: "N", lonD: "E", latitude: 0.7639902500000001, longitude: 127.37660693333333, timestamp: "2024-06-06T20:15:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839408", lon: "12722.596411", latD: "N", lonD: "E", latitude: 0.7639901333333333, longitude: 127.37660685, timestamp: "2024-06-06T20:16:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839408", lon: "12722.596411", latD: "N", lonD: "E", latitude: 0.7639901333333333, longitude: 127.37660685, timestamp: "2024-06-06T20:16:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839408", lon: "12722.596411", latD: "N", lonD: "E", latitude: 0.7639901333333333, longitude: 127.37660685, timestamp: "2024-06-06T20:16:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839408", lon: "12722.596411", latD: "N", lonD: "E", latitude: 0.7639901333333333, longitude: 127.37660685, timestamp: "2024-06-06T20:16:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839406", lon: "12722.596414", latD: "N", lonD: "E", latitude: 0.7639900999999999, longitude: 127.3766069, timestamp: "2024-06-06T20:16:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839406", lon: "12722.596414", latD: "N", lonD: "E", latitude: 0.7639900999999999, longitude: 127.3766069, timestamp: "2024-06-06T20:16:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839406", lon: "12722.596414", latD: "N", lonD: "E", latitude: 0.7639900999999999, longitude: 127.3766069, timestamp: "2024-06-06T20:16:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839406", lon: "12722.596414", latD: "N", lonD: "E", latitude: 0.7639900999999999, longitude: 127.3766069, timestamp: "2024-06-06T20:16:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839394", lon: "12722.596434", latD: "N", lonD: "E", latitude: 0.7639899, longitude: 127.37660723333333, timestamp: "2024-06-06T20:16:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839394", lon: "12722.596434", latD: "N", lonD: "E", latitude: 0.7639899, longitude: 127.37660723333333, timestamp: "2024-06-06T20:16:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839394", lon: "12722.596434", latD: "N", lonD: "E", latitude: 0.7639899, longitude: 127.37660723333333, timestamp: "2024-06-06T20:16:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.7", lat: "0045.839394", lon: "12722.596434", latD: "N", lonD: "E", latitude: 0.7639899, longitude: 127.37660723333333, timestamp: "2024-06-06T20:16:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839382", lon: "12722.596447", latD: "N", lonD: "E", latitude: 0.7639897, longitude: 127.37660745, timestamp: "2024-06-06T20:17:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839382", lon: "12722.596447", latD: "N", lonD: "E", latitude: 0.7639897, longitude: 127.37660745, timestamp: "2024-06-06T20:17:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839382", lon: "12722.596447", latD: "N", lonD: "E", latitude: 0.7639897, longitude: 127.37660745, timestamp: "2024-06-06T20:17:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839382", lon: "12722.596447", latD: "N", lonD: "E", latitude: 0.7639897, longitude: 127.37660745, timestamp: "2024-06-06T20:17:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839368", lon: "12722.596460", latD: "N", lonD: "E", latitude: 0.7639894666666667, longitude: 127.37660766666667, timestamp: "2024-06-06T20:17:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839368", lon: "12722.596460", latD: "N", lonD: "E", latitude: 0.7639894666666667, longitude: 127.37660766666667, timestamp: "2024-06-06T20:17:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839368", lon: "12722.596460", latD: "N", lonD: "E", latitude: 0.7639894666666667, longitude: 127.37660766666667, timestamp: "2024-06-06T20:17:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839368", lon: "12722.596460", latD: "N", lonD: "E", latitude: 0.7639894666666667, longitude: 127.37660766666667, timestamp: "2024-06-06T20:17:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839375", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639895833333333, longitude: 127.37660776666667, timestamp: "2024-06-06T20:17:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839375", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639895833333333, longitude: 127.37660776666667, timestamp: "2024-06-06T20:17:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839375", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639895833333333, longitude: 127.37660776666667, timestamp: "2024-06-06T20:17:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839375", lon: "12722.596466", latD: "N", lonD: "E", latitude: 0.7639895833333333, longitude: 127.37660776666667, timestamp: "2024-06-06T20:17:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839385", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.76398975, longitude: 127.37660798333333, timestamp: "2024-06-06T20:18:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839385", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.76398975, longitude: 127.37660798333333, timestamp: "2024-06-06T20:18:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839385", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.76398975, longitude: 127.37660798333333, timestamp: "2024-06-06T20:18:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "10", hdop: "0.6", lat: "0045.839385", lon: "12722.596479", latD: "N", lonD: "E", latitude: 0.76398975, longitude: 127.37660798333333, timestamp: "2024-06-06T20:18:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839384", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.7639897333333334, longitude: 127.37660785, timestamp: "2024-06-06T20:18:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839384", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.7639897333333334, longitude: 127.37660785, timestamp: "2024-06-06T20:18:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839384", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.7639897333333334, longitude: 127.37660785, timestamp: "2024-06-06T20:18:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839387", lon: "12722.596440", latD: "N", lonD: "E", latitude: 0.7639897833333333, longitude: 127.37660733333334, timestamp: "2024-06-06T20:18:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839387", lon: "12722.596440", latD: "N", lonD: "E", latitude: 0.7639897833333333, longitude: 127.37660733333334, timestamp: "2024-06-06T20:18:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.1", satellites: "09", hdop: "0.6", lat: "0045.839384", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.7639897333333334, longitude: 127.37660785, timestamp: "2024-06-06T20:18:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839387", lon: "12722.596440", latD: "N", lonD: "E", latitude: 0.7639897833333333, longitude: 127.37660733333334, timestamp: "2024-06-06T20:18:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839387", lon: "12722.596440", latD: "N", lonD: "E", latitude: 0.7639897833333333, longitude: 127.37660733333334, timestamp: "2024-06-06T20:18:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839388", lon: "12722.596441", latD: "N", lonD: "E", latitude: 0.7639897999999999, longitude: 127.37660735, timestamp: "2024-06-06T20:19:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839388", lon: "12722.596441", latD: "N", lonD: "E", latitude: 0.7639897999999999, longitude: 127.37660735, timestamp: "2024-06-06T20:19:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839388", lon: "12722.596441", latD: "N", lonD: "E", latitude: 0.7639897999999999, longitude: 127.37660735, timestamp: "2024-06-06T20:19:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839388", lon: "12722.596441", latD: "N", lonD: "E", latitude: 0.7639897999999999, longitude: 127.37660735, timestamp: "2024-06-06T20:19:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839380", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639896666666667, longitude: 127.37660765, timestamp: "2024-06-06T20:19:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839380", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639896666666667, longitude: 127.37660765, timestamp: "2024-06-06T20:19:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839380", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639896666666667, longitude: 127.37660765, timestamp: "2024-06-06T20:19:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.7", lat: "0045.839380", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639896666666667, longitude: 127.37660765, timestamp: "2024-06-06T20:19:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "10", hdop: "0.6", lat: "0045.839379", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.76398965, longitude: 127.37660785, timestamp: "2024-06-06T20:19:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "10", hdop: "0.6", lat: "0045.839379", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.76398965, longitude: 127.37660785, timestamp: "2024-06-06T20:19:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "10", hdop: "0.6", lat: "0045.839379", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.76398965, longitude: 127.37660785, timestamp: "2024-06-06T20:19:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "10", hdop: "0.6", lat: "0045.839379", lon: "12722.596471", latD: "N", lonD: "E", latitude: 0.76398965, longitude: 127.37660785, timestamp: "2024-06-06T20:19:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839372", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639895333333333, longitude: 127.37660765, timestamp: "2024-06-06T20:20:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839372", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639895333333333, longitude: 127.37660765, timestamp: "2024-06-06T20:20:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839372", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639895333333333, longitude: 127.37660765, timestamp: "2024-06-06T20:20:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839367", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639894500000001, longitude: 127.37660765, timestamp: "2024-06-06T20:20:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839367", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639894500000001, longitude: 127.37660765, timestamp: "2024-06-06T20:20:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "08", hdop: "0.7", lat: "0045.839372", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639895333333333, longitude: 127.37660765, timestamp: "2024-06-06T20:20:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839367", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639894500000001, longitude: 127.37660765, timestamp: "2024-06-06T20:20:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.2", satellites: "09", hdop: "0.6", lat: "0045.839367", lon: "12722.596459", latD: "N", lonD: "E", latitude: 0.7639894500000001, longitude: 127.37660765, timestamp: "2024-06-06T20:20:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839349", lon: "12722.596475", latD: "N", lonD: "E", latitude: 0.76398915, longitude: 127.37660791666667, timestamp: "2024-06-06T20:20:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839349", lon: "12722.596475", latD: "N", lonD: "E", latitude: 0.76398915, longitude: 127.37660791666667, timestamp: "2024-06-06T20:20:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839349", lon: "12722.596475", latD: "N", lonD: "E", latitude: 0.76398915, longitude: 127.37660791666667, timestamp: "2024-06-06T20:20:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.3", satellites: "09", hdop: "0.7", lat: "0045.839349", lon: "12722.596475", latD: "N", lonD: "E", latitude: 0.76398915, longitude: 127.37660791666667, timestamp: "2024-06-06T20:20:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839349", lon: "12722.596483", latD: "N", lonD: "E", latitude: 0.76398915, longitude: 127.37660805, timestamp: "2024-06-06T20:21:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839349", lon: "12722.596483", latD: "N", lonD: "E", latitude: 0.76398915, longitude: 127.37660805, timestamp: "2024-06-06T20:21:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839349", lon: "12722.596483", latD: "N", lonD: "E", latitude: 0.76398915, longitude: 127.37660805, timestamp: "2024-06-06T20:21:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.3", satellites: "10", hdop: "0.6", lat: "0045.839349", lon: "12722.596483", latD: "N", lonD: "E", latitude: 0.76398915, longitude: 127.37660805, timestamp: "2024-06-06T20:21:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "10", hdop: "0.6", lat: "0045.839340", lon: "12722.596525", latD: "N", lonD: "E", latitude: 0.763989, longitude: 127.37660875, timestamp: "2024-06-06T20:21:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "10", hdop: "0.6", lat: "0045.839340", lon: "12722.596525", latD: "N", lonD: "E", latitude: 0.763989, longitude: 127.37660875, timestamp: "2024-06-06T20:21:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "10", hdop: "0.6", lat: "0045.839340", lon: "12722.596525", latD: "N", lonD: "E", latitude: 0.763989, longitude: 127.37660875, timestamp: "2024-06-06T20:21:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "10", hdop: "0.6", lat: "0045.839331", lon: "12722.596556", latD: "N", lonD: "E", latitude: 0.76398885, longitude: 127.37660926666666, timestamp: "2024-06-06T20:21:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "10", hdop: "0.6", lat: "0045.839340", lon: "12722.596525", latD: "N", lonD: "E", latitude: 0.763989, longitude: 127.37660875, timestamp: "2024-06-06T20:21:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "10", hdop: "0.6", lat: "0045.839331", lon: "12722.596556", latD: "N", lonD: "E", latitude: 0.76398885, longitude: 127.37660926666666, timestamp: "2024-06-06T20:21:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "10", hdop: "0.6", lat: "0045.839331", lon: "12722.596556", latD: "N", lonD: "E", latitude: 0.76398885, longitude: 127.37660926666666, timestamp: "2024-06-06T20:21:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "10", hdop: "0.6", lat: "0045.839331", lon: "12722.596556", latD: "N", lonD: "E", latitude: 0.76398885, longitude: 127.37660926666666, timestamp: "2024-06-06T20:21:50.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "08", hdop: "0.7", lat: "0045.839313", lon: "12722.596578", latD: "N", lonD: "E", latitude: 0.76398855, longitude: 127.37660963333333, timestamp: "2024-06-06T20:22:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "08", hdop: "0.7", lat: "0045.839313", lon: "12722.596578", latD: "N", lonD: "E", latitude: 0.76398855, longitude: 127.37660963333333, timestamp: "2024-06-06T20:22:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "08", hdop: "0.7", lat: "0045.839313", lon: "12722.596578", latD: "N", lonD: "E", latitude: 0.76398855, longitude: 127.37660963333333, timestamp: "2024-06-06T20:22:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "08", hdop: "0.7", lat: "0045.839313", lon: "12722.596578", latD: "N", lonD: "E", latitude: 0.76398855, longitude: 127.37660963333333, timestamp: "2024-06-06T20:22:10.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.839301", lon: "12722.596598", latD: "N", lonD: "E", latitude: 0.76398835, longitude: 127.37660996666666, timestamp: "2024-06-06T20:22:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.839301", lon: "12722.596598", latD: "N", lonD: "E", latitude: 0.76398835, longitude: 127.37660996666666, timestamp: "2024-06-06T20:22:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.839301", lon: "12722.596598", latD: "N", lonD: "E", latitude: 0.76398835, longitude: 127.37660996666666, timestamp: "2024-06-06T20:22:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.5", satellites: "09", hdop: "0.6", lat: "0045.839301", lon: "12722.596598", latD: "N", lonD: "E", latitude: 0.76398835, longitude: 127.37660996666666, timestamp: "2024-06-06T20:22:30.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "09", hdop: "0.6", lat: "0045.839308", lon: "12722.596553", latD: "N", lonD: "E", latitude: 0.7639884666666668, longitude: 127.37660921666667, timestamp: "2024-06-06T20:22:51.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "09", hdop: "0.6", lat: "0045.839308", lon: "12722.596553", latD: "N", lonD: "E", latitude: 0.7639884666666668, longitude: 127.37660921666667, timestamp: "2024-06-06T20:22:51.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "09", hdop: "0.6", lat: "0045.839308", lon: "12722.596553", latD: "N", lonD: "E", latitude: 0.7639884666666668, longitude: 127.37660921666667, timestamp: "2024-06-06T20:22:51.000Z" },
        { speed: "0.0", course: "303.4", altitude: "-0.4", satellites: "09", hdop: "0.6", lat: "0045.839308", lon: "12722.596553", latD: "N", lonD: "E", latitude: 0.7639884666666668, longitude: 127.37660921666667, timestamp: "2024-06-06T20:22:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.6", lat: "0045.839897", lon: "12722.595988", latD: "N", lonD: "E", latitude: 0.7639982833333333, longitude: 127.3765998, timestamp: "2024-06-06T20:23:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.6", lat: "0045.839897", lon: "12722.595988", latD: "N", lonD: "E", latitude: 0.7639982833333333, longitude: 127.3765998, timestamp: "2024-06-06T20:23:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.7", lat: "0045.839894", lon: "12722.595981", latD: "N", lonD: "E", latitude: 0.7639982333333334, longitude: 127.37659968333334, timestamp: "2024-06-06T20:23:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.6", lat: "0045.839897", lon: "12722.595988", latD: "N", lonD: "E", latitude: 0.7639982833333333, longitude: 127.3765998, timestamp: "2024-06-06T20:23:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.6", lat: "0045.839897", lon: "12722.595988", latD: "N", lonD: "E", latitude: 0.7639982833333333, longitude: 127.3765998, timestamp: "2024-06-06T20:23:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.7", lat: "0045.839894", lon: "12722.595981", latD: "N", lonD: "E", latitude: 0.7639982333333334, longitude: 127.37659968333334, timestamp: "2024-06-06T20:23:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.7", lat: "0045.839894", lon: "12722.595981", latD: "N", lonD: "E", latitude: 0.7639982333333334, longitude: 127.37659968333334, timestamp: "2024-06-06T20:23:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.7", lat: "0045.839894", lon: "12722.595981", latD: "N", lonD: "E", latitude: 0.7639982333333334, longitude: 127.37659968333334, timestamp: "2024-06-06T20:23:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.7", lat: "0045.839909", lon: "12722.595993", latD: "N", lonD: "E", latitude: 0.7639984833333333, longitude: 127.37659988333333, timestamp: "2024-06-06T20:23:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.7", lat: "0045.839909", lon: "12722.595993", latD: "N", lonD: "E", latitude: 0.7639984833333333, longitude: 127.37659988333333, timestamp: "2024-06-06T20:23:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.7", lat: "0045.839909", lon: "12722.595993", latD: "N", lonD: "E", latitude: 0.7639984833333333, longitude: 127.37659988333333, timestamp: "2024-06-06T20:23:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "08", hdop: "0.7", lat: "0045.839907", lon: "12722.596009", latD: "N", lonD: "E", latitude: 0.76399845, longitude: 127.37660015, timestamp: "2024-06-06T20:24:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "09", hdop: "0.7", lat: "0045.839909", lon: "12722.595993", latD: "N", lonD: "E", latitude: 0.7639984833333333, longitude: 127.37659988333333, timestamp: "2024-06-06T20:23:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "08", hdop: "0.7", lat: "0045.839907", lon: "12722.596009", latD: "N", lonD: "E", latitude: 0.76399845, longitude: 127.37660015, timestamp: "2024-06-06T20:24:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "08", hdop: "0.7", lat: "0045.839907", lon: "12722.596009", latD: "N", lonD: "E", latitude: 0.76399845, longitude: 127.37660015, timestamp: "2024-06-06T20:24:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "08", hdop: "0.7", lat: "0045.839907", lon: "12722.596009", latD: "N", lonD: "E", latitude: 0.76399845, longitude: 127.37660015, timestamp: "2024-06-06T20:24:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.839922", lon: "12722.596003", latD: "N", lonD: "E", latitude: 0.7639987, longitude: 127.37660005, timestamp: "2024-06-06T20:24:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.839922", lon: "12722.596003", latD: "N", lonD: "E", latitude: 0.7639987, longitude: 127.37660005, timestamp: "2024-06-06T20:24:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.839922", lon: "12722.596003", latD: "N", lonD: "E", latitude: 0.7639987, longitude: 127.37660005, timestamp: "2024-06-06T20:24:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.839922", lon: "12722.596003", latD: "N", lonD: "E", latitude: 0.7639987, longitude: 127.37660005, timestamp: "2024-06-06T20:24:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.839945", lon: "12722.596023", latD: "N", lonD: "E", latitude: 0.7639990833333333, longitude: 127.37660038333334, timestamp: "2024-06-06T20:24:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.839945", lon: "12722.596023", latD: "N", lonD: "E", latitude: 0.7639990833333333, longitude: 127.37660038333334, timestamp: "2024-06-06T20:24:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.839945", lon: "12722.596023", latD: "N", lonD: "E", latitude: 0.7639990833333333, longitude: 127.37660038333334, timestamp: "2024-06-06T20:24:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.839945", lon: "12722.596023", latD: "N", lonD: "E", latitude: 0.7639990833333333, longitude: 127.37660038333334, timestamp: "2024-06-06T20:24:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.3", satellites: "10", hdop: "0.6", lat: "0045.839956", lon: "12722.596052", latD: "N", lonD: "E", latitude: 0.7639992666666667, longitude: 127.37660086666666, timestamp: "2024-06-06T20:25:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.3", satellites: "10", hdop: "0.6", lat: "0045.839956", lon: "12722.596052", latD: "N", lonD: "E", latitude: 0.7639992666666667, longitude: 127.37660086666666, timestamp: "2024-06-06T20:25:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.3", satellites: "10", hdop: "0.6", lat: "0045.839956", lon: "12722.596052", latD: "N", lonD: "E", latitude: 0.7639992666666667, longitude: 127.37660086666666, timestamp: "2024-06-06T20:25:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.3", satellites: "10", hdop: "0.6", lat: "0045.839956", lon: "12722.596052", latD: "N", lonD: "E", latitude: 0.7639992666666667, longitude: 127.37660086666666, timestamp: "2024-06-06T20:25:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.3", satellites: "10", hdop: "0.6", lat: "0045.839977", lon: "12722.596071", latD: "N", lonD: "E", latitude: 0.7639996166666666, longitude: 127.37660118333334, timestamp: "2024-06-06T20:25:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.3", satellites: "10", hdop: "0.6", lat: "0045.839977", lon: "12722.596071", latD: "N", lonD: "E", latitude: 0.7639996166666666, longitude: 127.37660118333334, timestamp: "2024-06-06T20:25:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.3", satellites: "10", hdop: "0.6", lat: "0045.839977", lon: "12722.596071", latD: "N", lonD: "E", latitude: 0.7639996166666666, longitude: 127.37660118333334, timestamp: "2024-06-06T20:25:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.3", satellites: "10", hdop: "0.6", lat: "0045.839977", lon: "12722.596071", latD: "N", lonD: "E", latitude: 0.7639996166666666, longitude: 127.37660118333334, timestamp: "2024-06-06T20:25:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840026", lon: "12722.596072", latD: "N", lonD: "E", latitude: 0.7640004333333333, longitude: 127.3766012, timestamp: "2024-06-06T20:25:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840026", lon: "12722.596072", latD: "N", lonD: "E", latitude: 0.7640004333333333, longitude: 127.3766012, timestamp: "2024-06-06T20:25:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840026", lon: "12722.596072", latD: "N", lonD: "E", latitude: 0.7640004333333333, longitude: 127.3766012, timestamp: "2024-06-06T20:25:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840026", lon: "12722.596072", latD: "N", lonD: "E", latitude: 0.7640004333333333, longitude: 127.3766012, timestamp: "2024-06-06T20:25:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840041", lon: "12722.596058", latD: "N", lonD: "E", latitude: 0.7640006833333334, longitude: 127.37660096666667, timestamp: "2024-06-06T20:26:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840041", lon: "12722.596058", latD: "N", lonD: "E", latitude: 0.7640006833333334, longitude: 127.37660096666667, timestamp: "2024-06-06T20:26:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.840059", lon: "12722.596068", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.37660113333334, timestamp: "2024-06-06T20:26:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840041", lon: "12722.596058", latD: "N", lonD: "E", latitude: 0.7640006833333334, longitude: 127.37660096666667, timestamp: "2024-06-06T20:26:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.4", satellites: "10", hdop: "0.6", lat: "0045.840041", lon: "12722.596058", latD: "N", lonD: "E", latitude: 0.7640006833333334, longitude: 127.37660096666667, timestamp: "2024-06-06T20:26:10.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.840059", lon: "12722.596068", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.37660113333334, timestamp: "2024-06-06T20:26:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.840059", lon: "12722.596068", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.37660113333334, timestamp: "2024-06-06T20:26:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.840059", lon: "12722.596068", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.37660113333334, timestamp: "2024-06-06T20:26:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.840085", lon: "12722.596086", latD: "N", lonD: "E", latitude: 0.7640014166666667, longitude: 127.37660143333333, timestamp: "2024-06-06T20:26:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.840085", lon: "12722.596086", latD: "N", lonD: "E", latitude: 0.7640014166666667, longitude: 127.37660143333333, timestamp: "2024-06-06T20:26:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.840085", lon: "12722.596086", latD: "N", lonD: "E", latitude: 0.7640014166666667, longitude: 127.37660143333333, timestamp: "2024-06-06T20:26:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.5", satellites: "10", hdop: "0.6", lat: "0045.840085", lon: "12722.596086", latD: "N", lonD: "E", latitude: 0.7640014166666667, longitude: 127.37660143333333, timestamp: "2024-06-06T20:26:50.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "08", hdop: "0.7", lat: "0045.840110", lon: "12722.596079", latD: "N", lonD: "E", latitude: 0.7640018333333334, longitude: 127.37660131666667, timestamp: "2024-06-06T20:27:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "08", hdop: "0.7", lat: "0045.840110", lon: "12722.596079", latD: "N", lonD: "E", latitude: 0.7640018333333334, longitude: 127.37660131666667, timestamp: "2024-06-06T20:27:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "08", hdop: "0.7", lat: "0045.840110", lon: "12722.596079", latD: "N", lonD: "E", latitude: 0.7640018333333334, longitude: 127.37660131666667, timestamp: "2024-06-06T20:27:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "08", hdop: "0.7", lat: "0045.840110", lon: "12722.596079", latD: "N", lonD: "E", latitude: 0.7640018333333334, longitude: 127.37660131666667, timestamp: "2024-06-06T20:27:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "08", hdop: "0.7", lat: "0045.840115", lon: "12722.596064", latD: "N", lonD: "E", latitude: 0.7640019166666666, longitude: 127.37660106666667, timestamp: "2024-06-06T20:27:31.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "08", hdop: "0.7", lat: "0045.840115", lon: "12722.596064", latD: "N", lonD: "E", latitude: 0.7640019166666666, longitude: 127.37660106666667, timestamp: "2024-06-06T20:27:31.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "08", hdop: "0.7", lat: "0045.840115", lon: "12722.596064", latD: "N", lonD: "E", latitude: 0.7640019166666666, longitude: 127.37660106666667, timestamp: "2024-06-06T20:27:31.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.6", satellites: "08", hdop: "0.7", lat: "0045.840115", lon: "12722.596064", latD: "N", lonD: "E", latitude: 0.7640019166666666, longitude: 127.37660106666667, timestamp: "2024-06-06T20:27:31.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "09", hdop: "0.7", lat: "0045.840106", lon: "12722.596070", latD: "N", lonD: "E", latitude: 0.7640017666666666, longitude: 127.37660116666666, timestamp: "2024-06-06T20:27:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "09", hdop: "0.7", lat: "0045.840106", lon: "12722.596070", latD: "N", lonD: "E", latitude: 0.7640017666666666, longitude: 127.37660116666666, timestamp: "2024-06-06T20:27:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "09", hdop: "0.7", lat: "0045.840106", lon: "12722.596070", latD: "N", lonD: "E", latitude: 0.7640017666666666, longitude: 127.37660116666666, timestamp: "2024-06-06T20:27:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "09", hdop: "0.7", lat: "0045.840106", lon: "12722.596070", latD: "N", lonD: "E", latitude: 0.7640017666666666, longitude: 127.37660116666666, timestamp: "2024-06-06T20:27:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "09", hdop: "0.7", lat: "0045.840111", lon: "12722.596068", latD: "N", lonD: "E", latitude: 0.76400185, longitude: 127.37660113333334, timestamp: "2024-06-06T20:28:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "09", hdop: "0.7", lat: "0045.840111", lon: "12722.596068", latD: "N", lonD: "E", latitude: 0.76400185, longitude: 127.37660113333334, timestamp: "2024-06-06T20:28:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "09", hdop: "0.7", lat: "0045.840111", lon: "12722.596068", latD: "N", lonD: "E", latitude: 0.76400185, longitude: 127.37660113333334, timestamp: "2024-06-06T20:28:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "10", hdop: "0.6", lat: "0045.840093", lon: "12722.596089", latD: "N", lonD: "E", latitude: 0.76400155, longitude: 127.37660148333333, timestamp: "2024-06-06T20:28:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "09", hdop: "0.7", lat: "0045.840111", lon: "12722.596068", latD: "N", lonD: "E", latitude: 0.76400185, longitude: 127.37660113333334, timestamp: "2024-06-06T20:28:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "10", hdop: "0.6", lat: "0045.840093", lon: "12722.596089", latD: "N", lonD: "E", latitude: 0.76400155, longitude: 127.37660148333333, timestamp: "2024-06-06T20:28:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "10", hdop: "0.6", lat: "0045.840093", lon: "12722.596089", latD: "N", lonD: "E", latitude: 0.76400155, longitude: 127.37660148333333, timestamp: "2024-06-06T20:28:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.7", satellites: "10", hdop: "0.6", lat: "0045.840093", lon: "12722.596089", latD: "N", lonD: "E", latitude: 0.76400155, longitude: 127.37660148333333, timestamp: "2024-06-06T20:28:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840083", lon: "12722.596113", latD: "N", lonD: "E", latitude: 0.7640013833333333, longitude: 127.37660188333334, timestamp: "2024-06-06T20:28:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840083", lon: "12722.596113", latD: "N", lonD: "E", latitude: 0.7640013833333333, longitude: 127.37660188333334, timestamp: "2024-06-06T20:28:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840083", lon: "12722.596113", latD: "N", lonD: "E", latitude: 0.7640013833333333, longitude: 127.37660188333334, timestamp: "2024-06-06T20:28:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840083", lon: "12722.596113", latD: "N", lonD: "E", latitude: 0.7640013833333333, longitude: 127.37660188333334, timestamp: "2024-06-06T20:28:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840075", lon: "12722.596124", latD: "N", lonD: "E", latitude: 0.76400125, longitude: 127.37660206666666, timestamp: "2024-06-06T20:29:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840075", lon: "12722.596124", latD: "N", lonD: "E", latitude: 0.76400125, longitude: 127.37660206666666, timestamp: "2024-06-06T20:29:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840075", lon: "12722.596124", latD: "N", lonD: "E", latitude: 0.76400125, longitude: 127.37660206666666, timestamp: "2024-06-06T20:29:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840075", lon: "12722.596124", latD: "N", lonD: "E", latitude: 0.76400125, longitude: 127.37660206666666, timestamp: "2024-06-06T20:29:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840059", lon: "12722.596141", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.37660235, timestamp: "2024-06-06T20:29:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840059", lon: "12722.596141", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.37660235, timestamp: "2024-06-06T20:29:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840059", lon: "12722.596141", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.37660235, timestamp: "2024-06-06T20:29:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840059", lon: "12722.596141", latD: "N", lonD: "E", latitude: 0.7640009833333333, longitude: 127.37660235, timestamp: "2024-06-06T20:29:30.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840067", lon: "12722.596141", latD: "N", lonD: "E", latitude: 0.7640011166666666, longitude: 127.37660235, timestamp: "2024-06-06T20:29:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840067", lon: "12722.596141", latD: "N", lonD: "E", latitude: 0.7640011166666666, longitude: 127.37660235, timestamp: "2024-06-06T20:29:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840067", lon: "12722.596141", latD: "N", lonD: "E", latitude: 0.7640011166666666, longitude: 127.37660235, timestamp: "2024-06-06T20:29:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840067", lon: "12722.596141", latD: "N", lonD: "E", latitude: 0.7640011166666666, longitude: 127.37660235, timestamp: "2024-06-06T20:29:51.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840079", lon: "12722.596129", latD: "N", lonD: "E", latitude: 0.7640013166666667, longitude: 127.37660215, timestamp: "2024-06-06T20:30:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840079", lon: "12722.596129", latD: "N", lonD: "E", latitude: 0.7640013166666667, longitude: 127.37660215, timestamp: "2024-06-06T20:30:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840079", lon: "12722.596129", latD: "N", lonD: "E", latitude: 0.7640013166666667, longitude: 127.37660215, timestamp: "2024-06-06T20:30:11.000Z" },
        { speed: "0.0", course: "311.8", altitude: "-3.8", satellites: "10", hdop: "0.6", lat: "0045.840079", lon: "12722.596129", latD: "N", lonD: "E", latitude: 0.7640013166666667, longitude: 127.37660215, timestamp: "2024-06-06T20:30:11.000Z" },
        { speed: "0.0", course: "66.5", altitude: "-2.6", satellites: "10", hdop: "0.6", lat: "0045.840237", lon: "12722.595963", latD: "N", lonD: "E", latitude: 0.76400395, longitude: 127.37659938333333, timestamp: "2024-06-06T20:30:31.000Z" },
        { speed: "0.0", course: "66.5", altitude: "-2.6", satellites: "10", hdop: "0.6", lat: "0045.840237", lon: "12722.595963", latD: "N", lonD: "E", latitude: 0.76400395, longitude: 127.37659938333333, timestamp: "2024-06-06T20:30:31.000Z" },
        { speed: "0.0", course: "66.5", altitude: "-2.6", satellites: "10", hdop: "0.6", lat: "0045.840237", lon: "12722.595963", latD: "N", lonD: "E", latitude: 0.76400395, longitude: 127.37659938333333, timestamp: "2024-06-06T20:30:31.000Z" },
        { speed: "0.0", course: "66.5", altitude: "-2.6", satellites: "10", hdop: "0.6", lat: "0045.840237", lon: "12722.595963", latD: "N", lonD: "E", latitude: 0.76400395, longitude: 127.37659938333333, timestamp: "2024-06-06T20:30:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.840321", lon: "12722.595390", latD: "N", lonD: "E", latitude: 0.76400535, longitude: 127.37658983333333, timestamp: "2024-06-06T20:30:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.840321", lon: "12722.595390", latD: "N", lonD: "E", latitude: 0.76400535, longitude: 127.37658983333333, timestamp: "2024-06-06T20:30:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.840321", lon: "12722.595390", latD: "N", lonD: "E", latitude: 0.76400535, longitude: 127.37658983333333, timestamp: "2024-06-06T20:30:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.5", satellites: "10", hdop: "0.6", lat: "0045.840321", lon: "12722.595390", latD: "N", lonD: "E", latitude: 0.76400535, longitude: 127.37658983333333, timestamp: "2024-06-06T20:30:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840232", lon: "12722.595333", latD: "N", lonD: "E", latitude: 0.7640038666666666, longitude: 127.37658888333333, timestamp: "2024-06-06T20:31:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840232", lon: "12722.595333", latD: "N", lonD: "E", latitude: 0.7640038666666666, longitude: 127.37658888333333, timestamp: "2024-06-06T20:31:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840232", lon: "12722.595333", latD: "N", lonD: "E", latitude: 0.7640038666666666, longitude: 127.37658888333333, timestamp: "2024-06-06T20:31:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840232", lon: "12722.595333", latD: "N", lonD: "E", latitude: 0.7640038666666666, longitude: 127.37658888333333, timestamp: "2024-06-06T20:31:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840229", lon: "12722.595353", latD: "N", lonD: "E", latitude: 0.7640038166666667, longitude: 127.37658921666667, timestamp: "2024-06-06T20:31:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840229", lon: "12722.595353", latD: "N", lonD: "E", latitude: 0.7640038166666667, longitude: 127.37658921666667, timestamp: "2024-06-06T20:31:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840229", lon: "12722.595353", latD: "N", lonD: "E", latitude: 0.7640038166666667, longitude: 127.37658921666667, timestamp: "2024-06-06T20:31:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840229", lon: "12722.595353", latD: "N", lonD: "E", latitude: 0.7640038166666667, longitude: 127.37658921666667, timestamp: "2024-06-06T20:31:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840210", lon: "12722.595379", latD: "N", lonD: "E", latitude: 0.7640035, longitude: 127.37658965, timestamp: "2024-06-06T20:31:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840210", lon: "12722.595379", latD: "N", lonD: "E", latitude: 0.7640035, longitude: 127.37658965, timestamp: "2024-06-06T20:31:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840210", lon: "12722.595379", latD: "N", lonD: "E", latitude: 0.7640035, longitude: 127.37658965, timestamp: "2024-06-06T20:31:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.7", satellites: "10", hdop: "0.6", lat: "0045.840210", lon: "12722.595379", latD: "N", lonD: "E", latitude: 0.7640035, longitude: 127.37658965, timestamp: "2024-06-06T20:31:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.840189", lon: "12722.595432", latD: "N", lonD: "E", latitude: 0.7640031500000001, longitude: 127.37659053333333, timestamp: "2024-06-06T20:32:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.840189", lon: "12722.595432", latD: "N", lonD: "E", latitude: 0.7640031500000001, longitude: 127.37659053333333, timestamp: "2024-06-06T20:32:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.840189", lon: "12722.595432", latD: "N", lonD: "E", latitude: 0.7640031500000001, longitude: 127.37659053333333, timestamp: "2024-06-06T20:32:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.840189", lon: "12722.595432", latD: "N", lonD: "E", latitude: 0.7640031500000001, longitude: 127.37659053333333, timestamp: "2024-06-06T20:32:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.840182", lon: "12722.595486", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37659143333333, timestamp: "2024-06-06T20:32:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.840182", lon: "12722.595486", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37659143333333, timestamp: "2024-06-06T20:32:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.840182", lon: "12722.595486", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37659143333333, timestamp: "2024-06-06T20:32:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "10", hdop: "0.6", lat: "0045.840182", lon: "12722.595486", latD: "N", lonD: "E", latitude: 0.7640030333333333, longitude: 127.37659143333333, timestamp: "2024-06-06T20:32:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "09", hdop: "0.7", lat: "0045.840187", lon: "12722.595524", latD: "N", lonD: "E", latitude: 0.7640031166666666, longitude: 127.37659206666666, timestamp: "2024-06-06T20:32:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "09", hdop: "0.7", lat: "0045.840187", lon: "12722.595524", latD: "N", lonD: "E", latitude: 0.7640031166666666, longitude: 127.37659206666666, timestamp: "2024-06-06T20:32:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "09", hdop: "0.7", lat: "0045.840187", lon: "12722.595524", latD: "N", lonD: "E", latitude: 0.7640031166666666, longitude: 127.37659206666666, timestamp: "2024-06-06T20:32:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.8", satellites: "09", hdop: "0.7", lat: "0045.840187", lon: "12722.595524", latD: "N", lonD: "E", latitude: 0.7640031166666666, longitude: 127.37659206666666, timestamp: "2024-06-06T20:32:51.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.840186", lon: "12722.595526", latD: "N", lonD: "E", latitude: 0.7640031, longitude: 127.3765921, timestamp: "2024-06-06T20:33:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.840186", lon: "12722.595526", latD: "N", lonD: "E", latitude: 0.7640031, longitude: 127.3765921, timestamp: "2024-06-06T20:33:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.840186", lon: "12722.595526", latD: "N", lonD: "E", latitude: 0.7640031, longitude: 127.3765921, timestamp: "2024-06-06T20:33:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.9", satellites: "10", hdop: "0.6", lat: "0045.840186", lon: "12722.595526", latD: "N", lonD: "E", latitude: 0.7640031, longitude: 127.3765921, timestamp: "2024-06-06T20:33:11.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.9", satellites: "09", hdop: "0.7", lat: "0045.840207", lon: "12722.595580", latD: "N", lonD: "E", latitude: 0.76400345, longitude: 127.376593, timestamp: "2024-06-06T20:33:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.9", satellites: "09", hdop: "0.7", lat: "0045.840207", lon: "12722.595580", latD: "N", lonD: "E", latitude: 0.76400345, longitude: 127.376593, timestamp: "2024-06-06T20:33:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.9", satellites: "09", hdop: "0.7", lat: "0045.840207", lon: "12722.595580", latD: "N", lonD: "E", latitude: 0.76400345, longitude: 127.376593, timestamp: "2024-06-06T20:33:31.000Z" },
        { speed: "0.0", course: "231.6", altitude: "-0.9", satellites: "09", hdop: "0.7", lat: "0045.840207", lon: "12722.595580", latD: "N", lonD: "E", latitude: 0.76400345, longitude: 127.376593, timestamp: "2024-06-06T20:33:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "09", hdop: "0.7", lat: "0045.838955", lon: "12722.594411", latD: "N", lonD: "E", latitude: 0.7639825833333334, longitude: 127.37657351666667, timestamp: "2024-06-06T20:33:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "09", hdop: "0.7", lat: "0045.838955", lon: "12722.594411", latD: "N", lonD: "E", latitude: 0.7639825833333334, longitude: 127.37657351666667, timestamp: "2024-06-06T20:33:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "09", hdop: "0.7", lat: "0045.838955", lon: "12722.594411", latD: "N", lonD: "E", latitude: 0.7639825833333334, longitude: 127.37657351666667, timestamp: "2024-06-06T20:33:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "09", hdop: "0.7", lat: "0045.838955", lon: "12722.594411", latD: "N", lonD: "E", latitude: 0.7639825833333334, longitude: 127.37657351666667, timestamp: "2024-06-06T20:33:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.838990", lon: "12722.594347", latD: "N", lonD: "E", latitude: 0.7639831666666667, longitude: 127.37657245, timestamp: "2024-06-06T20:34:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.838990", lon: "12722.594347", latD: "N", lonD: "E", latitude: 0.7639831666666667, longitude: 127.37657245, timestamp: "2024-06-06T20:34:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.838990", lon: "12722.594347", latD: "N", lonD: "E", latitude: 0.7639831666666667, longitude: 127.37657245, timestamp: "2024-06-06T20:34:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.838990", lon: "12722.594347", latD: "N", lonD: "E", latitude: 0.7639831666666667, longitude: 127.37657245, timestamp: "2024-06-06T20:34:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.4", satellites: "09", hdop: "0.7", lat: "0045.839077", lon: "12722.594386", latD: "N", lonD: "E", latitude: 0.7639846166666667, longitude: 127.3765731, timestamp: "2024-06-06T20:34:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.4", satellites: "09", hdop: "0.7", lat: "0045.839077", lon: "12722.594386", latD: "N", lonD: "E", latitude: 0.7639846166666667, longitude: 127.3765731, timestamp: "2024-06-06T20:34:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.4", satellites: "09", hdop: "0.7", lat: "0045.839077", lon: "12722.594386", latD: "N", lonD: "E", latitude: 0.7639846166666667, longitude: 127.3765731, timestamp: "2024-06-06T20:34:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.4", satellites: "09", hdop: "0.7", lat: "0045.839077", lon: "12722.594386", latD: "N", lonD: "E", latitude: 0.7639846166666667, longitude: 127.3765731, timestamp: "2024-06-06T20:34:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.839086", lon: "12722.594421", latD: "N", lonD: "E", latitude: 0.7639847666666667, longitude: 127.37657368333333, timestamp: "2024-06-06T20:34:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.839086", lon: "12722.594421", latD: "N", lonD: "E", latitude: 0.7639847666666667, longitude: 127.37657368333333, timestamp: "2024-06-06T20:34:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.839086", lon: "12722.594421", latD: "N", lonD: "E", latitude: 0.7639847666666667, longitude: 127.37657368333333, timestamp: "2024-06-06T20:34:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.3", satellites: "09", hdop: "0.7", lat: "0045.839086", lon: "12722.594421", latD: "N", lonD: "E", latitude: 0.7639847666666667, longitude: 127.37657368333333, timestamp: "2024-06-06T20:34:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.2", satellites: "08", hdop: "0.7", lat: "0045.839112", lon: "12722.594468", latD: "N", lonD: "E", latitude: 0.7639852, longitude: 127.37657446666667, timestamp: "2024-06-06T20:35:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.2", satellites: "08", hdop: "0.7", lat: "0045.839112", lon: "12722.594468", latD: "N", lonD: "E", latitude: 0.7639852, longitude: 127.37657446666667, timestamp: "2024-06-06T20:35:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.2", satellites: "08", hdop: "0.7", lat: "0045.839112", lon: "12722.594468", latD: "N", lonD: "E", latitude: 0.7639852, longitude: 127.37657446666667, timestamp: "2024-06-06T20:35:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.2", satellites: "08", hdop: "0.7", lat: "0045.839112", lon: "12722.594468", latD: "N", lonD: "E", latitude: 0.7639852, longitude: 127.37657446666667, timestamp: "2024-06-06T20:35:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.1", satellites: "08", hdop: "0.7", lat: "0045.839141", lon: "12722.594532", latD: "N", lonD: "E", latitude: 0.7639856833333333, longitude: 127.37657553333334, timestamp: "2024-06-06T20:35:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.1", satellites: "08", hdop: "0.7", lat: "0045.839141", lon: "12722.594532", latD: "N", lonD: "E", latitude: 0.7639856833333333, longitude: 127.37657553333334, timestamp: "2024-06-06T20:35:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.1", satellites: "08", hdop: "0.7", lat: "0045.839141", lon: "12722.594532", latD: "N", lonD: "E", latitude: 0.7639856833333333, longitude: 127.37657553333334, timestamp: "2024-06-06T20:35:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.1", satellites: "08", hdop: "0.7", lat: "0045.839141", lon: "12722.594532", latD: "N", lonD: "E", latitude: 0.7639856833333333, longitude: 127.37657553333334, timestamp: "2024-06-06T20:35:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.0", satellites: "08", hdop: "0.7", lat: "0045.839158", lon: "12722.594606", latD: "N", lonD: "E", latitude: 0.7639859666666666, longitude: 127.37657676666667, timestamp: "2024-06-06T20:35:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.0", satellites: "08", hdop: "0.7", lat: "0045.839158", lon: "12722.594606", latD: "N", lonD: "E", latitude: 0.7639859666666666, longitude: 127.37657676666667, timestamp: "2024-06-06T20:35:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.0", satellites: "08", hdop: "0.7", lat: "0045.839158", lon: "12722.594606", latD: "N", lonD: "E", latitude: 0.7639859666666666, longitude: 127.37657676666667, timestamp: "2024-06-06T20:35:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-3.0", satellites: "08", hdop: "0.7", lat: "0045.839158", lon: "12722.594606", latD: "N", lonD: "E", latitude: 0.7639859666666666, longitude: 127.37657676666667, timestamp: "2024-06-06T20:35:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.839182", lon: "12722.594663", latD: "N", lonD: "E", latitude: 0.7639863666666666, longitude: 127.37657771666667, timestamp: "2024-06-06T20:36:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.839182", lon: "12722.594663", latD: "N", lonD: "E", latitude: 0.7639863666666666, longitude: 127.37657771666667, timestamp: "2024-06-06T20:36:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.839182", lon: "12722.594663", latD: "N", lonD: "E", latitude: 0.7639863666666666, longitude: 127.37657771666667, timestamp: "2024-06-06T20:36:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.839182", lon: "12722.594663", latD: "N", lonD: "E", latitude: 0.7639863666666666, longitude: 127.37657771666667, timestamp: "2024-06-06T20:36:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.839197", lon: "12722.594687", latD: "N", lonD: "E", latitude: 0.7639866166666667, longitude: 127.37657811666666, timestamp: "2024-06-06T20:36:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.839197", lon: "12722.594687", latD: "N", lonD: "E", latitude: 0.7639866166666667, longitude: 127.37657811666666, timestamp: "2024-06-06T20:36:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.839197", lon: "12722.594687", latD: "N", lonD: "E", latitude: 0.7639866166666667, longitude: 127.37657811666666, timestamp: "2024-06-06T20:36:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.8", satellites: "10", hdop: "0.6", lat: "0045.839197", lon: "12722.594687", latD: "N", lonD: "E", latitude: 0.7639866166666667, longitude: 127.37657811666666, timestamp: "2024-06-06T20:36:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.7", satellites: "10", hdop: "0.6", lat: "0045.839205", lon: "12722.594726", latD: "N", lonD: "E", latitude: 0.76398675, longitude: 127.37657876666667, timestamp: "2024-06-06T20:36:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.7", satellites: "10", hdop: "0.6", lat: "0045.839205", lon: "12722.594726", latD: "N", lonD: "E", latitude: 0.76398675, longitude: 127.37657876666667, timestamp: "2024-06-06T20:36:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.7", satellites: "10", hdop: "0.6", lat: "0045.839205", lon: "12722.594726", latD: "N", lonD: "E", latitude: 0.76398675, longitude: 127.37657876666667, timestamp: "2024-06-06T20:36:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.7", satellites: "10", hdop: "0.6", lat: "0045.839205", lon: "12722.594726", latD: "N", lonD: "E", latitude: 0.76398675, longitude: 127.37657876666667, timestamp: "2024-06-06T20:36:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.6", satellites: "10", hdop: "0.6", lat: "0045.839202", lon: "12722.594749", latD: "N", lonD: "E", latitude: 0.7639867, longitude: 127.37657915, timestamp: "2024-06-06T20:37:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.6", satellites: "10", hdop: "0.6", lat: "0045.839202", lon: "12722.594749", latD: "N", lonD: "E", latitude: 0.7639867, longitude: 127.37657915, timestamp: "2024-06-06T20:37:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.6", satellites: "10", hdop: "0.6", lat: "0045.839202", lon: "12722.594749", latD: "N", lonD: "E", latitude: 0.7639867, longitude: 127.37657915, timestamp: "2024-06-06T20:37:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.839216", lon: "12722.594771", latD: "N", lonD: "E", latitude: 0.7639869333333333, longitude: 127.37657951666667, timestamp: "2024-06-06T20:37:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.6", satellites: "10", hdop: "0.6", lat: "0045.839202", lon: "12722.594749", latD: "N", lonD: "E", latitude: 0.7639867, longitude: 127.37657915, timestamp: "2024-06-06T20:37:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.839216", lon: "12722.594771", latD: "N", lonD: "E", latitude: 0.7639869333333333, longitude: 127.37657951666667, timestamp: "2024-06-06T20:37:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.839216", lon: "12722.594771", latD: "N", lonD: "E", latitude: 0.7639869333333333, longitude: 127.37657951666667, timestamp: "2024-06-06T20:37:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.5", satellites: "10", hdop: "0.6", lat: "0045.839216", lon: "12722.594771", latD: "N", lonD: "E", latitude: 0.7639869333333333, longitude: 127.37657951666667, timestamp: "2024-06-06T20:37:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.4", satellites: "10", hdop: "0.6", lat: "0045.839235", lon: "12722.594813", latD: "N", lonD: "E", latitude: 0.76398725, longitude: 127.37658021666667, timestamp: "2024-06-06T20:37:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.4", satellites: "10", hdop: "0.6", lat: "0045.839235", lon: "12722.594813", latD: "N", lonD: "E", latitude: 0.76398725, longitude: 127.37658021666667, timestamp: "2024-06-06T20:37:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.4", satellites: "10", hdop: "0.6", lat: "0045.839235", lon: "12722.594813", latD: "N", lonD: "E", latitude: 0.76398725, longitude: 127.37658021666667, timestamp: "2024-06-06T20:37:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.4", satellites: "10", hdop: "0.6", lat: "0045.839235", lon: "12722.594813", latD: "N", lonD: "E", latitude: 0.76398725, longitude: 127.37658021666667, timestamp: "2024-06-06T20:37:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.3", satellites: "10", hdop: "0.6", lat: "0045.839270", lon: "12722.594838", latD: "N", lonD: "E", latitude: 0.7639878333333333, longitude: 127.37658063333333, timestamp: "2024-06-06T20:38:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.3", satellites: "10", hdop: "0.6", lat: "0045.839270", lon: "12722.594838", latD: "N", lonD: "E", latitude: 0.7639878333333333, longitude: 127.37658063333333, timestamp: "2024-06-06T20:38:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.3", satellites: "10", hdop: "0.6", lat: "0045.839270", lon: "12722.594838", latD: "N", lonD: "E", latitude: 0.7639878333333333, longitude: 127.37658063333333, timestamp: "2024-06-06T20:38:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.3", satellites: "10", hdop: "0.6", lat: "0045.839270", lon: "12722.594838", latD: "N", lonD: "E", latitude: 0.7639878333333333, longitude: 127.37658063333333, timestamp: "2024-06-06T20:38:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.2", satellites: "09", hdop: "0.7", lat: "0045.839289", lon: "12722.594849", latD: "N", lonD: "E", latitude: 0.76398815, longitude: 127.37658081666666, timestamp: "2024-06-06T20:38:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.2", satellites: "09", hdop: "0.7", lat: "0045.839289", lon: "12722.594849", latD: "N", lonD: "E", latitude: 0.76398815, longitude: 127.37658081666666, timestamp: "2024-06-06T20:38:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.2", satellites: "09", hdop: "0.7", lat: "0045.839289", lon: "12722.594849", latD: "N", lonD: "E", latitude: 0.76398815, longitude: 127.37658081666666, timestamp: "2024-06-06T20:38:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.2", satellites: "09", hdop: "0.7", lat: "0045.839289", lon: "12722.594849", latD: "N", lonD: "E", latitude: 0.76398815, longitude: 127.37658081666666, timestamp: "2024-06-06T20:38:31.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.0", satellites: "10", hdop: "0.6", lat: "0045.839321", lon: "12722.594869", latD: "N", lonD: "E", latitude: 0.7639886833333333, longitude: 127.37658115, timestamp: "2024-06-06T20:38:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.0", satellites: "10", hdop: "0.6", lat: "0045.839321", lon: "12722.594869", latD: "N", lonD: "E", latitude: 0.7639886833333333, longitude: 127.37658115, timestamp: "2024-06-06T20:38:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.0", satellites: "10", hdop: "0.6", lat: "0045.839321", lon: "12722.594869", latD: "N", lonD: "E", latitude: 0.7639886833333333, longitude: 127.37658115, timestamp: "2024-06-06T20:38:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.9", satellites: "10", hdop: "0.6", lat: "0045.839348", lon: "12722.594905", latD: "N", lonD: "E", latitude: 0.7639891333333334, longitude: 127.37658175, timestamp: "2024-06-06T20:39:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.9", satellites: "10", hdop: "0.6", lat: "0045.839348", lon: "12722.594905", latD: "N", lonD: "E", latitude: 0.7639891333333334, longitude: 127.37658175, timestamp: "2024-06-06T20:39:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-2.0", satellites: "10", hdop: "0.6", lat: "0045.839321", lon: "12722.594869", latD: "N", lonD: "E", latitude: 0.7639886833333333, longitude: 127.37658115, timestamp: "2024-06-06T20:38:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.9", satellites: "10", hdop: "0.6", lat: "0045.839348", lon: "12722.594905", latD: "N", lonD: "E", latitude: 0.7639891333333334, longitude: 127.37658175, timestamp: "2024-06-06T20:39:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.9", satellites: "10", hdop: "0.6", lat: "0045.839348", lon: "12722.594905", latD: "N", lonD: "E", latitude: 0.7639891333333334, longitude: 127.37658175, timestamp: "2024-06-06T20:39:11.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839374", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639895666666666, longitude: 127.37658201666666, timestamp: "2024-06-06T20:39:32.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839374", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639895666666666, longitude: 127.37658201666666, timestamp: "2024-06-06T20:39:32.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839374", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639895666666666, longitude: 127.37658201666666, timestamp: "2024-06-06T20:39:32.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.7", satellites: "09", hdop: "0.7", lat: "0045.839380", lon: "12722.594926", latD: "N", lonD: "E", latitude: 0.7639896666666667, longitude: 127.3765821, timestamp: "2024-06-06T20:39:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.7", satellites: "09", hdop: "0.7", lat: "0045.839380", lon: "12722.594926", latD: "N", lonD: "E", latitude: 0.7639896666666667, longitude: 127.3765821, timestamp: "2024-06-06T20:39:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.8", satellites: "10", hdop: "0.6", lat: "0045.839374", lon: "12722.594921", latD: "N", lonD: "E", latitude: 0.7639895666666666, longitude: 127.37658201666666, timestamp: "2024-06-06T20:39:32.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.7", satellites: "09", hdop: "0.7", lat: "0045.839380", lon: "12722.594926", latD: "N", lonD: "E", latitude: 0.7639896666666667, longitude: 127.3765821, timestamp: "2024-06-06T20:39:51.000Z" },
        { speed: "0.0", course: "245.9", altitude: "-1.7", satellites: "09", hdop: "0.7", lat: "0045.839380", lon: "12722.594926", latD: "N", lonD: "E", latitude: 0.7639896666666667, longitude: 127.3765821, timestamp: "2024-06-06T20:39:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.840494", lon: "12722.599028", latD: "N", lonD: "E", latitude: 0.7640082333333333, longitude: 127.37665046666666, timestamp: "2024-06-06T20:40:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.840494", lon: "12722.599028", latD: "N", lonD: "E", latitude: 0.7640082333333333, longitude: 127.37665046666666, timestamp: "2024-06-06T20:40:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.840494", lon: "12722.599028", latD: "N", lonD: "E", latitude: 0.7640082333333333, longitude: 127.37665046666666, timestamp: "2024-06-06T20:40:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "13.7", satellites: "10", hdop: "0.6", lat: "0045.840494", lon: "12722.599028", latD: "N", lonD: "E", latitude: 0.7640082333333333, longitude: 127.37665046666666, timestamp: "2024-06-06T20:40:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.6", satellites: "10", hdop: "0.6", lat: "0045.840594", lon: "12722.598437", latD: "N", lonD: "E", latitude: 0.7640099, longitude: 127.37664061666666, timestamp: "2024-06-06T20:40:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.6", satellites: "10", hdop: "0.6", lat: "0045.840594", lon: "12722.598437", latD: "N", lonD: "E", latitude: 0.7640099, longitude: 127.37664061666666, timestamp: "2024-06-06T20:40:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.6", satellites: "10", hdop: "0.6", lat: "0045.840594", lon: "12722.598437", latD: "N", lonD: "E", latitude: 0.7640099, longitude: 127.37664061666666, timestamp: "2024-06-06T20:40:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.6", satellites: "10", hdop: "0.6", lat: "0045.840594", lon: "12722.598437", latD: "N", lonD: "E", latitude: 0.7640099, longitude: 127.37664061666666, timestamp: "2024-06-06T20:40:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.840613", lon: "12722.598455", latD: "N", lonD: "E", latitude: 0.7640102166666666, longitude: 127.37664091666667, timestamp: "2024-06-06T20:40:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.840613", lon: "12722.598455", latD: "N", lonD: "E", latitude: 0.7640102166666666, longitude: 127.37664091666667, timestamp: "2024-06-06T20:40:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.840613", lon: "12722.598455", latD: "N", lonD: "E", latitude: 0.7640102166666666, longitude: 127.37664091666667, timestamp: "2024-06-06T20:40:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.5", satellites: "09", hdop: "0.6", lat: "0045.840613", lon: "12722.598455", latD: "N", lonD: "E", latitude: 0.7640102166666666, longitude: 127.37664091666667, timestamp: "2024-06-06T20:40:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.840622", lon: "12722.598461", latD: "N", lonD: "E", latitude: 0.7640103666666668, longitude: 127.37664101666667, timestamp: "2024-06-06T20:41:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.840622", lon: "12722.598461", latD: "N", lonD: "E", latitude: 0.7640103666666668, longitude: 127.37664101666667, timestamp: "2024-06-06T20:41:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.840622", lon: "12722.598461", latD: "N", lonD: "E", latitude: 0.7640103666666668, longitude: 127.37664101666667, timestamp: "2024-06-06T20:41:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.4", satellites: "09", hdop: "0.6", lat: "0045.840622", lon: "12722.598461", latD: "N", lonD: "E", latitude: 0.7640103666666668, longitude: 127.37664101666667, timestamp: "2024-06-06T20:41:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.840641", lon: "12722.598444", latD: "N", lonD: "E", latitude: 0.7640106833333333, longitude: 127.37664073333333, timestamp: "2024-06-06T20:41:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.840641", lon: "12722.598444", latD: "N", lonD: "E", latitude: 0.7640106833333333, longitude: 127.37664073333333, timestamp: "2024-06-06T20:41:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.840641", lon: "12722.598444", latD: "N", lonD: "E", latitude: 0.7640106833333333, longitude: 127.37664073333333, timestamp: "2024-06-06T20:41:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.3", satellites: "09", hdop: "0.6", lat: "0045.840641", lon: "12722.598444", latD: "N", lonD: "E", latitude: 0.7640106833333333, longitude: 127.37664073333333, timestamp: "2024-06-06T20:41:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.840658", lon: "12722.598434", latD: "N", lonD: "E", latitude: 0.7640109666666667, longitude: 127.37664056666667, timestamp: "2024-06-06T20:41:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.840658", lon: "12722.598434", latD: "N", lonD: "E", latitude: 0.7640109666666667, longitude: 127.37664056666667, timestamp: "2024-06-06T20:41:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.840658", lon: "12722.598434", latD: "N", lonD: "E", latitude: 0.7640109666666667, longitude: 127.37664056666667, timestamp: "2024-06-06T20:41:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.2", satellites: "08", hdop: "0.7", lat: "0045.840658", lon: "12722.598434", latD: "N", lonD: "E", latitude: 0.7640109666666667, longitude: 127.37664056666667, timestamp: "2024-06-06T20:41:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.840674", lon: "12722.598421", latD: "N", lonD: "E", latitude: 0.7640112333333333, longitude: 127.37664035, timestamp: "2024-06-06T20:42:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.840674", lon: "12722.598421", latD: "N", lonD: "E", latitude: 0.7640112333333333, longitude: 127.37664035, timestamp: "2024-06-06T20:42:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.840674", lon: "12722.598421", latD: "N", lonD: "E", latitude: 0.7640112333333333, longitude: 127.37664035, timestamp: "2024-06-06T20:42:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.840674", lon: "12722.598421", latD: "N", lonD: "E", latitude: 0.7640112333333333, longitude: 127.37664035, timestamp: "2024-06-06T20:42:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.840683", lon: "12722.598425", latD: "N", lonD: "E", latitude: 0.7640113833333333, longitude: 127.37664041666666, timestamp: "2024-06-06T20:42:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.840683", lon: "12722.598425", latD: "N", lonD: "E", latitude: 0.7640113833333333, longitude: 127.37664041666666, timestamp: "2024-06-06T20:42:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.840683", lon: "12722.598425", latD: "N", lonD: "E", latitude: 0.7640113833333333, longitude: 127.37664041666666, timestamp: "2024-06-06T20:42:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "09", hdop: "0.6", lat: "0045.840683", lon: "12722.598425", latD: "N", lonD: "E", latitude: 0.7640113833333333, longitude: 127.37664041666666, timestamp: "2024-06-06T20:42:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "10", hdop: "0.6", lat: "0045.840714", lon: "12722.598448", latD: "N", lonD: "E", latitude: 0.7640119, longitude: 127.3766408, timestamp: "2024-06-06T20:42:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "10", hdop: "0.6", lat: "0045.840714", lon: "12722.598448", latD: "N", lonD: "E", latitude: 0.7640119, longitude: 127.3766408, timestamp: "2024-06-06T20:42:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "10", hdop: "0.6", lat: "0045.840714", lon: "12722.598448", latD: "N", lonD: "E", latitude: 0.7640119, longitude: 127.3766408, timestamp: "2024-06-06T20:42:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.1", satellites: "10", hdop: "0.6", lat: "0045.840714", lon: "12722.598448", latD: "N", lonD: "E", latitude: 0.7640119, longitude: 127.3766408, timestamp: "2024-06-06T20:42:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.840735", lon: "12722.598432", latD: "N", lonD: "E", latitude: 0.7640122500000001, longitude: 127.37664053333333, timestamp: "2024-06-06T20:43:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.840735", lon: "12722.598432", latD: "N", lonD: "E", latitude: 0.7640122500000001, longitude: 127.37664053333333, timestamp: "2024-06-06T20:43:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.840735", lon: "12722.598432", latD: "N", lonD: "E", latitude: 0.7640122500000001, longitude: 127.37664053333333, timestamp: "2024-06-06T20:43:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.0", satellites: "09", hdop: "0.6", lat: "0045.840735", lon: "12722.598432", latD: "N", lonD: "E", latitude: 0.7640122500000001, longitude: 127.37664053333333, timestamp: "2024-06-06T20:43:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.840763", lon: "12722.598414", latD: "N", lonD: "E", latitude: 0.7640127166666667, longitude: 127.37664023333333, timestamp: "2024-06-06T20:43:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.840763", lon: "12722.598414", latD: "N", lonD: "E", latitude: 0.7640127166666667, longitude: 127.37664023333333, timestamp: "2024-06-06T20:43:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.840763", lon: "12722.598414", latD: "N", lonD: "E", latitude: 0.7640127166666667, longitude: 127.37664023333333, timestamp: "2024-06-06T20:43:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "9.0", satellites: "10", hdop: "0.6", lat: "0045.840763", lon: "12722.598414", latD: "N", lonD: "E", latitude: 0.7640127166666667, longitude: 127.37664023333333, timestamp: "2024-06-06T20:43:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.840791", lon: "12722.598400", latD: "N", lonD: "E", latitude: 0.7640131833333333, longitude: 127.37664, timestamp: "2024-06-06T20:43:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.840791", lon: "12722.598400", latD: "N", lonD: "E", latitude: 0.7640131833333333, longitude: 127.37664, timestamp: "2024-06-06T20:43:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.840791", lon: "12722.598400", latD: "N", lonD: "E", latitude: 0.7640131833333333, longitude: 127.37664, timestamp: "2024-06-06T20:43:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.9", satellites: "10", hdop: "0.6", lat: "0045.840791", lon: "12722.598400", latD: "N", lonD: "E", latitude: 0.7640131833333333, longitude: 127.37664, timestamp: "2024-06-06T20:43:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.840780", lon: "12722.598373", latD: "N", lonD: "E", latitude: 0.764013, longitude: 127.37663955, timestamp: "2024-06-06T20:44:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.840780", lon: "12722.598373", latD: "N", lonD: "E", latitude: 0.764013, longitude: 127.37663955, timestamp: "2024-06-06T20:44:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.840780", lon: "12722.598373", latD: "N", lonD: "E", latitude: 0.764013, longitude: 127.37663955, timestamp: "2024-06-06T20:44:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.8", satellites: "09", hdop: "0.6", lat: "0045.840780", lon: "12722.598373", latD: "N", lonD: "E", latitude: 0.764013, longitude: 127.37663955, timestamp: "2024-06-06T20:44:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.6", satellites: "09", hdop: "0.6", lat: "0045.840778", lon: "12722.598353", latD: "N", lonD: "E", latitude: 0.7640129666666666, longitude: 127.37663921666666, timestamp: "2024-06-06T20:44:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.6", satellites: "09", hdop: "0.6", lat: "0045.840778", lon: "12722.598353", latD: "N", lonD: "E", latitude: 0.7640129666666666, longitude: 127.37663921666666, timestamp: "2024-06-06T20:44:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.6", satellites: "09", hdop: "0.6", lat: "0045.840778", lon: "12722.598353", latD: "N", lonD: "E", latitude: 0.7640129666666666, longitude: 127.37663921666666, timestamp: "2024-06-06T20:44:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.6", satellites: "09", hdop: "0.6", lat: "0045.840778", lon: "12722.598353", latD: "N", lonD: "E", latitude: 0.7640129666666666, longitude: 127.37663921666666, timestamp: "2024-06-06T20:44:31.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.4", satellites: "08", hdop: "0.7", lat: "0045.840784", lon: "12722.598326", latD: "N", lonD: "E", latitude: 0.7640130666666667, longitude: 127.37663876666667, timestamp: "2024-06-06T20:44:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.4", satellites: "08", hdop: "0.7", lat: "0045.840784", lon: "12722.598326", latD: "N", lonD: "E", latitude: 0.7640130666666667, longitude: 127.37663876666667, timestamp: "2024-06-06T20:44:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.4", satellites: "08", hdop: "0.7", lat: "0045.840784", lon: "12722.598326", latD: "N", lonD: "E", latitude: 0.7640130666666667, longitude: 127.37663876666667, timestamp: "2024-06-06T20:44:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.4", satellites: "08", hdop: "0.7", lat: "0045.840784", lon: "12722.598326", latD: "N", lonD: "E", latitude: 0.7640130666666667, longitude: 127.37663876666667, timestamp: "2024-06-06T20:44:51.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.3", satellites: "08", hdop: "0.7", lat: "0045.840788", lon: "12722.598306", latD: "N", lonD: "E", latitude: 0.7640131333333334, longitude: 127.37663843333333, timestamp: "2024-06-06T20:45:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.3", satellites: "08", hdop: "0.7", lat: "0045.840788", lon: "12722.598306", latD: "N", lonD: "E", latitude: 0.7640131333333334, longitude: 127.37663843333333, timestamp: "2024-06-06T20:45:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.3", satellites: "08", hdop: "0.7", lat: "0045.840788", lon: "12722.598306", latD: "N", lonD: "E", latitude: 0.7640131333333334, longitude: 127.37663843333333, timestamp: "2024-06-06T20:45:11.000Z" },
        { speed: "0.0", course: "319.4", altitude: "8.3", satellites: "08", hdop: "0.7", lat: "0045.840788", lon: "12722.598306", latD: "N", lonD: "E", latitude: 0.7640131333333334, longitude: 127.37663843333333, timestamp: "2024-06-06T20:45:11.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "08", hdop: "0.7", lat: "0045.840707", lon: "12722.597310", latD: "N", lonD: "E", latitude: 0.7640117833333334, longitude: 127.37662183333333, timestamp: "2024-06-06T20:45:31.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "08", hdop: "0.7", lat: "0045.840707", lon: "12722.597310", latD: "N", lonD: "E", latitude: 0.7640117833333334, longitude: 127.37662183333333, timestamp: "2024-06-06T20:45:31.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "08", hdop: "0.7", lat: "0045.840707", lon: "12722.597310", latD: "N", lonD: "E", latitude: 0.7640117833333334, longitude: 127.37662183333333, timestamp: "2024-06-06T20:45:31.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "08", hdop: "0.7", lat: "0045.840707", lon: "12722.597310", latD: "N", lonD: "E", latitude: 0.7640117833333334, longitude: 127.37662183333333, timestamp: "2024-06-06T20:45:31.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "09", hdop: "0.6", lat: "0045.840664", lon: "12722.597236", latD: "N", lonD: "E", latitude: 0.7640110666666666, longitude: 127.3766206, timestamp: "2024-06-06T20:45:51.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "09", hdop: "0.6", lat: "0045.840664", lon: "12722.597236", latD: "N", lonD: "E", latitude: 0.7640110666666666, longitude: 127.3766206, timestamp: "2024-06-06T20:45:51.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "09", hdop: "0.6", lat: "0045.840664", lon: "12722.597236", latD: "N", lonD: "E", latitude: 0.7640110666666666, longitude: 127.3766206, timestamp: "2024-06-06T20:45:51.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "09", hdop: "0.6", lat: "0045.840664", lon: "12722.597236", latD: "N", lonD: "E", latitude: 0.7640110666666666, longitude: 127.3766206, timestamp: "2024-06-06T20:45:51.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "10", hdop: "0.6", lat: "0045.840675", lon: "12722.597222", latD: "N", lonD: "E", latitude: 0.7640112499999999, longitude: 127.37662036666667, timestamp: "2024-06-06T20:46:13.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "10", hdop: "0.6", lat: "0045.840675", lon: "12722.597222", latD: "N", lonD: "E", latitude: 0.7640112499999999, longitude: 127.37662036666667, timestamp: "2024-06-06T20:46:13.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "10", hdop: "0.6", lat: "0045.840675", lon: "12722.597222", latD: "N", lonD: "E", latitude: 0.7640112499999999, longitude: 127.37662036666667, timestamp: "2024-06-06T20:46:13.000Z" },
        { speed: "0.0", course: "219.2", altitude: "5.0", satellites: "10", hdop: "0.6", lat: "0045.840675", lon: "12722.597222", latD: "N", lonD: "E", latitude: 0.7640112499999999, longitude: 127.37662036666667, timestamp: "2024-06-06T20:46:13.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840706", lon: "12722.597201", latD: "N", lonD: "E", latitude: 0.7640117666666666, longitude: 127.37662001666666, timestamp: "2024-06-06T20:46:32.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840706", lon: "12722.597201", latD: "N", lonD: "E", latitude: 0.7640117666666666, longitude: 127.37662001666666, timestamp: "2024-06-06T20:46:32.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840706", lon: "12722.597201", latD: "N", lonD: "E", latitude: 0.7640117666666666, longitude: 127.37662001666666, timestamp: "2024-06-06T20:46:32.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840706", lon: "12722.597201", latD: "N", lonD: "E", latitude: 0.7640117666666666, longitude: 127.37662001666666, timestamp: "2024-06-06T20:46:32.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840725", lon: "12722.597185", latD: "N", lonD: "E", latitude: 0.7640120833333334, longitude: 127.37661975, timestamp: "2024-06-06T20:46:51.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840725", lon: "12722.597185", latD: "N", lonD: "E", latitude: 0.7640120833333334, longitude: 127.37661975, timestamp: "2024-06-06T20:46:51.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840725", lon: "12722.597185", latD: "N", lonD: "E", latitude: 0.7640120833333334, longitude: 127.37661975, timestamp: "2024-06-06T20:46:51.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840725", lon: "12722.597185", latD: "N", lonD: "E", latitude: 0.7640120833333334, longitude: 127.37661975, timestamp: "2024-06-06T20:46:51.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840751", lon: "12722.597170", latD: "N", lonD: "E", latitude: 0.7640125166666666, longitude: 127.3766195, timestamp: "2024-06-06T20:47:11.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840751", lon: "12722.597170", latD: "N", lonD: "E", latitude: 0.7640125166666666, longitude: 127.3766195, timestamp: "2024-06-06T20:47:11.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840751", lon: "12722.597170", latD: "N", lonD: "E", latitude: 0.7640125166666666, longitude: 127.3766195, timestamp: "2024-06-06T20:47:11.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.9", satellites: "10", hdop: "0.6", lat: "0045.840751", lon: "12722.597170", latD: "N", lonD: "E", latitude: 0.7640125166666666, longitude: 127.3766195, timestamp: "2024-06-06T20:47:11.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.8", satellites: "10", hdop: "0.6", lat: "0045.840777", lon: "12722.597144", latD: "N", lonD: "E", latitude: 0.76401295, longitude: 127.37661906666666, timestamp: "2024-06-06T20:47:31.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.8", satellites: "10", hdop: "0.6", lat: "0045.840777", lon: "12722.597144", latD: "N", lonD: "E", latitude: 0.76401295, longitude: 127.37661906666666, timestamp: "2024-06-06T20:47:31.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.8", satellites: "10", hdop: "0.6", lat: "0045.840777", lon: "12722.597144", latD: "N", lonD: "E", latitude: 0.76401295, longitude: 127.37661906666666, timestamp: "2024-06-06T20:47:31.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.8", satellites: "10", hdop: "0.6", lat: "0045.840777", lon: "12722.597144", latD: "N", lonD: "E", latitude: 0.76401295, longitude: 127.37661906666666, timestamp: "2024-06-06T20:47:31.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.7", satellites: "10", hdop: "0.6", lat: "0045.840793", lon: "12722.597157", latD: "N", lonD: "E", latitude: 0.7640132166666667, longitude: 127.37661928333333, timestamp: "2024-06-06T20:47:52.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.7", satellites: "10", hdop: "0.6", lat: "0045.840793", lon: "12722.597157", latD: "N", lonD: "E", latitude: 0.7640132166666667, longitude: 127.37661928333333, timestamp: "2024-06-06T20:47:52.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.7", satellites: "10", hdop: "0.6", lat: "0045.840793", lon: "12722.597157", latD: "N", lonD: "E", latitude: 0.7640132166666667, longitude: 127.37661928333333, timestamp: "2024-06-06T20:47:52.000Z" },
        { speed: "0.0", course: "219.2", altitude: "4.7", satellites: "10", hdop: "0.6", lat: "0045.840793", lon: "12722.597157", latD: "N", lonD: "E", latitude: 0.7640132166666667, longitude: 127.37661928333333, timestamp: "2024-06-06T20:47:52.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.840554", lon: "12722.596502", latD: "N", lonD: "E", latitude: 0.7640092333333333, longitude: 127.37660836666667, timestamp: "2024-06-06T20:48:12.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.840554", lon: "12722.596502", latD: "N", lonD: "E", latitude: 0.7640092333333333, longitude: 127.37660836666667, timestamp: "2024-06-06T20:48:12.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.840554", lon: "12722.596502", latD: "N", lonD: "E", latitude: 0.7640092333333333, longitude: 127.37660836666667, timestamp: "2024-06-06T20:48:12.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.6", satellites: "10", hdop: "0.6", lat: "0045.840554", lon: "12722.596502", latD: "N", lonD: "E", latitude: 0.7640092333333333, longitude: 127.37660836666667, timestamp: "2024-06-06T20:48:12.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.840824", lon: "12722.596993", latD: "N", lonD: "E", latitude: 0.7640137333333333, longitude: 127.37661655, timestamp: "2024-06-06T20:48:32.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.840824", lon: "12722.596993", latD: "N", lonD: "E", latitude: 0.7640137333333333, longitude: 127.37661655, timestamp: "2024-06-06T20:48:32.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.840824", lon: "12722.596993", latD: "N", lonD: "E", latitude: 0.7640137333333333, longitude: 127.37661655, timestamp: "2024-06-06T20:48:32.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.3", satellites: "10", hdop: "0.6", lat: "0045.840824", lon: "12722.596993", latD: "N", lonD: "E", latitude: 0.7640137333333333, longitude: 127.37661655, timestamp: "2024-06-06T20:48:32.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.840882", lon: "12722.597042", latD: "N", lonD: "E", latitude: 0.7640147, longitude: 127.37661736666666, timestamp: "2024-06-06T20:48:51.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.840882", lon: "12722.597042", latD: "N", lonD: "E", latitude: 0.7640147, longitude: 127.37661736666666, timestamp: "2024-06-06T20:48:51.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.840882", lon: "12722.597042", latD: "N", lonD: "E", latitude: 0.7640147, longitude: 127.37661736666666, timestamp: "2024-06-06T20:48:51.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.840882", lon: "12722.597042", latD: "N", lonD: "E", latitude: 0.7640147, longitude: 127.37661736666666, timestamp: "2024-06-06T20:48:51.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.840912", lon: "12722.597066", latD: "N", lonD: "E", latitude: 0.7640152, longitude: 127.37661776666667, timestamp: "2024-06-06T20:49:11.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.840912", lon: "12722.597066", latD: "N", lonD: "E", latitude: 0.7640152, longitude: 127.37661776666667, timestamp: "2024-06-06T20:49:11.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.840912", lon: "12722.597066", latD: "N", lonD: "E", latitude: 0.7640152, longitude: 127.37661776666667, timestamp: "2024-06-06T20:49:11.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.4", satellites: "10", hdop: "0.6", lat: "0045.840912", lon: "12722.597066", latD: "N", lonD: "E", latitude: 0.7640152, longitude: 127.37661776666667, timestamp: "2024-06-06T20:49:11.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.840981", lon: "12722.597102", latD: "N", lonD: "E", latitude: 0.76401635, longitude: 127.37661836666666, timestamp: "2024-06-06T20:49:32.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.840981", lon: "12722.597102", latD: "N", lonD: "E", latitude: 0.76401635, longitude: 127.37661836666666, timestamp: "2024-06-06T20:49:32.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.840981", lon: "12722.597102", latD: "N", lonD: "E", latitude: 0.76401635, longitude: 127.37661836666666, timestamp: "2024-06-06T20:49:32.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.5", satellites: "10", hdop: "0.6", lat: "0045.840981", lon: "12722.597102", latD: "N", lonD: "E", latitude: 0.76401635, longitude: 127.37661836666666, timestamp: "2024-06-06T20:49:32.000Z" },
        { speed: "0.0", course: "213.3", altitude: "1.5", satellites: "09", hdop: "0.6", lat: "0045.840924", lon: "12722.597045", latD: "N", lonD: "E", latitude: 0.7640154, longitude: 127.37661741666666, timestamp: "2024-06-06T20:49:51.000Z" },
      ],
      uid: "kmp_maming",
    },
  ],
};

const mapStyles = {
  width: "100%",
  height: "72vh",
};

const center = {
  lat: -6.2240456,
  lng: 118.0148634,
};

// const libraries = ["drawing", "marker", "places", "geometry"];

const io = socketIO.getInstance();
io.setHost("https://socket.atapteknologi.id");
const socket = io.getSocket();

let timeoutId;

const Monitoring = (props) => {
  const { devices, devicesLoading, clients, clientLoading, clientError, histories, historiesLoading, parameters, notifications, historyPublics, deviceOnes, publicLoading } = useSelector((state) => ({
    clients: state.Client.clients,
    clientLoading: state.Client.loading,
    clientError: state.Client.error,
    histories: state.History.histories,
    historiesLoading: state.History.loading,
    parameters: state.Parameter.parameters,
    notifications: state.Notification.notifications,
    devices: state.Device.devices,
    devicesLoading: state.Device.loading,
    deviceOnes: state.Device.deviceOnes,
    historyPublics: state.History.historyPublics,
    publicLoading: state.History.publicLoading,
  }));
  const [mapApiKey, setMapApiKey] = useState(sessionStorage.getItem("mapApiKey"));

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: mapApiKey,
  //   libraries,
  // });

  const { t } = props;

  const googleRef = useRef(null);
  const [initialPage, setInitialPage] = useState(true)
  const [idxRoute, setIdxRoute] = useState(0);
  const [activeAccordion, setActiveAccordion] = useState("unit-1");
  const moments = extendMoment(moment);
  const [activeGroupAccordion, setActiveGroupAccordion] = useState(""); //"group-1"
  const [activeTab, setActiveTab] = useState("1");
  const [markers, setMarkers] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [series, setSeries] = useState([]);
  const [seriesCategories, setSeriesCategories] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [unitClick, setUnitClick] = useState();
  const [unitIdx, setUnitIdx] = useState();
  const [open, setOpen] = useState("1");
  const [openAcc, setOpenAcc] = useState("1");
  const [primaryImg, setPrimaryImg] = useState();
  const [dataDevice, setDataDevice] = useState();
  const [fileList, setFileList] = useState([]);
  const [hasClick, setHasClick] = useState(false);
  const [accClick, setAccClick] = useState(false);
  const [accValue, setAccValue] = useState();
  const [zoomVal, setZoomVal] = useState("0");
  const [deviceCheck, setDeviceCheck] = useState([]);
  const [idxCompanies, setIdxCompanies] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState();
  const [isRoute, setIsRoute] = useState(false);
  const [routeDevice, setRouteDevice] = useState([]);
  const [arrayStorage, setArrayStorage] = useState(() => {
    const storedArray = localStorage.getItem("arrayStorage");
    return storedArray ? JSON.parse(storedArray) : [];
  });

  ///
  const updateMarker = useRef([]);
  const [companyList, setCompanyList] = useState([]);
  // const [companyOriList, setCompanyOriList] = useState([]);
  const companyListOri = useRef([]);
  const [deviceData, setDeviceData] = useState(null);
  const [deviceIdentifierList, setDeviceIdentifierList] = useState([]);
  const [deviceHasLoaded, setDeviceHasLoaded] = useState(false);
  const [historyHasLoaded, setHistoryHasLoaded] = useState(false);
  ///
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [uidEvents, setUidEvents] = useState([]);
  const [labels, setLabels] = useState([]);
  const [map, setMap] = useState();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [infoWindowData, setInfoWindowData] = useState();
  const [companyArr, setCompanyArr] = useState([]);
  const [tooltipDotsOpen, setTooltipDotsOpen] = useState(false);
  const [tooltipWifiOpen, setTooltipWifiOpen] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState("");
  const [dataUid, setDataUid] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [dataStatus, setDataStatus] = useState([]);
  const [isCheckRoute, setIsCheckRoute] = useState(false);
  const [statusRoute, setStatusRoute] = useState("start");

  const filterBy = [
    { label: "Name", value: "name" },
    { label: "Status", value: "status" },
  ];

  const [filter, setFilter] = useState(filterBy[0]);
  const lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 3,
  };

  const handleMarkerClick = (id, lat, lng, address) => {
    setMapRef({ lat, lng });
    setInfoWindowData({ id, address });
  };

  function geocodeLatLng(geocoder, geo) {
    try {
      const latlngStr = geo.split(",", 2);
      if (latlngStr.length !== 2) {
        throw new Error("Input tidak valid. Harus dalam format 'lat,lng'.");
      }

      const lat = parseFloat(latlngStr[0]);
      const lng = parseFloat(latlngStr[1]);

      if (isNaN(lat) || isNaN(lng)) {
        throw new Error("Koordinat tidak valid.");
      }

      const latlng = { lat, lng };

      geocoder
        .geocode({ location: latlng })
        .then((response) => {
          if (response.results && response.results[0]) {
            setFormattedAddress(response.results[0].formatted_address);
          } else {
            toast.error("Alamat tidak ditemukan");
          }
        })
        .catch((e) => {
          toast.error("Alamat tidak muncul karena masalah jaringan");
        });
    } catch (error) {
      toast.error("Error: " + error.message);
    }
  }

  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  const toggleAcc = (id) => {
    if (openAcc === id) {
      setOpenAcc("");
    } else {
      setOpenAcc(id);
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function onConnect() {
    //console.log("soket terhubung");
    // setIsConnected(true);
  }

  function onDisconnect() {
    //console.log("soket terputus");
    // setIsConnected(false);
  }

  useEffect(() => {
    socket.connect();
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.disconnect();
    };
  }, []);

  const addressRender = (unitsAddress) => {
    if (unitsAddress) {
      let dataAddress = null;
      unitsAddress.forEach((address) => {
        if (address.isPrimary) {
          dataAddress = address;
        } else {
          dataAddress = "-";
        }
      });

      return `${dataAddress ? dataAddress.village : ""}, ${dataAddress ? dataAddress.district : ""}, ${dataAddress ? dataAddress.city : ""}, ${dataAddress ? dataAddress.province : ""}, ${dataAddress ? dataAddress.code : ""} `;
    } else {
      return "-";
    }
  };

  const onClickView = useCallback(
    (device) => {
      console.log(device, "click view");
      setDataDevice(device);
      const image = device.image ? api.MEDIA_URL + "/media/" + device.image : dummyImg;
      setPrimaryImg(image);

      let dataFile = [];
      if (device.device_file) {
        for (const b of device.device_file) {
          dataFile.push(b);
        }
      }
      setFileList(dataFile);

      let dataLabel = [];
      device.device_parameter &&
        device.device_parameter.forEach((item) => {
          if (item && item.label) {
            if (item.showParameter) {
              dataLabel.push({
                label: item && item.label,
                value: item && item.value,
              });
            }
          }
        });
      setLabels(dataLabel);
      // setSeries([]);
      // setSeriesCategories([]);
      // dispatch(resetParameterState());
      // dispatch(resetNotificationState());
      // dispatch(
      //   getNotification({
      //     type: "alarm",
      //     deviceId: device.id,
      //   })
      // );
      dispatch(getDeviceOne(device.id));
      // let date = new Date();
      // let dates = date.getTime();

      let dataP = [];
      device.device_parameter &&
        device.device_parameter.forEach((dev) => {
          dataP.push({
            label: dev.label,
            // shift: [],
            value: 0,
            isShift: false,
            condition: "",
            parameter: dev.value,
          });
        });

      dispatch(
        getHistoryPublic({
          parameter: JSON.stringify({ parameter: dataP }),
          uid: device.identifier,
          type: "today",
          aggregate: "$first",
          group: "$hour",
        })
      );
      // setHasClick(true);
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isEmpty(devices) && !deviceHasLoaded) {
      console.log("jalan");
      let cMap = {};
      let marLst = [];
      let identifierList = [];

      devices.forEach((device, i) => {
        if (device.identifier !== null || device.identifier !== "") {
          device.isChecked = true;
          device.routes = [];
          marLst.push(device);
          identifierList.push(device.identifier);

          device.isOpen = false;
          if (cMap[device.device_company.name] === undefined) {
            cMap[device.device_company.name] = { id: device.device_company.id, devices: [device] };
          } else {
            cMap[device.device_company.name].devices.push(device);
          }
        }
      });

      const comMapToArr = Object.keys(cMap).map((key) => ({ ...cMap[key], name: key, isOpen: true, isChecked: true }));
      dispatch(getHistory({ type: "current", uid: JSON.stringify(identifierList) }));
      setDeviceIdentifierList(identifierList);
      setCompanyList(comMapToArr);
      companyListOri.current = comMapToArr;
      setMarkers(marLst);
      setDeviceHasLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, dispatch]);

  useEffect(() => {
    return () => {
      if (deviceHasLoaded) {
        setDeviceHasLoaded(false);
      }
      if (historyHasLoaded) {
        setHistoryHasLoaded(false);
      }
      dispatch(resetHistoryState());
      dispatch(resetDeviceState());
      setCompanyList([]);
      setMarkers([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log(markers, "marker");
  // }, [markers]);

  const onUidEvent = useCallback(
    (value) => {
      const dataSocket = JSON.parse(value);
      const newDataMarkerList = [...markers];
      for (const device of newDataMarkerList) {
        if (!device.routes) device.routes = [];
        if (device.identifier === dataSocket.uid) {
          if (dataSocket.gps) {
            // console.log(markers[device.id]);
            // console.log(markers[device.id]);
            device.device_time = dataSocket.device_time;
            // if (device.id !== routeDevice.id) {
            if (parseFloat(dataSocket.gps.latitude) && parseFloat(dataSocket.gps.longitude) && parseFloat(dataSocket.gps.longitude) !== 0) {
              device.latitude = dataSocket.gps.latitude;
              device.longitude = dataSocket.gps.longitude;
              device.routes = [...device.routes, { lat: dataSocket.gps.latitude, lng: dataSocket.gps.longitude }];
              setDeviceData({ id: device.id, isPanTo: false, isChecked: device.isChecked, location: { lat: dataSocket.gps.latitude, lng: dataSocket.gps.longitude }, routes: [...device.routes, { lat: dataSocket.gps.latitude, lng: dataSocket.gps.longitude }] });
              // if (device.isChecked === true) {

              // }else{
              //   device.routes = [];
              // }
            }
            //   device.latitude = dataSocket.gps.latitude;
            //   device.longitude = dataSocket.gps.longitude;

            // if (device.identifier === "mcu_kpp_tl169") {
            //   console.log(device.routes, "okkk");
            // }
            // }
          }

          device.device_parameter &&
            device.device_parameter.forEach((parameter, parameterIndex) => {
              if (dataSocket.message) {
                const amount = dataSocket.message[parameter.value];
                if (parameter.value === "device_time" && dataSocket.message.device_time) {
                  device.device_time = dataSocket.device_time;
                  var date1 = new Date(parameter.amount);
                  var date2 = new Date(dataSocket.device_time);
                  if (date1.getTime() > date2.getTime()) {
                    dataSocket.message.device_time = parameter.amount;
                  } else {
                    dataSocket.message.device_time = dataSocket.device_time;
                  }
                }
                if (parameter.value === "datetime" && dataSocket.message.datetime) {
                  var date3 = new Date(parameter.amount);
                  var date4 = new Date(dataSocket.device_time);
                  if (date3.getTime() > date4.getTime()) {
                    dataSocket.message.datetime = parameter.amount;
                  } else {
                    dataSocket.message.datetime = dataSocket.device_time;
                  }
                }

                if (dataSocket.message.timestamp) {
                  const start = new Date(dataSocket.message.timestamp);
                  const end = new Date();
                  const range1 = moments.range(start, end);
                  const toMinute = range1.snapTo("minute");
                  device.toMinute = toMinute.diff("minutes");
                } else if (dataSocket.device_time) {
                  const start = new Date(dataSocket.device_time);
                  const end = new Date();
                  const range1 = moments.range(start, end);
                  const toMinute = range1.snapTo("minute");
                  device.toMinute = toMinute.diff("minutes");
                }

                const indexMar = markers.findIndex((m) => m.id === device.id);

                if (dataSocket.sensor_status) {
                  newDataMarkerList[indexMar].sensor_status = dataSocket.sensor_status;
                  device.sensor_status = dataSocket.sensor_status;
                }

                if (amount) {
                  newDataMarkerList[indexMar].device_parameter[parameterIndex].amount = amount;
                  device.device_parameter[parameterIndex].amount = amount;
                } else if (amount === 0) {
                  newDataMarkerList[indexMar].device_parameter[parameterIndex].amount = "0";
                  device.device_parameter[parameterIndex].amount = "0";
                }
              }
            });
        }
      }

      // updateMarker.current = newDataMarkerList;

      // setDataUpdateMarker(newDataMarkerList);

      // setMarkers(newDataMarkerList);
    },
    [markers, moments]
  );

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setMarkers(updateMarker.current);
  //   }, 1000);

  // Clean up the interval
  //   return () => clearInterval(interval);
  // }, []);

  // const onUidEvent = useCallback(
  //   // (value) => {
  //   //   const val = JSON.parse(value);
  //   //   const data = uidEvents.filter((item) => item.uid !== val.uid);

  //   //   data.push(val);
  //   //   setUidEvents(data);
  //   // },
  //   // [uidEvents]
  //   (value) => {
  //     const data = JSON.parse(value);
  //     // const newComList = [...newComList];
  //     for (const company of companyList) {
  //       for (const device of company.devices) {
  //         if (device.identifier === data.uid) {
  //           if (data.gps) {
  //             if (device.id !== routeDevice.id) {
  //               setDeviceData({ id: device.id, isPanTo: false, location: { lat: data.gps.latitude ? parseFloat(data.gps.latitude) : 0, lng: data.gps.longitude ? parseFloat(data.gps.longitude) : 0 } });
  //               device.latitude = data.gps.latitude;
  //               device.longitude = data.gps.longitude;
  //               // if (device.routes && device.routes.length > 0) {
  //               //   device.routes = [...device.routes, { lat: element.gps.latitude, lng: element.gps.longitude }];
  //               // }
  //             }
  //           }
  //         }
  //       }
  //     }

  //     // setCompanyList(newComList);

  //     /////

  //     // for (const company of companyList) {
  //     //   for (const device of company.devices) {
  //     //     if (device.identifier === val.uid) {
  //     //       if (val.gps) {
  //     //         if (device.id !== routeDevice.id) {

  //     //           device.latitude = val.gps.latitude;
  //     //           device.longitude = val.gps.longitude;
  //     //           // if (device.routes && device.routes.length > 0) {
  //     //           //   device.routes = [...device.routes, { lat: element.gps.latitude, lng: element.gps.longitude }];
  //     //           // }
  //     //         }
  //     //       }
  //   // device.device_parameter &&
  //   //   device.device_parameter.forEach((parameter, parameterIndex) => {
  //   //     if (element.message) {
  //   //       const amount = element.message[parameter.value];
  //   //       if (parameter.value === "device_time" && element.message.device_time) {
  //   //         var date1 = new Date(parameter.amount);
  //   //         var date2 = new Date(element.device_time);
  //   //         if (date1.getTime() > date2.getTime()) {
  //   //           element.message.device_time = parameter.amount;
  //   //         } else {
  //   //           element.message.device_time = element.device_time;
  //   //         }
  //   //       }
  //   //       if (parameter.value === "datetime" && element.message.datetime) {
  //   //         var date3 = new Date(parameter.amount);
  //   //         var date4 = new Date(element.device_time);
  //   //         if (date3.getTime() > date4.getTime()) {
  //   //           element.message.datetime = parameter.amount;
  //   //         } else {
  //   //           element.message.datetime = element.device_time;
  //   //         }
  //   //       }

  //   //       if (element.message.timestamp) {
  //   //         const start = new Date(element.message.timestamp);
  //   //         const end = new Date();
  //   //         const range1 = moments.range(start, end);
  //   //         const toMinute = range1.snapTo("minute");
  //   //         groupData[groupIndex].un_device[deviceIndex].toMinute = toMinute.diff("minutes");
  //   //       } else if (element.device_time) {
  //   //         const start = new Date(element.device_time);
  //   //         const end = new Date();
  //   //         const range1 = moments.range(start, end);
  //   //         const toMinute = range1.snapTo("minute");
  //   //         groupData[groupIndex].un_device[deviceIndex].toMinute = toMinute.diff("minutes");
  //   //       }

  //   //       if (element.sensor_status) {
  //   //         groupData[groupIndex].un_device[deviceIndex].sensor_status = element.sensor_status;
  //   //       }

  //   //       if (amount) {
  //   //         groupData[groupIndex].un_device[deviceIndex].device_parameter[parameterIndex].amount = amount;
  //   //       } else if (amount === 0) {
  //   //         groupData[groupIndex].un_device[deviceIndex].device_parameter[parameterIndex].amount = "0";
  //   //       }
  //   //     }
  //   //   });
  // }
  //   }
  // }
  //   },
  //   [companyList]
  // );

  useEffect(() => {
    //if (isLoaded) {
    if (!isEmpty(deviceIdentifierList)) {
      deviceIdentifierList.forEach((element) => {
        socket.on(element, onUidEvent);
      });
    }

    return () => {
      deviceIdentifierList.forEach((element) => {
        socket.off(element, onUidEvent);
      });
    };
    //}
  }, [deviceIdentifierList, onUidEvent]);

  useEffect(() => {
    if (historyHasLoaded && initialPage) {
      
      
      if (histories) {
        let datas = [];
        histories.length > 0 &&
          histories.forEach((his) => {
            // console.log(his.gps, "sdsd");

            his.gps &&
              his.gps.length > 0 &&
              his.gps.sort(function (a, b) {
                a = new Date(a.timestamp);
                b = new Date(b.timestamp);
                return a < b ? -1 : a > b ? 1 : 0;
              });
            his.gps &&
              his.gps.length > 0 &&
              his.gps.forEach((route) => {
                datas.push({
                  uid: his.uid,
                  lat: parseFloat(route.latitude),
                  lng: parseFloat(route.longitude),
                  timestamp: route.timestamp,
                });
              });
          });

        // console.log(datas, 'iii');
          
        if (datas && datas.length > 0) {
          setIsRoute(true);
          setIsCheckRoute(true);
          setRoutes(datas);
        } else {
          if (!initialPage) {
            toast.error("Device location not found", {
              autoClose: 3000,
            });
          }
          setIsRoute(false);
          setIsCheckRoute(false);
          setRoutes([]);
        }
      }
      setInitialPage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [histories, historyHasLoaded]);

  useEffect(() => {
    if (!isEmpty(histories) && !isEmpty(companyList) && historyHasLoaded === false) {
      
      console.log(companyList, "data his");
      histories.forEach((element) => {
        
        companyList.forEach((company, i) => {
          company.devices.forEach((dev, devIndex) => {
            if (dev.identifier === element.uid) {
              if (element && element.gps) {
                if (parseFloat(element.gps.latitude) && parseFloat(element.gps.longitude) && parseFloat(element.gps.longitude) !== 0) {
                  dev.latitude = element.gps.latitude;
                  dev.longitude = element.gps.longitude;
                  if (dev.isChecked === true) {
                    dev.routes = [{ lat: element.gps.latitude, lng: element.gps.longitude }];
                  }
                }
                dev.isFleet = true;
              }

              dev.device_parameter &&
                dev.device_parameter.forEach((parameter, parameterIndex) => {
                  if (element.message) {
                    const amount = element.message[parameter.value];
                    if (parameter.value === "device_time" && element.message.device_time) {
                      dev.device_time = element.device_time;
                      var date1 = new Date(parameter.amount);
                      var date2 = new Date(element.device_time);
                      if (date1.getTime() > date2.getTime()) {
                        element.message.device_time = parameter.amount;
                      } else {
                        element.message.device_time = element.device_time;
                      }
                    }
                    if (parameter.value === "datetime" && element.message.datetime) {
                      var date3 = new Date(parameter.amount);
                      var date4 = new Date(element.device_time);
                      if (date3.getTime() > date4.getTime()) {
                        element.message.datetime = parameter.amount;
                      } else {
                        element.message.datetime = element.device_time;
                      }
                    }

                    if (element.message.timestamp) {
                      const start = new Date(element.message.timestamp);
                      const end = new Date();
                      const range1 = moments.range(start, end);
                      const toMinute = range1.snapTo("minute");
                      dev.toMinute = toMinute.diff("minutes");
                    } else if (element.device_time) {
                      const start = new Date(element.device_time);
                      const end = new Date();
                      const range1 = moments.range(start, end);
                      const toMinute = range1.snapTo("minute");
                      dev.toMinute = toMinute.diff("minutes");
                    }

                    const indexMar = markers.findIndex((m) => m.id === dev.id);

                    if (element.sensor_status) {
                      markers[indexMar].sensor_status = element.sensor_status;
                      dev.sensor_status = element.sensor_status;
                    }

                    if (amount) {
                      markers[indexMar].device_parameter[parameterIndex].amount = amount;
                      dev.device_parameter[parameterIndex].amount = amount;
                    } else if (amount === 0) {
                      markers[indexMar].device_parameter[parameterIndex].amount = "0";
                      dev.device_parameter[parameterIndex].amount = "0";
                    }
                  }
                });
            }
          });
        });
        setDeviceData();
      });
      setHistoryHasLoaded(true);
    }
    // if (!isDone && histories.length > 0 && deviceList.length > 0) {
    //   histories.forEach((element) => {
    //     deviceList.forEach((dev, devIndex) => {
    //       if (dev.identifier === element.uid) {
    //         if (element && element.gps) {
    //           dev.latitude = element.gps.latitude;
    //           dev.longitude = element.gps.longitude;
    //           dev.isFleet = true;
    //           dev.routes = [{ lat: element.gps.latitude, lng: element.gps.longitude }];
    //         }
    //         dev.device_parameter &&
    //           dev.device_parameter.forEach((parameter, parameterIndex) => {
    //             if (element.message) {
    //               const amount = element.message[parameter.value];
    //               if (parameter.value === "device_time" && element.message.device_time) {
    //                 var date1 = new Date(parameter.amount);
    //                 var date2 = new Date(element.device_time);
    //                 if (date1.getTime() > date2.getTime()) {
    //                   element.message.device_time = parameter.amount;
    //                 } else {
    //                   element.message.device_time = element.device_time;
    //                 }
    //               }
    //               if (parameter.value === "datetime" && element.message.datetime) {
    //                 var date3 = new Date(parameter.amount);
    //                 var date4 = new Date(element.device_time);
    //                 if (date3.getTime() > date4.getTime()) {
    //                   element.message.datetime = parameter.amount;
    //                 } else {
    //                   element.message.datetime = element.device_time;
    //                 }
    //               }

    //               if (element.message.timestamp) {
    //                 const start = new Date(element.message.timestamp);
    //                 const end = new Date();
    //                 const range1 = moments.range(start, end);
    //                 const toMinute = range1.snapTo("minute");
    //                 deviceList[devIndex].toMinute = toMinute.diff("minutes");
    //               } else if (element.device_time) {
    //                 const start = new Date(element.device_time);
    //                 const end = new Date();
    //                 const range1 = moments.range(start, end);
    //                 const toMinute = range1.snapTo("minute");
    //                 deviceList[devIndex].toMinute = toMinute.diff("minutes");
    //               }

    //               if (element.sensor_status) {
    //                 deviceList[devIndex].sensor_status = element.sensor_status;
    //               }

    //               if (amount) {
    //                 deviceList[devIndex].device_parameter[parameterIndex].amount = amount;
    //               } else if (amount === 0) {
    //                 deviceList[devIndex].device_parameter[parameterIndex].amount = "0";
    //               }
    //             }
    //           });
    //       }
    //     });
    //   });
    //   setIsDone(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [histories, companyList]);

  useEffect(() => {
    console.log(historyHasLoaded, "sdddsddd");
  }, [historyHasLoaded]);

  // useEffect(() => {
  //   if (isDone) {
  //     let dataMark = [];
  //     groupData.forEach((group, groupIndex) => {
  //       group.un_device.forEach((device, deviceIndex) => {
  //         if (device.device_status.id === 2) {
  //           arrayStorage.forEach((item) => {
  //             if (device.id === item.id) {
  //               device.isMarkerActive = true;
  //             }
  //           });
  //           dataMark.push(device);
  //           setMarkers(dataMark);
  //         }
  //         uidEvents.forEach((element) => {
  //           if (device.identifier === element.uid) {
  //             if (element.gps) {
  //               if (device.id !== routeDevice.id) {
  //                 device.latitude = element.gps.latitude;
  //                 device.longitude = element.gps.longitude;
  //                 if (device.routes && device.routes.length > 0) {
  //                   device.routes = [...device.routes, { lat: element.gps.latitude, lng: element.gps.longitude }];
  //                 }
  //               }
  //             }
  //             device.device_parameter &&
  //               device.device_parameter.forEach((parameter, parameterIndex) => {
  //                 if (element.message) {
  //                   const amount = element.message[parameter.value];
  //                   if (parameter.value === "device_time" && element.message.device_time) {
  //                     var date1 = new Date(parameter.amount);
  //                     var date2 = new Date(element.device_time);
  //                     if (date1.getTime() > date2.getTime()) {
  //                       element.message.device_time = parameter.amount;
  //                     } else {
  //                       element.message.device_time = element.device_time;
  //                     }
  //                   }
  //                   if (parameter.value === "datetime" && element.message.datetime) {
  //                     var date3 = new Date(parameter.amount);
  //                     var date4 = new Date(element.device_time);
  //                     if (date3.getTime() > date4.getTime()) {
  //                       element.message.datetime = parameter.amount;
  //                     } else {
  //                       element.message.datetime = element.device_time;
  //                     }
  //                   }

  //                   if (element.message.timestamp) {
  //                     const start = new Date(element.message.timestamp);
  //                     const end = new Date();
  //                     const range1 = moments.range(start, end);
  //                     const toMinute = range1.snapTo("minute");
  //                     groupData[groupIndex].un_device[deviceIndex].toMinute = toMinute.diff("minutes");
  //                   } else if (element.device_time) {
  //                     const start = new Date(element.device_time);
  //                     const end = new Date();
  //                     const range1 = moments.range(start, end);
  //                     const toMinute = range1.snapTo("minute");
  //                     groupData[groupIndex].un_device[deviceIndex].toMinute = toMinute.diff("minutes");
  //                   }

  //                   if (element.sensor_status) {
  //                     groupData[groupIndex].un_device[deviceIndex].sensor_status = element.sensor_status;
  //                   }

  //                   if (amount) {
  //                     groupData[groupIndex].un_device[deviceIndex].device_parameter[parameterIndex].amount = amount;
  //                   } else if (amount === 0) {
  //                     groupData[groupIndex].un_device[deviceIndex].device_parameter[parameterIndex].amount = "0";
  //                   }
  //                 }
  //               });
  //           }
  //         });
  //       });
  //     });
  //     console.log("Has change", uidEvents);
  //   }
  // }, [uidEvents, groupData, routes, moments, routeDevice, isDone, arrayStorage]);

  useEffect(() => {
    // if (!isLoaded) {
    //   // dispatch(resetClientState());
    //   // dispatch(getClient());
    //   dispatch(getDevice({ status: 2 }));
    // }
    dispatch(resetDeviceState());
    dispatch(getDevice({ status: 2 }));

    return (()=>{
      dispatch(resetDeviceState())
      dispatch(resetCompanyState())
      dispatch(resetHistoryState())
    })

  }, [dispatch]);

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY HH:mm");
  };

  const handleValidDateNoYear = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MM/D, HH:mm");
  };

  // useEffect(() => {
  //   if (dataDevice) {
  //     let dataLabel = [];
  //     dataDevice.device_parameter &&
  //       dataDevice.device_parameter.forEach((item) => {
  //         if (item && item.label) {
  //           dataLabel.push({
  //             label: item && item.label,
  //             value: item && item.value,
  //           });
  //           setLabels(dataLabel);
  //         }
  //       });
  //   }
  // }, [dataDevice]);

  function setGroupAccordion(e) {
    if (activeGroupAccordion === e) {
      setActiveGroupAccordion("");
      setIsOpen(false);
      setAccClick(false);
      setAccValue();
    } else {
      setActiveGroupAccordion(e);
    }
  }

  function setAccordion(e) {
    if (activeAccordion === e) {
      setActiveAccordion("");
    } else {
      setActiveAccordion(e);
    }
  }

  const handlecheckedCompany = (e, company) => {
    const companyId = company.id;
    const dCollapseEl = document.getElementById(`deviceCollapse-${companyId}`);
    const newListCom = [...companyList];
    const idxCom = newListCom.findIndex((com) => com.id === companyId);

    newListCom[idxCom].isChecked = e.target.checked;

    let mrks = [];
    for (const d of newListCom[idxCom].devices) {
      mrks.push(d);
      d.isChecked = e.target.checked;
    }

    dCollapseEl.children[0].childNodes.forEach((el) => {
      el.children[0].children[0].firstChild.checked = e.target.checked;
    });

    if (e.target.checked) {
      let addMrks = [...markers, ...mrks];
      setMarkers(addMrks);
      console.log("true");
    } else {
      let removeMrks = markers.filter((m) => !mrks.includes(m));
      setMarkers(removeMrks);
      console.log("false");
    }

    setCompanyList(newListCom);
  };

  function handleCheckedClick(e, device) {
    const companyId = device.companyId;
    const deviceId = device.id;
    console.log(companyId);
    const newListCom = [...companyList];
    // const newListMarker = [...markers];
    const idxCom = newListCom.findIndex((com) => com.id === companyId);
    const idxDev = newListCom[idxCom].devices.findIndex((dev) => dev.id === deviceId);
    newListCom[idxCom].devices[idxDev].isChecked = e.target.checked;

    if (e.target.checked) {
      setDeviceData({ id: device.id, isPanTo: false, isChecked: true, location: { lat: device.latitude, lng: device.longitude }, routes: device.routes });
      setMarkers((val) => [...val, device]);
      console.log("true");
    } else {
      setDeviceData({ id: device.id, isPanTo: false, isChecked: false, location: { lat: device.latitude, lng: device.longitude }, routes: device.routes });
      setMarkers((val) => {
        return val.filter((m) => m.id !== deviceId);
      });
      console.log("false");
    }
    setCompanyList(newListCom);
  }

  useEffect(() => {
    console.log(companyList, "marker");
  }, [companyList]);

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    // if (checkall.checked) {
    //   ele.forEach((ele) => {
    //     ele.checked = true;
    //   });

    //   let data = [];
    //   labels.forEach((z) => {
    //     let obj = {};
    //     obj.name = z.label;
    //     let arr = [];
    //     let entries;
    //     histories.forEach((item) => {
    //       let parseData = JSON.parse(item.data);
    //       entries = Object.entries(parseData);
    //       entries.forEach((e) => {
    //         if (e[0] === z.value) {
    //           if (e[0] !== "latitude" && e[0] !== "longitude") {
    //             arr.push(Math.round(e[1]));
    //           }
    //         }
    //       });
    //     });

    //     obj.data = arr;
    //     data.push(obj);
    //   });
    //   setSeries(data);
    // } else {
    //   ele.forEach((ele) => {
    //     ele.checked = false;
    //   });
    //   setSeries([]);
    // }
  };

  const checkedOne = (e) => {
    // if (e.target.checked) {
    //   let data = [];
    //   labels.forEach((z) => {
    //     let obj = {};
    //     obj.name = z.label;
    //     let arr = [];
    //     let entries;
    //     histories.forEach((item) => {
    //       let parseData = JSON.parse(item.data);
    //       entries = Object.entries(parseData);
    //       entries.forEach((e) => {
    //         if (e[0] === z.value) {
    //           if (e[0] !== "latitude" && e[0] !== "longitude") {
    //             arr.push(Math.round(e[1]));
    //           }
    //         }
    //       });
    //     });
    //     obj.data = arr;
    //     data.push(obj);
    //   });
    //   setSeries(data);
    // } else {
    //   let data = [];
    //   series.forEach((element) => {
    //     if (element.name !== e.target.value) {
    //       data.push(element);
    //     }
    //   });
    //   setSeries(data);
    // }
  };

  // useEffect(() => {
  //   if (unitClick) {
  //     let lat = unitClick.latitude;
  //     let long = unitClick.longitude;
  //     let id = unitClick.id;
  //     let name = unitClick.name;
  //     let accVal = unitClick;
  //     let geo = `${unitClick.latitude}, ${unitClick.longitude}`;

  //     if (!lat || !long) {
  //       toast.error(t("Device location not found"), {
  //         autoClose: 3000,
  //       });
  //     } else {
  //       // handleMarkerClick(id, parseFloat(lat), parseFloat(long), name);
  //       // setZoomVal("1");
  //       // setAccClick(true);
  //       setAccValue(accVal);
  //       const geocoder = new window.google.maps.Geocoder();
  //       geocodeLatLng(geocoder, geo);
  //     }
  //   }
  // }, [unitClick, map, t]);

  // useEffect(() => {
  //   if (dataDevice) {
  //     const image = dataDevice.image ? api.MEDIA_URL + "/media/" + dataDevice.image : dummyImg;
  //     setPrimaryImg(image);

  //     let data = [];
  //     if (dataDevice.device_file) {
  //       for (const b of dataDevice.device_file) {
  //         data.push(b);
  //       }
  //     }
  //     setFileList(data);
  //   }
  // }, [dataDevice]);

  // useEffect(() => {
  //   if (deviceCheck) {
  //     localStorage.setItem("deviceCheck", JSON.stringify(deviceCheck));
  //   }
  // }, [deviceCheck]);

  // useEffect(() => {
  //   if (!isDone) {
  //     if (dataUid && dataUid.length > 0) {
  //       dispatch(resetHistoryState());
  //       dispatch(getHistory({ type: "current", uid: dataUid }));
  //     }
  //   }
  // }, [isDone, dispatch, dataUid]);

  // useEffect(() => {
  //   if (dataDevice && isDone) {
  //     let date = new Date();
  //     let dates = date.getTime();
  //     let data = [];
  //     dataDevice.device_parameter &&
  //       dataDevice.device_parameter.forEach((dev) => {
  //         data.push({
  //           label: dev.label,
  //           // shift: [],
  //           value: 0,
  //           isShift: false,
  //           condition: "",
  //           parameter: dev.value,
  //         });
  //       });
  //     dispatch(
  //       getHistoryPublic({
  //         parameter: JSON.stringify({ parameter: data }),
  //         uid: dataDevice.identifier,
  //         type: "today",
  //         aggregate: "$first",
  //         group: "$hour",
  //       })
  //     );
  //   }
  // }, [dispatch, dataDevice, isDone]);

  useEffect(() => {
    if (historyPublics && historyPublics.length > 0) {
      historyPublics.sort((a, b) => a.hour - b.hour);
      let arrCat = historyPublics.map((item) => moment(item.timestamp).format("YYYY-MM-DD HH:mm"));

      setDataStatus(historyPublics);
      setSeriesCategories(arrCat);
    }
  }, [historyPublics]);

  useEffect(() => {
    if (historyPublics && historyPublics.length > 0 && labels) {
      let data = [];
      labels.forEach((z) => {
        if (z.value !== "timestamp") {
          let obj = {};
          obj.name = z.label && z.label;
          let arr = [];
          let entries;
          historyPublics &&
            historyPublics.forEach((item) => {
              if (item) {
                entries = Object.entries(item);
                entries.forEach((e) => {
                  if (e[0] !== null) {
                    if (e[0] === z.value) {
                      arr.push(e[1]);
                    }
                  }
                });
              }
            });
          obj.data = arr;
          data.push(obj);
        }
      });
      setSeries(data);
    }
  }, [historyPublics, labels]);

  const delay = (milliseconds) => {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  };

  const handlePlay = () => {
    // timeoutId = routes.map((route, routeIdx) =>
    //   setTimeout(() => {
    //     if (routeIdx >= idxRoute) {
    //       let arr = [];
    //       routeDevice.latitude = route && route.lat;
    //       routeDevice.longitude = route && route.lng;
    //       arr.push(routeDevice);
    //       setMarkers([...markers], arr);
    //       setIdxRoute(idx++);
    //     }
    //   }, routeIdx * 500)
    // );
    // if (statusRoute === "start" || statusRoute === "stop") {
    //   if (routes && routes.length > 0) {
    //     let idx = 0;
    //     timeoutId = routes.map((route, routeIdx) =>
    //       setTimeout(() => {
    //         let arr = [];
    //         routeDevice.latitude = route && route.lat;
    //         routeDevice.longitude = route && route.lng;
    //         arr.push(routeDevice);
    //         setMarkers([...markers], arr);
    //         setIdxRoute(idx++);
    //       }, routeIdx * 500)
    //     );
    //   }
    // } else if (statusRoute === "pause") {
    //   let idx = idxRoute;
    //   timeoutId = routes.map((route, routeIdx) =>
    //     setTimeout(() => {
    //       if (routeIdx >= idxRoute) {
    //         let arr = [];
    //         routeDevice.latitude = route && route.lat;
    //         routeDevice.longitude = route && route.lng;
    //         arr.push(routeDevice);
    //         setMarkers([...markers], arr);
    //         setIdxRoute(idx++);
    //       }
    //     }, routeIdx * 500)
    //   );
    // }
  };

  // useEffect(() => {
  //   if (statusRoute === "stop") {
  //     let arr = [];
  //     let mark = [];
  //     timeoutId?.forEach((id) => clearTimeout(id));
  //     routes.forEach((route) => {
  //       arr.push({ lat: route.lat, lng: route.lng });
  //     });
  //     routeDevice.latitude = routes[0] && routes[0].lat;
  //     routeDevice.longitude = routes[0] && routes[0].lng;
  //     routeDevice.routes = arr;
  //     mark.push(routeDevice);
  //     setMarkers(mark);
  //   } else if (statusRoute === "pause") {
  //     timeoutId?.forEach((id) => clearTimeout(id));
  //   }
  // }, [statusRoute, routeDevice, routes]);

  useEffect(() => {
    localStorage.setItem("arrayStorage", JSON.stringify(arrayStorage));
  }, [arrayStorage]);

  const addStorage = (id) => {
    const newItem = { id: id };
    setArrayStorage([...arrayStorage, newItem]);
  };

  const deleteStorage = (id) => {
    const updatedArray = arrayStorage.filter((item) => item.id !== id);
    setArrayStorage(updatedArray);
  };

  const handleSearch = (keyword) => {
    if (keyword.toString().length > 0) {
      // let arrCompany = [];
      let comp = [];
      let coms = [...companyListOri.current];
      for (const com of coms) {
        comp.push({
          ...com,
          devices: com.devices.filter((d) => {
            return d.name.toLowerCase().includes(keyword.toLowerCase());
          }),
        });
        // let arrTemp = com;
        // arrTemp.devices = [];
        // com.devices.forEach((d) => {
        //   if (d.name.toLowerCase().includes(keyword.toLowerCase())) {
        //     arrTemp.devices.push(d);
        //   }
        // });

        // arrCompany.push(comp);
      }

      console.log(comp, "com");
      setCompanyList(comp);
    } else {
      setCompanyList(companyListOri.current);
    }
  };

  const handleCollapseCompany = (companyId) => {
    let comp = [];
    let coms = [...companyList];
    for (const com of coms) {
      if (com.id === companyId) {
        com.isOpen = !com.isOpen;
      }
      // let arrTemp = com;
      // arrTemp.devices = [];
      // com.devices.forEach((d) => {
      //   if (d.name.toLowerCase().includes(keyword.toLowerCase())) {
      //     arrTemp.devices.push(d);
      //   }
      // });

      comp.push(com);
    }

    console.log(comp, "com");
    setCompanyList(comp);
  };

  const handleCollapseDevice = (companyId, deviceId) => {
    const newListCom = [...companyList];
    const idxCom = newListCom.findIndex((com) => com.id === companyId);
    const idxDev = newListCom[idxCom].devices.findIndex((dev) => dev.id === deviceId);
    newListCom[idxCom].devices[idxDev].isOpen = !newListCom[idxCom].devices[idxDev].isOpen;

    setCompanyList(newListCom);
  };

  const handleMarker = (device) => {
    if (parseFloat(device.latitude) && parseFloat(device.longitude) && parseFloat(device.longitude) !== 0) {
      setDeviceData({ id: device.id, isPanTo: true, isChecked: device.isChecked, location: { lat: device.latitude, lng: device.longitude }, routes: device.routes });
      setMarkers((val) => val);
    } else {
      toast.error("Belum ada lat lng", { autoClose: 3000 });
    }
  };

  // if (loadError) {
  //   return <div>Error loading maps</div>;
  // }

  // if (!isLoaded) {
  //   return <div>Loading maps</div>;
  // }

  document.title = `${t("Monitoring")} | TELKOMSEL - IOT Monitoring`;
  return (
    <React.Fragment>
      <div style={{ margin: "70px 0 0 0", padding: "0" }}>
        <div className="hstack" style={{ height: "88vh" }}>
          <div className="w-25 h-100 monitoring">
            <SimpleBar className="h-100 pt-3 pb-5 pe-3 ps-3" data-simplebar-direction="rtl" style={{ overflowX: "hidden" }}>
              <Row className="mb-3">
                <Col xl={12}>
                  <div className="search-box">
                    <input
                      onChange={(e) => {
                        handleSearch(e.target.value);
                        e.preventDefault();
                      }}
                      id="search-bar-0"
                      type="text"
                      className="form-control search"
                      placeholder={`${t("Search Device")}...`}
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </Col>
              </Row>
              {devicesLoading && (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner color="primary" type="grow" size={"sm"}></Spinner>
                  &nbsp;
                  <Spinner color="success" type="grow" size={"sm"}>
                    Loading...
                  </Spinner>
                  &nbsp;
                </div>
              )}
              {companyList &&
                companyList.map((company, idxCompany) => {
                  return (
                    company.devices.length > 0 && (
                      <div key={company.id + "company"} className="mb-0 shadow-none border">
                        <div className="align-items-center d-flex bg-light p-3 pb-2">
                          <Input
                            type="checkbox"
                            className="me-2"
                            defaultChecked={company.isChecked}
                            onClick={(e) => {
                              handlecheckedCompany(e, company);
                            }}
                          />
                          <div
                            onClick={() => {
                              // console.log(company);
                              handleCollapseCompany(company.id);
                            }}
                            style={{ cursor: "pointer" }}
                            className="w-100 align-items-center justify-content-between d-flex"
                          >
                            <span className="mb-0 flex-grow-1">{company.name}</span>
                            <span>
                              <i className="ri-arrow-down-s-fill fs-20"></i>
                            </span>
                          </div>
                        </div>
                        <CardBody className="p-0 m-0">
                          <Collapse isOpen={company.isOpen} id={`deviceCollapse-${company.id}`}>
                            <div>
                              {company.devices &&
                                company.devices.map((device, idxDevice) => {
                                  let deviceDesc = ReactHtmlParser(device.description);
                                  return (
                                    <Card key={device.id + "device"} className="mb-0">
                                      <CardHeader
                                        className="align-items-center pt-1 pb-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          handleMarker(device);
                                        }}
                                      >
                                        <div className="d-flex pb-0 mb-0">
                                          <Input
                                            type="checkbox"
                                            className="me-2"
                                            defaultChecked={device.isChecked}
                                            onClick={(e) => {
                                              handleCheckedClick(e, device);
                                            }}
                                          />
                                          <div className="mb-0 flex-grow-1">{device.name}</div>
                                          <div className="ms-auto mb-0">
                                            <div className="d-flex align-items-center">
                                              {device.isFleet && (
                                                <Fragment>
                                                  <i
                                                    className={device.isFleet ? "ri-route-line fs-14 me-1 btn btn-sm btn-soft-info shadow-none p-0 ps-1 pe-1" : "d-none"}
                                                    data-bs-toggle="offcanvas"
                                                    data-bs-target="#offcanvasScrollingPlay"
                                                    style={{ cursor: "pointer" }}
                                                    aria-controls="offcanvasScrolling"
                                                    onClick={() => {
                                                      setRouteDevice(device);
                                                    }}
                                                  ></i>
                                                  <div className="offcanvas offcanvas-start" style={{ marginLeft: "70px", width: "370px", marginTop: "72px", height: "88vh" }} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrollingPlay" aria-labelledby="offcanvasScrollingLabel">
                                                    <div className="offcanvas-header">
                                                      <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                                                        Search for tracking using intervals
                                                      </h5>
                                                      <button
                                                        type="button"
                                                        className="btn-close d-flex justify-content-end "
                                                        data-bs-dismiss="offcanvas"
                                                        aria-label="Close"
                                                        onClick={() => {
                                                          // dispatch(resetHistoryState());
                                                          setIsRoute(false);
                                                          setIsCheckRoute(false);
                                                          setRoutes([]);
                                                          setFromDate("");
                                                          setToDate("");
                                                          setStatusRoute("start");
                                                        }}
                                                      ></button>
                                                      <hr />
                                                    </div>
                                                    <div className="offcanvas-body">
                                                      <Row className="mt-3">
                                                        <Col lg={12}>
                                                          <Label>Start</Label>
                                                          <div className="search-box me-2 mb-2 col-12">
                                                            <Flatpickr
                                                              className="form-control"
                                                              id="datepicker-publish-input"
                                                              placeholder={t("Start Date")}
                                                              value={fromDate}
                                                              options={{
                                                                mode: "single",
                                                                dateFormat: "Y F j H:i",
                                                                enableTime: true,
                                                                time_24hr: true,
                                                              }}
                                                              onChange={(obj, str) => {
                                                                setFromDate(obj[0].getTime());
                                                              }}
                                                            />
                                                            <i className="bx bx-calendar-alt search-icon"></i>
                                                          </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                          <Label>End</Label>
                                                          <div className="search-box me-2 mb-2 col-12">
                                                            <Flatpickr
                                                              className="form-control"
                                                              id="datepicker-publish-input"
                                                              placeholder={t("End Date")}
                                                              value={toDate}
                                                              options={{
                                                                mode: "single",
                                                                dateFormat: "Y F j H:i",
                                                                enableTime: true,
                                                                time_24hr: true,
                                                              }}
                                                              onChange={(obj, str) => {
                                                                setToDate(obj[0].getTime());
                                                              }}
                                                            />
                                                            <i className="bx bx-calendar-alt search-icon"></i>
                                                          </div>
                                                        </Col>
                                                      </Row>
                                                      <button
                                                        className="btn btn-success"
                                                        onClick={() => {
                                                          if (fromDate !== "" && toDate !== "") {
                                                            // dispatch(resetHistoryState());
                                                            dispatch(getHistory({ type: "period", uid: routeDevice.identifier, start: fromDate, end: toDate }));
                                                          } else {
                                                            toast.error("Please select startat and endat", {
                                                              autoClose: 3000,
                                                            });
                                                          }
                                                        }}
                                                      >
                                                        Check Route
                                                      </button>
                                                      {isCheckRoute ? <h6 className="mt-5">Actions</h6> : null}
                                                      {isCheckRoute ? (
                                                        <div className="d-flex flex-row mt-3">
                                                          <div className="p-1">
                                                            {statusRoute === "start" || statusRoute === "pause" ? (
                                                              <button
                                                                className="btn btn-success btn-sm"
                                                                onClick={() => {
                                                                  setStatusRoute("play");
                                                                }}
                                                              >
                                                                <i className="ri-play-fill fs-18"></i>
                                                              </button>
                                                            ) : (
                                                              <button
                                                                className="btn btn-warning btn-sm"
                                                                onClick={() => {
                                                                  setStatusRoute("pause");
                                                                  // handlePlay();
                                                                }}
                                                              >
                                                                <i className="ri-pause-fill fs-18"></i>
                                                              </button>
                                                            )}
                                                          </div>

                                                          <div className="p-1">
                                                            <button
                                                              className="btn btn-danger btn-sm"
                                                              onClick={() => {
                                                                setStatusRoute("start");
                                                              }}
                                                            >
                                                              <i className="ri-stop-fill fs-18"></i>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </Fragment>
                                              )}

                                              <Link
                                                to="#"
                                                className={"fs-14 btn btn-sm btn-soft-info shadow-none p-0 ps-1 pe-1 m-0"}
                                                onClick={(e) => {
                                                  handleCollapseDevice(company.id, device.id);
                                                }}
                                              >
                                                <i className="ri-arrow-down-s-fill"></i>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center mt-0 ms-4">
                                          {(device.status === "Signal" || device.status === "signal") && device.toMinute >= 30 ? <MdOutlineWifiOff className="text-danger me-1 fs-14" id={"wifi-" + device.id} style={{ cursor: "pointer" }} /> : <MdOutlineWifi className="text-success me-1 fs-14" id={"wifi-" + device.id} style={{ cursor: "pointer" }} />}
                                          <UncontrolledTooltip placement="top" target={"wifi-" + device.id} trigger="hover">
                                            {(device.status === "Signal" || device.status === "signal") && device.toMinute >= 30 ? "Jaringan Buruk" : "Jaringan Bagus"}
                                          </UncontrolledTooltip>
                                          {(device.status === "Maintenance" || device.status === "maintenance") && device.toMinute >= 30 ? <i className="ri-tools-line me-1 fs-14 text-danger" id={"maintenance-" + device.id} style={{ cursor: "pointer" }}></i> : <i className="ri-tools-line me-1 fs-14 text-success" id={"maintenance-" + device.id} style={{ cursor: "pointer" }}></i>}
                                          <UncontrolledTooltip placement="top" target={"maintenance-" + device.id} trigger="hover">
                                            {(device.status === "Maintenance" || device.status === "maintenance") && device.toMinute >= 30 ? "Sedang ada maintenance" : "Tidak ada maintenance"}
                                          </UncontrolledTooltip>
                                          <BsDeviceSsdFill className={device.sensor_status === 0 ? "fs-12 me-1 text-danger" : device.sensor_status === 1 ? "fs-12 me-1 text-success" : "fs-12 me-1 text-danger"} id={"com-" + device.id} style={{ cursor: "pointer" }} />
                                          <UncontrolledTooltip placement="top" target={"com-" + device.id} trigger="hover">
                                            {device.sensor_status === 0 ? "Status Nonaktif" : device.sensor_status === 1 ? "Sensor Aktif" : "Sensor Nonaktif"}
                                          </UncontrolledTooltip>
                                          <i className={device.toMinute <= 15 ? "ri-radio-button-line text-success me-1 fs-14" : device.toMinute > 15 && device.toMinute <= 30 ? "me-1 fs-14 ri-radio-button-line text-warning" : "me-1 fs-14 ri-radio-button-line text-danger"} id={"dots-" + device.id} style={{ cursor: "pointer" }}></i>
                                          <UncontrolledTooltip placement="top" target={"dots-" + device.id} trigger="hover">
                                            {device.toMinute <= 15 ? "Device Aktif" : device.toMinute > 15 && device.toMinute <= 30 ? "Device Idle > 15 Menit " : "Device Nonaktif"}
                                          </UncontrolledTooltip>
                                          <div className="fs-10 align-items-center">
                                            <i className="ri-time-fill fs-14 text-muted"></i> {device.device_time ? handleValidDate(device.device_time) : "-"}
                                          </div>
                                        </div>
                                      </CardHeader>
                                      <CardBody className="p-0 bg-light">
                                        <Collapse isOpen={device.isOpen} id="collapseExample">
                                          <div className="p-2 border-bottom">
                                            <div className="bg-light d-flex p-2 rounded position-relative ">
                                              <div className="flex-shrink-0 avatar-xs">
                                                <div className="avatar-title bg-soft-success text-success fs-15 rounded">
                                                  <i className="ri-router-fill"></i>
                                                </div>
                                              </div>
                                              <div className="d-flex flex-column ">
                                                <div className="ms-2">
                                                  <span
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "black",
                                                    }}
                                                  >
                                                    {`Name : ${device.name || "-"}`}
                                                  </span>
                                                </div>
                                                <div className="ms-2">
                                                  <span
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "black",
                                                    }}
                                                  >
                                                    {`Code : ${device.code || "-"}`}
                                                  </span>
                                                </div>

                                                <div className="ms-2">
                                                  <span
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "black",
                                                    }}
                                                  >
                                                    {`Service : ${device.device_service?.name || "-"}`}
                                                  </span>
                                                </div>
                                                <div className="ms-2">
                                                  <span
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "black",
                                                    }}
                                                  >
                                                    {`Identifier : ${device.identifier || "-"}`}
                                                  </span>
                                                </div>
                                                <div className="ms-2">
                                                  <span
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "black",
                                                    }}
                                                  >
                                                    {`Imei : ${device.imei || "-"}`}
                                                  </span>
                                                </div>
                                                <div className="ms-2">
                                                  <span
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "black",
                                                    }}
                                                  >
                                                    {`Host : ${device.host || "-"}`}
                                                  </span>
                                                </div>
                                                <div className="ms-2">
                                                  <span
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "black",
                                                    }}
                                                  >
                                                    {`Port : ${device.port || "-"}`}
                                                  </span>
                                                </div>
                                                <div className="ms-2">
                                                  <span
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "black",
                                                    }}
                                                  >
                                                    {`Description : ${deviceDesc[0]?.props.children[0] || "-"}`}
                                                  </span>
                                                </div>
                                                {/* <div className="ms-2">
                                                  <span
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "black",
                                                    }}
                                                  >
                                                    {`Address : ${formattedAddress || "-"}`}
                                                  </span>
                                                </div> */}
                                              </div>
                                            </div>
                                          </div>
                                        </Collapse>
                                      </CardBody>
                                    </Card>
                                  );
                                })}
                            </div>
                          </Collapse>
                        </CardBody>
                      </div>
                    )
                  );
                })}

              {/* /// */}
              {/* {!isDone ? (
                      <div className="d-flex justify-content-center mt-3">
                        <Spinner color="primary" type="grow" size={"sm"}></Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </div>
                    ) : (
                      <UncontrolledAccordion
                        open={open}
                        toggle={toggleAccordion}
                        className="accordion-icon-none"
                        flush
                        stayOpen
                        // defaultOpen={toggleDefault ? idxCompanies : []}
                      >
                        {(companyArr || []).map((group, groupIdx) => {
                          return (
                            <Fragment key={"group-" + groupIdx}>
                              {(group || []).map((company, companyIdx) => {
                                return (
                                  <AccordionItem key={"company-" + companyIdx} className="p-0">
                                    <AccordionHeader targetId={"company-" + companyIdx}>
                                      <h6>{company.name}</h6>
                                    </AccordionHeader>
                                    <AccordionBody className="p-0" accordionId={"company-" + companyIdx}>
                                      {(company.com_branch || []).map((branch, branchIdx) => {
                                        return (
                                          <Fragment key={"branch-" + branchIdx}>
                                            {branch.br_unit.map((unit, unitIdx) => {
                                              let dataDevices = [];
                                              let dataDev;
                                              if (searchInput !== "") {
                                                const results = unit.un_device.filter((elemen) => {
                                                  return elemen.name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
                                                });
                                                dataDev = results;
                                              } else {
                                                dataDev = unit.un_device;
                                              }
                                              dataDev.forEach((devices) => {
                                                if (devices.device_status.id === 2) {
                                                  dataDevices.push(devices);
                                                }
                                              });
                                              console.log(dataDevices);
                                              if (filter.value === "name") {
                                                dataDevices.sort((a, b) => a.name.localeCompare(b.name));
                                              } else {
                                                dataDevices.sort((a, b) => a.toMinute - b.toMinute);
                                              }
                                              return (
                                                <div key={"unit-" + unitIdx}>
                                                  {(dataDevices || []).map((device, deviceIdx) => {
                                                    let groupNumberr = deviceIdx + 1;
                                                    let deviceDesc = ReactHtmlParser(device.description);
                                                    return (
                                                      <>
                                                        <div className="d-flex">
                                                          <div className="mt-2">
                                                            <Input
                                                              type="checkbox"
                                                              className="me-2 ms-2"
                                                              checked={device.isMarkerActive ? true : false}
                                                              onClick={(e) => {
                                                                if (e.target.checked === true) {
                                                                  handleMarkerClick(device.id, parseFloat(device.latitude), parseFloat(device.longitude), device.name);
                                                                  device.isMarkerActive = true;
                                                                  addStorage(device.id);
                                                                } else {
                                                                  device.isMarkerActive = false;
                                                                  deleteStorage(device.id);
                                                                }
                                                                setDeviceCheck([...deviceCheck, device.id]);
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="mt-0">
                                                            <AccordionItem
                                                              className="p-0 w-100"
                                                              key={"device-" + groupNumberr}
                                                              onClick={() => {
                                                                handleMarkerClick(device.id, parseFloat(device.latitude), parseFloat(device.longitude), device.name);
                                                              }}
                                                            >
                                                              <div className="accordion-header accordion-device" style={{ width: "160px" }}>
                                                                <div
                                                                  className={classnames("accordion-button p-1", {
                                                                    collapsed: activeGroupAccordion === `device-${device.id}-` + groupNumberr ? false : true,
                                                                  })}
                                                                  type="button"
                                                                  onClick={(e) => {
                                                                    setGroupAccordion(`device-${device.id}-` + groupNumberr);
                                                                    setUnitClick(device);
                                                                    setUnitIdx(deviceIdx);
                                                                    if (activeGroupAccordion === `device-${device.id}-` + groupNumberr) {
                                                                      device.isMarkerActive = false;
                                                                      deleteStorage(device.id);
                                                                      setZoomVal("0");
                                                                    } else if (e.target.textContent === device.name) {
                                                                      device.isMarkerActive = true;
                                                                      addStorage(device.id);
                                                                      setZoomVal("1");
                                                                    }
                                                                  }}
                                                                  style={{
                                                                    cursor: "pointer",
                                                                  }}
                                                                >
                                                                  <div className="d-flex justify-content-center mt-1">
                                                                    <div>
                                                                      <span
                                                                        style={{
                                                                          fontSize: "12px",
                                                                        }}
                                                                      >
                                                                        {device.name}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <Collapse isOpen={activeGroupAccordion === `device-${device.id}-` + groupNumberr ? true : false} id={device.id} style={{ width: "160px" }}>
                                                                <div className="d-flex border border-dashed p-2 mb-3 rounded position-relative ">
                                                                  <div className="flex-shrink-0 avatar-xs">
                                                                    <div className="avatar-title bg-soft-success text-success fs-15 rounded">
                                                                      <i className="ri-router-fill"></i>
                                                                    </div>
                                                                  </div>
                                                                  <div className="d-flex flex-column ">
                                                                    <div className="ms-2">
                                                                      <span
                                                                        style={{
                                                                          fontSize: "10px",
                                                                          color: "black",
                                                                        }}
                                                                      >
                                                                        {`Name : ${device.name || "-"}`}
                                                                      </span>
                                                                    </div>
                                                                    <div className="ms-2">
                                                                      <span
                                                                        style={{
                                                                          fontSize: "10px",
                                                                          color: "black",
                                                                        }}
                                                                      >
                                                                        {`Code : ${device.code || "-"}`}
                                                                      </span>
                                                                    </div>

                                                                    <div className="ms-2">
                                                                      <span
                                                                        style={{
                                                                          fontSize: "10px",
                                                                          color: "black",
                                                                        }}
                                                                      >
                                                                        {`Service : ${device.device_service?.name || "-"}`}
                                                                      </span>
                                                                    </div>
                                                                    <div className="ms-2">
                                                                      <span
                                                                        style={{
                                                                          fontSize: "10px",
                                                                          color: "black",
                                                                        }}
                                                                      >
                                                                        {`Identifier : ${device.identifier || "-"}`}
                                                                      </span>
                                                                    </div>
                                                                    <div className="ms-2">
                                                                      <span
                                                                        style={{
                                                                          fontSize: "10px",
                                                                          color: "black",
                                                                        }}
                                                                      >
                                                                        {`Imei : ${device.imei || "-"}`}
                                                                      </span>
                                                                    </div>
                                                                    <div className="ms-2">
                                                                      <span
                                                                        style={{
                                                                          fontSize: "10px",
                                                                          color: "black",
                                                                        }}
                                                                      >
                                                                        {`Host : ${device.host || "-"}`}
                                                                      </span>
                                                                    </div>
                                                                    <div className="ms-2">
                                                                      <span
                                                                        style={{
                                                                          fontSize: "10px",
                                                                          color: "black",
                                                                        }}
                                                                      >
                                                                        {`Port : ${device.port || "-"}`}
                                                                      </span>
                                                                    </div>
                                                                    <div className="ms-2">
                                                                      <span
                                                                        style={{
                                                                          fontSize: "10px",
                                                                          color: "black",
                                                                        }}
                                                                      >
                                                                        {`Description : ${deviceDesc[0]?.props.children[0] || "-"}`}
                                                                      </span>
                                                                    </div>
                                                                    <div className="ms-2">
                                                                      <span
                                                                        style={{
                                                                          fontSize: "10px",
                                                                          color: "black",
                                                                        }}
                                                                      >
                                                                        {`Address : ${formattedAddress || "-"}`}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </Collapse>
                                                            </AccordionItem>
                                                          </div>
                                                          <div className="ms-auto">
                                                            <div className="d-flex">
                                                              <i className="ri-focus-3-line text-success me-1 fs-17 d-none"></i>
                                                              <i className="ri-arrow-right-circle-fill text-success me-1 fs-17 d-none"></i>
                                                              {(device.status === "Signal" || device.status === "signal") && device.toMinute >= 30 ? <MdOutlineWifiOff className="fs-18 text-danger me-1 mt-1" id={"wifi-" + device.id} style={{ cursor: "pointer" }} /> : <MdOutlineWifi className="fs-18 text-success me-1 mt-1" id={"wifi-" + device.id} style={{ cursor: "pointer" }} />}
                                                              <UncontrolledTooltip placement="top" target={"wifi-" + device.id} trigger="hover">
                                                                {(device.status === "Signal" || device.status === "signal") && device.toMinute >= 30 ? "Jaringan Buruk" : "Jaringan Bagus"}
                                                              </UncontrolledTooltip>
                                                              {(device.status === "Maintenance" || device.status === "maintenance") && device.toMinute >= 30 ? <i className="ri-tools-line fs-18 text-danger me-1 mt-0" id={"maintenance-" + device.id} style={{ cursor: "pointer" }}></i> : <i className="ri-tools-line fs-18 text-success me-1 mt-0" id={"maintenance-" + device.id} style={{ cursor: "pointer" }}></i>}
                                                              <UncontrolledTooltip placement="top" target={"maintenance-" + device.id} trigger="hover">
                                                                {(device.status === "Maintenance" || device.status === "maintenance") && device.toMinute >= 30 ? "Sedang ada maintenance" : "Tidak ada maintenance"}
                                                              </UncontrolledTooltip>
                                                              <BsDeviceSsdFill className={device.sensor_status === 0 ? " fs-15 me-1 text-danger mb-2 mt-1" : device.sensor_status === 1 ? " fs-15 me-1 text-success mb-2 mt-1" : "fs-15 me-1 text-danger mb-2 mt-1"} id={"com-" + device.id} style={{ cursor: "pointer" }} />
                                                              <UncontrolledTooltip placement="top" target={"com-" + device.id} trigger="hover">
                                                                {device.sensor_status === 0 ? "Status Nonaktif" : device.sensor_status === 1 ? "Sensor Aktif" : "Sensor Nonaktif"}
                                                              </UncontrolledTooltip>
                                                              <i className={device.toMinute <= 15 ? "ri-radio-button-line text-success me-1 fs-17 " : device.toMinute > 15 && device.toMinute <= 30 ? "ri-radio-button-line text-warning me-1 fs-17 " : "ri-radio-button-line text-danger me-1 fs-17"} id={"dots-" + device.id} style={{ cursor: "pointer" }}></i>
                                                              <UncontrolledTooltip placement="top" target={"dots-" + device.id} trigger="hover">
                                                                {device.toMinute <= 15 ? "Device Aktif" : device.toMinute > 15 && device.toMinute <= 30 ? "Device Idle > 15 Menit " : "Device Nonaktif"}
                                                              </UncontrolledTooltip>

                                                              {company.id === 2 || company.id === 7 || company.id === 1 ? (
                                                                <Fragment>
                                                                  <i
                                                                    className={device.isFleet ? "ri-route-line fs-17" : "d-none"}
                                                                    data-bs-toggle="offcanvas"
                                                                    data-bs-target="#offcanvasScrollingPlay"
                                                                    style={{ cursor: "pointer" }}
                                                                    aria-controls="offcanvasScrolling"
                                                                    onClick={() => {
                                                                      setRouteDevice(device);
                                                                    }}
                                                                  ></i>
                                                                  <div className="offcanvas offcanvas-start" style={{ marginLeft: "95px", width: "376px", marginTop: "92px", height: "633px" }} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrollingPlay" aria-labelledby="offcanvasScrollingLabel">
                                                                    <div className="offcanvas-header">
                                                                      <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                                                                        Search for tracking using intervals
                                                                      </h5>
                                                                      <button
                                                                        type="button"
                                                                        className="btn-close d-flex justify-content-end "
                                                                        data-bs-dismiss="offcanvas"
                                                                        aria-label="Close"
                                                                        onClick={() => {
                                                                          setIsCheckRoute(false);
                                                                          setRouteDevice({});
                                                                          // setMarkers(deviceList)
                                                                          setZoomVal("0");
                                                                          routeDevice.routes = [];
                                                                        }}
                                                                      ></button>
                                                                      <hr />
                                                                    </div>
                                                                    <div className="offcanvas-body">
                                                                      <Row className="mt-3">
                                                                        <Col lg={12}>
                                                                          <Label>Start</Label>
                                                                          <div className="search-box me-2 mb-2 col-12">
                                                                            <Flatpickr
                                                                              className="form-control"
                                                                              id="datepicker-publish-input"
                                                                              placeholder={t("Start Date")}
                                                                              options={{
                                                                                mode: "single",
                                                                                dateFormat: "Y F j H:i",
                                                                                enableTime: true,
                                                                                time_24hr: true,
                                                                              }}
                                                                              onChange={(obj, str) => {
                                                                                setFromDate(obj[0].getTime());
                                                                              }}
                                                                            />
                                                                            <i className="bx bx-calendar-alt search-icon"></i>
                                                                          </div>
                                                                        </Col>
                                                                        <Col lg={12}>
                                                                          <Label>End</Label>
                                                                          <div className="search-box me-2 mb-2 col-12">
                                                                            <Flatpickr
                                                                              className="form-control"
                                                                              id="datepicker-publish-input"
                                                                              placeholder={t("End Date")}
                                                                              options={{
                                                                                mode: "single",
                                                                                dateFormat: "Y F j H:i",
                                                                                enableTime: true,
                                                                                time_24hr: true,
                                                                              }}
                                                                              onChange={(obj, str) => {
                                                                                setToDate(obj[0].getTime());
                                                                              }}
                                                                            />
                                                                            <i className="bx bx-calendar-alt search-icon"></i>
                                                                          </div>
                                                                        </Col>
                                                                      </Row>
                                                                      <button
                                                                        className="btn btn-success"
                                                                        onClick={() => {
                                                                          setIsRoute(true);
                                                                          setIsCheckRoute(true);
                                                                          dispatch(resetHistoryState());
                                                                          dispatch(getHistory({ type: "period", uid: routeDevice.identifier, start: fromDate, end: toDate }));
                                                                        }}
                                                                      >
                                                                        Check Route
                                                                      </button>
                                                                      {isCheckRoute ? <h6 className="mt-5">Actions</h6> : null}
                                                                      {isCheckRoute ? (
                                                                        <div className="d-flex flex-row mt-3">
                                                                          <div className="p-1">
                                                                            {statusRoute === "pause" || statusRoute === "stop" || statusRoute === "start" ? (
                                                                              <button
                                                                                className="btn btn-success btn-sm"
                                                                                onClick={() => {
                                                                                  setStatusRoute("play");
                                                                                  handlePlay();
                                                                                }}
                                                                              >
                                                                                <i className="ri-play-fill fs-18"></i>
                                                                              </button>
                                                                            ) : statusRoute === "play" ? (
                                                                              <button
                                                                                className="btn btn-warning btn-sm"
                                                                                onClick={() => {
                                                                                  setStatusRoute("pause");
                                                                                }}
                                                                              >
                                                                                <i className="ri-pause-fill fs-18"></i>
                                                                              </button>
                                                                            ) : null}
                                                                          </div>
                                                                          <div className="p-1">
                                                                            <button
                                                                              className="btn btn-danger btn-sm"
                                                                              onClick={() => {
                                                                                setStatusRoute("stop");
                                                                              }}
                                                                            >
                                                                              <i className="ri-stop-fill fs-18"></i>
                                                                            </button>
                                                                          </div>
                                                                        </div>
                                                                      ) : null}
                                                                    </div>
                                                                  </div>
                                                                </Fragment>
                                                              ) : null}
                                                              <div className="flex-shrink-1">
                                                                <UncontrolledDropdown className="card-header-dropdown me-0">
                                                                  <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                                                                    <span className="text-muted fs-18">
                                                                      <i className="mdi mdi-dots-vertical"></i>
                                                                    </span>
                                                                  </DropdownToggle>
                                                                  <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem
                                                                      onClick={() => {
                                                                        setAccordion("device-" + groupNumberr);
                                                                      }}
                                                                    >
                                                                      {activeAccordion === "device-" + groupNumberr ? `${t("Hide")}` : `${t("Show")}`}
                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => {}}>{`${t("Update")} ${t("Unit")}`}</DropdownItem>
                                                                  </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                              );
                                            })}
                                          </Fragment>
                                        );
                                      })}
                                    </AccordionBody>
                                  </AccordionItem>
                                );
                              })}
                            </Fragment>
                          );
                        })}
                      </UncontrolledAccordion>
                    )} */}
            </SimpleBar>
          </div>
          <div className="w-75  h-100">
            <GoogleComponent loading={devicesLoading || historiesLoading || publicLoading} markers={isCheckRoute ? routes : markers} deviceData={deviceData} onClickView={onClickView} isCheckRoute={isCheckRoute} statusRoute={statusRoute} />

            {/* <div id="gmaps-markers" className="gmaps" style={{ position: "relative" }}>
                <GoogleMap
                  mapContainerStyle={mapStyles}
                  zoom={companyId && companyId === 2 && zoomVal && zoomVal === "0" ? 11 : zoomVal && zoomVal !== "0" ? 16 : 5}
                  center={companyId && companyId === 2 && zoomVal && zoomVal === "0" ? { lat: 1.0579192, lng: 117.6434826 } : zoomVal !== "0" ? mapRef : center}
                  options={{
                    mapTypeId: "hybrid",
                    mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                  }}
                  onLoad={(map) => setMap(map)}
                  // onClick={() => setIsOpen(false)}
                >
                  <MarkerClusterer gridSize={60}>
                    {(clusterer) => (
                      <div>
                        {markers
                          ? markers.map((mark, markIdx) => {
                              if (mark.routes) {
                                if (mark.routes.length > 10) {
                                  mark.routes.splice(0, 1);
                                }
                              }
                              return (
                                <div key={markIdx}>
                                  {mark.isMarkerActive ? (
                                    <Marker
                                      position={{
                                        lat: parseFloat(mark.latitude),
                                        lng: parseFloat(mark.longitude),
                                      }}
                                      clusterer={clusterer}
                                      key={mark.id}
                                      title={mark.name}
                                      label={{
                                        color: "#f0d7c2",
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        text: mark.name,
                                        className: "mb-5 pb-3",
                                      }}
                                      icon={{
                                        url:
                                          mark.device_service && mark.device_service.name === "Fuel Management System"
                                            ? require("../../assets/images/map-tank.png")
                                            : mark.device_service && mark.device_service.name === "Power Monitoring"
                                            ? require("../../assets/images/map-power.png")
                                            : mark.device_service && mark.device_service.name === "Vessel Monitoring System"
                                            ? require("../../assets/images/map-vessel.png")
                                            : mark.device_service && mark.device_service.name === "Smart Water Monitoring"
                                            ? require("../../assets/images/map-water.png")
                                            : mark.device_service && mark.device_service.name === "Engine Monitoring"
                                            ? require("../../assets/images/map-engine.png")
                                            : mark.device_service && mark.device_service.name === "Energy Monitoring System"
                                            ? require("../../assets/images/map-lighting.png")
                                            : mark.device_service && mark.device_service.name === "Temperature Monitoring"
                                            ? require("../../assets/images/map-weather.png")
                                            : mark.device_service && mark.device_service.name === "Gps Tracking"
                                            ? require("../../assets/images/map-gps.png")
                                            : null,
                                        scaledSize: new window.google.maps.Size(32, 50),
                                      }}
                                      onClick={() => {
                                        handleMarkerClick(markIdx, parseFloat(mark.latitude), parseFloat(mark.longitude), mark.name);
                                        setIsOpen(true);
                                        setZoomVal("1");
                                        let geo = `${mark.latitude}, ${mark.longitude}`;
                                        const geocoder = new window.google.maps.Geocoder();
                                        geocodeLatLng(geocoder, geo);
                                      }}
                                    >
                                      {isOpen && infoWindowData?.id === markIdx ? (
                                        <InfoWindow
                                          onCloseClick={() => {
                                            setIsOpen(false);
                                          }}
                                        >
                                          <div>
                                            <div className="d-flex">
                                              <div className="justify-content-start">
                                                <h3 className="text-primary">{mark.name}</h3>
                                                <p className="m-0">{`Location : ${mark.latitude},${mark.longitude}`}</p>
                                                <p className="m-0">Identifier: {mark.identifier}</p>
                                                <p className="m-0 mb-3">Phone: {mark.phone}</p>
                                              </div>
                                              <div className="justify-content-end ms-3 mt-3">
                                                <button
                                                  onClick={() => {
                                                    onClickView(mark);
                                                  }}
                                                  className="btn btn-primary"
                                                  type="button"
                                                  data-bs-toggle="offcanvas"
                                                  data-bs-target="#offcanvasExample"
                                                  aria-controls="offcanvasExample"
                                                >
                                                  {t("View")}
                                                </button>
                                              </div>
                                            </div>
                                            <img alt="" src={mark.image ? api.MEDIA_URL + "/media/" + mark.image : dummyImg} className="img-thumbnail" width="300" />
                                          </div>
                                        </InfoWindow>
                                      ) : accClick && accValue.id === mark.id ? (
                                        <InfoWindow
                                          onCloseClick={() => {
                                            setIsOpen(false);
                                            setAccClick(false);
                                            setAccValue();
                                          }}
                                        >
                                          <div>
                                            <div className="d-flex">
                                              <div className="justify-content-start">
                                                <h3 className="text-primary">{mark.name}</h3>
                                                <p className="m-0">{`Location : ${mark.latitude},${mark.longitude}`}</p>
                                                <p className="m-0">Identifier: {mark.identifier}</p>
                                                <p className="m-0 mb-3">Phone: {mark.phone}</p>
                                              </div>
                                              <div className="justify-content-end ms-3 mt-3">
                                                <button
                                                  onClick={() => {
                                                    onClickView(mark);
                                                  }}
                                                  className="btn btn-primary"
                                                  type="button"
                                                  data-bs-toggle="offcanvas"
                                                  data-bs-target="#offcanvasExample"
                                                  aria-controls="offcanvasExample"
                                                >
                                                  {t("View")}
                                                </button>
                                              </div>
                                            </div>
                                            <img alt="" src={mark.image ? api.MEDIA_URL + "/media/" + mark.image : dummyImg} className="img-thumbnail" width="300" />
                                          </div>
                                        </InfoWindow>
                                      ) : null}
                                      {mark.isFleet && mark.routes && mark.routes.length > 0 ? (
                                        <Fragment>
                                          <Polyline
                                            path={mark.routes}
                                            options={{
                                              strokeColor: "#ADFF2F",
                                              strokeOpacity: 0,
                                              strokeWeight: 2,
                                              icons: [
                                                {
                                                  icon: lineSymbol,
                                                  offset: "0",
                                                  repeat: "1px",
                                                },
                                              ],
                                            }}
                                          />
                                        </Fragment>
                                      ) : null}
                                    </Marker>
                                  ) : null}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    )}
                  </MarkerClusterer>
                </GoogleMap>
              </div> */}
          </div>
        </div>
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ height: "100%", width: "100%" }}>
          <div className="offcanvas-header">
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <Row className="project-wrapper">
              <Col xl={12}>
                <Col xl={12}>{dataDevice ? <CardParamsComponent data={dataDevice} t={t} /> : null}</Col>
                <Col xxl={12}>
                  <Row>
                    <Col xl={6}>
                      <Card>
                        <CardHeader className="align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">{dataDevice && dataDevice.name ? dataDevice.name : "-"}</h4>
                        </CardHeader>
                        <CardBody>
                          <div>
                            <div className="flex-shrink-0 mb-3">
                              <img
                                src={primaryImg && primaryImg ? primaryImg : dummyImg}
                                className="rounded img-fluid"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                alt=""
                              />
                            </div>
                            <div className="mb-3 ">
                              <h5 className="card-title mb-2">{t("Description")}</h5>
                              <p>{dataDevice && dataDevice.description ? dataDevice.description.replace(/<[^>]+>/g, "") : "-"}</p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      {/* <TaskDeviceComponent
                          data={deviceOnes ? deviceOnes : []}
                        /> */}
                      {deviceOnes.device_parameter_history && deviceOnes.device_parameter_history.length > 0 ? <ParamsHistoryComponent data={deviceOnes ? deviceOnes : []} /> : null}
                    </Col>
                    <Col xl={6}>
                      <Col xl={12}>
                        <Row>
                          <Col xl={12}>
                            <StatusWidget t={t} histories={dataStatus && dataStatus.length > 0 ? dataStatus : {}} publicloading={publicLoading} />
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12}>
                        <Row>
                          <Col xl={12}>
                            <Card>
                              <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">{t("Media Files")}</h4>
                              </CardHeader>
                              <CardBody className="p-0">
                                <ul className="list-group list-group-flush border-dashed px-3 divi">
                                  {fileList.length > 0 ? (
                                    fileList.map((item, idx) => (
                                      <li className="list-group-item ps-0" key={"filelist" + idx}>
                                        <div className="d-flex align-items-center">
                                          <i className="bx bx-file"></i>
                                          <div className="flex-grow-1">
                                            <label className="form-check-label mb-0 ps-2">{item.filename}</label>
                                          </div>
                                        </div>
                                      </li>
                                    ))
                                  ) : (
                                    <h6 className="mt-2">{t("No media files")}</h6>
                                  )}
                                </ul>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xl={12}>
                            <AlertWidget t={t} />
                          </Col>
                        </Row>
                        <Col xl={12}>
                          <StatisticComponent series={series} seriesCategories={seriesCategories} t={t} publicLoading={publicLoading} />
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

Monitoring.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Monitoring));
