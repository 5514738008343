import { takeEvery, takeLatest, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Login Redux States
import {
  API_ERROR,
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_SOCIAL,
} from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { setAuthorization } from "../../../helpers/api_helper";

//Include Both Helper File with needed methods
import {
  postLogin,
  postLoginSocial,
  postRefreshToken,
} from "../../../helpers/backend_helper";

//const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      username: user.username,
      password: user.password,
      clientAppId: 2,
    });
    console.log(`response`, response);
    if (response.status === "success") {
      sessionStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
      const token = response.token;
      if (token) setAuthorization(token);
    } else {
      yield put(apiError(response.message));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    sessionStorage.removeItem("authUser");
    sessionStorage.removeItem("mapApiKey")
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      yield put(logoutUserSuccess(LOGOUT_USER, {}));
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* loginSocial({ payload: { user, history } }) {
  try {
    const response = yield call(postLoginSocial, {
      type: "login",
      email: user.email,
      tokenId: user.tokenId,
      clientAppId: 2,
    });
    console.log(`response`, response.status);
    if (response.status === "success") {
      sessionStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
      const token = response.token;
      if (token) setAuthorization(token);
    } else {
      yield put(apiError(response.message));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(LOGIN_SOCIAL, loginSocial);
}

export default authSaga;
