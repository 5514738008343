import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button, CardHeader, Progress, Nav, NavItem, NavLink, TabContent, TabPane, Spinner } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isEmpty } from "lodash";

// action

import { registerUser, registerUserFailed, resetRegisterFlag } from "../../store/auth/register/actions";
import {
    getClient as onGetClients,
    addNewClient as onAddNewClient,
    updateClient as onUpdateClient,
    deleteClient as onDeleteClient,
    getClientLoading,
} from "../../store/client/action";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";

//import images 
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import classnames from "classnames";
import dummyUser from "../../assets/images/users/user-dummy-img.jpg"
import Select from "react-select";
import AsyncSelect from "react-select/async";

const Register = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [samePw, setSamePw] = useState(false)
    const [progressbarvalue, setprogressbarvalue] = useState(0);
    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [villageList, setVillageList] = useState([]);


    function toggleTab(tab, value) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
        setprogressbarvalue(value);
    }


    const handleSubscribedAt = (date) => {
        validation.setFieldValue("subscribedat", date);
    };

    const handleExpirededAt = (date) => {
        validation.setFieldValue("expiredat", date);
    };

    const statusActiveData = [
        {
            options: [
                { label: "Activated", value: "1" },
                { label: "Deactivated", value: "2" },
            ],
        },
    ];

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: '',
            email: '',
            username: '',
            password: '',
            phone: '',
            client_name: '',
            subscribedat: '',
            expiredat: '',
            description: '',
            statusId: '',
            address: '',
            villageId: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Your Name"),
            username: Yup.string().required("Please Enter Your Username"),
            password: Yup.string().required("Please Enter Your Password"),
            email: Yup.string().required("Please Enter Your Email"),
            phone: Yup.string().required("Please Enter Your Phone"),
            retype_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords does not match'),
        }),
        onSubmit: (values) => {
            const newRegister = {
                name: values.name,
                email: values.email,
                username: values.username,
                password: values.password,
                phone: values.phone,
                client_name: values.client_name,
                subscribedat: null,
                expiredat: null,
                description: values.description,
                statusId: null,
                address: values.address,
                villageId: values.village.value,
            };
            dispatch(registerUser(newRegister));
        }
    });

    const { error, registrationError, success, villages, villageLoading } = useSelector(state => ({
        success: state.Register.success,
        error: state.Register.error,
        registrationError: state.Register.registrationError,
        villages: state.Village.villages,
        villageLoading: state.Village.loading,
    }));

    // useEffect(() => {
    //     dispatch(registerUserFailed());
    // }, [dispatch]);

    useEffect(() => {
        if (!isEmpty(villages)) {
            var villageData = [];
            var opt = [];
            villages.forEach((item, key) => {
                const village_name = item.village_name;
                const id = item.id;
                const city_name = item.vc.city_name;
                const district_name = item.vd.district_name;
                const province_name = item.vp.province_name;
                var obj = {
                    label:
                        village_name +
                        ", " +
                        district_name +
                        ", " +
                        city_name +
                        " - " +
                        province_name,
                    value: id,
                };
                opt.push(obj);
            });
            var option = { options: opt };
            villageData.push(option);
            setVillageList(villageData);
        }
    }, [villages]);

    useEffect(() => {
        if (success) {
            setTimeout(() => history("/login"), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);

    }, [dispatch, success, error, history]);

    const retype = (event) => {
        if (validation.values.password === event.target.value) {
            setSamePw(true)
        } else {
            setSamePw(false)
        }
    }

    useEffect(() => {
        if (validation.values.password === validation.values.retype_password) {
            setSamePw(true)
        } else {
            setSamePw(false)
        }
    }, [validation.values.password, validation.values.retype_password])

    document.title = "Register | TELKOMSEL ";

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="90" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={8} xl={8}>
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={(e) => {
                                            if (samePw) {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }
                                        }}
                                            className="needs-validation" action="#">
                                            <div className="text-center pt-3 pb-4 mb-1">
                                                <h5>Signup Your Account</h5>
                                            </div>

                                            <div className="progress-nav mb-4">
                                                <Progress
                                                    value={progressbarvalue}
                                                    style={{ height: "1px" }}
                                                />

                                                <Nav
                                                    className="nav-pills progress-bar-tab custom-nav"
                                                    role="tablist"

                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            to="#"
                                                            id="pills-gen-info-tab"
                                                            className={classnames(
                                                                {
                                                                    active: activeTab === 1,
                                                                    done: activeTab <= 4 && activeTab >= 0,
                                                                },
                                                                "rounded-pill"
                                                            )}
                                                            onClick={() => {
                                                                toggleTab(1, 0);
                                                            }}
                                                            tag="button"
                                                        >
                                                            1
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            to="#"
                                                            id="pills-gen-info-tab"
                                                            className={classnames(
                                                                {
                                                                    active: activeTab === 2,
                                                                    done: activeTab <= 4 && activeTab > 1,
                                                                },
                                                                "rounded-pill"
                                                            )}
                                                            onClick={() => {
                                                                toggleTab(2, 50);
                                                            }}
                                                            tag="button"
                                                        >
                                                            2
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>

                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={1}>
                                                    <div>
                                                        <div className="mb-4">
                                                            <div>
                                                                <h5 className="mb-1">General Information</h5>
                                                                <p className="text-muted">
                                                                    Fill all Information as below
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="name" className="form-label">Name  <span className="text-danger">*</span></Label>
                                                                    <Input
                                                                        name="name"
                                                                        type="text"
                                                                        placeholder="Enter name"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.name || ""}
                                                                        invalid={
                                                                            validation.touched.name && validation.errors.name ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.name && validation.errors.name ? (
                                                                        <FormFeedback type="invalid"><div>{validation.errors.name}</div></FormFeedback>
                                                                    ) : null}

                                                                </div>

                                                                <div className="mb-3">
                                                                    <Label htmlFor="useremail" className="form-label">Email <span className="text-danger">*</span></Label>
                                                                    <Input
                                                                        id="email"
                                                                        name="email"
                                                                        className="form-control"
                                                                        placeholder="Enter email address"
                                                                        type="email"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.email || ""}
                                                                        invalid={
                                                                            validation.touched.email && validation.errors.email ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.email && validation.errors.email ? (
                                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                                    ) : null}

                                                                </div>
                                                                <div className="mb-2">
                                                                    <Label htmlFor="phone" className="form-label">Phone <span className="text-danger">*</span></Label>
                                                                    <Input
                                                                        name="phone"
                                                                        type="number"
                                                                        placeholder="Phone"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.phone || ""}
                                                                        invalid={
                                                                            validation.touched.phone && validation.errors.phone ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.phone && validation.errors.phone ? (
                                                                        <FormFeedback type="invalid"><div>{validation.errors.phone}</div></FormFeedback>
                                                                    ) : null}

                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="username" className="form-label">Username <span className="text-danger">*</span></Label>
                                                                    <Input
                                                                        name="username"
                                                                        type="text"
                                                                        placeholder="Enter username"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.username || ""}
                                                                        invalid={
                                                                            validation.touched.username && validation.errors.username ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.username && validation.errors.username ? (
                                                                        <FormFeedback type="invalid"><div>{validation.errors.username}</div></FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="userpassword" className="form-label">Password <span className="text-danger">*</span></Label>
                                                                    <Input
                                                                        name="password"
                                                                        type="password"
                                                                        placeholder="Enter Password"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.password || ""}
                                                                        invalid={
                                                                            validation.touched.password && validation.errors.password ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.password && validation.errors.password ? (
                                                                        <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                                                    ) : null}

                                                                </div>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="retype_password" className="form-label">Retype Password <span className="text-danger">*</span></Label>
                                                                    <Input
                                                                        name="retype_password"
                                                                        type="password"
                                                                        placeholder="Enter Retype Password"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.retype_password && validation.errors.retype_password ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.retype_password && validation.errors.retype_password ? (
                                                                        <FormFeedback type="invalid"><div>{validation.errors.retype_password}</div></FormFeedback>
                                                                    ) : null}

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                        <button
                                                            type="button"
                                                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                                            onClick={() => {
                                                                toggleTab(activeTab + 1, 50);
                                                            }}
                                                        >
                                                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                            Next
                                                        </button>
                                                    </div>
                                                </TabPane>

                                                <TabPane tabId={2}>
                                                    {!success && !success ? (
                                                        <Alert color="danger"><div>
                                                            Please complete your data first before submit....
                                                        </div></Alert>
                                                    ) : null}
                                                    <div>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="clientname-field"
                                                                        className="form-label"
                                                                    >
                                                                        Nama Perusahaan     </Label>
                                                                    <Input
                                                                        name="client_name"
                                                                        id="clientclient_name-field"
                                                                        className="form-control"
                                                                        placeholder="Enter Name"
                                                                        type="text"
                                                                        validate={{ required: { value: true } }}
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.client_name || ""}
                                                                        invalid={
                                                                            validation.touched.client_name &&
                                                                                validation.errors.client_name
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    {validation.touched.client_name &&
                                                                        validation.errors.client_name ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.client_name}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </div>

                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label
                                                                        htmlFor="address-field"
                                                                        className="form-label"
                                                                    >
                                                                        ALamat Perusahaan
                                                                    </Label>
                                                                    <Input
                                                                        name="address"
                                                                        id="address-field"
                                                                        className="form-control"
                                                                        placeholder="Enter Street Name"
                                                                        type="textarea"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.address}
                                                                    />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="des-field" className="form-label">
                                                                        Description
                                                                    </Label>
                                                                    <Input
                                                                        name="description"
                                                                        id="des-field"
                                                                        className="form-control"
                                                                        placeholder="Enter Description"
                                                                        type="textarea"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.description || ""}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                        <button
                                                            type="button"
                                                            className="btn btn-link text-decoration-none btn-label previestab"
                                                            onClick={() => {
                                                                toggleTab(activeTab - 1, 0);
                                                            }}
                                                        >
                                                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                                            Back to General
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                                            onClick={() => {
                                                                if (success) {
                                                                    toggleTab(activeTab + 1, 100);
                                                                }
                                                            }}
                                                        >
                                                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </Form>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Signin </Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Register;
