import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_PARAMETER,
  DELETE_PARAMETER,
  UPDATE_PARAMETER,
  ADD_NEW_PARAMETER,
  ADD_NEW_PARAMETER_TABLE,
  UPDATE_PARAMETER_TABLE
} from "./actionType";

import {
  getParameterSuccess,
  getParameterFail,
  deleteParameterSuccess,
  deleteParameterFail,
  updateParameterSuccess,
  updateParameterFail,
  addParameterSuccess,
  addParameterFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveParameterApi,
  getParameterApi,
  updateParameterApi,
  deleteParameterApi,
  addNewParameterApi,
  addNewParameterTableApi,
  updateParameterTableApi,
} from "../../helpers/backend_helper";

function* getParameter({ payload: parameter }) {
  try {
    const response = yield call(getParameterApi, parameter);
    yield put(getParameterSuccess(GET_PARAMETER, response.data));
  } catch (error) {
    yield put(getParameterFail(GET_PARAMETER, error));
  }
}

function* onUpdateParameter({ payload: parameter }) {
  try {
    if (parameter.setApprove) {
      const response = yield call(approveParameterApi, parameter);
      yield put(updateParameterSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateParameterApi, parameter);
      yield put(updateParameterSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateParameterFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onUpdateParameterTable({ payload: parameter }) {
  try {
    const response = yield call(updateParameterTableApi, parameter);
    yield put(updateParameterSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateParameterFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteParameter({ payload: parameter }) {
  try {
    const response = yield call(deleteParameterApi, parameter);
    yield put(deleteParameterSuccess({ parameter, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteParameterFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewParameter({ payload: parameter }) {
  try {
    const response = yield call(addNewParameterApi, parameter);
    yield put(addParameterSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addParameterFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewParameterTable({ payload: parameter }) {
  try {
    const response = yield call(addNewParameterTableApi, parameter);
    yield put(addParameterSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addParameterFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetParameter() {
  yield takeEvery(GET_PARAMETER, getParameter);
}

export function* watchUpdateParameter() {
  yield takeEvery(UPDATE_PARAMETER, onUpdateParameter);
}

export function* watchDeleteParameter() {
  yield takeEvery(DELETE_PARAMETER, onDeleteParameter);
}

export function* watchAddNewParameter() {
  yield takeEvery(ADD_NEW_PARAMETER, onAddNewParameter);
}

export function* watchAddNewParameterTable() {
  yield takeEvery(ADD_NEW_PARAMETER_TABLE, onAddNewParameterTable);
}

export function* watchUpdateParameterTable() {
  yield takeEvery(UPDATE_PARAMETER_TABLE, onUpdateParameterTable);
}

function* parameterSaga() {
  yield all([
    fork(watchGetParameter),
    fork(watchDeleteParameter),
    fork(watchUpdateParameter),
    fork(watchAddNewParameter),
    fork(watchAddNewParameterTable),
    fork(watchUpdateParameterTable)
  ]);
}

export default parameterSaga;
