export const ADDRESS_LOADING = "ADDRESS_LOADING";
export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAIL = "GET_ADDRESS_FAIL";
export const DELETE_ADDRESS = "DELETE_ADDRESS"
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS"
export const DELETE_ADDRESS_FAIL = "DELETE_ADDRESS_FAIL"
export const UPDATE_ADDRESS = "UPDATE_ADDRESS"
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS"
export const UPDATE_ADDRESS_FAIL = "UPDATE_ADDRESS_FAIL"
export const ADD_NEW_ADDRESS = "ADD_NEW_ADDRESS"
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS"
export const ADD_ADDRESS_FAIL = "ADD_ADDRESS_FAIL"