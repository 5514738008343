import {
  GET_PARAMETER,
  GET_PARAMETER_SUCCESS,
  GET_PARAMETER_FAIL,
  DELETE_PARAMETER,
  DELETE_PARAMETER_SUCCESS,
  DELETE_PARAMETER_FAIL,
  UPDATE_PARAMETER,
  UPDATE_PARAMETER_SUCCESS,
  UPDATE_PARAMETER_FAIL,
  ADD_NEW_PARAMETER,
  ADD_PARAMETER_SUCCESS,
  ADD_PARAMETER_FAIL,
  PARAMETER_LOADING,
  PARAMETER_RESET,
  ADD_NEW_PARAMETER_TABLE,
  UPDATE_PARAMETER_TABLE
} from "./actionType";

export const resetParameterState = (actionType) => ({
  type: PARAMETER_RESET,
  payload: { actionType },
});

export const getParameterSuccess = (actionType, data) => ({
  type: GET_PARAMETER_SUCCESS,
  payload: { actionType, data },
});

export const getParameterFail = (actionType, error) => ({
  type: GET_PARAMETER_FAIL,
  payload: { actionType, error },
});

export const getParameter = (data) => ({
  type: GET_PARAMETER,
  payload: data,
});

export const getParameterLoading = () => ({
  type: PARAMETER_LOADING,
});

export const deleteParameter = (data) => ({
  type: DELETE_PARAMETER,
  payload: data,
});

export const deleteParameterSuccess = (data) => ({
  type: DELETE_PARAMETER_SUCCESS,
  payload: data,
});

export const deleteParameterFail = (error) => ({
  type: DELETE_PARAMETER_FAIL,
  payload: error,
});

export const updateParameter = (data) => ({
  type: UPDATE_PARAMETER,
  payload: data,
});

export const updateParameterFail = (error) => ({
  type: UPDATE_PARAMETER_FAIL,
  payload: error,
});

export const updateParameterSuccess = (data) => ({
  type: UPDATE_PARAMETER_SUCCESS,
  payload: data,
});

export const addNewParameter = (data) => ({
  type: ADD_NEW_PARAMETER,
  payload: data,
});

export const addParameterSuccess = (data) => ({
  type: ADD_PARAMETER_SUCCESS,
  payload: data,
});

export const addParameterFail = (error) => ({
  type: ADD_PARAMETER_FAIL,
  payload: error,
});

export const addNewParameterTable = (data) => ({
  type: ADD_NEW_PARAMETER_TABLE,
  payload: data,
});

export const updateParameterTable = (data) => ({
  type: UPDATE_PARAMETER_TABLE,
  payload: data,
});
