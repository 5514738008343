export const STORAGEMOVE_LOADING = "STORAGEMOVE_LOADING";
export const STORAGEMOVE_RESET = "STORAGEMOVE_RESET";
export const GET_STORAGEMOVE = "GET_STORAGEMOVE";
export const GET_STORAGEMOVE_SUCCESS = "GET_STORAGEMOVE_SUCCESS";
export const GET_STORAGEMOVE_FAIL = "GET_STORAGEMOVE_FAIL";
export const DELETE_STORAGEMOVE = "DELETE_STORAGEMOVE";
export const DELETE_STORAGEMOVE_SUCCESS = "DELETE_STORAGEMOVE_SUCCESS";
export const DELETE_STORAGEMOVE_FAIL = "DELETE_STORAGEMOVE_FAIL";
export const UPDATE_STORAGEMOVE = "UPDATE_STORAGEMOVE";
export const UPDATE_STORAGEMOVE_SUCCESS = "UPDATE_STORAGEMOVE_SUCCESS";
export const UPDATE_STORAGEMOVE_FAIL = "UPDATE_STORAGEMOVE_FAIL";
export const ADD_NEW_STORAGEMOVE = "ADD_NEW_STORAGEMOVE";
export const ADD_STORAGEMOVE_SUCCESS = "ADD_STORAGEMOVE_SUCCESS";
export const ADD_STORAGEMOVE_FAIL = "ADD_STORAGEMOVE_FAIL";
export const GET_STORAGEMOVE_ONE = "GET_STORAGEMOVE_ONE";
