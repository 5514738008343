import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_REPORTTEMPLATE,
  DELETE_REPORTTEMPLATE,
  UPDATE_REPORTTEMPLATE,
  ADD_NEW_REPORTTEMPLATE,
} from "./actionType";

import {
  getReportTemplateSuccess,
  getReportTemplateFail,
  deleteReportTemplateSuccess,
  deleteReportTemplateFail,
  updateReportTemplateSuccess,
  updateReportTemplateFail,
  addReportTemplateSuccess,
  addReportTemplateFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveReportTemplateApi,
  getReportTemplateApi,
  updateReportTemplateApi,
  deleteReportTemplateApi,
  addNewReportTemplateApi,
} from "../../helpers/backend_helper";

function* getReportTemplate({ payload: reportTemplate }) {
  try {
    const response = yield call(getReportTemplateApi, reportTemplate);
    yield put(getReportTemplateSuccess(GET_REPORTTEMPLATE, response.data));
  } catch (error) {
    yield put(getReportTemplateFail(GET_REPORTTEMPLATE, error));
  }
}

function* onUpdateReportTemplate({ payload: reportTemplate }) {
  try {
    if (reportTemplate.setApprove) {
      const response = yield call(approveReportTemplateApi, reportTemplate);
      yield put(updateReportTemplateSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateReportTemplateApi, reportTemplate);
      yield put(updateReportTemplateSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateReportTemplateFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteReportTemplate({ payload: reportTemplate }) {
  try {
    const response = yield call(deleteReportTemplateApi, reportTemplate);
    yield put(deleteReportTemplateSuccess({ reportTemplate, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteReportTemplateFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewReportTemplate({ payload: reportTemplate }) {
  try {
    const response = yield call(addNewReportTemplateApi, reportTemplate);
    yield put(addReportTemplateSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addReportTemplateFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetReportTemplate() {
  yield takeEvery(GET_REPORTTEMPLATE, getReportTemplate);
}

export function* watchUpdateReportTemplate() {
  yield takeEvery(UPDATE_REPORTTEMPLATE, onUpdateReportTemplate);
}

export function* watchDeleteReportTemplate() {
  yield takeEvery(DELETE_REPORTTEMPLATE, onDeleteReportTemplate);
}

export function* watchAddNewReportTemplate() {
  yield takeEvery(ADD_NEW_REPORTTEMPLATE, onAddNewReportTemplate);
}

function* reportTemplateSaga() {
  yield all([
    fork(watchGetReportTemplate),
    fork(watchDeleteReportTemplate),
    fork(watchUpdateReportTemplate),
    fork(watchAddNewReportTemplate),
  ]);
}

export default reportTemplateSaga;
