export const GEOFENCE_LOADING = "GEOFENCE_LOADING";
export const GEOFENCE_RESET = "GEOFENCE_RESET";
export const GET_GEOFENCE = "GET_GEOFENCE";
export const GET_GEOFENCE_SUCCESS = "GET_GEOFENCE_SUCCESS";
export const GET_GEOFENCE_FAIL = "GET_GEOFENCE_FAIL";
export const DELETE_GEOFENCE = "DELETE_GEOFENCE";
export const DELETE_GEOFENCE_SUCCESS = "DELETE_GEOFENCE_SUCCESS";
export const DELETE_GEOFENCE_FAIL = "DELETE_GEOFENCE_FAIL";
export const UPDATE_GEOFENCE = "UPDATE_GEOFENCE";
export const UPDATE_GEOFENCE_SUCCESS = "UPDATE_GEOFENCE_SUCCESS";
export const UPDATE_GEOFENCE_FAIL = "UPDATE_GEOFENCE_FAIL";
export const ADD_NEW_GEOFENCE = "ADD_NEW_GEOFENCE";
export const ADD_GEOFENCE_SUCCESS = "ADD_GEOFENCE_SUCCESS";
export const ADD_GEOFENCE_FAIL = "ADD_GEOFENCE_FAIL";
export const GET_GEOFENCE_ONE = "GET_GEOFENCE_ONE";
