import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_UOM,
  DELETE_UOM,
  UPDATE_UOM,
  ADD_NEW_UOM,
} from "./actionType";

import {
  getUomSuccess,
  getUomFail,
  deleteUomSuccess,
  deleteUomFail,
  updateUomSuccess,
  updateUomFail,
  addUomSuccess,
  addUomFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getUomApi,
  updateUomApi,
  deleteUomApi,
  addNewUomApi
} from "../../helpers/backend_helper";

function* getUom({ payload: uom }) {
  try {
    const response = yield call(getUomApi, uom);
    yield put(getUomSuccess(GET_UOM, response.data));
  } catch (error) {
    yield put(getUomFail(GET_UOM, error));
  }
}

function* onUpdateUom({ payload: uom }) {
  try {
    const response = yield call(updateUomApi, uom);
    yield put(updateUomSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateUomFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteUom({ payload: uom }) {
  try {
    const response = yield call(deleteUomApi, uom);
    yield put(deleteUomSuccess({ uom, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteUomFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewUom({ payload: uom }) {
  try {
    const response = yield call(addNewUomApi, uom);
    yield put(addUomSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addUomFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetUom() {
  yield takeEvery(GET_UOM, getUom);
}

export function* watchUpdateUom() {
  yield takeEvery(UPDATE_UOM, onUpdateUom);
}

export function* watchDeleteUom() {
  yield takeEvery(DELETE_UOM, onDeleteUom);
}

export function* watchAddNewUom() {
  yield takeEvery(ADD_NEW_UOM, onAddNewUom);
}

function* uomSaga() {
  yield all([
    fork(watchGetUom),
    fork(watchDeleteUom),
    fork(watchUpdateUom),
    fork(watchAddNewUom)
  ]);
}

export default uomSaga;
