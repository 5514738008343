import {
  BRANDTYPE_LOADING,
  GET_BRANDTYPE,
  GET_BRANDTYPE_SUCCESS,
  GET_BRANDTYPE_FAIL,
  ADD_BRANDTYPE_SUCCESS,
  ADD_BRANDTYPE_FAIL,
  DELETE_BRANDTYPE_SUCCESS,
  DELETE_BRANDTYPE_FAIL,
  UPDATE_BRANDTYPE_SUCCESS,
  UPDATE_BRANDTYPE_FAIL,
  BRANDTYPE_RESET,
} from "./actionType";

const INIT_STATE = {
  BRANDTYPEs: [],
  error: {},
};

const BRANDTYPE = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANDTYPE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BRANDTYPE:
          return {
            ...state,
            BRANDTYPEs: action.payload.data,
            isBRANDTYPECreated: false,
            isBRANDTYPESuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_BRANDTYPE_FAIL:
      switch (action.payload.actionType) {
        case GET_BRANDTYPE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isBRANDTYPECreated: false,
            isBRANDTYPESuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_BRANDTYPE: {
      return {
        ...state,
        isBRANDTYPECreated: false,
        loading: true,
      };
    }

    case BRANDTYPE_LOADING: {
      return {
        ...state,
        isBRANDTYPECreated: false,
        loading: true,
      };
    }

    case ADD_BRANDTYPE_SUCCESS:
      return {
        ...state,
        isBRANDTYPECreated: true,
        loading: false,
        BRANDTYPEs: [...state.BRANDTYPEs, action.payload.data],
      };

    case ADD_BRANDTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_BRANDTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        BRANDTYPEs: state.BRANDTYPEs.filter(
          (BRANDTYPE) => BRANDTYPE.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_BRANDTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_BRANDTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        BRANDTYPEs: state.BRANDTYPEs.map((BRANDTYPE) =>
          BRANDTYPE.id.toString() === action.payload.data.id.toString()
            ? { ...BRANDTYPE, ...action.payload.data }
            : BRANDTYPE
        ),
      };

    case UPDATE_BRANDTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BRANDTYPE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default BRANDTYPE;
