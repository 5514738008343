export const UOM_LOADING = "UOM_LOADING";
export const GET_UOM = "GET_UOM";
export const GET_UOM_SUCCESS = "GET_UOM_SUCCESS";
export const GET_UOM_FAIL = "GET_UOM_FAIL";
export const DELETE_UOM = "DELETE_UOM"
export const DELETE_UOM_SUCCESS = "DELETE_UOM_SUCCESS"
export const DELETE_UOM_FAIL = "DELETE_UOM_FAIL"
export const UPDATE_UOM = "UPDATE_UOM"
export const UPDATE_UOM_SUCCESS = "UPDATE_UOM_SUCCESS"
export const UPDATE_UOM_FAIL = "UPDATE_UOM_FAIL"
export const ADD_NEW_UOM = "ADD_NEW_UOM"
export const ADD_UOM_SUCCESS = "ADD_UOM_SUCCESS"
export const ADD_UOM_FAIL = "ADD_UOM_FAIL"