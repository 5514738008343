import React, {
    Fragment,
    useEffect,
    useState,
    useMemo,
    useCallback,
    useRef,
} from "react";
import {
    Table,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    Modal,
    ModalHeader,
    Form,
    ModalBody,
    Label,
    Input,
    Spinner,
    TabContent,
    TabPane,
    FormFeedback
} from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import { isEmpty, clone } from "lodash";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect,
} from "react-table";

import { ToastContainer } from "react-toastify";
import Flatpickr from "react-flatpickr";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import { useSelector, useDispatch } from "react-redux";
import {
    getDevice as onGetDevices,
    addNewDevice as onAddNewDevice,
    updateDevice as onUpdateDevice,
    deleteDevice as onDeleteDevice,
    getDeviceLoading,
    resetDeviceState,
} from "../../store/device/action";

import { getClient, resetClientState } from "../../store/client/action";
import { getCompany, resetCompanyState } from "../../store/company/action";
import { getBranch, resetBranchState } from "../../store/branch/action";
import {
    getDeviceType,
    resetDeviceTypeState,
} from "../../store/deviceType/action";
import { getUnit, resetUnitState } from "../../store/unit/action";
import { getService, resetServiceState } from "../../store/service/action";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ModalInputFile from "../../Components/ModalInputFile";
import defaultImg from "../../assets/images/upload.png";
import { api } from "../../config";
import { getHistory, resetHistoryState } from "../../store/actions";

const TemplateSmartWeight = (props) => {
    const { t } = props;
    const [modal, setModal] = useState(false);
    const [roles, setRoles] = useState();
    const [userType, setUserType] = useState();
    const [activeTab, setActiveTab] = useState("1");
    const dispatch = useDispatch();
    const [fileNameItem, setFileNameItem] = useState([]);
    const [file, setFile] = useState([]);
    const [isClick, setIsClick] = useState(false);
    const [clientName, setClientName] = useState();
    const [userRole, setUserRole] = useState();
    const [modalMedia, setModalMedia] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [companyId, setCompanyId] = useState();
    const [clientId, setClientId] = useState();
    const [pIndex, setPIndex] = useState(0);
    const [branchSelect, setBranchSelect] = useState()
    const [unitSelect, setUnitSelect] = useState()
    const [deviceSelect, setDeviceSelect] = useState()
    const [list, setList] = useState([])

    const [getMonthSelect, setMonthSelect] = useState([
        { label: "Januari", value: 1 },
        { label: "Februari", value: 2 },
        { label: "Maret", value: 3 },
        { label: "April", value: 4 },
        { label: "Mei", value: 5 },
        { label: "Juni", value: 6 },
        { label: "Juli", value: 7 },
        { label: "Agustus", value: 8 },
        { label: "September", value: 9 },
        { label: "Oktober", value: 10 },
        { label: "November", value: 11 },
        { label: "Desember", value: 12 },
    ]);

    const [getYearSelect, setYearSelect] = useState([
        { label: "2023", value: 2023 },
        { label: "2024", value: 2024 },
        { label: "2025", value: 2025 },
        { label: "2026", value: 2026 },
        { label: "2027", value: 2027 },
        { label: "2028", value: 2028 },
    ]);

    const getMonthName = useCallback((param) => {
        switch (param) {
            case 2:
                return "Febuari";
            case 3:
                return "Maret";
            case 4:
                return "April";
            case 5:
                return "Mei";
            case 6:
                return "Juni";
            case 7:
                return "Juli";
            case 8:
                return "Agustus";
            case 9:
                return "September";
            case 10:
                return "Oktober";
            case 11:
                return "November";
            case 12:
                return "FebuarDesember";
            default:
                return "Januari";
        }
    }, []);

    const [filterMonth, setFilterMonth] = useState({
        label: getMonthName(parseInt(new Date().getMonth() + 1)),
        value: parseInt(new Date().getMonth() + 1),
    });
    const [filterYear, setFilterYear] = useState({
        label: "" + parseInt(new Date().getFullYear()),
        value: parseInt(new Date().getFullYear()),
    });

    const dTime = new Date();
    const defFirstDate = useRef(new Date(dTime.getFullYear(), dTime.getMonth(), 1, 0, 0, 0, 0));
    const defLastDate = useRef(new Date(dTime.getFullYear(), dTime.getMonth() + 1, 0, 0, 0, 0, 0));

    const {
        devices,
        isDeviceCreated,
        isDeviceSuccess,
        loading,
        error,
        isMediaDeleted,
        media,
        mediaLoading,
        clients,
        deviceTypes,
        units,
        services,
        companies,
        branches,
        histories,
        companiesLoading,
        branchesLoading,
        unitLoading,
        serviceLoading,
    } = useSelector((state) => ({
        devices: state.Device.devices,
        clients: state.Client.clients,
        deviceTypes: state.DeviceType.deviceTypes,
        units: state.Unit.units,
        services: state.Service.services,
        isDeviceCreated: state.Device.isDeviceCreated,
        isDeviceSuccess: state.Device.isDeviceSuccess,
        loading: state.Device.loading,
        error: state.Device.error,
        companies: state.Company.companies,
        branches: state.Branch.branches,
        companiesLoading: state.Company.loading,
        branchesLoading: state.Branch.loading,
        unitLoading: state.Unit.loading,
        serviceLoading: state.Service.loading,
        histories: state.History.histories
    }));

    const [deviceList, setDeviceList] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [device, setDevice] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [deviceTypeList, setDeviceTypeList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [parentList, setParentList] = useState([]);
    const [isExportCSV, setIsExportCSV] = useState(false);
    const [statusActive, setStatusActive] = useState([]);
    const [filterDate, setFilterDate] = useState();
    const [isSearch, setIsSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [connection, setConnection] = useState("tcp");
    const [mediaType, setMediaType] = useState("");
    const [logo, setLogo] = useState(defaultImg);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [resCheck, setResCheck] = useState(false);
    const [activeTabModal, setActiveTabModal] = useState("1")
    const [isHis, setIsHis] = useState(false)
    const [dataHistory, setDataHistory] = useState([])


    const [isExecutive, setIsExecutive] = useState(false);
    const statusActiveData = [
        {
            options: [
                { label: "Draft", value: 1 },
                { label: "Active", value: 2 },
                { label: "Deactivated", value: 3 },
            ],
        },
    ];

    const connectionTypeData = [
        {
            options: [
                { label: "Default", value: "default" },
                { label: "Http Api", value: "api" },
            ],
        },
    ];

    const types = [
        {
            options: [
                { label: "TCP", value: "tcp" },
                { label: "MQTT", value: "mqtt" },
            ],
        },
    ];

    const resCheckHandler = () => {
        setResCheck(!resCheck);
    };

    const handleSelectMonth = (param) => {
        if (param && param.value) {
            setFilterMonth(param);
        } else {
            setFilterMonth(filterMonth);
        }
    };

    const handleSelectYear = (param) => {
        if (param && param.value) {
            setFilterYear(param);
        } else {
            setFilterYear(filterYear);
        }
    };

    useEffect(() => {
        if (!isEmpty(histories)) {
            setDataHistory(histories)
        }
    }, [histories])

    useEffect(() => {
        const obj = JSON.parse(sessionStorage.getItem("authUser"));
        if (!isSearch) {
            setDataHistory([])
            setIsHis(true)
            const def = getFirstAndLastDateOfMonth();
            dispatch(resetBranchState());
            dispatch(getBranch());
            dispatch(resetDeviceState())
            dispatch(onGetDevices())
            dispatch(resetHistoryState())
            dispatch(getHistory({ type: "transaction", start: defFirstDate.current.getTime(), end: defLastDate.current.getTime(), companyId: obj.data.companyId }))
        }
        setIsSearch(true);
        if (obj) {
            if (
                obj.data &&
                obj.data.employee_position &&
                obj.data.employee_position.position_role
            )
                setRoles(obj.data.employee_position.position_role.role_method);
            else setRoles(null);

            if (obj.data && obj.data.userType) setUserType(obj.data.userType);
            if (obj.data && obj.data.userRole) setUserRole(obj.data.userRole);

            if (obj.data.user_client && obj.data.user_client.id) {
                setClientName(obj.data.user_client.id);
            }

            if (obj.data.userType === "company") {
                setCompanyId(obj.data.companyId);
                setClientId(obj.data.clientId);
            } else if (obj.data.userType === "client") {
                setClientId(obj.data.clientId);
            }
        } else {
            window.location = "/logout";
        }
    }, [defFirstDate, defLastDate, dispatch, isSearch]);

    useEffect(() => {
        if ((dataHistory && dataHistory.length > 0) && isHis) {
            console.log(dataHistory, 'his');
            let dataHis = []
            dataHistory.forEach((his) => {
                if (his.sensor_data) {
                    let date = new Date(his.timestamp);

                    let options = { timeZone: 'Asia/Jakarta', year: "numeric", month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };

                    let formatter = new Intl.DateTimeFormat('id-ID', options);
                    let formattedDate = formatter.format(date);

                    console.log(formattedDate);
                    his.sensor_data.time = formattedDate
                    his.sensor_data.uid = his.uid
                    dataHis.push(his.sensor_data)
                }
            })
            if (dataHis.length > 0) {
                deviceList.forEach((dev) => {
                    dataHis.forEach((dat) => {
                        if (dev.identifier === dat.uid) {
                            dat.device = dev.name
                            dat.unit = dev.device_device_unit[0].device_unit_unit.name
                        } else {
                            dat.device = "-"
                            dat.unit = "-"
                        }
                    })
                })
            }
            console.log(dataHis);
            setList(dataHis)
            setIsHis(false)
        }
    }, [dataHistory, deviceList, isHis])

    const onClickDelete = (device) => {
        setDevice(device);
        setDeleteModal(true);
    };

    const handleDeleteDevice = () => {
        if (device) {
            dispatch(onDeleteDevice(device));
            setDeleteModal(false);
        }
    };

    useEffect(() => {
        if (!isEmpty(clients)) {
            var data = [];
            var opt = [];
            clients.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setClientList(data);
        }
    }, [clients]);

    useEffect(() => {
        if (!isEmpty(companies)) {
            var data = [];
            var opt = [];
            companies.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setCompanyList(data);
        }
    }, [companies]);

    useEffect(() => {
        if (!isEmpty(branches)) {
            var data = [];
            var opt = [];
            branches.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setBranchList(data);
        }
    }, [branches]);

    useEffect(() => {
        if (!isEmpty(units)) {
            var data = [];
            var opt = [];
            units.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id, unitId: id };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setUnitList(data);
        }
    }, [units]);

    useEffect(() => {
        if (!isEmpty(devices)) {
            setDeviceList(devices);
        }
    }, [devices]);

    useEffect(() => {
        if (!isEmpty(devices)) {
            let arr = [];
            devices.forEach((element) => {
                arr.push({ value: element.id, label: element.name, identifier: element.identifier });
            });
            setParentList(arr);
        }
    }, [devices]);

    useEffect(() => {
        dispatch(resetClientState());
        dispatch(getClient()); //{ status: 2 }
    }, [dispatch]);

    useEffect(() => {
        dispatch(resetServiceState());
        dispatch(getService());
    }, [dispatch]);

    useEffect(() => {
        dispatch(resetDeviceTypeState());
        dispatch(getDeviceType());
    }, [dispatch]);

    const handleValidDate = (date) => {
        return moment(date, "YYYY/MM/DD").tz("Asia/Jakarta").format("MMM D, YYYY HH:mm");
    };

    const toggleTab = (tab, type) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            let filteredDevices = devices;
            if (type !== "all") {
                filteredDevices = devices.filter(
                    (device) => device.device_status.name === type
                );
            }
            setDeviceList(filteredDevices);
        }
    };

    const toggleTabModal = (tab) => {
        if (activeTabModal !== tab) {
            setActiveTabModal(tab);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: `${t("Waktu")}`,
                accessor: "time",
                filterable: true,
            },
            {
                Header: `${t("Lokasi")}`,
                accessor: "unit",
                filterable: true,
            },
            {
                Header: `${t("Alat")}`,
                accessor: "device",
                filterable: true,
            },
            {
                Header: `${t("Berat")}`,
                accessor: "weight",
                filterable: true,
            },
            {
                Header: `${t("Sumber Limbah")}`,
                accessor: "waste_source",
                filterable: true,
            },
            {
                Header: `${t("Jenis Limbah")}`,
                accessor: "waste_type",
                filterable: true,
            },
            {
                Header: `${t("Sub Jenis Limbah")}`,
                accessor: "waste_sub_type",
                filterable: true,
            },
            {
                Header: `${t("Kategori Limbah")}`,
                accessor: "waste_category",
                filterable: true,
            },
            {
                Header: `${t("Max. Penyimpanan")}`,
                accessor: "waste_storage",
                filterable: true,
            },
        ],
        [t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns: columns,
            data: list,
            defaultColumn: { Filter: null },
            initialState: {
                pageIndex: pIndex,
                pageSize: 10,
                selectedRowIds: 0,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
    };

    const generateFilterable = (column) => {
        return column.filterable
            ? column.isSorted
                ? column.isSortedDesc
                    ? " sorting_desc"
                    : " sorting_asc"
                : " sort"
            : "";
    };

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value));
    };

    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    const count = preGlobalFilteredRows.length;
    const [searchValue, setSearchValue] = useState("");
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    const getFirstAndLastDateOfMonth = (month, year) => {
        if (month < 1 || month > 12) {
            throw new Error('Bulan harus dalam rentang antara 1 hingga 12');
        }
        if (!year) {
            year = new Date().getFullYear();
        }
        const firstDate = new Date(year, month - 1, 1);
        const lastDate = new Date(year, month, 0);

        const startDate = new Date(firstDate);
        const endDate = new Date(lastDate);

        return {
            startDate: startDate,
            endDate: endDate
        };
    }

    function handleFilter() {
        var result = getFirstAndLastDateOfMonth(filterMonth.value, filterYear.value);
        let start = result.startDate.getTime();
        let end = result.endDate.getTime();
        let device = []

        if (deviceSelect) {
            device.push(deviceSelect.identifier)
            console.log("Case device select")
        } else if (units.length > 0) {
            units.forEach((unit) => {
                unit.unit_device.forEach((dev) => {
                    device.push(dev.identifier)
                })
            })
            console.log("Case unit length")
        } else if (devices.length > 0) {
            devices.forEach((dev) => {
                device.push(dev.identifier)
            })
            console.log("Case device length")
        }

        const param = {
            type: "transaction",
            start: start,
            end: end,
            uid: JSON.stringify(device)
        };
        console.log(param);
        setIsHis(true)
        setDataHistory([])
        dispatch(getHistory(param));
        dispatch(resetUnitState())
        dispatch(resetDeviceState())
        setDeviceSelect()
    }


    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".dataCheckbox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    // Delete Multiple
    const deleteMultiple = () => {
        const ele = document.querySelectorAll(".dataCheckbox:checked");
        const checkall = document.getElementById("checkBoxAll");
        ele.forEach((element) => {
            dispatch(onDeleteDevice(element.value));
        });
        checkall.checked = false;
    };

    const submitFile = () => {
        if (!isClick) {
            for (const a of fileNameItem) {
                if (!a.isPrimary) {
                    const data = fileNameItem.slice(-1);
                    data[0].isPrimary = true;
                }
            }
        }
    };

    const handleDeleteFiles = useCallback(
        (files) => {
            // const object = file.findIndex((obj) => obj === files);
            if (file) {
                file.splice(file.indexOf(files), 1);
                setFile(file);
                forceUpdate();
            }

            // if (object > -1) {
            //   file.splice(object, 1);
            // }
        },
        [file, forceUpdate]
    );

    useEffect(() => {
        setPIndex(pageIndex);
    }, [pageIndex]);

    const handleUnit = (data) => {
        dispatch(resetUnitState());
        dispatch(getUnit({ branchId: data.value }));
    };

    const handleDevice = (data) => {
        dispatch(resetDeviceState());
        dispatch(onGetDevices({ unitId: data.value }));
    };

    document.title = `${t("Dashboard")} | TELKOMSEL - IOT Monitoring`;

    return (
        <div className='page-content'>
            <ExportCSVModal
                show={isExportCSV}
                onCloseClick={() => setIsExportCSV(false)}
                data={deviceList}
            />
            <Container fluid>
                <BreadCrumb title={t("Dashboard")} pageTitle={t("Dashboard")} />
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardBody className='pt-0'>
                                <div>
                                    <Row className='mb-3'>
                                        <CardBody className='border border-top-0 border-end-0 border-start-0'>
                                            <form>
                                                <Row>
                                                    <Col sm={1} className='col-xxl-1'>
                                                        <div className='me-2 mb-2 col-12'>
                                                            <select
                                                                className='form-select'
                                                                value={pageSize}
                                                                onChange={onChangeInSelect}>
                                                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                                                    <option key={pageSize} value={pageSize}>
                                                                        {pageSize}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <div className='mb-3'>
                                                            <Select
                                                                name='branch'
                                                                value={branchSelect || ""}
                                                                placeholder={`${t("Select")} ${t(
                                                                    "Branch"
                                                                )}`}
                                                                onChange={(e) => {
                                                                    setBranchSelect(e)
                                                                    handleUnit(e)
                                                                }}
                                                                options={branchList}></Select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <div className='mb-3'>
                                                            <Select
                                                                name='unit'
                                                                value={unitSelect || ""}
                                                                placeholder={`${t("Select")} ${t(
                                                                    "Unit"
                                                                )}`}
                                                                onChange={(e) => {
                                                                    setUnitSelect(e)
                                                                    handleDevice(e)
                                                                }}
                                                                options={unitList}></Select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <div className='mb-3'>
                                                            <Select
                                                                name='device'
                                                                value={deviceSelect || ""}
                                                                placeholder={`${t("Select")} ${t(
                                                                    "Device"
                                                                )}`}
                                                                onChange={(e) => {
                                                                    setDeviceSelect(e)
                                                                }}
                                                                options={parentList}></Select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <div className='search-box me-2 mb-2 col-12'>
                                                            <Select
                                                                placeholder={`--- Pilih Bulan ---`}
                                                                value={filterMonth}
                                                                // searchable
                                                                onChange={(e) => {
                                                                    handleSelectMonth(e);
                                                                    return false;
                                                                }}
                                                                options={getMonthSelect}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <div className='search-box me-2 mb-2 col-12'>
                                                            <Select
                                                                placeholder={`--- Pilih Tahun ---`}
                                                                value={filterYear}
                                                                // searchable
                                                                onChange={(e) => {
                                                                    handleSelectYear(e);
                                                                    return false;
                                                                }}
                                                                options={getYearSelect}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col sm={1}>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-light w-100'
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleFilter();
                                                                }}>
                                                                <i className='ri-search-line me-1 align-bottom fs-14'></i>{" "}
                                                                {t("Search")}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </CardBody>
                                    </Row>
                                    <div className='table-responsive table-card mb-1'>
                                        <Table
                                            hover
                                            {...getTableProps()}
                                            className='align-middle table-nowrap'>
                                            <thead className='table-light text-muted'>
                                                {headerGroups.map((headerGroup) => (
                                                    <tr
                                                        className={""}
                                                        key={headerGroup.id}
                                                        {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map((column) => (
                                                            <th
                                                                key={column.id}
                                                                className={"" + generateFilterable(column)}
                                                                {...column.getSortByToggleProps()}>
                                                                {column.render("Header")}
                                                                {generateSortingIndicator(column)}
                                                                {/* <Filter column={column} /> */}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {page.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <Fragment key={row.getRowProps().key}>
                                                            <tr>
                                                                {row.cells.map((cell) => {
                                                                    return (
                                                                        <td key={cell.id} {...cell.getCellProps()}>
                                                                            {cell.render("Cell")}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>

                                    <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                                        <Col className='col-md-auto'>
                                            <div className='d-flex gap-1'>
                                                <Button
                                                    className='btn btn-light'
                                                    onClick={previousPage}
                                                    disabled={!canPreviousPage}>
                                                    {"<"}
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className='col-md-auto d-none d-md-block'>
                                            {`${t("Page")} `}
                                            <strong>
                                                {pageIndex + 1} {t("of")} {pageOptions.length}
                                            </strong>
                                        </Col>
                                        <Col className='col-md-auto'>
                                            <Input
                                                type='number'
                                                min={1}
                                                style={{ width: 70 }}
                                                max={pageOptions.length}
                                                value={pIndex + 1}
                                                onChange={onChangeInInput}
                                            />
                                        </Col>

                                        <Col className='col-md-auto'>
                                            <div className='d-flex gap-1'>
                                                <Button
                                                    className='btn btn-light'
                                                    onClick={nextPage}
                                                    disabled={!canNextPage}>
                                                    {">"}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <ToastContainer closeButton={false} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

TemplateSmartWeight.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(TemplateSmartWeight));
