import React, { useCallback, useEffect, useRef, useState } from "react";
import PoiMarkers from "../../Components/Common/PoiMarkers";
import { APIProvider, useApiIsLoaded, Map, useMap, useMapsLibrary, AdvancedMarker, APILoadingStatus, useApiLoadingStatus } from "@vis.gl/react-google-maps";
import { Spinner } from "reactstrap";
import mapGps from "../../assets/images/map-gps.png";
const delay = (milliseconds) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};

// const lineCoordinates = [
//   { lat: 37.772, lng: -122.214 },
//   { lat: 21.291, lng: -157.821 },
//   { lat: -18.142, lng: 178.431 },
//   { lat: -27.467, lng: 153.027 },
// ];

// const lineCoordinates2 = [
//   { lat: 37.67, lng: -122.2323 },
//   { lat: 21.456, lng: -157.1212 },
//   { lat: -18.45, lng: 178.76 },
//   { lat: -27.886, lng: 153.96 },
// ];

const Polygon = ({ data, deviceData }) => {
  const map = useMap();
  const maps = useMapsLibrary("maps");
  const line = useRef(null);

  useEffect(() => {
    if (!map && !maps) return;
    if (!line.current) {
      let mapL = {};
      if (data.length > 0) {
        data.forEach((m) => {
          mapL[m.id] = new maps.Polyline({
            map: map,
            geodesic: true,
            strokeColor: "#3498db",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
        });
        // console.log(mapL);
        line.current = mapL;
      }
    }
  }, [map, maps, data]);

  // useEffect(() => {
  //   return () => {
  //     if (line.current !== null) {
  //       for (let [_, value] of line.current) {
  //         value.setMap(null);
  //       }
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (line.current !== null) {
      // console.log("jln");
      // data.forEach((m) => {
      //   if (line.current[m.id] !== undefined) {
      //     console.log(m.isChecked);
      //     console.log(m.id);
      //     if (m.isChecked === true) {
      //       line.current[m.id].setPath(m.routes);
      //     }else{
      //       line.current[m.id].setPath([]);
      //     }
      //   }
      // });

      for (let key in line.current) {
        const idx = data.findIndex((d) => parseInt(d.id) === parseInt(key));
        // console.log( idx, "ooo");
        if (idx !== -1) {
          line.current[key].setPath(data[idx].routes);
        } else {
          line.current[key].setPath([]);
        }
      }

      // if (deviceData) {
      //   console.log("kkk");
      //   if (deviceData.isChecked === true) {
      //     line.current[deviceData.id].setPath(deviceData.routes);
      //   } else {
      //     if (deviceData.routes.length > 0) {
      //       line.current[deviceData.id].setPath([]);
      //     }
      //   }
      // }
    }
  }, [data, deviceData]);

  useEffect(() => {
    return () => {
      if (line.current) {
        for (let key in line.current) {
          line.current[key].setPath([]);
          line.current[key].setMap(null);
        }
      }
    };
  }, []);

  // const addLine = () => {
  //   line.current.setPath(lineCoordinates);
  // };

  return (
    <div className="position-absolute top-0">
      {/* <input id="remove-line" onClick={removeLine} type="button" value="Remove line" /> */}
      {/* <input id="add-line" onClick={addLine} type="button" value="Restore line" /> */}
    </div>
  );
};

const PolygonRoute = ({ data }) => {
  const map = useMap();
  const maps = useMapsLibrary("maps");
  const line = useRef(null);

  useEffect(() => {
    if (!map && !maps) return;
    if (!line.current) {
      line.current = new maps.Polyline({
        map: map,
        geodesic: true,
        strokeColor: "#3498db",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
    }
  }, [map, maps]);

  // useEffect(() => {
  //   return () => {
  //     if (line.current !== null) {
  //       for (let [_, value] of line.current) {
  //         value.setMap(null);
  //       }
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (line.current !== null) {
      let lineCoordinates = [];
      for (const d of data) {
        lineCoordinates.push({ lat: d.lat, lng: d.lng });
      }

      line.current.setPath(lineCoordinates);
    }
    // line.current.setPath(lineCoordinates)
  }, [data]);

  useEffect(() => {
    return () => {
      if (line.current) {
        line.current.setPath([]);
        line.current.setMap(null);
      }
    };
  }, []);

  // const addLine = () => {
  //   line.current.setPath(lineCoordinates);
  // };

  return (
    <div className="position-absolute top-0">
      {/* <input id="remove-line" onClick={removeLine} type="button" value="Remove line" /> */}
      {/* <input id="add-line" onClick={addLine} type="button" value="Restore line" /> */}
    </div>
  );
};

const MarkerRoute = ({ data, statusRoute }) => {
  const [markerLocation, setMarkerLocation] = useState(null);
  const status = useRef(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const map = useMap();

  useEffect(() => {
    if (data) {
      setMarkerLocation({ lat: data[0].lat, lng: data[0].lng });
      map.setCenter({ lat: data[0].lat, lng: data[0].lng });
      map.panTo({ lat: data[0].lat, lng: data[0].lng });
      map.setZoom(18);
    }
  }, [map, data]);

  const handleRoute = useCallback(async () => {
    if (statusRoute) {
      switch (statusRoute) {
        case "play":
          status.current = "play";
          for (let index = currentIndex; index < data.length; index++) {
            if (status.current === "play") {
              await delay(100);
              setMarkerLocation({ lat: data[index].lat, lng: data[index].lng });
              setCurrentIndex(index);
            } else if (status.current === "pause") {
              break;
            } else {
              setMarkerLocation({ lat: data[0].lat, lng: data[0].lng });
              map.setCenter({ lat: data[0].lat, lng: data[0].lng });
              map.panTo({ lat: data[0].lat, lng: data[0].lng });
              map.setZoom(18);
              setCurrentIndex(0);
              break;
            }
          }
          break;

        case "pause":
          status.current = "pause";
          break;
        case "start":
          setMarkerLocation({ lat: data[0].lat, lng: data[0].lng });
          map.setCenter({ lat: data[0].lat, lng: data[0].lng });
          map.panTo({ lat: data[0].lat, lng: data[0].lng });
          map.setZoom(18);
          setCurrentIndex(0);
          status.current = "start";
          break;
        default:
          break;
      }
    }
    // await delay(100);
    // console.log(statusRoute);
  }, [statusRoute, map, currentIndex, data]);

  useEffect(() => {
    handleRoute();
  }, [statusRoute, status, handleRoute]);

  return (
    <AdvancedMarker position={markerLocation}>
      <div
        style={{
          width: "35px",
          height: "50px",
          backgroundImage: `url(${mapGps})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          boxSizing: "border-box",
        }}
      ></div>
    </AdvancedMarker>
  );
};

const GoogleComponent = ({ markers, deviceData, onClickView, loading, isCheckRoute, statusRoute }) => {
  // const status = useApiLoadingStatus();
  // const map = useMap();
  // const [mapLoaded, setMapLoaded] = useState(false);

  // const setMap = async () => {
  //   setMapLoaded(true);
  //   // await delay(1000);
  // };

  // useEffect(() => {
  //   setMap();
  // }, []);

  // useEffect(() => {
  //   return () => {
  //     setMapLoaded(false);
  //   };
  // }, []);

  return (
    <div style={{ width: "100%", height: "88vh" }} className="position-relative">
      <APIProvider
        apiKey="AIzaSyD0auKcsMNas3qbqi-BgF_s3UxwtQNP74Q"
        onLoad={() => {
          // setMapLoaded(true);
        }}
      >
        <Map
          reuseMaps={true}
          defaultZoom={5}
          defaultCenter={{ lat: 1.0579192, lng: 117.6434826 }}
          mapId="MAP_ID"
          mapTypeId="hybrid"
          options={{
            scrollwheel: true,
            gestureHandling: "auto",
          }}
        >
          {!isCheckRoute ? <PoiMarkers data={markers} deviceData={deviceData} onClickView={onClickView} /> : <MarkerRoute data={markers} statusRoute={statusRoute} />}

          {!isCheckRoute ? <Polygon data={markers} deviceData={deviceData} /> : <PolygonRoute data={markers} />}
        </Map>
      </APIProvider>

      {loading && (
        <div className="d-flex position-absolute justify-content-center align-items-center bg-dark opacity-25 top-50 start-50 translate-middle h-100 w-100">
          <Spinner size="lg" className="flex-shrink-0 text-white" role="status">
            Loading...
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default React.memo(GoogleComponent);
