import React, { useEffect, useState } from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import moment from "moment";
import { useDispatch } from "react-redux";
import Masonry from "react-responsive-masonry";

function CardParamsComponent({ data, t }) {
  const dispatch = useDispatch();
  const [paramsList, setParamsList] = useState([]);

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY HH:mm");
  };

  function formatString(str) {
    let formattedStr = str.replace(/_/g, ' ');
    formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
    return formattedStr;
  }

  useEffect(() => {
    let datas = []
    if (data.device_parameter && data.device_parameter.length > 0) {
      data.device_parameter.forEach((item) => {
        if (item.statusId === 2) {
          if (item.showParameter) {
            item.label = formatString(item.label)
            datas.push(item)
          }
        }
      })
      
      datas.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      setParamsList(datas);
    }
  }, [data]);

  return (
    <Masonry columnsCount={paramsList.length === 1 ? 1 : 6} gutter='15px'>
      {(paramsList || []).map((params, i) => {
        return (
          <div key={"params" + i} className="mb-0">
            {params &&
              params.label !== "Device Time" &&
              params.value !== "timestamp" ? (
              <Card className='card-animate' style={{ cursor: "pointer" }}>
                <CardBody>
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <h4 className='text-truncate mb-0 text-wrap text-center'>
                      {params && params.amount ? (
                        `${new Intl.NumberFormat(["ban", "id"]).format(params.amount)} ${params.uom !== null ? params.uom : ""
                        }`
                      ) : (
                        <div className='d-flex justify-content-center '>
                          <Spinner
                            color='primary'
                            type='grow'
                            size={"sm"}
                            style={{
                              width: "10px",
                              height: "10px",
                            }}>
                            Loading...
                          </Spinner>
                          &nbsp;
                          <Spinner
                            color='success'
                            type='grow'
                            size={"sm"}
                            style={{
                              width: "10px",
                              height: "10px",
                            }}>
                            Loading...
                          </Spinner>
                          &nbsp;
                        </div>
                      )}
                    </h4>
                    <p className='text-muted text-truncate mb-0 text-wrap text-center'>
                      {params && params.label}
                    </p>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Card className='card-animate'>
                <CardBody>
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <h6 className='text-truncate mb-0 text-wrap text-center'>
                      {params && params.amount ? (
                        handleValidDate(params.amount)
                      ) : (
                        <div className='d-flex justify-content-center '>
                          <Spinner
                            color='primary'
                            type='grow'
                            size={"sm"}
                            style={{
                              width: "10px",
                              height: "10px",
                            }}>
                            Loading...
                          </Spinner>
                          &nbsp;
                          <Spinner
                            color='success'
                            type='grow'
                            size={"sm"}
                            style={{
                              width: "10px",
                              height: "10px",
                            }}>
                            Loading...
                          </Spinner>
                          &nbsp;
                        </div>
                      )}
                    </h6>
                    <p className='text-muted text-truncate mb-0 text-wrap text-center'>
                      {params && params.label}
                    </p>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
        );
      })}
    </Masonry>
  );
}

export default CardParamsComponent;
