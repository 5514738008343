import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, Spinner, FormFeedback } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { isEmpty, clone } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";

import { ToastContainer } from "react-toastify";
import Flatpickr from "react-flatpickr";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import { useSelector, useDispatch } from "react-redux";
import { getGeofence as onGetGeofences, addNewGeofence as onAddNewGeofence, updateGeofence as onUpdateGeofence, deleteGeofence as onDeleteGeofence, getGeofenceLoading, resetGeofenceState } from "../../../store/geofence/action";

import { getClient, resetClientState } from "../../../store/client/action";
import { getCompany, resetCompanyState } from "../../../store/company/action";
import { getBranch, resetBranchState } from "../../../store/branch/action";
// import { getGeofenceType, resetGeofenceTypeState } from "../../../store/geofenceType/action";
import { getUnit, resetUnitState } from "../../../store/unit/action";
import { getService, resetServiceState } from "../../../store/service/action";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ModalInputFile from "../../../Components/ModalInputFile";
import defaultImg from "../../../assets/images/upload.png";
import { api } from "../../../config";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { Circle } from "./Circle";

const INITIAL_CENTER = { lat: 1.0579192, lng: 117.6434826 };

const Geofence = (props) => {
  const { t } = props;
  const [modal, setModal] = useState(false);
  const [userType, setUserType] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [clientId, setClientId] = useState();
  const [pIndex, setPIndex] = useState(0);

  const { geofences, isGeofenceCreated, isGeofenceSuccess, loading, error, isMediaDeleted, media, mediaLoading, clients, units, services, companies, branches, companiesLoading, branchesLoading, unitLoading, serviceLoading } = useSelector((state) => ({
    geofences: state.Geofence.geofences,
    clients: state.Client.clients,
    units: state.Unit.units,
    services: state.Service.services,
    isGeofenceCreated: state.Geofence.isGeofenceCreated,
    isGeofenceSuccess: state.Geofence.isGeofenceSuccess,
    loading: state.Geofence.loading,
    error: state.Geofence.error,
    companies: state.Company.companies,
    branches: state.Branch.branches,
    companiesLoading: state.Company.loading,
    branchesLoading: state.Branch.loading,
    unitLoading: state.Unit.loading,
    serviceLoading: state.Service.loading,
  }));

  const [geofenceList, setGeofenceList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [geofence, setGeofence] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const [filterDate, setFilterDate] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [connection, setConnection] = useState("tcp");
  const [mediaType, setMediaType] = useState("");
  const [logo, setLogo] = useState(defaultImg);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [resCheck, setResCheck] = useState(false);
  const [activeTabModal, setActiveTabModal] = useState("1");

  const [isExecutive, setIsExecutive] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (geofence && geofence.id) || "",
      name: (geofence && geofence.name) || "",
      position: (geofence && geofence.position) || { lat: 0, lng: 0 },
      radius: (geofence && geofence.radius) || 0,
      description: (geofence && geofence.description) || "",
      status: (geofence && geofence.status) || "",
      // clientId: req.body.clientId,
      // statusId: req.body.statusId,
      // companyId: req.body.companyId,
      // branchId: req.body.branchId,
    },
    validationSchema: Yup.object({
      // client: Yup.object().required("Please Select Client"),
      // company: Yup.object().required("Please Select Company"),
      // branch: Yup.object().required("Please Select Branch"),
      // // unit: Yup.array().of(
      // //   Yup.object().shape({
      // //     label: Yup.string().required("Label Required"),
      // //     unitId: Yup.number().required("Unit ID Required"),
      // //     value: Yup.number().required("Value Required"),
      // //   })
      // // ),
      // // service: Yup.object().required("Please Select Service"),
      // // geofenceType: Yup.object().required("Please Select Geofence Type"),
      // // connection_type: Yup.object().required("Please Select Connection Type"),
      // // status: Yup.object().required("Please Select Status"),
      name: Yup.string().required("Please Enter Name"),
    }),
    onSubmit: (values) => {
      console.log(values);
      if (isEdit) {
        const editData = {
          id: values.id,
          name: values.name,
          latitude: values.position.lat,
          longitude: values.position.lng,
          radius: values.radius,
          description: values.description,
          statusId: values.status.value,
        };
        dispatch(onUpdateGeofence(editData));
      } else {
        const addData = {
          name: values.name,
          latitude: values.position.lat,
          longitude: values.position.lng,
          radius: values.radius,
          description: values.description,
          statusId: values.status.value,
        };

        dispatch(onAddNewGeofence(addData));
      }
      validation.resetForm();
      toggle();
    },
  });

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: 1 },
        { label: "Active", value: 2 },
        { label: "Deactivated", value: 3 },
      ],
    },
  ];

  const connectionTypeData = [
    {
      options: [
        { label: "Default", value: "default" },
        { label: "Http Api", value: "api" },
      ],
    },
  ];

  const types = [
    {
      options: [
        { label: "TCP", value: "tcp" },
        { label: "MQTT", value: "mqtt" },
      ],
    },
  ];

  const resCheckHandler = () => {
    setResCheck(!resCheck);
  };

  useEffect(() => {
    if (geofences && !geofences.length && !isSearch) {
      dispatch(resetGeofenceState());
      dispatch(onGetGeofences());
    }
    setIsSearch(true);
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      if (obj.data && obj.data.userType) setUserType(obj.data.userType);
      if (obj.data.userType === "company") {
        setCompanyId(obj.data.companyId);
        setClientId(obj.data.clientId);
      } else if (obj.data.userType === "client") {
        setClientId(obj.data.clientId);
      }
    } else {
      window.location = "/logout";
    }
  }, [dispatch, geofences, isSearch]);

  const onClickDelete = (geofence) => {
    setGeofence(geofence);
    setDeleteModal(true);
  };

  const handleDeleteGeofence = () => {
    if (geofence) {
      dispatch(onDeleteGeofence(geofence));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(companies)) {
      var data = [];
      var opt = [];
      companies.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setCompanyList(data);
    }
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(branches)) {
      var data = [];
      var opt = [];
      branches.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setBranchList(data);
    }
  }, [branches]);

  useEffect(() => {
    if (!isEmpty(services)) {
      var data = [];
      var opt = [];
      services.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setServiceList(data);
    }
  }, [services]);

  useEffect(() => {
    if (!isEmpty(units)) {
      var data = [];
      var opt = [];
      units.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id, unitId: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setUnitList(data);
    }
  }, [units]);

  useEffect(() => {
    setGeofenceList(geofences);
  }, [geofences]);

  useEffect(() => {
    dispatch(resetClientState());
    dispatch(getClient()); //{ status: 2 }
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetServiceState());
    dispatch(getService());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(resetGeofenceTypeState());
  //   dispatch(getGeofenceType());
  // }, [dispatch]);

  const handleValidDate = (date) => {
    return moment(date, "YYYY/MM/DD").tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredGeofences = geofences;
      if (type !== "all") {
        filteredGeofences = geofences.filter((geofence) => geofence.geo_status?.name === type);
      }
      setGeofenceList(filteredGeofences);
    }
  };

  const toggleTabModal = (tab) => {
    if (activeTabModal !== tab) {
      setActiveTabModal(tab);
    }
  };

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  const toggle = useCallback(() => {
    if (modal) {
      setGeofence(null);
      // setIsClick(false);
      // setFile([]);
      // forceUpdate();
      // setResCheck(false);
      validation.resetForm();
      validation.setErrors({});
      setModal(false);
    } else {
      // initFile();
      setModal(true);
    }
    if (!isExecutive) {
      //validation.setva
    }
  }, [modal, isExecutive, forceUpdate]);

  const handleCompany = (data) => {
    dispatch(resetCompanyState());
    dispatch(getCompany({ clientId: data.value }));
  };

  const handleUnit = (data) => {
    dispatch(resetUnitState());
    dispatch(getUnit({ branchId: data.value }));
  };

  const handleBranch = (data) => {
    dispatch(resetBranchState());
    dispatch(getBranch({ companyId: data.value }));
  };

  useEffect(() => {
    if (companyId) {
      dispatch(resetBranchState());
      dispatch(getBranch({ companyId: companyId }));
    }
  }, [companyId, dispatch]);

  const handleGeofenceClick = useCallback(
    (arg) => {
      const data = arg;

      setGeofence({
        id: data.id,
        name: data.name,
        position: {
          lat: data.latitude === "" || data.latitude === null || data.latitude === undefined ? 0 : parseFloat(data.latitude),
          lng: data.longitude === "" || data.longitude === null || data.longitude === undefined ? 0 : parseFloat(data.longitude),
        },
        radius: data.radius === "" || data.radius === null || data.radius === undefined ? 0 : parseFloat(data.radius),
        status: {
          label: data.geo_status?.name,
          value: data.geo_status?.id,
        },
        description: data.description,
        client: {
          label: data.geo_client ? data.geo_client.name : "",
          value: data.geo_client ? data.geo_client.id : "",
        },
        company: {
          label: data.geo_company ? data.geo_company.name : "",
          value: data.geo_company ? data.geo_company.id : "",
        },
        branch: {
          label: data.geo_branch ? data.geo_branch.name : "",
          value: data.geo_branch ? data.geo_branch.id : "",
        },
      });

      console.log(geofence);

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="dataCheckbox form-check-input" value={cellProps.row.original.id} />;
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleGeofenceClick(data);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-danger remove-item-btn"
                onClick={() => {
                  const data = cellProps.row.original;
                  onClickDelete(data);
                }}
              >
                <i className=" ri-delete-bin-line fs-15"></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: `${t("Name")}`,
        accessor: "name",
        filterable: true,
      },
      {
        Header: `${t("Company")}`,
        accessor: "geo_company.name",
        filterable: true,
      },
      // {
      //   Header: `${t("Branch")}`,
      //   accessor: "geo_branch.name",
      //   filterable: true,
      // },
      {
        Header: "Radius (Meter)",
        accessor: "radius",
        filterable: true,
      },
      {
        Header: `${t("Latitude")}`,
        accessor: "latitude",
        filterable: true,
      },
      {
        Header: `${t("Longitude")}`,
        accessor: "longitude",
        filterable: true,
      },
      {
        Header: `${t("Status")}`,
        accessor: "geo_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "Activated":
              return <span className="badge text-uppercase badge-soft-secondary">{t(cell.value)}</span>;
            case "Deactivated":
              return <span className="badge text-uppercase badge-soft-danger">{t(cell.value)}</span>;
            case "Draft":
              return <span className="badge text-uppercase badge-soft-warning">{t(cell.value)}</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">{t(cell.value)}</span>;
          }
        },
      },
    ],
    [handleGeofenceClick, t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: geofenceList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: pIndex,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    if (filterDate) {
      let datearr = filterDate.split(" to ");
      if (datearr[1]) {
        startat = datearr[0];
        endat = datearr[1];
      }
    }

    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetGeofences(param));
  }

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteGeofence(element.value));
    });
    checkall.checked = false;
  };

  const handleSelectFile = (file) => {
    switch (mediaType) {
      case "geofence":
        validation.setFieldValue("files", file.filename);
        break;
      case "image":
        validation.setFieldValue("image", file.filename);
        break;
      default:
        break;
    }

    toggleMedia();
  };

  useEffect(() => {
    if (validation.values.files) {
      setFile((file) => [...file, { filename: validation.values.files }]);
    }
  }, [validation.values.files]);

  const handleDeleteFiles = useCallback(
    (files) => {
      // const object = file.findIndex((obj) => obj === files);
      if (file) {
        file.splice(file.indexOf(files), 1);
        setFile(file);
        forceUpdate();
      }

      // if (object > -1) {
      //   file.splice(object, 1);
      // }
    },
    [file, forceUpdate]
  );

  const handleDeleteImages = useCallback(
    (files) => {
      let a = "";
      // const object = file.findIndex((obj) => obj === files);
      if (validation.values.image) {
        validation.setFieldValue("image", a);
        forceUpdate();
      }

      // if (object > -1) {
      //   file.splice(object, 1);
      // }
    },
    [validation, forceUpdate]
  );

  useEffect(() => {
    setPIndex(pageIndex);
  }, [pageIndex]);

  const changeCenter = (newCenter) => {
    if (!newCenter) return;
    validation.setFieldValue("position", { lng: newCenter.lng(), lat: newCenter.lat() });
  };

  document.title = `${t("Geofences")} | TELKOMSEL - IOT Monitoring`;

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={geofenceList} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteGeofence} onCloseClick={() => setDeleteModal(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={t("Geofences")} pageTitle={t("Master Data")} />
        <Row>
          <Col xxl={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    {t("Geofences History")} &nbsp;
                    {loading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}></Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      id="create-btn"
                      onClick={() => {
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-hard-drive-2-line me-1 align-bottom fs-14"></i> {t("Add")}
                    </button>{" "}
                    <button type="button" className="btn btn-success" onClick={() => setIsExportCSV(true)}>
                      <i className="ri-file-download-line me-1 align-bottom fs-14"></i> {t("Export")}
                    </button>{" "}
                    <button className="btn btn-soft-danger" onClick={() => setDeleteModalMulti(true)}>
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-15"></i> {t("Delete")}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-hard-drive-2-line me-1 align-bottom fs-14"></i>
                        {t("All Geofences")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href="#"
                      >
                        <i className="ri-draft-line me-1 align-bottom fs-14"></i>
                        {t("Draft")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i>
                        {t("Activated")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "4" }, "fw-semibold")}
                        onClick={() => {
                          toggleTab("4", "Deactivated");
                        }}
                        href="#"
                      >
                        <i className="ri-indeterminate-circle-line me-1 align-bottom fs-14"></i>
                        {t("Deactivated")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-1">
                            <div className="me-2 mb-2 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} ${t("Search")}...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={6} className="col-xxl-3">
                            <div className="search-box me-2 mb-2 col-12">
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder={t("Select created date")}
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(obj, str) => {
                                  setFilterDate(str);
                                }}
                              />
                              <i className="bx bx-calendar-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={4} className="col-xxl-2">
                            <div className="me-2 mb-2 col-12">
                              <Select
                                value={statusActive}
                                placeholder={t("Select Status")}
                                onChange={(e) => {
                                  setStatusActive(e);
                                }}
                                options={statusActiveData}
                                name="choices-single-default"
                                id="idStatus"
                              ></Select>
                            </div>
                          </Col>
                          <Col className="col-xxl-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i> {t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      {`${t("Page")} `}
                      <strong>
                        {pageIndex + 1} {t("of")} {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} value={pIndex + 1} onChange={onChangeInInput} />
                    </Col>

                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered size="xl">
                  <ModalHeader className=" p-3" toggle={toggle}>
                    {!!isEdit ? `${t("Edit")}  ${t("Geofence")}` : `${t("Add")}  ${t("Geofence")}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <Row>
                        <Col lg={8}>
                          <APIProvider
                            apiKey="AIzaSyD0auKcsMNas3qbqi-BgF_s3UxwtQNP74Q"
                            onLoad={() => {
                              // setMapLoaded(true);
                            }}
                          >
                            <div style={{ width: "100%", height: "600px" }} className="position-relative">
                              <Map
                                reuseMaps={true}
                                defaultZoom={12}
                                defaultCenter={validation.values.position.lat === 0 || validation.values.position.lng === 0 ? INITIAL_CENTER : validation.values.position}
                                mapId="MAP_ID"
                                mapTypeId="hybrid"
                                onClick={(e) => {
                                  console.log("jln");
                                  validation.setFieldValue("position", { lat: e.detail.latLng.lat ?? 0, lng: e.detail.latLng.lng ?? 0 });
                                }}
                                options={{
                                  scrollwheel: true,
                                  gestureHandling: "auto",
                                }}
                              >
                                <Marker
                                  position={validation.values.position.lat === 0 || validation.values.position.lng === 0 ? INITIAL_CENTER : validation.values.position}
                                  draggable
                                  onDrag={(e) => {
                                    console.log("jln");
                                    validation.setFieldValue("position", { lat: e.latLng?.lat() ?? 0, lng: e.latLng?.lng() ?? 0 });
                                  }}
                                />
                                <Circle
                                  radius={validation.values.radius}
                                  center={validation.values.position.lat === 0 || validation.values.position.lng === 0 ? INITIAL_CENTER : validation.values.position}
                                  onRadiusChanged={(val) => {
                                    validation.setFieldValue("radius", val);
                                  }}
                                  // onCenterChanged={changeCenter}
                                  strokeColor={"#0c4cb3"}
                                  strokeOpacity={1}
                                  strokeWeight={3}
                                  fillColor={"#3b82f6"}
                                  fillOpacity={0.3}
                                  // editable
                                  // draggable
                                />
                                {/* {!isCheckRoute ? <PoiMarkers data={markers} deviceData={deviceData} onClickView={onClickView} /> : <MarkerRoute data={markers} statusRoute={statusRoute} />}

                                {!isCheckRoute ? <Polygon data={markers} deviceData={deviceData} /> : <PolygonRoute data={markers} />} */}
                              </Map>

                              {/* {loading && (
                                <div className="d-flex position-absolute justify-content-center align-items-center bg-dark opacity-25 top-50 start-50 translate-middle h-100 w-100">
                                  <Spinner size="lg" className="flex-shrink-0 text-white" role="status">
                                    Loading...
                                  </Spinner>
                                </div>
                              )} */}
                            </div>
                          </APIProvider>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Label htmlFor="name-field" className="form-label">
                              {t("Name")} <span className="text-danger">*</span>
                            </Label>
                            <Input name="name" id="name-field" className="form-control" placeholder={`${t("Enter")} ${t("Name")}`} type="text" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name || ""} invalid={validation.touched.name && validation.errors.name ? true : false} />
                            {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="radius-field" className="form-label">
                              {t("Radius")} {t("(Meter)")}
                            </Label>
                            <Input name="radius" id="radius-field" className="form-control" placeholder={`${t("Enter")} ${t("Name")}`} type="number" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.radius} invalid={validation.touched.radius && validation.errors.radius ? true : false} />
                            {validation.touched.radius && validation.errors.radius ? <FormFeedback type="invalid">{validation.errors.radius}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="latitude-field" className="form-label">
                              {t("Latitude")}
                            </Label>
                            <Input
                              name="latitude"
                              id="latitude-field"
                              className="form-control"
                              placeholder={`${t("Enter")} ${t("Name")}`}
                              type="number"
                              onChange={(e) => {
                                console.log(e.target.value);
                                const pos = { ...validation.values.position };
                                pos.lat = e.target.value;
                                validation.setFieldValue("position", pos);
                              }}
                              value={validation.values.position.lat}
                            />
                            {/* {validation.touched.position.lat && validation.errors.position.lat ? <FormFeedback type="invalid">{"validation.errors.position"}</FormFeedback> : null} */}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="longitude-field" className="form-label">
                              {t("Longitude")}
                            </Label>
                            <Input
                              name="longitude"
                              id="latitude-field"
                              className="form-control"
                              placeholder={`${t("Enter")} ${t("Name")}`}
                              type="number"
                              onChange={(e) => {
                                console.log(e.target.value);
                                const pos = { ...validation.values.position };
                                pos.lng = e.target.value;
                                validation.setFieldValue("position", pos);
                              }}
                              value={validation.values.position.lng}
                            />
                            {/* {validation.touched.position.lat && validation.errors.position.lat ? <FormFeedback type="invalid">{"validation.errors.position"}</FormFeedback> : null} */}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="status-field" className="form-label">
                              Status
                            </Label>
                            <Select
                              name="status"
                              value={validation.values.status}
                              aria-invalid={validation.touched.status}
                              aria-errormessage={validation.errors.status}
                              validate={{ required: { value: true } }}
                              placeholder={`${t("Select")} ${t("Status")}`}
                              onChange={(e) => {
                                validation.setFieldValue("status", e);
                              }}
                              options={statusActiveData}
                            ></Select>
                            {validation.touched.status && (
                              <p role="alert" style={{ color: "#f06548", fontSize: "11px" }}>
                                {validation.errors.status}
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end mt-3">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            toggle();
                          }}
                        >
                          {t("Close")}
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">{!!isEdit ? `${t("Update")}  ${t("Geofence")}` : `${t("Add")}  ${t("Geofence")}`}</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ModalInputFile onSelect={handleSelectFile} loading={loading} modal={modalMedia} toggle={toggleMedia} t={t} />
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Geofence.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Geofence));
