import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

function WidgetList({ data }) {
  return (
    <>
      <ListGroup flush numbered className="mt-2">
        {data.dd_parameter.map((wParams, idxWParams) => {
          return (
            <ListGroupItem key={idxWParams}>
              {wParams.ddp_parameter.label + " : "}
              {wParams.ddp_parameter.amount
                ? `${new Intl.NumberFormat(["ban", "id"]).format(wParams.ddp_parameter.amount)} ${wParams.ddp_parameter.uom}`
                : "-"}
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </>
  );
}

export default WidgetList;
