import React, { useEffect, useState } from "react";

import TemplateComponent from "../../../Components/Common/TemplateComponent";
import {
  getDashboard,
  resetDashboardState,
} from "../../../store/dashboard/action";

import { useSelector, useDispatch } from "react-redux";
import TemplateComponent2 from "../../../Components/Common/TemplateComponent2";
import TemplateComponent3 from "../../../Components/Common/TemplateComponent3";
import TemplateAdmin from "../../../Components/Common/TemplateAdmin";

const Default = (mapApiKey) => {
  const dispatch = useDispatch();
  const [tempId, setTempId] = useState();
  const [mapList, setMapList] = useState("");
  const [userType, setClientId] = useState("");
  const [clientId, setUserId] = useState("");

  const { dashboards } = useSelector((state) => ({
    dashboards: state.Dashboard.dashboards,
  }));

  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    setUserId(obj);
    if (obj) {
      dispatch(resetDashboardState());
      dispatch(getDashboard({ companyId: obj.data.companyId }));
    } else {
      window.location = "/logout";
    }
  }, [dispatch]);

  useEffect(() => {
    if (dashboards[0] && dashboards[0].dsbTemplateId) {
      setTempId((dashboards[0] && dashboards[0].dsbTemplateId) || 1);
    }
  }, [dashboards]);

  useEffect(() => {
    if (dashboards) {
      let dataMap;
      dashboards.forEach((dashboard) => {
        setClientId(dashboards.clientId);
        dashboard.dashboard_detail.forEach((detail) => {
          if (detail.type === "map") {
            dataMap = detail.value;
          }
        });
      });
      setMapList(dataMap);
    }
  }, [dashboards]);

  document.title = `Dashboard | TELKOMSEL - IOT Monitoring`;
  return userType && userType === "admin" && clientId === 1 ? (
    <TemplateAdmin isEdit={false} dashboards={dashboards} mapLists={mapList} />
  ) : userType && userType === "client" ? (
    <TemplateComponent
      isEdit={false}
      dashboards={dashboards}
      mapLists={mapList}
    />
  ) : tempId && tempId === 1 ? (
    <TemplateComponent
      isEdit={false}
      dashboards={dashboards}
      mapLists={mapList}
      mapApiKey={mapApiKey}
    />
  ) : tempId && tempId === 3 ? (
    <TemplateComponent2
      isEdit={false}
      dashboards={dashboards}
      mapLists={mapList}
    />
  ) : tempId && tempId === 2 ? (
    <TemplateComponent3
      isEdit={false}
      dashboards={dashboards}
      mapLists={mapList}
    />
  ) : (
    <p>loading...</p>
  );
};

export default Default;
