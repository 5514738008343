import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { getTask as onGetTasks, resetTaskState } from "../../store/task/action";
import {
  // editImageUser as onEditImageUser,
  resetProfileFlag,
} from "../../store/auth/profile/actions";
import {
  getUserOne as onGetUserOne,
  getUser as onGetUser,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
  resetUserState,
  getUserLoading,
} from "../../store/users/action";
import { getClient, resetClientState } from "../../store/client/action";
import { deleteFile } from "../../store/file/action";
import {
  getClient as onGetClients,
  addNewClient as onAddNewClient,
  updateClient as onUpdateClient,
  deleteClient as onDeleteClient,
  getClientLoading,
} from "../../store/client/action";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormFeedback,
  Spinner,
  Progress,
  ListGroup,
  CardTitle,
  CardText,
} from "reactstrap";
import classnames from "classnames";
import * as Yup from "yup";
import { useFormik, Field, FieldArray } from "formik";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import moment from "moment-timezone";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { isEmpty, clone } from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../Components/Common/DeleteModal";
import AsyncSelect from "react-select/async";

import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

//Images
import defaultImg from "../../assets/images/upload.png";
import avatar from "../../assets/images/users/user-dummy-img.jpg";
import { Fileserver } from "../../helpers/fileserver";

const SimplePage = (props) => {
  const { t } = props;
  const { user, success } = useSelector((state) => ({
    success: state.User.success,
    user: state.User.user,
  }));
  const { isUserSuccess } = useSelector((state) => ({
    isUserSuccess: state.User.isUserSuccess,
  }));
  SwiperCore.use([Autoplay]);

  const [logo, setLogo] = useState(defaultImg);

  const [currentbyte, setCurrentByte] = useState(0);
  const [totalbyte, setTotalByte] = useState(0);
  const [percentbyte, setPercentByte] = useState(0);
  const [fileNameItem, setFileNameItem] = useState([]);
  const [activeFileUpload, setActiveFileUpload] = useState({});
  const [isUploadFile, setIsFileUpload] = useState(false);
  const [deletedFileId, setDeletedFileId] = useState("");
  const [file, setFile] = useState([]);
  const [changeImg, setChangeImg] = useState(false);
  const [done, setDone] = useState(false);
  const [currentbyteVaksin, setCurrentByteVaksin] = useState(0);
  const [totalbyteVaksin, setTotalByteVaksin] = useState(0);
  const [percentbyteVaksin, setPercentByteVaksin] = useState(0);
  const [fileNameItemVaksin, setFileNameItemVaksin] = useState([]);
  const [activeFileUploadVaksin, setActiveFileUploadVaksin] = useState({});
  const [isUploadFileVaksin, setIsFileUploadVaksin] = useState(false);
  const [deletedFileIdVaksin, setDeletedFileIdVaksin] = useState("");

  const [currentbyteKes, setCurrentByteKes] = useState(0);
  const [totalbyteKes, setTotalByteKes] = useState(0);
  const [percentbyteKes, setPercentByteKes] = useState(0);
  const [fileNameItemKes, setFileNameItemKes] = useState([]);
  const [activeFileUploadKes, setActiveFileUploadKes] = useState({});
  const [isUploadFileKes, setIsFileUploadKes] = useState(false);
  const [deletedFileIdKes, setDeletedFileIdKes] = useState("");

  const [currentbyteProfile, setCurrentByteProfile] = useState(0);
  const [totalbyteProfile, setTotalByteProfile] = useState(0);
  const [percentbyteProfile, setPercentByteProfile] = useState(0);
  const [fileNameItemProfile, setFileNameItemProfile] = useState([]);
  const [activeFileUploadProfile, setActiveFileUploadProfile] = useState({});
  const [isUploadFileProfile, setIsFileUploadProfile] = useState(false);
  const [deletedFileIdProfile, setDeletedFileIdProfile] = useState("");

  const [userId, setUserId] = useState("");
  const [userImage, setUserImage] = useState(avatar);
  const [userName, setUserName] = useState("User");
  const [userPosition, setUserPosition] = useState("-");
  const [userEmail, setUserEmail] = useState("");
  const [userLocation, setUserLocation] = useState([]);
  const [userJoinDate, setUserJoinDate] = useState("");
  const [userDescription, setUserDescription] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabTask, setActiveTabTask] = useState("1");
  const [activityTab, setActivityTab] = useState("1");
  const [open, setOpen] = useState("2");
  const [originalFiles, setOriginalFiles] = useState([]);
  const [addressName, setAddresName] = useState();
  const [addressLabel, setAddressLabel] = useState();
  const [addressCity, setAddressCity] = useState();
  const [addressProv, setAddressProv] = useState();
  const [addressValue, setAddressValue] = useState();
  const [subscribeAt, setSubscribeAt] = useState();
  const [expiredAt, setExpiredAt] = useState();
  const [statusClient, setStatusClient] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataClient, setDataClient] = useState([]);
  const [kirim, setKirim] = useState(false);
  const [currentbyteSign, setCurrentByteSign] = useState(0);
  const [totalbyteSign, setTotalByteSign] = useState(0);
  const [percentbyteSign, setPercentByteSign] = useState(0);
  const [fileNameItemSign, setFileNameItemSign] = useState([]);
  const [activeFileUploadSign, setActiveFileUploadSign] = useState({});
  const [isUploadFileSign, setIsFileUploadSign] = useState(false);
  const [deletedFileIdSign, setDeletedFileIdSign] = useState("");
  const [image, setImage] = useState(avatar);
  const [type, setType] = useState();
  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen(open);
    } else {
      setOpen(id);
    }
  };

  const userTypes = [
    {
      options: [
        { label: "Internal", value: "internal" },
        { label: "Client", value: "client" },
      ],
    },
  ];

  const userRole = [
    {
      options: [
        { label: "Administrator", value: "administrator" },
        { label: "User", value: "user" },
        { label: "Guest", value: "guest" },
      ],
    },
  ];

  const [isSearch, setIsSearch] = useState(false);
  const [userList, setUserList] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const [statusActive, setStatusActive] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalClient, setModalClient] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [userr, setUserr] = useState([]);
  const dispatch = useDispatch();
  const [fileUser, setFileUser] = useState();

  const { error, users, userLoading, clients, loading, isMediaDeleted } =
    useSelector((state) => ({
      error: state.User.error,
      users: state.User.users,
      loading: state.User.loading,
      clients: state.Client.clients,
    }));

  const statusActiveData = [
    {
      options: [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "2" },
      ],
    },
  ];
  const defaultdate = () => {
    return moment(new Date())
      .tz("Asia/Jakarta")
      .format("YYYY/MM/DD HH:mm:ss")
      .toString();
  };

  const [filterDate, setFilterDate] = useState(defaultdate());

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleTabTask = (tab, type) => {
    if (activeTabTask !== tab) {
      setActiveTabTask(tab);
      let filteredTasks = users;
      if (type !== "all") {
        filteredTasks = users.filter(
          (users) => users.user_status.name === type
        );
      }
      setUserList(filteredTasks);
    }
  };

  const toggleActivityTab = (tab) => {
    if (activityTab !== tab) {
      setActivityTab(tab);
    }
  };

  const handleValidDate = (date) => {
    return moment(new Date(date), "YYYY/MM/DD")
      .tz("Asia/Jakarta")
      .format("MMM D, YYYY");
  };

  //

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable
      ? column.isSorted
        ? column.isSortedDesc
          ? " sorting_desc"
          : " sorting_asc"
        : " sort"
      : "";
  };

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const onClickDelete = (user) => {
    setUserr(user);
    setDeleteModal(true);
  };
  useEffect(() => {
    if (isUploadFileSign) {
      // setTotalByte(totalbyte);
      switch (true) {
        case currentbyteSign <= totalbyteSign:
          setPercentByteSign(
            Math.ceil((currentbyteSign / totalbyteSign) * 100)
          );
          break;
        default:
          break;
      }
    }
  }, [isUploadFileSign, totalbyteSign, currentbyteSign, setPercentByteSign]);

  useEffect(() => {
    if (percentbyteSign === 100 && isUploadFileSign) {
      const fc = [];
      const fcId = [];

      if (fileNameItemSign && fileNameItemSign.length > 0) {
        fileNameItemSign.forEach((itm) => {
          fc.push(itm);
          fcId.push({ file_id: itm.id });
        });
      }

      if (activeFileUploadSign && activeFileUploadSign.id) {
        fc.push({
          file_id: activeFileUploadSign.id,
          originalname: activeFileUploadSign.filename,
          filename: activeFileUploadSign.filejadi,
          size: activeFileUploadSign.filesize,
          type: "ttd",
        });
        setFileNameItemSign(fc);

        fcId.push({ file_id: activeFileUploadSign.id });
        const o = {};
        setActiveFileUploadSign(o);
      }
      setTimeout(() => {
        setTotalByteSign(0);
        setCurrentByteSign(0);
        setPercentByteSign(0);
        setIsFileUploadSign(false);
      }, 3000);
    }
  }, [
    percentbyteSign,
    isUploadFileSign,
    fileNameItemSign,
    activeFileUploadSign,
  ]);

  useEffect(() => {
    if (isMediaDeleted) {
      const object = fileNameItemSign.findIndex(
        (obj) => obj.file_id === deletedFileIdSign
      );
      if (object > -1) {
        fileNameItemSign.splice(object, 1);
      }
      const fc = fileNameItemSign;
      setFileNameItemSign(fc);
    }
  }, [isMediaDeleted, fileNameItemSign, deletedFileIdSign]);

  const onStartFileUploadSign = useCallback(
    (filesize) => {
      setIsFileUploadSign(true);
      setTotalByteSign(filesize);
      // totalbyte = filesize;
      // document.querySelector('#_total').innerText =  `Total Bytes: ${totalbyte}`
    },
    [setTotalByteSign]
  );

  const onProgressFileUploadSign = useCallback(
    (total) => {
      setCurrentByteSign(total);
      // setPercentByte(Math.ceil((currentbyte / totalbyte) * 100));
    },
    [setCurrentByteSign]
  );

  const onEndFileUploadSign = useCallback(
    (data) => {
      const js = JSON.parse(data.data);
      setTotalByteSign(parseInt(js.filesize));
      // const id = js.id;
      // handleFile(id);
      setActiveFileUploadSign(js);
      document.querySelector("#fUpload").value = "";
    },
    [setActiveFileUploadSign]
  );

  const initFileSign = useCallback(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const allowedmime = [
      // "application/vnd.ms-excel",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/msword",
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/png",
    ];

    const option = {
      idinput: "fUploadSign",
      userId: obj.data.userId.toString(),
      clientId: obj.data.user_client
        ? obj.data.user_client.id.toString()
        : "null",
      folder: "user",
      allowedmime: allowedmime,
      onStart: onStartFileUploadSign,
      onEnd: onEndFileUploadSign,
      onProgress: onProgressFileUploadSign,
    };

    Fileserver(option);
  }, [onStartFileUploadSign, onEndFileUploadSign, onProgressFileUploadSign]);

  const onEndFileUpload = useCallback(
    (data) => {
      const js = JSON.parse(data.data);
      setTotalByte(parseInt(js.filesize));
      // const id = js.id;
      // handleFile(id);
      setActiveFileUpload(js);
      document.querySelector("#fUpload").value = "";
    },
    [setActiveFileUpload]
  );

  const onStartFileUpload = useCallback(
    (filesize) => {
      setIsFileUpload(true);
      setTotalByte(filesize);
      // totalbyte = filesize;
      // document.querySelector('#_total').innerText =  `Total Bytes: ${totalbyte}`
    },
    [setTotalByte]
  );

  const onProgressFileUpload = useCallback(
    (total) => {
      // console.log("progres", total);
      // console.log("totalbyte", totalbyte);
      setCurrentByte(total);
      // setPercentByte(Math.ceil((currentbyte / totalbyte) * 100));
      //   console.log(`
      //   current byte: ${currentbyte},
      //   percent byte: ${percentbyte}
      // `);
    },
    [setCurrentByte]
  );

  const initFile = useCallback(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const allowedmime = [
      // "application/vnd.ms-excel",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/msword",
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/png",
    ];

    const option = {
      idinput: "fUpload",
      userId: obj.data.userId.toString(),
      folder: "client",
      allowedmime: allowedmime,
      onStart: onStartFileUpload,
      onEnd: onEndFileUpload,
      onProgress: onProgressFileUpload,
    };

    Fileserver(option);
  }, [onStartFileUpload, onEndFileUpload, onProgressFileUpload]);

  useEffect(() => {
    if (isUploadFileVaksin) {
      // setTotalByte(totalbyte);
      switch (true) {
        case currentbyteVaksin <= totalbyteVaksin:
          setPercentByteVaksin(
            Math.ceil((currentbyteVaksin / totalbyteVaksin) * 100)
          );
          break;
        default:
          break;
      }
    }
  }, [
    isUploadFileVaksin,
    totalbyteVaksin,
    currentbyteVaksin,
    percentbyteVaksin,
    setTotalByteVaksin,
    setPercentByteVaksin,
    setIsFileUploadVaksin,
  ]);

  useEffect(() => {
    if (isUploadFileProfile) {
      // setTotalByte(totalbyte);
      switch (true) {
        case currentbyteProfile <= totalbyteProfile:
          setPercentByteProfile(
            Math.ceil((currentbyteProfile / totalbyteProfile) * 100)
          );
          break;
        default:
          break;
      }
    }
  }, [
    isUploadFileProfile,
    totalbyteProfile,
    currentbyteProfile,
    percentbyteProfile,
    setTotalByteProfile,
    setPercentByteProfile,
    setIsFileUploadProfile,
  ]);

  useEffect(() => {
    if (isUploadFileKes) {
      // setTotalByte(totalbyte);
      switch (true) {
        case currentbyteKes <= totalbyteKes:
          setPercentByteKes(Math.ceil((currentbyteKes / totalbyteKes) * 100));
          break;
        default:
          break;
      }
    }
  }, [isUploadFileKes, totalbyteKes, currentbyteKes, setPercentByteKes]);

  useEffect(() => {
    if (percentbyteVaksin === 100 && isUploadFileVaksin) {
      const fc = [];
      const fcId = [];

      if (fileNameItemVaksin && fileNameItemVaksin.length > 0) {
        fileNameItemVaksin.forEach((itm) => {
          fc.push(itm);
          fcId.push({ file_id: itm.id });
        });
      }

      if (activeFileUploadVaksin && activeFileUploadVaksin.id) {
        fc.push({
          file_id: activeFileUploadVaksin.id,
          originalname: activeFileUploadVaksin.filename,
          filename: activeFileUploadVaksin.filejadi,
          size: activeFileUploadVaksin.filesize,
          type: "vaksin",
        });
        setFileNameItemVaksin(fc);

        fcId.push({ file_id: activeFileUploadVaksin.id });
        const o = {};
        setActiveFileUploadVaksin(o);
      }
      setTimeout(() => {
        setTotalByteVaksin(0);
        setCurrentByteVaksin(0);
        setPercentByteVaksin(0);
        setIsFileUploadVaksin(false);
      }, 3000);
    }
  }, [
    percentbyteVaksin,
    isUploadFileVaksin,
    fileNameItemVaksin,
    activeFileUploadVaksin,
  ]);

  useEffect(() => {
    if (percentbyteProfile === 100 && isUploadFileProfile) {
      const fc = [];
      const fcId = [];

      if (fileNameItemProfile && fileNameItemProfile.length > 0) {
        fileNameItemProfile.forEach((itm) => {
          fc.push(itm);
          fcId.push({ file_id: itm.id });
        });
      }

      if (activeFileUploadProfile && activeFileUploadProfile.id) {
        fc.push({
          file_id: activeFileUploadProfile.id,
          originalname: activeFileUploadProfile.filename,
          filename: activeFileUploadProfile.filejadi,
          size: activeFileUploadProfile.filesize,
          type: "profile",
        });
        setFileNameItemProfile(fc);

        fcId.push({ file_id: activeFileUploadProfile.id });
        const o = {};
        setActiveFileUploadProfile(o);
      }
      setTimeout(() => {
        setTotalByteProfile(0);
        setCurrentByteProfile(0);
        setPercentByteProfile(0);
        setIsFileUploadProfile(false);
      }, 3000);
    }
  }, [
    percentbyteProfile,
    isUploadFileProfile,
    fileNameItemProfile,
    activeFileUploadProfile,
  ]);

  useEffect(() => {
    if (percentbyteKes === 100 && isUploadFileKes) {
      const fc = [];
      const fcId = [];

      if (fileNameItemKes && fileNameItemKes.length > 0) {
        fileNameItemKes.forEach((itm) => {
          fc.push(itm);
          fcId.push({ file_id: itm.id });
        });
      }

      if (activeFileUploadKes && activeFileUploadKes.id) {
        fc.push({
          file_id: activeFileUploadKes.id,
          originalname: activeFileUploadKes.filename,
          filename: activeFileUploadKes.filejadi,
          size: activeFileUploadKes.filesize,
          type: "kes",
        });
        setFileNameItemKes(fc);

        fcId.push({ file_id: activeFileUploadKes.id });
        const o = {};
        setActiveFileUploadKes(o);
      }
      setTimeout(() => {
        setTotalByteKes(0);
        setCurrentByteKes(0);
        setPercentByteKes(0);
        setIsFileUploadKes(false);
      }, 3000);
    }
  }, [percentbyteKes, isUploadFileKes, fileNameItemKes, activeFileUploadKes]);

  useEffect(() => {
    if (isMediaDeleted) {
      const object = fileNameItemVaksin.findIndex(
        (obj) => obj.file_id === deletedFileIdVaksin
      );
      if (object > -1) {
        fileNameItemVaksin.splice(object, 1);
      }
      const fc = fileNameItemVaksin;
      setFileNameItemVaksin(fc);
    }
  }, [isMediaDeleted, fileNameItemVaksin, deletedFileIdVaksin]);

  useEffect(() => {
    if (isMediaDeleted) {
      const object = fileNameItemProfile.findIndex(
        (obj) => obj.file_id === deletedFileIdProfile
      );
      if (object > -1) {
        fileNameItemProfile.splice(object, 1);
      }
      const fc = fileNameItemProfile;
      setFileNameItemProfile(fc);
    }
  }, [isMediaDeleted, fileNameItemProfile, deletedFileIdProfile]);

  useEffect(() => {
    if (isMediaDeleted) {
      const object = fileNameItemKes.findIndex(
        (obj) => obj.file_id === deletedFileIdKes
      );
      if (object > -1) {
        fileNameItemKes.splice(object, 1);
      }
      const fc = fileNameItemKes;
      setFileNameItemKes(fc);
    }
  }, [isMediaDeleted, fileNameItemKes, deletedFileIdKes]);

  const onStartFileUploadVaksin = useCallback(
    (filesize) => {
      setIsFileUploadVaksin(true);
      setTotalByteVaksin(filesize);
      // totalbyte = filesize;
      // document.querySelector('#_total').innerText =  `Total Bytes: ${totalbyte}`
    },
    [setTotalByteVaksin]
  );

  const onStartFileUploadProfile = useCallback(
    (filesize) => {
      setIsFileUploadProfile(true);
      setTotalByteProfile(filesize);
      // totalbyte = filesize;
      // document.querySelector('#_total').innerText =  `Total Bytes: ${totalbyte}`
    },
    [setTotalByteProfile]
  );

  const onStartFileUploadKes = useCallback(
    (filesize) => {
      setIsFileUploadKes(true);
      setTotalByteKes(filesize);
      // totalbyte = filesize;
      // document.querySelector('#_total').innerText =  `Total Bytes: ${totalbyte}`
    },
    [setTotalByteKes]
  );

  const onProgressFileUploadVaksin = useCallback(
    (total) => {
      setCurrentByteVaksin(total);
      // setPercentByte(Math.ceil((currentbyte / totalbyte) * 100));
    },
    [setCurrentByteVaksin]
  );

  const onProgressFileUploadProfile = useCallback(
    (total) => {
      setCurrentByteProfile(total);
      // setPercentByte(Math.ceil((currentbyte / totalbyte) * 100));
    },
    [setCurrentByteProfile]
  );

  const onProgressFileUploadKes = useCallback(
    (total) => {
      setCurrentByteKes(total);
      // setPercentByte(Math.ceil((currentbyte / totalbyte) * 100));
    },
    [setCurrentByteKes]
  );

  const onEndFileUploadVaksin = useCallback(
    (data) => {
      const js = JSON.parse(data.data);
      setTotalByteVaksin(parseInt(js.filesize));
      // const id = js.id;
      // handleFile(id);
      setActiveFileUploadVaksin(js);
      document.querySelector("#fUpload").value = "";
    },
    [setActiveFileUploadVaksin]
  );

  const onEndFileUploadProfile = useCallback(
    (data) => {
      const js = JSON.parse(data.data);
      setTotalByteProfile(parseInt(js.filesize));
      // const id = js.id;
      // handleFile(id);
      setActiveFileUploadProfile(js);
      document.querySelector("#fUpload").value = "";
    },
    [setActiveFileUploadProfile]
  );

  const onEndFileUploadKes = useCallback(
    (data) => {
      const js = JSON.parse(data.data);
      setTotalByteKes(parseInt(js.filesize));
      // const id = js.id;
      // handleFile(id);
      setActiveFileUploadKes(js);
      document.querySelector("#fUpload").value = "";
    },
    [setActiveFileUploadKes]
  );

  const initFileVaksin = useCallback(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const allowedmime = [
      // "application/vnd.ms-excel",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/msword",
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/png",
    ];

    const option = {
      idinput: "fUploadVaksin",
      userId: obj.data.userId.toString(),
      clientId: obj.data.user_client
        ? obj.data.user_client.id.toString()
        : "null",
      folder: "user",
      allowedmime: allowedmime,
      onStart: onStartFileUploadVaksin,
      onEnd: onEndFileUploadVaksin,
      onProgress: onProgressFileUploadVaksin,
    };

    Fileserver(option);
  }, [
    onStartFileUploadVaksin,
    onEndFileUploadVaksin,
    onProgressFileUploadVaksin,
  ]);

  const initFileProfile = useCallback(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const allowedmime = [
      // "application/vnd.ms-excel",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/msword",
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/png",
    ];

    const option = {
      idinput: "fUploadProfile",
      userId: obj.data.userId.toString(),
      clientId: obj.data.user_client
        ? obj.data.user_client.id.toString()
        : "null",
      folder: "user",
      allowedmime: allowedmime,
      onStart: onStartFileUploadProfile,
      onEnd: onEndFileUploadProfile,
      onProgress: onProgressFileUploadProfile,
    };

    Fileserver(option);
  }, [
    onStartFileUploadProfile,
    onEndFileUploadProfile,
    onProgressFileUploadProfile,
  ]);

  const initFileKes = useCallback(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const allowedmime = [
      // "application/vnd.ms-excel",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/msword",
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/png",
    ];

    const option = {
      idinput: "fUploadKes",
      userId: obj.data.userId.toString(),
      clientId: obj.data.user_client
        ? obj.data.user_client.id.toString()
        : "null",
      folder: "user",
      allowedmime: allowedmime,
      onStart: onStartFileUploadKes,
      onEnd: onEndFileUploadKes,
      onProgress: onProgressFileUploadKes,
    };

    Fileserver(option);
  }, [onStartFileUploadKes, onEndFileUploadKes, onProgressFileUploadKes]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setUserr([]);
      setFileNameItem([]);
      setFileNameItemSign([]);
      setFileNameItemVaksin([]);
      setFileNameItemKes([]);
      setFileNameItemProfile([]);
    } else {
      setModal(true);
      initFile();
      initFileSign();
      initFileVaksin();
      initFileKes();
      initFileProfile();
    }
  }, [
    modal,
    initFile,
    initFileSign,
    initFileVaksin,
    initFileKes,
    initFileProfile,
  ]);

  useEffect(() => {
    //if (users && !users.length && !isSearch) {
    if (!isSearch) {
      setIsSearch(true);
      const emp = JSON.parse(sessionStorage.getItem("authUser"));
      setType(emp.data.userType);
    }
  }, [dispatch, users, isSearch]);

  useEffect(() => {
    if (isUserSuccess) {
      setUserId(user.id);
      if (user.user_image) {
        setUserImage(
          process.env.REACT_APP_API_URL +
          "/media/" +
          user.user_image.file_folder.name +
          "/" +
          user.user_image.filename
        );
      } else {
        setUserImage(avatar);
      }

      let clientAddress = [];
      console.log("us", user);
      if (user.user_client && user.user_client.client_address.length) {
        user.user_client.client_address.forEach((address) => {
          if (address.isPrimary) clientAddress = address;
        });
      }

      const addressLabel = clientAddress
        ? clientAddress.district && clientAddress.district
        : "";
      const addressCity = clientAddress
        ? clientAddress.city && clientAddress.city
        : "";
      const addressProv = clientAddress
        ? clientAddress.province && clientAddress.province
        : "";
      const addressName = clientAddress
        ? clientAddress.village && clientAddress.village
        : "";

      const clientData = {
        id: user.user_client && user.user_client.id,
        name: user.user_client && user.user_client.name,
        subscribeat: user.user_client && user.user_client.subscribeat,
        expiredat: user.user_client && user.user_client.expiredat,
        description: user.user_client && user.user_client.description,
        status: {
          value:
            user.user_client && user.user_client.client_status
              ? user.user_client.client_status.id
              : "",
          label:
            user.user_client && user.user_client.client_status
              ? user.user_client.client_status.name
              : "",
        },
        file:
          user.user_client && user.user_client
            ? user.user_client.client_image?.filename
            : defaultImg,
        address: addressLabel,
      };

      if (user.user_client) {
        let dataFile = [];
        dataFile.push({
          id: user.user_client.client_image?.id,
          filename: user.user_client.client_image?.filename,
          originalname: user.user_client.client_image?.originalname,
        });
        setFile(dataFile);
      }
      if (user && user.user_image) {
        let datafile = [];
        datafile.push({
          id: user.user_image.id,
          filename: user.user_image.filename,
          originalname: user.user_image.originalname,
        });
        setFileUser(datafile);
        setDone(true);
      }
      setDataClient(clientData);

      setAddresName(addressName);
      setAddressLabel(addressLabel);
      setAddressCity(addressCity);
      setAddressProv(addressProv);
      setAddressValue(addressValue);
      setSubscribeAt(user.user_client && user.user_client.subscribeat);
      setExpiredAt(user.user_client && user.user_client.expiredat);
      setStatusClient(user.user_client && user.user_client.client_status?.name);

      setUserName(user.user_client && user.user_client.name);
      setUserPosition(
        user.user_position ? user.user_position.position_name : "-"
      );

      setOriginalFiles(user.user_file);
      setUserLocation(user.user_location);
      setUserEmail(user.email);
      setUserJoinDate(user.joinat);
      setUserDescription(user.description);
    }
  }, [isUserSuccess, user, addressName, addressValue]);

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetUserState());
      dispatch(onGetUser({ status: 2 }));
      setIsSearch(true);
    }
  }, [dispatch, isSearch]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setIsEdit(false);
      setUserList(users);
    }
  }, [users]);

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetClientState());
      dispatch(getClient());
    }
  }, [isSearch, dispatch]);

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if (obj.data.userId) {
        setUserId(obj.data.userId);
        dispatch(onGetUserOne(obj.data.userId));
      }
    }
  }, [dispatch]);

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }
    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    // dispatch(onGetTasks(param));
  }

  const handleDeleteUser = () => {
    if (userr) {
      dispatch(onDeleteUser(userr));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      opt.push({ label: "INTERNAL", value: null });
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  const handleDeleteFiles = useCallback(
    (filesId) => {
      const object = fileNameItem.findIndex((obj) => obj.file_id === filesId);
      const object2 = fileNameItemSign.findIndex(
        (obj) => obj.file_id === filesId
      );
      const object3 = fileNameItemVaksin.findIndex(
        (obj) => obj.file_id === filesId
      );
      const object4 = fileNameItemKes.findIndex(
        (obj) => obj.file_id === filesId
      );
      const object5 = fileNameItemProfile.findIndex(
        (obj) => obj.file_id === filesId
      );

      if (object > -1) {
        dispatch(deleteFile({ id: filesId }));
        fileNameItem.splice(object, 1);
      }

      if (object2 > -1) {
        dispatch(deleteFile({ id: filesId }));
        fileNameItemSign.splice(object, 1);
      }

      if (object3 > -1) {
        dispatch(deleteFile({ id: filesId }));
        fileNameItemVaksin.splice(object, 1);
      }

      if (object4 > -1) {
        dispatch(deleteFile({ id: filesId }));
        fileNameItemKes.splice(object, 1);
      }

      if (object5 > -1) {
        dispatch(deleteFile({ id: filesId }));
        fileNameItemProfile.splice(object, 1);
      }
    },
    [
      dispatch,
      fileNameItemSign,
      fileNameItem,
      fileNameItemVaksin,
      fileNameItemKes,
      fileNameItemProfile,
    ]
  );

  const handleSubscribedAt = (date) => {
    validationClient.setFieldValue("subscribedat", date);
  };

  const handleExpirededAt = (date) => {
    validationClient.setFieldValue("expiredat", date);
  };

  useEffect(() => {
    if (isUploadFile) {
      // setTotalByte(totalbyte);
      switch (true) {
        case currentbyte <= totalbyte:
          setPercentByte(Math.ceil((currentbyte / totalbyte) * 100));
          break;
        default:
          break;
      }
    }
  }, [
    isUploadFile,
    totalbyte,
    currentbyte,
    percentbyte,
    setTotalByte,
    setPercentByte,
    setIsFileUpload,
  ]);

  useEffect(() => {
    if (percentbyte === 100 && isUploadFile) {
      const fc = [];
      const fcId = [];

      if (fileNameItem && fileNameItem.length > 0) {
        fileNameItem.forEach((itm) => {
          fc.push(itm);
          fcId.push({ file_id: itm.id });
        });
      }

      if (activeFileUpload && activeFileUpload.id) {
        fc.push({
          file_id: activeFileUpload.id,
          originalname: activeFileUpload.filename,
          filename: activeFileUpload.filejadi,
          size: activeFileUpload.filesize,
          type: "ktp",
        });
        setFileNameItem(fc);
        setKirim(true);

        fcId.push({ file_id: activeFileUpload.id });
        const o = {};
        setActiveFileUpload(o);
      }
      setTimeout(() => {
        setTotalByte(0);
        setCurrentByte(0);
        setPercentByte(0);
        setIsFileUpload(false);
      }, 3000);
    }
  }, [percentbyte, isUploadFile, fileNameItem, activeFileUpload]);

  useEffect(() => {
    if (isMediaDeleted) {
      const object = fileNameItem.findIndex(
        (obj) => obj.file_id === deletedFileId
      );
      if (object > -1) {
        fileNameItem.splice(object, 1);
      }
      const fc = fileNameItem;
      setFileNameItem(fc);
    }
  }, [isMediaDeleted, fileNameItem, deletedFileId]);

  const onEndFileUploadUser = useCallback(
    (data) => {
      const js = JSON.parse(data.data);
      setTotalByte(parseInt(js.filesize));

      // const id = js.id;
      // handleFile(id);
      setActiveFileUpload(js);
      document.querySelector("#profile-img-file-input").value = "";
    },
    [setActiveFileUpload]
  );

  const initFileUser = useCallback(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const allowedmime = [
      // "application/vnd.ms-excel",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/msword",
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/png",
    ];

    const option = {
      idinput: "profile-img-file-input",
      userId: obj.data.userId.toString(),
      clientId: obj.data.user_client ? obj.data.user_client.id.toString() : "1",
      folder: "user",
      allowedmime: allowedmime,
      onStart: onStartFileUpload,
      onEnd: onEndFileUploadUser,
      onProgress: onProgressFileUpload,
    };

    Fileserver(option);
  }, [onStartFileUpload, onEndFileUploadUser, onProgressFileUpload]);

  const toggleEditClient = useCallback(() => {
    if (modalClient) {
      setModalClient(false);
      setUserr([]);
      setLogo(defaultImg);
      setFile([]);
      setFileNameItem([]);
    } else {
      initFile();
      setModalClient(true);
    }
  }, [modalClient, initFile]);

  useEffect(() => {
    if (changeImg && kirim) {
      if (percentbyte === 100 && fileNameItem) {
        dispatch(
          onUpdateUser({
            id: user.id,
            file: JSON.stringify(fileNameItem),
          })
        );
        setChangeImg(false);
        setKirim(false);
      }
    }
  }, [
    activeFileUpload,
    dispatch,
    user,
    changeImg,
    percentbyte,
    isUploadFile,
    fileNameItem,
    kirim,
  ]);

  useEffect(() => {
    if (file[0] && percentbyte > 0) {
      handleDeleteFiles(file[0].id);
    }
  }, [file, handleDeleteFiles, percentbyte]);

  useEffect(() => {
    if (done && fileUser[0]) {
      if (fileUser[0] && percentbyte > 0) {
        handleDeleteFiles(fileUser[0].id);
        setDone(false);
      }
    }
  }, [fileUser, handleDeleteFiles, percentbyte, done]);

  const handleUserClick = useCallback(
    (arg) => {
      const userr = arg;

      if (userr.user_image) {
        let dataFile = [];
        dataFile.push({
          file_id: userr.user_image.id,
          filename: userr.user_image.filename,
          originalname: userr.user_image.originalname,
          type: "profile",
        });
        setFileNameItemProfile(dataFile);
      }

      if (userr.user_image_health) {
        let dataFile = [];
        dataFile.push({
          file_id: userr.user_image_health.id,
          filename: userr.user_image_health.filename,
          originalname: userr.user_image_health.originalname,
          type: "kes",
        });
        setFileNameItemKes(dataFile);
      }

      if (userr.user_image_ktp) {
        let dataFile = [];
        dataFile.push({
          file_id: userr.user_image_ktp.id,
          filename: userr.user_image_ktp.filename,
          originalname: userr.user_image_ktp.originalname,
          type: "ktp",
        });
        setFileNameItem(dataFile);
      }

      if (userr.user_image_signed) {
        let dataFile = [];
        dataFile.push({
          file_id: userr.user_image_signed.id,
          filename: userr.user_image_signed.filename,
          originalname: userr.user_image_signed.originalname,
          type: "ttd",
        });
        setFileNameItemSign(dataFile);
      }

      if (userr.user_image_vaksin) {
        let dataFile = [];
        dataFile.push({
          file_id: userr.user_image_vaksin.id,
          filename: userr.user_image_vaksin.filename,
          originalname: userr.user_image_vaksin.originalname,
          type: "vaksin",
        });
        setFileNameItemVaksin(dataFile);
      }

      setUserr({
        id: userr.id,
        name: userr.name,
        username: userr.username,
        pass: userr.pass,
        email: userr.email,
        phone: userr.phone,
        userType: userr.userType,
        userRole: userr.userRole,
        status: userr.status,
        description: userr.description,
        client: {
          label: user.user_client ? user.user_client.name : "",
          value: user.user_client ? user.user_client.id : "",
        },
      });

      setIsEdit(true);
      toggle();
    },
    [toggle, user]
  );

  const columns = useMemo(
    () => [
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className='hstack gap-2 mt-4 mt-sm-0'>
              <Link
                to='#'
                className='btn-icon btn btn-sm btn-soft-primary edit-item-btn'
                onClick={() => {
                  const data = cellProps.row.original;
                  handleUserClick(data);
                }}>
                <i className='ri-pencil-line fs-16'></i>
              </Link>
              <Link
                to='#'
                className='btn-icon btn btn-sm btn-soft-danger remove-item-btn'
                onClick={() => {
                  const data = cellProps.row.original;
                  onClickDelete(data);
                }}>
                <i className=' ri-delete-bin-line fs-15'></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: "Nama",
        filterable: true,
        accessor: "name",
        Cell: (user) => (
          <>
            <div className='d-flex align-items-center'>
              <div className='flex-shrink-0'>
                {user.row.original.user_image &&
                  user.row.original.user_image.filename ? (
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "/media/" +
                      user.row.original.user_image.file_folder.name +
                      "/" +
                      user.row.original.user_image.filename
                    }
                    alt=''
                    className='avatar-xs rounded-circle'
                  />
                ) : (
                  <div className='flex-shrink-0 avatar-xs me-2'>
                    <div className='avatar-title bg-soft-success text-success rounded-circle fs-13'>
                      {user.row.original.username.charAt(0).toUpperCase()}
                    </div>
                  </div>
                )}
              </div>
              <div className='flex-grow-1 ms-2 name'>
                {user.row.original.username}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
      },
      {
        Header: "Username",
        accessor: "username",
        filterable: true,
      },
      {
        Header: "User Type",
        accessor: "userType",
        filterable: true,
      },
      {
        Header: "UserRole",
        accessor: "userRole",
        filterable: true,
      },
      {
        Header: `${t("Status")}`,
        accessor: "user_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "Activated":
              return (
                <span className='badge text-uppercase badge-soft-secondary'>
                  {t(cell.value)}
                </span>
              );
            case "Deactivated":
              return (
                <span className='badge text-uppercase badge-soft-danger'>
                  {t("cell.value")}
                </span>
              );
            case "Draft":
              return (
                <span className='badge text-uppercase badge-soft-warning'>
                  {t("cell.value")}
                </span>
              );
            default:
              return (
                <span className='badge text-uppercase badge-soft-warning'>
                  {t("cell.value")}
                </span>
              );
          }
        },
      },
    ],
    [handleUserClick, t]
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (userr && userr.name) || "",
      username: (userr && userr.username) || "",
      pass: (userr && userr.pass) || "",
      email: (userr && userr.email) || "",
      phone: (userr && userr.phone) || "",
      client: (userr && userr.client) || "",
      userType: (userr && userr.userType) || "internal",
      userRole: (userr && userr.userRole) || "administrator",
      status: (userr && userr.status) || "1",
      description: (userr && userr.description) || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Username").nullable(true),
      pass: Yup.string().required("Please Enter Password").nullable(true),
    }),
    onSubmit: (values) => {
      dispatch(getUserLoading());
      if (isEdit) {
        const updateUser = {
          id: userr ? userr.id : 0,
          name: values.name,
          username: values.username,
          pass: values.pass,
          email: values.email,
          phone: values.phone,
          clientId: values.client.value,
          userType: values.userType,
          userRole: values.userRole,
          statusId: values.status,
          description: values.description,
          file: [
            ...fileNameItem,
            ...fileNameItemSign,
            ...fileNameItemVaksin,
            ...fileNameItemKes,
            ...fileNameItemProfile,
          ],
        };

        dispatch(onUpdateUser(updateUser));
        console.log("PATCH", updateUser);
      } else {
        const createUser = {
          name: values.name,
          username: values.username,
          pass: values.pass,
          email: values.email,
          phone: values.phone,
          clientId: values.client.value,
          userType: values.userType,
          userRole: values.userRole,
          statusId: values.status,
          description: values.description,
          file: [
            ...fileNameItem,
            ...fileNameItemSign,
            ...fileNameItemVaksin,
            ...fileNameItemKes,
            ...fileNameItemProfile,
          ],
        };
        dispatch(onAddNewUser(createUser));
      }
      validation.resetForm();
      toggle();
    },
  });

  const validationClient = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (dataClient && dataClient.name) || "",
      subscribedat: (dataClient && dataClient.subscribedat) || "",
      expiredat: (dataClient && dataClient.expiredat) || "",
      status: (dataClient && dataClient.status) || "",
      description: (dataClient && dataClient.description) || "",
      address: (dataClient && dataClient.address) || "",
      file: (dataClient && dataClient.file) || null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Client Name"),
      subscribedat: Yup.string().required("Please Enter Subscription Date"),
      expiredat: Yup.string().required("Please Enter Expired Date"),
      status: Yup.object().required("Please Enter Status"),
    }),
    onSubmit: (values) => {
      dispatch(getClientLoading());
      if (isEdit) {
        const editClient = {
          id: user.user_client.id ? user.user_client.id : 0,
          name: values.name,
          subscribedat: values.subscribedat,
          expiredat: values.expiredat,
          description: values.description,
          statusId: values.status.value,
          file: JSON.stringify(fileNameItem),
          address: values.address,
        };
        dispatch(onUpdateClient(editClient));
        validationClient.resetForm();
        toggleEditClient();
      }
    },
  });

  useEffect(() => {
    console.log(userList);
  }, [userList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: userList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 8,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const count = preGlobalFilteredRows.length;

  const handleValidTime = (time) => {
    return moment(time, "YYYY/MM/DD HH:mm").tz("Asia/Jakarta").format("HH:mm");
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  //

  document.title = "Profile| TELKOMSEL - IOT Monitoring";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col lg={12} className='mt-0'>
              <div>
                <Row>
                  <Col xxl={3}>
                    <Card>
                      <CardBody className='p-4'>
                        <div className='text-center'>
                          <div className='profile-user position-relative d-inline-block mx-auto  mb-4'>
                            <img
                              src={userImage}
                              alt=''
                              className='rounded-circle avatar-xl img-thumbnail user-profile-image'
                            />
                            <div className='avatar-xs p-0 rounded-circle profile-photo-edit'>
                              <Input
                                id='profile-img-file-input'
                                type='file'
                                className='profile-img-file-input'
                                onClick={() => {
                                  initFileUser();
                                  setChangeImg(true);
                                }}
                              />
                              <Label
                                htmlFor='profile-img-file-input'
                                className='profile-photo-edit avatar-xs'>
                                <span className='avatar-title rounded-circle bg-light text-body'>
                                  <i className='ri-camera-fill'></i>
                                </span>
                              </Label>
                            </div>
                          </div>
                          <h4 className='mb-1'>Username : {user.username}</h4>
                          <h4 className='mb-1'>Email : {user.email}</h4>
                          <h4 className='mb-1'>Phone Number : {user.phone}</h4>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <div className='align-item-right mb-5'>
                          <button
                            type='button'
                            className='btn btn-success edit-btn'
                            id='edit-btn'
                            onClick={() => {
                              setUserr("");
                              setIsEdit(true);
                              toggleEditClient();
                            }}>
                            <i className='ri-group-line me-1 align-bottom fs-14'></i>{" "}
                            Edit Client
                          </button>{" "}
                        </div>
                        <h5 className='card-title mb-3'>Info Client</h5>
                        <div className='table-responsive'>
                          <Table className='table-borderless mb-0'>
                            <tbody>
                              <tr>
                                <th className='ps-0' scope='row'>
                                  Full Name :
                                </th>
                                <td className='text-muted'>
                                  {userName ? userName : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th className='ps-0' scope='row'>
                                  Subscribe At :
                                </th>
                                <td className='text-muted'>
                                  {subscribeAt ? subscribeAt : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th className='ps-0' scope='row'>
                                  Expired At :
                                </th>
                                <td className='text-muted'>
                                  {expiredAt ? expiredAt : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th className='ps-0' scope='row'>
                                  Address :
                                </th>
                                <td className='text-muted'>
                                  {addressName +
                                    "," +
                                    addressLabel +
                                    "," +
                                    addressCity +
                                    "-" +
                                    addressProv}
                                </td>
                              </tr>
                              <tr>
                                <th className='ps-0' scope='row'>
                                  Status :
                                </th>
                                <td className='text-muted'>
                                  {statusClient ? statusClient : "-"}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl={9}>
                    <div className='d-flex mt-4 mb-4'>
                      <Nav
                        pills
                        className='animation-nav text-primary gap-2 gap-lg-3 flex-grow-1'
                        role='tablist'>
                        <NavItem>
                          <NavLink
                            href='#projects'
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("3");
                            }}>
                            <i className='ri-price-tag-line d-inline-block d-md-none'></i>{" "}
                            <span className='d-none d-md-inline-block'>
                              Users
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <div className='flex-shrink-0'>
                        <button
                          type='button'
                          className='btn btn-primary add-btn'
                          id='create-btn'
                          onClick={() => {
                            setUserr("");
                            setIsEdit(false);
                            toggle();
                          }}>
                          <i className='ri-group-line me-1 align-bottom fs-14'></i>{" "}
                          Add New User
                        </button>{" "}
                      </div>
                    </div>
                    <TabContent activeTab={activeTab} className='pt-0'>
                      <TabPane tabId='1'>
                        <Card>
                          <CardBody>
                            <div>
                              <Nav
                                className='nav-tabs nav-tabs-custom nav-success'
                                role='tablist'>
                                <NavItem>
                                  <NavLink
                                    className={classnames(
                                      { active: activeTabTask === "1" },
                                      "fw-semibold"
                                    )}
                                    onClick={() => {
                                      toggleTabTask("1", "all");
                                    }}
                                    href='#'>
                                    <i className='ri-todo-line me-1 align-bottom fs-14'></i>{" "}
                                    All User
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={classnames(
                                      { active: activeTabTask === "2" },
                                      "fw-semibold"
                                    )}
                                    onClick={() => {
                                      toggleTabTask("2", "Draft");
                                    }}
                                    href='#'>
                                    <i className='ri-time-line me-1 align-bottom fs-14'></i>{" "}
                                    Draft
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={classnames(
                                      { active: activeTabTask === "3" },
                                      "fw-semibold"
                                    )}
                                    onClick={() => {
                                      toggleTabTask("3", "Activated");
                                    }}
                                    href='#'>
                                    <i className='ri-time-line me-1 align-bottom fs-14'></i>{" "}
                                    Activated
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={classnames(
                                      { active: activeTabTask === "4" },
                                      "fw-semibold"
                                    )}
                                    onClick={() => {
                                      toggleTabTask("4", "Deactivated");
                                    }}
                                    href='#'>
                                    <i className='ri-indeterminate-circle-line me-1 align-bottom fs-14'></i>{" "}
                                    Deactivated
                                  </NavLink>
                                </NavItem>
                              </Nav>
                              <Row className='mb-3'>
                                <CardBody className='border border-top-0 border-end-0 border-start-0'>
                                  <form>
                                    <Row>
                                      <Col sm={1} className='col-xxl-1'>
                                        <div className='me-2 mb-2 col-12'>
                                          <select
                                            className='form-select'
                                            value={pageSize}
                                            onChange={onChangeInSelect}>
                                            {[10, 20, 30, 40, 50].map(
                                              (pageSize) => (
                                                <option
                                                  key={pageSize}
                                                  value={pageSize}>
                                                  {pageSize}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </Col>

                                      <Col sm={4}>
                                        <div className='search-box me-2 mb-2 d-inline-block col-12'>
                                          <input
                                            id='search-bar-0'
                                            type='text'
                                            className='form-control search'
                                            onChange={(e) => {
                                              setSearchValue(e.target.value);
                                              onChange(e.target.value);
                                            }}
                                            placeholder={`${count} Search...`}
                                            value={searchValue || ""}
                                          />{" "}
                                          <i className='bx bx-search-alt search-icon'></i>
                                        </div>
                                      </Col>
                                      <Col sm={6} className='col-xxl-3'>
                                        <div className='search-box me-2 mb-2 col-12'>
                                          <Flatpickr
                                            className='form-control'
                                            id='datepicker-publish-input'
                                            placeholder='Select due date'
                                            options={{
                                              mode: "range",
                                              dateFormat: "Y/m/d",
                                            }}
                                            onChange={(obj, str) => {
                                              setFilterDate(str);
                                            }}
                                          />
                                          <i className='bx bx-calendar-alt search-icon'></i>
                                        </div>
                                      </Col>
                                      <Col sm={4} className='col-xxl-2'>
                                        <div className='me-2 mb-2 col-12'>
                                          <Select
                                            value={statusActive}
                                            placeholder='Select Status'
                                            onChange={(e) => {
                                              setStatusActive(e);
                                            }}
                                            options={statusActiveData}
                                            name='choices-single-default'
                                            id='idStatus'></Select>
                                        </div>
                                      </Col>
                                      <Col className='col-xxl-2'>
                                        <div>
                                          <button
                                            type='button'
                                            className='btn btn-light w-100'
                                            onClick={() => {
                                              handleFilter();
                                            }}>
                                            <i className='ri-search-line me-1 align-bottom fs-14'></i>{" "}
                                            Search
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </form>
                                </CardBody>
                              </Row>
                              <div className='table-responsive table-card mb-1'>
                                <Table
                                  hover
                                  {...getTableProps()}
                                  className='align-middle table-nowrap'>
                                  <thead className='table-light text-muted'>
                                    {headerGroups.map((headerGroup) => (
                                      <tr
                                        className={""}
                                        key={headerGroup.id}
                                        {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                          <th
                                            key={column.id}
                                            className={
                                              "" + generateFilterable(column)
                                            }
                                            {...column.getSortByToggleProps()}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                            {/* <Filter column={column} /> */}
                                          </th>
                                        ))}
                                      </tr>
                                    ))}
                                  </thead>

                                  <tbody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                      prepareRow(row);
                                      return (
                                        <Fragment key={row.getRowProps().key}>
                                          <tr>
                                            {row.cells.map((cell) => {
                                              return (
                                                <td
                                                  key={cell.id}
                                                  {...cell.getCellProps()}>
                                                  {cell.render("Cell")}
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        </Fragment>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>

                              <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                                <Col className='col-md-auto'>
                                  <div className='d-flex gap-1'>
                                    <Button
                                      color='light'
                                      onClick={previousPage}
                                      disabled={!canPreviousPage}>
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className='col-md-auto d-none d-md-block'>
                                  Page
                                  <strong>
                                    {pageIndex + 1} of {pageOptions.length}{" "}
                                  </strong>
                                </Col>
                                <Col className='col-md-auto'>
                                  <Input
                                    type='number'
                                    min={1}
                                    style={{ width: 70 }}
                                    max={pageOptions.length}
                                    defaultValue={pageIndex + 1}
                                    onChange={onChangeInInput}
                                  />
                                </Col>
                                <Col className='col-md-auto'>
                                  <div className='d-flex gap-1'>
                                    <Button
                                      color='light'
                                      onClick={nextPage}
                                      disabled={!canNextPage}>
                                      {">"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Modal
                              id='showModal'
                              isOpen={modal}
                              centered
                              size='lg'>
                              <ModalHeader
                                className='bg-light p-3'
                                toggle={toggle}>
                                {!!isEdit ? "Edit User" : "Add User"}
                              </ModalHeader>
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  validation.handleSubmit();
                                  return false;
                                }}>
                                <ModalBody>
                                  <div className='row'>
                                    <div className='col-lg-6'>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='username-field'
                                          className='form-label'>
                                          {" "}
                                          Name{" "}
                                        </Label>
                                        <Input
                                          name='name'
                                          id='username-field'
                                          className='form-control'
                                          placeholder='Enter Name'
                                          type='text'
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.name || ""}
                                          invalid={
                                            validation.touched.name &&
                                              validation.errors.name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.name &&
                                          validation.errors.name ? (
                                          <FormFeedback type='invalid'>
                                            {validation.errors.name}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='emaol-field'
                                          className='form-label'>
                                          {" "}
                                          Email{" "}
                                        </Label>
                                        <Input
                                          name='email'
                                          id='email-field'
                                          className='form-control'
                                          placeholder='Enter Email'
                                          type='email'
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.email || ""}
                                          invalid={
                                            validation.touched.email &&
                                              validation.errors.email
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.email &&
                                          validation.errors.email ? (
                                          <FormFeedback type='invalid'>
                                            {validation.errors.email}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='phone-field'
                                          className='form-label'>
                                          {" "}
                                          Phone{" "}
                                        </Label>
                                        <Input
                                          name='phone'
                                          id='phone-field'
                                          className='form-control'
                                          placeholder='Enter phone'
                                          type='text'
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.phone || ""}
                                          invalid={
                                            validation.touched.phone &&
                                              validation.errors.phone
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.phone &&
                                          validation.errors.phone ? (
                                          <FormFeedback type='invalid'>
                                            {validation.errors.phone}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='username-field'
                                          className='form-label'>
                                          {" "}
                                          Username{" "}
                                        </Label>
                                        <Input
                                          name='username'
                                          id='username-field'
                                          className='form-control'
                                          placeholder='Enter username'
                                          type='text'
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.username || ""
                                          }
                                          invalid={
                                            validation.touched.username &&
                                              validation.errors.username
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.username &&
                                          validation.errors.username ? (
                                          <FormFeedback type='invalid'>
                                            {validation.errors.username}
                                          </FormFeedback>
                                        ) : null}
                                      </div>

                                      {isEdit ? (
                                        <div className='mb-3'>
                                          <Label
                                            htmlFor='password-field'
                                            className='form-label d-none'>
                                            {" "}
                                            Password{" "}
                                          </Label>
                                          <Input
                                            name='pass'
                                            id='pass-field'
                                            className='form-control d-none'
                                            placeholder='Enter pass'
                                            type='password'
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.pass || ""}
                                            invalid={
                                              validation.touched.pass &&
                                                validation.errors.pass
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched.pass &&
                                            validation.errors.pass ? (
                                            <FormFeedback type='invalid'>
                                              {validation.errors.pass}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      ) : (
                                        <div className='mb-3'>
                                          <Label
                                            htmlFor='password-field'
                                            className='form-label'>
                                            {" "}
                                            Password{" "}
                                          </Label>
                                          <Input
                                            name='pass'
                                            id='pass-field'
                                            className='form-control'
                                            placeholder='Enter pass'
                                            type='password'
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.pass || ""}
                                            invalid={
                                              validation.touched.pass &&
                                                validation.errors.pass
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched.pass &&
                                            validation.errors.pass ? (
                                            <FormFeedback type='invalid'>
                                              {validation.errors.pass}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      )}
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='password-field'
                                          className='form-label'>
                                          {" "}
                                          Client{" "}
                                        </Label>
                                        <Select
                                          name='parent'
                                          value={validation.values.client}
                                          placeholder='Enter Client'
                                          isDisabled={
                                            type === "client" ? true : false
                                          }
                                          onChange={(e) => {
                                            validation.setFieldValue(
                                              "client",
                                              e
                                            );
                                          }}
                                          options={clientList}></Select>
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='active-status'
                                          className='form-label'>
                                          User Type
                                        </Label>
                                        <Input
                                          name='userType'
                                          type='select'
                                          className='form-select'
                                          onChange={(e) => {
                                            validation.setFieldValue(
                                              "userType",
                                              e.target.value
                                            );
                                          }}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.userType}>
                                          {userTypes.map((item, key) => (
                                            <React.Fragment key={key}>
                                              {item.options.map((item, key) => (
                                                <option
                                                  value={item.value}
                                                  key={key}>
                                                  {item.label}
                                                </option>
                                              ))}
                                            </React.Fragment>
                                          ))}
                                        </Input>
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='active-status'
                                          className='form-label'>
                                          User Role
                                        </Label>
                                        <Input
                                          name='userRole'
                                          type='select'
                                          className='form-select'
                                          onChange={(e) => {
                                            validation.setFieldValue(
                                              "userRole",
                                              e.target.value
                                            );
                                          }}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.userRole}>
                                          {userRole.map((item, key) => (
                                            <React.Fragment key={key}>
                                              {item.options.map((item, key) => (
                                                <option
                                                  value={item.value}
                                                  key={key}>
                                                  {item.label}
                                                </option>
                                              ))}
                                            </React.Fragment>
                                          ))}
                                        </Input>
                                      </div>
                                    </div>
                                    <div className='col-lg-6'>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='active-status'
                                          className='form-label'>
                                          Status
                                        </Label>
                                        <Input
                                          name='status'
                                          type='select'
                                          className='form-select'
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.status || "1"
                                          }>
                                          {statusActiveData.map((item, key) => (
                                            <React.Fragment key={key}>
                                              {item.options.map((item, key) => (
                                                <option
                                                  value={item.value}
                                                  key={key}>
                                                  {item.label}
                                                </option>
                                              ))}
                                            </React.Fragment>
                                          ))}
                                        </Input>
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='des-field'
                                          className='form-label'>
                                          Keterangan
                                        </Label>
                                        <Input
                                          name='description'
                                          id='des-field'
                                          className='form-control'
                                          placeholder='Enter Description'
                                          type='textarea'
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.description || ""
                                          }
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='clientname-field'
                                          className='form-label'>
                                          KTP
                                        </Label>
                                        <input
                                          type='file'
                                          id='fUpload'
                                          className='form-control'
                                        />
                                        <div className='mb-3 pt-2 v-stack gap-2'>
                                          <Progress
                                            id='progress'
                                            name='progress'
                                            value={percentbyte}
                                            color='info'
                                            className='mt-3 bg-soft-info progress-sm rounded-0 animated-progress custom-progress progress-label'>
                                            <div className='label'>{`${percentbyte}%`}</div>
                                          </Progress>
                                          <div className='mt-2 mb-3 d-flex flex-grow-1'>
                                            <span className='fs-6'>
                                              {/* <strong className="text-secondary">{percentbyte > 0 ? percentbyte+"%" : ""}</strong> */}
                                              {percentbyte === 0
                                                ? ""
                                                : percentbyte === 100
                                                  ? " Finish upload"
                                                  : " Upload in progress..."}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='mb-3'>
                                          <ListGroup>
                                            {fileNameItem &&
                                              fileNameItem.map((f, i) => {
                                                return (
                                                  <Card body key={i}>
                                                    <div className='d-flex'>
                                                      <div className='flex-shrink-0'>
                                                        <CardTitle tag='h5'>
                                                          <img
                                                            src={image}
                                                            alt=''
                                                            className='avatar-xs rounded-circle'
                                                          />
                                                        </CardTitle>
                                                      </div>
                                                      <div className='flex-grow-1'>
                                                        <CardText className='text-center mt-1'>
                                                          {f.originalname}
                                                        </CardText>
                                                      </div>
                                                      <div className='flex-shrink-1 ms-5'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-sm btn-icon btn-danger'
                                                          onClick={(e) => {
                                                            handleDeleteFiles(
                                                              f.file_id
                                                            );
                                                            return false;
                                                          }}>
                                                          <i className='ri-delete-bin-7-line'></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </Card>
                                                );
                                              })}
                                          </ListGroup>
                                        </div>
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='clientname-field'
                                          className='form-label'>
                                          TTD
                                        </Label>
                                        <input
                                          type='file'
                                          id='fUploadSign'
                                          className='form-control'
                                        />
                                        <div className='mb-3 pt-2 v-stack gap-2'>
                                          <Progress
                                            id='progress'
                                            name='progress'
                                            value={percentbyteSign}
                                            color='info'
                                            className='mt-3 bg-soft-info progress-sm rounded-0 animated-progress custom-progress progress-label'>
                                            <div className='label'>{`${percentbyteSign}%`}</div>
                                          </Progress>
                                          <div className='mt-2 mb-3 d-flex flex-grow-1'>
                                            <span className='fs-6'>
                                              {/* <strong className="text-secondary">{percentbyte > 0 ? percentbyte+"%" : ""}</strong> */}
                                              {percentbyteSign === 0
                                                ? ""
                                                : percentbyteSign === 100
                                                  ? " Finish upload"
                                                  : " Upload in progress..."}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='mb-3'>
                                          <ListGroup>
                                            {fileNameItemSign &&
                                              fileNameItemSign.map((f, i) => {
                                                return (
                                                  <Card body key={i}>
                                                    <div className='d-flex'>
                                                      <div className='flex-shrink-0'>
                                                        <CardTitle tag='h5'>
                                                          <img
                                                            src={image}
                                                            alt=''
                                                            className='avatar-xs rounded-circle'
                                                          />
                                                        </CardTitle>
                                                      </div>
                                                      <div className='flex-grow-1'>
                                                        <CardText className='text-center mt-1'>
                                                          {f.originalname}
                                                        </CardText>
                                                      </div>
                                                      <div className='flex-shrink-1 ms-5'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-sm btn-icon btn-danger'
                                                          onClick={(e) => {
                                                            handleDeleteFiles(
                                                              f.file_id
                                                            );
                                                            return false;
                                                          }}>
                                                          <i className='ri-delete-bin-7-line'></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </Card>
                                                );
                                              })}
                                          </ListGroup>
                                        </div>
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='clientname-field'
                                          className='form-label'>
                                          Vaksin
                                        </Label>
                                        <input
                                          type='file'
                                          id='fUploadVaksin'
                                          className='form-control'
                                        />
                                        <div className='mb-3 pt-2 v-stack gap-2'>
                                          <Progress
                                            id='progress'
                                            name='progress'
                                            value={percentbyteVaksin}
                                            color='info'
                                            className='mt-3 bg-soft-info progress-sm rounded-0 animated-progress custom-progress progress-label'>
                                            <div className='label'>{`${percentbyteVaksin}%`}</div>
                                          </Progress>
                                          <div className='mt-2 mb-3 d-flex flex-grow-1'>
                                            <span className='fs-6'>
                                              {/* <strong className="text-secondary">{percentbyte > 0 ? percentbyte+"%" : ""}</strong> */}
                                              {percentbyteVaksin === 0
                                                ? ""
                                                : percentbyteVaksin === 100
                                                  ? " Finish upload"
                                                  : " Upload in progress..."}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='mb-3'>
                                          <ListGroup>
                                            {fileNameItemVaksin &&
                                              fileNameItemVaksin.map((f, i) => {
                                                return (
                                                  <Card body key={i}>
                                                    <div className='d-flex'>
                                                      <div className='flex-shrink-0'>
                                                        <CardTitle tag='h5'>
                                                          <img
                                                            src={image}
                                                            alt=''
                                                            className='avatar-xs rounded-circle'
                                                          />
                                                        </CardTitle>
                                                      </div>
                                                      <div className='flex-grow-1'>
                                                        <CardText className='text-center mt-1'>
                                                          {f.originalname}
                                                        </CardText>
                                                      </div>
                                                      <div className='flex-shrink-1 ms-5'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-sm btn-icon btn-danger'
                                                          onClick={(e) => {
                                                            handleDeleteFiles(
                                                              f.file_id
                                                            );
                                                            return false;
                                                          }}>
                                                          <i className='ri-delete-bin-7-line'></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </Card>
                                                );
                                              })}
                                          </ListGroup>
                                        </div>
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='clientname-field'
                                          className='form-label'>
                                          Kesehatan
                                        </Label>
                                        <input
                                          type='file'
                                          id='fUploadKes'
                                          className='form-control'
                                        />
                                        <div className='mb-3 pt-2 v-stack gap-2'>
                                          <Progress
                                            id='progress'
                                            name='progress'
                                            value={percentbyteKes}
                                            color='info'
                                            className='mt-3 bg-soft-info progress-sm rounded-0 animated-progress custom-progress progress-label'>
                                            <div className='label'>{`${percentbyteKes}%`}</div>
                                          </Progress>
                                          <div className='mt-2 mb-3 d-flex flex-grow-1'>
                                            <span className='fs-6'>
                                              {/* <strong className="text-secondary">{percentbyte > 0 ? percentbyte+"%" : ""}</strong> */}
                                              {percentbyteKes === 0
                                                ? ""
                                                : percentbyteKes === 100
                                                  ? " Finish upload"
                                                  : " Upload in progress..."}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='mb-3'>
                                          <ListGroup>
                                            {fileNameItemKes &&
                                              fileNameItemKes.map((f, i) => {
                                                return (
                                                  <Card body key={i}>
                                                    <div className='d-flex'>
                                                      <div className='flex-shrink-0'>
                                                        <CardTitle tag='h5'>
                                                          <img
                                                            src={image}
                                                            alt=''
                                                            className='avatar-xs rounded-circle'
                                                          />
                                                        </CardTitle>
                                                      </div>
                                                      <div className='flex-grow-1'>
                                                        <CardText className='text-center mt-1'>
                                                          {f.originalname}
                                                        </CardText>
                                                      </div>
                                                      <div className='flex-shrink-1 ms-5'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-sm btn-icon btn-danger'
                                                          onClick={(e) => {
                                                            handleDeleteFiles(
                                                              f.file_id
                                                            );
                                                            return false;
                                                          }}>
                                                          <i className='ri-delete-bin-7-line'></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </Card>
                                                );
                                              })}
                                          </ListGroup>
                                        </div>
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='clientname-field'
                                          className='form-label'>
                                          Profile
                                        </Label>
                                        <input
                                          type='file'
                                          id='fUploadProfile'
                                          className='form-control'
                                        />
                                        <div className='mb-3 pt-2 v-stack gap-2'>
                                          <Progress
                                            id='progress'
                                            name='progress'
                                            value={percentbyteProfile}
                                            color='info'
                                            className='mt-3 bg-soft-info progress-sm rounded-0 animated-progress custom-progress progress-label'>
                                            <div className='label'>{`${percentbyteProfile}%`}</div>
                                          </Progress>
                                          <div className='mt-2 mb-3 d-flex flex-grow-1'>
                                            <span className='fs-6'>
                                              {/* <strong className="text-secondary">{percentbyte > 0 ? percentbyte+"%" : ""}</strong> */}
                                              {percentbyteProfile === 0
                                                ? ""
                                                : percentbyteProfile === 100
                                                  ? " Finish upload"
                                                  : " Upload in progress..."}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='mb-3'>
                                          <ListGroup>
                                            {fileNameItemProfile &&
                                              fileNameItemProfile.map(
                                                (f, i) => {
                                                  return (
                                                    <Card body key={i}>
                                                      <div className='d-flex'>
                                                        <div className='flex-shrink-0'>
                                                          <CardTitle tag='h5'>
                                                            <img
                                                              src={image}
                                                              alt=''
                                                              className='avatar-xs rounded-circle'
                                                            />
                                                          </CardTitle>
                                                        </div>
                                                        <div className='flex-grow-1'>
                                                          <CardText className='text-center mt-1'>
                                                            {f.originalname}
                                                          </CardText>
                                                        </div>
                                                        <div className='flex-shrink-1 ms-5'>
                                                          <button
                                                            type='button'
                                                            className='btn btn-sm btn-icon btn-danger'
                                                            onClick={(e) => {
                                                              handleDeleteFiles(
                                                                f.file_id
                                                              );
                                                              return false;
                                                            }}>
                                                            <i className='ri-delete-bin-7-line'></i>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </Card>
                                                  );
                                                }
                                              )}
                                          </ListGroup>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ModalBody>
                                <div className='modal-footer'>
                                  <div className='hstack gap-2 justify-content-end'>
                                    <button
                                      type='button'
                                      className='btn btn-light'
                                      onClick={() => {
                                        setModal(false);
                                        validation.resetForm();
                                        setFileNameItem([]);
                                        setFileNameItemSign([]);
                                        setFileNameItemVaksin([]);
                                        setFileNameItemKes([]);
                                        setFileNameItemProfile([]);
                                      }}>
                                      {" "}
                                      Close{" "}
                                    </button>
                                    <button
                                      type='submit'
                                      className='btn btn-success'
                                      disabled={loading}>
                                      <span className='d-flex align-items-center'>
                                        <span className='flex-grow-1 me-2'>
                                          {!!isEdit ? "Update" : "Add User"}
                                        </span>
                                        {loading ? (
                                          <Spinner
                                            size='sm'
                                            className='flex-shrink-0'
                                            role='status'>
                                            Loading...
                                          </Spinner>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Modal>
                            <Modal
                              id='showModal'
                              isOpen={modalClient}
                              centered
                              fullscreen={false}
                              size='xl'>
                              <ModalHeader
                                className='bg-light p-3'
                                toggle={toggleEditClient}>
                                {!!isEdit ? "Edit Client" : "Tambah Client"}
                              </ModalHeader>
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  validationClient.handleSubmit();
                                  return false;
                                }}>
                                <ModalBody>
                                  <Row>
                                    <Col lg={6}>
                                      <div className='col-lg-12 mb-3'>
                                        <Label
                                          htmlFor='clientname-field'
                                          className='form-label'>
                                          {" "}
                                          Logo Client
                                        </Label>
                                        <input
                                          type='file'
                                          id='fUpload'
                                          className='form-control'
                                        />
                                        <div className='mb-3 pt-2 v-stack gap-2'>
                                          <Progress
                                            id='progress'
                                            name='progress'
                                            value={percentbyte}
                                            color='info'
                                            className='mt-3 bg-soft-info progress-sm rounded-0 animated-progress custom-progress progress-label'>
                                            <div className='label'>{`${percentbyte}%`}</div>
                                          </Progress>
                                          <div className='mt-2 mb-3 d-flex flex-grow-1'>
                                            <span className='fs-6'>
                                              {/* <strong className="text-secondary">{percentbyte > 0 ? percentbyte+"%" : ""}</strong> */}
                                              {percentbyte === 0
                                                ? ""
                                                : percentbyte === 100
                                                  ? " Finish upload"
                                                  : " Upload in progress..."}
                                            </span>
                                          </div>
                                        </div>
                                        <div className='mb-3'>
                                          <ListGroup>
                                            {fileNameItem &&
                                              fileNameItem.map((f, i) => {
                                                return (
                                                  <Card body key={i}>
                                                    <div className='d-flex'>
                                                      <div className='flex-shrink-0'>
                                                        <CardTitle tag='h5'>
                                                          <img
                                                            src={logo}
                                                            alt=''
                                                            className='avatar-xs rounded-circle'
                                                          />
                                                        </CardTitle>
                                                      </div>
                                                      <div className='flex-grow-1'>
                                                        <CardText className='text-center mt-1'>
                                                          {f.originalname}
                                                        </CardText>
                                                      </div>
                                                      <div className='flex-shrink-0'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-sm btn-icon btn-danger'
                                                          onClick={(e) => {
                                                            handleDeleteFiles(
                                                              f.file_id
                                                            );
                                                            return false;
                                                          }}>
                                                          <i className='ri-delete-bin-7-line'></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </Card>
                                                );
                                              })}
                                            {file &&
                                              file.map((f, i) => {
                                                return (
                                                  <Card body key={i}>
                                                    <div className='d-flex'>
                                                      <div className='flex-shrink-0'>
                                                        <CardTitle tag='h5'>
                                                          <img
                                                            src={logo}
                                                            alt=''
                                                            className='avatar-xs rounded-circle'
                                                          />
                                                        </CardTitle>
                                                      </div>
                                                      <div className='flex-grow-1'>
                                                        <CardText className='text-center mt-1'>
                                                          {f.originalname}
                                                        </CardText>
                                                      </div>
                                                      <div className='flex-shrink-1'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-sm btn-icon btn-danger'
                                                          onClick={(e) => {
                                                            handleDeleteFiles(
                                                              f.id
                                                            );
                                                            return false;
                                                          }}>
                                                          <i className='ri-delete-bin-7-line'></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </Card>
                                                );
                                              })}
                                          </ListGroup>
                                        </div>
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='clientname-field'
                                          className='form-label'>
                                          Client Nam
                                        </Label>
                                        <Input
                                          name='name'
                                          id='clientname-field'
                                          className='form-control'
                                          placeholder='Enter Name'
                                          type='text'
                                          validate={{
                                            required: { value: true },
                                          }}
                                          onChange={
                                            validationClient.handleChange
                                          }
                                          onBlur={validationClient.handleBlur}
                                          value={
                                            validationClient.values.name || ""
                                          }
                                          invalid={
                                            validationClient.touched.name &&
                                              validationClient.errors.name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationClient.touched.name &&
                                          validationClient.errors.name ? (
                                          <FormFeedback type='invalid'>
                                            {validationClient.errors.name}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='address-field'
                                          className='form-label'>
                                          Address Detail
                                        </Label>
                                        <Input
                                          name='address'
                                          id='address-field'
                                          className='form-control'
                                          placeholder='Enter Street Name'
                                          type='textarea'
                                          onChange={
                                            validationClient.handleChange
                                          }
                                          onBlur={validationClient.handleBlur}
                                          value={
                                            validationClient.values.address
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='date-field'
                                          className='form-label'>
                                          Subscribed At
                                        </Label>
                                        <Flatpickr
                                          name='date-picker'
                                          className='form-control'
                                          placeholder='Select Tanggal Berlangganan'
                                          options={{
                                            enableTime: true,
                                            time_24hr: true,
                                            dateFormat: "Y/m/d H:i",
                                          }}
                                          value={
                                            validationClient.values
                                              .subscribedat || ""
                                          }
                                          onChange={(o, e) => {
                                            handleSubscribedAt(e);
                                          }}
                                        />
                                        <Input
                                          name='subscribedat'
                                          id='subscribedat'
                                          type='hidden'
                                          validate={{
                                            required: { value: true },
                                          }}
                                          onChange={
                                            validationClient.handleChange
                                          }
                                          invalid={
                                            validationClient.touched
                                              .subscribedat &&
                                              validationClient.errors.subscribedat
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationClient.touched
                                          .subscribedat &&
                                          validationClient.errors.subscribedat ? (
                                          <FormFeedback type='invalid'>
                                            {
                                              validationClient.errors
                                                .subscribedat
                                            }
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='date-field'
                                          className='form-label'>
                                          Expireded At
                                        </Label>
                                        <Flatpickr
                                          name='date-picker2'
                                          className='form-control'
                                          placeholder='Select Tanggal kadaluarsa'
                                          options={{
                                            enableTime: true,
                                            time_24hr: true,
                                            dateFormat: "Y/m/d H:i",
                                          }}
                                          value={
                                            validationClient.values.expiredat ||
                                            ""
                                          }
                                          onChange={(o, e) => {
                                            handleExpirededAt(e);
                                          }}
                                        />
                                        <Input
                                          name='expiredat'
                                          id='expiredat'
                                          type='hidden'
                                          validate={{
                                            required: { value: true },
                                          }}
                                          onChange={
                                            validationClient.handleChange
                                          }
                                          invalid={
                                            validationClient.touched
                                              .expiredat &&
                                              validationClient.errors.expiredat
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationClient.touched.expiredat &&
                                          validationClient.errors.expiredat ? (
                                          <FormFeedback type='invalid'>
                                            {validationClient.errors.expiredat}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='des-field'
                                          className='form-label'>
                                          Description
                                        </Label>
                                        <Input
                                          name='description'
                                          id='des-field'
                                          className='form-control'
                                          placeholder='Enter Description'
                                          type='textarea'
                                          onChange={
                                            validationClient.handleChange
                                          }
                                          onBlur={validationClient.handleBlur}
                                          value={
                                            validationClient.values
                                              .description || ""
                                          }
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <Label
                                          htmlFor='active-status'
                                          className='form-label'>
                                          Status
                                        </Label>
                                        <Select
                                          name='status'
                                          value={validationClient.values.status}
                                          validate={{
                                            required: { value: true },
                                          }}
                                          placeholder='Select Status'
                                          onChange={(e) => {
                                            validationClient.setFieldValue(
                                              "status",
                                              e
                                            );
                                          }}
                                          onInputChange={(e) => {
                                            // handleUnit(e);
                                          }}
                                          options={statusActiveData}
                                          invalid={
                                            validationClient.touched.status &&
                                              validationClient.errors.status
                                              ? true
                                              : false
                                          }></Select>
                                        {validationClient.touched.status &&
                                          validationClient.errors.status ? (
                                          <p
                                            className='text-danger'
                                            type='invalid'>
                                            {validationClient.errors.status}
                                          </p>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                </ModalBody>
                                <div className='modal-footer mb3'>
                                  <div className='hstack gap-2 justify-content-end'>
                                    <button
                                      type='button'
                                      className='btn btn-light'
                                      onClick={() => {
                                        setModal(false);
                                        setFile([]);
                                        setFileNameItem([]);
                                      }}>
                                      Cancel{" "}
                                    </button>
                                    <button
                                      type='submit'
                                      className='btn btn-success'>
                                      <span className='d-flex align-items-center'>
                                        <span className='flex-grow-1 me-2'>
                                          {!!isEdit
                                            ? "Update"
                                            : "Tambah Client"}
                                        </span>
                                        {loading ? (
                                          <Spinner
                                            size='sm'
                                            className='flex-shrink-0'
                                            role='status'>
                                            Loading...
                                          </Spinner>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Modal>
                          </CardBody>
                        </Card>
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <ToastContainer closeButton={false} />
        </Container>
      </div>
    </React.Fragment>
  );
};

SimplePage.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SimplePage));
