import flagus from "../assets/images/flags/us.svg";
import flagid from "../assets/images/flags/id.svg";

const languages = {
  en: {
    label: "English",
    flag: flagus,
  },
  id: {
    label: "Bahasa",
    flag: flagid,
  }
}

export default languages
