export const FILE_LOADING = "FILE_LOADING";
export const FILE_RESET = "FILE_RESET";
export const GET_FILE = "GET_FILE";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";
export const GET_FILE_FAIL = "GET_FILE_FAIL";
export const DELETE_FILE = "DELETE_FILE"
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS"
export const DELETE_FILE_FAIL = "DELETE_FILE_FAIL"
export const UPDATE_FILE = "UPDATE_FILE"
export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS"
export const UPDATE_FILE_FAIL = "UPDATE_FILE_FAIL"
export const ADD_NEW_FILE = "ADD_NEW_FILE"
export const ADD_FILE_SUCCESS = "ADD_FILE_SUCCESS"
export const ADD_FILE_FAIL = "ADD_FILE_FAIL"