export const DEVICETYPE_LOADING = "DEVICETYPE_LOADING";
export const DEVICETYPE_RESET = "DEVICETYPE_RESET";
export const GET_DEVICETYPE = "GET_DEVICETYPE";
export const GET_DEVICETYPE_SUCCESS = "GET_DEVICETYPE_SUCCESS";
export const GET_DEVICETYPE_FAIL = "GET_DEVICETYPE_FAIL";
export const DELETE_DEVICETYPE = "DELETE_DEVICETYPE";
export const DELETE_DEVICETYPE_SUCCESS = "DELETE_DEVICETYPE_SUCCESS";
export const DELETE_DEVICETYPE_FAIL = "DELETE_DEVICETYPE_FAIL";
export const UPDATE_DEVICETYPE = "UPDATE_DEVICETYPE";
export const UPDATE_DEVICETYPE_SUCCESS = "UPDATE_DEVICETYPE_SUCCESS";
export const UPDATE_DEVICETYPE_FAIL = "UPDATE_DEVICETYPE_FAIL";
export const ADD_NEW_DEVICETYPE = "ADD_NEW_DEVICETYPE";
export const ADD_DEVICETYPE_SUCCESS = "ADD_DEVICETYPE_SUCCESS";
export const ADD_DEVICETYPE_FAIL = "ADD_DEVICETYPE_FAIL";
