import {
  GET_STORAGEMOVE,
  GET_STORAGEMOVE_SUCCESS,
  GET_STORAGEMOVE_FAIL,
  DELETE_STORAGEMOVE,
  DELETE_STORAGEMOVE_SUCCESS,
  DELETE_STORAGEMOVE_FAIL,
  UPDATE_STORAGEMOVE,
  UPDATE_STORAGEMOVE_SUCCESS,
  UPDATE_STORAGEMOVE_FAIL,
  ADD_NEW_STORAGEMOVE,
  ADD_STORAGEMOVE_SUCCESS,
  ADD_STORAGEMOVE_FAIL,
  STORAGEMOVE_LOADING,
  STORAGEMOVE_RESET,
  GET_STORAGEMOVE_ONE,
} from "./actionType";

export const resetStorageMoveState = (actionType) => ({
  type: STORAGEMOVE_RESET,
  payload: { actionType },
});

export const getStorageMoveSuccess = (actionType, data) => ({
  type: GET_STORAGEMOVE_SUCCESS,
  payload: { actionType, data },
});

export const getStorageMoveFail = (actionType, error) => ({
  type: GET_STORAGEMOVE_FAIL,
  payload: { actionType, error },
});

export const getStorageMove = (data) => ({
  type: GET_STORAGEMOVE,
  payload: data,
});

export const getStorageMoveLoading = () => ({
  type: STORAGEMOVE_LOADING,
});

export const deleteStorageMove = (data) => ({
  type: DELETE_STORAGEMOVE,
  payload: data,
});

export const deleteStorageMoveSuccess = (data) => ({
  type: DELETE_STORAGEMOVE_SUCCESS,
  payload: data,
});

export const deleteStorageMoveFail = (error) => ({
  type: DELETE_STORAGEMOVE_FAIL,
  payload: error,
});

export const updateStorageMove = (data) => ({
  type: UPDATE_STORAGEMOVE,
  payload: data,
});

export const updateStorageMoveFail = (error) => ({
  type: UPDATE_STORAGEMOVE_FAIL,
  payload: error,
});

export const updateStorageMoveSuccess = (data) => ({
  type: UPDATE_STORAGEMOVE_SUCCESS,
  payload: data,
});

export const addNewStorageMove = (data) => ({
  type: ADD_NEW_STORAGEMOVE,
  payload: data,
});

export const addStorageMoveSuccess = (data) => ({
  type: ADD_STORAGEMOVE_SUCCESS,
  payload: data,
});

export const addStorageMoveFail = (error) => ({
  type: ADD_STORAGEMOVE_FAIL,
  payload: error,
});

export const getStorageMoveOne = (data) => ({
  type: GET_STORAGEMOVE_ONE,
  payload: data,
});
