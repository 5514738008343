import React, { useEffect, useState, useCallback, Fragment } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Spinner,
    ListGroup,
    ListGroupItem,
    Tooltip,
    UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
//Import actions
import { getDevice, resetDeviceState } from "../../store/device/action";
import { getHistory, resetHistoryState } from "../../store/history/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import defaultImg from "../../assets/images/upload.png";
import device from "../../assets/images/device.jpeg";
import socketIO from "../../helpers/socket";
import BreadCrumbTemplate from "./BreadCrumbTemplate";
import { PortfolioCharts2 } from "../../pages/Dashboard/DashboardCharts";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { extendMoment } from "moment-range";
import WidgetCard from "./WidgetCard";
import DonutChart from "./DonutChart";
import Gauge from "./Gauge";
import Masonry from "react-responsive-masonry";

const io = socketIO.getInstance();
io.setHost("https://socket.atapteknologi.id");
const socket = io.getSocket();

const TemplateKppRantau = (props) => {
    const { t } = props;
    const moments = extendMoment(moment);
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [uidEvents, setUidEvents] = useState([]);
    const [dataUid, setDataUid] = useState([]);
    const [isDone, setIsDone] = useState(false);
    const [deviceSocket, setDeviceSocket] = useState([]);

    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    const [chartData, setchartData] = useState([0, 0]);
    const [tooltipDotsOpen, setTooltipDotsOpen] = useState(false);

    const {
        dashboardTemplateDetails,
        isDashboardTemplateDetailCreated,
        isDashboardTemplateDetailDeleted,
        isDashboardTemplateDetailSuccess,
        loading,
        error,
        clients,
        isDashboardSuccess,
        histories,
        dashboardLoading,
        units,
        devices,
        deviceLoading
    } = useSelector((state) => ({
        dashboardTemplateDetails:
            state.DashboardTemplateDetail.dashboardTemplateDetails,
        isDashboardTemplateDetailCreated:
            state.DashboardTemplateDetail.isDashboardTemplateDetailCreated,
        isDashboardTemplateDetailSuccess:
            state.DashboardTemplateDetail.isDashboardTemplateDetailSuccess,
        isDashboardTemplateDetailDeleted:
            state.DashboardTemplateDetail.isDashboardTemplateDetailDeleted,
        loading: state.DashboardTemplateDetail.loading,
        error: state.DashboardTemplateDetail.error,
        parameters: state.Parameter.parameters,
        clients: state.Client.clients,
        isDashboardSuccess: state.Dashboard.isDashboardSuccess,
        histories: state.History.histories,
        dashboardLoading: state.Dashboard.loading,
        units: state.Unit.units,
        devices: state.Device.devices,
        deviceLoading: state.Device.loading
    }));

    const [templates, setTemplates] = useState("");

    const [isSearch, setIsSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userType, setUserType] = useState("user");
    const [companyId, setCompanyId] = useState();
    const [isRealTime, setIsRealTime] = useState(false);
    const [socketActive, setSocketActive] = useState(0);
    const [deviceList, setDeviceList] = useState([])
    const dotsToggle = () => setTooltipDotsOpen(!tooltipDotsOpen);

    const handleValidDate = (date) => {
        return moment(date).tz("Asia/Jakarta").format("DD-MM-YYYY HH:mm:ss");
    };

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        socket.on("connect", onConnect);
        socket.on("disconnect", onDisconnect);

        return () => {
            socket.off("connect", onConnect);
            socket.off("disconnect", onDisconnect);
        };
    });

    const onUidEvent = useCallback(
        (value) => {
            const val = JSON.parse(value);
            const data = uidEvents.filter((item) => item.identifier !== val.uid);
            data.push(val);
            setUidEvents(data);
        },
        [uidEvents]
    );

    useEffect(() => {
        deviceSocket.forEach((element) => {
            socket.on(element.identifier, onUidEvent);
        });

        return () => {
            deviceSocket.forEach((element) => {
                socket.off(element.identifier, onUidEvent);
            });
        };
    }, [uidEvents, deviceSocket, onUidEvent]);

    useEffect(() => {
        if (!isEmpty(devices)) {
            let data = [];
            devices.forEach((device, i) => {
                data.push(device.identifier)
            });
            setDataUid(JSON.stringify(data));
            setDeviceSocket(devices);
            setDeviceList(devices)
            setIsRealTime(false)
        }
    }, [devices, props.dashboards]);

    useEffect(() => {
        if (dataUid.length > 0) {
            dispatch(resetHistoryState());
            dispatch(getHistory({ type: "current", uid: dataUid }));
        }
    }, [dispatch, dataUid, isRealTime]);

    useEffect(() => {
        if (histories.length > 0 && deviceList.length > 0) {
            let counter = 0;
            deviceList.forEach((device, deviceIdx) => {
                histories.forEach((element) => {
                    if (device.identifier === element.uid) {
                        device.timestamp = handleValidDate(element.timestamp)
                        device.device_parameter.forEach((params, paramsIdx) => {
                            if (element.message) {
                                const amount = element.message[params.value];
                                if (amount) {
                                    deviceList[deviceIdx].device_parameter[paramsIdx].amount =
                                        amount;
                                } else if (amount === 0) {
                                    deviceList[deviceIdx].device_parameter[paramsIdx].amount =
                                        "0";
                                } else if (amount === null) {
                                    deviceList[deviceIdx].device_parameter[paramsIdx].amount =
                                        "NA";
                                }

                                const start = new Date(element.timestamp);
                                const end = new Date();
                                const range1 = moments.range(start, end);
                                const toMinute = range1.snapTo("minute");
                                deviceList[deviceIdx].toMinute = toMinute.diff("minutes")
                            }
                        })
                        if (element && element.message) {
                            if ((new Date().getTime() - new Date(element.timestamp).getTime()) < 1800000) {
                                counter++;
                                deviceSocket.forEach((dl, n) => {
                                    if (dl.identifier === element.uid) deviceSocket[n].isOnline = true;
                                })
                            }
                        }
                    }
                });
            });
            setSocketActive(counter);
            setchartData([deviceSocket.length - counter, counter]);
            setIsDone(true)
        }
    }, [deviceList, deviceSocket, histories, moments]);

    useEffect(() => {
        if (isDone) {
            deviceList.forEach((device, deviceIdx) => {
                uidEvents.forEach((element) => {
                    if (device.identifier === element.uid) {
                        device.device_parameter.forEach((params, paramsIdx) => {
                            if (element.message) {
                                const amount = element.message[params.value];
                                if (amount) {
                                    deviceList[deviceIdx].device_parameter[paramsIdx].amount =
                                        amount;
                                } else if (amount === 0) {
                                    deviceList[deviceIdx].device_parameter[paramsIdx].amount =
                                        "0";
                                } else if (amount === null) {
                                    deviceList[deviceIdx].device_parameter[paramsIdx].amount =
                                        "NA";
                                }

                                const start = new Date(element.message.timestamp);
                                const end = new Date();
                                const range1 = moments.range(start, end);
                                const toMinute = range1.snapTo("minute");
                                deviceList[deviceIdx].toMinute = toMinute.diff("minutes")
                            }
                        })
                    }
                });
            });
            console.log(uidEvents, 'Has Change');
        }
    }, [deviceList, uidEvents, isDone, moments]);

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        const obj = JSON.parse(sessionStorage.getItem("authUser"));
        if (obj) {
            if (obj.data && obj.data.userType) setUserType(obj.data.userType);

            if (obj.data.userType === "company" || obj.data.userType === "user") {
                setCompanyId(obj.data.companyId);
            } else if (obj.data.userType === "admin") {
                setCompanyId(obj.data.companyId);
            }
        } else {
            window.location = "/logout";
        }
    }, [dispatch]);

    useEffect(() => {
        if (!isSearch) {
            dispatch(resetDeviceState());
            dispatch(getDevice({ status: 2 }));
        }
        setIsSearch(true);
        const obj = JSON.parse(sessionStorage.getItem("authUser"));
        if (obj) {
            if (obj.data && obj.data.userType) setUserType(obj.data.userType);
            if (obj.data.userType !== "admin") {
                if (obj.data && obj.data.companyId) setCompanyId(obj.data.companyId);
            }
        } else {
            window.location = "/logout";
        }
    }, [
        dispatch,
        dashboardTemplateDetails,
        isSearch,
        templates,
        userType,
        companyId,
    ]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [modal]);

    useEffect(() => {
        if (uidEvents && deviceSocket) {
            let data = [];
            let counter = 0;
            deviceSocket.forEach((dev, n) => {
                uidEvents.forEach((element) => {
                    if (dev.identifier === element.uid && (new Date().getTime() - new Date(element.device_time).getTime()) < 1800000) {
                        data.push(dev);
                        counter++;
                        deviceSocket[n].isOnline = true;
                    }
                });
            });

            const jum = deviceSocket.filter(obj => {
                return obj.isOnline;
            })
            setSocketActive(jum.length);
            setchartData([deviceSocket.length - jum.length, jum.length]);
        }
    }, [uidEvents, deviceSocket]);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    document.title = `${t("Dashboard")} | TELKOMSEL - IOT Monitoring`;
    return (
        <div className='page-content'>
            <BreadCrumbTemplate
                dashboards={props.dashboards}
                clients={clients}
                userType={userType}
                isDashboardTemplateDetailCreated={isDashboardTemplateDetailCreated}
                isDashboardTemplateDetailDeleted={isDashboardTemplateDetailDeleted}
                t={t}
            />
            <Row className='mb-4'>
                <Col lg={12}>
                    <Card className='mb-3'>
                        <CardBody>
                            <div className='d-flex'>
                                <div className='avatar-md mt-3 ms-3'>
                                    <span className='bg-light'>
                                        <img src={device} alt='' height='50px' width='50px' />
                                    </span>
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='text-center'>
                                        <h1 className='mt-4'>{t("Total Devices")}</h1>
                                    </div>
                                </div>
                                <div className='mb-0 text-muted flex-shrink-0'>
                                    <div className='d-inline-flex'>
                                        <div className='mt-4'>
                                            <p className='fs-16 mb-0 text-muted'>
                                                <i className='mdi mdi-circle fs-10 align-middle text-info me-1'></i>{" "}
                                                {socketActive} {t("ON")}
                                            </p>
                                            <p className='fs-16 mb-0 text-muted'>
                                                <i className='mdi mdi-circle fs-10 align-middle text-primary me-1'></i>{" "}
                                                {deviceSocket.length - socketActive} {t("OFF")}
                                            </p>
                                        </div>
                                        <PortfolioCharts2
                                            series={chartData}
                                            labelText='Total'
                                            labelSize='12px'
                                            labelData={["OFF", "ON"]}
                                            dataWidth={130}
                                            dataColors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-success"]'
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <div>
                    {deviceList.map((dev, devIdx) => {
                        let card = []
                        let gauge = []
                        let donut = []
                        dev.device_parameter.forEach((item) => {
                            if ((item.value === "total_engine_hours" || item.value === "colant_level" || item.value === "fuel_rate") || (item.value === "electrical_potensial" || item.value === "battery_potensial")) {
                                card.push(item)
                            }

                            if ((item.value === "engine_coolant_temp" || item.value === "rpm_engine" || item.value === "engine_oil_temp") || item.value === "engine_oil_press") {
                                gauge.push(item)
                            }

                            if (item.value === "percent_load") {
                                donut.push(item)
                            }
                            console.log(donut[0], 'donut')
                        })
                        return (
                            <div className="card" key={'devIdx-' + devIdx}>
                                <div className="card-header">
                                    <div className="d-flex">
                                        <div className="w-100">
                                            <div className="d-flex flex-row">
                                                <i
                                                    className={
                                                        dev.toMinute <= 15
                                                            ? "ri-radio-button-line text-success me-1 fs-3 "
                                                            : dev.toMinute > 15 && dev.toMinute <= 30 ? "ri-radio-button-line text-warning me-1 fs-3 " : "ri-radio-button-line text-danger me-1 fs-3"
                                                    } id={"dots-" + dev.id} style={{ cursor: "pointer" }}></i>
                                                <UncontrolledTooltip
                                                    placement="top"
                                                    target={"dots-" + dev.id}
                                                    trigger="hover"
                                                >{
                                                        dev.toMinute <= 15
                                                            ? "Device Aktif"
                                                            : dev.toMinute > 15 && dev.toMinute <= 30 ? "Device Idle > 15 Menit" : "Device Nonaktif"
                                                    }</UncontrolledTooltip>
                                                <h3 className="mt-1">{dev.name}</h3>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <span className="fs-4 mt-1 me-2">{dev.timestamp}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Masonry columnsCount={card.length === 1 ? 1 : 5} gutter='15px'>
                                        {(card || []).map((cardI, idxCard) => {
                                            return (
                                                <Card className="card-animate bg-light" key={'idxCard-' + idxCard}>
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <h6>{`${capitalizeFirstLetter(cardI.label).replaceAll("_", " ")} : ${cardI.amount}`}</h6>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )
                                        })}
                                    </Masonry>
                                    <div className="d-flex">
                                        {gauge.length > 0 && gauge.map((item, gIdx) => {
                                            return (
                                                <Gauge data={item} key={'gIdx-' + gIdx} />
                                            )
                                        })}
                                        <div className="flex-fill">
                                            <div className='mt-3'>
                                                <h5 className='text-center'>
                                                    {donut && donut[0] ? capitalizeFirstLetter(donut[0].label).replaceAll("_", " ") : null}
                                                </h5>
                                                {donut.length > 0 ? (
                                                    <DonutChart data={donut} />
                                                ) : null}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Row>
            <ToastContainer closeButton={false} />
        </div >
    );
};

TemplateKppRantau.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(TemplateKppRantau));
