import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_GEOFENCE,
  DELETE_GEOFENCE,
  UPDATE_GEOFENCE,
  ADD_NEW_GEOFENCE,
  GET_GEOFENCE_ONE,
} from "./actionType";

import {
  getGeofenceSuccess,
  getGeofenceFail,
  deleteGeofenceSuccess,
  deleteGeofenceFail,
  updateGeofenceSuccess,
  updateGeofenceFail,
  addGeofenceSuccess,
  addGeofenceFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveGeofenceApi,
  getGeofenceApi,
  updateGeofenceApi,
  deleteGeofenceApi,
  addNewGeofenceApi,
  getOneGeofenceApi,
} from "../../helpers/backend_helper";

function* getGeofence({ payload: geofence }) {
  try {
    const response = yield call(getGeofenceApi, geofence);
    yield put(getGeofenceSuccess(GET_GEOFENCE, response.data));
  } catch (error) {
    yield put(getGeofenceFail(GET_GEOFENCE, error));
  }
}

function* getGeofenceOne({ payload: id }) {
  try {
    const response = yield call(getOneGeofenceApi, id);
    yield put(getGeofenceSuccess(GET_GEOFENCE_ONE, response.data));
  } catch (error) {
    yield put(getGeofenceFail(GET_GEOFENCE_ONE, error));
  }
}

function* onUpdateGeofence({ payload: geofence }) {
  try {
    if (geofence.setApprove) {
      const response = yield call(approveGeofenceApi, geofence);
      yield put(updateGeofenceSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateGeofenceApi, geofence);
      yield put(updateGeofenceSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateGeofenceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteGeofence({ payload: geofence }) {
  try {
    const response = yield call(deleteGeofenceApi, geofence);
    yield put(deleteGeofenceSuccess({ geofence, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteGeofenceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewGeofence({ payload: geofence }) {
  try {
    const response = yield call(addNewGeofenceApi, geofence);
    yield put(addGeofenceSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addGeofenceFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetGeofence() {
  yield takeEvery(GET_GEOFENCE, getGeofence);
}

export function* watchUpdateGeofence() {
  yield takeEvery(UPDATE_GEOFENCE, onUpdateGeofence);
}

export function* watchDeleteGeofence() {
  yield takeEvery(DELETE_GEOFENCE, onDeleteGeofence);
}

export function* watchAddNewGeofence() {
  yield takeEvery(ADD_NEW_GEOFENCE, onAddNewGeofence);
}

export function* watchGetGeofenceOne() {
  yield takeEvery(GET_GEOFENCE_ONE, getGeofenceOne);
}

function* geofenceSaga() {
  yield all([
    fork(watchGetGeofence),
    fork(watchDeleteGeofence),
    fork(watchUpdateGeofence),
    fork(watchAddNewGeofence),
    fork(watchGetGeofenceOne),
  ]);
}

export default geofenceSaga;
