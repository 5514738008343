import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { getClient, resetClientState } from "../../store/client/action";
import { getCompany, resetCompanyState } from "../../store/company/action";
import { Label, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Select from "react-select";
import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
defineElement(lottie.loadAnimation);
const DashTemplateModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  userType,
  t,
}) => {
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [clientInput, setClientInput] = useState();
  const [companyInput, setCompanyInput] = useState();

  useEffect(() => {
    dispatch(resetClientState());
    dispatch(getClient({ status: 2 }));
  }, [dispatch]);

  const { clients, companies, companiesLoading } = useSelector((state) => ({
    clients: state.Client.clients,
    companies: state.Company.companies,
    companiesLoading: state.Company.loading,
  }));

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  const handleCompany = (data) => {
    dispatch(resetCompanyState());
    dispatch(getCompany({ clientId: data.value }));
  };

  useEffect(() => {
    if (!isEmpty(companies)) {
      var data = [];
      var opt = [];
      companies.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setCompanyList(data);
    }
  }, [companies]);

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className='py-3 px-5'>
        <div className='mt-2 text-center'>
          <lord-icon
            src='https://cdn.lordicon.com/wuvorxbv.json'
            trigger='loop'
            style={{ width: "100px", height: "100px" }}></lord-icon>
          <div className='mt-4 pt-2 fs-15 mx-4 mx-sm-5'>
            {userType === "admin" ? (
              <>
                <div className='mb-3'>
                  <Label htmlFor='password-field' className='form-label'>
                    {t("Client")}
                  </Label>
                  <Select
                    name='client'
                    placeholder={`${t("Select")} ${t("Client")}`}
                    onChange={(e) => {
                      setCompanyList([]);
                      setClientInput(e.value);
                      handleCompany(e);
                    }}
                    options={clientList}></Select>
                </div>
                <div className='mb-3'>
                  <Label htmlFor='password-field' className='form-label'>
                    {t("Company")}
                  </Label>
                  {companiesLoading ? (
                    <>
                      <Spinner
                        color='primary'
                        type='grow'
                        size={"sm"}
                        className='ms-2'></Spinner>
                      &nbsp;
                      <Spinner color='success' type='grow' size={"sm"}>
                        Loading...
                      </Spinner>
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}
                  <Select
                    name='company'
                    placeholder={`${t("Select")} ${t("Company")}`}
                    onChange={(e) => {
                      setCompanyInput(e.value);
                    }}
                    options={companyList}></Select>
                </div>
              </>
            ) : null}
            <h4>{t("Are you sure ?")}</h4>
            <p className='text-muted mx-4 mb-0'>
              {t("Are you sure you want to use this template ?")}
            </p>
          </div>
        </div>
        <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>
          <button
            type='button'
            className='btn w-sm btn-light'
            data-bs-dismiss='modal'
            onClick={onCloseClick}>
            {t("Close")}
          </button>
          <button
            type='button'
            className='btn w-sm btn-success '
            id='delete-record'
            onClick={() => {
              onDeleteClick(companyInput, clientInput);
            }}>
            {t("Yes, Use It!")}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

DashTemplateModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(DashTemplateModal));
