import {
  GET_DASHBOARDTEMPLATEDETAIL,
  GET_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  GET_DASHBOARDTEMPLATEDETAIL_FAIL,
  DELETE_DASHBOARDTEMPLATEDETAIL,
  DELETE_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  DELETE_DASHBOARDTEMPLATEDETAIL_FAIL,
  UPDATE_DASHBOARDTEMPLATEDETAIL,
  UPDATE_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  UPDATE_DASHBOARDTEMPLATEDETAIL_FAIL,
  ADD_NEW_DASHBOARDTEMPLATEDETAIL,
  ADD_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  ADD_DASHBOARDTEMPLATEDETAIL_FAIL,
  DASHBOARDTEMPLATEDETAIL_LOADING,
  DASHBOARDTEMPLATEDETAIL_RESET,
} from "./actionType";

export const resetDashboardTemplateDetailState = (actionType) => ({
  type: DASHBOARDTEMPLATEDETAIL_RESET,
  payload: { actionType },
});

export const getDashboardTemplateDetailSuccess = (actionType, data) => ({
  type: GET_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  payload: { actionType, data },
});

export const getDashboardTemplateDetailFail = (actionType, error) => ({
  type: GET_DASHBOARDTEMPLATEDETAIL_FAIL,
  payload: { actionType, error },
});

export const getDashboardTemplateDetail = (data) => ({
  type: GET_DASHBOARDTEMPLATEDETAIL,
  payload: data,
});

export const getDashboardTemplateDetailLoading = () => ({
  type: DASHBOARDTEMPLATEDETAIL_LOADING,
});

export const deleteDashboardTemplateDetail = (data) => ({
  type: DELETE_DASHBOARDTEMPLATEDETAIL,
  payload: data,
});

export const deleteDashboardTemplateDetailSuccess = (data) => ({
  type: DELETE_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  payload: data,
});

export const deleteDashboardTemplateDetailFail = (error) => ({
  type: DELETE_DASHBOARDTEMPLATEDETAIL_FAIL,
  payload: error,
});

export const updateDashboardTemplateDetail = (data) => ({
  type: UPDATE_DASHBOARDTEMPLATEDETAIL,
  payload: data,
});

export const updateDashboardTemplateDetailFail = (error) => ({
  type: UPDATE_DASHBOARDTEMPLATEDETAIL_FAIL,
  payload: error,
});

export const updateDashboardTemplateDetailSuccess = (data) => ({
  type: UPDATE_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  payload: data,
});

export const addNewDashboardTemplateDetail = (data) => ({
  type: ADD_NEW_DASHBOARDTEMPLATEDETAIL,
  payload: data,
});

export const addDashboardTemplateDetailSuccess = (data) => ({
  type: ADD_DASHBOARDTEMPLATEDETAIL_SUCCESS,
  payload: data,
});

export const addDashboardTemplateDetailFail = (error) => ({
  type: ADD_DASHBOARDTEMPLATEDETAIL_FAIL,
  payload: error,
});
