import React, {
    Fragment,
    useEffect,
    useState,
    useMemo,
    useCallback,
    useRef,
} from "react";
import {
    Table,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Row,
    Modal,
    ModalHeader,
    Form,
    ModalBody,
    Label,
    Input,
    FormFeedback,
    Spinner,
} from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import ApproveModal from "../../../Components/Common/ApproveModal";
import Flatpickr from "react-flatpickr";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect,
} from "react-table";
import Select from "react-select";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import { deleteReportTemplateDetail as onDeleteReportTemplateDetail } from "../../../store/reportTemplateDetail/action";
import {
    getReportTemplate as onGetReportTemplates,
    addNewReportTemplate as onAddNewReportTemplate,
    updateReportTemplate as onUpdateReportTemplate,
    deleteReportTemplate as onDeleteReportTemplate,
    getReportTemplateLoading,
} from "../../../store/reportTemplate/action";
import { getClient, resetClientState } from "../../../store/client/action";
import { getCompany, resetCompanyState } from "../../../store/company/action";
import { getDevice, resetDeviceState } from "../../../store/device/action";
import { getSensor, resetSensorState } from "../../../store/sensor/action";
import {
    getHistoryPublic,
    resetHistoryState,
} from "../../../store/history/action";
import { getUser, resetUserState } from "../../../store/users/action";
import {
    getParameter,
    resetParameterState,
} from "../../../store/parameter/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExportExcelModal from "../../../Components/Common/ExportExcelModal";
import ExportExcelModalObject from "../../../Components/Common/ExportExcelModalObject";
import ChartReport from "../../../Components/Common/ChartReport";

const ReportCbu = (props) => {
    const { t } = props;
    const [modal, setModal] = useState(false);
    const [modalDetail, setModalDetail] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const dispatch = useDispatch();
    const [templateSelect, setTemplateSelect] = useState();
    const [deviceSelect, setDeviceSelect] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [labels, setLabels] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [series, setSeries] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [pIndex, setPIndex] = useState(0);
    const [isShift, setIsShift] = useState(false)
    const flatpickrRef = useRef(null);


    const {
        reportTemplates,
        loading,
        clients,
        devices,
        parameters,
        sensors,
        companies,
        companiesLoading,
        users,
        userLoading,
        historyPublics,
    } = useSelector((state) => ({
        reportTemplates: state.ReportTemplate.reportTemplates,
        isReportTemplateCreated: state.ReportTemplate.isReportTemplateCreated,
        isReportTemplateSuccess: state.ReportTemplate.isReportTemplateSuccess,
        loading: state.ReportTemplate.loading,
        error: state.ReportTemplate.error,
        reportTemplateDetails: state.ReportTemplateDetail.reportTemplateDetails,
        reportTemplateDetailLoading: state.ReportTemplateDetail.loading,
        isReportTemplateDetailCreated:
            state.ReportTemplateDetail.isReportTemplateDetailCreated,
        isReportTemplateDetailSuccess:
            state.ReportTemplateDetail.isReportTemplateDetailSuccess,
        clients: state.Client.clients,
        devices: state.Device.devices,
        parameters: state.Parameter.parameters,
        sensors: state.Sensor.sensors,
        companies: state.Company.companies,
        companiesLoading: state.Company.loading,
        users: state.User.users,
        userLoading: state.User.loading,
        historyPublics: state.History.historyPublics,
    }));

    const [isSearch, setIsSearch] = useState(false);
    const [reportTemplateList, setReportTemplateList] = useState([]);
    const [reportTemplate, setReportTemplate] = useState([]);
    const [taskDetailAddedId, setReportTemplateDetailAddedId] = useState(0);
    const [info, setInfo] = useState([]);
    const [isExportCSV, setIsExportCSV] = useState(false);
    const [statusActive, setStatusActive] = useState([]);
    const [reportTemplateDetail, setReportTemplateDetail] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [approveModal, setApproveModal] = useState(false);
    const [positionList, setPositionList] = useState([]);
    const [roles, setRoles] = useState(null);
    const [userType, setUserType] = useState("user");
    const [unitName, setUnitName] = useState({});
    const [templateId, setTemplateId] = useState();
    const [clientList, setClientList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [companyId, setCompanyId] = useState();
    const [clientName, setClientName] = useState("");
    const [deviceList, setDeviceList] = useState([]);
    const [parameterList, setParameterList] = useState([]);
    const [parameterSelects, setParameterSelects] = useState([]);
    const [sensorList, setSensorList] = useState([]);
    const [sensorId, setSensorId] = useState();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isDetailClick, setIsDetailClick] = useState(false);
    const [inputVal, setInputVal] = useState();
    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const [outerText, setOuterText] = useState();
    const [detailArr, setDetailArr] = useState([]);
    const [deleteParameterId, setDeleteParameterId] = useState(-1);
    const [deleteParameterIndex, setDeleteParameterIndex] = useState(-1);
    const [searchValueSecond, setSearchValueSecond] = useState([]);
    const [mailToList, setMailToList] = useState([]);
    const [valuesPar, setValuesPar] = useState([]);
    const [dateDaily, setDateDaily] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditDetail, setIsEditDetail] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalDetail, setDeleteModalDetail] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [templateValue, setTemplateValue] = useState();
    const [periodDate, setPeriodDate] = useState();
    const [clientId, setClientId] = useState();
    const [templateDetail, setTemplateDetail] = useState([]);
    const [paramLabel, setParamLabel] = useState([]);
    const [reportSelectList, setReportSelectList] = useState([]);
    const [exportList, setExportList] = useState([]);
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [tablePeriod, setTablePeriod] = useState([]);
    const [isExportRaw, setIsExportRaw] = useState(false)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [headerTable, setHeaderTable] = useState([])
    const [shift, setShift] = useState([])
    const [sensorSelect, setSensorSelect] = useState({
        label: "",
        value: "",
        deviceId: "",
    });
    const [deviceId, setDeviceId] = useState(-1);
    const ref = useRef(null);

    const statusActiveData = [
        {
            options: [
                { label: "Draft", value: 1 },
                { label: "Activated", value: 2 },
                { label: "Deactivated", value: 3 },
            ],
        },
    ];

    const defaultdate = () => {
        return moment(new Date())
            .tz("Asia/Jakarta")
            .format("YYYY/MM/DD HH:mm:ss")
            .toString();
    };
    const template = [
        {
            options: [
                { label: "Table", value: "table" },
                { label: "Line Chart", value: "line" },
                { label: "Donut Chart", value: "donut" },
                { label: "Pie Chart", value: "pie" },
            ],
        },
    ];

    const aggregate = [
        {
            options: [
                { label: "None", value: "none" },
                { label: "Sum", value: "$sum" },
                { label: "Average", value: "$avg" },
                {
                    label: "First",
                    value: "$first",
                },
                { label: "Last", value: "$last" },
            ],
        },
    ];

    const condition = [
        {
            options: [
                { label: "=", value: "$eq" },
                { label: ">", value: "$gt" },
                { label: "<", value: "$lt" },
                { label: ">=", value: "$gte" },
                { label: "<=", value: "$lte" },
            ],
        },
    ];

    const dataGroupBy = [
        {
            options: [
                { label: "None", value: "none" },
                { label: "Hour", value: "$hour" },
                { label: "Date", value: "$dayOfMonth" },
                { label: "Month", value: "$month" },
                { label: "Quarterly", value: "$quarter" },
                { label: "Semester", value: "$semester" },
                { label: "Annualy", value: "$year" },
            ],
        },
    ];

    const onClickDelete = (reportTemplate) => {
        setReportTemplate(reportTemplate);
        setDeleteModal(true);
    };

    const onClickDeleteDetail = (reportTemplateDetail) => {
        setReportTemplateDetail(reportTemplateDetail);
        setDeleteModalDetail(true);
    };

    const handleDeleteReportTemplate = () => {
        if (reportTemplate) {
            dispatch(onDeleteReportTemplate(reportTemplate));
            setDeleteModal(false);
        }
    };

    const handleDeleteReportTemplateDetail = () => {
        if (reportTemplateDetail) {
            setReportTemplateDetailAddedId(reportTemplateDetail.reportTemplateId);
            dispatch(onDeleteReportTemplateDetail(reportTemplateDetail));
            setDeleteModalDetail(false);
        }
    };

    const onClickApprove = (user) => {
        setReportTemplate(user);
        setApproveModal(true);
    };

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    const toggleTab = (tab, type) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            let filteredReportTemplates = reportTemplates;
            if (type !== "all") {
                filteredReportTemplates = reportTemplates.filter(
                    (reportTemplate) => reportTemplate.rptTemplate_status.name === type
                );
            }
            setReportTemplateList(filteredReportTemplates);
        }
    };

    useEffect(() => {
        if (!isSearch) {
            dispatch(resetClientState());
            dispatch(getClient({ status: 2 }));
            dispatch(resetSensorState());
            dispatch(getSensor({ status: 2 }));
            setIsSearch(true);
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            if (obj) {
                dispatch(resetUserState());
                dispatch(
                    getUser({
                        type: "Task",
                        clientId: obj.data.clientId,
                    })
                );
                if (obj.data && obj.data.userType) setUserType(obj.data.userType);
                if (obj.data.user_client && obj.data.user_client.id) {
                    setClientName(obj.data.user_client.id);
                }
                if (obj.data.userType === "company") {
                    setCompanyId(obj.data.companyId);
                    setClientId(obj.data.clientId);
                } else if (obj.data.userType === "client") {
                    setClientId(obj.data.clientId);
                }
            } else {
                window.location = "/logout";
            }
        }
    }, [dispatch, reportTemplates, isSearch, clientId]);

    useEffect(() => {
        var data = [];
        var opt = [];
        users.forEach((item, key) => {
            const name = item.name;
            const id = item.id;
            const taskListId = item.taskListId;
            const clientId = item.clientId;
            const position =
                item.user_position && item.user_position.name
                    ? `, ${item.user_position.name}`
                    : "";
            const client_name =
                item.user_client && item.user_client.name
                    ? item.user_client.name
                    : "Not set";
            var obj = {
                label: name + position + " - " + client_name,
                value: id,
                taskListId: taskListId,
                clientId: clientId,
            };
            opt.push(obj);
        });
        var option = { options: opt };
        data.push(option);

        if (users.length > 0) {
            setMailToList(data);
        }
    }, [users]);

    useEffect(() => {
        if (userType === "company" && companyId) {
            dispatch(resetDeviceState());
            dispatch(getDevice({ companyId: companyId, status: 2 }));
        }
    }, [userType, dispatch, companyId]);

    useEffect(() => {
        dispatch(resetParameterState());
        dispatch(getParameter({ status: 2, company: companyId }));
    }, [dispatch, companyId]);

    useEffect(() => {
        if (!isEmpty(reportTemplates)) {
            setIsEdit(false);
            setReportTemplateList(reportTemplates);
            let current = 0;
            if (taskDetailAddedId !== 0) {
                reportTemplates.forEach((obj, i) => {
                    if (obj.id === taskDetailAddedId) current = i;
                });
            }
            setInfo(reportTemplates[current]);
        }
    }, [reportTemplates, taskDetailAddedId]);

    useEffect(() => {
        if (!isEmpty(devices)) {
            var data = [];
            var opt = [];
            devices.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id, identifier: item.identifier };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setDeviceList(data);
        }
    }, [devices]);

    useEffect(() => {
        if (!isEmpty(parameters)) {
            var data = [];
            var opt = [];
            parameters.forEach((item, key) => {
                const name = item.label;
                const id = item.id;
                var obj = {
                    label: name,
                    value: id,
                    sensorId: item.sensorId,
                    name: item.value,
                };
                opt.push(obj);
            });
            const result = opt.reduce((accumulator, current) => {
                let exists = accumulator.find((item) => {
                    return item.label === current.label;
                });
                if (!exists) {
                    accumulator = accumulator.concat(current);
                }
                return accumulator;
            }, []);
            var option = { options: result };
            data.push(option);
            setParameterList(data);
        }
    }, [parameters]);

    useEffect(() => {
        if (!isEmpty(reportTemplates)) {
            var data = [];
            var opt = [];
            reportTemplates.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id, item: item };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setReportSelectList(data);
        }
    }, [reportTemplates]);

    useEffect(() => {
        if (!isEmpty(sensors)) {
            var data = [];
            var opt = [];
            sensors.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id, deviceId: item.deviceId };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setSensorList(data);
        }
    }, [sensors]);

    useEffect(() => {
        if (!isEmpty(companies)) {
            var data = [];
            var opt = [];
            companies.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setCompanyList(data);
        }
    }, [companies]);

    useEffect(() => {
        if (!isEmpty(clients)) {
            var data = [];
            var opt = [];
            clients.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setClientList(data);
        }
    }, [clients]);

    const [filterDate, setFilterDate] = useState(defaultdate());
    const [sendMailDate, setSendMailDate] = useState();

    const handleValidDate = (date) => {
        return moment(new Date(date), "YYYY/MM/DD")
            .tz("Asia/Jakarta")
            .format("MMM D, YYYY HH:mm:ss");
    };

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setReportTemplate(null);
            setTemplateDetail([]);
            setIsDetailClick(false);
        } else {
            setModal(true);
        }
    }, [modal]);

    const handleReportTemplateClick = useCallback(
        (arg) => {
            const data = arg;
            setDeviceId(data.deviceId);
            const kalimat = (name) => {
                if (name) {
                    let besar = name.charAt(0).toUpperCase();
                    let kecil = name.slice(1);
                    return besar + kecil;
                }
            };
            const kalimatAgreggate = (name) => {
                if (name) {
                    let split = name.split("");
                    const idx = split.indexOf("$");
                    if (idx > -1) {
                        split.splice(idx, 1);
                    }
                    let join = split.join("");
                    if (join) {
                        let besar = join.charAt(0).toUpperCase();
                        let kecil = join.slice(1);
                        return besar + kecil;
                    }
                }
            };
            let fc = [];
            let fe = [];
            if (typeof data.detail === 'string') {
                let detailSelect = [];
                let detailParse = JSON.parse(data.detail)
                detailParse && detailParse.parameter.forEach((item) => {
                    detailSelect.push(item.parameter);
                })
                if (detailParse && detailParse.parameter.length > 0) {
                    setIsDetailClick(true);
                }
                if (detailParse && detailParse.shift.length > 0) {
                    setIsShift(true);
                }
                setTemplateDetail(detailParse && detailParse.parameter);
                setShift(detailParse && detailParse.shift)
                setParameterSelects(detailSelect);
            } else if (typeof data.detail === 'object' && !Array.isArray(data.detail)) {
                let detailSelect = [];
                data.detail && data.detail.parameter.forEach((item) => {
                    detailSelect.push(item.parameter);
                })
                if (data.detail && data.detail.parameter.length > 0) {
                    setIsDetailClick(true);
                }
                if (data.detail && data.detail.shift.length > 0) {
                    setIsShift(true);
                }
                console.log(data.detail && data.detail.parameter);
                setTemplateDetail(data.detail && data.detail.parameter);
                setShift(data.detail && data.detail.shift)
                setParameterSelects(detailSelect);
            } else if (Array.isArray(data.detail)) {
                let detailSelect = [];
                let fc = []
                data.detail.forEach((datas) => {
                    detailSelect.push(datas.parameter);
                    fc.push({
                        label: datas.label,
                        condition: datas.condition,
                        parameter: datas.parameter,
                        value: datas.value
                        ,
                    })
                })
                if (data.detail && data.detail.length > 0) {
                    setIsDetailClick(true);
                }
                console.log(fc);
                setTemplateDetail(fc);
                setParameterSelects(detailSelect);
            }
            setReportTemplate({
                id: data.id,
                name: data.name,
                client: {
                    label: data.rptTemplate_client.name,
                    value: data.rptTemplate_client.id,
                },
                company: {
                    label: data.rptTemplate_company.name,
                    value: data.rptTemplate_company.id,
                },
                description: data.description,
                status: {
                    label: data.rptTemplate_status.name,
                    value: data.rptTemplate_status.id,
                },
                type: {
                    label: kalimat(data.type),
                    value: data.type,
                },
                aggregate: {
                    label: kalimatAgreggate(data.aggregate),
                    value: data.aggregate,
                },
                group: {
                    label:
                        data.group === "$hour"
                            ? "Hour"
                            : data.group === "$dayOfMonth"
                                ? "Date"
                                : data.group === "$month"
                                    ? "Month"
                                    : "",
                    value: data.group,
                },
                templateDetail: fc,
                paramLabel: fe,
            });

            setIsEdit(true);
            toggle();
        },
        [toggle]
    );

    const getSensorSelectList = useMemo(() => {
        if (deviceId > -1) {
            const newOptSensor =
                sensorList[0] &&
                sensorList[0].options.filter((itm) => {
                    return itm.deviceId === deviceId;
                });
            return [{ options: newOptSensor }];
        } // else {
        //   return sensorList;
        // }
    }, [sensorList, deviceId]);

    const toggleDetail = useCallback(() => {
        if (modalDetail) {
            setModalDetail(false);
            setReportTemplateDetail(null);
            setReportTemplate(null);
        } else {
            setModalDetail(true);
        }
    }, [modalDetail]);

    const columns = useMemo(
        () => [
            {
                Header: " ",
                maxWidth: 100,
                filterable: false,
                Cell: (cellProps) => {
                    return (
                        <div className='hstack gap-2 mt-4 mt-sm-0'>
                            <Link
                                to='#'
                                className='btn-icon btn btn-sm btn-soft-primary edit-item-btn'
                                onClick={() => {
                                    handleReportTemplateClick(cellProps.row.original);
                                }}>
                                <i className='ri-pencil-line fs-16'></i>
                            </Link>
                            <Link
                                to='#'
                                className='btn-icon btn btn-sm btn-soft-danger remove-item-btn'
                                onClick={() => {
                                    const reportTemplateData = cellProps.row.original;
                                    onClickDelete(reportTemplateData);
                                }}>
                                <i className=' ri-delete-bin-line fs-15'></i>
                            </Link>
                        </div>
                    );
                },
            },
            {
                Header: `${t("Name")}`,
                accessor: "name",
                filterable: true,
            },
        ],
        [handleReportTemplateClick, t]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns: columns,
            data: reportTemplateList,
            defaultColumn: { Filter: null },
            initialState: {
                pageIndex: 0,
                pageSize: 8,
                selectedRowIds: 0,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const columnsSecond = useMemo(
        () => [
            {
                Header: `${t("Start")}`,
                accessor: "start",
                Cell: (cellProps) => {
                    return (
                        <div className='hstack gap-2 mt-4 mt-sm-0'>
                            {cellProps.row.original.start ? (
                                <p>{cellProps.row.original.start}</p>
                            ) : "-"}
                        </div>
                    );
                },
            },
            {
                Header: `${t("End")}`,
                accessor: "last",
                Cell: (cellProps) => {
                    return (
                        <div className='hstack gap-2 mt-4 mt-sm-0'>
                            {cellProps.row.original.end ? (
                                <p>{cellProps.row.original.end}</p>
                            ) : "-"}
                        </div>
                    );
                },
            },
            {
                Header: `${t("Product")}`,
                accessor: "product",
                filterable: true,
            },
            {
                Header: "Totalizer-Mass (ton)",
                accessor: "total",
                filterable: true,
            },
            {
                Header: "Loading Rate (ton/hour)",
                accessor: "loading",
                filterable: true,
            },
        ],
        [t]
    );

    const {
        getTableProps: getTablePropsSecond,
        getTableBodyProps: getTableBodyPropsSecond,
        headerGroups: headerGroupsSecond,
        page: pageSecond,
        prepareRow: prepareRowSecond,
        canPreviousPage: canPreviousPageSecond,
        canNextPage: canNextPageSecond,
        pageOptions: pageOptionsSecond,
        gotoPage: gotoPageSecond,
        nextPage: nextPageSecond,
        previousPage: previousPageSecond,
        setPageSize: setPageSizeSecond,
        preGlobalFilteredRows: preGlobalFilteredRowsSecond,
        setGlobalFilter: setGlobalFilterSecond,
        state: { pageIndex: pageIndexSecond, pageSize: pageSizeSecond },
    } = useTable(
        {
            columns: columnsSecond,
            data: tablePeriod || [],
            defaultColumn: { Filter: null },
            initialState: {
                pageIndex: 0,
                pageSize: 8,
                selectedRowIds: 0,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const columnsThird = useMemo(
        () => headerTable,
        [headerTable]
    );

    const {
        getTableProps: getTablePropsThird,
        getTableBodyProps: getTableBodyPropsThird,
        headerGroups: headerGroupsThird,
        page: pageThird,
        prepareRow: prepareRowThird,
        canPreviousPage: canPreviousPageThird,
        canNextPage: canNextPageThird,
        pageOptions: pageOptionsThird,
        gotoPage: gotoPageThird,
        nextPage: nextPageThird,
        previousPage: previousPageThird,
        setPageSize: setPageSizeThird,
        preGlobalFilteredRows: preGlobalFilteredRowsThird,
        setGlobalFilter: setGlobalFilterThird,
        state: { pageIndex: pageIndexThird, pageSize: pageSizeThird },
    } = useTable(
        {
            columns: columnsThird,
            data: rawData || [],
            defaultColumn: { Filter: null },
            initialState: {
                pageIndex: pIndex,
                pageSize: 50,
                selectedRowIds: 0,
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const onChangeSecond = useAsyncDebounce((value) => {
        setGlobalFilterSecond(value || undefined);
    }, 200);

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
    };

    const generateFilterable = (column) => {
        return column.filterable
            ? column.isSorted
                ? column.isSortedDesc
                    ? " sorting_desc"
                    : " sorting_asc"
                : " sort"
            : "";
    };

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value));
    };

    const onChangeInSelectThird = (event) => {
        setPageSizeThird(Number(event.target.value));
    };

    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    const onChangeInInputThird = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPageThird(page);
    };

    const count = preGlobalFilteredRows.length;
    const [searchValue, setSearchValue] = useState([]);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    const handleApproveReportTemplate = () => {
        if (reportTemplate) {
            reportTemplate.setApprove = true;
            dispatch(onUpdateReportTemplate(reportTemplate));
            setApproveModal(false);
        }
    };

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            client: (reportTemplate && reportTemplate.client) || "",
            type: (reportTemplate && reportTemplate.type) || "",
            aggregate: (reportTemplate && reportTemplate.aggregate) || "",
            name: (reportTemplate && reportTemplate.name) || "",
            description: (reportTemplate && reportTemplate.description) || "",
            status: (reportTemplate && reportTemplate.status) || "",
            templateDetail: (reportTemplate && reportTemplate.templateDetail) || "",
            paramLabel: (reportTemplate && reportTemplate.paramLabel) || "",
            company: (reportTemplate && reportTemplate.company) || "",
            group: (reportTemplate && reportTemplate.group) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            type: Yup.object().required("Please Select Type"),
            aggregate: Yup.object().required("Please Select Aggregate"),
            group: Yup.object().required("Please Select Group"),
        }),
        onSubmit: (values) => {
            dispatch(getReportTemplateLoading());
            if (isEdit) {
                const updateReportTemplate = {
                    id: reportTemplate ? reportTemplate.id : 0,
                    clientId: clientId ? clientId : values.client.value,
                    companyId: companyId ? companyId : values.company.value,
                    name: values.name,
                    type: values.type.value ? values.type.value : "table",
                    aggregate: values.aggregate.value ? values.aggregate.value : "none",
                    detail: { parameter: templateDetail, shift: shift },
                    description: values.description,
                    statusId: 2,
                    group: values.group.value ? values.group.value : "",
                };
                dispatch(onUpdateReportTemplate(updateReportTemplate));
                validation.resetForm();
            } else {
                const newReportTemplate = {
                    clientId: clientId ? clientId : values.client.value,
                    companyId: companyId ? companyId : values.company.value,
                    name: values.name,
                    type: values.type.value ? values.type.value : "table",
                    aggregate: values.aggregate.value ? values.aggregate.value : "none",
                    detail: { parameter: templateDetail, shift: shift },
                    description: values.description,
                    statusId: 2,
                    group: values.group.value ? values.group.value : "",
                };
                console.log(newReportTemplate);
                dispatch(onAddNewReportTemplate(newReportTemplate));
                validation.resetForm();
            }
            toggle();
        },
    });

    const checkedAllReportTemplate = () => {
        const checkall = document.getElementById("checkBoxAllTask");
        const ele = document.querySelectorAll(".dataCheckboxTask");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    // Delete Multiple
    const deleteMultiple = () => {
        const ele = document.querySelectorAll(".dataCheckboxTask:checked");
        const checkall = document.getElementById("checkBoxAllTask");
        ele.forEach((element) => {
            dispatch(onDeleteReportTemplate(element.value));
        });
        checkall.checked = false;
    };

    function handleFilter() {
        let startat = "";
        let endat = "";
        let datearr = filterDate.split(" to ");
        if (datearr[1]) {
            startat = datearr[0];
            endat = datearr[1];
        }
        var status = statusActive.value ? statusActive.value : "";
        const param = {
            title: searchValue,
            startat: startat,
            endat: endat,
            status: status,
        };
        dispatch(onGetReportTemplates(param));
    }

    const handleCompany = (data) => {
        dispatch(resetCompanyState());
        dispatch(getCompany({ clientId: data.value }));
    };

    const handleDevice = (data) => {
        dispatch(resetDeviceState());
        dispatch(getDevice({ companyId: data.value }));
    };

    function handleDetailAdd() {
        let fc = [];
        let fe = [];
        if (templateDetail && templateDetail) {
            templateDetail.forEach((element) => {
                fc.push(element);
            });
        }
        if (paramLabel && paramLabel) {
            paramLabel.forEach((element) => {
                fe.push(element);
            });
        }
        fc.push({
            label: "",
            parameter: "",
            condition: "",
            value: 0,
        });
        fe.push({
            paramId: "",
        });
        setTemplateDetail(fc);
        setParamLabel(fe);
    }

    function handleAddShift() {
        let fc = [];
        if (shift && shift) {
            shift.forEach((element) => {
                fc.push(element);
            });
        }
        fc.push({
            name: "",
            from: "",
            to: "",
        });
        setShift(fc);
    }

    const handleShift = () => {
        let fc = [];
    };

    const getParameterValue = useCallback(
        (id) => {
            if (id && parameterList) {
                const val = parameterList[0]?.options.filter((itm) => {
                    return itm.name === id;
                });
                return val;
            }
        },
        [parameterList]
    );

    const getParameterOptions = useMemo(() => {
        if (parameterList || parameterSelects) {
            const val = parameterList[0]?.options.filter((itm) => {
                return parameterSelects.indexOf(itm.name) === -1;
            });
            console.log(val);
            return val;
        }
    }, [parameterList, parameterSelects]);

    const deleteTemplate = (index) => {
        // Buat salinan array templateDetail
        const updatedTemplates = [...templateDetail];
        // Hapus elemen di index tertentu
        updatedTemplates.splice(index, 1);
        // Update state dengan templateDetail yang baru
        setTemplateDetail(updatedTemplates);
    };

    const handleDeleteParameters = useCallback((item) => {

        if (templateDetail) {
            templateDetail.splice(templateDetail.indexOf(item), 1);
            setTemplateDetail(templateDetail);
            forceUpdate();
        }
        if (parameterSelects) {
            parameterSelects.splice(parameterSelects.indexOf(item.parameter), 1)
            setParameterSelects(parameterSelects)
            forceUpdate()

            var data = [];
            var opt = [];
            parameters.forEach((item, key) => {
                const name = item.label;
                const id = item.id;
                var obj = {
                    label: name,
                    value: id,
                    sensorId: item.sensorId,
                    name: item.value,
                };
                opt.push(obj);
            });
            const result = opt.reduce((accumulator, current) => {
                let exists = accumulator.find((item) => {
                    return item.label === current.label;
                });
                if (!exists) {
                    accumulator = accumulator.concat(current);
                }
                return accumulator;
            }, []);
            var option = { options: result };
            data.push(option);
            setParameterList(data);
        }
    }, [forceUpdate, templateDetail, parameterSelects, parameters])

    const handleDeleteShift = useCallback((item) => {
        if (shift) {
            shift.splice(shift.indexOf(item), 1);
            setShift(shift);
            forceUpdate();
        }
    }, [forceUpdate, shift])

    useEffect(() => {
        if (templateDetail) {
            console.log(templateDetail, 'changed');
        }
    }, [templateDetail])

    useEffect(() => {
        if (
            !isEmpty(historyPublics) &&
            templateSelect &&
            historyPublics.length > 0
        ) {
            if (templateSelect.type === "line") {
                historyPublics.sort((a, b) => a.hour - b.hour);
                let arrCat = [];
                console.log(historyPublics);
                historyPublics.forEach((his) => {
                    console.log(his);
                    let date = new Date(his.datestring)
                    let value = date.setHours(his.hour, 0, 0, 0)
                    let fixDate = new Date(value)
                    arrCat.push(moment(fixDate).format("MMM DD HH:mm"))
                })
                console.log(historyPublics);
                setDateDaily(arrCat)
            } else if (templateSelect.type === "donut" || templateSelect.type === "pie") {
                historyPublics.sort((a, b) => a.hour - b.hour);
                const start = moment(historyPublics[0]?.datestring).startOf('day');

                const times = historyPublics.length;

                let arrCat = [];
                for (let i = 0; i < times; i++) {
                    const toPrint = moment(start)
                        .add(i * 60, 'minutes')
                        .format('LT');

                    arrCat.push(toPrint);
                }

                console.log(arrCat);
                setDateDaily(arrCat);
            } else if (templateSelect.type === "table" || templateSelect.aggregate === "$first") {
                historyPublics.sort((a, b) => a.hour - b.hour);
                const start = moment(historyPublics[0]?.datestring).startOf('day');

                const times = historyPublics.length;

                let arrCat = [];
                for (let i = 0; i < times; i++) {
                    const toPrint = moment(start)
                        .add(i * 60, 'minutes')
                        .format('MMM D, YYYY HH:mm:ss');

                    arrCat.push(toPrint);
                }

                console.log(arrCat);
                setDateDaily(arrCat);
            }
        }
    }, [templateSelect, historyPublics]);

    useEffect(() => {
        if (
            !isEmpty(historyPublics) &&
            parameterList &&
            historyPublics.length > 0 &&
            dateDaily.length > 0
        ) {
            let dataLabel = [];
            let entries;
            parameterList[0] &&
                parameterList[0].options.forEach((item) => {
                    historyPublics.forEach((his) => {
                        entries = Object.entries(his);
                        entries.forEach((e) => {
                            if (item.label && item.name && item.name === e[0]) {
                                dataLabel.push({
                                    label: item.label,
                                    value: item.name,
                                });
                            }
                        });
                    });
                });
            const result = dataLabel.reduce((accumulator, current) => {
                let exists = accumulator.find((item) => {
                    return item.value === current.value;
                });
                if (!exists) {
                    accumulator = accumulator.concat(current);
                }
                return accumulator;
            }, []);
            setLabels(result);
        }
    }, [parameterList, historyPublics, dateDaily]);

    useEffect(() => {
        if (
            historyPublics.length > 0 &&
            labels &&
            dateDaily.length > 0
        ) {
            let data = [];
            labels.forEach((z) => {
                let obj = {};
                obj.name = z.label && z.label;
                let arr = [];
                let entries;
                historyPublics.forEach((item) => {
                    if (item) {
                        entries = Object.entries(item);
                        entries.forEach((e) => {
                            if (e[0] !== null) {
                                if (e[0] === z.value) {
                                    arr.push(new Intl.NumberFormat(["ban", "id"]).format(e[1]));
                                }
                            }
                        });
                    }
                });
                obj.data = arr;
                data.push(obj);
            });
            console.log(data, 'ini');
            setValuesPar(data);
        }
    }, [labels, historyPublics, dateDaily]);

    useEffect(() => {
        if (valuesPar && dateDaily) {
            let arr = [];
            valuesPar.forEach((val) => {
                arr.push({
                    label: val.name,
                    value: val.data,
                    date: dateDaily,
                });
            });
            let exportList = [];
            arr.forEach((arr) => {
                exportList.push([arr.label, arr.value]);
            });
            exportList.forEach((t) => {
                t[1].forEach((ti) => {
                    t.push(ti);
                });
                t.splice(1, 1);
            });
            exportList.push(["Parameter"]);
            dateDaily.forEach((a, i) => {
                exportList[exportList.length - 1].push(a);
            });
            exportList.unshift(exportList[exportList.length - 1]);
            exportList.splice(-1, 1);
            setExportList(exportList);
        }
    }, [valuesPar, dateDaily]);

    useEffect(() => {
        if (
            !isEmpty(historyPublics) &&
            templateSelect &&
            templateSelect.name === "Totalizer Mass" &&
            historyPublics.length > 0
        ) {
            if (startDate && endDate) {
                let datas = [
                    {
                        start: startDate,
                        end: endDate,
                        product: deviceSelect && deviceSelect.label,
                        total:
                            historyPublics &&
                                historyPublics[0] &&
                                historyPublics[0].mass_total.length > 0
                                ? new Intl.NumberFormat(["ban", "id"]).format(historyPublics[0].mass_total[
                                    historyPublics[0].mass_total.length - 1
                                ])
                                : "0",
                        loading:
                            historyPublics &&
                                historyPublics[0] &&
                                historyPublics[0].mass_total.length > 0
                                ? new Intl.NumberFormat(["ban", "id"]).format(historyPublics[0].mass_total[
                                    historyPublics[0].mass_total.length - 1
                                ] - historyPublics[0].mass_total[0])
                                : "0",
                    },
                ];
                setTablePeriod(datas);
            } else {
                let datas = [
                    {
                        product: deviceSelect && deviceSelect.label,
                        total:
                            historyPublics &&
                                historyPublics[0] &&
                                historyPublics[0].mass_total.length > 0
                                ? new Intl.NumberFormat(["ban", "id"]).format(historyPublics[0].mass_total[
                                    historyPublics[0].mass_total.length - 1
                                ])
                                : "0",
                        loading:
                            historyPublics &&
                                historyPublics[0] &&
                                historyPublics[0].mass_total.length > 0
                                ? new Intl.NumberFormat(["ban", "id"]).format(historyPublics[0].mass_total[
                                    historyPublics[0].mass_total.length - 1
                                ] - historyPublics[0].mass_total[0])
                                : "0",
                    },
                ];
                setTablePeriod(datas);
            }
        }

        if (templateSelect && templateSelect.type === "table" && templateSelect && templateSelect.aggregate === "none") {
            if (Array.isArray(historyPublics)) {
                console.log('data is an array');
                let arr = []
                setRawData(historyPublics)
                setExportList(historyPublics)
                historyPublics && historyPublics.forEach((his) => {
                    his.timestamp = handleValidDate(new Date(his.timestamp))
                    let entriesHis = Object.entries(his)
                    entriesHis.forEach((en, enIdx) => {
                        let fixHeader = en[0].replaceAll('_', ' ')
                        arr.push({
                            Header: fixHeader.substring(0, 1).toUpperCase() + fixHeader.substring(1),
                            accessor: en[0],
                            filterable: true
                        })
                    })
                })
                let uniqueArray = Array.from(new Set(arr.map(item => item.accessor)))
                    .map(accessor => {
                        return arr.find(a => a.accessor === accessor)
                    });
                setHeaderTable(uniqueArray)
            } else {
                console.log('data is not an array');
            }
        }
    }, [historyPublics, deviceSelect, templateSelect, startDate, endDate]);

    useEffect(() => {
      if (historyPublics.length > 0 && labels && templateSelect && templateSelect.type === "line") {
        console.log(historyPublics, 'historyPublics');
        let data = [];
        labels.forEach((z) => {
          if (z.value !== "timestamp") {
            let obj = {};
            obj.name = z.label && z.label;
            let arr = [];
            let entries;
            historyPublics && historyPublics.forEach((item) => {
              if (item) {
                entries = Object.entries(item);
                entries.forEach((e) => {
                  if (e[0] !== null) {
                    if (e[0] === z.value) {
                      arr.push(Math.round(e[1]));
                    }
                  }
                });
              }
            });
            obj.data = arr;
            data.push(obj);
          }
        });
        setSeries(historyPublics.series);
      } else if (historyPublics.length > 0 &&
          labels &&
          templateSelect &&
          templateSelect.group === "$hour" &&
          (templateSelect.type === "donut" || templateSelect.type === "pie")) {
          let arr = []
          historyPublics.forEach((his) => {
              let hisEntries = Object.entries(his)
              hisEntries.forEach((en) => {
                  if (en[0] === templateSelect.detail[0]?.parameter) {
                      arr.push(Math.round(en[1]))
                  }
              })
          })
          setSeries(arr)
      }
    }, [historyPublics, labels, templateSelect]);

    useEffect(() => {
        setPIndex(pageIndexThird);
    }, [pageIndexThird]);

    const getFlatpickrOptions = () => {
        switch (validation.values.group.value) {
            case "$hour":
                return {
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'i',
                    time_24hr: true,
                };
            case "$dayOfMonth":
                return {
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    time_24hr: true,
                };
            case "$month":
                return {
                    enableTime: true,
                    dateFormat: 'Y-m-dTH:i',
                    time_24hr: true,
                };
            default:
                return {};
        }
    };


    useEffect(() => {
        if (templateSelect) {
            console.log(templateSelect, 'template');
        }
    }, [templateSelect])

    document.title = `${t("Report")} | TELKOMSEL - IOT Monitoring`;

    return (
        <div className='page-content'>
            <ExportExcelModal
                show={isExportExcel}
                onCloseClick={() => setIsExportExcel(false)}
                data={exportList}
                filename={deviceSelect ? deviceSelect.label : []}
                dataTemplate={templateSelect ? templateSelect : []}
            />
            <ExportExcelModalObject
                show={isExportRaw}
                onCloseClick={() => setIsExportRaw(false)}
                data={rawData}
                filename={deviceSelect ? deviceSelect.label : []}
                rptType={"RawData"}
                dataTemplate={templateSelect ? templateSelect : []}
            />
            <ExportExcelModalObject
                show={isExportCSV}
                onCloseClick={() => setIsExportCSV(false)}
                data={tablePeriod}
                filename={deviceSelect ? deviceSelect.label : []}
                rptType={"Period"}
                dataTemplate={templateSelect ? templateSelect : []}
            />
            <ApproveModal
                data={reportTemplate && reportTemplate.task_name}
                show={approveModal}
                onApproveClick={handleApproveReportTemplate}
                onCloseClick={() => setApproveModal(false)}
            />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteReportTemplate}
                onCloseClick={() => setDeleteModal(false)}
            />
            <DeleteModal
                show={deleteModalDetail}
                onDeleteClick={handleDeleteReportTemplateDetail}
                onCloseClick={() => setDeleteModalDetail(false)}
            />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultiple();
                    setDeleteModalMulti(false);
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
            <Container fluid>
                <BreadCrumb title={t("Report")} pageTitle={t("Report")} />
                <Row>
                    <Col xxl={2}>
                        <Card className='card-header border-0'>
                            <CardBody className='mt-0 p-0 '>
                                <Row>
                                    <Col lg={12}>
                                        <Select
                                            name='template'
                                            // value={templateSelect ? templateSelect : ""}
                                            onChange={(e) => {
                                                setValuesPar([])
                                                setDateDaily([])
                                                dispatch(resetHistoryState())
                                                setTemplateSelect(e.item);
                                            }}
                                            validate={{ required: { value: true } }}
                                            placeholder={`${t("Select")} ${t("Template")}`}
                                            options={reportSelectList}></Select>
                                    </Col>
                                    <Col lg={12} className='mt-2'>
                                        <Select
                                            name='device'
                                            // value={deviceSelect ? deviceSelect : ""}
                                            onChange={(e) => {
                                                setDeviceSelect(e);
                                            }}
                                            validate={{ required: { value: true } }}
                                            placeholder={`${t("Select")} ${t("Device")}`}
                                            options={deviceList}></Select>
                                    </Col>
                                </Row>
                                <div className='btn-group w-100 mt-3' role='group'>
                                    <button
                                        type='button'
                                        className='btn btn-light w-50 fs-10'
                                        onClick={(e) => {
                                            if (!templateSelect || !deviceSelect) {
                                                toast.info(
                                                    "Anda harus memilih template dan device terlebih dahulu",
                                                    { autoClose: 3000 }
                                                );
                                            } else {
                                                let parameter = []
                                                let shift = []
                                                if (typeof templateSelect.detail === 'string') {
                                                    let detail = JSON.parse(templateSelect.detail)
                                                    parameter = detail.parameter
                                                    shift = detail.shift
                                                } else if (Array.isArray(templateSelect.detail)) {
                                                    templateSelect.detail.forEach((det) => {
                                                        parameter.push({
                                                            condition: det.condition,
                                                            label: det.label,
                                                            parameter: det.parameter,
                                                            value: det.value
                                                        })
                                                    })
                                                } else {
                                                    parameter = templateSelect.detail.parameter
                                                    shift = templateSelect.detail.shift
                                                }
                                                dispatch(resetHistoryState());
                                                dispatch(
                                                    getHistoryPublic({
                                                        parameter: JSON.stringify({ parameter: parameter, shift: shift.length > 0 ? shift : null }),
                                                        uid: deviceSelect.identifier,
                                                        type: "today",
                                                        aggregate: templateSelect.aggregate,
                                                        group: templateSelect.group,
                                                        shownAs: templateSelect.type
                                                    })
                                                );
                                                setDateDaily([]);
                                                setValuesPar([]);
                                            }
                                        }}>
                                        Today
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-light w-50 fs-10'
                                        onClick={(e) => {
                                            if (!templateSelect || !deviceSelect) {
                                                toast.info(
                                                    "Anda harus memilih template dan device terlebih dahulu",
                                                    { autoClose: 3000 }
                                                );
                                            } else {
                                                let parameter = []
                                                let shift = []
                                                if (typeof templateSelect.detail === 'string') {
                                                    let detail = JSON.parse(templateSelect.detail)
                                                    parameter = detail.parameter
                                                    shift = detail.shift
                                                } else if (Array.isArray(templateSelect.detail)) {
                                                    templateSelect.detail.forEach((det) => {
                                                        parameter.push({
                                                            condition: det.condition,
                                                            label: det.label,
                                                            parameter: det.parameter,
                                                            value: det.value
                                                        })
                                                    })
                                                } else {
                                                    parameter = templateSelect.detail.parameter
                                                    shift = templateSelect.detail.shift
                                                }
                                                dispatch(resetHistoryState());
                                                dispatch(
                                                    getHistoryPublic({
                                                        parameter: JSON.stringify({ parameter: parameter, shift: shift.length > 0 ? shift : null }),
                                                        uid: deviceSelect.identifier,
                                                        type: "yesterday",
                                                        aggregate: templateSelect.aggregate,
                                                        group: templateSelect.group,
                                                        shownAs: templateSelect.type
                                                    })
                                                );
                                                setDateDaily([]);
                                                setValuesPar([]);
                                            }
                                        }}>
                                        Yesterday
                                    </button>
                                </div>
                                <div className='button-group w-100'>
                                    <button
                                        type='button'
                                        className='btn btn-light w-50 fs-10'
                                        onClick={(e) => {
                                            if (!templateSelect || !deviceSelect) {
                                                toast.info(
                                                    "Anda harus memilih template dan device terlebih dahulu",
                                                    { autoClose: 3000 }
                                                );
                                            } else {
                                                let parameter = []
                                                let shift = []
                                                if (typeof templateSelect.detail === 'string') {
                                                    let detail = JSON.parse(templateSelect.detail)
                                                    parameter = detail.parameter
                                                    shift = detail.shift
                                                } else if (Array.isArray(templateSelect.detail)) {
                                                    templateSelect.detail.forEach((det) => {
                                                        parameter.push({
                                                            condition: det.condition,
                                                            label: det.label,
                                                            parameter: det.parameter,
                                                            value: det.value
                                                        })
                                                    })
                                                } else {
                                                    parameter = templateSelect.detail.parameter
                                                    shift = templateSelect.detail.shift
                                                }
                                                dispatch(resetHistoryState());
                                                dispatch(
                                                    getHistoryPublic({
                                                        parameter: JSON.stringify({ parameter: parameter, shift: shift.length > 0 ? shift : null }),
                                                        uid: deviceSelect.identifier,
                                                        type: "week",
                                                        aggregate: templateSelect.aggregate,
                                                        group: templateSelect.group,
                                                        shownAs: templateSelect.type
                                                    })
                                                );
                                                setDateDaily([]);
                                                setValuesPar([]);
                                            }
                                        }}>
                                        Week
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-light w-50 fs-10'
                                        onClick={(e) => {
                                            if (!templateSelect || !deviceSelect) {
                                                toast.info(
                                                    "Anda harus memilih template dan device terlebih dahulu",
                                                    { autoClose: 3000 }
                                                );
                                            } else {
                                                let parameter = []
                                                let shift = []
                                                if (typeof templateSelect.detail === 'string') {
                                                    let detail = JSON.parse(templateSelect.detail)
                                                    parameter = detail.parameter
                                                    shift = detail.shift
                                                } else if (Array.isArray(templateSelect.detail)) {
                                                    templateSelect.detail.forEach((det) => {
                                                        parameter.push({
                                                            condition: det.condition,
                                                            label: det.label,
                                                            parameter: det.parameter,
                                                            value: det.value
                                                        })
                                                    })
                                                } else {
                                                    parameter = templateSelect.detail.parameter
                                                    shift = templateSelect.detail.shift
                                                }
                                                dispatch(resetHistoryState());
                                                dispatch(
                                                    getHistoryPublic({
                                                        parameter: JSON.stringify({ parameter: parameter, shift: shift.length > 0 ? shift : null }),
                                                        uid: deviceSelect.identifier,
                                                        type: "month",
                                                        aggregate: templateSelect.aggregate,
                                                        group: templateSelect.group,
                                                        shownAs: templateSelect.type
                                                    })
                                                );
                                                setDateDaily([]);
                                                setValuesPar([]);
                                            }
                                        }}>
                                        Month
                                    </button>
                                </div>
                                <hr />
                                <h4>By Interval</h4>
                                <Row className='mt-3'>
                                    <Col lg={12}>
                                        <div className='search-box me-2 mb-2 col-12'>
                                            <Flatpickr
                                                className='form-control'
                                                id='datepicker-publish-input'
                                                placeholder={t("From Date")}
                                                options={{
                                                    mode: "single",
                                                    dateFormat: "Y F j H:i",
                                                    enableTime: true,
                                                    time_24hr: true
                                                }}
                                                onChange={(obj, str) => {
                                                    setFromDate(obj[0].getTime());
                                                    setStartDate(str);
                                                }}
                                            />
                                            <i className='bx bx-calendar-alt search-icon'></i>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className='search-box me-2 mb-2 col-12'>
                                            <Flatpickr
                                                className='form-control'
                                                id='datepicker-publish-input'
                                                placeholder={t("To Date")}
                                                options={{
                                                    mode: "single",
                                                    dateFormat: "Y F j H:i",
                                                    enableTime: true,
                                                    time_24hr: true
                                                }}
                                                onChange={(obj, str) => {
                                                    setToDate(obj[0].getTime());
                                                    setEndDate(str);
                                                }}
                                            />
                                            <i className='bx bx-calendar-alt search-icon'></i>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='w-100'>
                                    <button
                                        type='button'
                                        className='btn btn-primary add-btn w-100'
                                        id='create-btn'
                                        onClick={() => {
                                            if (!templateSelect || !deviceSelect) {
                                                toast.info(
                                                    "Anda harus memilih template dan device terlebih dahulu",
                                                    { autoClose: 3000 }
                                                );
                                            } else if (!fromDate || !toDate) {
                                                toast.info("Anda harus memilih date terlebih dahulu", {
                                                    autoClose: 3000,
                                                });
                                            } else {
                                                let parameter = []
                                                let shift = []
                                                if (typeof templateSelect.detail === 'string') {
                                                    let detail = JSON.parse(templateSelect.detail)
                                                    parameter = detail.parameter
                                                    shift = detail.shift
                                                } else if (Array.isArray(templateSelect.detail)) {
                                                    templateSelect.detail.forEach((det) => {
                                                        parameter.push({
                                                            condition: det.condition,
                                                            label: det.label,
                                                            parameter: det.parameter,
                                                            value: det.value
                                                        })
                                                    })
                                                } else {
                                                    parameter = templateSelect.detail.parameter
                                                    shift = templateSelect.detail.shift
                                                }
                                                dispatch(resetHistoryState());
                                                dispatch(
                                                    getHistoryPublic({
                                                        parameter: JSON.stringify({ parameter: parameter, shift: shift.length > 0 ? shift : null }),
                                                        uid: deviceSelect.identifier,
                                                        start: fromDate,
                                                        end: toDate,
                                                        type: "period",
                                                        aggregate: templateSelect.aggregate || "",
                                                        group: templateSelect.group || "",
                                                        shownAs: templateSelect.type
                                                    })
                                                );
                                                setDateDaily([]);
                                                setValuesPar([]);
                                            }
                                        }}>
                                        <i className='ri-file-chart-line me-1 align-bottom fs-14'></i>{" "}
                                        {t("Execute")}
                                    </button>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader
                                className='card-header border-0'
                            // style={{ backgroundColor: "#f2f2f7" }}
                            >
                                <div className='d-flex align-items-center'>
                                    <h5 className='card-title mb-0 flex-grow-1'>
                                        {t("Report Template")} &nbsp;
                                        {isLoading ? (
                                            <>
                                                <Spinner color='primary' type='grow' size={"sm"}>
                                                    Loading...
                                                </Spinner>
                                                &nbsp;
                                                <Spinner color='success' type='grow' size={"sm"}>
                                                    Loading...
                                                </Spinner>
                                                &nbsp;
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </h5>
                                </div>
                            </CardHeader>
                            <CardBody className='pt-2'>
                                <div>
                                    <div className='flex-shrink-0 mb-4'>
                                        <button
                                            type='button'
                                            className='btn btn-primary add-btn w-100'
                                            id='create-btn'
                                            onClick={() => {
                                                setDeviceId(-1);
                                                setReportTemplate("");
                                                setIsEdit(false);
                                                toggle();
                                            }}>
                                            <i className='ri-file-chart-line me-1 align-bottom fs-14'></i>{" "}
                                            {t("Add")}
                                        </button>{" "}
                                        <button
                                            className='btn btn-soft-danger d-none'
                                            onClick={() => setDeleteModalMulti(true)}>
                                            <i className='ri-delete-bin-5-line me-1 align-bottom fs-15'></i>{" "}
                                            {t("Delete")}
                                        </button>
                                        <div className='search-box w-100 d-inline-block mt-2'>
                                            <input
                                                onChange={(e) => {
                                                    setSearchValue(e.target.value);
                                                    onChange(e.target.value);
                                                }}
                                                id='search-bar-0'
                                                type='text'
                                                className='form-control search w-100'
                                                placeholder={`${count} ${t("Search")}...`}
                                                value={searchValue || ""}
                                            />
                                            <i className='bx bx-search-alt search-icon'></i>
                                        </div>
                                    </div>
                                    <Row className='mb-3 d-none'>
                                        <CardBody className='border border-top-0 border-end-0 border-start-0'>
                                            <form>
                                                <Row>
                                                    <Col sm={1} className='col-xxl-1'>
                                                        <div className='me-2 mb-2 col-12'>
                                                            <select
                                                                className='form-select'
                                                                value={pageSize}
                                                                onChange={onChangeInSelect}>
                                                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                                                    <option key={pageSize} value={pageSize}>
                                                                        {pageSize}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <div className='search-box me-2 mb-2 d-inline-block col-12'>
                                                            <input
                                                                onChange={(e) => {
                                                                    setSearchValue(e.target.value);
                                                                    onChange(e.target.value);
                                                                }}
                                                                id='search-bar-0'
                                                                type='text'
                                                                className='form-control search'
                                                                placeholder={`${count} ${t("Search")}...`}
                                                                value={searchValue || ""}
                                                            />
                                                            <i className='bx bx-search-alt search-icon'></i>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} className='col-xxl-3'>
                                                        <div className='search-box me-2 mb-2 col-12'>
                                                            <Flatpickr
                                                                className='form-control'
                                                                id='datepicker-publish-input'
                                                                placeholder={t("Select created date")}
                                                                options={{
                                                                    mode: "range",
                                                                    dateFormat: "Y/m/d",
                                                                }}
                                                                onChange={(obj, str) => {
                                                                    setFilterDate(str);
                                                                }}
                                                            />
                                                            <i className='bx bx-calendar-alt search-icon'></i>
                                                        </div>
                                                    </Col>
                                                    <Col sm={4} className='col-xxl-2'>
                                                        <div className='me-2 mb-2 col-12'>
                                                            <Select
                                                                value={statusActive}
                                                                placeholder={t("Select Status")}
                                                                onChange={(e) => {
                                                                    setStatusActive(e);
                                                                }}
                                                                options={statusActiveData}
                                                                name='choices-single-default'
                                                                id='idStatus'></Select>
                                                        </div>
                                                    </Col>
                                                    <Col className='col-xxl-2'>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-light w-100'
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleFilter();
                                                                }}>
                                                                <i className='ri-search-line me-1 align-bottom fs-14'></i>{" "}
                                                                {t("Search")}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </CardBody>
                                    </Row>
                                    <div className='table-responsive table-card mb-1'>
                                        <Table
                                            hover
                                            {...getTableProps()}
                                            className='align-middle table-nowrap'>
                                            <thead className='table-light text-muted'>
                                                {headerGroups.map((headerGroup) => (
                                                    <tr
                                                        className={""}
                                                        key={headerGroup.id}
                                                        {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map((column) => (
                                                            <th
                                                                key={column.id}
                                                                className={"" + generateFilterable(column)}
                                                                {...column.getSortByToggleProps()}>
                                                                {column.render("Header")}
                                                                {generateSortingIndicator(column)}
                                                                {/* <Filter column={column} /> */}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {page.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <Fragment key={row.getRowProps().key}>
                                                            <tr>
                                                                {row.cells.map((cell) => {
                                                                    return (
                                                                        <td key={cell.id} {...cell.getCellProps()}>
                                                                            {cell.render("Cell")}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col className='col-md-auto d-none d-md-block'>
                                            {t("Page")}
                                            <strong>
                                                {pageIndex + 1} of {pageOptions.length}{" "}
                                            </strong>
                                        </Col>
                                    </Row>

                                    <Row className=''>
                                        <div className='pe-2 col-lg-3'>
                                            <Button
                                                className='w-100 align-items-end'
                                                color='light'
                                                onClick={previousPage}
                                                disabled={!canPreviousPage}>
                                                {"<"}
                                            </Button>
                                        </div>
                                        <div className='p-0 col-lg-6'>
                                            <Input
                                                type='number'
                                                min={1}
                                                className='w-100'
                                                max={pageOptions.length}
                                                defaultValue={pageIndex + 1}
                                                onChange={onChangeInInput}
                                            />
                                        </div>
                                        <div className='ps-2 col-lg-3 align-items-start'>
                                            <Button
                                                className='w-100'
                                                color='light'
                                                onClick={nextPage}
                                                disabled={!canNextPage}>
                                                {">"}
                                            </Button>
                                        </div>
                                    </Row>
                                </div>
                                <Modal id='showModal' isOpen={modal} centered size='xl'>
                                    <ModalHeader className='bg-light p-3' toggle={toggle}>
                                        {!!isEdit
                                            ? `${t("Edit")} ${t("Report Template")}`
                                            : `${t("Add")} ${t("Report Template")}`}
                                    </ModalHeader>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                        <ModalBody>
                                            <Row>
                                                <Col lg={3}>
                                                    {userType === "admin" || userType === "user" ? (
                                                        <>
                                                            <div className='mb-3'>
                                                                <Label
                                                                    htmlFor='password-field'
                                                                    className='form-label'>
                                                                    {t("Client")}
                                                                </Label>
                                                                <Select
                                                                    name='client'
                                                                    value={validation.values.client}
                                                                    placeholder={`${t("Select")} ${t("Client")}`}
                                                                    onChange={(e) => {
                                                                        validation.setFieldValue("client", e);
                                                                        handleCompany(e);
                                                                    }}
                                                                    options={clientList}></Select>
                                                            </div>
                                                            <div className='mb-3'>
                                                                <Label
                                                                    htmlFor='password-field'
                                                                    className='form-label'>
                                                                    {t("Company")}
                                                                </Label>
                                                                {companiesLoading ? (
                                                                    <>
                                                                        <Spinner
                                                                            color='primary'
                                                                            type='grow'
                                                                            size={"sm"}
                                                                            className='ms-2'></Spinner>
                                                                        &nbsp;
                                                                        <Spinner
                                                                            color='success'
                                                                            type='grow'
                                                                            size={"sm"}>
                                                                            Loading...
                                                                        </Spinner>
                                                                        &nbsp;
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <Select
                                                                    name='company'
                                                                    value={validation.values.company}
                                                                    placeholder={`${t("Select")} ${t("Company")}`}
                                                                    onChange={(e) => {
                                                                        validation.setFieldValue("company", e);
                                                                        handleDevice(e);
                                                                    }}
                                                                    options={companyList}></Select>
                                                            </div>
                                                        </>
                                                    ) : userType === "client" ? (
                                                        <>
                                                            <div className='mb-3'>
                                                                <Label
                                                                    htmlFor='password-field'
                                                                    className='form-label'>
                                                                    {t("Company")}
                                                                </Label>
                                                                {companiesLoading ? (
                                                                    <>
                                                                        <Spinner
                                                                            color='primary'
                                                                            type='grow'
                                                                            size={"sm"}
                                                                            className='ms-2'></Spinner>
                                                                        &nbsp;
                                                                        <Spinner
                                                                            color='success'
                                                                            type='grow'
                                                                            size={"sm"}>
                                                                            Loading...
                                                                        </Spinner>
                                                                        &nbsp;
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <Select
                                                                    name='company'
                                                                    value={validation.values.company}
                                                                    placeholder={`${t("Select")} ${t("Company")}`}
                                                                    onChange={(e) => {
                                                                        validation.setFieldValue("company", e);
                                                                        handleDevice(e);
                                                                    }}
                                                                    options={companyList}></Select>
                                                            </div>
                                                        </>
                                                    ) : null}

                                                    <div className='mb-3'>
                                                        <Label
                                                            htmlFor='active-status'
                                                            className='form-label'>
                                                            {t("Type")} <span className='text-danger'>*</span>
                                                        </Label>
                                                        <Select
                                                            name='type'
                                                            value={validation.values.type}
                                                            validate={{ required: { value: true } }}
                                                            placeholder={`${t("Select")} ${t("Type")}`}
                                                            onChange={(e) => {
                                                                validation.setFieldValue("type", e);
                                                                setTemplateValue(e.value);
                                                            }}
                                                            options={template}></Select>
                                                        {validation.touched.type && (
                                                            <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                                                {validation.errors.type}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className='mb-3'>
                                                        <Label
                                                            htmlFor='active-status'
                                                            className='form-label'>
                                                            {t("Aggregate")} <span className='text-danger'>*</span>
                                                        </Label>
                                                        <Select
                                                            name='aggregate'
                                                            value={validation.values.aggregate ? validation.values.aggregate : { label: "None", value: "none" }}
                                                            validate={{ required: { value: true } }}
                                                            placeholder={`${t("Select")} ${t("Aggregate")}`}
                                                            onChange={(e) => {
                                                                validation.setFieldValue("aggregate", e);
                                                                setTemplateValue(e.value);
                                                            }}
                                                            options={aggregate}></Select>
                                                    </div>
                                                    <div className='mb-3'>
                                                        <Label
                                                            htmlFor='active-status'
                                                            className='form-label'>
                                                            {t("Group by")} <span className='text-danger'>*</span>
                                                        </Label>
                                                        <Select
                                                            name='group'
                                                            value={validation.values.group ? validation.values.group : { label: "None", value: "none" }}
                                                            validate={{ required: { value: true } }}
                                                            placeholder={`${t("Select")} ${t("Group")}`}
                                                            onChange={(e) => {
                                                                validation.setFieldValue("group", e);
                                                                setShift([])
                                                            }}
                                                            options={dataGroupBy}></Select>
                                                    </div>
                                                    <div className='mb-3'>
                                                        <Label
                                                            htmlFor='task_name-field'
                                                            className='form-label'>
                                                            {t("Name")} <span className='text-danger'>*</span>
                                                        </Label>
                                                        <Input
                                                            name='name'
                                                            id='name-field'
                                                            className='form-control'
                                                            placeholder={`${t("Enter")} ${t("Name")}`}
                                                            type='text'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.name}
                                                            invalid={
                                                                validation.touched.name &&
                                                                    validation.errors.name
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        {validation.touched.name &&
                                                            validation.errors.name ? (
                                                            <FormFeedback type='invalid'>
                                                                {validation.errors.name}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className='mb-3'>
                                                        <Label
                                                            htmlFor='description-field'
                                                            className='form-label'>
                                                            {t("Description")}
                                                        </Label>
                                                        <Input
                                                            name='description'
                                                            id='description-field'
                                                            rows='4'
                                                            className='form-control'
                                                            placeholder={`${t("Enter")} ${t("Description")}`}
                                                            type='textarea'
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.description}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={9}>
                                                    <ul
                                                        className='nav nav-pills nav-justified'
                                                        id='myTab'
                                                        role='tablist'>
                                                        <li className='nav-item' role='presentation'>
                                                            <button
                                                                className='nav-link active'
                                                                id='parameter-tab'
                                                                data-bs-toggle='tab'
                                                                data-bs-target='#parameter'
                                                                type='button'
                                                                role='tab'
                                                                aria-controls='parameter'
                                                                aria-selected='true'>
                                                                Shift
                                                            </button>
                                                        </li>
                                                        <li className='nav-item' role='presentation'>
                                                            <button
                                                                className='nav-link'
                                                                id='table-tab'
                                                                data-bs-toggle='tab'
                                                                data-bs-target='#table'
                                                                type='button'
                                                                role='tab'
                                                                aria-controls='table'
                                                                aria-selected='false'>
                                                                Detail
                                                            </button>
                                                        </li>
                                                    </ul>
                                                    <div className='tab-content'>
                                                        <div
                                                            className='tab-pane active'
                                                            id='parameter'
                                                            role='tabpanel'
                                                            aria-labelledby='parameter-tab'
                                                            tabIndex='0'>
                                                            <div className='p-2 d-flex justify-content-end'>
                                                                {(validation.values.group.value === "$dayOfMonth" || validation.values.group.value === "$hour" || validation.values.group.value === "$month") ? (
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-primary add-btn me-2'
                                                                        id='create-btn'
                                                                        onClick={() => {
                                                                            handleAddShift();
                                                                            setIsShift(true);
                                                                        }}>
                                                                        <i className='ri-list-check label-icon align-middle fs-13 me-1'></i>
                                                                        {`${t("Add")} ${t("Shift")}`}
                                                                    </button>
                                                                ) : null}
                                                            </div>
                                                            {isShift ? (
                                                                <Card>
                                                                    <CardBody>
                                                                        <div className='row mt-4'>
                                                                            <div className='col-md-3'>
                                                                                <Label>Name</Label>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <Label>Start</Label>
                                                                            </div>
                                                                            <div className='col-md-4'>
                                                                                <Label>End</Label>
                                                                            </div>
                                                                        </div>
                                                                        {(shift || []).map((shifts, idxShift) => {
                                                                            return (
                                                                                <div className='border-bottom mb-0' key={"idxShift-" + idxShift}>
                                                                                    <div className="row mt-3">
                                                                                        <div className="col-lg-3">
                                                                                            <div className='mb-3'>
                                                                                                <Input
                                                                                                    name={`shift.${idxShift}.name`}
                                                                                                    className='form-control'
                                                                                                    placeholder={`${t("Enter")} ${t(
                                                                                                        "Label"
                                                                                                    )}`}
                                                                                                    type='text'
                                                                                                    value={
                                                                                                        shift[idxShift].name || ""
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        validation.setFieldValue(
                                                                                                            `shift.${idxShift}.name`,
                                                                                                            e.target.value
                                                                                                        );
                                                                                                        shift[idxShift].name =
                                                                                                            e.target.value;
                                                                                                    }}
                                                                                                    onBlur={validation.handleBlur}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-4">
                                                                                            <div className='mb-3'>
                                                                                                <Flatpickr
                                                                                                    className='form-control'
                                                                                                    id='datepicker-publish-input'
                                                                                                    ref={flatpickrRef}
                                                                                                    placeholder={t("From Hour")}
                                                                                                    value={
                                                                                                        shift[idxShift].from || ""
                                                                                                    }
                                                                                                    options={getFlatpickrOptions()}
                                                                                                    onChange={(obj, str) => {
                                                                                                        shift[idxShift].from = str;
                                                                                                        // setFromDate(obj[0].getTime());
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-4">
                                                                                            <div className='mb-3'>
                                                                                                <Flatpickr
                                                                                                    className='form-control'
                                                                                                    id='datepicker-publish-input'
                                                                                                    ref={flatpickrRef}
                                                                                                    placeholder={t("To Hour")}
                                                                                                    value={
                                                                                                        shift[idxShift].to || ""
                                                                                                    }
                                                                                                    options={getFlatpickrOptions()}
                                                                                                    onChange={(obj, str) => {
                                                                                                        shift[idxShift].to = str
                                                                                                        // setToDate(obj[0].getTime());
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-md-1'>
                                                                                            <div className='mb-3'>
                                                                                                <Link
                                                                                                    to='#'
                                                                                                    className='btn-icon btn btn-sm btn-soft-danger'
                                                                                                    onClick={(e) => {
                                                                                                        // e.preventDefault();
                                                                                                        handleDeleteShift(shifts);
                                                                                                        forceUpdate()
                                                                                                    }}>
                                                                                                    <i className='ri-delete-bin-5-line fs-16'></i>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </CardBody>
                                                                </Card>
                                                            ) : null}
                                                        </div>
                                                        <div
                                                            className='tab-pane'
                                                            id='table'
                                                            role='tabpanel'
                                                            aria-labelledby='table-tab'
                                                            tabIndex='0'>
                                                            <div className='p-2 d-flex justify-content-end'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-success add-btn'
                                                                    id='create-btn'
                                                                    onClick={() => {
                                                                        handleDetailAdd();
                                                                        setIsDetailClick(true);
                                                                    }}>
                                                                    <i className='ri-list-check label-icon align-middle fs-13 me-1'></i>
                                                                    {`${t("Add")} ${t("Detail")}`}
                                                                </button>
                                                            </div>
                                                            {isDetailClick ? (
                                                                <Card>
                                                                    <CardBody>
                                                                        <div className='row mt-4'>
                                                                            <div className='col-md-3'>
                                                                                <Label>Label</Label>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <Label>Parameter</Label>
                                                                            </div>
                                                                            <div className='col-md-2'>
                                                                                <Label>Condition</Label>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <Label>Value</Label>
                                                                            </div>
                                                                        </div>
                                                                        {templateDetail && templateDetail.map((item, i) => (
                                                                            <div className='border-bottom mb-0' key={i}>
                                                                                <div className='row mt-3'>
                                                                                    <div className='col-md-3'>
                                                                                        <div className='mb-3'>
                                                                                            <Input
                                                                                                name={`templateDetail.${i}.label`}
                                                                                                className='form-control'
                                                                                                placeholder={`${t("Enter")} ${t(
                                                                                                    "Label"
                                                                                                )}`}
                                                                                                type='text'
                                                                                                value={
                                                                                                    templateDetail[i].label || ""
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    validation.setFieldValue(
                                                                                                        `templateDetail.${i}.label`,
                                                                                                        e.target.value
                                                                                                    );
                                                                                                    templateDetail[i].label =
                                                                                                        e.target.value;
                                                                                                }}
                                                                                                onBlur={validation.handleBlur}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-3'>
                                                                                        <div className='mb-3'>
                                                                                            <Select
                                                                                                name={`templateDetail.${i}.parameter`}
                                                                                                value={getParameterValue((templateDetail[i] && templateDetail[i].parameter) || -1, parameterList)}
                                                                                                validate={{ required: { value: true } }}
                                                                                                placeholder={`${t("Select")} ${t(
                                                                                                    "Parameter"
                                                                                                )}`}
                                                                                                onChange={(e) => {
                                                                                                    const fc = [];
                                                                                                    parameterSelects.forEach((itm) => {
                                                                                                        fc.push(itm);
                                                                                                    });
                                                                                                    fc.push(e.name);
                                                                                                    setParameterSelects(fc);
                                                                                                    validation.setFieldValue(
                                                                                                        `templateDetail.${i}.parameter`,
                                                                                                        e.name
                                                                                                    );
                                                                                                    templateDetail[i].parameter = e.name;
                                                                                                }}
                                                                                                options={getParameterOptions}></Select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-2'>
                                                                                        <div className='mb-3'>
                                                                                            <Select
                                                                                                name={`templateDetail.${i}.condition`}
                                                                                                value={{
                                                                                                    label:
                                                                                                        templateDetail[
                                                                                                            i
                                                                                                        ] &&
                                                                                                            templateDetail[i]
                                                                                                                ?.condition === "$eq"
                                                                                                            ? "="
                                                                                                            : templateDetail[i]
                                                                                                                ?.condition === "$gt"
                                                                                                                ? ">"
                                                                                                                : templateDetail[i]
                                                                                                                    ?.condition === "$lt"
                                                                                                                    ? "<"
                                                                                                                    : templateDetail[i]
                                                                                                                        ?.condition === "$gte"
                                                                                                                        ? ">="
                                                                                                                        : validation.values
                                                                                                                            .templateDetail[i]
                                                                                                                            ?.condition === "$lte"
                                                                                                                            ? "<="
                                                                                                                            : "",
                                                                                                    value:
                                                                                                        templateDetail[
                                                                                                        i
                                                                                                        ] &&
                                                                                                        templateDetail[i]
                                                                                                            .condition,
                                                                                                }}
                                                                                                validate={{ required: { value: true } }}
                                                                                                placeholder={`${t("Select")} ${t(
                                                                                                    "Condition"
                                                                                                )}`}
                                                                                                onChange={(e) => {
                                                                                                    validation.setFieldValue(
                                                                                                        `templateDetail.${i}.condition`,
                                                                                                        e.value || ""
                                                                                                    );
                                                                                                    templateDetail[i].condition =
                                                                                                        e.value || "";
                                                                                                }}
                                                                                                options={condition}></Select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-3'>
                                                                                        <div className='mb-3'>
                                                                                            <Input
                                                                                                name={`templateDetail.${i}.value`}
                                                                                                className='form-control'
                                                                                                placeholder={`${t("Enter")} ${t(
                                                                                                    "Value"
                                                                                                )}`}
                                                                                                type='number'
                                                                                                value={
                                                                                                    templateDetail[i].value
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    validation.setFieldValue(
                                                                                                        `templateDetail.${i}.value`,
                                                                                                        parseInt(e.target.value)
                                                                                                    );
                                                                                                    templateDetail[i].value = parseInt(
                                                                                                        e.target.value
                                                                                                    );
                                                                                                }}
                                                                                                onBlur={validation.handleBlur}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-1'>
                                                                                        <div className='mb-3'>
                                                                                            <Link
                                                                                                to='#'
                                                                                                className='btn-icon btn btn-sm btn-soft-danger'
                                                                                                onClick={(e) => {
                                                                                                    // e.preventDefault();
                                                                                                    handleDeleteParameters(item);
                                                                                                    forceUpdate()
                                                                                                }}>
                                                                                                <i className='ri-delete-bin-5-line fs-16'></i>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </CardBody>
                                                                </Card>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <div className='modal-footer'>
                                            <div className='hstack gap-2 justify-content-end'>
                                                <button
                                                    type='button'
                                                    className='btn btn-light'
                                                    onClick={() => {
                                                        setModal(false);
                                                        validation.resetForm();
                                                        setTemplateDetail([]);
                                                        setIsDetailClick(false);
                                                        forceUpdate();
                                                    }}>
                                                    {t("Close")}
                                                </button>
                                                <button type='submit' className='btn btn-success'>
                                                    <span className='d-flex align-items-center'>
                                                        <span className='flex-grow-1 me-2'>
                                                            {!!isEdit ? `${t("Update")}` : `${t("Add")}`}
                                                        </span>
                                                        {loading && (
                                                            <Spinner
                                                                size='sm'
                                                                className='flex-shrink-0'
                                                                role='status'>
                                                                Loading...
                                                            </Spinner>
                                                        )}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </Modal>
                                <ToastContainer closeButton={false} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={10}>
                        <Card style={{ minHeight: "516px" }}>
                            <CardBody>
                                {valuesPar.length > 0 &&
                                    dateDaily.length > 0 &&
                                    templateSelect &&
                                    templateSelect.type === "table" &&
                                    templateSelect.aggregate === "$first" ? (
                                    <Fragment>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <h5 className="mt-2">{templateSelect.name}</h5>
                                            </div>
                                            <div className='flex-shrink-0'>
                                                <button
                                                    type='button'
                                                    className='btn btn-success mb-3'
                                                    onClick={() => setIsExportExcel(true)}>
                                                    <i className='ri-file-download-line me-1 align-bottom fs-14'></i>{" "}
                                                    Export
                                                </button>
                                            </div>
                                        </div>
                                        <div className='table-responsive table-card mb-1 mt-0'>
                                            <Table bordered>
                                                <thead>
                                                    <tr>
                                                        <th className='table-light'>Parameter</th>
                                                        {(dateDaily || []).map((date, idxDate) => {
                                                            return (
                                                                <th
                                                                    key={"idxDate" + idxDate}
                                                                    className='table-light'>
                                                                    {date}
                                                                </th>
                                                            );
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(valuesPar || []).map((val, idxVal) => {
                                                        return (
                                                            <tr key={"idxVal" + idxVal}>
                                                                <th scope='row'>{val.name}</th>
                                                                {(val.data || []).map((datas, idxDatas) => {
                                                                    return (
                                                                        <td key={"idxDatas" + idxDatas}>{datas}</td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Fragment>
                                ) : valuesPar.length > 0 &&
                                    dateDaily.length > 0 && templateSelect &&
                                    templateSelect.name === "table" ? (
                                    <Fragment>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <h5 className="mt-2">{templateSelect.name}</h5>
                                            </div>
                                            <div className='flex-shrink-0'>
                                                <button
                                                    type='button'
                                                    className='btn btn-success mb-3'
                                                    onClick={() => setIsExportExcel(true)}>
                                                    <i className='ri-file-download-line me-1 align-bottom fs-14'></i>{" "}
                                                    Export
                                                </button>
                                            </div>
                                        </div>
                                        <div className='table-responsive table-card mb-1 mt-0'>
                                            <Table bordered>
                                                <thead>
                                                    <tr>
                                                        <th className='table-light'>Parameter</th>
                                                        {(dateDaily || []).map((date, idxDate) => {
                                                            return (
                                                                <th
                                                                    key={"idxDate" + idxDate}
                                                                    className='table-light'>
                                                                    {date}
                                                                </th>
                                                            );
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(valuesPar || []).map((val, idxVal) => {
                                                        return (
                                                            <tr key={"idxVal" + idxVal}>
                                                                <th scope='row'>{val.name}</th>
                                                                {(val.data || []).map((datas, idxDatas) => {
                                                                    return (
                                                                        <td key={"idxDatas" + idxDatas}>{datas}</td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Fragment>
                                ) : null}
                                {templateSelect &&
                                    templateSelect.name === "Totalizer Mass" &&
                                    historyPublics &&
                                    historyPublics[0] &&
                                    historyPublics[0].mass_total ? (
                                    <Fragment>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <h5 className="mt-2">{templateSelect.name}</h5>
                                            </div>
                                            <div className='flex-shrink-0'>
                                                <button
                                                    type='button'
                                                    className='btn btn-success mb-3'
                                                    onClick={() => setIsExportCSV(true)}>
                                                    <i className='ri-file-download-line me-1 align-bottom fs-14'></i>{" "}
                                                    Export
                                                </button>
                                            </div>
                                        </div>
                                        <div className='table-responsive table-card mb-1 mt-3'>
                                            <Table
                                                hover
                                                {...getTablePropsSecond()}
                                                className='align-middle table-nowrap'>
                                                <thead className='table-light text-muted'>
                                                    {headerGroupsSecond.map((headerGroup) => (
                                                        <tr
                                                            className={""}
                                                            key={headerGroup.id}
                                                            {...headerGroup.getHeaderGroupProps()}>
                                                            {headerGroup.headers.map((column) => (
                                                                <th
                                                                    key={column.id}
                                                                    className={"" + generateFilterable(column)}
                                                                    {...column.getSortByToggleProps()}>
                                                                    {column.render("Header")}
                                                                    {generateSortingIndicator(column)}
                                                                    {/* <Filter column={column} /> */}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </thead>
                                                <tbody {...getTableBodyProps()}>
                                                    {pageSecond.map((row) => {
                                                        prepareRowSecond(row);
                                                        return (
                                                            <Fragment key={row.getRowProps().key}>
                                                                <tr>
                                                                    {row.cells.map((cell) => {
                                                                        return (
                                                                            <td key={cell.id} {...cell.getCellProps()}>
                                                                                {cell.render("Cell")}
                                                                            </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            </Fragment>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Fragment>
                                ) : null}
                                {templateSelect && templateSelect.type !== "table" && dateDaily.length > 0 ? (
                                    <Fragment>
                                        <h5 className="mt-2">{templateSelect.name}</h5>
                                        <ChartReport
                                            series={series}
                                            seriesCategories={dateDaily}
                                            templateSelect={templateSelect}
                                        />
                                    </Fragment>
                                ) : null}
                                {templateSelect &&
                                    (templateSelect.type === "table" && templateSelect.name === "Raw Data History") ? (
                                    <Fragment>
                                        {historyPublics && historyPublics[0] ? (
                                            <>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mt-2">{templateSelect.name}</h5>
                                                    </div>
                                                    <div className='flex-shrink-0'>
                                                        <button
                                                            type='button'
                                                            className='btn btn-success mb-3'
                                                            onClick={() => setIsExportRaw(true)}>
                                                            <i className='ri-file-download-line me-1 align-bottom fs-14'></i>{" "}
                                                            Export
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className='me-2 mb-2 col-3'>
                                                        <select
                                                            className='form-select'
                                                            value={pageSizeThird}
                                                            onChange={onChangeInSelectThird}>
                                                            {[50, 100, 250, 500, 1000].map((pageSizeThird) => (
                                                                <option key={pageSizeThird} value={pageSizeThird}>
                                                                    {pageSizeThird}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='table-responsive table-card mb-1 mt-3'>
                                                    <Table
                                                        hover
                                                        {...getTablePropsThird()}
                                                        className='align-middle table-nowrap'>
                                                        <thead className='table-light text-muted'>
                                                            {headerGroupsThird.map((headerGroup) => (
                                                                <tr
                                                                    className={""}
                                                                    key={headerGroup.id}
                                                                    {...headerGroup.getHeaderGroupProps()}>
                                                                    {headerGroup.headers.map((column) => (
                                                                        <th
                                                                            key={column.id}
                                                                            className={"" + generateFilterable(column)}
                                                                            {...column.getSortByToggleProps()}>
                                                                            {column.render("Header")}
                                                                            {generateSortingIndicator(column)}
                                                                            {/* <Filter column={column} /> */}
                                                                        </th>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </thead>
                                                        <tbody {...getTableBodyProps()}>
                                                            {pageThird.map((row) => {
                                                                prepareRowThird(row);
                                                                return (
                                                                    <Fragment key={row.getRowProps().key}>
                                                                        <tr>
                                                                            {row.cells.map((cell) => {
                                                                                return (
                                                                                    <td key={cell.id} {...cell.getCellProps()}>
                                                                                        {cell.render("Cell")}
                                                                                    </td>
                                                                                );
                                                                            })}
                                                                        </tr>
                                                                    </Fragment>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                                                    <Col className='col-md-auto'>
                                                        <div className='d-flex gap-1'>
                                                            <Button
                                                                className='btn btn-light'
                                                                onClick={previousPageThird}
                                                                disabled={!canPreviousPageThird}>
                                                                {"<"}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                    <Col className='col-md-auto d-none d-md-block'>
                                                        {`${t("Page")} `}
                                                        <strong>
                                                            {pageIndexThird + 1} {t("of")} {pageOptionsThird.length}
                                                        </strong>
                                                    </Col>
                                                    <Col className='col-md-auto'>
                                                        <Input
                                                            type='number'
                                                            min={1}
                                                            style={{ width: 70 }}
                                                            max={pageOptionsThird.length}
                                                            value={pIndex + 1}
                                                            onChange={onChangeInInputThird}
                                                        />
                                                    </Col>

                                                    <Col className='col-md-auto'>
                                                        <div className='d-flex gap-1'>
                                                            <Button
                                                                className='btn btn-light'
                                                                onClick={nextPageThird}
                                                                disabled={!canNextPageThird}>
                                                                {">"}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <Spinner color='primary' type='grow' size={"sm"}>
                                                    Loading...
                                                </Spinner>
                                                &nbsp;
                                                <Spinner color='success' type='grow' size={"sm"}>
                                                    Loading...
                                                </Spinner>
                                                &nbsp;
                                            </>
                                        )}

                                    </Fragment>
                                ) : null}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

ReportCbu.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(ReportCbu));
