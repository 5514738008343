import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_STORAGEMOVE,
  DELETE_STORAGEMOVE,
  UPDATE_STORAGEMOVE,
  ADD_NEW_STORAGEMOVE,
  GET_STORAGEMOVE_ONE,
} from "./actionType";

import {
  getStorageMoveSuccess,
  getStorageMoveFail,
  deleteStorageMoveSuccess,
  deleteStorageMoveFail,
  updateStorageMoveSuccess,
  updateStorageMoveFail,
  addStorageMoveSuccess,
  addStorageMoveFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveStorageMoveApi,
  getStorageMoveApi,
  updateStorageMoveApi,
  deleteStorageMoveApi,
  addNewStorageMoveApi,
  getOneStorageMoveApi,
} from "../../helpers/backend_helper";

function* getStorageMove({ payload: storageMove }) {
  try {
    const response = yield call(getStorageMoveApi, storageMove);
    yield put(getStorageMoveSuccess(GET_STORAGEMOVE, response.data));
  } catch (error) {
    yield put(getStorageMoveFail(GET_STORAGEMOVE, error));
  }
}

function* getStorageMoveOne({ payload: id }) {
  try {
    const response = yield call(getOneStorageMoveApi, id);
    yield put(getStorageMoveSuccess(GET_STORAGEMOVE_ONE, response.data));
  } catch (error) {
    yield put(getStorageMoveFail(GET_STORAGEMOVE_ONE, error));
  }
}

function* onUpdateStorageMove({ payload: storageMove }) {
  try {
    if (storageMove.setApprove) {
      const response = yield call(approveStorageMoveApi, storageMove);
      yield put(updateStorageMoveSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateStorageMoveApi, storageMove);
      yield put(updateStorageMoveSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateStorageMoveFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteStorageMove({ payload: storageMove }) {
  try {
    const response = yield call(deleteStorageMoveApi, storageMove);
    yield put(deleteStorageMoveSuccess({ storageMove, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteStorageMoveFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewStorageMove({ payload: storageMove }) {
  try {
    const response = yield call(addNewStorageMoveApi, storageMove);
    yield put(addStorageMoveSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addStorageMoveFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetStorageMove() {
  yield takeEvery(GET_STORAGEMOVE, getStorageMove);
}

export function* watchUpdateStorageMove() {
  yield takeEvery(UPDATE_STORAGEMOVE, onUpdateStorageMove);
}

export function* watchDeleteStorageMove() {
  yield takeEvery(DELETE_STORAGEMOVE, onDeleteStorageMove);
}

export function* watchAddNewStorageMove() {
  yield takeEvery(ADD_NEW_STORAGEMOVE, onAddNewStorageMove);
}

export function* watchGetStorageMoveOne() {
  yield takeEvery(GET_STORAGEMOVE_ONE, getStorageMoveOne);
}

function* storageMoveSaga() {
  yield all([
    fork(watchGetStorageMove),
    fork(watchDeleteStorageMove),
    fork(watchUpdateStorageMove),
    fork(watchAddNewStorageMove),
    fork(watchGetStorageMoveOne),
  ]);
}

export default storageMoveSaga;
