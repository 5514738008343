import {
  GET_ACTIVITYLOG,
  GET_ACTIVITYLOG_SUCCESS,
  GET_ACTIVITYLOG_FAIL,
  DELETE_ACTIVITYLOG,
  DELETE_ACTIVITYLOG_SUCCESS,
  DELETE_ACTIVITYLOG_FAIL,
  UPDATE_ACTIVITYLOG,
  UPDATE_ACTIVITYLOG_SUCCESS,
  UPDATE_ACTIVITYLOG_FAIL,
  ADD_NEW_ACTIVITYLOG,
  ADD_ACTIVITYLOG_SUCCESS,
  ADD_ACTIVITYLOG_FAIL,
  ACTIVITYLOG_LOADING,
  ACTIVITYLOG_RESET,
  ADD_NEW_ACTIVITYLOG_TABLE,
  UPDATE_ACTIVITYLOG_TABLE
} from "./actionType";

export const resetActivityLogState = (actionType) => ({
  type: ACTIVITYLOG_RESET,
  payload: { actionType },
});

export const getActivityLogSuccess = (actionType, data) => ({
  type: GET_ACTIVITYLOG_SUCCESS,
  payload: { actionType, data },
});

export const getActivityLogFail = (actionType, error) => ({
  type: GET_ACTIVITYLOG_FAIL,
  payload: { actionType, error },
});

export const getActivityLog = (data) => ({
  type: GET_ACTIVITYLOG,
  payload: data,
});

export const getActivityLogLoading = () => ({
  type: ACTIVITYLOG_LOADING,
});

export const deleteActivityLog = (data) => ({
  type: DELETE_ACTIVITYLOG,
  payload: data,
});

export const deleteActivityLogSuccess = (data) => ({
  type: DELETE_ACTIVITYLOG_SUCCESS,
  payload: data,
});

export const deleteActivityLogFail = (error) => ({
  type: DELETE_ACTIVITYLOG_FAIL,
  payload: error,
});

export const updateActivityLog = (data) => ({
  type: UPDATE_ACTIVITYLOG,
  payload: data,
});

export const updateActivityLogFail = (error) => ({
  type: UPDATE_ACTIVITYLOG_FAIL,
  payload: error,
});

export const updateActivityLogSuccess = (data) => ({
  type: UPDATE_ACTIVITYLOG_SUCCESS,
  payload: data,
});

export const addNewActivityLog = (data) => ({
  type: ADD_NEW_ACTIVITYLOG,
  payload: data,
});

export const addActivityLogSuccess = (data) => ({
  type: ADD_ACTIVITYLOG_SUCCESS,
  payload: data,
});

export const addActivityLogFail = (error) => ({
  type: ADD_ACTIVITYLOG_FAIL,
  payload: error,
});

export const addNewActivityLogTable = (data) => ({
  type: ADD_NEW_ACTIVITYLOG_TABLE,
  payload: data,
});

export const updateActivityLogTable = (data) => ({
  type: UPDATE_ACTIVITYLOG_TABLE,
  payload: data,
});
