export const DEVICE_LOADING = "DEVICE_LOADING";
export const DEVICE_RESET = "DEVICE_RESET";
export const GET_DEVICE = "GET_DEVICE";
export const GET_DEVICE_SUCCESS = "GET_DEVICE_SUCCESS";
export const GET_DEVICE_FAIL = "GET_DEVICE_FAIL";
export const DELETE_DEVICE = "DELETE_DEVICE";
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS";
export const DELETE_DEVICE_FAIL = "DELETE_DEVICE_FAIL";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";
export const UPDATE_DEVICE_FAIL = "UPDATE_DEVICE_FAIL";
export const ADD_NEW_DEVICE = "ADD_NEW_DEVICE";
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS";
export const ADD_DEVICE_FAIL = "ADD_DEVICE_FAIL";
export const GET_DEVICE_ONE = "GET_DEVICE_ONE";
