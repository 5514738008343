import {
  FILE_LOADING,
  GET_FILE,
  GET_FILE_SUCCESS,
  GET_FILE_FAIL,
  ADD_FILE_SUCCESS,
  ADD_FILE_FAIL,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAIL,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_FAIL,
  FILE_RESET
} from "./actionType";

const INIT_STATE = {
  files: [],
  error: {},
};

const File = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FILE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_FILE:
          return {
            ...state,
            files: action.payload.data,
            isFileCreated: false,
            isFileSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_FILE_FAIL:
      switch (action.payload.actionType) {
        case GET_FILE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isFileCreated: false,
            isFileSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_FILE: {
      return {
        ...state,
        isFileCreated: false,
        loading: true,
      };
    }

    case FILE_LOADING: {
      return {
        ...state,
        isFileCreated: false,
        loading: true,
      };
    }

    case ADD_FILE_SUCCESS:
      return {
        ...state,
        isFileCreated: true,
        loading: false,
        files: [...state.files, action.payload.data],
      };

    case ADD_FILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        files: state.files.filter(
          file => file.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_FILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        files: state.files.map(file =>
          file.id.toString() === action.payload.data.id.toString() ? { ...file, ...action.payload.data } : file
        ),
      };

    case UPDATE_FILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FILE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default File;