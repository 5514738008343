import {
  GET_DASHBOARDTEMPLATE,
  GET_DASHBOARDTEMPLATE_SUCCESS,
  GET_DASHBOARDTEMPLATE_FAIL,
  DELETE_DASHBOARDTEMPLATE,
  DELETE_DASHBOARDTEMPLATE_SUCCESS,
  DELETE_DASHBOARDTEMPLATE_FAIL,
  UPDATE_DASHBOARDTEMPLATE,
  UPDATE_DASHBOARDTEMPLATE_SUCCESS,
  UPDATE_DASHBOARDTEMPLATE_FAIL,
  ADD_NEW_DASHBOARDTEMPLATE,
  ADD_DASHBOARDTEMPLATE_SUCCESS,
  ADD_DASHBOARDTEMPLATE_FAIL,
  DASHBOARDTEMPLATE_LOADING,
  DASHBOARDTEMPLATE_RESET,
} from "./actionType";

export const resetDashboardTemplateState = (actionType) => ({
  type: DASHBOARDTEMPLATE_RESET,
  payload: { actionType },
});

export const getDashboardTemplateSuccess = (actionType, data) => ({
  type: GET_DASHBOARDTEMPLATE_SUCCESS,
  payload: { actionType, data },
});

export const getDashboardTemplateFail = (actionType, error) => ({
  type: GET_DASHBOARDTEMPLATE_FAIL,
  payload: { actionType, error },
});

export const getDashboardTemplate = (data) => ({
  type: GET_DASHBOARDTEMPLATE,
  payload: data,
});

export const getDashboardTemplateLoading = () => ({
  type: DASHBOARDTEMPLATE_LOADING,
});

export const deleteDashboardTemplate = (data) => ({
  type: DELETE_DASHBOARDTEMPLATE,
  payload: data,
});

export const deleteDashboardTemplateSuccess = (data) => ({
  type: DELETE_DASHBOARDTEMPLATE_SUCCESS,
  payload: data,
});

export const deleteDashboardTemplateFail = (error) => ({
  type: DELETE_DASHBOARDTEMPLATE_FAIL,
  payload: error,
});

export const updateDashboardTemplate = (data) => ({
  type: UPDATE_DASHBOARDTEMPLATE,
  payload: data,
});

export const updateDashboardTemplateFail = (error) => ({
  type: UPDATE_DASHBOARDTEMPLATE_FAIL,
  payload: error,
});

export const updateDashboardTemplateSuccess = (data) => ({
  type: UPDATE_DASHBOARDTEMPLATE_SUCCESS,
  payload: data,
});

export const addNewDashboardTemplate = (data) => ({
  type: ADD_NEW_DASHBOARDTEMPLATE,
  payload: data,
});

export const addDashboardTemplateSuccess = (data) => ({
  type: ADD_DASHBOARDTEMPLATE_SUCCESS,
  payload: data,
});

export const addDashboardTemplateFail = (error) => ({
  type: ADD_DASHBOARDTEMPLATE_FAIL,
  payload: error,
});
