import React, { useState, useEffect } from "react";
import { SimpleDonut2 } from "../../pages/Dashboard/PieCharts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function DonutChart({ data }) {
    const dispatch = useDispatch()
    const { historyPublics } = useSelector((state) => ({
        historyPublics: state.History.historyPublics,
    }));

    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [seriesCategories, setSeriesCategories] = useState([])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        if (data) {
            let dataLabel = [];
            if (data) {
                dataLabel.push({
                    label: data[0] && data[0].label,
                    value: data[0] && data[0].value,
                });
                setLabels(dataLabel);
            }
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            let arrCat = [];
            arrCat.push(data[0].label);
            setSeriesCategories(arrCat);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            console.log(data, 'datas');
            let arr = []
            arr.push(Math.round(data[0].amount))
            setSeries(arr)
        }
    }, [historyPublics, data]);

    return (
        <div className='mb-3 mt-2'>
            <SimpleDonut2 dataColors='["--vz-success", "--vz-primary"]' seriesCategories={seriesCategories} series={series} title={data.label} />
        </div>
    );
}

export default DonutChart;
