import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import avatar from "../../assets/images/users/user-dummy-img.jpg";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const ProfileDropdown = (props) => {
  const { t } = props;
  const [userName, setUserName] = useState("User");
  const [userPosition, setUserPosition] = useState("-");
  const [userImage, setUserImage] = useState();

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if (obj) {
        setUserName(obj.data.name || "User");
        if (obj.data.userType) setUserPosition(obj.data.userType);
        else setUserPosition("Belum diposisikan");

        if (obj.data.user_image)
          setUserImage(
            process.env.REACT_APP_API_URL +
              "/media/" +
              obj.data.user_image.file_folder.name +
              "/" +
              obj.data.user_image.filename
          );
        else setUserImage(avatar);
      } else {
        window.location = "/logout";
      }
    }
  }, [userName]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className='ms-sm-3 header-item topbar-user '>
        <DropdownToggle tag='button' type='button' className='btn shadow-none'>
          <span className='d-flex align-items-center'>
            <img
              className='rounded-circle header-profile-user'
              src={userImage && userImage}
              alt='Header Avatar'
            />
            <span className='text-start ms-xl-2'>
              <span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>
                {userName}
              </span>
              <span className='d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text'>
                {userPosition}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          <h6 className='dropdown-header'>
            {t("Welcome")} {userName}!
          </h6>
          <DropdownItem>
            <Link to='/profile'>
              <i className='mdi mdi-account-circle text-muted fs-16 align-middle me-1'></i>
              <span className='align-middle'>{t("Profile")}</span>
            </Link>
          </DropdownItem>
          <DropdownItem href='/logout'>
            <i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>
            <span className='align-middle' data-key='t-logout'>
              {t("Logout")}
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileDropdown.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ProfileDropdown));
