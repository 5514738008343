export const REPORTTEMPLATEDETAIL_LOADING = "REPORTTEMPLATEDETAIL_LOADING";
export const REPORTTEMPLATEDETAIL_RESET = "REPORTTEMPLATEDETAIL_RESET";
export const GET_REPORTTEMPLATEDETAIL = "GET_REPORTTEMPLATEDETAIL";
export const GET_REPORTTEMPLATEDETAIL_SUCCESS = "GET_REPORTTEMPLATEDETAIL_SUCCESS";
export const GET_REPORTTEMPLATEDETAIL_FAIL = "GET_REPORTTEMPLATEDETAIL_FAIL";
export const DELETE_REPORTTEMPLATEDETAIL = "DELETE_REPORTTEMPLATEDETAIL";
export const DELETE_REPORTTEMPLATEDETAIL_SUCCESS = "DELETE_REPORTTEMPLATEDETAIL_SUCCESS";
export const DELETE_REPORTTEMPLATEDETAIL_FAIL = "DELETE_REPORTTEMPLATEDETAIL_FAIL";
export const UPDATE_REPORTTEMPLATEDETAIL = "UPDATE_REPORTTEMPLATEDETAIL";
export const UPDATE_REPORTTEMPLATEDETAIL_SUCCESS = "UPDATE_REPORTTEMPLATEDETAIL_SUCCESS";
export const UPDATE_REPORTTEMPLATEDETAIL_FAIL = "UPDATE_REPORTTEMPLATEDETAIL_FAIL";
export const ADD_NEW_REPORTTEMPLATEDETAIL = "ADD_NEW_REPORTTEMPLATEDETAIL";
export const ADD_REPORTTEMPLATEDETAIL_SUCCESS = "ADD_REPORTTEMPLATEDETAIL_SUCCESS";
export const ADD_REPORTTEMPLATEDETAIL_FAIL = "ADD_REPORTTEMPLATEDETAIL_FAIL";
