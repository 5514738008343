export const PARAMETER_LOADING = "PARAMETER_LOADING";
export const PARAMETER_RESET = "PARAMETER_RESET";
export const GET_PARAMETER = "GET_PARAMETER";
export const GET_PARAMETER_SUCCESS = "GET_PARAMETER_SUCCESS";
export const GET_PARAMETER_FAIL = "GET_PARAMETER_FAIL";
export const DELETE_PARAMETER = "DELETE_PARAMETER";
export const DELETE_PARAMETER_SUCCESS = "DELETE_PARAMETER_SUCCESS";
export const DELETE_PARAMETER_FAIL = "DELETE_PARAMETER_FAIL";
export const UPDATE_PARAMETER = "UPDATE_PARAMETER";
export const UPDATE_PARAMETER_SUCCESS = "UPDATE_PARAMETER_SUCCESS";
export const UPDATE_PARAMETER_FAIL = "UPDATE_PARAMETER_FAIL";
export const ADD_NEW_PARAMETER = "ADD_NEW_PARAMETER";
export const ADD_PARAMETER_SUCCESS = "ADD_PARAMETER_SUCCESS";
export const ADD_PARAMETER_FAIL = "ADD_PARAMETER_FAIL";
export const ADD_NEW_PARAMETER_TABLE = "ADD_NEW_PARAMETER_TABLE";
export const UPDATE_PARAMETER_TABLE = "UPDATE_PARAMETER_TABLE";