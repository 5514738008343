import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_HISTORY,
  DELETE_HISTORY,
  UPDATE_HISTORY,
  ADD_NEW_HISTORY,
  GET_HISTORY_PUBLIC,
} from "./actionType";

import {
  getHistorySuccess,
  getHistoryFail,
  deleteHistorySuccess,
  deleteHistoryFail,
  updateHistorySuccess,
  updateHistoryFail,
  addHistorySuccess,
  addHistoryFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveHistoryApi,
  getHistoryApi,
  updateHistoryApi,
  deleteHistoryApi,
  addNewHistoryApi,
  getHistoryPublicApi,
} from "../../helpers/backend_helper";

function* getHistory({ payload: history }) {
  try {
    const response = yield call(getHistoryApi, history);
    yield put(getHistorySuccess(GET_HISTORY, response.data));
  } catch (error) {
    yield put(getHistoryFail(GET_HISTORY, error));
  }
}

function* getHistoryPublic({ payload: history }) {
  try {
    const response = yield call(getHistoryPublicApi, history);
    yield put(getHistorySuccess(GET_HISTORY_PUBLIC, response.data));
  } catch (error) {
    yield put(getHistoryFail(GET_HISTORY_PUBLIC, error));
  }
}

function* onUpdateHistory({ payload: history }) {
  try {
    const response = yield call(updateHistoryApi, history);
    yield put(updateHistorySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateHistoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteHistory({ payload: history }) {
  try {
    const response = yield call(deleteHistoryApi, history);
    yield put(deleteHistorySuccess({ history, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteHistoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewHistory({ payload: history }) {
  try {
    const response = yield call(addNewHistoryApi, history);
    yield put(addHistorySuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addHistoryFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetHistory() {
  yield takeEvery(GET_HISTORY, getHistory);
}

export function* watchGetHistoryPublic() {
  yield takeEvery(GET_HISTORY_PUBLIC, getHistoryPublic);
}

export function* watchUpdateHistory() {
  yield takeEvery(UPDATE_HISTORY, onUpdateHistory);
}

export function* watchDeleteHistory() {
  yield takeEvery(DELETE_HISTORY, onDeleteHistory);
}

export function* watchAddNewHistory() {
  yield takeEvery(ADD_NEW_HISTORY, onAddNewHistory);
}

function* historySaga() {
  yield all([
    fork(watchGetHistory),
    fork(watchGetHistoryPublic),
    fork(watchDeleteHistory),
    fork(watchUpdateHistory),
    fork(watchAddNewHistory),
  ]);
}

export default historySaga;
