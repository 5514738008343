import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_SENSORTYPE,
  DELETE_SENSORTYPE,
  UPDATE_SENSORTYPE,
  ADD_NEW_SENSORTYPE,
} from "./actionType";

import {
  getSensorTypeSuccess,
  getSensorTypeFail,
  deleteSensorTypeSuccess,
  deleteSensorTypeFail,
  updateSensorTypeSuccess,
  updateSensorTypeFail,
  addSensorTypeSuccess,
  addSensorTypeFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveSensorTypeApi,
  getSensorTypeApi,
  updateSensorTypeApi,
  deleteSensorTypeApi,
  addNewSensorTypeApi,
} from "../../helpers/backend_helper";

function* getSensorType({ payload: sensorType }) {
  try {
    const response = yield call(getSensorTypeApi, sensorType);
    yield put(getSensorTypeSuccess(GET_SENSORTYPE, response.data));
  } catch (error) {
    yield put(getSensorTypeFail(GET_SENSORTYPE, error));
  }
}

function* onUpdateSensorType({ payload: sensorType }) {
  try {
    const response = yield call(updateSensorTypeApi, sensorType);
    yield put(updateSensorTypeSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateSensorTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteSensorType({ payload: sensorType }) {
  try {
    const response = yield call(deleteSensorTypeApi, sensorType);
    yield put(deleteSensorTypeSuccess({ sensorType, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteSensorTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewSensorType({ payload: sensorType }) {
  try {
    const response = yield call(addNewSensorTypeApi, sensorType);
    yield put(addSensorTypeSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addSensorTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetSensorType() {
  yield takeEvery(GET_SENSORTYPE, getSensorType);
}

export function* watchUpdateSensorType() {
  yield takeEvery(UPDATE_SENSORTYPE, onUpdateSensorType);
}

export function* watchDeleteSensorType() {
  yield takeEvery(DELETE_SENSORTYPE, onDeleteSensorType);
}

export function* watchAddNewSensorType() {
  yield takeEvery(ADD_NEW_SENSORTYPE, onAddNewSensorType);
}

function* sensorTypeSaga() {
  yield all([
    fork(watchGetSensorType),
    fork(watchDeleteSensorType),
    fork(watchUpdateSensorType),
    fork(watchAddNewSensorType),
  ]);
}

export default sensorTypeSaga;
