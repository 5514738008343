import {
  GET_GEOFENCE,
  GET_GEOFENCE_SUCCESS,
  GET_GEOFENCE_FAIL,
  DELETE_GEOFENCE,
  DELETE_GEOFENCE_SUCCESS,
  DELETE_GEOFENCE_FAIL,
  UPDATE_GEOFENCE,
  UPDATE_GEOFENCE_SUCCESS,
  UPDATE_GEOFENCE_FAIL,
  ADD_NEW_GEOFENCE,
  ADD_GEOFENCE_SUCCESS,
  ADD_GEOFENCE_FAIL,
  GEOFENCE_LOADING,
  GEOFENCE_RESET,
  GET_GEOFENCE_ONE,
} from "./actionType";

export const resetGeofenceState = (actionType) => ({
  type: GEOFENCE_RESET,
  payload: { actionType },
});

export const getGeofenceSuccess = (actionType, data) => ({
  type: GET_GEOFENCE_SUCCESS,
  payload: { actionType, data },
});

export const getGeofenceFail = (actionType, error) => ({
  type: GET_GEOFENCE_FAIL,
  payload: { actionType, error },
});

export const getGeofence = (data) => ({
  type: GET_GEOFENCE,
  payload: data,
});

export const getGeofenceLoading = () => ({
  type: GEOFENCE_LOADING,
});

export const deleteGeofence = (data) => ({
  type: DELETE_GEOFENCE,
  payload: data,
});

export const deleteGeofenceSuccess = (data) => ({
  type: DELETE_GEOFENCE_SUCCESS,
  payload: data,
});

export const deleteGeofenceFail = (error) => ({
  type: DELETE_GEOFENCE_FAIL,
  payload: error,
});

export const updateGeofence = (data) => ({
  type: UPDATE_GEOFENCE,
  payload: data,
});

export const updateGeofenceFail = (error) => ({
  type: UPDATE_GEOFENCE_FAIL,
  payload: error,
});

export const updateGeofenceSuccess = (data) => ({
  type: UPDATE_GEOFENCE_SUCCESS,
  payload: data,
});

export const addNewGeofence = (data) => ({
  type: ADD_NEW_GEOFENCE,
  payload: data,
});

export const addGeofenceSuccess = (data) => ({
  type: ADD_GEOFENCE_SUCCESS,
  payload: data,
});

export const addGeofenceFail = (error) => ({
  type: ADD_GEOFENCE_FAIL,
  payload: error,
});

export const getGeofenceOne = (data) => ({
  type: GET_GEOFENCE_ONE,
  payload: data,
});
