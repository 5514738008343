import {
  NOTIFICATION_LOADING,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  NOTIFICATION_RESET,
  NOTIFICATION_RESET_CREATE
} from "./actionType";

const INIT_STATE = {
  notifications: [],
  notificationAdded: {},
  error: {},
};

const Notification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_SUCCESS:
      switch (action.payload.actionType) {
        case GET_NOTIFICATION:
          return {
            ...state,
            notifications: action.payload.data,
            isNotificationCreated: false,
            isNotificationSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_NOTIFICATION_FAIL:
      switch (action.payload.actionType) {
        case GET_NOTIFICATION_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isNotificationCreated: false,
            isNotificationSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_NOTIFICATION: {
      return {
        ...state,
        isNotificationCreated: false,
        loading: true,
      };
    }

    case NOTIFICATION_LOADING: {
      return {
        ...state,
        isNotificationCreated: false,
        loading: true,
      };
    }

    case ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isNotificationCreated: true,
        loading: false,
        notifications: [...state.notifications, action.payload.data],
        notificationAdded: action.payload.data,
      };

    case ADD_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: state.notifications.filter(
          notification => notification.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: state.notifications.map(notification =>
          notification.id.toString() === action.payload.data.id.toString() ? { ...notification, ...action.payload.data } : notification
        ),
      };

    case UPDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NOTIFICATION_RESET_CREATE:
      return {
        ...state,
        isNotificationCreated: false,
      };

    case NOTIFICATION_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Notification;