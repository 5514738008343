import {
  GEOFENCE_LOADING,
  GET_GEOFENCE,
  GET_GEOFENCE_SUCCESS,
  GET_GEOFENCE_FAIL,
  ADD_GEOFENCE_SUCCESS,
  ADD_GEOFENCE_FAIL,
  DELETE_GEOFENCE_SUCCESS,
  DELETE_GEOFENCE_FAIL,
  UPDATE_GEOFENCE_SUCCESS,
  UPDATE_GEOFENCE_FAIL,
  GEOFENCE_RESET,
  GET_GEOFENCE_ONE,
} from "./actionType";

const INIT_STATE = {
  geofences: [],
  error: {},
  geofenceOnes : []
};

const Geofence = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GEOFENCE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_GEOFENCE:
          return {
            ...state,
            geofences: action.payload.data,
            isGeofenceCreated: false,
            isGeofenceSuccess: true,
            loading: false,
          };

          case GET_GEOFENCE_ONE:
          return {
            ...state,
            geofenceOnes: action.payload.data,
            isGeofenceSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_GEOFENCE_FAIL:
      switch (action.payload.actionType) {
        case GET_GEOFENCE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isGeofenceCreated: false,
            isGeofenceSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_GEOFENCE: {
      return {
        ...state,
        isGeofenceCreated: false,
        loading: true,
      };
    }

    case GET_GEOFENCE_ONE: {
      return {
        ...state,
        loading: true,
      };
    }

    case GEOFENCE_LOADING: {
      return {
        ...state,
        isGeofenceCreated: false,
        loading: true,
      };
    }

    case ADD_GEOFENCE_SUCCESS:
      return {
        ...state,
        isGeofenceCreated: true,
        loading: false,
        geofences: [...state.geofences, action.payload.data],
      };

    case ADD_GEOFENCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_GEOFENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        geofences: state.geofences.filter(
          (geofence) => geofence.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_GEOFENCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_GEOFENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        geofences: state.geofences.map((geofence) =>
          geofence.id.toString() === action.payload.data.id.toString()
            ? { ...geofence, ...action.payload.data }
            : geofence
        ),
      };

    case UPDATE_GEOFENCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GEOFENCE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Geofence;
