import {
  STORAGEMOVE_LOADING,
  GET_STORAGEMOVE,
  GET_STORAGEMOVE_SUCCESS,
  GET_STORAGEMOVE_FAIL,
  ADD_STORAGEMOVE_SUCCESS,
  ADD_STORAGEMOVE_FAIL,
  DELETE_STORAGEMOVE_SUCCESS,
  DELETE_STORAGEMOVE_FAIL,
  UPDATE_STORAGEMOVE_SUCCESS,
  UPDATE_STORAGEMOVE_FAIL,
  STORAGEMOVE_RESET,
  GET_STORAGEMOVE_ONE,
} from "./actionType";

const INIT_STATE = {
  storageMoves: [],
  error: {},
  storageMoveOnes: [],
};

const StorageMove = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORAGEMOVE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_STORAGEMOVE:
          return {
            ...state,
            storageMoves: action.payload.data,
            isStorageMoveCreated: false,
            isStorageMoveSuccess: true,
            loading: false,
          };

        case GET_STORAGEMOVE_ONE:
          return {
            ...state,
            storageMoveOnes: action.payload.data,
            isStorageMoveSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_STORAGEMOVE_FAIL:
      switch (action.payload.actionType) {
        case GET_STORAGEMOVE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isStorageMoveCreated: false,
            isStorageMoveSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_STORAGEMOVE: {
      return {
        ...state,
        isStorageMoveCreated: false,
        loading: true,
      };
    }

    case GET_STORAGEMOVE_ONE: {
      return {
        ...state,
        loading: true,
      };
    }

    case STORAGEMOVE_LOADING: {
      return {
        ...state,
        isStorageMoveCreated: false,
        loading: true,
      };
    }

    case ADD_STORAGEMOVE_SUCCESS:
      return {
        ...state,
        isStorageMoveCreated: true,
        loading: false,
        storageMoves: [...state.storageMoves, action.payload.data],
      };

    case ADD_STORAGEMOVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_STORAGEMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        storageMoves: state.storageMoves.filter(
          (storageMove) =>
            storageMove.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_STORAGEMOVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_STORAGEMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStorageMoveCreated: true,
        storageMoves: state.storageMoves.map((storageMove) =>
          storageMove.id.toString() === action.payload.data.id.toString()
            ? { ...storageMove, ...action.payload.data }
            : storageMove
        ),
      };

    case UPDATE_STORAGEMOVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case STORAGEMOVE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default StorageMove;
