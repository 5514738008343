import {
  GET_ROLE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  ADD_NEW_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  ROLE_LOADING,
  ROLE_RESET,
} from "./actionType";

export const resetRoleState = (actionType) => ({
  type: ROLE_RESET,
  payload: { actionType },
});

export const getRoleSuccess = (actionType, data) => ({
  type: GET_ROLE_SUCCESS,
  payload: { actionType, data },
});

export const getRoleFail = (actionType, error) => ({
  type: GET_ROLE_FAIL,
  payload: { actionType, error },
});

export const getRole = data => ({
  type: GET_ROLE,
  payload: data,
});

export const getRoleLoading = () => ({
  type: ROLE_LOADING
});

export const deleteRole = data => ({
  type: DELETE_ROLE,
  payload: data,
});

export const deleteRoleSuccess = data => ({
  type: DELETE_ROLE_SUCCESS,
  payload: data,
});

export const deleteRoleFail = error => ({
  type: DELETE_ROLE_FAIL,
  payload: error,
});

export const updateRole = data => ({
  type: UPDATE_ROLE,
  payload: data,
});

export const updateRoleFail = error => ({
  type: UPDATE_ROLE_FAIL,
  payload: error,
});

export const updateRoleSuccess = data => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: data,
});

export const addNewRole = data => ({
  type: ADD_NEW_ROLE,
  payload: data,
});

export const addRoleSuccess = data => ({
  type: ADD_ROLE_SUCCESS,
  payload: data,
});

export const addRoleFail = error => ({
  type: ADD_ROLE_FAIL,
  payload: error,
});