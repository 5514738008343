import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_ADDRESS,
  DELETE_ADDRESS,
  UPDATE_ADDRESS,
  ADD_NEW_ADDRESS,
} from "./actionType";

import {
  getAddressSuccess,
  getAddressFail,
  deleteAddressSuccess,
  deleteAddressFail,
  updateAddressSuccess,
  updateAddressFail,
  addAddressSuccess,
  addAddressFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getAddressApi,
  updateAddressApi,
  deleteAddressApi,
  addNewAddressApi
} from "../../helpers/backend_helper";

function* getAddress({ payload: address }) {
  try {
    const response = yield call(getAddressApi, address);
    yield put(getAddressSuccess(GET_ADDRESS, response.data));
  } catch (error) {
    yield put(getAddressFail(GET_ADDRESS, error));
  }
}

function* onUpdateAddress({ payload: address }) {
  try {
    const response = yield call(updateAddressApi, address);
    yield put(updateAddressSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateAddressFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteAddress({ payload: address }) {
  try {
    const response = yield call(deleteAddressApi, address);
    yield put(deleteAddressSuccess({ address, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteAddressFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewAddress({ payload: address }) {
  try {
    const response = yield call(addNewAddressApi, address);
    yield put(addAddressSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addAddressFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetAddress() {
  yield takeEvery(GET_ADDRESS, getAddress);
}

export function* watchUpdateAddress() {
  yield takeEvery(UPDATE_ADDRESS, onUpdateAddress);
}

export function* watchDeleteAddress() {
  yield takeEvery(DELETE_ADDRESS, onDeleteAddress);
}

export function* watchAddNewAddress() {
  yield takeEvery(ADD_NEW_ADDRESS, onAddNewAddress);
}

function* addressSaga() {
  yield all([
    fork(watchGetAddress),
    fork(watchDeleteAddress),
    fork(watchUpdateAddress),
    fork(watchAddNewAddress)
  ]);
}

export default addressSaga;
