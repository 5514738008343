import {
  STORAGERENAME_LOADING,
  GET_STORAGERENAME,
  GET_STORAGERENAME_SUCCESS,
  GET_STORAGERENAME_FAIL,
  ADD_STORAGERENAME_SUCCESS,
  ADD_STORAGERENAME_FAIL,
  DELETE_STORAGERENAME_SUCCESS,
  DELETE_STORAGERENAME_FAIL,
  UPDATE_STORAGERENAME_SUCCESS,
  UPDATE_STORAGERENAME_FAIL,
  STORAGERENAME_RESET,
  GET_STORAGERENAME_ONE,
} from "./actionType";

const INIT_STATE = {
  storageRenames: [],
  error: {},
  storageRenameOnes: [],
};

const StorageRename = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORAGERENAME_SUCCESS:
      switch (action.payload.actionType) {
        case GET_STORAGERENAME:
          return {
            ...state,
            storageRenames: action.payload.data,
            isStorageRenameCreated: false,
            isStorageRenameSuccess: true,
            loading: false,
          };

        case GET_STORAGERENAME_ONE:
          return {
            ...state,
            storageRenameOnes: action.payload.data,
            isStorageRenameSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_STORAGERENAME_FAIL:
      switch (action.payload.actionType) {
        case GET_STORAGERENAME_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isStorageRenameCreated: false,
            isStorageRenameSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_STORAGERENAME: {
      return {
        ...state,
        isStorageRenameCreated: false,
        loading: true,
      };
    }

    case GET_STORAGERENAME_ONE: {
      return {
        ...state,
        loading: true,
      };
    }

    case STORAGERENAME_LOADING: {
      return {
        ...state,
        isStorageRenameCreated: false,
        loading: true,
      };
    }

    case ADD_STORAGERENAME_SUCCESS:
      return {
        ...state,
        isStorageRenameCreated: true,
        loading: false,
        storageRenames: [...state.storageRenames, action.payload.data],
      };

    case ADD_STORAGERENAME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_STORAGERENAME_SUCCESS:
      return {
        ...state,
        loading: false,
        storageRenames: state.storageRenames.filter(
          (storageRename) =>
            storageRename.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_STORAGERENAME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_STORAGERENAME_SUCCESS:
      return {
        ...state,
        loading: false,
        isStorageRenameCreated: true,
        storageRenames: state.storageRenames.map((storageRename) =>
          storageRename.id.toString() === action.payload.data.id.toString()
            ? { ...storageRename, ...action.payload.data }
            : storageRename
        ),
      };

    case UPDATE_STORAGERENAME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case STORAGERENAME_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default StorageRename;
