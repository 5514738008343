import {
  GET_STORAGERENAME,
  GET_STORAGERENAME_SUCCESS,
  GET_STORAGERENAME_FAIL,
  DELETE_STORAGERENAME,
  DELETE_STORAGERENAME_SUCCESS,
  DELETE_STORAGERENAME_FAIL,
  UPDATE_STORAGERENAME,
  UPDATE_STORAGERENAME_SUCCESS,
  UPDATE_STORAGERENAME_FAIL,
  ADD_NEW_STORAGERENAME,
  ADD_STORAGERENAME_SUCCESS,
  ADD_STORAGERENAME_FAIL,
  STORAGERENAME_LOADING,
  STORAGERENAME_RESET,
  GET_STORAGERENAME_ONE,
} from "./actionType";

export const resetStorageRenameState = (actionType) => ({
  type: STORAGERENAME_RESET,
  payload: { actionType },
});

export const getStorageRenameSuccess = (actionType, data) => ({
  type: GET_STORAGERENAME_SUCCESS,
  payload: { actionType, data },
});

export const getStorageRenameFail = (actionType, error) => ({
  type: GET_STORAGERENAME_FAIL,
  payload: { actionType, error },
});

export const getStorageRename = (data) => ({
  type: GET_STORAGERENAME,
  payload: data,
});

export const getStorageRenameLoading = () => ({
  type: STORAGERENAME_LOADING,
});

export const deleteStorageRename = (data) => ({
  type: DELETE_STORAGERENAME,
  payload: data,
});

export const deleteStorageRenameSuccess = (data) => ({
  type: DELETE_STORAGERENAME_SUCCESS,
  payload: data,
});

export const deleteStorageRenameFail = (error) => ({
  type: DELETE_STORAGERENAME_FAIL,
  payload: error,
});

export const updateStorageRename = (data) => ({
  type: UPDATE_STORAGERENAME,
  payload: data,
});

export const updateStorageRenameFail = (error) => ({
  type: UPDATE_STORAGERENAME_FAIL,
  payload: error,
});

export const updateStorageRenameSuccess = (data) => ({
  type: UPDATE_STORAGERENAME_SUCCESS,
  payload: data,
});

export const addNewStorageRename = (data) => ({
  type: ADD_NEW_STORAGERENAME,
  payload: data,
});

export const addStorageRenameSuccess = (data) => ({
  type: ADD_STORAGERENAME_SUCCESS,
  payload: data,
});

export const addStorageRenameFail = (error) => ({
  type: ADD_STORAGERENAME_FAIL,
  payload: error,
});

export const getStorageRenameOne = (data) => ({
  type: GET_STORAGERENAME_ONE,
  payload: data,
});
