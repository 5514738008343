import React from "react";
import Wave from "react-wavify";
import tankImage from "../../assets/images/tank_CPO.png";

function WidgetTank({ data, isEdit, comp }) {
  if (data) {
    var angka = Math.min(
      Math.max(data.dd_parameter[0].ddp_parameter.amount, data.min),
      data.max
    );
    var valueWave = 100 - (angka / data.max) * 100;
  }
  return (
    <>
      <h5 className=" text-center mb-3">{data.name}</h5>
      <div className="d-flex align-items-center justify-content-center">
        <Wave
          fill={data.bgColor}
          paused={false}
          style={
            comp === 1
              ? {
                  marginLeft: "11%",
                  width: "24%",
                  height: "107px",
                }
              : { marginLeft: "4%", width: "10%", height: "107px" }
          }
          options={{
            height: valueWave,
            amplitude: 3,
            speed: 1,
            points: 3,
          }}
        />
        {!isEdit ? (
          <img
            src={tankImage}
            alt=""
            className="position-absolute "
            style={{
              width: "110px",
              height: "125px",
            }}
          />
        ) : (
          <img
            src={tankImage}
            alt=""
            className="position-absolute "
            style={{
              width: "110px",
              height: "125px",
            }}
          />
        )}
        {!isEdit ? (
          <span
            className="fs-14 position-absolute top-0 start-0"
            style={
              comp === 1
                ? { marginTop: "90px", marginLeft: "90px" }
                : { marginTop: "90px", marginLeft: "270px" }
            }
          >
            {angka ? angka + "%" : "0%"}
          </span>
        ) : (
          <span
            className="fs-14 position-absolute top-0 start-0"
            style={
              comp === 1
                ? { marginTop: "140px", marginLeft: "90px" }
                : { marginTop: "140px", marginLeft: "270px" }
            }
          >
            {angka ? angka + "%" : "0%"}
          </span>
        )}
      </div>
      <div className="d-flex flex-column mt-4">
        <h6 className="text-center">
          {data.dd_parameter[0] && data.dd_parameter[0].ddp_parameter.label}
        </h6>
        <h1 className="text-center">
          {data.dd_parameter[0] && data.dd_parameter[0].ddp_parameter.amount
            ? `${
                Math.round(data.dd_parameter[0].ddp_parameter.amount * 100) /
                100
              } ${data.dd_parameter[0].ddp_parameter.uom}`
            : "0"}
        </h1>
      </div>
    </>
  );
}

export default WidgetTank;
