import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_SENSOR,
  DELETE_SENSOR,
  UPDATE_SENSOR,
  ADD_NEW_SENSOR,
} from "./actionType";

import {
  getSensorSuccess,
  getSensorFail,
  deleteSensorSuccess,
  deleteSensorFail,
  updateSensorSuccess,
  updateSensorFail,
  addSensorSuccess,
  addSensorFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveSensorApi,
  getSensorApi,
  updateSensorApi,
  deleteSensorApi,
  addNewSensorApi,
} from "../../helpers/backend_helper";

function* getSensor({ payload: sensor }) {
  try {
    const response = yield call(getSensorApi, sensor);
    yield put(getSensorSuccess(GET_SENSOR, response.data));
  } catch (error) {
    yield put(getSensorFail(GET_SENSOR, error));
  }
}

function* onUpdateSensor({ payload: sensor }) {
  try {
    const response = yield call(updateSensorApi, sensor);
    yield put(updateSensorSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateSensorFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteSensor({ payload: sensor }) {
  try {
    const response = yield call(deleteSensorApi, sensor);
    yield put(deleteSensorSuccess({ sensor, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteSensorFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewSensor({ payload: sensor }) {
  try {
    const response = yield call(addNewSensorApi, sensor);
    yield put(addSensorSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addSensorFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetSensor() {
  yield takeEvery(GET_SENSOR, getSensor);
}

export function* watchUpdateSensor() {
  yield takeEvery(UPDATE_SENSOR, onUpdateSensor);
}

export function* watchDeleteSensor() {
  yield takeEvery(DELETE_SENSOR, onDeleteSensor);
}

export function* watchAddNewSensor() {
  yield takeEvery(ADD_NEW_SENSOR, onAddNewSensor);
}

function* sensorSaga() {
  yield all([
    fork(watchGetSensor),
    fork(watchDeleteSensor),
    fork(watchUpdateSensor),
    fork(watchAddNewSensor),
  ]);
}

export default sensorSaga;
