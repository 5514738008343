import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";
import {
  loginUser,
  apiError,
  resetLoginFlag,
  loginSocial,
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";

// Formik validation
import logoLight from "../../assets/images/logo-light.png";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";

firebase.initializeApp({
  apiKey: "AIzaSyD0auKcsMNas3qbqi-BgF_s3UxwtQNP74Q",
  authDomain: "global-product-338605.firebaseapp.com",
  projectId: "global-product-338605",
  storageBucket: "global-product-338605.appspot.com",
  messagingSenderId: "729313900237",
  appId: "1:729313900237:web:83e8ea22d9b62198b31b84",
  measurementId: "G-EL9X85G088",
});

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const { user, loginLoading, isLogin, error } = useSelector((state) => ({
    user: state.Register.user,
    loginLoading: state.Login.loading,
    isLogin: state.Login.isLogin,
    error: state.Login.error,
  }));
  const [userLogin, setUserLogin] = useState([]);
  useEffect(() => {
    if (user && user) {
      setUserLogin({
        username: user.data.username,
      });
    }
  }, [user]);

  useEffect(() => {
    if (isLogin) {
      navigate("/dashboardx");
    }
  }, [isLogin, navigate]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      clientAppId: 2,
      username: userLogin.username || "",
      password: userLogin.password || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  //const googleProvider = new GoogleAuthProvider();
  const signInWithGoogle = () => {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(async (res) => {
        let user = res.user;
        console.log(user);
        console.log(res);

        let idToken = await res.user.getIdToken(true);
        const obj = {
          type: "login",
          email: user.email,
          clientAppId: 2,
          tokenId: idToken,
        };
        console.log(obj);
        dispatch(loginSocial(obj, props.router.navigate));
      })
      .catch((err) => err.message);
    return false;
  };

  const signInWithFacebook = () => {
    const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(facebookAuthProvider)
      .then(async (res) => {
        let user = res.user;
        console.log(user);
        console.log(res);

        let idToken = await res.user.getIdToken(true);
        const obj = {
          type: "login",
          email: user.email,
          clientAppId: 2,
          tokenId: idToken,
        };
        console.log(obj);
        // dispatch(loginSocial(obj, props.router.navigate));
      })
      .catch((err) => err.message);
    return false;
  };

  // const signInWithGoogle = () => {
  //   const provider = new firebase.auth().GoogleAuthProvider();
  //   firebase.auth().signInWithPopup(provider).then((res) => {
  //       let user = res.user;
  //       console.log(user);
  //       console.log(res);

  //       const obj = {
  //         type: "login",
  //         email: user.email,
  //         clientAppId: 2,
  //         tokenId: res.credential.idToken,
  //       };
  //       console.log(obj);
  //       dispatch(loginSocial(obj, props.router.navigate));
  //     })
  //     .catch((err) => err.message);
  // };

  document.title = "Login | TELKOMSEL ";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='text-center mt-sm-5 mb-4 text-white-50'>
                  <div>
                    <a href='/' className='d-inline-block auth-logo'>
                      <img src={logoLight} alt='' height='90' />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Welcome Back !</h5>
                      <p className='text-muted'>
                        Sign in to continue to <br />
                        SMART IOT DASHBOARD .
                      </p>
                    </div>
                    <div className='p-2 mt-4'>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action='#'>
                        {error && error.response ? (
                          <Alert color='danger'>
                            <div>{error.response.data.message}</div>
                          </Alert>
                        ) : null}
                        <div className='mb-3'>
                          <Label htmlFor='username' className='form-label'>
                            Username
                          </Label>
                          <Input
                            name='username'
                            className='form-control'
                            placeholder='Enter username'
                            type='text'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <Label
                            className='form-label'
                            htmlFor='password-input'>
                            Password
                          </Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              name='password'
                              value={validation.values.password || ""}
                              type={showPassword ? "text" : "password"}
                              className='form-control pe-5'
                              placeholder='Enter Password'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                              type='button'
                              id='password-addon'
                              onClick={() => setShowPassword((prev) => !prev)}>
                              <i className='ri-eye-fill align-middle'></i>
                            </button>
                          </div>
                        </div>

                        <div className='form-check'>
                          <Input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='auth-remember-check'
                          />
                          <Label
                            className='form-check-label'
                            htmlFor='auth-remember-check'>
                            Remember me
                          </Label>
                        </div>

                        <div className='mt-4'>
                          <Button
                            color='primary'
                            className='btn btn-primary w-100'
                            type='submit'>
                            Sign In{" "}
                            {loginLoading && (
                              <Spinner
                                size='sm'
                                className='flex-shrink-0'
                                role='status'>
                                Loading...
                              </Spinner>
                            )}
                          </Button>
                        </div>
                      </Form>
                      <div className='mt-3'>
                        <h6 className='fw-6 text-center'>Continue with</h6>
                        <div className='d-flex flex-row justify-content-center '>
                          <div>
                            <GoogleLoginButton
                              onClick={signInWithGoogle}
                              style={{ height: "35px" }}>
                              <span>Google</span>
                            </GoogleLoginButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <div className='mt-4 text-center'>
                  <p className='mb-0'>
                    Do you not currently have an account ?{" "}
                    <Link
                      to='/register'
                      className='fw-semibold text-primary text-decoration-underline'>
                      {" "}
                      Register{" "}
                    </Link>{" "}
                  </p>
                </div>
                {/* <GoogleLogin
                  clientId={google.apiKey ? google.apiKey : ""}
                  render={(renderProps) => (
                    <Button
                      color='danger'
                      to='#'
                      className='btn-icon me-1'
                      onClick={renderProps.onClick}>
                      <i className='ri-google-fill fs-16' />
                    </Button>
                  )}
                  onSuccess={googleResponse}
                  onFailure={(error) => {}}
                /> */}
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
