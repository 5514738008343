import { io } from "socket.io-client";

export default class socketIO {
  static instance = null;
  static getInstance() {
    console.log(`socket IO called`);
    if (!socketIO.instance) {
      console.log(`socket IO generated`);
      socketIO.instance = new socketIO();
    }

    return socketIO.instance;
  }

  setHost(host) {
    this.host = host;
  }

  setPort(port) {
    this.port = port;
  }

  getSocket() {
    if (!this._socket || !this._socket.connected) {
      this._socket = io(`${this.host}${this.port ? `:${this.port}` : ""}`);

      this._socket.on("connect", () => {
        console.log(`socket id: ${this._socket.id}`);
      });

      this._socket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          // the disconnection was initiated by the server, you need to reconnect manually
          this._socket.connect();
        }
        // else the _socket will automatically try to reconnect
      });

      this._socket.on("connect_error", () => {
        setTimeout(() => {
          this._socket.connect();
        }, 1000);
      });
    }
    return this._socket;
  }
}
