import React, { useState } from "react";
import defaultImg from "../../assets/images/upload.png";
import { api } from "../../config";

function WidgetSingle({ data }) {
  console.log(data, 'single');
  return (
    <>
      <h5 className="text-center">{data.name}</h5>
      <div className="d-flex justify-content-center">
        <img
          src={
            !data.image ? defaultImg : `${api.MEDIA_URL}/media/${data.image}`
          }
          alt=""
          data-dz-thumbnail=""
          className="avatar-md rounded bg-light"
        />
      </div>
      <div className="d-flex flex-column mt-2">
        <h6 className="text-center">
          {data.dd_parameter && data.dd_parameter[0] && data.dd_parameter[0].ddp_parameter.label}
        </h6>
        <h1 className="text-center">
          {data.dd_parameter && data.dd_parameter[0] && data.dd_parameter[0].ddp_parameter.amount
            ? `${data.dd_parameter[0].ddp_parameter.amount * 100 /
            100
            } ${data.dd_parameter[0].ddp_parameter.uom ? data.dd_parameter[0].ddp_parameter.uom : ""}`
            : "-"}
        </h1>
      </div>
    </>
  );
}

export default WidgetSingle;
