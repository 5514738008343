import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_REPORTTEMPLATEDETAIL,
  DELETE_REPORTTEMPLATEDETAIL,
  UPDATE_REPORTTEMPLATEDETAIL,
  ADD_NEW_REPORTTEMPLATEDETAIL,
} from "./actionType";

import {
  getReportTemplateDetailSuccess,
  getReportTemplateDetailFail,
  deleteReportTemplateDetailSuccess,
  deleteReportTemplateDetailFail,
  updateReportTemplateDetailSuccess,
  updateReportTemplateDetailFail,
  addReportTemplateDetailSuccess,
  addReportTemplateDetailFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveReportTemplateDetailApi,
  getReportTemplateDetailApi,
  updateReportTemplateDetailApi,
  deleteReportTemplateDetailApi,
  addNewReportTemplateDetailApi,
} from "../../helpers/backend_helper";

function* getReportTemplateDetail({ payload: reportTemplateDetail }) {
  try {
    const response = yield call(getReportTemplateDetailApi, reportTemplateDetail);
    yield put(getReportTemplateDetailSuccess(GET_REPORTTEMPLATEDETAIL, response.data));
  } catch (error) {
    yield put(getReportTemplateDetailFail(GET_REPORTTEMPLATEDETAIL, error));
  }
}

function* onUpdateReportTemplateDetail({ payload: reportTemplateDetail }) {
  try {
    if (reportTemplateDetail.setApprove) {
      const response = yield call(approveReportTemplateDetailApi, reportTemplateDetail);
      yield put(updateReportTemplateDetailSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateReportTemplateDetailApi, reportTemplateDetail);
      yield put(updateReportTemplateDetailSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateReportTemplateDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteReportTemplateDetail({ payload: reportTemplateDetail }) {
  try {
    const response = yield call(deleteReportTemplateDetailApi, reportTemplateDetail);
    yield put(deleteReportTemplateDetailSuccess({ reportTemplateDetail, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteReportTemplateDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewReportTemplateDetail({ payload: reportTemplateDetail }) {
  try {
    const response = yield call(addNewReportTemplateDetailApi, reportTemplateDetail);
    yield put(addReportTemplateDetailSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addReportTemplateDetailFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetReportTemplateDetail() {
  yield takeEvery(GET_REPORTTEMPLATEDETAIL, getReportTemplateDetail);
}

export function* watchUpdateReportTemplateDetail() {
  yield takeEvery(UPDATE_REPORTTEMPLATEDETAIL, onUpdateReportTemplateDetail);
}

export function* watchDeleteReportTemplateDetail() {
  yield takeEvery(DELETE_REPORTTEMPLATEDETAIL, onDeleteReportTemplateDetail);
}

export function* watchAddNewReportTemplateDetail() {
  yield takeEvery(ADD_NEW_REPORTTEMPLATEDETAIL, onAddNewReportTemplateDetail);
}

function* reportTemplateDetailSaga() {
  yield all([
    fork(watchGetReportTemplateDetail),
    fork(watchDeleteReportTemplateDetail),
    fork(watchUpdateReportTemplateDetail),
    fork(watchAddNewReportTemplateDetail),
  ]);
}

export default reportTemplateDetailSaga;
