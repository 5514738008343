import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  Table,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";

import Flatpickr from "react-flatpickr";
import Select from "react-select";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
  getDeviceType as onGetDeviceTypes,
  addNewDeviceType as onAddNewDeviceType,
  updateDeviceType as onUpdateDeviceType,
  deleteDeviceType as onDeleteDeviceType,
  getDeviceTypeLoading,
  resetDeviceTypeState,
} from "../../../store/deviceType/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import defaultImg from "../../../assets/images/upload.png";
import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const DeviceType = (props) => {
  const { t } = props;
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [logo, setLogo] = useState(defaultImg);
  const dispatch = useDispatch();
  const [pIndex, setPIndex] = useState(0);

  const {
    deviceTypes,
    isDeviceTypeCreated,
    isDeviceTypeSuccess,
    loading,
    error,
  } = useSelector((state) => ({
    deviceTypes: state.DeviceType.deviceTypes,
    isDeviceTypeCreated: state.DeviceType.isDeviceTypeCreated,
    isDeviceTypeSuccess: state.DeviceType.isDeviceTypeSuccess,
    loading: state.DeviceType.loading,
    error: state.DeviceType.error,
  }));

  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const [deviceType, setDeviceType] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: 1 },
        { label: "Activated", value: 2 },
        { label: "Deactivated", value: 3 },
      ],
    },
  ];

  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [userType, setUserType] = useState("user");

  const onClickDelete = (deviceType) => {
    setDeviceType(deviceType);
    setDeleteModal(true);
  };

  const handleDeleteDeviceType = () => {
    if (deviceType) {
      dispatch(onDeleteDeviceType(deviceType));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setDeviceTypeList(deviceTypes);
  }, [deviceTypes]);

  useEffect(() => {
    if (!isEmpty(deviceTypes)) setDeviceTypeList(deviceTypes);
  }, [deviceTypes]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredDeviceTypes = deviceTypes;
      if (type !== "all") {
        filteredDeviceTypes = deviceTypes.filter(
          (deviceType) => deviceType.device_status.name === type
        );
      }
      setDeviceTypeList(filteredDeviceTypes);
    }
  };

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetDeviceTypeState());
      dispatch(onGetDeviceTypes());
    }
    setIsSearch(true);
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      if (obj.data && obj.data.userType) setUserType(obj.data.userType);
    } else {
      window.location = "/logout";
    }
  }, [dispatch, deviceTypes, isSearch]);

  useEffect(() => {
    setDeviceType(deviceTypes);
  }, [deviceTypes]);

  useEffect(() => {
    if (!isEmpty(deviceTypes)) {
      setDeviceType(deviceTypes);
      setIsEdit(false);
    }
  }, [deviceTypes]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setDeviceType(null);
      setLogo(defaultImg);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleDeviceTypeClick = useCallback(
    (arg) => {
      const deviceType = arg;

      setDeviceType({
        id: deviceType.id,
        name: deviceType.name,
        code: deviceType.code,
        description: deviceType.description,
        status: {
          label: deviceType.device_status.name,
          value: deviceType.device_status.id,
        },
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (deviceType && deviceType.name) || "",
      code: (deviceType && deviceType.code) || "",
      description: (deviceType && deviceType.description) || "",
      status: (deviceType && deviceType.status) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      code: Yup.string().required("Please Enter Code"),
      status: Yup.object().required("Please Select Status"),
    }),
    onSubmit: (values) => {
      dispatch(getDeviceTypeLoading());
      if (isEdit) {
        const editDeviceType = {
          id: deviceType ? deviceType.id : 0,
          name: values.name,
          code: values.code,
          description: values.description,
          statusId: values.status.value,
        };
        dispatch(onUpdateDeviceType(editDeviceType));
        validation.resetForm();
      } else {
        const newDeviceType = {
          name: values.name,
          code: values.code,
          description: values.description,
          statusId: values.status.value,
        };
        dispatch(onAddNewDeviceType(newDeviceType));
        validation.resetForm();
      }
      toggle();
    },
  });

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteDeviceType(element.value));
    });
    checkall.checked = false;
  };

  // able Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type='checkbox'
            id='checkBoxAll'
            className='form-check-input'
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type='checkbox'
              className='dataCheckbox form-check-input'
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className='hstack gap-2 mt-4 mt-sm-0'>
              <Link
                to='#'
                className='btn-icon btn btn-sm btn-soft-primary edit-item-btn'
                onClick={() => {
                  const deviceTypeData = cellProps.row.original;
                  handleDeviceTypeClick(deviceTypeData);
                }}>
                <i className='ri-pencil-line fs-16'></i>
              </Link>
              <Link
                to='#'
                className='btn-icon btn btn-sm btn-soft-danger remove-item-btn'
                onClick={() => {
                  const deviceTypeData = cellProps.row.original;
                  onClickDelete(deviceTypeData);
                }}>
                <i className=' ri-delete-bin-line fs-15'></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: `${t("Name")}`,
        accessor: "name",
        filterable: true,
        Cell: (deviceType) => (
          <>
            <div className='d-flex align-items-center'>
              <div className='flex-shrink-0'>
                <div className='flex-shrink-0 avatar-xs me-2'>
                  <div className='avatar-title bg-soft-success text-success rounded-circle fs-13'>
                    {deviceType.row.original.name.charAt(0).toUpperCase()}
                  </div>
                </div>
              </div>
              <div className='flex-grow-1 ms-2 name'>
                {deviceType.row.original.name}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: `${t("Code")}`,
        accessor: "code",
        filterable: true,
      },
      {
        Header: `${t("Description")}`,
        accessor: "description",
        filterable: true,
      },
      {
        Header: `${t("Status")}`,
        accessor: "device_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "Activated":
              return (
                <span className='badge text-uppercase badge-soft-secondary'>
                  {cell.value}
                </span>
              );
            case "Deactivated":
              return (
                <span className='badge text-uppercase badge-soft-danger'>
                  {cell.value}
                </span>
              );
            case "Draft":
              return (
                <span className='badge text-uppercase badge-soft-warning'>
                  {cell.value}
                </span>
              );
            default:
              return (
                <span className='badge text-uppercase badge-soft-warning'>
                  {cell.value}
                </span>
              );
          }
        },
      },
    ],
    [handleDeviceTypeClick, t]
  );

  const defaultdate = () => {
    return moment().tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const [filterDate, setFilterDate] = useState(defaultdate());

  const handleSubscribedAt = (date) => {
    validation.setFieldValue("subscribedat", date);
  };

  const handleExpirededAt = (date) => {
    validation.setFieldValue("expiredat", date);
  };

  const handleValidDate = (date) => {
    return moment(new Date(date), "YYYY/MM/DD")
      .tz("Asia/Jakarta")
      .format("MMM D, YYYY");
  };

  const handleValidTime = (time) => {
    return moment(new Date(time), "YYYY/MM/DD")
      .tz("Asia/Jakarta")
      .format("HH:mm");
  };

  //TABLE
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: deviceTypeList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: pIndex,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable
      ? column.isSorted
        ? column.isSortedDesc
          ? " sorting_desc"
          : " sorting_asc"
        : " sort"
      : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState([]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    if (filterDate) {
      let datearr = filterDate.split(" to ");
      if (datearr[1]) {
        startat = datearr[0];
        endat = datearr[1];
      }
    }
    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetDeviceTypes(param));
  }

  function handleLogoChange(event) {
    if (event.target.files && event.target.files[0]) {
      setLogo(URL.createObjectURL(event.target.files[0]));
      validation.setFieldValue("img", event.target.files[0]);
    }
  }

  useEffect(() => {
    setPIndex(pageIndex);
  }, [pageIndex]);

  document.title = `${t("Device Type")} | TELKOMSEL - IOT Monitoring`;
  return (
    <div className='page-content'>
      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={deviceTypeList}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDeviceType}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={t("Device Type")} pageTitle={t("Master Data")} />
        <Row>
          <Col lg={12}>
            <Card id='deviceTypeList'>
              <CardHeader className='card-header border-0'>
                <div className='d-flex align-items-center'>
                  <h5 className='card-title mb-0 flex-grow-1'>
                    {t("Device Type")} &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color='primary' type='grow' size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color='success' type='grow' size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className='flex-shrink-0'>
                    <button
                      type='button'
                      className='btn btn-primary add-btn'
                      id='create-btn'
                      onClick={() => {
                        setDeviceType("");
                        setIsEdit(false);
                        toggle();
                      }}>
                      <i className='ri-hard-drive-2-line me-1 align-bottom fs-14'></i>{" "}
                      {t("Add")}
                    </button>{" "}
                    <button
                      type='button'
                      className='btn btn-success'
                      onClick={() => setIsExportCSV(true)}>
                      <i className='ri-file-download-line me-1 align-bottom fs-14'></i>{" "}
                      {t("Export")}
                    </button>{" "}
                    <button
                      className='btn btn-soft-danger'
                      onClick={() => setDeleteModalMulti(true)}>
                      <i className='ri-delete-bin-5-line me-1 align-bottom fs-15'></i>{" "}
                      {t("Delete")}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className='pt-0'>
                <div>
                  <Nav
                    className='nav-tabs nav-tabs-custom nav-success'
                    role='tablist'>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href='#'>
                        <i className='ri-hard-drive-2-line me-1 align-bottom fs-14'></i>{" "}
                        {t("All Device Types")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href='#'>
                        <i className='ri-draft-line me-1 align-bottom fs-14'></i>{" "}
                        {t("Draft")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "3" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href='#'>
                        <i className='ri-checkbox-circle-line me-1 align-bottom fs-14'></i>{" "}
                        {t("Activated")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "4" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("4", "Deactivated");
                        }}
                        href='#'>
                        <i className='ri-indeterminate-circle-line me-1 align-bottom fs-14'></i>{" "}
                        {t("Deactivated")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className='mb-3'>
                    <CardBody className='border border-top-0 border-end-0 border-start-0'>
                      <form>
                        <Row>
                          <Col sm={1} className='col-xxl-1'>
                            <div className='me-2 mb-2 col-12'>
                              <select
                                className='form-select'
                                value={pageSize}
                                onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className='search-box me-2 mb-2 d-inline-block col-12'>
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id='search-bar-0'
                                type='text'
                                className='form-control search'
                                placeholder={`${count} ${t("Search")}...`}
                                value={searchValue || ""}
                              />
                              <i className='bx bx-search-alt search-icon'></i>
                            </div>
                          </Col>
                          <Col sm={6} className='col-xxl-3'>
                            <div className='search-box me-2 mb-2 col-12'>
                              <Flatpickr
                                className='form-control'
                                id='datepicker-publish-input'
                                placeholder={t("Select created date")}
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(obj, str) => {
                                  setFilterDate(str);
                                }}
                              />
                              <i className='bx bx-calendar-alt search-icon'></i>
                            </div>
                          </Col>
                          <Col sm={4} className='col-xxl-2'>
                            <div className='me-2 mb-2 col-12'>
                              <Select
                                value={statusActive}
                                placeholder={t("Select Status")}
                                onChange={(e) => {
                                  setStatusActive(e);
                                }}
                                options={statusActiveData}
                                name='choices-single-default'
                                id='idStatus'></Select>
                            </div>
                          </Col>
                          <Col className='col-xxl-2'>
                            <div>
                              <button
                                type='button'
                                className='btn btn-light w-100'
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleFilter();
                                }}>
                                <i className='ri-search-line me-1 align-bottom fs-14'></i>{" "}
                                {t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className='table-responsive table-card mb-1'>
                    <Table
                      hover
                      {...getTableProps()}
                      className='align-middle table-nowrap'>
                      <thead className='table-light text-muted'>
                        {headerGroups.map((headerGroup) => (
                          <tr
                            className={""}
                            key={headerGroup.id}
                            {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                key={column.id}
                                className={"" + generateFilterable(column)}
                                {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                    <Col className='col-md-auto'>
                      <div className='d-flex gap-1'>
                        <Button
                          color='light'
                          onClick={previousPage}
                          disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className='col-md-auto d-none d-md-block'>
                      {`${t("Page")} `}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className='col-md-auto'>
                      <Input
                        type='number'
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        value={pIndex + 1}
                        onChange={onChangeInInput}
                      />
                    </Col>

                    <Col className='col-md-auto'>
                      <div className='d-flex gap-1'>
                        <Button
                          className='btn btn-light'
                          onClick={nextPage}
                          disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal
                  id='showModal'
                  isOpen={modal}
                  centered
                  fullscreen={false}
                  size='md'>
                  <ModalHeader className='bg-light p-3' toggle={toggle}>
                    {!!isEdit
                      ? `${t("Edit")}  ${t("Device Type")}`
                      : `${t("Add")}  ${t("Device Type")}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <div className='mb-3'>
                            <Label htmlFor='name-field' className='form-label'>
                              {t("Name")} <span className='text-danger'>*</span>
                            </Label>
                            <Input
                              name='name'
                              id='name-field'
                              className='form-control'
                              placeholder={`${t("Enter")} ${t("Name")}`}
                              type='text'
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                  validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                              validation.errors.name ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className='mb-3'>
                            <Label htmlFor='code-field' className='form-label'>
                              {t("Code")} <span className='text-danger'>*</span>
                            </Label>
                            <Input
                              name='code'
                              id='code-field'
                              className='form-control'
                              placeholder={`${t("Enter")} ${t("Code")}`}
                              type='text'
                              validate={{ required: { value: true } }}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.code || ""}
                              invalid={
                                validation.touched.code &&
                                  validation.errors.code
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.code &&
                              validation.errors.code ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.code}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className='mb-3'>
                            <Label htmlFor='des-field' className='form-label'>
                              {t("Description")}
                            </Label>
                            <Input
                              name='description'
                              id='des-field'
                              className='form-control'
                              rows={6}
                              placeholder={`${t("Enter")} ${t("Description")}`}
                              type='textarea'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.description || ""}
                            />
                          </div>
                          <div className='mb-3'>
                            <Label
                              htmlFor='active-status'
                              className='form-label'>
                              {t("Status")} <span className='text-danger'>*</span>
                            </Label>
                            <Select
                              name='status'
                              value={validation.values.status}
                              aria-invalid={validation.touched.status}
                              aria-errormessage={validation.errors.status}
                              validate={{ required: { value: true } }}
                              placeholder={`${t("Select")} ${t("Status")}`}
                              onChange={(e) => {
                                validation.setFieldValue("status", e);
                              }}
                              options={statusActiveData}></Select>
                            {validation.touched.status && (
                              <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                {validation.errors.status}
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <div className='modal-footer mb3'>
                      <div className='hstack gap-2 justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-light'
                          onClick={() => {
                            setModal(false);
                            validation.resetForm();
                          }}>
                          {t("Cancel")}
                        </button>
                        <button type='submit' className='btn btn-success'>
                          <span className='d-flex align-items-center'>
                            <span className='flex-grow-1 me-2'>
                              {!!isEdit
                                ? `${t("Update")}  ${t("Device Type")}`
                                : `${t("Add")}  ${t("Device Type")}`}
                            </span>
                            {loading ? (
                              <Spinner
                                size='sm'
                                className='flex-shrink-0'
                                role='status'>
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

DeviceType.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(DeviceType));
