import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledCarousel,
  CardHeader,
} from "reactstrap";

// import { SplineAreaChart } from "./AreaCharts";
// Import Content
import UiContent from "../../../Components/Common/UiContent";
//import Components
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TemplateComponent from "../../../Components/Common/TemplateComponent";
import temp1 from "../../../assets/images/temp1.png";

import { useSelector, useDispatch } from "react-redux";
import {
  getDashboardTemplate as onGetDashboardTemplates,
  resetDashboardTemplateState,
} from "../../../store/dashboardTemplate/action";

import {
  getDashboard,
  addNewDashboard as onAddNewDashboard,
  resetDashboardState,
} from "../../../store/dashboard/action";

import { ToastContainer } from "react-toastify";
import "moment-timezone";
import DashTemplateModal from "../../../Components/Common/DashTemplateModal";
import TemplateComponent2 from "../../../Components/Common/TemplateComponent2";
import TemplateComponent3 from "../../../Components/Common/TemplateComponent3";
import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
const LoadingContainer = () => <div>Loading...</div>;
const DashboardTemplate = (props) => {
  const { t } = props;
  const [isSearch, setIsSearch] = useState(false);
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState("user");
  const [clientId, setClientId] = useState();
  const [companyId, setCompanyId] = useState();
  const dispatch = useDispatch();
  const [isKlik, setIsKlik] = useState(false);
  const [dashId, setDashId] = useState();
  const [isModalDash, setIsModalDash] = useState(false);
  const [postTemplate, setPostTemplate] = useState({});

  const { dashboardTemplates, dashboards } = useSelector((state) => ({
    dashboardTemplates: state.DashboardTemplate.dashboardTemplates,
    dashboards: state.Dashboard.dashboards,
  }));

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetDashboardTemplateState());
      dispatch(onGetDashboardTemplates());
      setIsSearch(true);
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if (obj) {
        if (
          obj.data &&
          obj.data.user_position &&
          obj.data.user_position.position_role
        ) {
          setRoles(obj.data.user_position.position_role.role_method);
        } else setRoles(null);

        if (obj.data && obj.data.userType) setUserType(obj.data.userType);
        if (obj.data && obj.data.clientId) setClientId(obj.data.clientId);
        if (obj.data && obj.data.companyId) setCompanyId(obj.data.companyId);
        dispatch(resetDashboardState());
        dispatch(getDashboard({ companyId: obj.data.companyId }));
      } else {
        window.location = "/logout";
      }
    }
  }, [dispatch, isSearch]);

  // useEffect(() => {
  //   if (companyId) {
  //     dispatch(resetDashboardState());
  //     dispatch(onGetDashboards({ companyId: companyId }));
  //   }
  // }, [companyId, dispatch]);

  const toggle = useCallback(() => {
    if (isModalDash) {
      setIsModalDash(false);
    } else {
      setIsModalDash(true);
    }
  }, [isModalDash]);

  const onClickDelete = (template) => {
    setPostTemplate(template);
    setIsModalDash(true);
  };

  const handlePostTemplate = (company, client) => {
    if (postTemplate) {
      if (userType !== "admin") {
        dispatch(onAddNewDashboard(postTemplate));
      } else {
        postTemplate.clientId = client;
        postTemplate.companyId = company;
        dispatch(onAddNewDashboard(postTemplate));
      }
      setIsModalDash(false);
      setIsKlik(true);
    }
  };

  document.title = `${t("Dashboard Template")} | TELKOMSEL - IOT Monitoring`;
  return !isKlik ? (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <DashTemplateModal
          show={isModalDash}
          onDeleteClick={handlePostTemplate}
          userType={userType}
          onCloseClick={() => setIsModalDash(false)}
        />
        <Container fluid>
          <BreadCrumb
            title={t("Dashboard Template")}
            pageTitle={t("Setting")}
          />
          <Row>
            {dashboardTemplates.map((item, idxItem) => {
              let isCurrent = false;
              dashboards.forEach((dash) => {
                if (item.id === dash.dsbTemplateId) {
                  isCurrent = true;
                }
              });
              return (
                <Col xl={4} key={idxItem}>
                  <Card>
                    <CardHeader>
                      {isCurrent ? (
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h5>{item.name}</h5>
                          </div>
                          <div className="flex-shrink-0">
                            <h5 className="text-muted">{t("Current")}</h5>
                          </div>
                        </div>
                      ) : (
                        <h5>{item.name}</h5>
                      )}
                    </CardHeader>
                    <CardBody style={{ cursor: "pointer" }}>
                      <div
                        className="live-preview"
                        onClick={() => {
                          if (userType !== "admin") {
                            let postDashboard = {
                              clientId: clientId,
                              companyId: companyId,
                              hasMap: item.hasMap,
                              hasBar: item.hasBar,
                              hasWidget: item.hasWidget,
                              hasChart: item.hasChart,
                              dsbTemplateId: item.id,
                            };
                            onClickDelete(postDashboard);
                          } else {
                            let postDashboard = {
                              hasMap: item.hasMap,
                              hasBar: item.hasBar,
                              hasWidget: item.hasWidget,
                              hasChart: item.hasChart,
                              dsbTemplateId: item.id,
                            };
                            onClickDelete(postDashboard);
                          }
                          setDashId(item.id);
                        }}
                      >
                        <UncontrolledCarousel
                          controls={false}
                          indicators={false}
                          interval={2000}
                          items={[
                            {
                              altText: item.name,
                              caption: item.name,
                              key: item.id,
                              src: temp1,
                            },
                          ]}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <ToastContainer closeButton={false} />
        </Container>
      </div>
    </React.Fragment>
  ) : dashId === 1 ? (
    <TemplateComponent isEdit={true} dashboards={dashboards} />
  ) : dashId === 3 ? (
    <TemplateComponent2 isEdit={true} dashboards={dashboards} />
  ) : dashId === 2 ? (
    <TemplateComponent3 isEdit={true} dashboards={dashboards} />
  ) : (
    <p>loading...</p>
  );
};

DashboardTemplate.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(DashboardTemplate));
