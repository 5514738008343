import React, { useEffect, useState } from "react";
import {
  Alert,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import socketIO from "../../helpers/socket";

import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import {
  getNotification as onGetNotifications,
  addNewNotification as onAddNewNotification,
  updateNotification as onUpdateNotification,
  deleteNotification as onDeleteNotification,
  getNotificationLoading,
} from "../../store/notification/action";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

import notifAudio from "../../assets/ringtone/notification.mp3";

//SimpleBar
import SimpleBar from "simplebar-react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
const io = socketIO.getInstance();
io.setHost("https://socket.atapteknologi.id");
const socket = io.getSocket();

const NotificationDropdown = (props) => {
  const { t } = props;
  const [notificationEvent, setNotificationEvent] = useState([]);
  const dispatch = useDispatch();
  const {
    notifications,
    isNotificationCreated,
    isNotificationSuccess,
    loading,
    error,
  } = useSelector((state) => ({
    notifications: state.Notification.notifications,
    isNotificationCreated: state.Notification.isNotificationCreated,
    isNotificationSuccess: state.Notification.isNotificationSuccess,
    loading: state.Notification.loading,
    error: state.Notification.error,
  }));

  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [notifList, setNotifList] = useState([]);
  const [notification, setNotification] = useState([]);
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const [userId, setUserId] = useState();
  const [valueNot, setValueNot] = useState();
  const [isSame, setIsSame] = useState(false);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const playAudio = () => {
    new Audio(notifAudio).play();
  };

  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      setUserId(obj.data.userId);
    } else {
      window.location = "/logout";
    }
  }, [dispatch]);

  useEffect(() => {
    function onConnect() {
      //setIsConnected(true);
    }

    function onDisconnect() {
      //setIsConnected(false);
    }

    function onFooEvent(value) {
      setValueNot(value);
      setNotificationEvent((previous) => [...previous, value]);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("atap_notification", onFooEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("foo", onFooEvent);
    };
  }, [userId]);

  useEffect(() => {
    if (valueNot) {
      valueNot.notification_user.forEach((item) => {
        if (item.userId === userId) {
          setIsSame(true);
        }
      });
    }
  }, [valueNot, userId]);

  useEffect(() => {
    if (isSame) {
      toast.info(valueNot.message);
      playAudio();
      setNotificationList((previous) => [...previous, valueNot]);
    }
  }, [isSame, valueNot]);

  useEffect(() => {
    setNotification(notifications);
  }, [notifications]);

  useEffect(() => {
    if (!isEmpty(notifications)) {
      setNotification(notifications);
      setIsEdit(false);
    }
  }, [notifications]);

  useEffect(() => {
    setNotificationList(notifications);
  }, [notifications]);

  useEffect(() => {
    if (!isSearch) {
      dispatch(onGetNotifications());
    }
    setIsSearch(true);
  }, [dispatch, isSearch]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className='topbar-head-dropdown ms-1 header-item'>
        <DropdownToggle
          type='button'
          tag='button'
          className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none'>
          <i className='bx bx-bell fs-22'></i>
          <span className='position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger'>
            {notificationList.length}
            <span className='visually-hidden'>{t("unread messages")}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-lg dropdown-menu-end p-0'>
          <div className='dropdown-head bg-primary bg-pattern rounded-top'>
            <div className='p-3'>
              <Row className='align-items-center'>
                <Col>
                  <h6 className='m-0 fs-16 fw-semibold text-white'>
                    {t("Notifications")}
                  </h6>
                </Col>
                <div className='col-auto dropdown-tabs'>
                  <span className='badge badge-soft-light fs-13'>
                    {notificationList.length} {t("New")}
                  </span>
                </div>
              </Row>
            </div>

            <div className='px-2 pt-2'>
              <Nav className='nav-tabs dropdown-tabs nav-tabs-custom'>
                <NavItem>
                  <NavLink
                    href='#'
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}>
                    {t("All")} ({notificationList.length})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href='#'
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}>
                    {t("General")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href='#'
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}>
                    {t("Alerts")}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId='1' className='py-2 ps-2'>
              <SimpleBar style={{ maxHeight: "300px" }} className='pe-2'>
                {(notificationList || []).map((element) => {
                  if (element.type === "alert") {
                    alertList.push(element);
                  } else {
                    notifList.push(element);
                  }
                  return (
                    <div
                      className='text-reset notification-item d-block dropdown-item position-relative'
                      key={"notification" + element.id}>
                      <Link target={"_blank"} to='https://erp.atapteknologi.id'>
                        <div className='d-flex'>
                          <div className='flex-1'>
                            <div to='#' className='stretched-link'>
                              <h6 className='mt-0 mb-1 lh-base'>
                                {element.name}
                              </h6>
                            </div>
                            <p className='mb-2 fs-11 fw-medium text-muted'>
                              {element.message}
                            </p>
                            <p className='mb-0 fs-11 fw-medium text-uppercase text-muted'>
                              <span>
                                <i className='mdi mdi-clock-outline'></i>{" "}
                                {moment(element.createdat).fromNow()}
                              </span>
                            </p>
                          </div>
                          <div className='px-2 fs-15'>
                            <div className='form-check notification-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id='all-notification-check01'
                              />
                              <label
                                className='form-check-label'
                                htmlFor='all-notification-check01'></label>
                            </div>
                            {/* <input className="form-check-input" type="checkbox" /> */}
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}

                <div className='my-3 text-center'>
                  <button
                    type='button'
                    className='btn btn-soft-success waves-effect waves-light'
                    onClick={() => {
                      dispatch(onUpdateNotification({ id: "all" }));
                    }}>
                    {t("View All Notifications")}
                    <i className='ri-arrow-right-line align-middle'></i>
                  </button>
                </div>
              </SimpleBar>
            </TabPane>

            <TabPane tabId='2' className='py-2 ps-2'>
              <SimpleBar style={{ maxHeight: "300px" }} className='pe-2'>
                {(notifList || []).map((element, elementIdx) => {
                  return (
                    <div
                      key={"notiflist" + elementIdx}
                      className='text-reset notification-item d-block dropdown-item position-relative'>
                      <div className='d-flex'>
                        <div className='flex-1'>
                          <Link to='#' className='stretched-link'>
                            <h6 className='mt-0 mb-1 lh-base'>
                              {element.name}
                            </h6>
                          </Link>
                          <p className='mb-2 fs-11 fw-medium text-muted'>
                            {element.message}
                          </p>
                          <p className='mb-0 fs-11 fw-medium text-uppercase text-muted'>
                            <span>
                              <i className='mdi mdi-clock-outline'></i>
                              {moment(element.createdat).fromNow()}
                            </span>
                          </p>
                        </div>
                        <div className='px-2 fs-15'>
                          <div className='form-check notification-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='all-notification-check01'
                            />
                            <label
                              className='form-check-label'
                              htmlFor='all-notification-check01'></label>
                          </div>
                          {/* <input className="form-check-input" type="checkbox" /> */}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className='my-3 text-center'>
                  <button
                    type='button'
                    className='btn btn-soft-success waves-effect waves-light'>
                    {t("View All Messages")}
                    <i className='ri-arrow-right-line align-middle'></i>
                  </button>
                </div>
              </SimpleBar>
            </TabPane>
            <TabPane tabId='3' className='p-4'>
              {(alertList || []).map((element) => {
                return (
                  <div
                    key={"alertlist" + element.id}
                    className='text-reset notification-item d-block dropdown-item position-relative'>
                    <div className='d-flex'>
                      <div className='flex-1'>
                        <Link to='#' className='stretched-link'>
                          <h6 className='mt-0 mb-1 lh-base'>{element.name}</h6>
                        </Link>
                        <p className='mb-2 fs-11 fw-medium text-muted'>
                          {element.message}
                        </p>
                        <p className='mb-0 fs-11 fw-medium text-uppercase text-muted'>
                          <span>
                            <i className='mdi mdi-clock-outline'></i>
                            {moment(element.createdat).fromNow()}
                          </span>
                        </p>
                      </div>
                      <div className='px-2 fs-15'>
                        <div className='form-check notification-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='all-notification-check01'
                          />
                          <label
                            className='form-check-label'
                            htmlFor='all-notification-check01'></label>
                        </div>
                        {/* <input className="form-check-input" type="checkbox" /> */}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className='text-center pb-5 mt-2'>
                <h6 className='fs-18 fw-semibold lh-base'>
                  {t("Hey! You have no any notifications")}
                </h6>
              </div>
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
      <ToastContainer />
    </React.Fragment>
  );
};

NotificationDropdown.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(NotificationDropdown));
