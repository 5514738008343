import {
  GET_SENSOR,
  GET_SENSOR_SUCCESS,
  GET_SENSOR_FAIL,
  DELETE_SENSOR,
  DELETE_SENSOR_SUCCESS,
  DELETE_SENSOR_FAIL,
  UPDATE_SENSOR,
  UPDATE_SENSOR_SUCCESS,
  UPDATE_SENSOR_FAIL,
  ADD_NEW_SENSOR,
  ADD_SENSOR_SUCCESS,
  ADD_SENSOR_FAIL,
  SENSOR_LOADING,
  SENSOR_RESET,
} from "./actionType";

export const resetSensorState = (actionType) => ({
  type: SENSOR_RESET,
  payload: { actionType },
});

export const getSensorSuccess = (actionType, data) => ({
  type: GET_SENSOR_SUCCESS,
  payload: { actionType, data },
});

export const getSensorFail = (actionType, error) => ({
  type: GET_SENSOR_FAIL,
  payload: { actionType, error },
});

export const getSensor = (data) => ({
  type: GET_SENSOR,
  payload: data,
});

export const getSensorLoading = () => ({
  type: SENSOR_LOADING,
});

export const deleteSensor = (data) => ({
  type: DELETE_SENSOR,
  payload: data,
});

export const deleteSensorSuccess = (data) => ({
  type: DELETE_SENSOR_SUCCESS,
  payload: data,
});

export const deleteSensorFail = (error) => ({
  type: DELETE_SENSOR_FAIL,
  payload: error,
});

export const updateSensor = (data) => ({
  type: UPDATE_SENSOR,
  payload: data,
});

export const updateSensorFail = (error) => ({
  type: UPDATE_SENSOR_FAIL,
  payload: error,
});

export const updateSensorSuccess = (data) => ({
  type: UPDATE_SENSOR_SUCCESS,
  payload: data,
});

export const addNewSensor = (data) => ({
  type: ADD_NEW_SENSOR,
  payload: data,
});

export const addSensorSuccess = (data) => ({
  type: ADD_SENSOR_SUCCESS,
  payload: data,
});

export const addSensorFail = (error) => ({
  type: ADD_SENSOR_FAIL,
  payload: error,
});
