import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_STORAGEFOLDER,
  DELETE_STORAGEFOLDER,
  UPDATE_STORAGEFOLDER,
  ADD_NEW_STORAGEFOLDER,
} from "./actionType";

import {
  getStorageFolderSuccess,
  getStorageFolderFail,
  deleteStorageFolderSuccess,
  deleteStorageFolderFail,
  updateStorageFolderSuccess,
  updateStorageFolderFail,
  addStorageFolderSuccess,
  addStorageFolderFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveStorageFolderApi,
  getStorageFolderApi,
  updateStorageFolderApi,
  deleteStorageFolderApi,
  addNewStorageFolderApi,
} from "../../helpers/backend_helper";

function* getStorageFolder({ payload: storageFolder }) {
  try {
    const response = yield call(getStorageFolderApi, storageFolder);
    yield put(getStorageFolderSuccess(GET_STORAGEFOLDER, response.data));
  } catch (error) {
    yield put(getStorageFolderFail(GET_STORAGEFOLDER, error));
  }
}

function* onUpdateStorageFolder({ payload: storageFolder }) {
  try {
    if (storageFolder.setApprove) {
      const response = yield call(approveStorageFolderApi, storageFolder);
      yield put(updateStorageFolderSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateStorageFolderApi, storageFolder);
      yield put(updateStorageFolderSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateStorageFolderFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteStorageFolder({ payload: storageFolder }) {
  try {
    const response = yield call(deleteStorageFolderApi, storageFolder);
    yield put(deleteStorageFolderSuccess({ storageFolder, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteStorageFolderFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewStorageFolder({ payload: storageFolder }) {
  try {
    const response = yield call(addNewStorageFolderApi, storageFolder);
    yield put(addStorageFolderSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addStorageFolderFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetStorageFolder() {
  yield takeEvery(GET_STORAGEFOLDER, getStorageFolder);
}

export function* watchUpdateStorageFolder() {
  yield takeEvery(UPDATE_STORAGEFOLDER, onUpdateStorageFolder);
}

export function* watchDeleteStorageFolder() {
  yield takeEvery(DELETE_STORAGEFOLDER, onDeleteStorageFolder);
}

export function* watchAddNewStorageFolder() {
  yield takeEvery(ADD_NEW_STORAGEFOLDER, onAddNewStorageFolder);
}

function* storageFolderSaga() {
  yield all([
    fork(watchGetStorageFolder),
    fork(watchDeleteStorageFolder),
    fork(watchUpdateStorageFolder),
    fork(watchAddNewStorageFolder),
  ]);
}

export default storageFolderSaga;
