import {
  GET_BRANDTYPE,
  GET_BRANDTYPE_SUCCESS,
  GET_BRANDTYPE_FAIL,
  DELETE_BRANDTYPE,
  DELETE_BRANDTYPE_SUCCESS,
  DELETE_BRANDTYPE_FAIL,
  UPDATE_BRANDTYPE,
  UPDATE_BRANDTYPE_SUCCESS,
  UPDATE_BRANDTYPE_FAIL,
  ADD_NEW_BRANDTYPE,
  ADD_BRANDTYPE_SUCCESS,
  ADD_BRANDTYPE_FAIL,
  BRANDTYPE_LOADING,
  BRANDTYPE_RESET,
} from "./actionType";

export const resetBrandTypeState = (actionType) => ({
  type: BRANDTYPE_RESET,
  payload: { actionType },
});

export const getBrandTypeSuccess = (actionType, data) => ({
  type: GET_BRANDTYPE_SUCCESS,
  payload: { actionType, data },
});

export const getBrandTypeFail = (actionType, error) => ({
  type: GET_BRANDTYPE_FAIL,
  payload: { actionType, error },
});

export const getBrandType = (data) => ({
  type: GET_BRANDTYPE,
  payload: data,
});

export const getBrandTypeLoading = () => ({
  type: BRANDTYPE_LOADING,
});

export const deleteBrandType = (data) => ({
  type: DELETE_BRANDTYPE,
  payload: data,
});

export const deleteBrandTypeSuccess = (data) => ({
  type: DELETE_BRANDTYPE_SUCCESS,
  payload: data,
});

export const deleteBrandTypeFail = (error) => ({
  type: DELETE_BRANDTYPE_FAIL,
  payload: error,
});

export const updateBrandType = (data) => ({
  type: UPDATE_BRANDTYPE,
  payload: data,
});

export const updateBrandTypeFail = (error) => ({
  type: UPDATE_BRANDTYPE_FAIL,
  payload: error,
});

export const updateBrandTypeSuccess = (data) => ({
  type: UPDATE_BRANDTYPE_SUCCESS,
  payload: data,
});

export const addNewBrandType = (data) => ({
  type: ADD_NEW_BRANDTYPE,
  payload: data,
});

export const addBrandTypeSuccess = (data) => ({
  type: ADD_BRANDTYPE_SUCCESS,
  payload: data,
});

export const addBrandTypeFail = (error) => ({
  type: ADD_BRANDTYPE_FAIL,
  payload: error,
});
