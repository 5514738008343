import {
  STORAGEFOLDER_LOADING,
  GET_STORAGEFOLDER,
  GET_STORAGEFOLDER_SUCCESS,
  GET_STORAGEFOLDER_FAIL,
  ADD_STORAGEFOLDER_SUCCESS,
  ADD_STORAGEFOLDER_FAIL,
  DELETE_STORAGEFOLDER_SUCCESS,
  DELETE_STORAGEFOLDER_FAIL,
  UPDATE_STORAGEFOLDER_SUCCESS,
  UPDATE_STORAGEFOLDER_FAIL,
  STORAGEFOLDER_RESET,
} from "./actionType";

const INIT_STATE = {
  storageFolders: [],
  error: {},
};

const StorageFolder = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STORAGEFOLDER_SUCCESS:
      switch (action.payload.actionType) {
        case GET_STORAGEFOLDER:
          return {
            ...state,
            storageFolders: action.payload.data,
            isStorageFolderCreated: false,
            isStorageFolderSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_STORAGEFOLDER_FAIL:
      switch (action.payload.actionType) {
        case GET_STORAGEFOLDER_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isStorageFolderCreated: false,
            isStorageFolderSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_STORAGEFOLDER: {
      return {
        ...state,
        isStorageFolderCreated: false,
        loading: true,
      };
    }

    case STORAGEFOLDER_LOADING: {
      return {
        ...state,
        isStorageFolderCreated: false,
        loading: true,
      };
    }

    case ADD_STORAGEFOLDER_SUCCESS:
      return {
        ...state,
        isStorageFolderCreated: true,
        loading: false,
        storageFolders: [...state.storageFolders, action.payload.data],
      };

    case ADD_STORAGEFOLDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_STORAGEFOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        storageFolders: state.storageFolders.filter(
          (storageFolder) =>
            storageFolder.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_STORAGEFOLDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_STORAGEFOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        storageFolders: state.storageFolders.map((storageFolder) =>
          storageFolder.id.toString() === action.payload.data.id.toString()
            ? { ...storageFolder, ...action.payload.data }
            : storageFolder
        ),
      };

    case UPDATE_STORAGEFOLDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case STORAGEFOLDER_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default StorageFolder;
