import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import Register from "../pages/Authentication/Register";
import Logout from "../pages/Authentication/Logout";

//Dashboard
import Dashboard from "../pages/Dashboard";
//Monitoring
import Monitoring from "../pages/Monitoring";
import Device from "../pages/Master/Device";
import DeviceType from "../pages/Master/DeviceType";
import Parameter from "../pages/Master/Parameter/index";
import SensorType from "../pages/Master/SensorType";
import Sensor from "../pages/Master/Sensor";

import Storage from "../pages/Storage";
import UserProfile from "../pages/Profile";
import DeviceDetail from "../pages/DeviceDetail";

import ReportTemplate from "../pages/Report/TemplateReport";
import ReportCbu from "../pages/Report/TemplateReport/reportCbu";

//Report
import TemplateReport from "../pages/Report/TemplateReport";
import Template from "../pages/Report/TemplateReport/Template";

import DashboardTemplate from "../pages/Setting/DashboardTemplate";

import QRLanding from "../pages/Landing/qr";
import User from "../pages/User";
import ActivityLog from "../pages/ActivityLog";
import General from "../pages/Setting/General";
import Geofence from "../pages/Master/Geofence";
import DownloadApp from "../pages/DownloadApp";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/monitoring", component: <Monitoring /> },
  { path: "/device-detail", component: <DeviceDetail /> },
  { path: "/device", component: <Device /> },
  { path: "/apps", component: <DownloadApp /> },
  {
    path: "/geofence",
    component: <Geofence />,
  },
  { path: "/parameter", component: <Parameter /> },
  { path: "/device-types", component: <DeviceType /> },
  { path: "/storage", component: <Storage /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/sensor-types", component: <SensorType /> },
  { path: "/sensor", component: <Sensor /> },
  { path: "/report-template", component: <TemplateReport /> },
  { path: "/report-template/:rptTemplateId", component: <Template /> },
  { path: "/report-template", component: <ReportTemplate /> },
  { path: "/report-cbu", component: <ReportCbu /> },
  { path: "/dashboard-templates", component: <DashboardTemplate /> },
  { path: "/users", component: <User /> },
  { path: "/activity-logs", component: <ActivityLog /> },
  { path: "/general", component: <General /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },
  { path: "/qr/:taskId", component: <QRLanding /> },
];

export { authProtectedRoutes, publicRoutes };
