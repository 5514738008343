export const REPORTTEMPLATE_LOADING = "REPORTTEMPLATE_LOADING";
export const REPORTTEMPLATE_RESET = "REPORTTEMPLATE_RESET";
export const GET_REPORTTEMPLATE = "GET_REPORTTEMPLATE";
export const GET_REPORTTEMPLATE_SUCCESS = "GET_REPORTTEMPLATE_SUCCESS";
export const GET_REPORTTEMPLATE_FAIL = "GET_REPORTTEMPLATE_FAIL";
export const DELETE_REPORTTEMPLATE = "DELETE_REPORTTEMPLATE";
export const DELETE_REPORTTEMPLATE_SUCCESS = "DELETE_REPORTTEMPLATE_SUCCESS";
export const DELETE_REPORTTEMPLATE_FAIL = "DELETE_REPORTTEMPLATE_FAIL";
export const UPDATE_REPORTTEMPLATE = "UPDATE_REPORTTEMPLATE";
export const UPDATE_REPORTTEMPLATE_SUCCESS = "UPDATE_REPORTTEMPLATE_SUCCESS";
export const UPDATE_REPORTTEMPLATE_FAIL = "UPDATE_REPORTTEMPLATE_FAIL";
export const ADD_NEW_REPORTTEMPLATE = "ADD_NEW_REPORTTEMPLATE";
export const ADD_REPORTTEMPLATE_SUCCESS = "ADD_REPORTTEMPLATE_SUCCESS";
export const ADD_REPORTTEMPLATE_FAIL = "ADD_REPORTTEMPLATE_FAIL";
