import React, { useEffect, useState, Fragment } from "react";
import {
  getDashboard,
  resetDashboardState,
} from "../../store/dashboard/action";
import { getCompany, resetCompanyState } from "../../store/company/action";

import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Spinner } from "reactstrap";
import Select from "react-select";
import { api } from "../../config";
import { isEmpty } from "lodash";

function BreadCrumbTemplate({
  dashboards,
  clients,
  userType,
  t,
}) {
  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState([]);
  const [compId, setCompId] = useState();
  const [companyId, setCompanyId] = useState();

  const { companiesLoading, companies } = useSelector(
    (state) => ({
      companiesLoading: state.Company.loading,
      companies: state.Company.companies,
    })
  );

  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      setCompanyId(obj.data.companyId);
    } else {
      window.location = "/logout";
    }
  }, []);

  useEffect(() => {
    dispatch(resetCompanyState());
    dispatch(getCompany({ id: JSON.parse(sessionStorage.getItem("authUser")).companyId }));
  }, [dispatch]);

  const handleDashboard = (data) => {
    setCompId(data.value);
    dispatch(resetDashboardState());
    dispatch(getDashboard({ companyId: data.value }));
  };

  useEffect(() => {
    if (!isEmpty(companies)) {
      var data = [];
      var opt = [];
      companies.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setCompanyList(data);
    }
  }, [companies]);

  return (
    <Fragment>
      {dashboards && dashboards[0] ? (
        <Row>
          <Col xs={12}>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  {dashboards && dashboards[0].dashboard_company.image !== null &&
                    dashboards[0].dashboard_company.image !== "" ? (
                    <img
                      src={
                        api.MEDIA_URL +
                        "/media/" +
                        dashboards[0].dashboard_company.image
                      }
                      alt=""
                      className="avatar-xs rounded-circle"
                    />
                  ) : (
                    <div className="flex-shrink-0 avatar-xs me-2">
                      <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                        {dashboards[0].dashboard_company.name !== null
                          ? dashboards[0].dashboard_company.name
                            .charAt(0)
                            .toUpperCase()
                          : "-"}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex-grow-1 ms-2 fs-5">
                  {dashboards[0].dashboard_company.name !== null
                    ? dashboards[0].dashboard_company.name
                    : "-"}
                </div>
              </div>
              {userType === "admin" || userType === "client" ? (
                <div className="flex-shrink-1">
                  {companiesLoading ? (
                    <>
                      <Spinner
                        color="primary"
                        type="grow"
                        size={"sm"}
                        className="ms-2"
                      ></Spinner>
                      &nbsp;
                      <Spinner color="success" type="grow" size={"sm"}>
                        Loading...
                      </Spinner>
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}
                  <Select
                    name="company"
                    placeholder={`${t("Select")} ${t("Company")}`}
                    onChange={(e) => {
                      handleDashboard(e);
                    }}
                    options={companyList}
                  ></Select>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      ) : companies[0] ? (
        <Row>
          <Col xs={12}>
            <div className="d-flex page-title-box d-sm-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  {companies[0].image !== null &&
                    companies[0].image !== "" ? (
                    <img
                      src={
                        api.MEDIA_URL +
                        "/media/" +
                        companies[0].image
                      }
                      alt=""
                      className="avatar-xs rounded-circle"
                    />
                  ) : (
                    <div className="flex-shrink-0 avatar-xs me-2">
                      <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                        {companies[0].name !== null
                          ? companies[0].name
                            .charAt(0)
                            .toUpperCase()
                          : "-"}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex-grow-1 ms-2 fs-5">
                  {companies[0].name !== null
                    ? companies[0].name
                    : "-"}
                </div>
              </div>
              {userType === "admin" || userType === "client" ? (
                <div className="flex-shrink-1">
                  {companiesLoading ? (
                    <>
                      <Spinner
                        color="primary"
                        type="grow"
                        size={"sm"}
                        className="ms-2"
                      ></Spinner>
                      &nbsp;
                      <Spinner color="success" type="grow" size={"sm"}>
                        Loading...
                      </Spinner>
                      &nbsp;
                    </>
                  ) : (
                    ""
                  )}
                  <Select
                    name="company"
                    placeholder={`${t("Select")} ${t("Company")}`}
                    onChange={(e) => {
                      handleDashboard(e);
                    }}
                    options={companyList}
                  ></Select>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      ) : null}
    </Fragment>
  );
}

export default BreadCrumbTemplate;
