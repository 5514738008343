import {
  BRAND_LOADING,
  GET_BRAND,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAIL,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAIL,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  BRAND_RESET
} from "./actionType";

const INIT_STATE = {
  brands: [],
  error: {},
};

const Brand = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRAND_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BRAND:
          return {
            ...state,
            brands: action.payload.data,
            isBrandCreated: false,
            isBrandSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_BRAND_FAIL:
      switch (action.payload.actionType) {
        case GET_BRAND_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isBrandCreated: false,
            isBrandSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_BRAND: {
      return {
        ...state,
        isBrandCreated: false,
        loading: true,
      };
    }

    case BRAND_LOADING: {
      return {
        ...state,
        isBrandCreated: false,
        loading: true,
      };
    }

    case ADD_BRAND_SUCCESS:
      return {
        ...state,
        isBrandCreated: true,
        loading: false,
        brands: [...state.brands, action.payload.data],
      };

    case ADD_BRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        brands: state.brands.filter(
          brand => brand.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_BRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        brands: state.brands.map(brand =>
          brand.id.toString() === action.payload.data.id.toString() ? { ...brand, ...action.payload.data } : brand
        ),
      };

    case UPDATE_BRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BRAND_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Brand;