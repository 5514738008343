import {
  DEVICETYPE_LOADING,
  GET_DEVICETYPE,
  GET_DEVICETYPE_SUCCESS,
  GET_DEVICETYPE_FAIL,
  ADD_DEVICETYPE_SUCCESS,
  ADD_DEVICETYPE_FAIL,
  DELETE_DEVICETYPE_SUCCESS,
  DELETE_DEVICETYPE_FAIL,
  UPDATE_DEVICETYPE_SUCCESS,
  UPDATE_DEVICETYPE_FAIL,
  DEVICETYPE_RESET,
} from "./actionType";

const INIT_STATE = {
  deviceTypes: [],
  error: {},
};

const DeviceType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICETYPE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_DEVICETYPE:
          return {
            ...state,
            deviceTypes: action.payload.data,
            isDeviceTypeCreated: false,
            isDeviceTypeSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_DEVICETYPE_FAIL:
      switch (action.payload.actionType) {
        case GET_DEVICETYPE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isDeviceTypeCreated: false,
            isDeviceTypeSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_DEVICETYPE: {
      return {
        ...state,
        isDeviceTypeCreated: false,
        loading: true,
      };
    }

    case DEVICETYPE_LOADING: {
      return {
        ...state,
        isDeviceTypeCreated: false,
        loading: true,
      };
    }

    case ADD_DEVICETYPE_SUCCESS:
      return {
        ...state,
        isDeviceTypeCreated: true,
        loading: false,
        deviceTypes: [...state.deviceTypes, action.payload.data],
      };

    case ADD_DEVICETYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_DEVICETYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceTypes: state.deviceTypes.filter(
          (deviceType) => deviceType.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DEVICETYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_DEVICETYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        deviceTypes: state.deviceTypes.map((deviceType) =>
          deviceType.id.toString() === action.payload.data.id.toString()
            ? { ...deviceType, ...action.payload.data }
            : deviceType
        ),
      };

    case UPDATE_DEVICETYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DEVICETYPE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default DeviceType;
