import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_STORAGERENAME,
  DELETE_STORAGERENAME,
  UPDATE_STORAGERENAME,
  ADD_NEW_STORAGERENAME,
  GET_STORAGERENAME_ONE,
} from "./actionType";

import {
  getStorageRenameSuccess,
  getStorageRenameFail,
  deleteStorageRenameSuccess,
  deleteStorageRenameFail,
  updateStorageRenameSuccess,
  updateStorageRenameFail,
  addStorageRenameSuccess,
  addStorageRenameFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveStorageRenameApi,
  getStorageRenameApi,
  updateStorageRenameApi,
  deleteStorageRenameApi,
  addNewStorageRenameApi,
  getOneStorageRenameApi,
} from "../../helpers/backend_helper";

function* getStorageRename({ payload: storageRename }) {
  try {
    const response = yield call(getStorageRenameApi, storageRename);
    yield put(getStorageRenameSuccess(GET_STORAGERENAME, response.data));
  } catch (error) {
    yield put(getStorageRenameFail(GET_STORAGERENAME, error));
  }
}

function* getStorageRenameOne({ payload: id }) {
  try {
    const response = yield call(getOneStorageRenameApi, id);
    yield put(getStorageRenameSuccess(GET_STORAGERENAME_ONE, response.data));
  } catch (error) {
    yield put(getStorageRenameFail(GET_STORAGERENAME_ONE, error));
  }
}

function* onUpdateStorageRename({ payload: storageRename }) {
  try {
    if (storageRename.setApprove) {
      const response = yield call(approveStorageRenameApi, storageRename);
      yield put(updateStorageRenameSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateStorageRenameApi, storageRename);
      yield put(updateStorageRenameSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    console.log(error);
    yield put(updateStorageRenameFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteStorageRename({ payload: storageRename }) {
  try {
    const response = yield call(deleteStorageRenameApi, storageRename);
    yield put(deleteStorageRenameSuccess({ storageRename, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteStorageRenameFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewStorageRename({ payload: storageRename }) {
  try {
    const response = yield call(addNewStorageRenameApi, storageRename);
    yield put(addStorageRenameSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addStorageRenameFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetStorageRename() {
  yield takeEvery(GET_STORAGERENAME, getStorageRename);
}

export function* watchUpdateStorageRename() {
  yield takeEvery(UPDATE_STORAGERENAME, onUpdateStorageRename);
}

export function* watchDeleteStorageRename() {
  yield takeEvery(DELETE_STORAGERENAME, onDeleteStorageRename);
}

export function* watchAddNewStorageRename() {
  yield takeEvery(ADD_NEW_STORAGERENAME, onAddNewStorageRename);
}

export function* watchGetStorageRenameOne() {
  yield takeEvery(GET_STORAGERENAME_ONE, getStorageRenameOne);
}

function* storageRenameSaga() {
  yield all([
    fork(watchGetStorageRename),
    fork(watchDeleteStorageRename),
    fork(watchUpdateStorageRename),
    fork(watchAddNewStorageRename),
    fork(watchGetStorageRenameOne),
  ]);
}

export default storageRenameSaga;
