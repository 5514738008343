import React from "react";
import { GaugeChart } from "../../pages/Dashboard/ECharts";

function WidgetGauge({ data }) {
  return (
    <>
      <div className='mt-3'>
        <h5 className='text-center'>
          {data.name
            ? data.name
            : null}
        </h5>
        <GaugeChart
          dataColors='["--vz-primary"]'
          min={data.min}
          max={data.max}
          name={
            data.dd_parameter[0].ddp_parameter
              ? data.dd_parameter[0].ddp_parameter.label
              : null
          }
          value={
            (
              Math.round(
                data.dd_parameter[0].ddp_parameter
                  ? data.dd_parameter[0].ddp_parameter.amount
                  : null * 100
              ) / 100
            ).toFixed(2) || 0
          }
        />
        <h5 className='text-center'>
          {data.dd_parameter[0].ddp_parameter
            ? data.dd_parameter[0].ddp_parameter.label
            : null}
        </h5>
      </div>
    </>
  );
}

export default WidgetGauge;
