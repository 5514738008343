export const SENSORTYPE_LOADING = "SENSORTYPE_LOADING";
export const SENSORTYPE_RESET = "SENSORTYPE_RESET";
export const GET_SENSORTYPE = "GET_SENSORTYPE";
export const GET_SENSORTYPE_SUCCESS = "GET_SENSORTYPE_SUCCESS";
export const GET_SENSORTYPE_FAIL = "GET_SENSORTYPE_FAIL";
export const DELETE_SENSORTYPE = "DELETE_SENSORTYPE";
export const DELETE_SENSORTYPE_SUCCESS = "DELETE_SENSORTYPE_SUCCESS";
export const DELETE_SENSORTYPE_FAIL = "DELETE_SENSORTYPE_FAIL";
export const UPDATE_SENSORTYPE = "UPDATE_SENSORTYPE";
export const UPDATE_SENSORTYPE_SUCCESS = "UPDATE_SENSORTYPE_SUCCESS";
export const UPDATE_SENSORTYPE_FAIL = "UPDATE_SENSORTYPE_FAIL";
export const ADD_NEW_SENSORTYPE = "ADD_NEW_SENSORTYPE";
export const ADD_SENSORTYPE_SUCCESS = "ADD_SENSORTYPE_SUCCESS";
export const ADD_SENSORTYPE_FAIL = "ADD_SENSORTYPE_FAIL";
