import React, { Fragment, useEffect, useRef, useState, useMemo, useCallback } from "react";
import { AdvancedMarker, useMap, InfoWindow, useMapsLibrary } from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import mapEngine from "../../assets/images/map-engine.png";
import mapGps from "../../assets/images/map-gps.png";
import mapLight from "../../assets/images/map-lighting.png";
import mapPower from "../../assets/images/map-power.png";
import mapTank from "../../assets/images/map-tank.png";
import mapVessel from "../../assets/images/map-vessel.png";
import mapWater from "../../assets/images/map-water.png";
import mapWeather from "../../assets/images/map-weather.png";
import { api } from "../../config";
import dummyImg from "../../assets/images/iot_device.png";
import "react-toastify/dist/ReactToastify.css";

const CustomMarker = ({ dat, location, handleMarkerClick }) => {
  const handleImage = (name) => {
    switch (name) {
      case "Fuel Management System":
        return mapTank;
      case "Power Monitoring":
        return mapPower;
      case "Vessel Monitoring System":
        return mapVessel;
      case "Smart Water Monitoring":
        return mapWater;
      case "Engine Monitoring":
        return mapEngine;
      case "Energy Monitoring System":
        return mapLight;
      case "Temperature Monitoring":
        return mapWeather;
      case "Gps Tracking":
        return mapGps;
      default:
        return mapGps;
    }
  };
  return (
    <AdvancedMarker
      position={location}
      // ref={(marker) => setMarkerRef(marker, dat.id + "m")}
      onClick={() => handleMarkerClick(dat)}
    >
      <div
        style={{
          width: "35px",
          height: "50px",
          backgroundImage: `url(${handleImage(dat.device_service ? dat.device_service.name : "")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          boxSizing: "border-box",
        }}
      ></div>
    </AdvancedMarker>
  );
};

function PoiMarkers({ data, deviceData, onClickView }) {
  const map = useMap();
  const maps = useMapsLibrary("core");
  // const maps = useMapsLibrary("maps");
  // const line = new maps.Polyline({
  //   geodesic: true,
  //   strokeColor: "#3498db",
  //   strokeOpacity: 1.0,
  //   strokeWeight: 2,
  // });
  const [markers, setMarkers] = useState({});
  const [hasBound, setHasBound] = useState(false);
  const clusterer = useRef(null);
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {
    console.log(map, "olkkkkkkkkkkkkkkkkkkkkkkkk");
  }, [map]);

  // useEffect(() => {
  //   if (!clusterer) return;
  //   clusterer.current?.clearMarkers();
  //   clusterer.current?.addMarkers(Object.values(markers));
  // }, [markers]);

  useEffect(() => {
    if (map) {
      if (deviceData) {
        if (deviceData.isPanTo) {
          map.setCenter(deviceData.location);
          map.panTo(deviceData.location);
          map.setZoom(18);
        }

        let mm = [];

        if (!hasBound) {
          console.log(data, "okkkkkk");
          for (const dat of data) {
            if (parseFloat(dat.latitude) && parseFloat(dat.longitude) && parseFloat(dat.longitude) !== 0) {
              mm.push({
                key: dat.id,
                position: { lat: parseFloat(dat.latitude), lng: parseFloat(dat.longitude) },
              });
            }
          }

          const bounds = new maps.LatLngBounds();
          mm.forEach((marker) => bounds.extend(marker.position));
          map.fitBounds(bounds);
          setHasBound(true);
        }
      }
      
    }
  }, [map, deviceData, data, hasBound]);

  // const setMarkerRef = (marker, key) => {
  //   if (marker && markers[key]) return;
  //   if (!marker && !markers[key]) return;

  //   setMarkers((prev) => {
  //     if (marker) {
  //       return { ...prev, [key]: marker };
  //     } else {
  //       const newMarkers = { ...prev };
  //       delete newMarkers[key];
  //       return newMarkers;
  //     }
  //   });
  // };

  useEffect(() => {
    // if (deviceData) {
    //   const dataDev = data.find((d) => d.id === deviceData.id);
    //   if (dataDev) {
    //     console.log("lllllll");
    //     dataDev.line = new maps.Polyline({
    //       path: dataDev.routes,
    //       geodesic: true,
    //       strokeColor: "#3498db",
    //       strokeOpacity: 1.0,
    //       strokeWeight: 2,
    //     });
    //     dataDev.line.setMap(map);
    //   }
    //   if (deviceData.isChecked === true) {
    //     console.log(true);
    //   }else{
    //   }
    // }
    // line.setPath([]);
    // line.setMap(null);
  }, [data, deviceData]);

  useEffect(() => {}, [data]);

  const handleMarkerClick = (marker) => {
    setActiveMarker(marker.id + "m");
  };

  const handleInfoWindowClose = () => {
    setActiveMarker(null);
  };

  return (
    <>
      {data.map((dat, i) => {
        if (parseFloat(dat.latitude) && parseFloat(dat.longitude) && parseFloat(dat.longitude) !== 0) {
          // console.log(parseFloat(dat.latitude), dat.name, "l");
          const location = { lat: parseFloat(dat.latitude), lng: parseFloat(dat.longitude) };
          return (
            <Fragment key={"dat-" + i}>
              <CustomMarker
                key={dat.id + "m"}
                dat={dat}
                location={location} // Sesuaikan dengan properti location yang digunakan
                // setMarkerRef={setMarkerRef}
                handleMarkerClick={handleMarkerClick}
              />
              {/* <AdvancedMarker key={dat.id} position={location} ref={(marker) => setMarkerRef(marker, dat.id)} onClick={() => handleMarkerClick(dat)}>
                <div style={{ width: "35px", height: "50px", backgroundImage: `url(${handleImage(dat.device_service.name)})`, backgroundSize: "cover", backgroundPosition: "center", boxSizing: "border-box" }}></div>
              </AdvancedMarker> */}

              {activeMarker === dat.id + "m" && (
                <InfoWindow position={location} onClose={handleInfoWindowClose} style={{ width: "350px" }}>
                  <div>
                    <div className="d-flex">
                      <div className="justify-content-start">
                        <h3 className="text-primary">{dat.name}</h3>
                        <p className="m-0">{`Location : ${dat.latitude},${dat.longitude}`}</p>
                        <p className="m-0">Identifier: {dat.identifier}</p>
                        <p className="m-0 mb-3">Phone: {dat.phone}</p>
                      </div>
                      <div className="justify-content-end ms-3 mt-3">
                        <button
                          onClick={() => {
                            onClickView(dat);
                          }}
                          className="btn btn-primary"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasExample"
                          aria-controls="offcanvasExample"
                        >
                          View
                        </button>
                      </div>
                    </div>
                    <img alt="" src={dat.image ? api.MEDIA_URL + "/media/" + dat.image : dummyImg} className="img-thumbnail w-100" />
                  </div>
                </InfoWindow>
              )}
              {/* {!dat.isMarkerActive ? (
                              <>
                                  
                              </>
                          ) : null} */}
            </Fragment>
          );
        } else {
          return null;
        }
      })}
    </>
  );
}

export default PoiMarkers;
