/* eslint-disable array-callback-return */
import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, ModalBody, Label, Input, FormFeedback, Spinner } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import ApproveModal from "../../Components/Common/ApproveModal";
import { isEmpty } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";

import Flatpickr from "react-flatpickr";
import Select from "react-select";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";

import { getClient, resetClientState } from "../../store/client/action";
import { getBranch, resetBranchState } from "../../store/branch/action";
import { resetCompanyState, getCompany } from "../../store/company/action";
//Import actions
import { resetUserState, getUser as onGetUser, addNewUser as onAddNewUser, updateUser as onUpdateUser, deleteUser as onDeleteUser, getUserLoading } from "../../store/users/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultImg from "../../assets/images/upload.png";
import imgNotFound from "../../assets/images/users/user-dummy-img.jpg"
import { api } from "../../config";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import ModalInputFile from "../../Components/ModalInputFile";

import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const User = (props) => {
    const { t } = props;
    const [logo, setLogo] = useState(defaultImg);
    const [modal, setModal] = useState(false);
    const [modalMedia, setModalMedia] = useState(false);
    const [modalUsernamePass, setModalUsernamePass] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const dispatch = useDispatch();

    const { companies, clients, users, isUserCreated, isUserSuccess, loading, error, roleListloading, roleLists, branches, branchLoading, devices } = useSelector((state) => ({
        users: state.User.users,
        isUserCreated: state.User.isUserCreated,
        isUserSuccess: state.User.isUserSuccess,
        loading: state.User.loading,
        error: state.User.error,
        clients: state.Client.clients,
        companies: state.Company.companies,
        roleLists: state.RoleList.roleLists,
        roleListloading: state.RoleList.loading,
        branches: state.Branch.branches,
        branchLoading: state.Branch.loading,
        devices: state.Device.devices
    }));

    const [userTypes, setUserTypes] = useState([])

    const defaultdate = () => {
        return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
    };
    const [filterDate, setFilterDate] = useState(defaultdate());
    const [userList, setUserList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [user, setUser] = useState([]);
    const [isExportCSV, setIsExportCSV] = useState(false);
    const [statusActive, setStatusActive] = useState([]);
    const [checkedDevice, setCheckedDevice] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isUsername, setIsUsername] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const [modalTag, setModalTag] = useState(false)
    const [clientId, setClientId] = useState()
    const [clientName, setClientName] = useState()
    const [companyId, setCompanyId] = useState()
    const [companyName, setCompanyName] = useState()
    const [userType, setUserType] = useState("")
    const [branchList, setBranchList] = useState([])
    const [byBranch, setByBranch] = useState(false)
    const [clientListView, setClientListView] = useState([])
    const [roleList, setRoleList] = useState([
        { name: "Dashboard", isCheck: false },
        { name: "Monitoring", isCheck: false },
        { name: "Device", isCheck: false },
        { name: "Report", isCheck: false },
        { name: "Activity Log", isCheck: false },
        { name: "Master Data", isCheck: false },
        { name: "Master Data - Device", isCheck: false },
        { name: "Master Data - Sensor", isCheck: false },
        { name: "Master Data - Parameter", isCheck: false },
        { name: "Master Data - Device Type", isCheck: false },
        { name: "Master Data - Sensor Type", isCheck: false },
        { name: "Dashboard Template", isCheck: false },
        { name: "User", isCheck: false },
    ])

    const [isExecutive, setIsExecutive] = useState(false);
    const rmFilterStr = /[\s~`!@#$%^&*()_+\-={[}\]|\\:;"'<,>.?/]+/g;

    const [open, setOpen] = useState("2");
    const toggleAccordion = (id) => {
        if (open === id) {
            setOpen(open);
        } else {
            setOpen(id);
        }
    };

    const statusActiveData = [
        {
            options: [
                { label: "Draft", value: "1" },
                { label: "Activated", value: "2" },
            ],
        },
    ];

    useEffect(() => {
        const obj = JSON.parse(sessionStorage.getItem("authUser"))
        if (obj && obj.data) {
            if (obj.data.userType === "admin") {
                setUserTypes([
                    {
                        options: [
                            { label: "Client", value: "client" },
                            { label: "Admin", value: "admin" },
                            { label: "User", value: "user" },
                            { label: "Company", value: "company" },
                        ],
                    },
                ])
            } else if (obj.data.userType === "company") {
                setUserTypes([
                    {
                        options: [
                            { label: "User", value: "user" },
                        ],
                    },
                ])
            } else if (obj.data.userType === "client") {
                setUserTypes([
                    {
                        options: [
                            { label: "User", value: "user" },
                            { label: "Company", value: "company" },
                        ],
                    },
                ])
            }
        }
    }, [])

    const toggleTag = useCallback(() => {
        if (modalTag) {
            let datas = clients
            datas.forEach((cl) => {
                cl.cl_company.forEach((com) => {
                    com.com_branch.forEach((br) => {
                        br.br_device.forEach((dev) => {
                            dev.isCheck = false
                        })
                    })
                })
            })
            console.log(datas,'datas');
            setClientListView(datas)
            setModalTag(false);
            setByBranch(false)
            setClientListView([])
            setRoleList([
                { name: "Dashboard", isCheck: false },
                { name: "Monitoring", isCheck: false },
                { name: "Device", isCheck: false },
                { name: "Report", isCheck: false },
                { name: "Activity Log", isCheck: false },
                { name: "Master Data", isCheck: false },
                { name: "Master Data - Device", isCheck: false },
                { name: "Master Data - Sensor", isCheck: false },
                { name: "Master Data - Parameter", isCheck: false },
                { name: "Master Data - Device Type", isCheck: false },
                { name: "Master Data - Sensor Type", isCheck: false },
                { name: "Dashboard Template", isCheck: false },
                { name: "User", isCheck: false }
            ])
        } else {
            setModalTag(true);
        }
    }, [modalTag, clients]);

    const checkAllRole = () => {
        let checkall = document.getElementById("SwitchCheck");
        let ele = document.querySelectorAll(".checked-all");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    const checkSubRole = (child, parent) => {
        let checkall = document.getElementById(parent);
        let ele = document.querySelectorAll("." + child);

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    const onClickDelete = (user) => {
        setUser(user);
        setDeleteModal(true);
    };

    const onClickApprove = (user) => {
        setUser(user);
        setApproveModal(true);
    };

    const handleDeleteUser = () => {
        if (user) {
            dispatch(onDeleteUser(user));
            setDeleteModal(false);
        }
    };

    const handleApproveUser = () => {
        if (user) {
            dispatch(onUpdateUser({ id: user.id, statusId: 2, isApproved: true }));
            setApproveModal(false);
        }
    };

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        //if (users && !users.length && !isSearch) {
        if (!isSearch) {
            dispatch(resetUserState());
            dispatch(onGetUser());
            dispatch(resetClientState())
            dispatch(getClient({ status: 2 }))
        }
        setIsSearch(true);
        const emp = JSON.parse(sessionStorage.getItem("authUser"));
        if (emp && emp.data) {
            if (emp.userType !== "admin") {
                setClientId(emp.data.clientId)
                setClientName(emp.data.client_name)
                setCompanyId(emp.data.companyId)
                setCompanyName(emp.data.company_name)
                setUserType(emp.data.userType)
                dispatch(resetBranchState())
                dispatch(getBranch({ companyId: emp.data.companyId }))
            }
        }
    }, [dispatch, users, isSearch]);

    useEffect(() => {
        setUserList(users);
    }, [users, clients]);

    const handleValidDate = (date) => {
        return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY");
    };

    const toggleTab = (tab, type) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            let filteredUser = users;
            if (type !== "all") {
                filteredUser = users.filter((user) => user.user_status.name === type);
            }
            setUserList(filteredUser);
        }
    };

    const toggle = useCallback(() => {
        if (modal) {
            setCompanyList([]);
            setClientList([]);
            dispatch(resetClientState());
            setModal(false);
            setUser(null);
        } else {
            dispatch(getClient({ status: 2 }));
            setModal(true);
        }
        if (!isExecutive) {
            // validation.setva
        }
    }, [modal, isExecutive, dispatch]);

    const toggleMedia = useCallback(() => {
        if (modalMedia) {
            setModalMedia(false);
        } else {
            setModalMedia(true);
        }
    }, [modalMedia]);

    const toggleUsernamePass = useCallback(() => {
        if (modalUsernamePass) {
            setModalUsernamePass(false);
            setUser(null);
        } else {
            setModalUsernamePass(true);
        }
    }, [modalUsernamePass]);

    const handleUserClick = useCallback(
        (arg, type) => {
            const user = arg;

            setUser({
                id: user.id,
                image: user.image,
                name: user.name,
                username: user.username,
                password: user.pass,
                email: user.email,
                phone: user.phone,
                userType:
                    user.userType === null
                        ? ""
                        : {
                            label: user.userType[0].toUpperCase() + user.userType.slice(1),
                            value: user.userType,
                        },
                status:
                    user.user_status === null
                        ? ""
                        : {
                            label: user.user_status.name,
                            value: user.user_status.id,
                        },
                client: {
                    label: user.user_client ? user.user_client.name : "",
                    value: user.user_client ? user.user_client.id : "",
                },
                company: {
                    label: user.user_company ? user.user_company.name : "",
                    value: user.user_company ? user.user_company.id : "",
                },
                branch: {
                    label: user.user_branch ? user.user_branch.name : "",
                    value: user.user_branch ? user.user_branch.id : "",
                },
                description: user.description === null ? "" : user.description,

            });

            if (user.user_client !== null) {
                handleCompany(user.user_client.id);
            }
            if (user.user_branch !== null) {
                handleBranch(user.user_branch.id);
            }

            if (type === "username") {
                setIsPassword(false);
                setIsUsername(true);
                toggleUsernamePass();
            } else if (type === "password") {
                setIsUsername(false);
                setIsPassword(true);
                toggleUsernamePass();
            } else {
                setIsUsername(false);
                setIsPassword(false);
                setIsEdit(type);
                toggle();
            }
        },
        [toggle, toggleUsernamePass]
    );

    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="dataCheckbox form-check-input" value={cellProps.row.original.id} />;
                },
                id: "#",
            },
            {
                Header: " ",
                filterable: false,
                Cell: (cellProps) => {
                    return (
                        <div className="hstack gap-2 mt-4 mt-sm-0">
                            <Link
                                to="#"
                                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    handleUserClick(data, true);
                                }}
                            >
                                <i className="ri-pencil-line fs-16"></i>
                            </Link>

                            <Link
                                to="#"
                                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    handleUserClick(data, "username");
                                }}
                            >
                                <i className="ri-shield-user-line"></i>
                            </Link>

                            <Link
                                to="#"
                                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    handleUserClick(data, "password");
                                }}
                            >
                                <i className="ri-lock-password-line"></i>
                            </Link>
                            <button
                                className="btn-label btn btn-sm btn-soft-info fs-12 me-0"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    let datas = roleList
                                    if (data.roleMenu && data.roleMenu) {
                                        let split = data.roleMenu.split(",")
                                        datas.forEach((role) => {
                                            split.forEach((sp) => {
                                                if (sp === role.name) {
                                                    role.isCheck = true
                                                }
                                            })
                                        })
                                        setRoleList(datas)
                                    }
                                    if (data.roleDevice && data.roleDevice) {
                                        let split = data.roleDevice.split(",")
                                        let datas = clients
                                        datas.forEach((cl) => {
                                            cl.cl_company.forEach((com) => {
                                                com.com_branch.forEach((br) => {
                                                    br.br_device.forEach((dev) => {
                                                        split.forEach((sp) => {
                                                            if (parseInt(sp) === dev.id) {
                                                                console.log("sama")
                                                                dev.isCheck = true
                                                            }
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                        console.log(datas, 'datas')
                                        setClientListView(datas)
                                    }
                                    setByBranch(data.roleByBranch)
                                    console.log(data)
                                    setUser(data);
                                    toggleTag(data);
                                }}
                            >
                                <i className="ri-lock-unlock-line label-icon align-middle me-0 fs-16"></i>Role{" "}
                            </button>

                            {
                                cellProps.row.original.statusId === 1 ? (
                                    <button
                                        className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                                        onClick={() => {
                                            const data = cellProps.row.original;
                                            onClickApprove(data);
                                        }}
                                    >
                                        <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i>Approve
                                    </button>
                                ) : (
                                    ""
                                )
                            }
                        </div >
                    );
                },
            },
            {
                Header: `${t("Name")}`,
                filterable: true,
                accessor: "name",
                Cell: (client) => (
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                            {client.row.original.image !== null && client.row.original.image !== "" ? (
                                <img
                                    src={api.MEDIA_URL + "/media/" + client.row.original.image}
                                    alt=""
                                    className="avatar-xs rounded-circle me-1"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = imgNotFound;
                                    }}
                                />
                            ) : (
                                <div className="flex-shrink-0 avatar-xs me-1">
                                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">{client.row.original.image !== null ? client.row.original.name.charAt(0).toUpperCase() : "-"}</div>
                                </div>
                            )}
                        </div>
                        <div className="flex-grow-1 ms-2 name">{client.row.original.image !== null ? client.row.original.name : "-"}</div>
                    </div>
                ),
            },
            {
                Header: `${t("Username")}`,
                accessor: "username",
                filterable: true,
            },
            {
                Header: `${t("Client")}`,
                accessor: "user_client.name",
                filterable: true,
            },
            {
                Header: `${t("Company")}`,
                accessor: "user_company.name",
                filterable: true,
            },
            {
                Header: `${t("User Type")}`,
                accessor: "userType",
                filterable: true,
            },
            {
                Header: "Status",
                accessor: "client_status.name",
                filterable: true,
                Cell: (cell) => {
                    switch (cell.row.original.statusId) {
                        case 1:
                            return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
                        case 2:
                            return <span className="badge text-uppercase badge-soft-info">Activated</span>;
                        case 3:
                            return <span className="badge text-uppercase badge-soft-info">Deactivated</span>;
                        default:
                            return <span className="badge text-uppercase badge-soft-warning">Draft</span>;
                    }
                },
            },
        ],
        [handleUserClick, t, toggleTag, roleList, clients]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns: columns,
            data: userList,
            defaultColumn: { Filter: null },
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                selectedRowIds: 0,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const submitRole = () => {
      console.log(checkedDevice, "dataPatch")
      let dataRole = []
      let dataDevice = []
      roleList && roleList.forEach((role) => {
          if (role.isCheck) {
              dataRole.push(role.name)
          }
      })
      clients && clients.forEach((cl) => {
        cl.cl_company.forEach((com) => {
          com.com_branch.forEach((br) => {
            br.br_device.forEach((dev) => {
              // console.log(`${dev.id}, ${dev.identifier}`, "dataPatch")
              // console.log(checkedDevice.indexOf(dev.id), "dataPatch")
              // if(checkedDevice.indexOf(dev.id) !== -1) dataDevice.push(dev.id);
              if (dev.isCheck) {
                  dataDevice.push(dev.id)
              }
            })
          })
        })
      })
      if (byBranch) {
          const dataPatch = {
              id: user.id,
              roleMenu: dataRole.join(),
              roleByBranch: byBranch,
              action: "role",
          }
          dispatch(onUpdateUser(dataPatch))
      } else {
          const dataPatch = {
              id: user.id,
              roleMenu: dataRole.join(),
              roleByBranch: byBranch,
              roleDevice: dataDevice.join(),
              action: "role",
          }
          console.log(dataPatch,'dataPatch');
          dispatch(onUpdateUser(dataPatch))
      }

      toggleTag();
    };

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
    };

    const generateFilterable = (column) => {
        return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
    };

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value));
    };

    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    const count = preGlobalFilteredRows.length;
    const [searchValue, setSearchValue] = useState("");
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    function handleFilter() {
        let startat = "";
        let endat = "";
        let datearr = filterDate.split(" to ");
        if (datearr[1]) {
            startat = datearr[0];
            endat = datearr[1];
        }

        var status = statusActive.value ? statusActive.value : "";
        const param = {
            title: searchValue,
            startat: startat,
            endat: endat,
            status: status,
        };
        dispatch(onGetUser(param));
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: (user && user.name) || "",
            username: (user && user.username) || "",
            password: (user && user.password) || "",
            userType: (user && user.userType) || "",
            email: (user && user.email) || "",
            phone: (user && user.phone) || "",
            image: (user && user.image) || "",
            client: userType !== "admin" ? { label: clientName, value: clientId } : (user && user.client),
            company: userType !== "admin" ? { label: companyName, value: companyId } : (user && user.company),
            branch: (user && user.branch),
            status: (user && user.status) || "",
            description: (user && user.description) || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Name"),
            username: Yup.string().required("Please Enter Username"),
            password: Yup.string().required("Please Enter Password"),
            email: Yup.string().required("Please Enter Email"),
            userType: Yup.object().required("Please Select User Type"),
            phone: Yup.string().required("Please Enter Phone Number"),
            client: Yup.object().required("Please Select Client"),
            company: Yup.object().required("Please Select Company"),
            status: Yup.object().required("Please Select Status"),
            branch: Yup.object().required("Please Select Branch"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                let editDataSubmit = {
                    id: user.id,
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    image: values.image,
                    userType: values.userType.value,
                    statusId: values.status.value,
                    clientId: values.client.value,
                    branchId: values.branch.value,
                    companyId: values.company.value,
                    description: values.description,
                };

                dispatch(onUpdateUser(editDataSubmit));
                validation.resetForm();
                toggle();
            } else if (isUsername) {
                let editUsernameSubmit = {
                    id: user.id,
                    username: values.username,
                };

                dispatch(onUpdateUser(editUsernameSubmit));
                validation.resetForm();
                toggleUsernamePass();
            } else if (isPassword) {
                let editPasswordSubmit = {
                    id: user.id,
                    pass: values.password,
                };

                dispatch(onUpdateUser(editPasswordSubmit));
                validation.resetForm();
                toggleUsernamePass();
            } else {
                let addDataSubmit = {
                    name: values.name,
                    username: values.username,
                    email: values.email,
                    phone: values.phone,
                    pass: values.password,
                    image: values.image,
                    userType: values.userType.value,
                    statusId: values.status.value,
                    clientId: values.client.value,
                    companyId: values.company.value,
                    branchId: values.branch.value,
                    description: values.description,
                };

                dispatch(onAddNewUser(addDataSubmit));
                validation.resetForm();
                toggle();
            }
        },
    });
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".dataCheckbox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    // Delete Multiple
    const deleteMultiple = () => {
        const ele = document.querySelectorAll(".dataCheckbox:checked");
        const checkall = document.getElementById("checkBoxAll");
        ele.forEach((element) => {
            dispatch(onDeleteUser(element.value));
        });
        checkall.checked = false;
    };

    useEffect(() => {
        if (!isEmpty(clients)) {
            var data = [];
            var opt = [];
            clients.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setClientList(data);
        }
    }, [clients]);

    useEffect(() => {
        if (!isEmpty(branches)) {
            var data = [];
            var opt = [];
            opt.push({
                label: "No Branch",
                value: null
            })
            branches.forEach((item, key) => {
                const name = item.name;
                const id = item.id;
                var obj = { label: name, value: id };
                opt.push(obj);
            });
            var option = { options: opt };
            data.push(option);
            setBranchList(data);
        }
    }, [branches]);

    const handleSelectFile = (file) => {
        validation.setFieldValue("image", file.filename);
        toggleMedia();
    };

    useEffect(() => {
        var data = [];
        var opt = [];
        companies.forEach((item, key) => {
            const name = item.name;
            const id = item.id;
            var obj = { label: name, value: id };
            opt.push(obj);
        });
        var option = { options: opt };
        data.push(option);
        setCompanyList(data);
    }, [companies]);

    function handleCompany(e) {
        validation.setFieldValue("company", "");
        const param = { clientId: e };
        dispatch(getCompany(param));
    }

    function handleBranch(e) {
        validation.setFieldValue("branch", "");
        const param = { companyId: e };
        dispatch(getBranch(param));
    }

    useEffect(() => {
        setCompanyList([]);
        return () => {
            dispatch(resetCompanyState());
        };
    }, [dispatch]);

    useEffect(() => {
        setBranchList([]);
        return () => {
            dispatch(resetBranchState());
        };
    }, [dispatch]);

    const clickByBranch = () => {
        setByBranch(!byBranch)
    }

    useEffect(() => {
        if (isUserCreated) {
            dispatch(resetUserState());
            dispatch(onGetUser());
        }
    }, [isUserCreated, dispatch])

    document.title = "Users | TELKOMSEL - IOT Monitoring";

    return (
        <div className="page-content">
            <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={userList} />
            <DeleteModal show={deleteModal} onDeleteClick={handleDeleteUser} onCloseClick={() => setDeleteModal(false)} />
            <ApproveModal data={user} show={approveModal} onApproveClick={handleApproveUser} onCloseClick={() => setApproveModal(false)} />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultiple();
                    setDeleteModalMulti(false);
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
            <Container fluid>
                <BreadCrumb title="Users" pageTitle="Users" />
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardHeader className="card-header border-0">
                                <div className="d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">
                                        User History &nbsp;
                                        {isLoading ? (
                                            <>
                                                <Spinner color="primary" type="grow" size={"sm"}>
                                                    Loading...
                                                </Spinner>
                                                &nbsp;
                                                <Spinner color="success" type="grow" size={"sm"}>
                                                    Loading...
                                                </Spinner>
                                                &nbsp;
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </h5>
                                    <div className="flex-shrink-0">
                                        <button
                                            type="button"
                                            className={"btn btn-primary add-btn"}
                                            id="create-btn"
                                            onClick={() => {
                                                setIsEdit(false);
                                                toggle();
                                            }}
                                        >
                                            <i className="ri-user-line me-1 align-bottom fs-14"></i> {t("Add")}
                                        </button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <div>
                                    <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                                                onClick={() => {
                                                    toggleTab("1", "all");
                                                }}
                                                href="#"
                                            >
                                                <i className=" ri-group-line me-1 align-bottom fs-14"></i> All User
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                                                onClick={() => {
                                                    toggleTab("2", "Draft");
                                                }}
                                                href="#"
                                            >
                                                <i className="ri-information-line me-1 align-bottom fs-14"></i> Draft
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                                                onClick={() => {
                                                    toggleTab("3", "Activated");
                                                }}
                                                href="#"
                                            >
                                                <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Activated{" "}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                                                onClick={() => {
                                                    toggleTab("4", "Deactivated");
                                                }}
                                                href="#"
                                            >
                                                <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i> Deactivated{" "}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <Row className="mb-3">
                                        <CardBody className="border border-top-0 border-end-0 border-start-0">
                                            <form>
                                                <Row>
                                                    <Col sm={1} className="col-xxl-1">
                                                        <div className="me-2 mb-3 col-12">
                                                            <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                                                    <option key={pageSize} value={pageSize}>
                                                                        {pageSize}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <div className="search-box me-2 mb-3 d-inline-block col-12">
                                                            <input
                                                                onChange={(e) => {
                                                                    setSearchValue(e.target.value);
                                                                    onChange(e.target.value);
                                                                }}
                                                                id="search-bar-0"
                                                                type="text"
                                                                className="form-control search"
                                                                placeholder={`${count} Search...`}
                                                                value={searchValue || ""}
                                                            />
                                                            <i className="bx bx-search-alt search-icon"></i>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} className="col-xxl-3">
                                                        <div className="search-box me-2 mb-3 col-12">
                                                            <Flatpickr
                                                                className="form-control"
                                                                id="datepicker-publish-input"
                                                                placeholder="Select join date"
                                                                options={{
                                                                    mode: "range",
                                                                    dateFormat: "Y/m/d",
                                                                }}
                                                                onChange={(selectedDates, dateStr) => {
                                                                    setFilterDate(dateStr);
                                                                }}
                                                            />
                                                            <i className="bx bx-calendar-alt search-icon"></i>
                                                        </div>
                                                    </Col>

                                                    <Col className="col-xxl-2">
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-light w-100"
                                                                onClick={() => {
                                                                    handleFilter();
                                                                }}
                                                            >
                                                                <i className="ri-search-line me-1 align-bottom fs-14"></i> {t("Search")}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </CardBody>
                                    </Row>
                                    <div className="table-responsive table-card mb-1">
                                        <Table hover {...getTableProps()} className="align-middle table-nowrap">
                                            <thead className="table-light text-muted">
                                                {headerGroups.map((headerGroup) => (
                                                    <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map((column) => (
                                                            <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                                                {column.render("Header")}
                                                                {generateSortingIndicator(column)}
                                                                {/* <Filter column={column} /> */}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {page.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <Fragment key={row.getRowProps().key}>
                                                            <tr>
                                                                {row.cells.map((cell) => {
                                                                    return (
                                                                        <td key={cell.id} {...cell.getCellProps()}>
                                                                            {cell.render("Cell")}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>

                                    <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                                        <Col className="col-md-auto">
                                            <div className="d-flex gap-1">
                                                <Button className="btn btn-light" onClick={previousPage} disabled={!canPreviousPage}>
                                                    {"<"}
                                                </Button>
                                            </div>
                                        </Col>
                                        go
                                        <Col className="col-md-auto d-none d-md-block">
                                            {" "}
                                            Page{" "}
                                            <strong>
                                                {pageIndex + 1} of {pageOptions.length}
                                            </strong>
                                        </Col>
                                        <Col className="col-md-auto">
                                            <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
                                        </Col>
                                        <Col className="col-md-auto">
                                            <div className="d-flex gap-1">
                                                <Button className="btn btn-light" onClick={nextPage} disabled={!canNextPage}>
                                                    {">"}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <Modal id="showModal" isOpen={modal} centered fullscreen={false} size="lg">
                                    <ModalHeader
                                        className="bg-light p-3"
                                        toggle={() => {
                                            toggle();
                                            validation.resetForm();
                                            validation.setErrors({});
                                        }}
                                    >
                                        {!!isEdit ? `${t("Edit")} Data User` : `${t("Add")} Data User`}
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="col-lg-12 mb-3">
                                                    <div className="text-center">
                                                        <div className="position-relative d-inline-block">
                                                            <div className="position-absolute bottom-0 end-0">
                                                                <Label
                                                                    onClick={() => {
                                                                        toggleMedia();
                                                                    }}
                                                                    htmlFor="unit-logo-input"
                                                                    className="mb-0"
                                                                >
                                                                    <div className="avatar-xs cursor-pointer">
                                                                        <div className="avatar-title bg-light border rounded-circle text-muted">
                                                                            <i className="ri-image-fill"></i>
                                                                        </div>
                                                                    </div>
                                                                </Label>
                                                            </div>
                                                            <div className="avatar-lg p-1">
                                                                <div className="avatar-title bg-light rounded-circle">
                                                                    <img
                                                                        src={validation.values.image === "" ? logo : `${api.MEDIA_URL}/media/${validation.values.image}`}
                                                                        alt=""
                                                                        id="unitlogo-img"
                                                                        className="avatar-md rounded-circle object-cover"
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null; // prevents looping
                                                                            currentTarget.src = imgNotFound;
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h5 className="fs-13 mt-3">Logo</h5>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="name-field" className="form-label">
                                                        Nama <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input name="name" id="name-field" className="form-control" placeholder="Enter Name" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.name || ""} invalid={validation.touched.name && validation.errors.name ? true : false} />
                                                    {validation.touched.name && validation.errors.name ? <FormFeedback type="invalid">{validation.errors.name}</FormFeedback> : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="email-field" className="form-label">
                                                        Email <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input name="email" id="email-field" className="form-control" placeholder="Enter Email" type="email" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.email || ""} invalid={validation.touched.email && validation.errors.email ? true : false} />
                                                    {validation.touched.email && validation.errors.email ? <FormFeedback type="invalid">{validation.errors.email}</FormFeedback> : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="phone-field" className="form-label">
                                                        Phone Number <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input name="phone" id="phone-field" className="form-control" placeholder="Enter Phone" type="nu" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.phone || ""} invalid={validation.touched.phone && validation.errors.phone ? true : false} />
                                                    {validation.touched.phone && validation.errors.phone ? <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback> : null}
                                                </div>
                                                {!isEdit ? (
                                                    <div className="mb-3">
                                                        <Label htmlFor="username-field" className="form-label">
                                                            Username <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input name="username" id="username-field" className="form-control" placeholder="Enter Username" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.username || ""} invalid={validation.touched.username && validation.errors.username ? true : false} />
                                                        {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null}
                                                    </div>
                                                ) : null}
                                                {!isEdit ? (
                                                    <div className="mb-3">
                                                        <Label htmlFor="password-field" className="form-label">
                                                            Password <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input name="password" id="password-field" className="form-control" placeholder="Enter Password" type="password" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.password || ""} invalid={validation.touched.password && validation.errors.password ? true : false} />
                                                        {validation.touched.password && validation.errors.password ? <FormFeedback type="invalid">{validation.errors.password}</FormFeedback> : null}
                                                    </div>
                                                ) : null}
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="password-field" className="form-label">
                                                        {" "}
                                                        Client  <span className="text-danger">*</span>
                                                    </Label>
                                                    <Select
                                                        name="client"
                                                        value={validation.values.client}
                                                        placeholder="Enter Client"
                                                        isDisabled={userType !== "admin" ? true : false}
                                                        onChange={(e) => {
                                                            validation.setFieldValue("client", e);
                                                            handleCompany(e.value);
                                                        }}
                                                        validate={{ required: { value: true } }}
                                                        options={clientList}
                                                    ></Select>
                                                    {validation.touched.client && (
                                                        <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                                            {validation.errors.client}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="company" className="form-label">
                                                        {t("Company")} <span className="text-danger">*</span>
                                                    </Label>
                                                    <Select
                                                        name="company"
                                                        isDisabled={userType !== "admin" ? true : false}
                                                        value={validation.values.company}
                                                        validate={{ required: { value: true } }}
                                                        placeholder="Select Company"
                                                        onChange={(e) => {
                                                            validation.setFieldValue("company", e);
                                                            handleBranch(e.value)
                                                        }}
                                                        options={companyList}
                                                    ></Select>
                                                    {validation.touched.company && (
                                                        <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                                            {validation.errors.company}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="active-status" className="form-label">
                                                        Branch <span className="text-danger">*</span>
                                                    </Label>
                                                    <Select
                                                        name="branch"
                                                        value={validation.values.branch}
                                                        validate={{ required: { value: true } }}
                                                        placeholder="Select Branch"
                                                        onChange={(e) => {
                                                            validation.setFieldValue("branch", e);
                                                        }}
                                                        options={branchList}
                                                    ></Select>
                                                    {validation.touched.branch && (
                                                        <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                                            {validation.errors.branch}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="active-status" className="form-label">
                                                        User Type <span className="text-danger">*</span>
                                                    </Label>
                                                    <Select
                                                        name="userType"
                                                        value={validation.values.userType}
                                                        validate={{ required: { value: true } }}
                                                        placeholder="Select User Type"
                                                        onChange={(e) => {
                                                            validation.setFieldValue("userType", e);
                                                        }}
                                                        onInputChange={(e) => {
                                                            // handleUnit(e);
                                                        }}
                                                        options={userTypes}
                                                    ></Select>
                                                    {validation.touched.userType && (
                                                        <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                                            {validation.errors.userType}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="active-status" className="form-label">
                                                        Status <span className="text-danger">*</span>
                                                    </Label>
                                                    <Select
                                                        name="status"
                                                        value={validation.values.status}
                                                        validate={{ required: { value: true } }}
                                                        placeholder="Select Status"
                                                        onChange={(e) => {
                                                            validation.setFieldValue("status", e);
                                                        }}
                                                        onInputChange={(e) => {
                                                            // handleUnit(e);
                                                        }}
                                                        options={statusActiveData}
                                                    ></Select>
                                                    {validation.touched.status && (
                                                        <p role="alert" style={{ color: '#f06548', fontSize: "11px" }}>
                                                            {validation.errors.status}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="description-field" className="form-label">
                                                        Description
                                                    </Label>
                                                    <Input name="description" id="description-field" className="form-control" placeholder="Enter Description" type="textarea" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.description || ""} invalid={validation.touched.description && validation.errors.description ? true : false} style={isEdit ? { minHeight: "182px" } : { minHeight: "268px" }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <div className="modal-footer mb3">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                onClick={() => {
                                                    toggle();
                                                    validation.resetForm();
                                                    validation.setErrors({});
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => {
                                                    validation.handleSubmit();
                                                }}
                                                type="submit"
                                                className="btn btn-success"
                                            >
                                                <span className="d-flex align-items-center">
                                                    <span className="flex-grow-1 me-2">Save</span>
                                                    {loading ? (
                                                        <Spinner size="sm" className="flex-shrink-0" role="status">
                                                            Loading...
                                                        </Spinner>
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal id="showModal" isOpen={modalUsernamePass} centered fullscreen={false} size="md">
                                    <ModalHeader
                                        className="bg-light p-3"
                                        toggle={toggleUsernamePass}
                                    >
                                        {!!isUsername ? "Edit Username" : "Edit Password"}
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col lg={12}>
                                                {isUsername ? (
                                                    <div className="mb-3">
                                                        <Label htmlFor="username-field" className="form-label">
                                                            Username <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input name="username" id="username-field" className="form-control" placeholder="Enter Username" type="text" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.username || ""} invalid={validation.touched.username && validation.errors.username ? true : false} />
                                                        {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null}
                                                    </div>
                                                ) : (
                                                    <div className="mb-3">
                                                        <Label htmlFor="password-field" className="form-label">
                                                            Password <span className="text-danger">*</span>
                                                        </Label>
                                                        <Input name="password" id="password-field" className="form-control" placeholder="Enter Password" type="password" validate={{ required: { value: true } }} onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.password || ""} invalid={validation.touched.password && validation.errors.password ? true : false} />
                                                        {validation.touched.password && validation.errors.password ? <FormFeedback type="invalid">{validation.errors.password}</FormFeedback> : null}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <div className="modal-footer mb3">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                onClick={() => {
                                                    setModalUsernamePass(false);
                                                    validation.resetForm();
                                                    validation.setErrors({});
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => {
                                                    validation.handleSubmit();
                                                }}
                                                type="submit"
                                                className="btn btn-success"
                                            >
                                                <span className="d-flex align-items-center">
                                                    <span className="flex-grow-1 me-2">Save</span>
                                                    {loading ? (
                                                        <Spinner size="sm" className="flex-shrink-0" role="status">
                                                            Loading...
                                                        </Spinner>
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </Modal>
                                <ModalInputFile onSelect={handleSelectFile} loading={loading} modal={modalMedia} toggle={toggleMedia} />
                                <Modal id="showModalTag" isOpen={modalTag} centered size="xl">
                                    <ModalHeader className="bg-light p-3" toggle={toggleTag}>
                                        Add Role
                                    </ModalHeader>
                                    <ModalBody>
                                        <Card>
                                            <CardBody className="pt-0">
                                                <div className="row">
                                                    <h4 className="text-primary mb-3">Menu</h4>
                                                    <div className="bg-primary" style={{ height: "3px" }}></div>
                                                    {roleList.map((o, i) => {
                                                        const nameMenu = o.name.replaceAll(rmFilterStr, "");
                                                        return (
                                                            <div className="col-md-3 mt-3" key={i}>
                                                                <div className="mb-2">
                                                                    <div className="row">
                                                                        <div className="col-md-12 text-semibold">
                                                                            <div className="form-check form-switch form-switch-custom form-switch-success mb-3">
                                                                                <Input
                                                                                    className={"form-check-input checked-all checkParent-" + nameMenu + i}
                                                                                    type="checkbox"
                                                                                    role="switch"
                                                                                    defaultChecked={o.isCheck}
                                                                                    onClick={() => {
                                                                                        o.isCheck = !o.isCheck
                                                                                    }}
                                                                                />
                                                                                <Label className="form-check-label text-uppercase" htmlFor={"checkParent-" + nameMenu + i}>
                                                                                    {o.name}
                                                                                </Label>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </CardBody>
                                        </Card>
                                        <div>
                                            <input className="form-check-input me-2 mb-3" type="checkbox" checked={byBranch} onClick={clickByBranch} />
                                            <label className="form-check-label mb-3">
                                                By Branch
                                            </label>
                                        </div>
                                        {!byBranch ? (
                                            <Card>
                                                <CardBody>

                                                    {console.log(clientListView, 'view')}
                                                    {console.log(clients, 'client')}
                                                    {clientListView && clientListView.length > 0 ? (
                                                        <div className="row">
                                                            {clientListView.map((client, clientIdx) => {
                                                                return (
                                                                    <Fragment key={"client-" + clientIdx}>
                                                                        {client.cl_company.map((com, comIdx) => {
                                                                            return (
                                                                                <Fragment key={"com-" + comIdx}>
                                                                                    {com.com_branch.map((br, brIdx) => {
                                                                                        return (
                                                                                            <Fragment key={"br-" + brIdx}>
                                                                                                {br && br.br_device && br.br_device.length > 0 ? (
                                                                                                    <Fragment>
                                                                                                        <h4 className="text-primary mb-3">{br.name}</h4>
                                                                                                        <div className="bg-primary" style={{ height: "3px" }}></div>
                                                                                                    </Fragment>
                                                                                                ) : null}

                                                                                                {br.br_device.map((dev, devIdx) => {
                                                                                                    if (!dev.isCheck) {
                                                                                                        dev.isCheck = false
                                                                                                    }
                                                                                                    console.log(dev,"dev");
                                                                                                    return ((dev.statusId === 2) ?
                                                                                                      <div className="col-md-4 mt-3" key={"devIdx-" + devIdx}>
                                                                                                          <div className="mb-2">
                                                                                                              <div className="row">
                                                                                                                  <div className="col-md-12 text-semibold">
                                                                                                                      <div className="form-check form-switch form-switch-custom form-switch-success mb-3">
                                                                                                                          <Input
                                                                                                                              className={"form-check-input checked-all checkParent-" + dev.name + devIdx}
                                                                                                                              type="checkbox"
                                                                                                                              role="switch"
                                                                                                                              defaultChecked={dev.isCheck}
                                                                                                                              onClick={() => {
                                                                                                                                  console.log(dev,'dev')
                                                                                                                                  dev.isCheck = !dev.isCheck;
                                                                                                                              }}
                                                                                                                          />
                                                                                                                          <Label className="form-check-label text-uppercase" htmlFor={"checkParent-" + dev.name + devIdx}>
                                                                                                                              {dev.name}
                                                                                                                          </Label>
                                                                                                                      </div>
                                                                                                                  </div>

                                                                                                              </div>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                    : <></>  
                                                                                                    )
                                                                                                })}

                                                                                                {/* {br && br.br_unit && br.br_unit.length > 0 ? (
                                                                                                <Fragment>
                                                                                                    <h4 className="text-primary mb-3">{br.name}</h4>
                                                                                                    <div className="bg-primary" style={{ height: "3px" }}></div>
                                                                                                </Fragment>
                                                                                            ) : null}
                                                                                            {br.br_unit.map((un, unIdx) => {
                                                                                                return (
                                                                                                    <Fragment key={"un-" + unIdx}>
                                                                                                        {un.un_device.map((dev, devIdx) => {
                                                                                                            if (!dev.isCheck) {
                                                                                                                dev.isCheck = false
                                                                                                            }
                                                                                                            //if (dev.device_status.id === 2)
                                                                                                                return (
                                                                                                                    <div className="col-md-4 mt-3" key={"devIdx-" + devIdx}>
                                                                                                                        <div className="mb-2">
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-md-12 text-semibold">
                                                                                                                                    <div className="form-check form-switch form-switch-custom form-switch-success mb-3">
                                                                                                                                        <Input
                                                                                                                                            className={"form-check-input checked-all checkParent-" + dev.name + devIdx}
                                                                                                                                            type="checkbox"
                                                                                                                                            role="switch"
                                                                                                                                            defaultChecked={dev.isCheck}
                                                                                                                                            onClick={() => {
                                                                                                                                                dev.isCheck = !dev.isCheck;
                                                                                                                                            }}
                                                                                                                                        />
                                                                                                                                        <Label className="form-check-label text-uppercase" htmlFor={"checkParent-" + dev.name + devIdx}>
                                                                                                                                            {dev.name}
                                                                                                                                        </Label>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                        })}
                                                                                                    </Fragment>
                                                                                                )
                                                                                            })} */}
                                                                                            </Fragment>
                                                                                        )
                                                                                    })}
                                                                                </Fragment>
                                                                            )
                                                                        })}
                                                                    </Fragment>
                                                                )
                                                            })}
                                                        </div>
                                                    ) : (
                                                        <div className="row">
                                                          {clients && clients.map((com, comIdx) => {
                                                            return (
                                                              <Fragment key={"com-" + comIdx}>
                                                                {com.cl_company.map((cl, clidx) => {
                                                                  return (
                                                                    <Fragment key={"cl-" + clidx}>
                                                                      {cl.com_branch.map((br, brIdx) => {
                                                                        return (
                                                                          <Fragment key={"br-" + brIdx}>
                                                                            {br && br.br_device && br.br_device.length > 0 ? (
                                                                              <Fragment>
                                                                                  <h4 className="text-primary mb-3">{br.name}</h4>
                                                                                  <div className="bg-primary" style={{ height: "3px" }}></div>
                                                                              </Fragment>
                                                                            ) : null}

                                                                            {br.br_device.map((dev, devIdx) => {
                                                                              if (!dev.isCheck) {
                                                                                  dev.isCheck = false
                                                                              }
                                                                              console.log(dev,"dev");
                                                                              return ((dev.statusId === 2) ?
                                                                                <div className="col-md-4 mt-3" key={"devIdx-" + devIdx}>
                                                                                  <div className="mb-2">
                                                                                    <div className="row">
                                                                                      <div className="col-md-12 text-semibold">
                                                                                        <div className="form-check form-switch form-switch-custom form-switch-success mb-3">
                                                                                          <Input
                                                                                            className={"form-check-input checked-all checkParent-" + dev.name + devIdx}
                                                                                            type="checkbox"
                                                                                            role="switch"
                                                                                            defaultChecked={false}
                                                                                            onClick={() => {
                                                                                              dev.isCheck = !dev.isCheck;
                                                                                            }}
                                                                                          />
                                                                                          <Label className="form-check-label text-uppercase" htmlFor={"checkParent-" + dev.name + devIdx}>
                                                                                            {dev.name}
                                                                                          </Label>
                                                                                        </div>
                                                                                      </div>

                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              : <></>  
                                                                              )
                                                                            })}
                                                                          </Fragment>
                                                                        )
                                                                      })}
                                                                    </Fragment>
                                                                  )
                                                                })}
                                                              </Fragment>
                                                            )
                                                        })}
                                                        </div>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        ) : null}
                                    </ModalBody>
                                    <div className="modal-footer">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button
                                              type="button"
                                              className="btn btn-light"
                                              onClick={() => {
                                                let datas = clients
                                                datas.forEach((cl) => {
                                                  cl.cl_company.forEach((com) => {
                                                    com.com_branch.forEach((br) => {
                                                      br.br_device.forEach((dev) => {
                                                        dev.isCheck = false
                                                      })
                                                    })
                                                  })
                                                })
                                                
                                                setClientListView(datas)
                                                setModalTag(false)
                                                toggleTag();
                                              }}
                                            >
                                              Close
                                            </button>
                                            <button type="submit" className="btn btn-success" onClick={() => submitRole() }>
                                                <span className="d-flex align-items-center">
                                                    <span className="flex-grow-1 me-2">Save Role</span>
                                                    {roleListloading && (
                                                        <Spinner size="sm" className="flex-shrink-0" role="status">
                                                            Loading...
                                                        </Spinner>
                                                    )}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </Modal>
                                <ToastContainer closeButton={false} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

User.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(User));
