export const DASHBOARDTEMPLATEDETAIL_LOADING =
  "DASHBOARDTEMPLATEDETAIL_LOADING";
export const DASHBOARDTEMPLATEDETAIL_RESET = "DASHBOARDTEMPLATEDETAIL_RESET";
export const GET_DASHBOARDTEMPLATEDETAIL = "GET_DASHBOARDTEMPLATEDETAIL";
export const GET_DASHBOARDTEMPLATEDETAIL_SUCCESS =
  "GET_DASHBOARDTEMPLATEDETAIL_SUCCESS";
export const GET_DASHBOARDTEMPLATEDETAIL_FAIL =
  "GET_DASHBOARDTEMPLATEDETAIL_FAIL";
export const DELETE_DASHBOARDTEMPLATEDETAIL = "DELETE_DASHBOARDTEMPLATEDETAIL";
export const DELETE_DASHBOARDTEMPLATEDETAIL_SUCCESS =
  "DELETE_DASHBOARDTEMPLATEDETAIL_SUCCESS";
export const DELETE_DASHBOARDTEMPLATEDETAIL_FAIL =
  "DELETE_DASHBOARDTEMPLATEDETAIL_FAIL";
export const UPDATE_DASHBOARDTEMPLATEDETAIL = "UPDATE_DASHBOARDTEMPLATEDETAIL";
export const UPDATE_DASHBOARDTEMPLATEDETAIL_SUCCESS =
  "UPDATE_DASHBOARDTEMPLATEDETAIL_SUCCESS";
export const UPDATE_DASHBOARDTEMPLATEDETAIL_FAIL =
  "UPDATE_DASHBOARDTEMPLATEDETAIL_FAIL";
export const ADD_NEW_DASHBOARDTEMPLATEDETAIL =
  "ADD_NEW_DASHBOARDTEMPLATEDETAIL";
export const ADD_DASHBOARDTEMPLATEDETAIL_SUCCESS =
  "ADD_DASHBOARDTEMPLATEDETAIL_SUCCESS";
export const ADD_DASHBOARDTEMPLATEDETAIL_FAIL =
  "ADD_DASHBOARDTEMPLATEDETAIL_FAIL";
