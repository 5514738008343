import {
  ACTIVITYLOG_LOADING,
  GET_ACTIVITYLOG,
  GET_ACTIVITYLOG_SUCCESS,
  GET_ACTIVITYLOG_FAIL,
  ADD_ACTIVITYLOG_SUCCESS,
  ADD_ACTIVITYLOG_FAIL,
  DELETE_ACTIVITYLOG_SUCCESS,
  DELETE_ACTIVITYLOG_FAIL,
  UPDATE_ACTIVITYLOG_SUCCESS,
  UPDATE_ACTIVITYLOG_FAIL,
  ACTIVITYLOG_RESET,
} from "./actionType";

const INIT_STATE = {
  activityLogs: [],
  error: {},
};

const ActivityLog = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACTIVITYLOG_SUCCESS:
      switch (action.payload.actionType) {
        case GET_ACTIVITYLOG:
          return {
            ...state,
            activityLogs: action.payload.data,
            isActivityLogCreated: false,
            isActivityLogSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_ACTIVITYLOG_FAIL:
      switch (action.payload.actionType) {
        case GET_ACTIVITYLOG_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isActivityLogCreated: false,
            isActivityLogSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_ACTIVITYLOG: {
      return {
        ...state,
        isActivityLogCreated: false,
        loading: true,
      };
    }

    case ACTIVITYLOG_LOADING: {
      return {
        ...state,
        isActivityLogCreated: false,
        loading: true,
      };
    }

    case ADD_ACTIVITYLOG_SUCCESS:
      return {
        ...state,
        isActivityLogCreated: true,
        loading: false,
        activityLogs: [...state.activityLogs, action.payload.data],
      };

    case ADD_ACTIVITYLOG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_ACTIVITYLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        activityLogs: state.activityLogs.filter(
          (activityLog) => activityLog.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ACTIVITYLOG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_ACTIVITYLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        activityLogs: state.activityLogs.map((activityLog) =>
          activityLog.id.toString() === action.payload.data.id.toString()
            ? { ...activityLog, ...action.payload.data }
            : activityLog
        ),
      };

    case UPDATE_ACTIVITYLOG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ACTIVITYLOG_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ActivityLog;
