import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  ADD_NEW_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_FAIL,
  NOTIFICATION_LOADING,
  NOTIFICATION_RESET,
  NOTIFICATION_RESET_CREATE
} from "./actionType";

export const resetNotificationState = (actionType) => ({
  type: NOTIFICATION_RESET,
  payload: { actionType },
});


export const resetNotificationCreateState = (actionType) => ({
  type: NOTIFICATION_RESET_CREATE,
  payload: { actionType }
});

export const getNotificationSuccess = (actionType, data) => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: { actionType, data },
});

export const getNotificationFail = (actionType, error) => ({
  type: GET_NOTIFICATION_FAIL,
  payload: { actionType, error },
});

export const getNotification = data => ({
  type: GET_NOTIFICATION,
  payload: data,
});

export const getNotificationLoading = () => ({
  type: NOTIFICATION_LOADING
});

export const deleteNotification = data => ({
  type: DELETE_NOTIFICATION,
  payload: data,
});

export const deleteNotificationSuccess = data => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: data,
});

export const deleteNotificationFail = error => ({
  type: DELETE_NOTIFICATION_FAIL,
  payload: error,
});

export const updateNotification = data => ({
  type: UPDATE_NOTIFICATION,
  payload: data,
});

export const updateNotificationFail = error => ({
  type: UPDATE_NOTIFICATION_FAIL,
  payload: error,
});

export const updateNotificationSuccess = data => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: data,
});

export const addNewNotification = data => ({
  type: ADD_NEW_NOTIFICATION,
  payload: data,
});

export const addNotificationSuccess = data => ({
  type: ADD_NOTIFICATION_SUCCESS,
  payload: data,
});

export const addNotificationFail = error => ({
  type: ADD_NOTIFICATION_FAIL,
  payload: error,
});