export const BRANDTYPE_LOADING = "BRANDTYPE_LOADING";
export const BRANDTYPE_RESET = "BRANDTYPE_RESET";
export const GET_BRANDTYPE = "GET_BRANDTYPE";
export const GET_BRANDTYPE_SUCCESS = "GET_BRANDTYPE_SUCCESS";
export const GET_BRANDTYPE_FAIL = "GET_BRANDTYPE_FAIL";
export const DELETE_BRANDTYPE = "DELETE_BRANDTYPE";
export const DELETE_BRANDTYPE_SUCCESS = "DELETE_BRANDTYPE_SUCCESS";
export const DELETE_BRANDTYPE_FAIL = "DELETE_BRANDTYPE_FAIL";
export const UPDATE_BRANDTYPE = "UPDATE_BRANDTYPE";
export const UPDATE_BRANDTYPE_SUCCESS = "UPDATE_BRANDTYPE_SUCCESS";
export const UPDATE_BRANDTYPE_FAIL = "UPDATE_BRANDTYPE_FAIL";
export const ADD_NEW_BRANDTYPE = "ADD_NEW_BRANDTYPE";
export const ADD_BRANDTYPE_SUCCESS = "ADD_BRANDTYPE_SUCCESS";
export const ADD_BRANDTYPE_FAIL = "ADD_BRANDTYPE_FAIL";
