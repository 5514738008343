import {
  DASHBOARDTEMPLATE_LOADING,
  GET_DASHBOARDTEMPLATE,
  GET_DASHBOARDTEMPLATE_SUCCESS,
  GET_DASHBOARDTEMPLATE_FAIL,
  ADD_DASHBOARDTEMPLATE_SUCCESS,
  ADD_DASHBOARDTEMPLATE_FAIL,
  DELETE_DASHBOARDTEMPLATE_SUCCESS,
  DELETE_DASHBOARDTEMPLATE_FAIL,
  UPDATE_DASHBOARDTEMPLATE_SUCCESS,
  UPDATE_DASHBOARDTEMPLATE_FAIL,
  DASHBOARDTEMPLATE_RESET,
} from "./actionType";

const INIT_STATE = {
  dashboardTemplates: [],
  error: {},
};

const DashboardTemplate = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARDTEMPLATE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_DASHBOARDTEMPLATE:
          return {
            ...state,
            dashboardTemplates: action.payload.data,
            isDashboardTemplateCreated: false,
            isDashboardTemplateSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_DASHBOARDTEMPLATE_FAIL:
      switch (action.payload.actionType) {
        case GET_DASHBOARDTEMPLATE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isDashboardTemplateCreated: false,
            isDashboardTemplateSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_DASHBOARDTEMPLATE: {
      return {
        ...state,
        isDashboardTemplateCreated: false,
        loading: true,
      };
    }

    case DASHBOARDTEMPLATE_LOADING: {
      return {
        ...state,
        isDashboardTemplateCreated: false,
        loading: true,
      };
    }

    case ADD_DASHBOARDTEMPLATE_SUCCESS:
      return {
        ...state,
        isDashboardTemplateCreated: true,
        loading: false,
        dashboardTemplates: [...state.dashboardTemplates, action.payload.data],
      };

    case ADD_DASHBOARDTEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_DASHBOARDTEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardTemplates: state.dashboardTemplates.filter(
          (dashboardTemplate) =>
            dashboardTemplate.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DASHBOARDTEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_DASHBOARDTEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardTemplates: state.dashboardTemplates.map((dashboardTemplate) =>
          dashboardTemplate.id.toString() === action.payload.data.id.toString()
            ? { ...dashboardTemplate, ...action.payload.data }
            : dashboardTemplate
        ),
      };

    case UPDATE_DASHBOARDTEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DASHBOARDTEMPLATE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default DashboardTemplate;
