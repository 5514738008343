import {
  GET_DEVICETYPE,
  GET_DEVICETYPE_SUCCESS,
  GET_DEVICETYPE_FAIL,
  DELETE_DEVICETYPE,
  DELETE_DEVICETYPE_SUCCESS,
  DELETE_DEVICETYPE_FAIL,
  UPDATE_DEVICETYPE,
  UPDATE_DEVICETYPE_SUCCESS,
  UPDATE_DEVICETYPE_FAIL,
  ADD_NEW_DEVICETYPE,
  ADD_DEVICETYPE_SUCCESS,
  ADD_DEVICETYPE_FAIL,
  DEVICETYPE_LOADING,
  DEVICETYPE_RESET,
} from "./actionType";

export const resetDeviceTypeState = (actionType) => ({
  type: DEVICETYPE_RESET,
  payload: { actionType },
});

export const getDeviceTypeSuccess = (actionType, data) => ({
  type: GET_DEVICETYPE_SUCCESS,
  payload: { actionType, data },
});

export const getDeviceTypeFail = (actionType, error) => ({
  type: GET_DEVICETYPE_FAIL,
  payload: { actionType, error },
});

export const getDeviceType = (data) => ({
  type: GET_DEVICETYPE,
  payload: data,
});

export const getDeviceTypeLoading = () => ({
  type: DEVICETYPE_LOADING,
});

export const deleteDeviceType = (data) => ({
  type: DELETE_DEVICETYPE,
  payload: data,
});

export const deleteDeviceTypeSuccess = (data) => ({
  type: DELETE_DEVICETYPE_SUCCESS,
  payload: data,
});

export const deleteDeviceTypeFail = (error) => ({
  type: DELETE_DEVICETYPE_FAIL,
  payload: error,
});

export const updateDeviceType = (data) => ({
  type: UPDATE_DEVICETYPE,
  payload: data,
});

export const updateDeviceTypeFail = (error) => ({
  type: UPDATE_DEVICETYPE_FAIL,
  payload: error,
});

export const updateDeviceTypeSuccess = (data) => ({
  type: UPDATE_DEVICETYPE_SUCCESS,
  payload: data,
});

export const addNewDeviceType = (data) => ({
  type: ADD_NEW_DEVICETYPE,
  payload: data,
});

export const addDeviceTypeSuccess = (data) => ({
  type: ADD_DEVICETYPE_SUCCESS,
  payload: data,
});

export const addDeviceTypeFail = (error) => ({
  type: ADD_DEVICETYPE_FAIL,
  payload: error,
});
