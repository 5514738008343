import React, { useState, useEffect, useCallback, Fragment } from "react";
import {
  Label,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Container,
  List,
  ListGroup,
  ListGroupItem,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment-timezone";
import { isEmpty, clone } from "lodash";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import socketIO from "../../../helpers/socket";
import { getClient, resetClientState } from "../../../store/client/action";
import { getHistory, resetHistoryState } from "../../../store/history/action";
import { changeLayout, changePreLoader } from "../../../store/layouts/action";
import Wave from "react-wavify";
import Flatpickr from "react-flatpickr";
import tankImage from "../../../assets/images/tank_CPO.png";
import flowImage from "../../../assets/images/flowmeter.jpg";
import { Tab } from "bootstrap";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";

const io = socketIO.getInstance();
io.setHost("https://socket.atapteknologi.id");
const socket = io.getSocket();
console.log(socket);

const SsmDashboard = ({ clientId, isload, isloaddefault }) => {
  const dispatch = useDispatch();

  const { clients, clientLoading, clientError, histories } = useSelector(
    (state) => ({
      clients: state.Client.clients,
      clientLoading: state.Client.loading,
      clientError: state.Client.error,
      histories: state.History.histories,
    })
  );

  const radius = 200;
  const state = 50;
  const startColor = "#FF8C00 "; // cornflowerblue
  const endColor = "#FF8C00 "; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = interpolate(state / 100);

  const gradientStops = [
    {
      key: "0%",
      stopColor: "#FF8C00",
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: "#FF8C00 ",
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: "#FF8C00 ",
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const nowStart = new Date();
  const now = new Date();
  const [isLoaded, setIsLoaded] = useState(isload);
  const [isLoadedDefault, setIsLoadedDefault] = useState(isloaddefault);
  const [units, setUnits] = useState([]);
  const [uids, setUids] = useState([]);
  const [flowUids, setFlowUids] = useState([]);
  const [currentHistories, setCurrentHistories] = useState([]);
  const [bcurrentHistories, setBCurrentHistories] = useState(false);
  const [transactionHistories, setTransactionHistories] = useState([]);
  const [btransactionHistories, setBTtransactionHistories] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState(
    moment(nowStart).format("YYYY-MM-DD") + " 00:00"
  );
  const [filterEndDate, setFilterEndDate] = useState(
    moment(now).format("YYYY-MM-DD hh:mm")
  );
  const [csvData, setCsvData] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);

  useEffect(() => {
    dispatch(changeLayout("horizontal"));
    dispatch(changePreLoader("enable"));
    if (!isLoaded) {
      dispatch(resetClientState());
      dispatch(getClient());
      setIsLoaded(true);
    }
  }, [dispatch, isLoaded]);

  useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    console.log(obj);
  }, []);

  useEffect(() => {
    if (!isEmpty(clients)) {
      console.log(clients);
      console.log(clients[0].client_unit);
      const arr = [];
      const arrFlow = [];
      const arrUn = [];
      clients.forEach((client) => {
        client.cl_company.forEach((company) => {
          if (company.id === 8) {
            company.com_branch.forEach((branch) => {
              branch.br_unit.forEach((unit) => {
                arrUn.push(unit);
                unit.un_device.forEach((itm) => {
                  arr.push(itm.uid);
                  itm.device_sensor.forEach((s) => {
                    console.log(s);
                    if (s.sensor_ty.toLowerCase() === "flow meter sensor") {
                      let ada = false;
                      arrFlow.forEach((af) => {
                        if (af.uid === itm.uid) {
                          ada = true;
                        }
                      });

                      if (!ada)
                        arrFlow.push({
                          uid: itm.uid,
                          name: itm.name,
                          unit_id: unit.id,
                        });
                      return;
                    }
                  });
                });
              });
            });
          }
        });
      });

      setUnits(arrUn);

      if (arr.length > 0) {
        setFlowUids(arrFlow);
        setUids(arr);
        dispatch(resetHistoryState());
        dispatch(getHistory({ type: "real_time", uid: JSON.stringify(arr) }));
      }
    }
  }, [clients, dispatch]);

  useEffect(() => {
    if (!isEmpty(histories) && !bcurrentHistories) {
      setCurrentHistories(histories);
      setBCurrentHistories(true);

      setBTtransactionHistories(false);
      dispatch(resetHistoryState());
      dispatch(
        getHistory({
          type: "transaction",
          uid: JSON.stringify(uids),
          start: filterStartDate,
          end: filterEndDate,
        })
      );
    }
  }, [
    histories,
    bcurrentHistories,
    filterEndDate,
    filterStartDate,
    uids,
    dispatch,
  ]);

  useEffect(() => {
    if (!isEmpty(currentHistories) && !isLoadedDefault) {
      const arr = [];
      console.log("u", units);
      units.forEach((uitm, i) => {
        units[i].un_device.forEach((dev, n) => {
          dev.device_sensor.forEach((par, x) => {
            if (par.sensor_parameter.length > 0) {
              par.sensor_parameter.forEach((sen, y) => {
                currentHistories.forEach((itm) => {
                  if (dev.uid === itm.uid && itm.message[sen.value]) {
                    par.sensor_parameter[y].nilai = itm.message[sen.value];
                    par.sensor_parameter[y].device_time = itm.device_time;
                    units[i].un_device[n].device_time = itm.device_time;
                    if (
                      sen.value.indexOf("ho") !== -1 &&
                      itm.message[sen.value]
                    ) {
                      // console.log(`${sen.value}, ${itm.message[sen.value]}`)
                      units[i].un_device[n].ho = itm.message[sen.value];
                    }
                    if (
                      sen.showInDashboard &&
                      (sen.value.indexOf("flr") !== -1 ||
                        sen.value.indexOf("fuel_level") !== -1) &&
                      itm.message[sen.value]
                    ) {
                      // console.log(`${sen.value}, ${itm.message[sen.value]}`)
                      units[i].un_device[n].level = itm.message[sen.value];
                    }
                  }
                });
              });
            }
          });
        });
      });

      setIsLoadedDefault(true);
    }
  }, [currentHistories, units, isLoadedDefault]);

  useEffect(() => {
    if (!isEmpty(uids)) {
      uids.forEach((u) => {
        // console.log(u)
        socket.on(u, (data) => {
          // console.log(data)
          const js = JSON.parse(data);
          // console.log(js)
          units.forEach((uitm, i) => {
            units[i].un_device.forEach((dev, n) => {
              dev.device_sensor.forEach((par, x) => {
                if (par.sensor_parameter.length > 0) {
                  par.sensor_parameter.forEach((sen, y) => {
                    if (dev.uid === js.uid && js.message[sen.value]) {
                      par.sensor_parameter[y].nilai = js.message[sen.value];
                      par.sensor_parameter[y].device_time = js.device_time;
                      units[i].un_device[n].device_time = js.device_time;
                      if (
                        sen.value.indexOf("ho") !== -1 &&
                        js.message[sen.value]
                      ) {
                        // console.log(`${sen.value}, ${js.message[sen.value]}`)
                        units[i].un_device[n].ho = js.message[sen.value];
                      }
                      if (
                        sen.showInDashboard &&
                        (sen.value.indexOf("flr") !== -1 ||
                          sen.value.indexOf("fuel_level") !== -1) &&
                        js.message[sen.value]
                      ) {
                        // console.log(`${sen.value}, ${js.message[sen.value]}`)
                        units[i].un_device[n].level = js.message[sen.value];
                      }
                    }
                  });
                }
              });
            });
          });
        });
      });
      // setUnits(units);
      return () => {
        uids.forEach((itm) => {
          socket.off(itm);
        });
      };
    }
  }, [uids, units]);

  useEffect(() => {
    if (!btransactionHistories && !isEmpty(histories)) {
      const data = [];
      histories.forEach((itm) => {
        let ada = false;
        transactionHistories.forEach((h, i) => {
          if (h._id.uid === itm._id.uid && h._id.type === itm._id.type) {
            transactionHistories[i] = itm;
            ada = true;
            return;
          }
        });

        if (!ada) {
          transactionHistories.push(itm);
        }
      });
      console.log(transactionHistories);
      setTransactionHistories(transactionHistories);
      setBTtransactionHistories(true);
    }
  }, [btransactionHistories, transactionHistories, histories]);

  const filterDate = (str, type) => {
    if (str.trim()) {
      switch (type) {
        case "start":
          setFilterStartDate(str);
          break;
        case "end":
          setFilterEndDate(str);
          break;
        default:
          break;
      }
    }
  };

  const handleFilter = (data) => {
    if (data && data.id) {
      const uids = [];
      data.un_device.forEach((itm) => {
        itm.device_sensor.forEach((s) => {
          // console.log(s)
          if (s.sensor_type.name.toLowerCase() === "flow meter") {
            uids.push(itm.uid);
            return;
          }
        });
      });

      if (!isEmpty(uids)) {
        setBTtransactionHistories(false);
        dispatch(resetHistoryState());
        dispatch(
          getHistory({
            type: "transaction",
            uid: JSON.stringify(uids),
            start: filterStartDate,
            end: filterEndDate,
          })
        );
      }
    }
  };

  return (
    <React.Fragment>
      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={csvData}
      />
      <div className='page-content'>
        <Container fluid>
          {units &&
            units.length > 0 &&
            units.map((u, i) => {
              u.un_device.sort((a, b) => (a.label > b.label ? 1 : -1));
              const devices = u.un_device;
              return (
                <Fragment key={`unit_${i}`}>
                  <Card>
                    <CardBody className='bg-light'>
                      <Row>
                        <h2 className='mb-1'>{u.name}</h2>
                        {u &&
                          u.un_device &&
                          u.un_device.map((d, ii) => {
                            return (
                              <Fragment key={ii}>
                                {d.device_sensor.map((itm, nn) => {
                                  console.log("itm", itm);
                                  const adaLevel =
                                    itm.sensor_ty
                                      .toLowerCase()
                                      .indexOf("fuel") !== -1
                                      ? true
                                      : false;
                                  const adaFlow =
                                    itm.sensor_ty
                                      .toLowerCase()
                                      .indexOf("flow") !== -1
                                      ? true
                                      : false;
                                  itm.sensor_parameter.sort((a, b) =>
                                    a.label > b.label ? 1 : -1
                                  );
                                  return adaLevel ? (
                                    <Col xl={3} key={`device_level_${nn}`}>
                                      <Card>
                                        <CardHeader>{d.name}</CardHeader>
                                        <CardBody>
                                          <Row>
                                            <Col xl={3}>
                                              <Wave
                                                fill='#f79902'
                                                paused={false}
                                                style={{
                                                  display: "flex",
                                                  position: "relative",
                                                  top: 0,
                                                  left: 0,
                                                  marginLeft: "20%",
                                                  marginTop: "15px",
                                                  width: "80%",
                                                  height: "100px",
                                                }}
                                                options={{
                                                  height:
                                                    d.level && d.ho
                                                      ? 100 -
                                                        parseInt(
                                                          (d.level / d.ho) * 100
                                                        )
                                                      : 100,
                                                  amplitude: 1,
                                                  speed: 0.2,
                                                  points: 3,
                                                }}></Wave>
                                              <img
                                                src={tankImage}
                                                alt=''
                                                className='position-absolute top-0 start-0'
                                                style={{
                                                  width: "100%",
                                                  height: "120px",
                                                }}
                                              />
                                              <span className='fs-14 position-absolute top-0 start-10 pt-5'>
                                                {d.level && d.ho
                                                  ? parseInt(
                                                      (d.level / d.ho) * 100
                                                    ) + "%"
                                                  : "0%"}
                                              </span>
                                            </Col>
                                            <Col xl={9}>
                                              <ListGroup className='list-group-flush border-dashed px-2 divi'>
                                                <ListGroupItem className='ps0 py-1'>
                                                  <div className='d-flex align-items-center'>
                                                    <div className='flex-grow-1'>
                                                      <label className='form-check-label mb-0 ps-0'>
                                                        {moment(d.device_time)
                                                          .tz("Asia/Jakarta")
                                                          .format(
                                                            "MMM D, YYYY HH:mm"
                                                          )}
                                                      </label>
                                                    </div>
                                                    <div className='flex-shrink-0 ms-2'>
                                                      <p className='text-muted fs-12 mb-0'>
                                                        {moment(
                                                          new Date()
                                                        ).diff(
                                                          moment(d.device_time),
                                                          "hours"
                                                        ) > 0
                                                          ? moment(
                                                              new Date()
                                                            ).diff(
                                                              moment(
                                                                d.device_time
                                                              ),
                                                              "hours"
                                                            ) + "h "
                                                          : " "}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </ListGroupItem>
                                                {itm.sensor_parameter.length >
                                                  0 &&
                                                  itm.sensor_parameter.map(
                                                    (h, n) => {
                                                      return h.showInDashboard ? (
                                                        <ListGroupItem
                                                          className='ps0 py-1'
                                                          key={`logger_level_${n}`}>
                                                          <div className='d-flex align-items-center'>
                                                            <div className='flex-grow-1'>
                                                              <label className='form-check-label mb-0 ps-0 fs-11'>
                                                                {h.label}
                                                              </label>
                                                            </div>
                                                            <div className='flex-shrink-0 ms-2'>
                                                              <p className='text-muted fs-12 mb-0'>
                                                                {h.nilai > -1
                                                                  ? parseFloat(
                                                                      h.nilai
                                                                    ).toFixed(2)
                                                                  : 0}
                                                                &nbsp;{h.uom}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </ListGroupItem>
                                                      ) : null;
                                                    }
                                                  )}
                                              </ListGroup>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  ) : adaFlow ? (
                                    <Col xl={3} key={`device_flow_${nn}`}>
                                      <Card>
                                        <CardHeader>{d.name}</CardHeader>
                                        <CardBody>
                                          <Row>
                                            <Col xl={3}>
                                              <img
                                                src={flowImage}
                                                alt=''
                                                className='position-absolute top-0 start-0'
                                                style={{ width: "100%" }}
                                              />
                                            </Col>
                                            <Col xl={9}>
                                              <ListGroup className='list-group-flush border-dashed px-2 divi'>
                                                <ListGroupItem className='ps0'>
                                                  <div className='d-flex align-items-center'>
                                                    <div className='flex-grow-1'>
                                                      <label className='form-check-label mb-0 ps-0 py-1'>
                                                        {moment(d.device_time)
                                                          .tz("Asia/Jakarta")
                                                          .format(
                                                            "MMM D, YYYY HH:mm"
                                                          )}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </ListGroupItem>
                                                {itm.sensor_parameter.length >
                                                  0 &&
                                                  itm.sensor_parameter.map(
                                                    (h, n) => {
                                                      return h.showInDashboard ? (
                                                        <ListGroupItem
                                                          className='ps0 py-1'
                                                          key={`logger_flow_${n}`}>
                                                          <div className='d-flex align-items-center'>
                                                            <div className='flex-grow-1'>
                                                              <label className='form-check-label mb-0 ps-0 fs-11'>
                                                                {h.label}
                                                              </label>
                                                            </div>
                                                            <div className='flex-shrink-0 ms-2'>
                                                              <p className='text-muted fs-12 mb-0'>
                                                                {h.nilai > -1
                                                                  ? parseFloat(
                                                                      h.nilai
                                                                    ).toFixed(2)
                                                                  : 0}
                                                                &nbsp;{h.uom}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </ListGroupItem>
                                                      ) : null;
                                                    }
                                                  )}
                                              </ListGroup>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  ) : null;
                                })}
                              </Fragment>
                            );
                          })}
                      </Row>
                      <Row className='mb-3'>
                        <Col xl={12}>
                          <hr></hr>
                          <h3>FLOW METER TRANSACTION</h3>
                          <div className='mb-3 pt-2 position-relative d-flex align-items-center'>
                            <div className='search-box w-25 me-2 mb-1 mt-2 col-12'>
                              <Flatpickr
                                className='form-control'
                                id='datepicker-publish-input-start'
                                placeholder='Select start date'
                                defaultValue={filterStartDate}
                                options={{
                                  altInput: true,
                                  enableTime: true,
                                  time_24hr: true,
                                  dateFormat: "Y-m-d H:i",
                                  timeFormat: "H:i",
                                }}
                                onChange={(obj, str) => {
                                  console.log(str);
                                  filterDate(str, "start");
                                }}
                              />
                              <i className='bx bx-calendar-alt search-icon'></i>
                            </div>
                            <div className='search-box w-25 me-2 mt-2 mb-1 col-12'>
                              <Flatpickr
                                className='form-control'
                                id='datepicker-publish-input-end'
                                placeholder='Select end date'
                                defaultValue={filterEndDate}
                                options={{
                                  altInput: true,
                                  enableTime: true,
                                  time_24hr: true,
                                  dateFormat: "Y-m-d H:i",
                                  timeFormat: "H:i",
                                }}
                                onChange={(obj, str) => {
                                  filterDate(str, "end");
                                }}
                              />
                              <i className='bx bx-calendar-alt search-icon'></i>
                            </div>
                            <button
                              type='button'
                              className='btn btn-info w-10'
                              onClick={(e) => {
                                e.preventDefault();
                                handleFilter(u);
                              }}>
                              <i className='ri-search-line me-1 align-midle fs-14'></i>{" "}
                              Search
                            </button>
                          </div>
                          <Row>
                            {/* {console.log(flowUids)} */}
                            {flowUids &&
                              flowUids.map((f, x) => {
                                let dataCsv = [];
                                transactionHistories &&
                                  transactionHistories.forEach((dt) => {
                                    if (
                                      dt._id.uid === f.uid &&
                                      f.unit_id === u.id
                                    ) {
                                      dt.data.forEach((it, xx) => {
                                        if (xx > 0) {
                                          dataCsv.push(it);
                                        }
                                      });
                                    }
                                  });
                                // console.log(dataCsv)
                                return (
                                  <Fragment key={`flow_${x}`}>
                                    {transactionHistories.map((dt, n) => {
                                      return (
                                        dt._id.uid === f.uid &&
                                        f.unit_id === u.id && (
                                          <Col xl={4} key={`dt_${n}`}>
                                            <Card>
                                              <CardBody>
                                                <ListGroup className='list-group-flush border-dashed px-2 py-2 divi'>
                                                  <ListGroupItem className='ps0 py-1'>
                                                    <div className='d-flex align-items-center'>
                                                      <div className='flex-grow-1'>
                                                        <label className='form-check-label mb-0 ps-2'>
                                                          &nbsp;
                                                        </label>
                                                      </div>
                                                      <div className='flex-shrink-0 ms-2'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-sm btn-success'
                                                          onClick={() => {
                                                            setCsvData(dataCsv);
                                                            setIsExportCSV(
                                                              true
                                                            );
                                                          }}>
                                                          <i className='ri-file-download-line me-1 align-bottom fs-14'></i>
                                                          Export
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </ListGroupItem>
                                                  <ListGroupItem className='ps0 py-1'>
                                                    <div className='d-flex align-items-center'>
                                                      <div className='flex-grow-1'>
                                                        <label className='form-check-label mb-0 ps-2 fs-11'>
                                                          {f.name}
                                                        </label>
                                                      </div>
                                                      <div className='flex-shrink-0 ms-2'>
                                                        <p className='text-muted fs-12 mb-0'>
                                                          {dt._id.type
                                                            .split("_")
                                                            .join(" ")
                                                            .toUpperCase()}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </ListGroupItem>
                                                  <ListGroupItem className='ps0 py-1'>
                                                    <div className='d-flex align-items-center'>
                                                      <div className='flex-grow-1'>
                                                        <label className='form-check-label mb-0 ps-2 fs-11'>
                                                          Total Flow
                                                        </label>
                                                      </div>
                                                      <div className='flex-shrink-0 ms-2'>
                                                        <p className='text-muted fs-12 mb-0'>
                                                          {parseFloat(
                                                            dt.totalFlow
                                                          ).toFixed(1)}
                                                          &nbsp;Ton
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </ListGroupItem>
                                                  <ListGroupItem className='ps0 py-1'>
                                                    <div className='d-flex align-items-center'>
                                                      <div className='flex-grow-1'>
                                                        <label className='form-check-label mb-0 ps-2 fs-11'>
                                                          Total Record
                                                        </label>
                                                      </div>
                                                      <div className='flex-shrink-0 ms-2'>
                                                        <p className='text-muted fs-12 mb-0'>
                                                          {dt.count}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </ListGroupItem>
                                                </ListGroup>
                                                <div className='table-responsive'>
                                                  <Table className='align-middle table-nowrap mb-0'>
                                                    <thead className='table-light'>
                                                      <tr>
                                                        <th scope='col'>
                                                          START TIME
                                                        </th>
                                                        <th scope='col'>
                                                          END TIME
                                                        </th>
                                                        <th scope='col'>
                                                          START FLOW
                                                        </th>
                                                        <th scope='col'>
                                                          END FLOW
                                                        </th>
                                                        <th scope='col'>
                                                          TOTAL FLOW
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {dt.data.map((d, dd) => {
                                                        return (
                                                          <tr
                                                            key={`data_${d.uid}_${dd}`}>
                                                            <td>
                                                              {moment(
                                                                d.start_time
                                                              )
                                                                .tz(
                                                                  "Asia/Jakarta"
                                                                )
                                                                .format(
                                                                  "DD-MM-YY hh:mm"
                                                                )}
                                                            </td>
                                                            <td>
                                                              {moment(
                                                                d.end_time
                                                              )
                                                                .tz(
                                                                  "Asia/Jakarta"
                                                                )
                                                                .format(
                                                                  "DD-MM-YY hh:mm"
                                                                )}
                                                            </td>
                                                            <td>
                                                              {d.start_total}
                                                            </td>
                                                            <td>
                                                              {d.end_total}
                                                            </td>
                                                            <td>
                                                              {parseFloat(
                                                                d.end_total -
                                                                  d.start_total
                                                              ).toFixed(1)}
                                                            </td>
                                                          </tr>
                                                        );
                                                      })}
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </CardBody>
                                            </Card>
                                          </Col>
                                        )
                                      );
                                    })}
                                  </Fragment>
                                );
                              })}
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Fragment>
              );
            })}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SsmDashboard;
