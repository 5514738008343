import { APIClient } from "./api_helper";
import { api as url } from "../config";

const api = new APIClient();
export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Login Method
export const postLogin = (data) => api.post(`${url.SSO_URL}/auth`, data);
export const postLoginSocial = (data) =>
  api.post(`${url.SSO_URL}/social-auth`, data);
export const logout = (data) => api.post(`${url.SSO_URL}/logout`, data);
export const postRegister = (data) => api.post(`${url.SSO_URL}/register`, data);
export const refreshToken = () => api.get("/refresh");

export const getBranchApi = (data) => api.get(`${url.SMS_URL}/branches`, data);
export const addNewBranchApi = (data) =>
  api.post(`${url.SMS_URL}/branches`, data);
export const updateBranchApi = (data) =>
  api.patch(`${url.SMS_URL}/branches`, data);
export const deleteBranchApi = (data) =>
  api.delete(`${url.SMS_URL}/branches`, data);

export const getOneUserApi = (id) => api.getOne(`/users`, id);
export const getUserApi = (data) => api.get(`/users`, data);
export const addNewUserApi = (data) => api.post(`/users`, data);
export const approveUserApi = (data) => api.put(`/users`, data);
export const updateUserApi = (data) => api.patch(`/users`, data);
export const deleteUserApi = (data) => api.delete(`/users`, data);

export const getItemCheckApi = (data) => api.get("/item/check", data);
export const addNewItemCheckApi = (data) => api.post("/item/check", data);
export const approveItemCheckApi = (data) => api.put("/item/check", data);
export const updateItemCheckApi = (data) => api.patch("/item/check", data);
export const deleteItemCheckApi = (data) => api.delete("/item/check", data);

export const getPositionApi = (data, path) => api.get("/position", data, path);
export const addNewPositionApi = (data) => api.post("/position", data);
export const updatePositionApi = (data) => api.patch("/position", data);
export const deletePositionApi = (data) => api.delete("/position", data);

export const getRegionalApi = (data) => api.get("/regional", data);
export const approveRegionalApi = (data) => api.put("/regional", data);
export const addNewRegionalApi = (data) => api.post("/regional", data);
export const updateRegionalApi = (data) => api.patch("/regional", data);
export const deleteRegionalApi = (data) => api.delete("/regional", data);

export const getUserLocationApi = (data) => api.get("/user-locations", data);
export const approveUserLocationApi = (data) =>
  api.put("/user-locations", data);
export const addNewUserLocationApi = (data) =>
  api.post("/user-locations", data);
export const updateUserLocationApi = (data) =>
  api.patch("/user-locations", data);
export const deleteUserLocationApi = (data) =>
  api.delete("/user-locations", data);

export const getDashboardApi = (data) => api.get("/dashboards", data);
export const approveDashboardApi = (data) => api.put("/dashboards", data);
export const addNewDashboardApi = (data) => api.post("/dashboards", data);
export const updateDashboardApi = (data) => api.patch("/dashboards", data);
export const deleteDashboardApi = (data) => api.delete("/dashboards", data);

export const getShipApi = (data) => api.get("/ship", data);
export const approveShipApi = (data) => api.put("/ship", data);
export const addNewShipApi = (data) => api.post("/ship", data);
export const updateShipApi = (data) => api.patch("/ship", data);
export const deleteShipApi = (data) => api.delete("/ship", data);

export const getAssetApi = (data) => api.get("/asset", data);
export const approveAssetApi = (data) => api.put("/asset", data);
export const addNewAssetApi = (data) => api.post("/asset", data);
export const updateAssetApi = (data) => api.patch("/asset", data);
export const deleteAssetApi = (data) => api.delete("/asset", data);

export const getMainAssetApi = (data) => api.get("/asset/part/main", data);
export const approveMainAssetApi = (data) => api.put("/asset/part/main", data);
export const addNewMainAssetApi = (data) => api.post("/asset/part/main", data);
export const updateMainAssetApi = (data) => api.patch("/asset/part/main", data);
export const deleteMainAssetApi = (data) =>
  api.delete("/asset/part/main", data);

export const getSubAssetApi = (data) => api.get("/asset/part/sub", data);
export const approveSubAssetApi = (data) => api.put("/asset/part/sub", data);
export const addNewSubAssetApi = (data) => api.post("/asset/part/sub", data);
export const updateSubAssetApi = (data) => api.patch("/asset/part/sub", data);
export const deleteSubAssetApi = (data) => api.delete("/asset/part/sub", data);

export const getLocationApi = (data) => api.get("/location", data);
export const approveLocationApi = (data) => api.put("/location", data);
export const addNewLocationApi = (data) => api.post("/location", data);
export const updateLocationApi = (data) => api.patch("/location", data);
export const deleteLocationApi = (data) => api.delete("/location", data);

export const getRepairingApi = (data) => api.get("/repairing", data);
export const approveRepairingApi = (data) => api.put("/repairing", data);
export const addNewRepairingApi = (data) => api.post("/repairing", data);
export const updateRepairingApi = (data) => api.patch("/repairing", data);
export const deleteRepairingApi = (data) => api.delete("/repairing", data);

export const getModuleCheckApi = (data) => api.get("/module/check", data);
export const getModuleCheckIdApi = (data) => api.getId("/module/check", data);
export const addNewModuleCheckApi = (data) => api.post("/module/check", data);
export const approveModuleCheckApi = (data) => api.put("/module/check", data);
export const updateModuleCheckApi = (data) => api.patch("/module/check", data);
export const deleteModuleCheckApi = (data) => api.delete("/module/check", data);

export const getMediaApi = (data) => api.get("/media", data);
export const addNewMediaApi = (data) => api.post("/media", data);
export const approveMediaApi = (data) => api.put("/media", data);
export const updateMediaApi = (data) => api.patch("/media", data);
export const deleteMediaApi = (data) => api.delete("/media", data);

export const getScheduleApi = (data) => api.get("/schedule", data);
export const addNewScheduleApi = (data) => api.post("/schedule", data);
export const approveScheduleApi = (data) => api.put("/schedule", data);
export const updateScheduleApi = (data) => api.patch("/schedule", data);
export const deleteScheduleApi = (data) => api.delete("/schedule", data);

export const getRoleApi = (data) => api.get("/role", data);
export const addNewRoleApi = (data) => api.post("/role", data);
export const approveRoleApi = (data) => api.put("/role", data);
export const updateRoleApi = (data) => api.patch("/role", data);
export const deleteRoleApi = (data) => api.delete("/role", data);

export const getClientApi = (data) => api.get("/clients", data);
export const addNewClientApi = (data) => api.post("/clients", data);
export const approveClientApi = (data) => api.put("/clients", data);
export const updateClientApi = (data) => api.patch("/clients", data);
export const deleteClientApi = (data) => api.delete("/clients", data);

export const getCompanyApi = (data) =>
  api.get(`/companies`, data);
export const addNewCompanyApi = (data) =>
  api.post(`/companies`, data);
export const approveCompanyApi = (data) =>
  api.put(`/companies`, data);
export const updateCompanyApi = (data) =>
  api.patch(`/companies`, data);
export const deleteCompanyApi = (data) =>
  api.delete(`/companies`, data);

export const getServiceApi = (data) => api.get("/services", data);
export const addNewServiceApi = (data) => api.post("/services", data);
export const approveServiceApi = (data) => api.put("/services", data);
export const updateServiceApi = (data) => api.patch("/services", data);
export const deleteServiceApi = (data) => api.delete("/services", data);

export const getOneGeofenceApi = (id) => api.getOne("/geofences", id);
export const getGeofenceApi = (data) => api.get("/geofences", data);
export const addNewGeofenceApi = (data) => api.post("/geofences", data);
export const approveGeofenceApi = (data) => api.put("/geofences", data);
export const updateGeofenceApi = (data) => api.patch("/geofences", data);
export const deleteGeofenceApi = (data) => api.delete("/geofences", data);

export const getOneDeviceApi = (id) => api.getOne("/devices", id);
export const getDeviceApi = (data) => api.get("/devices", data);
export const addNewDeviceApi = (data) => api.post("/devices", data);
export const approveDeviceApi = (data) => api.put("/devices", data);
export const updateDeviceApi = (data) => api.patch("/devices", data);
export const deleteDeviceApi = (data) => api.delete("/devices", data);

export const getDeviceTypeApi = (data) => api.get("/device-types", data);
export const addNewDeviceTypeApi = (data) => api.post("/device-types", data);
export const approveDeviceTypeApi = (data) => api.put("/device-types", data);
export const updateDeviceTypeApi = (data) => api.patch("/device-types", data);
export const deleteDeviceTypeApi = (data) => api.delete("/device-types", data);

export const getUnitApi = (data) => api.get("/units", data);
export const addNewUnitApi = (data) => api.post("/units", data);
export const approveUnitApi = (data) => api.put("/units", data);
export const updateUnitApi = (data) => api.patch("/units", data);
export const deleteUnitApi = (data) => api.delete("/units", data);

export const getProvinceApi = (data) => api.get("/provinces", data);
export const addNewProvinceApi = (data) => api.post("/provinces", data);
export const updateProvinceApi = (data) => api.patch("/provinces", data);
export const deleteProvinceApi = (data) => api.delete("/provinces", data);

export const getCityApi = (data) => api.get("/cities", data);
export const addNewCityApi = (data) => api.post("/cities", data);
export const updateCityApi = (data) => api.patch("/cities", data);
export const deleteCityApi = (data) => api.delete("/cities", data);

export const getDistrictApi = (data) => api.get("/districts", data);
export const addNewDistrictApi = (data) => api.post("/districts", data);
export const updateDistrictApi = (data) => api.patch("/districts", data);
export const deleteDistrictApi = (data) => api.delete("/districts", data);

export const getVillageApi = (data) => api.get("/villages", data);
export const addNewVillageApi = (data) => api.post("/villages", data);
export const updateVillageApi = (data) => api.patch("/villages", data);
export const deleteVillageApi = (data) => api.delete("/villages", data);

export const getAddressApi = (data) => api.get("/addresses", data);
export const addNewAddressApi = (data) => api.post("/addresses", data);
export const updateAddressApi = (data) => api.patch("/addresses", data);
export const deleteAddressApi = (data) => api.delete("/addresses", data);

export const getFolderApi = (data) => api.get("/storage-folders", data);
export const addNewFolderApi = (data) => api.post("/storage-folders", data);
export const updateFolderApi = (data) => api.patch("/storage-folders", data);
export const deleteFolderApi = (data) => api.delete("/storage-folders", data);

export const getFileApi = (data) => api.get("/storage-files", data);
export const addNewFileApi = (data) => api.postImg("/storage-files", data);
export const updateFileApi = (data) => api.patchImg("/storage-files", data);
export const deleteFileApi = (data) => api.delete("/storage-files", data);

export const getParameterApi = (data) => api.get("/parameters", data);
export const addNewParameterApi = (data) => api.post("/parameters", data);
export const addNewParameterTableApi = (data) =>
  api.post("/parameter-tables", data);
export const approveParameterApi = (data) => api.put("/parameters", data);
export const updateParameterApi = (data) => api.patch("/parameters", data);
export const updateParameterTableApi = (data) =>
  api.patch("/parameter-tables", data);
export const deleteParameterApi = (data) => api.delete("/parameters", data);

export const getBillingApi = (data) => api.get("/billings", data);
export const addNewBillingApi = (data) => api.post("/billings", data);
export const approveBillingApi = (data) => api.put("/billings", data);
export const updateBillingApi = (data) => api.patch("/billings", data);
export const deleteBillingApi = (data) => api.delete("/billings", data);

export const getPaymentApi = (data) => api.get("/payments", data);
export const addNewPaymentApi = (data) => api.post("/payments", data);
export const approvePaymentApi = (data) => api.put("/payments", data);
export const updatePaymentApi = (data) => api.patch("/payments", data);
export const deletePaymentApi = (data) => api.delete("/payments", data);

export const getSensorTypeApi = (data) => api.get("/sensor-types", data);
export const addNewSensorTypeApi = (data) => api.post("/sensor-types", data);
export const approveSensorTypeApi = (data) => api.put("/sensor-types", data);
export const updateSensorTypeApi = (data) => api.patch("/sensor-types", data);
export const deleteSensorTypeApi = (data) => api.delete("/sensor-types", data);

export const getSensorApi = (data) => api.get("/sensors", data);
export const addNewSensorApi = (data) => api.post("/sensors", data);
export const approveSensorApi = (data) => api.put("/sensors", data);
export const updateSensorApi = (data) => api.patch("/sensors", data);
export const deleteSensorApi = (data) => api.delete("/sensors", data);

export const getHistoryApi = (data) => api.get("/history", data);
export const getHistoryPublicApi = (data) => api.get("/history/public", data);
export const addNewHistoryApi = (data) => api.post("/history", data);
export const approveHistoryApi = (data) => api.put("/history", data);
export const updateHistoryApi = (data) => api.patch("/history", data);
export const deleteHistoryApi = (data) => api.delete("/history", data);

export const getTaskApi = (data) => api.get(`${url.ERP_URL}/tasks`, data);
export const getPublicTaskApi = (data) =>
  api.get(`${url.ERP_URL}/task-publics`, data);
export const approveTaskApi = (data) => api.put(`${url.ERP_URL}/tasks`, data);
export const addNewTaskApi = (data) => api.post(`${url.ERP_URL}/tasks`, data);
export const updateTaskApi = (data) => api.patch(`${url.ERP_URL}/tasks`, data);
export const deleteTaskApi = (data) => api.delete(`${url.ERP_URL}/tasks`, data);

export const getTaskDetailApi = (data) =>
  api.get(`${url.ERP_URL}/task-details`, data);
export const approveTaskDetailApi = (data) =>
  api.put(`${url.ERP_URL}/task-details`, data);
export const addNewTaskDetailApi = (data) =>
  api.post(`${url.ERP_URL}/task-details`, data);
export const updateTaskDetailApi = (data) =>
  api.patch(`${url.ERP_URL}/task-details`, data);
export const deleteTaskDetailApi = (data) =>
  api.delete(`${url.ERP_URL}/task-details`, data);

export const getTaskListDetailApi = (data) =>
  api.get("/task-list-details", data);
export const approveTaskListDetailApi = (data) =>
  api.put("/task-list-details", data);
export const addNewTaskListDetailApi = (data) =>
  api.post("/task-list-details", data);
export const updateTaskListDetailApi = (data) =>
  api.patch("/task-list-details", data);
export const deleteTaskListDetailApi = (data) =>
  api.delete("/task-list-details", data);

export const getTaskListApi = (data) => api.get("/task-lists", data);
export const approveTaskListApi = (data) => api.put("/task-lists", data);
export const addNewTaskListApi = (data) => api.post("/task-lists", data);
export const updateTaskListApi = (data) => api.patch("/task-lists", data);
export const deleteTaskListApi = (data) => api.delete("/task-lists", data);

export const getTaskCategoryApi = (data) => api.get("/task-categories", data);
export const addNewTaskCategoryApi = (data) =>
  api.post("/task-categories", data);
export const approveTaskCategoryApi = (data) =>
  api.put("/task-categories", data);
export const updateTaskCategoryApi = (data) =>
  api.patch("/task-categories", data);
export const deleteTaskCategoryApi = (data) =>
  api.delete("/task-categories", data);

export const getBrandApi = (data) => api.get("/brands", data);
export const addNewBrandApi = (data) => api.postImg("/brands", data);
export const updateBrandApi = (data) => api.patchImg("/brands", data);
export const deleteBrandApi = (data) => api.delete("/brands", data);

export const getProductApi = (data) => api.get("/products", data);
export const approveProductApi = (data) => api.put("/products", data);
export const addNewProductApi = (data) => api.postImg("/products", data);
export const updateProductApi = (data) => api.patchImg("/products", data);
export const deleteProductApi = (data) => api.delete("/products", data);

export const getPurchaseApi = (data) => api.get("/purchases", data);
export const approvePurchaseApi = (data) => api.put("/purchases", data);
export const addNewPurchaseApi = (data) => api.postImg("/purchases", data);
export const updatePurchaseApi = (data) => api.patchImg("/purchases", data);
export const deletePurchaseApi = (data) => api.delete("/purchases", data);

export const getPaymentTermApi = (data) => api.get("/payment-terms", data);
export const approvePaymentTermApi = (data) => api.put("/payment-terms", data);
export const addNewPaymentTermApi = (data) => api.post("/payment-terms", data);
export const updatePaymentTermApi = (data) => api.patch("/payment-terms", data);
export const deletePaymentTermApi = (data) =>
  api.delete("/payment-terms", data);

export const getPaymentMethodApi = (data) => api.get("/payment-methods", data);
export const approvePaymentMethodApi = (data) =>
  api.put("/payment-methods", data);
export const addNewPaymentMethodApi = (data) =>
  api.post("/payment-methods", data);
export const updatePaymentMethodApi = (data) =>
  api.patch("/payment-methods", data);
export const deletePaymentMethodApi = (data) =>
  api.delete("/payment-methods", data);

export const getVendorApi = (data) => api.get("/vendors", data);
export const addNewVendorApi = (data) => api.post("/vendors", data);
export const approveVendorApi = (data) => api.put("/vendors", data);
export const updateVendorApi = (data) => api.patch("/vendors", data);
export const deleteVendorApi = (data) => api.delete("/vendors", data);

export const getBankApi = (data) => api.get("/banks", data);
export const addNewBankApi = (data) => api.post("/banks", data);
export const updateBankApi = (data) => api.patch("/banks", data);
export const deleteBankApi = (data) => api.delete("/banks", data);

export const getTransactionTaxApi = (data) =>
  api.get("/transaction-taxes", data);
export const approveTransactionTaxApi = (data) =>
  api.put("/transaction-taxes", data);
export const addNewTransactionTaxApi = (data) =>
  api.post("/transaction-taxes", data);
export const updateTransactionTaxApi = (data) =>
  api.patch("/transaction-taxes", data);
export const deleteTransactionTaxApi = (data) =>
  api.delete("/transaction-taxes", data);

export const getProductCategoryApi = (data) =>
  api.get("/product-categories", data);
export const addNewProductCategoryApi = (data) =>
  api.post("/product-categories", data);
export const updateProductCategoryApi = (data) =>
  api.patch("/product-categories", data);
export const deleteProductCategoryApi = (data) =>
  api.delete("/product-categories", data);

export const getReportTemplateApi = (data) =>
  api.get("/report-templates", data);
export const addNewReportTemplateApi = (data) =>
  api.post("/report-templates", data);
export const updateReportTemplateApi = (data) =>
  api.patch("/report-templates", data);
export const deleteReportTemplateApi = (data) =>
  api.delete("/report-templates", data);
export const approveReportTemplateApi = (data) =>
  api.put("/report-templates", data);

export const getReportTemplateDetailApi = (data) =>
  api.get("/report-template-details", data);
export const addNewReportTemplateDetailApi = (data) =>
  api.post("/report-template-details", data);
export const updateReportTemplateDetailApi = (data) =>
  api.patch("/report-template-details", data);
export const deleteReportTemplateDetailApi = (data) =>
  api.delete("/report-template-details", data);
export const approveReportTemplateDetailApi = (data) =>
  api.put("/report-template-details", data);

export const getSikTemplateApi = (data) => api.get("/sik-templates", data);
export const addNewSikTemplateApi = (data) => api.post("/sik-templates", data);
export const updateSikTemplateApi = (data) => api.patch("/sik-templates", data);
export const deleteSikTemplateApi = (data) =>
  api.delete("/sik-templates", data);
export const approveSikTemplateApi = (data) => api.put("/sik-templates", data);

export const getBastTemplateApi = (data) => api.get("/bast-templates", data);
export const addNewBastTemplateApi = (data) =>
  api.post("/bast-templates", data);
export const updateBastTemplateApi = (data) =>
  api.patch("/bast-templates", data);
export const deleteBastTemplateApi = (data) =>
  api.delete("/bast-templates", data);
export const approveBastTemplateApi = (data) =>
  api.put("/bast-templates", data);

export const getProductOptionApi = (data) => api.get("/product-options", data);
export const approveProductOptionApi = (data) =>
  api.put("/product-options", data);
export const addNewProductOptionApi = (data) =>
  api.post("/product-options", data);
export const updateProductOptionApi = (data) =>
  api.patch("/product-options", data);
export const deleteProductOptionApi = (data) =>
  api.delete("/product-options", data);

export const getProductOptionDetailApi = (data) =>
  api.get("/product-option-details", data);
export const approveProductOptionDetailApi = (data) =>
  api.put("/product-option-details", data);
export const addNewProductOptionDetailApi = (data) =>
  api.post("/product-option-details", data);
export const updateProductOptionDetailApi = (data) =>
  api.patch("/product-option-details", data);
export const deleteProductOptionDetailApi = (data) =>
  api.delete("/product-option-details", data);

export const getProductVarianApi = (data) => api.get("/product-varians", data);
export const addNewProductVarianApi = (data) =>
  api.post("/product-varians", data);
export const updateProductVarianApi = (data) =>
  api.patch("/product-varians", data);
export const deleteProductVarianApi = (data) =>
  api.delete("/product-varians", data);

export const getProductTagApi = (data) => api.get("/product-tags", data);
export const approveProductTagApi = (data) => api.put("/product-tags", data);
export const addNewProductTagApi = (data) => api.post("/product-tags", data);
export const updateProductTagApi = (data) => api.patch("/product-tags", data);
export const deleteProductTagApi = (data) => api.delete("/product-tags", data);

export const getProductCollectionApi = (data) =>
  api.get("/product-collections", data);
export const approveProductCollectionApi = (data) =>
  api.put("/product-collections", data);
export const addNewProductCollectionApi = (data) =>
  api.post("/product-collections", data);
export const updateProductCollectionApi = (data) =>
  api.patch("/product-collections", data);
export const deleteProductCollectionApi = (data) =>
  api.delete("/product-collections", data);

export const getProductInventoryApi = (data) =>
  api.get("/product-inventories", data);
export const approveProductInventoryApi = (data) =>
  api.put("/product-inventories", data);
export const addNewProductInventoryApi = (data) =>
  api.post("/product-inventories", data);
export const updateProductInventoryApi = (data) =>
  api.patch("/product-inventories", data);
export const deleteProductInventoryApi = (data) =>
  api.delete("/product-inventories", data);

export const getProductInventoryDetailApi = (data) =>
  api.get("/product-inventory-details", data);
export const approveProductInventoryDetailApi = (data) =>
  api.put("/product-inventory-details", data);
export const addNewProductInventoryDetailApi = (data) =>
  api.post("/product-inventory-details", data);
export const updateProductInventoryDetailApi = (data) =>
  api.patch("/product-inventory-details", data);
export const deleteProductInventoryDetailApi = (data) =>
  api.delete("/product-inventory-details", data);

export const getTransferApi = (data) => api.get("/product-transfers", data);
export const approveTransferApi = (data) => api.put("/product-transfers", data);
export const addNewTransferApi = (data) => api.post("/product-transfers", data);
export const updateTransferApi = (data) =>
  api.patch("/product-transfers", data);
export const deleteTransferApi = (data) =>
  api.delete("/product-transfers", data);

export const getTransferDetailApi = (data) =>
  api.get("/product-transfer-details", data);
export const approveTransferDetailApi = (data) =>
  api.put("/product-transfer-details", data);
export const addNewTransferDetailApi = (data) =>
  api.post("/product-transfer-details", data);
export const updateTransferDetailApi = (data) =>
  api.patch("/product-transfer-details", data);
export const deleteTransferDetailApi = (data) =>
  api.delete("/product-transfer-details", data);

export const getTransferHistoryApi = (data) =>
  api.get("/product-transfer-histories", data);
export const approveTransferHistoryApi = (data) =>
  api.put("/product-transfer-histories", data);
export const addNewTransferHistoryApi = (data) =>
  api.post("/product-transfer-histories", data);
export const updateTransferHistoryApi = (data) =>
  api.patch("/product-transfer-histories", data);
export const deleteTransferHistoryApi = (data) =>
  api.delete("/product-transfer-histories", data);

export const getUomApi = (data) => api.get("/uoms", data);
export const approveUomApi = (data) => api.put("/uoms", data);
export const addNewUomApi = (data) => api.post("/uoms", data);
export const updateUomApi = (data) => api.patch("/uoms", data);
export const deleteUomApi = (data) => api.delete("/uoms", data);

export const getAccAccountApi = (data) => api.get("/acc-accounts", data);
export const approveAccAccountApi = (data) => api.put("/acc-accounts", data);
export const addNewAccAccountApi = (data) => api.post("/acc-accounts", data);
export const updateAccAccountApi = (data) => api.patch("/acc-accounts", data);
export const deleteAccAccountApi = (data) => api.delete("/acc-accounts", data);

export const getAccCategoryApi = (data) => api.get("/acc-categories", data);
export const approveAccCategoryApi = (data) => api.put("/acc-categories", data);
export const addNewAccCategoryApi = (data) => api.post("/acc-categories", data);
export const updateAccCategoryApi = (data) =>
  api.patch("/acc-categories", data);
export const deleteAccCategoryApi = (data) =>
  api.delete("/acc-categories", data);

export const getBrandTypeApi = (data) => api.get("/brand-types", data);
export const addNewBrandTypeApi = (data) => api.post("/brand-types", data);
export const approveBrandTypeApi = (data) => api.put("/brand-types", data);
export const updateBrandTypeApi = (data) => api.patch("/brand-types", data);
export const deleteBrandTypeApi = (data) => api.delete("/brand-types", data);

export const getOneStorageFileApi = (id) =>
  api.getOne(`${url.MEDIA_URL}/storage-files`, id);
export const getStorageFileApi = (data) =>
  api.get(`${url.MEDIA_URL}/storage-files`, data);
export const addNewStorageFileApi = (data) =>
  api.postImg(`${url.MEDIA_URL}/storage-files`, data);
export const approveStorageFileApi = (data) =>
  api.put(`${url.MEDIA_URL}/storage-files`, data);
export const updateStorageFileApi = (data) =>
  api.patchImg(`${url.MEDIA_URL}/storage-files`, data);
export const deleteStorageFileApi = (data) =>
  api.delete(`${url.MEDIA_URL}/storage-files`, data);

export const getOneStorageFolderApi = (id) =>
  api.getOne(`${url.MEDIA_URL}/storage-folders`, id);
export const getStorageFolderApi = (data) =>
  api.get(`${url.MEDIA_URL}/storage-folders`, data);
export const addNewStorageFolderApi = (data) =>
  api.post(`${url.MEDIA_URL}/storage-folders`, data);
export const approveStorageFolderApi = (data) =>
  api.put(`${url.MEDIA_URL}/storage-folders`, data);
export const updateStorageFolderApi = (data) =>
  api.patch(`${url.MEDIA_URL}/storage-folders`, data);
export const deleteStorageFolderApi = (data) =>
  api.delete(`${url.MEDIA_URL}/storage-folders`, data);

export const getOneStorageMoveApi = (id) =>
  api.getOne(`${url.MEDIA_URL}/storage-moves`, id);
export const getStorageMoveApi = (data) =>
  api.get(`${url.MEDIA_URL}/storage-moves`, data);
export const addNewStorageMoveApi = (data) =>
  api.post(`${url.MEDIA_URL}/storage-moves`, data);
export const approveStorageMoveApi = (data) =>
  api.put(`${url.MEDIA_URL}/storage-moves`, data);
export const updateStorageMoveApi = (data) =>
  api.patch(`${url.MEDIA_URL}/storage-moves`, data);
export const deleteStorageMoveApi = (data) =>
  api.delete(`${url.MEDIA_URL}/storage-moves`, data);

export const getOneStorageRenameApi = (id) =>
  api.getOne(`${url.MEDIA_URL}/storage-renames`, id);
export const getStorageRenameApi = (data) =>
  api.get(`${url.MEDIA_URL}/storage-renames`, data);
export const addNewStorageRenameApi = (data) =>
  api.post(`${url.MEDIA_URL}/storage-renames`, data);
export const approveStorageRenameApi = (data) =>
  api.put(`${url.MEDIA_URL}/storage-renames`, data);
export const updateStorageRenameApi = (data) =>
  api.patch(`${url.MEDIA_URL}/storage-renames`, data);
export const deleteStorageRenameApi = (data) =>
  api.delete(`${url.MEDIA_URL}/storage-renames`, data);

export const getDashboardTemplateApi = (data) =>
  api.get("/dashboard-templates", data);
export const addNewDashboardTemplateApi = (data) =>
  api.post("/dashboard-templates", data);
export const updateDashboardTemplateApi = (data) =>
  api.patch("/dashboard-templates", data);
export const deleteDashboardTemplateApi = (data) =>
  api.delete("/dashboard-templates", data);
export const approveDashboardTemplateApi = (data) =>
  api.put("/dashboard-templates", data);

export const getDashboardTemplateDetailApi = (data) =>
  api.get("/dashboard-details", data);
export const addNewDashboardTemplateDetailApi = (data) =>
  api.post("/dashboard-details", data);
export const updateDashboardTemplateDetailApi = (data) =>
  api.patch("/dashboard-details", data);
export const deleteDashboardTemplateDetailApi = (data) =>
  api.delete("/dashboard-details", data);
export const approveDashboardTemplateDetailApi = (data) =>
  api.put("/dashboard-details", data);

export const getNotificationApi = (data) =>
  api.get(`${url.ERP_URL}/notifications`, data);
export const approveNotificationApi = (data) =>
  api.put(`${url.ERP_URL}/notifications`, data);
export const addNewNotificationApi = (data) =>
  api.post(`${url.ERP_URL}/notifications`, data);
export const updateNotificationApi = (data) =>
  api.put(`${url.ERP_URL}/notifications`, data);
export const deleteNotificationApi = (data) =>
  api.delete(`${url.ERP_URL}/notifications`, data);

export const getRoleListApi = (data) => api.get("/role-lists", data);
export const addNewRoleListApi = (data) => api.post("/role-lists", data);
export const updateRoleListApi = (data) => api.patch("/role-lists", data);
export const deleteRoleListApi = (data) => api.delete("/role-lists", data);
export const approveRoleListApi = (data) => api.put("/role-lists", data);

export const getActivityLogApi = (data) => api.get("/activity-logs", data);
export const addNewActivityLogApi = (data) => api.post("/activity-logs", data);
export const addNewActivityLogTableApi = (data) => api.post("", data);
export const approveActivityLogApi = (data) => api.put("/activity-logs", data);
export const updateActivityLogApi = (data) => api.patch("/activity-logs", data);
export const updateActivityLogTableApi = (data) => api.patch("", data);
export const deleteActivityLogApi = (data) =>
  api.delete("/activity-logs", data);

export const getOneDownloadAppApi = (id) =>
  api.getOne(`${url.SMS_URL}/apks`, id);
export const getDownloadAppApi = (data) =>
  api.get(`${url.SMS_URL}/apks`, data);
export const addNewDownloadAppApi = (data) =>
  api.postImg(`${url.SMS_URL}/apks`, data);
export const approveDownloadAppApi = (data) =>
  api.put(`${url.SMS_URL}/apks`, data);
export const updateDownloadAppApi = (data) =>
  api.patchImg(`${url.SMS_URL}/apks`, data);
export const deleteDownloadAppApi = (data) =>
  api.delete(`${url.SMS_URL}/apks`, data);
