import React from "react";
import defaultImg from "../../assets/images/upload.png";
import { api } from "../../config";
import { ListGroup, ListGroupItem } from "reactstrap";

function WidgetList({ data }) {
  return (
    <>
      <h5 className='text-center'>{data.name}</h5>
      <div className='d-flex justify-content-center mt-2'>
        <img
          src={
            !data.image ? defaultImg : `${api.MEDIA_URL}/media/${data.image}`
          }
          alt=''
          data-dz-thumbnail=''
          height='30'
          className='avatar-md rounded bg-light'
        />
      </div>
      <ListGroup flush numbered className='mt-2'>
        {data.dd_parameter.map((wParams, idxWParams) => {
          return (
            <ListGroupItem key={idxWParams}>
              {wParams.ddp_parameter.label + " : "}
              {wParams.ddp_parameter.amount
                ? `${wParams.ddp_parameter.amount} ${wParams.ddp_parameter.uom}`
                : "-"}
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </>
  );
}

export default WidgetList;
