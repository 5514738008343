import {
  BRANCH_LOADING,
  GET_BRANCH,
  GET_BRANCH_SUCCESS,
  GET_BRANCH_FAIL,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  BRANCH_RESET
} from "./actionType";

const INIT_STATE = {
  branches: [],
  error: {},
};

const Branch = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANCH_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BRANCH:
          return {
            ...state,
            branches: action.payload.data,
            isBranchCreated: false,
            isBranchSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_BRANCH_FAIL:
      switch (action.payload.actionType) {
        case GET_BRANCH_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isBranchCreated: false,
            isBranchSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_BRANCH: {
      return {
        ...state,
        isBranchCreated: false,
        loading: true,
      };
    }

    case BRANCH_LOADING: {
      return {
        ...state,
        isBranchCreated: false,
        loading: true,
      };
    }

    case ADD_BRANCH_SUCCESS:
      return {
        ...state,
        isBranchCreated: true,
        loading: false,
        branches: [...state.branches, action.payload.data],
      };

    case ADD_BRANCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        branches: state.branches.filter(
          branch => branch.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_BRANCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        branches: state.branches.map(branch =>
          branch.id.toString() === action.payload.data.id.toString() ? { ...branch, ...action.payload.data } : branch
        ),
      };

    case UPDATE_BRANCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BRANCH_RESET:
      return INIT_STATE;


    default:
      return { ...state };
  }
};

export default Branch;