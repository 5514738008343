import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_BRAND,
  DELETE_BRAND,
  UPDATE_BRAND,
  ADD_NEW_BRAND,
} from "./actionType";

import {
  getBrandSuccess,
  getBrandFail,
  deleteBrandSuccess,
  deleteBrandFail,
  updateBrandSuccess,
  updateBrandFail,
  addBrandSuccess,
  addBrandFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getBrandApi,
  updateBrandApi,
  deleteBrandApi,
  addNewBrandApi
} from "../../helpers/backend_helper";

function* getBrand({ payload: brand }) {
  try {
    const response = yield call(getBrandApi, brand);
    yield put(getBrandSuccess(GET_BRAND, response.data));
  } catch (error) {
    yield put(getBrandFail(GET_BRAND, error));
  }
}

function* onUpdateBrand({ payload: brand }) {
  try {
    const response = yield call(updateBrandApi, brand);
    yield put(updateBrandSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateBrandFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteBrand({ payload: brand }) {
  try {
    const response = yield call(deleteBrandApi, brand);
    yield put(deleteBrandSuccess({ brand, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBrandFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewBrand({ payload: brand }) {
  try {
    const response = yield call(addNewBrandApi, brand);
    yield put(addBrandSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addBrandFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetBrand() {
  yield takeEvery(GET_BRAND, getBrand);
}

export function* watchUpdateBrand() {
  yield takeEvery(UPDATE_BRAND, onUpdateBrand);
}

export function* watchDeleteBrand() {
  yield takeEvery(DELETE_BRAND, onDeleteBrand);
}

export function* watchAddNewBrand() {
  yield takeEvery(ADD_NEW_BRAND, onAddNewBrand);
}

function* brandSaga() {
  yield all([
    fork(watchGetBrand),
    fork(watchDeleteBrand),
    fork(watchUpdateBrand),
    fork(watchAddNewBrand)
  ]);
}

export default brandSaga;
