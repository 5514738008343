import React, { useState, Fragment } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabPane,
  TabContent,
} from "reactstrap";
import { Link } from "react-router-dom";
import { api } from "../../config";
import moment from "moment";
import classnames from "classnames";
import SimpleBar from "simplebar-react";

import dummyImg from "../../assets/images/iot_device.png";

function TaskDeviceComponent({ data }) {
  console.log(data, 'datas');
  const [open, setOpen] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [imageDiscusData, setImageDiscusData] = useState({
    index: 0,
    urls: [],
  });
  const [isOpenImage, setIsOpenImage] = useState(false);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleAcc = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY HH:mm");
  };
  const renderIconAttecmentList = (fileName) => {
    const type = fileName.split(".");

    switch (type[type.length - 1]) {
      case "sheet":
        return (
          <i className='ri-file-excel-line me-1 align-bottom text-success'></i>
        );

      case "x-compressed":
        return (
          <i className='ri-file-zip-line me-1 align-bottom text-danger'></i>
        );

      case "pdf":
        return (
          <i className='ri-file-pdf-line me-1 align-bottom text-danger'></i>
        );

      default:
        return <i className='ri-image-line me-1  align-bottom text-info '></i>;
    }
  };

  const renderIconImgList = (fileName) => {
    const type = fileName.split(".");

    switch (type[type.length - 1]) {
      case "sheet":
        return (
          <i
            className='ri-file-excel-line me-1 align-bottom text-success'
            style={{ fontSize: "45px" }}></i>
        );

      case "x-compressed":
        return (
          <i
            className='ri-file-zip-line me-1 align-bottom text-danger'
            style={{ fontSize: "45px" }}></i>
        );

      case "pdf":
        return (
          <i
            className='ri-file-pdf-line me-1 align-bottom text-danger'
            style={{ fontSize: "45px" }}></i>
        );

      default:
        return (
          <img
            src={
              fileName === "" ? dummyImg : `${api.MEDIA_URL}/media/${fileName}`
            }
            alt=''
            data-dz-thumbnail=''
            height='30'
            className='avatar-sm rounded bg-light'
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = dummyImg;
            }}
          />
        );
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleValidTime = (time) => {
    return moment(time, "YYYY/MM/DD HH:mm").tz("Asia/Jakarta").format("HH:mm");
  };

  const handleDuration = (minutes) => {
    var h = Math.floor(minutes / 60);
    var m = Math.round(minutes % 60);
    if (h > 0) return h + " hours " + m + " min";
    else return m + " minutes";
  };

  return (
    <Accordion flush open={open} toggle={toggleAcc}>
      {(data.device_task || []).map((task, taskIdx) => {
        return (
          <AccordionItem key={"task" + taskIdx}>
            <AccordionHeader targetId={"task" + taskIdx}>
              {task && task.task_device_task && task.task_device_task.name ? task.task_device_task.name : ""}
            </AccordionHeader>
            <AccordionBody accordionId={"task" + taskIdx}>
              <div className='d-flex'>
                <div className='flex-grow-1 fw-bold '>
                  <h6>{task && task.task_device_task && task.task_device_task.task_type}</h6>
                  <h6 className='mb-2'>
                    {task && task.task_device_task && task.task_device_task.task_category ? task.task_device_task.task_category.name : ""}
                  </h6>
                  <div className='mt-lg-0'>
                    <p className='text-muted mb-1'>Assigned To:</p>
                    <div className='avatar-group'>
                      {((task && task.task_device_task && task.task_device_task.task_assign) || []).map(
                        (element) => {
                          if (element.taska_user.image) {
                            return (
                              <Fragment key={element.id}>
                                <Link
                                  to='#'
                                  className='avatar-group-item'
                                  id={"img-" + element.id}>
                                  <img
                                    src={`${api.MEDIA_URL}/media/${element.taska_user.image}-50-50`}
                                    alt=''
                                    className='rounded-circle avatar-xs'
                                  />
                                </Link>
                                <UncontrolledTooltip
                                  placement='top'
                                  target={"img-" + element.id}>
                                  {element && element.taska_user && element.taska_user.name}
                                </UncontrolledTooltip>
                              </Fragment>
                            );
                          } else {
                            return (
                              <Fragment key={element.id}>
                                <Link
                                  className='avatar-group-item'
                                  id={"img-" + element.id}>
                                  <div className='avatar-xs'>
                                    <div className='avatar-title rounded-circle bg-light text-primary'>
                                      {element && element.taska_user && element.taska_user.name
                                        .charAt(0)
                                        .toUpperCase()}
                                    </div>
                                  </div>
                                </Link>
                                <UncontrolledTooltip
                                  placement='top'
                                  target={"img-" + element.id}>
                                  {element && element.taska_user && element.taska_user.name}
                                </UncontrolledTooltip>
                              </Fragment>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className='flex-shrink-0 fw-bold '>
                  <h6>{handleValidDate(task && task.task_device_task && task.task_device_task.createdat)}</h6>
                  {task && task.task_device_task && task.task_device_task.task_status?.name === "Completed" ? (
                    <h6>
                      {task && task.task_device_task && task.task_device_task.task_status?.name}
                      <span className='ms-3'>{`(${task.task_device_task.rating})`}</span>
                    </h6>
                  ) : (
                    <h6>{task && task.task_device_task && task.task_device_task.task_status?.name}</h6>
                  )}
                  <div className='mt-lg-0'>
                    <p className='text-muted mb-1'>Report To:</p>
                    <div className='avatar-group'>
                      {((task && task.task_device_task && task.task_device_task.task_report) || []).map(
                        (element) => {
                          if (element.taskr_user.image) {
                            return (
                              <Fragment key={element.id}>
                                <Link
                                  to='#'
                                  className='avatar-group-item'
                                  id={"img-" + element.id}>
                                  <img
                                    src={`${api.MEDIA_URL}/media/${element.taskr_user.image}-50-50`}
                                    alt=''
                                    className='rounded-circle avatar-xs'
                                  />
                                </Link>
                                <UncontrolledTooltip
                                  placement='top'
                                  target={"img-" + element.id}>
                                  {element && element.taskr_user && element.taskr_user.name}
                                </UncontrolledTooltip>
                              </Fragment>
                            );
                          } else {
                            return (
                              <Fragment key={element.id}>
                                <Link
                                  className='avatar-group-item'
                                  id={"img-" + element.id}>
                                  <div className='avatar-xs'>
                                    <div className='avatar-title rounded-circle bg-light text-primary'>
                                      {element && element.taskr_user && element.taskr_user.name
                                        .charAt(0)
                                        .toUpperCase()}
                                    </div>
                                  </div>
                                </Link>
                                <UncontrolledTooltip
                                  placement='top'
                                  target={"img-" + element.id}>
                                  {element && element.taskr_user && element.taskr_user.name}
                                </UncontrolledTooltip>
                              </Fragment>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <ListGroup flush numbered className='mt-4'>
                {((task && task.task_device_task && task.task_device_task.task_detail) || []).map(
                  (taskDet, taskDetId) => (
                    <ListGroupItem key={"taskDet" + taskDetId}>
                      {taskDet && taskDet.name}
                    </ListGroupItem>
                  )
                )}
              </ListGroup>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center'>
                    <div className='flex-grow-1'>
                      <h5>{task && task.name}</h5>
                      <p className='text-muted'>{task && task.description}</p>
                    </div>
                    <div className='flex-shrink-0 ms-3'></div>
                  </div>
                </CardBody>
                <CardHeader>
                  <div className='d-flex align-items-center'>
                    <div className='flex-grow-1'>
                      <Nav
                        className='nav-tabs-custom rounded card-header-tabs border-bottom-0'
                        role='tablist'>
                        <NavItem>
                          <NavLink
                            href='#'
                            className={classnames({
                              active: activeTab === "1-" + taskIdx,
                            })}
                            onClick={() => {
                              toggleTab("1-" + taskIdx);
                            }}>
                            <i className='ri-discuss-line align-bottom '></i>{" "}
                            Discussions
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href='#'
                            className={classnames({
                              active: activeTab === "2-" + taskIdx,
                            })}
                            onClick={() => {
                              toggleTab("2-" + taskIdx);
                            }}>
                            <i className='ri-attachment-line align-bottom '></i>{" "}
                            Attachments Files
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href='#'
                            className={classnames({
                              active: activeTab === "3-" + taskIdx,
                            })}
                            onClick={() => {
                              toggleTab("3-" + taskIdx);
                            }}>
                            <i className='ri-timer-line align-bottom '></i> Time
                            Entries
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={"1-" + taskIdx}>
                      {task.tasks_note && task.tasks_note.length > 1 ? (
                        <SimpleBar
                          style={{ height: "248px" }}
                          className='px-3 mx-n3 mb-2'>
                          {task.tasks_note.map((note, idn) => {
                            return (
                              <div className='d-flex mb-4' key={idn + "note"}>
                                <div className='flex-shrink-0'>
                                  <img
                                    src={
                                      note.taskn_user &&
                                        note.taskn_user.user_image &&
                                        note.taskn_user.user_image.path
                                        ? process.env.REACT_APP_API_URL +
                                        "/files/" +
                                        note.taskn_user.user_image.path
                                        : dummyImg
                                    }
                                    alt=''
                                    className='avatar-xs rounded-circle'
                                  />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                  <h5 className='fs-13'>
                                    <Link to='/pages-profile'>
                                      {note.taskn_user && note.taskn_user.name}
                                      <small>
                                        {note.taskn_user &&
                                          note.taskn_user.user_position &&
                                          note.taskn_user.user_position
                                            .position_name}
                                      </small>
                                    </Link>
                                    <small className='text-muted ms-2'>
                                      {moment(note.createdat)
                                        .tz("Asia/Jakarta")
                                        .fromNow()}
                                    </small>
                                  </h5>
                                  <p className='text-muted'>
                                    {note.description}
                                  </p>
                                  <Row className='g-2'>
                                    {note.taskn_file.map((f, idf) => {
                                      return (
                                        <Col
                                          onClick={() => {
                                            const typeFile =
                                              f.filename.split(".");
                                            if (
                                              typeFile[typeFile.length - 1] ===
                                              "x-compressed"
                                            ) {
                                              window.open(
                                                `${api.MEDIA_URL}/media/${f.filename}`
                                              );
                                            } else if (
                                              typeFile[typeFile.length - 1] ===
                                              "sheet"
                                            ) {
                                              window.open(
                                                `${api.MEDIA_URL}/media/${f.filename}`
                                              );
                                            } else if (
                                              typeFile[typeFile.length - 1] ===
                                              "pdf"
                                            ) {
                                              window.open(
                                                `${api.MEDIA_URL}/media/${f.filename}`
                                              );
                                            } else {
                                              const urlsArr =
                                                note.taskn_file.filter((el) => {
                                                  const type =
                                                    el.filename.split(".");
                                                  return (
                                                    type[type.length - 1] !==
                                                    "x-compressed" &&
                                                    type[type.length - 1] !==
                                                    "sheet" &&
                                                    type[type.length - 1] !==
                                                    "pdf"
                                                  );
                                                });
                                              const indexImg =
                                                urlsArr.findIndex(
                                                  (url) =>
                                                    url.filename === f.filename
                                                );
                                              setImageDiscusData({
                                                index: indexImg,
                                                urls: urlsArr.map((el) => {
                                                  return {
                                                    src: `${api.MEDIA_URL}/media/${el.filename}`,
                                                  };
                                                }),
                                              });
                                              setIsOpenImage(true);
                                            }
                                          }}
                                          className='mb-2'
                                          key={idf + "file"}
                                          lg={1}
                                          sm={2}
                                          xs={6}>
                                          {renderIconImgList(f.filename)}
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                  <Link
                                    to='#'
                                    className='badge text-muted bg-light'>
                                    <i className='mdi mdi-reply'></i> Reply
                                  </Link>
                                </div>
                              </div>
                            );
                          })}
                        </SimpleBar>
                      ) : (
                        <>
                          {(task.tasks_note || []).map((note, idn) => {
                            return (
                              <div className='d-flex mb-4' key={idn + "note"}>
                                <div className='flex-shrink-0'>
                                  {note.taskn_user.image ? (
                                    <>
                                      <Link
                                        to='#'
                                        className='avatar-group-item'
                                        id={"nimg-" + note.id}>
                                        <img
                                          src={`${api.MEDIA_URL}/media/${note.taskn_user.image}-50-50`}
                                          alt=''
                                          className='rounded-circle avatar-xs'
                                        />
                                      </Link>
                                      <UncontrolledTooltip
                                        placement='top'
                                        target={"nimg-" + note.id}>
                                        {note && note.taskn_user && note.taskn_user.name}
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Link
                                        className='avatar-group-item'
                                        id={"nimg-" + note.id}>
                                        <div className='avatar-xs'>
                                          <div className='avatar-title rounded-circle bg-light text-primary'>
                                            {note && note.taskn_user && note.taskn_user.name
                                              .charAt(0)
                                              .toUpperCase()}
                                          </div>
                                        </div>
                                      </Link>
                                      <UncontrolledTooltip
                                        placement='top'
                                        target={"nimg-" + note.id}>
                                        {note && note.taskn_user && note.taskn_user.name}
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                  <h5 className='fs-13'>
                                    <Link to='/pages-profile'>
                                      {note && note.taskn_user && note.taskn_user.name}
                                      <small>
                                        {note.taskn_user.name &&
                                          note.taskn_user.user_position &&
                                          note.taskn_user.user_position.name}
                                      </small>
                                    </Link>
                                    <small className='text-muted ms-2'>
                                      {moment(note.createdat)
                                        .tz("Asia/Jakarta")
                                        .fromNow()}
                                    </small>
                                  </h5>
                                  <p className='text-muted'>
                                    {note.description}
                                  </p>
                                  <Row className='g-2 mb-3'>
                                    {note.taskn_file.map((f, idf) => (
                                      <Col
                                        key={idf + "file"}
                                        lg={1}
                                        sm={2}
                                        xs={6}>
                                        {f.mimetype.includes("image") ? (
                                          <img
                                            className='img-fluid rounded'
                                            alt={f.filename}
                                            src={
                                              process.env.REACT_APP_API_URL +
                                              "/files/" +
                                              f.path
                                            }
                                          />
                                        ) : (
                                          <i className='display-6 text-muted ri-function-line' />
                                        )}
                                      </Col>
                                    ))}
                                  </Row>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </TabPane>
                    <TabPane tabId={"2-" + taskIdx}>
                      <div className='table-responsive table-card'>
                        <table className='table align-middle table-nowrap mb-0'>
                          <thead className='table-active'>
                            <tr>
                              <th scope='col'>File Name</th>
                              <th scope='col'>Added By</th>
                              <th scope='col'>File Item</th>
                              <th scope='col'>File Size</th>
                              <th scope='col'>Recent Date</th>
                              <th scope='col' className='text-center'>
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody id='file-list'>
                            {(task.tasks_file || []).map((file, key) => (
                              <tr
                                onClick={() => {
                                  const typeFile = file.filename.split(".");
                                  if (
                                    typeFile[typeFile.length - 1] ===
                                    "x-compressed"
                                  ) {
                                    window.open(
                                      `${api.MEDIA_URL}/media/${file.filename}`
                                    );
                                  } else if (
                                    typeFile[typeFile.length - 1] === "sheet"
                                  ) {
                                    window.open(
                                      `${api.MEDIA_URL}/media/${file.filename}`
                                    );
                                  } else if (
                                    typeFile[typeFile.length - 1] === "pdf"
                                  ) {
                                    window.open(
                                      `${api.MEDIA_URL}/media/${file.filename}`
                                    );
                                  } else {
                                    setImageDiscusData({
                                      index: 0,
                                      urls: [
                                        {
                                          src: `${api.MEDIA_URL}/media/${file.filename}`,
                                        },
                                      ],
                                    });
                                    setIsOpenImage(true);
                                  }
                                }}
                                key={key + "file"}>
                                <td>
                                  <input
                                    className='form-control filelist-id'
                                    type='hidden'
                                    value='1'
                                    id='filelist-1'
                                  />
                                  <div className='d-flex align-files-center'>
                                    <div className='flex-shrink-0 fs-17 me-2 filelist-icon'>
                                      <span className=' fs-18 text-info'>
                                        {renderIconAttecmentList(file.filename)}
                                      </span>
                                    </div>
                                    <div className='flex-grow-1 filelist-name'>
                                      {file.filename}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <Link to='/pages-profile'>
                                    {file.file_task_note &&
                                      file.file_task_note.taskn_user &&
                                      file.file_task_note.taskn_user.name}
                                  </Link>
                                </td>
                                <td>{file.mimetype}</td>
                                <td className='filelist-size'>
                                  {formatBytes(file.size)}
                                </td>
                                <td className='filelist-create'>
                                  {handleValidDate(file.createdat)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </TabPane>
                    <TabPane tabId={"3-" + taskIdx}>
                      <div className='table-responsive table-card'>
                        <table className='table align-middle mb-0'>
                          <thead className='table-light'>
                            <tr>
                              <th scope='col'>Member</th>
                              <th scope='col'>Date</th>
                              <th scope='col'>Duration</th>
                              <th scope='col'>Tasks Title</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(task.tasks_time || []).map((time, key) => (
                              <tr key={key + "time"}>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <img
                                      src={
                                        time.taskt_user &&
                                          time.taskt_user.user_image &&
                                          time.taskt_user.user_image.path
                                          ? process.env.REACT_APP_API_URL +
                                          "/files/" +
                                          time.taskt_user.user_image.path
                                          : dummyImg
                                      }
                                      alt=''
                                      className='avatar-xs rounded-circle'
                                    />
                                    <div className='flex-grow-1 ms-2'>
                                      <p className='mb-0 fw-medium'>
                                        <Link to='/pages-profile'>
                                          {time.taskt_user &&
                                            time.taskt_user.name}
                                        </Link>
                                      </p>
                                      <small className='mb-0 text-muted'>
                                        {time.taskt_user &&
                                          time.taskt_user.user_position &&
                                          time.taskt_user.user_position
                                            .position_name}
                                      </small>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {handleValidDate(time.startat)}{" "}
                                  <small>{handleValidTime(time.startat)}</small>
                                </td>
                                <td>
                                  {time.minutes && handleDuration(time.minutes)}
                                </td>
                                <td>{time && time.time_sheet_name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </AccordionBody>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}

export default TaskDeviceComponent;
