export const HISTORY_LOADING = "HISTORY_LOADING";
export const HISTORY_RESET = "HISTORY_RESET";
export const GET_HISTORY = "GET_HISTORY";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const GET_HISTORY_FAIL = "GET_HISTORY_FAIL";
export const DELETE_HISTORY = "DELETE_HISTORY";
export const DELETE_HISTORY_SUCCESS = "DELETE_HISTORY_SUCCESS";
export const DELETE_HISTORY_FAIL = "DELETE_HISTORY_FAIL";
export const UPDATE_HISTORY = "UPDATE_HISTORY";
export const UPDATE_HISTORY_SUCCESS = "UPDATE_HISTORY_SUCCESS";
export const UPDATE_HISTORY_FAIL = "UPDATE_HISTORY_FAIL";
export const ADD_NEW_HISTORY = "ADD_NEW_HISTORY";
export const ADD_HISTORY_SUCCESS = "ADD_HISTORY_SUCCESS";
export const ADD_HISTORY_FAIL = "ADD_HISTORY_FAIL";
export const GET_HISTORY_PUBLIC = "GET_HISTORY_PUBLIC";
