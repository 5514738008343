import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  Table,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  Spinner,
  TabContent,
  TabPane,
  FormFeedback,
} from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { isEmpty, clone } from "lodash";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";

import { ToastContainer } from "react-toastify";
import Flatpickr from "react-flatpickr";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import { useSelector, useDispatch } from "react-redux";
import {
  getDevice as onGetDevices,
  addNewDevice as onAddNewDevice,
  updateDevice as onUpdateDevice,
  deleteDevice as onDeleteDevice,
  getDeviceLoading,
  resetDeviceState,
} from "../../../store/device/action";

import { getClient, resetClientState } from "../../../store/client/action";
import { getCompany, resetCompanyState } from "../../../store/company/action";
import { getBranch, resetBranchState } from "../../../store/branch/action";
import {
  getDeviceType,
  resetDeviceTypeState,
} from "../../../store/deviceType/action";
import { getUnit, resetUnitState } from "../../../store/unit/action";
import { getService, resetServiceState } from "../../../store/service/action";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ModalInputFile from "../../../Components/ModalInputFile";
import defaultImg from "../../../assets/images/upload.png";
import { api } from "../../../config";

const Device = (props) => {
  const { t } = props;
  const [modal, setModal] = useState(false);
  const [roles, setRoles] = useState();
  const [userType, setUserType] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const [fileNameItem, setFileNameItem] = useState([]);
  const [file, setFile] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [clientName, setClientName] = useState();
  const [userRole, setUserRole] = useState();
  const [modalMedia, setModalMedia] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [clientId, setClientId] = useState();
  const [pIndex, setPIndex] = useState(0);

  const {
    devices,
    isDeviceCreated,
    isDeviceSuccess,
    loading,
    error,
    isMediaDeleted,
    media,
    mediaLoading,
    clients,
    deviceTypes,
    units,
    services,
    companies,
    branches,
    companiesLoading,
    branchesLoading,
    unitLoading,
    serviceLoading,
  } = useSelector((state) => ({
    devices: state.Device.devices,
    clients: state.Client.clients,
    deviceTypes: state.DeviceType.deviceTypes,
    units: state.Unit.units,
    services: state.Service.services,
    isDeviceCreated: state.Device.isDeviceCreated,
    isDeviceSuccess: state.Device.isDeviceSuccess,
    loading: state.Device.loading,
    error: state.Device.error,
    companies: state.Company.companies,
    branches: state.Branch.branches,
    companiesLoading: state.Company.loading,
    branchesLoading: state.Branch.loading,
    unitLoading: state.Unit.loading,
    serviceLoading: state.Service.loading,
  }));

  const [deviceList, setDeviceList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [device, setDevice] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [groupList, setParentList] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const [filterDate, setFilterDate] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [connection, setConnection] = useState("tcp");
  const [mediaType, setMediaType] = useState("");
  const [logo, setLogo] = useState(defaultImg);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [resCheck, setResCheck] = useState(false);
  const [activeTabModal, setActiveTabModal] = useState("1");

  const [isExecutive, setIsExecutive] = useState(false);
  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: 1 },
        { label: "Active", value: 2 },
        { label: "Deactivated", value: 3 },
      ],
    },
  ];

  const connectionTypeData = [
    {
      options: [
        { label: "Default", value: "default" },
        { label: "Http Api", value: "api" },
      ],
    },
  ];

  const types = [
    {
      options: [
        { label: "TCP", value: "tcp" },
        { label: "MQTT", value: "mqtt" },
      ],
    },
  ];

  const resCheckHandler = () => {
    setResCheck(!resCheck);
  };

  useEffect(() => {
    if (devices && !devices.length && !isSearch) {
      dispatch(resetDeviceState());
      dispatch(onGetDevices());
    }
    setIsSearch(true);
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    console.log(obj);
    if (obj) {
      if (
        obj.data &&
        obj.data.employee_position &&
        obj.data.employee_position.position_role
      )
        setRoles(obj.data.employee_position.position_role.role_method);
      else setRoles(null);

      if (obj.data && obj.data.userType) setUserType(obj.data.userType);
      if (obj.data && obj.data.userRole) setUserRole(obj.data.userRole);

      if (obj.data.client_name) {
        setClientName(obj.data.client_name);
      }

      if (obj.data.userType === "company") {
        setCompanyId(obj.data.companyId);
        setClientId(obj.data.clientId);
      } else if (obj.data.userType === "client") {
        setClientId(obj.data.clientId);
      }
    } else {
      window.location = "/logout";
    }
  }, [dispatch, devices, isSearch]);

  const onClickDelete = (device) => {
    setDevice(device);
    setDeleteModal(true);
  };

  const handleDeleteDevice = () => {
    if (device) {
      dispatch(onDeleteDevice(device));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(clients)) {
      var data = [];
      var opt = [];
      clients.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setClientList(data);
    }
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(companies)) {
      var data = [];
      var opt = [];
      companies.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setCompanyList(data);
    }
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(branches)) {
      var data = [];
      var opt = [];
      branches.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setBranchList(data);
    }
  }, [branches]);

  useEffect(() => {
    if (!isEmpty(services)) {
      var data = [];
      var opt = [];
      services.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setServiceList(data);
    }
  }, [services]);

  useEffect(() => {
    if (!isEmpty(deviceTypes)) {
      var data = [];
      var opt = [];
      deviceTypes.forEach((item, key) => {
        const name = item.title;
        const id = item.id;
        var obj = { label: name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setDeviceTypeList(data);
    }
  }, [deviceTypes]);

  useEffect(() => {
    if (!isEmpty(units)) {
      var data = [];
      var opt = [];
      units.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name, value: id, unitId: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setUnitList(data);
    }
  }, [units]);

  useEffect(() => {
    setDeviceList(devices);
  }, [devices]);

  useEffect(() => {
    if (!isEmpty(devices)) {
      let arr = [];
      devices.forEach((element) => {
        arr.push({ value: element.id, label: element.name });
      });
      setParentList(arr);
    }
  }, [devices]);

  useEffect(() => {
    dispatch(resetClientState());
    dispatch(getClient()); //{ status: 2 }
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetServiceState());
    dispatch(getService());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetDeviceTypeState());
    dispatch(getDeviceType());
  }, [dispatch]);

  const handleValidDate = (date) => {
    return moment(date, "YYYY/MM/DD").tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredDevices = devices;
      if (type !== "all") {
        filteredDevices = devices.filter(
          (device) => device.device_status?.name === type
        );
      }
      setDeviceList(filteredDevices);
    }
  };

  const toggleTabModal = (tab) => {
    if (activeTabModal !== tab) {
      setActiveTabModal(tab);
    }
  };

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setDevice(null);
      setIsClick(false);
      setFile([]);
      forceUpdate();
      setResCheck(false);
    } else {
      // initFile();
      setModal(true);
    }
    if (!isExecutive) {
      //validation.setva
    }
  }, [modal, isExecutive, forceUpdate]);

  const handleCompany = (data) => {
    dispatch(resetCompanyState());
    dispatch(getCompany({ clientId: data.value }));
  };

  const handleUnit = (data) => {
    dispatch(resetUnitState());
    dispatch(getUnit({ branchId: data.value }));
  };

  const handleBranch = (data) => {
    dispatch(resetBranchState());
    dispatch(getBranch({ companyId: data.value }));
  };

  useEffect(() => {
    if (companyId) {
      dispatch(resetBranchState());
      dispatch(getBranch({ companyId: companyId }));
    }
  }, [companyId, dispatch]);

  const handleDeviceClick = useCallback(
    (arg) => {
      const device = arg;
      let dataFile = [];
      device.device_file.forEach((files) => {
        dataFile.push(files);
      });

      setFile(dataFile);

      let device_unit = [];

      if (device.device_device_unit) {
        device.device_device_unit.forEach((item) => {
          const label = item.device_unit_unit?.name;
          const id = item.device_unit_unit?.id;
          var obj = { label: label, value: id, unitId: id };
          device_unit.push(obj);
        });
      }

      setDevice({
        id: device.id,
        name: device.name,
        code: device.code,
        uid: device.uid,
        host: device.retranslator ? device.retranslator.host : "",
        phone: device.phone,
        longitude: device.longitude,
        latitude: device.latitude,
        connection_type:
          device.connection_type === "default"
            ? { label: "Default", value: "default" }
            : device.connection_type === "api"
              ? { label: "Http Api", value: "api" }
              : "",
        username: device.retranslator ? device.retranslator.username : "",
        password: device.retranslator ? device.retranslator.password : "",
        mqtt_clientId: device.mqtt_clientId,
        imei: device.imei,
        port: device.retranslator ? device.retranslator.port : "",
        publishTopic: device.retranslator
          ? device.retranslator.publishTopic
          : "",
        subscribeTopic: device.retranslator
          ? device.retranslator.subscribeTopic
          : "",
        status: {
          label: device.device_status?.name,
          value: device.device_status?.id,
        },
        description: device.description,
        unit: device_unit,
        client: {
          label: device.device_client.name,
          value: device.device_client.id,
        },
        deviceType: {
          label: device.device_type ? device.device_type.title : "",
          value: device.device_type ? device.device_type.id : "",
        },
        service: {
          label: device.device_service ? device.device_service.name : "",
          value: device.device_service ? device.device_service.id : "",
        },
        company: {
          label: device.device_company ? device.device_company.name : "",
          value: device.device_company ? device.device_company.id : "",
        },
        branch: {
          label: device.device_branch ? device.device_branch.name : "",
          value: device.device_branch ? device.device_branch.id : "",
        },
        // statusDevice: device.status,
        image: device.image,
        identifier: device.identifier,
        reIdentifier: device.retranslator ? device.retranslator.identifier : "",
      });

      setResCheck(device.isRetranslator);
      if (device.retranslator) {
        setConnection(device.retranslator.connection_type);
      }

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="dataCheckbox form-check-input"
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleDeviceClick(data);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </Link>
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-danger remove-item-btn"
                onClick={() => {
                  const data = cellProps.row.original;
                  onClickDelete(data);
                }}
              >
                <i className=" ri-delete-bin-line fs-15"></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: `${t("Name")}`,
        accessor: "name",
        filterable: true,
      },
      {
        Header: `${t("Company")}`,
        accessor: "device_company.name",
        filterable: true,
      },
      {
        Header: `${t("Branch")}`,
        accessor: "device_branch.name",
        filterable: true,
      },
      {
        Header: "IMEI",
        accessor: "imei",
        filterable: true,
      },
      {
        Header: `${t("Phone")}`,
        accessor: "phone",
        filterable: true,
      },
      {
        Header: `${t("Description")}`,
        accessor: "description",
        filterable: true,
      },
      {
        Header: `${t("Status")}`,
        accessor: "device_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "Activated":
              return (
                <span className="badge text-uppercase badge-soft-secondary">
                  {t(cell.value)}
                </span>
              );
            case "Deactivated":
              return (
                <span className="badge text-uppercase badge-soft-danger">
                  {t(cell.value)}
                </span>
              );
            case "Draft":
              return (
                <span className="badge text-uppercase badge-soft-warning">
                  {t(cell.value)}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase badge-soft-warning">
                  {t(cell.value)}
                </span>
              );
          }
        },
      },
    ],
    [handleDeviceClick, t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: deviceList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: pIndex,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable
      ? column.isSorted
        ? column.isSortedDesc
          ? " sorting_desc"
          : " sorting_asc"
        : " sort"
      : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState("");
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    if (filterDate) {
      let datearr = filterDate.split(" to ");
      if (datearr[1]) {
        startat = datearr[0];
        endat = datearr[1];
      }
    }

    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetDevices(param));
  }

  const handleYup = () => {
    let datas = {
      client: Yup.object().required("Please Select Client"),
      company: Yup.object().required("Please Select Company"),
      branch: Yup.object().required("Please Select Branch"),
      unit: Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required("Label Required"),
          unitId: Yup.number().required("Unit ID Required"),
          value: Yup.number().required("Value Required"),
        })
      ),
      service: Yup.object().required("Please Select Service"),
      deviceType: Yup.object().required("Please Select Device Type"),
      connection_type: Yup.object().required("Please Select Connection Type"),
      status: Yup.object().required("Please Select Status"),
      name: Yup.string().required("Please Enter Name"),
      identifier: Yup.string().required("Please Enter Identifier"),
    };
    if (userType === "company") {
      delete datas.client;
      delete datas.company;
    } else if (userType === "client") {
      delete datas.client;
    }
    return datas;
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      code: (device && device.code) || "",
      name: (device && device.name) || "",
      host: (device && device.host) || "",
      port: (device && device.port) || "",
      identifier: (device && device.identifier) || "",
      identifier2: (device && device.identifier2) || "",
      phone: (device && device.phone) || "",
      image: (device && device.image) || "",
      longitude: (device && device.longitude) || "",
      latitude: (device && device.latitude) || "",
      connection_type: (device && device.connection_type) || "",
      isRetranslator: (device && device.isRetranslator) || "",
      retranslator: device && device.retranslator,
      description: (device && device.description) || "",
      status: (device && device.status) || "",
      company: (device && device.company) || "",
      branch: (device && device.branch) || "",
      service: (device && device.service) || "",
      // statusDevice: (device && device.statusDevice) || "",
      client: (device && device.client) || "",
      deviceType: (device && device.deviceType) || "",
      imei: (device && device.imei) || "",
      unit: device && device.unit,
      files: (device && device.files) || null,
      reIdentifier: (device && device.reIdentifier) || "",
      username: (device && device.username) || "",
      password: (device && device.password) || "",
      mqtt_clientId: (device && device.mqtt_clientId) || "",
      publishTopic: (device && device.publishTopic) || "",
      subscribeTopic: (device && device.subscribeTopic) || "",
    },
    validationSchema: Yup.object(handleYup()),
    onSubmit: (values) => {
      dispatch(getDeviceLoading());
      if (isEdit) {
        if (connection === "mqtt") {
          if (values.unit) {
            values.unit.forEach((x) => {
              delete x.label;
              delete x.value;
            });
          }
          const updateDevice = {
            id: device ? device.id : 0,
            code: values.code,
            name: values.name,
            identifier: values.identifier,
            identifier2: values.identifier2,
            phone: values.phone,
            image: values.image,
            longitude: values.longitude,
            latitude: values.latitude,
            connection_type: values.connection_type.value,
            isRetranslator: resCheck,
            retranslator: {
              connection_type: connection,
              host: values.host,
              port: values.port,
              username: values.username,
              password: values.password,
              identifier: values.reIdentifier,
              clientId: values.mqtt_clientId || "",
              publishTopic: values.publishTopic,
              subscribeTopic: values.subscribeTopic,
            },
            imei: values.imei,
            description: values.description,
            // status: values.statusDevice,
            companyId: companyId ? companyId : values.company.value,
            branchId: values.branch.value,
            serviceId: values.service.value,
            statusId: values.status.value,
            clientId: clientId ? clientId : values.client.value,
            deviceTypeId: values.deviceType.value,
            unit: values.unit,
            files: file,
          };

          dispatch(onUpdateDevice(updateDevice));
        } else {
          if (values.unit) {
            values.unit.forEach((x) => {
              delete x.label;
              delete x.value;
            });
          }
          const updateDevice = {
            id: device ? device.id : 0,
            code: values.code,
            name: values.name,
            identifier: values.identifier,
            identifier2: values.identifier2,
            phone: values.phone,
            image: values.image,
            longitude: values.longitude,
            latitude: values.latitude,
            connection_type: values.connection_type.value,
            isRetranslator: resCheck,
            retranslator: {
              connection_type: connection,
              host: values.host,
              port: values.port,
              username: values.username,
              password: values.password,
              identifier: values.reIdentifier,
              clientId: values.mqtt_clientId || "",
              publishTopic: values.publishTopic,
              subscribeTopic: values.subscribeTopic,
            },
            imei: values.imei,
            description: values.description,
            // status: values.statusDevice,
            companyId: companyId ? companyId : values.company.value,
            branchId: values.branch.value,
            serviceId: values.service.value,
            statusId: values.status.value,
            clientId: clientId ? clientId : values.client.value,
            deviceTypeId: values.deviceType.value,
            unit: values.unit,
            files: file,
          };

          dispatch(onUpdateDevice(updateDevice));
        }
      } else {
        if (connection === "mqtt") {
          if (values.unit) {
            values.unit.forEach((x) => {
              delete x.label;
              delete x.value;
            });
          }
          const createDevice = {
            code: values.code,
            name: values.name,
            identifier: values.identifier,
            identifier2: values.identifier2,
            phone: values.phone,
            image: values.image,
            longitude: values.longitude,
            latitude: values.latitude,
            connection_type: values.connection_type.value,
            isRetranslator: resCheck,
            retranslator: {
              connection_type: connection,
              host: values.host,
              port: values.port,
              username: values.username,
              password: values.password,
              identifier: values.reIdentifier,
              clientId: values.mqtt_clientId || "",
              publishTopic: values.publishTopic,
              subscribeTopic: values.subscribeTopic,
            },
            imei: values.imei,
            description: values.description,
            // status: values.statusDevice,
            companyId: companyId ? companyId : values.company.value,
            branchId: values.branch.value,
            serviceId: values.service.value,
            statusId: values.status.value,
            clientId: clientId ? clientId : values.client.value,
            deviceTypeId: values.deviceType.value,
            unit: values.unit,
            files: file,
          };
          dispatch(onAddNewDevice(createDevice));
        } else {
          if (values.unit) {
            values.unit.forEach((x) => {
              delete x.label;
              delete x.value;
            });
          }
          const createDevice = {
            code: values.code,
            name: values.name,
            identifier: values.identifier,
            identifier2: values.identifier2,
            phone: values.phone,
            image: values.image,
            longitude: values.longitude,
            latitude: values.latitude,
            connection_type: values.connection_type.value,
            isRetranslator: resCheck,
            retranslator: {
              connection_type: connection,
              host: values.host,
              port: values.port,
              username: values.username,
              password: values.password,
              identifier: values.reIdentifier,
              clientId: values.mqtt_clientId || "",
              publishTopic: values.publishTopic,
              subscribeTopic: values.subscribeTopic,
            },
            imei: values.imei,
            description: values.description,
            // status: values.statusDevice,
            companyId: companyId ? companyId : values.company.value,
            branchId: values.branch.value,
            serviceId: values.service.value,
            statusId: values.status.value,
            clientId: clientId ? clientId : values.client.value,
            deviceTypeId: values.deviceType.value,
            unit: values.unit,
            files: file,
          };
          dispatch(onAddNewDevice(createDevice));
        }
      }
      validation.resetForm();
      toggle();
    },
  });
  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteDevice(element.value));
    });
    checkall.checked = false;
  };

  const submitFile = () => {
    if (!isClick) {
      for (const a of fileNameItem) {
        if (!a.isPrimary) {
          const data = fileNameItem.slice(-1);
          data[0].isPrimary = true;
        }
      }
    }
  };

  const handleSelectFile = (file) => {
    if (Array.isArray(file)) {
      setFile(file)
    } else {
      switch (mediaType) {
        case "device":
          validation.setFieldValue("files", file.filename);
          break;
        case "image":
          validation.setFieldValue("image", file.filename);
          break;
        default:
          break;
      }
    }

    toggleMedia();
  };

  useEffect(() => {
    if (validation.values.files) {
      setFile((file) => [...file, { filename: validation.values.files }]);
    }
  }, [validation.values.files]);

  const handleDeleteFiles = useCallback(
    (files) => {
      // const object = file.findIndex((obj) => obj === files);
      if (file) {
        file.splice(file.indexOf(files), 1);
        setFile(file);
        forceUpdate();
      }

      // if (object > -1) {
      //   file.splice(object, 1);
      // }
    },
    [file, forceUpdate]
  );

  const handleDeleteImages = useCallback(
    (files) => {
      let a = "";
      // const object = file.findIndex((obj) => obj === files);
      if (validation.values.image) {
        validation.setFieldValue("image", a);
        forceUpdate();
      }

      // if (object > -1) {
      //   file.splice(object, 1);
      // }
    },
    [validation, forceUpdate]
  );

  useEffect(() => {
    setPIndex(pageIndex);
  }, [pageIndex]);

  document.title = `${t("Devices")} | TELKOMSEL - IOT Monitoring`;

  return (
    <div className="page-content">
      <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={deviceList}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDevice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={t("Devices")} pageTitle={t("Master Data")} />
        <Row>
          <Col xxl={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    {t("Devices History")} &nbsp;
                    {loading ? (
                      <>
                        <Spinner
                          color="primary"
                          type="grow"
                          size={"sm"}
                        ></Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      id="create-btn"
                      onClick={() => {
                        setDevice("");
                        setIsEdit(false);
                        toggle();
                      }}
                    >
                      <i className="ri-hard-drive-2-line me-1 align-bottom fs-14"></i>{" "}
                      {t("Add")}
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => setIsExportCSV(true)}
                    >
                      <i className="ri-file-download-line me-1 align-bottom fs-14"></i>{" "}
                      {t("Export")}
                    </button>{" "}
                    <button
                      className="btn btn-soft-danger"
                      onClick={() => setDeleteModalMulti(true)}
                    >
                      <i className="ri-delete-bin-5-line me-1 align-bottom fs-15"></i>{" "}
                      {t("Delete")}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "all");
                        }}
                        href="#"
                      >
                        <i className="ri-hard-drive-2-line me-1 align-bottom fs-14"></i>
                        {t("All Devices")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("2", "Draft");
                        }}
                        href="#"
                      >
                        <i className="ri-draft-line me-1 align-bottom fs-14"></i>
                        {t("Draft")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "3" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("3", "Activated");
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i>
                        {t("Activated")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "4" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("4", "Deactivated");
                        }}
                        href="#"
                      >
                        <i className="ri-indeterminate-circle-line me-1 align-bottom fs-14"></i>
                        {t("Deactivated")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-1">
                            <div className="me-2 mb-2 col-12">
                              <select
                                className="form-select"
                                value={pageSize}
                                onChange={onChangeInSelect}
                              >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} ${t("Search")}...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={6} className="col-xxl-3">
                            <div className="search-box me-2 mb-2 col-12">
                              <Flatpickr
                                className="form-control"
                                id="datepicker-publish-input"
                                placeholder={t("Select created date")}
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                }}
                                onChange={(obj, str) => {
                                  setFilterDate(str);
                                }}
                              />
                              <i className="bx bx-calendar-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col sm={4} className="col-xxl-2">
                            <div className="me-2 mb-2 col-12">
                              <Select
                                value={statusActive}
                                placeholder={t("Select Status")}
                                onChange={(e) => {
                                  setStatusActive(e);
                                }}
                                options={statusActiveData}
                                name="choices-single-default"
                                id="idStatus"
                              ></Select>
                            </div>
                          </Col>
                          <Col className="col-xxl-2">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i>{" "}
                                {t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table
                      hover
                      {...getTableProps()}
                      className="align-middle table-nowrap"
                    >
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr
                            className={""}
                            key={headerGroup.id}
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map((column) => (
                              <th
                                key={column.id}
                                className={"" + generateFilterable(column)}
                                {...column.getSortByToggleProps()}
                              >
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button
                          className="btn btn-light"
                          onClick={previousPage}
                          disabled={!canPreviousPage}
                        >
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      {`${t("Page")} `}
                      <strong>
                        {pageIndex + 1} {t("of")} {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input
                        type="number"
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        value={pIndex + 1}
                        onChange={onChangeInInput}
                      />
                    </Col>

                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button
                          className="btn btn-light"
                          onClick={nextPage}
                          disabled={!canNextPage}
                        >
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered size="xl">
                  <ModalHeader className=" p-3" toggle={toggle}>
                    {!!isEdit
                      ? `${t("Edit")}  ${t("Device")}`
                      : `${t("Add")}  ${t("Device")}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      submitFile();
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody className="bg-light">
                      <ul
                        className="nav nav-pills nav-justified"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="true"
                          >
                            Device Profile
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="file-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#file"
                            type="button"
                            role="tab"
                            aria-controls="status"
                            aria-selected="false"
                          >
                            Files
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                          tabIndex="1"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <Card>
                                <CardHeader className="fw-medium">
                                  Device Profile
                                </CardHeader>
                                <CardBody>
                                  <Row>
                                    <Col lg={6}>
                                      {userType === "admin" ||
                                        userType === "user" ? (
                                        <>
                                          <div className="mb-3">
                                            <Label
                                              htmlFor="password-field"
                                              className="form-label"
                                            >
                                              {t("Client")}{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </Label>
                                            <Select
                                              name="client"
                                              value={validation.values.client}
                                              aria-invalid={
                                                validation.touched.client
                                              }
                                              aria-errormessage={
                                                validation.errors.client
                                              }
                                              placeholder={`${t("Select")} ${t(
                                                "Client"
                                              )}`}
                                              onChange={(e) => {
                                                validation.setFieldValue(
                                                  "client",
                                                  e
                                                );
                                                handleCompany(e);
                                              }}
                                              options={clientList}
                                            ></Select>
                                            {validation.touched.client && (
                                              <p
                                                role="alert"
                                                style={{
                                                  color: "#f06548",
                                                  fontSize: "11px",
                                                }}
                                              >
                                                {validation.errors.client}
                                              </p>
                                            )}
                                          </div>
                                          <div className="mb-3">
                                            <Label
                                              htmlFor="password-field"
                                              className="form-label"
                                            >
                                              {t("Company")}{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </Label>
                                            {companiesLoading ? (
                                              <>
                                                <Spinner
                                                  color="primary"
                                                  type="grow"
                                                  size={"sm"}
                                                  className="ms-2"
                                                ></Spinner>
                                                &nbsp;
                                                <Spinner
                                                  color="success"
                                                  type="grow"
                                                  size={"sm"}
                                                >
                                                  Loading...
                                                </Spinner>
                                                &nbsp;
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            <Select
                                              name="company"
                                              value={validation.values.company}
                                              aria-invalid={
                                                validation.touched.company
                                              }
                                              aria-errormessage={
                                                validation.errors.company
                                              }
                                              placeholder={`${t("Select")} ${t(
                                                "Company"
                                              )}`}
                                              onChange={(e) => {
                                                validation.setFieldValue(
                                                  "company",
                                                  e
                                                );
                                                handleBranch(e);
                                              }}
                                              options={companyList}
                                            ></Select>
                                            {validation.touched.company && (
                                              <p
                                                role="alert"
                                                style={{
                                                  color: "#f06548",
                                                  fontSize: "11px",
                                                }}
                                              >
                                                {validation.errors.company}
                                              </p>
                                            )}
                                          </div>
                                        </>
                                      ) : userType === "client" ? (
                                        <>
                                          <div className="mb-3">
                                            <Label
                                              htmlFor="password-field"
                                              className="form-label"
                                            >
                                              {t("Company")}{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </Label>
                                            {companiesLoading ? (
                                              <>
                                                <Spinner
                                                  color="primary"
                                                  type="grow"
                                                  size={"sm"}
                                                  className="ms-2"
                                                ></Spinner>
                                                &nbsp;
                                                <Spinner
                                                  color="success"
                                                  type="grow"
                                                  size={"sm"}
                                                >
                                                  Loading...
                                                </Spinner>
                                                &nbsp;
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            <Select
                                              name="company"
                                              value={validation.values.company}
                                              aria-invalid={
                                                validation.touched.company
                                              }
                                              aria-errormessage={
                                                validation.errors.company
                                              }
                                              placeholder={`${t("Select")} ${t(
                                                "Company"
                                              )}`}
                                              onChange={(e) => {
                                                validation.setFieldValue(
                                                  "company",
                                                  e
                                                );
                                                handleBranch(e);
                                              }}
                                              options={companyList}
                                            ></Select>
                                            {validation.touched.company && (
                                              <p
                                                role="alert"
                                                style={{
                                                  color: "#f06548",
                                                  fontSize: "11px",
                                                }}
                                              >
                                                {validation.errors.company}
                                              </p>
                                            )}
                                          </div>
                                        </>
                                      ) : null}
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="password-field"
                                          className="form-label"
                                        >
                                          {t("Branch")}{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        {branchesLoading ? (
                                          <>
                                            <Spinner
                                              color="primary"
                                              type="grow"
                                              size={"sm"}
                                              className="ms-2"
                                            ></Spinner>
                                            &nbsp;
                                            <Spinner
                                              color="success"
                                              type="grow"
                                              size={"sm"}
                                            >
                                              Loading...
                                            </Spinner>
                                            &nbsp;
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <Select
                                          name="branch"
                                          value={validation.values.branch}
                                          aria-invalid={
                                            validation.touched.branch
                                          }
                                          aria-errormessage={
                                            validation.errors.branch
                                          }
                                          placeholder={`${t("Select")} ${t(
                                            "Branch"
                                          )}`}
                                          onChange={(e) => {
                                            validation.setFieldValue(
                                              "branch",
                                              e
                                            );
                                            handleUnit(e);
                                          }}
                                          options={branchList}
                                        ></Select>
                                        {validation.touched.branch && (
                                          <p
                                            role="alert"
                                            style={{
                                              color: "#f06548",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {validation.errors.branch}
                                          </p>
                                        )}
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="password-field"
                                          className="form-label"
                                        >
                                          {t("Unit")}{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        {unitLoading ? (
                                          <>
                                            <Spinner
                                              color="primary"
                                              type="grow"
                                              size={"sm"}
                                              className="ms-2"
                                            >
                                              Loading...
                                            </Spinner>
                                            &nbsp;
                                            <Spinner
                                              color="success"
                                              type="grow"
                                              size={"sm"}
                                            >
                                              Loading...
                                            </Spinner>
                                            &nbsp;
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <Select
                                          name="unit"
                                          value={validation.values.unit}
                                          aria-invalid={validation.touched.unit}
                                          aria-errormessage={
                                            validation.errors.unit
                                          }
                                          placeholder={`${t("Select")} ${t(
                                            "Unit"
                                          )}`}
                                          isMulti
                                          onChange={(opt, { option }) => {
                                            let newOpts = opt;
                                            if (
                                              option &&
                                              option.value === "all"
                                            ) {
                                              let filteredOptions =
                                                clone(unitList);
                                              filteredOptions =
                                                filteredOptions[0].options.filter(
                                                  (filteredOption) =>
                                                    !newOpts.includes(
                                                      filteredOption
                                                    )
                                                );
                                              newOpts = newOpts
                                                .concat(filteredOptions)
                                                .filter(
                                                  (newOpt) =>
                                                    newOpt.value !== "all"
                                                );
                                            }
                                            validation.setFieldValue(
                                              "unit",
                                              newOpts
                                            );
                                          }}
                                          options={unitList}
                                        ></Select>
                                        {validation.touched.unit && (
                                          <p
                                            role="alert"
                                            style={{
                                              color: "#f06548",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {validation.errors.unit}
                                          </p>
                                        )}
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="service-field"
                                          className="form-label"
                                        >
                                          {t("Service")}{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        {serviceLoading ? (
                                          <>
                                            <Spinner
                                              color="primary"
                                              type="grow"
                                              size={"sm"}
                                              className="ms-2"
                                            >
                                              Loading...
                                            </Spinner>
                                            &nbsp;
                                            <Spinner
                                              color="success"
                                              type="grow"
                                              size={"sm"}
                                            >
                                              Loading...
                                            </Spinner>
                                            &nbsp;
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <Select
                                          name="service"
                                          value={validation.values.service}
                                          aria-invalid={
                                            validation.touched.service
                                          }
                                          aria-errormessage={
                                            validation.errors.service
                                          }
                                          placeholder={`${t("Select")} ${t(
                                            "Service"
                                          )}`}
                                          onChange={(e) => {
                                            validation.setFieldValue(
                                              "service",
                                              e
                                            );
                                          }}
                                          options={serviceList}
                                        ></Select>
                                        {validation.touched.service && (
                                          <p
                                            role="alert"
                                            style={{
                                              color: "#f06548",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {validation.errors.service}
                                          </p>
                                        )}
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="password-field"
                                          className="form-label"
                                        >
                                          {t("Device Type")}{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                          name="deviceType"
                                          value={validation.values.deviceType}
                                          placeholder={`${t("Select")} ${t(
                                            "Device Type"
                                          )}`}
                                          aria-invalid={
                                            validation.touched.deviceType
                                          }
                                          aria-errormessage={
                                            validation.errors.deviceType
                                          }
                                          onChange={(e) => {
                                            validation.setFieldValue(
                                              "deviceType",
                                              e
                                            );
                                          }}
                                          options={deviceTypeList}
                                        ></Select>
                                        {validation.touched.deviceType && (
                                          <p
                                            role="alert"
                                            style={{
                                              color: "#f06548",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {validation.errors.deviceType}
                                          </p>
                                        )}
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="devicename-field"
                                          className="form-label"
                                        >
                                          {t("Name")}{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                          name="name"
                                          id="devicename-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Name"
                                          )}`}
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.name || ""}
                                          invalid={
                                            validation.touched.name &&
                                              validation.errors.name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.name &&
                                          validation.errors.name ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.name}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="devicename-field"
                                          className="form-label"
                                        >
                                          {t("Code")}
                                        </Label>
                                        <Input
                                          name="code"
                                          id="code-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Code"
                                          )}`}
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.code || ""}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="imei-field"
                                          className="form-label"
                                        >
                                          Imei / Serial Number
                                        </Label>
                                        <Input
                                          name="imei"
                                          id="imei-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Imei"
                                          )}`}
                                          type="text"
                                          value={validation.values.imei || ""}
                                          onChange={validation.handleChange}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="phone_number-field"
                                          className="form-label"
                                        >
                                          {t("Phone Number")}
                                        </Label>
                                        <Input
                                          name="phone"
                                          id="phone-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Phone Number"
                                          )}`}
                                          type="text"
                                          value={validation.values.phone}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                        />
                                      </div>
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="mb-3">
                                            <Label
                                              htmlFor="latitude-field"
                                              className="form-label"
                                            >
                                              {t("Latitude")}
                                            </Label>
                                            <Input
                                              name="latitude"
                                              id="latitude-field"
                                              className="form-control"
                                              placeholder={`${t("Enter")} ${t(
                                                "Latitude"
                                              )}`}
                                              type="text"
                                              value={validation.values.latitude}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="mb-3">
                                            <Label
                                              htmlFor="longitude-field"
                                              className="form-label"
                                            >
                                              {t("Longitude")}
                                            </Label>
                                            <Input
                                              name="longitude"
                                              id="longitude-field"
                                              className="form-control"
                                              placeholder={`${t("Enter")} ${t(
                                                "Longitude"
                                              )}`}
                                              type="text"
                                              value={
                                                validation.values.longitude
                                              }
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="status-field"
                                          className="form-label"
                                        >
                                          Status{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                          name="status"
                                          value={validation.values.status}
                                          aria-invalid={
                                            validation.touched.status
                                          }
                                          aria-errormessage={
                                            validation.errors.status
                                          }
                                          validate={{
                                            required: { value: true },
                                          }}
                                          placeholder={`${t("Select")} ${t(
                                            "Status"
                                          )}`}
                                          onChange={(e) => {
                                            validation.setFieldValue(
                                              "status",
                                              e
                                            );
                                          }}
                                          options={statusActiveData}
                                          invalid={
                                            validation.touched.status &&
                                              validation.errors.status
                                              ? true
                                              : false
                                          }
                                        ></Select>
                                        {validation.touched.status && (
                                          <p
                                            role="alert"
                                            style={{
                                              color: "#f06548",
                                              fontSize: "11px",
                                            }}
                                          >
                                            {validation.errors.status}
                                          </p>
                                        )}
                                      </div>
                                      {/* <div className='mb-3'>
                                        <Label
                                          htmlFor='devicename-field'
                                          className='form-label'>
                                          {`${t("Status")} ${t("Device")}`}
                                        </Label>
                                        <Input
                                          name='statusDevice'
                                          id='devicestatus-field'
                                          className='form-control'
                                          placeholder={`${t("Enter")} ${t(
                                            "Status"
                                          )} ${t("Device")}`}
                                          type='text'
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.statusDevice || ""
                                          }
                                          invalid={
                                            validation.touched.statusDevice && validation.errors.statusDevice
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.statusDevice && validation.errors.statusDevice ? (
                                          <FormFeedback type='invalid'>
                                            {validation.errors.statusDevice}
                                          </FormFeedback>
                                        ) : null}
                                      </div> */}
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <Card>
                                <CardHeader className="fw-medium">
                                  Connection
                                </CardHeader>
                                <CardBody>
                                  <div className="row">
                                    <div className="col-6">
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="identifier-field"
                                          className="form-label"
                                        >
                                          {t("Identifier")}
                                        </Label>
                                        <Input
                                          name="identifier"
                                          id="identifier-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Identifier"
                                          )}`}
                                          type="text"
                                          value={validation.values.identifier || ""}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          invalid={
                                            validation.touched.identifier &&
                                              validation.errors.identifier
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.identifier &&
                                          validation.errors.identifier ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.identifier}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="identifier-field"
                                          className="form-label"
                                        >
                                          {t("Identifier")}
                                        </Label>
                                        <Input
                                          name="identifier2"
                                          id="identifier-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Identifier2"
                                          )}`}
                                          type="text"
                                          value={validation.values.identifier2 || ""}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="connection-field"
                                      className="form-label"
                                    >
                                      {t("Connection Type")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                      name="connection_type"
                                      value={validation.values.connection_type}
                                      aria-invalid={
                                        validation.touched.connection_type
                                      }
                                      aria-errormessage={
                                        validation.errors.connection_type
                                      }
                                      placeholder={`${t("Select")} ${t(
                                        "Connection Type"
                                      )}`}
                                      onChange={(e) => {
                                        validation.setFieldValue(
                                          "connection_type",
                                          e
                                        );
                                      }}
                                      options={connectionTypeData}
                                    ></Select>
                                    {validation.touched.connection_type && (
                                      <p
                                        role="alert"
                                        style={{
                                          color: "#f06548",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {validation.errors.connection_type}
                                      </p>
                                    )}
                                  </div>
                                  <div className="mb-0">
                                    <label htmlFor="checkbox">
                                      Retranslator
                                    </label>
                                    <input
                                      type="checkbox"
                                      id="checkbox"
                                      checked={resCheck}
                                      onChange={resCheckHandler}
                                      className="ms-2"
                                    />
                                  </div>
                                  {resCheck ? (
                                    <div className="mb-3">
                                      <Input
                                        name="type"
                                        type="select"
                                        className="form-select"
                                        onBlur={validation.handleBlur}
                                        value={connection}
                                        onClick={(e) =>
                                          setConnection(e.target.value)
                                        }
                                      >
                                        {types.map((item, key) => (
                                          <React.Fragment key={key}>
                                            {item.options.map((item, key) => (
                                              <option
                                                value={item.value}
                                                key={key}
                                              >
                                                {item.label}
                                              </option>
                                            ))}
                                          </React.Fragment>
                                        ))}
                                      </Input>
                                    </div>
                                  ) : null}
                                  {resCheck ? (
                                    <>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="username-field"
                                          className="form-label"
                                        >
                                          {t("Username")}
                                        </Label>
                                        <Input
                                          name="username"
                                          id="username-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Username"
                                          )}`}
                                          type="text"
                                          value={
                                            validation.values.username || ""
                                          }
                                          onChange={validation.handleChange}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="password-field"
                                          className="form-label"
                                        >
                                          {t("Password")}
                                        </Label>
                                        <Input
                                          name="password"
                                          id="password-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Password"
                                          )}`}
                                          type="password"
                                          value={
                                            validation.values.password || ""
                                          }
                                          onChange={validation.handleChange}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="reIdentifier-field"
                                          className="form-label"
                                        >
                                          {t("Identifier")}
                                        </Label>
                                        <Input
                                          name="reIdentifier"
                                          id="reIdentifier-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Identifier"
                                          )}`}
                                          type="text"
                                          value={
                                            validation.values.reIdentifier || ""
                                          }
                                          onChange={validation.handleChange}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="host-field"
                                          className="form-label"
                                        >
                                          {" "}
                                          Host
                                        </Label>
                                        <Input
                                          name="host"
                                          id="host-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Host"
                                          )}`}
                                          type="text"
                                          value={validation.values.host}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="port-field"
                                          className="form-label"
                                        >
                                          Port
                                        </Label>
                                        <Input
                                          name="port"
                                          id="port-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Port"
                                          )}`}
                                          type="number"
                                          value={validation.values.port || ""}
                                          onChange={validation.handleChange}
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                  {resCheck && connection === "mqtt" ? (
                                    <div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="mqtt_clientId-field"
                                          className="form-label"
                                        >
                                          {t("Client Id")}
                                        </Label>
                                        <Input
                                          name="mqtt_clientId"
                                          id="mqtt_clientId-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Client Id"
                                          )}`}
                                          type="text"
                                          value={
                                            validation.values.mqtt_clientId ||
                                            ""
                                          }
                                          onChange={validation.handleChange}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="publishTopic-field"
                                          className="form-label"
                                        >
                                          {t("Publish Topic")}
                                        </Label>
                                        <Input
                                          name="publishTopic"
                                          id="publishTopic-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Publish Topic"
                                          )}`}
                                          type="text"
                                          value={
                                            validation.values.publishTopic || ""
                                          }
                                          onChange={validation.handleChange}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label
                                          htmlFor="subscribeTopic-field"
                                          className="form-label"
                                        >
                                          {t("Subscribe Topic")}
                                        </Label>
                                        <Input
                                          name="subscribeTopic"
                                          id="subscribeTopic-field"
                                          className="form-control"
                                          placeholder={`${t("Enter")} ${t(
                                            "Subscribe Topic"
                                          )}`}
                                          type="text"
                                          value={
                                            validation.values.subscribeTopic ||
                                            ""
                                          }
                                          onChange={validation.handleChange}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="file"
                          role="tabpanel"
                          aria-labelledby="file-tab"
                          tabIndex="2"
                        >
                          <div className="row">
                            <div className="col-12">
                              <Card>
                                <CardHeader className=" fw-medium">
                                  Files
                                </CardHeader>
                                <CardBody>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="education"
                                      className="form-label"
                                    >
                                      {t("Device Image")}
                                    </Label>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-label btn-soft-success"
                                      onClick={() => {
                                        setMediaType("image");
                                        toggleMedia();
                                      }}
                                    >
                                      <i className="ri-file-cloud-fill label-icon align-middle fs-16 me-1"></i>{" "}
                                      Media
                                    </button>
                                  </div>
                                  {validation.values.image !== "" ? (
                                    <div className="border-bottom mb-3">
                                      <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div className="me-2">
                                          <img
                                            src={
                                              !validation.values.image
                                                ? logo
                                                : `${api.MEDIA_URL}/media/${validation.values.image}`
                                            }
                                            alt=""
                                            data-dz-thumbnail=""
                                            height="30"
                                            className="avatar-sm rounded bg-light"
                                          />
                                        </div>

                                        <div className="text-truncate">
                                          {validation.values.image}
                                        </div>
                                        <div>
                                          <Link
                                            to="#"
                                            className={
                                              "btn-icon btn btn-sm btn-soft-danger remove-item-btn mx-2"
                                            }
                                            onClick={(e) => {
                                              // e.stopPropagation();
                                              // handleDeleteFile(sFile.id);
                                              handleDeleteImages(
                                                validation.values.image
                                              );
                                            }}
                                          >
                                            <i className=" ri-delete-bin-line fs-15"></i>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="education"
                                      className="form-label"
                                    >
                                      {t("Device File")}
                                    </Label>
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-label btn-soft-success"
                                      onClick={() => {
                                        setMediaType("device");
                                        toggleMedia();
                                      }}
                                    >
                                      <i className="ri-file-cloud-fill label-icon align-middle fs-16 me-1"></i>{" "}
                                      Media
                                    </button>
                                  </div>
                                  {file &&
                                    file.map((files, i) => (
                                      <div
                                        className="border-bottom mb-3"
                                        key={i}
                                      >
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                          <div className="me-2">
                                            <img
                                              src={
                                                files.filename === ""
                                                  ? logo
                                                  : `${api.MEDIA_URL}/media/${files.filename}`
                                              }
                                              alt=""
                                              data-dz-thumbnail=""
                                              height="30"
                                              className="avatar-sm rounded bg-light"
                                            />
                                          </div>
                                          <div className="text-truncate">
                                            {files.filename}
                                          </div>
                                          <div>
                                            <Link
                                              to="#"
                                              className={
                                                "btn-icon btn btn-sm btn-soft-danger remove-item-btn mx-2"
                                              }
                                              onClick={(e) => {
                                                // e.stopPropagation();
                                                // handleDeleteFile(sFile.id);
                                                handleDeleteFiles(files);
                                              }}
                                            >
                                              <i className=" ri-delete-bin-line fs-15"></i>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                      removePlugins: [
                                        "EasyImage",
                                        "ImageUpload",
                                        "MediaEmbed",
                                      ],
                                    }}
                                    data={validation.values.description}
                                    onReady={(editor) => {
                                      editor.editing.view.change((writer) => {
                                        writer.setStyle(
                                          "height",
                                          "194px",
                                          editor.editing.view.document.getRoot()
                                        );
                                      });
                                    }}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      validation.setFieldValue(
                                        "description",
                                        data
                                      );
                                    }}
                                  />
                                </CardBody>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end mt-3">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModal(false);
                            validation.resetForm();
                            setFile([]);
                            forceUpdate();
                            setResCheck(false);
                          }}
                        >
                          {t("Close")}
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">
                              {!!isEdit
                                ? `${t("Update")}  ${t("Device")}`
                                : `${t("Add")}  ${t("Device")}`}
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ModalInputFile
                  onSelect={handleSelectFile}
                  loading={loading}
                  modal={modalMedia}
                  toggle={toggleMedia}
                  t={t}
                />
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Device.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Device));
