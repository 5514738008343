import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Row,
  Col,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  Label,
} from "reactstrap";
import Select from "react-select";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isEmpty, clone } from "lodash";
import Dropzone from "react-dropzone";
import DeleteModal from "../Components/Common/DeleteModal";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  deleteStorageFolder,
  getStorageFolder,
  resetStorageFolderState,
} from "../store/storageFolder/action";
import {
  addNewStorageFile,
  deleteStorageFile,
  getStorageFile,
  resetStorageFileState,
} from "../store/storageFiles/action";
import {
  addNewStorageMove,
  updateStorageMove,
} from "../store/storageMove/action";
import {
  addNewStorageRename,
  updateStorageRename,
} from "../store/storageRename/action";
import { api } from "../config";

const ModalInputFile = ({ modal, toggle, onSelect, type }) => {
  const [storageFolderList, setStorageFolderList] = useState([]);
  const [storageFileList, setStorageFileList] = useState([]);
  const dispatch = useDispatch();
  const [folderKlik, setFolderKlik] = useState(false);
  const [folder, setFolder] = useState(null);
  const [file, setFile] = useState(null);
  const [modalFolder, setModalFolder] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [folderList, setFolderList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [valueKlik, setValueKlik] = useState();
  const [isMove, setIsMove] = useState(false);
  const [multiple, setMultiple] = useState([])
  const {
    storageFolders,
    storageFolder,
    isStorageFolderCreated,
    isStorageFolderSuccess,
    loadingFolder,
    errorFile,
    storageFiles,
    loadingFile,
    isStorageMoveCreated,
    isStorageRenameCreated,
  } = useSelector((state) => ({
    storageFolders: state.StorageFolder.storageFolders,
    storageFolder: state.StorageFolder.storageFolder,
    isStorageFolderCreated: state.StorageFolder.isStorageFolderCreated,
    isStorageFolderSuccess: state.StorageFolder.isStorageFolderSuccess,
    loadingFolder: state.StorageFolder.loading,
    errorFile: state.StorageFolder.error,
    storageFiles: state.StorageFile.storageFiles,
    loadingFile: state.StorageFile.loading,
    isStorageMoveCreated: state.StorageMove.isStorageMoveCreated,
    isStorageRenameCreated: state.StorageRename.isStorageRenameCreated,
  }));

  const onClickDelete = (folder) => {
    setFolder(folder);
    setDeleteModal(true);
  };

  const handleDeleteFolder = () => {
    if (folder) {
      dispatch(deleteStorageFolder(folder));
      setDeleteModal(false);
    }
  };

  const folderToggle = useCallback(() => {
    if (modalFolder) {
      setModalFolder(false);
      setFolder(null);
    } else {
      setModalFolder(true);
    }
  }, [modalFolder]);

  const fileToggle = useCallback(() => {
    if (modalFile) {
      setModalFile(false);
      setFile(null);
    } else {
      setModalFile(true);
    }
  }, [modalFile]);

  const handleFolderClick = useCallback(
    (arg, type) => {
      const folder = arg;
      console.log(arg);

      if (type === "folder") {
        setFolder({
          id: folder.id,
          name: folder.name,
          type: type,
        });
      } else {
        setFolder({
          id: folder.id,
          name: folder.originalname,
          type: type,
        });
      }

      setIsEdit(true);
      folderToggle();
    },
    [folderToggle]
  );

  const handleFileClick = useCallback(
    (arg) => {
      const file = arg;
      console.log(arg);
      let company = "Not set";
      if (file.company_name) {
        company = file.company_name;
      }
      setFile({
        id: file.id,
        fileId: file.id,
        folder: {
          label: file.file_folder.name + " - " + company,
          value: file.folderId,
        },
      });
      setIsEdit(true);
      fileToggle();
    },
    [fileToggle]
  );

  const folderValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (folder && folder.name) || "",
      type: (folder && folder.type) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Folder Name"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateFolder = {
          id: folder ? folder.id : 0,
          name: values.name,
          type: values.type,
        };
        // save edit Folder
        console.log("update", updateFolder);
        dispatch(updateStorageRename(updateFolder));
        setIsMove(true);
        folderValidation.resetForm();
      } else {
        const newFolder = {
          name: values.name,
          type: values.type,
        };
        // save new Folder
        console.log(newFolder);
        dispatch(addNewStorageRename(newFolder));
        setIsMove(true);
        folderValidation.resetForm();
      }
      folderToggle();
    },
  });

  const fileValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fileId: (file && file.fileId) || "",
      folder: (file && file.folder) || "",
    },
    validationSchema: Yup.object({
      fileId: Yup.number().required("Please Enter File Id"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateFile = {
          id: file ? file.fileId : 0,
          fileId: values.fileId,
          folderId: values.folder.value,
        };
        console.log(updateFile);
        dispatch(updateStorageMove(updateFile));
        setIsMove(true);
        fileValidation.resetForm();
      } else {
        const newFile = {
          fileId: values.fileId,
          folderId: values.folder.value,
        };
        console.log(newFile);
        dispatch(addNewStorageMove(newFile));
        setIsMove(true);
        fileValidation.resetForm();
      }
      fileToggle();
    },
  });

  const handleUpload = (files) => {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));
    formData.append("clientId", authUser.data.clientId);

    dispatch(addNewStorageFile(formData));
  };

  const handleDeleteFile = (id) => {
    dispatch(deleteStorageFile(id));
  };

  const handleSearchFolder = (value) => {
    const filteredBooks = storageFolders.filter(({ name }) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
    setStorageFolderList(filteredBooks);
  };

  const handleSearchFiles = (value) => {
    const filteredBooks = storageFiles.filter(({ originalname }) =>
      originalname.toLowerCase().includes(value.toLowerCase())
    );
    setStorageFileList(filteredBooks);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    if (modal) {
      dispatch(resetStorageFolderState());
      dispatch(getStorageFolder({ status: 2 }));
    }
  }, [modal, dispatch]);

  useEffect(() => {
    if (isMove) {
      dispatch(resetStorageFileState());
      if (type) {
        dispatch(getStorageFile({ folderId: valueKlik, type: type }));
        dispatch(resetStorageFolderState());
        dispatch(getStorageFolder({ status: 2 }));
        setIsMove(false);
      } else {
        dispatch(getStorageFile({ folderId: valueKlik }));
        dispatch(resetStorageFolderState());
        dispatch(getStorageFolder({ status: 2 }));
        setIsMove(false);
      }
    }
  }, [isMove, dispatch, valueKlik, type]);

  useEffect(() => {
    if (!isEmpty(storageFolders)) {
      var data = [];
      var opt = [];
      storageFolders.forEach((item, key) => {
        const name = item.name;
        const id = item.id;
        var obj = { label: name + " - " + item.company_name, value: id };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setFolderList(data);
    }
  }, [storageFolders]);

  const handleKlikFolder = (data) => {
    setFolderKlik(true);
    dispatch(resetStorageFileState());
    setValueKlik(data);
    if (type) {
      dispatch(getStorageFile({ folderId: data, type: type }));
    } else {
      dispatch(getStorageFile({ folderId: data }));
    }
  };

  useEffect(() => {
    setStorageFolderList(storageFolders);
  }, [storageFolders]);

  useEffect(() => {
    setStorageFileList(storageFiles);
  }, [storageFiles]);

  const renderIconImgList = (file) => {
    switch (file.mimetype) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return (
          <i
            className='ri-file-excel-line me-1 align-bottom text-success'
            style={{ fontSize: "45px" }}></i>
        );

      case "application/x-compressed":
        return (
          <i
            className='ri-file-zip-line me-1 align-bottom text-danger'
            style={{ fontSize: "45px" }}></i>
        );

      case "application/pdf":
        return (
          <i
            className='ri-file-pdf-line me-1 align-bottom text-danger'
            style={{ fontSize: "45px" }}></i>
        );

      default:
        return (
          <LazyLoadImage
            src={api.MEDIA_URL + "/media/" + file.filename + "-w-50"}
            height={50}
            width={50}
            alt=''
            className=' bg-light'
            effect='blur'
          />
        );
    }
  };

  const handleSaveMultiple = () => {
    let data = []
    storageFileList.toReversed().forEach((file) => {
      if (file.checked) {
        data.push({ filename: file.filename })
      }
    })
    onSelect(data)
  }

  return (
    <Modal id='showModalMedia' isOpen={modal} centered size='xl'>
      <ModalHeader
        className='bg-light p-3'
        toggle={() => {
          toggle();
          handleSearchFolder("");
          handleSearchFiles("");
          setFolderKlik(false);
        }}>
        <div className='d-flex'>
          <div className='flex-grow-1'>Media File</div>
          <div className='flex-shrink-0 ms-2'>
            {loadingFolder ? (
              <>
                <Spinner
                  color='primary'
                  type='grow'
                  size={"sm"}
                  className='ms-2'></Spinner>
                &nbsp;
                <Spinner color='success' type='grow' size={"sm"}>
                  Loading...
                </Spinner>
                &nbsp;
              </>
            ) : (
              ""
            )}
            {loadingFile ? (
              <>
                <Spinner
                  color='primary'
                  type='grow'
                  size={"sm"}
                  className='ms-2'></Spinner>
                &nbsp;
                <Spinner color='success' type='grow' size={"sm"}>
                  Loading...
                </Spinner>
                &nbsp;
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteFolder}
          onCloseClick={() => setDeleteModal(false)}
        />
        {!folderKlik ? (
          <div className='row'>
            <div className='col-lg-8'>
              <div>
                <div className='search-box me-2 mb-3 d-inline-block col-12'>
                  <input
                    onChange={(e) => {
                      handleSearchFolder(e.target.value);
                    }}
                    id='search-bar-0'
                    type='text'
                    className='form-control search'
                    placeholder={`${storageFolderList.length} Search...`}
                  />
                  <i className='bx bx-search-alt search-icon'></i>
                </div>
              </div>
              <div
                style={{
                  maxHeight: "700px",
                  height: "700px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}>
                <button
                  type='button'
                  className='btn btn-sm btn-success add-btn me-2 mb-3'
                  id='create-btn'
                  onClick={() => {
                    setModalFolder(!modalFolder);
                    setIsEdit(false);
                  }}>
                  <i className='ri-folder-line me-1 align-bottom fs-14'></i>{" "}
                  Tambah Folder
                </button>
                <Row id='folderlist-data'>
                  {(storageFolderList || []).map((item, key) => (
                    <Col xxl={4} className='col-6 folder-card' key={key}>
                      <Card
                        className='bg-light shadow-none'
                        id={"folder-" + item.id}>
                        <CardBody>
                          <div className='d-flex mb-1'>
                            <div className='form-check form-check-danger mb-3 fs-15 flex-grow-1'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id={"folderlistCheckbox_" + item.id}
                              />
                              <label
                                className='form-check-label'
                                htmlFor={
                                  "folderlistCheckbox_" + item.id
                                }></label>
                            </div>

                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag='button'
                                className='btn btn-ghost-primary btn-icon btn-sm dropdown'>
                                <i className='ri-more-2-fill fs-16 align-bottom' />
                              </DropdownToggle>
                              <DropdownMenu className='dropdown-menu-end'>
                                <DropdownItem
                                  className='view-item-btn'
                                  onClick={() => handleKlikFolder(item.id)}>
                                  Open
                                </DropdownItem>
                                <DropdownItem
                                  className='edit-folder-list'
                                  onClick={() => {
                                    handleFolderClick(item, "folder");
                                    setValueKlik(item.id);
                                  }}>
                                  Rename
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    onClickDelete(item);
                                  }}
                                  className='d-none'>
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>

                          <div
                            className='text-center'
                            style={{ cursor: "pointer" }}
                            onClick={() => handleKlikFolder(item.id)}>
                            <div className='mb-2'>
                              <i className='ri-folder-2-fill align-bottom text-warning display-5'></i>
                            </div>
                            <h6 className='fs-15 folder-name'>{item.name}</h6>
                          </div>
                          <div className='mt-4 text-muted'>
                            <div>
                              {item.company_name
                                ? item.company_name
                                : item.client_name}
                            </div>
                            <div className='hstack'>
                              <span className='me-auto'>
                                <b>
                                  {item.folder_file && item.folder_file.length}
                                </b>{" "}
                                Files
                              </span>
                              <span>
                                <b>{item.size}</b>GB
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='mb-3'>
                <Dropzone
                  multiple={true}
                  onDrop={(acceptedFiles) => {
                    handleUpload(acceptedFiles);
                  }}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className='dropzone dz-clickable'
                      style={{ cursor: "pointer", height: "140px" }}
                      {...getRootProps({ className: "dropzone" })}>
                      <div className='dz-message needsclick'>
                        <div>
                          <i className='display-4 text-muted ri-upload-cloud-2-fill' />
                          <input {...getInputProps()} />
                        </div>
                        <h5>Drop files or click here to upload.</h5>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
        ) : (
          <div className='row'>
            <div className='col-lg-8'>
              <div>
                <div className='search-box me-2 mb-3 d-inline-block col-12'>
                  <input
                    onChange={(e) => {
                      handleSearchFiles(e.target.value);
                    }}
                    id='search-bar-0'
                    type='text'
                    className='form-control search'
                    placeholder={`${storageFileList.length} Search...`}
                  />
                  <i className='bx bx-search-alt search-icon'></i>
                </div>
              </div>
              <div
                style={{
                  maxHeight: "700px",
                  height: "700px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}>
                {storageFileList.toReversed().map((sFile, i) => {
                  return (
                    <Card
                      style={{
                        cursor: "pointer",
                      }}
                      key={i}
                      className='mt-1 mb-0 me-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete pe-auto'>
                      <div className='px-3 mt-3'>
                        <div className='d-flex'>
                          <div className="form-check mt-3">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={sFile.checked} onChange={(e) => {
                              sFile.checked = e.target.checked
                            }} />
                          </div>
                          <div
                            className='flex-grow-1'
                            onClick={() => {
                              onSelect(sFile);
                              setFolderKlik(false);
                            }}>
                            <div className='d-flex mb-0'>
                              <div className='me-2'>
                                {renderIconImgList(sFile)}
                              </div>
                              <div>
                                <p className='mb-0'>
                                  <strong>{sFile.originalname}</strong>
                                </p>
                                <p className='text-muted font-weight-bold mb-0'>
                                  {formatBytes(sFile.size)}
                                </p>
                                <p className='text-muted font-weight-bold'>
                                  {moment(sFile.createdat).fromNow()}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='flex-shrink-0'>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag='button'
                                className='btn btn-ghost-primary btn-icon btn-sm dropdown'>
                                <i className='ri-more-2-fill fs-16 align-bottom' />
                              </DropdownToggle>
                              <DropdownMenu className='dropdown-menu-end'>
                                <DropdownItem
                                  className='view-item-btn'
                                  onClick={() => {
                                    onSelect(sFile);
                                    setFolderKlik(false);
                                  }}>
                                  Open
                                </DropdownItem>
                                <DropdownItem
                                  className='edit-folder-list'
                                  onClick={() =>
                                    handleFolderClick(sFile, "file")
                                  }>
                                  Rename
                                </DropdownItem>
                                <DropdownItem
                                  className='edit-folder-list'
                                  onClick={() => handleFileClick(sFile)}>
                                  Move to folder
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteFile(sFile.id);
                                  }}>
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='mb-3'>
                <Dropzone
                  multiple={true}
                  onDrop={(acceptedFiles) => {
                    handleUpload(acceptedFiles);
                  }}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className='dropzone dz-clickable'
                      style={{ cursor: "pointer", height: "140px" }}
                      {...getRootProps({ className: "dropzone" })}>
                      <div className='dz-message needsclick'>
                        <div>
                          <i className='display-4 text-muted ri-upload-cloud-2-fill' />
                          <input {...getInputProps()} />
                        </div>
                        <h5>Drop files or click here to upload.</h5>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      <div className='modal-footer'>
        {folderKlik ? (
          <div className='hstack gap-2 justify-content-start'>
            <button
              type='button'
              className='btn btn-light'
              onClick={() => {
                setFolderKlik(false);
              }}>
              Back
            </button>
          </div>
        ) : null}
        <div className='hstack gap-2 justify-content-end'>
          <button
            type='button'
            className='btn btn-light'
            onClick={() => {
              setFolderKlik(false);
              toggle();
            }}>
            Close
          </button>
        </div>
        <div className='hstack gap-2 justify-content-end'>
          <button
            type='button'
            className='btn btn-light'
            onClick={() => {
              handleSaveMultiple()
              setFolderKlik(false);
              toggle();
            }}>
            Save Multiple
          </button>
        </div>
      </div>
      <Modal
        className='fade zoomIn'
        isOpen={modalFolder}
        toggle={() => setModalFolder(!modalFolder)}
        id='createFolderModal'
        modalClassName='zoomIn'
        centered
        tabIndex='-1'>
        <ModalHeader
          className='p-3 bg-soft-success'
          id='createFolderModalLabel'
          toggle={() => setModalFolder(!modalFolder)}>
          {" "}
          {isEdit ? "Folder Rename" : "Create Folder"}{" "}
        </ModalHeader>
        <ModalBody>
          <form
            autoComplete='off'
            className='needs-validation createfolder-form'
            id='createfolder-form'
            noValidate=''
            onSubmit={(e) => {
              e.preventDefault();
              folderValidation.handleSubmit();
              return false;
            }}>
            <div className='mb-4'>
              <label htmlFor='name-input' className='form-label'>
                Name
              </label>
              <input
                type='text'
                className='form-control'
                id='name-input'
                name='name'
                onChange={folderValidation.handleChange}
                onBlur={folderValidation.handleBlur}
                value={folderValidation.values.name || ""}
              // invalid={folderValidation.touched.name && folderValidation.errors.name ? true : false}
              />
              {folderValidation.touched.name && folderValidation.errors.name ? (
                <FormFeedback type='invalid'>
                  {folderValidation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className='hstack gap-2 justify-content-end'>
              <button
                type='button'
                className='btn btn-ghost-success'
                onClick={() => setModalFolder(false)}>
                <i className='ri-close-line align-bottom'></i> Close
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                id='addNewFolder'>
                {isEdit ? "Save" : "Add Folder"}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        className='fade zoomIn'
        isOpen={modalFile}
        id='createFileModal'
        modalClassName='zoomIn'
        centered>
        <ModalHeader
          className='p-3 bg-soft-success'
          id='createFolderModalLabel'
          toggle={fileToggle}>
          {"Move File"}
        </ModalHeader>
        <ModalBody>
          <form
            autoComplete='off'
            className='needs-validation createfolder-form'
            id='createfolder-form'
            onSubmit={(e) => {
              e.preventDefault();
              fileValidation.handleSubmit();
              return false;
            }}>
            <div className='mb-3'>
              <Label htmlFor='folder-field' className='form-label'>
                Folder
              </Label>
              <Select
                name='folder'
                value={fileValidation.values.folder}
                placeholder='Enter Folder'
                onChange={(e) => {
                  fileValidation.setFieldValue("folder", e);
                }}
                options={folderList}></Select>
            </div>
            <div className='hstack gap-2 justify-content-end'>
              <button
                type='button'
                className='btn btn-ghost-success'
                onClick={() => {
                  setModalFile(false);
                  fileValidation.resetForm();
                }}>
                <i className='ri-close-line align-bottom'></i> Close
              </button>
              <button type='submit' className='btn btn-primary' id='addNewFile'>
                {"Move"}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Modal>
  );
};

export default ModalInputFile;
