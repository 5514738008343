import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import CryptoJS from "crypto-js";
import moment from "moment";
import "moment-timezone";
import { api } from "../../config";

import {
  getPublicTask,
  getTaskLoading,
  resetTaskState,
} from "../../store/task/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Starter = () => {
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const { tasks } = useSelector((state) => ({
    tasks: state.Task.tasks,
  }));

  const [isSearch, setIsSearch] = useState(false);
  const [task, setTask] = useState();

  useEffect(() => {
    if (!isSearch) {
      var decrypted = CryptoJS.AES.decrypt(
        taskId.replace(/[|]+/g, "/"),
        "@ptek4321"
      ).toString(CryptoJS.enc.Utf8);
      console.log(taskId.replace(/[|]+/g, "/"));
      dispatch(resetTaskState());
      dispatch(getPublicTask({ task: decrypted }));
      setIsSearch(true);
    }
  }, [dispatch, isSearch, taskId]);

  useEffect(() => {
    if (tasks) setTask(tasks[0]);
  }, [tasks]);

  const handleValidDate = (date) => {
    return moment(new Date(date), "YYYY/MM/DD")
      .tz("Asia/Jakarta")
      .format("MMM D, YYYY");
  };

  const numRoman = (num) => {
    console.log(num);
    if (num === "1") {
      return "I";
    } else if (num === "2") {
      return "II";
    } else if (num === "3") {
      return "III";
    } else if (num === "4") {
      return "IV";
    } else if (num === "5") {
      return "V";
    } else if (num === "6") {
      return "VI";
    } else if (num === "7") {
      return "VII";
    } else if (num === "8") {
      return "VIII";
    } else if (num === "9") {
      return "IX";
    } else if (num === "10") {
      return "X";
    } else if (num === "11") {
      return "XI";
    } else if (num === "12") {
      return "XII";
    }
  };

  document.title = "Document | Document Verification";
  return (
    <React.Fragment>
      <div className='layout-wrapper landing'>
        <section className='section' id='services'>
          <Container>
            <Row className='justify-content-center'>
              <Col lg={8}>
                <div className='text-center mb-5'>
                  <img
                    alt=''
                    src={
                      task && task.task_client
                        ? api.MEDIA_URL + "/media/" + task.task_client.image
                        : null
                    }
                    style={{ maxWidth: "150px", maxHeight: "50px" }}
                  />
                  <h1 className='mb-3 ff-secondary fw-semibold lh-base'>
                    Informasi Dokumen
                  </h1>
                  <p className='mb-1'>
                    Tanggal Dokumen:{" "}
                    {task && task.startat
                      ? handleValidDate(task.startat)
                      : "undefined"}
                  </p>
                  <p className='mb-1'>
                    No Dokumen:
                    {task &&
                      task.id +
                        "/" +
                        task.document_prefix +
                        "/" +
                        numRoman(moment(task.documentat).format("M")) +
                        "/" +
                        moment(task.documentat).format("YYYY")}
                  </p>
                  <p className='mb-1'>
                    Perihal: {task && task.name ? task.name : "undefined"}
                  </p>
                  <p className='mb-1'>
                    {task && task.description ? task.description : "undefined"}
                  </p>

                  <div
                    className='avatar-sm icon-effect '
                    style={{ margin: "auto" }}>
                    {task ? (
                      <div className='avatar-title bg-transparent text-success rounded-circle'>
                        <i className='ri-shield-check-line fs-36'></i>
                      </div>
                    ) : (
                      <div className='avatar-title bg-transparent text-danger rounded-circle'>
                        <i className='ri-close-circle-line fs-36'></i>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Starter;
