import {
  GET_STORAGEFOLDER,
  GET_STORAGEFOLDER_SUCCESS,
  GET_STORAGEFOLDER_FAIL,
  DELETE_STORAGEFOLDER,
  DELETE_STORAGEFOLDER_SUCCESS,
  DELETE_STORAGEFOLDER_FAIL,
  UPDATE_STORAGEFOLDER,
  UPDATE_STORAGEFOLDER_SUCCESS,
  UPDATE_STORAGEFOLDER_FAIL,
  ADD_NEW_STORAGEFOLDER,
  ADD_STORAGEFOLDER_SUCCESS,
  ADD_STORAGEFOLDER_FAIL,
  STORAGEFOLDER_LOADING,
  STORAGEFOLDER_RESET,
} from "./actionType";

export const resetStorageFolderState = (actionType) => ({
  type: STORAGEFOLDER_RESET,
  payload: { actionType },
});

export const getStorageFolderSuccess = (actionType, data) => ({
  type: GET_STORAGEFOLDER_SUCCESS,
  payload: { actionType, data },
});

export const getStorageFolderFail = (actionType, error) => ({
  type: GET_STORAGEFOLDER_FAIL,
  payload: { actionType, error },
});

export const getStorageFolder = (data) => ({
  type: GET_STORAGEFOLDER,
  payload: data,
});

export const getStorageFolderLoading = () => ({
  type: STORAGEFOLDER_LOADING,
});

export const deleteStorageFolder = (data) => ({
  type: DELETE_STORAGEFOLDER,
  payload: data,
});

export const deleteStorageFolderSuccess = (data) => ({
  type: DELETE_STORAGEFOLDER_SUCCESS,
  payload: data,
});

export const deleteStorageFolderFail = (error) => ({
  type: DELETE_STORAGEFOLDER_FAIL,
  payload: error,
});

export const updateStorageFolder = (data) => ({
  type: UPDATE_STORAGEFOLDER,
  payload: data,
});

export const updateStorageFolderFail = (error) => ({
  type: UPDATE_STORAGEFOLDER_FAIL,
  payload: error,
});

export const updateStorageFolderSuccess = (data) => ({
  type: UPDATE_STORAGEFOLDER_SUCCESS,
  payload: data,
});

export const addNewStorageFolder = (data) => ({
  type: ADD_NEW_STORAGEFOLDER,
  payload: data,
});

export const addStorageFolderSuccess = (data) => ({
  type: ADD_STORAGEFOLDER_SUCCESS,
  payload: data,
});

export const addStorageFolderFail = (error) => ({
  type: ADD_STORAGEFOLDER_FAIL,
  payload: error,
});
