import {
  HISTORY_LOADING,
  GET_HISTORY,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAIL,
  ADD_HISTORY_SUCCESS,
  ADD_HISTORY_FAIL,
  DELETE_HISTORY_SUCCESS,
  DELETE_HISTORY_FAIL,
  UPDATE_HISTORY_SUCCESS,
  UPDATE_HISTORY_FAIL,
  HISTORY_RESET,
  GET_HISTORY_PUBLIC,
} from "./actionType";

const INIT_STATE = {
  histories: [],
  error: {},
  historyPublics: []
};

const History = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HISTORY_SUCCESS:
      switch (action.payload.actionType) {
        case GET_HISTORY:
          return {
            ...state,
            histories: action.payload.data,
            isHistoryCreated: false,
            isHistorySuccess: true,
            loading: false,
          };

        case GET_HISTORY_PUBLIC:
          return {
            ...state,
            historyPublics: action.payload.data,
            isHistoryCreated: false,
            isHistorySuccess: true,
            publicLoading: false,
          };

        default:
          return { ...state };
      }
    case GET_HISTORY_FAIL:
      switch (action.payload.actionType) {
        case GET_HISTORY_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isHistoryCreated: false,
            isHistorySuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_HISTORY: {
      return {
        ...state,
        isHistoryCreated: false,
        loading: true,
      };
    }

    case GET_HISTORY_PUBLIC: {
      return {
        ...state,
        isHistoryCreated: false,
        publicLoading: true,
      };
    }

    case HISTORY_LOADING: {
      return {
        ...state,
        isHistoryCreated: false,
        loading: true,
      };
    }

    case ADD_HISTORY_SUCCESS:
      return {
        ...state,
        isHistoryCreated: true,
        loading: false,
        histories: [...state.histories, action.payload.data],
      };

    case ADD_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        histories: state.histories.filter(
          (history) => history.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        histories: state.histories.map((history) =>
          history.id.toString() === action.payload.data.id.toString()
            ? { ...history, ...action.payload.data }
            : history
        ),
      };

    case UPDATE_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case HISTORY_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default History;
