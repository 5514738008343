import {
  GET_SENSORTYPE,
  GET_SENSORTYPE_SUCCESS,
  GET_SENSORTYPE_FAIL,
  DELETE_SENSORTYPE,
  DELETE_SENSORTYPE_SUCCESS,
  DELETE_SENSORTYPE_FAIL,
  UPDATE_SENSORTYPE,
  UPDATE_SENSORTYPE_SUCCESS,
  UPDATE_SENSORTYPE_FAIL,
  ADD_NEW_SENSORTYPE,
  ADD_SENSORTYPE_SUCCESS,
  ADD_SENSORTYPE_FAIL,
  SENSORTYPE_LOADING,
  SENSORTYPE_RESET,
} from "./actionType";

export const resetSensorTypeState = (actionType) => ({
  type: SENSORTYPE_RESET,
  payload: { actionType },
});

export const getSensorTypeSuccess = (actionType, data) => ({
  type: GET_SENSORTYPE_SUCCESS,
  payload: { actionType, data },
});

export const getSensorTypeFail = (actionType, error) => ({
  type: GET_SENSORTYPE_FAIL,
  payload: { actionType, error },
});

export const getSensorType = (data) => ({
  type: GET_SENSORTYPE,
  payload: data,
});

export const getSensorTypeLoading = () => ({
  type: SENSORTYPE_LOADING,
});

export const deleteSensorType = (data) => ({
  type: DELETE_SENSORTYPE,
  payload: data,
});

export const deleteSensorTypeSuccess = (data) => ({
  type: DELETE_SENSORTYPE_SUCCESS,
  payload: data,
});

export const deleteSensorTypeFail = (error) => ({
  type: DELETE_SENSORTYPE_FAIL,
  payload: error,
});

export const updateSensorType = (data) => ({
  type: UPDATE_SENSORTYPE,
  payload: data,
});

export const updateSensorTypeFail = (error) => ({
  type: UPDATE_SENSORTYPE_FAIL,
  payload: error,
});

export const updateSensorTypeSuccess = (data) => ({
  type: UPDATE_SENSORTYPE_SUCCESS,
  payload: data,
});

export const addNewSensorType = (data) => ({
  type: ADD_NEW_SENSORTYPE,
  payload: data,
});

export const addSensorTypeSuccess = (data) => ({
  type: ADD_SENSORTYPE_SUCCESS,
  payload: data,
});

export const addSensorTypeFail = (error) => ({
  type: ADD_SENSORTYPE_FAIL,
  payload: error,
});
