export const STORAGEFOLDER_LOADING = "STORAGEFOLDER_LOADING";
export const STORAGEFOLDER_RESET = "STORAGEFOLDER_RESET";
export const GET_STORAGEFOLDER = "GET_STORAGEFOLDER";
export const GET_STORAGEFOLDER_SUCCESS = "GET_STORAGEFOLDER_SUCCESS";
export const GET_STORAGEFOLDER_FAIL = "GET_STORAGEFOLDER_FAIL";
export const DELETE_STORAGEFOLDER = "DELETE_STORAGEFOLDER";
export const DELETE_STORAGEFOLDER_SUCCESS = "DELETE_STORAGEFOLDER_SUCCESS";
export const DELETE_STORAGEFOLDER_FAIL = "DELETE_STORAGEFOLDER_FAIL";
export const UPDATE_STORAGEFOLDER = "UPDATE_STORAGEFOLDER";
export const UPDATE_STORAGEFOLDER_SUCCESS = "UPDATE_STORAGEFOLDER_SUCCESS";
export const UPDATE_STORAGEFOLDER_FAIL = "UPDATE_STORAGEFOLDER_FAIL";
export const ADD_NEW_STORAGEFOLDER = "ADD_NEW_STORAGEFOLDER";
export const ADD_STORAGEFOLDER_SUCCESS = "ADD_STORAGEFOLDER_SUCCESS";
export const ADD_STORAGEFOLDER_FAIL = "ADD_STORAGEFOLDER_FAIL";
