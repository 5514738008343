export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING";
export const NOTIFICATION_RESET = "NOTIFICATION_RESET";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS"
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL"
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS"
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL"
export const ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION"
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS"
export const ADD_NOTIFICATION_FAIL = "ADD_NOTIFICATION_FAIL"
export const NOTIFICATION_RESET_CREATE = "NOTIFICATION_RESET_CREATE"