import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_ACTIVITYLOG,
  DELETE_ACTIVITYLOG,
  UPDATE_ACTIVITYLOG,
  ADD_NEW_ACTIVITYLOG,
  ADD_NEW_ACTIVITYLOG_TABLE,
  UPDATE_ACTIVITYLOG_TABLE
} from "./actionType";

import {
  getActivityLogSuccess,
  getActivityLogFail,
  deleteActivityLogSuccess,
  deleteActivityLogFail,
  updateActivityLogSuccess,
  updateActivityLogFail,
  addActivityLogSuccess,
  addActivityLogFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveActivityLogApi,
  getActivityLogApi,
  updateActivityLogApi,
  deleteActivityLogApi,
  addNewActivityLogApi,
  addNewActivityLogTableApi,
  updateActivityLogTableApi,
} from "../../helpers/backend_helper";

function* getActivityLog({ payload: activityLog }) {
  try {
    const response = yield call(getActivityLogApi, activityLog);
    yield put(getActivityLogSuccess(GET_ACTIVITYLOG, response.data));
  } catch (error) {
    yield put(getActivityLogFail(GET_ACTIVITYLOG, error));
  }
}

function* onUpdateActivityLog({ payload: activityLog }) {
  try {
    if (activityLog.setApprove) {
      const response = yield call(approveActivityLogApi, activityLog);
      yield put(updateActivityLogSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    } else {
      const response = yield call(updateActivityLogApi, activityLog);
      yield put(updateActivityLogSuccess(response));
      toast.success(response.message, { autoClose: 3000 });
    }
  } catch (error) {
    yield put(updateActivityLogFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onUpdateActivityLogTable({ payload: activityLog }) {
  try {
    const response = yield call(updateActivityLogTableApi, activityLog);
    yield put(updateActivityLogSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateActivityLogFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteActivityLog({ payload: activityLog }) {
  try {
    const response = yield call(deleteActivityLogApi, activityLog);
    yield put(deleteActivityLogSuccess({ activityLog, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteActivityLogFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewActivityLog({ payload: activityLog }) {
  try {
    const response = yield call(addNewActivityLogApi, activityLog);
    yield put(addActivityLogSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addActivityLogFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewActivityLogTable({ payload: activityLog }) {
  try {
    const response = yield call(addNewActivityLogTableApi, activityLog);
    yield put(addActivityLogSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addActivityLogFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetActivityLog() {
  yield takeEvery(GET_ACTIVITYLOG, getActivityLog);
}

export function* watchUpdateActivityLog() {
  yield takeEvery(UPDATE_ACTIVITYLOG, onUpdateActivityLog);
}

export function* watchDeleteActivityLog() {
  yield takeEvery(DELETE_ACTIVITYLOG, onDeleteActivityLog);
}

export function* watchAddNewActivityLog() {
  yield takeEvery(ADD_NEW_ACTIVITYLOG, onAddNewActivityLog);
}

export function* watchAddNewActivityLogTable() {
  yield takeEvery(ADD_NEW_ACTIVITYLOG_TABLE, onAddNewActivityLogTable);
}

export function* watchUpdateActivityLogTable() {
  yield takeEvery(UPDATE_ACTIVITYLOG_TABLE, onUpdateActivityLogTable);
}

function* activityLogSaga() {
  yield all([
    fork(watchGetActivityLog),
    fork(watchDeleteActivityLog),
    fork(watchUpdateActivityLog),
    fork(watchAddNewActivityLog),
    fork(watchAddNewActivityLogTable),
    fork(watchUpdateActivityLogTable)
  ]);
}

export default activityLogSaga;
