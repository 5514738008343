import {
  GET_BRAND,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAIL,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
  UPDATE_BRAND,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  ADD_NEW_BRAND,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAIL,
  BRAND_LOADING,
  BRAND_RESET,
} from "./actionType";

export const resetBrandState = (actionType) => ({
  type: BRAND_RESET,
  payload: { actionType },
});

export const getBrandSuccess = (actionType, data) => ({
  type: GET_BRAND_SUCCESS,
  payload: { actionType, data },
});

export const getBrandFail = (actionType, error) => ({
  type: GET_BRAND_FAIL,
  payload: { actionType, error },
});

export const getBrand = data => ({
  type: GET_BRAND,
  payload: data,
});

export const getBrandLoading = () => ({
  type: BRAND_LOADING
});

export const deleteBrand = data => ({
  type: DELETE_BRAND,
  payload: data,
});

export const deleteBrandSuccess = data => ({
  type: DELETE_BRAND_SUCCESS,
  payload: data,
});

export const deleteBrandFail = error => ({
  type: DELETE_BRAND_FAIL,
  payload: error,
});

export const updateBrand = data => ({
  type: UPDATE_BRAND,
  payload: data,
});

export const updateBrandFail = error => ({
  type: UPDATE_BRAND_FAIL,
  payload: error,
});

export const updateBrandSuccess = data => ({
  type: UPDATE_BRAND_SUCCESS,
  payload: data,
});

export const addNewBrand = data => ({
  type: ADD_NEW_BRAND,
  payload: data,
});

export const addBrandSuccess = data => ({
  type: ADD_BRAND_SUCCESS,
  payload: data,
});

export const addBrandFail = error => ({
  type: ADD_BRAND_FAIL,
  payload: error,
});