import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Table, Button, Card, CardBody, Col, Container, CardHeader, Nav, NavItem, NavLink, Row, Modal, ModalHeader, Form, ModalBody, Label, Input, FormFeedback, Spinner, Alert, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import ApproveModal from "../../../Components/Common/ApproveModal";
import { isEmpty, clone } from "lodash";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination, useRowSelect } from "react-table";
import Select from "react-select";

import readXlsxFile from "read-excel-file";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import classnames from "classnames";

//redux
import { useSelector, useDispatch } from "react-redux";

//   Import actions
import { getUnit } from "../../../store/unit/action";
import { getSensor as onGetSensors, addNewSensor as onAddNewSensor, updateSensor as onUpdateSensor, deleteSensor as onDeleteSensor, getSensorLoading, resetSensorState } from "../../../store/sensor/action";
import { getDevice, resetDeviceState } from "../../../store/device/action";
import { getParameter as onGetParameters, addNewParameter as onAddNewParameter, updateParameter as onUpdateParameter, deleteParameter as onDeleteParameter, addNewParameterTable, getParameterLoading, resetParameterState, updateParameterTable } from "../../../store/parameter/action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

const Parameter = (props) => {
  const { t } = props;
  const [modal, setModal] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [modalTable, setModalTable] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabSecond, setActiveTabSecond] = useState("1");
  const [valuesKey, setValuesKey] = useState("");
  const [valuesValue, setValuesValue] = useState("");
  const [valuesStart, setValuesStart] = useState("");
  const [valuesEnd, setValuesEnd] = useState("");
  const dispatch = useDispatch();
  const [pIndex, setPIndex] = useState(0);
  const [pIndexSecond, setPIndexSecond] = useState(0);
  const [pIndexThird, setPIndexThird] = useState(0);
  const [companyId, setCompanyId] = useState();

  const { sensors, parameters, isSensorCreated, isSensorSuccess, loading, error, parameterLoading, devices, deviceLoading, isDeviceCreated } = useSelector((state) => ({
    sensors: state.Sensor.sensors,
    isSensorCreated: state.Sensor.isSensorCreated,
    isSensorSuccess: state.Sensor.isSensorSuccess,
    loading: state.Sensor.loading,
    error: state.Sensor.error,
    parameters: state.Parameter.parameters,
    parameterLoading: state.Parameter.loading,
    devices: state.Device.devices,
    deviceLoading: state.Device.loading,
    isDeviceCreated: state.Device.isDeviceCreated,
  }));

  const [isSearch, setIsSearch] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [sensorSelectList, setSensorSelectList] = useState([]);
  const [sensor, setSensor] = useState([]);
  const [parameterTable, setParameterTable] = useState([]);
  const [parameterTableList, setParameterTableList] = useState([]);
  const [info, setInfo] = useState([]);
  const [statusActive, setStatusActive] = useState([]);
  const [parameter, setSensorDetail] = useState([]);
  const [approveModal, setApproveModal] = useState(false);
  const [userType, setUserType] = useState("user");
  const [parameterList, setParameterList] = useState([]);
  const [deviceId, setDeviceId] = useState();
  const [clientId, setClientId] = useState();
  const [sensorName, setSensorName] = useState();
  const [searchFormula, setSearchFormula] = useState("");
  const [searchFormulaInput, setSearchFormulaInput] = useState("");
  const [klik, setKlik] = useState(false);
  const [varr, setVarr] = useState();
  const [inputList, setInputList] = useState();
  const [inputListTable, setInputListTable] = useState();
  const [rangeParam, setRangeParam] = useState();
  const [varB, setVarB] = useState();
  const [varLast, setVarLast] = useState();
  const [splited, setSplited] = useState();
  const [tableItem, setTableItem] = useState([]);
  const [selectVal, setSelectVal] = useState("");
  const [paramsList, setParamsList] = useState([]);
  const [tableParamList, setTableParamList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValueSecond, setSearchValueSecond] = useState([]);
  const [searchValueThird, setSearchValueThird] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: 1 },
        { label: "Activated", value: 2 },
        { label: "Deactivated", value: 3 },
      ],
    },
  ];

  const tableList = [
    {
      options: [
        { label: "Lookup Table", value: "lookup_table" },
        { label: "Range Table", value: "range_table" },
      ],
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditDetail, setIsEditDetail] = useState(false);
  const [isEditTable, setIsEditTable] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalDetail, setDeleteModalDetail] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [unitName, setUnitName] = useState({});
  const [paramAsoc, setParamAsoc] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const handleDeleteTable = useCallback(
    (item) => {
      if (tableItem) {
        tableItem.splice(tableItem.indexOf(item), 1);
        setTableItem(tableItem);
        forceUpdate();
      }
    },
    [forceUpdate, tableItem]
  );

  const defaultdate = () => {
    return moment(new Date()).tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const onClickDelete = (sensor) => {
    setSensor(sensor);
    setDeleteModal(true);
  };

  const onClickDeleteDetail = (parameter) => {
    setSensorDetail(parameter);
    setDeleteModalDetail(true);
  };

  const handleDeleteSensor = () => {
    if (sensor) {
      dispatch(onDeleteSensor(sensor));
      setDeleteModal(false);
    }
  };

  const handleDeleteSensorDetail = () => {
    if (parameter) {
      dispatch(onDeleteParameter(parameter));
      setDeleteModalDetail(false);
    }
  };

  const onClickApprove = (employee) => {
    setSensor(employee);
    setApproveModal(true);
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!isEmpty(devices)) {
      var data = [];
      var opt = [];
      devices.forEach((item, key) => {
        if (item.id === deviceId) {
          item.device_sensor.forEach((sensor) => {
            if (sensor.statusId === 2) {
              const name = sensor.name + " -> Slave ID " + sensor.slaveId;
              const id = sensor.id;
              const deviceId = sensor.deviceId;
              const clientId = sensor.clientId;
              var obj = {
                label: name,
                value: id,
                deviceId: deviceId,
                clientId: clientId,
              };
              opt.push(obj);
            }
          });
        }
      });
      var option = { options: opt };
      data.push(option);
      setSensorSelectList(data);
    }
  }, [devices, deviceId]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredDevices = parameters;
      if (type !== "all") {
        filteredDevices = parameters.filter((parameter) => parameter.parameter_status?.name === type);
      }
      setParameterList(filteredDevices);
    }
  };

  const toggleTabSecond = (tab, type) => {
    if (activeTabSecond !== tab) {
      setActiveTabSecond(tab);
      let data = [];
      for (const parameter of parameters) {
        if (parameter.isTable) {
          data.push(parameter);
        }
      }
      let filteredDevices = data;
      if (type !== "all") {
        filteredDevices = data.filter((parameter) => parameter.parameter_status?.name === type);
      }
      setParameterTableList(filteredDevices);
    }
  };

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetDeviceState());
      dispatch(getDevice({ status: 2 }));
      dispatch(resetSensorState());
      dispatch(onGetSensors({ status: 2 }));
      setIsSearch(true);
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if (obj) {
        if (obj.data && obj.data.employee_position && obj.data.employee_position.position_role) {
          setUnitName({
            label: obj.data.employee_unit.unit_name,
            value: obj.data.employee_unit.id,
          });
        }
      } else {
        window.location = "/logout";
      }
    }
  }, [dispatch, isSearch]);

  useEffect(() => {
    if (!isEmpty(devices)) {
      dispatch(resetParameterState());
      dispatch(
        onGetParameters({
          device: devices[devices.length - 1]?.id,
        })
      );
    }
  }, [dispatch, devices]);

  useEffect(() => {
    if (!isEmpty(devices)) {
      setIsEdit(false);
      setDeviceList(devices);
      setInfo(devices[devices.length - 1]);
      setDeviceId(devices[devices.length - 1].id);
    }
  }, [devices]);

  useEffect(() => {
    if (isDeviceCreated) {
      dispatch(resetDeviceState());
      dispatch(getDevice({ status: 2 }));
    }
  }, [dispatch, isDeviceCreated]);

  function handleUnit(e) {
    if (e.length > 2) {
      const param = { title: e, status: 2 };
      dispatch(getUnit(param));
    }
    return false;
  }

  const [filterDate, setFilterDate] = useState(defaultdate());

  const handleValidDate = (date) => {
    return moment(new Date(date), "YYYY/MM/DD").tz("Asia/Jakarta").format("MMM D, YYYY");
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setSensor(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const toggleDetail = useCallback(() => {
    if (modalDetail) {
      setModalDetail(false);
      setSensorDetail([]);
      setSensor([]);
      setSearchFormula("");
    } else {
      setModalDetail(true);
    }
  }, [modalDetail]);

  const toggleUpload = useCallback(() => {
    if (modalUpload) {
      setModalUpload(false);
      setValuesKey("");
      setValuesValue("");
      setValuesStart("");
      setValuesEnd("");
    } else {
      if (selectVal) {
        setModalUpload(true);
      } else {
        toast.error(t("You have not selected a table type"));
      }
    }
  }, [modalUpload, selectVal, t]);

  const toggleTable = useCallback(() => {
    if (modalTable) {
      setModalTable(false);
      setTableItem([]);
      setSearchFormula("");
      setSelectVal("");
    } else {
      setModalTable(true);
    }
  }, [modalTable]);

  const handleSensorDetailClick = useCallback(
    (arg, arginfo, isEdit) => {
      const data = arg;
      setSensorDetail({
        id: data.id,
        label: data.label,
        value: data.value,
        uom: data.uom,
        formula: data.formula,
        status: data.parameter_status
          ? {
              label: data.parameter_status.name,
              value: data.parameter_status.id,
            }
          : "",
        client: data.clientId,
        sensor: data.parameter_sensor
          ? {
              label: data.parameter_sensor.name,
              value: data.parameter_sensor.id,
            }
          : "",
        device: data.deviceId,
        showInDashboard: data.showInDashboard,
        showParameter: data.showParameter,
        warningOver: data.warningOver,
        criticalOver: data.criticalOver,
        warningUnder: data.warningUnder,
        criticalUnder: data.criticalUnder,
        scaleFactor: data.scaleFactor,
        amount: data.amount,
        formulaSequence: data.formulaSequence,
      });

      setSearchFormula(data.formula ? data.formula : "");

      setIsEditDetail(true);
      toggleDetail();
    },
    [toggleDetail]
  );

  const handleClickTable = useCallback(
    (arg, isEdit) => {
      const data = arg;

      let tableTypes;
      let tableValues;
      let tableParams;
      const lookup = {
        label: "Lookup Table",
        value: "lookup_table",
      };
      const range = {
        label: "Range Table",
        value: "range_table",
      };
      if (data.tableType === "lookup_table") {
        tableTypes = lookup;
      } else {
        tableTypes = range;
      }
      setSelectVal(data.tableType);
      if (data.parameter_table) {
        data.parameter_table.forEach((dataP) => {
          tableValues = dataP.value;
          tableParams = { label: dataP.parameter, value: dataP.parameter };
        });
        setTableItem(tableValues);
      }
      setParameterTable({
        id: data.id,
        label: data.label,
        value: data.value,
        status: data.parameter_status
          ? {
              label: data.parameter_status.name,
              value: data.parameter_status.id,
            }
          : "",
        sensor: data.parameter_sensor
          ? {
              label: data.parameter_sensor.name,
              value: data.parameter_sensor.id,
            }
          : "",
        client: data.clientId,
        device: data.deviceId,
        showInDashboard: data.showInDashboard,
        showParameter: data.showParameter,
        tableType: tableTypes,
        tableValue: tableValues,
        tableParam: tableParams,
        uom: data.uom ? data.uom : "",
        formula: data.formula,
        formulaSequence: data.formulaSequence,
      });
      setSearchFormula(data.formula ? data.formula : "");
      setIsEditTable(isEdit);
      toggleTable();
    },
    [toggleTable]
  );

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        maxWidth: 100,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              {cellProps.row.original.statusActiveId === 1 ? (
                <button
                  className="btn-label btn btn-sm btn-soft-success fs-12 me-0"
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}
                >
                  <i className="ri-checkbox-circle-line label-icon align-middle fs-16 me-1"></i>
                  {t("Approve")}
                </button>
              ) : (
                ""
              )}
              <Link
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary"
                onClick={() => {
                  setInfo(cellProps.row.original);
                  setDeviceId(cellProps.row.original.id);
                  setClientId(cellProps.row.original.clientId);
                  setCompanyId(cellProps.row.original.companyId);
                  setSensorName(cellProps.row.original.name);
                  dispatch(resetParameterState());
                  dispatch(
                    onGetParameters({
                      device: cellProps.row.original.id,
                    })
                  );
                }}
              >
                <i className="ri-eye-line fs-15"></i>
              </Link>
            </div>
          );
        },
      },

      {
        Header: `${t("Name")}`,
        accessor: "name",
        filterable: true,
      },
      {
        Header: `${t("Company")}`,
        accessor: "device_company.name",
        filterable: true,
      },
    ],
    [dispatch, t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: deviceList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: pIndex,
        pageSize: 8,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const columnsSecond = useMemo(
    () => [
      {
        Header: `${t("Action")}`,
        maxWidth: 100,
        filterable: false,
        Cell: (cell) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <button
                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                onClick={() => {
                  handleSensorDetailClick(cell.row.original, true);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </button>
              {/* <button
                to='#'
                className='btn-icon btn btn-sm btn-soft-primary edit-item-btn'
                onClick={() => {
                  handleSensorDetailClick(cell.row.original, false);
                }}>
                <i className='ri-clipboard-line fs-16'></i>
              </button> */}
              {cell.row.original.statusActiveId !== 2 ? (
                <button
                  to="#"
                  className="btn-icon btn btn-sm btn-soft-danger remove-item-btn"
                  onClick={() => {
                    onClickDeleteDetail(cell.row.original);
                  }}
                >
                  <i className=" ri-delete-bin-line fs-15"></i>
                </button>
              ) : null}
            </div>
          );
        },
      },
      {
        Header: `${t("Sensor")}`,
        filterable: true,
        Cell: (cell) => {
          return <span>{cell.row.original.parameter_sensor ? cell.row.original.parameter_sensor.name : "-"}</span>;
        },
      },
      {
        Header: `${t("Label")}`,
        accessor: "label",
        filterable: true,
      },
      {
        Header: `${t("Value")}`,
        accessor: "value",
        filterable: true,
      },
      {
        Header: `${t("UOM")}`,
        accessor: "uom",
        filterable: true,
      },
      {
        Header: `${t("Formula Sequence")}`,
        accessor: "formulaSequence",
        filterable: true,
      },
      {
        Header: `${t("Status")}`,
        accessor: "parameter_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "Activated":
              return <span className="badge text-uppercase badge-soft-secondary">{t(cell.value)}</span>;
            case "Deactivated":
              return <span className="badge text-uppercase badge-soft-danger">{t(cell.value)}</span>;
            case "Draft":
              return <span className="badge text-uppercase badge-soft-warning">{t(cell.value)}</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">{t(cell.value)}</span>;
          }
        },
      },
    ],
    [handleSensorDetailClick, t]
  );

  const {
    getTableProps: getTablePropsSecond,
    getTableBodyProps: getTableBodyPropsSecond,
    headerGroups: headerGroupsSecond,
    page: pageSecond,
    prepareRow: prepareRowSecond,
    canPreviousPage: canPreviousPageSecond,
    canNextPage: canNextPageSecond,
    pageOptions: pageOptionsSecond,
    gotoPage: gotoPageSecond,
    nextPage: nextPageSecond,
    previousPage: previousPageSecond,
    setPageSize: setPageSizeSecond,
    preGlobalFilteredRows: preGlobalFilteredRowsSecond,
    setGlobalFilter: setGlobalFilterSecond,
    state: { pageIndex: pageIndexSecond, pageSize: pageSizeSecond },
  } = useTable(
    {
      columns: columnsSecond,
      data: parameterList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: pIndexSecond,
        pageSize: 8,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const columnsThird = useMemo(
    () => [
      {
        Header: `${t("Action")}`,
        maxWidth: 100,
        filterable: false,
        Cell: (cell) => {
          return (
            <div className="hstack gap-2 mt-4 mt-sm-0">
              <button
                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                onClick={() => {
                  handleClickTable(cell.row.original, true);
                }}
              >
                <i className="ri-pencil-line fs-16"></i>
              </button>
              <button
                to="#"
                className="btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                onClick={() => {
                  handleClickTable(cell.row.original, false);
                }}
              >
                <i className="ri-clipboard-line fs-16"></i>
              </button>
              {cell.row.original.statusActiveId !== 2 ? (
                <button
                  to="#"
                  className="btn-icon btn btn-sm btn-soft-danger remove-item-btn"
                  onClick={() => {
                    onClickDeleteDetail(cell.row.original);
                  }}
                >
                  <i className=" ri-delete-bin-line fs-15"></i>
                </button>
              ) : null}
            </div>
          );
        },
      },
      {
        Header: `${t("Label")}`,
        accessor: "label",
        filterable: true,
      },
      {
        Header: `${t("Value")}`,
        accessor: "value",
        filterable: true,
      },
      {
        Header: `${t("Formula")}`,
        accessor: "formula",
        filterable: true,
      },
      {
        Header: `${t("Formula Sequence")}`,
        accessor: "formulaSequence",
        filterable: true,
      },
      {
        Header: `${t("Type")}`,
        accessor: "tableType",
        filterable: true,
      },
      {
        Header: `${t("Status")}`,
        accessor: "parameter_status.name",
        filterable: true,
        Cell: (cell) => {
          switch (cell.value) {
            case "Activated":
              return <span className="badge text-uppercase badge-soft-secondary">{t(cell.value)}</span>;
            case "Deactivated":
              return <span className="badge text-uppercase badge-soft-danger">{t(cell.value)}</span>;
            case "Draft":
              return <span className="badge text-uppercase badge-soft-warning">{t(cell.value)}</span>;
            default:
              return <span className="badge text-uppercase badge-soft-warning">{t(cell.value)}</span>;
          }
        },
      },
    ],
    [handleClickTable, t]
  );

  const {
    getTableProps: getTablePropsThird,
    getTableBodyProps: getTableBodyPropsThird,
    headerGroups: headerGroupsThird,
    page: pageThird,
    prepareRow: prepareRowThird,
    canPreviousPage: canPreviousPageThird,
    canNextPage: canNextPageThird,
    pageOptions: pageOptionsThird,
    gotoPage: gotoPageThird,
    nextPage: nextPageThird,
    previousPage: previousPageThird,
    setPageSize: setPageSizeThird,
    preGlobalFilteredRows: preGlobalFilteredRowsThird,
    setGlobalFilter: setGlobalFilterThird,
    state: { pageIndex: pageIndexThird, pageSize: pageSizeThird },
  } = useTable(
    {
      columns: columnsThird,
      data: parameterTableList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: pIndexThird,
        pageSize: 8,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable ? (column.isSorted ? (column.isSortedDesc ? " sorting_desc" : " sorting_asc") : " sort") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState([]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const onChangeSecond = useAsyncDebounce((value) => {
    setGlobalFilterSecond(value || undefined);
  }, 200);

  const onChangeThird = useAsyncDebounce((value) => {
    setGlobalFilterThird(value || undefined);
  }, 200);

  const handleApproveComponent = () => {
    if (sensor) {
      sensor.setApprove = true;
      // dispatch(onUpdateSensor(sensor));
      setApproveModal(false);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      currentId: (sensor && sensor.currentId) || "",
      sensor: (sensor && sensor.sensor) || "",
    },
    validationSchema: Yup.object({
      sensor: Yup.object().required("Please Enter Name").nullable(true),
    }),
    onSubmit: (values) => {
      const dataSensor = {
        id: sensor ? sensor.id : 0,
        clientTargetId: sensor ? sensor.clientId : 0,
        sensorTargetId: sensor ? sensor.id : 0,
        deviceTargetId: sensor ? sensor.deviceId : 0,
        sensorId: values.sensor.value,
        deviceId: values.sensor.deviceId,
        clientId: values.sensor.clientId,
      };
      dispatch(onUpdateSensor(dataSensor));
      validation.resetForm();
      toggle();
    },
  });

  const validationDetail = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      id: (parameter && parameter.id) || "",
      label: (parameter && parameter.label) || "",
      value: (parameter && parameter.value) || "",
      uom: (parameter && parameter.uom) || "",
      formula: (parameter && parameter.formula && parameter.formula.replace(/[{}]/g, "") && parameter.formula.replace(/\s/g, "") && parameter.formula.replace(/>/g, " >") && parameter.formula.replace(/=/g, "= ")) || "",
      client: (parameter && parameter.client) || "",
      sensor: (parameter && parameter.sensor) || "",
      status: (parameter && parameter.status) || "",
      device: (parameter && parameter.device) || "",
      company: (parameter && parameter.company) || "",
      showInDashboard: (parameter && parameter.showInDashboard) || false,
      showParameter: (parameter && parameter.showParameter) || false,
      warningUnder: parameter && parameter.warningUnder,
      criticalUnder: parameter && parameter.criticalUnder,
      warningOver: parameter && parameter.warningOver,
      criticalOver: parameter && parameter.criticalOver,
      scaleFactor: parameter && parameter.scaleFactor,
      amount: parameter && parameter.amount,
      formulaSequence: (parameter && parameter.formulaSequence) || 0,
    },
    validationSchema: Yup.object({
      label: Yup.string().required("Please Enter Label"),
      value: Yup.string().required("Please Enter Value"),
      sensor: Yup.object().required("Please Select Sensor"),
      status: Yup.object().required("Please Select Status"),
    }),
    onSubmit: (values) => {
      dispatch(getParameterLoading());
      if (isEditDetail) {
        const updateParameter = {
          id: parameter ? parameter.id : 0,
          label: values.label,
          value: values.value,
          uom: values.uom,
          formula: searchFormulaInput,
          clientId: clientId,
          companyId: companyId,
          sensorId: values.sensor.value,
          deviceId: deviceId,
          statusId: values.status.value,
          showInDashboard: values.showInDashboard,
          showParameter: values.showParameter,
          warningUnder: values.warningUnder,
          criticalUnder: values.criticalUnder,
          warningOver: values.warningOver,
          criticalOver: values.criticalOver,
          scaleFactor: values.scaleFactor,
          amount: values.amount,
          formulaSequence: values.formulaSequence,
        };
        console.log(updateParameter);
        dispatch(onUpdateParameter(updateParameter));
        validationDetail.resetForm();
      } else {
        const newParameter = {
          label: values.label,
          value: values.value,
          uom: values.uom,
          formula: searchFormulaInput,
          clientId: clientId,
          companyId: companyId,
          sensorId: values.sensor.value,
          deviceId: deviceId,
          statusId: values.status.value,
          showInDashboard: values.showInDashboard,
          showParameter: values.showParameter,
          warningUnder: values.warningUnder,
          criticalUnder: values.criticalUnder,
          warningOver: values.warningOver,
          criticalOver: values.criticalOver,
          scaleFactor: values.scaleFactor,
          amount: values.amount,
          formulaSequence: values.formulaSequence,
        };
        console.log(newParameter);
        dispatch(onAddNewParameter(newParameter));
        validationDetail.resetForm();
      }
      toggleDetail();
    },
  });

  const validationTable = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (parameterTable && parameterTable.id) || "",
      label: (parameterTable && parameterTable.label) || "",
      value: (parameterTable && parameterTable.value) || "",
      showInDashboard: (parameterTable && parameterTable.showInDashboard) || false,
      showParameter: (parameterTable && parameterTable.showParameter) || false,
      client: (parameterTable && parameterTable.client) || "",
      sensor: (parameterTable && parameterTable.sensor) || "",
      device: (parameterTable && parameterTable.device) || "",
      tableParam: (parameterTable && parameterTable.tableParam) || "",
      tableValue: (parameterTable && parameterTable.tableValue) || "",
      tableType: (parameterTable && parameterTable.tableType) || "",
      status: (parameterTable && parameterTable.status) || "",
      uom: (parameterTable && parameterTable.uom) || "",
      formula: (parameterTable && parameterTable.formula && parameterTable.formula.replace(/[{}]/g, "") && parameterTable.formula.replace(/\s/g, "") && parameterTable.formula.replace(/>/g, " >") && parameterTable.formula.replace(/=/g, "= ")) || "",
      formulaSequence: (parameterTable && parameterTable.formulaSequence) || 0,
    },
    validationSchema: Yup.object({
      label: Yup.string().required("Please Enter Label"),
      value: Yup.string().required("Please Enter Value"),
      status: Yup.object().required("Please Select Status"),
      sensor: Yup.object().required("Please Select Sensor"),
    }),
    onSubmit: (values) => {
      dispatch(getParameterLoading());
      if (!isEditTable) {
        const createParameterTable = {
          label: values.label,
          value: values.value,
          showInDashboard: values.showInDashboard,
          showParameter: values.showParameter,
          clientId: clientId,
          deviceId: deviceId,
          sensorId: values.sensor.value,
          table_param: values.tableParam.value,
          table_value: JSON.stringify(tableItem),
          table_type: values.tableType.value,
          statusId: values.status.value,
          uom: values.uom,
          formula: searchFormulaInput,
          formulaSequence: values.formulaSequence,
        };
        dispatch(addNewParameterTable(createParameterTable));
        validationTable.resetForm();
      } else {
        const editParameterTable = {
          id: parameterTable ? parameterTable.id : 0,
          label: values.label,
          value: values.value,
          showInDashboard: values.showInDashboard,
          showParameter: values.showParameter,
          clientId: clientId,
          deviceId: deviceId,
          sensorId: values.sensor.value,
          table_param: values.tableParam.value,
          table_value: JSON.stringify(tableItem),
          table_type: values.tableType.value,
          statusId: values.status.value,
          uom: values.uom,
          formula: searchFormulaInput,
          formulaSequence: values.formulaSequence,
        };
        dispatch(updateParameterTable(editParameterTable));
        validationTable.resetForm();
      }
      toggleTable();
    },
  });

  useEffect(() => {
    if (!modalTable) {
      validationTable.resetForm();
    }
  }, [modalTable, validationTable]);

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    // ele.forEach((element) => {
    //   dispatch(onDeleteParameter(element.value));
    // });
    // checkall.checked = false;
  };

  function handleSensorStatus(data) {
    setStatusActive(data);
  }

  function handleFilter() {
    let startat = "";
    let endat = "";
    let datearr = filterDate.split(" to ");
    if (datearr[1]) {
      startat = datearr[0];
      endat = datearr[1];
    }
    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetSensors(param));
  }

  useEffect(() => {
    if (parameters) {
      setInputList(parameters);
      let dataInp = [];
      dataInp.push({ id: null, label: "start", value: "start", isTable: true }, { id: null, label: "end", value: "end", isTable: true });
      parameters.forEach((param) => {
        dataInp.push(param);
      });
      setRangeParam(dataInp);
      setInputListTable(dataInp);
      let data = [];
      let params = [];
      let noTable = [];
      const parAsoc = [];
      for (const parameter of parameters) {
        if (!parAsoc[parameter.value]) {
          parAsoc[parameter.value] = true;
        }
        params.push(parameter);
        if (parameter.isTable) {
          data.push(parameter);
        } else {
          noTable.push(parameter);
        }
      }
      setParamAsoc(parAsoc);
      setParameterList(noTable);
      setParamsList(params);
      setParameterTableList(data);
    }
  }, [parameters]);

  useEffect(() => {
    if (varB) {
      const query = varB;
      var updatedList = [...parameters];
      updatedList = updatedList.filter((item) => {
        return item.value.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      if (updatedList.length > 0) {
        setInputList(updatedList);
        setInputListTable(updatedList);
      } else {
        setInputList(parameters);
        setInputListTable(rangeParam);
      }
    }
  }, [parameters, varB, rangeParam]);

  useEffect(() => {
    if (searchFormula !== "") {
      if (searchFormula.includes("+")) {
        let varA = searchFormula.split("+");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else if (searchFormula.includes("-")) {
        let varA = searchFormula.split("-");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else if (searchFormula.includes("*")) {
        let varA = searchFormula.split("*");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else if (searchFormula.includes("/")) {
        let varA = searchFormula.split("/");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else if (searchFormula.includes("%")) {
        let varA = searchFormula.split("%");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else if (searchFormula.includes("<")) {
        console.log(`ada < `);
        let varA = searchFormula.split("<");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else if (searchFormula.includes(">")) {
        let varA = searchFormula.split(">");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else if (searchFormula.includes("=")) {
        let varA = searchFormula.split("=");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else if (searchFormula.includes("AND")) {
        let varA = searchFormula.split("AND");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else if (searchFormula.includes("OR")) {
        let varA = searchFormula.split("OR");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else if (searchFormula.includes("?")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (searchFormula.includes(":")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (searchFormula.includes(" ")) {
        let varA = searchFormula.split(" ");
        let varB = varA[varA.length - 1];
        setVarB(varB);
      } else {
        let varA = [searchFormula];
        let varB = varA[varA.length - 1];
        setVarB(varB);
      }

      const text = searchFormula.split("");
      if (text.includes("+")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes("-")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes("*")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes("/")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes("%")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes("<")) {
        console.log(`ada < text`);
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes(">")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes("=")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes("AND")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes("OR")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes("?")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else if (text.includes(":")) {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      } else {
        setInputList(parameters);
        setInputListTable(rangeParam);
        setKlik(false);
        setVarr(searchFormula);
      }
    } else {
      setVarr("");
      setInputList(parameters);
      setInputListTable(rangeParam);
    }
  }, [searchFormula, parameters, rangeParam]);

  useEffect(() => {
    console.log(varr && varr.length ? varr.length : 0, "length");
    if (varr) {
      let data2 = [];
      let varA = varr;
      console.log(varA);
      const getOccurrence = (array, value) => {
        return array.filter((v) => v === value).length;
      };
      if (varr.length > 0) {
        varA.split(/([\[\]\(\)?:+*/%<>]|-|AND|OR)/g);
      } else {
        varA.split(/([\[\]\(\)?:+*/%<>]|-|AND|OR)/g);
      }
      const text = varA.split(/([\[\]\(\)?:+*/%<>]|-|AND|OR)/g);
      if (text.includes("=")) {
        const getOccurrence = (array, value) => {
          return array.filter((v) => v === value).length;
        };
        let idx = getOccurrence(text, "=");
        for (let i = 0; i < idx; i++) {
          const indexKrg = text.indexOf("=", i);
          if (text[indexKrg - 1] === "") {
            text.splice(indexKrg - 1, 1);
          }
        }
      }
      console.log(text.length, "text");
      console.log("params", paramAsoc);
      text.forEach((item) => {
        if (item.trim()) {
          if (paramAsoc[item.trim()]) {
            console.log(item, "item");
            data2.push("{" + item.trim() + "}");
          } else {
            data2.push(`${item}`);
          }
        }
      });
      let dataFormulas = data2.join();
      let rSplit = dataFormulas.split("");
      let idx = getOccurrence(rSplit, ",");
      for (let i = 0; i < idx; i++) {
        dataFormulas = dataFormulas.replace(",", "");
      }
      setSearchFormulaInput(dataFormulas);
    } else {
      console.log("varr ga ada", varr);
      setSearchFormulaInput("");
    }
  }, [varr, paramAsoc]);

  function handleTableAdd() {
    let fc = [];
    if (tableItem && tableItem) {
      tableItem.forEach((element) => {
        fc.push(element);
      });
    }
    if (selectVal === "lookup_table") {
      fc.push({
        key: "",
        value: "",
      });
    } else {
      fc.push({
        start: "",
        end: "",
        value: "",
      });
    }

    setTableItem(fc);
  }

  const olahUpload = (data) => {
    readXlsxFile(data).then((rows) => {
      if (selectVal === "lookup_table") {
        if (rows && rows.length > 0) {
          let i = 0;
          let iKey = 0;
          let iVal = 1;
          const det = [];
          for (const r of rows) {
            console.log(r, "ini");
            if (i === 0) {
              console.log(r, "i 1");
              iKey = r.indexOf(valuesKey);
              iVal = r.indexOf(valuesValue);
            } else {
              det.push({ key: (r[iKey] === 0) ? "0" : r[iKey], value: (r[iVal] === 0) ? "0" : r[iVal] });
            }
            i++;
          }
          setTableItem(det);
          setUploadFile(null);
        }
      } else {
        if (rows && rows.length > 0) {
          let i = 0;
          let iStart = 0;
          let iVal = 1;
          let iEnd = 2;
          const det = [];
          for (const r of rows) {
            if (i === 0) {
              iStart = r.indexOf(valuesStart);
              iEnd = r.indexOf(valuesEnd);
              iVal = r.indexOf(valuesValue);
            } else {
              det.push({ start: r[iStart], end: r[iEnd], value: r[iVal] });
            }
            i++;
          }
          setTableItem(det);
          setUploadFile(null);
        }
      }
    });
  };

  useEffect(() => {
    if (paramsList) {
      var data = [];
      var opt = [];
      paramsList.forEach((item, key) => {
        if (item.isTable === false) {
          const name = item.label;
          const id = item.id;
          const value = item.value;
          var obj = { label: name, value: value };
          opt.push(obj);
        }
      });
      var option = { options: opt };
      data.push(option);
      setTableParamList(data);
    }
  }, [paramsList]);

  useEffect(() => {
    setPIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setPIndexSecond(pageIndexSecond);
  }, [pageIndexSecond]);

  useEffect(() => {
    setPIndexThird(pageIndexThird);
  }, [pageIndexThird]);

  document.title = "Parameter | TELKOMSEL - IOT Monitoring";
  return (
    <div className="page-content">
      <ApproveModal data={sensor && sensor.contract_employee ? sensor.contract_employee.employee_name : ""} show={approveModal} onApproveClick={handleApproveComponent} onCloseClick={() => setApproveModal(false)} />
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteSensor} onCloseClick={() => setDeleteModal(false)} />
      <DeleteModal show={deleteModalDetail} onDeleteClick={handleDeleteSensorDetail} onCloseClick={() => setDeleteModalDetail(false)} />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title="Parameter" pageTitle={t("Master Data")} />
        <Row>
          <Col xxl={5}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    {t("Parameter History")} &nbsp;
                    {deviceLoading ? (
                      <>
                        <Spinner color="primary" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color="success" type="grow" size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist"></Nav>
                  <Row className="mb-3">
                    <CardBody className="border border-top-0 border-end-0 border-start-0">
                      <form>
                        <Row>
                          <Col sm={1} className="col-xxl-2">
                            <div className="me-2 mb-2 col-12">
                              <select className="form-select" value={pageSize} onChange={onChangeInSelect}>
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm={7}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  onChange(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${count} ${t("Search")}...`}
                                value={searchValue || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                          <Col className="col-xxl-3">
                            <div>
                              <button
                                type="button"
                                className="btn btn-light w-100"
                                onClick={() => {
                                  handleFilter();
                                }}
                              >
                                <i className="ri-search-line me-1 align-bottom fs-14"></i>
                                {t("Search")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card mb-1">
                    <Table hover {...getTableProps()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroups.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={previousPage} disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      {`${t("Page")} `}
                      <strong>
                        {pageIndex + 1} {t("of")} {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} value={pIndex + 1} onChange={onChangeInInput} />
                    </Col>

                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPage} disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal id="showModal" isOpen={modal} centered size="md">
                  <ModalHeader className="bg-light p-3" toggle={toggle}>
                    {!!isEdit ? `${t("Edit")}  ${t("Parameter")}` : `${t("Copy")}  ${t("Parameter")}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <Label htmlFor="unit" className="form-label">
                              Sensor
                              {loading ? (
                                <Spinner color="primary" size="sm">
                                  Loading...
                                </Spinner>
                              ) : (
                                ""
                              )}
                            </Label>
                            <Select
                              name="sensor"
                              value={validation.values.sensor}
                              placeholder={`${t("Select")} ${t("Sensor")}`}
                              onChange={(e) => {
                                validation.setFieldValue("sensor", e);
                              }}
                              options={sensorSelectList}
                            ></Select>
                            {validation.touched.sensor && validation.errors.sensor ? (
                              <small className="text-danger" type="invalid">
                                {validation.errors.sensor}
                              </small>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModal(false);
                          }}
                        >
                          {t("Close")}
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">{!!isEdit ? `${t("Update")}  ${t("Parameter")}` : `${t("Copy")}  ${t("Parameter")}`}</span>
                            {loading ? (
                              <Spinner size="sm" className="flex-shrink-0" role="status">
                                Loading...
                              </Spinner>
                            ) : (
                              ""
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <Modal id="showModalTable" isOpen={modalTable} centered size="xl">
                  <ModalHeader className="bg-light p-3" toggle={toggleTable}>
                    {!!isEditTable ? `${t("Edit")}  ${t("Table")}` : `${t("Add")}  ${t("Table")}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationTable.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="d-flex mb-3">
                            <div className="me-auto p-2">
                              <Select
                                name="tableType"
                                value={validationTable.values.tableType}
                                validate={{ required: { value: true } }}
                                placeholder={`${t("Select")} ${t("Type")} ${t("Table")}`}
                                onChange={(e) => {
                                  validationTable.setFieldValue("tableType", e);
                                  setSelectVal(e.value);
                                }}
                                options={tableList}
                                invalid={validationTable.touched.tableType && validationTable.errors.tableType ? true : false}
                              ></Select>
                              {validationTable.touched.tableType && validationTable.errors.tableType ? (
                                <p className="text-danger" type="invalid">
                                  {validationTable.errors.tableType}
                                </p>
                              ) : null}
                            </div>
                            {validationTable.values.tableType.value ? (
                              <div className="p-2">
                                <button
                                  type="button"
                                  className="btn btn-success add-btn"
                                  id="create-btn"
                                  onClick={() => {
                                    handleTableAdd();
                                  }}
                                >
                                  <i className="ri-list-check label-icon align-middle fs-13 me-1"></i>
                                  {`${t("Add")} ${t("Table")}`}
                                </button>
                              </div>
                            ) : null}
                            <div className="p-2">
                              <button
                                type="button"
                                className="btn btn-success add-btn"
                                id="create-btn"
                                onClick={() => {
                                  toggleUpload();
                                }}
                              >
                                <i className="ri-list-check label-icon align-middle fs-13 me-1"></i>
                                {t("Upload")}
                              </button>
                            </div>
                          </div>
                          <div className="overflow-auto" style={{ height: "500px" }}>
                            {selectVal === "lookup_table" ? (
                              <div className="row" style={{ width: "500px" }}>
                                <div className="col-lg-5">
                                  <Label>Key</Label>
                                </div>
                                <div className="col-lg-5">
                                  <Label>Value</Label>
                                </div>
                              </div>
                            ) : selectVal === "range_table" ? (
                              <div className="row" style={{ width: "500px" }}>
                                <div className="col-md-4">
                                  <Label>Start</Label>
                                </div>
                                <div className="col-md-3">
                                  <Label>End</Label>
                                </div>
                                <div className="col-md-3">
                                  <Label>Value</Label>
                                </div>
                              </div>
                            ) : null}
                            {selectVal === "lookup_table" ? (
                              <>
                                {tableItem.map((o, i) => {
                                  return (
                                    <div key={i} className="border-bottom mb-3">
                                      <div className="row" style={{ width: "500px" }}>
                                        <div className="col-md-5">
                                          <div className="mb-3">
                                            <Input
                                              name={`tableItem.${i}.key`}
                                              className="form-control"
                                              placeholder="Key"
                                              type="text"
                                              value={tableItem[i].key || ""}
                                              onChange={(e) => {
                                                validationTable.setFieldValue(`tableItem.${i}.key`, e.target.value);
                                                tableItem[i].key = e.target.value;
                                              }}
                                              onBlur={validationTable.handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-5">
                                          <div className="mb-3">
                                            <Input
                                              name={`tableItem.${i}.value`}
                                              className="form-control"
                                              placeholder="Value"
                                              type="text"
                                              value={tableItem[i].value || ""}
                                              onChange={(e) => {
                                                validationTable.setFieldValue(`tableItem.${i}.value`, e.target.value);
                                                tableItem[i].value = e.target.value;
                                              }}
                                              onBlur={validationTable.handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-2">
                                          <div className="mb-3">
                                            <Link
                                              to="#"
                                              className="btn-icon btn btn-sm btn-soft-danger"
                                              onClick={(e) => {
                                                // e.preventDefault();
                                                handleDeleteTable(o);
                                                forceUpdate();
                                              }}
                                            >
                                              <i className="ri-delete-bin-5-line fs-16"></i>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                {tableItem.map((o, i) => {
                                  return (
                                    <div key={i} className="border-bottom mb-3">
                                      <div className="row" style={{ width: "500px" }}>
                                        <div className="col-md-4">
                                          <div className="mb-3">
                                            <Input
                                              name={`tableItem.${i}.start`}
                                              className="form-control"
                                              placeholder="Start"
                                              type="text"
                                              value={tableItem[i].start || ""}
                                              onChange={(e) => {
                                                validationTable.setFieldValue(`tableItem.${i}.start`, e.target.value);
                                                tableItem[i].start = e.target.value;
                                              }}
                                              onBlur={validationTable.handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="mb-3">
                                            <Input
                                              name={`tableItem.${i}.end`}
                                              className="form-control"
                                              placeholder="End"
                                              type="text"
                                              value={tableItem[i].end || ""}
                                              onChange={(e) => {
                                                validationTable.setFieldValue(`tableItem.${i}.end`, e.target.value);
                                                tableItem[i].end = e.target.value;
                                              }}
                                              onBlur={validationTable.handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="mb-3">
                                            <Input
                                              name={`tableItem.${i}.value`}
                                              className="form-control"
                                              placeholder="Value"
                                              type="text"
                                              value={tableItem[i].value || ""}
                                              onChange={(e) => {
                                                validationTable.setFieldValue(`tableItem.${i}.value`, e.target.value);
                                                tableItem[i].value = e.target.value;
                                              }}
                                              onBlur={validationTable.handleBlur}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-2">
                                          <div className="mb-3">
                                            <Link
                                              to="#"
                                              className="btn-icon btn btn-sm btn-soft-danger"
                                              onClick={(e) => {
                                                // e.preventDefault();
                                                handleDeleteTable(o);
                                                forceUpdate();
                                              }}
                                            >
                                              <i className="ri-delete-bin-5-line fs-16"></i>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 shadow border">
                          <div className="mb-3 mt-3">
                            <Label htmlFor="sensor-field" className="form-label">
                              {t("Sensor")} <span className="text-danger">*</span>
                            </Label>
                            {loading ? (
                              <>
                                <Spinner color="primary" type="grow" size={"sm"} className="ms-2">
                                  Loading...
                                </Spinner>
                                &nbsp;
                                <Spinner color="success" type="grow" size={"sm"}>
                                  Loading...
                                </Spinner>
                                &nbsp;
                              </>
                            ) : (
                              ""
                            )}
                            <Select
                              name="sensor"
                              value={validationTable.values.sensor}
                              aria-invalid={validationTable.touched.sensor}
                              aria-errormessage={validationTable.errors.sensor}
                              placeholder={`${t("Select")} ${t("Sensor")}`}
                              onChange={(e) => {
                                validationTable.setFieldValue("sensor", e);
                              }}
                              options={sensorSelectList}
                            ></Select>
                            {validationTable.touched.sensor && (
                              <p role="alert" style={{ color: "#f06548", fontSize: "11px" }}>
                                {validationTable.errors.sensor}
                              </p>
                            )}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="label-field" className="form-label">
                              Label <span className="text-danger">*</span>
                            </Label>
                            <Input name="label" id="label-field" className="form-control" placeholder={`${t("Enter")} ${t("Label")}`} type="text" onChange={validationTable.handleChange} onBlur={validationTable.handleBlur} value={validationTable.values.label} invalid={validationTable.touched.label && validationTable.errors.label ? true : false} />
                            {validationTable.touched.label && validationTable.errors.label ? <FormFeedback type="invalid">{validationTable.errors.label}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="value-field" className="form-label">
                              {t("Value")} <span className="text-danger">*</span>
                            </Label>
                            <Input name="value" id="value-field" className="form-control" placeholder={`${t("Enter")} ${t("Value")}`} type="text" onChange={validationTable.handleChange} onBlur={validationTable.handleBlur} value={validationTable.values.value} invalid={validationTable.touched.value && validationTable.errors.value ? true : false} />
                            {validationTable.touched.value && validationTable.errors.value ? <FormFeedback type="invalid">{validationTable.errors.value}</FormFeedback> : null}
                          </div>
                          <div className="mb-3 col-lg-12">
                            <Label htmlFor="value-field" className="form-label">
                              {t("Unit Of Measure")}
                            </Label>
                            <Input name="uom" id="uom-field" className="form-control" placeholder={`${t("Enter")} ${t("Unit Of Measure")}`} type="text" onChange={validationTable.handleChange} onBlur={validationTable.handleBlur} value={validationTable.values.uom} invalid={validationTable.touched.uom && validationTable.errors.uom ? true : false} />
                            {validationTable.touched.uom && validationTable.errors.uom ? <FormFeedback type="invalid">{validationTable.errors.uom}</FormFeedback> : null}
                          </div>
                          <div className="mb-3 pt-1">
                            <Label htmlFor="formulaSequence-field" className="form-label">
                              {t("Formula Sequence")}
                            </Label>
                            <Input name="formulaSequence" id="formulaSequence-field" className="form-control" placeholder={`${t("Enter")} ${t("Formula Sequence")}`} type="number" onChange={validationTable.handleChange} onBlur={validationTable.handleBlur} value={validationTable.values.formulaSequence} invalid={validationTable.touched.formulaSequence && validationTable.errors.formulaSequence ? true : false} />
                            {validationTable.touched.formulaSequence && validationTable.errors.formulaSequence ? <FormFeedback type="invalid">{validationTable.errors.formulaSequence}</FormFeedback> : null}
                          </div>
                          {selectVal === "range_table" ? (
                            <div className="mb-3 col-lg-12 position-relative">
                              <Label htmlFor="value-field" className="form-label">
                                {t("Formula")} <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="formula"
                                id="formula-field"
                                className="form-control"
                                placeholder={`${t("Enter")} ${t("Formula")}`}
                                type="text"
                                onChange={(e) => {
                                  console.log(e.target.value, "di input");
                                  setSearchFormula(e.target.value);
                                  setDropdownOpen(true);
                                  if (e.target.value.length > 0) {
                                    let varA = e.target.value.split(/([\[\]\(\)?:+*/%]|-|AND|OR)/g);
                                    let varB = varA[varA.length - 1];
                                    if (varA.includes("(")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx = getOccurrence(varA, "(");
                                      for (let i = 0; i < idx; i++) {
                                        const indexKrg = varA.indexOf("(", i);
                                        if (varA[indexKrg - 1] === "") {
                                          varA.splice(indexKrg - 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes("[")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx = getOccurrence(varA, "[");
                                      for (let i = 0; i < idx; i++) {
                                        const indexKrg = varA.indexOf("[", i);
                                        if (varA[indexKrg - 1] === "") {
                                          varA.splice(indexKrg - 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes(")")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx2 = getOccurrence(varA, ")");
                                      for (let i = 0; i < idx2; i++) {
                                        const indexKrg = varA.indexOf(")", i);
                                        if (varA[indexKrg + 1] === "") {
                                          varA.splice(indexKrg + 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes("]")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx2 = getOccurrence(varA, "]");
                                      for (let i = 0; i < idx2; i++) {
                                        const indexKrg = varA.indexOf("]", i);
                                        if (varA[indexKrg + 1] === "") {
                                          varA.splice(indexKrg + 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes("?")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx2 = getOccurrence(varA, "?");
                                      for (let i = 0; i < idx2; i++) {
                                        const indexKrg = varA.indexOf("?", i);
                                        if (varA[indexKrg + 1] === " ") {
                                          varA.splice(indexKrg + 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes(":")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx2 = getOccurrence(varA, ":");
                                      for (let i = 0; i < idx2; i++) {
                                        const indexKrg = varA.indexOf(":", i);
                                        if (varA[indexKrg + 1] === " ") {
                                          varA.splice(indexKrg + 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes("=")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx = getOccurrence(varA, "=");
                                      for (let i = 0; i < idx; i++) {
                                        const indexKrg = varA.indexOf("=", i);
                                        if (varA[indexKrg - 1] === "") {
                                          varA.splice(indexKrg - 1, 1);
                                        }
                                      }
                                    }
                                    setVarLast(varB);
                                    setSplited(varA);
                                  } else {
                                    let varA = e.target.value.split(/([\[\]\(\)?:+*/%]|-|AND|OR)/g);
                                    let varB = varA[0];
                                    if (varA.includes("(")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx = getOccurrence(varA, "(");
                                      for (let i = 0; i < idx; i++) {
                                        const indexKrg = varA.indexOf("(", i);
                                        if (varA[indexKrg - 1] === "") {
                                          varA.splice(indexKrg - 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes("[")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx = getOccurrence(varA, "[");
                                      for (let i = 0; i < idx; i++) {
                                        const indexKrg = varA.indexOf("[", i);
                                        if (varA[indexKrg - 1] === "") {
                                          varA.splice(indexKrg - 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes(")")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx2 = getOccurrence(varA, ")");
                                      for (let i = 0; i < idx2; i++) {
                                        const indexKrg = varA.indexOf(")", i);
                                        if (varA[indexKrg + 1] === "") {
                                          varA.splice(indexKrg + 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes("]")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx2 = getOccurrence(varA, "]");
                                      for (let i = 0; i < idx2; i++) {
                                        const indexKrg = varA.indexOf("]", i);
                                        if (varA[indexKrg + 1] === "") {
                                          varA.splice(indexKrg + 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes("?")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx = getOccurrence(varA, "?");
                                      for (let i = 0; i < idx; i++) {
                                        const indexKrg = varA.indexOf("?", i);
                                        if (varA[indexKrg - 1] === "") {
                                          varA.splice(indexKrg - 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes(":")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx = getOccurrence(varA, ":");
                                      for (let i = 0; i < idx; i++) {
                                        const indexKrg = varA.indexOf(":", i);
                                        if (varA[indexKrg - 1] === "") {
                                          varA.splice(indexKrg - 1, 1);
                                        }
                                      }
                                    }
                                    if (varA.includes("=")) {
                                      const getOccurrence = (array, value) => {
                                        return array.filter((v) => v === value).length;
                                      };
                                      let idx = getOccurrence(varA, "=");
                                      for (let i = 0; i < idx; i++) {
                                        const indexKrg = varA.indexOf("=", i);
                                        if (varA[indexKrg - 1] === "") {
                                          varA.splice(indexKrg - 1, 1);
                                        }
                                      }
                                    }
                                    setVarLast(varB);
                                    setSplited(varA);
                                  }
                                }}
                                value={searchFormula}
                              />
                              {!klik ? (
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                  <DropdownToggle className="bg-transparent border border-white shadow-none "></DropdownToggle>
                                  <DropdownMenu className="col-lg-12">
                                    <DropdownItem header>{t("Formula")}</DropdownItem>
                                    {(inputListTable || []).map((item, idx) => {
                                      return (
                                        <DropdownItem
                                          key={idx}
                                          onClick={(e) => {
                                            console.log(e.target);
                                            if (splited) {
                                              const index = splited.indexOf(varLast);
                                              splited[index] = e.target.outerText;
                                              let join = splited.join();
                                              let rSplit = join.split("");
                                              const getOccurrence = (array, value) => {
                                                return array.filter((v) => v === value).length;
                                              };
                                              let idx = getOccurrence(rSplit, ",");
                                              for (let i = 0; i < idx; i++) {
                                                join = join.replace(",", "");
                                              }
                                              setSearchFormula(join);
                                            } else {
                                              let arr = e.target.outerText;
                                              let join = arr.join();
                                              let rSplit = join.split("");
                                              const getOccurrence = (array, value) => {
                                                return array.filter((v) => v === value).length;
                                              };
                                              let idx = getOccurrence(rSplit, ",");
                                              for (let i = 0; i < idx; i++) {
                                                join = join.replace(",", "");
                                              }
                                              setSplited(arr);
                                              setSearchFormula(join);
                                            }
                                          }}
                                        >
                                          {item.value}
                                        </DropdownItem>
                                      );
                                    })}
                                  </DropdownMenu>
                                </Dropdown>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : null}
                          {selectVal === "lookup_table" ? (
                            <div className="mb-3">
                              <Label htmlFor="active-status" className="form-label">
                                {selectVal === "lookup_table" ? "Param Lookup Value " : selectVal === "range_table" ? "Param Range Value " : "Param "}
                                <span className="text-danger">*</span>
                              </Label>

                              <Select
                                name="tableParam"
                                value={validationTable.values.tableParam}
                                validate={{ required: { value: true } }}
                                placeholder={`${t("Select")} ${t("Param")}`}
                                onChange={(e) => {
                                  validationTable.setFieldValue("tableParam", e);
                                }}
                                options={tableParamList}
                                invalid={validationTable.touched.tableParam && validationTable.errors.tableParam ? true : false}
                              ></Select>
                              {validationTable.touched.tableParam && validationTable.errors.tableParam ? (
                                <p className="text-danger" type="invalid">
                                  {validationTable.errors.tableParam}
                                </p>
                              ) : null}
                            </div>
                          ) : null}

                          <div className="mb-3">
                            <Label htmlFor="active-status" className="form-label">
                              Status <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="status"
                              value={validationTable.values.status}
                              validate={{ required: { value: true } }}
                              aria-invalid={validation.touched.status}
                              aria-errormessage={validation.errors.status}
                              placeholder={`${t("Select")} ${t("Status")}`}
                              onChange={(e) => {
                                validationTable.setFieldValue("status", e);
                              }}
                              options={statusActiveData}
                              invalid={validationTable.touched.status && validationTable.errors.status ? true : false}
                            ></Select>
                            {validationTable.touched.status && (
                              <p role="alert" style={{ color: "#f06548", fontSize: "11px" }}>
                                {validationTable.errors.status}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModalTable(false);
                            setValuesKey("");
                            setValuesValue("");
                            setValuesStart("");
                            setValuesEnd("");
                            setSelectVal("");
                            validationTable.resetForm();
                            setTableItem([]);
                            // validationTable.resetForm()
                          }}
                        >
                          {t("Close")}
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">{!!isEditTable ? `${t("Update")}  ${t("Table")}` : `${t("Add")}  ${t("Table")}`}</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <Modal id="showModalUpload" isOpen={modalUpload} centered size="md">
                  <ModalHeader className="bg-light p-3" toggle={toggleUpload}>
                    {t("Upload Setting")}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="row">
                        {selectVal === "lookup_table" ? (
                          <div>
                            <div className="mb-3 col-lg-12">
                              <Label htmlFor="label-field" className="form-label">
                                {t("Key")}
                              </Label>
                              <Input
                                name="key"
                                id="key-field"
                                className="form-control"
                                placeholder={`${t("Enter")} ${t("Key")}`}
                                type="text"
                                onChange={(e) => {
                                  setValuesKey(e.target.value);
                                }}
                                value={valuesKey || ""}
                              />
                            </div>
                            <div className="mb-3 col-lg-12">
                              <Label htmlFor="value-field" className="form-label">
                                {t("Value")}
                              </Label>
                              <Input
                                name="value"
                                id="value-field"
                                className="form-control"
                                placeholder={`${t("Enter")} ${t("Value")}`}
                                type="text"
                                onChange={(e) => {
                                  setValuesValue(e.target.value);
                                }}
                                value={valuesValue || ""}
                              />
                            </div>
                            <div className="mb-3 col-lg-12">
                              <Input
                                type="file"
                                id="input_file"
                                onChange={(e) => {
                                  if (e.target.files[0]) {
                                    setUploadFile(e.target.files[0]);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ) : selectVal === "range_table" ? (
                          <div>
                            <div className="mb-3 col-lg-12">
                              <Label htmlFor="label-field" className="form-label">
                                {t("Start")}
                              </Label>
                              <Input
                                name="start"
                                id="start-field"
                                className="form-control"
                                placeholder={`${t("Enter")} ${t("Start")}`}
                                type="text"
                                onChange={(e) => {
                                  setValuesStart(e.target.value);
                                }}
                                value={valuesStart || ""}
                              />
                            </div>
                            <div className="mb-3 col-lg-12">
                              <Label htmlFor="label-field" className="form-label">
                                End
                              </Label>
                              <Input
                                name="ens"
                                id="end-field"
                                className="form-control"
                                placeholder={`${t("Enter")} ${t("End")}`}
                                type="text"
                                onChange={(e) => {
                                  setValuesEnd(e.target.value);
                                }}
                                value={valuesEnd || ""}
                              />
                            </div>
                            <div className="mb-3 col-lg-12">
                              <Label htmlFor="value-field" className="form-label">
                                {t("Value")}
                              </Label>
                              <Input
                                name="value"
                                id="value-field"
                                className="form-control"
                                placeholder={`${t("Enter")} ${t("Value")}`}
                                type="text"
                                onChange={(e) => {
                                  setValuesValue(e.target.value);
                                }}
                                value={valuesValue || ""}
                              />
                            </div>
                            <Input
                              type="file"
                              id="input_file"
                              onChange={(e) => {
                                if (e.target.files[0]) {
                                  setUploadFile(e.target.files[0]);
                                }
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModalUpload(false);
                            setValuesKey("");
                            setValuesValue("");
                            setValuesStart("");
                            setValuesEnd("");
                          }}
                        >
                          {t("Close")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => {
                            setModalUpload(false);
                            setValuesKey("");
                            setValuesValue("");
                            setValuesStart("");
                            setValuesEnd("");
                            olahUpload(uploadFile);
                          }}
                        >
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">{t("Submit")}</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <Modal id="showModalDetail" isOpen={modalDetail} centered size="lg">
                  <ModalHeader className="bg-light p-3" toggle={toggleDetail}>
                    {!!isEditDetail ? `${t("Edit")}  ${t("Detail")}` : `${t("Add")}  ${t("Detail")}`}
                  </ModalHeader>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationDetail.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            {!deviceId ? (
                              <Alert color="danger">
                                <div>{t("You must select the device first")}</div>
                              </Alert>
                            ) : null}
                            <div className="mb-3">
                              <Label htmlFor="password-field" className="form-label">
                                {t("Sensor")} <span className="text-danger">*</span>
                              </Label>
                              {loading ? (
                                <>
                                  <Spinner color="primary" type="grow" size={"sm"} className="ms-2">
                                    Loading...
                                  </Spinner>
                                  &nbsp;
                                  <Spinner color="success" type="grow" size={"sm"}>
                                    Loading...
                                  </Spinner>
                                  &nbsp;
                                </>
                              ) : (
                                ""
                              )}
                              <Select
                                name="sensor"
                                value={validationDetail.values.sensor}
                                aria-invalid={validationDetail.touched.sensor}
                                aria-errormessage={validationDetail.errors.sensor}
                                placeholder={`${t("Select")} ${t("Sensor")}`}
                                onChange={(e) => {
                                  validationDetail.setFieldValue("sensor", e);
                                }}
                                options={sensorSelectList}
                              ></Select>
                              {validationDetail.touched.sensor && (
                                <p role="alert" style={{ color: "#f06548", fontSize: "11px" }}>
                                  {validationDetail.errors.sensor}
                                </p>
                              )}
                            </div>
                            <Label htmlFor="label-field" className="form-label">
                              Label <span className="text-danger">*</span>
                            </Label>
                            <Input name="label" id="label-field" className="form-control" placeholder={`${t("Enter")} ${t("Label")}`} validate={{ required: { value: true } }} type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.label || ""} invalid={validationDetail.touched.label && validationDetail.errors.label ? true : false} />
                            {validationDetail.touched.label && validationDetail.errors.label ? <FormFeedback type="invalid">{validationDetail.errors.label}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="value-field" className="form-label">
                              {t("Value")} <span className="text-danger">*</span>
                            </Label>
                            <Input name="value" id="value-field" className="form-control" placeholder={`${t("Enter")} ${t("Value")}`} type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.value} invalid={validationDetail.touched.value && validationDetail.errors.value ? true : false} />
                            {validationDetail.touched.value && validationDetail.errors.value ? <FormFeedback type="invalid">{validationDetail.errors.value}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="value-field" className="form-label">
                              {t("Unit Of Measure")}
                            </Label>
                            <Input name="uom" id="uom-field" className="form-control" placeholder={`${t("Enter")} ${t("Unit Of Measure")}`} type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.uom} invalid={validationDetail.touched.uom && validationDetail.errors.uom ? true : false} />
                            {validationDetail.touched.uom && validationDetail.errors.uom ? <FormFeedback type="invalid">{validationDetail.errors.uom}</FormFeedback> : null}
                          </div>
                          <div className="mb-4 position-relative">
                            <Label htmlFor="value-field" className="form-label">
                              {t("Formula")}
                            </Label>
                            <Input
                              name="formula"
                              id="formula-field"
                              className="form-control"
                              placeholder={`${t("Enter")} ${t("Formula")}`}
                              type="text"
                              onChange={(e) => {
                                setSearchFormula(e.target.value);
                                setDropdownOpen(true);
                                if (e.target.value.length > 0) {
                                  let varA = e.target.value.split(/([-+*/%()[\]])/g);
                                  let varB = varA[varA.length - 1];
                                  if (varA.includes("(")) {
                                    const getOccurrence = (array, value) => {
                                      return array.filter((v) => v === value).length;
                                    };
                                    let idx = getOccurrence(varA, "(");
                                    for (let i = 0; i < idx; i++) {
                                      const indexKrg = varA.indexOf("(", i);
                                      if (varA[indexKrg - 1] === "") {
                                        varA.splice(indexKrg - 1, 1);
                                      }
                                    }
                                  }
                                  if (varA.includes("[")) {
                                    const getOccurrence = (array, value) => {
                                      return array.filter((v) => v === value).length;
                                    };
                                    let idx = getOccurrence(varA, "[");
                                    for (let i = 0; i < idx; i++) {
                                      const indexKrg = varA.indexOf("[", i);
                                      if (varA[indexKrg - 1] === "") {
                                        varA.splice(indexKrg - 1, 1);
                                      }
                                    }
                                  }
                                  if (varA.includes(")")) {
                                    const getOccurrence = (array, value) => {
                                      return array.filter((v) => v === value).length;
                                    };
                                    let idx2 = getOccurrence(varA, ")");
                                    for (let i = 0; i < idx2; i++) {
                                      const indexKrg = varA.indexOf(")", i);
                                      if (varA[indexKrg + 1] === "") {
                                        varA.splice(indexKrg + 1, 1);
                                      }
                                    }
                                  }
                                  if (varA.includes("]")) {
                                    const getOccurrence = (array, value) => {
                                      return array.filter((v) => v === value).length;
                                    };
                                    let idx2 = getOccurrence(varA, "]");
                                    for (let i = 0; i < idx2; i++) {
                                      const indexKrg = varA.indexOf("]", i);
                                      if (varA[indexKrg + 1] === "") {
                                        varA.splice(indexKrg + 1, 1);
                                      }
                                    }
                                  }
                                  setVarLast(varB);
                                  setSplited(varA);
                                } else {
                                  let varA = e.target.value.split(/([-+*/%()[\]])/g);
                                  let varB = varA[0];
                                  if (varA.includes("(")) {
                                    const getOccurrence = (array, value) => {
                                      return array.filter((v) => v === value).length;
                                    };
                                    let idx = getOccurrence(varA, "(");
                                    for (let i = 0; i < idx; i++) {
                                      const indexKrg = varA.indexOf("(", i);
                                      if (varA[indexKrg - 1] === "") {
                                        varA.splice(indexKrg - 1, 1);
                                      }
                                    }
                                  }
                                  if (varA.includes("[")) {
                                    const getOccurrence = (array, value) => {
                                      return array.filter((v) => v === value).length;
                                    };
                                    let idx = getOccurrence(varA, "[");
                                    for (let i = 0; i < idx; i++) {
                                      const indexKrg = varA.indexOf("[", i);
                                      if (varA[indexKrg - 1] === "") {
                                        varA.splice(indexKrg - 1, 1);
                                      }
                                    }
                                  }
                                  if (varA.includes(")")) {
                                    const getOccurrence = (array, value) => {
                                      return array.filter((v) => v === value).length;
                                    };
                                    let idx2 = getOccurrence(varA, ")");
                                    for (let i = 0; i < idx2; i++) {
                                      const indexKrg = varA.indexOf(")", i);
                                      if (varA[indexKrg + 1] === "") {
                                        varA.splice(indexKrg + 1, 1);
                                      }
                                    }
                                  }
                                  if (varA.includes("]")) {
                                    const getOccurrence = (array, value) => {
                                      return array.filter((v) => v === value).length;
                                    };
                                    let idx2 = getOccurrence(varA, "]");
                                    for (let i = 0; i < idx2; i++) {
                                      const indexKrg = varA.indexOf("]", i);
                                      if (varA[indexKrg + 1] === "") {
                                        varA.splice(indexKrg + 1, 1);
                                      }
                                    }
                                  }
                                  setVarLast(varB);
                                  setSplited(varA);
                                }
                              }}
                              value={searchFormula.replace(/[{}]/g, "")}
                            />
                            {!klik ? (
                              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle className="bg-transparent border border-white shadow-none "></DropdownToggle>
                                <DropdownMenu className="col-lg-12">
                                  <DropdownItem header>{t("Formula")}</DropdownItem>
                                  {(inputList || []).map((item, idx) => {
                                    return (
                                      <DropdownItem
                                        key={idx}
                                        onClick={(e) => {
                                          if (splited) {
                                            const index = splited.indexOf(varLast);
                                            splited[index] = e.target.outerText;
                                            let join = splited.join();
                                            let rSplit = join.split("");
                                            const getOccurrence = (array, value) => {
                                              return array.filter((v) => v === value).length;
                                            };
                                            let idx = getOccurrence(rSplit, ",");
                                            for (let i = 0; i < idx; i++) {
                                              join = join.replace(",", "");
                                            }
                                            setSearchFormula(join);
                                          } else {
                                            let arr = e.target.outerText;
                                            let join = arr.join();
                                            let rSplit = join.split("");
                                            const getOccurrence = (array, value) => {
                                              return array.filter((v) => v === value).length;
                                            };
                                            let idx = getOccurrence(rSplit, ",");
                                            for (let i = 0; i < idx; i++) {
                                              join = join.replace(",", "");
                                            }
                                            setSplited(arr);
                                            setSearchFormula(join);
                                          }
                                        }}
                                      >
                                        {item.value}
                                      </DropdownItem>
                                    );
                                  })}
                                </DropdownMenu>
                              </Dropdown>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="mb-3 pt-1">
                            <Label htmlFor="formulaSequence-field" className="form-label">
                              {t("Formula Sequence")}
                            </Label>
                            <Input name="formulaSequence" id="formulaSequence-field" className="form-control" placeholder={`${t("Enter")} ${t("Formula Sequence")}`} type="number" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.formulaSequence} invalid={validationDetail.touched.formulaSequence && validationDetail.errors.formulaSequence ? true : false} />
                            {validationDetail.touched.formulaSequence && validationDetail.errors.formulaSequence ? <FormFeedback type="invalid">{validationDetail.errors.formulaSequence}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="active-status" className="form-label">
                              Status <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="status"
                              value={validationDetail.values.status}
                              aria-invalid={validation.touched.status}
                              aria-errormessage={validation.errors.status}
                              validate={{ required: { value: true } }}
                              placeholder={`${t("Select")} ${t("Status")}`}
                              onChange={(e) => {
                                validationDetail.setFieldValue("status", e);
                              }}
                              options={statusActiveData}
                              invalid={validationDetail.touched.status && validationDetail.errors.status ? true : false}
                            ></Select>
                            {validationDetail.touched.status && (
                              <p role="alert" style={{ color: "#f06548", fontSize: "11px" }}>
                                {validationDetail.errors.status}
                              </p>
                            )}
                          </div>
                          {/* <div className="mb-3">
                            <Input name="showInDashboard" id="showInDashboard-field" className="form-check-input" type="checkbox" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} checked={validationDetail.values.showInDashboard} /> &nbsp;
                            <Label htmlFor="showInDashboard-field" className="form-label">
                              {t("Show In Dashboard")}
                            </Label>
                          </div> */}
                          <div className="mb-3">
                            <Input name="showParameter" id="showParameter-field" className="form-check-input" type="checkbox" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} checked={validationDetail.values.showParameter} /> &nbsp;
                            <Label htmlFor="showParameter-field" className="form-label">
                              {t("Show Parameter")}
                            </Label>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="warningUnder-field" className="form-label">
                              {t("Warning Under")}
                            </Label>
                            <Input name="warningUnder" id="warningUnder-field" className="form-control" placeholder={`${t("Enter")} ${t("Warning Under")}`} type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.warningUnder} invalid={validationDetail.touched.warningUnder && validationDetail.errors.warningUnder ? true : false} />
                            {validationDetail.touched.warningUnder && validationDetail.errors.warningUnder ? <FormFeedback type="invalid">{validationDetail.errors.warningUnder}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="criticalUnder-field" className="form-label">
                              {t("Critical Under")}
                            </Label>
                            <Input name="criticalUnder" id="criticalUnder-field" className="form-control" placeholder={`${t("Enter")} ${t("Critical Under")}`} type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.criticalUnder} invalid={validationDetail.touched.criticalUnder && validationDetail.errors.criticalUnder ? true : false} />
                            {validationDetail.touched.criticalUnder && validationDetail.errors.criticalUnder ? <FormFeedback type="invalid">{validationDetail.errors.criticalUnder}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="warningOver-field" className="form-label">
                              {t("Warning Over")}
                            </Label>
                            <Input name="warningOver" id="warningOver-field" className="form-control" placeholder={`${t("Enter")} ${t("Warning Over")}`} type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.warningOver} invalid={validationDetail.touched.warningOver && validationDetail.errors.warningOver ? true : false} />
                            {validationDetail.touched.warningOver && validationDetail.errors.warningOver ? <FormFeedback type="invalid">{validationDetail.errors.warningOver}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="criticalOver-field" className="form-label">
                              {t("Critical Over")}
                            </Label>
                            <Input name="criticalOver" id="criticalOver-field" className="form-control" placeholder={`${t("Enter")} ${t("Critical Over")}`} type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.criticalOver} invalid={validationDetail.touched.criticalOver && validationDetail.errors.criticalOver ? true : false} />
                            {validationDetail.touched.criticalOver && validationDetail.errors.criticalOver ? <FormFeedback type="invalid">{validationDetail.errors.criticalOver}</FormFeedback> : null}
                          </div>
                          <div className="mb-5">
                            <Label htmlFor="scaleFactor-field" className="form-label">
                              {t("Scale Factor")}
                            </Label>
                            <Input name="scaleFactor" id="scaleFactor-field" className="form-control" placeholder={`${t("Enter")} ${t("Scale Factor")}`} type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.scaleFactor} invalid={validationDetail.touched.scaleFactor && validationDetail.errors.scaleFactor ? true : false} />
                            {validationDetail.touched.scaleFactor && validationDetail.errors.scaleFactor ? <FormFeedback type="invalid">{validationDetail.errors.scaleFactor}</FormFeedback> : null}
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="value-field" className="form-label">
                              {t("Amount")}
                            </Label>
                            <Input name="amount" id="amount-field" className="form-control" placeholder={`${t("Enter")} ${t("Amount")}`} type="text" onChange={validationDetail.handleChange} onBlur={validationDetail.handleBlur} value={validationDetail.values.amount} invalid={validationDetail.touched.amount && validationDetail.errors.amount ? true : false} />
                            {validationDetail.touched.amount && validationDetail.errors.amount ? <FormFeedback type="invalid">{validationDetail.errors.amount}</FormFeedback> : null}
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <div className="modal-footer">
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => {
                            setModalDetail(false);
                            setSearchFormula("");
                            setSensorDetail([]);
                          }}
                        >
                          {t("Close")}
                        </button>
                        <button type="submit" className="btn btn-success">
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">{!!isEditDetail ? `${t("Update")}  ${t("Detail")}` : `${t("Add")}  ${t("Detail")}`}</span>
                            {loading && (
                              <Spinner size="sm" className="flex-shrink-0" role="status">
                                Loading...
                              </Spinner>
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </Modal>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
          <Col xxl={7}>
            <ul className="nav nav-pills nav-justified" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="parameter-tab" data-bs-toggle="tab" data-bs-target="#parameter" type="button" role="tab" aria-controls="parameter" aria-selected="true">
                  Parameter
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="table-tab" data-bs-toggle="tab" data-bs-target="#table" type="button" role="tab" aria-controls="table" aria-selected="false">
                  {t("Table")} Parameter
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div className="tab-pane active" id="parameter" role="tabpanel" aria-labelledby="parameter-tab" tabIndex="0">
                <Card>
                  <div className="card-body d-flex align-items-center">
                    <h5 className="text-uppercase mb-2 flex-grow-1">
                      {sensorName}
                      {parameterLoading ? (
                        <>
                          <Spinner color="primary" type="grow" size={"sm"}></Spinner>
                          &nbsp;
                          <Spinner color="success" type="grow" size={"sm"}>
                            Loading...
                          </Spinner>
                          &nbsp;
                        </>
                      ) : (
                        ""
                      )}
                    </h5>

                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        className="btn btn-success add-btn"
                        id="create-btn"
                        onClick={() => {
                          setSensor(info);
                          setIsEditDetail(false);
                          if (info && info.id) {
                            toggleDetail();
                            var data = [];
                            var opt = [{ label: "Select All", value: "all" }];
                            info.pc_detail &&
                              info.pc_detail.forEach((item, key) => {
                                if (item.component_type === "Allowance") {
                                  const detail_name = item.detail_name;
                                  const id = item.id;
                                  var obj = { label: detail_name, value: id };
                                  opt.push(obj);
                                }
                              });
                            var option = { options: opt };
                            data.push(option);
                          } else {
                            toast(t("You have not selected parameters!"), {
                              position: "top-center",
                              hideProgressBar: true,
                              closeOnClick: false,
                              className: "bg-danger text-white",
                              autoClose: 3000,
                            });
                          }
                        }}
                      >
                        <i className="ri-list-settings-line label-icon align-middle fs-13 me-1"></i>
                        {`${t("Add")} Parameter`}
                      </button>
                      <button
                        className="btn btn-soft-danger ms-1"
                        onClick={() => {
                          const ele = document.querySelectorAll(".dataCheckbox:checked");
                          ele.forEach((element) => {
                            dispatch(onDeleteParameter(element.value));
                          });
                        }}
                      >
                        <i className="ri-delete-bin-5-line me-1 align-bottom fs-13"></i>
                      </button>
                    </div>
                  </div>
                  <Row>
                    <CardBody>
                      <Nav className="nav-tabs nav-tabs-custom nav-success mb-3" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "1" }, "fw-semibold")}
                            onClick={() => {
                              toggleTab("1", "all");
                            }}
                            href="#"
                          >
                            <i className=" ri-list-settings-line me-1 align-bottom fs-14"></i>
                            {t("All Parameters")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "2" }, "fw-semibold")}
                            onClick={() => {
                              toggleTab("2", "Draft");
                            }}
                            href="#"
                          >
                            <i className="ri-draft-line me-1 align-bottom fs-14"></i>
                            {t("Draft")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "3" }, "fw-semibold")}
                            onClick={() => {
                              toggleTab("3", "Activated");
                            }}
                            href="#"
                          >
                            <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i>
                            {t("Activated")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "4" }, "fw-semibold")}
                            onClick={() => {
                              toggleTab("4", "Deactivated");
                            }}
                            href="#"
                          >
                            <i className="ri-indeterminate-circle-line me-1 align-bottom fs-14"></i>
                            {t("Deactivated")}
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <form>
                        <Row>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValueSecond(e.target.value);
                                  onChangeSecond(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${t("Search")}...`}
                                value={searchValueSecond || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>

                  <div className="table-responsive table-card m-0">
                    <Table hover {...getTablePropsSecond()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroupsSecond.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyPropsSecond()}>
                        {pageSecond.map((row) => {
                          prepareRowSecond(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={previousPageSecond} disabled={!canPreviousPageSecond}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      {`${t("Page")} `}
                      <strong>
                        {pageIndexSecond + 1} {t("of")} {pageOptionsSecond.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptionsSecond.length} value={pIndexSecond + 1} onChange={onChangeInInput} />
                    </Col>

                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPageSecond} disabled={!canNextPageSecond}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div className="tab-pane" id="table" role="tabpanel" aria-labelledby="table-tab" tabIndex="0">
                <Card>
                  <div className="card-body d-flex align-items-center">
                    <h5 className="text-uppercase mb-2 flex-grow-1">
                      {sensorName}
                      {parameterLoading ? (
                        <>
                          <Spinner color="primary" type="grow" size={"sm"}></Spinner>
                          &nbsp;
                          <Spinner color="success" type="grow" size={"sm"}>
                            Loading...
                          </Spinner>
                          &nbsp;
                        </>
                      ) : (
                        ""
                      )}
                    </h5>
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        className="btn btn-success add-btn"
                        id="create-btn"
                        onClick={() => {
                          setIsEditTable(false);
                          toggleTable();
                        }}
                      >
                        <i className="ri-list-settings-line label-icon align-middle fs-13 me-1"></i>
                        {`${t("Add")} ${t("Table")}`}
                      </button>
                      <button
                        className="btn btn-soft-danger ms-1"
                        onClick={() => {
                          const ele = document.querySelectorAll(".dataCheckbox:checked");
                          ele.forEach((element) => {
                            dispatch(onDeleteParameter(element.value));
                          });
                        }}
                      >
                        <i className="ri-delete-bin-5-line me-1 align-bottom fs-13"></i>
                      </button>
                    </div>
                  </div>
                  <Row className="ms-2">
                    <CardBody>
                      <Nav className="nav-tabs nav-tabs-custom nav-success mb-3" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTabSecond === "1" }, "fw-semibold")}
                            onClick={() => {
                              toggleTabSecond("1", "all");
                            }}
                            href="#"
                          >
                            <i className=" ri-list-settings-line me-1 align-bottom fs-14"></i>
                            {t("All Parameters")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTabSecond === "2" }, "fw-semibold")}
                            onClick={() => {
                              toggleTabSecond("2", "Draft");
                            }}
                            href="#"
                          >
                            <i className="ri-draft-line me-1 align-bottom fs-14"></i>
                            {t("Draft")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTabSecond === "3" }, "fw-semibold")}
                            onClick={() => {
                              toggleTabSecond("3", "Activated");
                            }}
                            href="#"
                          >
                            <i className="ri-checkbox-circle-line me-1 align-bottom fs-14"></i>
                            {t("Activated")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTabSecond === "4" }, "fw-semibold")}
                            onClick={() => {
                              toggleTabSecond("4", "Deactivated");
                            }}
                            href="#"
                          >
                            <i className="ri-indeterminate-circle-line me-1 align-bottom fs-14"></i>
                            {t("Deactivated")}
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <form>
                        <Row>
                          <Col sm={4}>
                            <div className="search-box me-2 mb-2 d-inline-block col-12">
                              <input
                                onChange={(e) => {
                                  setSearchValueThird(e.target.value);
                                  onChangeThird(e.target.value);
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control search"
                                placeholder={`${t("Search")}...`}
                                value={searchValueThird || ""}
                              />
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Row>
                  <div className="table-responsive table-card m-0">
                    <Table hover {...getTablePropsThird()} className="align-middle table-nowrap">
                      <thead className="table-light text-muted">
                        {headerGroupsThird.map((headerGroup) => (
                          <tr className={""} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th key={column.id} className={"" + generateFilterable(column)} {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyPropsThird()}>
                        {pageThird.map((row) => {
                          prepareRowThird(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={previousPageThird} disabled={!canPreviousPageThird}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                      {`${t("Page")} `}
                      <strong>
                        {pageIndexThird + 1} {t("of")} {pageOptionsThird.length}
                      </strong>
                    </Col>
                    <Col className="col-md-auto">
                      <Input type="number" min={1} style={{ width: 70 }} max={pageOptionsThird.length} value={pIndexThird + 1} onChange={onChangeInInput} />
                    </Col>

                    <Col className="col-md-auto">
                      <div className="d-flex gap-1">
                        <Button className="btn btn-light" onClick={nextPageThird} disabled={!canNextPageThird}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Parameter.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Parameter));
