import {
  REPORTTEMPLATE_LOADING,
  GET_REPORTTEMPLATE,
  GET_REPORTTEMPLATE_SUCCESS,
  GET_REPORTTEMPLATE_FAIL,
  ADD_REPORTTEMPLATE_SUCCESS,
  ADD_REPORTTEMPLATE_FAIL,
  DELETE_REPORTTEMPLATE_SUCCESS,
  DELETE_REPORTTEMPLATE_FAIL,
  UPDATE_REPORTTEMPLATE_SUCCESS,
  UPDATE_REPORTTEMPLATE_FAIL,
  REPORTTEMPLATE_RESET,
} from "./actionType";

const INIT_STATE = {
  reportTemplates: [],
  error: {},
};

const ReportTemplate = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTTEMPLATE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_REPORTTEMPLATE:
          return {
            ...state,
            reportTemplates: action.payload.data,
            isReportTemplateCreated: false,
            isReportTemplateSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_REPORTTEMPLATE_FAIL:
      switch (action.payload.actionType) {
        case GET_REPORTTEMPLATE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isReportTemplateCreated: false,
            isReportTemplateSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_REPORTTEMPLATE: {
      return {
        ...state,
        isReportTemplateCreated: false,
        loading: true,
      };
    }

    case REPORTTEMPLATE_LOADING: {
      return {
        ...state,
        isReportTemplateCreated: false,
        loading: true,
      };
    }

    case ADD_REPORTTEMPLATE_SUCCESS:
      return {
        ...state,
        isReportTemplateCreated: true,
        loading: false,
        reportTemplates: [...state.reportTemplates, action.payload.data],
      };

    case ADD_REPORTTEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_REPORTTEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        reportTemplates: state.reportTemplates.filter(
          (reportTemplate) => reportTemplate.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_REPORTTEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_REPORTTEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        reportTemplates: state.reportTemplates.map((reportTemplate) =>
          reportTemplate.id.toString() === action.payload.data.id.toString()
            ? { ...reportTemplate, ...action.payload.data }
            : reportTemplate
        ),
      };

    case UPDATE_REPORTTEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REPORTTEMPLATE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default ReportTemplate;
