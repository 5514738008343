import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Fragment,
} from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Input,
  CardHeader,
  Table,
  Spinner,
  Nav,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment-timezone";

import dummyImg from "../../assets/images/iot_device.png";

import { useSelector, useDispatch } from "react-redux";
import {
  getDevice as onGetDevices,
  resetDeviceState,
} from "../../store/device/action";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty } from "lodash";
import { getHistory, getHistoryPublic, resetHistoryState } from "../../store/history/action";
import { getClient, resetClientState } from "../../store/client/action";
import { api } from "../../config";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";

import socketIO from "../../helpers/socket";
import AlertWidget from "../../Components/Common/AlertWidget";
import TaskDeviceComponent from "../../Components/Common/TaskDeviceComponent";
import CardParamsComponent from "../../Components/Common/CardParamsComponent";
import StatusWidget from "../../Components/Common/StatusWidget";
import StatisticComponent from "../../Components/Common/StatisticComponent";
import ParamsHistoryComponent from "../../Components/Common/ParamsHistoryComponent";
import { extendMoment } from "moment-range";
import { BsDeviceSsdFill } from "react-icons/bs";
import { MdOutlineWifi, MdOutlineWifiOff } from "react-icons/md";
const io = socketIO.getInstance();
io.setHost("https://socket.atapteknologi.id");
const socket = io.getSocket();

const DeviceDetail = (props) => {
  const { t } = props;
  const moments = extendMoment(moment);
  const [modal, setModal] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [info, setInfo] = useState([]);
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState("user");
  const [approveModal, setApproveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [searchValue, setSearchValue] = useState([]);
  const [primaryImg, setPrimaryImg] = useState();
  const [series, setSeries] = useState([]);
  const [seriesCategories, setSeriesCategories] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [uidEvents, setUidEvents] = useState([]);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [labels, setLabels] = useState([]);
  const [dataUid, setDataUid] = useState([]);
  const [dataStatus, setDataStatus] = useState([]);

  const defaultdate = () => {
    return moment(new Date())
      .tz("Asia/Jakarta")
      .format("YYYY/MM/DD HH:mm:ss")
      .toString();
  };

  const [filterDate, setFilterDate] = useState(defaultdate());

  const dispatch = useDispatch();

  const {
    devices,
    parameters,
    isDeviceCreated,
    isDeviceSuccess,
    loading,
    error,
    parameterLoading,
    deviceOnes,
    histories,
    clients,
    notifications,
    historyPublics,
    publicLoading
  } = useSelector((state) => ({
    devices: state.Device.devices,
    isDeviceCreated: state.Device.isDeviceCreated,
    isDeviceSuccess: state.Device.isDeviceSuccess,
    loading: state.Device.loading,
    error: state.Device.error,
    deviceOnes: state.Device.deviceOnes,
    histories: state.History.histories,
    clients: state.Client.clients,
    parameters: state.Parameter.parameters,
    notifications: state.Notification.notifications,
    historyPublics: state.History.historyPublics,
    publicLoading: state.History.publicLoading
  }));

  const onClickApprove = (employee) => {
    setApproveModal(true);
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);

  const onClickDelete = (device) => {
    setDeleteModal(true);
  };

  const handleValidDate = (date) => {
    return moment(date).tz("Asia/Jakarta").format("MMM D, YYYY HH:mm");
  };

  const handleDeviceClick = useCallback(
    (arg) => {
      const data = arg;
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const getDevice = useMemo(() => {
    if (devices) {
      return devices;
    }
  }, [devices]);

  const columns = useMemo(
    () => [
      {
        Header: " ",
        maxWidth: 100,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className='hstack gap-2 mt-4 mt-sm-0'>
              {cellProps.row.original.statusActiveId === 1 ? (
                <button
                  className='btn-label btn btn-sm btn-soft-success fs-12 me-0'
                  onClick={() => {
                    const data = cellProps.row.original;
                    onClickApprove(data);
                  }}>
                  <i className='ri-checkbox-circle-line label-icon align-middle fs-16 me-1'></i>
                  {t("Approve")}
                </button>
              ) : (
                ""
              )}
              {isDone ? (
                <Link
                  to='#'
                  className='btn-icon btn btn-sm btn-soft-primary'
                  onClick={() => {
                    setInfo(cellProps.row.original);
                    setSeries([]);
                    setSeriesCategories([]);
                    setDataStatus([])
                  }}>
                  <i className='ri-eye-line fs-15'></i>
                </Link>
              ) : (
                <>
                  <Spinner color='primary' size={"sm"}>
                    Loading...
                  </Spinner>
                </>
              )
              }
              {(cellProps.row.original.status === "Signal" || cellProps.row.original.status === "signal") && cellProps.row.original.toMinute >= 10 ? (
                <MdOutlineWifiOff
                  className='fs-18 text-danger me-1 mt-0'
                  id={"wifi-" + cellProps.row.original.id}
                  style={{ cursor: "pointer" }}
                />
              ) : <MdOutlineWifi
                className='fs-18 text-success me-1 mt-0'
                id={"wifi-" + cellProps.row.original.id}
                style={{ cursor: "pointer" }}
              />}
              <UncontrolledTooltip
                placement="top"
                target={"wifi-" + cellProps.row.original.id}
                trigger="hover"
              >{(cellProps.row.original.status === "Signal" || cellProps.row.original.status === "signal") && cellProps.row.original.toMinute >= 30 ? "Jaringan Buruk" : "Jaringan Bagus"}</UncontrolledTooltip>
              {(cellProps.row.original.status === "Maintenance" || cellProps.row.original.status === "maintenance") && cellProps.row.original.toMinute >= 30 ? (
                <i className="ri-tools-line fs-18 text-danger me-1 mt-0" id={"maintenance-" + cellProps.row.original.id} style={{ cursor: "pointer" }}></i>
              ) : <i className="ri-tools-line fs-18 text-success me-1 mt-0" id={"maintenance-" + cellProps.row.original.id} style={{ cursor: "pointer" }}></i>}
              <UncontrolledTooltip
                placement="top"
                target={"maintenance-" + cellProps.row.original.id}
                trigger="hover"
              >{(cellProps.row.original.status === "Maintenance" || cellProps.row.original.status === "maintenance") && cellProps.row.original.toMinute >= 30 ? "Sedang ada maintenance" : "Tidak ada maintenance"}</UncontrolledTooltip>
              <BsDeviceSsdFill style={{ cursor: "pointer" }} className={cellProps.row.original.sensor_status === 0 ? ' fs-15 me-1 text-danger mt-0' : cellProps.row.original.sensor_status === 1 ? ' fs-15 me-1 text-success mt-0' : "fs-15 me-1 text-danger mb-2 mt-1"} id={"com-" + cellProps.row.original.id} />
              <UncontrolledTooltip
                placement="top"
                target={"com-" + cellProps.row.original.id}
                trigger="hover"
              >{cellProps.row.original.sensor_status === 0 ? 'Sensor Nonaktif' : cellProps.row.original.sensor_status === 1 ? 'Sensor Aktif' : 'Sensor Nonaktif'}</UncontrolledTooltip>
              <i
                className={
                  cellProps.row.original.toMinute <= 15
                    ? "ri-radio-button-line text-success me-1 fs-17 "
                    : cellProps.row.original.toMinute > 15 && cellProps.row.original.toMinute <= 30 ? "ri-radio-button-line text-warning me-1 fs-17 " : "ri-radio-button-line text-danger me-1 fs-17"
                } id={"dots-" + cellProps.row.original.id} style={{ cursor: "pointer" }}></i>
              <UncontrolledTooltip
                placement="top"
                target={"dots-" + cellProps.row.original.id}
                trigger="hover"
              >{
                  cellProps.row.original.toMinute <= 15
                    ? "Device Aktif"
                    : cellProps.row.original.toMinute > 15 && cellProps.row.original.toMinute <= 30 ? "Device Idle > 15 Menit" : "Device Nonaktif"
                }</UncontrolledTooltip>
              <Link
                to='#'
                className={
                  userType === "administrator"
                    ? "btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                    : roles && roles.split(",").indexOf("updateDevice") !== -1
                      ? "btn-icon btn btn-sm btn-soft-primary edit-item-btn"
                      : "btn-icon btn btn-sm btn-soft-primary edit-item-btn d-none"
                }
                onClick={() => {
                  handleDeviceClick(cellProps.row.original);
                }}>
                <i className='ri-pencil-line fs-16'></i>
              </Link>
              <Link
                to='#'
                className={
                  userType === "administrator"
                    ? "btn-icon btn btn-sm btn-soft-danger remove-item-btn"
                    : roles && roles.split(",").indexOf("deleteDevice") !== -1
                      ? "btn-icon btn btn-sm btn-soft-danger remove-item-btn"
                      : "btn-icon btn btn-sm btn-soft-danger remove-item-btn d-none"
                }
                onClick={() => {
                  const deviceData = cellProps.row.original;
                  onClickDelete(deviceData);
                }}>
                <i className=' ri-delete-bin-line fs-15'></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: `${t("Name")}`,
        accessor: "name",
        filterable: true,
      },
    ],
    [handleDeviceClick, userType, roles, t, isDone]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: deviceList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const count = preGlobalFilteredRows.length;
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable
      ? column.isSorted
        ? column.isSortedDesc
          ? " sorting_desc"
          : " sorting_asc"
        : " sort"
      : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetDeviceState());
      dispatch(onGetDevices({ status: 2 }));
      dispatch(resetClientState())
      dispatch(getClient());
      setIsDone(false)
      setIsSearch(true);
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if (obj) {
        if (
          obj.data &&
          obj.data.employee_position &&
          obj.data.employee_position.position_role
        ) {
          setRoles(obj.data.employee_position.position_role.role_method);
        } else setRoles(null);

        if (obj.data && obj.data.userType) setUserType(obj.data.userType);
      } else {
        window.location = "/logout";
      }
    }
  }, [dispatch, devices, isSearch]);

  useEffect(() => {
    if (info) {
      const image = info.image
        ? api.MEDIA_URL + "/media/" + info.image
        : dummyImg;
      setPrimaryImg(image);

      let data = [];
      if (info.device_file) {
        for (const b of info.device_file) {
          data.push(b);
        }
      }
      setFileList(data);
    }
  }, [info]);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  }, [dispatch, isLoaded]);

  useEffect(() => {
    if (!isEmpty(clients)) {
      let data = [];
      clients.forEach((client, i) => {
        client.cl_company.forEach((company) => {
          company.com_branch.forEach((branch) => {
            branch.br_unit.forEach((unit) => {
              unit.un_device.forEach((device) => {
                if (device.identifier && device.identifier !== null) {
                  data.push(device.identifier);
                }
              });
            });
          });
        });
      });
      setDataUid(JSON.stringify(data));
      setIsDone(false)
    }
  }, [clients]);

  useEffect(() => {
    if (!isDone && dataUid && dataUid.length > 0) {
      dispatch(resetHistoryState());
      dispatch(getHistory({ type: "current", uid: dataUid }));
    }
  }, [isDone, dispatch, dataUid]);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  });

  useEffect(() => {
    if (devices) {
      console.log(devices, "dev");
      setDeviceList(devices);
    }
  }, [devices]);

  const onUidEvent = useCallback(
    (value) => {
      const val = JSON.parse(value);
      const data = uidEvents.filter((item) => item.uid !== val.uid);
      data.push(val);
      setUidEvents(data);
    },
    [uidEvents]
  );

  useEffect(() => {
    deviceList.forEach((element) => {
      socket.on(element.identifier, onUidEvent);
    });

    return () => {
      deviceList.forEach((element) => {
        socket.off(element.identifier, onUidEvent);
      });
    };
  }, [deviceList, onUidEvent]);

  useEffect(() => {
    if (!isDone && histories.length > 0) {
      histories.forEach((element) => {
        devices.forEach((dev, devIndex) => {
          if (dev.identifier === element.uid) {
            dev.device_parameter &&
              dev.device_parameter.forEach((parameter, parameterIndex) => {
                if (element.message) {
                  const amount = element.message[parameter.value];
                  if (amount) {
                    devices[devIndex].device_parameter[parameterIndex].amount =
                      amount;
                  } else if (amount === 0) {
                    devices[devIndex].device_parameter[parameterIndex].amount =
                      "0";
                  } else if (amount === null) {
                    devices[devIndex].device_parameter[parameterIndex].amount =
                      "NA";
                  }

                  if (element.message.timestamp) {
                    const start = new Date(element.message.timestamp);
                    const end = new Date();
                    const range1 = moments.range(start, end);
                    const toMinute = range1.snapTo("minute");
                    devices[devIndex].toMinute = toMinute.diff("minutes")
                  } else if (element.device_time) {
                    const start = new Date(element.device_time);
                    const end = new Date();
                    const range1 = moments.range(start, end);
                    const toMinute = range1.snapTo("minute");
                    devices[devIndex].toMinute = toMinute.diff("minutes")
                  }

                  if (element.sensor_status) {
                    devices[devIndex].sensor_status = element.sensor_status
                  }
                }
              });
          }
        });
      });
      setDeviceList(devices);
      setIsDone(true);
    }
  }, [devices, histories, isDone, moments]);

  useEffect(() => {
    uidEvents.forEach((element) => {
      devices.forEach((dev, devIndex) => {
        if (dev.identifier === element.uid) {
          dev.device_parameter &&
            dev.device_parameter.forEach((parameter, parameterIndex) => {
              if (element.message) {
                const amount = element.message[parameter.value];
                if (amount) {
                  devices[devIndex].device_parameter[parameterIndex].amount =
                    amount;
                } else if (amount === 0) {
                  devices[devIndex].device_parameter[parameterIndex].amount =
                    "0";
                } else if (!amount) {
                  devices[devIndex].device_parameter[parameterIndex].amount =
                    "NA";
                }

                if (element.message.timestamp) {
                  const start = new Date(element.message.timestamp);
                  const end = new Date();
                  const range1 = moments.range(start, end);
                  const toMinute = range1.snapTo("minute");
                  devices[devIndex].toMinute = toMinute.diff("minutes")
                } else if (element.device_time) {
                  const start = new Date(element.device_time);
                  const end = new Date();
                  const range1 = moments.range(start, end);
                  const toMinute = range1.snapTo("minute");
                  devices[devIndex].toMinute = toMinute.diff("minutes")
                }

                if (element.sensor_status) {
                  devices[devIndex].sensor_status = element.sensor_status
                }
              }
            });
        }
      });
    });
    console.log(uidEvents);
    setDeviceList(devices);
  }, [uidEvents, devices, moments]);

  useEffect(() => {
    if (info && isDone) {
      let date = new Date();
      let dates = date.getTime();
      let data = []
      info.device_parameter && info.device_parameter.forEach((dev) => {
        data.push({
          label: dev.label,
          // shift: [],
          value: 0, isShift: false, condition: "", parameter: dev.value
        })
      })
      dispatch(
        getHistoryPublic({
          parameter: JSON.stringify({ parameter: data }),
          uid: info.identifier,
          type: "today",
          aggregate: "$first",
          group: "$hour",
        })
      );
    }
  }, [dispatch, info, isDone]);

  useEffect(() => {
    if (historyPublics && historyPublics.length > 0 && isDone) {
      historyPublics.sort((a, b) => a.hour - b.hour);

      console.log(historyPublics);

      let arrCat = historyPublics.map(item =>
        `${item.hour.toString().padStart(2, '0')}:00`
      );

      setDataStatus(historyPublics);
      setSeriesCategories(arrCat);
    }
  }, [historyPublics, isDone]);

  useEffect(() => {
    if (historyPublics.length > 0 && labels && isDone) {
      let data = [];
      labels.forEach((z) => {
        if (z.value !== "timestamp") {
          let obj = {};
          obj.name = z.label && z.label;
          let arr = [];
          let entries;
          historyPublics && historyPublics.forEach((item) => {
            if (item) {
              entries = Object.entries(item);
              entries.forEach((e) => {
                if (e[0] !== null) {
                  if (e[0] === z.value) {
                    if (Number.isInteger(parseInt(e[1]))) {
                      arr.push(numberWithCommas(parseInt(e[1])));
                    } else {
                      arr.push(0)
                    }
                  }
                }
              });
            }
          });
          obj.data = arr;
          data.push(obj);
        }
      });
      setSeries(data);
    }
  }, [historyPublics, labels, parameters, isDone]);

  useEffect(() => {
    if (info) {
      let dataLabel = [];
      info.device_parameter &&
        info.device_parameter.forEach((item) => {
          if (item && item.label) {
            dataLabel.push({
              label: item && item.label,
              value: item && item.value,
            });
            setLabels(dataLabel);
          }
        });
    }
  }, [parameters, info]);

  document.title = `${t("Device Detail")} | TELKOMSEL - IOT Monitoring`;
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Row className='project-wrapper mb-4'>
            <Col xl={3}>
              <Card>
                <CardHeader className='card-header border-0'>
                  <div className='d-flex align-items-center'>
                    <h5 className='card-title mb-0 flex-grow-1'>
                      {t("Device")} &nbsp;
                      {loading ? (
                        <>
                          <Spinner color='primary' type='grow' size={"sm"}>
                            Loading...
                          </Spinner>
                          &nbsp;
                          <Spinner color='success' type='grow' size={"sm"}>
                            Loading...
                          </Spinner>
                          &nbsp;
                        </>
                      ) : (
                        ""
                      )}
                    </h5>
                    <div className='flex-shrink-0'>
                      <button
                        type='button'
                        className={
                          userType === "administrator"
                            ? "btn btn-primary add-btn"
                            : roles &&
                              roles.split(",").indexOf("createDevice") !== -1
                              ? "btn btn-primary add-btn"
                              : "btn btn-primary add-btn d-none"
                        }
                        id='create-btn'
                        onClick={() => {
                          setIsEdit(false);
                          toggle();
                        }}>
                        <i className='ri-booklet-line me-1 align-bottom fs-14'></i>{" "}
                        {t("Tambah")}
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className='pt-0'>
                  <div>
                    <Nav
                      className='nav-tabs nav-tabs-custom nav-success'
                      role='tablist'></Nav>
                    <Row className='mb-3'>
                      <CardBody className='border border-top-0 border-end-0 border-start-0'>
                        <form>
                          <Row>
                            <Col sm={2} className='col-xxl-3'>
                              <div className='me-2 mb-2 col-12'>
                                <select
                                  className='form-select'
                                  value={pageSize}
                                  onChange={onChangeInSelect}>
                                  {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                      {pageSize}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            <Col sm={9}>
                              <div className='search-box me-2 mb-2 d-inline-block col-12'>
                                <input
                                  onChange={(e) => {
                                    setSearchValue(e.target.value);
                                    onChange(e.target.value);
                                  }}
                                  id='search-bar-0'
                                  type='text'
                                  className='form-control search'
                                  placeholder={`${count} ${t("Search")}...`}
                                  value={searchValue || ""}
                                />
                                <i className='bx bx-search-alt search-icon'></i>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </CardBody>
                    </Row>
                    <div className='table-responsive table-card mb-1'>
                      <Table
                        hover
                        {...getTableProps()}
                        className='align-middle table-nowrap'>
                        <thead className='table-light text-muted'>
                          {headerGroups.map((headerGroup, trIdx) => (
                            <tr
                              className={""}
                              key={"headerGroup" + trIdx}
                              {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column, tdIdx) => (
                                <th
                                  key={"column" + tdIdx}
                                  className={"" + generateFilterable(column)}
                                  {...column.getSortByToggleProps()}>
                                  {column.render("Header")}
                                  {generateSortingIndicator(column)}
                                  {/* <Filter column={column} /> */}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                          {page.map((row, rowIdx) => {
                            prepareRow(row);
                            return (
                              <Fragment key={"row" + rowIdx}>
                                <tr>
                                  {row.cells.map((cell, tdIdx) => {
                                    return (
                                      <td
                                        key={"cell" + tdIdx}
                                        {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    );
                                  })}
                                </tr>
                              </Fragment>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <Row className="d-flex- justify-content-center ">
                      <Col className='col-md-auto d-none d-md-block'>
                        {t("Page")}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}{" "}
                        </strong>
                      </Col>
                    </Row>
                    <Row className=''>
                      <div className='pe-2 col-lg-3'>
                        <Button
                          className='w-100 align-items-end'
                          color='light'
                          onClick={previousPage}
                          disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                      <div className='p-0 col-lg-6'>
                        <Input
                          type='number'
                          min={1}
                          className='w-100'
                          max={pageOptions.length}
                          defaultValue={pageIndex + 1}
                          onChange={onChangeInInput}
                        />
                      </div>
                      <div className='ps-2 col-lg-3 align-items-start'>
                        <Button
                          className='w-100'
                          color='light'
                          onClick={nextPage}
                          disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Row>
                  </div>
                  <ToastContainer closeButton={false} />
                </CardBody>
              </Card>
            </Col>
            <Col xl={9}>
              <Col xl={12}>
                {info && isDone ? <CardParamsComponent data={info} t={t} /> : null}
              </Col>
              <Col xxl={12}>
                <Row>
                  <Col xl={6}>
                    <Card>
                      <CardHeader className='align-items-center d-flex'>
                        <h4 className='card-title mb-0 flex-grow-1'>
                          {info && info.name ? info.name : "-"}
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <div>
                          <div className='flex-shrink-0 mb-3'>
                            <img
                              src={
                                primaryImg && primaryImg ? primaryImg : dummyImg
                              }
                              className='rounded img-fluid'
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              alt=''
                            />
                          </div>
                          <div className='mb-3 '>
                            <h5 className='card-title mb-2'>
                              {t("Description")}
                            </h5>
                            <p>
                              {info && info.description
                                ? info.description.replace(/<[^>]+>/g, "")
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    {/* <TaskDeviceComponent data={info ? info : []} /> */}
                    {info.device_parameter_history &&
                      info.device_parameter_history.length > 0 ? (
                      <ParamsHistoryComponent data={info ? info : []} />
                    ) : null}
                  </Col>
                  <Col xl={6}>
                    <Col xl={12}>
                      <Row>
                        <Col xl={12}>
                          <StatusWidget t={t} histories={dataStatus && dataStatus.length > 0 ? dataStatus : {}} publicloading={publicLoading} />
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={12}>
                      <Row>
                        <Col xl={12}>
                          <Card>
                            <CardHeader className='align-items-center d-flex'>
                              <h4 className='card-title mb-0 flex-grow-1'>
                                {t("Media Files")}
                              </h4>
                            </CardHeader>
                            <CardBody className='p-0'>
                              <ul className='list-group list-group-flush border-dashed px-3 divi'>
                                {fileList.length > 0 ? (
                                  fileList.map((item, idx) => (
                                    <li
                                      className='list-group-item ps-0'
                                      key={"fileList" + idx}>
                                      <div className='d-flex align-items-center'>
                                        <i className='bx bx-file'></i>
                                        <div className='flex-grow-1'>
                                          <label className='form-check-label mb-0 ps-2'>
                                            {item.filename}
                                          </label>
                                        </div>
                                      </div>
                                    </li>
                                  ))
                                ) : (
                                  <h6 className='mt-2'>
                                    {t("No media files")}
                                  </h6>
                                )}
                              </ul>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={12}>
                          <AlertWidget t={t} />
                        </Col>
                      </Row>
                      <Col xl={12}>
                        <StatisticComponent
                          series={series}
                          seriesCategories={seriesCategories}
                          t={t}
                          publicLoading={publicLoading}
                        />
                      </Col>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

DeviceDetail.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(DeviceDetail));
