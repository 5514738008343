import {
  GET_REPORTTEMPLATEDETAIL,
  GET_REPORTTEMPLATEDETAIL_SUCCESS,
  GET_REPORTTEMPLATEDETAIL_FAIL,
  DELETE_REPORTTEMPLATEDETAIL,
  DELETE_REPORTTEMPLATEDETAIL_SUCCESS,
  DELETE_REPORTTEMPLATEDETAIL_FAIL,
  UPDATE_REPORTTEMPLATEDETAIL,
  UPDATE_REPORTTEMPLATEDETAIL_SUCCESS,
  UPDATE_REPORTTEMPLATEDETAIL_FAIL,
  ADD_NEW_REPORTTEMPLATEDETAIL,
  ADD_REPORTTEMPLATEDETAIL_SUCCESS,
  ADD_REPORTTEMPLATEDETAIL_FAIL,
  REPORTTEMPLATEDETAIL_LOADING,
  REPORTTEMPLATEDETAIL_RESET,
} from "./actionType";

export const resetReportTemplateDetailState = (actionType) => ({
  type: REPORTTEMPLATEDETAIL_RESET,
  payload: { actionType },
});

export const getReportTemplateDetailSuccess = (actionType, data) => ({
  type: GET_REPORTTEMPLATEDETAIL_SUCCESS,
  payload: { actionType, data },
});

export const getReportTemplateDetailFail = (actionType, error) => ({
  type: GET_REPORTTEMPLATEDETAIL_FAIL,
  payload: { actionType, error },
});

export const getReportTemplateDetail = (data) => ({
  type: GET_REPORTTEMPLATEDETAIL,
  payload: data,
});

export const getReportTemplateDetailLoading = () => ({
  type: REPORTTEMPLATEDETAIL_LOADING,
});

export const deleteReportTemplateDetail = (data) => ({
  type: DELETE_REPORTTEMPLATEDETAIL,
  payload: data,
});

export const deleteReportTemplateDetailSuccess = (data) => ({
  type: DELETE_REPORTTEMPLATEDETAIL_SUCCESS,
  payload: data,
});

export const deleteReportTemplateDetailFail = (error) => ({
  type: DELETE_REPORTTEMPLATEDETAIL_FAIL,
  payload: error,
});

export const updateReportTemplateDetail = (data) => ({
  type: UPDATE_REPORTTEMPLATEDETAIL,
  payload: data,
});

export const updateReportTemplateDetailFail = (error) => ({
  type: UPDATE_REPORTTEMPLATEDETAIL_FAIL,
  payload: error,
});

export const updateReportTemplateDetailSuccess = (data) => ({
  type: UPDATE_REPORTTEMPLATEDETAIL_SUCCESS,
  payload: data,
});

export const addNewReportTemplateDetail = (data) => ({
  type: ADD_NEW_REPORTTEMPLATEDETAIL,
  payload: data,
});

export const addReportTemplateDetailSuccess = (data) => ({
  type: ADD_REPORTTEMPLATEDETAIL_SUCCESS,
  payload: data,
});

export const addReportTemplateDetailFail = (error) => ({
  type: ADD_REPORTTEMPLATEDETAIL_FAIL,
  payload: error,
});
