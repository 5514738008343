import React from "react";
import defaultImg from "../../assets/images/upload.png";
import { api } from "../../config";
import { ListGroup, ListGroupItem } from "reactstrap";

function WidgetCard({ data }) {
    return (
        <>
            <ListGroup flush numbered className='mt-2'>
                {data.map((wParams, idxWParams) => {
                    return (
                        <ListGroupItem key={idxWParams}>
                            {wParams.label + " : "}
                            {wParams.amount
                                ? `${wParams.amount} ${wParams.uom ? wParams.uom : ""}`
                                : "-"}
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
        </>
    );
}

export default WidgetCard;
