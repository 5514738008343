export const SENSOR_LOADING = "SENSOR_LOADING";
export const SENSOR_RESET = "SENSOR_RESET";
export const GET_SENSOR = "GET_SENSOR";
export const GET_SENSOR_SUCCESS = "GET_SENSOR_SUCCESS";
export const GET_SENSOR_FAIL = "GET_SENSOR_FAIL";
export const DELETE_SENSOR = "DELETE_SENSOR";
export const DELETE_SENSOR_SUCCESS = "DELETE_SENSOR_SUCCESS";
export const DELETE_SENSOR_FAIL = "DELETE_SENSOR_FAIL";
export const UPDATE_SENSOR = "UPDATE_SENSOR";
export const UPDATE_SENSOR_SUCCESS = "UPDATE_SENSOR_SUCCESS";
export const UPDATE_SENSOR_FAIL = "UPDATE_SENSOR_FAIL";
export const ADD_NEW_SENSOR = "ADD_NEW_SENSOR";
export const ADD_SENSOR_SUCCESS = "ADD_SENSOR_SUCCESS";
export const ADD_SENSOR_FAIL = "ADD_SENSOR_FAIL";
