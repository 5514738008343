import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import SimpleBar from "simplebar-react";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

function AlertWidget(props) {
  const { t } = props;
  const { notifications } = useSelector((state) => ({
    notifications: state.Notification.notifications,
  }));
  return (
    <Card>
      <CardHeader className='align-items-center d-flex'>
        <h4 className='card-title mb-0 flex-grow-1'>{t("Alerts")}</h4>
      </CardHeader>
      <CardBody className='p-0'>
        <SimpleBar
          style={notifications.length > 0 ? { height: "250px" } : null}>
          <ul className='list-group list-group-flush border-dashed px-3 divi'>
            {notifications.length > 0 ? (
              notifications.map((notif, notifIdx) => {
                return (
                  <li className='list-group-item ps-0' key={notifIdx}>
                    <div className='d-flex align-items-center'>
                      <span className='dot-red'></span>
                      <div className='flex-grow-1'>
                        <label className='form-check-label mb-0 ps-2'>
                          {notif.name}
                        </label>
                      </div>
                      <div className='flex-shrink-0 ms-2'>
                        <p className='text-muted fs-12 mb-0'>
                          {moment(notif.createdat).fromNow()}
                        </p>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <h6 className='mt-2'>{t("No notifications")}</h6>
            )}
          </ul>
        </SimpleBar>
        <div className='p-3 pt-2 d-flex justify-content-center gap-2'>
          <div className='d-flex align-items-center gap-1'>
            <span className='dot-red'></span>
            <span className='text-muted'>{t("High")}</span>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='dot-yellow'></span>
            <span className='text-muted'>{t("Medium")}</span>
          </div>
          <div className='d-flex align-items-center gap-1'>
            <span className='dot-green'></span>
            <span className='text-muted'>{t("Low")}</span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

AlertWidget.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(AlertWidget));
