import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  GET_BRANDTYPE,
  DELETE_BRANDTYPE,
  UPDATE_BRANDTYPE,
  ADD_NEW_BRANDTYPE,
} from "./actionType";

import {
  getBrandTypeSuccess,
  getBrandTypeFail,
  deleteBrandTypeSuccess,
  deleteBrandTypeFail,
  updateBrandTypeSuccess,
  updateBrandTypeFail,
  addBrandTypeSuccess,
  addBrandTypeFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  approveBrandTypeApi,
  getBrandTypeApi,
  updateBrandTypeApi,
  deleteBrandTypeApi,
  addNewBrandTypeApi,
} from "../../helpers/backend_helper";

function* getBrandType({ payload: brandType }) {
  try {
    const response = yield call(getBrandTypeApi, brandType);
    yield put(getBrandTypeSuccess(GET_BRANDTYPE, response.data));
  } catch (error) {
    yield put(getBrandTypeFail(GET_BRANDTYPE, error));
  }
}

function* onUpdateBrandType({ payload: brandType }) {
  try {
    const response = yield call(updateBrandTypeApi, brandType);
    yield put(updateBrandTypeSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateBrandTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteBrandType({ payload: brandType }) {
  try {
    const response = yield call(deleteBrandTypeApi, brandType);
    yield put(deleteBrandTypeSuccess({ brandType, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteBrandTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewBrandType({ payload: brandType }) {
  try {
    const response = yield call(addNewBrandTypeApi, brandType);
    yield put(addBrandTypeSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addBrandTypeFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

export function* watchGetBrandType() {
  yield takeEvery(GET_BRANDTYPE, getBrandType);
}

export function* watchUpdateBrandType() {
  yield takeEvery(UPDATE_BRANDTYPE, onUpdateBrandType);
}

export function* watchDeleteBrandType() {
  yield takeEvery(DELETE_BRANDTYPE, onDeleteBrandType);
}

export function* watchAddNewBrandType() {
  yield takeEvery(ADD_NEW_BRANDTYPE, onAddNewBrandType);
}

function* brandTypeSaga() {
  yield all([
    fork(watchGetBrandType),
    fork(watchDeleteBrandType),
    fork(watchUpdateBrandType),
    fork(watchAddNewBrandType),
  ]);
}

export default brandTypeSaga;
