import React, { useEffect, useState, useMemo, Fragment } from "react";
import {
  Table,
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Spinner,
  Button,
  Input,
  Label,
} from "reactstrap";
import moment from "moment";
import "moment-timezone";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";
// Formik
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Select from "react-select";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getHistory, resetHistoryState } from "../../../store/history/action";
import { getDevice, resetDeviceState } from "../../../store/device/action";

//Import actions
import {
  getReportTemplate as onGetReportTemplates,
  deleteReportTemplate as onDeleteReportTemplate,
} from "../../../store/reportTemplate/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExportExcelModal from "../../../Components/Common/ExportExcelModal";
import ExportExcelModalObject from "../../../Components/Common/ExportExcelModalObject";
import PropTypes from "prop-types";
import withRouter from "../../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";

const dataPeriod = [
  {
    id: 1,
    start: "20-03-2024 13:50",
    end: "20-03-2024 16:53",
    product: "FM1 (olein)",
    total: 1256.567378,
    loading: 375.875938,
    status: "Activated",
  },
  {
    id: 2,
    start: "20-03-2024 18:23",
    end: "20-03-2024 23:54",
    product: "FM1 (olein)",
    total: 1312.35847,
    loading: 405.547987,
    status: "Activated",
  },
  {
    id: 3,
    start: "21-03-2024 13:50",
    end: "21-03-2024 16:53",
    product: "FM1 (olein)",
    total: 32322.35847,
    loading: 4205.547987,
    status: "Activated",
  },
  {
    id: 4,
    start: "21-03-2024 18:23",
    end: "21-03-2024 23:54",
    product: "FM1 (olein)",
    total: 33322.35847,
    loading: 105.547987,
    status: "Activated",
  },
  {
    id: 5,
    start: "22-03-2024 13:50",
    end: "22-03-2024 16:53",
    product: "FM1 (olein)",
    total: 54556.35847,
    loading: 905.547987,
    status: "Activated",
  },
  {
    id: 6,
    start: "22-03-2024 18:23",
    end: "22-03-2024 23:54",
    product: "FM1 (olein)",
    total: 878676.35847,
    loading: 805.547987,
    status: "Activated",
  },
  {
    id: 7,
    start: "23-03-2024 13:50",
    end: "23-03-2024 16:53",
    product: "FM1 (olein)",
    total: 97676.35847,
    loading: 405.547987,
    status: "Activated",
  },
];

const dataMonthly = [
  {
    id: 1,
    month: "Mar",
    product: "FM1 (olein)",
    loading: 375.875938,
    sort_order: 3,
  },
  {
    id: 2,
    month: "Apr",
    product: "FM1 (olein)",
    loading: 405.547987,
    sort_order: 4,
  },
  {
    id: 3,
    month: "Mei",
    product: "FM1 (olein)",
    loading: 4205.547987,
    sort_order: 5,
  },
  {
    id: 4,
    month: "Jun",
    product: "FM1 (olein)",
    loading: 105.547987,
    sort_order: 6,
  },
  {
    id: 5,
    month: "Jul",
    product: "FM1 (olein)",
    loading: 905.547987,
    sort_order: 7,
  },
  {
    id: 6,
    month: "Agu",
    product: "FM1 (olein)",
    loading: 805.547987,
    sort_order: 8,
  },
  {
    id: 7,
    month: "Sep",
    product: "FM1 (olein)",
    loading: 405.547987,
    sort_order: 9,
  },
  {
    id: 8,
    month: "Okt",
    product: "FM1 (olein)",
    loading: 605.547987,
    sort_order: 10,
  },
  {
    id: 9,
    month: "Nov",
    product: "FM1 (olein)",
    loading: 305.547987,
    sort_order: 11,
  },
  {
    id: 10,
    month: "Des",
    product: "FM1 (olein)",
    loading: 805.547987,
    sort_order: 12,
  },
  {
    id: 11,
    month: "Jan",
    product: "FM1 (olein)",
    loading: 4605.547987,
    sort_order: 1,
  },
  {
    id: 12,
    month: "Feb",
    product: "FM1 (olein)",
    loading: 9605.547987,
    sort_order: 2,
  },
];

const Template = (props) => {
  const { rptTemplateId } = useParams();
  const sortLoad = dataMonthly.sort((a, b) => {
    return b.sort_order - a.sort_order;
  });
  const dispatch = useDispatch();
  const [dateDaily, setDateDaily] = useState([]);
  const [template, setTemplate] = useState();
  const [valuesPar, setValuesPar] = useState([]);
  const [labels, setLabels] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [optionSelect, setOptionSelect] = useState("");
  const [deviceIdentifier, setDeviceIdentifier] = useState();
  const [rptType, setRptType] = useState("");
  const [optionPeriod, setOptionPeriod] = useState({});
  const [tableMonthly, setTableMonthly] = useState([]);
  const [startPeriod, setStartPeriod] = useState();
  const [endPeriod, setEndPeriod] = useState();
  const [isPeriod, setIsPeriod] = useState(false);
  const [tablePeriod, setTablePeriod] = useState([]);
  const { t } = props;

  const { clients, loading, reportTemplates, histories, devices } = useSelector(
    (state) => ({
      clients: state.Client.clients,
      loading: state.Client.loading,
      error: state.Client.error,
      reportTemplates: state.ReportTemplate.reportTemplates,
      histories: state.History.histories,
      devices: state.Device.devices,
    })
  );

  const [clientList, setClientList] = useState([]);
  const [client, setClient] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [exportList, setExportList] = useState([]);
  const defaultdate = () => {
    return moment().tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [roles, setRoles] = useState(null);
  const [userType, setUserType] = useState("user");
  const [filterDate, setFilterDate] = useState(defaultdate());
  const [unitId, setUnitId] = useState();
  const [deviceList, setDeviceList] = useState([]);
  const [deviceUid, setDeviceUid] = useState({});
  const [rptIdentifier, setRptIdentifier] = useState();
  const [isMonthly, setIsMonthly] = useState(false);
  const [isDaily, setIsDaily] = useState(false);

  const getDeviceSelectList = useMemo(() => {
    if (unitId > -1) {
      const newOptDevice = deviceList[0].options.filter((itm) => {
        return itm.unitId === unitId;
      });
      return [{ options: newOptDevice }];
    } else {
      return deviceList;
    }
  }, [deviceList, unitId]);

  useEffect(() => {
    if (!isEmpty(devices)) {
      var data = [];
      var opt = [];
      devices.forEach((item, key) => {
        console.log(item);
        const name = item.name;
        const id = item.id;
        var obj = {
          label: name,
          value: id,
          unitId: item.device_device_unit[0]
            ? item.device_device_unit[0].unitId
            : "",
          identifier: item.identifier,
        };
        opt.push(obj);
      });
      var option = { options: opt };
      data.push(option);
      setDeviceList(data);
    }
  }, [devices]);

  const optionSelectData = [
    {
      options: [
        { label: "Monthly", value: "monthly" },
        { label: "Period", value: "period" },
      ],
    },
  ];

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable
      ? column.isSorted
        ? column.isSortedDesc
          ? " sorting_desc"
          : " sorting_asc"
        : " sort"
      : "";
  };

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: `${t("Month")}`,
        accessor: "month",
        filterable: true,
      },
      {
        Header: `${t("Year")}`,
        accessor: "year",
        filterable: true,
      },
      {
        Header: "Product",
        accessor: "product",
        filterable: true,
      },
      {
        Header: "Totalizer-Mass (ton)",
        accessor: "total",
        filterable: true,
      },
      {
        Header: "Loading Rate (ton/hour))",
        accessor: "loading",
        filterable: true,
      },
    ],
    [t]
  );

  const columnsSecond = useMemo(
    () => [
      {
        Header: `${t("Start")}`,
        accessor: "start",
        filterable: true,
      },
      {
        Header: `${t("End")}`,
        accessor: "last",
        filterable: true,
      },
      {
        Header: `${t("Product")}`,
        accessor: "product",
        filterable: true,
      },
      {
        Header: "Totalizer-Mass (ton)",
        accessor: "total",
        filterable: true,
      },
      {
        Header: "Loading Rate (ton/hour)",
        accessor: "loading",
        filterable: true,
      },
    ],
    [t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: tableMonthly.length > 0 ? columns : columnsSecond,
      data: tableMonthly.length > 0 ? tableMonthly : tablePeriod,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const handleDeleteClient = () => {
    if (client) {
      dispatch(onDeleteReportTemplate(client));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setClientList(clients);
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(clients)) setClientList(clients);
  }, [clients]);

  function handleFilter() {
    let startat = "";
    let endat = "";
    if (filterDate) {
      let datearr = filterDate.split(" to ");
      if (datearr[1]) {
        startat = datearr[0];
        endat = datearr[1];
      }
    }
    const param = {
      startat: startat,
      endat: endat,
      uid: template && template.rptTemplate_device.uid,
    };
    console.log(param);
    dispatch(getHistory(param));
  }

  useEffect(() => {
    if (template) {
      let dataLabel = [];
      template.detail &&
        template.detail.forEach((item) => {
          if (item.label && item.parameter) {
            dataLabel.push({
              label: item.label && item.parameter,
              value: item.label && item.parameter,
            });
          }
        });
      setLabels(dataLabel);
    }
  }, [template, isDaily]);

  useEffect(() => {
    if (template && isDaily) {
      if (template.type === "daily") {
        const start = moment().startOf(histories[0]?.datetime);
        const times = histories.length;

        let arrCat = [];
        for (let i = 0; i < times; i++) {
          const toPrint = moment(start)
            .add(60 * i, "minutes")
            .format("YYYY-MM-DD HH:mm");

          arrCat.push(toPrint);
        }
        setDateDaily(arrCat);
      } else if (template.type === "weekly") {
        const times = histories.length;

        let arrCat = [];
        for (let i = 0; i < times; i++) {
          const toPrint = moment(histories[i]?.datestring).format("YYYY-MM-DD");

          arrCat.push(toPrint);
        }
        setDateDaily(arrCat);
      } else if (template.type === "monthly") {
        const times = histories.length;

        let arrCat = [];
        for (let i = 0; i < times; i++) {
          const toPrint = moment(histories[i]?.datestring).format("YYYY-MM-DD");

          arrCat.push(toPrint);
        }
        setDateDaily(arrCat);
      }
    }
  }, [template, histories, isDaily]);

  useEffect(() => {
    if (histories.length > 0 && isDaily) {
      let data = [];
      labels.forEach((z) => {
        let obj = {};
        obj.name = z.label && z.label;
        let arr = [];
        let entries;
        histories.forEach((item) => {
          if (item.data) {
            console.log(item);
            entries = Object.entries(item.data);
            console.log(entries);
            entries.forEach((e) => {
              if (e[0] !== null) {
                if (e[0] === z.value) {
                  arr.push(Math.round(e[1]));
                }
              }
            });
          }
        });
        obj.data = arr;
        data.push(obj);
      });
      setValuesPar(data);
      setIsDaily(false);
    }
  }, [labels, histories, isDaily]);

  useEffect(() => {
    if (valuesPar && dateDaily) {
      let arr = [];
      valuesPar.forEach((val) => {
        arr.push({
          label: val.name,
          value: val.data,
          date: dateDaily,
        });
      });
      let exportList = [];
      arr.forEach((arr) => {
        exportList.push([arr.label, arr.value]);
      });
      exportList.forEach((t) => {
        t[1].forEach((ti) => {
          t.push(ti);
        });
        t.splice(1, 1);
      });
      exportList.push(["Parameter"]);
      dateDaily.forEach((a, i) => {
        exportList[exportList.length - 1].push(a);
      });
      exportList.unshift(exportList[exportList.length - 1]);
      exportList.splice(-1, 1);
      setExportList(exportList);
    }
  }, [valuesPar, dateDaily]);

  useEffect(() => {
    setIsSearch(true);
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      if (
        obj.data &&
        obj.data.employee_position &&
        obj.data.employee_position.position_role
      )
        setRoles(obj.data.employee_position.position_role.role_method);
      else setRoles(null);

      if (obj.data && obj.data.userType) setUserType(obj.data.userType);
    } else {
      window.location = "/logout";
    }
  }, [dispatch, reportTemplates, isSearch, rptTemplateId]);

  useEffect(() => {
    dispatch(onGetReportTemplates({ status: 2 }));
    dispatch(resetDeviceState());
    dispatch(getDevice({ status: 2 }));
  }, [dispatch, rptTemplateId]);

  useEffect(() => {
    if (reportTemplates) {
      let data;
      reportTemplates.forEach((item) => {
        if (item.id === parseInt(rptTemplateId)) {
          data = item;
        }
      });
      if (data && data.type) {
        setRptType(data.type);
      }
      // if (data && data.type !== "period") {
      //   dispatch(resetHistoryState());
      //   dispatch(
      //     getHistory({
      //       type: data && data.type,
      //       uid: data && data.rptTemplate_device.uid,
      //     })
      //   );
      // } else if (deviceUid) {
      //   dispatch(resetHistoryState());
      //   dispatch(getHistory({ type: "daily", uid: deviceUid }));
      // } else {
      //   dispatch(resetHistoryState());
      // }
      setTemplate(data);
      setSelectedDevice(data && data.rptTemplate_device);
    }
  }, [dispatch, reportTemplates, rptTemplateId]);

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteReportTemplate(element.value));
    });
    checkall.checked = false;
  };

  useEffect(() => {
    if (isMonthly && histories.length > 0) {
      let datas = [
        {
          month: "Jan",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 1,
        },
        {
          month: "Feb",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 2,
        },
        {
          month: "Mar",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 3,
        },
        {
          month: "Apr",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 4,
        },
        {
          month: "May",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 5,
        },
        {
          month: "Jun",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 6,
        },
        {
          month: "Jul",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 7,
        },
        {
          month: "Aug",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 8,
        },
        {
          month: "Sep",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 9,
        },
        {
          month: "Oct",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 10,
        },
        {
          month: "Nov",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 11,
        },
        {
          month: "Des",
          year: moment().format("YYYY"),
          product: deviceUid && deviceUid.label,
          total: 0,
          loading: 0,
          sort_order: 12,
        },
      ];
      datas.forEach((dat) => {
        histories.forEach((his) => {
          if (dat.sort_order === his.month) {
            dat.total = his.last.mass_total;
            dat.loading = his.last.mass_total - his.first.mass_total;
          }
        });
      });
      let sortData = datas.sort((a, b) => {
        return b.sort_order - a.sort_order;
      });
      setTableMonthly(sortData);
      setTablePeriod([]);
      setIsMonthly(false);
    }
  }, [isMonthly, histories, deviceUid]);

  useEffect(() => {
    if (histories.length > 0 && isPeriod) {
      let datas = [];
      histories.forEach((his) => {
        datas.push({
          start: moment(his.first.timestamp).format("DD-MM-YYYY"),
          last: moment(his.last.timestamp).format("DD-MM-YYYY"),
          product: deviceUid && deviceUid.label,
          total: his.last.mass_total,
          loading: his.last.mass_total - his.first.mass_total,
        });
      });
      setTablePeriod(datas);
      setTableMonthly([]);
      setIsPeriod(false);
    }
  }, [histories, isPeriod, deviceUid]);

  //TABLE

  document.title = `${t("Template")} | TELKOMSEL - IOT Monitoring`;
  return rptType && rptType === "daily" ? (
    <div className='page-content'>
      <ExportExcelModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={exportList}
        filename={selectedDevice ? selectedDevice : []}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteClient}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={template && template.name} pageTitle={t("Report")} />
        <Row>
          <Col lg={12}>
            <Card id='clientList'>
              <CardHeader className='card-header border-0'>
                <div className='d-flex align-items-center'>
                  <h5 className='card-title mb-0 flex-grow-1'>
                    {template && template
                      ? `${template.name} (${template.type})`
                      : "-"}
                    &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color='primary' type='grow' size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color='success' type='grow' size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className='flex-shrink-0'>
                    <button
                      type='button'
                      className='btn btn-success mb-3'
                      onClick={() => setIsExportCSV(true)}>
                      <i className='ri-file-download-line me-1 align-bottom fs-14'></i>{" "}
                      Export
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className='pt-0'>
                <Row className='mb-3'>
                  <CardBody className='border border-top-0 border-end-0 border-start-0'>
                    <form>
                      <Row>
                        <Col lg={3}>
                          <div className='mb-3'>
                            <Select
                              name='device'
                              // value={deviceUid}
                              placeholder={`${t("Select")} ${t("Device")}`}
                              onChange={(e) => {
                                setDeviceUid(e);
                                setDeviceIdentifier(e.identifier);
                              }}
                              options={getDeviceSelectList}></Select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className='search-box me-2 mb-2 col-12'>
                            <Flatpickr
                              className='form-control'
                              id='datepicker-publish-input'
                              placeholder={t("Select created date")}
                              options={{
                                mode: "single",
                                dateFormat: "Y/m/d",
                              }}
                              onChange={(obj, str) => {
                                setFilterDate(obj[0].getTime());
                              }}
                            />
                            <i className='bx bx-calendar-alt search-icon'></i>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div>
                            <button
                              type='button'
                              className='btn btn-light w-100'
                              onClick={(e) => {
                                setIsDaily(true);
                                dispatch(
                                  getHistory({
                                    type: "daily",
                                    uid: deviceIdentifier,
                                    date: filterDate,
                                  })
                                );
                              }}>
                              <i className='ri-search-line me-1 align-bottom fs-14'></i>{" "}
                              {t("Search")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Row>
                <div>
                  {valuesPar.length > 0 && dateDaily.length > 0 ? (
                    <div className='table-responsive table-card mb-1 mt-0'>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th className='table-light'>Parameter</th>
                            {(dateDaily || []).map((date, idxDate) => {
                              return (
                                <th
                                  key={"idxDate" + idxDate}
                                  className='table-light'>
                                  {date}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {(valuesPar || []).map((val, idxVal) => {
                            return (
                              <tr key={"idxVal" + idxVal}>
                                <th scope='row'>{val.name}</th>
                                {(val.data || []).map((datas, idxDatas) => {
                                  return (
                                    <td key={"idxDatas" + idxDatas}>{datas}</td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  ) : null}
                </div>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  ) : rptType && rptType === "period" ? (
    <div className='page-content'>
      <ExportExcelModalObject
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={tableMonthly.length > 0 ? tableMonthly : tablePeriod}
        filename={selectedDevice ? selectedDevice : []}
        rptType={rptType}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteClient}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <Container fluid>
        <BreadCrumb title={template && template.name} pageTitle={t("Report")} />
        <Row>
          <Col lg={12}>
            <Card id='clientList'>
              <CardHeader className='card-header border-0'>
                <div className='d-flex align-items-center'>
                  <h5 className='card-title mb-0 flex-grow-1'>
                    {template && template
                      ? `${template.name} (${template.type})`
                      : "-"}
                    &nbsp;
                    {isLoading ? (
                      <>
                        <Spinner color='primary' type='grow' size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                        <Spinner color='success' type='grow' size={"sm"}>
                          Loading...
                        </Spinner>
                        &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                  <div className='flex-shrink-0'>
                    <button
                      type='button'
                      className='btn btn-success mb-3'
                      onClick={() => setIsExportCSV(true)}>
                      <i className='ri-file-download-line me-1 align-bottom fs-14'></i>{" "}
                      Export
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody className='pt-0'>
                <Row className='mb-3'>
                  <CardBody className='border border-top-0 border-end-0 border-start-0'>
                    <form>
                      <Row>
                        <Col lg={3}>
                          <div className='mb-3'>
                            <Select
                              name='device'
                              // value={deviceUid}
                              placeholder={`${t("Select")} ${t("Device")}`}
                              onChange={(e) => {
                                setDeviceUid(e);
                                setDeviceIdentifier(e.identifier);
                              }}
                              options={getDeviceSelectList}></Select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className='mb-3'>
                            <Select
                              name='option'
                              // value={optionPeriod}
                              placeholder={`${t("Select")} ${t("Option")}`}
                              onChange={(e) => {
                                setOptionPeriod(e);
                                setOptionSelect(e.value);
                              }}
                              options={optionSelectData}></Select>
                          </div>
                        </Col>
                        {optionSelect === "period" ? (
                          <Col sm={6} className='col-xxl-3'>
                            <div className='search-box me-2 mb-2 col-12'>
                              <Flatpickr
                                className='form-control'
                                id='datepicker-publish-input'
                                placeholder={t("Select created date")}
                                options={{
                                  mode: "range",
                                  dateFormat: "Y/m/d",
                                  minDate: new Date(
                                    new Date().setFullYear(
                                      new Date().getFullYear() - 1
                                    )
                                  ),
                                  maxDate: "today",
                                }}
                                onChange={(obj, str) => {
                                  setStartPeriod(obj[0] && obj[0].getTime());
                                  setEndPeriod(obj[1] && obj[1].getTime());
                                  setFilterDate(str);
                                }}
                              />
                              <i className='bx bx-calendar-alt search-icon'></i>
                            </div>
                          </Col>
                        ) : null}
                        <Col className='col-xxl-2'>
                          <div>
                            <button
                              type='button'
                              className='btn btn-light w-100'
                              onClick={(e) => {
                                e.preventDefault();
                                if (optionPeriod.value === "monthly") {
                                  dispatch(
                                    getHistory({
                                      type: "annualy",
                                      uid: deviceIdentifier,
                                    })
                                  );
                                  setIsMonthly(true);
                                } else {
                                  setIsPeriod(true);
                                  dispatch(
                                    getHistory({
                                      type: "periode",
                                      uid: deviceIdentifier,
                                      start: startPeriod,
                                      end: endPeriod,
                                    })
                                  );
                                }
                              }}>
                              <i className='ri-search-line me-1 align-bottom fs-14'></i>{" "}
                              {t("Search")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Row>
                <Fragment>
                  <div className='table-responsive table-card mb-1'>
                    <Table
                      hover
                      {...getTableProps()}
                      className='align-middle table-nowrap'>
                      <thead className='table-light text-muted'>
                        {headerGroups.map((headerGroup) => (
                          <tr
                            className={""}
                            key={headerGroup.id}
                            {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                key={column.id}
                                className={"" + generateFilterable(column)}
                                {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                                {/* <Filter column={column} /> */}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <Fragment key={row.getRowProps().key}>
                              <tr>
                                {row.cells.map((cell) => {
                                  return (
                                    <td key={cell.id} {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                    <Col className='col-md-auto'>
                      <div className='d-flex gap-1'>
                        <Button
                          className='btn btn-light'
                          onClick={previousPage}
                          disabled={!canPreviousPage}>
                          {"<"}
                        </Button>
                      </div>
                    </Col>
                    <Col className='col-md-auto d-none d-md-block'>
                      {`${t("Page")} `}
                      <strong>
                        {pageIndex + 1} {t("of")} {pageOptions.length}
                      </strong>
                    </Col>
                    <Col className='col-md-auto'>
                      <Input
                        type='number'
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        defaultValue={pageIndex + 1}
                        onChange={onChangeInInput}
                      />
                    </Col>

                    <Col className='col-md-auto'>
                      <div className='d-flex gap-1'>
                        <Button
                          className='btn btn-light'
                          onClick={nextPage}
                          disabled={!canNextPage}>
                          {">"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
};

Template.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Template));
