import React from "react";
import { GaugeChart } from "../../pages/Dashboard/ECharts";

function Gauge({ data }) {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <>
      <div className='mt-3 mx-5'>
        <h5 className='text-center'>
          {data.label ? capitalizeFirstLetter(data.label).replaceAll("_", " ") : null}
        </h5>
        <GaugeChart
          dataColors='["--vz-primary"]'
          min={1}
          max={100}
          name={data ? data.label : null}
          value={(data && data.amount)}
        />
      </div>
    </>
  );
}

export default Gauge;
