export const ACTIVITYLOG_LOADING = "ACTIVITYLOG_LOADING";
export const ACTIVITYLOG_RESET = "ACTIVITYLOG_RESET";
export const GET_ACTIVITYLOG = "GET_ACTIVITYLOG";
export const GET_ACTIVITYLOG_SUCCESS = "GET_ACTIVITYLOG_SUCCESS";
export const GET_ACTIVITYLOG_FAIL = "GET_ACTIVITYLOG_FAIL";
export const DELETE_ACTIVITYLOG = "DELETE_ACTIVITYLOG";
export const DELETE_ACTIVITYLOG_SUCCESS = "DELETE_ACTIVITYLOG_SUCCESS";
export const DELETE_ACTIVITYLOG_FAIL = "DELETE_ACTIVITYLOG_FAIL";
export const UPDATE_ACTIVITYLOG = "UPDATE_ACTIVITYLOG";
export const UPDATE_ACTIVITYLOG_SUCCESS = "UPDATE_ACTIVITYLOG_SUCCESS";
export const UPDATE_ACTIVITYLOG_FAIL = "UPDATE_ACTIVITYLOG_FAIL";
export const ADD_NEW_ACTIVITYLOG = "ADD_NEW_ACTIVITYLOG";
export const ADD_ACTIVITYLOG_SUCCESS = "ADD_ACTIVITYLOG_SUCCESS";
export const ADD_ACTIVITYLOG_FAIL = "ADD_ACTIVITYLOG_FAIL";
export const ADD_NEW_ACTIVITYLOG_TABLE = "ADD_NEW_ACTIVITYLOG_TABLE";
export const UPDATE_ACTIVITYLOG_TABLE = "UPDATE_ACTIVITYLOG_TABLE";