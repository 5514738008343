import {
  GET_REPORTTEMPLATE,
  GET_REPORTTEMPLATE_SUCCESS,
  GET_REPORTTEMPLATE_FAIL,
  DELETE_REPORTTEMPLATE,
  DELETE_REPORTTEMPLATE_SUCCESS,
  DELETE_REPORTTEMPLATE_FAIL,
  UPDATE_REPORTTEMPLATE,
  UPDATE_REPORTTEMPLATE_SUCCESS,
  UPDATE_REPORTTEMPLATE_FAIL,
  ADD_NEW_REPORTTEMPLATE,
  ADD_REPORTTEMPLATE_SUCCESS,
  ADD_REPORTTEMPLATE_FAIL,
  REPORTTEMPLATE_LOADING,
  REPORTTEMPLATE_RESET,
} from "./actionType";

export const resetReportTemplateState = (actionType) => ({
  type: REPORTTEMPLATE_RESET,
  payload: { actionType },
});

export const getReportTemplateSuccess = (actionType, data) => ({
  type: GET_REPORTTEMPLATE_SUCCESS,
  payload: { actionType, data },
});

export const getReportTemplateFail = (actionType, error) => ({
  type: GET_REPORTTEMPLATE_FAIL,
  payload: { actionType, error },
});

export const getReportTemplate = (data) => ({
  type: GET_REPORTTEMPLATE,
  payload: data,
});

export const getReportTemplateLoading = () => ({
  type: REPORTTEMPLATE_LOADING,
});

export const deleteReportTemplate = (data) => ({
  type: DELETE_REPORTTEMPLATE,
  payload: data,
});

export const deleteReportTemplateSuccess = (data) => ({
  type: DELETE_REPORTTEMPLATE_SUCCESS,
  payload: data,
});

export const deleteReportTemplateFail = (error) => ({
  type: DELETE_REPORTTEMPLATE_FAIL,
  payload: error,
});

export const updateReportTemplate = (data) => ({
  type: UPDATE_REPORTTEMPLATE,
  payload: data,
});

export const updateReportTemplateFail = (error) => ({
  type: UPDATE_REPORTTEMPLATE_FAIL,
  payload: error,
});

export const updateReportTemplateSuccess = (data) => ({
  type: UPDATE_REPORTTEMPLATE_SUCCESS,
  payload: data,
});

export const addNewReportTemplate = (data) => ({
  type: ADD_NEW_REPORTTEMPLATE,
  payload: data,
});

export const addReportTemplateSuccess = (data) => ({
  type: ADD_REPORTTEMPLATE_SUCCESS,
  payload: data,
});

export const addReportTemplateFail = (error) => ({
  type: ADD_REPORTTEMPLATE_FAIL,
  payload: error,
});
