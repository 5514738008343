import React from "react";
import { UpdateDonut } from "../../pages/Dashboard/PieCharts";

function ChartDonut({ label, series }) {
  return (
    <div className='mb-3'>
      <UpdateDonut
        dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'
        labelData={label}
        series={series}
      />
    </div>
  );
}

export default ChartDonut;
