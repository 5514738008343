import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Spinner, Progress } from "reactstrap";
import moment from "moment-timezone";

function StatusWidget({ t, histories, publicloading }) {
  // console.log(histories, "data s");
  // console.log(publicloading, "loading s");
  const valHour = histories.length;
  const [seriesCategories, setSeriesCategories] = useState([]);
  const [isDone, setIsDone] = useState(false);

  function days(month, year) {
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return 30;
      case 4:
      case 6:
      case 9:
      case 11:
        return 29;
      case 2:
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
          return 28;
        } else {
          return 27;
        }
      default:
        return "Bulan tidak valid";
    }
  }

  useEffect(() => {
    if (histories) {
      if (Array.isArray(histories)) {
        const start = new Date();
        start.setHours(0, 0, 0, 0);
        const times = valHour;

        let date = new Date();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let numbers = 0;

        if (histories && histories[0] && histories[0].hour !== undefined) {
          numbers = 23;
        } else if (histories && histories[0] && histories[0].month !== undefined) {
          numbers = 11;
        } else if (histories && histories[0] && histories[0].date !== undefined) {
          numbers = days(month, year);
        }

        let arrCat = [];
        if (numbers !== 0) {
          for (let i = 0; i <= numbers; i++) {
            const toPrint = moment(start)
              .add(60 * i, "minutes")
              .format("YYYY-MM-DD HH:mm");
            const hour = new Date(toPrint);
            const hours = hour.getHours();
            arrCat.push({ date: toPrint, hour: hours.toString(), status: 0 });
          }
        }
        histories.forEach((his) => {
          arrCat.forEach((series, idx) => {
            if (parseInt(his.hour || his.month || his.date) - 1 === parseInt(series.hour)) {
              arrCat[idx].status = 1;
            }
          });
        });

        setIsDone(true);
        setSeriesCategories(arrCat);
      } else {
        setIsDone(true);
        setSeriesCategories([]);
      }
    }
  }, [valHour, histories]);

  // useEffect(() => {
  //   console.log(seriesCategories, "asas");
  // }, [seriesCategories]);

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        {publicloading ? (
          <>
            <Spinner color="primary" type="grow" size={"sm"}>
              Loading...
            </Spinner>
            &nbsp;
            <Spinner color="success" type="grow" size={"sm"}>
              Loading...
            </Spinner>
            &nbsp;
          </>
        ) : (
          <>
            <h4 className="card-title mb-0 flex-grow-1">{t("Status")}</h4>
            <div className="d-flex gap-2">
              <div className="d-flex align-items-center gap-1">
                <span className="dot-green"></span>
                <span className="text-muted">{t("ON")}</span>
              </div>
              <div className="d-flex align-items-center gap-1">
                <span className="dot-red"></span>
                <span className="text-muted">{t("OFF")}</span>
              </div>
            </div>
          </>
        )}
      </CardHeader>
      {seriesCategories.length > 0 ? (
        <CardBody>
          {/* <Progress multi>
            {seriesCategories.map((arr, arrKey) => {
              return (
                <Progress
                  key={arrKey}
                  bar
                  value="2.7"
                  color={arr.status === 1 ? "success" : "danger"}
                />
              );
            })}

          </Progress> */}
          <div className="d-flex justify-content-center mb-1">
            <div className="d-flex rounded overflow-hidden" style={{ width: "100%" }}>
              {isDone ? (
                <>
                  {seriesCategories.map((arr, arrKey) => {
                    return <div className={arr.status === 1 ? "green p-1 w-100" : "red p-1 w-100"} key={"keyArr-" + arrKey}></div>;
                  })}
                </>
              ) : null}
            </div>
          </div>
          <div className="d-flex justify-content-between text-muted flex-wrap ">
            {seriesCategories.map((arr, arrKey) => {
              return <p key={"arrKey-" + arrKey}>{`${arr.hour}`}</p>;
            })}
          </div>
        </CardBody>
      ) : (
        <CardBody>No status</CardBody>
      )}
    </Card>
  );
}

export default StatusWidget;
