import {
  UOM_LOADING,
  GET_UOM,
  GET_UOM_SUCCESS,
  GET_UOM_FAIL,
  ADD_UOM_SUCCESS,
  ADD_UOM_FAIL,
  DELETE_UOM_SUCCESS,
  DELETE_UOM_FAIL,
  UPDATE_UOM_SUCCESS,
  UPDATE_UOM_FAIL
} from "./actionType";

const INIT_STATE = {
  uoms: [],
  error: {},
};

const Uom = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UOM_SUCCESS:
      switch (action.payload.actionType) {
        case GET_UOM:
          return {
            ...state,
            uoms: action.payload.data,
            isUomCreated: false,
            isUomSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_UOM_FAIL:
      switch (action.payload.actionType) {
        case GET_UOM_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isUomCreated: false,
            isUomSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_UOM: {
      return {
        ...state,
        isUomCreated: false,
        loading: true,
      };
    }

    case UOM_LOADING: {
      return {
        ...state,
        isUomCreated: false,
        loading: true,
      };
    }

    case ADD_UOM_SUCCESS:
      return {
        ...state,
        isUomCreated: true,
        loading: false,
        uoms: [...state.uoms, action.payload.data],
      };

    case ADD_UOM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_UOM_SUCCESS:
      return {
        ...state,
        loading: false,
        uoms: state.uoms.filter(
          uom => uom.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_UOM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_UOM_SUCCESS:
      return {
        ...state,
        loading: false,
        uoms: state.uoms.map(uom =>
          uom.id.toString() === action.payload.data.id.toString() ? { ...uom, ...action.payload.data } : uom
        ),
      };

    case UPDATE_UOM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Uom;