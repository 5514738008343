import {
  SENSORTYPE_LOADING,
  GET_SENSORTYPE,
  GET_SENSORTYPE_SUCCESS,
  GET_SENSORTYPE_FAIL,
  ADD_SENSORTYPE_SUCCESS,
  ADD_SENSORTYPE_FAIL,
  DELETE_SENSORTYPE_SUCCESS,
  DELETE_SENSORTYPE_FAIL,
  UPDATE_SENSORTYPE_SUCCESS,
  UPDATE_SENSORTYPE_FAIL,
  SENSORTYPE_RESET,
} from "./actionType";

const INIT_STATE = {
  sensorTypes: [],
  error: {},
};

const SensorType = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SENSORTYPE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SENSORTYPE:
          return {
            ...state,
            sensorTypes: action.payload.data,
            isSensorTypeCreated: false,
            isSensorTypeSuccess: true,
            loading: false,
          };

        default:
          return { ...state };
      }
    case GET_SENSORTYPE_FAIL:
      switch (action.payload.actionType) {
        case GET_SENSORTYPE_FAIL:
          return {
            ...state,
            error: action.payload.error,
            isSensorTypeCreated: false,
            isSensorTypeSuccess: false,
            loading: false,
          };
        default:
          return { ...state };
      }

    case GET_SENSORTYPE: {
      return {
        ...state,
        isSensorTypeCreated: false,
        loading: true,
      };
    }

    case SENSORTYPE_LOADING: {
      return {
        ...state,
        isSensorTypeCreated: false,
        loading: true,
      };
    }

    case ADD_SENSORTYPE_SUCCESS:
      return {
        ...state,
        isSensorTypeCreated: true,
        loading: false,
        sensorTypes: [...state.sensorTypes, action.payload.data],
      };

    case ADD_SENSORTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_SENSORTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        sensorTypes: state.sensorTypes.filter(
          (sensorType) => sensorType.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SENSORTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_SENSORTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        sensorTypes: state.sensorTypes.map((sensorType) =>
          sensorType.id.toString() === action.payload.data.id.toString()
            ? { ...sensorType, ...action.payload.data }
            : sensorType
        ),
      };

    case UPDATE_SENSORTYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SENSORTYPE_RESET:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default SensorType;
