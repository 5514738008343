export const DOWNLOADAPP_LOADING = "DOWNLOADAPP_LOADING";
export const DOWNLOADAPP_RESET = "DOWNLOADAPP_RESET";
export const GET_DOWNLOADAPP = "GET_DOWNLOADAPP";
export const GET_DOWNLOADAPP_SUCCESS = "GET_DOWNLOADAPP_SUCCESS";
export const GET_DOWNLOADAPP_FAIL = "GET_DOWNLOADAPP_FAIL";
export const DELETE_DOWNLOADAPP = "DELETE_DOWNLOADAPP";
export const DELETE_DOWNLOADAPP_SUCCESS = "DELETE_DOWNLOADAPP_SUCCESS";
export const DELETE_DOWNLOADAPP_FAIL = "DELETE_DOWNLOADAPP_FAIL";
export const UPDATE_DOWNLOADAPP = "UPDATE_DOWNLOADAPP";
export const UPDATE_DOWNLOADAPP_SUCCESS = "UPDATE_DOWNLOADAPP_SUCCESS";
export const UPDATE_DOWNLOADAPP_FAIL = "UPDATE_DOWNLOADAPP_FAIL";
export const ADD_NEW_DOWNLOADAPP = "ADD_NEW_DOWNLOADAPP";
export const ADD_DOWNLOADAPP_SUCCESS = "ADD_DOWNLOADAPP_SUCCESS";
export const ADD_DOWNLOADAPP_FAIL = "ADD_DOWNLOADAPP_FAIL";
