import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  GET_DASHBOARD,
  DELETE_DASHBOARD,
  UPDATE_DASHBOARD,
  ADD_NEW_DASHBOARD,
} from "./actionType";

import {
  getDashboardSuccess,
  getDashboardFail,
  deleteDashboardSuccess,
  deleteDashboardFail,
  updateDashboardSuccess,
  updateDashboardFail,
  addDashboardSuccess,
  addDashboardFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getDashboardApi,
  updateDashboardApi,
  deleteDashboardApi,
  addNewDashboardApi
} from "../../helpers/backend_helper";

function* getDashboard({ payload: dashboard , path}) {
  try {
    const response = yield call(getDashboardApi, dashboard, path);
    yield put(getDashboardSuccess(GET_DASHBOARD, response.data));
  } catch (error) {
    yield put(getDashboardFail(GET_DASHBOARD, error));
  }
}

function* onUpdateDashboard({ payload: dashboard }) {
  try {
    const response = yield call(updateDashboardApi, dashboard);
    yield put(updateDashboardSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(updateDashboardFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onDeleteDashboard({ payload: dashboard }) {
  try {
    const response = yield call(deleteDashboardApi, dashboard);
    yield put(deleteDashboardSuccess({ dashboard, ...response }));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteDashboardFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
}

function* onAddNewDashboard({ payload: dashboard }) {
  try {
    const response = yield call(addNewDashboardApi, dashboard);
    yield put(addDashboardSuccess(response));
    toast.success(response.message, { autoClose: 3000 });
  } catch (error) {
    yield put(addDashboardFail(error));
    toast.error(error.response.data.message, { autoClose: 3000 });
  }

}

export function* watchGetDashboard() {
  yield takeEvery(GET_DASHBOARD, getDashboard);
}

export function* watchUpdateDashboard() {
  yield takeEvery(UPDATE_DASHBOARD, onUpdateDashboard);
}

export function* watchDeleteDashboard() {
  yield takeEvery(DELETE_DASHBOARD, onDeleteDashboard);
}

export function* watchAddNewDashboard() {
  yield takeEvery(ADD_NEW_DASHBOARD, onAddNewDashboard);
}

function* dashboardSaga() {
  yield all([
    fork(watchGetDashboard),
    fork(watchDeleteDashboard),
    fork(watchUpdateDashboard),
    fork(watchAddNewDashboard)
  ]);
}

export default dashboardSaga;
