import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  Table,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap";
import moment from "moment";
import "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";

import Flatpickr from "react-flatpickr";
import Select from "react-select";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
  getDownloadApp as onGetDownloadApps,
  addNewDownloadApp as onAddNewDownloadApp,
  updateDownloadApp as onUpdateDownloadApp,
  deleteDownloadApp as onDeleteDownloadApp,
  getDownloadAppLoading,
  resetDownloadAppState,
} from "../../store/downloadApp/action";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import defaultImg from "../../assets/images/upload.png";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";
import { withTranslation } from "react-i18next";
import ModalInputFile from "../../Components/ModalInputFile";
import { api } from "../../config";
import Android from "../../assets/images/android-logo-12377-1210x660.png"

const DownloadApp = (props) => {
  const { t } = props;
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [logo, setLogo] = useState(defaultImg);
  const dispatch = useDispatch();
  const [pIndex, setPIndex] = useState(0);

  const {
    downloadApps,
    isDownloadAppCreated,
    isDownloadAppSuccess,
    loading,
    error,
  } = useSelector((state) => ({
    downloadApps: state.DownloadApp.downloadApps,
    isDownloadAppCreated: state.DownloadApp.isDownloadAppCreated,
    isDownloadAppSuccess: state.DownloadApp.isDownloadAppSuccess,
    loading: state.DownloadApp.loading,
    error: state.DownloadApp.error,
  }));

  const [downloadAppList, setDownloadAppList] = useState([]);
  const [downloadApp, setDownloadApp] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [statusActive, setStatusActive] = useState([]);
  const statusActiveData = [
    {
      options: [
        { label: "Draft", value: 1 },
        { label: "Activated", value: 2 },
        { label: "Deactivated", value: 3 },
      ],
    },
  ];

  const [isSearch, setIsSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [userType, setUserType] = useState("user");
  const [file, setFile] = useState([]);
  const [mediaType, setMediaType] = useState("");
  const [modalMedia, setModalMedia] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const toggleMedia = useCallback(() => {
    if (modalMedia) {
      setModalMedia(false);
    } else {
      setModalMedia(true);
    }
  }, [modalMedia]);

  const onClickDelete = (downloadApp) => {
    setDownloadApp(downloadApp);
    setDeleteModal(true);
  };

  const handleDeleteDownloadApp = () => {
    if (downloadApp) {
      dispatch(onDeleteDownloadApp(downloadApp));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setDownloadAppList(downloadApps);
  }, [downloadApps]);

  useEffect(() => {
    if (!isEmpty(downloadApps)) setDownloadAppList(downloadApps);
  }, [downloadApps]);

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredDownloadApps = downloadApps;
      if (type !== "all") {
        filteredDownloadApps = downloadApps.filter(
          (downloadApp) => downloadApp.device_status.name === type
        );
      }
      setDownloadAppList(filteredDownloadApps);
    }
  };

  useEffect(() => {
    if (!isSearch) {
      dispatch(resetDownloadAppState());
      dispatch(onGetDownloadApps());
    }
    setIsSearch(true);
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    if (obj) {
      if (obj.data && obj.data.userType) setUserType(obj.data.userType);
    } else {
      window.location = "/logout";
    }
  }, [dispatch, downloadApps, isSearch]);

  useEffect(() => {
    setDownloadApp(downloadApps);
  }, [downloadApps]);

  useEffect(() => {
    if (!isEmpty(downloadApps)) {
      setDownloadApp(downloadApps);
      setIsEdit(false);
    }
  }, [downloadApps]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setDownloadApp(null);
      setLogo(defaultImg);
      setFile([]);
      forceUpdate();
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleDeleteFiles = useCallback(
    (files) => {
      // const object = file.findIndex((obj) => obj === files);
      if (file) {
        file.splice(file.indexOf(files), 1);
        setFile(file);
        forceUpdate();
      }

      // if (object > -1) {
      //   file.splice(object, 1);
      // }
    },
    [file, forceUpdate]
  );

  const handleSelectFile = (file) => {
    if (Array.isArray(file)) {
      setFile(file)
    } else {
      switch (mediaType) {
        case "device":
          validation.setFieldValue("file", file.filename);
          break;
        case "image":
          validation.setFieldValue("image", file.filename);
          break;
        default:
          break;
      }
    }

    toggleMedia();
  };

  const handleDownloadAppClick = useCallback(
    (arg) => {
      const downloadApp = arg;

      // setFile(dataFile);

      setDownloadApp({
        id: downloadApp.id,
        name: downloadApp.name,
        version: downloadApp.version,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (downloadApp && downloadApp.name) || "",
      version: (downloadApp && downloadApp.version) || "",
      file: (downloadApp && downloadApp.file) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      version: Yup.string().required("Please Enter Version"),
      description: Yup.object().required("Please Select Description"),
    }),
    onSubmit: (values) => {
      dispatch(getDownloadAppLoading());
      if (isEdit) {
        const editDownloadApp = {
          id: downloadApp ? downloadApp.id : 0,
          name: values.name,
          version: values.version,
          file: file,
          description: values.description,
        };
        dispatch(onUpdateDownloadApp(editDownloadApp));
        validation.resetForm();
      } else {
        const newDownloadApp = {
          name: values.name,
          version: values.version,
          file: file,
          description: values.description,
        };
        dispatch(onAddNewDownloadApp(newDownloadApp));
        validation.resetForm();
      }
      toggle();
    },
  });

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".dataCheckbox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".dataCheckbox:checked");
    const checkall = document.getElementById("checkBoxAll");
    ele.forEach((element) => {
      dispatch(onDeleteDownloadApp(element.value));
    });
    checkall.checked = false;
  };

  // able Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type='checkbox'
            id='checkBoxAll'
            className='form-check-input'
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type='checkbox'
              className='dataCheckbox form-check-input'
              value={cellProps.row.original.id}
            />
          );
        },
        id: "#",
      },
      {
        Header: " ",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <div className='hstack gap-2 mt-4 mt-sm-0'>
              <Link
                to='#'
                className='btn-icon btn btn-sm btn-soft-primary edit-item-btn'
                onClick={() => {
                  const downloadAppData = cellProps.row.original;
                  handleDownloadAppClick(downloadAppData);
                }}>
                <i className='ri-pencil-line fs-16'></i>
              </Link>
              <Link
                to='#'
                className='btn-icon btn btn-sm btn-soft-danger remove-item-btn'
                onClick={() => {
                  const downloadAppData = cellProps.row.original;
                  onClickDelete(downloadAppData);
                }}>
                <i className=' ri-delete-bin-line fs-15'></i>
              </Link>
            </div>
          );
        },
      },
      {
        Header: `${t("Name")}`,
        accessor: "name",
        filterable: true,
        Cell: (downloadApp) => (
          <>
            <div className='d-flex align-items-center'>
              <div className='flex-shrink-0'>
                <div className='flex-shrink-0 avatar-xs me-2'>
                  <div className='avatar-title bg-soft-success text-success rounded-circle fs-13'>
                    {downloadApp.row.original.name.charAt(0).toUpperCase()}
                  </div>
                </div>
              </div>
              <div className='flex-grow-1 ms-2 name'>
                {downloadApp.row.original.name}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: `${t("Version")}`,
        accessor: "version",
        filterable: true,
      },
      {
        Header: `${t("File")}`,
        accessor: "file",
        filterable: true,
      },
      {
        Header: `${t("Description")}`,
        accessor: "description",
        filterable: true,
      },
    ],
    [handleDownloadAppClick, t]
  );

  const defaultdate = () => {
    return moment().tz("Asia/Jakarta").format("YYYY/MM/DD HH:mm:ss").toString();
  };

  const [filterDate, setFilterDate] = useState(defaultdate());

  const handleSubscribedAt = (date) => {
    validation.setFieldValue("subscribedat", date);
  };

  const handleExpirededAt = (date) => {
    validation.setFieldValue("expiredat", date);
  };

  const handleValidDate = (date) => {
    return moment(new Date(date), "YYYY/MM/DD")
      .tz("Asia/Jakarta")
      .format("MMM D, YYYY");
  };

  const handleValidTime = (time) => {
    return moment(new Date(time), "YYYY/MM/DD")
      .tz("Asia/Jakarta")
      .format("HH:mm");
  };

  //TABLE
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: downloadAppList,
      defaultColumn: { Filter: null },
      initialState: {
        pageIndex: pIndex,
        pageSize: 10,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const generateFilterable = (column) => {
    return column.filterable
      ? column.isSorted
        ? column.isSortedDesc
          ? " sorting_desc"
          : " sorting_asc"
        : " sort"
      : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const count = preGlobalFilteredRows.length;
  const [searchValue, setSearchValue] = useState([]);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleFilter() {
    let startat = "";
    let endat = "";
    if (filterDate) {
      let datearr = filterDate.split(" to ");
      if (datearr[1]) {
        startat = datearr[0];
        endat = datearr[1];
      }
    }
    var status = statusActive.value ? statusActive.value : "";
    const param = {
      title: searchValue,
      startat: startat,
      endat: endat,
      status: status,
    };
    dispatch(onGetDownloadApps(param));
  }

  function handleLogoChange(event) {
    if (event.target.files && event.target.files[0]) {
      setLogo(URL.createObjectURL(event.target.files[0]));
      validation.setFieldValue("img", event.target.files[0]);
    }
  }

  useEffect(() => {
    setPIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    if (validation.values.file) {
      setFile((file) => [...file, { filename: validation.values.file }]);
    }
  }, [validation.values.file]);

  document.title = `${t("Download App")} | TELKOMSEL - IOT Monitoring`;
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={t("Download App")} pageTitle={t("")} />
        <Row>
          <Col lg={4}>
            <Card className="p-3">
              <img src={Android} />
              <div className="d-flex justify-content-between">
                <h4 className="mt-4">
                  {`Name : ${downloadApps[0]?.name}`}
                </h4>
                <h4 className="mt-4">
                  {`Version : ${downloadApps[0]?.version}`}
                </h4>
                <a className="mt-3" href={`https://api-iot.atapteknologi.id/apks/download?id=${downloadApps[0]?.id}`} download>
                  <button className="btn btn-success">Download</button>
                </a>
              </div>

            </Card>
          </Col>
        </Row>
      </Container>
      <ModalInputFile
        onSelect={handleSelectFile}
        loading={loading}
        modal={modalMedia}
        toggle={toggleMedia}
        t={t}
      />
    </div>
  );
};

DownloadApp.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(DownloadApp));
