import React, { useState } from 'react';
import { leftSidebarTypes } from "../../Components/constants/layout";

const SideBarType = ({ sidebarType, onChangeSidebarTheme }) => {

    const mode = sidebarType === leftSidebarTypes['DARK'] ? leftSidebarTypes['LIGHT'] : leftSidebarTypes['DARK'];
    const [isDark, setIsDark] = useState(true);
    return (
        <div className="ms-1 header-item d-none d-sm-flex">
            <button
                onClick={() => {
                    sidebarType === leftSidebarTypes['LIGHT'] ? setIsDark(true) :  setIsDark(false);
                    onChangeSidebarTheme(mode);
                }}
                type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none">
                <i className={ isDark ? 'ri-book-read-fill fs-22' : ' ri-book-read-line fs-22'}></i>
            </button>
        </div>
    );
};

export default SideBarType;